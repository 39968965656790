import React, { useEffect, useState } from 'react';
import { Offcanvas, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import ScheduleContact from './ScheduleContact';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { encrypt_Decrypt_Plan } from '../../../../constants/utils';

const Contact = (props: any) => {

    const location = useLocation();
    const [t] = useTranslation();
    const nagivate = useNavigate();
    const { showContact, contactClose, contactShow, singleContact, state, setLoading, currentContract, loading } = props;
    const [showScheduleContactModal, setScheduleContactModal] = useState(false);
    const scheduleContactModalClose = () => setScheduleContactModal(false);
    const scheduleContactModalShow = () => {
        contactClose(true);
        setScheduleContactModal(true);
    }
    const [storeData, setStoreData] = useState([]);
    useEffect(() => {
        if (showContact) {
            setStoreData(singleContact);
        }
    }, [showContact])

    const [userPlanString, setUserPlanString] = useState("");
    useEffect(() => {
        let userPLAN = encrypt_Decrypt_Plan();
        // setUserPlanString(userPLAN)
    setUserPlanString("300")
    }, [location])

    const NofityEmail = (e: any) => {
        e.preventDefault();
        let data = {
            email: (singleContact?.[0]?.["ST_EMAIL_PES"] && singleContact?.[0]?.["ST_EMAIL_PES"] !== "") ? singleContact?.[0]?.["ST_EMAIL_PES"] : singleContact?.[0]?.["ST_EMAIL_SAC"],
            template_id: "",
            contract_id: currentContract.ID_CONTRATO_CON
        }
        nagivate("/notify_email", { state: data });
    }
    return (
        <>
            <Offcanvas className="border-sm-none" show={showContact} onHide={contactClose} placement="end">
                <Form className="d-flex flex-column h-100">
                    <Offcanvas.Header className="border-bottom" closeButton>
                        {t("formLabel.contact")}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="d-sm-flex align-items-center justify-content-between mb-lg-4 mb-3">
                            <h6 className="fw-bold md-sm-0">{t("NonPayment.clientInformation")}</h6>
                            {singleContact?.[0]?.["ID_PESSOA_PES"] &&
                                <span className="fw-bold text-primary cursor-pe">
                                    <Link to={`/profile_details/${singleContact[0]["ID_PESSOA_PES"]}`}>{t("NonPayment.openCompleteRecord")}</Link>
                                </span>
                            }
                        </div>
                        <Row className="g-lg-4 g-3">
                            <Col sm={12}>
                                <span className="fw-semibold text-secondary">{t("formLabel.name")}</span>
                                <h6 className="mb-0">
                                    {(singleContact?.[0]?.["ST_NOME_PES"] && singleContact?.[0]?.["ST_NOME_PES"] !== "") ? singleContact?.[0]?.["ST_NOME_PES"] : (singleContact?.[0]?.["ST_NOME_SAC"] ?? "N/A")}
                                    {/* {singleContact?.[0]?.["ST_NOME_PES"] ?? singleContact?.[0]?.["ST_NOME_SAC"]} */}
                                </h6>
                            </Col>
                            <Col sm="auto">
                                <span className="fw-semibold text-secondary">{t("NonPayment.fixedLinePhone")}</span>
                                <h6 className="mb-0">
                                    {(singleContact?.[0]?.["ST_TELEFONE_PES"] && singleContact?.[0]?.["ST_TELEFONE_PES"] !== "") ? singleContact?.[0]?.["ST_TELEFONE_PES"] : (singleContact?.[0]?.["ST_TELEFONE_SAC"] ?? "N/A")}
                                    {/* {singleContact?.[0]?.["ST_TELEFONE_PES"] ?? singleContact?.[0]?.["ST_TELEFONE_SAC"]} */}
                                </h6>
                            </Col>
                            <Col sm="auto">
                                <span className="fw-semibold text-secondary">{t("formLabel.cellPhone")}</span>
                                <h6 className="mb-0">
                                    {(singleContact?.[0]?.["ST_CELULAR_PES"] && singleContact?.[0]?.["ST_CELULAR_PES"] !== "") ? singleContact?.[0]?.["ST_CELULAR_PES"] : (singleContact?.[0]?.["ST_CELULAR_SAC"] ?? "N/A")}
                                    {/* {singleContact?.[0]?.["ST_CELULAR_PES"] ?? singleContact?.[0]?.["ST_CELULAR_SAC"]} */}
                                </h6>
                            </Col>
                            <Col sm={12}>
                                <span className="fw-semibold text-secondary">{t("formLabel.email")}</span>
                                <h6 className="mb-0">
                                    {(singleContact?.[0]?.["ST_EMAIL_PES"] && singleContact?.[0]?.["ST_EMAIL_PES"] !== "") ? singleContact?.[0]?.["ST_EMAIL_PES"] : (singleContact?.[0]?.["ST_EMAIL_SAC"] ?? "N/A")}
                                    {/* {singleContact?.[0]?.["ST_EMAIL_PES"] ?? singleContact?.[0]?.["ST_EMAIL_SAC"]} */}
                                </h6>
                            </Col>
                        </Row>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {currentContract && currentContract?.["ID_CONTRATO_CON"] &&
                            <Link to={"#"} onClick={NofityEmail} className="btn btn-outline-primary mx-1">{t("NonPayment.notifyEmail")}</Link>
                        }
                        {
                            userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" &&
                            <Button variant="primary" onClick={scheduleContactModalShow}>{t("NonPayment.scheduleContact")}</Button>
                        }
                    </div>
                </Form>
            </Offcanvas>
            {/* modal start */}
            <ScheduleContact
                showScheduleContact={showScheduleContactModal}
                scheduleContactClose={scheduleContactModalClose}
                storeData={storeData[0] ?? storeData}
                type="schedule"
                other=""
                state={state}
                loading={loading}
                setLoading={setLoading}
            />
        </>
    )
}

export default Contact
