import axios from 'axios';
import { gettoken, config } from './apiConfig';
const companyV2_URL = config.companyV2_URL;
const notificationsV2URL = config.notificationsV2URL;
axios.defaults.headers.common['Content-Type'] = "application/json";
const Token = gettoken.Token;
axios.defaults.headers.common['x-auth-token']    = localStorage.getItem("tokenV2");//Token;
axios.defaults.headers.common['connection-name'] = localStorage.getItem("userData");

export const notificationService = {
    addNotificationRecords : async function(param){
        const response = await axios.request({
            url: `${notificationsV2URL}/notifications/addNotifications`,
            method: "post", 
            data: param
        })
        return response.data
    },
    fetchNotificationData : async function(param){
        const response = await axios.request({
            url:`${companyV2_URL}/notifications/fetchNotifications`,
            method:"post",

        })
        return response.data
    }
}