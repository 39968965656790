import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type SuccessToastProps = {
  message: string;
  show: boolean;
  onClose: () => void;
};

export function SuccessToast(props: SuccessToastProps) {
  const { message, show, onClose } = props;
  return (
    <ToastContainer
      className="p-3 position-fixed"
      position="bottom-end"
      style={{ zIndex: 10 }}
    >
      <Toast
        className="rounded-4 text-white border-0"
        onClose={onClose}
        show={show}
        style={{ backgroundColor: "#404040" }}
        delay={3000}
        autohide
      >
        <Toast.Body className="d-flex align-items-center p-3">
          <img
            className="me-2 me-xxl-4 me-xl-3"
            src="assets/images/icons/check-circle-success.svg"
            alt="Check circle"
          />
          <span className="me-2">{message}</span>
          <span className="ms-auto cursor-pe" onClick={onClose}>
            <img
              src="assets/images/icons/dismiss.svg"
              alt="X"
              width={16}
              height={16}
            />
          </span>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
