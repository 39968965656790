import React, { useEffect, useState } from 'react'
import { Dropdown, Col, Row, Form, Button, Modal, Card, Table, FloatingLabel, Alert, Accordion } from 'react-bootstrap';
import { ComProps } from '../../../Interface';
import { Outlet, Link, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../redux/store';
import { handleAction } from '../../../Profile/Company/actions';
import { useTranslation } from 'react-i18next';
import { ownerForm } from '../../../Interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import customerService from '../../../../services/customerService';
import Swal from 'sweetalert2';
import Loader from '../../../Profile/Company/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';
import i18n from '../../../../i18n';

const Broker_details = ({ submenu }: ComProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { billingData } = useSelector((state: RootState) => state.expenditureData)
  useEffect(() => {
    dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
  }, [dispatch]);

  const { id } = useParams();
  const [t] = useTranslation();
  const [admName, setAdmName] = useState<any>()
  const [status, setStatus] = useState(true)
  const propertyValidation = Yup.object().shape({
    cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),

  });
  let frm = {
    resolver: yupResolver(propertyValidation)
  }
  const { register, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<ownerForm>(frm);


  useEffect(() => {
    let brokerData = { broker: "", start: 0, end: 10000 }
    customerService.getAllBroker(brokerData).then(res => {
      if (res.data.resCode === 201) {
        res.data.data.forEach((item: any) => {
          if (item.id == id) {
            setAdmName(item?.name)
            const fields: string[] = ['id', 'cnpj', 'name', 'payment', 'mobile', 'telephone', 'email', 'rg', 'issue_agency', 'shipping_date', 'sex', 'birth', 'marital_status', 'nationality', 'observation'];
            fields.forEach((field: any) => setValue(field, item[field]));

          }
        });
      }
      else {
        // setData([]);
        // setTotal(0)
      }
    }).catch(err => {
      console.log("err", err)
    });
  }, [])

  const onSubmit = async (data: any) => {
    customerService.updateBroker(data).then(res => {
      /*-------Check the api response--------*/
      if (res.data.resCode === 200) {
        setStatus(true)
        Swal.fire({
          title: t("Sweetalert.Update"),
          text: t("Sweetalert.updatesuccess"),
          // icon: 'success',"success",
          confirmButtonText: "OK",
          timer: 2000
        });
      }
      else {
        setStatus(false)
        Swal.fire({
          title: t("Sweetalert.Error"),
          text: t("Sweetalert.somethingWent"),
          // icon: 'success',"error",
          confirmButtonText: "OK",
          timer: 2000
        });
      }
    }).catch(error => {
      /*-------Error exception handling--------*/
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000
      });
    });
  }
  const handleEdit = (e: any, cmd: string) => {
  }
  const HandleInput = (e: any) => {
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
    if (newValue == "" || newValue == null || newValue == undefined) {
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`
      });
    } else {
      clearErrors('telephone')
    }
  }
  const HandleInputMob = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("mobile", {
        type: "manual",
        message: `${t("requiredMmessage.mobileError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('mobile');
    }
  }

  const handleCPF_CNJPVaue = async (e, type) => {
    let name = e.target.name;
    let checkCNJP = CNJP_CPFValidation(e.target.value, type);
    if (checkCNJP?.["status"] || e.target.value == "") {
      clearErrors(name);
      let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
      zipCodeCheck?.["setAttribute"]('disabled', "")
      if (e.target.value !== "") {
        let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, id);
        if (duplicateData["data"] > 0) {
          zipCodeCheck?.["setAttribute"]('disabled', "")
          setError(name, {
            type: "manual",
            message: t("formLabel.ErrorCNPJCPF")
          });
        } else {
          zipCodeCheck?.["removeAttribute"]('disabled');
        }
      }
    } else {
      // errorCNPJCPF(e.target.value)
      setError(name, {
        type: "manual",
        message: checkCNJP?.["message"]
      });
    };
  }

  return (
    <section className="content-wrapper">
      {submenu}
      {/*  <!-- Breadcrumb section start from here --> */}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
        <div className="w-100">
          <Row className="gx-2 justify-content-between align-items-center">
            <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
              <Link to={"/brokers"}>
                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
              </Link>
            </Col>
            <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mb-md-0 mb-1">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      {/* <!-- /Breadcrumb section end --> */}
      {/* <!-- Main content start from here --> */}
      {status == false ?
        <Loader />
        : <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
          <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 ">
            <Col xl={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg={8}>
                      <div className="d-flex align-items-center">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/home-reqular.svg"} alt="Folder" className="h-40 flex-shrink-0" />
                        <div className="w-100 ms-2 ms-lg-3">
                          <h1 className="h3 lh-base mb-0">{admName}</h1>
                          <p className="mb-0">Broker</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <ul
                        className="list-inline d-sm-flex align-items-center justify-content-start justify-content-lg-end mb-0">
                        <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                          <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                              Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mh-456 overflow-auto">
                              <Dropdown.Item>Historic</Dropdown.Item>
                              <Dropdown.Item>Delete</Dropdown.Item>
                              <Dropdown.Item>Invite to use the Owal app</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li className="list-inline-item mb-sm-0 mb-1">
                          <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic2">
                              Printout
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#">Contract</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                  data
                  <span className="d-block TOEDIT">
                    <span>
                      <span>
                        <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "administ")}>
                          {t("button.edit")}
                        </Button>
                      </span>
                    </span>
                  </span>
                  <div className="d-none ACTION">
                    <Row>
                      <Col xl={4} sm={4}>
                        <Button variant="link " className="p-0 fw-bold text-nowrap zipCodeCheck" onClick={(e) => handleAction(e, "update", "administ")} type="submit">
                          {t("button.toSave")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit(onSubmit)} id="editform_companydata">
                    <Form.Control hidden type="text" placeholder="id"  {...register('id')} />
                    <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf')} className="mb-3 removeInsertDisable" >
                      <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={`form-control ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} disabled onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                      <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel controlId="Name" label="Name" className="mb-lg-4 mb-3 removeInsertDisable">
                      <Form.Control disabled type="text" placeholder="Name" className={`${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                      <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                    <span className="d-block mb-3 fw-bold">{t('formLabel.payment')}</span>
                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.defaultPaymentMethod')}>
                      <Form.Select className={`form-control ${errors.payment ? 'is-invalid' : ''}`}  {...register('payment')} aria-label={t('formLabel.defaultPaymentMethod')} id="removeInsertDisable" disabled>
                        <option value="">{t('dropdownItem.select')}</option>
                        {/* {
                          billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                              <option key={index} value={Number(item.id)}>{item.name}</option>
                          ))
                              : ''
                      } */}
                        <option value="1">{t("defaultPaymentMethod1.chequedeposit")}</option>
                        <option value="2">{t("defaultPaymentMethod1.cashdeposit")}</option>
                        <option value="3">{t("defaultPaymentMethod1.pickupchequeonsite")}</option>
                        <option value="4">{t("defaultPaymentMethod1.withdrawmoneyonthespot")}</option>
                        <option value="5">{t("defaultPaymentMethod1.banktransfer")}</option>
                        <option value="6">{t("defaultPaymentMethod1.docted")}</option>
                        <option value="12">{t("defaultPaymentMethod1.pixboleto")}</option>
                      </Form.Select>
                      <div className="invalid-feedback">{errors.payment?.message}</div>
                    </FloatingLabel>
                    <Accordion className="accordion-custom">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className='d-block mb-3 fw-bold'>{t('formLabel.contact')}</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col>
                              <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} disabled />
                                <div className="invalid-feedback">{errors.mobile?.message}</div>
                              </FloatingLabel>
                            </Col>
                            <Col>
                              <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} disabled />
                                <div className="invalid-feedback">{errors.telephone?.message}</div>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.email')}>
                            <Form.Control type="email" placeholder={t('formLabel.email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} disabled />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                          </FloatingLabel>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col>
                              <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('RG')}>
                                <Form.Control type="text" placeholder={t('RG')}  {...register('rg')} disabled />
                              </FloatingLabel>
                            </Col>
                            <Col>
                              <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('issue_agency')} disabled />
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                <Form.Control type="text" placeholder={t('formLabel.shippingDate')}  {...register('shipping_date')} disabled />
                              </FloatingLabel>
                            </Col>
                            <Col>
                              <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                <Form.Select {...register('sex')} aria-label={t('formLabel.sex')} disabled id="removeInsertDisable">
                                  <option value="">{t('dropdownItem.select')}..</option>
                                  <option value="1">{t('formLabel.male')}</option>
                                  <option value="2">{t('formLabel.female')}</option>
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.birth')}>
                                <Form.Control type="text" placeholder={t('formLabel.birth')}  {...register('birth')} disabled />
                              </FloatingLabel>
                            </Col>
                            <Col>
                              <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} id="removeInsertDisable" disabled>
                                 <option value="">{t('formLabel.select')}</option>
                                                        <option value="1">{t("formLabel.married")}</option>
                                                        <option value="2">{t("formLabel.single")}</option>
                                                        <option value="3">{t("formLabel.widower")}</option>
                                                        <option value="4">{t("formLabel.legallySeparated")}</option>
                                                        <option value="5">{t("formLabel.disquitado")}</option>
                                                        <option value="6">{t("formLabel.divorced")}</option>
                                                        <option value="7">{t("formLabel.stableunion")}</option>

                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.nationality')}>
                            <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('nationality')} disabled />
                          </FloatingLabel>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                        <Accordion.Body>
                          <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')} className='removeInsertDisable'>
                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} disabled />
                          </FloatingLabel>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Button className="submitForm zipCodeCheck" type="submit" variant="primary" hidden>
                      {t("button.update")}
                    </Button>
                    <Button className="fillModalwithThisData" onClick={(e) => handleEdit(e, "administ")} hidden>
                      {t("button.edit")}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={6}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Latest payments</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={10}>
                        <span className="d-block mb-3 fw-bold">Contact in</span>
                        <FloatingLabel className="mb-3" controlId="floatingSelect" label="Contact in">
                          <Form.Select className={`form-control ${errors.payment ? 'is-invalid' : ''}`} aria-label={t('formLabel.defaultPaymentMethod')} id="removeInsertDisable">
                            <option value="">{t('dropdownItem.select')}..</option>
                            <option value="1">Active</option>
                            <option value="2">paused</option>
                            <option value="3">Pending</option>
                            <option value="4">Suspended</option>
                            <option value="5">Terminated and terminated</option>
                            <option value="6">Todos</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Email list</Accordion.Header>
              <Accordion.Body>
                <Alert variant="warning" className="text-center my-lg-3 my-2">
                  No email in the mailing list.
                </Alert>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Payments for the last 2 years</Accordion.Header>
              <Accordion.Body>
                <Alert variant="warning" className="text-center my-lg-3 my-2">
                  No payments found.
                </Alert>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Conquered properties</Accordion.Header>
              <Accordion.Body>
                <Alert variant="warning" className="text-center my-lg-3 my-2">
                  No properties found.
                </Alert>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </main>
      }

      {/* <!-- /Main content start end --> */}
    </section >
  )
}

export default Broker_details