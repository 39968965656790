import Swal from "sweetalert2";
import { getDimobIntegrationFile } from "../services/getDimobIntegrationFile";
import { t } from "i18next";

export async function handleExport(dimobContracts: any[], selectedYear: string) {

    const payload = {
        baseYear: Number(selectedYear),
        contracts: dimobContracts.map(contract => contract.id)
    };

    try {
        const fileBlob = await getDimobIntegrationFile(payload);

        const url = window.URL.createObjectURL(fileBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `dimob_${selectedYear}.txt`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        Swal.fire({
            title: t("Sweetalert.Success"),
            text: t("Sweetalert.integrationFileGeneratedSuccessfully"),
        });
    } catch (err) {
        console.error("Erro ao exportar:", err);

        Swal.fire({
            title: t("Sweetalert.Error"),
            text: t("Sweetalert.integrationFileGenerationFailed"),
        });
    }
}
