export enum EnabledBanksToHomologate {
  "BancoDoBrasil" = "1",
  "Santander" = "33",
  "Inter" = "77",
  "CaixaEconomica" = "104",
  "Bradesco" = "237",
  "C6" = "336",
  "Itau" = "341",
  "Safra" = "422",
  "Sicredi" = "748",
  "Sicoob" = "756",
}

export enum AccountType {
  CORRENTE = "1",
  MANUAL = "2",
  INVESTIMENTO = "3",
}
