import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { handleAction, handleSubmitData } from './notification';
import { useForm, } from "react-hook-form";
import { modal_reducer, gurantees_reducer } from "../../../../redux/slice/notificationSlice";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Gurantees = (props: any) => {
    const inputElement : any = React.useRef(null);
    let { dispatch, stateData,notificationData } = props;
    const navigate = useNavigate();


    // The useForm hook is used to handle form registration, submission, and validation.
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({});
    const [object, setObject] = useState([]);
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation()
    const { gurantees } = stateData;
    let basicdata = {
        dispatch,
        stateData,
        modal_reducer,
        gurantees_reducer
    }

    useEffect(() => {
        // Set the initial value of 'object' to gurantees.basic_data
        setObject(gurantees.basic_data);
    }, [])
    useEffect(() => {
        if(notificationData){
          setValue("send_notification_before", Number(notificationData["gurantees_send_notification_before"]))
          setValue("days_before", Number(notificationData["gurantees_days_before"]))
          setValue("send_notification_after", Number(notificationData["gurantees_send_notification_after"]))
          setValue("days_after", Number(notificationData["gurantees_days_after"]))
        }
      },[notificationData])
    useEffect(() => {
        // Retrieve the field names from the `gurantees.basic_data` object
        let fields: string[] = Object.keys(gurantees.basic_data);
        // Set the form field values using the field names and corresponding values from `gurantees.basic_data`
        fields.forEach((field: any) => setValue(field, gurantees?.basic_data?.[field]));
    }, [object, stateData])

    let page = "gurantees";
    // Function to handle form submission
    const onSubmit = async (data: any) => {
        let checkSubmition = true;
        // Check if any input value contains a negative number
        document.querySelectorAll(`.${page}.removeInsertDisable`).forEach(item => {
            if (item["value"].includes('-')) {
                checkSubmition = false;
            }
        })
        if (checkSubmition) {
            // Proceed with form submission
            handleSubmitData(data);
        } else {
            Swal.fire({
                position: 'center',
                title: `${t('Sweetalert.Error')}`,
                text: `${t('Sweetalert.negativeNumberNotAllowed')}`,
                showConfirmButton: true,
                timer: 1000
            })
        }
        inputElement.current?.click()
    }

    const handleEmailName = (id,NOME, LABEL) => {
        document.querySelector('#root')?.setAttribute('st_nome_moh', NOME);
        document.querySelector('#root')?.setAttribute('st_label_moh', LABEL);
        localStorage.setItem("YhGUFY_hygft",id);
        let data = {
          notification: "notification"
        }
        navigate("/message", { state: data });
    }

    return (
        <Col lg={6}>
            <Card className="h-100">
                <Card.Header className="d-flex justify-content-between">
                    <div className="w-80 me-2">{t("notifications.expirationGurantees")}
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-property-id">{t('notifiToolTip.renterExpiry')}</Tooltip>}
                        >
                        <img src="assets/images/icons/info-light.svg" alt="Info icon" className="h-18 ms-2" />
                        </OverlayTrigger>
                    </div>
                    <span className='d-block TOEDIT'>
                        <span>
                            <span>
                                <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "gurantees", basicdata)}>{t("button.edit")}</Button>
                            </span>
                        </span>
                    </span>
                    <div className="d-none ACTION">
                        <Row>
                            <Col xl={4} sm={4}>
                                <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "save", "gurantees", basicdata)} >{t("button.save")}</Button>
                            </Col>
                            {/* <Col xl={4} sm={4}>
                                <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "edit", "gurantees", basicdata)}>Edit</Button>
                            </Col> */}
                            <Col xl={4} sm={4}>
                                <Dropdown className="radio--custom" autoClose="outside">
                                    <Dropdown.Toggle className='p-0 text-nowrap' variant='link' size="sm">
                                        <b>{t("button.edit")}</b>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <label className="d-block cursor-pe" htmlFor="ft9" onClick={() => handleEmailName("28","A garantia do seu contrato de locação venceu", "notificacoescontratuais")}>
                                            <input type="radio" name="statusFilter" id="ft9" />
                                            <div className="radio-inner px-4 py-2">
                                                {t("notifications.beforeExpiration")}
                                            </div>
                                        </label>
                                        <label className="d-block cursor-pe" htmlFor="ft10" onClick={() => handleEmailName("27","A garantia do seu contrato de locação vence em breve", "notificacoescontratuais")}>
                                            <input type="radio" name="statusFilter" id="ft10" />
                                            <div className="radio-inner px-4 py-2">
                                                {t("notifications.afterExpiration")}
                                            </div>
                                        </label>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl={4} sm={4}>
                                <Button variant="link" className="p-0 fw-bold text-nowrap" ref={inputElement} onClick={(e) => handleAction(e, "cancel", "gurantees", basicdata)} >{t("button.cancel")}</Button>
                            </Col>
                        </Row>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Form.Check className="mb-3 mb-lg-4 removeInsertDisable" {...register("send_notification_before")} type="checkbox" id={`default_6`} label={t("notifications.checkNotification")} disabled />
                        <Row className="gx-3 gy-xl-4 gy-3 align-items-center mb-lg-4 mb-3">
                            <Col sm={3} xl={2}>
                                <Form.Control type="number" className={`${page} removeInsertDisable`} {...register("days_before")} aria-label="days" min="0" disabled />
                            </Col>
                            <Col className="col-sm">{t("notifications.daysBeforeExpiration")}</Col>
                        </Row>
                        <Form.Check className="mb-3 mb-lg-4 removeInsertDisable" type="checkbox" {...register("send_notification_after")} id={`default_7`} label={t("notifications.checkNotification")} disabled />
                        <Row className="gx-3 gy-xl-4 gy-3 align-items-center">
                            <Col sm={3} xl={2}>
                                <Form.Control type="number" className={`${page} removeInsertDisable`} {...register("days_after")} aria-label="days" min="0" disabled />
                            </Col>
                            <Col sm>{t("notifications.daysAfterExpiration")}</Col>
                        </Row>
                        <Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default Gurantees