import React, { useEffect, useState } from 'react'
import { Dropdown, Col, Row, Form, Button, Modal, Card, Table, FloatingLabel, Alert, Accordion } from 'react-bootstrap';
import { ComProps } from '../../../Interface';
import { Link, useParams } from 'react-router-dom';
import { getAllProperty_administractor } from '../../../../redux/slice/contractDataSlice';
import { AppDispatch } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';

import moment from "moment-timezone";
const Mangers_details = ({ submenu }: ComProps) => {
    let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const { id } = useParams()
    const [t] = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState([])
    const [mangerData, setMangerData] = useState<any>()
    const [Vacant, setVacant] = useState<number>(0)
    const [leasedProperty, setLeasedProperty] = useState<number>(0)
    const [achieved, setAchieved] = useState<number>(0)
    useEffect(() => {
        (async () => {
            let resp = await dispatch(getAllProperty_administractor({
                start: 0,
                end: 10000
            }));
            let allData: any = [];
            let setcount: number = 0;
            let leasedcount: number = 0;
            let achievedcount: number = 0
            resp.payload.data.forEach((item) => {
                if (Number(item.manager_id) === Number(id)) {
                    allData.push(item)
                    // Parse contract creation date and get the month (1-indexed)
                    const contractDate = new Date(item.contract_creation_date);
                    // Get the month and year of the contract date
                    const contractMonth = contractDate.getMonth() + 1; // Adding 1 to get 1-indexed month
                    const contractYear = contractDate.getFullYear();

                    // Get the current month and year
                    const currentDate = new Date(dateBrazil);
                    const currentMonth = currentDate.getMonth() + 1; // Adding 1 to get 1-indexed month
                    const currentYear = currentDate.getFullYear();

                    // Check if both month and year are matching
                    if (contractMonth === currentMonth && contractYear === currentYear) {
                        achievedcount++;
                    }
                    if (item.contractId == "" || item.contractId == null) {
                        setcount++
                    } else {
                        leasedcount++
                    }
                }
            });
            setData(allData)
            setVacant(setcount)
            setLeasedProperty(leasedcount)
            setAchieved(achievedcount)
        })();
    }, []);

    useEffect(() => {
        let managersData = { manager: "", start: 0, end: 100 }
        customerService.getAllManagers(managersData).then(res => {
            if (res.data.resCode === 201) {
                res.data.data.forEach((item) => {
                    if (item.id == id)
                        setMangerData(item)
                })

            }
            else {
            }
        }).catch(err => {
            console.log("err", err)
        });
    }, [])
    return (
        <section className="content-wrapper">
            {submenu}
            {/*  <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <Link to={"/managers"}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                            </Link>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col xl={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col lg={8}>
                                        <div className="d-flex align-items-center">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/home-reqular.svg"} alt="Folder" className="h-40 flex-shrink-0" />
                                            <div className="w-100 ms-2 ms-lg-3">
                                                <h1 className="h3 lh-base mb-0">{mangerData?.name}</h1>
                                                <p className="mb-0">{mangerData?.telephone}</p>
                                                <p className="mb-0">{mangerData?.email}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <ul
                                            className="list-inline d-sm-flex align-items-center justify-content-start justify-content-lg-end mb-0">
                                            <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="mh-456 overflow-auto">
                                                        <Dropdown.Item>Testing</Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="list-inline-item mb-sm-0 mb-1">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic2">
                                                        Printout
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">Contract</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                        <Col md={4}>
                            <Card className="h-100">
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/refresh-light.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"/contracts"} className="d-block text-center text-dark h3 mt-3">{data?.length}</Link>
                                            <span className="d-block text-center text-secondary">{t('customerAndEmployees.active')}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/arrow-up-to-line-light.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"#"} className="d-block text-center text-dark h3  mt-3">{achieved}</Link>
                                            <span className="d-block text-center text-secondary">Achieved this month</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/refresh-light.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"#"} className="d-block text-center text-dark h3 mt-3">0</Link>
                                            <span className="d-block text-center text-secondary">Renewing this month</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/clock-light.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"#"} className="d-block text-center text-dark h3 mt-3">775%</Link>
                                            <span className="d-block text-center text-secondary">Default rate</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/arrow-down-to-line-light.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"#"} className="d-block text-center text-dark h3  mt-3">0</Link>
                                            <span className="d-block text-center text-secondary">Terminated this month</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/chart-line2.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"#"} className="d-block text-center text-dark h3  mt-3">0</Link>
                                            <span className="d-block text-center text-secondary">To readjust this month</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/home-reqular.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"#"} className="d-block text-center text-dark h3 mt-3">{Vacant}</Link>
                                            <span className="d-block text-center text-secondary">Vacant property</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Row className="h-100">
                                        <Col>
                                            <span className="d-block text-center">
                                                <img src="../assets/images/icons/home-reqular.svg" alt="progress" className="flex-shrink-0 h-20" />
                                            </span>
                                            <Link to={"#"} className="d-block text-center text-dark h3 mt-3">{leasedProperty}</Link>
                                            <span className="d-block text-center text-secondary">Leased property</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </div>
                <Accordion>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Property portfolio</Accordion.Header>
                        <Accordion.Body>
                            <Table responsive className="table-custom mb-0">
                                <thead>
                                    <tr>
                                        <th></th>

                                        <th>{t('property.immobile')}</th>
                                        <th></th>
                                        <th>{t('property.saleValue')}</th>
                                        <th>{t('property.rentAmount')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((item: any, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="check_Checkbox">
                                                        {/* <Checkbox key={item.id} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} */}
                                                        {/* /> */}
                                                    </td>
                                                    <td>
                                                        <Link className="text-reset" to={"/property_detail/" + item.id}>
                                                            <img src="../assets/images/icons/home_g.svg" />
                                                            <span className="ps-1 text-dark-70">
                                                                {item.number} - {item.address}, {item.zipcode}
                                                            </span>
                                                            <span className="d-block">
                                                                {item.city} - {item.state}
                                                            </span>
                                                        </Link>
                                                    </td>
                                                    <td className="text-dark-70">
                                                        <span className="d-block">
                                                            <img src="../assets/images/icons/user_g.svg" />
                                                            <Link to={"/profile/" + item.ownerId} className="text-secondary text-nowrap">
                                                                {item.ownerName}
                                                            </Link>

                                                        </span>
                                                        <span className="d-block">
                                                            <img src="../assets/images/icons/key_g.svg" />
                                                            <Link to={"/profile_details/" + item.tenetID} className="text-secondary text-nowrap">
                                                                {item.tenetName}
                                                            </Link>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {item.sale_value !== null ? (
                                                            <span className="d-block text-nowrap">R$ {item.sale_value}</span>
                                                        ) : (
                                                            <span>R$ {0}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.rent_amount !== null ? (
                                                            <span className="d-block text-nowrap">R$ {item.rent_amount}</span>
                                                        ) : (
                                                            <span>R$ {0}</span>
                                                        )}
                                                    </td>
                                                    <td className="text-end">

                                                        {
                                                            item.contractId ?
                                                                <Link to={"/contract_details/" + item.contractId} className="fw-bold me-2 text-nowrap">
                                                                    {t('button.seeContract')}
                                                                </Link>
                                                                :
                                                                ''
                                                        }
                                                        <Link to={"/property_detail/" + item.id} className="fw-bold text-nowrap">
                                                            {t('button.seeProperty')}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <span>{t('formLabel.noRecordsFounds')}</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
            {/* <!-- /Main content start end --> */}
        </section >
    )
}

export default Mangers_details