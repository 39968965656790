import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { setExpenseTitle, setExpenseCheckbox, setExpenseType, newExpenseOpen } from "../../../../redux/slice/contractDataSlice";
import { transferContracts, setTransferClose, setTransferId, insertTransfer, singelTransferContract, setTransferOpen } from "../../../../redux/slice/transferDataSlice";
import { setExpType, getAllContractChecklist } from "../../../../redux/slice/expenditureDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { transferForm } from '../../../Interface';
import ExpenseService from './ExpenseService';
import Swal from 'sweetalert2';
import { openErrorbox } from './actionPerform';
//import moment from "moment";
import moment from "moment-timezone";
import { numericOnly, isNumber } from './actionPerform';

const TransferService = (transferForm) => {
  let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
  
  const { stEndDate,stStartDate,transferOpen, singleTransferContractData, transferLoad, transferId } = useSelector((state: RootState) => state.transferData)
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const [double, setDouble] = useState(false);
  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).format("MMM/YYYY");
  let endDt = moment(newDate).add(2, 'M').format("MMM/YYYY");
  let [ user,setUser ] = useState<any>([]);
  let [ transferAmount,setTransferAmount ] = useState<any>([]);
  const [state, setState] = useState({
    contract: "",
    owner: "",
    startDate: "",
    endDate: "",
    filteSearchDate:"",
    filterEndDate:"",
    start: 0,
    end: 10
  })
  const transferClosed = () => {
    dispatch(setTransferId(''));
    reset();
    dispatch(setTransferClose(false))
    setDouble(false)
  }
  useEffect(() => {
    setState({
      ...state,
      ['filteSearchDate']: stStartDate,
      ['filterEndDate']: stEndDate
    })
    getTranferData(transferId);
  }, [transferId])
  let splitUser: any = [];
  let tsfAmount: any = [];
  const getTranferData = async (id) => {
    if (id) {      
      let data = await dispatch(singelTransferContract({ id: id }));
      if (data.payload.data.length > 0) {
        tsfAmount.push({
          totalAmount : data.payload.data[0].ttlAmount,
          adminAmount : data.payload.data[0].adminAmount,
          transerAmount : data.payload.data[0].totalPaidAmount
        })
        data.payload.data[0].owner?.forEach((item, index) => {
          splitUser.push( { 
            id   : item.id, 
            name : item.name,
            type : item.type,
            totalAmount : item.totalAmount,
            adminAmount : item.adminAmount,
            perentage   : item.perentage,
            recAmount   : item.recAmount
          })
        })
        setUser(splitUser);
        setTransferAmount(tsfAmount);
        setValue('transferId', data.payload.data[0]['id']);
        setValue('payment', data.payload.data[0]['payment']);
        setValue('admFee', data.payload.data[0]['adminAmount']);
        setValue('externalObservation', data.payload.data[0]['externalObservation']);
        setValue('id', data.payload.data[0]['id'])
        dispatch(setTransferOpen(true));
      }
      else {
        Swal.fire({
          title: t("Sweetalert.Error"),
          text: t("Sweetalert.somethingWent"),
          // icon: 'success',"error",
          confirmButtonText: "OK",
          timer: 2000
        })
      }
    }
  }
  const expenseOpen = (evt) => {
    dispatch(setExpenseTitle(evt))
    dispatch(setExpenseType(false));
    dispatch(setExpType(true));
    dispatch(setExpenseCheckbox(true));
    dispatch(newExpenseOpen(true));
  }

  const formValidation = Yup.object().shape({
    payment: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
    // admFee: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired'))
  });
  let frm = {
    resolver: yupResolver(formValidation)
  }
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<transferForm>(frm);
  const formSubmit = async (data: transferForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    let response = await dispatch(insertTransfer(data));
    if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:t("Sweetalert.successget"),
        confirmButtonText:"OK",
        timer:2000})
      dispatch(transferContracts(state))
      transferClosed();
    }
    else {
      setDouble(false)
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }
  const adminfeeCalculation = async(evt) => {
    let admFee = evt.target.value ? evt.target.value : 0;
    if(transferAmount.length>0 && admFee < parseFloat(transferAmount[0].totalAmount)){
      let transerAmount = parseFloat(transferAmount[0].totalAmount) - parseFloat(admFee);
      tsfAmount.push({
        totalAmount   : transferAmount[0].totalAmount,
        adminAmount   : admFee,
        transerAmount : transerAmount.toFixed(2)
      }) 
      setTransferAmount(tsfAmount);
      user?.forEach((item, index) => {
        let recAmount = parseFloat(item.totalAmount) - parseFloat(admFee);
        splitUser.push( { 
          id          : item.id, 
          name        : item.name,
          type        : item.type,
          totalAmount : item.totalAmount,
          adminAmount : item.adminAmount,
          perentage   : item.perentage,
          recAmount   : recAmount.toFixed(2)
        })
      })
      setUser(splitUser);
    }
    else {
      setValue("admFee",transferAmount ? transferAmount[0].adminAmount : 0)
    }
  }
  return (
    <>
      {/* Transfer edit modal start from here */}
      <Modal show={transferOpen} onHide={transferClosed}>
        <Form onSubmit={handleSubmit(formSubmit)}>
          <input type="hidden" {...register('id')} />
          <Modal.Header closeButton>
            <Modal.Title>{t("formLabel.transfer")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <!-- Table start from here -->*/}
            <div className="table-responsive">
              <div className="h4">{t("TransferPage.Billingdetails")} {singleTransferContractData.length != 0 ? singleTransferContractData.data[0].id : ''}</div>
              <table className="table table-custom table-custom-bordered mb-0">
                <thead className="p-0 m-0">
                  <tr>
                    <th className="p-0 m-0"></th>
                    <th className="p-0 m-0"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="d-block">{t("property.description")}</span>
                      <span className="d-block">{t("rent")} {singleTransferContractData.length != 0 ? singleTransferContractData.data[0].start + t("TransferPage.to") + singleTransferContractData.data[0].end : ''}
                        {/*<Link to={"#"}> <u  onClick={() => expenseOpen("Edit expense")}>Change</u></Link>*/}
                      </span>
                      {/*<span className="d-block">Real estate loan bank fee</span>*/}
                    </td>
                    <td>
                      <span className="d-block">{t("value")}</span>
                      <span className="d-block">{singleTransferContractData.length != 0 ? singleTransferContractData.data[0].totalAmount : ''}</span>
                      {/*<span className="d-block">0</span>*/}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="d-block">{t("comparetolastmonth.total")}</span>
                    </td>
                    <td>
                      <span className="d-block">{singleTransferContractData.length != 0 ? singleTransferContractData.data[0].totalAmount : ''}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <!-- Table end from here -->*/}
            {/* <!-- Table start from here -->*/}
            {/*<div className="table-responsive mt-4" style={{ display: singleTransferContractData.length!=0 ? singleTransferContractData.data[0].rStatus!=0 ? "block" : "none"  : "none" }} >*/}
            <div className="table-responsive mt-4" >
              <div className="h4 d-flex justify-content-between">
                <span>{t("TransferPage.Transferdetails")} ( {t("TransferPage.TXADM")} {singleTransferContractData.length != 0 ? singleTransferContractData.data[0].adminRatePercentage : ''}% )</span>
                {/*<img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" onClick={() => expenseOpen("New expense")} />*/}
              </div>
              <table className="table table-custom table-custom-bordered mb-0">
                <thead className="p-0 m-0">
                  <tr>
                    <th className="p-0 m-0"></th>
                    <th className="p-0 m-0"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="d-block">{t("property.description")}</span>
                      <span className="d-block">{t("rent")} {singleTransferContractData.length != 0 > 0 ? singleTransferContractData.data[0].start + ' to ' + singleTransferContractData.data[0].end : ''}</span>
                      <span className="d-block">{t("Dashboard.Administrationfee")}</span>
                    </td>
                    <td>
                      <span className="d-block">{t("value")}</span>
                      <span className="d-block">{transferAmount.length  > 0 ? transferAmount[0].totalAmount : ''}</span>
                      <span className="d-block text-primary">-{transferAmount.length > 0 ? transferAmount[0].adminAmount : ''}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="d-block">{t("comparetolastmonth.total")} ( {transferAmount.length != 0 ? transferAmount[0].totalAmount : ''} - <span className="fw-bold text-danger">{transferAmount.length != 0 ? transferAmount[0].adminAmount : ''} </span> )</span>
                      {
                        user.length != 0 && user.length > 0 ? user.map((item, index) => (
                          <span key={index} className="d-block">{item.name} {item.perentage}%</span>
                        )) : ''
                      }
                    </td>
                    <td>
                      <span className="d-block">{transferAmount.length != 0 ? transferAmount[0].transerAmount : ''}</span>
                      {
                        user.length != 0 && user.length > 0 ? user.map((item, index) => (
                          <span key={index} className="d-block">{item.recAmount}</span>
                        )) : ''
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <!-- Table end from here -->*/}
            <Accordion className="accordion-custom">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                <Accordion.Body>
                  <FloatingLabel controlId="maturity" className="mb-3 mb-lg-4" label={t('formLabel.payment')}>
                    <Form.Control type="date" className={`form-control ${errors.payment ? 'is-invalid' : ''}`} {...register('payment')} placeholder={t('formLabel.payment')} />
                    <Form.Control.Feedback type="invalid">{errors.payment?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel className="mb-3 mb-lg-4" label={t('TransferPage.Admfee') + "(R$)"}>
                    <Form.Control type="Text" className={`form-control ${errors.admFee ? 'is-invalid' : ''}`} {...register('admFee')} onKeyUp={(event)=>{ adminfeeCalculation(event);numericOnly(event);}}  aria-label="Adm fee (R$)" placeholder={t('TransferPage.Admfee') + "(R$)"} />
                    <Form.Control.Feedback type="invalid">{errors.admFee?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel className="mb-3 mb-lg-4" label={t("TransferPage.Externalobservation")}>
                    <Form.Control type="Text" {...register('externalObservation')} aria-label="External observation" placeholder={t("TransferPage.Externalobservation")} />
                  </FloatingLabel>
                  <Form.Check type="checkbox" {...register('printStatement')} id="print_statement" label={t("TransferPage.Printstatement")} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={transferClosed}>
              {t("button.close")}
            </Button>
            <Button disabled={double} variant="primary" type="submit" onClick={openErrorbox}>
              {t("TransferPage.Launchinfinancial")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* /Transfer edit modal end */}
      <ExpenseService />
    </>
  )
}
export default TransferService;