export function convertCurrencyToFloat(value: string) {
  const cleanedValue = value.replace(/[R$\s]/g, "");

  const thousands = cleanedValue.replace(/\./g, "");
  const decimals = thousands.replace(",", ".");

  const float = parseFloat(decimals);

  if (isNaN(float)) return 0;

  if (float < 1) return Math.round(float * 100);

  return float;
}
