import React, { useState } from 'react';
import { Modal, Form, Button, FloatingLabel, Row, Col, CloseButton } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useTranslation } from 'react-i18next';
const NotifyContract = (props) => {
  const [t] = useTranslation();
  //Notified contract modal close open function
  const { showNotifiedContract, notifiedContractClose, notifiedContractShow } = props;

  // Notified contract modal steps initialization
  const [hide, setHide] = useState(true);
  const [hide2, setHide2] = useState(false);
  const step1 = () => {
    setHide(true);
    setHide2(false);
  }
  const step2 = () => {
    setHide(false);
    setHide2(true);
  }
  return (
    <>
      {/* Notified modal start from here */}
      <Modal show={showNotifiedContract} onHide={notifiedContractClose}>
        <Form>
          <Modal.Body className="pt-4">
            <div style={{ display: hide ? "block" : "none" }}>
              <div className="d-flex align-items-cneter justify-content-between">
                <div className="me-2">
                  <h4 className="mb-0 me-3">{t("contractlistother.Whowillbenotified")}?</h4>
                  <span className="mb-3 mb-lg-4 mb-3 me-3 d-block">{t("contractlistother.sendemail")}</span>
                </div>
                <CloseButton onClick={notifiedContractClose} />
              </div>
              <h4 className="mb-3 mb-lg-4">{t("contractlistother.Partiestonotify")}</h4>
              <Row className="gy-lg-4 gy-3">
                <Col md={6}>
                  <FloatingLabel controlId="tenants" label={t("customerAndEmployees.tenants")}>
                    <Form.Select aria-label="Tenants">
                      <option value="">{t("formLabel.select")}</option>
                      <option value="1">Notify</option>
                      <option value="2">Do not notify</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="owners" label={t("customerAndEmployees.owners")}>
                    <Form.Select aria-label="Owners">
                      <option value="">{t("formLabel.select")}</option>
                      <option value="1">Notify</option>
                      <option value="2">Do not notify</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="guarantors" label={t("customerAndEmployees.guarantors") + " " + t("customerAndEmployees.and") + " " + t("customerAndEmployees.guarantors")}>
                    <Form.Select aria-label="Guarantors and guarantors" disabled>
                      <option value="">{t("formLabel.select")}</option>
                      <option value="1">Notify</option>
                      <option value="2">Do not notify</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="administrator" label={t("formLabel.administrator")}>
                    <Form.Select aria-label="Administrator">
                      <option value="">{t("formLabel.select")}</option>
                      <option value="1">Notify</option>
                      <option value="2">Do not notify</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="fire_insurer" label={t("contractlistother.Fireinsurer")}>
                    <Form.Select aria-label="Fire insurer">
                      <option value="">{t("formLabel.select")}</option>
                      <option value="1">Notify</option>
                      <option value="2">Do not notify</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="bail_insurer" label={t("contractlistother.Bailinsurer")}>
                    <Form.Select aria-label="Bail insurer" disabled>
                      <option value="">{t("formLabel.select")}</option>
                      <option value="1">Notify</option>
                      <option value="2">Do not notify</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div style={{ display: hide2 ? "block" : "none" }}>
              <div className="d-flex align-items-cneter justify-content-between">
                <div className="me-2">
                  <h4 className="mb-0 me-3">{t("formLabel.select")} {t("formLabel.model")}</h4>
                  <span className="mb-3 mb-lg-4 mb-3 me-3 d-block">{t("formLabel.sendEmail")}</span>
                </div>
                <CloseButton onClick={notifiedContractClose} />
              </div>
              <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                <Col md>
                  <FloatingLabel controlId="select_model" label={t("contractlistother.Selectamodel")}>
                    <Form.Select aria-label="Select a model">
                      <option value="">{t("formLabel.select")}..</option>
                      <option value="1">Property Vacancy Notification</option>
                      <option value="2">test</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md="auto">
                  <Button variant="link" className="bg-light border-light"><img src="../assets/images/icons/delete-light.svg" alt="Delete icon" className="h-16 cursor-pe" /></Button>
                </Col>
              </Row>
              <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                <Col md={6}>
                  <FloatingLabel controlId="title" label={t("formLabel.title")}>
                    <Form.Control type="text" value="Notificação de Desocupação do Imóvel" placeholder={t("formLabel.title")} />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="identifier" label={t("formLabel.identifier")}>
                    <Form.Control type="text" placeholder={t("formLabel.identifier")} />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="caption" label={t("formLabel.caption")}>
                    <Form.Control type="text" placeholder={t("formLabel.caption")} />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="variables" label={t("formLabel.variables")}>
                    <Form.Select aria-label="Variables">
                      <option value="">{t("formLabel.select")}..</option>
                      <option value="1">Today</option>
                      <option value="2">Current day</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <CKEditor editor={ClassicEditor} data="Testing" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={notifiedContractClose}>
              {t('button.close')}
            </Button>
            <Button variant="outline-primary" onClick={hide2 ? step1 : step1}>
            {t('button.previous')}
            </Button>
            <Button variant="primary" style={{ display: hide ? "block" : "none" }} onClick={hide ? step2 : step2} className="min-w-auto">
            {t('button.next')}
            </Button>
            <Button variant="primary" className="min-w-auto" style={{ display: hide2 ? "block" : "none" }} type="submit">
            {t('button.save')} {t('customerAndEmployees.and')} {t('contractlistother.send')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* /Notified modal end */}
    </>
  )
}

export default NotifyContract
