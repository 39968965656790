import React, { useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import { Search } from "./Filters/Search";
import { Configuration } from "./Informations/Configuration";
import { Filters } from "./Filters";

export function SubHeader() {
  const [openFilters, setOpenFilters] = useState(false)

  return (
    <div
      style={{ height: "144px" }}
      className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3"
    >
      <div className="w-100 h-100">
        <Row className="gx-xl-5 justify-content-between align-items-center">
          <Col xs={{ order: 12 }} xl={{ order: 12 }} className="mb-xl-0 mb-3">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center gap-2">
                <h1 className="mb-0 lh-base text-truncate me-xl-1 text-dark-90">
                  DIMOB
                </h1>
                <Search />
              </div>

              <div className="d-flex align-items-center ">
                <Configuration />

                <div className="d-xl-none">
                  <Button
                    variant="light"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setOpenFilters(!openFilters)}
                    aria-controls="collpase-filter-text"
                    aria-expanded={openFilters}
                  >
                    Filtros
                    <img
                      src="assets/images/icons/filter-icon.svg"
                      alt="Filter"
                      className="ms-1 h-12"
                    />
                  </Button>
                </div>
              </div>

            </div>
          </Col>
        </Row>
        <div className="mt-3">
          <Collapse className="d-xl-block collpase-filters" in={openFilters}>
            <div>
              <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                <h5 className="mb-0 fw-bold">Filters</h5>
                <span className="cursor-pe" onClick={() => setOpenFilters(!openFilters)}>
                  <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                </span>
              </div>
              <Filters />
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}
