import { Form, Accordion, Offcanvas, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { administratorClose, propertyOpen, insertAdministrator, condosOpen } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getAdministrators } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { administratorForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import customerService from '../../../../services/customerService';
import '../../../../App.css';
import { CNJP_CPFValidation } from '../../../constants/utils';
const AdministratorService = (props) => {
    let {title, setAdmType} = props
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { newAdministratorShow } = useSelector((state: RootState) => state.contractData)
    /*------------History modal hide function-----------*/
    const newAdministratorClose = () => {
        if(title == "condos"){
            dispatch(condosOpen(true))
         setAdmType('')
        }else{
            dispatch(propertyOpen(true));
        }
        dispatch(administratorClose(false));
        setDouble(false);
        reset();
    }
    /*------------Form validation-----------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const administratorValidation = Yup.object().shape({
        name: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        //telephone: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(administratorValidation)
    }
    /*------------Submit history form data-----------*/

    const { register, reset, handleSubmit, setValue, setError, clearErrors, formState: { errors } } = useForm<administratorForm>(frm);
    const onSubmit = async (data: administratorForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        let response = await dispatch(insertAdministrator(data));
        if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: response.payload.message,
                confirmButtonText: "OK"
                // timer : 2000
            })
            dispatch(getAdministrators())
            newAdministratorClose();
        }
        else {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: response.payload.message,
                // icon: 'success',"error",
                confirmButtonText: "OK"
                //timer : 2000
            })
        }
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const [btn2, setBtn2] = useState(false);
    const getLocation = async (e, inputType) => {
        let str = e.target.value
    let newStr = str.replace('-', ''); // Removes the hyphen
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
    let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
    // Error validation
    // Retrieve address based on zipcode using customerService
    let resp = await customerService.getAddressBasedOnZipcode(
      `${inputType}/${newStr}`
    );
        let querySel: any = document.querySelector('.validZipcode [name="zipcode"]');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                setBtn2(false);
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                setBtn2(true);
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
            }
        } else {
            setBtn2(false);
            querySel.style.border = "0px solid red";
        }
    }
    const [allStates, setAllStates] = useState([]);
    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, []);

    const handleCPF_CNJPVaue = (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
        } else {
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Offcanvas className="border-sm-none" show={newAdministratorShow} onHide={newAdministratorClose} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <Offcanvas.Header className="border-bottom" closeButton>
                        <Offcanvas.Title className="fw-semibold">
                            <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />{t('property.newAdministrator')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p className="mb-lg-4 mb-3">{t('newContractDev.insertNewPropertyAdministrator')}</p>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3 mb-lg-4" >
                            <Form.Control type="text" placeholder={t('formLabel.name')} className={`${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <Row className="g-lg-4 g-3">
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cnpj')}>
                                    <Form.Control type="text" placeholder={t('formLabel.cnpj')} className={`${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")} />
                                    <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.responsible')}>
                                    <Form.Control type="text" placeholder={t('formLabel.responsible')} className={`${errors.responsible ? 'is-invalid' : ''}`} {...register('responsible')} />
                                    <Form.Control.Feedback type="invalid">{errors.responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`${errors.telephone ? 'is-invalid' : ''}`} {...register('telephone')} onChange={HandleInput} />
                                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="email" placeholder={t('formLabel.email')} className={`${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.address')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={4}>
                                <InputGroup>
                                    <FloatingLabel className="validZipcode" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} onChange={(e) => getLocation(e, "zipcode")} maxLength={9} minLength={9}  />
                                        <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text>
                                        <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.address')}>
                                    <Form.Control type="text" placeholder={t('formLabel.address')} className={`${errors.address ? 'is-invalid' : ''}`} {...register('address')} />
                                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.number')}>
                                    <Form.Control type="text" placeholder={t('formLabel.number')} className={`${errors.number ? 'is-invalid' : ''}`} {...register('number')} onChange={numericOnly} />
                                    <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                    <Form.Control type="text" placeholder={t('formLabel.complement')} className={`${errors.complement ? 'is-invalid' : ''}`} {...register('complement')} />
                                    <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.state')}>
                                    <Form.Select className={`${errors.state ? 'is-invalid' : ''}`}  {...register('state')} aria-label={t('formLabel.state')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {allStates.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.city')}>
                                    <Form.Control type="text" placeholder={t('formLabel.city')} className={`${errors.city ? 'is-invalid' : ''}`} {...register('city')} />
                                    <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`${errors.neighborhood ? 'is-invalid' : ''}`} {...register('neighborhood')} />
                                    <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('others')}</h6>
                        <Form.Group controlId="domiciled" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-lg-4 mb-3">
                            <Form.Check type="checkbox" label={t('newContractDev.propertyAdministratorSupplier')} />
                        </Form.Group>
                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')} >
                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                        </FloatingLabel>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {/* <Form.Group className="mb-3">
                            <Form.Check type="checkbox" {...register('isAfter')} label="This admin is also a vendor." />
                        </Form.Group> */}
                        <Button variant="outline-primary" onClick={newAdministratorClose}>
                            {t('button.close')}
                        </Button>
                        <Button disabled={btn2 || double ? true : false} className="min-w-auto" type="submit" variant="primary" onClick={openErrorbox} >
                            {t('button.save')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default AdministratorService;