import { z } from "zod";
import { BancoDoBrasilSchema } from "./components/BanksFields/BancoDoBrasilFields";
import { BradescoSchema } from "./components/BanksFields/BradescoFields";
import { C6Schema } from "./components/BanksFields/C6Fields";
import { CaixaEconomicaSchema } from "./components/BanksFields/CaixaEconomicaFields";
import { InterSchema } from "./components/BanksFields/InterFields";
import { ItauSchema } from "./components/BanksFields/ItauFields";
import { SafraSchema } from "./components/BanksFields/SafraFields";
import { SantanderSchema } from "./components/BanksFields/SantanderFields";
import { SicoobSchema } from "./components/BanksFields/SicoobFields";
import { SicrediSchema } from "./components/BanksFields/SicrediFields";
import { AccountType, EnabledBanksToHomologate } from "./types";

const BankAccountSchema = z.object({
  nameReference: z.string().min(1, "Campo obrigatório"),
  branchtype: z.string().min(1, "Campo obrigatório"),
  accountVariation: z.string().regex(/^[12]$/, "Campo obrigatório"),
  bankname: z.string().min(1, "Campo obrigatório"),
  accountType: z.string().regex(/^[13]$/, "Campo obrigatório"),
  agency: z.string().min(1, "Campo obrigatório"),
  account: z.string().min(1, "Campo obrigatório"),
  moneyaccount: z.string().min(1, "Campo obrigatório"),
  date: z.string().min(1, "Campo obrigatório"),
  shouldHomologate: z.string().optional(),
});

const ManualAccountSchema = z.object({
  nameReference: z.string().min(1, "Campo obrigatório"),
  branchtype: z.string().min(1, "Campo obrigatório"),
  moneyaccount: z.string().min(1, "Campo obrigatório"),
  date: z.string().min(1, "Campo obrigatório"),
});

export function getBankAccountFormSchema({
  accountVariation,
  selectedBank,
  shouldHomologate,
}: {
  accountVariation: string;
  selectedBank: string;
  shouldHomologate: boolean;
}) {
  if (accountVariation === AccountType.MANUAL) return ManualAccountSchema;

  if (!shouldHomologate) return BankAccountSchema;

  const bankToHomologateSchema = {
    [EnabledBanksToHomologate.BancoDoBrasil]: BancoDoBrasilSchema,
    [EnabledBanksToHomologate.Santander]: SantanderSchema,
    [EnabledBanksToHomologate.Inter]: InterSchema,
    [EnabledBanksToHomologate.CaixaEconomica]: CaixaEconomicaSchema,
    [EnabledBanksToHomologate.Bradesco]: BradescoSchema,
    [EnabledBanksToHomologate.C6]: C6Schema,
    [EnabledBanksToHomologate.Itau]: ItauSchema,
    [EnabledBanksToHomologate.Safra]: SafraSchema,
    [EnabledBanksToHomologate.Sicredi]: SicrediSchema,
    [EnabledBanksToHomologate.Sicoob]: SicoobSchema,
  };

  return BankAccountSchema.merge(bankToHomologateSchema[selectedBank]);
}

export type BankAccountFormType = z.infer<typeof BankAccountSchema>;
