import { config } from "../../../../services/apiConfig";

export async function getBranchesList(): Promise<
  Array<{
    id: string;
    name: string;
  }>
> {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(`${config.companyV2_URL}/branch/branchList`, {
    method: "POST",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      "connection-name": connectionName,
    },
  });

  const data = await response.json();

  return data.data.result;
}
