import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { setWitnessType, getAllWitness, newElectronicEnvelopeClose, insertElectronicEnvelope } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { witnessOpen } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { newElectronicEnvelopeForm } from '../../../Interface';
import Swal from 'sweetalert2';
import WitnessService from '../Common/WitnessService';
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';
import customerService from '../../../../services/customerService';
import { gettoken, config } from "../../../../services/apiConfig";
import Loader from '../../../Profile/Company/Loader';
import { emailPostSave } from '../../../../redux/slice/SendEmailSlice';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Token = gettoken.Token;
const storedData = localStorage.getItem('userData');
const CustomerApiBaseUrl = config.TRANSFER___API;

const ElectronicEnvelopeService = (newElectronicEnvelopeForm) => {
    const location = useLocation();
    const { currentContract, electronicEnvelopeListingCall } = newElectronicEnvelopeForm;
    const { newElectronicEnvelopeShow, witnessStatus, witnessData, status } = useSelector((state: RootState) => state.contractData)
    const [double, setDouble] = useState(false);
    let { id } = useParams();
    const [nonp, setnonp] = useState("");

    useEffect(() => {
        if (newElectronicEnvelopeShow) {
            if (location.state && location.state.contract_id !== "" && location.state.contract_id !== null && typeof location.state.contract_id !== "undefined") {
                id = location.state.contract_id;
            }

            if (currentContract && currentContract?.ID_CONTRATO_CON && currentContract.ID_CONTRATO_CON !== "") {
                setnonp(currentContract.ID_CONTRATO_CON);
            }
        }
    }, [currentContract, location.state, newElectronicEnvelopeShow])


    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [subscriberData, setSubscriberData] = useState<any>([]);
    const [witnessData1, setWitnessData1] = useState<any>([]);
    const [electronicTemplateData, setElectronicTemplateData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [loader_on, setLoaderOn] = useState(false);
    const [firstSelection, setFirstSelection] = useState('');
    const [secondSelection, setSecondSelection] = useState('');
    const [thirdSelection, setThirdSelection] = useState('');
    const [selectionData, setSelectionData] = useState<any>([]);
    let [desc, setDesc] = useState('');
    const [templateName, setTemplateName] = useState<any>("");
    const [identifier, setIdentifier] = useState<any>("");
    const [contractVariableData, setContractVariableData] = useState<any>({})
    const [propertyVariableData, setPropertyVariableData] = useState<any>({});
    useEffect(() => {
        if (newElectronicEnvelopeShow) {
            dispatch(getAllWitness());
        }
    }, [dispatch])
    const electronicEnvelopeClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        dispatch(newElectronicEnvelopeClose(false));
        reset();
    }
    const newWitnessShow = () => {
        dispatch(setWitnessType("contractEvelope"))
        dispatch(witnessOpen(true));
        dispatch(newElectronicEnvelopeClose(false));
    }
    const checklistValidation = Yup.object().shape({
        person: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        email: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .email(t('requiredMmessage.fieldNotValid')),
        type: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        witnessPerson: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        witnessEmail: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .email(t('requiredMmessage.fieldNotValid')),
        cpf: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        rg: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // templateSelection: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        firstSelectionOption: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        secondSelectionOption: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(checklistValidation)
    }
    const { register, reset, handleSubmit, setValue, getValues, clearErrors, setError, formState: { errors } } = useForm<newElectronicEnvelopeForm>(frm);
    const onSubmit = async (data: any, e: any) => {
        setLoading(true);
        setLoaderOn(true);
        const contract_id = id ?? nonp;
        const templateSelection = `${data.secondSelectionOption}`;
        if (desc && desc !== "") {
            const rendered_template_response = await customerService.renderElectronicEnvelope(contract_id, templateSelection, desc);
            let email_template: any = rendered_template_response?.data?.data[0]?.ST_TEXTO_MOH; // base64
            let base64Pdf:any = rendered_template_response?.data?.data[0]?.base64Pdf

            let template_name = templateName;
            let emaildata: any = {
                status: "new",
                subject: "Email vindo do POSTMAN",
                content: email_template,
                layout: "default",
                sender: "noreply@dev.kenlo.io",
                to: [data.email],
                "cc": [
                    "vikash@wegile.com"
                ],
                "bcc": [
                    "karan@wegile.com"
                ],
                identifier: "testing",
                model: 1,
                caption: "test",
                title: template_name,
            }
            dispatch(emailPostSave(emaildata))
            let subscriber_details: any = {
                statusdocumento: "0",
                assinantes: [
                    {
                        id: `${data.subscriber_id}`,
                        tipo: `${data.type}`,
                        nome: `${data.person}`,
                        cpf: `${data.subcpf}`,
                        email: `${data.email}`,
                        identificador: null,
                        status: "Pendente"
                    }
                ]
            }
            subscriber_details = JSON.stringify(subscriber_details);
            const email_data: any = {
                "person": `${data.person}`, //name
                "email": `${data.email}`, //email
                "type": `${data.type}`,
                "witnessPerson": `${data.witnessPerson}`,
                "witnessEmail": `${data.witnessEmail}`,
                "cpf": `${data.cpf}`,
                "rg": `${data.rg}`,
                "isEstateSignature": `${data.isEstateSignature}`,
                "moreEmail": `${data.moreEmail}`,
                "isElectronicSignatures": `${data.isElectronicSignatures}`,
                "firstSignature": `${data.firstSignature}`,
                "secondSignature": `${data.secondSignature}`,
                "thirdSignature": `${data.thirdSignature}`,
                "fourthSignature": `${data.fourthSignature}`,
                "templateSelection": `${data.templateSelection}`,
                "contract_id": `${id}`,
                "Token": Token,
                "Connection": storedData,
                "CustomerApiBaseUrl": CustomerApiBaseUrl,
                "email_template": email_template,
                "ST_DADOSASSINATURA_ENV": subscriber_details,
                "template_name": template_name
            }
      
            let clickSignData = {
                content: `data:application/pdf;base64,${base64Pdf}`,
                userData: [{
                    name: data.name,
                    email: data.email
                }],
                contract_id: id,
                template_name:template_name
            }
            const response = await customerService.postEmailDocusign(clickSignData);
            if (response.data.resCode === 202 || response.data.resCode === 200) {
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setLoading(false);
                setLoaderOn(false);
                electronicEnvelopeClose();
                if (currentContract && currentContract.ID_CONTRATO_CON && currentContract.ID_CONTRATO_CON !== "") {

                } else {
                    electronicEnvelopeListingCall();
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000);
                }
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setLoading(false);
                setLoaderOn(false);
            }

        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Empty Template",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }
    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    useEffect(() => {
        if (newElectronicEnvelopeShow) {
            const data = async () => {
                const response = await customerService.getSubscribers(id ?? nonp);
                if (response.data.resCode == 201) {
                    setSubscriberData(response.data.data);
                }
            }
            const witnessData = async () => {
                const response = await customerService.getAllWitness({ start: 0, end: 100 });
                if (response.data.resCode == 201) {
                    setWitnessData1(response.data.data);
                }
            }
            const electronicTenplates = async () => {
                const response = await customerService.selectElectronicEnvelopeTemplate();
                if (response.data.resCode == 201) {
                    setElectronicTemplateData(response.data.data);
                }
            }
            const getAllElectronicEnvelopeTemplateWithIdentifierAndFolder = async () => {
                const response = await customerService.getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
                setSelectionData(response.data.data)
            }
            const fetchData = async () => {
                try {
                    const response = await customerService.getTemplates();
                    const variableResult = response.data.data[1];
                    const propertyVariableResult = response.data.data[2];
                    // setVariableData(variableResult);
                    setContractVariableData(variableResult);
                    setPropertyVariableData(propertyVariableResult);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
            data();
            witnessData();
            electronicTenplates();
            getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
        }
    }, [nonp, newElectronicEnvelopeShow])


    const handlePersonChange = async (selectedPersonId) => {
        selectedPersonId -= 1;
        setValue('email', subscriberData[selectedPersonId].email);
        setValue('type', subscriberData[selectedPersonId].type);
        setValue('subcpf', subscriberData[selectedPersonId].CPF);
        setValue('subscriber_id', subscriberData[selectedPersonId].subscriber_id);
    };

    const handleWitnessChange = async (selectedPersonId) => {
        selectedPersonId -= 1;
        setValue('witnessEmail', witnessData1[selectedPersonId].email);
        setValue('cpf', witnessData1[selectedPersonId].CPF);
        setValue('rg', witnessData1[selectedPersonId].RG);
        setValue('witness_id', witnessData1[selectedPersonId].subscriber_id)
    }


    const jsonData = selectionData;
    // Extracting unique values for first, second, and third dropdowns
    const firstOptions: any = Array.from(new Set(jsonData.map(item => item.ST_NOME_TEH)));
    const secondOptionsData: any = {};
    const thirdOptionsData: any = {};

    jsonData.forEach(item => {
        secondOptionsData[item.ST_NOME_TEH] = Array.from(new Set(jsonData.filter(data => data.ST_NOME_TEH === item.ST_NOME_TEH).map(data => ({
            value: data.ID_MODELO_MOH,
            label: data.ST_NOME_MOH,
            templateData: data.ST_TEXTO_MOH,
            templateIdentifier: data.ST_IDENTIFICADOR_MOH
        }))));
        thirdOptionsData[item.ID_MODELO_MOH] = Array.from(new Set(jsonData.filter(data => data.ID_MODELO_MOH === item.ID_MODELO_MOH).map(data => ({
            value: data.ST_IDENTIFICADOR_MOH,
            label: data.ST_NOME_MOH
        }))));
    });
    // Function to handle change in the first dropdown
    const handleFirstChange = (event) => {
        const selectedValue = event.target.value;
        setFirstSelection(selectedValue);
        // Clear selections in second and third dropdowns
        setSecondSelection('');
        setThirdSelection('');
    };

    // Function to handle change in the second dropdown
    const handleSecondChange = (event, optionsData) => {
        const selectedValue = event.target.value;
        if (optionsData && optionsData.length) {
            let templateData = optionsData;
            let obj = templateData.find(obj => obj.value == selectedValue);
            setIdentifier(obj.templateIdentifier);
            setTemplateName(obj.label)
            setDesc(obj.templateData);
            setSecondSelection(selectedValue);
            setThirdSelection('');
        }
    };
    // Function to handle change in the third dropdown
    const handleThirdChange = (event) => {
        const selectedValue = event.target.value;
        setThirdSelection(selectedValue);
    };
    return (
        <>
            {/* <!-- New electronic envelope modal start from here --> */}
            <Modal show={newElectronicEnvelopeShow} onHide={electronicEnvelopeClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Newenvelope")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loader_on ? (<Loader />) : (<>
                            <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("envelop.Person's") + " *"}>
                                        <Form.Select
                                            className={`form-select ${errors.person ? 'is-invalid' : ''}`}
                                            {...register('person')}
                                            onChange={(e) => handlePersonChange(e.target.selectedIndex)}
                                            aria-label="Select Person"
                                        >
                                            <option value="" >{t('dropdownItem.select')}..</option>
                                            {subscriberData && subscriberData.length > 0 ? subscriberData.map((item, index) => (
                                                <option key={index} value={item.name}>{item.name}</option>
                                            )) : <option value="" disabled>No Data</option>}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.person?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label="E-mail *">
                                        <Form.Control type="text" placeholder="E-mail" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                                        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Form.Control type="text" placeholder="witness_id" {...register('witness_id')} disabled hidden />
                            <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput" label={t("envelop.Type") + " *"}>
                                <Form.Control type="text" placeholder={t("envelop.Type")} className={`form-control ${errors.type ? 'is-invalid' : ''}`} {...register('type')} />
                                <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput" label={t("envelop.subcpf") + " *"}>
                                <Form.Control type="text" placeholder={t("envelop.subcpf")} className={`form-control ${errors.type ? 'is-invalid' : ''}`} {...register('subcpf')} disabled />
                                <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <Form.Control type="text" placeholder={t("envelop.subscriber_id")}  {...register('subscriber_id')} disabled hidden />

                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>{t("envelop.Witnesses")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="gy-3 gy-lg-4">
                                            <Col md={6}>
                                                <InputGroup>
                                                    <FloatingLabel controlId="floatingInput" label={t("envelop.Person's") + " *"}>
                                                        <Form.Select className={`form-control ${errors.witnessPerson ? 'is-invalid' : ''}`} {...register('witnessPerson')} onChange={(e) => handleWitnessChange(e.target.selectedIndex)} >
                                                            <option value="">{t('dropdownItem.select')}..</option>
                                                            {witnessData1 && witnessData1.length > 0 ? witnessData1.map((item, index) => (
                                                                <option key={index} value={item.name}>{item.name}</option>
                                                            )) : <option value="" disabled>No Data</option>}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{errors.witnessPerson?.message}</Form.Control.Feedback >
                                                    </FloatingLabel>
                                                    <InputGroup.Text onClick={newWitnessShow} >
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20" />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label="E-mail *">
                                                    <Form.Control type="text" placeholder="E-mail" className={`form-control ${errors.witnessEmail ? 'is-invalid' : ''}`} {...register('witnessEmail')} />
                                                    <Form.Control.Feedback type="invalid">{errors.witnessEmail?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label="CNPJ/CPF *">
                                                    <Form.Control type="text" placeholder="CNPJ/CPF" className={`form-control ${errors.cpf ? 'is-invalid' : ''}`} {...register('cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                                    <Form.Control.Feedback type="invalid">{errors.cpf?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label="RG *">
                                                    <Form.Control type="text" placeholder="RG" className={`form-control ${errors.rg ? 'is-invalid' : ''}`} {...register('rg')} />
                                                    <Form.Control.Feedback type="invalid">{errors.rg?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Check className="mb-lg-4 mb-3" type="checkbox" {...register('isEstateSignature')} label={t("envelop.Insertrealestatesignature")} />
                                        <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput" label="E-mail">
                                            <Form.Control type="text" placeholder="E-mail" {...register('moreEmail')} />
                                        </FloatingLabel>
                                        <Form.Group>
                                            <Form.Check className="mb-lg-4 mb-3" type="checkbox" {...register('isElectronicSignatures')} label={t("envelop.Setorderforsendinge-mailforelectronicsignatures")} />
                                        </Form.Group>
                                        <Row className="gy-3 gy-lg-4">
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("envelop.1st") + " " + t("envelop.signature")}>
                                                    <Form.Select {...register('firstSignature')} aria-label="1st signature">
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">{t("envelop.ownersRealEstate")}</option>
                                                        <option value="2">{t("envelop.tenants")}</option>
                                                        <option value="3">{t("customerAndEmployees.guarantors")}</option>
                                                        <option value="4">{t("envelop.witnesses")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("envelop.2nd") + " " + t("envelop.signature")}>
                                                    <Form.Select {...register('secondSignature')} aria-label="2nd signature">
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">{t("envelop.ownersRealEstate")}</option>
                                                        <option value="2">{t("envelop.tenants")}</option>
                                                        <option value="3">{t("customerAndEmployees.guarantors")}</option>
                                                        <option value="4">{t("envelop.witnesses")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("envelop.3rd") + " " + t("envelop.signature")}>
                                                    <Form.Select {...register('thirdSignature')} aria-label="3rd signature">
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">{t("envelop.ownersRealEstate")}</option>
                                                        <option value="2">{t("envelop.tenants")}</option>
                                                        <option value="3">{t("customerAndEmployees.guarantors")}</option>
                                                        <option value="4">{t("envelop.witnesses")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("envelop.4th") + " " + t("envelop.signature")}>
                                                    <Form.Select {...register('fourthSignature')} aria-label="4th signature">
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">{t("envelop.ownersRealEstate")}</option>
                                                        <option value="2">{t("envelop.tenants")}</option>
                                                        <option value="3">{t("customerAndEmployees.guarantors")}</option>
                                                        <option value="4">{t("envelop.witnesses")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>{t("banks.templates")}</Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            jsonData && jsonData.length > 0 ?

                                                <>
                                                    <Row className="gy-3 gy-lg-4">

                                                        <Col md={6}>
                                                            {/* First dropdown */}
                                                            <FloatingLabel controlId="firstSelect" label={t('selectFolder') + " *"}>
                                                                <Form.Select className={`form-control ${errors.firstSelectionOption ? 'is-invalid' : ''}`} {...register('firstSelectionOption')} aria-label="firstSelectionOption" value={firstSelection} onChange={handleFirstChange} >
                                                                    <option value="">{t('dropdownItem.select')}</option>
                                                                    {firstOptions.map((option, index) => (
                                                                        <option key={index} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">{(errors.firstSelectionOption?.message as string)}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>

                                                        <Col md={6}>
                                                            {/* Second dropdown */}
                                                            {firstSelection && (
                                                                <FloatingLabel controlId="secondSelect" label={t('selectTemplate') + " *"}>
                                                                    <Form.Select className={`form-control ${errors.secondSelectionOption ? 'is-invalid' : ''}`} {...register('secondSelectionOption')} aria-label="secondSelectionOption" value={secondSelection} onChange={(e) => { handleSecondChange(e, secondOptionsData[firstSelection]) }}>
                                                                        <option value="">Select</option>
                                                                        {secondOptionsData[firstSelection].map((option, index) => (
                                                                            <option key={index} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">{(errors.secondSelectionOption?.message as string)}</Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            )}
                                                        </Col>
                                                        <Col md={6}>
                                                            {/* Third dropdown */}
                                                            {identifier && (identifier == "Contract") ?
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                        onChange={(e) => {
                                                                            const selectedVariable = e.target.value;
                                                                            const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                                        }}>
                                                                        <option value="">{t('dropdownItem.select')}</option>
                                                                        {contractVariableData && Object.keys(contractVariableData).length > 0
                                                                            ? Object.keys(contractVariableData).map(key => (
                                                                                <option key={key} value={contractVariableData[key]}>
                                                                                    {key}
                                                                                </option>
                                                                            ))
                                                                            : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                        }
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                                :
                                                                ""}

                                                            {identifier && (identifier == "Property") ?
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                        onChange={(e) => {
                                                                            const selectedVariable = e.target.value;
                                                                            const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                                        }}>
                                                                        <option value="">{t('dropdownItem.select')}</option>
                                                                        {propertyVariableData && Object.keys(propertyVariableData).length > 0
                                                                            ? Object.keys(propertyVariableData).map(key => (
                                                                                <option key={key} value={propertyVariableData[key]}>
                                                                                    {key}
                                                                                </option>
                                                                            ))
                                                                            : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                        }
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                                :
                                                                ""}

                                                        </Col>

                                                        <Col md={12}>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={desc}
                                                                onReady={editor => {
                                                                    const data = editor.getData();
                                                                    setDesc(data)
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setDesc(data)
                                                                }}
                                                                onBlur={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setDesc(data)

                                                                }}
                                                                onFocus={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setDesc(data)
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                ""
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>)}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={electronicEnvelopeClose}>
                            {t("button.close")}
                        </Button>
                        {loading ? (
                            <Button disabled={true} className='zipCodeCheck'>
                                {t("button.submitting")}
                            </Button>

                        ) : (
                            // Your existing Modal content
                            <Button disabled={double} type="submit" variant="primary" onClick={openErrorbox} className='zipCodeCheck'>
                                {t("button.toSave")}
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal >
            <WitnessService />
            {/* <!-- /New electronic envelope modal end --> */}
        </>
    )
}
export default ElectronicEnvelopeService;