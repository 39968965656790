import React from 'react';
import { Modal, Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const DeleteChargeItem = (props) => { 
    const { showDeleteItem, deleteItemClose } = props;
    const [t] = useTranslation();
    return ( 
        <>
            <Modal show={showDeleteItem} onHide={deleteItemClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("newTransfer.deleteItem")}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    By permanently deleting this item from the charge, it will no longer be counted or included.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={deleteItemClose}>
                        {t("button.cancel")}
                        </Button>
                        <Button variant="primary min-w-auto" onClick={deleteItemClose}>
                        {t("button.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default DeleteChargeItem
