import { config } from "../../../../services/apiConfig";

type UpdateHomologateBankPayload = {
  ContaCodigoBanco: string;
  ContaAgencia: string;
  ContaAgenciaDV: string;
  ContaNumero: string;
  ContaNumeroDV: string;
  ContaTipo: string;
  ContaCodigoBeneficiario: string;
  ConvenioNumero: string;
  ConvenioDescricao: string;
  ConvenioCarteira: string;
  ConvenioEspecie: string;
  ConvenioPadraoCNAB: string;
  id: number;
};

export async function updateHomologateBankAccount(
  updateHomologateBankPayload: UpdateHomologateBankPayload
) {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(
    `${config.TRANSFER_API}/homologate/bankslip/bank/${updateHomologateBankPayload.id}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        "connection-name": connectionName,
      },
      body: JSON.stringify(updateHomologateBankPayload),
    }
  );

  const data = await response.json();

  if (data.resCode === 201 || data.resCode === 200) return data.data;

  const invalidPayloadError = Object.values(data.errors?.at(0) ?? {})?.at(0);
  const homologateError = data.data?.message;
  const genericError = data.data;

  throw new Error(invalidPayloadError || homologateError || genericError);
}
