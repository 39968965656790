import { Form, Modal, Accordion, Offcanvas } from 'react-bootstrap';
import { partnershipLargeOpen,partnershipSmallOpen } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import '../../../../App.css';
const InsurancePopups = (insuranceForm) => {
	const { kenloPartnershipLarge ,  kenloPartnershipSmall } = useSelector((state: RootState) => state.contractData)
	const dispatch = useDispatch<AppDispatch>();
	const [double, setDouble] = useState(false);
	const [insuranceComp, setInsuranceCompleted] = useState(false);
	const [modal1, setModal1] = useState(false);
	const [modal2, setModal2] = useState(false);
	const [modal3, setModal3] = useState(false);
	const [modal4, setModal4] = useState(false);
	const [t] = useTranslation();
	const newInsuranceClose = () => { 
		dispatch(partnershipLargeOpen(false)) 
		modal1Close();
	}
	const modal1Close = () => { 
		dispatch(partnershipSmallOpen('0')) 
		setModal1(false);
	} 
	const modal2Close = () => { 
		dispatch(partnershipSmallOpen('0')) 
		setModal2(false);
	} 
	const modal3Close = () => { 
		dispatch(partnershipSmallOpen('0')) 
		setModal3(false);
	}
	const modal4Close = () => { 
		dispatch(partnershipSmallOpen('0')) 
		setModal4(false);
	}
	const modal5Close = () => { 
		dispatch(partnershipSmallOpen('0')) 
		setInsuranceCompleted(false);
	} 
	useEffect(() => {
		if( kenloPartnershipSmall == "1" ){
			setModal1(true);
		}
		if( kenloPartnershipSmall == "2" ){
			setModal2(true);
		}
		if( kenloPartnershipSmall == "3" ){
			setModal3(true);
		}
		if( kenloPartnershipSmall == "4" ){
			setModal4(true);
		}
		if( kenloPartnershipSmall == "5" ){
			setInsuranceCompleted(true);
		}
	}, [kenloPartnershipSmall])

	
	return ( 
		<>
			<Offcanvas show={kenloPartnershipLarge || modal1} onHide={newInsuranceClose} placement="end">
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("Dashboard.KenloInsurancePartnership")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p className="mb-lg-4 mb-3">{t("Dashboard.KenloInsurancePartnershipContent")}</p>
                    <Accordion defaultActiveKey="0">
                        <div className="border borrder-secondary rounded-3 opacity-10 mt-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header >
                                    <span className='fs-6 fw-bold'>{t("Dashboard.HowDoesCommissionRealEstateAgencyWork")}</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>{t("Dashboard.HowDoesCommissionRealEstateAgencyWorkContent")}</p>
                                    <p className='mb-0'>{t("Dashboard.HowDoesCommissionRealEstateAgencyWorkContent2")}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="border borrder-secondary rounded-3 opacity-10 mt-3">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header >
                                    <span className='fs-6 fw-bold'>{t("Dashboard.HowDoesPaymentInsurancePremiums")}</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className='mb-0'>{t("Dashboard.HowDoesPaymentInsurancePremiumsContent")}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="border borrder-secondary rounded-3 opacity-10 mt-3">
                            <Accordion.Item eventKey="2">
                                <Accordion.Header >
                                    <span className='fs-6 fw-bold'>{t("Dashboard.HowDoReceiveInsurancePolicy")}</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className='mb-0'>{t("Dashboard.HowDoReceiveInsurancePolicyContent")}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="border borrder-secondary rounded-3 opacity-10 mt-3">
                            <Accordion.Item eventKey="3">
                                <Accordion.Header >
                                    <span className='fs-6 fw-bold'>{t("Dashboard.WhatCoveragePackagesKenloOffer")}</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>{t("Dashboard.WhatCoveragePackagesKenloOfferContent")}</p>
                                    <div className=''>
                                        <ul className='!ps-1'>
                                            <p className='mb-1 ms-n3'><span className='fw-bold'>{t("Dashboard.BasicPackage")}</span> ({t("Dashboard.HomeWithoutAssistance")}):</p>
                                            <li>
											{t("Dashboard.BasicPackageContent1")}
                                            </li>
                                            <li>
											{t("Dashboard.BasicPackageContent2")}
                                            </li>
                                        </ul>
                                        <ul className='list-group ms-3 mt-3'>
                                            <p className='mb-1 ms-n3'><span className='fw-bold'>{t("Dashboard.PackageWithAssistance")}</span> ({t("Dashboard.HomePlus")}):</p>
                                            <li>
											{t("Dashboard.PackageWithAssistanceContent1")}
                                            </li>
                                            <li>
											{t("Dashboard.PackageWithAssistanceContent2")}
                                            </li>
                                            <li>
											{t("Dashboard.PackageWithAssistanceContent3")}
                                            </li>
                                            <li>
											{t("Dashboard.PackageWithAssistanceContent4")}
                                            </li>
                                            <li>
											{t("Dashboard.PackageWithAssistanceContent5")}
                                            </li>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    </Accordion>
                </Offcanvas.Body>
            </Offcanvas> 

			{ /*-- modal 0 --*/}
			<Modal show={modal2} onHide={modal2Close}>
				<Form>
					<Modal.Header closeButton>
						<Modal.Title>Kenlo Insurance partnership</Modal.Title>
					</Modal.Header>
					<Modal.Body className="pb-2 pt-0">
						<p>The analysis period for your registration will take longer than expected. We will provide an update within 3 business days.</p>
						<div className='border borrder-secondary rounded-3 opacity-10 p-3 rounded'>
							<p className='fw-bold'>
								Why is my registration in migration?
							</p>
							<p className='mb-3 mt-3'>According to the policies of Tokio Marine Insurer, with whom we are partners, it is not allowed for a client to be associated with two brokerages simultaneously. Therefore, we are currently working on migrating your registration to Kenlo Insurance.</p>
							<p className='mb-3 mt-3'>This process may take up to 3 business days to complete, and we will contact you if necessary.</p>
						</div>
					</Modal.Body>
				</Form>
			</Modal>

			{ /*-- modal 3 --*/}
			<Modal show={modal3} onHide={modal3Close}>
				<Form>
					<Modal.Header closeButton>
						<Modal.Title>Kenlo Insurance partnership</Modal.Title>
					</Modal.Header>
					<Modal.Body className="pb-2 pt-0">
						<p>The analysis period for your registration will take longer than expected because we are addressing an impediment with Tokio Marine Insurer.</p>
						<div className='border borrder-secondary rounded-3 opacity-10 p-3 rounded'>
							<p className='fw-bold'>
								Why is my real estate agency blocked by the insurance company?
							</p>
							<p className='mb-3 mt-3'>Your real estate agency is blocked by the insurance company for violating the company's policies. This is a protective measure aimed at preventing conflicts.</p>
							<p className='mb-3 mt-3'>We are actively working to resolve this blockage during the process of migrating your registration to our brokerage. We appreciate your patience and will provide an update soon.</p>
						</div>
					</Modal.Body>
				</Form>
			</Modal>

			{ /*-- modal 4 --*/}
			<Modal show={modal4} onHide={modal4Close}>
				<Form>
					<Modal.Header closeButton>
						<Modal.Title>Kenlo Insurance partnership</Modal.Title>
					</Modal.Header>
					<Modal.Body className="pb-2 pt-0">
						<p>Your real estate agency is not eligible to offer Kenlo Insurance as it is permanently blocked by our partner insurance company.</p>
						<div className='border borrder-secondary rounded-3 opacity-10 p-3 rounded'>
							<p className='fw-bold'>
								Why is my real estate agency blocked by the insurance company?
							</p>
							<p className='mb-3 mt-3'>After Tokio Marine's analysis, we are unable to proceed with your registration. According to the insurance company, the reason for the refusal is due to the credit score rating in credit bureaus.</p>
							<p className='mb-3 mt-3'>If you would like more information, you can contact our Support.</p>
						</div>
					</Modal.Body>
				</Form>
			</Modal>

			<Modal show={insuranceComp} onHide={modal5Close}>
				<Form>
					<Modal.Header closeButton>
						<Modal.Title>Kenlo Insurance partnership</Modal.Title>
					</Modal.Header>
					<Modal.Body className="pb-2 pt-0">
						<p>As a Kenlo partner, you receive a commission for each active fire insurance policy contracted by your clients, according to our commercial agreement.</p>
						<div className='border borrder-secondary rounded-3 opacity-10 p-3 rounded'>
							<p className='fw-bold'>
								Earning potential in commissions
								<div className='d-flex justify-content-between mt-3'>
									<div className='d-flex align-items-center'>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.53033 12.9697C4.23744 12.6768 3.76256 12.6768 3.46967 12.9697C3.17678 13.2626 3.17678 13.7374 3.46967 14.0303L7.96967 18.5303C8.26256 18.8232 8.73744 18.8232 9.03033 18.5303L20.0303 7.53033C20.3232 7.23744 20.3232 6.76256 20.0303 6.46967C19.7374 6.17678 19.2626 6.17678 18.9697 6.46967L8.5 16.9393L4.53033 12.9697Z" fill="#239867"/>
										</svg>
										<div className='ms-2'>
											<p className='mb-0 h5 border-bottom border-white border-3 text-success'>R$ 235,00</p>
											<p className='mb-0 fs-12 fw-normal text-success'>Received so far</p>
										</div>
									</div>
									<div className='d-flex align-items-center'>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13.7489 5.5L21.3027 5.50052L21.403 5.51444L21.5018 5.54205L21.5621 5.5676C21.6413 5.60246 21.7155 5.65315 21.7808 5.71836L21.8215 5.7624L21.865 5.81878L21.9192 5.9089L21.9579 5.99922L21.977 6.0633L21.9906 6.1273L22 6.2215L22.0004 13.7539C22.0004 14.1681 21.6647 14.5039 21.2504 14.5039C20.8708 14.5039 20.557 14.2217 20.5073 13.8557L20.5004 13.7539L20.5 8.059L12.7812 15.7793C12.5149 16.0455 12.0982 16.0698 11.8046 15.8519L11.7205 15.7793L8.75001 12.8089L3.28033 18.2786C2.98744 18.5715 2.51256 18.5715 2.21967 18.2786C1.9534 18.0123 1.9292 17.5957 2.14705 17.3021L2.21967 17.2179L8.21967 11.2179C8.48593 10.9517 8.90259 10.9275 9.1962 11.1453L9.28032 11.2179L12.2508 14.1883L19.438 7H13.7489C13.3692 7 13.0554 6.71785 13.0058 6.35177L12.9989 6.25C12.9989 5.8703 13.2811 5.55651 13.6472 5.50685L13.7489 5.5Z" fill="#888888"/>
										</svg>
										<div className='ms-2 '>
											<p className='mb-0 h5 border-bottom border-white border-3'>R$ 235,00</p>
											<p className='mb-0 fs-12 fw-normal'>Received so far</p>
										</div>
									</div>
								</div>
							</p>
						</div>
						<p className='mb-3 mt-3'>Close more fire insurance contracts to earn more!</p>
					</Modal.Body>
				</Form>
			</Modal>
		</>
	)
}    
export default InsurancePopups;