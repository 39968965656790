import { contractsData, getDocuments, getPropertyDocuments, openEmailPop } from '../../../../redux/slice/contractDataSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import customerService from '../../../../services/customerService';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Table, Button, Row, Col, Card, Dropdown, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../services/apiConfig';
import { emailPost } from '../../../../redux/slice/SendEmailSlice';
import { registerData } from '../../../../redux/slice/propertyDataSlice';
import SendDocumentEmail from '../Common/SendDocumentEmail';
import moment from "moment-timezone";
import Loader from '../../../Profile/Company/Loader';

interface Document {
  property_id: string;
  file_name: string;
  file_size: number;
  send_date: any;
  file_extension: any;
  file_hash: string;
}

// let final_data: any;

const Documents: React.FC = () => {
  let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
  const [t] = useTranslation();
  const [final_data, setFinal_data] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [documentList, setDocumentList] = useState<Document[]>([]);
  const [statusDoc, setStatusDoc] = useState<boolean>(true)
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id?: any }>();
  const { status, documents } = useSelector((state: RootState) => state.contractData)
  const [publicationData, SetPublicationData] = useState<any>({})
  const linkRef: any = useRef(null);
  const [propertyData, setPropertyData] = useState<any>([]);
  const [mailText, setMailText] = useState("Send Document Via Email");
  let documentType = "property"
  const [template, setTemplate] = useState<any>(`<p>Please Find The Downloadable File Link Below</p>`)



  useEffect(() => {
    const property_data = async () => {
      const response = await customerService.getSingleProperty(id);
      SetPublicationData(response.data.data[0]);
    }
    property_data();
  }, [dispatch])


  const getFileExtension = (filename: string): string => {
    return filename.slice(((filename.lastIndexOf('.') - 1 >>> 0) + 2));
  };

  const calculateFileHash = async (file) => {
    // Read the contents of the file as an ArrayBuffer
    const fileBuffer = await file.arrayBuffer();

    // Convert the ArrayBuffer to a Uint8Array
    const uint8Array = new Uint8Array(fileBuffer);

    // Use the SubtleCrypto API to calculate the hash (SHA-256) of the Uint8Array
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', uint8Array);

    // Convert the hash result (ArrayBuffer) to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
  };

  function formatDateToISOString(date) {
    // Step 1: Adjust the time zone offset to convert to UTC
    const offsetInMinutes = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() + offsetInMinutes * 60 * 1000);

    // Step 2: Format the resulting date as a string in the desired format
    const formattedDate = adjustedDate.toISOString();

    return formattedDate;
  }

  const [openOnce, setOpenOnce] = useState(true);


  const handleUpload = async () => {
    if (openOnce) {
      setOpenOnce(false);
      let AddDoc: any = document.querySelector('.attachedDocument');
      AddDoc.click();
    }
  };



  const [documentData, setDocumentData] = useState([]);
  const documentData1 = () => {
    (async () => {
      setStatusDoc(true)
      let response = await customerService.getPropertyDetailDocuments(id);
      setDocumentData(response.data.data);
      setStatusDoc(false)
    })()
  }
  const property_detail_data = async () => {
    let data = await dispatch(registerData(id));
    if (data.payload.resCode == 201) {
      // console.log("datadoc",data.payload.data);
      setPropertyData(data.payload.data);
    }
  }

  useEffect(() => {
    documentData1();
    property_detail_data();
  }, [])

  // console.log(documentData[0], "hdad");

  useEffect(() => {

    setFinal_data(documentData);
  }, [documentData])

  // console.log(final_data, "This is final dataaaaa");

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const handleDelete = (documentId: number) => {
    if (documentId) {
      Swal.fire({
        title: t("Sweetalert.Areyousure"),
        text: t("Sweetalert.Youwontbeabletorevert"),
        // icon: 'success',"question",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it",
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          setStatusDoc(true)
          let res = await customerService.deleteContractDetailDocuments(documentId);
          if (res.data.resCode == 408) {
            setStatusDoc(false)
            // Show a success message using Swal
            Swal.fire({
              // icon: 'success','success',
              title: t("Sweetalert.Delete"),
              text: t("Sweetalert.deletesuccess"),
              showConfirmButton: false,
              timer: 2000
            })
            documentData1();

          } else {
            Swal.fire({
              // icon: 'success','error',
              title: t("Sweetalert.Error"),
              text: t("Sweetalert.somethingWent"),
              timer: 2000
            })
          }
        }
      })
    }
  };



  /*--------------Harjinder-----------*/
  const historyBack = () => {
    window.history.back()
  }
  /*---------End----------*/

  function formatFileSize(fileSizeInBytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (fileSizeInBytes < kilobyte) {
      return fileSizeInBytes + ' B';
    } else if (fileSizeInBytes < megabyte) {
      return (fileSizeInBytes / kilobyte).toFixed(2) + ' KB';
    } else {
      return (fileSizeInBytes / megabyte).toFixed(2) + ' MB';
    }
  }

  const handleOptionChange = async (optionId, documentId, contractId) => {
    // Do something with the selected option ID

    const response = await customerService.documentStatusUpdate(optionId, documentId);
    if (response.data.resCode === 200 || response.data.resCode === "200") {
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.RecordUpdated"),
        confirmButtonText: "OK",
        timer: 2000
      })
      setOpenOnce(true);
      documentData1();
    }
    else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000
      })
      setOpenOnce(true);
    }
  };



  const handleFileChange = async (event: any) => {
    const file: any = event.target.files[0];
    setSelectedFile(file);
    handleFormSubmitformm(file)
  };


  const handleFormSubmitformm = async (fileData: any) => {
    // console.log(fileData, "formsubmit")
    if (fileData && fileData !== null) {
      const currentDate = new Date(dateBrazil);
      const formData = new FormData();
      formData.append('file', fileData);
      formData.append('property_id', id);
      formData.append('file_name', fileData.name);
      formData.append('file_size', fileData.size.toString());
      formData.append('send_date', formatDateToISOString(currentDate));
      formData.append('file_extension', getFileExtension(fileData.name));
      setStatusDoc(true)
      const response = await customerService.uploadContractDetailDocuments(formData);
      if (typeof response !== "undefined" && response.data.resCode === 202) {
        Swal.fire({
          // icon: 'success','success',
          title: t("Sweetalert.Success"),
          text: t("Sweetalert.successget"),
          confirmButtonText: "OK",
          timer: 2000
        });
        setOpenOnce(true);
        documentData1();
        setStatusDoc(false)
      } else {
        Swal.fire({
          title: t("Sweetalert.Error"),
          text: t("Sweetalert.somethingWent"),
          // icon: 'success',"error",
          confirmButtonText: "OK",
          timer: 2000
        });
        setOpenOnce(true);
      }
    } else {
      setOpenOnce(true);
    }
  };

  let ownerEmails = propertyData[0]?.owner.map(obj => obj.email) || [];
  let tenantEmails = ["karan@wegile.com"];
  let allEmails = [...ownerEmails, ...tenantEmails];
  let emailCollection = [allEmails, ownerEmails, tenantEmails];


  const handleSendEmail = async (e, file_hash, file_type) => {

    const newTemplate = `
    <p>Please Find The Downloadable File Link Below</p>

    <p><a href="${file_hash}" style="color: green;">Click to Download File</a></p>
    
    `
    setTemplate(newTemplate)
    await dispatch(openEmailPop(true))
  }



  return (
    <section className="content-wrapper" id="propertydocumentstsx">
      {/*  <!-- Breadcrumb section start from here --> */}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
        <div className="w-100">
        <Row className="gx-xl-5 justify-content-between align-items-center">
        <Col xs={{ order: 12 }} xl={{ order: 0 }} className="mb-xl-0 mb-3 col-xl">
        <div className="d-flex align-items-center justify-content-start">
              <Link to={"/property_detail/" + publicationData.id}>
                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
              </Link>
              <h1 className="mb-0 h4 lh-base ms-2 ms-lg-3 ms-xl-4 text-nowrap fw-medium">{t("contractlistother.Publicationofdocuments")}
              <Badge bg="light" className="text-dark ms-1 fw-medium">{publicationData.id !== undefined ? publicationData.propertyType + " " + publicationData.id : ""}</Badge>
              </h1>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} xl={{ order: 3, span: "auto" }} className="text-xl-end mb-xl-0 mb-2">
            <Link className="btn btn-link p-0 fw-bold" to={"/property_detail/" + publicationData.id}>{publicationData.id !== undefined ? publicationData.propertyType + " " + publicationData.id : ""} </Link>
          </Col>
          </Row>
        </div>
      </div>
      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between">
            {t("documents")}
            <form>
              <input type="file" onChange={handleFileChange} className="position-absolute left-0 end-0 attachedDocument" hidden />
              {/* <Button variant="link" type="submit" size="sm" className="fw-bold position-relative z-index-1 DocumentSubmitfilxx d-none">hidden</Button> */}
            </form>
            <Button variant="link" size="sm" className="fw-bold position-relative p-0 z-index-1" onClick={handleUpload}>{t("contractlistother.Attachdocuments")}</Button>
          </Card.Header>
          {statusDoc ?
            <Loader />
            :
            <Card.Body>
              <Table responsive="lg" className="table table-custom table-custom-bordered mb-0">
                <thead>
                  <tr>
                    <th className="pb-0">{t("contractlistother.DocumentNo")}</th>
                    <th className="pb-0">{t("documents")} {t("formLabel.name")}</th>
                    <th className="pb-0">{t("contractlistother.SizeUploaded")}</th>
                    <th className="pb-0">{t("contractlistother.SendDate")}</th>
                    <th className="pb-0">{t("contractlistother.Share")}</th>
                    <th className="pb-0">{t("button.action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {final_data ? final_data.reverse().map((document: any, index) => (
                    <tr key={index}>
                      <td>{document.file_id}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {(typeof document.file_hash == "string" && document.file_hash !== "") ?

                            (<>
                              <a className='text-dark' href={document.file_hash}>
                                <img className="me-1 flex-shrink-0 " style={{ width: '40px' }} src={`../assets/images/icons/${document.file_extension}.png`} alt="send" /> {document.file_name}
                              </a>
                            </>) : (
                              <Link className='text-dark'
                                to={`${config.CUSTOMER___API}/checklist/${document.file_name}`}
                                ref={linkRef}
                              >
                                <img className="me-1 flex-shrink-0 " style={{ width: '40px' }} src={`../assets/images/icons/${document.file_extension}.png`} alt="send" /> {document.file_name} </Link>
                            )}

                        </div>
                      </td>
                      <td>{formatFileSize(document.file_size)}</td>
                      <td>{formatDate(document.send_date)}</td>
                      <td>

                        <Dropdown>
                          <Dropdown.Toggle size="sm" variant="link" className="border-0 bg-primary-20 text-dark rounded-3 fs-12 px-2" id="dropdown-basic">
                            {document.file_type === 1 ? t("customerAndEmployees.tenants") + " " + t("customerAndEmployees.and") + " " + t("customerAndEmployees.owners") :
                              document.file_type === 2 ? t("customerAndEmployees.owners") :
                                document.file_type === 3 ? t("customerAndEmployees.tenants") :
                                  document.file_type === 4 ? t("contractlistother.Not") + " " + t("contractlistother.Share") :
                                    t("contractlistother.Toshare")}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="mh-456 overflow-auto">
                            {/* <Dropdown.Item
                            onClick={() => handleOptionChange(1, document.file_id, id)}
                            className={document.file_type === 1 ? 'active bg-dark text-white' : ''}
                          >
                            {t("customerAndEmployees.tenants") + " " + t("customerAndEmployees.and") + " " + t("customerAndEmployees.owners")}
                          </Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={() => handleOptionChange(2, document.file_id, id)}
                              className={document.file_type === 2 ? 'active bg-dark text-white' : ''}
                            >
                              {t("customerAndEmployees.owners")}
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                            onClick={() => handleOptionChange(3, document.file_id, id)}
                            className={document.file_type === 3 ? 'active bg-dark text-white' : ''}
                          >
                            {t("customerAndEmployees.tenants")}
                          </Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={() => handleOptionChange(4, document.file_id, id)}
                              className={document.file_type === 4 ? 'active bg-dark text-white' : ''}
                            >
                              {t("contractlistother.Not")} {t("contractlistother.Share")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>



                      </td>
                      <td className="text-end">
                        <Button variant="link" className="p-0 me-2" onClick={(e) => handleSendEmail(e, document.file_hash, document.file_type)}>
                          <img className="h-14" src="../assets/images/icons/send-light.svg" alt="send" />
                        </Button>
                        <Button variant="link" className="p-0" onClick={() => handleDelete(document.file_id)}>
                          <img className="h-14" src="../assets/images/icons/delete-light.svg" alt="Delete" />
                        </Button>
                      </td>
                    </tr>
                  )) : <p>{t("datanotfound")}</p>}
                </tbody>
              </Table>
              {
                final_data ? <p className='mb-0'>{t("formLabel.listing")} {final_data.length} {t("documents")}</p> : ""
              }
              {/* <p className="mb-0">Listing 3 documents.</p> */}
            </Card.Body>
          }
        </Card>

      </main>
      <SendDocumentEmail mailText={mailText} mailData={emailCollection} type={documentType} />
      <SendDocumentEmail mailText={mailText} mailData={emailCollection} type={documentType} template={template} />
    </section>
  );
};
export default Documents;

function documentData1() {
  throw new Error('Function not implemented.');
}
