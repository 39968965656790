import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      "loginPage": {
        "title": "Boost your real estate agency with automation and intelligence",
        "subtitle": "Unleash the potential of your real estate agency, increase your sales and always be ahead in the real estate market",
        "knowimob": "Get to know Kenlo Imob",
        "welcome": "Welcome",
        "kenlo": "Kenlo Locação",
        "isOnline": "is online",
        "notSignin": "Can't get in?",
        "chat": "Chat with our team."
      },
      "submit" : "Submit",
      "verifying" : "Verifying",
      "activateAccountFirst": "Please activate bank account first",
      "mainAccountStatus": "Main Account Status",
      "inActives": "In-Active",
      "recExpense": "of which are recurring.",
      "readjustContract": "Readjust Contract",
      "previousBalance": "Previous balance",
      "searchCategoriesAccount": "Type to search for categories",
      "undetermined": "Undetermined",
      "overDueExpense": "Overdue expenses",
      "charge": "Charge",
      "noProportionalRent": "No Proportional Rent For This Contract",
      "editExtraChargeLabel": "Edit Charge",
      "reverse": "Reversed",
      "undo": "Undo",
      "reverseTransfer": "Reverse Transfer",
      "reverseRecieved": "Reverse Recieved",
      "chargeClone": "Charge Clone",
      "reasonForBaleto": "Reason for Cancel Boleto/Pix",
      "Indefinite": "Indefinite",
      "Yessuspendedit!": "Yes, suspended it!",
      "Youwould'ntbeabletorevertthis!": "You would'nt be able to revert this!",
      "Alert": "Alert",
      "customDate": "Custom Date",
      "batchSettlement": "Batch Settlement",
      "sttleCustomDate": "Settle on the custom date",
      "readjustment_date": "Last readjustment date",
      "Supplieridentificationcode": "Supplier identification code",
      "accountingcontable": "Accounting account code of this supplier for data export to accounting",
      "dueDaysErr": "Due days required less than current month days",
      "Cancelinstallments": "Cancel installments",
      "Deleteservice": "Delete service",
      "Bydeletibecharged": "By deleting this service, it will no longer be charged",
      "Createelectronicenvelope": "Create electronic envelope",
      "Keyreturnagreement": "Key return agreement",
      "KeyHandoverFileNotAvailable": "Key Handover File Not Available",
      "TerminationAgreement": "Termination Agreement",
      "Viewtemplates": "View templates",
      "Sendsignedterminationagreement": "Send signed termination agreement",
      "Inspectionreport": "Inspection report",
      "Generatereport": "Generate report",
      "Sendsignedinspectionreport": "Send signed inspection report",
      "Otherdocuments": "Other documents",

      "Othercharges": "Other charges",
      "Chargedata": "Charge data",
      "Thesettingsofthiscontrac": "The settings of this contract do not include a termination penalty",
      "Proportionaldays": "Proportional days",
      "Proportionalamount": "Proportional amount",
      "Chargeduedate": "Charge due date",
      "Terminationpenalty": "Termination penalty",
      "Terminationfine": "Termination fine",
      "Viewcalculation": "View calculation",
      "Additionsanddeductions": "Additions and deductions",
      "Fee": "Fee",
      "Totalchargeamount": "Total charge amount",
      "DeductfromSecurityDep": "Deduct from Security Dep",
      "Securitydepositvalue": "Security deposit value",
      "Observationsfortheclient": "Observations for the client",
      "Finalamount": "Final amount",

      "Keyreturnterm": "Key return term",
      "Sendkeyreturnterm": "Send key return term",
      "Generateterm": "Generate term",
      "Releasetheproperty": "Release the property for another lease",
      "Deleteexpense": "Delete expense",
      "BydeletingServiceth": "By deleting Service, the expense will no longer be debited",
      "ByaddingServicetheterminationdocument": "By adding Service fee, the expense will be taken into account in the final calculation of the termination document",
      "Deletedrecurringexpense": "Deleted recurring expense",
      "Endofexpense": "End of expense",
      "Addtotermination": "Add to termination",
      "Norecordsareaddedtotermination": "No records are added to termination. Are you sure want to continue.",
      "Inspectiondata": "Inspection data",
      "Beneficiaries": "Beneficiaries",
      "Repassedia": "Transfer day",
      "bloquendo": "Blocked balance",
      "donotnotify": "Do not notify in the collection rule",
      "dependentsNo": "N° of dependents",
      "non_recedential": "Non-resident in Brazil",
      "Renda": "Income",
      "possiu": "No income",
      "vinculo": "Employment relationship",
      "beneficio": "Benefit number",
      "rendimentous": "Other income",
      "performThisAction": "You can perform this action with in the contract",
      "Yearly": "Yearly",
      "Monthly": "Monthly",
      "OriginalValue": "Original Value",
      "sendInspection": "Send inspection report",
      "SupportedFormats": "Supported formats: PDF and PNG",
      "AddFile": "Add File",
      "chargeDescription": "Charge description",
      "transferDescription": "Transfer description",
      "totalCharge": "Total charge",
      "totalTransfer": "Total transfer",
      "nextRentalFee": "Next Rental fee",
      "propertyIdentifierID": "Identification (ID) of property in the system",
      "seeTenant": "See tenant",
      "seeOwner": "See owner",
      "readjustments": "Readjustments",
      "listOfReadjustContacts": "List of Readjust Contracts",
      "seeAllIndices": "See all indices",
      "adjustmentIndices": "Adjustment indices",
      "simulateReadjust": "Simulate and readjust",
      "contractReadjust": "Contracts to be readjusted",
      "checklistOwner": "The owner is responsible for this item",
      "checklistTenant": "The Tenant is responsible for this item",
      "checklistGuarantor": "The Guarantor is responsible for this item",
      "checkListRealEstate": "The Real estate is responsible for this item",
      "sendEmailActiveContracts": "Send Email : Active Contracts Owners",
      "sendEmailActiveTenantContracts": "Send Email : Active Contracts Tenants",
      "sendEmailNonActiveContracts": "Send Email : Non Active Contracts Owners",
      "sendEmailNonActiveTenantContracts": "Send Email : Non Active Contracts Tenants",
      "observacoes": "Comments",
      "dateCapture": "Date of capture",
      "propertyPer": "Property %",
      "contaCorr": "Owner's current account",
      "util": "Use the Owner's Current Account?",
      "utilDesc": "When deciding to use the Owner's Current Account, it will not be possible to undo this operation after saving the property.",
      "selectFolder": "Select Template Folder",
      "selectTemplate": "Select Template",
      "postponeAdjustment": "Postpone adjustment",
      "postponeAdjustmentFor": "Postpone adjustment for",
      "Has no warranty": "Has no warranty",
      "Guarantor": "Guarantor",
      "Security deposit": "Security deposit",
      "Surety bond": "Surety bond",
      "Capitalization bond": "Capitalization bond",
      "Surety": "Surety",
      "Fiduciary assignment": "Fiduciary assignment",
      "RemoveSettledCharges": "Remove Settled Charges",
      "detail": "detail",
      "contractDetail": "Contract Details",
      "notConnectedcontract": "Contract not connected",
      "Install": "Installment",
      "Nao": "No",
      "createCharge": "Create Charge",
      "lunchCharge": "Launch on the next charge",
      "toDisregard": "To disregard",
      "diffAmt": "What to do with the difference of",
      "chargeDate": "Charge date",
      "Mensalmente": "Monthly",
      "Bimestralmente": "Bimonthly",
      "Trimestralmente": "Quarterly",
      "Semestralmente": "Biannually",
      "Anualmente": "Annually",
      "chargeSimulation": "Charge Simulation",
      "generateSimulation": "Generate simulation",
      "numberChargesGenerated": "Total number of charges to be generated",
      "chargeGenerated": "Total charges to be generated",
      "clientBilled": "Total clients to be billed",
      "amountChargesGenerated": "Total amount of charges to be generated",
      "amountWithholdings": "Total amount of withholdings",
      "numberCharges": "Number of charges that did not reach the minimum value",
      "amountCharges": "Amount of charges that did not reach the minimum value",

      "create": "Create",
      "EditallSubsequentExpenses": "Edit all Subsequent Expenses",
      "EnsureCreditDateAfterSaleDate": "Ensure Credit Date After Sale Date",
      "unforgiving": "Divorced",
      "single": "Single",
      "married": "Married",
      "feminine": "Feminino",
      "masculine": "Masculine",
      "rent": "Rent",
      "transfer_forecast": "Transfer forecast",
      "foreverycontract": "for every contract",
      "uninformed": "Uninformed",
      "singlephase": "Single phase",
      "fixedvalue": "Fixed value",
      "percentual": "Percentage",
      "biphasic": "Biphasic",
      "threephase": "Three phase",
      "undefined": "Undefined",
      "focusonlyonrent": "Focus only on rent",
      "urbanproperty": "Urban property",
      "ruralproperty": "Rural property",
      "focusonrent": "Focus on rent and other services",
      "periodfilter": "Period filter",
      "expiredMonth": "Expired Month",
      "Monthtoexpire": "Month to expire",
      "monthDue": "Month Due",
      "proportionRent": "Proportion Rent",
      "infosuccesschange": "Information changed successfully.",
      "chargesGenerated": "Charges Generated",
      "simulateAll": "Simulate All",
      "contractToReadjust": "Contract To Readjust",
      "Welcome to React": "Welcome to React and react-i18next.",
      "Broker": "Broker",
      "contracts": "Contracts",
      "AccessMetrixs": "Access Metrixs",
      "FormsOfReceipts": "Forms Of Receipts",
      "PageNotFound": "Page Not Found",
      "profile": "Profile",
      "InvalidateProperties": "Invalidate Properties",
      "PropertyCharges": "Property Charges",
      "DetailedAgreements": "Detailed Agreements",
      // "doFixedAmount": "Do not use percentage, use fixed value",
      "Termofagreementandacknowledgmentofdebt": "Term of agreement and acknowledgment of debt",
      "DefaultSummary": "Default Summary",
      "Defaultwithcomposition": "Default with composition",
      "newContract": "New contract",
      "expenses": "Expenses",
      "ofthecontract": "Of the contract",
      "pChecklists": "Pending checklists",
      "Checklists": "Checklists",
      "Addchecklist": "Add checklist",
      "AddchecklistItem": "New Item",
      "tchecklists": "Checklist templates",
      "datanotfound": "Data not found",
      "OOPS": "Oops No Records Found!",
      "ticketonthesecondcopy": "Update the value of the ticket on the second copy",
      "sprate": "Spread",
      "daytounavailable": "Days to unavailable 2nd copy",
      "additionalbillinginstruct": "Additional billing instructions",
      "messageonalltickets": "Message on all tickets",
      "AgencyAccount": "Agency Account",
      "insurers": "Insurers",
      "active": "Active",
      "disabled": "disabled",
      "terminated": "Terminated",
      "terminateds": "Terminated",
      "Deactive": "Deactive",
      "pending": "Pending",
      "paused": "Paused",
      "finished": "Finished",
      "Branch": "Branch",
      "tenant": "Tenant",
      "real estate": "real estate",
      "bankAccount": "Bank account",
      "LeaseDetailsRent": "Lease Details Rent",
      "thepropertyforthiscontract": "You cannot unrelease the property for this contract",
      "intheamountof": "in the amount of",
      "paymentSplit": "Payment split",
      "withSplit": "With split",
      "withoutSplit": "Without split",
      "manager": "Manager",
      "moreFilters": "More filters",
      "overview": "Overview",
      "summaryMonth": "Summary in the month",
      "conquered": "Conquered",
      "toReadjust": "To Readjust",
      "noRentalFee": "No rental fee",
      "listOfContracts": "List of contracts",
      "contract": "Contract",
      "adminFee": "Admin fee",
      "Property": "Property",
      "recordFailed": "Record Failed to Insert",
      "templateSaved": "Template Saved",
      "yesConfirm": "Yes, I Confirm!",
      "confirmTemplateSelection": "Are you sure you want to select {{type}}? You would be unable to revert this!",
      "seeProperty": "See Property",
      "seeContract": "See Contract",
      "totalAdminFee": "Total Admin fee",
      "contractMirror": "Contract mirror",
      "contractlabel": "Contract label",
      "mailingLabel": "Mailing label",
      "most": "Most",
      "changeStatus": "Change Status",
      "changemain": "Change main account",
      "updateStatus": "Update status",
      "chgFncStatus": "Change financial status",
      "chgBankFee": "Change DOC/TED bank fee",
      "chgBankAccount": "Change billing and branch bank account",
      "chgRdIndex": "Change readjustment index",
      "chgDIMOBOpt": "Change DIMOB option",
      "chgSplitOpt": "Change split option",
      "chgInvoiceOpt": "Change invoice option",
      "chgBillingMethod": "Change billing method",
      "chgTenantBankFee": "Charge tenant's bank fee",
      "inviteOwnerAreas": "Invite to owner and tenant areas",
      "changeGtdTsfCnt": "Change guaranteed transfers of contracts",
      "changeGtdTsf": "Change to not pass on fines and interest in guaranteed transfer",
      "commercialAgents": "Commercial agents",
      "financialStatus": "Financial status",
      "readjustmentIndex": "Readjustment index",
      "incomeTaxStatus": "Income tax status",
      "formOfPayment": "Form of payment",
      "warranty": "Warranty",
      "insurance": "Insurance",
      "checklist": "Checklist",
      "checklistStatus": "Checklist status",
      "electronicEnvelope": "Electronic Envelope",
      "dueDate": "Due Date",
      "dueDays": "Due days",
      "expireDate": "Expire date",
      "currentAccount": "Current account",
      "SavingAccount": "Saving account",
      "whichProperty": "Which property?",
      "whichTenant": "Which tenant?",
      "whatGuaranteeUsed": "What guarantee is used?",
      "doFireInsurance": "Do you have fire insurance?",
      "contractDetails": "Contract details",
      "transferRent": "Transfer of rent",
      "whatStatusCont": "What is the status of this contract?",
      "activeProcontract": "Active products in the contract",
      "immobile": "Immobile",
      "immobilePlaceholder": "Identifier , Address , Zipcode , Property type",
      "owner": "Owner",
      "contractType": "Contract type",
      "residential": "Residential",
      "nonResidential": "Non-residential",
      "comercial": "Commercial",
      "indústria": "Industry",
      "temporada": "Season",
      "misto": "Mixed",
      "porEncomenda": "By order",
      "commercial": "Commercial",
      "industry": "Industry",
      "season": "Season",
      "byOrder": "By order",
      "mixed": "Mixed",
      "businessActivity": "Business activity",
      "taxations": "Taxation",
      "invoice": "Invoice",
      "doSystem": "Do not issue by system",
      "issueBySystem": "Issue by system",
      "incomeTax": "Income tax",
      "doesTax": "Does not withhold tax",
      "withholdsSystem": "Withholds, but the withdrawal will not be controlled by the system",
      "withholdTaxes": "Withhold, and the real estate is the one who collects and pays taxes",
      "withholdCollects": "Withhold, and the tenant collects",
      "deductIRRF": "Deduct the administration fee in the automatic calculation of the IRRF.",
      "select": "Select",
      "followGeneralSetup": "Follow general setup",
      "yesContract": "Yes, only for this contract",
      "noJustContract": "No, just for this contract",
      "checkDIMOB": "If checked, it will appear in DIMOB and in the income report generated by the system", // add new
      "suspendInfo": "Select this option to suspend all charges for this contract (suspended charges cannot be viewed by renters).",                         // add new
      "selectTenant": "Select a tenant or click on the icon to register a new one",                         // add new
      "billingAddress": "Billing address",
      "useRentalPtyAddress": "Use o endereço do imóvel alugado",
      "useTenantAddress": "Usar endereço de inquilino",
      "setAnotherAddress": "Definir outro endereço",
      "sex": "Sex",
      "birth": "Birth",
      "maritalStatus": "Marital status",
      "nationality": "Nationality",
      "zipCode": "Zip code",
      "address": "Address",
      "number": "Number",
      "site": "Site",
      "complement": "Complement",
      "neighborhood": "Neighborhood",
      "city": "City",
      "guarantee": "Guarantee",
      "guarantor": "Guarantor",
      "securityDeposit": "Security deposit",
      "suretyBond": "Bail insurance",
      "capitalizationBond": "Capitalization bond",
      "surety": "Pledger",
      "fiduciaryAssignment": "Fiduciary assignment",
      "hasNoWarranty": "Has no warranty",
      "details": "Details",
      "type": "Type",
      "value": "Value",
      "vehicle": "Vehicle",
      "letterGuarantee": "letter of guarantee",
      "others": "Others",
      "realEstate": "Real estate",
      "responsible": "Responsible",
      "description": "Description",
      "identifier": "Identifier",
      "insurer": "insurer",
      "Doesnothaveinsurance": "Does not have insurance",
      "Typeofactivity": "Type of activity",
      "insurencefireDescription": "By registering the contract's fire insurance, the system will alert you about renewal deadlines.",
      "policy": "Policy",
      "numberInstallments": "Number of installments",
      "bank": "Bank",
      "backgroundType": "Background type",
      "applicationNumber": "Application number",
      "DaysOfContract": "Days of contract",
      "Daysendcontract": "Days until end of contract",
      "realState": "Real Estate",
      "inMonths": "In Months",
      "inDays": "In Days",
      "in": "In",
      "until": "Until",
      "observation": "Observation",
      "no": "No",
      "yes": "Yes",
      "start": "Start",
      "end": "End",
      "assuredValue": "Assured value",
      "prizeAmount": "Prize amount",
      "installmentValue": "Installment value",
      "insuranceCompany": "Insurance company",
      "contractedPlan": "Contracted plan",
      "duration": "Duration (in months)",
      "rentValue": "Rent value",
      "graceDays": "Grace days",
      "occupation": "Occupation",
      "1stRent": "1ST PROPORTIONAL RENT",
      "value1stRent": "Value of the 1st rent",
      "charge2ndRent": "Charge with the 2nd rent.",
      "copyuserdata": "Copy user data",
      "copygroupdata": "Copy user group data",
      "withtagged": "With tagged",
      "readjustment": "Readjustment",
      "more": "More",
      "billingMethod": "Billing method",
      "Fire": "Fire",
      "lightning": "lightning",
      "explosion": "explosion",
      "smoke": "smoke",
      "aircraftcrash": "aircraft crash",
      "Gale": "Gale",
      "hurricane": "hurricane",
      "cyclone": "cyclones",
      "tornado": "tornado and hail",
      "hail": "hail",
      "ResponsabilidadeCivil": "Responsabilidade Civil",
      "Theftofgoods": "Theft of goods through break-in and robbery",
      "Glass": "Glass",
      "mirrors": "mirrors",
      "marble": "marble",
      "Rentalexpenses": "Rental expenses",
      "Windstorms": "Windstorms",
      "Documentrestoration": "Document restoration",
      "Assistance": "Assistance",
      "Locksmith": "Locksmith",
      "ElectricalLabor": "Electrical Labor",
      "PlumbingLabor": "Plumbing Labor",
      "TemporaryRoofCovering": "Temporary Roof Covering",
      "InstallationofSimpleLocks": "Installation of Simple Locks",
      "InstallationofTetraLocks": "Installation of Tetra Locks",
      "amongOthers": "among others",
      "unclogging": "unclogging",
      "electrician": "electrician",
      "electricaldamage": "electrical damage",
      "plumber": "plumber",
      "removalAndFurnitureStorage": "removal and furniture storage",
      "glazier": "glazier",
      "billingMethods": {
        "expired": "The bill is generated after moving into the property, i.e., the tenant moves in and then pays.",
        "due": "The bill is generated before moving into the property, i.e., the tenant pays and then moves in."
      },
      "deliveryMethod": "Delivery method",
      "mailOnly": "Mail only",
      "digitalOnly": "Digital only",
      "exemptionFine": "Exemption from termination fine",
      "exemptionFineTooltip": "Indicate whether the contract provides for exemption from the termination fee. This information will be used to calculate the termination fee for this contract.",
      "noExemption": "No exemption",
      "free": "Free",
      "exemptionMonths": "Exemption months",
      "monthsExemption": "Months for fine exemption",
      "leaseMonth": "Rent period with complete month",
      "leaseMonthTooltip": "If this option is selected, in the rental with a closed month period, the charge will be made based on the days of the month. For example, if the rent is due on the 20th, the period charged will be from the first day to the last day of the month (01 to 30).",
      "differentContract": "Different interest, penalty, and discount for this contract",
      "differentContractTooltip": "Select this option only if the contract has interest, fines and discounts different from what was filled in in the rates charged.",
      "fees": "Fees(%)",
      "Much": "Much(%)",
      "trafficTicket": "Traffic ticket(%)",
      "punctualityDiscount": "Punctuality discount (%)",
      "chargeBankFee": "Charge the renter's bank fee",
      "chargeBoletoFee": "Charge the renter's Boleto fee (R$4.00)",
      "commercialManager": "Commercial Manager",
      "additionalClauses": "Additional clauses",
      "monthlyAdminRate": "Monthly administration fee",
      "adminrate": "Admin rate",
      "Leaserate": "Lease rate",
      "doFixedAmount": "Use numerical value",
      "automaticPayment": "Automatic payment via Split (with Arian)",
      "manualPayment": "Manual payment",
      "transferType": "Transfer type",
      "businessPayment": "Business days after rent payment",
      "fixedDay": "fixed day",
      "calendarPayment": "Calendar days after rent payment",
      "transferDay": "Transfer day",
      "guaranteedTransfer": "Guaranteed transfer",
      "doesNotHave": "does not have",
      "guaranteeFewMonths": "Guarantee for few months",
      "guaranteeContract": "Guarantee for the whole contract",
      "months": "Months",
      "maturity": "Maturity",
      "chargeDocBankFee": "Charge DOC/TED bank fee",
      "generatedimob": "Generate DIMOB by the system",
      "price": "Price R$)",
      "itIsReady": "It is ready, it is possible to generate charges",
      "itHasPending": "It has pending issues, it is not possible to generate charges",
      "chooseCheckTemplate": "Choose a checklist template",
      "contractGenerated": "Contract generated successfully!",
      "printOut": "Print out",
      "launchRentalFee": "Launch rental fee",
      "accessContract": "Access contract",
      "registerAthContract": "Register another contract",
      "close": "Close",
      "previous": "Previous",
      "next": "Next",
      "save": "Save",
      "generateCharges": "Generate charges",
      "generateCharge": "Generate charge",
      "numberMonths": "Number of months",
      "generateInfo": "Do not forget to enter expenses such as IPTU, condominium fees in the period in which the charges are generated.",
      "generateInfo2": "Simulate charges to ensure they are generated correctly.",
      "simulate": "Simulate",
      "historic": "Historic",
      "initiateTermination": "Initiate termination",
      "cancelTermination": "Cancel termination",
      "successCancledTermination": "Termination cancelled successfully",
      "pauseContract": "Pause contract",
      "activeContract": "Activate contract",
      "notifyContract": "Notify the parties to the contract",
      "tag": "Tag",
      "keyTerm": "Key handover term",
      "fireInsurance": "Information for applying for fire insurance",
      "checkList": "Check list",
      "documents": "Documents",
      "overdueCharges": "Overdue charges",
      "overdueCharge": "Overdue charge",
      "transfersComplete": "transfer to make",
      "transfersCompletes": "transfers to make",
      "monthsGuaranteeds": "Months already guaranteed",
      "monthsGuaranteed": "Month already guaranteed",
      "changeContract": "Change contract",
      "renewContract": "Renew Contract",
      "house": "House in the",
      "monthExpired": "Month expired",
      "monthdue": "Month due",
      "admTxt": "Admin Fees",
      "Servicesprovision": "Services provision",
      "Adminfeeisleviedon": "Admin fee is levied on",
      "Changeservicedelivery": "Change service delivery",
      "Monthsforexemptionfromterminationfine": "Months for exemption from termination fine",
      "Maximumleaseterm": "Maximum lease term",
      "Leasetax": "Lease tax",
      "nfsE": "NFS-e",
      "contractInvoice": "This contract issues Invoice",
      "transfer": "Transfer",
      "every": "Every",
      "transferRate": "Transfer rate",
      "billingEnd": "Billing address ",
      "deliveryForm": "Delivery form",
      "index": "Index",
      "Monthyear": "Month year",
      "notFound": "Not found",
      "nextReadjustment": "Next readjustment",
      "lastReadjustment": "Last readjustment",
      "locator": "Locator",
      "FireInsurance": "Fire Insurance",
      "renewInsurance": "Renew insurance",
      "addInsurance": "Add insurance",
      "getQuote": "Get quote",
      "validity": "Validity",
      "from": "From  ",
      "to": "to",
      "renewWarranty": "Renew warranty",
      "addwarranty": "Add warranty",
      "danielBeck": "Daniel Beck",
      "viewExpense": "View expense",
      "newExpense": "New expense",
      "newRecurringExp": "New recurring expense",
      "Expensesfor": "Expenses for",
      "withBarcode": "With barcode",
      "rentalFee": "Rental fee",
      "issuedChecks": "Issued checks",
      "selectAll": "Select all",
      "inProgress": "In progress",
      "inActive": "In Active",
      "All": "All",
      "Electronicenvelope": "Electronic envelope",
      "Newenvelope": "New envelope",
      "Noenvelopefound": "No envelope found",
      "Contractchargesin": "Contract charges in",
      "Newextracharge": "New extra charge",
      "Totherenter": "To the renter",
      "Totheowner": "To the owner",
      "Settledcharges": "Settled charges",
      "chargesclienttotal": "charges client total",
      "Client": "Client",
      "Transfersmadein": "Transfers made in",
      "Groupedstatementof": "Grouped statement of",
      "Report": "Report",
      "Printkeydeliveryterms": "Print key delivery terms",
      "Keyhandoverdate": "Key handover date",
      "KeyHandOver": "Key HandOver",
      "arianMsg": "Please Configure Owner's Bank Account.",
      "checklistEmpty": "Checklist is empty.",
      "notMarked": "Not marked",
      "marked": "Marked",
      "sent": "Sent",
      "scheduled": "Scheduled",
      "witherror": "With error",
      "settled": "Settled",
      "checkstoclear": "Checks to clear",
      "closeMonth": "Closed period month",
      "openMonth": "Open period month",
      "renewWarrantyTitle": "By registering the desired warranty, the system will alert you about renewal deadlines.",
      "show": "Show",
      "entries": "entries",
      "award": "Award",
      "assuredAmount": "Assured amount",
      "numberInstallment": "Number of installments",
      "contractPlan": "Contract plan",
      "total": "Total",
      "saveChanges": "Save changes",
      "attachFile": "Attach file",
      "cloneproperty": "Clone property",
      "invalidzipcode": "Invalid zip code",
      "actions": "Actions",
      "amount": "Amount",
      "Amount": "Amount",
      "Details": "Details",
      "NoRecords": "No Records found",
      "tobereceived": "to be received",
      "help": "Help",
      "Processing": "Processing...",
      "Alreadyconnected": "Already connected to Plug Notes",
      "AlreadyconnectedKenloSlip": "Already connected to",
      "connecttoplugnotus": "Connect to Plug Notes",
      "changeperson": "Change person",
      "Sendemail": "Send email",
      "Contractswithoverdue": "Contracts with overdue charges",
      "Defaultofcontracts": "Default of contracts",
      "Extractforincome": "Extract for income tax return",
      "Incomegroupedby": "Income grouped by tenant",
      "Currentaccountstatement": "Current account statement",
      "Transferstatement": "Transfer statement from",
      "Groupedstatement": "Grouped statement",
      "Unrealizedtransfers": "Unrealized transfers",
      "Transfersmade": "Transfers made",
      "Toadd": "To add",
      "Registerother": "Register other payment methods for the owner?.",
      "oneprimaryaccount": "Please select at least one primary account!",
      "Primary": "Primary",
      "BankDetails": "Bank Details",
      "Currentaccount": "Current account movements in",
      "Separate": "Separate",
      "Forthird": "For third",
      "Blockowner": "Block owner balance",
      "Ticketfor": "Ticket for",
      "Internalticket": "Internal ticket",
      "Seealltickets": "See all tickets",
      "Emaillist": "Email list",
      "Message": "Message",
      "Recenttickets": "Recent tickets",
      "emails": "emails",
      "sentandwith": "sent and 0 with errors",
      "Realestatedetails": "Real estate details",
      "Date": "Date",
      "ChangeMade": "Change Made",
      "Amendmnt": "Amendmant",
      "tenantcharges": "Tenant charge",
      "ownercharges": "Owner charge",
      "Disablefeature": "Disable feature:billing",
      "Disablefeaturehistory": "Disable feature:billing history",
      "Disableactiveconfirmation": "Disable active confirmation",
      "Dontshowfuturecharges": "Don't show future charges",
      "Emailadministrators": "Email administrators when updating chart of accounts",
      "Enabledailycashier": "Enable daily cashier",
      "Activateoption": "Activate option to select the incidence and withholding of taxes in the simple national",
      "CancelBoleto": "Cancel Boleto",
      "CancelRegenrateBoleto": "Cancel and Regenrate Boleto",
      "CancelRegenrate": "Change due date",
      "MarkAsPaid": "Mark As Paid",
      "CheckPaymentStatus": "Check Payment Status",
      "Showlist": "Show list",
      "EmailDownloads": "Click to download and see content",
      "Addcontact": "Add contact",
      "updatecontact": "Update contact",
      "Maximummonths": "Maximum 30 months allowed",
      "pendingitems": "Pending items",
      "awaitingapproval": "Awaiting approval",
      "Editbilling": "Edit billing",
      "enter": "enter",
      "paid": "Paid",
      "Senddocument": "Send document via email",
      "DownloadableFileLinkBelow": "Please Find The Downloadable File Link Below",
      "DownloadFile": "Click to download file",
      "ativarDesativar": "Activate/Deactivate Service",
      "guaranteed": "Guaranteed",
      "In": "In",
      "Documentlist": "Document list",
      "Residential": "Residential",
      "Non-residential": "Non-residential",
      "Commercial": "Commercial",
      "Industry": "Industry",
      "Season": "Season",
      "Mixed": "Mixed",
      "By order": "By order",
      "Unpaid": "Unpaid",
      "Paid": "Paid",
      "Pendentes": "Pendings",
      "Managedproperties": "Managed properties",
      "Securitydeposit": "Security deposit",
      "noCalculateAdminFee": `As the contract settings allow the taxation issue by the system, to select the administration fee, you must connect the CNAE code to the service "Taxa de administração."`,
      "Contractalerts": "Contract needs to be readjusted.",
      "toreadjust": "to see the contracts to readjust.",
      "expired": "Expired",
      "editrecurringexpense": "Edit recurring expense",
      "Guaranteeproductincontracts": "Guarantee product in contracts with guaranteed transfer",
      "ownerEmailnotEqualAgencyEmail": "Owner email cannot be same as Agency email.",
      "yourproperty": "Your property",
      "broker": "broker",
      "inspector": "Inspector",
      "supplier": "Supplier",
      "year": "year",
      "years": "years",
      "ago": "ago",
      "announced": "Announce",
    "CancelInsurance" : "Cancel Insurance",
      "defaultPaymentMethod1": {
        "chequedeposit": "Cheque deposit",
        "cashdeposit": "Cash deposit",
        "pickupchequeonsite": "Pick up Cheque on agency",
        "withdrawmoneyonthespot": "Pick up cash on agency",
        "banktransfer": "Bank transfer",
        "docted": "Doc/Ted",
        "pixboleto": "Pix/Boleto",

      },
      "propertyTypeData": {
        "Casa": "House",
        "Apartamento": "Apartment",
        "ApartamentoDuplex": "Duplex Apartment",
        "ApartamentoTriplex": "Triplex Apartment",
        "Áreacomum": "Common Area",
        "Andarcorporativo": "Corporate Floor",
        "Bangalo": "Bungalow",
        "Barracao": "Shed",
        "BoxGaragem": "Garage Box",
        "Casacomercial": "Commercial House",
        "Casaemcondominio": "Condominium House",
        "CasaAssobradada": "Two-story House",
        "Cobertura": "Penthouse",
        "Chacara": "Farm",
        "Conjunto": "Set",
        "Edicula": "Outbuilding",
        "Escritorio": "Office",
        "Fazenda": "Farmhouse",
        "Flat": "Flat",
        "Galpao": "Warehouse",
        "Garagem": "Garage",
        "Garden": "Garden",
        "Haras": "Haras",
        "Hotel": "Hotel",
        "Ilha": "Island",
        "Kitnet": "Kitnet",
        "Laje": "Slab",
        "Loft": "Loft",
        "Loja": "Store",
        "Pavilhao": "Pavilion",
        "Penthouse": "Penthouse",
        "Predio": "Building",
        "Pousada": "Inn",
        "Ponto": "Point",
        "Quiosque": "Kiosk",
        "Rancho": "Ranch",
        "Resort": "Resort",
        "Salao": "Hall",
        "Salacomercial": "Commercial Room",
        "Sitio": "Farm",
        "Sobrado": "Two-story House",
        "Studio": "Studio",
        "Terreno": "Land",
        "Village": "Village",
        "Consultorio": "Office",
        "Outro": "Other",
        "Realstateagency": "Real state agency",
        "Thirdpart": "Third part"
      },
      "envelop": {
        "Person's": "Person's",
        "E-mail": "E-mail",
        "Type": "Type",
        "Witnesses": "Witnesses",
        "Insertrealestatesignature": "Insert real estate signature",
        "Setorderforsendinge-mailforelectronicsignatures": "Set order for sending e-mail for electronic signatures",
        "signature": "signature",
        "1st": "1st",
        "2nd": "2nd",
        "3rd": "3rd",
        "4th": "4th",
        "Newwitness": "New witness",
        "responsible": "Responsible",
        "templates": "Templates",
        "subcpf": "subcpf",
        "ownersRealEstate": "Owners and/or Real Estate",
        "witnesses": "witnesses",
        "tenants": "Tenants"

      },
      "TransferPage": {
        "Batchprocess": "Batch process",
        "Accesscurrentaccounttransfer": "Access current account transfer",
        "Transfersmade": "Transfers made",
        "Transactionsmade": "Transactions made",
        "Guaranteedandrealizedtransfer": "Guaranteed and realized transfer",
        "Transferstobecarriedout": "Transfers to be carried out",
        "Ownersextract": "Owners extract",
        "Simplifiedownerstatement": "Simplified owner statement",
        "Transfersbybank": "Transfers by bank",
        "Transferinvoices": "Transfer invoices",
        "Filterby": "Filter by",
        "banktransactions": "Bank transactions",
        "receipts": "Receipts",
        "payments": "Payments",
        "Withpaidcharges": "With paid charges",
        "Withchargessettledbetween": "With charges settled between",
        "Duetotheduedatebetween": "Due to due date between",
        "Pendingfrom": "Pending from",
        "Transfertocarryout": "Transfer to carry out",
        "tocarryout": "to carry out",
        "performed": "performed",
        "Invoicestobegenerated": "Invoices to be generated",
        "Generateinvoices": "Generate invoices",
        "Collection": "Collection",
        "collection": "collection",
        "Forward": "Forward",
        "dueon": "due on",
        "generatedintheamountof": "generated in the amount of",
        "on": "on",
        "transfertobecarriedout": "transfer to be carried out",
        "frompreviousmonths": "from previous months",
        "Simulatetransfer": "Simulate transfer",
        "select": "{{value}} selected",
        "selects": "{{value}} selected",
        "Groupedtranfer": "Grouped tranfer",
        "Changeexpiration": "Change expiration",
        "Withoutcontractstatus": "Without contract status",
        "Ofthebranches": "Of the branches",
        "OftheManagers": "Of the Managers",
        "Billingdetails": "Billingdetails",
        "to": "to",
        "Transferdetails": "Transfer details",
        "TXADM": "TX ADM",
        "Admfee": "Adm fee",
        "Externalobservation": "External observation",
        "Printstatement": "Print statement",
        "Launchinfinancial": "Launch in financial",
        "Changematurity": "Change maturity",
        "Sendbyemail": "Send by email",
        "Undotranfer": "Undo tranfer",
        "UNDO": "Undo",
        "Fromtheowners": "From the owners",
        "Withouttheowners": "Without the owners",
        "Withoutfinancialstatusofthecontract": "Without financial status of the contract",
        "Bynumberofproeprtiesperowner": "By number of proeprties per owner",
        "startDate": "Start date",
        "endDate": "End Date",
        "month": "Month",
        "liquidatedatebetween" : "Liquidate Date Between",
        "crediteddatebetween" : "Credited Date Between",
        "canceldatebetween" : "Cancelled Date Between",
        "generatedatebetween" : "Generated Date Between",
      },
      "ReceiveandReceived": {
        "ticketInformation": "Ticket information",
        "Questionsaboutcreditcarderrorcodes": "Questions about credit card error codes",
        "Clickhere": "Click here",
        "orHelpintheupperrightcornerandlearnmore": "or Help in the upper right corner and learn more",
        "chargesfrom": "charges from",
        "customersinthetotalamountof": "customers in the total amount of",
        "totalamountof": "total amount of",
        "Invalidate": "Invalidate",
        "Changeinterestpenaltyanddiscount": "Change interest, penalty and discount",
        "Returnagreedinterest": "Return agreed interest",
        "Returnagreedfine": "Return agreed fine",
        "Backagreeddiscount": "Back agreed discount",
        "Forcecreditcardprocessing": "Force credit card processing",
        "Markforshipment": "Mark for shipment",
        "Unmarkfromshipment": "Unmark from shipment",
        "Changebankaccount": "Change bank account",
        "Changecompetency": "Change competency",
        "Reasonforinvalidation": "Reason for invalidation",
        "usetheoptiononly": "Use this option only in cases that you do not want to generate charges for the month that you are invalidating. After invalidating a charge you will not be able to generate it again",
        "Improperbilling": "Improper billing",
        "Billingreplaced": "Billing replaced",
        "Deletependingexpensesforthirdparty": "Delete pending expenses for third party",
        "Changerates": "Change rates",
        "Chargesdue": "Bills receivable",
        "Chargesduetexts": "received charges",
        "Chargesduetext": "received charge",
        "Shipping": "Shipping",
        "Markallforshipment": "Mark all for shipment",
        "Deselectallfromshipment": "Deselect all from shipment",
        "Help": "Help",
        "Receivable": "Receivable",
        "Creditcardmistakes": "Credit card mistakes",
        "Waysofreceiving": "Ways of receiving",
        "Postdatedchecks": "Post-dated checks",
        "Bankholidays": "Bank holidays",
        "Annualdebtdischargestatement": "Annual debt discharge statement",
        "Ticket": "Ticket",
        "Meat": "Meat",
        "Customers": "Customers",
        "Thereare": "There are",
        "charges": "charges",
        "tobemarkedforshipment": "to be marked for shipment",
        "Nobillettobeunmarkedfromtheshipment": "No billet to be unmarked from the shipment",
        "Billingpaymentmethod": "Billing payment method",
        "Customerpaymentmethod": "Customer payment method",
        "Markedforshipment": "Marked for shipment",
        "Deliverymethods": "Delivery methods",
        "Groundsforinvalidation": "Grounds for invalidation",
        "Typeofcreditcardfailure": "Type of credit card failure",
        "Unitaryvalue": "Unitary value",
        "New": "New",
        "Youmayprefertopostcharges": "You may prefer to post charges more efficiently using the charge generation process",
        "Todothisaddrecurringandone": "To do this, add recurring and/or one-time revenues and invoice. Thus, charges and nfs will be issued automatically according to the rules defined for each customer",
        "Productsorservices": "Products or services",
        "Totalvalue": "Total value",
        "Changerateforthischarge": "Change rate for this charge",
        "Summaryof": "Summary of",
        "Today": "Today",
        "Yesterday": "Yesterday",
        "Tomorrow": "Tomorrow",
        "viawithexpired.chang": "2. via with expired. changed for the day of viewing, link can be forwarded to the customer",
        "info": "info",
        "Latefor": "Due on ",
        "Remain": "Due today",
        "remain": "remain",
        "Billing": "Billing",
        "NochargesFound": "No charges Found",
        "Saleoff": "Sale off",
        "saleoff": "Sale off",
        "Amountpaid": "Amount paid",
        "Debitbankfee": "Debit bank fee",
        "Legaladditions": "Legal additions",
        "Additions": "Additions",
        "Checknumber": "Check number",
        "Banknumber": "Bank number",
        "Goodfor": "Good for",
        "Summary": "Summary",
        "Issued": "Issued",
        "Fine": "Fine",
        "Interest": "Interest",
        "Due": "Due",
        "Paid": "Paid",
        "Credited": "Credited",
        "BackAddressingRequires": "Back Addressing (Requires capable printer)",
        "Morelinesrequires": "More lines (requires Legal II paper)",
        "RegisterinbillingCRM": "Register in billing CRM",
        "Expirationwillonlychangeon": "Expiration will only change on this printout. Interest and fines will be added to the original amount",
        "Marked": "Marked",
        "collectionfrom": "collection from",
        "customerinthetotalamountof": "customer in the total amount of",
        "Invalidation": "Invalidation",
        "Reverse": "Reverse",
        "Receipt": "Receipt",
        "Issuereceipt": "Issue receipt",
        "Chargeswillbecalculatedforeach": "Charges will be calculated for each slip, according to individual settlement",
        "Reversecharge": "Reverse charge",
        "Doyoureallywanttoreversethecharges": "Do you really want to reverse the charges",
        "Settledon": "Settled on",
        "delay": "delay",
        "with": "With",
        "creditedon": "credited on",
        "sara": "sara",
        "Manual": "Manual",
        "dinadvance": "d in advance",
        "dlate": "d late",
        "Paidontheday": "Paid on the day",
        "liquidateas": "Settle in batch",
        "Duetomorrow": "Due tomorrow",
        "Overdue": "Overdue for",
        "Incomereceived": "Income received"
      },
      "Sweetalert": {
        "emailProgress": "Email Sending Progress",
        "Success": "Success!",
        "Info": "Info!",
        "ConfirmButtonText": "Ok",
        "permissionChange": "Permission successfully updated!",
        "insertRecord": "Your details have been saved successfully.",
        "cannotInsert": "Unable to save your details. Please try again later.",
        "cannotupdated": "Unable to update your details. Please try again later.",
        "RecordUpdated": "The selected item has been updated successfully.!",
        "deleteRecord": "Your details have been delete successfully.",
        "deleteFailed": "Unable to delete your details. Please try again later.",
        "deletingMainAc": "Are you certain you wish to delete your main account?",
        "activeBank": "Kindly enable this bank account.",
        "deleteBranch": "Do you really want to delete the branch?",
        "deleteService": "Do you really want to delete this service?",
        "updatefooter": "Would you like to confirm the updates?",
        "changeMainAccount": "Do you really want to switch the main account?",
        "deleteMainAccount": "Do you really want to remove the main account?",
        "pleaseSelectBank": "Ensure you pick at least one bank.",
        "changeIt": "Change it",
        "statusChange": "Status change successfully.",
        "statusUpdate": "Status update successfully.",
        "newExpence": "New expence created Failed!",
        "deleteBankAccount": "Do you really want to remove the bank account?",
        "deletelogo": "Are you sure to delete logo",
        "sendEmailDocument": "Are you sure you want to send document email",
        "yesDeleteMainAccount": "Yes Delete Main Account",
        "yesDeleteIt": "Yes, Delete It",
        "Error": "Error!",
        "somethingWent": 'Something went wrong!',
        "5mb": 'File size cannot be greater than',
        "contractSuspended": 'Contract is Suspended!',
        "pleaseSelect": "Select at least one user, please.",
        "serverError": "Server error encountered. Please try your request again later. If the issue persists, contact support for assistance.",
        "Warning": "Warning",
        "selectedAccount": "Selected account is linked with ways of receiving. This action cannot be performed",
        "uniqueNumber": "The account number already exists. Please enter a different account number.",
        "healthInsurance": "Health insurance",
        "selectHealthInsurance": "Please select health insurance.",
        "negativeNumberNotAllowed": "Negative number not allowed",
        "addedService": "Success",
        "passFileData": "Please Select Document Share Type or Download Link Coming Null",
        "serviceWrongCategory": "It's not possible to use this category for new services",

        "newService": "Your details have been saved successfully..",
        "selectServices": "Please pick one or more services",
        "deleteTaxation": "Do you really want to delete this taxation?",
        "wrongpassword": "Incorrect password, please try again.",
        "wrongemail": "Invalid email. Please try again.",
        "successget": "Your details have been saved and updated successfully",
        "successfailed": "Failed to save or update your details.",
        "updatesuccess": "Updated successfully",
        "UpdateSuccess": "Updated successfully",
        "updatefailed": "Failed to update your details",
        "deletesuccess": "Your details have been deleted successfully",
        "deletefailed": "Failed to deleted your details",

        "alreadybillinggenerate": "Already billing generate on this records!",
        "Pleasecheckedthe": "Please checked the checkbox.",
        "pleasecheckone": "please check atleast one checkbox",
        "Areyousureyouwantto": "Are you sure you want to cancel the suspension of all charges for this contract?",
        "contractPending": "Contract is not active, please activate your contract first.",
        "terminationReversed": "Are you sure want to cancel termination?",
        "Generatedfilekeyarehandovered": "Generated file key are handovered",
        "Generatemeat": "Generate charges successfully!",
        "Youwontbeabletorevert": "You won't be able to revert this!",
        "Yourimaginaryrecordissafe": "Your imaginary record is safe",
        "cancelled": "Cancelled",
        "Areyousuretodeleteitem": "Are you sure to delete item",
        "Areyousurewanttogenerateit": "Are you sure want to generate it",
        "Youwontabletomodifyitlater": "You won't able to modify it later",
        "Areyousure": "Are you sure?",
        "Update": "Update!",
        "Delete": "Delete!",
        "PleaseSelectOwners": "Please select owner's",
        "Itwasnotpossibletoperformth": "It was not possible to perform the deletion because there is a property linked to the administrator",
        "PleaseSelectUser": "Please Select User's",
        "Areyousuretoenableproperties": "Are you sure to enable properties",
        "PropertiesEnabled": "Properties Enabled",
        "OperationFailed": "Operation Failed",
        "Areyousuretodisableproperties": "Are you sure to disable properties",
        "PropertiesDisabled": "Properties Disabled",
        "Pleaseselectatleasttransaction": "Please select atleast transaction",
        "Areyousurewantto": "Are you sure want to",
        "selectedrecords": "selected record's?",
        "Selectedrecords": "Selected record's?",
        "Pleaseselectrecords": "Please select record's",
        "Emptyfilefield": "Empty file field",
        "Filerequired": "File required",
        "sendsuccessfullytoselected": "send successfully to selected record's",
        "Areyousurewanttosendsms": "Are you sure want to send sms?",
        "SMSsendsuccessfully": "SMS send successfully",
        "Areyousurewanttoremoveprotest": "Are you sure want to remove protest",
        "Accesscustomercontacthistory": "Access customer contact history",
        "Removeprotest": "Remove protest",
        "Allowaccess": "Allow access",
        "Oops": "Oops...",
        "Pleaseselectsomeusers": "Please select some user's",
        "PaymentispaidsuccessfullyonAarin": "Payment is paid successfully on Aarin",
        "PaymentiscanceledonAarin": "Payment is canceled successfully on Aarin",
        "PaymentisnotCompleted": "Payment is not Completed",
        "Paymentmarkedpaidsuccessfully": "Payment marked paid successfully",
        "MessageUpdateSuccessfully": "Message Update Successfully",
        "FailedtoUpdateAddMessage": "Failed to Update/Add Message!!!",
        'Descriptionrequired': 'Description required.',
        "Addothernumber": "Add other number",
        "AccountNoNeedtobeunique": "Account No Need to be unique. Please enter unique number",
        "Successfully": "Successfully",
        "recordsinsertupdatedand": "records insert/updated ",
        "failedtoinsertupdate": "failed to insert/update",
        "Successfullyupdate": "Successfully update",
        "Yournewlogoupdatedsavedsuccessfully": "Your new logo updated saved successfully",
        "WantToSave": "Want To Save?",
        "Areyousuretosaveit": "Are you sure to save it",
        "Namemusthavemorethan2characters": "Name must have more than 2 characters",
        "Sourceuserisrequired": "Source user is required",
        "Pleaseselectgroup": "Please select group",
        "allownumber": "Only one zero is allowed.",
        "validnumber": "Characters are not allowed.",
        "mailsent": "Mail sent successfully",
        "updateService": "update your service code",
        "alreadyuse": "This selected item is used somewhere else, this cannot be deleted",
        "invaliduserpassword": "Invalid username or password. Please check your credentials and try again",
        "userDeactiveErrorOccured": "User is deactivated in the system. Please check with your agency for assistance",
        "permissionErrorOccured": "You don't have permission to log in to the system. Please check with your agency for assistance",
        "recoveryFailedPassword": "Sorry, we couldn't reset your password.",
        "passwordconform": "Password and Confirm Password mismatch!",
        "unableotchangeorupdate": "Sorry, we are currently unable to change or update your password. Please try again later or contact support for assistance.",
        "agencynotfound": "No agency found with the provided details. Please check your search criteria and try again.",
        "footerupdate": "Your footer work saved successfully.",
        "updateplugnota": "You won't to connect with Plugnota ?",
        "chargereverse": "Charge reverse successfully!",
        "updateCertificate": "Please upload the certificate then register company",
        "changepass": "Are you sure want to send password change request",
        "contractupdate": "Record  has been updated successfully!",
        "generateplugnota": "Generate plugnotas",
        "emailsMessage": "Please enter the email message",
        "getrecord": "Record(s) fetched successfully!",
        "duplicate": "Duplicate entry not allowed!",
        "Recordinserted": "Record inserted successfully!",
        "Recordupdated": "Record updated successfully!",
        "changepassword": "Your password has been changed.",
        "deletecontact": "Do you really want to delete the contact?",
        "Ownerarerequired": "Owner are required.",
        "Tenantsarerequired": "Tenants are required.",
        "editbilling": "Billing editing must be done via the contract screen",
        "emailsRequire": "Email is require",
        "Thisidentifier": "Already used identifier",
        "NameReferencealreadyexists": "The cashier account with same name already exists. Please enter a different cashier account.",
        "accountnumberalreadyexists": "The account number already exists with same the Bank. Please enter a different account number.",
        "OpeningHours": "Opening hours is require",
        "unqueEmails": "The email already exists. Please enter a different email.",
        "SuccessfullyComplete": "Successfully Complete",
        "Youwonundo": "You won't be able to undo this!",
        "Yessuspended": "Yes, suspended it!",
        "lesstotalpercentage": "The total percentage is less than 100. Please check the percentage",
        "moretotalpercentage": "The total percentage is more than 100. Please check the percentage",
      },
      "Dashboard": {
        "Internal": "Internal",
        "Dashboard": "Dashboard",
        "ActiveContracts": " Active contracts",
        "Contractswithoutwarranty": "Contracts without warranty",
        "Contractswithoutinsurance": "Contracts without insurance",
        "Wanttoincreaseyourincome": "Want to increase your income",
        "Increaseyourrevenuebyupto": "Increase your revenue by up to",

        "kenloInsurance1T1": "Your registration is under review",
        "kenloInsurance2T1": "In migration to Kenlo Insurance",
        "kenloInsurance3T1": "Register on Kenlo Insurance",
        "kenloInsurance4T1": "Register on Kenlo Insurance",
        "kenloInsurance5T1": "Enjoy our partnership!",

        "kenloInsurance1T2": "Upon approval, Kenlo Insurance benefits will be available to you within up to 2 business days.",
        "kenloInsurance2T2": "You are registered with another brokerage, but we will resolve this for you within 3 business days.",
        "kenloInsurance3T2": "We detected that you are blocked at our partner insurance company. We are working on a solution.",
        "kenloInsurance4T2": "You are blocked at the partner insurance company. Click to better understand the situation.",
        "kenloInsurance5T2": "Offer Kenlo Insurance to your clients and earn commissions for every contract completed.",

        "registerKenloinsur": "Register on Kenlo Insurance. Enjoy benefits such as real-time quotes and commissions for each contract completed.",
        "KenloInsurancePartnership": "Kenlo Insurance partnership",
        "KenloInsurancePartnershipContent": "While your registration is under review, here are some questions and answers about Kenlo Insurance.",
        "HowDoesCommissionRealEstateAgencyWork": "How does the commission for the real estate agency work?",
        "HowDoesCommissionRealEstateAgencyWorkContent": "The real estate agency earns a commission for each active insurance policy, as per the commercial agreement.",
        "HowDoesCommissionRealEstateAgencyWorkContent2": "After payment to the insurance company, within 5 days, the commission is deposited into the account provided by the real estate agency in the registration (for amounts over R$99). Upon receiving the payment, the real estate agency issues an Electronic Invoice to the insurance company within 10 days.",
        "HowDoesPaymentInsurancePremiums": "How does the payment of the insurance premiums work?",
        "HowDoesPaymentInsurancePremiumsContent": "The insurance premium is automatically included in the rental invoice. This way, the real estate agency receives the amounts along with the rent payment and forwards them to the insurance company on a monthly basis through a consolidated invoice for all active insurance policies.",
        "HowDoReceiveInsurancePolicy": "How do I receive the insurance policy?",
        "HowDoReceiveInsurancePolicyContent": "After purchasing the insurance, the policy is sent to the tenant via email, with a copy to the real estate agency. The delivery takes approximately 30 days.",
        "WhatCoveragePackagesKenloOffer": "What coverage packages does Kenlo offer?",
        "WhatCoveragePackagesKenloOfferContent": "Kenlo Insurance offers two coverage package options.",
        "BasicPackage": "Basic Package",
        "HomeWithoutAssistance": "Home Without Assistance",
        "BasicPackageContent1": "Fire, lightning strike, explosion, smoke, and aircraft impact.",
        "BasicPackageContent2": "Storm, hurricane, cyclone, tornado, and hail.",
        "PackageWithAssistance": "Package with Assistance",
        "HomePlus": "Home Plus",
        "PackageWithAssistanceContent1": "Fire, lightning strike, explosion, smoke, and aircraft impact.",
        "PackageWithAssistanceContent2": "Storm, hurricane, cyclone, tornado, and hail.",
        "PackageWithAssistanceContent3": "Electrical damage.",
        "PackageWithAssistanceContent4": "Theft of property with forced entry and robbery.",
        "PackageWithAssistanceContent5": "Assistance: locksmith, unclogging, electrician, plumber, moving and storage, glazier.",

        "withKenloWarrantyandKenloInsurance": " with Kenlo Warranty and Kenlo Insurance",
        "Pendencies": "Pendencies",
        "Contracts": "Contracts",
        "Warranties": "Warranties",
        "Insurances": "Insurances",
        "Expenses": "Expenses",
        "Maturity": "Maturity",
        "Category": "Category",
        "Contract": "Contract",
        "Renter": "Renter",
        "Owner": "Owner",
        "Formofpayment": "Form of payment",
        "Responsible": "Responsible",
        "Rent-amfree": "Rent - adm fee",
        "Seeall": "See all",
        "Kenlo": "Kenlo",
        "Charges": "Charges",
        "Overdue": "Overdue",
        "Duesoon": "Due soon",
        "Received": "Received",
        "Nextmaturities": "Next maturities",
        "Current": "Current month",
        "Next": "Next",
        "months": "months",
        "Income": "Income",
        "Foreseen": "Foreseen",
        "Averageadmfee": "Average admin rate",
        "Totaladmfee": "Total admin rate",
        "Mainsources": "Largest sources",
        "Administrationfee": "Administration fee",
        "Salescommission": "Sales commission",
        "Insurancecommission": "Insurance commission",
        "Agreement": "Agreement",
        "Transfers": "Transfers",
        "Made": "made",
        "value": "Value",
        "Tenant": "Tenant",
        "amount": "Amount",
        "duetoday": "To be done today",
        "Payabletoday": "Payable today",
        "duethisweek": "Due this week",
        "Third": "Third",
        "month": "month"
      },
      "checklistother": {
        "newchecklist": "New Checklist",
        "newchecklistItems": "New Checklist Items",
        "howtouse": "How to use",
        "notificationperiod": "Notification period",
        "items": "Items",
        "description": "Description",
        "responsible": "Responsible",
        "notify": "Notify",
        "editemailtemplate": "Edit email template",
        "observation": "Observation",
        "mandatoryProof": "Mandatory Proof",
        "showinOwliApp": "Show in Owli App",
        "deliverBy": "Deliver By",
      },
      "comparetolastmonth": {
        "StatementofIncomeandExpenses": "Statement of Income and Expenses",
        "Toview": "To view",
        "Export": "Export",
        "CSV": "CSV",
        "excel": "excel",
        "Currentmonth": "Current month",
        "Variation": "  Variation",
        "records": "records",
        "Previousbalance": "Previous balance",
        "Newcostcenter": "New cost center",
        "ledgeraccount": "Ledger account",
        "Setasdefaultcostcenter": "Set as default cost center",
        "sendfile": "Send file",
        "total": "Total"
      },
      "costcenter": {
        "Costcenter": "Cost center",
        "Newcostcenter": "New cost center",
        "LedgerAccount": "Ledger Account",
        "Setasdefaultcostcenter": "Set as default cost center",
        "Sendfile": "Send file"
      },
      "terminatelist": {
        "filenotavailable": "File Not Available",
        "Move-out": "Move-out",
        "moveoutdate": "Move-out date",
        "details": "details",
        "Completetermination": "Complete termination",
        "Processstart": "Process start",
        "date": "date",
        "Lastmodified": "Last modified",
        "Reason": "Reason",
        "Propertyunfitforobtainingapermitand/orfiredepartmentclearancecertificate": "Property unfit for obtaining a permit and/or fire department clearance certificate",
        "Terminationchecklist": "Termination checklist",
        "outof": "out of",
        "itemscompleted": "items completed",
        "exitediting": "Save & Exit",
        "item": 'Item',
        "limit": "Limit",
        "notice": "notice",
        "Propertyactions": "Property actions",
        "Schedulemove-outinspection": "Schedule move-out inspection",
        "Concludemove-outinspection": "Conclude move-out inspection",
        "Checkpropertytaxandcondos": "Check property tax and condos",
        "Cancelinsuranceandguarantee": "Cancel insurance and guarantee",
        "Modifyrecurringexpenses": "Modify recurring expenses",
        "Handoverofpropertykeys": "Handover of property keys",
        "Terminationcalculation": "Termination calculation",
        "Generateandsenddocuments": "Generate and send documents",
        "Uponconfirmingcompletionthes": 'Upon confirming completion, the status of the property will be changed to "terminated". You can edit and access the history of the termination checklist',
        "Confirmandissueagreement": "Confirm and issue agreement",
        "Responsibleparty'sname": "Responsible party's name",
        "Partyresponsiblefor": "Party responsible for",
        "electricitypayment": "electricity payment",
        "waterpayment": "water payment",
        "Thepropertylistinghasbeenreactivatedonthewebsite": "The property listing has been reactivated on the website",
        "Attachfile": "Attach file",
        "SupportedformatsPDFandPNG": "Supported formats: PDF and PNG",
        "Savedraft": "Save draft",
        "Saveandcomplete": "Save and complete",
        "Thistenanthas": "This tenant has",
        "Check": "Check",
        "pendingcharges": "pending charges",
        "Initialdata": "Initial data",
        "Terminationforecast": "Termination forecast",
        "Includemove-outdateintheterminationdocument": "Include move-out date in the termination document",
        "Reasonfortermination": "Reason for termination",
        "Notifypropertyowner": "Notify property owner",


        "Rented_outside_the_real_estate_agency": "Rented outside the real estate agency",
        "Rented_another_property_from_the_real_estate_agency": "Rented another property from the real estate agency",
        "Increased_family_birth_of_children": "Increased family (birth of children)",
        "Bought_own_property": "Bought own property",
        "Illness_inability_to_live_alone": "Illness, inability to live alone",
        "Termination_of_contract": "Termination of contract",
        "Death_of_a_family_member_who_lived_in_the_same_property": "Death of a family member who lived in the same property",
        "Death_of_the_guarantor_with_the_impossibility_of_another_guarantee": "Death of the guarantor with the impossibility of another guarantee",
        "Death_of_the_tenant_himself": "Death of the tenant himself",
        "Closed_the_business": "Closed the business",
        "Small_property": "Small property",
        "Property_without_conditions_for_issuing_a_permit_and/or_certificate_of_firefighter_regularity": "Property without conditions for issuing a permit and/or certificate of firefighter regularity",
        "Tenant_moved_to_another_city": "Tenant moved to another city",
        "Structural_problem": "Structural problem",
        "Financial_problems": "Financial problems",
        "Problems_in_the_neighborhood": "Problems in the neighborhood",
        "Owner_requested_property": "Owner requested property",
        "Tenant_transfer": "Tenant transfer",
        "Transfer_of_Ownership": "Transfer of Ownership",
        "Rent_value/contract_fees": "Rent value/contract fees",
        "Withdrawal": "Withdrawal",
        "Others": "Others"
      },
      "insurelist": {
        "newinsurers": "New insurers",
        "editinsurers": "Edit insurer",
        "closeAll": "Close all",
        "aboutyou": "About you",
        "aboutReal": "About the real estate agency",
        "aboutRealAddress": "Real estate agency's address",
        "fiscalInfo": "Fiscal information",
        "bankData": "Bank account data",
        "readaccept": "I have read and accept the",
        "submitRegistration": "Submit registration",
        "responsibleParty": "Responsible Party",
        "inpartnershipwithTokio": "In partnership with Tokio Marine, Kenlo Insurance offers you many advantages with complete freedom.",
        "commissionsforeveryfire": " Commissions for every fire insurance contract completed.",
        "noobligation": "No obligation to use.",
        "realtimequotesduring": "Real-time quotes during new lease contract registration.",
        "automaticcontractapproval": "Automatic contract approval without bureaucracy.",
        "automaticbilling": "Automatic billing for tenants.",
        "securityfrom": "Security from two well-established companies in the market."
      },
      "Links": {
        "dashboard": "Dashboard",
        "contracts": "Contracts",
        "readjustmentIndexes": "Readjustment indexes",
        "properties": "Properties",
        "customersAndEmployees": "Customers and employees",
        "income": "Income",
        "generateCharges": "Generate charges",
        "toReceive": "To receive",
        "invoices": "Invoices",
        "bankReturn": "Bank return",
        "received": "Received",
        "delinquency": "Delinquency",
        "agreements": "Agreements",
        "billingRule": "Billing rule",
        "exit": "Exit",
        "prints": "Prints",
        "sentMessages": "Sent messages",
        "sentEmails": "Sent e-mails",
        "alterations": "Alterations",
        "history": "History",
        "notifications": "Notifications",
        "services": "Services",
        "dimob": "Dimob",
        "reports": "Reports",
        "accountsPlan": "Accounts plan",
        "taxation": "Taxation",
        "formsOfReceipt": "Forms of receipt",
        "bankAccounts": "Bank accounts",
        "branches": "Branches",
        "companyData": "Company data",
        "company": "Company",
        "kenloAppClients": "Kenlo App clients",
        "myAccount": "My account",
        "users": "Users",
        "expenses": "Expenses",
        "transfers": "Transfers",
        "performedTransfers": "Performed transfers",
        "toBePaid": "To be paid",
        "paid": "Paid",
        "suppliers": "Suppliers",
        "bankTransactions": "Bank transactions",
        "bankConcilliation": "Bank concilliation",
        "diagnoses": "Diagnoses",
        "compareToLastMonth": "Compare to last month",
        "costCenter": "Cost center",
        "financial": "Financial",
        "Inspections": "Inspections",
        "Maintenance": "Maintenance",
        "Nonpayment": "Nonpayment",
        "support": "Support"
      },
      "customerAndEmployees": {
        "withdrawMoney": "Pick up cash on agency",
        "pickUpCheque": "Pick up cheque on agency ",
        "chequeDeposit": "Cheque deposit",
        "Cheque": "Check",
        "pix": "Pix",
        "boleto": "Boleto",
        "docTed": "TED",
        "bankTransfer": "Bank transfer",
        "cashDeposit": "Cash deposit",
        "creditcard": "Credit card",
        "DebitCard": "Debit card",
        "Transferbanking": "Transfer. banking",
        "Automatictransfer": "Automatic transfer",
        "with": "With",
        "others": "Others",
        "money": "money",
        "active": "Active",
        "legal": "Legal",
        "legals": "Legals",
        "owners": "Owners",
        "newOwner": "New Owner",
        "tenants": "Tenants",
        "newTenant": "New Tenant",
        "guarantors": "Guarantors",
        "updateowner": "Update owner",
        "updateTenant": "Update tenant",
        "updateguarantor": "Update guarantor",
        "updatebroker": "Update broker",
        "updatesupplier": "Update supplier",
        "updateinspector": "Update inspector",
        "and": "and",
        "newGuarantor": "New Guarantor",
        "brokers": "Brokers",
        "newBroker": "New Broker",
        "providers": "Providers",
        "consultSupplier": "New Supplier",
        "inspectors": "Inspectors",
        "newInspector": "New Inspector",
        "newSurveyor": "New surveyor",
        "newUser": "New User",
        "whatlooking": "What are you looking for?",
        "clientsAndCollaborators": "Clients and collaborators",
        "guarantorsReport": "Guarantors report",
        "notifyActiveContracts": "Notify for active contracts",
        "configureOwnerArea": "Configure owner area",
        "currentAccountStatement": "Current account statement",
        "ownersReport": "Owners report",
        "detailedOwnersReport": "Detailed Owners Report",
        "incomeGroupedOwner": "Income grouped by owner",
        "extractIncomeTaxReturn": "Extract for income tax return",
        "configureTenantArea": "Configure tenant area",
        "tenantReport": "Tenant report",
        "detailedTenantReport": "Detailed tenant report",
        "notifyWithoutActiveContracts": "Notify without active contracts",
        "newSuppliers": "New suppliers",
        "suppliers": "Suppliers",
        "tenantArea": "Tenant area",
        "BranchCode": "Branch Code",
        "Inthenameof": "In the name of",
        "Newownerorbeneficiary": "New owner or beneficiary",
        "ormoreproperties": "or more properties",
        "Only": "Only",
        "propertie": "property",
        "Seehowitturnedout": "See how it turned out",
        "Ownersareaamenites": "Owner's area amenites",
        "Disableonlinetransferextracts": "Disable online transfer extracts",
        "Disablefilesonline": "Disable files online",
        "Disableonlinerealestate": "Disable online real estate",
        "DeactivateextractforIRdeclaration": "Deactivate extract for IR declaration",
        "Deactivateonlineregistrationupdate": "Deactivate online registration update",
        "Backgroundimage": "Background image",
        "Uploadnew": "Upload new",
        "Whatshouldthebackgroundimagelooklike": "What should the background image look like",
        "Emailadministratorswhenupdatingchartofaccounts": "Send email to administrators when updating chart of accounts",
        "Enabledailycashier": "Enable daily cash",
        "Activateoptiontoselecttheinci": "Activate the option to select the incidence and withholding of taxes in the national simple",
        "sendEmailActiveContracts": "Send e-mail to active contracts",
        "sendEmailWithoutActiveContracts": "Send e-mail to owners without active contracts",
        "sendEmailWithoutTenantActiveContracts": "Send e-mail to tenants without active contracts",
        "Otherpaymentmethods": "Other payment methods",
        "splitpaymentKenloPay": "Split payment Kenlo Pay"
      },
      "property": {
        "historic": "Historic",
        "newHistory": "New history",
        "most": "Most",
        "data": "Data",
        "description": "Description",
        "record": "record",
        "records": "records",
        "administrators": "Administrator",
        "newAdministrator": "New administrator",
        "condominium": "Condominium",
        "trustee": "Trustee",
        "condos": "Condos",
        "newCondos": "New condominium",
        "managers": "Managers",
        "newManager": "New manager",
        "moreFilters": "More filters",
        "typeOfBusiness": "Type of business",
        "realEstate": "Real estate",
        "properties": "properties.",
        "immobile": "Immobile",
        "saleValue": "Sale value",
        "rentAmount": "Rent amount",
        "newProperty": "New property",
        "Properties": "Properties",
        "changeAdministrator": "Change administrator",
        "changeCondominium": "Change condominium",
        "addManager": "Add manager",
        "removeManager": "Remove manager",
        "changeBranch": "Change Branch",
        "property": "Property",
        "Leasedproperty": "Leased property",
      },
      "button": {
        "saveAndGenerateContractAmmendment": "Save and generate contract ammendment",
        "action": "Action",
        "open": "Open",
        "more": "More",
        "close": "Close",
        "previous": "Previous",
        "next": "Next",
        "save": "Save",
        "reajust": "Reajust",
        "afterSaving": "After saving register another.",
        "toSend": "To send",
        "saveSend": "Save & send",
        "selectAll": "Select all",
        "clearSelect": "Clear selection",
        "toSave": "Save",
        "toApply": "To apply",
        "delete": "Delete",
        "administrators": "Administrators",
        "seeContract": "See contract",
        "seeProperty": "See property",
        "toEdit": "To edit",
        "edit": "Edit",
        "suspend": "Suspend",
        "invalidate": "Invalidate",
        "seeBoleto": "See item",
        "update": "Update",
        "addsubaccount": "Add subaccount",
        "additem": "Add item",
        "newItem": "New item",
        "createitem": "Create item",
        "createAccount": "Create category",
        "account": "account",
        "changes": "changes",
        "toClose": "To close",
        "resetfilter": "Reset filter",
        "updateLogo": "Update new logo",
        "sumbitLogo": "Insert new logo",
        "crop": "Crop",
        "cancel": "Cancel",
        "done": "Done",
        "clear": "Clear",
        "resetFilters": "Reset filters",
        "apply": "Apply",
        "confirm": "Confirm",
        "send": "Send",
        "submitting": "Submitting...",
        "clickHereToGenerateToken": "Click Here To Generate Token",
        // "confirm": "Confirm",
        "Newmovement": "New movement",
        "reverse": "reverse",
        "yesdelete": "Yes, delete it!",
      },
      "label": {
        "registeredSurveyor": "Do you want to save this form?",
        "updateSurveyor": "Are you sure you want to update this form?",
        "inviteToApp": "Invite to app.",
        "checkAvailability": "Check app availability in your plan.",
        "group": "Group",
        "access": "Access",
        "ownaccess": "Own access",
        "Ownerregistered": "registered successfully!"
      },
      "requiredMmessage": {
        "creditSameAs": "Does match with debit!",
        "fieldRequired": "This field is required.",
        "fieldNotValid": "This field is not valid.",
        "fieldTooShort": "This field is too short.",
        "fieldTooLong": "This field is too long.",
        "Pleaseenteravaluemorethan1": "Please enter a value more than 1",
        "Pleaseenteravaluelessthan12": "Please enter a value less than 12",
        "Thevaluemustbeanumber": "The value must be a number",
        "Doesnotmatchwithdebit": "Does not match with debit!",
        "Enddatecantbebeforestartdate": "Debit month can't be before maturity date.",
        "Pleaseenteravaluelessthan2digit": "Please enter a value less than 2 digit",
        "TelephoneError": "Telephone must be in the format (XX)XXXX-XXXX",
        "mobileError": "Mobile must be in the format (XX)XXXX-XXXX",
        "passwordrequired": "Forget to set password for file",
        "Agencyrequire": "Agency must be at least 4 characters long",
        "numbernotallow": "Numbers and special characters are not allowed.",
        "7characters": "This field can have a maximum of 15 characters",
        "Specialcharacters": "Special characters are not allowed",
        "20characters": "This field can have a maximum of 20 characters",
        "40characters": "This field can have a maximum of 40 characters",
        "valuecannot": "value cannot be more than",
        "Pleaseenteronlynumbers": "Please enter only numbers and hyphens",
        "password": "min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit",
        "maturityDate": "The maturity date cannot be before the starting date of the bank account"
      },

      "formLabel": {
        "typeOfPerson": "type of person",
        "preselectedTemplates": "Preselected Templates",
        "allItemsSelected": "All item selected",
        "Supplier": "Supplier",
        "settings": "Settings",
        "clientCollaborators": "Client and collaborators",
        "blockOwner": "Block owner registration without CPF/CNPJ",
        "blockTenant": "Block tenant registration without CPF/CNPJ",
        "stopGuarantor": "Stop guarantor registration without CPF/CNPJ",
        "blockSurveyor": "Block surveyor registration without CPF/CNPJ",
        "blockBroker": "Block broker registration without CPF/CNPJ",
        "alwaysRefundIR": "Always refund the IR of the invoice",
        "alwaysRefundIPS": "Always refund PIS/COFINS/CSLL from the invoice",
        "cnpjCpf": "CNPJ/CPF",
        "cnpjCeiCpf": "CNPJ/CEI/CPF",
        "cnpj": "CNPJ",
        "name": "Name",
        "payment": "Payment",
        "defaultPaymentMethod": "Preferred payment method",
        "contact": "Contact",
        "mobileNumber": "Mobile (DDD + Number)",
        "telephone": "Telephone",
        "email": "Email",
        "password": "Password",
        "mobilePassword": "Mobile app’s password",
        "copygroupaccess": "Copy group access",
        "portion": "Portion",
        "in": "In",
        "debitmonth": "Debit month",
        "affiliation": "Affiliation",
        "motherName": "Mother's name",
        "fatherName": "Father's name",
        "moreFields": "More fields",
        "rg": "RG",
        "issuingAgency": "Dispatching Body",
        "shippingDate": "Dispatch Date",
        "sex": "Sex",
        "birth": "Birth",
        "Birthplace": "Birthplace",
        "maritalStatus": "Marital status",
        "nationality": "Nationality",
        "zipCode": "Zip code",
        "address": "Address",
        "number": "Number",
        "site": "Site",
        "complement": "Complement",
        "products": "Products",
        "neighborhood": "Neighborhood",
        "city": "City",
        "day": "Day",
        "das": "Das",
        "until": "Until",
        "state": "State",
        "line1": "Line 1",
        "line2": "Line 2",
        "line3": "Line 3",
        "nameReference": "Name reference",
        "nameinBank": "Name in Bank Account",
        "employmentData": "Employment data",
        "company": "Company",
        "profession": "Profession",
        "fieldOfActivity": "Industry",
        "monthlyIncome": "Monthly income",
        "taxesRetention": "Taxes retention",
        "withHoldIssqn": "With hold ISSQN",
        "observation": "Observation",
        "representative": "Representative",
        "typeSearch": "Type to search",
        "typeBasedThis": "Charge id, Name, Amount",
        "listing": "Listing",
        "of": "of",
        "to": "to",
        "users": "users",
        "withTagged": "With tagged",
        "mark": "Mark",
        "all": "All",
        "none": "None",
        "sendEmail": "Send Email",
        "configureEmail": "Configure Email",
        "identifier": "Identifier",
        "model": "Model",
        "title": "Title",
        "caption": "Caption",
        "variables": "Variables",
        "noRecordsFounds": "No records founds.",
        "moreItems": "More Items - Auto - All Items",
        "allItem": "All Items.",
        "sendLetter": "Send letter",
        "mailingLabel": "Mailing label",
        "changeAccessPermi": "Change access permissions",
        "labelTemplate": "Label template",
        "select": "Select",
        "test": "Test",
        "positionLabel": "Position of the 1st label",
        "numberOfCopies": "Number of copies",
        "guarantorAndGuarantor": "Guarantors and guarantors",
        "propertyType": "Property type",
        "formOfPayment": "Form of payment",
        "printScreen": "Print this screen",
        "ownerArea": "Owner's area",
        "ownersBeneficiaries": "Owners and Beneficiaries",
        "moreThanProperty": "More than 1 property",
        "hits": "Hits",
        "responsible": "Responsible",
        "billingMethod": "Billing Method",
        "condoDueDate": "Condominium due date",
        "liquidatorAddress": "Liquidator's address",
        "fantasyName": "Fantasy name",
        "administrator": "Administrator",
        "cpf": "CPF",
        "caretaker": "Caretaker",
        "lawyerContact": "Lawyer contact",
        "cellPhone": "Cell phone",
        "properties": "Properties",
        "attorney": "Attorney",
        "caretakerContact": "Caretaker's contact",
        "monthlyAdminRate": "Monthly admin rate",
        "doNotPercentage": "Do not use percentage, use fixed amount",
        "leaseFee": "Lease fee",
        "ownerAndBenef": "Owners and beneficiaries",
        "type": "Type",
        "propertyPercentage": "Property(%)",
        "transfer": "Transfer",
        "guaranteedTransfer": "Guaranteed transfer",
        "transferDay": "Transfer day",
        "daysOfTransfer": "Transfer days",
        "proServiceContact": "Provision of service contact",
        "fundingDate": "Date of capture",
        "fineExemption": "Fine exemption",
        "maximumLease": "Maximum lease term (months)",
        "maximumLeaseTooltip": "Contract duration in months (Editable only for properties without a contract)",
        "administrationFee": "Administration fee is levied on",
        "Condominiumadministration": "Condominium administration",
        "propertyIdentifier": "Property identifier",
        "buildingCondominium": "Building/Condominium",
        "value": "Value",
        "condominiumEstate": "Condominium is managed by the real estate",
        "propertyDetails": "Property details",
        "sequentialCode": "Sequential code",
        "waterCode": "Water code",
        "energyCode": "Energy code",
        "electricalConnection": "Type of electrical connection",
        "gasCode": "Gas code",
        "usableArea": "Usable area (m²)",
        "size": "Size (m²)",
        "taxpayerCode": "Taxpayer Code",
        "garbageFeeCode": "Garbage tax code",
        "firefighterCode": "Fire department code",
        "spuCode": "SPU code",
        "location": "Location",
        "propertyDestination": "Property destination",
        "listedProperty": "Listed property",
        "manager": "Manager",
        "registry": "Registry",
        "registration": "Registration",
        "fundraiser": "Fundraiser",
        "installments": "Installments",
        "branch": "Branch",
        "garages": "Garages",
        "identification": "Identification",
        "another": "Another",
        "comments": "Comments",
        "hobbyBox": "Hobby box",
        "corporateReason": "Corporate reason",
        "municipalregistration": "Municipal registration",
        "stateregistration": "State registration",
        "responsiblename": "Responsible name",
        "environment": "Environment",
        "approximate": "Approximate amount of taxes (%)",
        "IBPT": "As provided for in article 2 of law 12,741/2012, see IBPT",
        "taxregime": "Tax regime",
        "culturalEncouraging": "Cultural Encouraging",
        "optforsimple": "Opt for Simple Nacional.",
        "withholding": "Withholding IRRF (%)",
        "withholdingincome": "Withholding income tax if amount greater than",
        "pISWithholding": "PIS Withholding (%)",
        "cofinsRetention": "Cofins Retention (%)",
        "CSocial": "C. Social (%)",
        "Withholdtac": "Withhold tac if amount greater than",
        "INSSWithholding": "INSS Withholding (%)",
        "category": "Category",
        "item": "Item",
        "description": "Description",
        "selectAll": "SelectAll",
        "accountCategory": "Account (category)",
        "serviceCode": "Service code (SKU)",
        "debit": "Debit",
        "credit": "Credit",
        "taxation": "Taxation",
        "ISSQNCollection": "ISSQN collection",
        "serviceCodeTax": "Service code (Tax)",
        "percentageOfTaxes": "Percentage of taxes",
        "notTaxable": "Not taxable",
        "incomeInvoice": "Income invoice and dimob",
        "afterSaving": "After saving register another",
        "sourceUser": "Source user",
        "sourceUsergroup": "Source user group",
        "municipal": "Municipal (%)",
        "state1": "State (%)",
        "federal": "Federal (%)",
        "splitTransfers": "Split transfers",
        "selectTemplate": "Select a template",
        "ExtractforIR": "Extract for IR declaration",
        "Enteryourmodel": "Enter your model",
        "companyName": "Company name",
        "spouse": "Spouse",
        "TransmissãoNacional": "National Portal Transmission",
        "Contractsin": "Contracts in",
        "married": "Married",
        "single": "Single",
        "widower": "Widower",
        "legallySeparated": "Legally separated",
        "disquitado": "Desquitado",
        "divorced": "Divorced",
        "stableunion": "Stable union",
        "male": "male",
        "female": "Female",
        "Chargebankfee": "Charge bank fees from the owner",
        "charge_renter": "Charge the renter a bank fee",
        "chargeValue": "Bank fee amount",
        "verifying": "Verifying...",
        "submit": "Submit",
        "agency": "Agency",
        "rememberforget": "Remember password ? Login",
        "forgetpassword": "I forgot my password",
        "EnsureDueDateAfterStartDate": "Ensure the due date is after the start date",
        "EnsureStartDateBeforeEndDate": "Ensure the start date is before the end date",
        "EnsureOccutionDateAfterStartDate": "Ensure the occupation date is after the start date.",
        "EnsureMoveDateAfterStartDate": "Ensure the move on date is after the start date.",
        "EnsureKeyHandDateAfterStartDate": "Key Hand over date cannot be greater than Contract Start date",
        "ErrorCNPJCPF": "Duplicate CNPJ/CPF detected. Please retry with a new one.",
        "Cellphone": "Cell phone (DDD + Number)",
        "webservicepassword": "Web service password",
        "certificatepassword": "Certificate password",
        "beneficiaries": "Beneficiaries of the main form",
        "Lockowner": "Lock owner registration without CPF/CNPJ",
        "Locktenant": "Lock tenant registration without CPF/CNPJ",
        "Lockguarantor": "Lock guarantor registration without CPF/CNPJ",
        "Locksurveyor": "Lock surveyor registration without CPF/CNPJ",
        "Blockbroker": "Block broker registration without CPF/CNPJ",
        "Alwaysreimburse": "Always reimburse IR from the invoice",
        "AlwaysreimbursePIS": "Always reimburse PIS/COFINS/CSLL from the invoice",
        "ownedproperties": "Owned property of the real estate agency",
        "userealstate": "Use real estate agency data as a property owner",
        "adminvender": "This admin is also a vendor.",
        "Supplieradv": "This supplier is also a client.",
        "notransfer": "No transfer date configured",
        "department": "Department",
        "quantity": "Quantity",
        "Goodoftheguarantor": "Good of the guarantor",
        "balanceblocked": "Balance blocked",
        "Rg": "RG",
        "Organexp": "Organ exp.",
        "INSS": "INSS",
        "RPA": "RPA",
        "country": "Country",
        "operation": "Operation",
        "Accountingintegration": "Accounting integration",
        "accountingsystem": "Code in the accounting system",
        "Keytype": "Key type",
        "Keyforaddressing": "Key for addressing",
        "Retention": "Retention",
        "Mainowner": "Main owner",
        "Corporateemail": "Corporate email",
        "phone": "Phone number",
        "BusinessName": "Business Name",
        "Legalname": "Legal name",
        "invoiceanddimob": "Invoice and dimob",
        "homologation": "homologation",
        "Production": "Production",
        "SpecialTaxRegime": "Special tax regime",
        "citycode": "City code",
        "typeofpatio": "Type of patio",
        "DateofSettlement": "Date of Settlement",
        "Settlementdate": "Settlement date"
      },

      "terminationCalc": {
        "title": "Calculation of the termination penalty",
        "subtitle": "The penalty amount is equivalent to three proportional rent payments for the remaining months to terminate the contract.",
        "subtitledai": "The penalty amount is equivalent to three proportional rent payments for the remaining days to terminate the contract.",
        "equation": "Equation",
        "fine": "Three rents",
        "rents": "Rents",
        "monthsContract": "Months of contract",
        "endMonthsContract": "Months until end of contract",
        "penalty": "Penalty",
        "total": "Total"
      },

      "otherfooter": {
        "Liquidatenotpayable": "Liquidate not payable",
        "Reversepayable": "Reverse payable",
        "Launchandleavepending": "Launch and leave pending",
        "Receiptofpayable": "Receipt of payable",
      },

      "dropdownItem": {
        "Inprogress": "In-progress",
        "select": "Select",
        "newTemplate": "New Template",
        "person": "Person",
        "status": "Status",
        "connected": "Connected",
        "physicalAndLegal": "Physical and Legal",
        "physical": "Physical",
        "legal": "Legal",
        "contractStatus": "Contract status",
        "gruops": "Gruops",
        "todos": "Todos",
        "activeContracts": "Only with active contracts",
        "filters": "Filters",
        "inProgress": "In progress",
        "paused": "Paused",
        "most": "Most",
        "Seenonline": "Seen online",
        "typeOfExpenses": "Type of expenses",
        "bankAccountType": "Bank type",
        "Physical and Legal": "Physical and Legal",
        "Physical": "Physical",
        "Legal": "Legal",
        "All": "Todos",
        "Marked for shipment": "Marked for shipment",
        "Not Marked for shipment": "Not marked for shipment",
        "Shipments sent": "Shipments sent",
        "Confirmed shipments": "Confirmed shipments",
        "Shipments with error": "Shipments with error",
        "Settled shipments": "Settled shipments",
        "No split": "No split",
        "With split": "With split",
        "Finalizedandbreached": "Finalized and breached",

        "activerent": "Active rent",
        "sold": "Sold",
        "pendinglease": "Pending lease",
        "available": "Available",
        "announced": "Announced",
        "invalid": "Invalid"
      },

      "contractsettings": {
        "ExpensePausedContracts": "Allow posting expense on paused contracts",
        "ExpenseTerminatedContracts": "Allow posting expense on terminated contracts",
        "ContractsActive": "Create all contracts with 'active' status",
        "ChargeBankFee": "Charge bank fee",
        "RentalPeriodClosedMonth": "Rental period with a closed month",
        "NewContractsWillBeGeneratedConsidering": "If checked, all new contracts will be generated considering the closed month period.",
        "DoNotDisplayContractData": "Display contract data in the body of the invoice",
        "DoNotDisplayRentalPeriod": "Display rental period on the invoice",
        "AllowExtraCharge": "Allow extra charge on terminated contract",
        "CalculateFirstProportional": "Calculate the first proportional rent considering the date of occupancy",
        "UncheckingThisOptio": "By unchecking this option, the contract start date will be considered.",
        "DoNotReadjustNegative": "Do not readjust negative index",
        "AccumulatedAmountIsNegative": "If checked, even if the accumulated amount is negative, it will not be readjusted and will not reduce the rental value.",
        "AdjustRentConsidering": "Adjust rent considering the occupancy date",
        "IncomeTaxWithheld": "Income Tax Withheld at Source (IRRF)",
        "automaticallymarkcontractforIR": "Automatically mark contract for IR",
        "AutomaticIRCalculation": "If checked, when registering new contracts, they will be configured to enter the automatic IR calculation.",
        "MarkContractForDIMOB": "Automatically mark contract for DIMOB",
        "AutomaticallyConfigured": "If checked, when registering new contracts, it will be automatically configured to enter DIMOB.",
        "SimplifiedDiscount": "Simplified discount for all owners",
        "DeductPunctualityDiscount": "Deduct punctuality discount from IR calculation.",
        "DeductAdministrationFee": "Do not deduct administration fee in automatic IRRF calculation",
        "SelectingThisOption": "When selecting this option, the calculation will be based on the gross rental value.",
        "Standardcredit": "Standard credit",
        "Standardservice": "Standard service",
        "Finecalculation": "Fine calculation",
        "Adjustments": "Adjustments",
        "Configuration": "Configuration",

        "Bankfeeamount": "Bank fee amount",
        "billofexchange": "Display contract data in the body of the bill of exchange",
        "periodonbillet": "Display rental period on billet",
        "leaseperiodwith": "Lease period with closed month",
        "automaticallymarkcontractforIR.": "Automatically mark contract for IR",
        "automaticallymarknewcontractsfordimob": "Automatically mark new contracts for DIMOB",
        "addonedaytotherentalperiod": "Add one day to the rental period",
        "pendingandpausedcontracts": "Allow posting of expenses on pending and paused contracts",
        "allowpostingofexpensesonfinalizedcontract": "Allow posting of expenses on finalized contract",
        "donotautomaticallyactivateagreementafterenvelopecompletes": "Do not automatically activate agreement after envelope completes",
        "updateonlytheyearofthelastadjustmentdatewhenadjustingthecontract": "Update only the year of the last adjustment date when adjusting the contract",
        "setthelastreadjustmentdatebasedonthebeginningofthecontract": "Set the last readjustment date based on the beginning of the contract",
        "donotreadjustnegativeindices": "Do not readjust negative indices",
        "readjustrentconsideringthefirstdayofthecontract": "Readjust rent considering the first day of the contract",
        "createalloutstandingcontracts.": "Create all outstanding contracts.",
        "alwaysconfigure1stproportionalrent": "Always configure 1st proportional rent",
        "useleaseoccupancydatetocalculate1stproratedrent": "Use lease occupancy date to calculate 1st prorated rent",
        "valueofthe1stproportionalrentbasedonthedaysofthemonthinwhichthecontractbegins": "Value of the 1st proportional rent based on the days of the month in which the contract begins",
        "IRretentionbands": "IR retention bands",
        "Changetracks": "Change tracks",
        "DeducttheadministrationfeeintheautomaticcalculationoftheIRRF": "Deduct the administration fee in the automatic calculation of the IRRF",
        "Displaythe landlord's CPF in the add-on": "Display the landlord's CPF in the add-on",
        "Renterandowerarea": "Renter and ower area",
        "Intheowner'sarea,youmustpresentallextractsfortheIRdeclaration": "In the owner's area, you must present all extracts for the IR declaration",
        "Setup invitations": "Set up invitations",
        "Terminationpenality": "Termination penality",

        "Monthsforcalculatingthecontractualfine": "Months for calculating the contractual fine",
        "ISSQNfunds": "ISSQN funds",
        "ChangeISSRate": "Change ISS Rate",
        "Darfcollection": "Darf collection",
        "Defaultvendor": "Default vendor",
        "Minimumamountforcollection": "Minimum amount for collection",
        "Deductanadministrationfeewhencalculatingthelion'sbill": "Deduct an administration fee when calculating the lion's bill",
        "Taxrefundofinvoices": "Tax refund of invoices",
        "IRrefund": "IR refund",
        "PIS/COFINS/CSLLrefund": "PIS/COFINS/CSLL refund",
        "SettingupTaxRefundsforInvoices": "Setting up Tax Refunds for Invoices",
        "Minimumrefundamount": "Minimum refund amount",
        "Rateusedforcalculation": "Rate used for calculation",
        "Serviceusedtoissuetherefund": "Service used to issue the refund",
        "Servicesusedforbasecalculation": "Services used for base calculation",
        "Service": "Service",
        "ConfigureISSReimbursementRates": "Configure ISS Reimbursement Rates",
        "ALIQUOTS": "ALIQUOTS",
        "Rate": "Rate",
        "change": "Change",
        "Selectaninvitation": "Select an invitation",
        "MonthlyIRRF Table - Calculation basis": "Monthly IRRF Table - Calculation basis",
        "Aliquot": "Aliquot",
        "Installmenttobededucted": "Installment to be deducted",
        "Tobetter understand how the IRRF calculation is done": "To better understand how the IRRF calculation is done",
        "clickhere": "click here",
        "retainsButCollection": "Retains, but the collection will not be controlled by the system",
        "retainAgencyCollects": "Retain, and the real estate agency collects",
        "retainsTenantCollects": "Retains, and the tenant collects",
        "doesTax": "Does not withhold tax",
        "changeFromToday": "Keep existing records and change from today",
        "changeFromSelectedDate": "Keep existing records and change from the selected date",
        "lastPaymentDate": "Change pending transactions after the last payment date"

      },

      "checkboxLabel": {
        "administrativeAccess": "Administrative Access",
        "usersChangeQuery": "Users - Change and query",
        "closingOpeningMonth": "Closing and opening of the month",
        "chartAccountsAmendment": "Chart of Accounts - Amendment",
        "settingsConsultation": "Settings - Consultation",
        "customersNotify": "Customers - Notify",
        "collectionsConsultation": "Collections - Consultation",
        "collectionsAmendment": "Collections - Amendment",
        "collectionsInvalidate": "Collections - Invalidate",
        "collectionsSettlement": "Collections - Settlement",
        "collectionsReverse": "Collections - Reverse",
        "metricsVisualization": "Metrics - Visualization",
        "boxConsultation": "Box - Consultation",
        "agreementsConsultation": "Agreements - Consultation",
        "monetaryUpdateAmendment": "Monetary Update Table - Amendment",
        "legalConsultation": "Legal - Consultation",
        "invoicesConsultation": "Invoices - Consultation",
        "invoicesAmendment": "Invoices - Amendment",
        "servicesConsultation": "Services - Consultation",
        "expensesConsultation": "Expenses - Consultation",
        "changeFromToday": "Keep existing records and change from today",
        "changeFromSelectedDate": "Keep existing records and change from the selected date",
        "lastPaymentDate": "Change pending transactions after the last payment date",
        "DoFixedAmount": "Do fixed amount",
        "Retaintransfer": "Retain transfer in the agency's account",
        "Setup1strental": "Calculate 1st proportional rent"
      },

      "branch": {
        "branches": "Branches",
        "newBranch": "New branch",
        "name": "Name",
        "contact": "Contact",
        "properties": "Properties",
        "attorney": "Attorney",
        "caretakerContact": "Caretaker's contact",
        "monthlyAdminRate": "Monthly admin rate",
        "doNotPercentage": "Do not use percentage, use fixed amount",
        "leaseFee": "Lease fee",
        "ownerAndBenef": "Owners and beneficiaries",
        "type": "Type",
        "propertyPercentage": "Property",
        "transfer": "Transfer",
        "guaranteedTransfer": "Guaranteed transfer",
        "transferDay": "Transfer day",
        "daysOfTransfer": "Days of transfer",
        "proServiceContact": "Provision of service contact",
        "fundingDate": "Funding date",
        "fineExemption": "Fine exemption",
        "maximumLease": "Maximum lease term (months)",
        "maximumLeaseTooltip": "Contract duration in months (Editable only for properties without a contract)",
        "administrationFee": "Administration fee is levied on",
        "propertyIdentifier": "Property identifier",
        "buildingCondominium": "Building/Condominium",
        "value": "Value",
        "condominiumEstate": "Condominium is managed by the real estate",
        "propertyDetails": "Property details",
        "sequentialCode": "Sequential code",
        "waterCode": "Water code",
        "energyCode": "Energy code",
        "electricalConnection": "Type of electrical connection",
        "gasCode": "Gas code",
        "usableArea": "Usable area (m²)",
        "size": "Size (m²)",
        "taxpayerCode": "Taxpayer Code",
        "garbageFeeCode": "Garbage fee code",
        "firefighterCode": "Firefighter code",
        "spuCode": "SPU code",
        "location": "Location",
        "propertyDestination": "Property destination",
        "listedProperty": "Listed property",
        "manager": "Manager",
        "registry": "Registry",
        "registration": "Registration",
        "fundraiser": "Fundraiser",
        "installments": "Installments",
        "branch": "Branch",
        "garages": "Garages",
        "identification": "Identification",
        "another": "Another",
        "comments": "Comments",
        "hobbyBox": "Hobby box"
      },

      "company": {
        "company": "Company",
        "registerData": "Registration data",
        "contact": "Contact",
        "address": "Address",
        "openingHours": "Opening Hours",
        "soon": "Logo",
        "pixelsize": "pixels in image/png",
        "baseboard": "Baseboard",
        "footerofofter": "Footer of reports",
        "listing": "Listing",
        "mondayToFriday": "Monday To Friday",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "ImageCrop": "Image crop"
      },

      "banks": {
        "name": "Bank",
        "bankAccounts": "Bank accounts",
        "newBank": "New bank",
        "mainAccount": "Main account",
        "openingBalance": "Opening balance",
        "moreOptions": "More options",
        "otherAccounts": "Other accounts",
        "agency": "Agency",
        "accountNo": "Account",
        "accountHome": "Account start",
        "accountType": "Account Type",
        "withDigit": "(With digit)",
        "closingDate": "Closing date",
        "howMuchMoney": "How much money is in the account?",
        "whenDidYou": "When did you see that it had that value?",
        "newBankAccount": "New bank account",
        "editbank": "Edit bank details",
        "listOver": "List Over ",
        "autoScroll": "Auto scroll ",
        "listAll": "List all",
        "addBank": "New Bank account",
        "checkingAccount": "Checking account*",
        "box": "Cashier",
        "investment": "Investment",
        "status": "Change bank status",
        "pixKey": "Pix key",
        "pixkeyType": "Pix key Type",
        "templates": "Templates",
        "docmenttype": "Docment type",
        "Contractcharges": "Contract charges"
      },

      "branches": {
        "branches": "Branches",
        "branch": "branches",
        "newBranch": "New branch",
        "name": "Name",
        "contact": "Contact",
        "editBranch": "Edit branch"
      },

      "formReceipt": {
        "selectwayofbankheader": "Select way of receiving",
        "selectwayofbankcontent": "Ways of receiving change status directly.",
        "atMonetary": "At monetary",
        "Formsofreceipt": "Forms of receipt",
        "waysOfReceiving": "Ways of recieving",
        "newWayOfReceiving": "New way of recieving",
        "anotherAction": "Receive with credit card",
        "bankSlipOptions": "Bank slip options",
        "tradeName": "Trade name of the company as assignor in the slips",
        "displayTheAmount": "Display the amount of composition in the body of slips",
        "displayTheTotal": "Display the total amount of the composition in the body of the slips",
        "doNotDisplay": "Do not display the company logo on the back of the slips",
        "congratulations": "Congratulations to the adimplents (individual)",
        "pendingCharges": "Pending charges alert (individual)",
        "sortBillet": "Sort billet compositions by service or product name",
        "doNotDisplaycomposition": "Do not display composition on slips",
        "configureBillingRule": "Configure billing rule",
        "blockingAndCancellation": "Blocking and cancellation",
        "practicedRates": "Practiced rates",
        "fees": "Fees",
        "allFees": "Interest",
        "trafficTicket": "Fine",
        "atmonetary": "At. monetary",
        "ticketDiscount": "Ticket Discount Instructions",
        "discount": "Discount",
        "doNotUsePercentage": "Do not use percentage, use fixed amount",
        "discountedServices": "Discounted services",
        "bankcreditTitle": "Which bank account will the amounts be credited to?",
        "alterTitle": 'The "Bank account" must be the same used in your agreement with the payment acquirer. Changing the account in the system does not imply changing with the purchaser.',
        "accountCredit": "Account credit:",
        "all": "All",
        "active": "Active",
        "disabled": "Disabled",
        "disable": "Disable",
        "activeContegency": "Active (Contegency)",
        "monthlyinterest": "Monthly interest rate",
        "interestandfine": "Interest and fine instructions on the bill",
        "finerate": "Fine rate",
        "discountrate": "Discount rate",
        "givediscount": "Give discount until",
        "applyAllDiscount": "Apply discount on all services",
        "lawyerFeesTitle": "Lawyer fees",
        "lawyerFees": "Lawyer fees",
        "display": "Display",
        "donotdisplay": "do not display",
        "ApplyDiscountOnly": "Apply discount only on some services",
        "dicountTitle": "Services that apply discounts",
        "characterremains": "Characters remaining",
        "characterremain": "Character remaining",

        "Whattypeofticketwillyouuse": "What type of ticket will you use?",
        "Youcanissueslipsusingyou": "You can issue slips using your own agreement with the bank or you can use Kobana slips",
        "WerecommendKobanaslips": "We recommend Kobana slips",
        "astheyarefullyautomated": "as they are fully automated, do not need to be configured or approved. You will never again need to download return files from the bank and process them in the system to carry out the write-offs, as the system will do all this for you",
        "knowmore": "know more",
        "HireKobana": "Hire Kobana",
        "HireKobanatoreceivewithacreditcard": "Hire Kobana to receive with a credit card ",
        "Pleaseconfigureyour": "Please configure your",
        "account": "account",
        "here": "here"
      },

      "paidTobepaid": {
        "Variousexpenses": "Various expenses",
        "Tributewithbarcode": "Tribute with barcode",
        "ImportNFe(xml)": "Import NFe(xml)",
        "CPF_CNPJOriginalpayer": "CPF/CNPJ Original payer",
        "Digitableline": "Digitable line",
        "Barcodeexpense": "Barcode expense",
        "Processing": "Processing...",
        "Markallforprocessing": "Mark all for processing",
        "Unmarkallforprocessing": "Unmark all for processing",
        "Processreturn": "Process return",
        "Transferspayable": "Transfers payable",
        "Realestateexpenses": "Real estate expenses",
        "Contractexpenses": "Contract expenses",
        "Processingstatus": "Processing status",
        "Unmarked": "Unmarked",
        "Sent": "Sent",
        "Scheduled": "Scheduled",
        "Witherror": "With error",
        "Paidwith": "Paid with",
        "Fromsuppliers": "From suppliers",
        "Youhave": "You have",
        "recurringexpensestopost": "recurring expenses to post",
        "Clickheretolaunch": "Click here to launch",
        "chargespaid": "Charges received",
        "chargepaid": "Charge received",
        "chargestobereceived": "Charge to be received",
        "chargetobereceived": "Charges to be received",
        "collectionsreceivable": "Expenses paid",
        "collectionsToBereceivable": "Expenses to be paid",
        "Unseencharges": "To be paid",
        "Expense": "Expense",
        "Documentpayment": "Document payment",
        "Documentsupervisior": "Document supervisior",
        "Proof": "Proof",
        "Others": "Others",
        "transfertobecarriedout": "Expenses payable",
        "Issuecheck": "Issue check",
        "Bordero": "Bordero",
        "Marktoprocess": "Mark to process",
        "Unmarkfromprocessing": "Unmark from processing",
        "Receipt": "Receipt",
        "Changebankaccount": "Change bank account",
        "Changeexpiration": "Change expiration",
        "Fromsuppliercategories": "From supplier categories",
        "Barcode": "Bar code",
        "File": "File",
        "Vendorbankaccounttype": "Vendor bank account type",
        "Supplierbankaccountbank": "Supplier bank account bank",
        "Ofthetype": "Of the type",
        "Withoutoutstandingcharges": "Without outstanding charges",
        "Resetfilter": "Reset filter",
        "Accountcategory": "Account category",
        "Ithasbeenpaid": "It has been paid",
        "Settleintothesamebankaccountthat": "Settle into the same bank account that the expense was posted",
        "BorderoSelectpaymentauthorizationdate": "Bordero(Select payment authorization date)",
        "Authorizationdate": "Authorization date",
        "Generatebordero": "Generate bordero",
        "Checkboxisnotchecked": "Checkbox is not checked",
        "Editexpense": "Edit expense",
        "Chargesent": "Charge sent but not viewed",
        "Chargessent": "Charges sent but not viewed",
        "chargespaids": "Charges paid",
        "chargepaids": "Charge paid",
        "chargespaidto": "Charges paid",
        "chargepaidsto": "Charge paid",
        "Financial Expenses": "Financial Expenses",
        "reverse": "Are you sure you want to make this refund?",
        "Reversed": "Reversed",
        "PaidTransfers": "Paid transfers",
        "Realestatexpenses": "Real estate expenses",
      },

      "taxation": {
        "warning": "We recommend that the taxes are parameterized by the accountant responsible for your company due to the high complexity of the Brazilian tax legislation. In addition, the accountant must validate the first invoices issued by the system at the city hall or federal revenue office in his state.",
        "taxationOfServicesRendered": "Taxation of services rendered",
        "general": "General",
        "important": "Important",
        "yourCompanyMust": "Your company must be authorized by the city hall to issue invoices using third-party software.",
        "registerTheTax": "Register the tax service code used by your company.",
        "youAlsoNeedAn": "You also need to upload an",
        "A1": "A1 certificate",
        "informTaxesByType": "Inform taxes by type",
        "serviceCodes1": "Service codes and rates",
        "newTaxCode": "New tax code",
        "CNAE": "CNAE",
        "serviceDescription": "Service description",
        "services": "Services",
        "ISSQN": "ISSQN",
        "ServiceCodes": "service codes.",
        "NF_E_service": "NF-E service",
        "taxesRetentation": "Taxes retentation",
        "cityHallAccessData": "City hall access data",
        "digitalCertificate": "Digital certificate is required to send NF-E.",
        "replaceCertificate": "Replace certificate",
        "information": "Information",
        "editTaxation": "Update taxation",
        "newTaxservice": "New service tax code",
        "Incurfederal": "Incur federal taxes",
        "PayIRRF": "Pay IRRF",
        "PayINSS": "Pay INSS",
        "serviceCode": "Service code",
        "ISSQNRate": "ISSQN rate (%)",
        "description": "Description",
        "municipalTaxCode": "Municipal tax code",
        "Aftersaving": "After saving register another",
        "Sendcertificate": "Send certificate",
        "Drag": "Drag",
        "DropPFXfile": "Drop PFX file",
        "yourfilestoassetsor": "your files to assets, or",
        "browse": "browse",
        "Cityhall": "City hall",
        "Download": "Download",
        "UploadedFile": "Uploaded file",
        "Clicktouploadnewfile": "Click to upload new file",
        "Microentrepreneur": "Microentrepreneur and Small Business (ME EPP)",
        "Municipal": "Municipal Microenterprise",
        "Iestimated": "I estimated",
        "Societyof": "Society of Professionals",
        "Cooperative": "Cooperative",
        "IndividualMicroentrepreneur": "Individual Microentrepreneur (MEI)",
        "ISS/Autonomous": "ISS/Autonomous Fixed",
        "LimitedSociety": "Limited society",
        "AnonymousSociety": "Anonymous society",
        "IndividualLimited": "Individual Limited Liability Company (EIRELI)",
        "Others": "Others",
        "SenhadoCertificado": "Digital Certificate Password A1",
        "A1digitalcertificate": "A1 digital certificate:",
        "certificateexpired": "The current certificate expired",
        "monthsago": "months ago",
        "currentcertificatewillexpire": "The current certificate will expire in",
        "monthson": "months on"
      },


      "chartofaccount": {
        "chartOfAccounts": "Accounts of Plan",
        "newitem": "New item",
        "editCategoty": "Edit account (category)",
        "newAccount": "New account (category)",
        "heading": "Items are created within accounts (category). Edits made will reflect on service and report from others areas of the system",
        "categoryheading": "Categories are the main divisions in the accounts plan hierarchy. Edits made will reflect on services and reports from other areas of the system.",
        "specialcateforyaccount": "SPECIAL CATEGORY ACCOUNTS",
        "Categoryaccountforbankfees": "Category account for bank fees",
        "Categoryaccountforbillfees": "Category account for bill fees",
        "deleteTobePaid": "Do you want to delete marked entries?"
      },

      "userModule": {
        "user": "User",
        "toMark": "To Mark",
        "addToGroup": "Add to Group",
        "permissionAccess": "Change access permissions",
        "groups": "Groups",
        "addNew": "Add New",
        "myIp": "Use my current ip",
        "fixedIp": "Limited by location (Fixed IP)",
        "limitAccessByday": "Limit access by day and time",
        "removeFromGroup": "Remove from a group",
        "Updategroup": "Update group",
        "updateUser": "Update user"
      },

      "readjIndex": {
        "extraReadjustmentIndex": "Extra readjustment indices",
        "readjustment_indexes": "Readjustment Indexes",
        "newIndexes": "New Indexes",
        "launchIndex": "Launch index",
        "NewIndex": "New Index",
        "Nameofthetable": "Name of the table",
        "table": "Table",
        "Outofdate": "Outdated",
        "Active": "Active",
      },

      "expenditure": {
        "expenses": "expenses",
        "beingrecurring": "being recurring",
        "Maturity": "Maturity",
        "Details": "Details",
        "value": "Value",
        "newCharge": "New charge",
        "newExpense": "New expense",
        "newRecurringExpense": "New recurring expense",
        "withTagged": "With tagged",
        "Settleonpayable": "Settle on payable",
        "reversePayable": "Reverse payable",
        "launchLeavePending": "Launch and leave pending",
        "receiptPayable": "Receipt of payable",
        "contractualExpenses": "Contractual expenses",
        "liquidate": "Liquidate",
        "settleontheduedate": "Settle on the due date",
        "printreceipt": "Print receipt",
        "tothrow": "To throw",
        "launchontheexpirationday": "Launch on the expiration day",
        "timeperiod": "time period",
        "Settle Lots": "Settle Lots",
        "liquidaremlote": "Settle Lots"
      },

      "contractlistother": {
        "Pausecontract": "Pause contract",
        "Reasonforpause": "Reason for pause",
        "Reasonforactive": "Reason for active",
        "charactersleft": "characters left",
        "Pause": "Pause",
        "Whowillbenotified": "Who will be notified",
        "sendemail": "send email",
        "Partiestonotify": "Parties to notify",
        "Tenants": "Tenants",
        "Main_tenant": "Main Tenants",
        "Fireinsurer": "Fire insurer",
        "Bailinsurer": "Bail insurer",
        "Selectamodel": "Select a model",
        "send": "send",
        "Publicationofdocuments": "Publication of documents",
        "Checkbox": "Checkbox",
        "SizeUploaded": " Size Uploaded",
        "SendDate": "Send Date",
        "Share": "Share",
        "Attachdocuments": "Attach documents",
        "Not": "Not",
        "percentage": "Percentage",
        "Toshare": "To share",
        "Extrasanddiscounts": "Extras and discounts",
        "Internalobservation": "Internal observation",
        "Noteforthecustomer": "Note for the customer",
        "Competence": "Competence",
        "Latestcontracts": "Latest contracts",
        "Tax": "Tax",
        "NewTax": "New Tax",
        "Code": "Code",
        "Category": "Category",
        "ValueBooklet": "Value Booklet",
        "charge": "charge",
        "NoLatestcontractsfound": "No Latest contracts found",
        "RegisterTax": "Register Tax",
        "Chargecategory": "Charge category",
        "Chargecode": "Charge code",
        "Chargetype": "Charge type",
        "Formofpayment": "Form of payment",
        "Amount": "Amount",
        "Numberofinstallments": "Number of installments",
        "Fraction": "Fraction",
        "Chargenotmanagedbytherealestate": "Charge not managed by the real estate",
        "Cashvalue": "Cash value",
        "Responsibleforpayment": "Responsible for payment",
        "Launchcharge": "Launch charge",
        "Expiration": "Expiration",
        "Incursfee": "Incurs fee",
        "Generatebilling": "Generate billing",
        "Settleonpayable": "Settle on payable",
        "Reversepayable": "Reverse payable",
        "Selectsubscribers": "Select subscribers",
        "DocumentNo": "Document no",
        "documentsName": "Documents Name"
      },

      "notifications": {
        "name": "Notifications",
        "welcome": "Welcome",
        "birthday": "birthday",
        "contract": "contract",
        "insurance": "Insurance",
        "gurantees": "gurantees",
        "birthdaysDay": "Birthdays of the day",
        "contractExpiration": "Contract expiration",
        "tenant": "Tenant",
        "owner": "Owner",
        "beforeExpiration": "Before expiration",
        "afterExpiration": "After expiration",
        "insuranceExpiration": "Insurance expiration",
        "expirationGurantees": "Expiration of gurantees",
        "checkNotification": "Send notification",
        "welcomeDayAfter": "Days after the start of the contract",
        "daysBeforeExpiration": "Days before expiration",
        "daysAfterExpiration": "Days after expiration",
        "notNotifyOwner": "Not notify owner when contract is vacating",
        "sendNotificationBirthday": "Send notification on birthday",
        "sendNotificationForActive": "Send notification for active contracts only",
        "gurantorDefault": "Gurantor default",
        "off": "off",
        "enable": "Enable",
        "editTemplate": "Edit template",
        "for": "for",
        "previewTemplate": "Preview template",
        "companyLogo": " Company Logo",
        "preview": "preview"
      },

      "services": {
        "newService": "New service",
        "editService": "Edit service",
        "description": "Description",
        "payIncomeTax": "Pay income tax",
        "calculateAdministration": "Calculate administration fee on the service",
        "calculateAdministrationFeeBeducting": "Calculate administration fee by deducting the value of the service",
        "otherCNAES": "Other CNAES",
        "administrationFeeApplies": "Administration fee applies",
        "incidesIncome": "Incides income tax on the owner",
        "tags": {
          "income": "DIMOB: Income",
          "commission": "DIMOB: Commission",
          "fee": "DIMOB: Tax",
          "ownProperty": "Own property",
          "admFee": "Administration fee applies",
          "ownerFee": "Incides income tax on the owner",
          "admCalc": "Deducts value in calculation of administration fee"
        },
        "tenant": "Tenant",
        "owner": "Owner",
        "Imobiliária": "Real estate",
        "Imóvel": "Imóvel",
        "third": "Third",
        "notInclude": "Not include",
        "includeAsIncome": 'Include as income',
        "issueAnInvoice": "Issue an invoice and include it as a commission",
        "includeAsWithholding": "Include as withholding tax",
        "alwaysInMunicipality": "Always in the municipality (Service provided in the company)",
        "outsideTheMunicipality": "Outside the Municipality when applicable (Service provided on the client)",
        "free": "Free",
        "immune": "Immune",
        "suspendedCourtDecision": "Suspended by court decision",
        "suspendedDueAdministrative": "Suspended due to administrative procedure",
        "calculatebasedonmoveout": "Calculate proportional days by the vacancy date"
      },

      "users": {
        "name": "Users",
        "user": "User",
        "changeRestrictions": "Change restrictions by day, time and location",
        "copyAccessPermissions": "Copy access, permissions, restrictions and groups from another user",
        "copyAccessFrom": "Copy access from a user group",
        "alertTouse": "Important: To use this feature, the location (where this user can access the system) must have a FIXED IP address. To get one, contact your ISP.",
        "userGroups": "User groups",
        "allusers": "All users",
        "usersWithoutGroup": "Users without group",
        "newgGroup": "New group",
        "theAccessesGroup": "The accesses of this group of users can be copied to the users in the",
        "register": "register",
        "ofusers": "of users or in",
        "usersMore": "Users More Copy accesses of a group of users",
        "allowCustomer": "Allow customer to open tickets for this group",
        "deleteGroup": "Are you sure you want to delete the group?",
      },

      "otherstran": {
        "Includeinthenexttransfer": "Include in the next transfer",
        "includeexpensein": "Include expense in the termination calculation",
        "recurring": "Recurring",
        "installment": "Installment",
      },

      "bankTransaction": {
        "selectbank": "Select Bank",
        "Banktransactionson": "Bank transactions on",
        "NewRecipe": "New Recipe",
        "Newtransfer": "New transfer",
        "Newreleseinbox": "New relese in box",
        "Inday": "In day",
        "Offthetype": "Off the type",
        "date": "Date",
        "Revenue": "Revenue",
        "Expenses": "Expenses",
        "Balance": "Balance",
        "Cloneexpense": "Clone expense",
        "Newretention": "New retention",
        "collectionagency": "Collection agency",
        "Discounts": "Discounts",
        "Issuance": "Issuance",
        "Newrelease": "New release",
        "Historycomplement": "History complement",
        "ImportexpenseviaNF-e": "Import expense via NF-e",
        "Invoice(Xml)": "Invoice (Xml)",
        "Filter": "Filter",
        "Withchargessettlededbetween": "With charges settleded between",
        "Withpaymentstatus": "With payment status",
        "Pendingpayment": "Pending payment",
        "clearingcheck": "clearing check",
        "AutomaticforwardingviaSplit": "Automatic forwarding via Split",
        "Manualtransfer": "Manual transfer",
        "Withoutguaranteedstatus": "Without guaranteed status",
        "ok": "ok",
        "Paymentmethod": "Payment method",
        "Withoutholdingtaxes": "Without holding taxes",
        "Docno": "Doc no",
        "Participation": "Participation",
        "Price": "Price",
        "Noofinstallments": "No. of installments",
        "Repeat": "Repeat",
        "MoreFileds": "More Fileds",
        "Datesaleoff": "Date sale off",
        "Previous balance": "Previous balance",
        "Financial Expense": "Financial Expense",
        "fixedDiscountError": "The discount is fixed, and the charge cannot be less than the discount amount.",
      },
      "newContractDev": {
        "lastPaymentDate": "Last payment date",
        "changeFromSelectedDate": "Change from selected date",
        "changeFromToday": "Change from today",
        "chooseFromContractual": "Choose from when the contractual changes should be considered for pending charges and transfers",
        "pendingChargesAndTransfer": "Pending charges and transfer",
        "PropertySection": "Property",
        "PropertyName": "Property *",
        "PropertyOwner": "Property owner",
        "ContractType": "Type of contract",
        "CommercialActivity": "Commercial activity",
        "TenantSection": "Tenant",
        "TenantName": "Tenant *",
        "AddAnotherTenant": "Add another tenant",
        "AddAnother": "Add another",
        "TaxInvoice": "Tax invoice",
        "DeductFee": "Deduct adm fee in IRRF",
        "GenerateDIMOB": "Generate DIMOB through the system",
        "ContractDetailsSection": "Contract details",
        "Start": "Start *",
        "End": "End",
        "MoveDate": "Date of occupancy",
        "RentAmount": "Rent amount (R$) *",
        "DueDate": "Due date *",
        "1stEwnt": "Proportional first rent",
        "istRentAmount": "First rent amount (R$)",
        "ChargeTogtherRent": "Charge together with the 2nd rent",
        "AdjustmentIndex": "Adjustment index *",
        "ChargeTitle": "Charge",
        "FormPayment": "Form of payment *",
        "WarrantySection": "Warranty",
        "Cosigner": "Cosigner",
        "AddAnotherCosigner": "Add another cosigner",
        "FireInsurance": "Fire insurance",
        "InsuredAmount": "Insured amount (R$)",
        "PremiumAmount": "Premium amount (R$)",
        "InstallmentAmount": "Installment amount (R$)",
        "SelectedPlan": "Selected plan",
        "ResponsibleParty": "Responsible party",
        "NoFireInsurance": "This contract does not have fire insurance",
        "RentTransferSection": "Rent transfer",
        "TypeTransfer": "Type of transfer",
        "DaysPaymentTransfer": "Days for payment transfer",
        "GuaranteedMonths": "Number of guaranteed months",
        "BankFee": "Bank fee for DOC/TED",
        "RenterFee": "Renter fee for charge",
        "ContractSituationSection": "Contract situation",
        "ActiveText": "It's ready, it is possible to generate invoices",
        "PendingText": "There are pending issues, it is not possible to generate invoices",
        "ChecklistTemplate": "Checklist template",
        "contractSummary": "Contract summary",
        "contractSummaryContent": "As you fill in the details, you will see a real-time contract summary.",
        "costThisContract": "Cost of this contract",
        "costThisContractContent": "The contracts generated through Kenlo Locação will be free when using Kenlo Insurance and Kenlo Pay services.",
        "remainingPlanBalance": "Remaining plan balance",
        "collapseAll": "Collapse all",
        "savedConnectedInternet": "The changes will be saved when you are connected to the internet",
        "zeroCostContract": "To have zero cost in this contract, keep Kenlo Insurance as the designated insurer.",
        "insuranceMandatoryTenancyLaw": "The hiring of fire insurance is mandatory for rentals according to Article 22 of the brazilian Tenancy Law.",
        "zeroCostContractInsurance": "To have zero cost in this contract, keep Kenlo Insurance as the designated insurer.",
        "costContractKenloInsurance": "The cost of this contract increased because you chose a insurance company other than Kenlo Insurance.",
        "apartmentWithoutAssistanc": "Apartment without assistanc",
        "inCollaborationKenloInsurance": "In collaboration with Kenlo Insurance",
        "inTotal": "in total",
        "electricalDamages": "Electrical damages",
        "fireExplosionSmoke": "Fire, explosion, smoke",
        "lightningStrikeFallingAircraft": "Lightning strike, falling aircraft",
        "insuranceAutomaticallyContractedActivated": "The insurance will be automatically contracted as soon as the rental contract is activated.",
        "apartmentPlus": "Apartment plus",
        "registerContract": "Register contract",
        "costOFThisContractContent": "The contracts generated through Kenlo Locação can have zero cost whenever you use these exclusive advantages together:",
        "kenloPay": "Kenlo Pay",
        "kenloPayContent": "Generate rent invoices at no cost using Kenlo Pay. The costs will be automatically passed on to the tenant.",
        "kenloInsuranceforFireInsurance": "Kenlo Insurance for fire insurance",
        "kenloInsuranceforFireInsuranceContent": "An exclusive partnership between Kenlo and Tokio Marine Insurer. Instant quoting and billing included in the rental invoices.",
        "newContractsSettingsArea": "You can change the cost standard for new contracts through the contract settings area.",
        "remainingPlanBalanceContent": "This is the total number of contracts still available in your current plan. When the balance is depleted, the billing method of Kenlo Locação will change, potentially becoming free when utilizing some exclusive Kenlo benefits.",
        "balanceRemaining": "Balance remaining",
        "balanceRemainingContent": "150 contracts in the Enterprise plan",
        "contractRegistered": "Contract registered!",
        "contractRegisteredContent": "You can open the contract page to access the document, print, edit information, and much more.",
        "contractRegisteredContentRemember": "Remember: the billing for Kenlo Insurance fire insurance will already be included in the rental invoices.",
        "newCosigner": "New cosigner",
        "newCosignerContent": "Insert the requested information to include a new cosigner.",
        "cpfOrcnpj": "CPF/CNPJ",
        "corporateName": "Corporate name",
        "tradeName": "Trade name",
        "contactInformation": "Contact information",
        "cellphoneNumber": "Cellphone (DDD + Number)",
        "parentage": "Parentage",
        "employmentInformation": "Employment information",
        "businessIndustry": "Business industry",
        "representatives": "Representatives",
        "addAnotherRepresentative": "Add another representative",
        "cosignerProperty": "Cosigner's property",
        "propertyValue": "Property value",
        "addAnotherProperty": "Add another property",
        "RGNumber": "RG (ID number)",
        "issuingAuthority": "Issuing authority",
        "issuanceDate": "Issuing date",
        "gender": "Gender",
        "dateOfBirth": "Date of birth",
        "nacionality": "Nacionality",
        "placeOfBirth": "Place of birth",
        "notDomiciledBrazi": "Not domiciled in Brazi",
        "insertNewOwnerOrBeneficiary": "Insert the requested information to include a new owner or beneficiary.",
        "standardFormPayment": "Standard form of payment",
        "enableSimplifiedDeduction": "Enable simplified deduction",
        "enableSimplifiedDeductionContent": "Enable the amount of R$  564,80 to be deducted from the base amount before calculating the income tax rate bracket and in the DARF",
        "doNotNotifyBillingRule": "Do not notify in the billing rule",
        "newRepresentative": "New representative",
        "newRepresentativeContent": "Insert the requested information to include a new representative.",
        "insertNewPropertyAdministrator": "Insert the requested information to include a new property administrator.",
        "propertyAdministratorSupplier": "This property administrator is also a supplier",
        "insertNewCondominium": "Insert the requested information to include a new condominium.",
        "newRealEstateAgent": "New real estate agent",
        "newRealEstateAgentContent": "Insert the requested information to include a new real estate agent.",
        "newTenantContent": "Insert the requested information to include a new tenant.",
        "costNewContracts": "Cost of new contracts",
        "costNewContractsContent": "The chosen configuration will be the default for all new contracts.",
        "zeroCost": "Zero cost",
        "zeroCostContent": "Use Kenlo Insurance fire insurance and generate invoices with Kenlo Pay to have zero cost during the contract period. The billing costs will be passed on to the tenant.",
        "customized": "Customized",
        "customizedContent": "Choose a different insurance company and rent payment method to generate a contract with a personalized monthly cost.",
        "UpTo": "up to",
        "Ativos": "Active",
        "services": "services",
        "Commerce": "Commerce",
        "Office": "Office",
        "consulting": "consulting",
      },
      "propertyDev": {
        "rentAmount": "Rent amount (R$)",
        "sellingPrice": "Selling price (R$)",
        "fixedValue": "Use fixed value",
        "rentFee": "Rent fee",
        "type": "Type",
        "ownershipPercentage": "Ownership percentage (%)",
        "transferProcessed": "Days until transfer is processed",
        "serviceContract": "Service Contract",
        "acquisitionDate": "Acquisition date",
        "admFee": "Adm fee apllies to",
        "amount": "Amount (R$)",
        "measurement": "Measurement (m²)",
        "contributorCode": "Contributor code",
        "propertyPurpose": "Property purpose",
        "historicalProperty": "Historical property",
        "registryOffice": "Registry office",
        "registrationNumber": "Registration number",
        "realAgent": "Real estate agent",
        "commissionAmount": "Commission amount (%)",
        "addOwner": "Add another owner or beneficiary",
        "addAgent": "Add another agent",
        "addGarage": "Add another garage",
        "addGarunter": "Add another garuntor",
        "addHobby": "Add another hobby box",
        "others": "Others",
        "observations": "Observations",
        "insertNewProperty": "Insert the requested information to include a new property.",
        "propertyAnotherSystem": "ID of the property in another system",
        "condoFee": "Condo fee",
      },
      "newTransfer": {
        "indentifier": "Indentifier",
        "transfers": "Transfers",
        "upcoming": "Upcoming",
        "completed": "Completed",
        "timePeriod": "Time period",
        "guaranteed": "Guaranteed",
        "charges": "Charges",
        "chargeSettlement": "Charge settlement",
        "chargeDueDate": "Charge due date",
        "PropertiesOwner": "Number of properties per owner",
        "contract": "Contract",
        "typepProperty": "Type of property",
        "cutOffDate": "Cut-off date",
        "charge": "Charge",
        "nonGuaranteed": "Non-guaranteed",
        "openContract": "Open contract",
        "verified": "Verified",
        "notSettled": "Not settled",
        "settledIn": "Settled in",
        "selected": "selected",
        "sendByEmail": "Send by e-mail",
        "revoke": "Revoke",
        "issueInvoice": "Issue invoice",
        "viewStatement": "View statement",
        "revokeTransfers": "Revoke transfers",
        "revokeTransfersContent": "Upon confirming the reversal, the selected transfers will change their status from settled to pending and can be accessed in the list of upcoming transfers.",
        "transfersRegisteredRevoked": "Transfers registered as revoked.",
        "openDetails": "Open details",
        "standardRent": "Administration fee",
        "refFrom": "Ref. from",
        "chargeExample": "Charge example",
        "deleteItem": "Delete item",
        "deleteItemContent": "To delete this item, you need to specify who is getting the credit.",
        "validityTransferEdits": "Validity of transfer edits",
        "validityTransferEditsContent": "Should the edits be considered only for the current transfer or for all subsequent transfers until the end of the contract?",
        "allSubsequentTransfers": "All subsequent transfers",
        "onlyCurrentTransfer": "Only the current transfer",
        "saveChanges": "Save changes",
        "editOnlyCurrentTransfer": "Edit only the current transfer",
        "editSubsequentTransfers": "Edit all the subsequent transfers",
        "settleTransfers": "Settle transfers",
        "settleTransfersContent": "Upon confirming this action, the simulated transfers will be recorded as expenses in the system and can be accessed in the section for completed transfers or in the section for pending expenses.",
        "supportedFormats": "Supported formats: PDF and PNG.",
        "transfersRegisteredSettled": "Transfers registered as settled.",
        "dateOfSettlement": "Date of settlement",
        "sendStatementBeneficiariesEmail": "Send statement to beneficiaries via email",
        "postInFinancial": "Post in financial",
        "postInFinancialContent": "Upon confirming this action, the simulated transfers will be recorded as expenses in the system and can be accessed in the section for completed transfers or in the section for pending expenses.",
        "transfersPostedInFinancial": "Transfers posted in financial.",
        "transferSimulation": "Transfer simulation",
        "printSimulation": "Print simulation",
        "viewCharge": "View charge",
        "forecast": "Forecast",
        "beneficiaries": "Beneficiaries",
        "markVerified": "Mark as verified",
        "totalAmount": "Total amount",
        "beneficiary": "Beneficiary",
        "warningRelatedBeneficiary": "The sum of the beneficiary values is exceeding the total value of the transfer.",
        "warningRelatedBeneficiary2": "This beneficiary has two more transfers this month.",
        "includeInSimulation": "Include in simulation",
        "financialInstitution": "Financial institution",
        "totalStatementAmount": "Total statement amount",
        "propertyOwnersPayed": "property owners to be payed",
        "settle": "Settle",
        "addBeneficiary": "Add beneficiary",
        "Postedinfinancial": "Posted in financial",
        "Settled": "Settled",
        "Pending": "Pending",
        "alltransfers": "All transfers",
        "Guaranteedtransfers": "Guaranteed transfers",
        "nonguaranteed": "Non-guaranteed transfers",
        "Allcharges": "All charges",
        "Settledcharges": "Settled charges",
        "Settledchargesdelay": "Settled charges with delay",
        "Settledwithoutdelay": "Settled charges without delay",
        "Notsettledcharges": "Not settled charges",
        "Lastdayofthemonth": "Last day of the month",
        "Selectdate": "Select date",
      },
      "DocumentTemplate": {
        "identifier": "Identifier",
        "amendmantName": "Amendmant Name",
        "amendmantTitle": "Amendmant Title",
        "amendmantSubTitle": "Amendmant Sub Title",
        "amendmantIdentificationSystem": "Amendmant identification in the system",
        "documentTemplates": "Document templates",
        "editWatermark": "Edit watermark",
        "newFolder": "New folder",
        "templates": "templates",
        "template": "template",
        "duplicate": "Duplicate",
        "rename": "Rename",
        "continueWithoutTemplate": "Continue without a template",
        "folderDuplicated": "The folder has been duplicated.",
        "editWatermarkContent": "The chosen watermark will appear on all document pages.",
        "supportedFormat": "Supported format: PNG.",
        "selectFile": "Select file",
        "enterFolderName": "Enter the folder name",
        "folderName": "Folder name",
        "whatDoYouWant": "What do you want to do?",
        "whatDoYouWantContent": "You can create a document template that will be saved for future use or draft a standalone document.",
        "draftStandaloneDocument": "Draft a standalone document",
        "createTemplate": "Create a template",
        "deleteFolder": "Delete folder",
        "deleteFolderContent": "When permanently deleting the folder, the document templates inside the folder cannot be recovered.",
        "nameNewFolder": "Name of the new folder",
        "newTemplate": "New template",
        "newAmendmant": "New template",
        "folderEmpty": "This folder is empty",
        "folderEmptyContent": "Create a new document template that can be used later.",
        "moveFolders": "Move folders",
        "documentTemplateDuplicated": "Document template has been duplicated.",
        "internalTemplate": "Internal template",
        "kenloTemplate": "Internal template",
        "enterTemplateName": "Enter the template name",
        "templateName": "Template name",
        "createCopyOrEdit": "Create a copy or edit the current template?",
        "createCopyOrEditContent": "If you choose to edit the current template, the changes will be permanent, and you won't be able to restore the original version.",
        "editCurrentTemplate": "Edit current template",
        "createCopy": "Create a copy",
        "deleteDocumentTemplate": "Delete document template?",
        "deleteDocumentTemplateContent": "When deleting the template permanently, it cannot be recovered.",
        "move": "Move",
        "moveContent": "Choose the folder to which you want to move the template.",
        "print": "Print",
        "printContent": "Do you want to print the document already associated with Residential Contract 109/1, or just the neutral template with unfilled variables?",
        "neutralTemplate": "Neutral template",
        "contractDocument": "Contract document",
        "share": "Share",
        "shareContent": "Do you want to share the document already associated with Residential Contract 109/1, or just the neutral template with unfilled variables?",
        "copy": "Copy",
        "copyContent": "Copy the link to share the document template with someone.",
        "changesSavedAutomatically": "The changes will be saved automatically.",
        "documentTitle": "Document title",
        "documentSubtitle": "Document subtitle",
        "showLogoHeader": "Show logo in the header",
        "templateIdentificationSystem": "Template identification in the system",
        "titleAappearHeader": "This title will appear in the document header",
        "subtitleAppearHeader": "This subtitle will appear in the document header",
        "variablesContent": "Variables are neutral placeholders used in document templates. When using this template to generate a clause or contract through the system, the variables will be automatically filled in.",
        "searchVariable": "Search for the variable you wish to use",
        "propertyOwnerVariables": "Property owner variables",
        "propertyVariables": "Property variables",
        "tenantVariables": "Tenant variables",
        "contractVariables": "Contract variables",
        "dateVariables": "Date variables",
        "guaranteeVariables": "Guarantee variables",
        "guarantorVariables": "Guarantor variables",
        "transferVariables": "Transfer variablese",
        "realStateAgencyVariables": "Real state agency variables",
        "managerVariables": "Manager variables",
        "signaturesVariables": "Signatures variables",
        "otherVariables": "Other variables",
      },
      "Reports": {
        "ExportPDF": "Export PDF",
        "LastUpdate": "Last update",
        "categories": "Categories",
        "showReportsWith": "Show reports with:",
        "statusFilter": "Status filter",
        "typeFilter": "Type filter",
        "searchForReports": "Search for reports",
        "showing": "Showing",
        "of": "of",
        "banking": "Banking",
        "cashier": "Cashier",
        "comparisonOfIncomeExpenses": "Comparison of Income and Expenses",
        "statementOfPlannedAccomplished": "Statement of planned and accomplished",
        "cashFlow": "Cash flow",
        "electronicFrenchie": "Electronic Frenchie",
        "bankTransactions": "Bank transactions",
        "annualBudgetForecast": "Annual budget forecast",
        "monthlyBudgetForecast": "Monthly budget forecast",
        "propertyServiceCodes": "Property service codes",
        "condominiums": "Condominiums",
        "propertyCharges": "Property charges",
        "invalidatedProperties": "Invalidated properties",
        "summaryOfProperties": "Summary of properties",
        "statementOfTransfers": "Statement of transfers",
        "transferDetails": "Transfer Details",
        "releaseDiary": "Release diary",
        "statementOfTransfersByOwner": "Statement of transfers by owner",
        "invoicesForTransfers": "Invoices for transfers",
        "fireInsuranceCancellation": "Fire insurance cancellation",
        "taskChecklistsForContracts": "Task checklists for contracts",
        "contractCharges": "Contract charges",
        "contractsToReadjusted": "Contracts to be readjusted",
        "adjustedContracts": "Adjusted contracts",
        "terminatedContracts": "Terminated contracts",
        "declarationOfRealEstateActivities": "DAI - Declaration of real estate activities",
        "guarantorDetails": "Guarantor details",
        "fireInsuranceDetails": "Fire insurance details",
        "mirrorOfContracts": "Mirror of contracts",
        "contractGuarantees": "Contract guarantees",
        "iptuLaunch": "IPTU launch",
        "Nonpayment": "Non payment",
        "detailedAgreements": "Detailed agreements",
        "detailedNonpayment": "Detailed Non payment",
        "oldestOutstandingCharge": "Oldest outstanding charge",
        "nonpaymentSummary": "Non payment summary",
        "agreementAndDebtConfession": "Agreement and debt confession",
        "System": "System",
        "sentEmails": "Sent emails",
        "smsSent": "SMS sent",
        "systemUsers": "System users",
        "checksIssued": "Checks issued",
        "expensesForVacantProperties": "Expenses for vacant properties",
        "expensesPaidAndNotReceived": "Expenses paid and not received",
        "expensesByCostCenter": "Expenses by cost center",
        "expensesReceivedAndNotPaid": "Expenses received and not paid",
        "documentsForAnExpense": "Documents for an expense",
        "Revenues": "Revenues",
        "creditCardsWithIssues": "Credit cards with issues",
        "compliantCustomers": "Compliant customers",
        "compositionOfCharges": "Composition of charges",
        "reconciliationOfPaymentsAndInvoices": "Reconciliation of payments and invoices",
        "summaryOfChargesReceived": "Summary of charges received",
        "summaryOfoutstandingRevenues": "Summary of outstanding revenues",
        "creditAndDebitCardSummary": "Credit and debit card summary",
        "summaryOfPostDatedChecks": "Summary of post-dated checks",
        "summaryOfInvoices": "Summary of invoices",
        "occasionalIncome": "Occasional income",
        "sales": "Sales",
        "salesContracts": "Sales contracts",
        "commissionDetails": "Commission details",
        "salesDetails": "Sales details",
        "salesMirror": "Sales mirror",
        "noReportsFound": "No reports found.",
        "categoryNotFiltered": "Category not filtered.",
      },
      "reportSystem": {
        "Recipient": "Recipient",
        "beneficiary": "Beneficiary",
        "EmailStatus": "Email status",
        "Deleted": "Deleted",
        "Topic": "Topic",
        "NoRecords": "No Records",
        "Permission": "Permission"
      },
      "reportBanking": {
        "Accomplished": "Accomplished",
        "VariationRs": "Variation (R$)",
        "ReferenceMonth": "Reference month",
        "TotalForThePeriod": "Total for the period",
        "ExpenseStatus": "Expense status",
        "Liquidated": "Liquidated",
        "CreditCard": "Credit card",
        "Debitcard": "Debit card",
        "DebitCard": "Debit card",
        "Deposit": "Deposit",
        "PostDatedCheck": "Post-dated check",
        "AutomaticDebit": "Automatic debit",
        "BankTransfer": "Bank transfer",
        "DOC/TED": "DOC/TED",
        "TributeWithoutBarcode": "Tribute without barcode",
        "DebitedCredited": "Debited/Credited",
        "Debited": "Debited",
        "Credited": "Credited",
        "Client": "Client",
        "Suspended": "Suspended",
        "OurNumber": "Our number",
        "Leased": "Leased",
        "ClientType": "Client type"
      },
      "reportTransferDetail": {
        "transferDate": "Transfer date",
        "TransferStatus": "Transfer status",
        "PaidOut": "Paid out",
        "TransferConference": "Transfer Conference",
        "Fulfilled": "Fulfilled",
        "ToBeFulfilled": "To be fulfilled",
        "NotGuaranteed": "Not guaranteed",
        "FirstPayment": "First payment",
        "Renovation": "Renovation",
        "AdministrateTax": "Administrate tax",
        "TransferValue": "Transfer value",
        "ChargeAmount": "Charge amount",
        "Deal": "Deal",
        "NotIssued": "Not issued",
        "IssuedAndNotConverted": "Issued (And not converted)",
        "Converted": "Converted",
        "InvoiceStatus": "Invoice status",
        "Manager": "Manager"
      },
      "reportSales": {
        "Seller": "Seller",
        "Buyer": "Buyer",
        "saleType": "Sale type",
        "Commission": "Commission",
        "commissionStatus": "Commission status",
        "Finalized": "Finalized",
        "CollectorRaiser": "Collector/Raiser",
        "SalesBroker": "Sales broker",
        "AgencyBroker": "Agency broker",
        "CoordinatingBroker": "Coordinating broker",
        "Director": "Director",
        "RealEstateAgency": "Real estate agency",
        "Recommendation": "Recommendation",
        "Supervisor": "Supervisor",
        "SaleStatus": "Sale status",
        "Canceled": "Canceled",
        "SalesType": "Sales type",
        "DateOfSale": "Date of sale",
        "BranchTeam": "Branch/Team",
        "TotalCommission": "Total commission",
        "Commissioned": "Commissioned",
        "FromThirdParties": "From third parties",
        "NewReleases": "New releases",
        "Not specified": "Not specified"
      },
      "Report-Property": {
        "CondominiumType": "Condominium type",
        "NotManagedByTheRealEstateAsgency": "Not managed by the real estate agency",
        "ManagedByTheRealEstateAgency": "Managed by the real estate agency",
        "ManagedAndPaidByTheRealEstateAgency": "Managed and paid by the real estate agency",
        "OwnerWillLiveInTheProperty": "Owner will live in the property",
        "OwnerSoldTheProperty": "Owner sold the property",
        "PropertyWasAuctioned": "Property was auctioned",
        "StructuralProblemsInTheProperty": "Structural problems in the property",
        "DelayInRentingTheProperty": "Delay in renting the property",
        "DissatisfiedWithTheAdministration": "Dissatisfied with the administration",
        "OwnerWillManage": "Owner will manage",
        "Fallen": "Fallen",
        "ThirdParty": "Third party",
        "AtTheMoment": "At the moment",
        "Exempt": "Example",
        "IPTU": "IPTU",
        "GarbageFee": "Garbage fee",
        "Garage": "Garage",
        "Main": "Main",
        "Additional": "Additional",
        "BurdenSharing": "Burden sharing",
        "Shared": "Shared",
        "NotShared": "Not shared",
        "AdministrationOfTheCharge": "Administration of the charge",
        "ManagedByRealEstateAgency": "Managed by real estate agency",
        "ResponsibleForPayment": "Responsible for payment ",
        "PaymentStatus": "Payment status",
        "PropertyStatus": "Property status",
        "City/Estado": "City/Estado",
        "TotalValueBooklet": "Total value booklet",
        "IPTUCode": "IPTU code",
        "TokioMarine": "Tokio Marine",
        "Pretension": "Pretension",
        "InvalidationDate": "Invalidation date",
        "Perct": "Perct"
      },
      "Report-Contract": {
        "creationDate": "Creation date",
        "GuaranteedThroughoutTheContract": "Guaranteed throughout the contract",
        "GuaranteedForAFewMonths": "Guaranteed for a few months",
        "WithInsurance": "With insurance",
        "WithoutInsurance": "Without Insurance",
        "AdjustmentIndex": "Adjustment index",
        "Termination": "Termination",
        "OccupancyOfTheProperty": "Occupancy of the property",
        "VacatingTheProperty": "Vacating the property",
        "OccupationInMonths": "Occupation in months",
        "TerminationDate": "Termination date",
        "GroupedByReasonForTermination": "Grouped by Reason for Termination",
        "Normal": "Normal",
        "InVacancy": "In vacancy",
        "StartOfContract": "Start of contract",
        "BailInsurance": "Bail insurance",
        "Pledger": "Pledger",
        "ChecklistType": "Checklist type",
        "Entry": "Entry",
        "TaskStatus": "Task status",
        "Entregue": "Entregue",
        "WaitingForApproval": "Waiting for approval",
        "WithoutResponsible": "Without Responsible",
        "ReasonForCancellation": "Reason for cancellation",
        "TerminationOfTheRentalContract": "Termination of the rental contract",
        "TenantTookOutOtherInsurance": "Tenant took out other insurance",
        "HiredByMistake": "Hired by mistake",
        "ThePriceOfInsurance": "The price of insurance",
        "CoverageDoesNotMeetTheNeed": "Coverage does not meet the need",
        "CancellationDate": "Cancellation date",
        "ReleaseStatus": "Release status",
        "Released": "Released",
      },
      "Report-Revenues": {
        "ExpiredValue": "Expired value",
        "NextExpirationDate": "Next expiration date",
        "LastSuccess": "Last success",
        "Card": "Card",
        "Authorization": "Authorization",
        "Liquidation": "Liquidation",
        "BillingStatus": "Billing Status",
        "Invalidated": "Invalidated",
        "Protested": "Protested",
        "SentToTheNotary": "Sent to the notary",
        "Automatic": "Automatic",
        "PersonType": "Person type",
        "PFAndPJ": "PF and PJ",
        "PFOnly": "PF only",
        "PJOnly": "PJ only",
        "ExpirationStatus": "Expiration status",
        "TaxInvoiceStatus": "Tax invoice status",
        "AwaitingDeposit": "Awaiting deposit",
        "ShippingStatus": "Shipping status",
        "Converteds": "Converteds",
        "TransactionType": "Transaction type",
        "TransactionStatus": "Transaction status",
        "Flag": "Flag",
        "QuantityOfCharges": "Quantity of charges",
        "QuantityOfInvoices": "Quantity of invoices",
        "TaxableAmountPaid": "Taxable amount paid",
        "InvoiceValue": "Invoice value",
        "Difference": "Difference",
        "NumberOfCharges": "Number of charges"
      },
      "Report-Expenses": {
        "Favored": "Favored",
        "DocumentNumber": "Document number",
        "CheckStatus": "Check status",
        "PrintStatus": "Print status",
        "PastHistory": "Past history",
        "Emission": "Emission",
        "ParticipationR$": "Participation (R$)",
        "Nominal": "Nominal",
        "LiquidationDate": "Liquidation date",
        "ExpirationDate": "Expiration Date"
      },
      "Report-NonPayment": {
        "OldestOutstandingChargeEachCustomer": "This report only shows the oldest outstanding charge for each customer.",
        "SurveysNetwork": "Surveys Network",
        "DaysCredited": "Days / Credited",
        "Generation": "Generation",
        "Honorarium": "Honorarium",
        "DelayedDays": "Delayed days",
        "MainValue": "Main value",
        "UpdatedValue": "Updated value",
        "ResultInThePeriod": "Result in the period",
        "FinalBalance": "Final balance",
        "Credits": "Credits"
      },
      "NonPayment": {
        "DelayedChargeList": "Delayed charge list",
        "contact": "Contact",
        "defaulters": "Defaulters",
        "scheduledContact": "Scheduled contact",
        "noActionTake": "No action take",
        "contactnotMade": "Contact not made",
        "inconclusiveContact": "Inconclusive contact",
        "effectiveContact": "Effective contact",
        "manualNotification": "Manual notification",
        "automaticNotification": "Automatic notification",
        "individualLegalEntity": "Individual and legal entity",
        "delayTime": "Delay time",
        "communicationChannel": "Communication channel",
        "manualOrAutomaticAction": "Manual or automatic action",
        "manualActionOnly": "Manual action only",
        "automaticActionOnly": "Automatic action only",
        "didNotReceivInvoice": "Did not receive the invoice",
        "disagrees": "Disagrees",
        "reasonForDelinquency": "Reason for delinquency",
        "totalOverdue": "Total overdue",
        "correlationBetweenDelayTimeAmounts": "Correlation between delay time and amounts",
        "days": "days",
        "or": "or",
        "cash": "Cash",
        "individualsOnly": "Individuals only",
        "legalEntitiesOnly": "Legal entities only",
        "bankSlip": "bank slip",
        "messagingApp": "Messaging app",
        "letter": "Letter",
        "phone": "Phone",
        "other": "Other",
        "delayedContractsList": "Delayed contracts list",
        "sorting": "Sorting",
        "alphabeticalByClient": "Alphabetical by client",
        "longestDelayTime": "Longest delay time",
        "shortestDelayTime": "Shortest delay time",
        "highestValue": "Highest value",
        "LowestValue": "Lowest value",
        "statusUrgency": "Status urgency",
        "delay": "Delay",
        "recordAgreement": "Record agreement",
        "sendDuplicateCharge": "Send duplicate charge",
        "viewInterestDetails": "View interest details",
        "viewContactHistory": "See contact history",
        "flagProtest": "Flag protest",
        "removeflagProtest": "Remove Flag protest",
        "openOriginalCharge": "Open original charge",
        "Closeoriginalcharges": "Close original charges",
        "openContractDetails": "Open contract details",
        "openCustomerRecord": "Open customer record",
        "openGuarantorRecord": "Open guarantor record",
        "duplicateChargeSent": "Duplicate charge sent.",
        "monetaryUpdate": "Monetary update",
        "processMonetaryUpdate": "Process monetary update",
        "daysUpdateValuesAfterDueDate": "Days to update values after due date",
        "monetaryUpdateTable": "Monetary update table",
        "doNotConsiderFirstPaymentNonpayment": "Do not consider first payment charges as non payment",
        "emailResponsibleForRespondingNonpaymentCharges": "Email responsible for responding to non payment charges",
        "separateEmailAddressesSemicolon": "Separate email addresses by semicolon",
        "interestRateInstallment": "Interest rate for installment (%)",
        "allowChangingInstallmentInterestRate": "Allow changing installment interest rate",
        "blockCollectionAgreementsLinkedToDispatch": "Block collection agreements linked to dispatch",
        "enableThirdPartyExpenseDeletionAgreementSource": "Enable third-party expense deletion at the agreement source",
        "additionalInstructionsForAgreementCollections": "Additional instructions for agreement collections",
        "newAgreement": "New agreement",
        "paidAgreementCharge": "Paid agreement charge",
        "invoicePrinting": "Invoice printing",
        "emailBillingDelivery": "Email billing delivery",
        "generationOfDelinquencyLetter": "Generation of delinquency letter",
        "editAutomaticChargeTemplate": "Edit automatic charge template",
        "selectVariableYouWantTemplate": "Select the variable you want to use in the template. The sent billing will automatically replace the variables with real data.",
        "agreementNumber": "Agreement number",
        "agreementAmount": "Agreement amount",
        "dueDateFirstInstallment": "Due date of the first installment",
        "templateContent": "Template content",
        "onTime": "On time",
        "overdueInstallment": "Overdue installment",
        "listOfAgreedContracts": "List of agreed contracts",
        "generated": "Generated",
        "paidInstallments": "Paid installments",
        "undoAgreement": "Undo agreement",
        "WhenUndoingAgreementDefaultersList": "When undoing an agreement, the charge will return to the defaulters list.",
        "agreementDetails": "Agreement details",
        "originalCharges": "Original charges",
        "createElectronicEnvelope": "Create electronic envelope",
        "sendCharge": "Send charge",
        "clientInformation": "Client information",
        "openCompleteRecord": "Open complete record",
        "fixedLinePhone": "Fixed-line phone",
        "notifyEmail": "Notify via email",
        "scheduleContact": "Schedule contact",
        "time": "Time",
        "schedule": "Schedule",
        "emailSubject": "Email subject",
        "tenantNotifiedEmail": "Tenant notified via email",
        "agreementSuccessfullyRegistered": "Agreement successfully registered!",
        "youCanSendInvoiceCustomer": "You can now send the invoice to the customer, create an envelope for electronic signature, or print agreement terms.",
        "printCharge": "Print charge",
        "printAgreementTerms": "Print agreement terms",
        "printDetailedAgreement": "Print detailed agreement",
        "chargeSentCustomerEmail": "Charge sent to the customer via email.",
        "howWillPaymentMade": "How will the payment be made?",
        "inOnePayment": "In one payment",
        "inInstallments": "In installments",
        "continue": "Continue",
        "productOrService": "Product or service",
        "addition": "Addition",
        "newPaymentMethod": "New payment method",
        "addInstallment": "Add installment",
        "amortization": "Amortization",
        "interestDetails": "Interest details",
        "penalty": "Penalty",
        "accumulated": "Accumulated",
        "deleteProductOrService": "Delete product or service?",
        "whenDeletingActionCannotUndone": "When deleting, the action cannot be undone.",
        "contactHistory": "Contact history",
        "registerNewContact": "Register new contact",
        "resultTheContact": "Result of the contact",
        "register": "Register",
        "contactRegistered": "Contact registered",
        "Effective": "Effective",
        "Paid": "Paid",
        "Promise": "Promise",
        "Inconclusive": "Inconclusive",
        "Disagrees": "Disagrees",
        "Call back": "Call back",
        "Online negotiation": "Online negotiation",
        "Not made": "Not made",
        "Wrong number": "Wrong number",
        "Doesn't answer": "Doesn't answer",
        "Busy": "Busy",
        "Outros": "Others",
        "slug": "Slug",
        "monetary": "Monetary"

      },
      "dimbo": {
        "ToSetup": "To setup",
        "SendExtract": "Send extract",
        "ContractAdministeredPeriod": "contract administered in the period",
        "IntermediatedSalePeriod": "Intermediated sale in the period",
        "OwnersWhoWillDIMOB": "Owners who will be part of DIMOB",
        "ErrorFoundGeneration": "Error found for DIMOB generation",
        "CheckErrors": "Check errors",
        "NoPendencyFoundDeclaration": "No pendency found for DIMOB declaration",
        "LeaseAgreements": "Lease agreements",
        "SalesContracts": "Sales contracts",
        "IncomeReport": "Income report",
        "SendStatement": "Send statement",
        "SalesEntering": "Sales entering DIMOB",
        "NoDataFound": "No data found",
      },
      "sendEmail": {
        "SummaryFrom": "Summary from",
        "Recipient": "Recipient",
        "Message": "Message",
        "EmailDisplay": "Email display",
        "NotificationsOfDefault": "Notifications of Default",
        "hasAlreadySeen": "has already been seen",
        "EmailViewed": "Email viewed",
        "hoursAgo": "hours ago",
        "BouncingEmails": "Bouncing emails",
        "SentEmails": "Sent emails",
        "OfDeliverability": "Of deliverability",
        "ForceSend": "Force send",
        "RemoveEmailBounceList": "Remove email from bounce list",
        "Returning": "Returning",
        "unseen": "unseen",
        "emailsBeing": "emails being",
        "BouncingEmail": "Bouncing email",
        "RemoveEmailFromList": "Remove email from list",
        "RemoveEmailFromListContent": "If you believe this email is on this list by mistake, enter the code below to remove it. We respect good anti-spam practices and therefore it is not possible to remove multiple recipients from this list at once.",
        "ToRemove": "To remove",
      },

      "financialStatuses": {
        "normal": "Noraml",
        "legal": "Legal",
        "invacancy": "In Vacancy",
        "sinister": "Sinister",
        "pendingFeatures": "Pending Features",
        "labelsuspendCharges": "Suspend contract charges",
        "labelFinanceForm": "Change financial status"
      },
      "Invoices": {
        "invoices": "Invoices",
        "newServiceNote": "New service note",
        "batches": "Batches",
        "series": "Series",
        "Taxation": "Taxation",
        "printThisScreen": "Print this screen",
        "issued": "Issued",
        "canceled": "Canceled",
        "customers": "Customers",
        "notesFrom": "notes from",
        "withtagged": "With tagged",
        "NFEDialtoTransmit": "NFE: Dial to transmit",
        "unmarkfromtransmission": "NFE: Unmark from transmission",
        "sendbyemail": "Send by email",
        "printinvoicemirror": "Print invoice mirror",
        "changeissuedate": "Change issue date",
        "changeresponsiblecompany": "Change responsible company",
        "changethecompanyresponsible": "Change the company responsible for the invoice",
        "headquarters": "Headquarters",
        "laercioJoseIlkiu": "Laercio Jose Ilkiu",
        "inactive": "Inactive",
        "shipping": "Shipping",
        "Unmarked": "Unmarked",
        "issuance": "Issuance",
        "Additionaldata": "Additional data",
        "client": "Client",
        "value": "Value",
        "Pdf": "PDF",
        "exclamation": "Exclamation",
        "summary": "Summary",
        "tobemarked": "To be marked",
        "tagandtransmit": "Tag and transmit",
        "shippingpending": "Shipping pending",
        "none": "None",
        "transmitnotes": "Transmit notes",
        "expiredcertificate": "Expired certificate",
        "Tocontinueissuinginvoices": "To continue issuing invoices, send a new certificate.",
        "Addthecertificate": "Add the certificate",
        "Transmitnotes": "Transmit notes",
        "fromotherperiods": "from other periods",
        "Youmayprefertopost": "You may prefer to post invoices more efficiently using the charge generation process.",
        "Todothisdd": "To do this, add recurring and/or one-time revenues and invoice. Thus, charges and invoices will be issued automatically according to the rules defined for each customer.",
        "gradetotal": "Grade total",
        "Totalafterretention": "Total after retention",
        "Delivery": "Delivery",
        "Mainaddress": "Main address",
        "Secondaryaddress": "Secondary address",
        "Withholdings": "Withholdings",
        "deductions": "Deductions",
        "calculationbasis": "Calculation basis",
        "ISSQNvalue": "ISSQN value",
        "Toretain": "To retain",
        "IRRF": "IRRF",
        "PIS": "PIS",
        "CSocial": "C. Social",
        "INSS": "INSS",
        "COFINS": "COFINS",
        "Unitaryvalue": "Unitary value",
        "Itemtotal": "Item total",
        "Newdeduction": "New deduction",
        "DeductISSQN": "Deduct ISSQN",
        "DeductINSS": "Deduct INSS"
      },
      "billingrule": {
        "ticket": "Ticket",
        "notificationbyemail": "Notification by email",
        "altermessage": "The emails will be sent out at dawn and only after 3 days of their creation to avoid incorrect messages.",
        "sendfirstnotification": "Send first notification",
        "days": "Days",
        "sendsecondnotification": "Send second notification",
        "sendthirdnotification": "Send third notification",
        "sendfourthnotification": "Send fourth notification",
        "daysafterexpiration": "Days after expiration",
        "extrajudicialnotice": "Extrajudicial notice by letter",
        "systemwillautomatically": 'The system will automatically generate notification letters according to these settings. Users with 1000 "Administrative" or 1073 "Customers-Notify"',
        "accesswillreceive": "access will receive instructions via e-mail for printing the letters.",
        "period": "Period",
        "messages": "Messages",
        "notificationexpiration": "Notification text for boletos before expiration",
        "notificationboletosexpiration": "Notification text for boletos sent on expiration",
        "notificationtextexpiration": "Notification text for boletos after expiration",
        "lastnotificationtext": "Last notification text",
        "SMSNotification": "SMS Notification",
        "notification": "Notification",
        "tosendyourcustomer": "To send your customer the ticket access link, use the variable: %link_boleto% (30 characters)",
        "tosendyourvariable": "To send your customer the typeable line of the bank slip, use the variable: %linhadigitavel% (40 characters)",
        "notificationforunpaidtickets": "Notification for unpaid tickets",
        "charactersleft": "characters left."
      },
    }
  },
  pt: {
    translation: {
      "loginPage": {
        "title": "Impulsione sua imobiliária com automação e inteligência",
        "subtitle": "Libere o potencial da sua imobiliária, aumente suas vendas e esteja sempre à frente no mercado imobiliário",
        "knowimob": "Conheça o Kenlo Imob",
        "welcome": "Boas vindas",
        "kenlo": "Kenlo Locação",
        "isOnline": "está online",
        "notSignin": "Não está conseguindo entrar?",
        "chat": "Converse com nosso time."
      },
      "submit" : "Enviar",
      "verifying" : "Verificando",
      "activateAccountFirst": "Por favor ative a conta bancária primeiro",
      "mainAccountStatus": "Status da Conta Principal",
      "inActives": "In-Ativo",
      "recExpense": "dos quais são recorrentes.",
      "readjustContract": "Reajustar Contrato",
      "previousBalance": "Saldo anterior",
      "searchCategoriesAccount": "Digite para pesquisar categorias",
      "undetermined": "Indeterminado",
      "overDueExpense": "Despesas vencidas",
      "charge": "Cobrança",
      "noProportionalRent": "Sem aluguel proporcional para este contrato",
      "editExtraChargeLabel": "Editar cobrança",
      "reverse": "Estornar",
      "undo": "Desfazer",
      "reverseTransfer": "Transferência reversa",
      "reverseRecieved": "Reverso Recebido",
      "reasonForBaleto": "Motivo do Cancelamento do Boleto/Pix",
      "chargeClone": "Clone de Carga",
      "Indefinite": "Indeterminado",
      "Yessuspendedit!": "Yes, suspended it!",
      "Youwould'ntbeabletorevertthis!": "Você não seria capaz de reverter isso!",
      "Alert": "Alerta",
      "customDate": "Data personalizada",
      "batchSettlement": "Liquidação em lote",
      "sttleCustomDate": "Defina a data personalizada",
      "readjustment_date": "Data do Último Reajuste",
      "Supplieridentificationcode": "Código de identificação do fornecedor",
      "accountingcontable": "Código da conta contábil deste fornecedor para exportação de dados para contabilidade",
      "dueDaysErr": "Os dias de vencimento são necessários menos que os dias do mês atual",
      "Createelectronicenvelope": "Criar envelope eletrônico",
      "Keyreturnagreement": "Acordo de devolução de chaves",
      "KeyHandoverFileNotAvailable": "Arquivo de entrega de chaves não disponível",
      "TerminationAgreement": "Acordo de rescisão",
      "Viewtemplates": "Visualizar modelos",
      "Sendsignedterminationagreement": "Enviar acordo de rescisão assinado",
      "Inspectionreport": "Relatório de inspeção",
      "Generatereport": "Gerar relatório",
      "Sendsignedinspectionreport": "Enviar relatório de inspeção assinado",
      "Otherdocuments": "Outros documentos",

      "Cancelinstallments": "Cancelar parcelas",
      "Deleteservice": "Excluir serviço",
      "Bydeletibecharged": "Ao excluir este serviço, ele não será mais cobrado",
      "Othercharges": "Outras despesas",
      "Chargedata": "Dados da cobrança",
      "Thesettingsofthiscontrac": "As configurações deste contrato não incluem uma penalidade de rescisão",
      "Proportionaldays": "Dias proporcionais",
      "Proportionalamount": "Valor proporcional",
      "Chargeduedate": "Data de vencimento da cobrança",
      "Terminationpenalty": "Penalidade de rescisão",
      "Terminationfine": "Multa rescisória de rescisão",
      "Viewcalculation": "Visualizar cálculo",
      "Additionsanddeductions": "Adições e deduções",
      "Fee": "Taxa",
      "Totalchargeamount": "Valor total da cobrança",
      "DeductfromSecurityDep": "Descontar do depósito de segurança",
      "Securitydepositvalue": "Valor do depósito de segurança",
      "Observationsfortheclient": "Observações para o cliente",
      "Finalamount": "Valor final",
      "Keyreturnterm": "Prazo para devolução das chaves",
      "Sendkeyreturnterm": "Enviar prazo para devolução das chaves",
      "Generateterm": "Gerar prazo",
      "Releasetheproperty": "Liberar o imóvel para outro aluguel",

      "Deleteexpense": "Excluir despesa",
      "BydeletingServiceth": "Ao excluir o Serviço, a despesa não será mais debitada",
      "ByaddingServicetheterminationdocument": "Ao adicionar a taxa de Serviço, a despesa será levada em conta no cálculo final do documento de rescisão",
      "Deletedrecurringexpense": "Despesa recorrente excluída",
      "Endofexpense": "Fim da despesa",
      "Addtotermination": "Adicionar à rescisão",
      "Norecordsareaddedtotermination": "Nenhum registro foi adicionado à rescisão. Tem certeza de que deseja continuar.",
      "Inspectiondata": "Dados de inspeção",
      "Beneficiaries": "Beneficiários",
      "Repassedia": "Dia de repasse",
      "bloquendo": "Saldo bloqueado",
      "donotnotify": "Não notificar na regra de cobrança",
      "dependentsNo": "N° de dependents",
      "non_recedential": "Não domiciliado no Brasil",
      "Renda": "Renda",
      "possiu": "Não possui renda",
      "vinculo": "Vínculo empregatício",
      "beneficio": "Número do benefício",
      "rendimentous": "Outros rendimentos",
      "performThisAction": "Realize esta ação dentro do contrato",
      "New Agreement": "Novo acordo",
      "Paid Agreement": "Acordo pago",
      "Invoice Printing": "Impressão de fatura",
      "Email Billing": "Faturamento por e-mail",
      "Yearly": "Anual",
      "Monthly": "Mensal",
      "OriginalValue": "Valor Original",
      "sendInspection": "Enviar relatório de inspeção",
      "SupportedFormats": "Formatos suportados: PDF e PNG.",
      "AddFile": "Anexar arquivos",
      "chargeDescription": "Descrição da cobrança",
      "transferDescription": "Descrição de transferência",
      "totalCharge": "Carga total",
      "totalTransfer": "Transferência total",
      "propertyIdentifierID": "Identificação (ID) do imóvel no sistema",
      "nextRentalFee": "Próxima taxa de aluguel",
      "seeTenant": "Ver inquilino",
      "seeOwner": "Ver proprietário",
      "readjustments": "Reajustes",
      "listOfReadjustContacts": "Lista de Contratos de Reajuste",
      "seeAllIndices": "Ver todos os índices",
      "adjustmentIndices": "Índices de ajuste",
      "simulateReadjust": "Simular e reajustar",
      "contractReadjust": "Contratos serão reajustados",
      "checklistOwner": "O Proprietário é responsável por este item",
      "checklistTenant": "O Locatário é responsável por este item",
      "checklistGuarantor": "O Fiador é responsável por este item",
      "checkListRealEstate": "A imobiliária é responsável por esse item",
      "sendEmailActiveContracts": "Enviar e-mail: Proprietários de contratos ativos",
      "sendEmailActiveTenantContracts": "Send Email : Active Contracts Tenants",
      "sendEmailNonActiveContracts": "Enviar e-mail: proprietários de contratos não ativos",
      "sendEmailNonActiveTenantContracts": "Enviar e-mail: inquilinos de contratos não ativos",
      "observacoes": "OBSERVAÇÕES",
      "dateCapture": "Data de captação",
      "propertyPer": "imóvel %",
      "contaCorr": "Conta corrente do proprietário",
      "util": "Utilizar o Conta Corrente do Proprietário?",
      "utilDesc": "Ao decidir utilizar o Conta Corrente do Proprietário não será possível desfazer esta operação após salvar o imóvel",
      "selectFolder": "Selecione a pasta do modelo",
      "selectTemplate": "Selecione o modelo",
      "postponeAdjustment": "Adiar ajuste",
      "postponeAdjustmentFor": "Adiar ajuste para",
      "Has no warranty": "Não possui garantia",
      "Guarantor": "Fiador",
      "Security deposit": "Caução",
      "Surety bond": "Fiança ligação",
      "Capitalization bond": "Bônus de capitalização",
      "Surety": "Fiança",
      "Fiduciary assignment": "Atribuição fiduciária",
      "RemoveSettledCharges": "Remover cobranças liquidadas",
      "detail": "detalhe",
      "contractDetail": "Detalhe do contrato",
      "notConnectedcontract": "Contrato não conectado",
      "Install": "Parcelado",
      "Nao": "Não",
      "createCharge": "Criar cobrança",
      "lunchCharge": "Lançar na próxima cobrança",
      "toDisregard": "Ignorar",
      "diffAmt": "O que fazer com a diferença de",
      "chargeDate": "Data de cobrança",
      "Mensalmente": "Mensalmente",
      "Bimestralmente": "Bimestralmente",
      "Trimestralmente": "Trimestralmente",
      "Semestralmente": "Semestralmente",
      "Anualmente": "Anualmente",
      "chargeSimulation": "Simulação de Carga",
      "generateSimulation": "Gerar simulação",
      "numberChargesGenerated": "Número total de cobranças a serem geradas",
      "chargeGenerated": "Total de cobranças a serem geradas",
      "clientBilled": "Total de clientes a serem cobrados",
      "amountChargesGenerated": "Quantidade total de cobranças a serem geradas",
      "amountWithholdings": "Quantidade total de retenções",
      "numberCharges": "Quantidade de cobranças que não atingiram o valor mínimo",
      "amountCharges": "Quantidade de cobranças que não atingiram o valor mínimo",

      "create": "Criar",
      "electronicEnvelope": "Envelope Eletrônico",
      "EditallSubsequentExpenses": "Editar todas as Despesas Subsequentes",
      "EnsureCreditDateAfterSaleDate": "Garanta a data de crédito após a data de venda",
      "unforgiving": "Desquitado",
      "single": "Avulso",
      "married": "Casado",
      "feminine": "Feminino",
      "masculine": "Masculino",
      "every": "Todo",
      "closeMonth": "meses fechado",
      "openMonth": "Mês aberto",
      "fees": "Juros (%)",
      "trafficTicket": "Multa (%)",
      "transfer_forecast": "Previsão de transferência",
      "foreverycontract": "para cada contrato",
      "uninformed": "Não informado",
      "singlephase": "Monofásico",
      "fixedvalue": "Valor fixo",
      "percentual": "Percentual",
      "biphasic": "Bifásico",
      "threephase": "Trifásico",
      "undefined": "Indefinido",
      "focusonlyonrent": "Foco apenas no aluguel",
      "urbanproperty": "Imóvel urbano",
      "ruralproperty": "Imóvel rural",
      "focusonrent": "Foco no aluguel e outros serviços",
      "periodfilter": "Filtro de período",
      "expiredMonth": "Mês vencido",
      "Monthtoexpire": "Mês a vencer",
      "monthDue": "Mês a vencer",
      "proportionRent": "Aluguel Proporcional",
      "infosuccesschange": "Informação salva com sucesso.",
      "chargesGenerated": "Cobranças geradas",
      "simulateAll": "Simular tudo",
      "contractToReadjust": "Contrato para reajustar",
      "Welcome to React": "Bem vindo ao React",
      "Broker": "Corretor",
      "contracts": "Contratos",
      "AccessMetrixs": "Acessar Métricas",
      "FormsOfReceipts": "Formas de Recebimento",
      "PageNotFound": "Página Não Encontrada",
      "profile": "Perfil",
      "InvalidateProperties": "Invalidar Imóveis",
      "PropertyCharges": "Cobranças de Imóvel",
      "DetailedAgreements": "Acordos Detalhados",
      "Termofagreementandacknowledgmentofdebt": "Termo de Acordo e Reconhecimento de Dívida",
      "DefaultSummary": "Resumo de Inadimplência",
      "Defaultwithcomposition": "Inadimplência com Composição",
      "newContract": "Novo contrato",
      "expenses": "Despesas",
      "ofthecontract": "do contrato",
      "pChecklists": "Checklists pendentes",
      "Checklists": "Listas de verificação",
      "Addchecklist": "Adicionar checklist",
      "AddchecklistItem": "Novo item",
      "tchecklists": "Modelos de checklist",
      "datanotfound": "Dados não encontrados",
      "OOPS": "Nenhum registro encontrado",
      "additionalbillinginstruct": "Instruções adicionais de cobrança",
      "messageonalltickets": "Mensagem em todos os boletos",
      "ticketonthesecondcopy": "Atualize o valor do boleto na segunda via",
      "daytounavailable": "Dias para a 2ª via indisponível",
      "insurers": "Seguradoras",
      "AgencyAccount": "Conta da Agência",
      "active": "Ativa",
      "disabled": "desabilitado",
      "terminated": "Rescindido",
      "terminateds": "Rescindidos",
      "pending": "Pendente",
      "paused": "Pausado",
      "Deactive": "Desativado",
      "finished": "Finalizado",
      "Branch": "Filial",
      "tenant": "Locatário",
      "real estate": "imobiliária",
      "bankAccount": "Conta bancária",
      "LeaseDetailsRent": "Detalhes do Contrato de Aluguel",
      "thepropertyforthiscontract": "Você não pode cancelar a liberação da propriedade para este contrato",
      "intheamountof": "no valor de",
      "paymentSplit": "Split de pagamento",
      "withSplit": "Com split",
      "withoutSplit": "Sem split",
      "manager": "Gestor",
      "moreFilters": "Mais filtros",
      "overview": "Visão geral",
      "summaryMonth": "Resumo no mês",
      "conquered": "Conquistado",
      "toReadjust": "Para reajustar",
      "noRentalFee": "Sem taxa de locação",
      "listOfContracts": "Lista de contratos",
      "contract": "Contrato",
      "rent": "Aluguel",
      "adminFee": "Taxa de administração",
      "Property": "Imóvel",
      "recordFailed": "Erro ao cadastrar modelo",
      "templateSaved": "Modelo Salvo",
      "yesConfirm": "Sim, Eu confirmo!",
      "confirmTemplateSelection": "Tem certeza que quer selecionar {{type}}? Não será possível reverter isso!",
      "seeProperty": "Ver Imóvel",
      "seeContract": "Ver Contrato",
      "totalAdminFee": "Taxa de administração",
      "contractMirror": "Espelho do contrato",
      "contractlabel": "Etiqueta do contrato",
      "mailingLabel": "Etiqueta de envio",
      "most": "Maioria",
      "chgFncStatus": "Alterar status financeiro",
      "changeStatus": "Alterar status",
      "changemain": "Alterar principal",
      "updateStatus": "Atualizar o status",
      "chgBankFee": "Alterar taxa bancária DOC/TED",
      "chgBankAccount": "Alterar conta bancária",
      "chgRdIndex": "Alterar índice de reajuste",
      "chgDIMOBOpt": "Alterar DIMOB",
      "chgSplitOpt": "Alterar split",
      "chgInvoiceOpt": "Alterar emissão de NF",
      "chgBillingMethod": "Alterar forma de cobrança",
      "chgTenantBankFee": "Cobrar taxa bancária do inquilino",
      "inviteOwnerAreas": "Convidar para Áreas do Proprietário e Locatário",
      "changeGtdTsfCnt": "Alterar repasse garantido de contratos",
      "changeGtdTsf": "Change to not pass on fines and interest in guaranteed transfer",
      "commercialAgents": "Agentes comerciais",
      "financialStatus": "Status financeiro",
      "readjustmentIndex": "Índice de reajuste",
      "incomeTaxStatus": "Status de imposto de renda",
      "formOfPayment": "Forma de pagamento",
      "warranty": "Garantia",
      "insurance": "Seguro",
      "checklist": "Checklist",
      "checklistStatus": "Status do Checklist",
      "dueDate": "Vencimento",
      "dueDays": "Dias de vencimento",
      "expireDate": "Data de validade",
      "currentAccount": "Conta corrente",
      "SavingAccount": "Conta poupança",
      "whichProperty": "Imóvel",
      "whichTenant": "Locatário",
      "whatGuaranteeUsed": "Garantia",
      "doFireInsurance": "Seguro incêndio",
      "contractDetails": "Detalhes do contrato",
      "transferRent": "Repasse de aluguel",
      "whatStatusCont": "Status do contrato",
      "activeProcontract": "Produtos ativos no contrato",
      "immobile": "Imóvel",
      "immobilePlaceholder": "Identificador, Endereço, CEP, Tipo de propriedade",
      "owner": "Proprietário",
      "contractType": "Tipo de contrato",
      "residential": "Residencial",
      "nonResidential": "Não residencial",
      "comercial": "Comercial",
      "indústria": "Indústria",
      "temporada": "Temporada",
      "misto": "Misto",
      "porEncomenda": "Por encomenda",
      "non-residential": "Não residencial",
      "commercial": "Comercial",
      "industry": "Industrial",
      "season": "Temporada",
      "byOrder": "Por ordem",
      "mixed": "Misto",
      "businessActivity": "Atividade comercial",
      "taxations": "Tributação",
      "invoice": "Nota fiscal",
      "doSystem": "Não emitir pelo sistema",
      "issueBySystem": "Emitir pelo sistema",
      "incomeTax": "Imposto de renda",
      "doesTax": "Não retém imposto",
      "withholdsSystem": "Retém, mas o recolhimento não será controlado pelo sistema",
      "withholdTaxes": "Reter, e a imobiliária é quem recolhe e paga impostos",
      "withholdCollects": "Reter, e o inquilino recolhe",
      "deductIRRF": "Deduzir a taxa de administração no cálculo automático do IRRF.",
      "followGeneralSetup": "Seguir a configuração geral",
      "select": "Selecionar",
      "yesContract": "Sim, apenas para este contrato",
      "noJustContract": "Não, apenas para este contrato",
      "checkDIMOB": "Se marcada, aparecerá no DIMOB e no relatório de rendimentos gerado pelo sistema",   // add new
      "suspendInfo": "Selecione essa opção para suspender todas as cobranças desse contrato (cobranças suspensa não podem ser visualizadas pelos locatários).",                 // add new
      "selectTenant": "Selecione um locatário ou clique no ícone para cadastrar um novo",                 // add new
      "billingAddress": "Endereço de Cobrança",
      "useRentalPtyAddress": "Usar o endereço do imóvel alugado",
      "useTenantAddress": "Usar endereço do locatário",
      "setAnotherAddress": "Definir outro endereço",
      "sex": "Sexo",
      "birth": "Data de nascimento",
      "maritalStatus": "Estado civil",
      "nationality": "Nacionalidade",
      "zipCode": "CEP",
      "address": "Endereço",
      "number": "Número",
      "site": "Site",
      "complement": "Complemento",
      "neighborhood": "Bairro",
      "city": "Cidade",
      "guarantee": "Garantia",
      "guarantor": "Fiador",
      "securityDeposit": "Caução",
      "suretyBond": "Seguro-fiança",
      "capitalizationBond": "Título de capitalização",
      "surety": "Caucionante",
      "fiduciaryAssignment": "Cessão fiduciária",
      "hasNoWarranty": "Não tem garantia",
      "details": "Detalhes",
      "type": "Tipo",
      "value": "Valor",
      "vehicle": "Veículo",
      "letterGuarantee": "Carta fiança",
      "others": "Outros",
      "realEstate": "Imobiliária",
      "responsible": "Responsável",
      "description": "Descrição",
      "identifier": "Identificador",
      "insurer": "Segurador",
      "Doesnothaveinsurance": "Não possui seguro", //Add new
      "Typeofactivity": "Type of activity",
      "insurencefireDescription": "Cadastrando o seguro incêndio do contrato, o sistema te alertará sobre os prazos de renovação.",
      "policy": "Apólice",
      "numberInstallments": "Número de parcelas",
      "bank": "Banco",
      "backgroundType": "Tipo de plano de fundo",
      "applicationNumber": "Número da aplicação",
      "realState": "Imobiliária",
      "DaysOfContract": "Dias de contrato",
      "Daysendcontract": "Dias até o fim do contrato",
      "inMonths": "Em Meses",
      "inDays": "Em Dias",
      "in": "Em",
      "until": "Até",
      "observation": "Observações",
      "no": "Não",
      "yes": "Sim",
      "start": "Início",
      "end": "Fim",
      "assuredValue": "Valor garantido",
      "prizeAmount": "Valor do prêmio",
      "installmentValue": "Valor da parcela",
      "insuranceCompany": "Seguradora",
      "contractedPlan": "Plano contratado",
      "duration": "Duração (em meses)",
      "rentValue": "Valor do aluguel",
      "graceDays": "Dias de Carência",
      "occupation": "Ocupação",
      "1stRent": "1º aluguel proporcional",
      "value1stRent": "Valor do 1º aluguel",
      "charge2ndRent": "Cobrar junto com o 2º aluguel.",
      "copyuserdata": "Copiar dados do usuário",
      "withtagged": "Com os marcados",
      "readjustment": "Reajuste",
      "more": "Mais",
      "billingMethod": "Forma de cobrança",
      "Fire": "Fogo",
      "lightning": "raio",
      "explosion": "explosão",
      "smoke": "fumaça",
      "aircraftcrash": "queda de aeronave",
      "Gale": "Vendaval",
      "hurricane": "furacão",
      "cyclone": "ciclones",
     "tornado": "tornado e granizo",
      "hail": "granizo",
      "ResponsabilidadeCivil": "Responsabilidade Civil",
      "Theftofgoods": "Roubo de bens por arrombamento e assalto",
      "Glass": "Vidro",
      "mirrors": "espelhos",
      "marble": "mármore",
      "Rentalexpenses": "Despesas de aluguel",
      "Windstorms": "Tempestades de vento",
      "Documentrestoration": "Restauração de documentos",
      "Assistance": "Assistência",
      "Locksmith": "Chaveiro",
      "ElectricalLabor": "Serviço Elétrico",
      "PlumbingLabor": "Serviço de Encanamento",
      "TemporaryRoofCovering": "Cobertura Temporária de Telhado",
      "InstallationofSimpleLocks": "Instalação de Fechaduras Simples",
      "InstallationofTetraLocks": "Instalação de Fechaduras Tetra",
      "amongOthers": "entre outros",
      "unclogging": "desentupimento",
      "electrician": "eletricista",
      "plumber": "encanador",
      "electricaldamage": "dano elétrico",
      "removalAndFurnitureStorage": "remoção e armazenamento de móveis",
      "glazier": "vidraceiro",
      "billingMethods": {
        "expired": "A cobrança é gerada após a entrada no imóvel, ou seja, o locatário mora e depois paga.",
        "due": "A cobrança é gerada antes da entrada no imóvel, ou seja, o locatário paga e depois mora."
      },
      "deliveryMethod": "Forma de Entrega",
      "mailOnly": "Somente correio",
      "digitalOnly": "Somente digital",
      "exemptionFine": "Isenção de multa rescisória",
      "exemptionFineTooltip": "Indique se o contrato prevê isenção da multa rescisória. Essa informação será usada para calcular a rescisão deste contrato.",
      "noExemption": "Sem isenção",
      "free": "Isento",
      "exemptionMonths": "Meses de isenção",
      "monthsExemption": "Meses para isenção de multa",
      "leaseMonth": "Período de aluguel com mês fechado.",
      "leaseMonthTooltip": "Se essa opção for selecionada, no aluguel com período de mês fechado, a cobrança será feita com base nos dias do mês. Por exemplo, se o aluguel vence no dia 20, o período cobrado será do primeiro dia até o último dia do mês (01 até 30).",
      "differentContract": "Juros, multas e descontos diferentes para este contrato",
      "differentContractTooltip": "Selecione essa opção apenas se o contrato possuir juros, multa e desconto diferente do que foi preenchido em taxas praticadas.",
      "Interest": "Juros(%)",
      "Much": "Muito",
      "Fine": "Multa(%)",
      "punctualityDiscount": "Desconto por pontualidade (%)",
      "chargeBankFee": "Cobrar taxa bancária do locatário",
      "chargeBoletoFee": "Cobrar taxa Boleto do locatário",
      "commercialManager": "Gerente comercial",
      "additionalClauses": "Cláusulas adicionais",
      "monthlyAdminRate": "Taxa de administração mensal",
      "adminrate": "Taxa de administração",
      "Leaserate": "Taxa de locação",
      "doFixedAmount": "Usar valor numérico",
      "automaticPayment": "Repasse automático via Split",
      "manualPayment": "Repasse manual",
      "transferType": "Tipo de repasse",
      "businessPayment": "dias úteis após o pagamento do aluguel",
      "fixedDay": "Dia fixo",
      "calendarPayment": "Dias corridos após o pagamento do aluguel",
      "transferDay": "Dia do repasse",
      "guaranteedTransfer": "Repasse garantido",
      "doesNotHave": "Não tem",
      "guaranteeFewMonths": "Garantido por alguns meses",
      "guaranteeContract": "Garantido por todo o contrato",
      "months": "Meses",
      "chargeDocBankFee": "Cobrar taxa bancária",
      "generatedimob": "Gerar DIMOB pelo sistema",
      "price": "Valor",
      "itIsReady": "Está pronto, é possível gerar cobranças",
      "itHasPending": "Possui pendências, não é possível gerar cobranças",
      "chooseCheckTemplate": "Modelo de checklist",
      "contractGenerated": "Contrato gerado com sucesso!",
      "printOut": "Imprimir",
      "launchRentalFee": "Lançar Taxa de Locação",
      "accessContract": "Acessar Contrato",
      "registerAthContract": "Cadastrar outro contrato",
      "close": "Fechar",
      "previous": "Anterior",
      "next": "Próximo",
      "save": "Salvar",
      "generateCharges": "Gerar cobranças",
      "generateCharge": "Gerar cobrança",
      "numberMonths": "Número de meses",
      "generateInfo": "Não se esqueça de inserir despesas como IPTU e taxas de condomínio no período em que as cobranças são geradas.",
      "generateInfo2": "Simule as cobranças para garantir que sejam geradas corretamente.",
      "simulate": "Simular",
      "historic": "Histórico",
      "initiateTermination": "Iniciar rescisão",
      "cancelTermination": "Cancelar a rescisão",
      "successCancledTermination": "Rescisão cancelada com sucesso",
      "pauseContract": "Pausar contrato",
      "activeContract": "Ativar contrato",
      "notifyContract": "Notificar as partes do contrato",
      "tag": "Marcação",
      "keyTerm": "Termo de entrega de chaves",
      "fireInsurance": "Informações para solicitar o seguro incêndio",
      "checkList": "Checklist",
      "documents": "Documentos",
      "overdueCharges": "Cobranças atrasada",
      "overdueCharge": "Cobrança atrasada",
      "transfersComplete": "repasse para realizar",
      "transfersCompletes": "repasses para realizar",
      "monthsGuaranteeds": "Meses já garantidos",
      "monthsGuaranteed": "Mese já garantidos",
      "changeContract": "Alterar contrato",
      "renewContract": "Renovar Contrato",
      "house": "Casa",
      "monthExpired": "Mês vencido",
      "admTxt": "Taxa de administração",
      "Servicesprovision": "Prestação de serviços",
      "Adminfeeisleviedon": "A taxa de administração é cobrada sobre",
      "Changeservicedelivery": "Alterar serviço",
      "Monthsforexemptionfromterminationfine": "Meses para isenção de multa de rescisão",
      "Maximumleaseterm": "Prazo máximo do contrato de locação",
      "Leasetax": "Taxa de locação",
      "nfsE": "NFS-e",
      "contractInvoice": "Este contrato emite Nota Fiscal",
      "transfer": "Repasse",
      "transferRate": "Taxa de repasse",
      "billingEnd": "Endereço de cobrança",
      "deliveryForm": "Forma de envio",
      "Monthyear": "Mês ano",
      "index": "Índice",
      "notFound": "Não encontrado",
      "nextReadjustment": "Próximo reajuste",
      "lastReadjustment": "Ultimo reajuste",
      "locator": "Proprietário",
      "FireInsurance": "Seguro Incêndio",
      "renewInsurance": "Renovar seguro",
      "addInsurance": "Adicionar seguro",
      "getQuote": "Obter cotação",
      "validity": "Validade",
      "from": "De",
      "to": "ate",
      "renewWarranty": "Renovar garantia",
      "addwarranty": "Adicionar garantia",
      "danielBeck": "Daniel Beck",
      "maturity": "Vencimento",
      "viewExpense": "Ver despesas",
      "newExpense": "Nova despesa",
      "newRecurringExp": "Nova despesa recorrente",
      "Expensesfor": "Despesas para",
      "withBarcode": "Com código de barras",
      "rentalFee": "Taxa de locação",
      "issuedChecks": "Cheques emitidos",
      "selectAll": "Selecionar tudo",
      "inProgress": "Em andamento",
      "inActive": "In ativos",
      "All": "Todos",
      "Electronicenvelope": "Envelope eletrônico",
      "Newenvelope": "Novo envelope",
      "Noenvelopefound": "Nenhum envelope encontrado",
      "Contractchargesin": "Cobranças do contrato em",
      "Newextracharge": "Nova taxa extra",
      "Totherenter": "Ao locatário",
      "Totheowner": "Ao proprietário",
      "Settledcharges": "Cobranças pagas",
      "chargesclienttotal": "Total das cobranças",
      "Client": "Cliente",
      "Transfersmadein": "Transferências realizadas em",
      "Groupedstatementof": "Extrato agrupado de",
      "Report": "Relatório",
      "Printkeydeliveryterms": "Imprimir termos de entrega de chaves",
      "Keyhandoverdate": "Data de entrega das chaves",
      "KeyHandOver": "Entrega de Chave",
      "arianMsg": "Por favor, configure a conta bancária do proprietário.", // add new  
      "checklistEmpty": "A lista de verificação está vazia.",   // add new  
      "settled": "Liquidados",
      "scheduled": "Agendado",                            // add new  
      "checkstoclear": "Cheques a compensar",                   // add new  
      "renewWarrantyTitle": "Cadastrando a garantia desejada, o sistema te alertará sobre os prazos de renovação.",
      "show": "Mostrar",
      "entries": "entradas",
      "award": "prêmio",
      "assuredAmount": "Importância segurada",
      "numberInstallment": "Número de parcelas",
      "contractPlan": "Plano de contrato",
      "total": "Total",
      "saveChanges": "Salvar edição",
      "attachFile": "Anexar arquivo",
      "cloneproperty": "Clonar imóvel",
      "invalidzipcode": "CEP inválido",
      "actions": "Ações",
      "amount": "Valor",
      "Amount": "Quantidade",
      "Details": "Detalhes",
      "NoRecords": "Nenhum registro foi encontrado!",
      "tobereceived": "a ser recebido",
      "help": "Ajuda",
      "Processing": "Processando...",
      "Alreadyconnected": "Já conectado ao Plug Notes",
      "AlreadyconnectedKenloSlip": "Já conectado ao",
      "connecttoplugnotus": "Conecte-se ao Plug Notes",
      "changeperson": "Alterar pessoa",
      "Sendemail": "Enviar e-mail",
      "Contractswithoverdue": "Contratos com cobranças em atraso",
      "Defaultofcontracts": "Inadimplência de contratos",
      "Extractforincome": "Extrato para declaração de imposto de renda",
      "Incomegroupedby": "Renda agrupada por inquilino",
      "Currentaccountstatement": "Extrato da conta corrente",
      "Transferstatement": "Extrato de transferências de",
      "Groupedstatement": "Extrato agrupado",
      "Unrealizedtransfers": "Transferências não realizadas",
      "Transfersmade": "Transferências realizadas",
      "Toadd": "Para adicionar",
      "Registerother": "Registrar outros métodos de pagamento para o proprietário",
      "oneprimaryaccount": "Por favor, selecione pelo menos uma conta principal!",
      "Primary": "Principal",
      "BankDetails": "Detalhes Bancários",
      "Currentaccount": "Movimentações da conta corrente em",
      "Separate": "Separado",
      "Forthird": "Para terceiro",
      "Blockowner": "Bloquear saldo do proprietário",
      "Ticketfor": "Bilhete para",
      "Internalticket": "Bilhete para",
      "Seealltickets": "Ver todos os bilhetes",
      "Emaillist": "Lista de e-mails",
      "Message": "Mensagem",
      "Recenttickets": "Bilhetes recentes",
      "emails": "E-mails",
      "sentandwith": "enviado e 0 com erros",
      "Realestatedetails": "Detalhes do imóvel",
      "Date": "Date",
      "ChangeMade": "Alteração Feita",
      "Amendmant": "Emenda",
      "tenantcharges": "Locatário cobranças",
      "ownercharges": "Proprietário cobranças",
      "Disablefeature": "Desativar recurso: faturamento",
      "Disablefeaturehistory": "Desativar recurso: histórico de faturamento",
      "Disableactiveconfirmation": "Desativar confirmação ativa",
      "Dontshowfuturecharges": "Não mostrar cobranças futuras",
      "Emailadministrators": "Enviar e-mail para administradores ao atualizar o plano de contas",
      "Enabledailycashier": "Ativar caixa diário",
      "Activateoption": "Ativar opção para selecionar a incidência e retenção de impostos no Simples Nacional",
      "CancelBoleto": "Cancelar Boleto",
      "CancelRegenrateBoleto": "Cancelar e regenerar boleto",
      "CancelRegenrate": "Alterar vencimento",
      "MarkAsPaid": "Marcar como pago",
      "CheckPaymentStatus": "Verificar status do pagamento",
      "Showlist": "Mostrar lista",
      "EmailDownloads": "Clique para baixar e ver o conteúdo",
      "Addcontact": "Adicionar contato",
      "updatecontact": "Atualizar contato",
      "Maximummonths": "Máximo de 30 meses permitidos",
      "pendingitems": "itens pendentes",
      "awaitingapproval": "aguardando aprovação",
      "Editbilling": "Editar cobrança",
      "enter": "entrar",
      "paid": "Pagas",
      "Senddocument": "Enviar documento por e-mail",
      "DownloadableFileLinkBelow": "Por favor, encontre o link para download do arquivo abaixo",
      "DownloadFile": "Clique para baixar o arquivo",
      "Documentlist": "Lista de documentos",
      "ativarDesativar": "Ativar/Desativar Serviço",
      "guaranteed": "Garantido",
      "In": "De",
      "Residential": "Residencial",
      "Non-residential": "Não residencial",
      "Commercial": "Comercial",
      "Industry": "Industrial",
      "Season": "Temporada",
      "Mixed": "Misto",
      "By order": "Por ordem",
      "Unpaid": "Não pago",
      "Paid": "Pago",
      "Pendentes": "Pendentes",
      "Managedproperties": "Imóveis administrados",
      "ContractAmendmants": "Alterações Contratuais",
      "noCalculateAdminFee": `Como as configurações do contrato permitem a emissão da tributação pelo sistema, para selecionar a taxa de administração é necessário conectar o código CNAE ao serviço “Taxa de administração.`,
      "Contractalerts": "Contrato precisa ser reajustado.",
      "toreadjust": "para ver os contratos para reajustar.",
      "expired": "Expirado",
      "editrecurringexpense": "Editar despesa recorrente",
      "Guaranteeproductincontracts": "Produto garantido em contratos com transferência garantida",
      "ownerEmailnotEqualAgencyEmail": "O e-mail do proprietário não pode ser igual ao e-mail da agência.",
      "yourproperty": "Sua propriedade",
      "broker": "Corretores",
      "inspector": "Vistoriadores",
      "supplier": "Fornecedores",
      "year": "ano",
      "years": "anos",
      "ago": "há",
      "announced": "Anunciar",
      "CancelInsurance" : "Cancelar seguro",
      "defaultPaymentMethod1": {
        "chequedeposit": "Depósito em cheque ",
        "cashdeposit": "Depósito em dinheiro",
        "pickupchequeonsite": "Retirar cheque no local",
        "withdrawmoneyonthespot": "Retirar dinheiro no local",
        "banktransfer": "Transferência bancária",
        "docted": "Doc/Ted",
        "pixboleto": "Pix/Boleto",

      },
      "propertyTypeData": {
        "Casa": "Casa",
        "Apartamento": "Apartamento",
        "ApartamentoDuplex": "Apartamento Duplex",
        "ApartamentoTriplex": "Apartamento Triplex",
        "Áreacomum": "Área comum",
        "Andarcorporativo": "Andar corporativo",
        "Bangalo": "Bangalô",
        "Barracao": "Barracão",
        "BoxGaragem": "Box/Garagem",
        "Casacomercial": "Casa comercial",
        "Casaemcondominio": "Casa em condomínio",
        "CasaAssobradada": "Casa Assobradada",
        "Cobertura": "Cobertura",
        "Chacara": "Chácara",
        "Conjunto": "Conjunto",
        "Edicula": "Edícula",
        "Escritorio": "Escritório",
        "Fazenda": "Fazenda",
        "Flat": "Flat",
        "Galpao": "Galpão",
        "Garagem": "Garagem",
        "Garden": "Garden",
        "Haras": "Haras",
        "Hotel": "Hotel",
        "Ilha": "Ilha",
        "Kitnet": "Kitnet",
        "Laje": "Laje",
        "Loft": "Loft",
        "Loja": "Loja",
        "Pavilhao": "Pavilhão",
        "Penthouse": "Penthouse",
        "Predio": "Prédio",
        "Pousada": "Pousada",
        "Ponto": "Ponto",
        "Quiosque": "Quiosque",
        "Rancho": "Rancho",
        "Resort": "Resort",
        "Salao": "Salão",
        "Salacomercial": "Sala comercial",
        "Sitio": "Sítio",
        "Sobrado": "Sobrado",
        "Studio": "Studio",
        "Terreno": "Terreno",
        "Village": "Village",
        "Consultorio": "Consultório",
        "Outro": "Outro",
        "Realstateagency": "Agência imobiliária",
        "Thirdpart": "Terceira parte"
      },
      "envelop": {
        "Person's": "Nome da pessoa",
        "E-mail": "E-mail",
        "Type": "Tipo",
        "Witnesses": "Testemunhas",
        "Insertrealestatesignature": "Inserir assinatura",
        "Setorderforsendinge-mailforelectronicsignatures": "Definir ordem para enviar e-mail para assinaturas eletrônicas",
        "signature": "assinatura",
        "1st": "1º",
        "2nd": "2º",
        "3rd": "3º",
        "4th": "4º",
        "Newwitness": "Nova testemunha",
        "responsible": "Responsável",
        "templates": "Modelos",
        "subcpf": "subcpf",
        "ownersRealEstate": "proprietário e/ou imobiliária",
        "witnesses": "testemunhas",
        "tenants": "inquilinos"
      },

      "TransferPage": {
        "Batchprocess": "Processamento em Lote",
        "Accesscurrentaccounttransfer": "Acessar Transferência da Conta Atual",
        "Transfersmade": "Repasses Realizados",
        "Transactionsmade": "Movimentações financeiras",
        "Guaranteedandrealizedtransfer": "Transferência Garantida e Realizada",
        "Transferstobecarriedout": "Repasses a Serem Realizads",
        "Ownersextract": "Extrato dos Proprietários",
        "Simplifiedownerstatement": "Demonstrativo Simplificado do Proprietário",
        "Transfersbybank": "Repasses por Banco",
        "Transferinvoices": "Faturas de Repasses",
        "Filterby": "Filtrar por",
        "Tocarryout": "Realizar",
        "Performed": "Realizadas",
        "Withpaidcharges": "Com cobranças Pagas",
        "Withchargessettledbetween": "Com Cobranças Liquidadas entre",
        "Duetotheduedatebetween": "Com vencimento entre",
        "Pendingfrom": "Pendentes de",
        "Transfertocarryout": "Repasses a Realizar",
        "Invoicestobegenerated": "Notas Fiscais a Serem Geradas",
        "Generateinvoices": "Gerar Notas Fiscais",
        "Collection": "Cobrança",
        "collection": "cobrança",
        "Forward": "Avançar",
        "dueon": "vencimento em",
        "generatedintheamountof": "gerada no valor de",
        "on": "em",
        "transfertobecarriedout": "Repasse a ser realizado",
        "frompreviousmonths": "dos meses anteriores",
        "Simulatetransfer": "Simular Repasse",
        "select": "{{value}} selecionado",
        "selects": "{{value}} selecionados",
        "Groupedtranfer": "Repasse Agrupado",
        "Changeexpiration": "Alterar Vencimento",
        "Withoutcontractstatus": "Sem Status de Contrato",
        "Ofthebranches": "Das Filiais",
        "OftheManagers": "Dos Gerentes",
        "Billingdetails": "Detalhes da cobrança",
        "to": "até",
        "Transferdetails": "Detalhes do repasse",
        "TXADM": "Tx Adm",
        "Admfee": "Taxa de Administração",
        "Externalobservation": "Observação Externa",
        "Printstatement": "Imprimir Demonstrativo",
        "Launchinfinancial": "Lançar em Financeiro",
        "Changematurity": "Alterar Vencimento",
        "Sendbyemail": "Enviar por e-mail",
        "Undotranfer": "Desfazer transferência",
        "UNDO": "Desfazer",
        "Fromtheowners": "Dos proprietários",
        "Withouttheowners": "Sem os proprietários",
        "Withoutfinancialstatusofthecontract": "Sem status financeiro do contrato",
        "Bynumberofproeprtiesperowner": "Por número de propriedades por proprietário",
        "banktransactions": "Transações bancárias",        // add new  
        "receipts": "Recibos",                             // add new  
        "payments": "Pagamentos",                          // add new  
        "startDate": "Data de início",
        "endDate": "Data de encerramento",
        "month": "Mês",
        "liquidatedatebetween" : "Data de liquidação entre",
        "crediteddatebetween" : "Data creditada entre",
        "canceldatebetween" : "Data cancelada entre",
        "generatedatebetween" : "Data gerada entre",
      },

      "ReceiveandReceived": {
        "ticketInformation": "Download",
        "Questionsaboutcreditcarderrorcodes": "Perguntas sobre códigos de erro de cartão de crédito",
        "Clickhere": "Clique aqui",
        "orHelpintheupperrightcornerandlearnmore": "ou Ajuda no canto superior direito e saiba mais",
        "chargesfrom": "cobranças de",
        "customersinthetotalamountof": "clientes no valor total de",
        "totalamountof": "quantidade total de",
        "Invalidate": "Invalidar",
        "Changeinterestpenaltyanddiscount": "Alterar juros, multa e desconto",
        "Returnagreedinterest": "Devolver juros acordados",
        "Returnagreedfine": "Devolver multa acordada",
        "Backagreeddiscount": "Desfazer desconto acordado",
        "Forcecreditcardprocessing": "Forçar processamento de cartão de crédito",
        "Markforshipment": "Marcar para envio",
        "Unmarkfromshipment": "Desmarcar do envio",
        "Changebankaccount": "Alterar conta bancária",
        "Changecompetency": "Alterar competência",
        "Reasonforinvalidation": "Motivo da invalidação",
        "usetheoptiononly": "Use esta opção apenas nos casos em que você não deseja gerar cobranças para o mês que está invalidando. Após invalidar uma cobrança, você não poderá gerá-la novamente",
        "Improperbilling": "Cobrança imprópria",
        "Billingreplaced": "Cobrança substituída",
        "Deletependingexpensesforthirdparty": "Excluir despesas pendentes de terceiros",
        "Changerates": "Alterar taxas",
        "Chargesdue": "Cobranças a receber",
        "Chargesduetexts": "Cobranças recebidas",
        "Chargesduetext": "Cobrança recebidas",
        "Shipping": "Envio",
        "Markallforshipment": "Marcar tudo para envio",
        "Deselectallfromshipment": "Desmarcar tudo do envio",
        "Help": "Ajuda",
        "Receivable": "Receber",
        "Creditcardmistakes": "Erros de cartão de crédito",
        "Waysofreceiving": "Formas de recebimento",
        "Postdatedchecks": "Cheques pré-datados",
        "Bankholidays": "Feriados bancários",
        "Annualdebtdischargestatement": "Demonstrativo anual de quitação de débitos",
        "Ticket": "Boleto",
        "Charges": "Carnê",
        "Customers": "Clientes",
        "Thereare": "Existem",
        "charges": "cobranças",
        "tobemarkedforshipment": "a serem marcadas para envio",
        "Nobillettobeunmarkedfromtheshipment": "Nenhum boleto para ser desmarcado do envio",
        "Billingpaymentmethod": "Forma de pagamento de cobrança",
        "Customerpaymentmethod": "Forma de pagamento do cliente",
        "Markedforshipment": "Marcado para envio",
        "Deliverymethods": "Formas de entrega",
        "Groundsforinvalidation": "Motivos de invalidação",
        "Typeofcreditcardfailure": "Tipo de falha de cartão de crédito",
        "Unitaryvalue": "Valor unitário",
        "New": "Novo",
        "Youmayprefertopostcharges": "Você pode criar cobranças de forma mais eficiente usando o processo de geração de cobranças",
        "Todothisaddrecurringandone": "Para fazer isso, adicione receitas recorrentes e/ou avulsas. Assim, as cobranças e nfs serão emitidas automaticamente de acordo com as regras definidas para cada cliente",
        "Productsorservices": "Produtos ou serviços",
        "Totalvalue": "Valor total",
        "Changerateforthischarge": "Alterar taxa para esta cobrança",
        "Summaryof": "Resumo de",
        "Today": "Hoje",
        "Yesterday": "Ontem",
        "Tomorrow": "Amanhã",
        "viawithexpired.chang": "2ª via com vencimento expirado. Alterada para o dia da visualização, o link pode ser encaminhado ao cliente",
        "info": "informações",
        "Latefor": "Vence em",
        "Remain": "Vence hoje",
        "remain": "restam",
        "Billing": "Faturamento",
        "NochargesFound": "Nenhuma cobrança encontrada",
        "Saleoff": "Liquidação",
        "saleoff": "Data da liquidação",
        "Amountpaid": "Valor pago",
        "Debitbankfee": "Taxa bancária débito",
        "Legaladditions": "Acréscimos legais",
        "Additions": "Acréscimos",
        "Checknumber": "Número do cheque",
        "Banknumber": "Número do banco",
        "Goodfor": "Válido para",
        "Summary": "Resumo",
        "Issued": "Emitido",
        "Fine": "Multa",
        "Interest": "Juros",
        "Due": "Vencimento",
        "Paid": "Pago",
        "Credited": "Creditado",
        "BackAddressingRequires": "Endereço no verso (Requer impressora compatível)",
        "Morelinesrequires": "Mais linhas (requer papel Ofício)",
        "RegisterinbillingCRM": "Registrar no CRM de faturamento",
        "Expirationwillonlychangeon": "O vencimento só mudará nesta impressão. Juros e multas serão adicionados ao valor original.",
        "Marked": "Marcado",
        "collectionfrom": "coleta de",
        "customerinthetotalamountof": "valor total de",
        "Invalidation": "Invalidação",
        "Reverse": "Estornar",
        "Receipt": "Recibo",
        "Issuereceipt": "Emitir recibo",
        "Chargeswillbecalculatedforeach": "As cobranças serão calculadas para cada boleto, de acordo com o acordo individual",
        "Reversecharge": "Estornar cobrança",
        "Doyoureallywanttoreversethecharges": "Deseja realizar o estorno?",
        "Settledon": "Liquidado em",
        "delay": "atraso",
        "with": "Com",
        "creditedon": "creditado em",
        "sara": "sara",
        "Manual": "Manual",
        "dinadvance": "d com antecedência",
        "dlate": "d atrasado",
        "Paidontheday": "Pago no dia",
        "liquidateas": "Liquidar em lote",
        "Duetomorrow": "Vence amanhã",
        "Overdue": "Vencido há",
        "Incomereceived": "Receitas recebidas"
      },

      "Sweetalert": {
        "emailProgress": "Progresso do envio de e-mail",
        "Success": "Sucesso!",
        "Info": "Informações!",
        "ConfirmButtonText": "Ok",
        "permissionChange": "Permissão atualizada com sucesso!",
        "insertRecord": "Seus detalhes foram salvos com sucesso.",
        "cannotInsert": "Não foi possível salvar seus detalhes. Por favor, tente novamente mais tarde.",
        "cannotupdated": "Não foi possível atualizar seus detalhes. Por favor, tente novamente mais tarde.",
        "RecordUpdated": "O item selecionado foi atualizado com sucesso!",
        "deleteRecord": "Seus detalhes foram excluídos com sucesso.",
        "deleteFailed": "Não foi possível excluir seus detalhes. Por favor, tente novamente mais tarde.",
        "deletingMainAc": "Você tem certeza de que deseja excluir sua conta principal?",
        "activeBank": "Ative esta conta bancária.",
        "deleteBranch": "Você realmente deseja excluir a filial?",
        "deleteService": "Você realmente deseja excluir este serviço?",
        "updatefooter": "Você deseja confirmar a atualização das alterações?",
        "changeMainAccount": "Você realmente deseja trocar a conta principal?",
        "deleteMainAccount": "Você realmente deseja remover a conta principal?",
        "pleaseSelectBank": "Certifique-se de escolher pelo menos um banco.",
        "changeIt": "Alterar",
        "statusChange": "Status alterado com sucesso.",
        "statusUpdate": "Status atualizado com sucesso.",
        "newExpence": "Falha na criação de nova despesa!",
        "deleteBankAccount": "Você realmente deseja remover a conta bancária?",

        "deletelogo": "Excluir o logotipo?",
        "sendEmailDocument": "Are you sure you want to send document email",
        "yesDeleteMainAccount": "Excluir conta principal",
        "yesDeleteIt": "Excluir",
        "Error": "Erro",
        "somethingWent": 'Algo deu errado',
        "passFileData": "Selecione o tipo de compartilhamento do documento ou o link de download será nulo",
        "newService": "Serviço salvo com sucesso.",
        "serviceWrongCategory": "Não é possível utilizar esta categoria para novos serviços",
        "5mb": 'O tamanho do arquivo não pode ser maior que',
        "contractSuspended": 'Contrato está suspenso!',
        "pleaseSelect": "Selecione pelo menos um usuário, por favor.",
        "serverError": "Erro do servidor encontrado. Por favor, tente sua solicitação novamente mais tarde. Se o problema persistir, entre em contato com o suporte para obter assistência.",
        "Warning": "Atenção",
        "selectedAccount": "Esta ação não pode ser realizada. A conta selecionada está vinculada a alguma forma de recebimento.",
        "uniqueNumber": "O número da conta já existe. Insira um número de conta diferente.",
        "Insurance": "Seguro",
        "selectInsurance": "Selecione o seguro.",
        "negativeNumberNotAllowed": "Número negativo não permitido",
        "addedService": "Sucesso",
        "NewService": "Seus detalhes foram salvos com sucesso.",
        "SelectServices": "Por favor, selecione um ou mais serviços.",
        "DeleteTaxation": "Você realmente deseja excluir esta tributação?",
        "WrongPassword": "Senha incorreta. Por favor, tente novamente.",
        "WrongEmail": "Email inválido. Por favor, tente novamente.",
        "successget": "Seus detalhes foram salvos e atualizados com sucesso.",
        "SuccessFailed": "Falha ao salvar ou atualizar seus detalhes.",
        "UpdateSuccess": "Seus detalhes foram atualizados com sucesso.",
        "updatesuccess": "Atualizado com sucesso.",
        "UpdateFailed": "Falha ao atualizar seus detalhes.",
        "deletesuccess": "Seus detalhes foram excluídos com sucesso.",
        "DeleteFailed": "Falha ao excluir seus detalhes.",
        "alreadybillinggenerate": "Já foi gerada a cobrança para esses registros",
        "Pleasecheckedthe": "Selecione uma opção",
        "pleasecheckone": "Marque pelo menos uma opção",
        "Areyousureyouwantto": "Cancelar a suspensão de todas as cobranças deste contrato?",
        "contractPending": "O contrato não está ativo, ative seu contrato primeiro.",
        "terminationReversed": "Você tem certeza de que deseja cancelar a rescisão?",
        "Generatedfilekeyarehandovered": "As chaves dos arquivos gerados foram entregues",
        "Generatemeat": "Gere cobranças com sucesso!",
        "Youwontbeabletorevert": "Atenção! Essa ação não poderá ser revertida",
        "Yourimaginaryrecordissafe": "Seu registro está seguro",
        "cancelled": "Cancelado",
        "Areyousuretodeleteitem": "Excluir o item?",
        "Areyousurewanttogenerateit": "Gerar?",
        "Youwontabletomodifyitlater": "Você não poderá modificar posteriormente",
        "Areyousure": "Tem certeza?",
        "Update": "Atualizado",
        "Delete": "Excluir",
        "PleaseSelectOwners": "Selecione os proprietários",
        "Itwasnotpossibletoperformth": "Não foi possível realizar a exclusão. Há uma propriedade vinculada ao administrador",
        "PleaseSelectUser": "Selecione os usuários",
        "Areyousuretoenableproperties": "Deseja habilitar os imóveis?",
        "PropertiesEnabled": "Imóveis habilitados",
        "OperationFailed": "Falha na operação",
        "Areyousuretodisableproperties": "Desabilitar as propriedades?",
        "PropertiesDisabled": "Propriedades desabilitadas",
        "Pleaseselectatleasttransaction": "Selecione pelo menos uma transação",
        "Areyousurewantto": "Deseja",
        "selectedrecords": "registro(s) selecionado(s)",
        "Selectedrecords": "Registro(s) selecionado(s)",
        "Pleaseselectrecords": "Por favor, selecione registros",
        "Emptyfilefield": "Campo de arquivo vazio",
        "Filerequired": "Arquivo necessário",
        "sendsuccessfullytoselected": "enviado com sucesso",
        "Areyousurewanttosendsms": "Tem certeza de que deseja enviar SMS?",
        "SMSsendsuccessfully": "SMS enviado com sucesso",
        "Areyousurewanttoremoveprotest": "Deseja remover o protesto?",
        "Accesscustomercontacthistory": "Acessar histórico de contato do cliente",
        "Removeprotest": "Remover protesto",
        "Allowaccess": "Permitir acesso",
        "Oops": "Ops...",
        "Pleaseselectsomeusers": "Selecione usuários",
        "PaymentispaidsuccessfullyonAarin": "O pagamento foi efetuado com sucesso via Kenlo",
        "PaymentiscanceledonAarin": "O pagamento foi cancelado com sucesso em Aarin",
        "PaymentisnotCompleted": "O pagamento não foi concluído",
        "Paymentmarkedpaidsuccessfully": "Pagamento efetuado",
        "MessageUpdateSuccessfully": "Mensagem atualizada",
        "FailedtoUpdateAddMessage": "Falha ao atualizar mensagem",
        "Descriptionrequired": "Adicione descrição",
        "Addothernumber": "Adicionar outro número",
        "AccountNoNeedtobeunique": "O número da conta precisa ser único. Por favor, insira um número único",
        "Successfully": "Com sucesso",
        "recordsinsertupdatedand": "registros atualizados",
        "failedtoinsertupdate": "falha ao atualizar",
        "Successfullyupdate": "Atualizado",
        "Yournewlogoupdatedsavedsuccessfully": "Seu logotipo foi atualizado",
        "WantToSave": "Quer salvar?",
        "Areyousuretosaveit": "Tem certeza de que deseja salvar?",
        "Namemusthavemorethan2characters": "O nome deve ter mais de 2 caracteres",
        "Sourceuserisrequired": "Usuário obrigatório",
        "Pleaseselectgroup": "Selecione um grupo",
        "allownumber": "Apenas números",
        "validnumber": "Caracteres não permitidos",
        "mailsent": "E-mail enviado com sucesso",
        "updateService": "Atualize o código do seu serviço",
        "alreadyuse": "Este item selecionado está sendo usado em outro lugar e não pode ser excluído",
        "invaliduserpassword": "Nome de usuário ou senha inválidos. Verifique suas credenciais e tente novamente.",
        "userDeactiveErrorOccured": "Usuário está desativado no sistema. Por favor consulte sua agência para obter assistência",
        "permissionErrorOccured": "Você não tem permissão para fazer login no sistema. Por favor, verifique com sua agência para obter assistência.",
        "recoveryFailedPassword": "Sinto muito, não foi possível redefinir sua senha.",
        "passwordconform": "Senha e Confirmação de Senha não coincidem!",
        "unableotchangeorupdate": "Desculpe, no momento não conseguimos alterar ou atualizar sua senha. Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter ajuda.",
        "agencynotfound": "Nenhuma agência encontrada com os detalhes fornecidos. Por favor, verifique seus critérios de pesquisa e tente novamente.",
        "footerupdate": "Seu trabalho de rodapé foi salvo com sucesso.",
        "updateplugnota": "Você não vai se conectar com o Plugnota?",
        "chargereverse": "Carregue reverso com sucesso!",
        "updateCertificate": "Faça upload do certificado e registre a empresa",
        "changepass": "Tem certeza de que deseja enviar uma solicitação de alteração de senha",
        "contractupdate": "Registro atualizado com sucesso!",
        "generateplugnota": "Gerar plugnota",
        "emailsMessage": "Por favor, insira a mensagem de e-mail.",
        "getrecord": "Registro(s) obtidos com sucesso!",
        "duplicate": "Entrada duplicada não permitida!",
        "Recordinserted": "Registro inserido com sucesso!",
        "Recordupdated": "Registro atualizado com sucesso!",
        "changepassword": "Sua senha foi alterada.",
        "deletecontact": "Você realmente deseja excluir o contato?",
        "Ownerarerequired": "Proprietários são obrigatórios.",
        "Tenantsarerequired": "Inquilinos são obrigatórios.",
        "emailsRequire": "O email é necessário",
        "Thisidentifier": "Identificador já utilizado",
        "NameReferencealreadyexists": "A conta de caixa com o mesmo nome já existe. Insira uma conta de caixa diferente.",
        "accountnumberalreadyexists": "O número da conta já existe no mesmo Banco. Insira um número de conta diferente.",
        "OpeningHours": "O horário de funcionamento é necessário.",
        "unqueEmails": "O e-mail já existe. Por favor, insira um e-mail diferente.",
        "SuccessfullyComplete": "Concluído com sucesso ",
        "editbilling": "A edição do faturamento deverá ser feita através da tela do contrato",
        "Youwonundo": "Você não poderá desfazer isso!",
        "Yessuspended": "Sim, suspendeu!",
        "lesstotalpercentage": "O percentual total é inferior a 100. Por favor, verifique o percentual.",
        "moretotalpercentage": "O percentual total é superior a 100. Por favor, verifique o percentual.",
      },

      "Dashboard": {
        "Internal": "Internas",
        "Dashboard": "Painel de gestão",
        "ActiveContracts": "Contratos ativos",
        "Contractswithoutwarranty": "Contratos sem garantia",
        "Contractswithoutinsurance": "Contratos sem seguros",
        "Wanttoincreaseyourincome": "Quer ganhar mais?",
        "Increaseyourrevenuebyupto": "Aumente em até",

        "kenloInsurance1T1": "Seu cadastro está em análise",
        "kenloInsurance2T1": "Em migração para Kenlo Insurance",
        "kenloInsurance3T1": "Cadastre-se na Kenlo Seguros",
        "kenloInsurance4T1": "Cadastre-se na Kenlo Seguros",
        "kenloInsurance5T1": "Aproveite nossa parceria!",

        "kenloInsurance1T2": "Após a aprovação, os benefícios do Seguro Kenlo estarão disponíveis para você em até 2 dias úteis.",
        "kenloInsurance2T2": "Você está registrado em outra corretora, mas resolveremos isso para você em até 3 dias úteis.",
        "kenloInsurance3T2": "Detectamos que você está bloqueado em nossa seguradora parceira. Estamos trabalhando em uma solução.",
        "kenloInsurance4T2": "Você está bloqueado na seguradora parceira. Clique para entender melhor a situação.",
        "kenloInsurance5T2": "Ofereça o Seguro Kenlo aos seus clientes e ganhe comissões por cada contrato concluído.",

        "registerKenloinsur": "Cadastre-se na Kenlo Insurance. Aproveite benefícios como cotações em tempo real e comissões para cada contrato concluído.",
        "KenloInsurancePartnership": "Parceria Kenlo Seguros",
        "KenloInsurancePartnershipContent": "Enquanto seu cadastro está em período de análise, aqui estão algumas perguntas e respostas sobre a Kenlo Seguros.",
        "HowDoesCommissionRealEstateAgencyWork": "Como funciona a comissão para a imobiliária?",
        "HowDoesCommissionRealEstateAgencyWorkContent": "A imobiliária ganha uma comissão por cada seguro ativo, conforme acordo comercial.",
        "HowDoesCommissionRealEstateAgencyWorkContent2": "Após o pagamento à seguradora, em até 5 dias, a comissão é depositada na conta informada pela imobiliária no cadastro (para valores acima de R$99). Após o recebimento, a imobiliária emite uma Nota Fiscal Eletrônica para a seguradora, em até 10 dias.",
        "HowDoesPaymentInsurancePremiums": "Como funciona o pagamento das parcelas do seguro?",
        "HowDoesPaymentInsurancePremiumsContent": "O valor do seguro é lançado automaticamente no boleto de aluguel. Desta forma, a imobiliária recebe os valores juntamente com o pagamento do aluguel e repassa para a seguradora mensalmente, através do boleto consolidado de todos os seguros ativos.",
        "HowDoReceiveInsurancePolicy": "Como recebo a apólice do seguro?",
        "HowDoReceiveInsurancePolicyContent": "Após a contratação do seguro, a apólice é enviada por e-mail ao inquilino, com cópia para a imobiliária. O envio demora cerca de 30 dias.",
        "WhatCoveragePackagesKenloOffer": "Quais são os pacotes de cobertura que a Kenlo oferece?",
        "WhatCoveragePackagesKenloOfferContent": "A Kenlo Seguros oferece duas opções de pacotes de cobertura.",
        "BasicPackage": "Pacote básico",
        "HomeWithoutAssistance": "Casa Sem Assistência",
        "BasicPackageContent1": "Incêndio, queda de raio, explosão, fumaça e queda de aeronaves.",
        "BasicPackageContent2": "Vendaval, furacão, ciclone, tornado e granizo.",
        "PackageWithAssistance": "Pacote com assistências",
        "HomePlus": "Casa Mais",
        "PackageWithAssistanceContent1": "Incêndio, queda de raio, explosão, fumaça e queda de aeronaves.",
        "PackageWithAssistanceContent2": "Vendaval, furacão, ciclone, tornado e granizo.",
        "PackageWithAssistanceContent3": "Danos elétricos.",
        "PackageWithAssistanceContent4": "Furto de bens com arrombamento e roubo.",
        "PackageWithAssistanceContent5": "Assistências: chaveiro, desentupimento, eletricista, encanador, mudança e guarda-móveis, vidraceiro.",

        "withKenloWarrantyandKenloInsurance": " o seu faturamento com Garantia e Seguros Kenlo",
        "Pendencies": "Pendências",
        "Contracts": "Contratos",
        "Warranties": "Garantias",
        "Insurances": "Seguros",
        "Expenses": "Despesas",
        "Maturity": "Vencimento",
        "Category": "Categoria",
        "Contract": "Contrato",
        "Renter": "Inquilino",
        "Owner": "Proprietário",
        "Formofpayment": "Forma de pagamento",
        "Responsible": "Responsável",
        "Rent-amfree": "Aluguel - taxa adm",
        "Seeall": "Ver tudo",
        "Kenlo": "Kenlo",
        "Charges": "Cobranças",
        "Overdue": "Atrasadas",
        "Duesoon": "A vencer",
        "Received": "Recebidas",
        "Nextmaturities": "Próximos vencimentos",
        "Current": "Mês atual",
        "Next": "Próximo",
        "months": "meses",
        "Income": "Receitas",
        "Foreseen": "Previstas",
        "Averageadmfee": "Taxa adm média",
        "Totaladmfee": "Taxa adm total",
        "Mainsources": "Maiores fontes",
        "Administrationfee": "Taxa de administração",
        "Salescommission": "Comissão de venda",
        "Insurancecommission": "Comissão de seguro",
        "Agreement": "Acordo",
        "Transfers": "Repasses",
        "Made": "feita",
        "value": "Valor",
        "Tenant": "Inquilino",
        "amount": "Quantia",
        "duetoday": "Para ser feito hoje",
        "Payabletoday": "A pagar hoje",
        "duethisweek": "Vencimento esta semana",
        "Third": "Terceiro",
        "month": "mês"
      },

      "checklistother": {
        "newchecklist": "Nova checklist",
        "newchecklistItems": "Nova checklist Itens",
        "howtouse": "Como usar",
        "notificationperiod": "Período de Notificação",
        "items": "Itens",
        "description": "Descrição",
        "responsible": "Responsável",
        "notify": "Notificar",
        "editemailtemplate": "Editar modelo de e-mail",
        "observation": "Observação",
        "mandatoryProof": "Item obrigatório",
        "showinOwliApp": "Mostrar no aplicativo Owli",
        "deliverBy": "Entregar por",
      },

      "comparetolastmonth": {
        "StatementofIncomeandExpenses": "Demonstrativo de Receitas e Despesas",
        "Toview": "Ver",
        "Export": "Exportar",
        "CSV": "CSV",
        "excel": "excel",
        "Currentmonth": "Mês atual",
        "Variation": "Variação",
        "records": "registros",
        "Previousbalance": "Saldo anterior",
        "Newcostcenter": "Novo centro de custos",
        "ledgeraccount": "Conta do razão",
        "Setasdefaultcostcenter": "Definir como centro de custos padrão",
        "sendfile": "Enviar arquivo",
        "total": "Total"
      },

      "costcenter": {
        "Costcenter": "Centro de custos",
        "Newcostcenter": "Novo centro de custos",
        "LedgerAccount": "Conta contábil",
        "Setasdefaultcostcenter": "Definir como centro de custos padrão",
        "Sendfile": "Enviar arquivo",
      },

      "terminatelist": {
        "filenotavailable": "Arquivo Não Disponível",
        "Move-out": "Mudança",
        "moveoutdate": "Data da desocupação",
        "details": "detalhes",
        "Completetermination": "Concluir rescisão",
        "Processstart": "Início do processo",
        "date": "data",
        "Lastmodified": "Última modificação",
        "Reason": "Motivo",
        "Propertyunfitforobtainingapermitand/orfiredepartmentclearancecertificate": "Propriedade não apta para obtenção de licença e/ou certificado de autorização do corpo de bombeiros",
        "Terminationchecklist": "Checklist de rescisão",
        "outof": "de um total de",
        "itemscompleted": "itens concluídos",
        "exitediting": "Salvar e Sair da",
        "item": 'Item',
        "limit": "Limite",
        "notice": "aviso",
        "Propertyactions": "Ações de imóvel",
        "Schedulemove-outinspection": "Agendar vistoria de saída",
        "Concludemove-outinspection": "Concluir vistoria de saída",
        "Checkpropertytaxandcondos": "Verificar IPTU e condomínios",
        "Cancelinsuranceandguarantee": "Cancelar seguro e garantia",
        "Modifyrecurringexpenses": "Modificar despesas recorrentes",
        "Handoverofpropertykeys": "Entrega das chaves da propriedade",
        "Terminationcalculation": "Cálculo de rescisão",
        "Generateandsenddocuments": "Gerar e enviar documentos",
        "Uponconfirmingcompletionthes": "Ao confirmar a conclusão, o status da propriedade será alterado para 'terminado'. Você pode editar e acessar o histórico da lista de verificação de rescisão.", "Confirmandissueagreement": "Confirmar e emitir acordo",
        "Responsibleparty'sname": "Responsável",
        "Partyresponsiblefor": "Responsável por",
        "electricitypayment": "Pagamento de eletricidade",
        "waterpayment": "Pagamento de água",
        "Thepropertylistinghasbeenreactivatedonthewebsite": "O anúncio da propriedade foi reativado no site",
        "Attachfile": "Anexar",
        "SupportedformatsPDFandPNG": "Formatos: PDF e PNG",
        "Savedraft": "Salvar rascunho",
        "Saveandcomplete": "Salvar e concluir",
        "Thistenanthas": "Este inquilino tem",
        "Check": "Verificar",
        "pendingcharges": "Cobranças pendentes",
        "Initialdata": "Dados iniciais",
        "Terminationforecast": "Previsão de rescisão",
        "Includemove-outdateintheterminationdocument": "Incluir a data de mudança no documento de rescisão",
        "Reasonfortermination": "Motivo da rescisão",
        "Notifypropertyowner": "Notificar o proprietário do imóvel",


        "Rented_outside_the_real_estate_agency": "Alugou fora da imobiliária",
        "Rented_another_property_from_the_real_estate_agency": "Alugou outro imóvel na imobiliária",
        "Increased_family_birth_of_children": "Aumentou a família (nascimento de filhos)",
        "Bought_own_property": "Comprou imóvel próprio",
        "Illness_inability_to_live_alone": "Doença, impossibilidade de morar sozinho",
        "Termination_of_contract": "Encerramento do contrato",
        "Death_of_a_family_member_who_lived_in_the_same_property": "Falecimento de algum membro da família que morava no mesmo imóvel",
        "Death_of_the_guarantor_with_the_impossibility_of_another_guarantee": "Falecimento do fiador com a impossibilidade de outra garantia",
        "Death_of_the_tenant_himself": "Falecimento do próprio locatário",
        "Closed_the_business": "Fechou o comércio",
        "Small_property": "Imóvel pequeno",
        "Property_without_conditions_for_issuing_a_permit_and/or_certificate_of_firefighter_regularity": "Imóvel sem condições para emissão de alvará e/ou atestado de regularidade do bombeiro",
        "Tenant_moved_to_another_city": "Locatário mudou de cidade",
        "Structural_problem": "Problema estrutural",
        "Financial_problems": "Problemas financeiros",
        "Problems_in_the_neighborhood": "Problems in the neighborhood",
        "Owner_requested_property": "Proprietário solicitou imóvel",
        "Tenant_transfer": "Transferência de inquilino",
        "Transfer_of_Ownership": "Transferência de proprietário",
        "Rent_value/contract_fees": "Valor do aluguel/taxas de contrato",
        "Withdrawal": "Desistência",
        "Others": "Outros"
      },

      "insurelist": {
        "newinsurers": "Nova seguradora",
        "editinsurers": "Editar seguradora",
        "closeAll": "Fechar tudo",
        "aboutyou": "Sobre você",
        "aboutReal": "Sobre a agência imobiliária",
        "aboutRealAddress": "Endereço da agência imobiliária",
        "fiscalInfo": "Informações fiscais",
        "bankData": "Dados da conta bancária",
        "readaccept": "Eu li e aceito os",
        "submitRegistration": "Enviar cadastro",
        "responsibleParty": "Parte responsável",
        "inpartnershipwithTokio": "Em parceria com Tokio Marine, a Kenlo Insurance oferece-lhe muitas vantagens com total liberdade.",
        "commissionsforeveryfire": "Comissões para cada contrato de seguro contra incêndio concluído.",
        "noobligation": "Sem obrigação de uso.",
        "realtimequotesduring": "Cotações em tempo real durante o registro de novos contratos de locação.",
        "automaticcontractapproval": "Aprovação automática de contrato sem burocracia.",
        "automaticbilling": "Faturamento automático para inquilinos.",
        "securityfrom": "Segurança de duas empresas bem estabelecidas no mercado."
      },

      "Links": {
        "dashboard": "Painel",
        "contracts": "Contratos",
        "readjustmentIndexes": "Índices de reajuste",
        "properties": "Imóveis",
        "customersAndEmployees": "Clientes e funcionários",
        "income": "Receitas",
        "generateCharges": "Gerar cobranças",
        "toReceive": "Receber",
        "invoices": "Notas Fiscais",
        "bankReturn": "Retorno bancário",
        "received": "Recebido",
        "delinquency": "Inadimplência",
        "agreements": "Acordos",
        "billingRule": "Régua de Cobrança",
        "exit": "Saída",
        "prints": "Impressões",
        "sentMessages": "Mensagens enviadas",
        "sentEmails": "E-mails enviados",
        "alterations": "Alterações",
        "history": "Histórico",
        "notifications": "Notificações",
        "services": "Serviços",
        "dimob": "Dimob",
        "reports": "Relatórios",
        "accountsPlan": "Plano de contas",
        "taxation": "Tributação",
        "formsOfReceipt": "Formas de recebimento",
        "bankAccounts": "Contas bancárias",
        "branches": "Filiais",
        "companyData": "Dados da empresa",
        "company": "Empresa",
        "kenloAppClients": "Clientes Kenlo App",
        "myAccount": "Minha conta",
        "users": "Usuários",
        "expenses": "Despesas",
        "transfers": "Repasses",
        "performedTransfers": "Repasses realizados",
        "toBePaid": "A pagar",
        "paid": "Paga",
        "suppliers": "Fornecedores",
        "bankTransactions": "Transações bancárias",
        "bankConcilliation": "Conciliação bancária",
        "diagnoses": "Diagnósticos",
        "compareToLastMonth": "Compare com o mês passado",
        "costCenter": "Centro de custo",
        "financial": "Financeiro",
        "Inspections": "Inspeções",
        "Maintenance": "Manutenção",
        "Nonpayment": "Não pagamento",
        "support": "Suporte"
      },

      "customerAndEmployees": {
        "withdrawMoney": "Retirar dinheiro no local",
        "chequeDeposit": "depósito em cheque",
        "pickUpCheque": "Retirar cheque no local",
        "chequedeposit": "Depósito em cheque ",
        "Cheque": "Cheque",
        "pix": "Pix",
        "boleto": "Boleto",
        "docTed": "TED",
        "bankTransfer": "Transferência bancária",
        "cashDeposit": "Depósito em dinheiro",
        "creditcard": "Cartão de crédito",
        "DebitCard": "Cartão de débito",
        "Transferbanking": "Transf. bancaria",
        "Automatictransfer": "Transferência automática",
        "with": "Com",
        "others": "Outros",
        "money": "dinheiro",
        "active": "Ativo",
        "legal": "jurídica",
        "legals": "Jurídicos",
        "owners": "Proprietários",
        "newOwner": "Novo Proprietário",
        "tenants": "Locatários",
        "newTenant": "Novo Locatário",
        "updateTenant": "Atualizar Locatários",
        "updateowner": "Atualizar proprietário",
        "updateguarantor": "Atualizar fiador",
        "updatebroker": "Atualizar corretor",
        "updatesupplier": "Atualizar fornecedor",
        "updateinspector": "Atualizar inspetor",
        "guarantors": "Fiadores",
        "and": "e",
        "newGuarantor": "Novo Fiador",
        "brokers": "Corretores",
        "newBroker": "Novo Corretor",
        "providers": "Fornecedores",
        "consultSupplier": "Novo Fornecedor",
        "inspectors": "Vistoriadores",
        "newInspector": "Novo Vistoriador",
        "newSurveyor": "Novo Vistoriador",
        "newUser": "Novo usuário",
        "whatlooking": "O que você está procurando?",
        "clientsAndCollaborators": "Clientes e colaboradores",
        "guarantorsReport": "Relatório de fiadores",
        "notifyActiveContracts": "Notificar para contratos ativos",
        "configureOwnerArea": "Configurar área do proprietário",
        "currentAccountStatement": "Extrato de conta corrente",
        "ownersReport": "Relatório de proprietários",
        "detailedOwnersReport": "Relatório Detalhado dos Proprietários",
        "incomeGroupedOwner": "Receita agrupada por proprietário",
        "extractIncomeTaxReturn": "Extrato para declaração de imposto de renda",
        "configureTenantArea": "Configurar área de locatário",
        "tenantReport": "Relatório de inquilinos",
        "detailedTenantReport": "Relatório detalhado de inquilinos",
        "notifyWithoutActiveContracts": "Notificar sem contratos ativos",
        "newSuppliers": "Novos fornecedores",
        "suppliers": "Fornecedores",
        "tenantArea": "Área do inquilino",
        "BranchCode": "Código da Filial",
        "Inthenameof": "Em nome de",
        "Newownerorbeneficiary": "Novo proprietário ou beneficiário",
        "ormoreproperties": "ou mais imóveis",
        "Only": "Apenas",
        "propertie": "imóvel",
        "Seehowitturnedout": "Veja como ficou",
        "Ownersareaamenites": "Funções da área do proprietário",
        "Disableonlinetransferextracts": "Extratos de repasse",
        "Disablefilesonline": "Arquivos",
        "Disableonlinerealestate": "Imobiliária",
        "DeactivateextractforIRdeclaration": "Extrato para declaração de IR",
        "Deactivateonlineregistrationupdate": "Atualização de cadastro",
        "Backgroundimage": "Imagem de fundo",
        "Uploadnew": "Carregar novo",
        "Whatshouldthebackgroundimagelooklike": "Imagem de fundo",
        "Emailadministratorswhenupdatingchartofaccounts": "Enviar email aos administradores ao atualizar o plano de contas",
        "Enabledailycashier": "Habilitar o caixa diário",
        "Activateoptiontoselecttheinci": "Ativar opção para selecionar a incidência e retenção de impostos no simples nacional",
        "sendEmailWithoutActiveContracts": "Enviar e-mail para proprietários sem contratos ativos",
        "sendEmailWithoutTenantActiveContracts": "Enviar e-mail para inquilinos sem contratos ativos",
        "Otherpaymentmethods": "Outras formas de pagamento",
        "splitpaymentKenloPay": "Repasse por Split Kenlo Pay"
      },

      "property": {
        "historic": "Histórico",
        "newHistory": "Novo histórico",
        "most": "Maioria",
        "data": "Dados",
        "description": "Descrição",
        "record": "Registro",
        "records": "registros",
        "administrators": "Administradoras",
        "newAdministrator": "Novo administrador",
        "condominium": "Condomínio",
        "trustee": "Administrador",
        "condos": "Condomínios",
        "newCondos": "Novo condomínio",
        "managers": "Gestor", // Gerentes
        "newManager": "Novo gerente",
        "moreFilters": "Mais filtros",
        "typeOfBusiness": "Tipo de negócio",
        "realEstate": "Imobiliária",
        "properties": "Imóveis",
        "immobile": "Imóvel",
        "saleValue": "Valor de venda",
        "rentAmount": "Valor do aluguel",
        "newProperty": "Novo imóvel",
        "Properties": "Imóveis",
        "changeAdministrator": "Alterar administrador",
        "changeCondominium": "Alterar Condomínio",
        "addManager": "Adicionar gerente",
        "removeManager": "Remover Gestor",
        "changeBranch": "Alterar filial",
        "property": "imóvel",
        "Leasedproperty": "Imóvel alugado",
      },

      "button": {
        "saveAndGenerateContractAmmendment": "Salvar e gerar aditivos contratuais",
        "action": "Ação",
        "open": "Abrir",
        "more": "Mais",
        "close": "Fechar",
        "previous": "Anterior",
        "next": "Próxima",
        "save": "Salvar",
        "reajust": "Reajustar",
        "afterSaving": "Após salvar cadastrar outro",
        "toSend": "Enviar",
        "saveSend": "Salvar e enviar",
        "selectAll": "Selecionar tudo",
        "clearSelect": "Limpar Seleção",
        "toSave": "Salvar",
        "toApply": "Aplicar",
        "delete": "Excluir",
        "administrators": "Administradoras",
        "seeContract": "Contrato",
        "seeProperty": "Imóvel",
        "toEdit": "Editar",
        "edit": "Editar",
        "suspend": "Suspender cobrança",
        "invalidate": "Invalidar cobrança",
        "seeBoleto": "Informações do boleto",
        "update": "Atualizar",
        "addsubaccount": "Adicionar subconta",
        "additem": "Adicionar item",
        "newItem": "Novo item",
        "createitem": "Criar item",
        "createAccount": "Criar categoria",
        "account": "Conta",
        "changes": "Alterações",
        "toClose": "Fechar",
        "resetfilter": "limpar filtro",
        "updateLogo": "Atualizar logotipo",
        "submitLogo": "Inserir logotipo",
        "crop": "Cortar",
        "cancel": "Cancelar",
        "done": "Feito",
        "clear": "Limpar",
        "resetFilters": "Limpar tudo",
        "apply": "Aplicar",
        "confirm": "Confirmar",
        "send": "Enviar",
        "submitting": "Enviando...",
        "clickHereToGenerateToken": "Clique Aqui Para Gerar Token",
        "Newmovement": "Novo movimento",
        "reverse": "Estornar",
        "yesdelete": "Sim, deletar"
      },

      "label": {
        "registeredSurveyor": "Você quer salvar este formulário?",
        "inviteToApp": "Convidar para o aplicativo.",
        "checkAvailability": "Verifique a disponibilidade do aplicativo",
        "group": "Agrupar",
        "access": "Acesso",
        "ownaccess": "Acesso próprio",      // add new
        "Ownerregistered": "cadastrado com sucesso!"
      },

      "requiredMmessage": {
        "creditSameAs": "Combina com débito!",
        "fieldRequired": "Campo obrigatório.",
        "fieldNotValid": "Este campo não é válido.",
        "fieldTooShort": "Campo muito curto.",
        "fieldTooLong": "Campo muito longo.",
        "Pleaseenteravaluemorethan1": "Insira um valor maior que 1",
        "Pleaseenteravaluelessthan12": "Insira um valor menor que 12",
        "Thevaluemustbeanumber": "O valor deve ser um número",
        "Doesnotmatchwithdebit": "Não corresponde ao débito",
        "Enddatecantbebeforestartdate": "O mês do débito não pode ser anterior à data de vencimento.",
        "Pleaseenteravaluelessthan2digit": "Insira um valor menor que 2 dígitos",
        "TelephoneError": "Telefone deve estar no formato (XX)XXXX-XXXX",
        "mobileError": "Celular deve estar no formato  (XX)XXXXX-XXXX",
        "passwordrequired": "Esqueceu de definir senha para o arquivo",   // add new
        "Agencyrequire": "A agência deve ter pelo menos 4 caracteres",
        "numbernotallow": "Números e caracteres especiais não são permitidos.",
        "7characters": "Este campo pode ter no máximo 15 caracteres",
        "Specialcharacters": "Caracteres especiais não são permitidos",
        "20characters": "Este campo pode ter no máximo 20 caracteres",
        "40characters": "Este campo pode ter no máximo 40 caracteres",
        "valuecannot": "valor não pode ser maior que",
        "Pleaseenteronlynumbers": "Por favor, insira apenas números e hífens.",
        "password": "Mínimo de 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 dígito numérico.",
        "maturityDate": "A data de vencimento não pode ser anterior à data de início da conta bancária."
      },

      "formLabel": {
        "typeOfPerson": "tipo de pessoa",
        "preselectedTemplates": "Modelos pré-selecionados",
        "allItemsSelected": "Todos os itens selecionados",
        "Supplier": "Fornecedor",
        "settings": "Configurações",
        "clientCollaborators": "Cliente e colaboradores",
        "blockOwner": "Bloquear cadastro de proprietário sem CPF/CNPJ",
        "blockTenant": "Bloquear cadastro de inquilino sem CPF/CNPJ",
        "stopGuarantor": "Cancelar cadastro de fiador sem CPF/CNPJ",
        "blockSurveyor": "Bloquear cadastro de vistoriador sem CPF/CNPJ",
        "blockBroker": "Bloquear cadastro de corretor sem CPF/CNPJ",
        "alwaysRefundIR": "Devolva sempre o IR da fatura",
        "alwaysRefundIPS": "Reembolsar sempre o PIS/COFINS/CSLL da nota fiscal",
        "copygroupaccess": "Copiar acesso ao grupo",
        "portion": "Parcela",
        "in": "De",
        "debitmonth": "Mês do débito",
        "cnpjCpf": "CNPJ/CPF",
        "cnpjCeiCpf": "CNPJ/CEI/CPF",
        "cnpj": "CNPJ",
        "name": "Nome",
        "payment": "Pagamento",
        "defaultPaymentMethod": "Forma de pagamento preferencial",
        "contact": "Contato",
        "mobileNumber": "Celular (DDD + Número)",
        "telephone": "Telefone",
        "email": "E-mail",
        "password": "Senha",
        "mobilePassword": "Senha do aplicativo móvel",
        "affiliation": "Filiação", // Afiliação
        "motherName": "Nome da mãe",
        "fatherName": "Nome do pai",
        "moreFields": "Mais campos",
        "rg": "RG",
        "issuingAgency": "Orgão Expedidor",
        "shippingDate": "Data de expedição",
        "sex": "Sexo",
        "birth": "Nascimento",
        "Birthplace": "Naturalidade", // Local de nascimento
        "maritalStatus": "Estado civil",
        "nationality": "Nacionalidade",
        "zipCode": "CEP",
        "address": "Endereço",
        "number": "Número",
        "site": "Site",
        "complement": "Complemento",
        "products": "Produtos",
        "neighborhood": "Bairro",
        "city": "Cidade",
        "day": "Dia",
        "das": "Das",
        "until": "Até",
        "line1": "Linha 1",
        "line2": "Linha 2",
        "line3": "Linha 3",
        "nameReference": "Nome referência",
        "nameinBank": "Nome na conta bancária",
        "bank": "banco",
        "state": "Estado",
        "employmentData": "Dados do empregador",
        "company": "Empresa",
        "profession": "Profissão",
        "fieldOfActivity": "Ramo de atividade",
        "monthlyIncome": "Renda mensal",
        "taxesRetention": "Retenção de impostos",
        "withHoldIssqn": "Reter ISSQN",
        "observation": "Observação",
        "representative": "Representante",
        "typeSearch": "Escreva para pesquisar",
        "typeBasedThis": "ID da cobrança, Nome, Quantia",
        "listing": "Listagem",
        "of": "de",
        "to": "até",
        "users": "Usuários",
        "withTagged": "Com marcado :",
        "mark": "Marca",
        "all": "Todos",
        "none": "Nenhum",
        "sendEmail": "Enviar email",
        "configureEmail": "Configure Email",
        "identifier": "Identificador",
        "model": "Modelo",
        "title": "Título",
        "caption": "Rubrica",
        "variables": "Variáveis",
        "noRecordsFounds": "Nenhum registro encontrado.",
        "moreItems": "Mais itens - Automático - Todos os itens.",
        "sendLetter": "Enviar carta",
        "mailingLabel": "Etiqueta de endereço",
        "changeAccessPermi": "Alterar permissões de acesso",
        "labelTemplate": "Modelo de etiqueta",
        "select": "Selecionar",
        "test": "Teste",
        "positionLabel": "Posição da 1ª etiqueta",
        "numberOfCopies": "Número de cópias",
        "guarantorAndGuarantor": "Fiadores e fiadores",
        "propertyType": "Tipo de Propriedade",
        "formOfPayment": "Forma de pagamento",
        "printScreen": "Imprimir tela",
        "ownerArea": "Area do proprietário",
        "ownersBeneficiaries": "Proprietários e Beneficiários",
        "moreThanProperty": "Mais de 1 imóvel",
        "hits": "Exitos",
        "responsible": "Responsável",
        "billingMethod": "Método de cobrança",
        "condoDueDate": "Data de vencimento do condomínio",
        "liquidatorAddress": "Endereço do liquidante",
        "fantasyName": "Nome fantasia",
        "administrator": "Administradora",
        "cpf": "CPF",
        "caretaker": "Zelador",
        "lawyerContact": "Contato do advogado",
        "cellPhone": "Celular",
        "properties": "Propriedades",
        "attorney": "Advogado",
        "caretakerContact": "Contato do zelador",
        "monthlyAdminRate": "Taxa de adm mensal",
        "doNotPercentage": "Não usar percentual, usar valor fixo",
        "leaseFee": "Taxa de locação",
        "ownerAndBenef": "Proprietários e beneficiários",
        "type": "Tipo",
        "propertyPercentage": "Propriedade",
        "transfer": "Repasse",
        "guaranteedTransfer": "Repasse garantido",
        "transferDay": "Dia do repasse",
        "daysOfTransfer": "Dias do repasse",
        "proServiceContact": "Contrato de Prestação de serviço",
        "fundingDate": "Data de captação",
        "fineExemption": "Isenção de multa",
        "Condominiumadministration": "Administração do condomínio",
        "maximumLease": "Prazo máximo de locação (meses)",
        "maximumLeaseTooltip": "Duração máxima que o imóvel poderá ficar locado.",
        "administrationFee": "A taxa de administração é cobrada sobre",
        "propertyIdentifier": "Identificador do imóvel",
        "buildingCondominium": "Edifício/Condomínio",
        "value": "Valor",
        "condominiumEstate": "Condomínio é administrado pela imobiliária",
        "propertyDetails": "Detalhes do imóvel",
        "sequentialCode": "Código sequencial",
        "waterCode": "Código de água",
        "energyCode": "Código de energia",
        "electricalConnection": "Tipo de conexão elétrica",
        "gasCode": "Código de gás",
        "usableArea": "Área útil(m²)",
        "size": "Metragem (m²)",
        "taxpayerCode": "Código do Contribuinte",
        "garbageFeeCode": "Código de taxa de lixo",
        "firefighterCode": "Código de bombeiro",
        "spuCode": "Código SPU",
        "location": "Localização",
        "propertyDestination": "Destinação imovel",
        "listedProperty": "Imóvel tombado",
        "manager": "Gestor", //Gerente
        "registry": "Cartório",
        "registration": "Matrícula",
        "fundraiser": "Angariador",
        "installments": "Parcelas",
        "branch": "Filial",
        "garages": "Garagens",
        "identification": "Identificação",
        "another": "Mais um",
        "comments": "Observações",
        "hobbyBox": "Hobby box",
        "corporateReason": "Razão social",
        "municipalregistration": "Inscrição municipal",
        "stateregistration": "Inscrição estadual",
        "responsiblename": "Nome responsável",
        "environment": "Ambiente",
        "approximate": "Valor aproximado dos tributos (%)",
        "IBPT": "Conforme disposto o artigo 2º da lei 12.741/2012, consulte IBPT",
        "taxregime": "Regime de Tributação",
        "culturalEncouraging": "Incentivo Cultural",
        "optforsimple": "Optante pelo Simples Nacional.",
        "withholding": "Retenção de IRRF (%)",
        "withholdingincome": "Retenção de imposto de renda se o valor for maior que",
        "pISWithholding": "Retenção PIS (%)",
        "cofinsRetention": "Retenção de Cofins (%)",
        "CSocial": "C. Sociais (%)",
        "Withholdtac": "Reter tac se o valor for maior que",
        "INSSWithholding": "Retenção INSS (%)",
        "category": "Categoria",
        "item": "Item",
        "description": "Descrição",
        "selectAll": "Selecionar tudo",
        "accountCategory": "Conta (categoria)",
        "serviceCode": "Código do serviço (SKU)",
        "debit": "Débito",
        "credit": "Crédito",
        "taxation": "Tributação",
        "ISSQNCollection": "Arrecadação de ISSQN",
        "serviceCodeTax": "Código do serviço (Tributação)",
        "percentageOfTaxes": "Percentual de impostos",
        "notTaxable": "Não tributável",
        "incomeInvoice": "Nota fiscal e DIMOB",
        "afterSaving": "Depois de salvar, registrar outro",
        "sourceUser": "Origem do Usuário",
        "sourceUsergroup": "Origem do Grupo de usuários",
        "municipal": "Municipal (%)",       // add new
        "state1": "Estadual (%)",           // add new
        "federal": "Federal (%)",           // add new
        "splitTransfers": "Repasses split",  // add new
        "selectTemplate": "Selecione um modelo", // add new
        "ExtractforIR": "Extrato para declaração de IR",  // add new
        "Enteryourmodel": "Insira seu modelo",             // add new
        "companyName": "Razão social",                        // add new
        "spouse": "Cônjuge",
        "verifying": "Verificando...",
        "submit": "Entrar",
        "agency": "Agência",
        "rememberforget": "Lembrou a senha? Entrar",
        "forgetpassword": "Esqueci minha senha",
        "EnsureDueDateAfterStartDate": "Certifique-se de que a data de vencimento seja posterior à data de início",
        "EnsureStartDateBeforeEndDate": "Certifique-se de que a data de início seja anterior à data de término",
        "EnsureOccutionDateAfterStartDate": "Certifique-se de que a data de ocupação seja posterior à data de início.",
        "EnsureMoveDateAfterStartDate": "Certifique-se de que a data de mudança seja posterior à data de início.",
        "EnsureKeyHandDateAfterStartDate": "A data de entrega da chave não pode ser posterior à data de início do contrato",
        "TransmissãoNacional": "Transmissão Portal Nacional",
        "ErrorCNPJCPF": "Duplicado CNPJ/CPF detectado. Por favor, tente novamente com um novo.",
        "Cellphone": "Celular (DDD + Número)",
        "webservicepassword": "Senha do serviço web",
        "certificatepassword": "Senha do certificado",
        "beneficiaries": "Beneficiários do formulário principal",
        "Lockowner": "Travar cadastro de proprietário sem CPF/CNPJ",
        "Locktenant": "Travar cadastro de locatário sem CPF/CNPJ",
        "Lockguarantor": "Travar cadastro de fiador sem CPF/CNPJ",
        "Locksurveyor": "Travar cadastro de vistoriador sem CPF/CNPJ",
        "Blockbroker": "Travar cadastro de corretor sem CPF/CNPJ",
        "Alwaysreimburse": "Sempre reembolsar IR da nota fiscal",
        "AlwaysreimbursePIS": "Sempre reembolsar PIS/COFINS/CSLL da nota fiscal",
        "ownedproperties": "Propriedade própria da imobiliária",
        "userealstate": "Usar dados da imobiliária como proprietário do imóvel",
        "adminvender": "Este administrador também éum fornecedor.",
        "Supplieradv": "Este fornecedor também éum client.",
        "notransfer": "Sem data de transferência configurada",
        "department": "Departamento",
        "Contractsin": "Contratos em",
        "married": "Casado",
        "single": "Solteiro",
        "widower": "Viúvo",
        "legallySeparated": "Legalmente separado",
        "disquitado": "Desquitado",
        "divorced": "Divorciado",
        "stableunion": "União estável",
        "male": "Masculino",
        "female": "Feminino",
        "Chargebankfee": "Cobrar tarifa bancária do proprietário",
        "charge_renter": "Cobrar tarifa bancária do locatário",
        "chargeValue": "Valor da tarifa bancária",
        "quantity": "Quantidade",
        "Goodoftheguarantor": "Bem do fiador",
        "balanceblocked": "Saldo bloqueado",
        "RG": "RG",
        "Organexp": "Órgão expedidor",
        "INSS": "INSS",
        "RPA": "RPA",
        "country": "País",
        "operation": "Operação",
        "Accountingintegration": "Integração contábil",
        "accountingsystem": "Código no sistema contábil",
        "Keytype": "Tipo de chave",
        "Keyforaddressing": "Chave para endereçamento",
        "Retention": "Retenção",
        "Mainowner": "Proprietário principal",
        "Corporateemail": "Email Corporativo",
        "phone": "Número de Telefone",
        "BusinessName": "Nome da Empresa",
        "Legalname": "Nome Legal",
        "invoiceanddimob": "Fatura e dimob",
        "homologation": "Homologação",
        "Production": "Produção",
        "SpecialTaxRegime": "Regime tributário especial",
        "citycode": "Código da cidade",
        "typeofpatio": "Tipo do logradouro",
        "DateofSettlement": "Data de Liquidação",
        "Settlementdate": "Data de liquidação"
      },

      "terminationCalc": {
        "title": "Cálculo da multa rescisória",
        "subtitle": "O valor da multa equivale a três pagamentos proporcionais de aluguel dos meses restantes para rescisão do contrato.",
        "subtitledai": "O valor da multa equivale a três pagamentos proporcionais de aluguel dos dias restantes para rescisão do contrato.",
        "equation": "Equação",
        "fine": "Três aluguéis",
        "rents": "Aluguéis",
        "monthsContract": "Meses de contrato",
        "endMonthsContract": "Meses até o fim do contrato",
        "penalty": "Multa",
        "total": "Total"
      },

      "otherfooter": {
        "Liquidatenotpayable": "Liquidar no a pagar",
        "Reversepayable": "Estornar do a pagar",
        "Launchandleavepending": "Lançar e deixar pendente",
        "Receiptofpayable": "Recibo",
      },

      "dropdownItem": {
        "Inprogress": "Em andamento",
        "select": "Selecionar",
        "newTemplate": "Novo Modelo",
        "person": "Pessoa",
        "status": "Status",
        "connected": "Conectado",
        "physicalAndLegal": "Física e Jurídico",
        "physical": "Físico",
        "legal": "Jurídico",
        "contractStatus": "Status do contrato",
        "groups": "Grupos",
        "todos": "Todos",
        "activeContracts": "Somente com contratos ativos",
        "filters": "Filtros",
        "inProgress": "Em andamento",
        "paused": "Pausado",
        "most": "Mais",
        "Seenonline": "Visto online",
        "typeOfExpenses": "Tipo de despesas", // Add new  
        "bankAccountType": "Tipo de conta",  //Add new
        "Physical and Legal": "Físico e Jurídico",
        "Physical": "Físico",
        "Legal": "Jurídico",
        "All": "Todos",
        "Marked for shipment": "Marcados para envio",
        "Not Marked for shipment": "Não marcados para envio",
        "Shipments sent": "Envios realizados",
        "Confirmed shipments": "Envios confirmados",
        "Shipments with error": "Envios com erro",
        "Settled shipments": "Envios liquidados",
        "No split": "Sem divisão",
        "With split": "Com divisão",
        "Finalizedandbreached": "Finalizado e violado",

        "activerent": "Aluguel ativo",
        "sold": "Vendido",
        "pendinglease": "Aluguel pendente",
        "available": "Disponível",
        "announced": "Anunciado",
        "invalid": "Inválido"
      },

      "contractsettings": {
        "ExpensePausedContracts": "Permitir o lançamento de despesas em contratos pausados",
        "ExpenseTerminatedContracts": "Permitir o lançamento de despesas em contratos rescindidos",
        "ContractsActive": "Criar todos os contratos com status 'ativo'",
        "ChargeBankFee": "Cobrar tarifa bancária",
        "RentalPeriodClosedMonth": "Período de aluguel com mês fechado",
        "NewContractsWillBeGeneratedConsidering": "Se marcado, todos os novos contratos serão gerados considerando período de mês fechado.",
        "DoNotDisplayContractData": "Exibir dados do contrato no corpo da fatura",
        "DoNotDisplayRentalPeriod": "Exibir o período de aluguel na fatura",
        "AllowExtraCharge": "Permitir cobrança extra em contrato rescindido",
        "CalculateFirstProportional": "Calcular 1º aluguel proporcional considerando a data de ocupação",
        "UncheckingThisOptio": "Ao desmarcar essa opção, será considerado a data de início do contrato.",
        "DoNotReadjustNegative": "Não reajustar índices negativos",
        "AccumulatedAmountIsNegative": "Se marcado, mesmo que o acumulado seja negativo, não será reajustado e não diminuirá o valor do aluguel.",
        "AdjustRentConsidering": "Reajustar aluguel considerando a data de ocupação",
        "IncomeTaxWithheld": "Imposto de Renda Retido na Fonte (IRRF)",
        "automaticallymarkcontractforIR": "Marcar automaticamente o contrato para IR",
        "AutomaticIRCalculation": "Se marcado, ao cadastrar novos contratos, já serão configurados para entrar no cálculo automático de IR.",
        "MarkContractForDIMOB": "Marcar automaticamente o contrato para DIMOB",
        "AutomaticallyConfigured": "Se marcado, ao cadastrar novos contratos, será configurado automaticamente para entrar na DIMOB.",
        "SimplifiedDiscount": "Desconto simplificado a todos os proprietários",
        "DeductPunctualityDiscount": "Deduzir desconto de pontualidade do cálculo de IR.",
        "DeductAdministrationFee": "Não deduzir taxa de administração no cálculo automático do IRRF",
        "SelectingThisOption": "Ao marcar essa opção, o calculo será sobre o valor bruto do aluguel.",
        "Standardcredit": "Credito padrão para",
        "Standardservice": "Serviço padrão",
        "Finecalculation": "Cálculo de multa",
        "Adjustments": "Reajustes",
        "Configuration": "Configurações",

        "Bankfeeamount": "Taxa bancária",
        "billofexchange": "Exibir dados do contrato no corpo do boleto",
        "periodonbillet": "Exibir período de locação no boleto",
        "leaseperiodwith": "Período de locação com mês fechado",
        "automaticallymarkcontractforIR.": "Marcar o contrato para  IR",
        "automaticallymarknewcontractsfordimob": "Marcar automaticamente novos contratos para DIMOB",
        "addonedaytotherentalperiod": "Adicionar um dia ao período de locação",
        "pendingandpausedcontracts": "Permitir lançamento de despesas em contratos pendentes e pausados",
        "allowpostingofexpensesonfinalizedcontract": "Permitir lançamento de despesas em contrato finalizado",
        "donotautomaticallyactivateagreementafterenvelopecompletes": "Não ativar automaticamente o acordo após o envelope ser concluído",
        "updateonlytheyearofthelastadjustmentdatewhenadjustingthecontract": "Atualizar apenas o ano da última data de ajuste ao ajustar o contrato",
        "setthelastreadjustmentdatebasedonthebeginningofthecontract": "Definir a última data de reajuste com base no início do contrato",
        "donotreadjustnegativeindices": "Não reajustar índices negativos",
        "readjustrentconsideringthefirstdayofthecontract": "Reajustar o aluguel considerando o primeiro dia do contrato",
        "createalloutstandingcontracts.": "Criar todos os contratos pendentes.",
        "alwaysconfigure1stproportionalrent": "Sempre configurar 1º aluguel proporcional",
        "useleaseoccupancydatetocalculate1stproratedrent": "Usar a data de ocupação do contrato para calcular o 1º aluguel proporcional",
        "valueofthe1stproportionalrentbasedonthedaysofthemonthinwhichthecontractbegins": "Valor do 1º aluguel proporcional com base nos dias do mês em que o contrato começa",
        "IRretentionbands": "Faixas de retenção do IR",
        "Changetracks": "Mudar trilhas",
        "DeducttheadministrationfeeintheautomaticcalculationoftheIRRF": "Deduzir a taxa de administração no cálculo automático do IRRF",
        "Displaythelandlord'sCPFintheadd-on": "Exibir o CPF do proprietário no complemento",
        "Renterandowerarea": "Área do locatário e proprietário",
        "Intheowner'sarea,youmustpresentallextractsfortheIRdeclaration": "Na área do proprietário, você deve apresentar todos os extratos para a declaração de IR",
        "Setupinvitations": "Configurar convites",
        "Terminationpenality": "Multa de rescisão",

        "Monthsforcalculatingthecontractualfine": "Meses para calcular a multa contratual",
        "ISSQNfunds": "Fundos de ISSQN",
        "ChangeISSRate": "Alterar a alíquota do ISS",
        "Darfcollection": "Cobrança do DARF",
        "Defaultvendor": "Fornecedor padrão",
        "Minimumamountforcollection": "Valor mínimo para a cobrança",
        "Deductanadministrationfeewhencalculatingthelion'sbill": "Deduzir uma taxa de administração ao calcular o carnê leão",
        "Taxrefundofinvoices": "Reembolso de impostos de faturas",
        "IRrefund": "Reembolso de IR",
        "PIS/COFINS/CSLLrefund": "Reembolso de PIS/COFINS/CSLL",
        "SettingupTaxRefunds for Invoices": "Configurando reembolsos de impostos para faturas",
        "Minimumrefundamount": "Valor mínimo de reembolso",
        "Rateusedforcalculation": "Taxa usada para cálculo",
        "Serviceusedtoissuetherefund": "Serviço usado para emitir o reembolso",
        "Servicesusedforbasecalculation": "Serviços usados para cálculo de base",
        "Service": "Serviço",
        "ConfigureISSReimbursementRates": "Configurar taxas de reembolso de ISS",
        "ALIQUOTS": "Alíquotas",
        "Rate": "Taxa",
        "change": "Alterar",
        "Selectaninvitation": "Selecionar um convite",
        "MonthlyIRRFTable-Calculationbasis": "Tabela IRRF mensal - Base de cálculo",
        "Aliquot": "Alíquota",
        "Installmenttobededucted": "Parcela a ser deduzida",
        "TobetterunderstandhowtheIRRFcalculationisdone": "Para entender melhor como é feito o cálculo do IRRF",
        "clickhere": "clique aqui",
        "retainsButCollection": "Retém, mas o recolhimento não será controlado pelo sistema",
        "retainAgencyCollects": "Retém, e a imobiliária recolhe",
        "retainsTenantCollects": "Retém, e o inquilino recolhe",
        "doesTax": "Não retém imposto.",
        "changeFromToday": "Manter os registros existentes e alterar a partir de hoje",
        "changeFromSelectedDate": "Manter os registros existentes e alterar a partir da data selecionada",
        "lastPaymentDate": "Alterar transações pendentes após a última data de pagamento",
      },

      "company": {
        "company": "Empresa",
        "registerData": "Dados cadastrais",
        "contact": "Contato",
        "address": "Endereço",
        "openingHours": "Horário de funcionamento",
        "soon": "Logotipo",
        "pixelsize": "Tamanho da imagem em pixels",
        "baseboard": "Rodapé",
        "footerofofter": "Rodapé dos relatórios",
        "listing": "Listando",
        "mondayToFriday": "Segunda à sexta",
        "monday": "Segunda-feira",
        "tuesday": "Terça-feira",
        "wednesday": "Quarta-feira",
        "thursday": "Quinta-feira",
        "friday": "Sexta-feira",
        "saturday": "Sábado",
        "sunday": "Domingo",
        "ImageCrop": "Corte de imagem"
      },

      "banks": {
        "name": "Banco",
        "bankAccounts": "Contas bancárias",
        "newBank": "Novo banco",
        "mainAccount": "Conta principal",
        "openingBalance": "Saldo inicial",
        "moreOptions": "Mais opções",
        "otherAccounts": "Outras contas",
        "agency": "Agência",
        "accountNo": "Conta",
        "accountHome": "Início da conta",
        "accountType": "Tipo de conta",
        "withDigit": "(Com dígito)",
        "closingDate": "Data de encerramento",
        "howMuchMoney": "Saldo inicial",
        "whenDidYou": "Data de Início",
        "newBankAccount": "Nova conta bancária",
        "listOver": "Listar mais de ",
        "autoScroll": "Auto rolagem",
        "listAll": "Listar tudo",
        "addBank": "Nova conta bancária",
        "checkingAccount": "Conta corrente",
        "box": "Caixa",
        "investment": "Investimento",
        "status": "Alterar banco status",
        "pixKey": "Chave Pix",
        "pixkeyType": "Tipo chave Pix",
        "templates": "Modelos",
        "docmenttype": "Tipo de documento",
        "Contractcharges": "Cobranças do contrato"
      },

      "checkboxLabel": {
        "administrativeAccess": "Acesso Administrativo",
        "usersChangeQuery": "Usuários - Alteração e Consulta",
        "closingOpeningMonth": "Fechamento e Abertura do Mês",
        "chartAccountsAmendment": "Alteração de Plano de Contas",
        "settingsConsultation": "Configurações - Consulta",
        "customersNotify": "Clientes - Notificação",
        "collectionsConsultation": "Consultar Cobranças",
        "collectionsAmendment": "Alteração de Cobranças",
        "collectionsInvalidate": "Invalidação de Cobranças",
        "collectionsSettlement": "Acerto de Cobranças",
        "collectionsReverse": "Estorno de Cobranças",
        "metricsVisualization": "Visualização de Métricas",
        "boxConsultation": "Consulta de Caixa",
        "agreementsConsultation": "Consultar Acordos",
        "monetaryUpdateAmendment": "Alteração de Tabela de Atualização Monetária",
        "legalConsultation": "Jurídico - Consulta",
        "invoicesConsultation": "Consultar Faturas",
        "invoicesAmendment": "Alteração de Faturas",
        "servicesConsultation": "Consultar Serviços",
        "expensesConsultation": "Consultar Despesas",
        "DoFixedAmount": "Fazer valor fixo",
        "Retaintransfer": "Reter a transferência na conta da agência.",
        "Setup1strental": "Calcular o 1º aluguer proporcional"
      },

      "branches": {
        "branches": "Filiais",
        "branch": "Filiais",
        "newBranch": "Nova filial",
        "name": "Nome",
        "contact": "Contato",
        "editBranch": "Editar filial"
      },

      "formReceipt": {
        "selectwayofbankheader": "Selecionar forma de recebimento",
        "selectwayofbankcontent": "As formas de recebimento alteram o status diretamente.",
        "atMonetary": "Atualização monetária",
        "Formsofreceipt": "Formas de recebimento",
        "waysOfReceiving": "Formas de recebimento",
        "newWayOfReceiving": "Nova forma de receber",
        "anotherAction": "Outra ação",
        "bankSlipOptions": "Opções do boleto bancário",
        "tradeName": "Nome fantasia da empresa como cedente nos boletos",
        "displayTheAmount": "Exibir a quantidade da composição no corpo dos boletos",
        "displayTheTotal": "Exibir a valor total da composição no corpo dos boletos",
        "doNotDisplay": "Não exibir o logo da empresa no verso dos boletos",
        "congratulations": "Parabéns aos adimplentes (individual)",
        "pendingCharges": "Alerta de cobranças pendentes (individual)",
        "sortBillet": "Ordenar composições do boleto pelo nome do serviço ou produto",
        "doNotDisplaycomposition": "Não exibir composição nos boletos",
        "configureBillingRule": "Configurar regra de cobrança",
        "blockingAndCancellation": "Bloqueio e cancelamento",
        "practicedRates": "Taxas praticadas",
        "fees": "Honorários",
        "allFees": "Juros",
        "trafficTicket": "Multa",
        "Fine": "Multa",
        "atmonetary": "Atualizaçã monetária",
        "ticketDiscount": "Instruções de desconto no boleto",
        "discount": "Desconto",
        "doNotUsePercentage": "Não usar percentual, usar valor fixo",
        "discountedServices": "Serviços que incidem desconto",
        "bankcreditTitle": "Para qual conta bancária os valores serão creditados?",
        "alterTitle": 'A "Conta bancária" deve ser a mesma utilizada no seu acordo com o adquirente de pagamentos. Alterar a conta no sistema não implica em mudança com o adquirente.',
        "accountCredit": "Crédito na conta:",
        "active": "Ativa",
        "disabled": "Desabilitado",
        "disable": "Desabilitar",
        "activeContegency": "Ativo (Contingência)",
        "monthlyinterest": "Taxa de juros mensal",
        "interestandfine": "Instruções de juros e multa no boleto",
        "finerate": "Taxa de multa",
        "discountrate": "Taxa de desconto",
        "givediscount": "Conceder desconto até",
        "applyAllDiscount": "Aplicar desconto em todos os serviços",
        "lawyerFeesTitle": "Honorários",
        "lawyerFees": "Honorários advocatícios",
        "display": "Mostrar",
        "donotdisplay": "não Mostrar",
        "ApplyDiscountOnly": "Aplicar desconto apenas em alguns serviços",
        "dicountTitle": "Serviços que incidem descontos",
        "characterremains": "Caracteres restantes",
        "characterremain": "Personagem restante",

        "Whattypeofticketwillyouuse": "Que tipo de boleto você usará?",
        "Youcanissueslipsusingyou": "Você pode emitir boletos usando sua conta homologada com o banco ou pode usar boletos Kenlo",
        "WerecommendKenloslips": "Recomendamos boletos Kenlo, pois eles são totalmente automatizados, não precisam ser configurados ou aprovados. Você nunca mais precisará baixar arquivos de retorno do banco e processá-los no sistema para realizar lançamentos, pois o sistema fará tudo isso por você.",
        "WerecommendKobanaslips": "Recomendamos deslizamentos Kobana",
        "astheyarefullyautomated": "pois eles são totalmente automatizados, não precisam ser configurados ou aprovados. Você nunca mais precisará baixar arquivos de retorno do banco e processá-los no sistema para realizar lançamentos, pois o sistema fará tudo isso por você.",
        "knowmore": "saiba mais",
        "HireKobana": "Contrate Kenlo",
        "HireKobanatoreceivewithacreditcard": "Contrate Kobana para receber com cartão de crédito",
        "Pleaseconfigureyour": "Por favor configure seu",
        "account": "conta",
        "here": "aqui"
      },

      "paidTobepaid": {
        "Variousexpenses": "Despesas diversas",
        "Tributewithbarcode": "Tributo com código de barras",
        "ImportNFe(xml)": "Importar NFe (xml)",
        "CPF_CNPJOriginalpayer": "CPF/CNPJ do pagador original",
        "Digitableline": "Linha digitável",
        "Barcodeexpense": "Despesa com código de barras",
        "Processing": "Processando...",
        "Markallforprocessing": "Marcar todos para processamento",
        "Unmarkallforprocessing": "Desmarcar todos para processamento",
        "Processreturn": "Processar devolução",
        "Transferspayable": "Repasses a pagar",
        "Realestateexpenses": "Despesas da imobiliária",
        "Contractexpenses": "Despesas de contrato",
        "Processingstatus": "Status de processamento",
        "Unmarked": "Não marcado",
        "Sent": "Enviado",
        "Scheduled": "Agendado",
        "Witherror": "Com erro",
        "Paidwith": "Pago com",
        "Fromsuppliers": "De fornecedores",
        "Youhave": "Você tem",
        "recurringexpensestopost": "despesas recorrentes para lançar",
        "Clickheretolaunch": "Clique aqui para lançar",
        "chargespaid": "despesas pagas",
        "chargepaid": "Cobrança recebidas",
        "chargestobereceived": "Cobrança a serem recebidas",
        "chargetobereceived": "Cobranças a serem recebidas",
        "collectionsreceivable": "Despesas pagas",
        "collectionsToBereceivable": "Despesas a pagar",
        "Unseencharges": "a pagar",
        "Expense": "Despesa",
        "Documentpayment": "Doc. a pagar",
        "Documentsupervisior": "Nota fiscal",
        "Proof": "Comprovante",
        "Others": "Outros",
        "transfertobecarriedout": "Despesas a pagar",
        "Issuecheck": "Emitir cheque",
        "Bordero": "Borderô",
        "Marktoprocess": "Marcar para processar",
        "Unmarkfromprocessing": "Desmarcar do processamento",
        "Receipt": "Recibo",
        "Changebankaccount": "Alterar conta bancária",
        "Changeexpiration": "Alterar vencimento",
        "Fromsuppliercategories": "De categorias de fornecedores",
        "Barcode": "Código de barras",
        "File": "Arquivo",
        "Vendorbankaccounttype": "Tipo de conta bancária do fornecedor",
        "Supplierbankaccountbank": "Banco da conta bancária do fornecedor",
        "Ofthetype": "Do tipo",
        "Withoutoutstandingcharges": "Sem cobranças pendentes",
        "Resetfilter": "Reiniciar filtro",
        "Accountcategory": "Categoria de conta",
        "Ithasbeenpaid": "Foi pago",
        "Settleintothesamebankaccountthat": "Liquidar na mesma conta bancária em que a despesa foi lançada",
        "BorderoSelectpaymentauthorizationdate": "Borderô (Selecionar data de autorização de pagamento)",
        "Authorizationdate": "Data de autorização",
        "Generatebordero": "Gerar borderô",
        "Checkboxisnotchecked": "Caixa de seleção não marcada",
        "Editexpense": "Editar despesa",
        "Chargesent": "Cobrança enviadas e não visualizadas",
        "Chargessent": "Cobranças enviadas e não visualizadas",
        "chargespaids": "Cobranças pagas",
        "chargepaids": "Cobrança pagas",
        "chargespaidto": "Cobranças recebidas",
        "chargepaidsto": "Cobrança recebidas",
        "Financial Expenses": "Despesas financeiras",
        "reverse": "Tem certeza que deseja realizar esse estorno?",
        "Reversed": "Estornado",
        "PaidTransfers": "Repasses pagos",
        "Realestatexpenses": "Despesas da imobiliaria",
      },

      "taxation": {
        "warning": "Recomendamos que os tributos sejam parametrizados pelo contador responsável pela sua empresa devido à alta complexidade da legislação tributária brasileira. Além disso, o contador deve validar as primeiras notas fiscais emitidas pelo sistema na prefeitura ou na receita federal de seu estado.",
        "taxationOfServicesRendered": "Tributação de serviços prestados",
        "general": "Geral",
        "important": "Importante",
        "yourCompanyMust": "Sua empresa deve ser autorizada pela prefeitura a emitir notas fiscais com software de terceiros",
        "registerTheTax": "Cadastre o código de serviço fiscal utilizado pela sua empresa.",
        "youAlsoNeedAn": "Você precisa ter um ",
        "A1": "certificado A1 ",
        "informTaxesByType": "Informar tributos por tipo",
        "serviceCodes1": "Códigos de serviços e alíquotas",
        "culturalEncouraging": "Incentivo Cultural",
        "newTaxCode": "Novo código fiscal",
        "CNAE": "CNAE",
        "serviceDescription": "Descrição do Serviço",
        "services": "Serviços",
        "ISSQN": "ISSQN",
        "taxesRetentation": "retenção de impostos",
        "NF_E_service": "serviço NF-E",
        "cityHallAccessData": "Dados de acesso da prefeitura",
        "digitalCertificate": "Certificado digital é necessário para enviar NF-E.",
        "replaceCertificate": "Substituir certificado",
        "information": "Informação",
        "editTaxation": "Editar tributação",
        "newTaxservice": "Novo código de imposto sobre serviços",
        "Incurfederal": "Incorrer em impostos federais",
        "PayIRRF": "Pagar IRRF",
        "PayINSS": "Pagar INSS",
        "serviceCode": "Código de Serviço",
        "ISSQNRate": "Taxa de ISSQN (%)",
        "description": "Descrição",
        "municipalTaxCode": "código tributário municipal",
        "Aftersaving": "Depois de salvar cadastre outro",
        "Sendcertificate": "Enviar certificado",                          // add new
        "Drag": "Arraste",                                                // add new
        "DropPFXfile": "Solte o arquivo PFX",                             // add new
        "yourfilestoassetsor": "seus arquivos para ativos, ou",           // add new
        "browse": "navegar",                                              // add new
        "Cityhall": "Cidade",                                             // add new
        "Download": "Download",                                           // add new
        "UploadedFile": "Arquivo carregado",                              // add new
        "Clicktouploadnewfile": "Clique para fazer o upload de um novo arquivo",   //add new
        "Microentrepreneur": "Microempresário e Empresa de Pequeno Porte (ME EPP)",
        "Municipal": "Microempresa Municipal",
        "Iestimated": "Estimativa",
        "Societyof": "Sociedade de Profissionais",
        "Cooperative": "Cooperativa",
        "IndividualMicroentrepreneur": "Microempresário Individual (MEI)",
        "ISS/Autonomous": "ISS/Fixo Autônomo",
        "LimitedSociety": "Sociedade Limitada",
        "AnonymousSociety": "Sociedade Anônima",
        "IndividualLimited": "Empresa Individual de Resp.Limitada (EIRELI)",
        "Others": "Outros",
        "SenhadoCertificado": "Senha do Certificado digital A1",
        "A1digitalcertificate": "Certificado digital A1:",
        "certificateexpired": "O certificado atual expirou",
        "monthsago": "meses atrás",
        "currentcertificatewillexpire": "O certificado atual expirará em",
        "monthson": "meses em"
      },

      "chartofaccount": {
        "chartOfAccounts": "Plano de Contas",
        "newitem": "Novo item",
        "editCategoty": "Editar conta (categoria)",
        "newAccount": "Nova conta (categoria)",
        "heading": "Os itens são criados dentro das contas (categoria). As edições feitas refletirão no serviço e no relatório de outras áreas do sistema",
        "categoryheading": "As categorias são as principais divisões na hierarquia do plano de contas. As edições feitas refletirão nos serviços e relatórios de outras áreas do sistema.",
        "specialcateforyaccount": "CONTAS DE CATEGORIA ESPECIAL",
        "Categoryaccountforbankfees": "Conta de categoria para taxas bancárias",
        "Categoryaccountforbillfees": "Conta de categoria para taxas de boletos",
        "deleteTobePaid": "Deseja excluir entradas marcadas?"
      },

      "userModule": {
        "user": "Usuário",
        "toMark": "Marcar",
        "addToGroup": "Adicionar ao grupo",
        "permissionAccess": "Editar acessos",
        "groups": "Grupos",
        "addNew": "Novo",
        "myIp": "Utilisar meu IP atual",
        "fixedIp": "Limitar por IP fixo",
        "limitAccessByday": "Limitar acesso por dia e horário",
        "removeFromGroup": "Excluir do grupo",
        "Updategroup": "Atualizar grupo",
        "updateUser": "Atualizar usuário"
      },

      "readjIndex": {
        "extraReadjustmentIndex": "Índices extras de reajuste",
        "readjustment_indexes": "Índices de reajuste",
        "newIndexes": "Novos índices",
        "launchIndex": "Indice de lançamento",
        "NewIndex": "Novo Índice",
        "Nameofthetable": "Nome da tabela",
        "table": "Tabela",
        "Outofdate": "Desatualizado",
        "Active": "Ativo",
      },

      "expenditure": {
        "expenses": "Despesas",
        "beingrecurring": "Recorrente",
        "Maturity": "Vencimento",
        "Details": "Detalhes",
        "value": "Valor",
        "newCharge": "Nova cobrança",
        "newExpense": "Nova despesa",
        "newRecurringExpense": "Nova despesa recorrente",
        "withTagged": "Com marcado",
        "Settleonpayable": "Liquidar",
        "reversePayable": "Estornar",
        "launchLeavePending": "Lançar como pendente",
        "receiptPayable": "Recibo",
        "contractualExpenses": "Despesas contratuais",
        "liquidate": "Liquidar",
        "settleontheduedate": "Liquidar na data de vencimento",
        "printreceipt": "Imprimir recibo",
        "tothrow": "Lançar",
        "launchontheexpirationday": "Lançar no dia de vencimento",
        "timeperiod": "Período",
        "liquidaremlote": "Liquidar em lote"
      },

      "contractlistother": {
        "Pausecontract": "Pausar contrato",
        "Reasonforpause": "Motivo da pausa",
        "Reasonforactive": "Motivo da ativa",
        "charactersleft": "caracteres restantes",
        "Pause": "Pausar",
        "Whowillbenotified": "Quem será notificado",
        "sendemail": "enviar email",
        "Partiestonotify": "Partes a notificar",
        "Tenants": "Locatários",
        "Main_tenant": "Locatário Principal",
        "Fireinsurer": "Seguradora de Incêndio",
        "Bailinsurer": "Caução",
        "Selectamodel": "Selecionar um modelo",
        "send": "enviar",
        "Publicationofdocuments": "Anexar documentos",
        "Checkbox": "Caixa de seleção",
        "SizeUploaded": "Tamanho do Arquivo",
        "SendDate": "Data de envio",
        "Share": "Compartilhar",
        "Attachdocuments": "Anexar documentos",
        "Not": "Não",
        "percentage": "Porcentagem",
        "Toshare": "Compartilhar",
        "Extrasanddiscounts": "Extras e descontos",
        "Internalobservation": "Observação interna",
        "Noteforthecustomer": "Observação para o cliente",
        "Competence": "Competência",
        "Latestcontracts": "Últimos contratos",
        "Tax": "Imposto",
        "NewTax": "Novo imposto",
        "Code": "Código",
        "Category": "Categoria",
        "ValueBooklet": "Valor",
        "charge": "cobrança",
        "NoLatestcontractsfound": "Nenhum contrato recente encontrado",
        "RegisterTax": "Cadastrar Imposto",
        "Chargecategory": "Categoria de Cobrança",
        "Chargecode": "Código da Cobrança",
        "Chargetype": "Tipo de Cobrança",
        "Formofpayment": "Forma de Pagamento",
        "Amount": "Valor",
        "Numberofinstallments": "Número de Parcelas",
        "Fraction": "Fração",
        "Chargenotmanagedbytherealestate": "Cobrança não gerenciada pela imobiliária",
        "Cashvalue": "Valor",
        "Responsibleforpayment": "Responsável pelo Pagamento",
        "Launchcharge": "Lançar Cobrança",
        "Expiration": "Vencimento",
        "Incursfee": "Incorre em taxa",
        "Generatebilling": "Gerar faturamento",
        "Settleonpayable": "Liquidar",
        "Reversepayable": "Estornar",
        "Selectsubscribers": "Selecionar assinantes",
        "DocumentNo": "Documento nº",
        "documentsName": "Nome do Documento"
      },

      "notifications": {
        "name": "Notificações",
        "welcome": "Bem-vindo",
        "birthday": "aniversário",
        "contract": "contrato",
        "insurance": "Seguro",
        "gurantees": "garantias",
        "birthdaysDay": "Aniversários do dia",
        "contractExpiration": "Vencimento de contrato",
        "tenant": "Locatário",
        "owner": "Proprietário",
        "beforeExpiration": "Antes do vencimento",
        "afterExpiration": "Após o vencimento",
        "insuranceExpiration": "Vencimento de seguro",
        "expirationGurantees": "Vencimento de  fiador",
        "checkNotification": "Enviar notificação",
        "welcomeDayAfter": "Dias após o início do contrato",
        "daysBeforeExpiration": "dias antes do vencimento",
        "daysAfterExpiration": "dias depois do vencimento",
        "notNotifyOwner": "Não notificar o proprietário quando o contrato estiver sendo desocupado",
        "sendNotificationBirthday": "Enviar notificação no aniversário",
        "sendNotificationForActive": "Enviar notificação apenas para contratos ativos",
        "gurantorDefault": "inadimplência fiador",
        "off": "desligado",
        "enable": "Habilitar",
        "editTemplate": "Editar modelo",
        "for": "para",
        "previewTemplate": "Visualizar modelo",
        "companyLogo": "Logo da empresa",
        "preview": "visualizar"
      },

      "services": {
        "newService": "Novo serviço",
        "editService": "Editar serviço",
        "description": "Descrição",
        "payIncomeTax": "Pagar imposto de renda",
        "calculateAdministration": "Calcular taxa de administração sobre o serviço",
        "calculateAdministrationFeeBeducting": "Calcular taxa de administração deduzindo o valor do serviço",
        "otherCNAES": "Outros CNAES",
        "administrationFeeApplies": "Aplicar taxa de administração",
        "incidesIncome": "Incide imposto de renda para o proprietário",
        "tags": {
          "income": "DIMOB: Rendimento",
          "commission": "DIMOB: Comissão",
          "fee": "DIMOB: Imposto",
          "ownProperty": "Imóvel próprio",
          "admFee": "Incide taxa de administração",
          "ownerFee": "Incide imposto de renda ao proprietário",
          "admCalc": "Deduz valor no cálculo da taxa de administração"
        },
        "tenant": "Locatário",
        "owner": "Proprietário",
        "Imobiliária": "Imobiliária",
        "immobile": "Imóvel",
        "third": "Terceiro",
        "notInclude": "Não incluir",
        "includeAsIncome": 'Incluir como rendimento',
        "issueAnInvoice": "Emitir nota fiscal e incluir como comissão",
        "includeAsWithholding": "Incluir como imposto retido",
        "alwaysInMunicipality": "Sempre no município (Serviço prestado na empresa)",
        "outsideTheMunicipality": "Fora do Município quando aplicável (Serviço prestado no cliente)",
        "free": "Isento",
        "immune": "Immune",
        "suspendedCourtDecision": "Suspensa por decisão judicial",
        "suspendedDueAdministrative": "Suspensa por procedimento administrativo",
        "calculatebasedonmoveout": "Calcular dias proporcionais pela data de férias"
      },

      "users": {
        "name": "Usuários",
        "user": "usuário",
        "changeRestrictions": "Alterar restrições por dia, horário e localização",
        "copyAccessPermissions": "Copiar acesso e grupos de outro usuário",
        "copyAccessFrom": "Copiar acesso de um grupo de usuários",
        "alertTouse": "Importante: Para usar esse recurso, a localização (onde esse usuário pode acessar o sistema) deve ter um endereço IP FIXO. Para obter um, entre em contato com seu provedor de serviços de Internet (ISP).",
        "userGroups": "Grupos de usuários",
        "allusers": "Usuários",
        "usersWithoutGroup": "Usuários sem grupo",
        "newgGroup": "Novo grupo",
        "theAccessesGroup": "Os acessos desse grupo podem ser copiados para os usuários do",
        "register": "registro",
        "ofusers": "de usuários ou em",
        "usersMore": "Copiar acessos de um grupo de usuários",
        "allowCustomer": "Permitir que o cliente abra chamados para este grupo",
        "deleteGroup": "Tem certeza de que deseja excluir o grupo?"
      },

      "otherstran": {
        "Includeinthenexttransfer": "Incluir no próximo repasse ",
        "includeexpensein": "Incluir despesa no cálculo de rescisão",
        "recurring": "Recorrente",
        "installment": "Parcela",
      },

      "bankTransaction": {
        "selectbank": "Selecione a conta",
        "Banktransactionson": "Transações bancárias em",
        "NewRecipe": "Novo Recebimento",
        "Newtransfer": "Novo repasse",
        "Newreleseinbox": "Nova Liberação no Caixa",
        "Inday": "Em dia",
        "Offthetype": "Do tipo",
        "date": "Data",
        "Revenue": "Receita",
        "Expenses": "Despesas",
        "Balance": "Saldo",
        "Cloneexpense": "Clonar despesa",
        "Newretention": "Nova Retenção",
        "collectionagency": "Agência de Cobrança",
        "Discounts": "Descontos",
        "Issuance": "Emissão",
        "Newrelease": "Nova Liberação",
        "Historycomplement": "Complemento de Histórico",
        "ImportexpenseviaNF-e": "Importar despesa via NF-e",
        "Invoice(Xml)": "Nota Fiscal (Xml)",
        "Filter": "Filtro",
        "Withchargessettlededbetween": "Com cobranças liquidadas entre",
        "Withpaymentstatus": "Com status de pagamento",
        "Pendingpayment": "Pagamento pendente",
        "clearingcheck": "Compensação de verificar",
        "AutomaticforwardingviaSplit": "Repasse automático via Split",
        "Manualtransfer": "Transferência manual",
        "Withoutguaranteedstatus": "Sem status de garantia",
        "ok": "Sim",
        "Paymentmethod": "Forma de pagamento",
        "Withoutholdingtaxes": "Impostos retidos",
        "Docno": "Nº do documento",
        "Participation": "Participação",
        "Price": "Preço",
        "Noofinstallments": "Nº de parcelas",
        "Repeat": "Repetir",
        "MoreFileds": "Mais Campos",
        "Datesaleoff": "Data de venda",
        "Previous balance": "Saldo anterior",
        "Financial Expense": "Despesa Financeira",
        "fixedDiscountError": "O desconto é fixo e o encargo não pode ser inferior ao valor do desconto."
      },
      "newContractDev": {
        "lastPaymentDate": "Última data de pagamento",
        "changeFromSelectedDate": "Alterar da data selecionada",
        "changeFromToday": "Mudança a partir de hoje",
        "chooseFromContractual": "Escolha quando as alterações contratuais devem ser consideradas para cobranças e transferências pendentes",
        "pendingChargesAndTransfer": "Cobranças pendentes e transferência",
        "PropertySection": "Imóvel",
        "PropertyName": "Imóvel *",
        "PropertyOwner": "Proprietário",
        "ContractType": "Tipo de contrato",
        "CommercialActivity": "Atividade comercial",
        "TenantSection": "Locatário",
        "TenantName": "Locatário *",
        "AddAnotherTenant": "Adicionar outro inquilino",
        "AddAnother": "Mais um",
        "TaxInvoice": "Nota fiscal",
        "DeductFee": "Deduzir taxa de adm. no IRRF",
        "GenerateDIMOB": "Gerar DIMOB pelo sistema",
        "ContractDetailsSection": "Detalhes do contrato",
        "Start": "Início *",
        "End": "Fim",
        "MoveDate": "Data de ocupação",
        "RentAmount": "Valor de aluguel (R$) *",
        "DueDate": "Vencimento *",
        "1stEwnt": "1° aluguel proporcional",
        "istRentAmount": "Valor do 1° aluguel (R$)",
        "ChargeTogtherRent": "Cobrar junto com o 2º aluguel",
        "AdjustmentIndex": "Índice de ajuste *",
        "ChargeTitle": "Cobrança",
        "FormPayment": "Forma de pagamento *",
        "WarrantySection": "Garantia",
        "Cosigner": "Fiador",
        "AddAnotherCosigner": "Adicionar outro fiador",
        "FireInsurance": "Seguro Incêndio",
        "InsuredAmount": "Valor de cobertura (R$)",
        "PremiumAmount": "Valor do prêmio (R$)",
        "InstallmentAmount": "Valor do prêmio (R$)",
        "SelectedPlan": "Plano contratado",
        "ResponsibleParty": "Responsável",
        "NoFireInsurance": "Este contrato não possui seguro incêndio",
        "RentTransferSection": "Repasse do aluguel",
        "TypeTransfer": "Tipo de repasse",
        "DaysPaymentTransfer": "Dias para repasse",
        "GuaranteedMonths": "Número de meses garantidos",
        "BankFee": "Tarifa bancária DOC/TED",
        "RenterFee": "Taxa de locatário por cobrança",
        "ContractSituationSection": "Situação do contrato",
        "ActiveText": "Já está pronto, é possível gerar cobranças",
        "PendingText": "Possui pendências, não é possível gerar cobranças",
        "ChecklistTemplate": "Modelo de checklist",
        "contractSummary": "Resumo do contrato",
        "contractSummaryContent": "Ao preencher os dados, você irá visualizar um resumo do contrato em tempo real.",
        "costThisContract": "Custo deste contrato",
        "costThisContractContent": "Os contratos gerados no Kenlo Locação serão gratuitos ao utilizar os serviços Kenlo Seguros e Kenlo Pagamentos.",
        "remainingPlanBalance": "Saldo restante do plano",
        "collapseAll": "Expandir tudo",
        "savedConnectedInternet": "O contrato ficará salvo como pendente automaticamente",
        "zeroCostContract": "Para ter custo zero neste contrato, mantenha a Kenlo Seguros como seguradora definida.",
        "insuranceMandatoryTenancyLaw": "A contratação de seguro incêndio é obrigatória para locações conforme o Artigo 22 da Lei do Inquilinato.",
        "zeroCostContractInsurance": "Para ter custo zero neste contrato, mantenha a Kenlo Seguros como seguradora definida.",
        "costContractKenloInsurance": "O custo deste contrato aumentou porque você definiu uma seguradora diferente de Kenlo Seguros.",
        "apartmentWithoutAssistanc": "Apartamento sem assistência",
        "inCollaborationKenloInsurance": "em parceria com Kenlo Seguros",
        "inTotal": "no total",
        "electricalDamages": "Danos elétricos",
        "fireExplosionSmoke": "Incêndio, explosão, fumaça",
        "lightningStrikeFallingAircraft": "Queda de raio, queda de aeronaves",
        "insuranceAutomaticallyContractedActivated": "O seguro será contratado automaticamente assim que o contrato de locação for ativado.",
        "apartmentPlus": "Apartamento mais",
        "registerContract": "Cadastrar contrato",
        "costOFThisContractContent": "Os contratos gerados no Kenlo Locação podem ter custo zero sempre que você  utilizar estas vantagens exclusivas em conjunto:",
        "kenloPay": "Kenlo Pagamentos",
        "kenloPayContent": "Gere os boletos de aluguel sem custo utilizando o Kenlo Pagamentos. Os custos serão repassados para o locatário automaticamente.",
        "kenloInsuranceforFireInsurance": "Seguro Incêndio Kenlo Seguros",
        "kenloInsuranceforFireInsuranceContent": "Uma parceria exclusiva entre Kenlo e Tokio Marine Seguradora. Cotação instantânea e cobrança inclusa nos boletos da locação.",
        "newContractsSettingsArea": "Você pode alterar o padrão de custo dos novos contratos através da área de configurações de contratos.",
        "remainingPlanBalanceContent": "Este é o total de contratos ainda disponíveis no seu plano atual. Quando o saldo se esgotar, o método de cobrança do Kenlo Locação irá mudar, podendo até ser gratuito ao utilizar algumas vantagens exclusivas da Kenlo.",
        "balanceRemaining": "Saldo",
        "balanceRemainingContent": "150 contratos no plano Enterprise",
        "contractRegistered": "Contrato cadastrado!",
        "contractRegisteredContent": "Você pode abrir a página do contrato para acessar o documento, imprimir, editar informações e muito mais.",
        "contractRegisteredContentRemember": "Lembre-se: a cobrança do seguro incêndio Kenlo Seguros já estará inclusa nos boletos de locação.",
        "newCosigner": "Novo fiador",
        "newCosignerContent": "Insira as informações solicitadas para incluir um novo fiador.",
        "cpfOrcnpj": "CPF/CNPJ",
        "corporateName": "Razão social",
        "tradeName": "Nome fantasia",
        "contactInformation": "Contato",
        "cellphoneNumber": "Celular (DDD + Número)",
        "parentage": "Filiação",
        "employmentInformation": "Dados empregatícios",
        "businessIndustry": "Ramo de atividade",
        "representatives": "Representante",
        "addAnotherRepresentative": "Adicionar outro representante",
        "cosignerProperty": "Bem do fiador",
        "propertyValue": "Valor imóvel",
        "addAnotherProperty": "Adicionar outro bem",
        "RGNumber": "RG",
        "issuingAuthority": "Órgão expedidor",
        "issuanceDate": "Data expedidor",
        "gender": "Sexo",
        "dateOfBirth": "Nascimento",
        "nacionality": "Nacionalidade",
        "placeOfBirth": "Naturalidade",
        "notDomiciledBrazi": "Não domiciliado no Brasil",
        "insertNewOwnerOrBeneficiary": "Insira as informações solicitadas para incluir um novo proprietário ou beneficiário.",
        "standardFormPayment": "Forma de pagamento preferencial",
        "enableSimplifiedDeduction": "Habilitar desconto simplificado",
        "enableSimplifiedDeductionContent": "Habilitar o valor de R$  564,80 a ser abatido do valor base antes de calcular a faixa da alíquota do IR e no DARF",
        "doNotNotifyBillingRule": "Não notificar na regra de cobrança",
        "newRepresentative": "Novo representante",
        "newRepresentativeContent": "Insira as informações solicitadas para incluir um novo representante.",
        "insertNewPropertyAdministrator": "Insira as informações solicitadas para incluir uma nova administradora.",
        "propertyAdministratorSupplier": "Esta administradora também é um fornecedor",
        "insertNewCondominium": "Insira as informações solicitadas para incluir um novo condomínio.",
        "newRealEstateAgent": "Novo corretor ou angariador",
        "newRealEstateAgentContent": "Insira as informações solicitadas para incluir um novo corretor ou angariador.",
        "newTenantContent": "Insira as informações solicitadas para incluir um novo inquilino.",
        "costNewContracts": "Custo de novos contratos",
        "costNewContractsContent": "A configuração escolhida será padrão para todos os novos contratos.",
        "zeroCost": "Custo zero",
        "zeroCostContent": "Utilize seguro incêndio Kenlo Seguros e gere boletos com o Kenlo Pagamentos para ter custo zero durante a vigência do contrato. Os custos de cobrança serão repassados para o locatário.",
        "customized": "Personalizado",
        "customizedContent": "Escolha outra seguradora e forma de pagamento dos aluguéis para gerar um contrato com custo mensal personalizado.",
        "UpTo": "até ",
        "Ativos": "Ativos",
        "services": "Serviços",
        "Commerce": "Commerce",
        "Office": "Escritório",
        "consulting": "consultoria",
      },
      "dimbo": {
        "ToSetup": "Configurar",
        "SendExtract": "Enviar extrato",
        "ContractAdministeredPeriod": "contrato administrado no período",
        "IntermediatedSalePeriod": "Venda intermediada no período",
        "OwnersWhoWillDIMOB": "Proprietários que farão parte do DIMOB",
        "ErrorFoundGeneration": "Erro encontrado na geração do DIMOB",
        "CheckErrors": "Verificar erros",
        "NoPendencyFoundDeclaration": "Nenhuma pendência encontrada para a declaração do DIMOB",
        "LeaseAgreements": "Contratos de locação",
        "SalesContracts": "Contratos de venda",
        "IncomeReport": "Relatório de renda",
        "SendStatement": "Enviar declaração",
        "SalesEntering": "Vendas entrando no DIMOB",
        "NoDataFound": "Nenhum dado encontrado"
      },
      "sendEmail": {
        "SummaryFrom": "Resumo de",
        "Recipient": "Destinatário",
        "Message": "Mensagem",
        "EmailDisplay": "Exibição do Email",
        "NotificationsOfDefault": "Notificações de Padrão",
        "hasAlreadySeen": "já foi visto",
        "EmailViewed": "Email visualizado",
        "hoursAgo": "horas atrás",
        "BouncingEmails": "Emails rejeitados",
        "SentEmails": "Emails enviados",
        "OfDeliverability": "Da entregabilidade",
        "ForceSend": "Forçar envio",
        "RemoveEmailBounceList": "Remover email da lista de rejeição",
        "Returning": "Retornando",
        "unseen": "não visto",
        "emailsBeing": "emails sendo",
        "BouncingEmail": "Email rejeitado",
        "RemoveEmailFromList": "Remover email da lista",
        "RemoveEmailFromListContent": "Se você acredita que este email está nesta lista por engano, insira o código abaixo para removê-lo. Respeitamos boas práticas anti-spam e, portanto, não é possível remover vários destinatários desta lista de uma vez.",
        "ToRemove": "Para remover"
      },
      "propertyDev": {
        "rentAmount": "Valor de aluguel (R$)",
        "sellingPrice": "Valor de venda (R$)",
        "fixedValue": "Não usar percentual, usar valor fixo",
        "rentFee": "Taxa de locação",
        "type": "Tipo",
        "ownershipPercentage": "Percentagem de propriedade (%)",
        "transferProcessed": "Dias para o repasse",
        "serviceContract": "Contrato de prestação de serviço",
        "acquisitionDate": "Data de aquisição",
        "admFee": "Taxa de adm incide sobre",
        "amount": "Valor (R$)",
        "measurement": "Metragem (m²)",
        "contributorCode": "Código do contribuinte",
        "propertyPurpose": "Destinação do imóvel",
        "historicalProperty": "Imóvel tombado",
        "registryOffice": "Cartório",
        "registrationNumber": "Matrícula",
        "realAgent": "Angariador",
        "commissionAmount": "Valor da comissão (%)",
        "addOwner": "Adicionar outro proprietário ou beneficiário",
        "addAgent": "Adicionar outro angariador",
        "addGarage": "Adicionar outra garagem",
        "addGarunter": "Adicionar outro fiador",
        "addHobby": "Adicionar outro hobby box",
        "others": "Outros",
        "observations": "Observações",
        "insertNewProperty": "Insira as informações solicitadas para incluir um novo imóvel.",
        "propertyAnotherSystem": "ID deste imóvel em outro sistema",
        "condoFee": "Adm do condomínio",
      },
      "newTransfer": {
        "indentifier": "Identificador",
        "transfers": "Repasses",
        "upcoming": "A realizar",
        "completed": "Realizados",
        "timePeriod": "Período",
        "guaranteed": "Todos os repasses",
        "charges": "Todas as cobranças",
        "chargeSettlement": "Liquidação da cobrança",
        "chargeDueDate": "Vencimento da cobrança",
        "PropertiesOwner": "Quantidade de imóveis por proprietário",
        "contract": "Contrato",
        "typepProperty": "Tipo de imóvel",
        "cutOffDate": "Data de corte",
        "charge": "Cobrança",
        "nonGuaranteed": "Não garantido",
        "openContract": "Abrir contrato",
        "verified": "Conferido",
        "notSettled": "Não liquidada",
        "settledIn": "Liquidada em",
        "selected": "selecionados",
        "sendByEmail": "Enviar por e-mail",
        "revoke": "Estornar",
        "issueInvoice": "Emitir nota fiscal",
        "viewStatement": "Ver extrato",
        "revokeTransfers": "Estornar repasses?",
        "revokeTransfersContent": "Ao confirmar estorno, os repasses selecionados mudarão o status de liquidado para pendente, e poderão ser acessados na listagem de repasses a realizar.",
        "transfersRegisteredRevoked": "Repasses registrados como estornados.",
        "openDetails": "Abrir detalhes",
        "standardRent": "Taxa de administração",
        "refFrom": "Ref. a",
        "chargeExample": "Exemplo de cobrança",
        "deleteItem": "Excluir item",
        "deleteItemContent": "Para excluir este item, é preciso alterar quem será creditado.",
        "validityTransferEdits": "Validade das edições do repasse",
        "validityTransferEditsContent": "As edições deve ser consideradas apenas para o repasse atual ou para todos os repasses seguintes até o fim do contrato?",
        "allSubsequentTransfers": "Para todos os repasses",
        "onlyCurrentTransfer": "Apenas para o repasse atual",
        "saveChanges": "Salvar edição antes de seguir",
        "editOnlyCurrentTransfer": "Editar apenas repasse atual",
        "editSubsequentTransfers": "Editar este e todos os repasses seguintes",
        "settleTransfers": "Liquidar repasses",
        "settleTransfersContent": "Ao confirmar esta ação, os repasses simulados serão registrados como liquidados e poderão ser acessados na área de repasses realizados ou na área de despesas pagas.",
        "supportedFormats": "Formatos suportados: PDF e PNG.",
        "transfersRegisteredSettled": "Repasses registrados como liquidados.",
        "dateOfSettlement": "Data da liquidação",
        "sendStatementBeneficiariesEmail": "Enviar extrato para beneficiários por e-mail",
        "postInFinancial": "Lançar no financeiro",
        "postInFinancialContent": "Ao confirmar esta ação, os repasses simulados entrarão como despesas no sistema e poderão ser acessados na área de repasses realizados ou na área de despesas a pagar.",
        "transfersPostedInFinancial": "Repasses lançados no financeiro.",
        "transferSimulation": "Simulação de repasse",
        "printSimulation": "Imprimir simulação",
        "viewCharge": "Ver cobrança",
        "forecast": "Previsão",
        "beneficiaries": "Beneficiários",
        "markVerified": "Marcar como conferido",
        "totalAmount": "Valor total",
        "beneficiary": "Beneficiário",
        "warningRelatedBeneficiary": "A soma dos valores dos beneficiários está ultrapassando o valor total do repasse.",
        "warningRelatedBeneficiary2": "Este beneficiário possui mais dois repasses  neste mês.",
        "includeInSimulation": "Incluir na simulação",
        "financialInstitution": "Instituição financeira",
        "totalStatementAmount": "Total do extrato",
        "propertyOwnersPayed": "proprietários para pagamento.",
        "settle": "Liquidar",
        "addBeneficiary": "Adicionar beneficiário",
        "Postedinfinancial": "Publicado em financeiro",
        "Settled": "Liquidado",
        "Pending": "Pendente",
        "alltransfers": "Todas as transferências",
        "Guaranteedtransfers": "Transferências garantidas",
        "nonguaranteed": "Transferências não garantidas",
        "Allcharges": "Todas as cobranças",
        "Settledcharges": "Cobranças liquidadas",
        "Settledchargesdelay": "Cobranças liquidadas com atraso",
        "Settledwithoutdelay": "Cobranças liquidadas sem atraso",
        "Notsettledcharges": "Cobranças não liquidadas",
        "Lastdayofthemonth": "Último dia do mês",
        "Selectdate": "Selecionar data"

      },
      "DocumentTemplate": {
        "identifier": "Identificador",
        "amendmantName": "Nome da Emenda",
        "amendmantTitle": "Título da Emenda",
        "amendmantSubTitle": "Subtítulo da alteração",
        "amendmantIdentificationSystem": "Identificação da alteração no sistema",
        "documentTemplates": "Modelos de documentos",
        "editWatermark": "Editar marca d’água",
        "newFolder": "Nova pasta",
        "templates": "modelos",
        "template": "modelo",
        "duplicate": "Duplicar",
        "rename": "Renomear",
        "continueWithoutTemplate": "Seguir sem modelo",
        "folderDuplicated": "Pasta duplicada.",
        "editWatermarkContent": "A marca d’água escolhida aparecerá em todas as páginas de documentos.",
        "supportedFormat": "Formato suportado: PNG.",
        "selectFile": "Escolher arquivo",
        "enterFolderName": "Insira o nome pasta",
        "folderName": "Nome da pasta",
        "whatDoYouWant": "O que quer fazer?",
        "whatDoYouWantContent": "Você pode criar um modelo de documento que  ficará salvo para ser utilizado outras vezes ou redigir um arquivo avulso.",
        "draftStandaloneDocument": "Redigir documento avulso",
        "createTemplate": "Criar um modelo",
        "deleteFolder": "Excluir pasta",
        "deleteFolderContent": "Ao excluir a pasta permanentemente, os modelos de documentos dentro da pasta não poderão ser recuperados.",
        "nameNewFolder": "Nome da nova pasta",
        "newTemplate": "Novo modelo",
        "newAmendmant": "Aditivo contratual",
        "folderEmpty": "Esta pasta está vazia",
        "folderEmptyContent": "Crie um novo modelo de documento que poderá ser aproveitado posteriormente.",
        "moveFolders": "Mover de pasta",
        "documentTemplateDuplicated": "Modelo de documento duplicado.",
        "internalTemplate": "Modelo interno",
        "kenloTemplate": "Modelo Kenlo",
        "enterTemplateName": "Insira o nome do modelo",
        "templateName": "Nome da modelo",
        "createCopyOrEdit": "Deseja criar uma cópia ou editar o modelo atual?",
        "createCopyOrEditContent": "Se escolher editar o modelo atual, as alterações serão permanentes e não será possível restaurar a versão original.",
        "editCurrentTemplate": "Editar modelo atual",
        "createCopy": "Criar uma cópia",
        "deleteDocumentTemplate": "Excluir modelo de documento?",
        "deleteDocumentTemplateContent": "Ao excluir o modelo permanentemente, ele não poderá ser recuperado.",
        "move": "Mover",
        "moveContent": "Escolha para qual pasta deseja mover o modelo.",
        "print": "Imprimir ",
        "printContent": "Deseja imprimir o documento já associado ao Contrato Residencial 109/1, ou apenas o modelo neutro com variáveis não preenchidas?",
        "neutralTemplate": "Modelo neutro",
        "contractDocument": "Documento do contrato",
        "share": "Compartilhar",
        "shareContent": "Deseja compartilhar o documento já associado ao Contrato Residencial 109/1, ou apenas o modelo neutro com variáveis não preenchidas?",
        "copy": "Copiar",
        "copyContent": "Copie o link para compartilhar o modelo do documento com alguém.",
        "changesSavedAutomatically": "As alterações serão salvas automaticamente.",
        "documentTitle": "Título do documento",
        "documentSubtitle": "Subtítulo do documento",
        "showLogoHeader": "Mostrar logotipo no cabeçalho",
        "templateIdentificationSystem": "Identificação do modelo de documento no sistema",
        "titleAappearHeader": "Este título aparecerá no cabeçalho do documento",
        "subtitleAppearHeader": "Este subtítulo aparecerá no cabeçalho do documento",
        "variablesContent": "Variáveis são atalhos neutros usados em modelos de documentos. Quando usar esse modelo ao gerar um termo ou contrato pelo sistema, as variáveis serão preenchidas automaticamente.",
        "searchVariable": "Busque pela variável que deseja usar",
        "propertyOwnerVariables": "Variáveis de proprietário",
        "propertyVariables": "Variáveis de imóvel",
        "tenantVariables": "Variáveis de inquilino",
        "contractVariables": "Variáveis de contrato",
        "dateVariables": "Variáveis de datas",
        "guaranteeVariables": "Variáveis de garantia",
        "guarantorVariables": "Variáveis de fiador",
        "transferVariables": "Variáveis de repasse",
        "realStateAgencyVariables": "Variáveis de imobiliária",
        "managerVariables": "Variáveis de gerente",
        "signaturesVariables": "Variáveis de assinaturas",
        "otherVariables": "Outras variáveis",
      },
      "reportSystem": {
        "Recipient": "Destinatário",
        "beneficiary": "Beneficiário",
        "EmailStatus": "Status do email",
        "Deleted": "Excluidos",
        "Topic": "Assunto",
        "NoRecords": "Não há registros",
        "Permission": "Permissão"
      },
      "reportBanking": {
        "Accomplished": "Realizado",
        "VariationRs": "Variação (R$)",
        "ReferenceMonth": "Mês de referência",
        "TotalForThePeriod": "Total do período",
        "ExpenseStatus": "Status da despesa",
        "Liquidated": "Liquidado",
        "CreditCard": "Cartão de crédito",
        "Debitcard": "Cartão de debito",
        "Deposit": "Depósito",
        "PostDatedCheck": "Verificar pré-datado",
        "AutomaticDebit": "Débito automático",
        "BankTransfer": "Transferência bancária",
        "DOC/TED": "DOC/TED",
        "TributeWithoutBarcode": "Tributo sem código de barras",
        "DebitedCredited": "Debitado/Creditado",
        "Debited": "Debitado",
        "Credited": "Creditado",
        "Client": "Cliente",
        "Suspended": "Suspensa",
        "OurNumber": "Nosso número",
        "Leased": "Alugada",
        "ClientType": "Tipo de cliente"
      },
      "reportTransferDetail": {
        "transferDate": "Data do repasse",
        "TransferStatus": "Status do repasse",
        "PaidOut": "Pago",
        "TransferConference": "Conferência do repasse ",
        "Fulfilled": "Realizada",
        "ToBeFulfilled": "A realizar",
        "NotGuaranteed": "Não garantidos",
        "FirstPayment": "Primeiro pagamento",
        "Renovation": "Renovação",
        "AdministrateTax": "Taxa administrativa",
        "TransferValue": "Valor do repasse",
        "ChargeAmount": "Valor da cobrança",
        "Deal": "Negócio",
        "NotIssued": "Não emitida",
        "IssuedAndNotConverted": "Emitida (E não convertida)",
        "Converted": "Convertida",
        "InvoiceStatus": "Status da nota fiscal",
        "Manager": "Gestor"
      },
      "reportSales": {
        "Seller": "Vendedor",
        "Buyer": "Comprador",
        "saleType": "Tipo de venda",
        "Commission": "Comissão",
        "commissionStatus": "Status da comissão",
        "Finalized": "Finalizados",
        "CollectorRaiser": "Captador/Angariador",
        "SalesBroker": "Corretor vendador",
        "AgencyBroker": "Corretor agenciador",
        "CoordinatingBroker": "Corretor coordenador",
        "Director": "Diretor",
        "RealEstateAgency": "Imobiliária",
        "Recommendation": "Indicação",
        "Supervisor": "Supervisora",
        "SaleStatus": "Status da venda",
        "Canceled": "Cancelada",
        "SalesType": "Tipo de venda ",
        "DateOfSale": "Data da venda",
        "BranchTeam": "Filial/Equipe",
        "TotalCommission": "Comissão total",
        "Commissioned": "Comissionados",
        "FromThirdParties": "De terceiros",
        "NewReleases": "Lançamentos",
        "Not specified": "Não especificada"
      },
      "Report-Property": {
        "CondominiumType": "Tipo de condomínio",
        "NotManagedByTheRealEstateAsgency": "Não administrados pela imobiliária",
        "ManagedByTheRealEstateAgency": "Administrados pela imobiliária",
        "ManagedAndPaidByTheRealEstateAgency": "Administrados e pagos pela imobiliária",
        "OwnerWillLiveInTheProperty": "Proprietário vai morar no imóvel",
        "OwnerSoldTheProperty": "Proprietário vendeu o imóvel",
        "PropertyWasAuctioned": "Imóvel foi leiloado",
        "StructuralProblemsInTheProperty": "Problemas estruturais no imóvel",
        "DelayInRentingTheProperty": "Demora na locação do imóvel",
        "DissatisfiedWithTheAdministration": "Insatisfeito com a administração",
        "OwnerWillManage": "Proprietário vai administrar",
        "Fallen": "Caída",
        "ThirdParty": "Terceiro Festa",
        "AtTheMoment": "No Momento",
        "Exempt": "Exempt",
        "IPTU": "IPTU",
        "GarbageFee": "Taxa de lixo",
        "Garage": "Lixo",
        "Main": "Principal",
        "Additional": "Adicional",
        "BurdenSharing": "Partilha do encargo",
        "Shared": "Compartilhada",
        "NotShared": "Não compartilhado",
        "AdministrationOfTheCharge": "Administração do encargo",
        "ManagedByRealEstateAgency": "Administrado por agência imobiliária",
        "ResponsibleForPayment": "Responsável pelo pagamento ",
        "PaymentStatus": "Status do pagamento",
        "PropertyStatus": "Status do imóvel ",
        "City/Estado": "Cidade/Estado",
        "TotalValueBooklet": "Valor total carnê",
        "IPTUCode": "Código de IPTU",
        "TokioMarine": "Tokio Marine",
        "Pretension": "Pretensão",
        "InvalidationDate": "Data da invalidação",
        "Perct": "Imóvel"
      },
      "Report-Contract": {
        "creationDate": "Data de criação",
        "GuaranteedThroughoutTheContract": "Garantidos por todo contrato",
        "GuaranteedForAFewMonths": "Garantidos por alguns meses",
        "WithInsurance": "Com seguro",
        "WithoutInsurance": "Sem seguro",
        "AdjustmentIndex": "Índice de ajuste",
        "Termination": "Término",
        "OccupancyOfTheProperty": "Ocupação do imóvel",
        "VacatingTheProperty": "Desocupação do imóvel",
        "OccupationInMonths": "Ocupação em meses",
        "TerminationDate": "Data da recisão",
        "GroupedByReasonForTermination": "Agrupados por motivo de rescisão",
        "Normal": "Normal",
        "InVacancy": "Em vaga",
        "StartOfContract": "Start of contract",
        "BailInsurance": "Seguro-fiança",
        "Pledger": "Caucionante",
        "ChecklistType": "Tipo de checklist",
        "Entry": "Entrada",
        "TaskStatus": "Status da tarefa",
        "Entregue": "Entregue",
        "WaitingForApproval": "Aguardando aprovação",
        "WithoutResponsible": "Sem Responsável",
        "ReasonForCancellation": "Motivo de cancelamento",
        "TerminationOfTheRentalContract": "Rescisão do contrato de aluguel",
        "TenantTookOutOtherInsurance": "Inquilino contratou outro seguro",
        "HiredByMistake": "Contratei por engano",
        "ThePriceOfInsurance": "O preço do seguro",
        "CoverageDoesNotMeetTheNeed": "As coberturas não atentem",
        "CancellationDate": "Data do cancelamento",
        "ReleaseStatus": "Status do lançamento",
        "Released": "Lançada",
      },
      "Report-Revenues": {
        "ExpiredValue": "Valor vencido",
        "NextExpirationDate": "Próximo vencimento",
        "LastSuccess": "Último sucesso",
        "Card": "Cartão",
        "Authorization": "Autorização",
        "Liquidation": "Liquidação",
        "BillingStatus": "Status da cobrança",
        "Invalidated": "Invalidadas",
        "Protested": "Protestado",
        "SentToTheNotary": "Remetidas ao cartório",
        "Automatic": "Automáticas",
        "PersonType": "Tipo de pessoa",
        "PFAndPJ": "PF e PJ",
        "PFOnly": "Somente PF",
        "PJOnly": "Somente PJ",
        "ExpirationStatus": "Status do vencimento",
        "TaxInvoiceStatus": "Status da nota fiscal",
        "AwaitingDeposit": "Aguardando depósito",
        "ShippingStatus": "Status do envio",
        "Converteds": "Convertidas",
        "TransactionType": "Tipo de transação",
        "TransactionStatus": "Status da transação",
        "Flag": "Bandeira",
        "QuantityOfCharges": "Quantidade de cobranças",
        "QuantityOfInvoices": "Quantidade de notas ficais",
        "TaxableAmountPaid": "Valor pago tributável",
        "InvoiceValue": "Valor notas fiscais",
        "Difference": "Diferença",
        "NumberOfCharges": "Número de cobranças"
      },
      "Report-Expenses": {
        "Favored": "Favorecido",
        "DocumentNumber": "Número do documento",
        "CheckStatus": "Status do verificar",
        "PrintStatus": "Status da Impressão",
        "PastHistory": "Histórico",
        "Emission": "Emissão",
        "ParticipationR$": "Participação (R$)",
        "Nominal": "Nominal",
        "LiquidationDate": "Data de Liquidação",
        "ExpirationDate": "Data de Vencimento"
      },
      "Report-NonPayment": {
        "OldestOutstandingChargeEachCustomer": "Este relatório traz apenas a mais antiga cobrança em atraso de cada cliente.",
        "SurveysNetwork": "Rede Vistorias",
        "DaysCredited": "Dias / Creditados",
        "Generation": "Geração",
        "Honorarium": "Honorários",
        "DelayedDays": "Dias em atraso",
        "MainValue": "Valor principal",
        "UpdatedValue": "Valor atualizado",
        "ResultInThePeriod": "Resultado no período",
        "FinalBalance": "Saldo final",
        "Credits": "Créditos",

      },

      "Reports": {
        "ExportPDF": "Exportar PDF",
        "LastUpdate": "Última atualização",
        "categories": "Categorias",
        "showReportsWith": "Mostrar relatórios com:",
        "statusFilter": "Filtro de status",
        "typeFilter": "Filtro de tipo",
        "searchForReports": "Busque por relatórios",
        "showing": "Mostrando",
        "of": "de",
        "banking": "Bancário",
        "cashier": "Caixinha",
        "comparisonOfIncomeExpenses": "Comparativo de Receitas e Despesas",
        "statementOfPlannedAccomplished": "Demonstrativo de previsto e realizado",
        "cashFlow": "Fluxo de caixa",
        "electronicFrenchie": "Francesinha eletrônica",
        "bankTransactions": "Movimentações bancárias",
        "annualBudgetForecast": "Previsão orçamentária anual ",
        "monthlyBudgetForecast": "Previsão orçamentária  mensal",
        "propertyServiceCodes": "Códigos de serviços dos imóveis",
        "condominiums": "Condomínios",
        "propertyCharges": "Encargos dos imóveis",
        "invalidatedProperties": "Imóveis invalidados",
        "summaryOfProperties": "Resumo dos imóveis",
        "statementOfTransfers": "Demonstrativo dos repasses",
        "transferDetails": "Detalhamento de Repasses",
        "releaseDiary": "Diário de lançamentos",
        "statementOfTransfersByOwner": "Extrato de repasses por proprietário ",
        "invoicesForTransfers": "Notas fiscais dos repasses",
        "fireInsuranceCancellation": "Cancelamento de seguro incêndio",
        "taskChecklistsForContracts": "Checklists de tarefas para contratos",
        "contractCharges": "Cobranças dos contratos",
        "contractsToReadjusted": "Contratos a reajustar",
        "adjustedContracts": "Contratos reajustados",
        "terminatedContracts": "Contratos rescindidos",
        "declarationOfRealEstateActivities": "DAI - Declaração de atividades imobiliárias",
        "guarantorDetails": "Detalhamento de fiadores",
        "fireInsuranceDetails": "Detalhamento de seguro incêndio",
        "mirrorOfContracts": "Espelho dos contratos",
        "contractGuarantees": "Garantias dos contratos",
        "iptuLaunch": "Lançamento de IPTU",
        "Nonpayment": "Inadimplência",
        "detailedAgreements": "Acordos detalhados",
        "detailedNonpayment": "Inadimplência detalhada",
        "oldestOutstandingCharge": "Mais antiga cobrança pendente",
        "nonpaymentSummary": "Resumo de inadimplência",
        "agreementAndDebtConfession": "Termo de acordo e confissão de dívida",
        "System": "Sistema",
        "sentEmails": "E-mails enviados",
        "smsSent": "SMS enviados",
        "systemUsers": "Usuários do sistema",
        "checksIssued": "Cheques emitidos",
        "expensesForVacantProperties": "Despesas dos imóveis vagos",
        "expensesPaidAndNotReceived": "Despesas pagas e não recebidas",
        "expensesByCostCenter": "Despesas por centro de custo",
        "expensesReceivedAndNotPaid": "Despesas recebidas e não pagas",
        "documentsForAnExpense": "Documentos de uma despesa",
        "Revenues": "Receitas",
        "creditCardsWithIssues": "Cartões de crédito com problemas",
        "compliantCustomers": "Clientes adimplentes",
        "compositionOfCharges": "Composição das cobranças",
        "reconciliationOfPaymentsAndInvoices": "Conciliação de pagamentos e notas fiscais",
        "summaryOfChargesReceived": "Resumo das cobranças recebidas",
        "summaryOfoutstandingRevenues": "Resumo das receitas pendentes",
        "creditAndDebitCardSummary": "Resumo de cartão de crédito e débito",
        "summaryOfPostDatedChecks": "Resumo de cheques pré datados",
        "summaryOfInvoices": "Resumo de notas fiscais",
        "occasionalIncome": "Receitas eventuais",
        "sales": "Vendas",
        "salesContracts": "Contratos de vendas",
        "commissionDetails": "Detalhamento de comissões",
        "salesDetails": "Detalhamento de vendas",
        "salesMirror": "Espelho de vendas",
        "noReportsFound": "Nenhum relatório encontrado.",
        "categoryNotFiltered": "Categoria não filtrada.",
      },
      "NonPayment": {
        "DelayedChargeList": "Lista de cobranças atrasadas",
        "contact": "Contato",
        "defaulters": "Inadimplência",
        "scheduledContact": "Contato agendado",
        "noActionTake": "Nenhuma ação tomada",
        "contactnotMade": "Contato não realizado",
        "inconclusiveContact": "Contato não conclusivo",
        "effectiveContact": "Contato efetivo",
        "manualNotification": "Notificação manual",
        "automaticNotification": "Notificação automática",
        "individualLegalEntity": "Pessoa física e jurídica",
        "delayTime": "Tempo de atraso",
        "communicationChannel": "Canal de comunicação",
        "manualOrAutomaticAction": "Ação manual ou automática",
        "manualActionOnly": "Apenas ação manual",
        "automaticActionOnly": "Apenas ação automática",
        "didNotReceivInvoice": "Não recebeu cobrança",
        "disagrees": "Não concorda",
        "reasonForDelinquency": "Motivo da inadimplência",
        "totalOverdue": "Total atrasado",
        "correlationBetweenDelayTimeAmounts": "Relação entre tempo de atraso e valores",
        "days": "dias",
        "or": "ou",
        "cash": "Dinheiro",
        "individualsOnly": "Apenas pessoa física",
        "legalEntitiesOnly": "Apenas pessoa jurídica",
        "bankSlip": "boleto bancário",
        "messagingApp": "Aplicativo de mensagem",
        "letter": "Carta",
        "phone": "Telefone",
        "other": "Outro",
        "delayedContractsList": "Lista de contratos com atraso",
        "sorting": "Ordenação",
        "alphabeticalByClient": "Alfabética do cliente",
        "longestDelayTime": "Maior tempo de atraso",
        "shortestDelayTime": "Menor tempo de atraso",
        "highestValue": "Maior valor",
        "LowestValue": "Menor valor",
        "statusUrgency": "Urgência do status",
        "delay": "Atraso",
        "recordAgreement": "Registrar acordo",
        "sendDuplicateCharge": "Enviar 2ª via do boleto",
        "viewInterestDetails": "Ver detalhes de juros ",
        "viewContactHistory": "ver historico de contato",
        "flagProtest": "Sinalizar protesto",
        "removeflagProtest": "Protestado",
        "openOriginalCharge": "Abrir cobranças originais",
        "Closeoriginalcharges": "Fechar cobranças originais",
        "openContractDetails": "Abrir detalhes do contrato",
        "openCustomerRecord": "Abrir ficha do cliente",
        "openGuarantorRecord": "Abrir ficha do fiador",
        "duplicateChargeSent": "Cobrança duplicada enviada.",
        "monetaryUpdate": "Atualização monetária",
        "processMonetaryUpdate": "Processar atualização monetária",
        "daysUpdateValuesAfterDueDate": "Dias para atualizar valores após o vencimento",
        "monetaryUpdateTable": "Tabela de atualização monetária",
        "doNotConsiderFirstPaymentNonpayment": "Não considerar cobranças de primeiro pagamento como inadimplência",
        "emailResponsibleForRespondingNonpaymentCharges": "E-mail responsável por responder cobranças de inadimplência",
        "separateEmailAddressesSemicolon": "Separe os endereços de e-mail por ponto e vírgula",
        "interestRateInstallment": "Taxa de juros para parcelamento (%)",
        "allowChangingInstallmentInterestRate": "Permitir alterar taxa de juros para parcelamento",
        "blockCollectionAgreementsLinkedToDispatch": "Bloquear acordos de cobranças vinculadas a remessa",
        "enableThirdPartyExpenseDeletionAgreementSource": "Habilitar exclusão de despesas para terceiro na origem do acordo",
        "additionalInstructionsForAgreementCollections": "Instruções adicionais para cobranças de acordo",
        "newAgreement": "Novo acordo",

        "paidAgreementCharge": "Cobrança de acordo paga",
        "invoicePrinting": "Impressão de cobrança",
        "emailBillingDelivery": "Envio de cobrança por e-mail",
        "generationOfDelinquencyLetter": "Geração de carta de inadimplência",
        "editAutomaticChargeTemplate": "Editar modelo de cobrança automática",
        "selectVariableYouWantTemplate": "Selecione a variável que deseja utilizar no modelo. A cobrança enviada irá substituir automaticamente as variáveis por dados reais.",
        "agreementNumber": "Número do acordo",
        "agreementAmount": "Valor do acordo",
        "dueDateFirstInstallment": "Vencimento da primeira parcela",
        "templateContent": "Conteúdo do modelo",
        "onTime": "Dentro do prazo",
        "overdueInstallment": "Parcela vencida",
        "listOfAgreedContracts": "Lista de contratos acordados",
        "generated": "Geração",
        "paidInstallments": "Parcelas pagas",
        "undoAgreement": "Desfazer acordo",
        "WhenUndoingAgreementDefaultersList": "Ao desfazer um acordo, a cobrança voltará para a lista de inadimplentes.",
        "agreementDetails": "Detalhes do acordo",
        "originalCharges": "Cobranças originais",
        "createElectronicEnvelope": "Criar envelope eletrônico",
        "sendCharge": "Enviar cobrança",
        "clientInformation": "Dados do cliente",
        "openCompleteRecord": "Abrir ficha completa",
        "fixedLinePhone": "Telefone fixo",
        "notifyEmail": "Notificar via e-mail",
        "scheduleContact": "Agendar contato",
        "time": "Hora",
        "schedule": "Agendar",
        "emailSubject": "Título do e-mail",
        "tenantNotifiedEmail": "Inquilino notificado via e-mail.",
        "agreementSuccessfullyRegistered": "Acordo registrado com sucesso",
        "youCanSendInvoiceCustomer": "Você já pode enviar a cobrança para o cliente, criar envelope para assinatura eletrônica ou imprimir termos de acordo.",
        "printCharge": "Imprimir cobrança",
        "printAgreementTerms": "Imprimir termo de acordo",
        "printDetailedAgreement": "Imprimir termo detalhado",
        "chargeSentCustomerEmail": "Cobrança enviada para o cliente via e-mail.",
        "howWillPaymentMade": "Como o pagamento será realizado?",
        "inOnePayment": "À vista",
        "inInstallments": "Parcelado",
        "continue": "Continuar",
        "productOrService": "Produto ou serviço",
        "addition": "Complemento",
        "newPaymentMethod": "Nova forma de pagamento",
        "addInstallment": "Adicionar parcela",
        "amortization": "Amortização",
        "interestDetails": "Detalhes de juros",
        "penalty": "Multa",
        "accumulated": "Acumulado",
        "deleteProductOrService": "Deletar produto ou serviço?",
        "whenDeletingActionCannotUndone": "Ao deletar, a ação não poderá ser desfeita.",
        "contactHistory": "Histórico de contato",
        "registerNewContact": "Registrar novo contato",
        "resultTheContact": "Resultado do contato",
        "register": "Registrar",
        "contactRegistered": "Contato registrado.",
        "Effective": "Efetivo",
        "Paid": "Pago",
        "Promise": "Promessa",
        "Inconclusive": "Inconclusivo",
        "Disagrees": "Discorda",
        "Call back": "Retornar a ligação",
        "Online negotiation": "Negociação online",
        "Not made": "Não realizado",
        "Wrong number": "Número errado",
        "Doesn't answer": "Não atende",
        "Busy": "Ocupado",
        "Outros": "Outros",
        "slug": "Lesma",
        "monetary": "Monetário"

      },

      "financialStatuses": {
        "normal": "Noraml",
        "legal": "Jurídico",
        "invacancy": "Em desocupação",
        "sinister": "Sinistro",
        "pendingFeatures": "Recursos pendentes",
        "labelsuspendCharges": "Suspender encargos contratuais",
        "labelFinanceForm": "Alterar situação financeira"
      },
      "Invoices": {
        "invoices": "Notas Fiscais",
        "newServiceNote": "Nova nota de serviço",
        "batches": "Lotes",
        "series": "Séries",
        "Taxation": "Tributação",
        "printThisScreen": "Imprimir esta tela",
        "issued": "Emitido",
        "canceled": "Cancelado",
        "customers": "Clientes",
        "notesFrom": "Notas de",
        "withtagged": "Com etiqueta",
        "NFEDialtoTransmit": "NFE: Discar para transmitir",
        "unmarkfromtransmission": "NFE: Desmarcar da transmissão",
        "sendbyemail": "Enviar por e-mail",
        "printinvoicemirror": "Imprimir espelho da fatura",
        "changeissuedate": "Alterar data de emissão",
        "changeresponsiblecompany": "Alterar empresa responsável",
        "changethecompanyresponsible": "Alterar a empresa responsável pela fatura",
        "headquarters": "Sede",
        "laercioJoseIlkiu": "Laercio Jose Ilkiu",
        "inactive": "Inativo",
        "shipping": "Envio",
        "Unmarked": "Desmarcado",
        "issuance": "Emissão",
        "Additionaldata": "Dados adicionais",
        "client": "Cliente",
        "value": "Valor",
        "Pdf": "PDF",
        "exclamation": "Exclamação",
        "summary": "Resumo",
        "tobemarked": "Para ser marcado",
        "tagandtransmit": "Etiquetar e transmitir",
        "shippingpending": "Envio pendente",
        "none": "Nenhuma",
        "transmitnotes": "Transmitir notas",
        "expiredcertificate": "Certificado vencido",
        "Tocontinueissuinginvoices": "Para continuar emitindo notas fiscais envie um novo certificado.",
        "Addthecertificate": "Adicionar o certificado",
        "Transmitnotes": "Transmitir notas",
        "fromotherperiods": "de outros períodos.",
        "Youmayprefertopost": "Talvez você prefira lançar NFs de forma mais eficiente usando o processo de geração de cobranças.",
        "Todothisdd": "Para isto, adicione receitas recorrentes e/ou avulsas e fature. Assim, cobranças e NFs serão emitidas automaticamente conforme as regras definidas para cada cliente.",
        "gradetotal": "Total da Nota",
        "Totalafterretention": "Total após retenção",
        "Delivery": "ENTREGA",
        "Mainaddress": "Main address",
        "Secondaryaddress": "Secondary address",
        "Withholdings": "Withholdings",
        "deductions": "Deductions",
        "calculationbasis": "Calculation basis",
        "ISSQNvalue": "ISSQN value",
        "Toretain": "To retain",
        "IRRF": "IRRF",
        "PIS": "PIS",
        "CSocial": "C. Social",
        "INSS": "INSS",
        "COFINS": "COFINS",
        "Unitaryvalue": "Unitary value",
        "Itemtotal": "Item total",
        "Newdeduction": "New deduction",
        "DeductISSQN": "Deduct ISSQN",
        "DeductINSS": "Deduct INSS"
      },
      "billingrule": {
        "ticket": "Cobrança",
        "notificationbyemail": "Notificação por e-mail",
        "altermessage": "Os e-mails serão enviados ao amanhecer e somente após 3 dias de sua criação para evitar mensagens incorretas.",
        "sendfirstnotification": "Enviar primeira notificação",
        "days": "Dias",
        "sendsecondnotification": "Enviar segunda notificação",
        "sendthirdnotification": "Enviar terceira notificação",
        "sendfourthnotification": "Enviar quarta notificação",
        "daysafterexpiration": "Dias após o vencimento",
        "extrajudicialnotice": "Aviso extrajudicial por carta",
        "systemwillautomatically": 'O sistema gerará automaticamente cartas de notificação de acordo com essas configurações. Usuários com 1000 "Administrativo" ou 1073 "Clientes-Notificar"',
        "accesswillreceive": "O acesso receberá instruções via e-mail para imprimir as cartas.",
        "period": "Período",
        "messages": "Mensagens",
        "notificationexpiration": "Texto de notificação para boletos antes do vencimento",
        "notificationboletosexpiration": "Texto de notificação para boletos enviados no vencimento",
        "notificationtextexpiration": "Texto de notificação para boletos após o vencimento",
        "lastnotificationtext": "Último texto de notificação",
        "SMSNotification": "Notificação por SMS",
        "notification": "Notificação",
        "tosendyourcustomer": "Para enviar ao seu cliente o link de acesso ao boleto, use a variável: %link_boleto% (30 caracteres)",
        "tosendyourvariable": "Para enviar ao seu cliente a linha digitável do boleto, use a variável: %linhadigitavel% (40 caracteres)",
        "notificationforunpaidtickets": "Notificação para boletos não pagos",
        "charactersleft": "caracteres restantes."
      }

    }


  },
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  interpolation: {
    escapeValue: false
  },
  returnNull: false,
});
export default i18n;
