import axios from 'axios';
import { gettoken, config } from './apiConfig';
const API_BASE_URL = config.companyV2_URL;
const CustomerApiBaseUrl = config.CUSTOMER_API;
const TRANSFER_API = config.TRANSFER_API;

axios.defaults.headers.common['Content-Type'] = "application/json";
const Token = gettoken.Token;
axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");//Token; 
axios.defaults.headers.common['connection-name'] = localStorage.getItem("userData");

const customerV2 = config.customerV2;
export const incomeService = {
    // Working
    getChargesSummery: async function (data) {
        data.userId = 1;
        const response = await axios.request({
            url: TRANSFER_API + '/M1/income/charges/getToReceived',
            method: "POST",
            data: data
        })
        return response.data
    },

    getpendingChargesSummery: async function (data) {
        data.userId = 1;
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/getToReceivedPending',
            method: "POST",
            data: data
        })
        return response.data
    },

    buttomFilter_charge: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/buttom_filter_update',
            method: "POST",
            data: data
        })
        return response.data
    },

    GeneratePJBANKtoBoleto: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/boleto/charges/generatePJBanktoBoleto',
            method: "POST",
            data: data
        })
        return response.data
    },

    getEditCharge: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/getEditCharge',
            data: {
                id: data.id ?? data
            },
            method: "POST"
        })
        return response.data
    },

    getDiscountData: async (user_id) => {
        let response = await axios.request({
            url: API_BASE_URL + '/company/forms_of_receipts/practiced_rates',
            method: "POST",
            data: {
                user_id: user_id
            }
        })
        return response.data;
    },

    addCharge: async function (data) {
        data['ID_EMPRESA_EMP'] = 1;
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/addCharge',
            // url: customerV2 + '/v2/income/charges/addCharge',
            data: data,
            method: "POST"
        })
        return response.data
    },

    getSingleChargesSummery: async function (param) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/getEditCharge',
            data: param,
            method: "POST"
        })
        return response.data
    },

    updateSingleCharge: async function (data) {
        data['ID_EMPRESA_EMP'] = gettoken.userId;
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/updateCharge',
            data: data,
            method: "POST"
        })
        return response.data
    },

    getClients: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/getClients',
            method: "GET"
        })
        return await response.data
    },
    postLiquidity: async function (data) {
        data.userId = 1;
        const response = await axios.request({
            // url: API_BASE_URL + '/income/charges/addUpdateLiquidate',
            url: TRANSFER_API +'/M1/income/charges/addUpdateLiquidate',
            method: "POST",
            data: data
        })
        return await response.data
    }, 
    getChargeInvoice: async function (data) {
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            url: CustomerApiBaseUrl + '/aarin/getChargeInvoice',
            method: "POST",
            data: data
        })
        return response.data
    },
    cancelPaymentStatus: async function (data) {
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            url: CustomerApiBaseUrl + '/aarin/cancelPaymentStatus',
            method: "POST",
            data: data
        })
        return response.data
    },

    updatePaymentStatus: async function (data) {
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            url: CustomerApiBaseUrl+'/aarin/updatePaymentStatus',
            method: "POST",
            data: data
        })
        return response.data
    },

    // mark Payment status to Paid
    markPaymentPaid: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/markPaymentPaid',
            method: "POST",
            data: data
        })
        return response.data
    },
    postUpdatePlugnota: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/updatePlugnota',
            method: "POST",
            data: data
        })
        return await response.data
    },
    getInvoce: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/getInvoce',
            method: "POST",
            data: data,
        })
        return response.data
    },
    addInvoice: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/addInvoice',
            data: data,
            method: "POST"
        })
        return response.data
    },
    EditInvoice: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/geteditInvoice',
            data: data,
            method: "POST"
        })
        return response.data
    },

    Invalidate_charge: async function (data) {
        const response = await axios.request({
            // url: API_BASE_URL + '/income/charges/invalidate_charge',
            url: CustomerApiBaseUrl + "/aarin/invalidateCharge", 
            method: "POST",
            data: data
        })
        return response.data
    },
    GeneratePlungnota: async function (data) {
        const response = await axios.request({
            url: API_BASE_URL + '/income/charges/plugnotasgenerate',
            method: "POST",
            data: data
        })
        return await response.data
    },
}

const fakeUrl = () => {
    return `&tron_id=${randomString()}&operation_id=${randomString()}`
}
const randomString = (length) => {
    if (!length) { length = 10; }
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

