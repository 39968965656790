import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, FloatingLabel, Card, Offcanvas } from 'react-bootstrap';
import CommonToast from './CommonToast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import customerService from '../../../../../../services/customerService';
import { LinkHttpChecklist, Text_Decoder, minDate } from '../../../../../constants/utils';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../redux/store';
import { contractDatails } from '../../../../../../redux/slice/contractDataSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { Termination } from '../../../../../Interface';
const CheckPropertyTax = (props) => {
    const [t] = useTranslation();
    const [loader_on, setloader_on] = useState(false);
    const [typeSubmit, settypeSubmit] = useState("draft");
    const validationSchema = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // observation_first: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
    });
    const validationSchema1 = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });
    var frm = {
        resolver: yupResolver(validationSchema1),
    };
    if (typeSubmit === "done") {
        frm = {
            resolver: yupResolver(validationSchema),
        };
    }

    const { id }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const typeValue = "check_properties_tax_XYZ";
    //Check property tax modal close open function
    const { showPropertyTaxModal, propertyTaxModalClose, ShowThisData, terminationResp, allresponsibleList } = props;
    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors } } = useForm<Termination>(frm);

    const [fileUploadName, setFileUploadName] = useState<any>({
        first_file: "",
        second_file: "",
        third_file: "",
        fourth_file: "",
    })

    const Close = () => {
        reset();
        propertyTaxModalClose();
        setrunOnce(true);
        setFileUploadName({
            first_file: "",
            second_file: "",
            third_file: "",
            fourth_file: "",
        })
        setOldData({
            old: "",
            new: "",
        })
    }

    const [oldData, setOldData] = useState<any>({
        old: "",
        new: "",
    });

    const [statusOfChecklist, setStatusOfChecklist] = useState(1);
    const [runOncee, setrunOnce] = useState(true);
    useEffect(() => {
        if (showPropertyTaxModal && runOncee) {
            setValue("responsible", "1");
            setShowResponsible(allresponsibleList[0]["agency"]);
            (async () => {
                let dataObj = {
                    type: typeValue,
                    contract_id: id
                }; 
                customerService.get_terminationDefaultTerminationList(dataObj).then(async (res) => {
                    if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                        let post = res["data"]["data"][0];
                        if (post !== undefined) {
                            let bolbdecode = await Text_Decoder(post["TA_FIRST_OBSERVATION_TERPOP"]["data"]);
                            setValue("id", post["ID_TERMINATIONPOPUP_TERPOP"]);
                            setValue("responsible", post["SL_RESPONSIBLE_TERPOP"]);
                            setValue("limit_date", moment(post["DT_LIMITDATE_TERPOP"].split("T")[0]).format("YYYY-MM-DD"));
                            setValue("first_file_or_report", post["FL_FIRST_FILE_OR_REPORT_TERPOP"] ?? "");
                            setValue("observation_first", bolbdecode);
                            setStatusOfChecklist(Number(post["ST_STATUS_TERPOP"]));

                            handleResponsible(post["SL_RESPONSIBLE_TERPOP"], post["SL_RESPONSIBLE_PARTY_NAME_TERPOP"] ?? "", "load")

                            setFileUploadName({
                                ...fileUploadName,
                                ["first_file"]: post["FL_FIRST_FILE_OR_REPORT_TERPOP"] ?? ""
                            })
                            setOldData({
                                old: post["FL_FIRST_FILE_OR_REPORT_TERPOP"] ?? "",
                                new: post["FL_FIRST_FILE_OR_REPORT_TERPOP"] ?? ""
                            });

                            // if (post["ST_STATUS_TERPOP"] !== 1) {
                            //     document.querySelectorAll('.DisableThis').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     });

                            //     document.querySelectorAll('.DisableThis input').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     })
                            // }

                            setrunOnce(false);
                        }
                    }
                })
            })()
        }
    }, [showPropertyTaxModal]);

    useEffect(() => {
        if (getValues("first_file_or_report") && showPropertyTaxModal) {
            setTimeout(() => {
                setOldData({
                    ...oldData,
                    ["new"]: getValues("first_file_or_report")?.[0]?.["name"] !== undefined ? "checklist/" + getValues("first_file_or_report")?.[0]?.["name"] : ""
                })
            }, 200)
        }
    }, [watch('first_file_or_report'), showPropertyTaxModal, oldData]);

    const handleSaveDraftClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('draft');
        // Submit the form
        handleSubmit((data, e) => onPropertyTaxFormSubmit(data, e, "draft"))();
    };

    let [showResponsible, setShowResponsible] = useState([]);
    const handleResponsible = (e, rep_id, type) => {
        setValue("responsible_party_name", "");
        let id: any = 0;
        let arrayChange: any = [];
        if (type == "form") {
            id = Number(e.target.value);
        } else {
            id = Number(e);
        }
        if (id !== "") {
            if (id == 1) {
                arrayChange = allresponsibleList[0]["agency"];
            }
            if (id == 2) {
                arrayChange = allresponsibleList[0]["owner"]
            }
            if (id == 3) {
                arrayChange = allresponsibleList[0]["tenent"]
            }
            if (id == 4) {
                arrayChange = allresponsibleList[0]["manager"]
            }
            if (id == 5) {
                arrayChange = allresponsibleList?.[0]?.["gurantor"] ?? allresponsibleList?.[0]?.["guarantor"]
            }
        }

        if (type == "load") {
            setTimeout(() => {
                setValue("responsible_party_name", rep_id);
            }, 100)
        }

        if (type == "form") {
            if (arrayChange.length > 0 && arrayChange.length < 2) {
                setTimeout(() => {
                    setValue("responsible_party_name", arrayChange[0].value);
                }, 100)
            } else {
                setTimeout(() => {
                    setValue("responsible_party_name", rep_id);
                }, 100)
            }
        }

        setShowResponsible(arrayChange);
    }

    const handleSaveCompleteClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('done');
        // Submit the form
        Swal.fire({
            // icon: 'success','warning',
            title: t("Sweetalert.WantToSave"),
            text: t("Sweetalert.Areyousuretosaveit"),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('button.confirm')
        }).then(res => {
            if (res.isConfirmed) {
                handleSubmit((data, e) => onPropertyTaxFormSubmit(data, e, "done"))();
            }
        })
    };

    //Form submit
    const onPropertyTaxFormSubmit = (data, e, type) => {
        setloader_on(true)

        data["type"] = typeValue;
        data["contract_id"] = id;

        if (type == "done") {
            data["status"] = 0;
        }

        if (type == "draft") {
            data["status"] = 1;
        }
        data["first_file_or_report"] = data["first_file_or_report"] ? data["first_file_or_report"] : "";
        const formData = new FormData();
        if (typeof data["first_file_or_report"] === "object") {
            formData.append('first_file_or_report', data["first_file_or_report"]?.[0]);
        } else {
            formData.append('first_file_or_report', data["first_file_or_report"]);
        }

        Object.keys(data) && Object.keys(data).length > 0 &&
            Object.keys(data).forEach((item, index) => {
                if (data !== "first_file_or_report") {
                    formData.append(`${item}`, data[`${item}`]);
                }
            })

        customerService.post_terminationDefaultTerminationList(formData).then(async res => {
            if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                settypeSubmit('draft')
                await dispatch(contractDatails(id));
                Swal.fire({
                    // icon:'success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setTimeout(() => {
                    settypeSubmit('draft');
                }, 1000)
            } else {
                Swal.fire({
                    // icon:'error',
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
            reset();
            propertyTaxModalClose();
            setloader_on(false);
        })
    };

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = () => {
        setToastShow(true);
        setTimeout(() => {
            setToastShow(false);
        }, 5000);
    }

    const handleDelete = (e) => {
        setValue("first_file_or_report", "");
        setOldData({
            ...oldData,
            ["new"]: ""
        });
        setFileUploadName({
            ...fileUploadName,
            ["first_file"]: ""
        })
    }
    return (
        <>
            <Offcanvas show={showPropertyTaxModal} placement="end" onHide={Close}>
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit((data, e) => onPropertyTaxFormSubmit(data, e, ""))}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className="h4">{t("terminatelist.Checkpropertytaxandcondos")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                            <Form.Control type="text"  {...register("id")} hidden readOnly />
                            <Col md={6}>
                                <FloatingLabel controlId="responsible" label={t('formLabel.responsible') + " *"}>
                                    <Form.Select aria-label="Responsible" {...register("responsible")} className={`DisableThis ${errors.responsible ? "is-invalid" : ""}`} onChange={(e) => handleResponsible(e, "", "form")}>
                                        <option value="">{t("formLabel.select")}</option>
                                        {terminationResp && terminationResp.length > 0 &&
                                            terminationResp.map((item, index) => {
                                                return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback className="invalid-feedback">{errors.responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="limit_date" label={t('terminatelist.limit') + " " + t("terminatelist.date") + " *"}>
                                    <Form.Control type="date" min={minDate()} placeholder={t('terminatelist.limit') + " " + t("terminatelist.date")} {...register("limit_date")} className={`DisableThis ${errors.limit_date ? "is-invalid" : ""}`} />
                                    <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel controlId="responsible_party_name" label={t("terminatelist.Responsibleparty'sname") + " *"} className="mb-lg-4 mb-3">
                            <Form.Select aria-label="Responsible party's name" {...register("responsible_party_name")} className={`DisableThis ${errors.responsible_party_name ? "is-invalid" : ""}`} >
                                <option value="">{t("formLabel.select")}</option>
                                {showResponsible && showResponsible.length > 0 &&
                                    showResponsible.map((item, index) => {
                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback className="invalid-feedback">{errors.responsible_party_name?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                        {
                            fileUploadName && fileUploadName["first_file"] !== "" && fileUploadName["first_file"] !== "undefined" ?
                                <Card className="border mblg-4 mb-3">
                                    <Card.Body className="d-sm-flex align-items-center">
                                        <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                            <h4 className="text-truncate">{fileUploadName["first_file"].replace("checklist/", "")}</h4>
                                            {/* <p className="mb-sm-0 fs-12 text-secondary">Sent in 03/05/2023</p> */}
                                        </div>
                                        <div className="flex-shrink-0 ms-sm-auto">
                                            <Link to={LinkHttpChecklist + oldData?.["old"]} className="btn text-link" download="file">
                                                <img src="../assets/images/icons/upload-primary.svg" alt="Edit" className="cursor-pe me-2 me-md-3 h-18" />
                                            </Link>
                                            {
                                                statusOfChecklist == 1 &&
                                                <img src="../assets/images/icons/delete-danger.svg" alt="Delete" className="cursor-pe h-18" onClick={(e) => handleDelete(e)} />
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                                :
                                oldData && oldData?.["new"] !== "" ?
                                    <Card className="border mblg-4 mb-3">
                                        <Card.Body className="d-sm-flex align-items-center">
                                            <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                                <h4 className="text-truncate">{getValues("first_file_or_report")?.[0]?.["name"]}</h4>
                                                {/* <p className="mb-sm-0 fs-12 text-secondary">Sent in 03/05/2023</p> */}
                                            </div>
                                            <div className="flex-shrink-0 ms-sm-auto">
                                                {
                                                    statusOfChecklist == 1 &&
                                                    <img src="../assets/images/icons/delete-danger.svg" alt="Delete" className="cursor-pe h-18" onClick={(e) => handleDelete(e)} />
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    :
                                    <>
                                        {
                                            statusOfChecklist === 0 ?
                                                <Card className="border mblg-4 mb-3">
                                                    <Card.Body className="d-sm-flex align-items-center">
                                                        <div className="me-lg-4 me-sm-3 w-100 overflow-hidden text-center">
                                                            <h4 className="text-truncate" style={{ "color": "red" }}>{t("SupportedFormats")}</h4>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                :
                                                <div className="border-2 border border-dashed py-4 px-2 position-relative text-center rounded-4 mb-lg-4 mb-3">
                                                    <Form.Control type="file" className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1 DisableThis" {...register("first_file_or_report")} />
                                                    <h4 className="text-primary fw-bold mb-2"><img src="../assets/images/icons/upload-file-primary.svg" alt="Upload file" className="cursor-pe me-2" />{t("attachFile")}</h4>
                                                    <label className="mb-0 form-label fw-normal text-dark"><img src="../assets/images/icons/info-dark.svg" alt="Info" className="h-16 cursor-pe me-1" />{t("SupportedFormats")}.</label>
                                                </div>
                                        }
                                    </>
                        }
                        <FloatingLabel controlId="observation" label={t("formLabel.observation")}>
                            <Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("observation_first")} className={`DisableThis ${errors.observation_first ? "is-invalid" : ""}`} />
                            <Form.Control.Feedback className="invalid-feedback">{errors.observation_first?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Offcanvas.Body>
                    {/* {
                        statusOfChecklist === 1 &&
                        <>
                            <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                <Button variant="outline-primary" onClick={handleSaveDraftClick}>
                                    {t('terminatelist.Savedraft')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('terminatelist.Saveandcomplete')}
                                </Button>
                            </div>
                        </>
                    } */}

                    {/* {
                        statusOfChecklist === 1 && */}
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {loader_on ?
                            <Button className="btn-width">
                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                            </Button>
                            :
                            <>
                                <Button variant="outline-primary" onClick={Close}>
                                    {t('button.close')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('button.save')}
                                </Button>
                            </>
                        }
                    </div>
                    {/* } */}
                </Form>
            </Offcanvas>
            {/* Toast start from here */}
            <CommonToast showCommonToast={showToast} commonToastClose={commonToastClose} />
            {/* Toast end here */}
        </>
    )
}

export default CheckPropertyTax
