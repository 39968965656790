import axios from "axios";

import { config } from "../../../../../../services/apiConfig";

export async function deleteHomologatedBankAccount(
  homologatedBankAccountId: number
) {
  try {
    const response = await axios.delete(
      `${config.TRANSFER_API}/homologate/bankslip/bank/${homologatedBankAccountId}`
    );

    if (response.status !== 200) {
      throw new Error();
    }

    return {
      type: "success",
    };
  } catch (err: any) {
    console.log("err", err);
    if (err.response && err.response.data) {
      return {
        type: "error",
        message: err.response.data.data[0] as string,
      };
    }

    return {
      type: "error",
      message:
        "Falha ao deletar conta homologada. Verifique suas informações e tente novamente.",
    };
  }
}
