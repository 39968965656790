import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SaveChanges = (props) => {
  //Save changes modal close open function
  const { showSaveChanges, saveChangesClose, saveChangesShow } = props;
  const [t] = useTranslation();
  return (
    <>
      <Modal show={showSaveChanges} onHide={saveChangesClose}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>{t("newTransfer.saveChanges")}?</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-2 pt-0">
            <Form.Group className="bg-light rounded-4 p-2 mb-lg-4 mb-3 min-h-56 d-flex flex-wrap align-items-center">
              <Form.Check
                type="radio"
                name="transfers"
                id="current_transfer"
                label={t("newTransfer.editOnlyCurrentTransfer")}
              />
            </Form.Group>
            <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
              <Form.Check
                type="radio"
                name="transfers"
                id="subsequent_transfers"
                label={t("newTransfer.editSubsequentTransfers")}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={saveChangesClose}>
              {t("button.cancel")}
            </Button>
            <Button variant="primary" onClick={saveChangesClose}>
              {t("saveChanges")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default SaveChanges;
