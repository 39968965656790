import React, { useEffect, useState, useRef } from 'react';
import { footer_data, getComapnyLogo, NewLogoInsert, updateComapnyLogo, deleteLogo } from "../../../../redux/slice/companyDataSlice";
import { ComProps, company, Branch } from '../../../Interface';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { config, gettoken } from '../../../../Api/Contact';
import { Card, Button, Form, Modal, Row, Col, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from './ImageCropper/useDebounceEffect';
import { canvasPreview } from './ImageCropper/canvasPreview';
import { handleAction } from '../actions';
import { IMAGEURLLINK } from '../../../constants/utils';
// This function performs a center aspect crop on a media element
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number,) {
  // Call the centerCrop function with a makeAspectCrop function
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
const Updatelogo = ({ submenu }: ComProps) => {
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>()
  // Initialize the translation function from the translation library
  const [t] = useTranslation()
  // Initialize form-related functions and values using React Hook Form
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({});
  const [src, setSrc] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [logofile, setLogo] = useState('');
  const [afterupload, setAfterupload] = useState(false);
  const [logofiles, setLogoFiles] = useState({})
  const [logotype, setLogoType] = useState<String>("company")

  // Get the 'getlogo' and 'status' variables from the 'companyData' state using the 'useSelector' hook
  const { getlogo, status } = useSelector((state: RootState) => state.companyData);
  // Assign the 'userId' property from the 'gettoken' object to the 'user_id' variable
  let user_id = gettoken.userId
  let imgtype = ''

  // Reference to the canvas element used for preview
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)
  // State for toggling the visibility of a component
  const [show, setShow] = useState(false);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Set 'afterupload' state to true
    setAfterupload(true);
    setShow(true);
    // Get the selected file
    let file: any = e.target.files;
    // Check if a file is selected
    if (file.length > 0) {
      setCrop(undefined)
      // Set 'logoFiles' state with the selected file
      setLogoFiles(file[0])
      // Set 'src' state with the object URL of the selected file
      setSrc(URL.createObjectURL(file[0]))
      let reader = new FileReader();
      // Define the onload event handler for the FileReader
      reader.onload = () => {
        if (reader.readyState === 2) {
          let newImg: any = reader.result;
          setLogo(newImg)
        }
      }
      reader.readAsDataURL(file[0]);
    } else {
      // No file is selected, log a message
      console.log("update file")
    }
  }

  useEffect(() => {
    // Trigger the 'getComapnyLogo' action when the component mounts or when 'imgtype' changes
    dispatch(getComapnyLogo())
  }, [dispatch, imgtype,])
  // Extract the logo type from 'getlogo.logo_name'
  let type = getlogo?.logo_name
  let gettype = type?.split(/[#?]/)[0].split('.').pop().trim()
  // Split the first part by '.' and retrieve the last part (logo file extension)
  imgtype = gettype

  const onSubmit = async () => {
    // Check if there is an element with the ID "insertImg"
    if (document.querySelector('#insertImg')) {
      let obj: any = {
        user_id: user_id,
        logo_type: logotype,
        logo_name: logofiles,
        width: "200",
        length: "100",
      }

      let formdata = new FormData();
      formdata.append("logo_name", obj?.logo_name);
      formdata.append("user_id", obj.user_id);
      formdata.append("id", obj.user_id);
      formdata.append("logo_type", obj.logo_type);
      formdata.append("width", obj.width);
      formdata.append("length", obj.width);
      // Dispatch action for new logo insertion
      let res = await dispatch(NewLogoInsert(formdata))
      if (res.payload.type == "Success") {
        Swal.fire({
          position: 'center',
          // icon: 'success','success',
          title: t("Sweetalert.Successfullyupdate"),
          text: t("Sweetalert.Yournewlogoupdatedsavedsuccessfully"),
          showConfirmButton: false,
          timer: 2000
        })
        dispatch(getComapnyLogo())
        setAfterupload(false);
      } else {
        Swal.fire({
          position: 'center',
          // icon: 'success','error',
          title: t("Sweetalert.Error"),
          text: t("Sweetalert.somethingWent"),
          showConfirmButton: true,
        })
      }
    }
    // Check if there is an element with the ID "updateImg"
    if (document.querySelector('#updateImg')) {
      let obj: any = {
        user_id: user_id,
        logo_type: logotype,
        logo_name: logofiles,
        width: "200",
        length: "100",
      }
      let formdata = new FormData();
      formdata.append("logo_name", obj?.logo_name);
      formdata.append("user_id", obj?.user_id);
      formdata.append("logo_type", obj?.logo_type);
      formdata.append("width", obj?.width);
      formdata.append("length", obj?.length);
      // formdata.append("photo", obj.photo);

      // Dispatch action for updating the new logo
      let res = await dispatch(NewLogoInsert(formdata));
      setAfterupload(false);
      if (res.payload.type == "Success") {
        // Show a success message using Swal
        Swal.fire({
          position: 'center',
          // icon: 'success','success',
          title: t("Sweetalert.Successfullyupdate"),
          text: t("Sweetalert.Yournewlogoupdatedsavedsuccessfully"),
          showConfirmButton: false,
          timer: 2000
        })
        setLogo('');
        dispatch(getComapnyLogo())
        setAfterupload(false);
      } else {
        // Show an error message
        Swal.fire({
          position: 'center',
          // icon: 'success','error',
          title: t("Sweetalert.Error"),
          text: t("Sweetalert.somethingWent"),
          showConfirmButton: true,
        })
      }
    }
  }

  const handleClose = () => {
    // Clear the logo state
    setLogo('')
    // Hide the modal
    setShow(false);
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    // Check if aspect ratio is provided
    if (aspect) {
      const { width, height } = e.currentTarget
      // Calculate the crop coordinates using the centerAspectCrop utility function
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onDownloadCropClick() {
    // Check if the crop canvas exists
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }
    // Convert the canvas content to a data URL
    let dataURL: any = (previewCanvasRef.current).toDataURL("image/png")
    // Remove the data URL prefix
    dataURL.replace(/^data:image\/?[A-z]*;base64,/);
    // Set the logo state with the data URL
    setLogo(dataURL);
    setShow(false)
    // Fetch the image file from the data URL
    const url = dataURL
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "newCropImg_ready", { type: "image/png" })
        // Set the logoFiles state with the new file
        setLogoFiles(file)
      })
  }
  // useDebounceEffect function
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )
  // handleCropInsert function
  const handleCropInsert = () => {
    onDownloadCropClick();
  }
  // handleZoomChange function
  const handleZoomChange = (e) => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);
  };

  const handleCancel = (e) => {
    dispatch(getComapnyLogo())
    setLogo("");
    setLogoFiles("");
    handleAction(e, "cancelSubmit", "footerData")
  }

  const deletenewLogo = async () => {
    Swal.fire({
      title: `${t('Sweetalert.Warning')}`,
      text: `${t('Sweetalert.deletelogo')}`,
      // icon: 'success',"question",
      showCancelButton: true,
      confirmButtonText: `${t('Sweetalert.yesDeleteIt')}`,
      cancelButtonText: `${t("button.cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await dispatch(deleteLogo())
        if (res.payload.status === "success" || res.payload.type === "Success" || res.payload.status === "Success") {
          Swal.fire({
            // icon: 'success','success',
            title: `${t('Sweetalert.Success')}`,
            text: `${t('Sweetalert.deleteRecord')}`,
            confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
            timer: 2000
          })
          dispatch(getComapnyLogo())
          setAfterupload(false);
          setLogo("");
          setLogoFiles("");
        } else {
          Swal.fire({
            title: `${t('Sweetalert.Error')}`,
            text: `${t('Sweetalert.somethingWent')}`,
            // icon: 'success',"error",
            confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
            timer: 2000
          })
        }
      }
    })
    // dispatch(deleteLogo())
    // 
  }

  const triggerNewLogo = () => {
    let clickData: any = document.querySelector(".updateImagewithsave");
    clickData.click();
  }

  return (
    <>
      {
        status == "loading" ? "" :
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <div>{t('company.soon')}</div>
              <span className={`${afterupload ? 'd-none' : 'd-block'} TOEDIT`}>
                {
                  (getlogo?.logo_name == "" || getlogo?.logo_name == undefined) ? '' : <span><img src="assets/images/icons/edit-primary.svg" alt="Edit" className="h-16 me-1 cursor-pe" onClick={(e) => handleAction(e, "toedit", "footerData")} /></span>
                }
              </span>
              <div className={`${afterupload ? 'd-block' : 'd-none'} ACTION`}>
                <Row sm={12}>
                  <Col xl={afterupload ? 6 : 5} sm={6}>
                    <Button
                      variant="link"
                      className="p-0 fw-bold  text-nowrap"
                      onClick={(e) =>
                        handleCancel(e)
                      }
                      type="submit"
                    >
                      {t("button.cancel")}
                    </Button>
                  </Col>
                  {
                    getlogo &&
                    <Col xl={afterupload ? 4 : 5} sm={6}>
                      <Button
                        variant="link"
                        className="p-0 fw-bold text-nowrap"
                        onClick={(e) =>
                          deletenewLogo()
                        }
                      >
                        {t("button.delete")}
                      </Button>
                    </Col>
                  }

                  {
                    afterupload ?
                      <Col xl={afterupload ? 6 : 5} sm={6}>
                        <>
                          <Button
                            variant="link"
                            className="p-0 fw-bold text-nowrap"
                            onClick={(e) =>
                              triggerNewLogo()
                            }
                          >
                            {t("button.save")}
                          </Button>
                        </>
                      </Col> : ""
                  }
                </Row>
              </div>
            </Card.Header>
            <Card.Body className="text-center newlogo">
              <Form onSubmit={handleSubmit(onSubmit)} >
                {
                  (getlogo?.logo_name == "" || getlogo?.logo_name == undefined) ?
                    <div>
                      <div className="w-100 d-block">
                        <input type="file" accept="image/png, image/jpeg" {...register('logo_name')} id='logoUploadlogofile' className="form-control removeInsertDisable" hidden onChange={(e) => handleChange(e)} />
                        <label htmlFor='logoUploadfile' title='Company Logo'>
                          <img className="form-control cursor-pe" src={logofile || 'https://www.freeiconspng.com/thumbs/upload-icon/upload-icon-22.png'} alt='imagename' width="200" height="200" onClick={() => {
                            const fileInput = document.getElementById('logoUploadlogofile');
                            if (fileInput) {
                              (fileInput as HTMLInputElement).click();
                            }
                          }} />
                        </label>

                      </div>
                      {(logofile) ? <Button size="sm" variant="link" className="mt-3 p-0 fw-bold p-1 delete updateImagewithsave d-none" id="updateImg" type="submit" >{t('button.updateLogo')} </Button> : ''}
                    </div>
                    :
                    <div>
                      <div className="w-100 d-block">
                        <input type="file" accept="image/png, image/jpeg" {...register('logo_name')} id='logoUploadfile' className="form-control removeInsertDisable" hidden onChange={(e) => handleChange(e)} disabled />
                        <label htmlFor='logoUploadfile' title='Upload Company Logo' id='imglabel'>
                          <img className="form-control cursor-pe" src={getlogo?.logo_name && IMAGEURLLINK.replace("{logoname}", ((getlogo?.logo_name).split("_|_")[0]).replace("/", "%2F")).replace("{generation}", (getlogo?.logo_name).split("_|_")[1])} alt='imagename' id="imgupdate" width="200" height="200" /> 
                        </label>
                      </div>
                      {(afterupload) ?
                        <Button size="sm" variant="link" className="mt-3 p-0 fw-bold p-1 delete updateImagewithsave d-none" id="updateImg" type="submit" >{t('button.updateLogo')} </Button> : ''
                      }
                      {
                        completedCrop?.height &&
                        <span className="text-secondary pt-3 w-100 d-block fs-12">{`${Math.ceil(completedCrop?.width ?? 0)}x${Math.ceil(completedCrop?.height ?? 0)} ${completedCrop?.unit == "px" ? t("company.pixelsize") : ""}`}</span>
                      }
                      {
                        !completedCrop?.height &&
                        <span className="text-secondary pt-3 w-100 d-block fs-12">156x128 {t("company.pixelsize")}</span>
                      }
                    </div>
                }
              </Form>
            </Card.Body>
            <div>
              <Modal show={show} onHide={handleClose}>
                <Form >
                  <Modal.Header closeButton>
                    <Modal.Title>{t("company.ImageCrop")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="py-2 text-center">

                    {logofile && (
                      <>
                        <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)} >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={logofile}
                            onLoad={onImageLoad}
                            style={{
                              transform: `scale(${scale}) rotate(${rotate}deg)`,
                              objectFit: "contain", // Use "contain" to avoid cutting off long-width images
                              minHeight: "300px",    // Adjust the minimum height as needed
                              minWidth: "300px",    // Adjust the minimum width as needed
                              width: "100%",        // Make the image responsive within the container
                              height: "auto",       // Let the height adjust proportionally
                            }}
                          />
                        </ReactCrop>

                      </>
                    )}
                    <div hidden>
                      {!!completedCrop && (
                        <>
                          <div>
                            <canvas ref={previewCanvasRef} style={{ border: '1px solid black', objectFit: 'contain', width: completedCrop.width, height: completedCrop.height, }} />
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="controls zoomfixed me-auto">
                      <input type="range" min={0.6} max={3} step={0.1} value={scale} onChange={handleZoomChange} className="zoom-range rounded" />
                    </div>
                    <Button variant="outline-primary" onClick={handleClose}>
                      {t("button.close")}
                    </Button>
                    <Button variant="primary" onClick={handleCropInsert}>
                      {t('button.crop')}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            </div>
          </Card>
      }
    </>
  )
}

export default Updatelogo