import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getBraches,
  delete_branch,
  delete_branche,
  Edit_branch,
  Newbranch,
  Update_Branch,
  EditBranch,
  Edit_branches,
} from "../../../../redux/slice/branchSlice";
import { ComProps } from "../../../Interface";
import NewBranch from "./NewBranch";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Loader from "../Loader";
import Swal from "sweetalert2";
import {
  Form,
  Modal,
  Button,
  Row,
  Col,
  Table,
  InputGroup,
  FloatingLabel,
  Card,
} from "react-bootstrap";
import Pagination from "../../../Pagination";
import customerService from "../../../../services/customerService";
import { openErrorbox } from "../../../Pages/Contracts/Common/actionPerform";
import {
  CNJP_CPFValidation,
  checkDuplicateCNJP_CPF,
  encrypt_Decrypt_Plan,
} from "../../../constants/utils";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyFolder from "../../../Pages/NewDesigns/Documents/EmptyFolder";
import EmptyReason from "../../../Pages/NewDesigns/Documents/EmptyReason";

interface Props {
  value: number;
  label: string;
}

const Branches = ({ submenu }: ComProps) => {
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation();
  // Retrieve banklist, show1, and status from the Redux state
  const { branchList, show1, status, totalRow } = useSelector(
    (state: RootState) => state.branch
  );
  // used it to show validation error on form
  const schema = yup.object().shape({
    branchname: yup.string().required(t("requiredMmessage.fieldRequired")),
    corporatereason: yup.string().required(t("requiredMmessage.fieldRequired")),
    CNPJ: yup.string().required(t("requiredMmessage.fieldRequired")),
    municipalregistration: yup
      .string()
      .required(t("requiredMmessage.fieldRequired")),
    stateregistration: yup
      .string()
      .required(t("requiredMmessage.fieldRequired")),
    responsiblename: yup.string().required(t("requiredMmessage.fieldRequired")),
    CPF: yup.string().required(t("requiredMmessage.fieldRequired")),
    email: yup.string().required(t("requiredMmessage.fieldRequired")),
    telephone: yup
      .string()
      .required(t("requiredMmessage.fieldRequired"))
      .matches(
        /\(\d{2}\) \d{4}-\d{4}/,
        `${t("requiredMmessage.TelephoneError")}`
      ),
    zipcode: yup.string().required(t("requiredMmessage.fieldRequired")),
    address: yup.string().required(t("requiredMmessage.fieldRequired")),
    number: yup.string().required(t("requiredMmessage.fieldRequired")),
    complement: yup.string().required(t("requiredMmessage.fieldRequired")),
    neighborhood: yup.string().required(t("requiredMmessage.fieldRequired")),
    city: yup.string().required(t("requiredMmessage.fieldRequired")),
    state: yup.string().required(t("requiredMmessage.fieldRequired")),
  });
  // The useForm hook is used to handle form registration, submission, and validation.
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Newbranch>({
    resolver: yupResolver(schema),
  });
  // get the branch id in useSatate
  const [branchId, seBranchId] = useState<number>();
  const [AllStates, setAllStates] = useState([]);
  const [branchcCount, setBranchCount] = useState(0);
  //Getting the data shown when the page is rendered for the first time --registerData and getbranches
  //console.log(totalRow)
  useEffect(() => {
    dispatch(getBraches(state));
  }, [dispatch]);

  const [userPlanString, setUserPlanString] = useState("");
  const [reasonBlock, setReasonBlock] = useState(false);

  useEffect(() => {
    let userPLAN = encrypt_Decrypt_Plan();
    // setUserPlanString(userPLAN)
    setUserPlanString("300")
    // if (userPLAN === "50" || userPLAN === "100" || userPLAN === "200") {
    //   setReasonBlock(true)
    // }
  }, [location])

  const [state, setState] = useState<any>({
    start: 0,
    end: 20,
  });

  // Pagination start
  let PageSize = state.end;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpage, setTotalPage] = useState(0);

  useEffect(() => {
    if (totalRow !== undefined) {
      let count = Number(totalRow);
      setTotalPage(count);
    }
  }, [totalRow]);

  useEffect(() => {
    state["start"] = (currentPage - 1) * state["end"];
    dispatch(getBraches(state));
  }, [currentPage, state]);

  // Pop up hide of edit branch on click
  const handleClose1 = () => {
    let zipCodeCheck: any = document.querySelector(".zipCodeCheck");
    zipCodeCheck?.["removeAttribute"]("disabled");
    dispatch(Edit_branch(false));
  };

  //When we first have to show the data in the input field edit branch
  const HandleSubmits = async (id: number) => {
    dispatch(Edit_branch(true));
    seBranchId(id);
    let data = await dispatch(Edit_branches(id));
    const fields: string[] = [
      "branchname",
      "corporatereason",
      "id",
      "CNPJ",
      "branch_id",
      "municipalregistration",
      "CPF",
      "stateregistration",
      "responsiblename",
      "zipcode",
      "address",
      "city",
      "state",
      "telephone",
      "email",
      "complement",
      "number",
      "neighborhood",
    ];
    fields.forEach((field: any) =>
      setValue(field, data.payload.data[0][field])
    );
  };
  // To delete one by one branch list
  const deletebranch = async (data: string, id: number) => {
    if (data == "delete") {
      Swal.fire({
        title: `${t("Sweetalert.Warning")}`,
        text: `${t("Sweetalert.deleteBranch")}`,
        // icon: 'success',"question",
        showCancelButton: true,
        cancelButtonText: `${t("button.cancel")}`,
        confirmButtonText: `${t("Sweetalert.yesDeleteIt")}`,
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          let res = await dispatch(delete_branch(id));
          if (res.payload.type == "Success") {
            // Show a success message using Swal
            Swal.fire({
              // icon: 'success','success',
              title: `${t("Sweetalert.Success")}`,
              text: `${t("Sweetalert.deleteRecord")}`,
              confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
              timer: 2000,
            });
            dispatch(delete_branche(id));
          } else {
            // Show an error message
            Swal.fire({
              title: `${t("Sweetalert.Error")}`,
              text: `${t("Sweetalert.alreadyuse")}`,
              // icon: 'success',"error",
              confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
              timer: 2000,
            });
          }
        } else {
          console.log("no");
        }
      });
    }
  };

  useEffect(() => {
    (async () => {
      let resp = await customerService.getAddressBasedOnZipcode(`states`);
      if (resp.data.type == "Success") {
        let loop = resp.data.data;
        let stateData = loop.map((item) => ({
          value: item.state,
          label: item.state,
        }));
        // Sort the stateData alphabetically by label
        stateData.sort((a, b) => a.label.localeCompare(b.label));
        setAllStates(stateData);
      }

      // Filter out elements with id equal to 0
      const filteredList =
        branchList && branchList.length > 0
          ? branchList.filter((element) => element.id !== 0)
          : [];
      // Get the total count of filtered elements
      const totalCount = filteredList && filteredList.length;
      setBranchCount(totalCount);
    })();
  }, []);

  const getLocation = async (e, inputType) => {
    // Retrieve address based on zipcode using customerService
    let str = e.target.value;
    let newStr = str.replace("-", ""); // Removes the hyphen
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, "").match(/(\d{0,5})(\d{0,3})/);
    let newValue = (e.target.value = !value[2]
      ? value[1]
      : value[1] + "-" + value[2]);
    // Error validation
    // Retrieve address based on zipcode using customerService
    let resp = await customerService.getAddressBasedOnZipcode(
      `${inputType}/${newStr}`
    );
    // Get the DOM element for the zipcode input
    let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
    let zipCodeCheck: any = document.querySelector(".zipCodeCheck");
    if (newValue !== "" || newValue !== null || newValue !== undefined) {
      // Check if the response status is success
      if (resp?.["data"]["type"] == "Success") {
        let res = resp["data"]["data"];
        querySel.style.border = "0px solid red";
        zipCodeCheck.removeAttribute("disabled");
        // Set the city value if available in the response
        if (res[0]["city"]) {
          setValue("city", res[0]["city"]);
        }
        if (res[0]["neigbohood"]) {
          setValue("neighborhood", res[0]["neigbohood"]);
        }
        if (res[0]["state"]) {
          setValue("state", res[0]["state"]);
        }
        if (res[0]["street"] || res[0]["streetType"]) {
          let data =
            (res[0]["streetType"] ? res[0]["streetType"] + " " : "") +
            (res[0]["street"] ?? "");
          setValue("address", data);
        }
        clearErrors("zipcode");
        clearErrors("city");
        clearErrors("neighborhood");
        clearErrors("address");
        clearErrors("state");
      } else {
        setError("zipcode", {
          type: "manual",
          message: t("invalidzipcode"),
        });
        // Reset city, neighborhood, and address values
        querySel.style.border = "2px solid red";
        setValue("city", "");
        setValue("neighborhood", "");
        setValue("state", "");
        setValue("address", "");
        zipCodeCheck.setAttribute("disabled", "");
      }
    } else {
      querySel.style.border = "0px solid red";
      zipCodeCheck.removeAttribute("disabled");
    }
  };

  // Used to insert edit  branch list
  const onSubmit = async (data: Newbranch) => {
    data["id"] = branchId;
    data["branch_id"] = branchId;
    let response = await dispatch(Update_Branch(data));
    if (response.payload.type == "Success") {
      // Show a success message using Swal
      Swal.fire({
        position: "center",
        // icon: 'success','success',
        title: `${t("Sweetalert.Success")}`,
        text: `${t("Sweetalert.RecordUpdated")}`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(EditBranch(data));
      dispatch(getBraches(state));
      dispatch(Edit_branch(false));
      reset();
    } else {
      // Show an error message
      Swal.fire({
        title: `${t("Sweetalert.Error")}`,
        text: `${t("Sweetalert.somethingWent")}`,
        // icon: 'success',"error",
        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
        timer: 2000,
      });
    }
  };

  const HandleInput = (e: any) => {
    // Input processing and formatting
    let value = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    let newValue = (e.target.value = !value[2]
      ? value[1]
      : "(" + value[1] + ") " + value[2] + (value[3] ? "-" + value[3] : ""));

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`,
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors("telephone");
    }
  };

  const handleCPF_CNJPVaue = async (e, type) => {
    let name = e.target.name;
    let checkCNJP = CNJP_CPFValidation(e.target.value, type);
    if (checkCNJP?.["status"] || e.target.value == "") {
      clearErrors(name);
      let zipCodeCheck: any = document.querySelector(".zipCodeCheck");
      zipCodeCheck?.["setAttribute"]("disabled", "");
      if (e.target.value !== "") {
        let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
        if (duplicateData["data"] > 0) {
          zipCodeCheck?.["setAttribute"]("disabled", "");
          setError(name, {
            type: "manual",
            message: t("formLabel.ErrorCNPJCPF"),
          });
        } else {
          zipCodeCheck?.["removeAttribute"]("disabled");
        }
      }
    } else {
      // errorCNPJCPF(e.target.value)
      setError(name, {
        type: "manual",
        message: checkCNJP?.["message"],
      });
    }
  };

  return (
    <>
      <section
        className="content-wrapper content-wrapper-primary"
        id="templateBranchedtsxhelpa"
      >
        {/* <!-- Breadcrumb section start from here --> */}
        {submenu}
        {
          reasonBlock ?
            <EmptyReason />
            :
            <>
              {status == "loading" ? (
                <Loader />
              ) : (
                <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                  {/*  <!-- Table start from here --> */}
                  <Card>
                    <Card.Body className="pb-1">
                      <Table
                        responsive
                        className="table-custom table-custom-bordered mb-0"
                      >
                        <thead>
                          <tr>
                            <th>{t("branches.name")}</th>
                            <th>{t("branches.contact")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {branchList && branchList.length > 0 ? (
                            branchList.map((curElem: any, index: number) => {
                              if (curElem.id !== '00') {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <span className="d-block">
                                        {curElem.branchname}
                                      </span>
                                      <span className="d-block text-dark-70">
                                        {curElem.address}
                                      </span>
                                      <span className="d-block text-dark-70">
                                        {curElem.CNPJ}
                                      </span>
                                      <span className="d-block">
                                        {curElem.complement}
                                      </span>
                                      <span className="d-block">{curElem.number}</span>
                                    </td>
                                    <td>
                                      <span className="d-block">{curElem.city}</span>
                                      <span className="d-block text-dark-70">
                                        {curElem.email}
                                      </span>
                                      <span className="d-block">
                                        {curElem.telephone}
                                      </span>
                                    </td>
                                    <td className="text-end">
                                      {userPlanString !== "100" && userPlanString !== "50" && curElem.id !==0 &&(
                                        <ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                          <li className="list-inline-item me-2 me-xxl-4 me-lg-3 BtnAction Edit">
                                            <span
                                              className="icon img-primary cursor-pe"
                                              onClick={() => HandleSubmits(curElem.id)}
                                            >
                                              <img
                                                src="assets/images/icons/edit-light.svg"
                                                alt="Edit"
                                                className="h-20"
                                              />
                                            </span>
                                          </li>
                                          <li className="list-inline-item">
                                            <span
                                              className="icon img-primary cursor-pe"
                                              onClick={() =>
                                                deletebranch("delete", curElem.id)
                                              }
                                            >
                                              <img
                                                src="./assets/images/icons/delete-light.svg"
                                                alt="Delete"
                                                className="h-20"
                                              />
                                            </span>
                                          </li>
                                        </ul>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan={3} className="text-center">
                                  {t("formLabel.noRecordsFounds")}.
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>

                  {totalRow ? (
                    <div className="d-flex justify-content-center mt-lg-4 mt-3">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalpage}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-lg-4 mt-3">
                    {t("comparetolastmonth.total")}{" "}
                    {branchList && branchList !== undefined ? branchList.length : 0}{" "}
                    {t("branches.branch")}
                  </div>
                  {/*  <!-- /Table end --> */}
                </main>
              )}
            </>
        }
        {/*<!-- /Breadcrumb section end --> */}

        {/* <!-- /Main content start end --> */}
        {/* New branch component */}
        <NewBranch />
        {/* show edit branch pop up start from here */}

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>{t("branches.editBranch")}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body className="py-2">
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={12}>
                  <FloatingLabel controlId="name" label={t("formLabel.name")}>
                    <Form.Control
                      type="text"
                      className={`${errors.branchname ? "is-invalid" : ""}`}
                      placeholder="Enter Your  Name"
                      {...register("branchname")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.branchname?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="corporateReason"
                    label={t("formLabel.corporateReason")}
                  >
                    <Form.Control
                      type="text"
                      className={`${errors.corporatereason ? "is-invalid" : ""}`}
                      placeholder="Corporate reason"
                      {...register("corporatereason")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.corporatereason?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="cnpj" label={t("formLabel.cnpj")}>
                    <Form.Control
                      type="text"
                      className={`${errors.CNPJ ? "is-invalid" : ""}`}
                      placeholder="CNPJ"
                      {...register("CNPJ")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.CNPJ?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="municipalRegistration"
                    label={t("formLabel.municipalregistration")}
                  >
                    <Form.Control
                      type="text"
                      className={`${errors.municipalregistration ? "is-invalid" : ""
                        }`}
                      placeholder="Municipal registration"
                      {...register("municipalregistration")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.municipalregistration?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="stateRegistration"
                    label={t("formLabel.stateregistration")}
                  >
                    <Form.Control
                      type="text"
                      className={`${errors.stateregistration ? "is-invalid" : ""
                        }`}
                      placeholder="State registration"
                      {...register("stateregistration")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.stateregistration?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <span className="d-block mb-3 fw-bold">{t("company.contact")}</span>
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={6}>
                  <FloatingLabel
                    controlId="responsiblename"
                    label={t("formLabel.responsiblename")}
                  >
                    <Form.Control
                      type="text"
                      className={`${errors.responsiblename ? "is-invalid" : ""}`}
                      placeholder="Responsible name"
                      {...register("responsiblename")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.responsiblename?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="cpf" label={t("formLabel.cpf")}>
                    <Form.Control
                      type="text"
                      className={`${errors.CPF ? "is-invalid" : ""}`}
                      placeholder="CPF"
                      {...register("CPF")}
                      onChange={(e) => handleCPF_CNJPVaue(e, "cpf")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.CPF?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="email" label={t("formLabel.email")}>
                    <Form.Control
                      type="email"
                      className={`${errors.email ? "is-invalid" : ""}`}
                      placeholder="Email"
                      {...register("email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="telephone"
                    label={t("formLabel.telephone")+ " *"}
                  >
                    <Form.Control
                      type="tel"
                      className={`${errors.telephone ? "is-invalid" : ""}`}
                      placeholder="Telephone"
                      {...register("telephone")}
                      onChange={HandleInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.telephone?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <span className="d-block mb-3 fw-bold">{t("company.address")}</span>
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={6}>
                  <InputGroup>
                    <FloatingLabel
                      controlId="zipCode"
                      label={t("formLabel.zipCode")+ " *"}
                      className="getzipcode"
                    >
                      <Form.Control
                        type="tel"
                        className={`${errors.zipcode ? "is-invalid" : ""}`}
                        placeholder="Zip code"
                        {...register("zipcode")}
                        onChange={(e) => getLocation(e, "zipcode")}
                        maxLength={9}
                        minLength={9}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zipcode?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <InputGroup.Text>
                      <img
                        src="assets/images/icons/search.svg"
                        alt="Search"
                        className="h-14 cursor-pe"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="address"
                    label={t("formLabel.address")}
                  >
                    <Form.Control
                      type="text"
                      className={`${errors.address ? "is-invalid" : ""}`}
                      placeholder="Address"
                      {...register("address")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="number" label={t("formLabel.number")}>
                    <Form.Control
                      type="number"
                      className={`${errors.number ? "is-invalid" : ""}`}
                      placeholder="Number"
                      {...register("number")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.number?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="complement"
                    label={t("formLabel.complement")}
                  >
                    <Form.Control
                      type="text"
                      className={`${errors.complement ? "is-invalid" : ""}`}
                      placeholder="Complement"
                      {...register("complement")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.complement?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={5}>
                  <FloatingLabel
                    controlId="neighborhood"
                    label={t("formLabel.neighborhood")}
                  >
                    <Form.Control
                      type="text"
                      className={`${errors.neighborhood ? "is-invalid" : ""}`}
                      placeholder="Neighborhood"
                      {...register("neighborhood")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.neighborhood?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={4}>
                  <FloatingLabel controlId="city" label={t("formLabel.city")}>
                    <Form.Control
                      type="text"
                      className={`${errors.city ? "is-invalid" : ""}`}
                      placeholder="City"
                      {...register("city")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={3}>
                  <FloatingLabel controlId="state" label={t("formLabel.state")}>
                    <Form.Select
                      className={`${errors.state ? "is-invalid" : ""}`}
                      {...register("state")}
                    >
                      <option value="">--type--</option>
                      {AllStates &&
                        AllStates.length > 0 &&
                        AllStates.map((item, index) => {
                          return (
                            <option key={index} value={item?.["label"]}>
                              {item?.["label"]}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.state?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                onClick={handleClose1}
                type="button"
                id="closemodal"
              >
                {t("button.close")}
              </Button>
              <Button
                variant="primary zipCodeCheck"
                type="submit"
                onClick={openErrorbox}
              >
                {t("button.toSave")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* show edit branch pop up end */}
      </section>
    </>
  );
};

export default Branches;
