import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { Icon } from "../../../../components/Icon";
import { useDimob } from "../../DimobProvider";
import "../../Styles/styles.filters.css";

export function Search() {
  const { updateFilters } = useDimob()

  return (
    <InputGroup className="search-input">
      <FormControl
        type="text"
        placeholder="Escreva para pesquisar"
        className="form-control"
        onChange={(e) => {
          const value = e.target.value ?? ""

          updateFilters({
            contract: value
          })
        }}
      />
      <InputGroup.Text className="input-group-text">
        <Icon name="search" />
      </InputGroup.Text>
    </InputGroup>
  );
}
