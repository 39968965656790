import { Button, Form, Modal, Row, Col, FloatingLabel, Accordion, Collapse, InputGroup } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import '../../../../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, encrypt_Decrypt, handleKeyDown, checkDuplicateCNJP_CPF } from '../../../constants/utils';
import { GetRepresentativeService, contractOpen } from '../../../../redux/slice/contractDataSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getManager } from '../../../../redux/slice/propertyDataSlice';
import RepresentativeService from '../../NewDesigns/Contracts/RepresentativeService';


const NewGuarantors = (props) => {
    let { show, setShow, type, onComplete } = props;
    const dispatch = useDispatch<AppDispatch>()
    // const { show } = useSelector((state: RootState) => state.expenditureData)
    const { managerData } = useSelector((state: RootState) => state.propertyData)
    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(getManager());
        handleRepresentative()
    }, [dispatch]);

    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        if (show) {
            let userResult = encrypt_Decrypt();
            if (!userResult || userResult.indexOf(2060) === -1) {
                navigate("/dashboard");
            }
            setUserJson(userResult);
        }
    }, [show])

    const [t] = useTranslation();
    // const [show, setShow] = useState(false);
    const [loader_on, setloader_on] = useState(false);

    const [hide, setHide] = useState(false);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [showrep, setshowRep] = useState(true)
    const [headingText, setHeadingText] = useState(t('customerAndEmployees.newGuarantor'));
    const [showNewRepresentativeModal, setNewRepresentativeModal] = useState(false);
    const [formData, setFormData] = useState<any>([{ Property_Type: '', Property_Value: '', Neighborhood: '', Zip_code: '', Address: '', Number: '', Complement: '', state_of: '', City: '', Registry: '', Registration: '', Building_con: '', }]);
    /*--------Data table listing state & multiple checked--------*/
    const [AllStates, setAllStates] = useState([]);
    const [showHideField, setShowHideField] = useState<any>("")

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, []);

    /*--------Common modal function--------*/
    useEffect(() => {
        if (show && showrep)
            setHide(true)
                const fetchData = async () => {
                    try {
                        const resp = await dispatch(GetRepresentativeService());
                        if (resp.payload.type.toLowerCase() === "success") {
                            setRepreData(resp.payload.data);
                        }
                    } catch (error) {
                        console.error("Error fetching representative data:", error);
                    }
                };
    
                fetchData();
    }, [show])

    const formClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        setHide(false); setHide2(false); setHide3(false); setShow(false); reset();
        if (type && type == "contractService") {
            dispatch(contractOpen(true));
        }
    }
    /*-------Step form function & validation--------*/
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => { setHide(true); setHide2(false); setHide3(false);}
    const step2 = () => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false); setHide2(true); setHide3(false);
        }
    }
    const step3 = (data: CustomersAndEmployeesForm) => { setHide(false); setHide2(false); setHide3(true); }

    // Retrieve banklist, show1, and status from the Redux state

    /*-------------Form validation-------------*/

    let regexPattern = /^[^0-9./-]*$/;
    if(showHideField == "cnpj"){
        regexPattern = /^[a-zA-Z0-9 ]*$/;
    }
    const formValidationSchemaStep1 = Yup.object().shape({
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    const formValidationSchemaStep3 = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(formValidationSchemaStep1)
    }
    if (hide || hide2 || hide3) {
        frm = {
            resolver: yupResolver(formValidationSchemaStep1)
        }
        if (hide2) {
            frm = {
                resolver: yupResolver(formValidationSchemaStep3)
            }
        }

    }

    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });


    const [repreData, setRepreData] = useState([])
    const handleRepresentative = async () => {
        let resp = await dispatch(GetRepresentativeService())
        if (resp.payload.type == "success" || resp.payload.type == "Success") {
            setRepreData(resp.payload.data)
        }
    }

    const handleChangeInput = (e: any, index: any, type: string, field: string) => {
        let value = e.target.value;
        addState[type][index][field] = value;
        let allState: any = addState;
        setAddState(allState);
    }

    const addAnother = (e: any, type: string) => {
        e.preventDefault();
        if (type == "retentionn") {
            let dataAdd: any = {
                ...addState,
                retentionn: [
                    ...addState["retentionn"],
                    { Tax: "", Aliquot: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "beneficiaries") {
            let dataAdd: any = {
                ...addState,
                beneficiaries: [
                    ...addState["beneficiaries"],
                    { name: "", transfer: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "representative") {
            let dataAdd: any = {
                ...addState,
                representative: [
                    ...addState["representative"],
                    { name: "" }
                ]
            }
            setAddState(dataAdd)
        }
    }

    const removeAddAnother = (index: any, array: any, type: any) => {
        array.splice(index, 1);
        // let TypeData = type;
        // reset(TypeData);
        if (type == "retentionn") {
            array.forEach((item: any, index: number) => {
                let Tax: any = `retentionn.${index}.tax`;
                let Aliquot: any = `retentionn.${index}.Aliquot`;
                setValue(Tax, item.Tax);
                setValue(Aliquot, item.Aliquot);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "beneficiaries") {
            array.forEach((item: any, index: number) => {
                let name: any = `beneficiaries.${index}.name`;
                let transfer: any = `beneficiaries.${index}.transfer`;
                setValue(name, item.name);
                setValue(transfer, item.transfer);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "representative") {
            array.forEach((item: any, index: number) => {
                let name: any = `representative.${index}.name`;
                setValue(name, item.name);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }
    }

    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);
    const formSubmit = (data: CustomersAndEmployeesForm) => {
        let formData = data.formData.slice(); // Clone the original array
        setloader_on(true);
        formData.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (item[key] === "") {
                    formData = []; // Reset formData if any property is empty
                }
            });
        });
        let owner = {
            type: data.type,
            cnpj: data.cnpj,
            name: data.name,
            payment: data.payment,
            mobile: data.mobile,
            telephone: data.telephone,
            email: data.email,
            password: process.env.REACT_APP_PASSWORD,
            mother: data.mother,
            father: data.father,
            rg: data.rg,
            issue_agency: data.issue_agency,
            shipping_date: data.shipping_date,
            sex: data.sex,
            birth: data.birth,
            birth_place: data.birth_place,
            marital_status: data.marital_status,
            nationality: data.nationality,
            zipcode: data.zipcode,
            address: data.address,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            employment_company: data.employment_company,
            employment_profession: data.employment_profession,
            employment_field_activity: data.employment_field_activity,
            employment_monthly_income: data.employment_monthly_income,
            employment_zipcode: data.employment_zipcode,
            employment_address: data.employment_address,
            employment_number: data.employment_number,
            employment_complement: data.employment_complement,
            employment_neighborhood: data.employment_neighborhood,
            employment_city: data.employment_city,
            employment_state: data.employment_state,
            representative_name: data.representative_name,
            retention: data.retention,
            observation: data.observation,
            is_vendor: data.is_vendor,
            formData: formData,
            spouse_name: data.spouse_name,
            spouse_cpf: data.spouse_cpf,
            spouse_rg: data.spouse_rg,
            spouse_issue_agency: data.spouse_issue_agency,
            spouse_nationality: data.spouse_nationality,
            spouse_sex: data.spouse_sex,
            spouse_profession: data.spouse_profession,
            spouse_cellphone: data.spouse_cellphone,
            spouse_telephone: data.spouse_telephone,
            spouse_email: data.spouse_email,
            spouse_observation: data.spouse_observation,
            block_balance: data.block_balance,
            manager: data.manager,
            ID_SACADO_SAC: data.ID_SACADO_SAC,
            ID_FAVORECIDO_FAV: data.ID_FAVORECIDO_FAV,
            corporateReason:data.corporateReason,
            stateRegistration:data.stateRegistration,
            municipalRegistration:data.municipalRegistration,
            
            new_added: {
                addState: addState,
                Renda: {
                    FL_SEMRENDA_PES: data.possiu ?? "",
                    FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                    ST_BENEFICIO_PES: data.beneficio ?? "",
                    VL_OUTROSRENDIMENTOS_PES: data.rendimentous ?? ""
                },
                Retencao_de_impostos: {
                    FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
                },
                Mais_opcoes: {
                    NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                    FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                    FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
                },
                Fornecedor: {
                    FL_SINCRONIZARSACADO_FAV: data.supplieradv ?? ""
                },
                Pagemento: {
                    DT_DIAREPASSE_PES: data.Repassedia ?? "",
                    VL_SALDOBLOQUEADO_PES: data.bloquendo ?? ""
                }
            }
        }

        customerService.createOwner(owner).then(res => {
            if (res.data.resCode === 202) {
                if (type != "contractService") {
                    props.getAllGuarantor({
                        guarantor: "",
                        person: "",
                        status: "",
                        start: 0,
                        end: 20,
                    });
                }
                if (onComplete) onComplete(owner)
                formClose();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            } else {
                let msg = ( (res.data?.data  && res.data.data.length ) || res.data.data ) ? res.data.message : '';
                if (res.data.resCode == 409) {
                    msg = t("Sweetalert.unqueEmails");
                }
                if (res.data.resCode === 400 && res.data.message == 'Owneremail!=AgencyEmail' ) {
                    msg = t("ownerEmailnotEqualAgencyEmail");
                }
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: msg ? msg : t("Sweetalert.somethingWent"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            setloader_on(false);
        }).catch(error => {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                confirmButtonText: "OK",
                timer: 2000
            });
            setloader_on(false);
        });
    }

    /*-------Checked/Unchecked multiple checkbox functionality--------*/
    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
        goodofZip: "",
    });

    useEffect(() => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (zipvalue.zip == "" && zipvalue.empzip == "" || zipvalue.goodofZip == "") {
            zipCodeCheck?.["removeAttribute"]('disabled');
        } else {
            zipCodeCheck?.["setAttribute"]('disabled', "")
        }
    }, [zipvalue])

    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySelthird: any
        let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="employment_zipcode"]');
        if (formData.length > 0) {
            formData?.forEach((curElem, index) => {
                querySelthird = document.querySelector(`.accordion-custom [name="formData.${index}.Zip_code"]`);
            });
        }
        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str,
            })
        } else if (forwhich == "second") {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["goodofZip"]: str
            })
        }
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("address", data)
                    }
                } else if (forwhich == "second") {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("employment_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("employment_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("employment_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("employment_address", data)
                    }
                } else {
                    if (formData.length > 0) {
                        const updatedFormData = [...formData];
                        formData?.forEach((curElem, index) => {
                            querySelthird.style.border = "0px solid red";
                            if (res[0]["city"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["Zip_code"]: str };
                                setValue(`formData.${index}.Zip_code`, str)
                            }
                            if (res[0]["city"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["City"]: res[0]["city"] };
                                setValue(`formData.${index}.City`, res[0]["city"])
                            }
                            if (res[0]["neigbohood"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["Neighborhood"]: res[0]["neigbohood"] };
                                setValue(`formData.${index}.Neighborhood`, res[0]["neigbohood"])
                            }
                            if (res[0]["state"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["state_of"]: res[0]["state"] };
                                setValue(`formData.${index}.state_of`, res[0]["state"])
                            }
                            if (res[0]["street"] || res[0]["streetType"]) {
                                let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                                updatedFormData[index] = { ...updatedFormData[index], ["Address"]: data };
                                setValue(`formData.${index}.Address`, data)
                            }
                        })
                        setFormData(updatedFormData)
                    }
                }

            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("city", "")
                    setValue("neighborhood", "")
                    setValue("state", "")
                    setValue("address", "")
                } else {
                    querySelsecond.style.border = "2px solid red"
                    setValue("employment_city", "")
                    setValue("employment_neighborhood", "")
                    setValue("employment_state", "")
                    setValue("employment_address", "")
                }
            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            setShowHideField(checkCNJP?.["type"])
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                let boolVal = false
                if(headingText == "New Guarantor" || headingText == "Novo Fiador"){
                    boolVal =  duplicateData["data"][0]?.FL_FIADOR_PES == 1 
                }
                const fieldsMapping:any = {
                    email: "ST_EMAIL_PES",
                    name: "ST_NOME_PES",
                    mobile: "ST_CELULAR_PES",
                    complement: "ST_COMPLEMENTO_PES",
                    zipcode: "ST_CEP_PES",
                    city: "ST_CIDADE_PES",
                    address: "ST_ENDERECO_PES",
                    state: "ST_ESTADO_PES",
                    number: "ST_NUMERO_PES",
                    neighborhood: "ST_BAIRRO_PES",
                    telephone: "ST_TELEFONE_PES",
                    observation: "ST_OBSERVACAO_PES",
                    nationality: "ST_NACIONALIDADE_PES",
                    sex: "ST_SEXO_PES",
                    birth: "DT_NASCIMENTO_PES",
                    marital_status: "ST_ESTADOCIVIL_PES",
                    employment_company: "ST_TRABALHOCEP_PES",
                    employment_profession: "ST_PROFISSAO_PES",
                    employment_field_activity: "ST_RAMOATIVIDADE_PES",
                    employment_address: "ST_TRABALHOENDERECO_PES",
                    employment_number: "ST_TRABALHONUMERO_PES",
                    employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
                    employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
                    employment_city: "ST_TRABALHOCIDADE_PES",
                    employment_state: "ST_TRABALHOESTADO_PES",
                    employment_monthly_income: "VL_RENDAMENSAL_PES",
                    rg: "ST_RG_COJ",
                    retention: "VL_TXISSQN_PES",
                    mother: "ST_NOMEMAE_PES",
                    father: "ST_NOMEPAI_PES",
                    shipping_date: "DT_EXPEDICAORG_PES",
                    block_balance: "VL_SALDOBLOQUEADO_PES",
                    manager: "ID_GESTOR_GES",
                    issue_agency: "ST_ORGAO_PES",
                    corporateReason: "ST_NOME_PES",
                    stateRegistration: "ST_IE_PES",
                    municipalRegistration: "ST_INSCMUNICIPAL_PES"
                  };
                if (duplicateData["data"].length> 0) {
                    if(boolVal){
                        zipCodeCheck?.["setAttribute"]('disabled', "")
                        setcnpjcpferrormessage({
                            name: name,
                            message: t("formLabel.ErrorCNPJCPF")
                        })
                        setError(name, {
                            type: "manual",
                            message: t("formLabel.ErrorCNPJCPF")
                        });
                    }else{
                        zipCodeCheck?.["removeAttribute"]('disabled');
                  
                          
                          // Assuming `duplicateData` is correctly populated
                          const data:any = duplicateData?.["data"]?.[0];
                          
                          if (data) {
                            Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                setValue('ID_FAVORECIDO_FAV',data?.ID_FAVORECIDO_FAV)
                                setValue('ID_SACADO_SAC',data?.ID_SACADO_SAC)
                            });
                          }
                    }
                } else {
                    Object?.entries(fieldsMapping)?.forEach(([fieldName, dataKey]) => {
                        setValue(fieldName as keyof CustomersAndEmployeesForm, "")
                        setValue('ID_FAVORECIDO_FAV', "")
                        setValue('ID_SACADO_SAC',"")
                    });
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    const [spouse, setspouse] = useState(false);
    const handleChangeMaritail = (e) => {
        if (e.target.value == "1") {
            setspouse(true);
        } else {
            setspouse(false);
        }
    }
    const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hide2) {
            step1();
        } else if (hide3) {
            step2();
        } else {
            step1();
        }
    }
    const handleNameof = (e) => {
        let { value } = e.target
        setValue("name_of", value)
        // Error validation
        if (value == "" || value == null || value == undefined) {
            // Set an error message using setError or update your error state
            setError("name", {
                type: "manual",
                message: (t('requiredMmessage.fieldRequired'))
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('name');
        }
    }
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedFormData = [...formData]; // Create a copy of the formData array
        const dataIndex = name.split('.')[1]; // Extract the index from the name attribute
        if (dataIndex !== undefined) {
            // If the name attribute contains an index, update the corresponding nested object
            const nestedIndex = parseInt(dataIndex); // Parse the index to an integer
            updatedFormData[nestedIndex] = { ...updatedFormData[nestedIndex], [name.split('.')[2]]: value };
        } else {
            // If the name attribute does not contain an index, update the main object
            updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        }
        setFormData(updatedFormData); // Update the state with the modified array
    };
    const addInput = () => {
        setFormData([...formData, { Property_Type: '', Property_Value: '', Neighborhood: '', Zip_code: '', Address: '', Number: '', Complement: '', state_of: '', City: '', Registry: '', Registration: '', Building_con: '', }]);
    };
    const removeInput = (index) => {
        formData.splice(index, 1);
        setFormData([...formData]);
        formData.forEach((item, indx) => {
            setValue(`formData.${indx}.Property_Type`, item.Property_Type);
            setValue(`formData.${indx}.Property_Value`, item.Property_Value);
            setValue(`formData.${indx}.Zip_code`, item.Zip_code);
            setValue(`formData.${indx}.Neighborhood`, item.Neighborhood);
            setValue(`formData.${indx}.Address`, item.Address);
            setValue(`formData.${indx}.Number`, item.Number);
            setValue(`formData.${indx}.Complement`, item.Complement);
            setValue(`formData.${indx}.state_of`, item.state);
            setValue(`formData.${indx}.City`, item.City);
            setValue(`formData.${indx}.Registry`, item.Registry);
            setValue(`formData.${indx}.Registration`, item.Registration);
            setValue(`formData.${indx}.Building_con`, item.Building_con);
        })
    };
    const newRepresentativeModalClose = () => {
        setNewRepresentativeModal(false);
        setHide(false)
        setHide2(true)
        setshowRep(false)
        setShow(true)
    }
    const newRepresentativeModalShow = () => {
        setNewRepresentativeModal(true);
        setShow(false)
    }
    const handleRepersen = (e: any, index) => {
        let target_name = e.target.name;
        let target_value = e.target.value;
        addState["representative"][`${index}`]["name"] = String(target_value);
        setAddState(addState)
    
      }
    return (
        <>
        <Modal show={show} onHide={formClose}>
            <Modal.Header closeButton>
                <Modal.Title>{headingText}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(formSubmit)} id="newGuarantorstsx">
                <Modal.Body>
                <input type="text" {...register('ID_SACADO_SAC')} hidden/>
                <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden/>
                    <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                        <Form.Control type="hidden" value={headingText} {...register('type')} />
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf')+ " *"}>
                            <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={` ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                            <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                    </div>

                    <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                        {showHideField == "cnpj"  ?
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.corporateReason")}>
                                        <Form.Control type="text" placeholder="Corporate reason" className={`form-control ${errors.corporateReason ? 'is-invalid' : ''}`} {...register('corporateReason')} />
                                        <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.fantasyName")+ " *"}>
                                        <Form.Control type="text" placeholder="Fantasy name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleNameof(e)} />
                                        <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.stateregistration")}>
                                        <Form.Control type="text" placeholder="State registration" className={`form-control ${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                                        <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.municipalregistration")}>
                                        <Form.Control type="text" placeholder="Municipal registration" className={`form-control ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                        <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row> :
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.name')+ " *"} className="mb-3" >
                                <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        }

                        <span className="d-block mb-3 fw-bold">{t('formLabel.contact')}</span>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} className={` ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                    <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                            <Form.Control type="email" placeholder={t('formLabel.email')} className={` ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
                            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                        <Accordion className="accordion-custom">
                            {showHideField == "cpf" ?
                                <>
                                    <Accordion.Item eventKey="1" style={{ display: hide4 ? 'none' : 'block' }}>
                                        <Accordion.Header>{t('formLabel.affiliation')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.motherName')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.motherName')}  {...register('mother')} onKeyDown={handleKeyDown} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fatherName')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.fatherName')}  {...register('father')} onKeyDown={handleKeyDown} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                        <Form.Control type="text" placeholder={t('RG')}  {...register('rg')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('issue_agency')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.shippingDate')}  {...register('shipping_date')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                        <Form.Select {...register('sex')} aria-label={t('formLabel.sex')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t('masculine')}</option>
                                                            <option value="2">{t('feminine')}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.birth')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.birth')}  {...register('birth')} />
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                                        <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} onChange={(e) => handleChangeMaritail(e)}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t("formLabel.married")}</option>
                                                            <option value="2">{t("formLabel.single")}</option>
                                                            <option value="3">{t("formLabel.widower")}</option>
                                                            <option value="4">{t("formLabel.legallySeparated")}</option>
                                                            <option value="5">{t("formLabel.disquitado")}</option>
                                                            <option value="6">{t("formLabel.divorced")}</option>
                                                            <option value="7">{t("formLabel.stableunion")}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="Birthplace" label={t('formLabel.Birthplace')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.birth')}  {...register('birth_place')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('nationality')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                <Form.Select className={`form-control ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {managerData?.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.manager?.message}</Form.Control.Feedback>
                                            </FloatingLabel>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                                : ""}
                            {
                                spouse &&
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>{t('formLabel.spouse')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('spouse_name')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cpf')}  {...register('spouse_cpf')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                    <Form.Control type="text" placeholder={t('RG')}  {...register('spouse_rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('spouse_issue_agency')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.profession')}  {...register('spouse_profession')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                    <Form.Select {...register('spouse_sex')} aria-label={t('formLabel.sex')}>
                                                        <option value="">{t('formLabel.select')}</option>
                                                        <option value="1">{t('formLabel.male')}</option>
                                                        <option value="2">{t('formLabel.female')}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cellPhone')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cellPhone')}  {...register('spouse_cellphone')} onChange={HandleInputMob}/>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('spouse_telephone')} onChange={HandleInput}/>

                                                </FloatingLabel>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="email" placeholder={t('formLabel.email')}  {...register('spouse_email')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('spouse_nationality')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.observation')}>
                                                <Form.Control type="text" placeholder={t('formLabel.observation')}  {...register('spouse_observation')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            <Accordion.Item eventKey="3" style={{ display: hide4 ? 'none' : 'block' }}>
                                <Accordion.Header>{t('formLabel.address')} </Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "first")} />
                                    </FloatingLabel>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                <Form.Control type="text" placeholder={t('formLabel.number')}  {...register('number')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('complement')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {
                                                        AllStates.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {showHideField == "cpf" ?
                                <Accordion.Item eventKey="4" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.employmentData')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.company')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.company')}  {...register('employment_company')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.profession')} {...register('employment_profession')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fieldOfActivity')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.fieldOfActivity')}  {...register('employment_field_activity')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')}  {...register('employment_monthly_income')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "second")} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                                                        <option value="">{t('formLabel.select')}</option>
                                                        {
                                                            AllStates.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                : ""}
                            <Accordion.Item eventKey="5" style={{ display: hide4 ? 'none' : 'block' }}>
                                <Accordion.Header>{t('formLabel.representative')}</Accordion.Header>
                                <Accordion.Body>
                                    {
                                        Object.keys(addState.representative).map((item: any, index: number) => {
                                            return (
                                                <Row className="mt-1">
                                                    <Col xs={11}>
                                                        <InputGroup>
                                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                                                <Form.Select {...register(`representative.${index}.name`)}  onChange={(e) => handleRepersen(e, index)}>
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    {repreData?.map((curElem: any, index) => (
                                                                        <option key={index} value={curElem.id}>{curElem.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                            <InputGroup.Text onClick={newRepresentativeModalShow}>
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                                </InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                    {
                                                        Object.keys(addState.representative) &&
                                                        Object.keys(addState.representative).length > 1 &&
                                                        <Col md={1}>
                                                            <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.representative, "representative")}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                        </Col>
                                                    }
                                                </Row>
                                            )
                                        })
                                    }
                                    < Col >
                                        <div className="text-center mt-1">
                                            <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother(e, "representative")}>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.AddAnother')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6" style={{ display: headingText !== "New Guarantor" && headingText !== "Novo Fiador" ? 'block' : 'none' }}>
                                <Accordion.Header>{t('formLabel.taxesRetention')}</Accordion.Header>
                                <Accordion.Body>
                                    <Form.Check type="checkbox" {...register('retention')} label={t('formLabel.withHoldIssqn')} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                        <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8" >
                                <Accordion.Header>{t('formLabel.Goodoftheguarantor')}</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        {formData?.map((_, index) => (
                                            <div key={index}>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel label={t('formLabel.propertyType')}>
                                                            <Form.Select className={`form-control`} {...register(`formData.${index}.Property_Type`)} onChange={(e) => handleInputChange(index, e)}>
                                                                <option value="">{t('dropdownItem.select')}..</option>
                                                                <option value="1">{t("propertyTypeData.Casa")}</option>
                                                                <option value="4">{t("propertyTypeData.Apartamento")}</option>
                                                                <option value="33">{t("propertyTypeData.ApartamentoDuplex")}</option>
                                                                <option value="38">{t("propertyTypeData.ApartamentoTriplex")}</option>
                                                                <option value="9">{t("propertyTypeData.Áreacomum")}</option>
                                                                <option value="42">{t("propertyTypeData.Andarcorporativo")}</option>
                                                                <option value="29">{t("propertyTypeData.Bangalo")}</option>
                                                                <option value="13">{t("propertyTypeData.Barracao")}</option>
                                                                <option value="40">{t("propertyTypeData.BoxGaragem")}</option>
                                                                <option value="3">{t("propertyTypeData.Casacomercial")}</option>
                                                                <option value="2">{t("propertyTypeData.Casaemcondominio")}</option>
                                                                <option value="44">{t("propertyTypeData.CasaAssobradada")}</option>
                                                                <option value="5">{t("propertyTypeData.Cobertura")}</option>
                                                                <option value="14">{t("propertyTypeData.Chacara")}</option>
                                                                <option value="25">{t("propertyTypeData.Conjunto")}</option>
                                                                <option value="39">{t("propertyTypeData.Edicula")}</option>
                                                                <option value="10">{t("propertyTypeData.Escritorio")}</option>
                                                                <option value="20">{t("propertyTypeData.Fazenda")}</option>
                                                                <option value="6">{t("propertyTypeData.Flat")}</option>
                                                                <option value="16">{t("propertyTypeData.Galpao")}</option>
                                                                <option value="8">{t("propertyTypeData.Garagem")}</option>
                                                                <option value="43">{t("propertyTypeData.Garden")}</option>
                                                                <option value="21">{t("propertyTypeData.Haras")}</option>
                                                                <option value="35">{t("propertyTypeData.Hotel")}</option>
                                                                <option value="31">{t("propertyTypeData.Ilha")}</option>
                                                                <option value="7">{t("propertyTypeData.Kitnet")}</option>
                                                                <option value="27">{t("propertyTypeData.Laje")}</option>
                                                                <option value="30">{t("propertyTypeData.Loft")}</option>
                                                                <option value="12">{t("propertyTypeData.Loja")}</option>
                                                                <option value="41">{t("propertyTypeData.Pavilhao")}</option>
                                                                <option value="32">{t("propertyTypeData.Penthouse")}</option>
                                                                <option value="22">{t("propertyTypeData.Predio")}</option>
                                                                <option value="28">{t("propertyTypeData.Pousada")}</option>
                                                                <option value="19">{t("propertyTypeData.Ponto")}</option>
                                                                <option value="18">{t("propertyTypeData.Quiosque")}</option>
                                                                <option value="36">{t("propertyTypeData.Rancho")}</option>
                                                                <option value="34">{t("propertyTypeData.Resort")}</option>
                                                                <option value="15">{t("propertyTypeData.Salao")}</option>
                                                                <option value="11">{t("propertyTypeData.Salacomercial")}</option>
                                                                <option value="23">{t("propertyTypeData.Sitio")}</option>
                                                                <option value="24">{t("propertyTypeData.Sobrado")}</option>
                                                                <option value="37">{t("propertyTypeData.Studio")}</option>
                                                                <option value="17">{t("propertyTypeData.Terreno")}</option>
                                                                <option value="26">{t("propertyTypeData.Village")}</option>
                                                                <option value="45">{t("propertyTypeData.Consultorio")}</option>
                                                                <option value="1000">{t("propertyTypeData.Outro")}</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="propertyValue" label={t('newContractDev.propertyValue')}>
                                                            <Form.Control type="text" placeholder={t('newContractDev.propertyValue')} {...register(`formData.${index}.Property_Value`)} onChange={(e) => handleInputChange(index, e)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="zipCode2" label={t('formLabel.zipCode')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register(`formData.${index}.Zip_code`)} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "third")} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="address2" label={t('formLabel.address')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.address')}  {...register(`formData.${index}.Address`)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="number2" label={t('formLabel.number')}>
                                                            <Form.Control type="number" placeholder={t('formLabel.number')}  {...register(`formData.${index}.Number`)} onChange={(e) => handleInputChange(index, e)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="complement2" label={t('formLabel.complement')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register(`formData.${index}.Complement`)} onChange={(e) => handleInputChange(index, e)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="neighborhood3" label={t('formLabel.neighborhood')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register(`formData.${index}.Neighborhood`)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="city2" label={t('formLabel.city')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.city')}  {...register(`formData.${index}.City`)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="state12" label={t('formLabel.state')}>
                                                            <Form.Select {...register(`formData.${index}.state_of`)} aria-label={t('formLabel.state')}>
                                                                <option value="">{t('formLabel.select')}</option>
                                                                {
                                                                    AllStates.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="registry" label={t('formLabel.registry')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.registry')}  {...register(`formData.${index}.Registry`)} onChange={(e) => handleInputChange(index, e)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="registration" label={t('formLabel.registration')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.registration')}  {...register(`formData.${index}.Registration`)} onChange={(e) => handleInputChange(index, e)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="buildingCondominium" label={t('formLabel.buildingCondominium')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.buildingCondominium')}  {...register(`formData.${index}.Building_con`)} onChange={(e) => handleInputChange(index, e)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                {(formData.length > 1) ?
                                                    <Button variant="link" className="p-0 fw-bold" onClick={() => removeInput(index)}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                    : ""}
                                            </div>
                                        ))}
                                    </div>
                                    <Button variant="link" className="p-0 fw-bold" onClick={addInput}>{t("Add Another")}</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Row>
                                <Col xs={4}>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.balanceblocked')}>
                                        <Form.Control type="number" placeholder={t('formLabel.balanceblocked')}  {...register('block_balance')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Accordion>
                    </div>
                    <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                        <div className="text-center">
                            <h3 className="mt-3 mb-2">{t('label.registeredSurveyor')}</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-sm-flex justify-content-end w-100 align-items-center">
                        <Button variant="outline-primary" onClick={formClose}>
                            {t('button.close')}
                        </Button>
                        {hide ? '' : <Button variant="outline-primary" onClick={(e) => handlePrevious(e)} className="js-btn-step me-2 me-lg-3" >
                                {t('button.previous')}
                            </Button>}
                        {/* <Button variant="outline-primary" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)} className="js-btn-step">
                            {t('button.previous')}
                        </Button> */}
                        {!hide3 ?
                            <Button variant="primary" onClick={handleSubmit(hide ? step2 : step3)} className="min-w-auto js-btn-step zipCodeCheck btn-primary-focus-none" >
                                {t('button.next')}
                            </Button>
                            :
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <Button variant="secondary" type="submit" className='btn-primary-focus-none'>
                                    {t('button.save')}
                                </Button>
                        }
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
            <RepresentativeService showNewRepresentative={showNewRepresentativeModal} newRepresentativeClose={newRepresentativeModalClose} newRepresentativeShow={newRepresentativeModalShow} />
            </>
    )
}

export default NewGuarantors