import { Accordion, FloatingLabel, Modal, Form, Button } from 'react-bootstrap';
import React from 'react';
import * as yup from 'yup';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

interface LowerLiquid {
    settle_due_date: boolean,
    settle_into: boolean
}

const schema = yup.object().shape({
    settle_due_date: yup.string().required("Settle on the due date is required"),
    settle_into: yup.string().required("Settle into the same bank account that the expense was posted is required")
})

const LowerLiquid = (props) => {
    const [t] = useTranslation();
    const { showlowerLiquidate, lowerliquidateCloses, lowerLiquidateShows } = props;

    const { register, handleSubmit, formState: { errors } } = useForm<LowerLiquid>({
        resolver: yupResolver(schema)
    })

    const onSubmit = (data: LowerLiquid) => { 
    }
    return (

        <Modal show={showlowerLiquidate} onHide={lowerliquidateCloses}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("expenditure.liquidate")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3 mb-lg-4">
                        <Form.Check type="checkbox" label={t("expenditure.settleontheduedate")} className={errors.settle_due_date ? "is-invalid" : ""} {...register("settle_due_date")} />
                        <Form.Control.Feedback type="invalid">{errors.settle_due_date?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Check type="checkbox" label={t("paidTobepaid.Settleintothesamebankaccountthat")} className={errors.settle_into ? "is-invalid" : ""} {...register("settle_into")} />
                        <Form.Control.Feedback type="invalid">{errors.settle_into?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={lowerliquidateCloses}>
                    {t("button.close")}
                    </Button>
                    <Button variant="primary" type='submit'>
                        {t("expenditure.liquidate")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default LowerLiquid
