import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../redux/store';
import customerService from '../../../../../services/customerService';
import { listTemplatesInsideFolder } from '../../../../../redux/slice/SendEmailSlice';
import Swal from 'sweetalert2';


const RenameTemplate = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()

    //Rename template modal close open function
    const { showRenameTemplate, renameTemplateClose, currentFileId, currentFileName, currentFolderId } = props;

    const [fileNameInput, setFileNameInput] = useState<any>();

    useEffect(() => {
        setFileNameInput(currentFileName)
    }, [currentFileName]);


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        //console.log("Folder Name:", fileNameInput);
        let data = {
            template_id: currentFileId,
            template_name: fileNameInput
        }
        if (data.template_name !== "" && data.template_name !== null) {
            const response = await customerService.renameDocumentTemplate(data);
            if (response.data.resCode == 200 || response.data.resCode == 201) {
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: "Template Renamed",
                    confirmButtonText: "OK",
                    timer: 2000
                })
                await dispatch(listTemplatesInsideFolder(currentFolderId));


                renameTemplateClose();

            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: "There is some issue in renaming the template",
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Template Name Cannot Be Empty",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }

    };

    return (
        <>
            <Modal show={showRenameTemplate} onHide={renameTemplateClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.enterTemplateName")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="template_name" label={t("DocumentTemplate.templateName")}>
                            <Form.Control type="text" placeholder={t("DocumentTemplate.templateName")} value={fileNameInput} onChange={(e) => setFileNameInput(e.target.value)} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={renameTemplateClose}>
                            {t("button.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {t("button.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default RenameTemplate
