import { useGoogleLogin } from "@react-oauth/google";
import CryptoJS from "crypto-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { config, secretKey } from "../../services/apiConfig";
import { userService } from "../../services/userService";
import * as S from "./Impersonate.styles";
export const Impersonate = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!username) {
      setError("Preencha todos os campos");
      return;
    }
    setError("");
    login();
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoading(true);
      fetch(`${config.companyV2_URL}/impersonate/login/:email`, {
        method: "POST",
        headers: {
          Authorization: `${tokenResponse.access_token}`,
          Accept: "application/json",
          email: username,
        },
      })
        .then((response) => response.json())
        .then(async (response) => {
          localStorage.setItem("userData", JSON.stringify(response.data));
          localStorage.setItem("tokenV2", response.data.accessToken);
          localStorage.setItem(
            "userData",
            JSON.stringify({ auth_token: response.data.accessToken })
          );

          let result = await userService.userPermission({ email: username });
          let permissionEncrypt = result?.data?.permissionId;
          localStorage.setItem("agencyId", result?.data?.agencyId);
          localStorage.setItem("agencyInitials", result?.data?.agencyInitials);
          localStorage.setItem("userEmailId", result?.data?.userEmailId);
          localStorage.setItem(
            "role",
            result?.data?.role == "agency" ? "1" : "2"
          );
          let stringifyPermissionData = JSON.stringify(permissionEncrypt);
          const encryptedString = CryptoJS.AES.encrypt(
            stringifyPermissionData,
            secretKey
          ).toString();
          localStorage.setItem("permissionData", encryptedString);

          navigate("/dashboard");
        })
        .catch(() => {
          setError("Usuário ou ID não encontrado");
          setLoading(false);
        });
    },
  });

  return (
    <S.ContainerImpersonate>
      <h1>Impersonate</h1>
      <span>
        Para acessar a conta de um usuário, preencha o email do usuário
      </span>

      <S.FormContainer className="form-container " onSubmit={handleSubmit}>
        <input
          type="email"
          autoComplete="email"
          name="email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="E-mail do usuário"
        />
        <S.LoginButton disabled={loading} type="submit">
          {loading ? "Aguarde..." : "Login"}
        </S.LoginButton>
      </S.FormContainer>
      {error && <span className="error">{error}</span>}
    </S.ContainerImpersonate>
  );
};
