import React from "react";

import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { BancoDoBrasilFields } from "./components/BanksFields/BancoDoBrasilFields";
import { BradescoFields } from "./components/BanksFields/BradescoFields";
import { C6Fields } from "./components/BanksFields/C6Fields";
import { CaixaEconomicaFields } from "./components/BanksFields/CaixaEconomicaFields";
import { InterFields } from "./components/BanksFields/InterFields";
import { ItauFields } from "./components/BanksFields/ItauFields";
import { SafraFields } from "./components/BanksFields/SafraFields";
import { SantanderFields } from "./components/BanksFields/SantanderFields";
import { SicoobFields } from "./components/BanksFields/SicoobFields";
import { SicrediFields } from "./components/BanksFields/SicrediFields";
import { DefaultFields } from "./components/DefaultFields/DefaultFields";
import { EnabledBanksToHomologate } from "./types";
import { useBankAccountForm } from "./useBankAccountForm";

export function BankAccountForm() {
  const { onSubmit, selectedBank, showHomologateFields } = useBankAccountForm();
  const { handleSubmit } = useFormContext();

  const bankToHomologateFields = {
    [EnabledBanksToHomologate.BancoDoBrasil]: <BancoDoBrasilFields />,
    [EnabledBanksToHomologate.Santander]: <SantanderFields />,
    [EnabledBanksToHomologate.Inter]: <InterFields />,
    [EnabledBanksToHomologate.CaixaEconomica]: <CaixaEconomicaFields />,
    [EnabledBanksToHomologate.Bradesco]: <BradescoFields />,
    [EnabledBanksToHomologate.C6]: <C6Fields />,
    [EnabledBanksToHomologate.Itau]: <ItauFields />,
    [EnabledBanksToHomologate.Safra]: <SafraFields />,
    [EnabledBanksToHomologate.Sicredi]: <SicrediFields />,
    [EnabledBanksToHomologate.Sicoob]: <SicoobFields />,
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="bankAccountForm">
      <DefaultFields />

      {showHomologateFields && bankToHomologateFields[selectedBank]}
    </Form>
  );
}
