import React, { useState, useEffect, useRef } from 'react';
import { FloatingLabel, InputGroup, Modal, Form, Button, Col, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { newRetention } from '../../../Interface';
import { useDispatch, useSelector } from 'react-redux';
import { convertToNumber, formatNumber } from '../../../constants/utils';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getTax, inserttax } from '../../../../redux/slice/financialDataSlice';
import { getAllSuppliers } from '../../../../redux/slice/contractDataSlice';
const NewRetention = (props: any) => {
    let { showNewRetentionModal, newRetentionModalClose, accountCatData1 } = props
    const { suppliersData } = useSelector((state: RootState) => state.contractData)
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const [valueCon, setValueCon] = useState('')
    const inputRef = useRef<any>(null);
    const [searchResults, setSearchResults] = useState<any>(accountCatData1);
    const [isInputFocused, setIsInputFocused] = useState(false);

    /*------------Form validation-----------*/
    const expenseValidation = Yup.object().shape({
        tax: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        code: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        aliquot: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        collectingAgency: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });

    let frm = {
        resolver: yupResolver(expenseValidation)
    }
    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<newRetention>(frm);
    useEffect(() => {
        if (showNewRetentionModal) {
            dispatch(getAllSuppliers());
        }
    }, [showNewRetentionModal])

    const AddAmount = (e: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if (value !== "") {
            setValue("aliquot", formatNumber(value))
            setValueCon(value)
            clearErrors("aliquot");
        } else {
            setError("aliquot", {
                type: "manual",
                message: `${t("requiredMmessage.fieldRequired")}`,
            });
        }
    }

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
        setSearchResults(accountCatData1);
    };
    const handlefilterBillmethod = async (e) => {
        const query = e.target.value.toLowerCase();
        const filtered = accountCatData1.filter((item) => {
            const description = item.ST_DESCRICAO_CONT.toLowerCase();
            const code = item.ST_CONTA_CONT.toLowerCase();
            return description.includes(query) || code.includes(query);
        });
        setSearchResults(filtered);
    }
    const HandleBankAccount = (e: any, val1, val2,) => {
        setValue('acountCategory', val1 + " - " + val2)
        setIsInputFocused(false)
    }

    const onSubmit = async (data: any) => {
        let aliquots: any = convertToNumber(valueCon);
        data["aliquot"] = aliquots;
        const accountCategoryCode = data?.acountCategory.split(' - ')[0];
        data["acountCategory"] = accountCategoryCode;
        let respo = await dispatch(inserttax(data))
        if (respo.payload.resCode == "202") {
            Swal.fire({
                // icon: 'success', 
                title: t("Sweetalert.Success"),
                text: respo.payload.message,
                confirmButtonText: "OK", timer: 2000
            })
            // Reset form fields after success
            reset();
            setValueCon(''); // Optional: reset the aliquot value
            newRetentionModalClose()
            dispatch(getTax({ "tax": "", "start": 0, "end": 1000 }));
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: respo.payload.message,
                //icon: "error", 
                confirmButtonText: "OK",
                timer: 2000
            })
        }
        console.log(respo)

    }
    return (
        <Modal show={showNewRetentionModal} onHide={newRetentionModalClose}>
            <Form onSubmit={handleSubmit(onSubmit)} className="NewRetention">
                <Modal.Header closeButton>
                    <Modal.Title>{t("bankTransaction.Newretention")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="tax" className="mb-3 mb-lg-4" label={t("contractlistother.Tax") + "*"}>
                        <Form.Control type="text" className={`form-control ${errors.tax ? 'is-invalid' : ''}`} placeholder={t("contractlistother.Tax")}  {...register("tax")} />
                        <Form.Control.Feedback type="invalid">{errors.tax?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="code" className="mb-3 mb-lg-4" label={t("contractlistother.Code") + "*"}>
                        <Form.Control type="text" className={`form-control ${errors.code ? 'is-invalid' : ''}`} placeholder={t("contractlistother.Code")} {...register("code")} />
                        <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                    <Col md={12} >
                        <InputGroup>
                            <Form.Floating ref={inputRef} >
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>{t("searchCategoriesAccount")}</Tooltip>}
                                >
                                    <Form.Control
                                        type="text"
                                        className="mb-2"
                                        placeholder="Type to search..."
                                        {...register("acountCategory")}
                                        onChange={(e) => handlefilterBillmethod(e)}
                                        onFocus={handleInputFocus}
                                        autoComplete="off"
                                    />
                                </OverlayTrigger>
                                {isInputFocused && (
                                    <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }} >
                                        {searchResults?.slice(0, 5).map((item: any, index) => (
                                            <ListGroup.Item className="px-3 cursor-pe" key={index} disabled={item.ST_CONTA_CONT.length === 1} onClick={(e) => HandleBankAccount(e, item.ST_CONTA_CONT, item.ST_DESCRICAO_CONT)}>
                                                {item.ST_CONTA_CONT}  {item.ST_DESCRICAO_CONT}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                                <Form.Label htmlFor="floatingInputValue">{t("formLabel.accountCategory")}</Form.Label >
                            </Form.Floating>
                        </InputGroup>
                    </Col>
                    {/* <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                        <Form.Select aria-label="Account (category)" {...register("acountCategory")}>
                            <option value="">{t("TransferPage.Tocarryout")}</option>
                            <option value="">{t("TransferPage.Performed")}</option>
                        </Form.Select>
                    </FloatingLabel> */}
                    <FloatingLabel controlId="aliquot" className="mb-3 mb-lg-4" label={t("contractsettings.Aliquot") + "(%)" + "*"}>
                        <Form.Control className={`form-control ${errors.aliquot ? 'is-invalid' : ''}`} type="text" placeholder={t("contractsettings.Aliquot") + "(%)"}  {...register("aliquot")} onChange={(e) => AddAmount(e)} />
                        <Form.Control.Feedback type="invalid">{errors.aliquot?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                    <InputGroup className="mb-3 mb-lg-4">
                        <FloatingLabel label={t("bankTransaction.collectionagency")} >
                            <Form.Select aria-label="collection agency" className={`form-control ${errors.collectingAgency ? 'is-invalid' : ''}`} {...register("collectingAgency")}>
                                <option value="">{t("formLabel.select")}</option>
                                {suppliersData && suppliersData.length > 0 && suppliersData.map((item: any, index: number) => (
                                    <option key={index} value={item.favorecidoId}>{item.name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.collectingAgency?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <InputGroup.Text title={t("formLabel.supplier")}>
                            {/* <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20"  onClick={newSupplierModalShow}/> */}
                        </InputGroup.Text>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-sm-flex justify-content-end w-100 align-items-center">
                        <Button variant="outline-primary" onClick={newRetentionModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t("button.save")}
                        </Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NewRetention