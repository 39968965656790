import React from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const TermofService = () => {
    return (
        <>
            <header className="main-header bg-white fixed-top">
                <Navbar expand="xl" bg="white" className="h-100">
                    <Container fluid className="px-xl-5 px-md-4 px-3">
                        <Navbar.Toggle
                            className="position-absolute top-0 start-0 p-0 mt-3 ms-2 ms-md-4 border-0"
                            aria-controls="headerNav"
                        ></Navbar.Toggle>
                        <Link className="navbar-brand ps-5 ps-xl-0 me-2" to={"/dashboard"}>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
                                className="header-logo"
                                alt="Logo"
                            />
                        </Link>

                    </Container >
                </Navbar >
            </header >
            <div className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3 mt-5">
                <div className="privacy-section mt-5">
                    <Row>
                        <Col xl={10}>
                            <h3 className="font-weight-bold text-primary">Terms of service</h3>
                            <h5 className="text-muted">lorem ipsum</h5>

                            <div className="content-section my-4">
                                <p>
                                    Ensuring your  has a privacy policy page protects your business from breaking the law and builds trust.
                                </p>
                                <p>
                                    At the time of this article, there are no federal laws mandating the use of a website privacy policy. However, entities that collect personal information could find themselves adhering to state laws aimed at protecting consumer privacy and consumer rights laws enabled by the Federal Trade Commission (FTC), which regulates consumer data protection in the United States.
                                </p>
                                <p>
                                    One only needs to consult their internet search engine to realize the costliness of privacy disputes. Whether the company is in the right or not, the expense of litigation is reason enough to take preemptive measures in privacy matters. Any website collecting personal data to identify an individual must provide a privacy policy as international laws require.
                                </p>
                                <p>
                                    Many third-party sites, such as commercial selling platforms and others, must have a privacy policy, which protects their third-party interest. Protective measures like privacy policies build goodwill with clients and ultimately attract more business, leading to greater profits and income. In general, keeping a website privacy policy is an excellent idea for remaining compliant with various laws and rules.
                                </p>

                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vulputate purus id odio fringilla, at suscipit ligula fermentum. Mauris dapibus malesuada metus, nec ullamcorper odio lacinia eget.
                                </p>
                                <p>
                                    Suspendisse potenti. Phasellus dictum dui nec justo hendrerit, et vulputate nisi eleifend. Duis varius turpis eu libero fermentum vestibulum.
                                </p>
                                <p>
                                    Nunc nec arcu et purus interdum sollicitudin. Donec egestas lacinia malesuada. Curabitur euismod, erat et dignissim consectetur, augue erat bibendum eros, at efficitur dui libero at quam.
                                </p>
                                <p>
                                    Aliquam erat volutpat. In ac vestibulum turpis. Nam sit amet libero sit amet odio hendrerit cursus at et nunc.
                                </p>
                                <p>
                                    Cras maximus ex eu dolor feugiat, sit amet commodo erat luctus. Praesent vulputate lorem vitae sapien varius, ut vestibulum leo vehicula.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Link to={'/dashboard'}> <button type="button" className="btn btn-outline-primary">Back</button></Link>
                </div>
            </div>
        </>
    )
}

export default TermofService