import React, { useState } from "react"
import { Button, ButtonGroup, Col, Dropdown, FloatingLabel, Form, Row } from "react-bootstrap"
import { MultiSelect } from "react-multi-select-component"

import { Icon } from "../../../../components/Icon"
import { useDimob } from "../../DimobProvider"
import { FilterParams } from "../../types"
import { CURRENT_DIMOB_YEAR } from "../../utils"
import { useFilters } from "./useFilters"

export function Filters() {
  const { ownerOptions, statusOptions } = useFilters()
  const [selectedOwners, setSelectedOwners] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const { updateFilters } = useDimob()
  const [filters, setFilters] = useState<FilterParams>({} as FilterParams)
  const [selectedYear, setSelectedYear] = useState(CURRENT_DIMOB_YEAR)

  function handleChange(evt: React.ChangeEvent<HTMLSelectElement>) {
    const name = evt.target.name;
    const value = evt.target.value;
    setFilters((current: any) => ({ ...current, [name]: value }))
    updateFilters({
      ...filters,
      [name]: value,
    })
  }

  function handleSelectYear(year: number) {
    if (year > new Date().getFullYear()) {
      return
    }

    setSelectedYear(year)
    updateFilters({
      ...filters,
      dimobYear: year,
    })
  }

  return (
    <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
      <Col xl="auto">
        <ButtonGroup
          className="btn-date-filter"
          size="sm"
        >
          <Button
            variant="primary"
            className="btn-arrow"
            onClick={() => handleSelectYear(selectedYear - 1)}
          >
            <Icon name="arrowLeft" />
          </Button>
          <span className="px-1 mx-2" style={{ color: "#808080" }}>
            {selectedYear}
          </span>
          <Button
            variant="primary"
            className="btn-arrow"
            onClick={() => handleSelectYear(selectedYear + 1)}
            disabled={new Date().getFullYear() === selectedYear}
          >
            <Icon name="arrowRight" />
          </Button>
        </ButtonGroup>
      </Col>
      <Col xl="auto">
        <Dropdown autoClose="outside">
          <Dropdown.Toggle variant="light" size="sm" className={`${selectedOwners?.length > 0 ? "show" : ""}`}>
            Proprietário
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdwon-lg px-4">

            <MultiSelect
              value={selectedOwners}
              options={ownerOptions}
              onChange={(selected: any) => {
                setSelectedOwners(selected)
                updateFilters({
                  ...filters,
                  owners: selected?.map((owner: any) => owner.value)
                })
              }}
              labelledBy="Proprietários"
              overrideStrings={{
                selectSomeItems: "Selecionar itens",
                selectAll: "Selecionar todos",
                search: "Procurar",
                allItemsAreSelected: "Todos selecionados",
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xl="auto">
        <Dropdown autoClose="outside">
          <Dropdown.Toggle variant="light" size="sm" className={`${selectedStatus?.length > 0 ? "show" : ""}`}>
            Status
          </Dropdown.Toggle>
          <Dropdown.Menu className="px-4">

            <MultiSelect
              value={selectedStatus}
              options={statusOptions}
              onChange={(selected: any) => {
                setSelectedStatus(selected)
                updateFilters({
                  ...filters,
                  status: selected?.map((status: any) => status.value)
                })
              }}
              labelledBy="Status"
              overrideStrings={{
                selectSomeItems: "Selecionar itens",
                selectAll: "Selecionar todos",
                search: "Procurar",
                allItemsAreSelected: "Todos selecionados",
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xl="auto">
        <Dropdown autoClose="outside">
          <Dropdown.Toggle variant="light" size="sm" >
            {filters?.incomeTaxStatus ? "Apenas contratos que Retém IR" : "Retido"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="px-4">
            <FloatingLabel label="Retido">
              <Form.Select
                name="incomeTaxStatus"
                onChange={handleChange}
                aria-label="Retido"
              >
                <option value="">Todos</option>
                <option value={["0", "1", "2"]}>Apenas contratos que Retém IR</option>
              </Form.Select>
            </FloatingLabel>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  )
}
