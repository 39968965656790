import React, { useEffect, useState } from 'react';
import { add_Bank, Main_Account, Newbankaccount } from '../../../../redux/slice/bankSlice';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addNewBank, handleOpnBank, innerbank } from "../../../../redux/slice/bankSlice";
import { AppDispatch, RootState } from '../../../../redux/store';
import { Modal, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// used it to show validation error on form
const Schema = yup.object().shape({
    bankname: yup.string().required('Bank name is required'),
})

const AddNewBank = () => {
    // Define form validation and submission using react-hook-form
    const [loader_on, setloader_on] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm<Newbankaccount>({
        resolver: yupResolver(Schema)
    });
    // This line initializes the useDispatch hook and assigns it to the dispatch variable
    const dispatch = useDispatch<AppDispatch>();
    //A usestate has been created to change the language.
    const [t] = useTranslation()
    // Used Redux-Toolkit, Got the data and Destructors the data from bankslice inside the redux folder
    const { newbanklist, show2 } = useSelector((state: RootState) => state.bank);

    //Getting the data shown when the page is rendered for the first time --listing bank
    useEffect(() => {
        dispatch(add_Bank())
    }, [dispatch, show2])

    // Used to add bank accounts
    const onSubmit = (data: any, e: any) => {
        newbanklist.forEach(item => {
            if (data.bankname == item.bank_code) {
                data["item"] = item;
            }
        })
        dispatch(Main_Account())
        dispatch(addNewBank(data.item))
        dispatch(handleOpnBank(true))
        dispatch(innerbank(false))
    }
    // Pop up hide of add bank account on click
    const handleOpen = () => {
        dispatch(handleOpnBank(true))
        dispatch(innerbank(false))
    }

    return (
        <>
            {/* <!-- New bank account modal start here --> */}
            <Modal show={show2} onHide={handleOpen}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("banks.newBank")}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body className='py-1'>
                        {/* Basic info for account add start from here */}
                        <div className={`input-group`} >
                            <FloatingLabel label={t("banks.name")}>
                                <Form.Select className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"  {...register("bankname")}>
                                    <option value="">{t("formLabel.select")}</option>
                                    {newbanklist && newbanklist.length > 0 &&
                                        newbanklist.map((curElem, index) => (
                                            <option key={index} value={curElem.bank_code}>{curElem.bank_name}</option>
                                        ))}
                                </Form.Select >
                                <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </div>
                        {/* /Basic info for account add end */}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                     <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary">{t(("button.close"))}</Button>
                                    <Button variant="primary" type="submit" id="addBank">{t("button.save")}</Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /New bank account modal end */}
        </>
    )
}

export default AddNewBank
