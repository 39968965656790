import React from 'react';
import { Offcanvas, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const MoreFilters = (props) => {
    const [t] = useTranslation();
    //More filters template modal close open function
    const { showMoreFilters, moreFiltersClose, handleFilter } = props;
    const handleClose = () => {
        moreFiltersClose()
        handleFilter("", "", "reset");
    }
    return (
        <Offcanvas className="border-sm-none" show={showMoreFilters} onHide={moreFiltersClose} placement="end">
            <Form className="d-flex flex-column h-100">
                <Offcanvas.Header className="border-bottom" closeButton>
                    {t("moreFilters")}
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <FloatingLabel label={t("NonPayment.communicationChannel")} className="mb-3">
                        <Form.Select aria-label="communication_channel" onChange={(e) => handleFilter(e, e.target.value, "channel")}>
                            <option value="">{t("formLabel.all")}</option>
                            <option value="1">{t("NonPayment.messagingApp")}</option>
                            <option value="2">{t("NonPayment.letter")}</option>
                            <option value="3">{t("formLabel.email")}</option>
                            <option value="4">SMS</option>
                            <option value="5">{t("NonPayment.phone")}</option>
                        </Form.Select>
                    </FloatingLabel>
                    {/* <FloatingLabel label={t("NonPayment.manualOrAutomaticAction")} className="mb-3">
                        <Form.Select aria-label="manual_or_automatic_action" onChange={(e) => handleFilter(e, e.target.value, "action")}>
                            <option value="1">{t("NonPayment.manualOrAutomaticAction")}</option>
                            <option value="2">{t("NonPayment.manualActionOnly")}</option>
                            <option value="3">{t("NonPayment.automaticActionOnly")}</option>
                        </Form.Select>
                    </FloatingLabel> */}
                    <FloatingLabel label={t("NonPayment.reasonForDelinquency")} className="mb-3">
                        <Form.Select aria-label="reason_for_delinquency" onChange={(e) => handleFilter(e, e.target.value, "reason")}>
                            <option value="1">{t("terminatelist.Financial_problems")}</option>
                            <option value="2">{t("NonPayment.didNotReceivInvoice")}</option>
                            <option value="3">{t("NonPayment.disagrees")}</option>
                            <option value="4">{t("NonPayment.other")}</option>
                        </Form.Select>
                    </FloatingLabel>
                </Offcanvas.Body>
                <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                    <Button variant="outline-primary" onClick={handleClose}>{t("button.resetfilter")}</Button>
                    {/* <Button variant="primary" disabled onClick={moreFiltersClose}>{t("button.apply")}</Button> */}
                </div>
            </Form>
        </Offcanvas>
    )
}

export default MoreFilters
