import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { set, useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal, Form, Button, Row, Col, FloatingLabel, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { openEmailPop } from '../../../../redux/slice/contractDataSlice';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { emailPost, mailData } from '../../../../redux/slice/SendEmailSlice';
import { openErrorbox } from './actionPerform'
import customerService from '../../../../services/customerService';
import { getComapnyLogo } from '../../../../redux/slice/companyDataSlice';
import { config } from '../../../../services/apiConfig';
import { isDataView } from 'util/types';
const SendDocumentEmail = (props) => {
    let imgtype = '';
    let emailCollection = props.mailData;
    let documentType = props.type;
    let templateDownloadData = props.template;
    // const contract_id = props.contract_id;
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { emailShow } = useSelector((state: RootState) => state.contractData)
    const { getlogo, status } = useSelector((state: RootState) => state.companyData);
    const mailClose = () => {
        dispatch(openEmailPop(false));
        reset();
    }
    const [templateData, setTemplateData] = useState<any>([]);
    const [variableData, setVariableData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [double, setDouble] = useState(false);
    const [logoPath, setLogoPath] = useState<any>("/logos/logo.png");
    const mailValidationSchema = Yup.object().shape({
        identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // model: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // variable: Yup.string().trim()
        //     .required(t('requiredMmessage.fieldRequired'))

    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<mailData>(frm);

    let [desc, setDesc] = useState<any>('');

    
    useEffect(() =>{
        setDesc(templateDownloadData)
    },[templateDownloadData])
    const mailSubmit = async (data: mailData) => {
        if (desc !== "") {
            let allEmails = emailCollection[0];
            let template = `<p>${t("DownloadableFileLinkBelow")}</p>`;
            if (desc && desc !== "") {
                template = desc;
            }
            if (data.identifier == "1" && emailCollection[0].length>0) {
                allEmails = emailCollection[0]
            } else if (data.identifier == "2" && emailCollection[1].length>0) {
                allEmails = emailCollection[1]
            } else if (data.identifier == "3" && emailCollection[2].length>0) {
                allEmails = emailCollection[2]
            } else {
                allEmails = emailCollection[3]
            }
            setLoading(true);
            let emaildata: any = {
                status: "new",
                subject: `${data.title}` ? `${data.title}` :  "Email vindo do POSTMAN",
                content: template,
                layout: "default",
                sender: "noreply@dev.kenlo.io",
                to: allEmails,
                "cc": [
                    "vikash@wegile.com"
                ],
                "bcc": [
                    "karan@wegile.com"
                ],
                identifier: "identifier",
                model: 1,
                caption: "caption",
                title: "Document File Attachment",
            }
            let res = await dispatch(emailPost(emaildata))
            if (res.payload.resCode == 201) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.mailsent"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setLoading(false);
                dispatch(openEmailPop(false))
                reset()
                setValue("title", ``)
                setDesc(``)
                setValue("description", "");
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setLoading(false);
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.emailsMessage"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
            setLoading(false);
        }

    } 
    useEffect(() => {
        // Trigger the 'getComapnyLogo' action when the component mounts or when 'imgtype' changes
        dispatch(getComapnyLogo())
    }, [dispatch, imgtype,])
    let type = getlogo?.logo_name
    let gettype = type?.split(/[#?]/)[0].split('.').pop().trim()
    // Split the first part by '.' and retrieve the last part (logo file extension)
    imgtype = gettype;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await customerService.getTemplates();
                const result = response.data.data[0]
                const variableResult = response.data.data[1];
                setTemplateData(result);
                setVariableData(variableResult);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        setValue("title", ``)
        setValue("description", templateDownloadData);
    }, []); 
    return (
        <>
            <Modal show={emailShow} className='Owners_and_beneficiariestsx' onHide={mailClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Senddocument")}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(mailSubmit)}>
                    <Modal.Body>
                        <Row id="CurrentPage">
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.identifier')}>
                                    <Form.Select className={` ${errors.identifier ? 'is-invalid' : ''}`} {...register('identifier')}>
                                        {documentType === "contract" ?
                                            <>
                                                <option value="1">{t("customerAndEmployees.tenants") + " " + t("customerAndEmployees.and") + " " + t("customerAndEmployees.owners")}</option>
                                                <option value="2">{t("customerAndEmployees.owners")}</option>
                                                <option value="3">{t("customerAndEmployees.tenants")}</option>
                                            </>
                                            :
                                            documentType === "owner" ?
                                                <option value="2">{t("customerAndEmployees.owners")}</option>
                                                :
                                                documentType === "tenant" ?
                                                    <option value="3" selected>{t("customerAndEmployees.tenants")}</option>
                                                    :
                                                    documentType === "guarantor" ?
                                                        <option value="5" selected>{t("customerAndEmployees.guarantors")}</option>
                                                        :
                                                        documentType === "broker" ?
                                                            <option value="6" selected>{t("customerAndEmployees.brokers")}</option>
                                                            :
                                                            documentType === "supplier" ?
                                                                <option value="7" selected>{t("customerAndEmployees.suppliers")}</option>
                                                                :
                                                                <option value="2">{t("customerAndEmployees.owners")}</option>
                                        }

                                        {/* Add more options as needed */}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.identifier?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col> 
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.title')}>
                                    <Form.Control type="text" placeholder={t('formLabel.title')} className={` ${errors.title ? 'is-invalid' : ''}`}  {...register('title')} />
                                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col> 
                        </Row>
                        <Row>
                            <Col md={12}>
                                {/* Add error handling for CKEditor input field */}
                                {/* Use description state to track the CKEditor content */}
                                <Form.Control type="hidden" placeholder="description" {...register('description')} />
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={desc}
                                    onReady={editor => {
                                        const data = editor.getData();
                                        setDesc(data);
                                        // Initialize description state with initial data
                                        setValue("description", data);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data);
                                        // Update description state as CKEditor content changes
                                        setValue("description", data);
                                    }}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data);
                                        // Update description state as CKEditor content changes
                                        setValue("description", data);
                                    }}
                                    onFocus={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data);
                                        // Update description state as CKEditor content changes
                                        setValue("description", data);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={mailClose}>
                            {t("button.close")}
                        </Button>
                        {loading ? (
                            <Button disabled={true}>
                                {t("button.submitting")}
                            </Button>

                        ) : (
                            // Your existing Modal content
                            <Button disabled={double} type="submit" variant="primary" onClick={openErrorbox}>
                                {t("button.toSave")}
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default SendDocumentEmail