import React, { useState } from 'react';
import { Modal, Form, Button, Dropdown, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ElectronicEnvelopeService from '../../../Contracts/Common/ElectronicEnvelopeService';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../redux/store';
import { newElectronicEnvelopeOpen } from '../../../../../redux/slice/contractDataSlice';
import Swal from 'sweetalert2';
import { nonPayment_send_charge } from '../../../../../redux/slice/NonPaymentSlice';

const AgreementSuccessfully = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    //Agreement successfully modal close open function
    const { showAgreementSuccessfully, agreementSuccessfullysClose, currentContract, respCharge } = props;
    //Common toast
    const [showToast, setToastShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const sentEmailToastClose = () => setToastShow(false);
    const sentEmailToastShow = async () => {
        setLoading(true);
        let bodydata = {
            subject: `New Duplicate Charge`,
            content: currentContract?.["GC_BOLETO_URL"] ?? "Empty string",
            sender: "noreply@dev.kenlo.io",
            to: [currentContract["ST_EMAIL_SAC"]],
            title: `Charge Regenerated`,
            status: "charge",
            respCharge: respCharge
            // identifier: "",
            // model: "",
            // layout: "",
        };
        let sendData = await dispatch(nonPayment_send_charge(bodydata));
        if (sendData && sendData?.["payload"]?.["type"] == "success") {
            setToastShow(true);
            setLoading(false);
            agreementSuccessfullysClose(false);
            setTimeout(() => {
                setToastShow(false);
            }, 2000);
        } else {
            setLoading(false);
            Swal.fire({
                //// icon: 'success',"error",
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.successfailed"),
                confirmButtonText: "OK",
            })
        }
    }

    const handleElectronicEnvelope = () => {
        if (currentContract.ID_CONTRATO_CON && currentContract.ID_CONTRATO_CON !== "") {
            dispatch(newElectronicEnvelopeOpen(true));
        } else {
            console.log("Cannot Send Electronic Envelope Without Contract Link");
        }
    }

    return (
        <>
            <Modal show={showAgreementSuccessfully} onHide={agreementSuccessfullysClose}>
                <Form>
                    <Modal.Header closeButton className="pb-2"></Modal.Header>
                    <Modal.Body className="pb-2 pt-0 text-center">
                        <img alt="thumb" src="assets/images/icons/thumb-up-light.svg" className="d-block mx-auto img-fluig min-h-56" />
                        <h4 className="my-3">{t("NonPayment.agreementSuccessfullyRegistered")}!</h4>
                        {t("NonPayment.youCanSendInvoiceCustomer")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Dropdown align="end" className='mx-2'>
                            <Dropdown.Toggle variant="link" className="border-2 border border-primary rounded-4 text-primary dropdown-arrow-none">
                                {t("documents")}<img alt="arrow" className="ms-1" src="assets/images/icons/arrow-bottom-primary.svg" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {currentContract && currentContract?.["ID_CONTRATO_CON"] &&
                                    <Dropdown.Item onClick={(e) => { handleElectronicEnvelope(); }}>{t("NonPayment.createElectronicEnvelope")}</Dropdown.Item>
                                }
                                <Dropdown.Item>{t("NonPayment.printCharge")}</Dropdown.Item>
                                <Dropdown.Item>{t("NonPayment.printAgreementTerms")}</Dropdown.Item>
                                <Dropdown.Item>{t("NonPayment.printDetailedAgreement")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {
                            loading ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :

                                <Button variant="primary" onClick={sentEmailToastShow}>
                                    {t("NonPayment.sendCharge")}
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1091 }}>
                <Toast bg="dark" className="rounded-4 text-white border-0 w-auto" show={showToast} onClick={sentEmailToastClose}>
                    <Toast.Body className="d-flex align-items-center p-3">
                        <img className="me-2 me-xl-3" src="assets/images/icons/success-light.svg" alt="arrow right" />
                        <span className="me-2">{t("NonPayment.chargeSentCustomerEmail")}</span>
                        <span className="ms-3 cursor-pe" onClick={sentEmailToastClose}><img src="assets/images/icons/times-white.svg" alt="arrow right" /></span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            <ElectronicEnvelopeService currentContract={currentContract} />
        </>
    )
}

export default AgreementSuccessfully
