import React from "react"

import "./styles.css"

type StatusColumnProps = {
  status: "error" | "alert" | "ready"
}

export function StatusColumn({ status }: StatusColumnProps) {
  const statusStyle = {
    alert: {
      bgColorClass: "bg-warning-5",
      text: "Alerta",
    },
    error: {
      bgColorClass: "bg-danger-status",
      text: "Erro",
    },
    ready: {
      bgColorClass: "bg-success-10",
      text: "Pronto",
    },
  }

  return (
    <div
      className={`${statusStyle[status].bgColorClass} rounded-2 px-1 text-dark-80 fs-12`}
      style={{ paddingBlock: "0.25rem" }}
    >
      {statusStyle[status].text}
    </div>
  )
}
