import { InputGroup, FloatingLabel, Col, Row, Table, Modal, Form, Button, Card, Badge } from 'react-bootstrap';
import { historyOpen,getAllHistory,registerData,deleteHistory }             from "../../../../redux/slice/propertyDataSlice";
import React,{ useState,MouseEvent,useEffect } from 'react';
import { Outlet, Link,useParams }  from 'react-router-dom';
import { useForm }                 from 'react-hook-form';
import { yupResolver }             from '@hookform/resolvers/yup';
import { AppDispatch,RootState }   from '../../../../redux/store';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation }          from 'react-i18next';
import HistoryService              from '../Common/HistoryService';
import * as Yup                    from 'yup';
import Loader                      from '../../../Profile/Company/Loader';
import Swal                        from 'sweetalert2';
//import moment                      from "moment";
import moment from "moment-timezone";
import Pagination                  from "../../../Pagination";

const Property_history = () => { 
    let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const { id }                 = useParams();
    const [ t ]                  = useTranslation();
    const dispatch               = useDispatch<AppDispatch>();
    const { historyData,status } = useSelector((state: RootState) => state.propertyData)
    const [ heading,setHeading ] = useState("");
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).format("MMM/YYYY");
    let endDt   = moment(newDate).add(2, 'M').format("MMM/YYYY");

    const [state, setState] = useState({
        propertyId: Number(id),
        startDate: startDt,
        endDate: endDt,
        history:"",
        start: 0,
        end: 20
    })
    /*----------Pagination---------*/
    
    let PageSize = 20;
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(()=>{
        setState({
            ...state,
            ['start'] : (currentPage > 1 ? currentPage - 1 : 0) * 20,
            ['end']   : 20
        })
    },[currentPage])

    useEffect(() => {
        getPropertyDetails(id);
        getHistoryData(state);       
    }, [dispatch,state])

    const getPropertyDetails = async (propertyId)=> {
        let data = await dispatch(registerData(propertyId));
        if(typeof data.payload !=="undefined"){
            let propertyType = data.payload.data[0]['propertyType'] ? data.payload.data[0]['propertyType'] + ' : ' : '';
            let number   = data.payload.data[0]['number'] ? data.payload.data[0]['number']+'-' : '';
            let address  = data.payload.data[0]['address'] ? data.payload.data[0]['address']+',' : '';
            let city     = data.payload.data[0]['city'] ? data.payload.data[0]['city']+',' : '';
            let state    = data.payload.data[0]['state'] ? data.payload.data[0]['state']+',' : '';
            let headText = propertyType+''+address + ' '+number+' '+ city + '' + state + ' CEP:' + data.payload.data[0]['zipcode'];
            setHeading(headText);
        }
    }

    const getHistoryData = async (histData)=>{
        let response = await dispatch(getAllHistory(histData))
    }
    const searchHistoryData = async (history) => {
        setState({
            ...state,
            ['history']: history
        })
    }
    const filterExpense = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(3, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
            }
            else {
                startDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).add(3, 'M').format("MMM/YYYY");
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt
            })
        }
    }
    const deleteHistoryData = async (historyId) => {
        Swal.fire({
            title: t("Sweetalert.Areyousure"),
            text: t("Sweetalert.Youwontbeabletorevert"),
            // icon: 'success','warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.value){
                let data = {
                    "id"         :historyId,
                    "propertyId" : id
                }
                let response = await dispatch(deleteHistory(data));
                if(typeof response.payload !=="undefined" && response.payload.resCode===408){
                    Swal.fire({
                        icon  : 'success',
                        title : t("Sweetalert.Success"),
                        text: response.payload.message,
                        confirmButtonText: "OK",
                        timer : 2000
                    })    
                    let historyData={
                        "start"       : 0,
                        "end"         : 20,
                        "propertyId"  : response.payload.data            
                    }
                    getHistoryData(historyData); 
                }
                else {
                    Swal.fire({
                        title : t("Sweetalert.Error"),
                        text: response.payload.message,
                        icon  : "error",
                        confirmButtonText: "OK",
                        timer : 2000
                    })
                }
            }
        })
    }

/*------------History modal show-----------*/  

    const newHistoryShow = () => { dispatch(historyOpen(true)); }
    const historyBack = () => {
        window.history.back()
    }

    return(
        <section className="content-wrapper">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="row gx-2 justify-content-between align-items-center h-100 w-100">
                    <div className="col-xxl-2 col-xl-2 order-0 order-xl-0 mb-xl-0 mb-3">
                        <div className="d-flex align-items-center justify-content-start">
                            <Link to={"#"} onClick={historyBack}>
                              <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                            </Link>
                        </div>
                    </div>
                    {/*<div className="col-xxl-7 col-xl order-1 order-xl-3 text-xl-end mb-xl-0 mb-2">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mb-md-0 mb-1">
                                <Link to={"#"} className="fw-bold" >
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                                </Link>
                            </li>
                        </ul>
                    </div>*/}
                </div>
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <div className="card mb-lg-4 mb-3">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                           <img src={process.env.PUBLIC_URL + "/assets/images/icons/home-reqular.svg"} className="h-40" alt="Home" />
                            <div className="w-100 d-md-flex align-items-center justify-content-between ms-xl-3 ms-2">
                                <div className="w-100 me-md-2">
                                    <h1 className="h3 lh-base mb-0">{heading}</h1>
                                    <p className="mb-0">{t('property.historic')}</p>
                                    {/*<span className="d-block">From 02/01/2023 Until 02/28/2023</span>*/}
                                </div>
                                <div className="flex-shrink-0 mt-md-0 mt-2">
                                    <button type="button" className="btn btn-light btn-sm" onClick={historyBack}>
                                        {t('property.property')} # {id}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- Button group section start from here -->*/}
                <div className="row align-items-center mb-lg-4 mb-3">
                    <div className="col-md-3 col-6 order-0">
                        <Link role="button" to={"#"} className="btn btn-light btn-sm" onClick={newHistoryShow}>
                            {t('property.newHistory')}
                        </Link>
                    </div>
                    <div className="col-md-6 text-center order-md-1 order-2">
                        <div className="btn-group btn-group-sm my-md-0 my-2" role="group" aria-label="Basic example">
                            <Button variant="light" onClick={() => filterExpense(state.startDate, 'Subtract')}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-left.svg"} className="me-1" />
                                {state.startDate}
                            </Button>
                            <Button variant="light" onClick={() => filterExpense(state.endDate, 'Add')}>
                                {state.endDate}
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-right.svg"} className="ms-1" />
                            </Button>
                            {/*<button type="button" className="btn btn-light">
                                {t('property.most')}
                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/chevron-sm-bottom.svg"} className="ms-1" />
                            </button>*/}
                        </div>
                    </div>
                    <div className="col-md-3 col-6 text-end order-md-2 order-1">
                        <ul className="list-inline d-flex align-items-center justify-content-end mb-0">
                            <li className="list-inline-item">
                                <div className="position-relative">
                                    <input type="text" onChange={(e) => searchHistoryData(e.target.value)} className="form-control form-control-sm border bg-white rounded-pill pe-5" placeholder={t('formLabel.typeSearch')} aria-label={t('formLabel.typeSearch')} />
                                    <img src={process.env.PUBLIC_URL+"/assets/images/icons/search-sm.svg"} className="position-absolute  top-0 mt-2 end-0 me-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/*<!-- Button group section end -->*/}
                {/*<!-- Table start from here -->*/}
                    {
                        (status == "loading") ?
                            <Loader />
                        :
                        <Table responsive className="table-custom mb-0">
                            <thead>
                                <tr>
                                    <th>{t('property.data')}</th>
                                    <th>{t('property.description')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { historyData.data && historyData.data.length > 0 ? historyData.data.map((item) => (
                                    <tr key={item.id}>
                                        <td>
                                            <span className="text-dark-70 d-block">{item.created}</span>
                                            <span className="d-block">{item.userName}</span>
                                            <span className="badge bg-primary">{item.createdType}</span>
                                        </td>
                                        <td>
                                            {item.history}
                                        </td>
                                        <td className="text-end">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" onClick={() => deleteHistoryData(item.id)} className="h-16 cursor-pe" />
                                        </td>
                                    </tr>
                                )) : 
                                    <tr>
                                        <td colSpan={3}>
                                            <span>{t('formLabel.noRecordsFounds')}</span>
                                        </td>
                                    </tr>
                                } 
                            </tbody>
                        </Table>
                    }
                {/*<!-- /Table end -->*/}
                    <div className="d-flex justify-content-center my-1">
                    { 
                        historyData.totalRow > 0 ? 
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={historyData.totalRow > 0 ? historyData.totalRow : 0}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        :
                        ''
                    }
                </div>
                <p className="d-block py-2">{t('formLabel.listing')} { historyData.totalRow && historyData.totalRow > 0 ? historyData.totalRow : 0 } {t('property.record')}.</p>
            </main>
            {/* <!-- /Main content start end --> */}
            <HistoryService getHistoryData = {getHistoryData}/>
        </section>)
}
export default Property_history;