import React, { useState } from "react";
import { Icon } from "../../../../components/Icon";
import ConfigDrawer from "../ConfigDrawer/ConfigDrawer";

export function Configuration() {

  const [showDIMOBDrawer, setShowDIMOBDrawer] = useState(false);

  const openDIMOBDrawer = () => setShowDIMOBDrawer(true);
  const closeDIMOBDrawer = () => setShowDIMOBDrawer(false);

  return (
    <div className="d-flex">
      <button
        className="d-flex align-items-center gap-1 justify-content-center text-center border-0 bg-transparent"
        onClick={openDIMOBDrawer}
      >
        <Icon name="settings" size={35} color="#f82e52" />
      </button>
      <ConfigDrawer showDIMOBDrawer={showDIMOBDrawer} dimobSettingClose={closeDIMOBDrawer} />
    </div>
  );
}
