import { Accordion, FloatingLabel, Modal, Form, Button, InputGroup } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { getBankList } from '../../../redux/slice/bankSlice';
import { useTranslation } from 'react-i18next';

const EditExpense = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const { showEditExpense, editExpenseClose, editExpenseModalShow, checkboxClicked } = props;
    const { userbanklist } = useSelector((state: RootState) => state.bank)

    let obj = {
        start: "0",
        end: "10",
    }
    useEffect(() => {
        dispatch(getBankList(obj))
    }, [dispatch])

    return (
        <Modal show={showEditExpense} onHide={editExpenseClose}>
            {checkboxClicked ? (
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("paidTobepaid.Editexpense")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel label={t("banks.bankAccounts")}>
                            <Form.Select aria-label="Bank account">
                                {userbanklist && userbanklist.map((curElem, index) => (
                                    <option key={index} value={curElem.id}>{curElem.nameReference}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={editExpenseClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={editExpenseClose}>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            ) : (
                <div>
                    <p>{t("paidTobepaid.Checkboxisnotchecked")}!</p>
                    {/* You can add additional elements or styling here */}
                </div>
            )
            }
        </Modal>
    )
}

export default EditExpense
