import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import customerService from '../../../../services/customerService';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { listTemplate } from '../../../../redux/slice/SendEmailSlice';

const DeleteFolder = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    //Delete folder modal close open function
    const { showDeleteFolder, deleteFolderClose, folderId } = props;

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        //console.log("currentFileId Delete", folderId);
        let data = {
            folder_id: folderId
        }
       const response = await customerService.deleteDocumentFolder(data);
        if (response.data.resCode == 408) {
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: "Folder Deleted",
                confirmButtonText: "OK",
                timer: 2000
            })
            await dispatch(listTemplate())
            deleteFolderClose();
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "There is some issue in deleting the folder",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    };

    return (
        <>
            <Modal show={showDeleteFolder} onHide={deleteFolderClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.deleteFolder")}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {t("DocumentTemplate.deleteFolderContent")}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="outline-primary" onClick={deleteFolderClose}>
                    {t("button.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {t("button.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default DeleteFolder
