import React from 'react'
import { Bars } from 'react-loader-spinner'

const Loader = () => {
    const customStyles = {
        // Set your desired background color here
        backgroundColor: '#ffffff00', // Change 'blue' to your preferred color
        height: "60vh"
      };
    return (
        <div className='d-flex align-items-center justify-content-center' style={customStyles}>
            <Bars
                height="80"
                width="80"
                color="#f82e52"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true} />
        </div>
    )
}
export default Loader