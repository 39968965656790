import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
let connectionName: any = '';
if (localStorage.getItem("userData")) {
  connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCEESS: "success",
  ERROR: 'error'
});
export interface FormsOfReceipts {
  monthly_interest: string,
  interest_and_fine: string
  fine_rate: string,
  discount_rate: string,
  give_discount: number,
  ticket_discount: string,
  apply_discount_all: number,
  lawyer_fee: number,
  use_percentage: string,
  discountSer: any,
  discounted_service?: any,
}

export interface Bankslip {
  Updatethevalue: string,
  sprated: number,
  envirment: string,
  Billing_Instructions: string,
  messageticket: string
  tradename: boolean,
  displaytheamount: any,
  displaythetotal: any,
  companylogo: any,
  congratulations: boolean,
  pendingcharges: boolean,
  sortbillet: boolean,
  donotdisplay: boolean,
  day: string,
  accounttype?: string,
  bankaccount?: string,
}

interface blocking {
  id: number,
  day: number
}

interface SliceState {

  Bankslip: Bankslip[]
  blocking: blocking[]
  formdata: any,
  wayofreceiving: [],
  status: string,
  show: boolean,
  status1:string
}

const initialState: SliceState = {
  Bankslip: [],
  blocking: [],
  formdata: [],
  wayofreceiving: [],
  status: STATUSES.IDLE,
  show: false,
  status1:STATUSES.IDLE,
};


let user_id = gettoken.userId
const formsOfReceipt = createSlice({
  name: "formofreceipt",
  initialState,
  reducers: {
    editBank(state, action) {
      state.show = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_forms_of_receipts.pending, (state, action) => {
        state.status1 = STATUSES.LOADING
      })
      .addCase(get_forms_of_receipts.fulfilled, (state, action: PayloadAction<any>) => {
        state.formdata = action.payload.data
        state.status1 = STATUSES.SUCEESS;
      })
      .addCase(get_forms_of_receipts.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR;
      });
    builder
      .addCase(update_forms_of_receipts.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(update_forms_of_receipts.fulfilled, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(update_forms_of_receipts.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
    builder
      .addCase(get_blocking_and_cancellation.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(get_blocking_and_cancellation.fulfilled, (state, action: PayloadAction<any>) => {
        state.blocking = action.payload.data
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(get_blocking_and_cancellation.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
    builder
      .addCase(update_blocking.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(update_blocking.fulfilled, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(update_blocking.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
    builder
      .addCase(getFormsofReceipt.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getFormsofReceipt.fulfilled, (state, action: PayloadAction<any>) => {
        state.wayofreceiving = action.payload.data;
        state.status = STATUSES.SUCEESS
      })
      .addCase(getFormsofReceipt.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(get_bank_slip.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(get_bank_slip.fulfilled, (state, action: PayloadAction<any>) => {
        state.Bankslip = action.payload.data
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(get_bank_slip.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
    builder
      .addCase(update_bank_slip.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(update_bank_slip.fulfilled, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(update_bank_slip.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
  }

})

export const { editBank } = formsOfReceipt.actions
export default formsOfReceipt.reducer

// GET - COMPANY DATA API
export const get_forms_of_receipts = createAsyncThunk(
  'user/forms_of_receipts',
  async (_, thunkAPI) => {
    var raw = JSON.stringify({
      "user_id": user_id
    });
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/forms_of_receipts/practiced_rates`, {
      method: 'POST',
      body: raw,
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);


// POST  -  UPDATE update_forms_of_receipts DATA API
export const update_forms_of_receipts = createAsyncThunk(
  'posts/update_forms_of_receipts',
  async (postData: any) => {
    postData["user_id"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/forms_of_receipts/practiced_rates`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data

  }
);


// GET Blocking and cancellation -- GET API
export const get_blocking_and_cancellation = createAsyncThunk(
  "Blocking_and_cancellation",
  async () => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/waysofrec/blocking_cancellation`, {
      method: "GET",
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      }
    })
    const data = response.json()
    return data
  }
)

// GET Blocking and cancellation -- PUT API
export const update_blocking = createAsyncThunk(
  "update_blocking",
  async (blockData: any) => {
    blockData["id"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/waysofrec/blocking_cancellation`, {
      method: "PUT",
      body: JSON.stringify(blockData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      }
    })
    const data = await response.json()
    return data
  }
)

// GET Blocking and cancellation -- PUT API
export const getFormsofReceipt = createAsyncThunk(
  "getFormsofReceipt",
  async () => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/waysofrec/receiving/getFormsofReceipt`, {
      method: "get",
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      }
    })
    const data = await response.json()
    let activedata = data["data"].filter((item, index) => item.FL_PRODUCAO_FRECB == 0);
    let activecontangency = data["data"].filter((item, index) => item.FL_PRODUCAO_FRECB == -1 || item.FL_PRODUCAO_FRECB == null);
    let disableddata = data["data"].filter((item, index) => item.FL_PRODUCAO_FRECB == 1);
    data["data"] = [...activedata, ...activecontangency, ...disableddata];
    return data
  }
)

// POST  -  UPDATE status forms_of_receipts
export const updateStatesWayof = createAsyncThunk(
  'posts/updateStatus',
  async (postData: any) => {
    postData["ID_EMPRESA_EMP"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/waysofrec/updateStatus`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

// POST  -  ADD/UPDATE orms_of_receipts
export const addUpdateWayof = createAsyncThunk(
  'posts/addUpdateFormData',
  async (postData: any) => {
    postData["ID_EMPRESA_EMP"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/waysofrec/addUpdateFormData`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data
  }
);
export const get_bank_slip = createAsyncThunk(
  'posts/get_bank_slip',
  async () => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/waysofrec/getBankOfSlip`, {
      method: 'POST',
      body: JSON.stringify({ "user_id": user_id }),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data
  }
);
export const update_bank_slip = createAsyncThunk(
  'posts/update_bank_slip',
  async (postData: any) => {
    postData["user_id"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/waysofrec/upadeBankOfSlip`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data
  }
);