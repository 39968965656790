import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { contractDatails, insertQuote, quoteClose } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { quoteForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';

const QuoteService = (quoteForm) => {
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { newQuoteShow } = useSelector((state: RootState) => state.contractData)

    useEffect(() => {
        if (newQuoteShow) {
            getContractsData(id)
        }
    }, [newQuoteShow])

    const getContractsData = async (id) => {
        const fields: string[] = ['id', 'immobile', 'contract_type', 'start', 'end', 'rent_value', 'readjustment_index', 'immobile', 'guarantee', 'observation2', 'value', 'transfer_day', 'in', 'until', 'responsible', 'contractedPlan', 'applicationNumber', 'last_readjustment', 'delivery_method', 'branch', 'observation', 'comercial_agent', 'manager', 'price', 'is_generate', 'automatic_payment', 'is_active', 'insuranceCompany', 'installmentValue', 'numberInstallments', 'assuredValue', 'prizeAmount', 'policy', 'responsible', 'isContract', 'start2', 'end2', 'observation3'];
        if (typeof id !== "undefined" && id) {
            let data = await dispatch(contractDatails(Number(id)));
            if (data.payload.data.length > 0) {
                fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));
            }
        }
    } 
    const newQuoteClose = () => {
        dispatch(quoteClose(false)); reset();
        getContractsData(id);
    } 
    const quoteValidation = Yup.object().shape({
        start2: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        end2: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        price: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        quote: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    let frm = {
        resolver: yupResolver(quoteValidation)
    } 
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<quoteForm>(frm);
    const onSubmit = async (data: quoteForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        let response = await dispatch(insertQuote(data));
        if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
            setDouble(false);
            Swal.fire({
                // icon:'success',
                title:t("Sweetalert.Success"),
                text:t("Sweetalert.successget"),
                confirmButtonText:"OK",
                timer:2000})
            newQuoteClose();
        }
        else {
            setDouble(false)
            Swal.fire({
                title:t("Sweetalert.Error"),
                text:t("Sweetalert.somethingWent"),
                // icon:"error",
                confirmButtonText:"OK",
                timer:2000})
        }
    }
    return (
        <>
            {/* <!-- New quote modal start from here --> */}
            <Modal show={newQuoteShow} onHide={newQuoteClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" {...register('id')} />
                    <Modal.Header closeButton>
                        <Modal.Title>Price</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label="Start">
                                    <Form.Control type="date" placeholder="Start" className={`form-control ${errors.start2 ? 'is-invalid' : ''}`} {...register('start2')} />
                                    <Form.Control.Feedback type="invalid">{errors.start2?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label="End">
                                    <Form.Control type="date" placeholder="End" className={`form-control ${errors.end2 ? 'is-invalid' : ''}`} {...register('end2')} />
                                    <Form.Control.Feedback type="invalid">{errors.end2?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                            <Col xs>
                                <FloatingLabel controlId="floatingInput" label="Coverage amount">
                                    <Form.Control type="text" placeholder="Coverage amount" className={`form-control ${errors.price ? 'is-invalid' : ''}`} {...register('price')} />
                                    <Form.Control.Feedback type="invalid">{errors.price?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label="Quote">
                                    <Form.Select className={`form-control ${errors.quote ? 'is-invalid' : ''}`} {...register('quote')}>
                                       <option value="">{t('dropdownItem.select')}..</option>
                                       <option value="1">Service</option>
                                        <option value="2">Business</option>
                                        <option value="3">Desk</option>
                                        <option value="4">Clinic</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.quote?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Alert variant="warning" className="mb-0">
                            <span className="d-block">Before hiring, update this data.</span>
                            <a href="#" className="text-primary">CLICK HERE to update.</a>
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={newQuoteClose}>
                            Close
                        </Button>
                        <Button disabled={double} type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /New quote modal end --> */}
        </>
    )
}
export default QuoteService;