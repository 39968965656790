import React from 'react';
import { Form, Modal, Button, Row, Col, FloatingLabel,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { contractSearchForm } from "../../../../Interface";
import customerService from "../../../../../services/customerService"; 
const ChangeFinancialStatus = (props) => {
    const [t] = useTranslation();
    //Change financial status modal close open function
    const { showFinancialStatus, financialStatusClose, contractids, financialStatusShow } = props;
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm<contractSearchForm>();

    const submitFinancialForm = async (data: contractSearchForm) => {
        let frmData = {
            contractids  : contractids,
            status : data.status,
            financialStatus : data.financialStatus
        };
        customerService.changeContractStatus(frmData).then(res => {
            if (res.data.resCode === 200) {
                Swal.fire({
                    title:t("Sweetalert.Success"),
                    text:t("Sweetalert.successget"),
                    // icon:"success",
                    confirmButtonText:"OK",
                    timer:2000});
                financialStatusClose();
            }
            else {
                Swal.fire({
                    title:t("Sweetalert.Error"),
                    text:t("Sweetalert.somethingWent"),
                    // icon:"error",
                    confirmButtonText:"OK",
                    timer:2000});
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title:t("Sweetalert.Error"),
                text:t("Sweetalert.somethingWent"),
                // icon:"error",
                confirmButtonText:"OK",
                timer:2000});
        });
    };
    return (
        <Modal show={showFinancialStatus} onHide={financialStatusClose}>
            <Form onSubmit={handleSubmit(submitFinancialForm)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change financial status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-lg-4 gy-3 align-items-center">
                        <Col md={6}>
                            <FloatingLabel controlId="status" label="Status">
                                <Form.Select aria-label="Status" {...register('status')}>
                                    <option value="0">{t('financialStatuses.normal')}</option>
                                    <option value="1">{t('financialStatuses.legal')}</option>
                                    <option value="2">{t('financialStatuses.invacancy')}</option>
                                    <option value="3">{t('financialStatuses.sinister')}</option>
                                    <option value="4">{t('financialStatuses.pendingFeatures')}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <OverlayTrigger placement="top" overlay={<Tooltip className="my-2" id="tooltip">{t('suspendInfo')}</Tooltip>}>
                                <Form.Check type="checkbox" {...register('financialStatus')} label={t('financialStatuses.labelsuspendCharges')} id="suspend_contract" />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={financialStatusClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">{t("button.done")}</Button>
                    {/* <Button variant="primary" onClick={financialStatusClose}>
                        Save
                    </Button> */}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default ChangeFinancialStatus
