import { Card, Table, Dropdown, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, Collapse } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useMemo } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import '../../../../App.css';
import Pagination from '../../../Pagination';

import { useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { getAllBillMethodListing, openTypeCustomer } from '../../../../redux/slice/expenditureDataSlice';
import { encrypt_Decrypt } from '../../../constants/utils';
import NewGuarantors from './NewGuarantors';
import { openEmailPop } from '../../../../redux/slice/contractDataSlice';
import SendEmails from '../Common/SendEmails';

const Guarantors = (CustomersAndEmployeesForm) => {
    const dispatch = useDispatch<AppDispatch>()

    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2060) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const [PageSize, setPageSize] = useState(20);
    const [state, setState] = useState({
        guarantor: "",
        person: "",
        status: "",
        start: 0,
        end: PageSize,
    });

    const [currentPage, setCurrentPage] = useState(1);

    const [t] = useTranslation();
    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);
    const [status, setStatus] = useState(true);

    /*--------Mailing and Label permission state--------*/

    const [hide5, setHide5] = useState(false);
    const [mailText, setMailText] = useState("");
    const [show3, setShow3] = useState(false);

    /*--------Data table listing state & multiple checked--------*/

    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);

    useEffect(() => {
        getAllGuarantor(state)
    }, [state]);

    useEffect(() => {
        setState({
            ...state,
            ["start"]: currentPage == 1 || currentPage == 0 ? 0 : (currentPage - 1) * PageSize,
            ["end"]: PageSize
        })
    }, [currentPage])

    /*--------Common modal function--------*/

    const formShow = () => {
        setHide(true); setShow(true);
    }
    const mailingShow = () => {
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');
        data.forEach((item, index) => {
            if (item["checked"]) {
                count = true;
            }
        })
        if (count) {
            setShow3(true);
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    const [sendMailData, setSendMailData] = useState<any>([]);
    const [mailData, setMailData] = useState<any>([]);
    const mailingClose = () => { setShow3(false); reset(); }
    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');

        data?.forEach((item, index) => {
            let mailData: any = []; // Initialize mailData array for each iteration
            if (item["checked"]) {
                count = true
                sendMailData.forEach((element: any) => {
                    if (element.id == item.id) {
                        mailData.push(element.email);
                    }
                });
                // Push the mailData array into the emailArray
                setMailData(prevEmailArray => [...prevEmailArray, ...mailData]);
            }
            // setMailData(emailArray)
        });

        if (count) {
            setMailText(e.currentTarget.name)
            if (e.currentTarget.name === "Send letter" || e.currentTarget.name === "Enviar carta") {
                setHide5(true);
            } else {
                setHide5(false);
            }
            // setShow2(true)
            dispatch(openEmailPop(true))
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }
    /*-------------Form validation-------------*/
    const mailLabelValidationSchema = Yup.object().shape({
        label_template: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        position: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        number_of_copies: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    let frm = {
        resolver: yupResolver(mailLabelValidationSchema)
    }

    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);
    /*-------------Mailing label form submit-------------*/

    const permissionSubmit = (data: CustomersAndEmployeesForm) => {
        let permissionData = {
            label_template: data.label_template,
            position: data.position,
            number_of_copies: data.number_of_copies
        }
    }
    /*-------Get the guarantors listing--------*/

    const getAllGuarantor = (guarantor) => {
        let guarantorData = { guarantor: guarantor.guarantor, personStatus: guarantor.person, activeContract: guarantor.status, start: guarantor.start, end: guarantor.end }
        setStatus(true);
        customerService.getAllGuarantor(guarantorData).then(res => {
            setStatus(false);
            if (res.data.resCode === 201) {
                setData(res?.data?.data || []);
                setTotal(res.data.totalRow || 0);
                setTotalPage(res.data.totalRow || 0);
                setSendMailData(res?.data?.data || [])
            }
            else {
                setData([]);
                setTotal(0);
                setTotalPage(0);
                setSendMailData([]);
            }
        }).catch(err => {
            setStatus(false);
            console.log("err", err)
        });
    }

    /*-------Checked/Unchecked multiple checkbox functionality--------*/

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }

                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })

                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)

        }
    };

    // Pagination start
    const [totalpage, setTotalPage] = useState(0)
    const [openFilters, setFiltersOpen] = useState(false);

    let typingTimer;
    const ActiveContract = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        if (name == "search") {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                setState({
                    ...state,
                    guarantor: value
                })
            }, 1000)
        } else {
            if (name == "person") {
                setState({
                    ...state,
                    [name]: value == "1" ? "both" : value == "2" ? "Physical" : value == "3" ? "Legal" : ""
                })
            } else {
                setState({
                    ...state,
                    [name]: value
                })
            }
        }
    }
    const handleSelectName = async(e, type, id) => {
        e.preventDefault();
        let data: any = {
            type: type
        }
            await navigate(`/profile_details/${id}`, { state: data });
    }
    const filterReset = () => {
        let setvalue: any = document.querySelector("#status");
        if(setvalue){
        setvalue.value = ""
        }
        let setvalue1: any = document.querySelector("#contactstatus");
        if(setvalue1){
            setvalue1.value = "All"
        }
        setState({
            guarantor: "",
            person: "",
            status: "",
            start: 0,
            end: 20
        })
    }
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
               <div className="w-100 mb-xl-4">
               <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/customer_and_employees"}>
                                    <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-xl-1">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t('formLabel.guarantorAndGuarantor')}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <div className="input-group">
                                <Form.Control type="text" name="search" id="search" placeholder={t('formLabel.typeSearch')} aria-describedby="basic-addon2" onChange={ActiveContract} />
                                <span className="input-group-text" id="basic-addon2">
                                    <img alt="img" src="assets/images/icons/search.svg" />
                                </span>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(2061) !== -1) &&
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Link to={"#"} onClick={formShow} className="fw-bold">
                                        <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                        {t('customerAndEmployees.newGuarantor')}
                                    </Link>
                                </li>
                                }
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 dropdown-arrow-none" id="dropdownMenuButton1">
                                            <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20 cursor-pe" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.printScreen')}</Dropdown.Item>
                                            <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('customerAndEmployees.guarantorsReport')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </div>
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["person"] ? "show" : ""}`}>
                                        {state?.["person"] == "both" ? t('dropdownItem.physicalAndLegal') : state?.["person"] == "Physical" ? t('dropdownItem.physical') : state?.["person"] == "Legal" ? t('dropdownItem.legal') : t('dropdownItem.person')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="px-4">
                                            <FloatingLabel controlId="floatingSelect" label={t('dropdownItem.status')}>
                                                <Form.Select id="status" name="person" aria-label={t('dropdownItem.status')} onChange={ActiveContract}>
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {/* <option value="1">{t('dropdownItem.physicalAndLegal')}</option> */}
                                                    <option value="2">{t('dropdownItem.physical')}</option>
                                                    <option value="3">{t('dropdownItem.legal')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["status"] ? "show" : ""}`}>
                                        {state?.["status"] == "activewithContract" ? t('dropdownItem.activeContracts') : state?.["status"] == "all" ? t('All') : t('dropdownItem.contractStatus')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="px-4">
                                            <FloatingLabel controlId="floatingSelect" label={t('dropdownItem.contractStatus')}>
                                                <Form.Select id="contactstatus" name="status" aria-label={t('dropdownItem.contractStatus')} onChange={ActiveContract}>
                                                    <option value="">{t('all')}</option>
                                                    <option value="activewithContract">{t('dropdownItem.activeContracts')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {state?.["person"] || state?.["status"] ? <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                        {t("button.resetfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col> :
                                ""}
                        </Row>
                    </Form>
                </Collapse>
            </div>
            {/* <!-- Breadcrumb section end from here --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">                            
                                    {/*<!-- Table start from here -->*/}
                                        {
                                            status ?
                                                <Loader />
                                                :
                                                <>
                                                <Card>
                                          <Card.Body className="pb-1">
                                                    <Table responsive className="table table-custom table-custom-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="w-24">
                                                                    {data && data.length > 0 ?
                                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                                        : ''}
                                                                </th>
                                                                <th>{t('formLabel.name')}</th>
                                                                <th>{t('formLabel.contact')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data && data.length > 0 ? data.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className={"check_Checkbox"}>
                                                                        <Checkbox key={item.id} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                                    </td>
                                                                    <td>
                                                                        <span className="d-block">
                                                                            <img alt="img" src="assets/images/icons/user_g.svg" />
                                                                            <Link to={"#"} onClick={(e) => handleSelectName(e, "Guarantors", item.id)} className="ps-1 mr-2 text-secondary">{item.name} </Link>
                                                                            {/* <br />
                                                                            <span>{legalAndPhysical ? legalAndPhysical : legalOwner ? legalOwner : ""}</span> */}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <a href={`mailto:${item.email}`} className="d-block text-dark-70">{item.email}</a>
                                                                        <span>{item.telephone ? item.telephone : item.cellphone}</span>
                                                                    </td>
                                                                </tr>
                                                            )) :
                                                                <tr>
                                                                    <td colSpan={3}>
                                                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    </Card.Body>    
                                            </Card>          
                                                    <div className="d-flex justify-content-center mt-lg-4 mt-3">
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPage}
                                                            totalCount={totalpage}
                                                            pageSize={PageSize}
                                                            onPageChange={(page) => setCurrentPage(page)}
                                                        />
                                                    </div>
                                                </>
                                        }
                                    {/*<!-- /Table end -->*/}
                                    {/* {t('formLabel.moreItems')} */}
                                    <p className="d-block py-2">{t('formLabel.listing')} {data && data.length} {t('customerAndEmployees.guarantors')}.</p>
                                    {/*<!-- Nav listing start from here -->*/}

                                    {
                                        userJson && userJson.length > 0 && (userJson.indexOf(2061) !== -1) &&
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                                        {t('formLabel.mark')}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.all')}</Dropdown.Item>
                                                        <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('formLabel.none')}</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                                <span className="px-1">
                                                    {t('formLabel.withTagged')}
                                                </span>
                                            </li>
                                            <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                                <button type="button" onClick={handleMouseEvent} name={t('formLabel.sendEmail')} className="btn btn-light btn-sm">
                                                    {t('formLabel.sendEmail')}
                                                </button>
                                            </li>
                                            <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                                <button type="button" onClick={handleMouseEvent} name={t('formLabel.sendLetter')} className="btn btn-light btn-sm">
                                                    {t('formLabel.sendLetter')}
                                                </button>
                                            </li>
                                            <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                                <button type="button" onClick={mailingShow} className="btn btn-light btn-sm">
                                                    {t('formLabel.mailingLabel')}
                                                </button>
                                            </li>
                                        </ul>
                                    }
                                    {/* <!-- /Nav listing  end --> */}
                        
            </main>
            {/* <!-- /Main content start end --> */}

            {/*---------------Mail/Letter form modal------------*/}
            <SendEmails mailText={mailText} mailData={mailData} />
        

            {/*---------------Mailing label permission form modal------------*/}

            <Modal show={show3} onHide={mailingClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('formLabel.changeAccessPermi')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(permissionSubmit)}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.labelTemplate')}>
                                    <Form.Select aria-label={t('formLabel.labelTemplate')} className={` ${errors.label_template ? 'is-invalid' : ''}`}  {...register('label_template')}>
                                        <option value="">{t('formLabel.select')}</option>
                                        <option value="1">{t('formLabel.test')} 1 </option>
                                        <option value="2">{t('formLabel.test')} 2</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.label_template?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.positionLabel')}>
                                    <Form.Control type="text" placeholder={t('formLabel.positionLabel')} className={` ${errors.position ? 'is-invalid' : ''}`}  {...register('position')} />
                                    <Form.Control.Feedback type="invalid">{errors.position?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.numberOfCopies')}>
                                    <Form.Control type="text" placeholder={t('formLabel.numberOfCopies')} className={` ${errors.number_of_copies ? 'is-invalid' : ''}`}  {...register('number_of_copies')} />
                                    <Form.Control.Feedback type="invalid">{errors.number_of_copies?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={mailingClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" name="To save">
                            {t('button.save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/*---------------Guarantors form modal------------*/}


            <NewGuarantors show={show} setShow={setShow} getAllGuarantor={getAllGuarantor} />
        </section>
    )
}
export default Guarantors;