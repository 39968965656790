import React, { useState } from 'react';
import { Modal, Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap';

const NotificationSetting = (props) => {
    //Notification setting modal close open function
    const { showNotificationSetting, notificationSettingClose, notificationSettingShow } = props;

    return (
        <Modal show={showNotificationSetting} onHide={notificationSettingClose}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Configure Contract Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                        <Col md={6}>
                            <FloatingLabel controlId="service" label="Service">
                                <Form.Control type="text" placeholder="Service" value="Repasse" />
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel controlId="person_email" label="Responsible person's email">
                                <Form.Control type="text" placeholder="Responsible person's email" value="himani@wegile.com" />
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel controlId="name" label="Responsible name">
                                <Form.Control type="text" placeholder="Responsible name" value="Himani" />
                            </FloatingLabel>
                        </Col>
                        <Col md="auto">
                            <Button variant="link" className="bg-light border-light"><img src="../assets/images/icons/delete-light.svg" alt="Delete icon" className="h-16 cursor-pe" /></Button>
                        </Col>
                    </Row>
                    <span className="d-block fw-bold text-primary">Another</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={notificationSettingClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={notificationSettingClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default NotificationSetting
