import React, { useEffect, useState } from 'react';
import { Offcanvas, Form, Button, FloatingLabel, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { nonPaymentCharge, nonPaymentGetContact, nonPaymentPostContact } from '../../../../../redux/slice/NonPaymentSlice';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../../../Profile/Company/Loader';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import moment from "moment-timezone";
import { schedultform } from '../../../../Interface';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const ScheduleContact = (props) => {
    const { showScheduleContact, scheduleContactClose, storeData, type, state, loading, setLoading, setEditor, other } = props;
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    let registerContact = Yup.object().shape({
        date: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        time: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        channel: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        result_contract: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        delinquency: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });

    const scheduleValidation = Yup.object().shape({
        date: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        time: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        channel: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });

    let applyThis = (type == "schedule") ? scheduleValidation : registerContact;
    const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm<schedultform>(
        { resolver: yupResolver(applyThis) }
    );
    const { nonGetPaymentGetContact } = useSelector((state: RootState) => state.nonPayment)

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const [communicationchannal, setcommunicationchannal] = useState([
        { value: "1", label: t("NonPayment.messagingApp") },
        { value: "2", label: t("NonPayment.letter") },
        { value: "3", label: t("formLabel.email") },
        { value: "4", label: "SMS" },
        { value: "5", label: t("NonPayment.phone") },
    ]);

    const [resultContract, setresultContract] = useState<any>([
        {
            label: "Effective", value: [
                { value: "1.1", label: "Paid" },
                { value: "1.2", label: "Promise" },
            ]
        },
        {
            label: "Inconclusive", value: [
                { value: "2.1", label: "Disagrees" },
                { value: "2.2", label: "Call back" },
                { value: "2.3", label: "Online negotiation" },
            ]
        },
        {
            label: "Not made", value: [
                { value: "3.1", label: "Wrong number" },
                { value: "3.2", label: "Doesn't answer" },
                { value: "3.3", label: "Busy" },
                { value: "3.4", label: "Outros" },
            ]
        }
    ])

    const commonToastClose = () => setToastShow(false);
    const commonToastShow = () => {
        setToastShow(true);
        handleClose();
        setTimeout(() => {
            setToastShow(false);
        }, 2000);
    }

    const handleClose = () => {
        if (other && other.length > 1 && other[0] == "edit") {
            setEditor([""]);
        }
        scheduleContactClose(false);
        reset();
    }

    const contactSubmit = async (data: any) => {
        data["tenant_id"] = storeData?.["ID_SACADO_SAC"] ?? '';
        data["tenant_name"] = storeData?.["ST_NOMEREF_SAC"] ?? "";
        data["recebimento_id"] = storeData?.["ID_RECEBIMENTO_RECB"] ?? "";
        data["schedule_id"] = data["schedule_id"] ?? null;
        data["type"] = type;
        data["other"] = other;
        data["contacted"] = 1;
        setLoading(true);
        let dataResponse = await dispatch(nonPaymentPostContact(data));
        if (dataResponse["payload"]["type"] == "Success" || dataResponse["payload"]["type"] == "success") {
            await dispatch(nonPaymentCharge(state))
            setLoading(false)
            commonToastShow();
            handleClose();
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            if (showScheduleContact) {
                // setValue("tenant_name", storeData?.["ST_NOMEREF_SAC"] ?? "");
                setValue("tenant_name", (storeData?.["ST_NOME_PES"] && storeData?.["ST_NOME_PES"] !== "") ? storeData?.["ST_NOME_PES"] : storeData?.["ST_NOMEREF_SAC"]);
                dispatch(nonPaymentGetContact({
                    "recebimento_id": storeData?.["ID_RECEBIMENTO_RECB"] ?? "",
                    "schedule_id": (other && other.length > 1) ? other[1] : ""
                }))
            }
        })()
    }, [showScheduleContact])

    useEffect(() => {
        if (nonGetPaymentGetContact && nonGetPaymentGetContact.length > 0) {
            let dataGet = nonGetPaymentGetContact[0];
            setValue("schedule_id", dataGet?.["ID_SCHEDULE_SCH"] ?? "");
            setValue("tenant_name", dataGet?.["TX_SACADO_NAME_SCH"] ?? "");
            setValue("date", moment(dataGet?.["DT_DATE_SCH"]).format("YYYY-MM-DD") ?? "");
            setValue("time", dataGet?.["TM_TIME_SCH"] ?? "");
            setValue("result_contract", dataGet?.["VL_RESULT_CONTRACT_SCH"] ?? "");
            setValue("delinquency", dataGet?.["VL_DELINQUENCY_SCH"] ?? "");
            setValue("channel", dataGet?.["SL_COMMUNICATION_SCH"] ?? "");
            setValue("observation", dataGet?.["ST_OBSERVACAO_SCH"] ?? "");
        }
    }, [nonGetPaymentGetContact])

    return (
        <>
            <Offcanvas className="border-sm-none" show={showScheduleContact} onHide={handleClose} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(contactSubmit)} >
                    <Offcanvas.Header className="border-bottom" closeButton>
                        {
                            type == "schedule" ?
                                t("NonPayment.scheduleContact")
                                :
                                t("NonPayment.registerNewContact")
                        }
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row className="g-lg-4 g-3">
                            <Form.Control type="text" disabled {...register("schedule_id")} hidden />
                            <Col sm={12}>
                                <FloatingLabel label={t("tenant")}>
                                    <Form.Control className={`form-control ${errors.tenant_name ? 'is-invalid' : ''}`} type="text" disabled placeholder={t("tenant")} {...register("tenant_name")} />
                                    <Form.Control.Feedback type="invalid">{errors.tenant_name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col sm={6}>
                                <FloatingLabel label={t("bankTransaction.date") + "*"} >
                                    <Form.Control className={`form-control ${errors.date ? 'is-invalid' : ''}`} type="date" placeholder={t("bankTransaction.date") + "*"} {...register("date")} />
                                    <Form.Control.Feedback type="invalid">{errors.date?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col sm={6}>
                                <FloatingLabel label={t("NonPayment.time") + "*"}>
                                    <Form.Control className={`form-control ${errors.time ? 'is-invalid' : ''}`} type="time" placeholder={t("NonPayment.time") + "*"} {...register("time")} />
                                    <Form.Control.Feedback type="invalid">{errors.time?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col sm={12}>
                                <FloatingLabel label={t("NonPayment.communicationChannel") + "*"}>
                                    <Form.Select className={`form-control ${errors.channel ? 'is-invalid' : ''} mh-336 overflow-auto`} aria-label="Ccmmunication_channel" {...register("channel")}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        {communicationchannal && communicationchannal.length > 0 &&
                                            communicationchannal.map((item, index) => {
                                                return (
                                                    <option key={index} value={item?.["value"]}>{item?.["label"]}</option>
                                                )
                                            })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.channel?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            {
                                type == "reschedule" &&
                                <>
                                    <Col sm={6}>
                                        <FloatingLabel label={t("NonPayment.resultTheContact") + "*"}>
                                            <Form.Select className={`form-control ${errors.result_contract ? 'is-invalid' : ''} mh-336 overflow-auto`} aria-label="result_contact"  {...register("result_contract")}>
                                                <option value="">{t('dropdownItem.select')}</option>
                                                {
                                                    resultContract && resultContract.length > 0 &&
                                                    resultContract.map((itemparent: any, indexparent: number) => {
                                                        return (
                                                            <optgroup label={t(`NonPayment.${itemparent.label}`)} key={indexparent}>
                                                                {
                                                                    itemparent["value"] && itemparent["value"].length > 0 &&
                                                                    itemparent["value"].map((itemChild: any, indexChild: number) => {
                                                                        return (
                                                                            <option value={itemChild.value} key={indexChild}>{t(`NonPayment.${itemChild.label}`)}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </optgroup>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.result_contract?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={6}>
                                        <FloatingLabel label={t("NonPayment.reasonForDelinquency") + "*"}>
                                            <Form.Select className={`form-control ${errors.delinquency ? 'is-invalid' : ''} mh-336 overflow-auto`} aria-label="reason_delinquency"  {...register("delinquency")}>
                                                <option value="">{t('dropdownItem.select')}</option>
                                                <option value="1">{t("terminatelist.Financial_problems")}</option>
                                                <option value="2">{t("NonPayment.didNotReceivInvoice")}</option>
                                                <option value="3">{t("NonPayment.disagrees")}</option>
                                                <option value="4">{t("NonPayment.other")}</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.delinquency?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </>
                            }
                            <Col sm={12}>
                                <FloatingLabel controlId="observations" label={t("propertyDev.observations")}>
                                    <Form.Control className={`form-control ${errors.observation ? 'is-invalid' : ''} mh-336 overflow-auto`} as="textarea" placeholder={t("propertyDev.observations")} {...register("observation")} />
                                    <Form.Control.Feedback type="invalid">{errors.observation?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {
                            loading ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary" onClick={scheduleContactClose}>{t("button.cancel")}</Button>
                                    <Button variant="primary" type="submit">
                                        {
                                            type == "reschedule" ? t("NonPayment.register") : t("NonPayment.schedule")
                                        }
                                    </Button>
                                </>
                        }
                    </div>
                </Form>
            </Offcanvas>
            <ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
                <Toast bg="dark" className="rounded-4 text-white border-0 w-auto" show={showToast} onClick={commonToastClose}>
                    <Toast.Body className="d-flex align-items-center p-3">
                        <img className="me-2 me-xxl-4 me-xl-3" src="assets/images/icons/success-light.svg" alt="arrow right" />
                        <span className="me-2">{t("NonPayment.scheduledContact")}.</span>
                        <span className="ms-3 cursor-pe" onClick={commonToastClose}><img src="assets/images/icons/times-white.svg" alt="arrow right" /></span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default ScheduleContact
