import { Table, Card, Dropdown, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, Collapse, InputGroup, ListGroup } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useMemo } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import '../../../../App.css';
import { openErrorbox } from '../Common/actionPerform';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, PrintPDF, encrypt_Decrypt, handleKeyDown, checkDuplicateCNJP_CPF } from '../../../constants/utils';
import i18n from '../../../../i18n';
import { GetRepresentativeService, contractOpen, openEmailPop, tenantsClose, tenantsOpen } from '../../../../redux/slice/contractDataSlice';
import { getManager } from '../../../../redux/slice/propertyDataSlice';
import RepresentativeService from '../../NewDesigns/Contracts/RepresentativeService';

const TenantsService = (props) => {
    const { iddata, type, tenant, gettenant, getAllTenant, tenstate } = props;
    const dispatch = useDispatch<AppDispatch>()
    const { newTenantsShow,pessoasType } = useSelector((state: RootState) => state.contractData)
    const { managerData } = useSelector((state: RootState) => state.propertyData)
    const [hide, setHide] = useState(false);
    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(getManager());
    }, [dispatch]);

    const [repreData, setRepreData] = useState([])
    const handleRepresentative = async (e: any) => {
        let resp = await dispatch(GetRepresentativeService())
        if (resp.payload.type == "success" || resp.payload.type == "Success") {
            setRepreData(resp.payload.data)
        }
    }

    const [ isEmail,setIsEmail ] = useState(false);
    const [ isNewEmail,setIsNewEmail ] = useState("");
    const [ isOldEmail,setIsOldEmail ] = useState("");

    useEffect(() => {
        if (newTenantsShow) {
            const fetchData = async () => {
                try {
                    const resp = await dispatch(GetRepresentativeService());
                    if (resp.payload.type.toLowerCase() === "success") {
                        setRepreData(resp.payload.data);
                    }
                } catch (error) {
                    console.error("Error fetching representative data:", error);
                }
            };

            fetchData();
        }
    }, [newTenantsShow, dispatch]);


    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        if (newTenantsShow) {
            let userResult = encrypt_Decrypt();
            if (!userResult || userResult.indexOf(1000) === -1) {
                navigate("/dashboard");
            }
            setUserJson(userResult);
            if (newTenantsShow && showrep) {
                setHide(true);
            }
        }
    }, [newTenantsShow])

    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });


    const [t] = useTranslation();
    const [loader_on, setloader_on] = useState(false);
    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [headingText, setHeadingText] = useState(t('customerAndEmployees.newTenant'));
    const [double, setDouble] = useState(false);
    const [editTenant, setEditTenant] = useState(false)
    const [formData, setFormData] = useState<any>([]);
    const [show3, setShow3] = useState(false);
    const [ProfileType, setProfileType] = useState()
    const [EmailReadOnly, setEmailReadOnly] = useState(false)
    /*--------Data table listing state & multiple checked--------*/
    const [AllStates, setAllStates] = useState([]);
    const [showHideField, setShowHideField] = useState<any>("")
    const [addressError, setaddressError] = useState(false)
    const [showNewRepresentativeModal, setNewRepresentativeModal] = useState(false);
    const [showrep, setshowRep] = useState(true)
    const newRepresentativeModalShow = () => {
        setNewRepresentativeModal(true);
        setShow(false)
        dispatch(tenantsClose(false))
    }

    const newRepresentativeModalClose = () => {
        setNewRepresentativeModal(false);
        setHide(false)
        setHide2(true)
        setshowRep(false)
        dispatch(tenantsOpen(true))
    }

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, []);
    /*--------Common modal function--------*/

    /*--------Common modal function--------*/
    useEffect(() => {
        if (newTenantsShow && iddata) {
            EditTenant(iddata)
        }
    }, [newTenantsShow])

    const formClose = () => {
        setIsOldEmail("");
        setIsNewEmail("");
        setIsEmail(false);
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        setHide(false); setHide2(false); setHide3(false); setShow(false); reset();
    }

    /*-------Step form function & validation--------*/
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => { setHide(true); setHide2(false); setHide3(false); }
    const step2 = () => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false); setHide2(true); setHide3(false);
        }
    }
    const step3 = () => {
        setHide(false);
        setHide2(false);
        setHide3(true);
    }

    /*-------------Form validation-------------*/

    const mailValidationSchema = Yup.object().shape({
        identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        model: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        variable: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    const mailLabelValidationSchema = Yup.object().shape({});
    if (show3) {
        frm = {
            resolver: yupResolver(mailLabelValidationSchema)
        }
        openErrorbox();
    }
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let regexPattern = /^[^0-9./-]*$/;
    if (showHideField === "cnpj") {
        regexPattern = /^[a-zA-Z0-9 ]*$/;
    }

    let formValidationSchemaStep1:any ={
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    }
    let formValidationSchemaStep2:any = {
        name: Yup.string().trim().nullable().when([], {
            is: () => headingText == "Update inspector" || headingText == "Atualizar inspetor",
            then: (schema) => schema.max(50, t('requiredMmessage.50characters')), // Custom max length message
        })
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        // password: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(passwordRules, { message: t("requiredMmessage.password") }),
    }   
    
    let formValidationSchemaStep5:any = {
        name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    }
    if (hide || hide2 || hide3) {
        
        if(isEmail){
            let emailVaild:any = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
            }
            if(isNewEmail!=isOldEmail){
                let passwordValid = {
                    password: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .matches(passwordRules, { message: t("requiredMmessage.password") }),
                }
                emailVaild = {...emailVaild,...passwordValid }
            }
            formValidationSchemaStep1 = { ...formValidationSchemaStep1,...emailVaild}
        }
        let ownerValid = Yup.object().shape(formValidationSchemaStep1)
        frm = { resolver: yupResolver(ownerValid) }

        if (hide2) {
           
           if(isEmail){
                let emailVaild:any = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),
                }
                if(isNewEmail!=isOldEmail){
                    let passwordValid = {
                        password: Yup.string().trim().nullable()
                        .required(t('requiredMmessage.fieldRequired'))
                        .matches(passwordRules, { message: t("requiredMmessage.password") }),
                    }
                    emailVaild = {...emailVaild,...passwordValid }
                }
                formValidationSchemaStep2 = { ...formValidationSchemaStep2,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep2)
            frm = { resolver: yupResolver(ownerValid) }

        }
        if (hide2 && showHideField == "cnpj") {
            
            if(isEmail){
                let emailVaild:any = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),
                }
                if(isNewEmail!=isOldEmail){
                    let passwordValid = {
                        password: Yup.string().trim().nullable()
                        .required(t('requiredMmessage.fieldRequired'))
                        .matches(passwordRules, { message: t("requiredMmessage.password") }),
                    }
                    emailVaild = {...emailVaild,...passwordValid }
                }
                formValidationSchemaStep2 = { ...formValidationSchemaStep2,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep2)
            frm = { resolver: yupResolver(ownerValid) }
        
        }
        if (hide2 && false) {
            
            if(isEmail){
                let emailVaild:any = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),
                }
                if(isNewEmail!=isOldEmail){
                    let passwordValid = {
                        password: Yup.string().trim().nullable()
                        .required(t('requiredMmessage.fieldRequired'))
                        .matches(passwordRules, { message: t("requiredMmessage.password") }),
                    }
                    emailVaild = {...emailVaild,...passwordValid }
                }
                formValidationSchemaStep2 = { ...formValidationSchemaStep2,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep2)
            frm = { resolver: yupResolver(ownerValid) }

        }
        if (headingText == "Update guarantor" || headingText == "Atualizar fiador") {
            
            if(isEmail){
                let emailVaild:any = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),
                }
                if(isNewEmail!=isOldEmail){
                    let passwordValid = {
                        password: Yup.string().trim().nullable()
                        .required(t('requiredMmessage.fieldRequired'))
                        .matches(passwordRules, { message: t("requiredMmessage.password") }),
                    }
                    emailVaild = {...emailVaild,...passwordValid }
                }
                formValidationSchemaStep5 = { ...formValidationSchemaStep5,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep5)
            frm = { resolver: yupResolver(ownerValid) }

        }
        if (headingText == "Update supplier" || headingText == "Atualizar fornecedor") {
            
            if(isEmail){
                let emailVaild:any = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),
                }
                if(isNewEmail!=isOldEmail){
                    let passwordValid = {
                        password: Yup.string().trim().nullable()
                        .required(t('requiredMmessage.fieldRequired'))
                        .matches(passwordRules, { message: t("requiredMmessage.password") }),
                    }
                    emailVaild = {...emailVaild,...passwordValid }
                }
                formValidationSchemaStep5 = { ...formValidationSchemaStep5,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep5)
            frm = { resolver: yupResolver(ownerValid) }
            
        }
    }
    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);

    const handleChangeInput = (e: any, index: any, type: string, field: string) => {
        let value = e.target.value;
        addState[type][index][field] = value;
        let allState: any = addState;
        setAddState(allState);
    }

    const [optionRendaen, setOptionRendaen] = useState([
        { value: "1", label: "Retired or Pensioner" },
        { value: "2", label: "Self-employed" },
        { value: "3", label: "Businessperson" },
        { value: "4", label: "Trainee" },
        { value: "5", label: "Student" },
        { value: "6", label: "Public agent" },
        { value: "7", label: "CLT employee" },
        { value: "8", label: "Liberal Professional" },
        { value: "9", label: "Rental income" }
    ])

    const [optionRendapt, setOptionRendapt] = useState([
        { value: "1", label: "Aposentado ou Pensionista" },
        { value: "2", label: "Autônomo" },
        { value: "3", label: "Empresário" },
        { value: "4", label: "Estagiário" },
        { value: "5", label: "Estudante" },
        { value: "6", label: "Funcionário Público" },
        { value: "7", label: "Funcionário CLT" },
        { value: "8", label: "Profissional Liberal" },
        { value: "9", label: "Renda proveniente de aluguel" }
    ]);

    const addAnother = (e: any, type: string) => {
        e.preventDefault();
        if (type == "retentionn") {
            let dataAdd: any = {
                ...addState,
                retentionn: [
                    ...addState["retentionn"],
                    { Tax: "", Aliquot: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "beneficiaries") {
            let dataAdd: any = {
                ...addState,
                beneficiaries: [
                    ...addState["beneficiaries"],
                    { name: "", transfer: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "representative") {
            let dataAdd: any = {
                ...addState,
                representative: [
                    ...addState["representative"],
                    { name: "" }
                ]
            }
            setAddState(dataAdd)
        }
    }

    const removeAddAnother = (index: any, array: any, type: any) => {
        array.splice(index, 1);
        // let TypeData = type;
        // reset(TypeData);
        if (type == "retentionn") {
            array.forEach((item: any, index: number) => {
                let Tax: any = `retentionn.${index}.tax`;
                let Aliquot: any = `retentionn.${index}.Aliquot`;
                setValue(Tax, item.Tax);
                setValue(Aliquot, item.Aliquot);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "beneficiaries") {
            array.forEach((item: any, index: number) => {
                let name: any = `beneficiaries.${index}.name`;
                let transfer: any = `beneficiaries.${index}.transfer`;
                setValue(name, item.name);
                setValue(transfer, item.transfer);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "representative") {
            array.forEach((item: any, index: number) => {
                let name: any = `representative.${index}.name`;
                setValue(name, item.name);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }
    }

    /*-------Call the api and save the tenants form data--------*/
    const formSubmit = (data: CustomersAndEmployeesForm) => {
        setloader_on(true)
        let owner = {
            id: data.id,
            type: data.type,
            tenantId: data.tenantId,
            cnpj: data.cnpj,
            name: data.name || data.fantasyName,
            payment: data.payment,
            mobile: data.mobile,
            telephone: data.telephone,
            email: data.email ? data.email : "",
            password: data.password ? data.password : "",
            mother: data.mother,
            father: data.father,
            rg: data.rg,
            issue_agency: data.issue_agency,
            shipping_date: data.shipping_date,
            sex: data.sex,
            birth: data.birth,
            marital_status: data.marital_status,
            nationality: data.nationality,
            zipcode: data.zipcode,
            address: data.address,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            birth_place: data.birth_place,
            state: data.state,
            employment_company: data.employment_company,
            employment_profession: data.employment_profession,
            employment_field_activity: data.employment_field_activity,
            employment_monthly_income: data.employment_monthly_income,
            employment_zipcode: data.employment_zipcode,
            employment_address: data.employment_address,
            employment_number: data.employment_number,
            employment_complement: data.employment_complement,
            employment_neighborhood: data.employment_neighborhood,
            employment_city: data.employment_city,
            employment_state: data.employment_state,
            representative_name: data.representative_name,
            retention: data.retention,
            observation: data.observation,
            is_vendor: data.is_vendor,

            name_of: data.name,
            cnpjcpf: data.cnpj,

            spouse_name: data.spouse_name,
            spouse_cpf: data.spouse_cpf,
            spouse_rg: data.spouse_rg,
            spouse_issue_agency: data.spouse_issue_agency,
            spouse_nationality: data.spouse_nationality,
            spouse_sex: data.spouse_sex,
            spouse_profession: data.spouse_profession,
            spouse_cellphone: data.spouse_cellphone,
            spouse_telephone: data.spouse_telephone,
            spouse_email: data.spouse_email,
            spouse_observation: data.spouse_observation,
            formData: data.formData,
            block_balance: data.block_balance,
            manager: data.manager,
            corporateReason: data.corporateReason,
            stateRegistration: data.stateRegistration,
            municipalRegistration: data.municipalRegistration,
            ID_SACADO_SAC: data.ID_SACADO_SAC,
            ID_FAVORECIDO_FAV: data.ID_FAVORECIDO_FAV,
            ID_PESSOAS: data.ID_PESSOAS,
            new_added: {
                addState: addState,
                Renda: {
                    FL_SEMRENDA_PES: data.possiu ?? "",
                    FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                    ST_BENEFICIO_PES: data.beneficio ?? "",
                    VL_OUTROSRENDIMENTOS_PES: data.rendimentous ?? ""
                },
                Retencao_de_impostos: {
                    FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
                },
                Mais_opcoes: {
                    NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                    FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                    FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
                },
                Fornecedor: {
                    FL_SINCRONIZARSACADO_FAV: data.supplieradv ?? ""
                },
                Pagemento: {
                    DT_DIAREPASSE_PES: data.Repassedia ?? "",
                    VL_SALDOBLOQUEADO_PES: data.bloquendo ?? ""
                }
            }
        }
        if (data.id !== "" && data.id !== undefined) {
            customerService.UpdateSingleTenant(owner).then(res => {
                if (res.data.resCode!=520 &&( res.data.type == "success" || res.data.type == "Success")) {
                    dispatch(tenantsOpen(false))
                    formClose();
                    if (data.type !== "Inspector") {
                        //getAllTenant(tenstate)
                        gettenant(data?.id)
                    } else {

                    }
                    let message:any = t("Sweetalert.successget");
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: message,
                        // icon: 'success',"success",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
                else if(res.data.resCode === 520 || res.data.resCode == 520){
                    setloader_on(false);
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.successgetSsoError"),
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                } 
                else {
                    let msg = res.data.message;
                    if (res.data.resCode == 409) {
                        msg = t("Sweetalert.unqueEmails");
                    }
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: msg ? msg : t("Sweetalert.somethingWent"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
                setloader_on(false);
            }).catch(error => {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setloader_on(false);
            });
        } else {
            customerService.createOwner(owner).then(res => {
                if (res.data.resCode === 202) {
                    formClose();
                    //getAllTenant(tenstate)
                    dispatch(tenantsOpen(false))
                    let message:any = t("Sweetalert.successget");
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: message,
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                    if (isChecked == true) {
                        setShow(true);
                    }
                    props.getAllTenant(props.tenstate)
                } 
                else if(res.data.resCode === 520 || res.data.resCode == 520){
                    setloader_on(false);
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.successgetSsoError"),
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                }
                else {
                    let msg = ((res.data?.data && res.data.data.length) || res.data.data) ? res.data.message : '';
                    if (res.data.resCode == 409) {
                        msg = t("Sweetalert.unqueEmails");
                    }
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: msg ? msg : t("Sweetalert.somethingWent"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
                setloader_on(false)
            }).catch(error => {
                /*-------Error exception handling--------*/
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setloader_on(false)
            });
        }
    }

    const handleRepersen = (e: any, index) => {
        let target_name = e.target.name;
        let target_value = e.target.value;
        addState["representative"][`${index}`]["name"] = String(target_value);
        setAddState(addState)

    }
    /*-------Get the tenants listing--------*/

    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
        goodofZip: "",
    });

    useEffect(() => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (zipvalue.zip == "" && zipvalue.empzip == "" || zipvalue.goodofZip == "") {
            zipCodeCheck?.["removeAttribute"]('disabled');
        } else {
            zipCodeCheck?.["setAttribute"]('disabled', "")
        }
    }, [zipvalue])

    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        let querySelthird: any = ''
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="employment_zipcode"]');
        if (formData.length > 0) {
            formData?.forEach((curElem, index) => {
                querySelthird = document.querySelector(`.accordion-custom [name="formData.${index}.Zip_code"]`);
            });
        }
        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str,
            })
        } else if (forwhich == "second") {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["goodofZip"]: str
            })
        }

        if (value != "") {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("address", data)
                    }
                } else if (forwhich == "second") {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("employment_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("employment_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("employment_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("employment_address", data)
                    }
                } if (formData.length > 0) {
                    const updatedFormData = [...formData];
                    formData?.forEach((curElem, index) => {
                        querySelthird.style.border = "0px solid red";
                        if (res[0]["city"]) {
                            updatedFormData[index] = { ...updatedFormData[index], ["Zip_code"]: str };
                            setValue(`formData.${index}.Zip_code`, str)
                        }
                        if (res[0]["city"]) {
                            updatedFormData[index] = { ...updatedFormData[index], ["City"]: res[0]["city"] };
                            setValue(`formData.${index}.City`, res[0]["city"])
                        }
                        if (res[0]["neigbohood"]) {
                            updatedFormData[index] = { ...updatedFormData[index], ["Neighborhood"]: res[0]["neigbohood"] };
                            setValue(`formData.${index}.Neighborhood`, res[0]["neigbohood"])
                        }
                        if (res[0]["state"]) {
                            updatedFormData[index] = { ...updatedFormData[index], ["state_of"]: res[0]["state"] };
                            setValue(`formData.${index}.state_of`, res[0]["state"])
                        }
                        if (res[0]["street"] || res[0]["streetType"]) {
                            let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                            updatedFormData[index] = { ...updatedFormData[index], ["Address"]: data };
                            setValue(`formData.${index}.Address`, data)
                        }
                    })
                    setFormData(updatedFormData)
                }

            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("city", "")
                    setValue("neighborhood", "")
                    setValue("state", "")
                    setValue("address", "")
                } else {
                    querySelsecond.style.border = "2px solid red"
                    setValue("employment_city", "")
                    setValue("employment_neighborhood", "")
                    setValue("employment_state", "")
                    setValue("employment_address", "")
                }
            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }

    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }


    const [spouse, setspouse] = useState(false);
    const handleChangeMaritail = (e) => {
        if (e.target.value == "1" || e.target.value == "7") {
            setspouse(true);
        } else {
            setspouse(false);
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            setShowHideField(checkCNJP?.["type"])
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                let boolVal = false
                if ((headingText == "New Tenant" || headingText == "Novo Locatário") && duplicateData.data?.[0]?.["FL_STATUS_SAC"] !== 1) {
                    boolVal = duplicateData["data"][0]?.FL_LOCATARIO_PES == 1
                } else if (headingText == "New Guarantors" || headingText == "New Guarantor" || headingText == "Novo Fiador") {
                    boolVal = duplicateData["data"][0]?.FL_FIADOR_PES == 1
                } else if (headingText == "New Brokers" || headingText == "New Broker" || headingText == "Nova Corretora") {
                    boolVal = duplicateData["data"][0]?.FL_CORRETOR_PES == 1
                } else if (headingText == "New Inspectors" || headingText == "Novo Inspetora") {
                    boolVal = duplicateData["data"][0]?.FL_VISTORIADOR_PES == 1
                } else if (headingText == "Novo Fornecedor" || headingText == "New Supplier") {
                    if (duplicateData["data"][0]?.ID_FAVORECIDO_FAV) {
                        boolVal = true
                    }
                } else {
                    if (duplicateData["data"][0]?.ID_PESSOA_PES == iddata) {
                        boolVal = false
                    } else {
                        if (duplicateData.data?.[0]?.["FL_STATUS_SAC"] !== 1) {
                            boolVal = true
                        }
                    }
                }

                setValue("ID_PESSOAS", duplicateData["data"][0]?.ID_PESSOA_PES);
                const fieldsMapping: any = {
                    email: "ST_EMAIL_PES",
                    name: "ST_NOME_PES",
                    mobile: "ST_CELULAR_PES",
                    complement: "ST_COMPLEMENTO_PES",
                    zipcode: "ST_CEP_PES",
                    city: "ST_CIDADE_PES",
                    address: "ST_ENDERECO_PES",
                    state: "ST_ESTADO_PES",
                    number: "ST_NUMERO_PES",
                    neighborhood: "ST_BAIRRO_PES",
                    telephone: "ST_TELEFONE_PES",
                    observation: "ST_OBSERVACAO_PES",
                    nationality: "ST_NACIONALIDADE_PES",
                    sex: "ST_SEXO_PES",
                    birth: "DT_NASCIMENTO_PES",
                    birth_place: "ST_NATURALIDADE_PES",
                    marital_status: "ST_ESTADOCIVIL_PES",
                    employment_company: "ST_TRABALHOCEP_PES",
                    employment_profession: "ST_PROFISSAO_PES",
                    employment_field_activity: "ST_RAMOATIVIDADE_PES",
                    employment_address: "ST_TRABALHOENDERECO_PES",
                    employment_number: "ST_TRABALHONUMERO_PES",
                    employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
                    employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
                    employment_city: "ST_TRABALHOCIDADE_PES",
                    employment_state: "ST_TRABALHOESTADO_PES",
                    employment_monthly_income: "VL_RENDAMENSAL_PES",
                    rg: "ST_RG_COJ",
                    retention: "VL_TXISSQN_PES",
                    mother: "ST_NOMEMAE_PES",
                    father: "ST_NOMEPAI_PES",
                    shipping_date: "DT_EXPEDICAORG_PES",
                    block_balance: "VL_SALDOBLOQUEADO_PES",
                    manager: "ID_GESTOR_GES",
                    issue_agency: "ST_ORGAO_PES",
                    corporateReason: "ST_NOME_PES",
                    stateRegistration: "ST_IE_PES",
                    municipalRegistration: "ST_INSCMUNICIPAL_PES"
                };

                if (duplicateData["data"].length > 0) {
                    if (boolVal) {
                        zipCodeCheck?.["setAttribute"]('disabled', "")
                        setcnpjcpferrormessage({
                            name: name,
                            message: t("formLabel.ErrorCNPJCPF")
                        })
                        setError(name, {
                            type: "manual",
                            message: t("formLabel.ErrorCNPJCPF")
                        });
                    } else {
                        zipCodeCheck?.["removeAttribute"]('disabled');

                        // Assuming `duplicateData` is correctly populated
                        const data: any = duplicateData?.["data"]?.[0];

                        if (data) {
                            Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                                setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
                            });
                        }
                    }
                } else {
                    const matchingHeadings = [
                        "New Tenant", "Novo Locatário",
                        "New Guarantor", "Novo Fiador",
                        "New Broker", "Novo Corretor",
                        "New Inspector", "Novo Vistoriador",
                        "New Supplier", "Novo Fornecedor"
                    ];

                    if (matchingHeadings.includes(headingText)) {
                        Object.entries(fieldsMapping || {}).forEach(([fieldName, dataKey]) => {
                            setValue(fieldName as keyof CustomersAndEmployeesForm, "");
                            setValue('ID_FAVORECIDO_FAV', "")
                            setValue('ID_SACADO_SAC', "")
                        });
                    } else {
                        const data: any = duplicateData?.["data"]?.[0];
                        if (data) {
                            Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                                setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
                            });
                        }
                    }
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });

            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
        };
    }
    useEffect(() => {
        if (newTenantsShow) {
            const fetchData = async () => {
                try {
                    const resp = await dispatch(GetRepresentativeService());
                    if (resp.payload.type.toLowerCase() === "success") {
                        setRepreData(resp.payload.data);
                    }
                } catch (error) {
                    console.error("Error fetching representative data:", error);
                }
            };

            fetchData();
        }
    }, [newTenantsShow, dispatch]);
    const newTenantsClose = () => {
        setIsEmail(false);
        setIsNewEmail("");
        setIsOldEmail("");
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        dispatch(tenantsClose(false))
        dispatch(contractOpen(true));
        setDouble(false);
        setHide2(false);
        reset();
    }
    const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hide2) {
            step1();
        } else if (hide3) {
            step2();
        } else {
            step1();
        }
    }
    const handleNameof = (e) => {
        let { value } = e.target
        // Error validation
        if (value == "" || value == null || value == undefined) {
            // Set an error message using setError or update your error state
            setError("name", {
                type: "manual",
                message: (t('requiredMmessage.fieldRequired'))
            });
        } else {
            if (headingText == "Atualizar inspetor") {
                if (value.length > 50) {
                    setError("name", {
                        type: "manual",
                        message: (t('requiredMmessage.50characters'))
                    });

                } else {
                    clearErrors('name');
                }
            }
        }
    }
    const [showPasswordInspection, setshowPasswordInspection] = useState<any>(true);
    const EditTenant = (id: number) => {
        customerService.getSingleCustomer(id)
            .then((res) => {
                reset()
                if (res.data.type === "success" || res.data.type === "Success") {
                    setEditTenant(true);

                    let type:any = res.data.data[0]?.type;
                    if (type && type.length > 0 && (type == "Inspector" || pessoasType=="Inspectors")) {
                        setshowPasswordInspection(false);
                        type = 'Inspector'
                        setProfileType(type)
                    }
                    else {
                        setProfileType(type)
                    }

                    switch (type) {
                        case "Tenant":
                            setHeadingText(t('customerAndEmployees.updateTenant'));
                            break;
                        case "Guarantor":
                            setHeadingText(t('customerAndEmployees.updateguarantor'));
                            break;
                        case "Broker":
                            setHeadingText(t('customerAndEmployees.updatebroker'));
                            break;
                        case "Inspector":
                            setHeadingText(t('customerAndEmployees.updateinspector'));
                            break;
                        default:
                            setHeadingText(t('customerAndEmployees.updatesupplier'));
                            break;
                    }

                    const fields: string[] = ['tenantId', 'type', 'id', 'cnpj', 'name', 'cnpjcpf', 'mobile', 'telephone', 'email', 'mother', 'father', 'corporateReason', 'stateRegistration', 'municipalRegistration', 'rg', 'issue_agency', 'shipping_date', 'sex', 'birth', 'marital_status', 'nationality', 'birth_place', 'zipcode', "address", 'number', 'complement', 'neighborhood', 'city', 'state', 'employment_company', 'employment_profession', 'employment_field_activity', 'employment_monthly_income', 'employment_zipcode', 'employment_address', 'employment_number', 'employment_complement', 'employment_neighborhood', 'employment_city', 'employment_state', 'representative_name', 'retention', 'observation', 'spouse_name', 'spouse_cellphone', 'spouse_cpf', 'spouse_issue_agency', 'spouse_nationality', 'spouse_observation', 'spouse_profession', 'spouse_sex', 'spouse_telephone', 'spouse_rg', 'spouse_email', 'manager'];
                    fields.forEach((field: any) => setValue(field, res.data.data[0]?.[field]));
                    const guaranterField: string[] = ['GoodofGuarantorid', 'Property_Type', 'Property_Value', 'Zip_code', 'Neighborhood', 'Address', 'Number', 'Complement', 'state_of', 'City', 'Registry', 'Registration', 'Building_con'];
                    if (res.data.data.length > 0) {
                        const updatedFormData = [...formData];
                        res?.data?.data[0]?.formData.forEach((data: any, index: number) => {
                            guaranterField.forEach((field: string) => {
                                updatedFormData[index] = { ...updatedFormData[index], [field]: data[field] }
                                let fieldsAre: any = `formData.${index}.${field}`;
                                setValue(fieldsAre, data[field]);
                            });
                        });
                        setFormData(updatedFormData)
                        setIsOldEmail(res?.data?.data[0]?.email)
                        if (res?.data?.data[0]?.email !== "" && res?.data?.data[0]?.email !== null) {
                            setEmailReadOnly(true)
                        } else {
                            setEmailReadOnly(false)
                        }
                    }
                    if(pessoasType=="Inspectors"){
                        setValue('type','Inspector')
                    }
                    const checkCNJP: any = CNJP_CPFValidation(res.data.data[0].cnpj, "both");
                    let repenData = res.data.data[0]?.repersentive || [];
                    if (repenData && repenData.length > 0) {
                        let allRepresentative: any = [];
                        repenData.forEach((item: any, index: number) => {
                            allRepresentative.push({ "name": item.ST_NOME_REJ })
                            let varDta: any = `representative.${index}.name`
                            setValue(varDta, item.ST_NOME_REJ);
                        })
                        addState["representative"] = allRepresentative;
                        setAddState(addState);
                    }
                    setShowHideField(checkCNJP?.type);
                    setspouse(res.data.data[0].marital_status === 1 || res.data.data[0].marital_status === 7);
                    const emailInputs = document.querySelectorAll('.paymentsDis');
                    emailInputs.forEach(element => {
                        element.setAttribute('readonly', 'true');
                        element.setAttribute('disabled', 'true');
                    });
                }

            })
            .catch((err) => {
                console.log("err", err);
            });

    }
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedFormData = [...formData]; // Create a copy of the formData array
        const dataIndex = name.split('.')[1]; // Extract the index from the name attribute
        if (dataIndex !== undefined) {
            // If the name attribute contains an index, update the corresponding nested object
            const nestedIndex = parseInt(dataIndex); // Parse the index to an integer
            updatedFormData[nestedIndex] = { ...updatedFormData[nestedIndex], [name.split('.')[2]]: value };
        } else {
            // If the name attribute does not contain an index, update the main object
            updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        }
        setFormData(updatedFormData); // Update the state with the modified array
    };


    const addInput = () => {
        setFormData([...formData, { GoodofGuarantorid: '', Property_Type: '', Property_Value: '', Neighborhood: '', Zip_code: '', Address: '', Number: '', Complement: '', state_of: '', City: '', Registry: '', Registration: '', Building_con: '', }]);
    };


    const removeInput = (index) => {
        formData.splice(index, 1);
        setFormData([...formData]);
        formData.forEach((item, indx) => {
            setValue(`formData.${indx}.Property_Type`, item.Property_Type);
            setValue(`formData.${indx}.Property_Value`, item.Property_Value);
            setValue(`formData.${indx}.Zip_code`, item.Zip_code);
            setValue(`formData.${indx}.Neighborhood`, item.Neighborhood);
            setValue(`formData.${indx}.Address`, item.Address);
            setValue(`formData.${indx}.Number`, item.Number);
            setValue(`formData.${indx}.Complement`, item.Complement);
            setValue(`formData.${indx}.state_of`, item.state);
            setValue(`formData.${indx}.City`, item.City);
            setValue(`formData.${indx}.Registry`, item.Registry);
            setValue(`formData.${indx}.Registration`, item.Registration);
            setValue(`formData.${indx}.Building_con`, item.Building_con);
        })
        // If you need to update the state or any other variable with the updated data, do it here
    };
    const Handleaddress = (e) => {
        let { value } = e.target;
        let numberOfCharacters = value.length;
        if (numberOfCharacters > 40) {
            setaddressError(true)

        } else {
            setaddressError(false)
        }
    }

    const handleEmail = (e)=>{
        e.preventDefault();
        let vl = e.target.value;
        if(vl && vl!=""){
            setIsEmail(true);
            setIsNewEmail(vl);
        }
        else {
            setIsEmail(false);
            setIsNewEmail("");
        }
    }

    return (
        <>
            {/* <!-- New Tenants modal start from here --> */}
            <Modal show={newTenantsShow} onHide={newTenantsClose} className='TenanttServicestsx'>
                <Modal.Header closeButton>
                    <Modal.Title>{headingText}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Body>
                        <input type="text" {...register('ID_SACADO_SAC')} hidden />
                        <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden />
                        <input type="text" {...register('ID_PESSOAS')} hidden />

                        <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                            <input type="hidden" value={headingText} {...register('type')} />
                            <input type="hidden" {...register('id')} />
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf') + " *"}>
                                <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={` ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </div>

                        <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                            {showHideField == "cnpj" ?
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.corporateReason")}>
                                            <Form.Control type="text" placeholder="Corporate reason" className={`form-control ${errors.corporateReason ? 'is-invalid' : ''}`} {...register('corporateReason')} />
                                            <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.fantasyName")}>
                                            <Form.Control type="text" placeholder="Fantasy name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleNameof(e)} />
                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.stateregistration")}>
                                            <Form.Control type="text" placeholder="State registration" maxLength={20} className={`form-control ${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.municipalregistration")}>
                                            <Form.Control type="text" placeholder="Municipal registration" maxLength={20} className={`form-control ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row> :
                                <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3 mt-2" >
                                    <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            }

                            {/* Bank Form End Here */}

                            <span className="d-block mb-3 fw-bold">{t('formLabel.contact')}</span>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber') + " *"}>
                                        <Form.Control type="tel" placeholder={t('formLabel.mobileNumber')} className={` ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                        <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                        <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                <Form.Control type="email" placeholder={t('formLabel.email')} className={`${errors.email ? 'is-invalid' : ''}`}  {...register('email')} onKeyUp={(e) => handleEmail(e)}
                                    readOnly={EmailReadOnly && (headingText == "Inspector" || headingText == "Update inspector" || headingText == "Atualizar inspetor") ? true : false}
                                    disabled={EmailReadOnly && (headingText == "Inspector" || headingText == "Update inspector" || headingText == "Atualizar inspetor") ? true : false}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            {(editTenant || !showPasswordInspection) ?
                                <>
                                    <FloatingLabel controlId="floatingInput" label={(headingText == 'New Tenant' || headingText == 'Novo Locatário') ? t('formLabel.mobilePassword') : t('formLabel.password')} className="mb-3" >
                                        <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`} {...register('password')} />
                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </>
                                :
                                <>
                                    <FloatingLabel controlId="floatingInput" label={t('formLabel.password')} className="mb-3" >
                                        <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />
                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                    </FloatingLabel>

                                </>
                            }
                            <Accordion className="accordion-custom">
                                {showHideField == "cpf" &&
                                    <>
                                        {ProfileType == "Inspector" || ProfileType == "Broker" ?
                                            "" :
                                            <Accordion.Item eventKey="1" style={{ display: hide4 ? 'none' : 'block' }}>
                                                <Accordion.Header>{t('formLabel.affiliation')}</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.motherName')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.motherName')}  {...register('mother')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fatherName')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.fatherName')}  {...register('father')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>}
                                        {ProfileType == "Inspector" || ProfileType == "Broker" || ProfileType == "Guarantor" || ProfileType == "Tenant" || headingText == 'New Tenant' || headingText == 'Novo Locatário' ?
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                                <Form.Control type="text" placeholder={t('RG')}  {...register('rg')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('issue_agency')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                                                <Form.Control type="date" placeholder={t('formLabel.shippingDate')}  {...register('shipping_date')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                                <Form.Select {...register('sex')} aria-label={t('formLabel.sex')}>
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    <option value="1">{t('masculine')}</option>
                                                                    <option value="2">{t('feminine')}</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.birth')}>
                                                                <Form.Control type="date" placeholder={t('formLabel.birth')}  {...register('birth')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                                                <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} onChange={(e) => handleChangeMaritail(e)}>
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    <option value="1">{t("formLabel.married")}</option>
                                                                    <option value="2">{t("formLabel.single")}</option>
                                                                    <option value="3">{t("formLabel.widower")}</option>
                                                                    <option value="4">{t("formLabel.legallySeparated")}</option>
                                                                    <option value="5">{t("formLabel.disquitado")}</option>
                                                                    <option value="6">{t("formLabel.divorced")}</option>
                                                                    <option value="7">{t("formLabel.stableunion")}</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FloatingLabel className="mb-3" controlId="Birthplace" label={t('formLabel.Birthplace')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.birth')}  {...register('birth_place')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('nationality')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('dependentsNo')}>
                                                                <Form.Control type="text" placeholder={t('dependentsNo')}  {...register('dependentsNo')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col xs={12} className='mt-1'>
                                                            <Accordion.Body>
                                                                <Form.Check type="checkbox" {...register('non_recedential')} label={t('non_recedential')} />
                                                            </Accordion.Body>
                                                        </Col>
                                                        <Col md={12} className='mt-1'>
                                                            <Form.Check type="checkbox" {...register('donotnotify')} label={t('donotnotify')} />
                                                        </Col>
                                                        {/* <Col md={12} className='mt-1'>
                                                            <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                                <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    {managerData?.map((item) => (
                                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col> */}
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            : ""
                                        }
                                    </>

                                }

                                {
                                    spouse &&
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>{t('formLabel.spouse')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('spouse_name')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.cpf')}  {...register('spouse_cpf')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                        <Form.Control type="text" placeholder={t('RG')}  {...register('spouse_rg')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('spouse_issue_agency')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.profession')}  {...register('spouse_profession')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                        <Form.Select {...register('spouse_sex')} aria-label={t('formLabel.sex')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t('masculine')}</option>
                                                            <option value="2">{t('feminine')}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cellPhone')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.cellPhone')}  {...register('spouse_cellphone')} onChange={HandleInputMob} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('spouse_telephone')} onChange={HandleInput} />

                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                        <Form.Control type="email" placeholder={t('formLabel.email')}  {...register('spouse_email')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('spouse_nationality')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.observation')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.observation')}  {...register('spouse_observation')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                {
                                    showHideField == "cpf" &&
                                    <Accordion.Item eventKey="14">
                                        <Accordion.Header>{t('Renda')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col xs={12}>
                                                    <Accordion.Body>
                                                        <Form.Check type="checkbox" {...register('possiu')} label={t('possiu')} />
                                                    </Accordion.Body>
                                                </Col>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <FloatingLabel controlId="floatingSelect" label={t('vinculo')}>
                                                            <Form.Select {...register('vinculo')} onClick={handleRepresentative}>
                                                                <option value="">{t('formLabel.select')}</option>
                                                                {

                                                                    i18n.language == "en" ?
                                                                        optionRendaen.map((item: any, index: number) => {
                                                                            return (
                                                                                <option value={item.value}>{item.label}</option>
                                                                            )
                                                                        })
                                                                        :
                                                                        optionRendapt.map((item: any, index: number) => {
                                                                            return (
                                                                                <option value={item.value}>{item.label}</option>
                                                                            )
                                                                        })
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('beneficio')}>
                                                        <Form.Control type="text" placeholder={t('beneficio')}  {...register('beneficio')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('rendimentous')}>
                                                        <Form.Control type="text" placeholder={t('rendimentous')}  {...register('rendimentous')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }

                                {ProfileType == "Broker" || ProfileType == "Inspector" ?
                                    "" :
                                    <Accordion.Item eventKey="3" style={{ display: hide4 ? 'none' : 'block' }}>
                                        <Accordion.Header>{t('formLabel.address')} </Accordion.Header>
                                        <Accordion.Body>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                                <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} onChange={(e) => getLocation(e, "zipcode", "first")} />
                                            </FloatingLabel>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} onChange={(e) => Handleaddress(e)} />
                                                        {addressError ? <span className="text-primary"> <small>{t("requiredMmessage.40characters")}</small></span> : ""}
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                        <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('number')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('complement')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                        <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            {
                                                                AllStates.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                {
                                    showHideField == "cpf" || headingText == 'New Tenant' ?
                                        (ProfileType == "Inspector" || ProfileType == "Broker" || ProfileType !== "Guarantor" && ProfileType !== "Tenant") ?
                                            "" :
                                            <Accordion.Item eventKey="4" style={{ display: hide4 ? 'none' : 'block' }}>
                                                <Accordion.Header>{t('formLabel.employmentData')}</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.company')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.company')}  {...register('employment_company')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.profession')} {...register('employment_profession')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fieldOfActivity')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.fieldOfActivity')}  {...register('employment_field_activity')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')}  {...register('employment_monthly_income')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} onChange={(e) => getLocation(e, "zipcode", "second")} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                                <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                                <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    {
                                                                        AllStates.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        : ""
                                }
                                {ProfileType == "Inspector" || ProfileType == "Broker" ?
                                    "" :
                                    <>
                                        <Accordion.Item eventKey="5" style={{ display: hide4 ? 'none' : 'block' }}>
                                            <Accordion.Header>{t('formLabel.representative')}</Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    Object.keys(addState.representative).map((item: any, index: number) => {
                                                        return (
                                                            <Row className="mt-1">
                                                                <Col xs={11}>
                                                                    <InputGroup>
                                                                        <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                                                            <Form.Select {...register(`representative.${index}.name`)} onChange={(e) => handleRepersen(e, index)}>
                                                                                <option value="">{t('formLabel.select')}</option>
                                                                                {repreData?.map((curElem: any, index) => (
                                                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                                                ))}
                                                                            </Form.Select>

                                                                        </FloatingLabel>
                                                                        <InputGroup.Text onClick={newRepresentativeModalShow}>
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                                        </InputGroup.Text>
                                                                    </InputGroup>
                                                                </Col>
                                                                {
                                                                    Object.keys(addState.representative) &&
                                                                    Object.keys(addState.representative).length > 1 &&
                                                                    <Col md={1}>
                                                                        <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.representative, "representative")}>  <img src="../assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        )
                                                    })
                                                }
                                                < Col >
                                                    <div className="text-center mt-1">
                                                        <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother(e, "representative")}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.AddAnother')}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                }

                                {/* {ProfileType == "Inspector" || ProfileType == "Broker" ?
                                    "" :
                                    <Accordion.Item eventKey="6" style={{ display: headingText !== "Update guarantor" && headingText !== "Novo Fiador" ? 'block' : 'none' }}>
                                        <Accordion.Header>{t('formLabel.taxesRetention')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Form.Check type="checkbox" {...register('retention')} label={t('formLabel.withHoldIssqn')} />
                                        </Accordion.Body>
                                    </Accordion.Item>} */}
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8" style={{ display: headingText !== "New Guarantor" && headingText !== "Novo Fiador" && headingText !== "Update guarantor" && headingText !== "Update guarantor" && headingText !== "Atualizar fiador" ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.Goodoftheguarantor')}</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            {formData.map((_, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col>
                                                            <input type='text' {...register(`formData.${index}.GoodofGuarantorid`)} hidden />
                                                            <FloatingLabel label={t('formLabel.propertyType')}>
                                                                <Form.Select className={`form-control`} {...register(`formData.${index}.Property_Type`)} onChange={(e) => handleInputChange(index, e)}>
                                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                                    <option value="1">{t("propertyTypeData.Casa")}</option>
                                                                    <option value="4">{t("propertyTypeData.Apartamento")}</option>
                                                                    <option value="33">{t("propertyTypeData.ApartamentoDuplex")}</option>
                                                                    <option value="38">{t("propertyTypeData.ApartamentoTriplex")}</option>
                                                                    <option value="9">{t("propertyTypeData.Áreacomum")}</option>
                                                                    <option value="42">{t("propertyTypeData.Andarcorporativo")}</option>
                                                                    <option value="29">{t("propertyTypeData.Bangalo")}</option>
                                                                    <option value="13">{t("propertyTypeData.Barracao")}</option>
                                                                    <option value="40">{t("propertyTypeData.BoxGaragem")}</option>
                                                                    <option value="3">{t("propertyTypeData.Casacomercial")}</option>
                                                                    <option value="2">{t("propertyTypeData.Casaemcondominio")}</option>
                                                                    <option value="44">{t("propertyTypeData.CasaAssobradada")}</option>
                                                                    <option value="5">{t("propertyTypeData.Cobertura")}</option>
                                                                    <option value="14">{t("propertyTypeData.Chacara")}</option>
                                                                    <option value="25">{t("propertyTypeData.Conjunto")}</option>
                                                                    <option value="39">{t("propertyTypeData.Edicula")}</option>
                                                                    <option value="10">{t("propertyTypeData.Escritorio")}</option>
                                                                    <option value="20">{t("propertyTypeData.Fazenda")}</option>
                                                                    <option value="6">{t("propertyTypeData.Flat")}</option>
                                                                    <option value="16">{t("propertyTypeData.Galpao")}</option>
                                                                    <option value="8">{t("propertyTypeData.Garagem")}</option>
                                                                    <option value="43">{t("propertyTypeData.Garden")}</option>
                                                                    <option value="21">{t("propertyTypeData.Haras")}</option>
                                                                    <option value="35">{t("propertyTypeData.Hotel")}</option>
                                                                    <option value="31">{t("propertyTypeData.Ilha")}</option>
                                                                    <option value="7">{t("propertyTypeData.Kitnet")}</option>
                                                                    <option value="27">{t("propertyTypeData.Laje")}</option>
                                                                    <option value="30">{t("propertyTypeData.Loft")}</option>
                                                                    <option value="12">{t("propertyTypeData.Loja")}</option>
                                                                    <option value="41">{t("propertyTypeData.Pavilhao")}</option>
                                                                    <option value="32">{t("propertyTypeData.Penthouse")}</option>
                                                                    <option value="22">{t("propertyTypeData.Predio")}</option>
                                                                    <option value="28">{t("propertyTypeData.Pousada")}</option>
                                                                    <option value="19">{t("propertyTypeData.Ponto")}</option>
                                                                    <option value="18">{t("propertyTypeData.Quiosque")}</option>
                                                                    <option value="36">{t("propertyTypeData.Rancho")}</option>
                                                                    <option value="34">{t("propertyTypeData.Resort")}</option>
                                                                    <option value="15">{t("propertyTypeData.Salao")}</option>
                                                                    <option value="11">{t("propertyTypeData.Salacomercial")}</option>
                                                                    <option value="23">{t("propertyTypeData.Sitio")}</option>
                                                                    <option value="24">{t("propertyTypeData.Sobrado")}</option>
                                                                    <option value="37">{t("propertyTypeData.Studio")}</option>
                                                                    <option value="17">{t("propertyTypeData.Terreno")}</option>
                                                                    <option value="26">{t("propertyTypeData.Village")}</option>
                                                                    <option value="45">{t("propertyTypeData.Consultorio")}</option>
                                                                    <option value="1000">{t("propertyTypeData.Outro")}</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="propertyValue" label={t('newContractDev.propertyValue')}>
                                                                <Form.Control type="text" placeholder={t('newContractDev.propertyValue')} {...register(`formData.${index}.Property_Value`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="zipCode2" label={t('formLabel.zipCode')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register(`formData.${index}.Zip_code`)} onChange={(e) => getLocation(e, "zipcode", "third")} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="address2" label={t('formLabel.address')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.address')}  {...register(`formData.${index}.Address`)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="number2" label={t('formLabel.number')}>
                                                                <Form.Control type="number" placeholder={t('formLabel.number')}  {...register(`formData.${index}.Number`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="complement2" label={t('formLabel.complement')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register(`formData.${index}.Complement`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="neighborhood3" label={t('formLabel.neighborhood')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register(`formData.${index}.Neighborhood`)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="city2" label={t('formLabel.city')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.city')}  {...register(`formData.${index}.City`)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="state12" label={t('formLabel.state')}>
                                                                <Form.Select {...register(`formData.${index}.state_of`)} aria-label={t('formLabel.state')}>
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    {
                                                                        AllStates.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="registry" label={t('formLabel.registry')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.registry')}  {...register(`formData.${index}.Registry`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="registration" label={t('formLabel.registration')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.registration')}  {...register(`formData.${index}.Registration`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="buildingCondominium" label={t('formLabel.buildingCondominium')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.buildingCondominium')}  {...register(`formData.${index}.Building_con`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    {(formData.length > 1) ?
                                                        <Button variant="link" className="p-0 fw-bold" onClick={() => removeInput(index)}><span> <img src="../assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></span> </Button>
                                                        : ""}
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant="link" className="p-0 fw-bold" onClick={addInput}>{t("Add Another")}</Button>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Row style={{ display: headingText == "New Tenant" || headingText == "Novo Locatário" || headingText !== "Update guarantor" && headingText !== "Atualizar fiador" ? 'none' : 'block' }}>
                                    <Col xs={4}>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.balanceblocked')}>
                                            <Form.Control type="number" placeholder={t('formLabel.balanceblocked')}  {...register('block_balance')} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Accordion>
                        </div>
                        <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                            <div className="text-center">
                                <h3 className="mt-3 mb-2">{t('label.registeredSurveyor')}</h3>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-sm-flex justify-content-end w-100 align-items-center">
                        <div>
                            <Button variant="outline-primary" className="rounded-4 me-2 me-lg-3" onClick={newTenantsClose}>
                                {t('button.close')}
                            </Button>
                            {hide ? '' : <Button variant="outline-primary" onClick={(e) => handlePrevious(e)} className="js-btn-step me-2 me-lg-3" >
                                {t('button.previous')}
                            </Button>}
                            {!hide3 ?
                                <Button variant="primary" onClick={handleSubmit(hide ? step2 : step3)} className="min-w-auto js-btn-step zipCodeCheck btn-primary-focus-none" >
                                    {t('button.next')}
                                </Button>
                                :
                                <>
                                    {/* // <Button variant="primary" type="submit" onClick={openErrorbox} className='btn-primary-focus-none'>
                                //     {t('button.save')}
                                // </Button> */}
                                    {
                                        loader_on ?
                                            <Button className="btn-width">
                                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                            </Button>
                                            :
                                            <Button variant="primary" type="submit" onClick={openErrorbox} className="btn-primary-focus-none">
                                                {t('button.save')}
                                            </Button>
                                    }
                                </>
                            }
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <RepresentativeService showNewRepresentative={showNewRepresentativeModal} newRepresentativeClose={newRepresentativeModalClose} newRepresentativeShow={newRepresentativeModalShow} />
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default TenantsService;