import { config } from "../../../../services/apiConfig";

type CreateHomologateAgencyPayload = {
  CedenteRazaoSocial: string;
  CedenteNomeFantasia: string;
  CedenteCPFCNPJ: string;
  CedenteEnderecoLogradouro: string;
  CedenteEnderecoNumero: string;
  CedenteEnderecoComplemento: string;
  CedenteEnderecoBairro: string;
  CedenteEnderecoCEP: string;
  CedenteEnderecoCidadeIBGE: string;
  CedenteTelefone: string;
  CedenteEmail: string;
};

export async function createHomologateAgency(
  createHomologateAgencyPayload: CreateHomologateAgencyPayload
) {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(
    `${config.TRANSFER_API}/homologate/bankslip/agency`,
    {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        "connection-name": connectionName,
      },
      body: JSON.stringify(createHomologateAgencyPayload),
    }
  );

  const data = await response.json();

  return data.resCode === 201 || data.resCode === 200;
}
