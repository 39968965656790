import { createSlice } from "@reduxjs/toolkit";


const initialState ={
    taxadata: [],
    show: false
}

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers:{
        handleClo:(state,action) =>{
            state.show = action.payload
        },
        handleOpn:(state,action) =>{
            state.show = action.payload
        }
    }
})

export const {handleClo, handleOpn} = profileSlice.actions
export default profileSlice.reducer