import React, { useEffect, useState } from 'react';
import { Outlet, Link, useParams } from 'react-router-dom';
import { Form, Button, FloatingLabel, Row, Col, Alert, Offcanvas, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import customerService from '../../../../../../services/customerService';
import Swal from 'sweetalert2';
import moment from 'moment';
import { contractDatails } from '../../../../../../redux/slice/contractDataSlice';
import { AppDispatch, RootState } from '../../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Termination } from '../../../../../Interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { userService } from '../../../../../../services/userService';
import { minDate } from '../../../../../constants/utils';

const Terminate = (props) => {
	const [t] = useTranslation();
	const validationSchema = Yup.object().shape({
		termination_responsiblename: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
		termination_forecast: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
		termination_moveoutdate: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
		termination_reason: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
		// termination_observation: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
	});

	const [loader_on, setloader_on] = useState(false);
	const { id }: any = useParams();
	const dispatch = useDispatch<AppDispatch>();
	const { contractDatail } = useSelector((state: RootState) => state.contractData);
	//Terminate contract modal close open function
	const { showTerminateContract, terminateContractClose, terminateContractShow, ShowThisData, allresponsibleList, terminatonReason } = props;
	// Alert
	const [show, setShow] = useState(true);
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<Termination>({
		resolver: yupResolver(validationSchema),
	});
	//Form submit
	const onTerminateFormSubmit = (data) => {
		data["contract_id"] = id;
		setloader_on(true)
		customerService.initialTermination(data).then(async (res) => {
			if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
				await dispatch(contractDatails(id));
				Swal.fire({
					title: t("Sweetalert.Success"),
					text: t("Sweetalert.successget"),
					confirmButtonText: "OK",
					timer: 2000
				})
				closeTermination();
				setloader_on(false);
			} else {
				Swal.fire({
					title: t("Sweetalert.Error"),
					text: t("Sweetalert.successfailed"),
					confirmButtonText: "OK",
					timer: 2000
				})
				setloader_on(false);
			}
		})
	};

	const closeTermination = () => {
		terminateContractClose();
		setPendignCharge(0)
		reset();
	}

	useEffect(() => {
		if (showTerminateContract) {
			(async () => {
				await dispatch(contractDatails(id));
			})()
		}
	}, [showTerminateContract])

	const [pendingCharge, setPendignCharge] = useState<number>(0);
	const [recebentoId, setRecebentoId] = useState("");

	useEffect(() => {
		if (showTerminateContract) {
			setValue("termination_responsiblename", "1");
			let data: any = {};
			data["contract_id"] = id;
			customerService.pendinginitialTermination(data).then(res => {
				if (res?.["data"]?.["type"] == "success" || res?.["data"]?.["type"] == "Success") {
					let lengthPending = res?.["data"]?.["data"];
					setPendignCharge(lengthPending[lengthPending.length - 1]?.["pending_charge"]);
					setRecebentoId(lengthPending[lengthPending.length - 1]?.["ID_RECEBIMENTO_RECB"]);
				}
			})
		}
	}, [showTerminateContract]);

	const [reEdit, setreEdit] = useState(true);
	const [terminateAgency, setTerminateAgency] = useState<any>();

	useEffect(() => {
		if (ShowThisData) {
			let data: any = {};
			data["contract_id"] = id;
			customerService.getinitialTermination(data).then(res => {
				if (res?.["data"]?.["type"] == "success" || res?.["data"]?.["type"] == "Success") {
					let post = res?.["data"]?.["data"]["selectResult"][0];
					setValue("termination_id", post?.["ID_TERMINATION_TRM"] !== "" && post?.["ID_TERMINATION_TRM"] !== "undefined" ? post?.["ID_TERMINATION_TRM"] : "");
					setValue('termination_responsiblename', post?.["TX_RESPONSABLE_NAME"] ?? "");
					setValue('termination_forecast', post?.["DT_RESCISAO_CON"] ? moment(post?.["DT_RESCISAO_CON"].split("T")[0]).format("YYYY-MM-DD") : "");
					setValue('termination_moveoutdate', post?.["DT_MOVEOUT_TRM"] ? moment(post?.["DT_MOVEOUT_TRM"].split("T")[0]).format("YYYY-MM-DD") : "");
					setValue('termination_include_moveoutdate', post?.["BL_INCLUDE_MOVEOUT_TRM"] ?? "");
					setValue('termination_reason', post?.["FL_MOTIVORESCISAO_CON"] ?? "");
					setValue('termination_observation', post?.["ST_OBSERVACAORECISAO_CON"] ?? "");
					setValue('termination_notify_property', post?.["BL_NOTIFY_TRM"] ?? "");
					// setreEdit(post === undefined);

					// if (post !== undefined) {
					// 	if (contractDatail?.[0]?.["termination_date"] !== "") {
					// 		document.querySelectorAll('.DisableThis').forEach((item, index) => {
					// 			item.setAttribute("disabled", "");
					// 			item.setAttribute("read-only", "");
					// 		});

					// 		document.querySelectorAll('.DisableThis input').forEach((item, index) => {
					// 			item.setAttribute("disabled", "");
					// 			item.setAttribute("read-only", "");
					// 		})
					// 	}
					// } else {
					// 	document.querySelectorAll('.DisableThis').forEach((item, index) => {
					// 		item.removeAttribute("disabled");
					// 		item.removeAttribute("read-only");
					// 	});

					// 	document.querySelectorAll('.DisableThis input').forEach((item, index) => {
					// 		item.removeAttribute("disabled");
					// 		item.removeAttribute("read-only");
					// 	})
					// }
				}
			})
		}

		userService.getAllUsers({ userId: "", start: 0, end: 1000 }).then(res => {
			let objectVal_agency: any = [];
			res?.["data"].forEach(itemsingle => {
				objectVal_agency.push({
					value: itemsingle?.["ID_USUARIO_USU"],
					label: itemsingle?.["ST_APELIDO_USU"],
				})
			});
			setTerminateAgency(objectVal_agency);
		})
	}, [ShowThisData && showTerminateContract, contractDatail]);

	const handlePendingChargeee = () => {
		document.querySelector("#root")?.setAttribute("recebentoId", recebentoId);
	}

	return (
		<>
			{/* Terminate modal start from here */}
			<Offcanvas show={showTerminateContract} placement="end" onHide={closeTermination}>
				<Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onTerminateFormSubmit)} id="concTerminattsx">
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>{t('terminatelist.Move-out')} {t('terminatelist.notice')}</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						{
							Number(pendingCharge) !== 0 &&
							<Alert className="mb-lg-4 mb-3" variant="warning" show={show} onClose={() => setShow(false)} dismissible>
								<div className="d-flex">
									<img src="../assets/images/icons/triangle-exclamation.svg" alt="trophy" className="me-2 me-lg-3 h-20 flex-shrink-0" />
									<div>
										<h6>{t('terminatelist.Thistenanthas')} {String(Number(pendingCharge))} {t('terminatelist.pendingcharges')}.</h6>
										{/* <Link to={'/receivable_charges'}>{t('terminatelist.Check')} {t('terminatelist.pendingcharges')}</Link> */}
										<Link to={'/receivable_charges'} onClick={handlePendingChargeee}>{t('terminatelist.Check')} {t('terminatelist.pendingcharges')}</Link>
									</div>
								</div>
							</Alert>
						}

						<Form.Control type="text" {...register("termination_id")} hidden />
						<FloatingLabel controlId="name" label={t("terminatelist.Responsibleparty'sname") + " *"} className="mb-3 mb-lg-4" >
							<Form.Select aria-label="Responsible party's name" {...register("termination_responsiblename")} className={`DisableThis ${errors.termination_responsiblename ? "is-invalid" : ""}`}>
								<option value="">{t("formLabel.select")}</option>
								{terminateAgency && terminateAgency.length > 0 &&
									terminateAgency.map((item, index) => {
										return (<option key={index} value={item["value"]}>{item["label"]}</option>)
									})
								}
							</Form.Select>
							<Form.Control.Feedback className="invalid-feedback">{errors.termination_responsiblename?.message}</Form.Control.Feedback>
						</FloatingLabel>
						<span className="fw-bold mb-3 mb-lg-4 d-block">{t("terminatelist.Initialdata")}</span>
						<Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
							<Col md={6}>
								<FloatingLabel controlId="termination_forecast" label={t("terminatelist.Terminationforecast") + " *"}>
									<Form.Control type="date" placeholder={t("terminatelist.Terminationforecast")} {...register("termination_forecast")} className={`DisableThis ${errors.termination_forecast ? "is-invalid" : ""}`} />
									<Form.Control.Feedback className="invalid-feedback">{errors.termination_forecast?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
							<Col md={6}>
								<FloatingLabel controlId="Move_out_date" label={t('terminatelist.Move-out') + " *" + " " + t('terminatelist.date') + " *"}>
									<Form.Control type="date" placeholder={t('terminatelist.Move-out') + "" + t('terminatelist.date')} {...register("termination_moveoutdate")} className={`DisableThis ${errors.termination_moveoutdate ? "is-invalid" : ""}`} />
									<Form.Control.Feedback className="invalid-feedback">{errors.termination_moveoutdate?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
						</Row>
						<Form.Group className="mb-3 mb-lg-4 bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
							<Form.Check id="move_out_date_document" label={t("terminatelist.Includemove-outdateintheterminationdocument")} {...register("termination_include_moveoutdate")} className="DisableThis" />
						</Form.Group>
						<FloatingLabel controlId="reason" label={t("terminatelist.Reasonfortermination") + " *"} className="mb-3 mb-lg-4">
							<Form.Select aria-label="Reason for termination" {...register("termination_reason")} className={`DisableThis ${errors.termination_reason ? "is-invalid" : ""}`} >
								<option value="">{t("formLabel.select")}..</option>
								{
									terminatonReason.map((item, index) => {
										return (<option key={index} value={item["value"]}>{item["label"]}</option>)
									})
								}
							</Form.Select>
							<Form.Control.Feedback className="invalid-feedback">{errors.termination_reason?.message}</Form.Control.Feedback>
						</FloatingLabel>
						<Form.Control.Feedback className="invalid-feedback">{errors.termination_forecast?.message}</Form.Control.Feedback>
						<FloatingLabel className="mb-lg-4 mb-3" controlId="observation" label={t("formLabel.observation")}>
							<Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("termination_observation")} className={`DisableThis ${errors.termination_observation ? "is-invalid" : ""}`} />
							<Form.Control.Feedback className="invalid-feedback">{errors.termination_observation?.message}</Form.Control.Feedback>
						</FloatingLabel>
						<Form.Group className="mb-3 mb-lg-4 bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
							<Form.Check id="notify_property" label={t("terminatelist.Notifypropertyowner")} {...register("termination_notify_property")} className='DisableThis' />
						</Form.Group>
					</Offcanvas.Body>
					{


					}


					{/* {
							reEdit &&
							<div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
								<Button onClick={closeTermination} variant="outline-primary">
									{t("button.close")}
								</Button>
								<Button type="submit" className="min-w-auto">{t("button.save")}</Button>
							</div>
						} */}
					<div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
						{
							loader_on ?
								<Button className="btn-width">
									<span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
								</Button>
								:
								<>
									<Button onClick={closeTermination} variant="outline-primary">
										{t("button.close")}
									</Button>
									<Button type="submit" className="min-w-auto">{t("button.save")}</Button>
								</>
						}
					</div>
				</Form>
			</Offcanvas>
			{/* /Terminate modal end */}
		</>
	)
}

export default Terminate
