import React from 'react';
import { Offcanvas, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const EditChargeTemplate = (props) => {
    const [t] = useTranslation();
    //Email charge template modal close open function
    const { showEditChargeTemplate, editChargeTemplateClose, folderName } = props;
    return (
        <>
        <Offcanvas className="border-sm-none" show={showEditChargeTemplate} onHide={editChargeTemplateClose} placement="end">
            <Form className="d-flex flex-column h-100">
                <Offcanvas.Header className="border-bottom" closeButton>
                  <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2 me-xl-4 me-lg-3" alt="Arrow left" />{t("NonPayment.editAutomaticChargeTemplate")} AAAA
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h6 className="mb-3 fw-bold">{t("NonPayment.generationOfDelinquencyLetter")}</h6>
                    <FloatingLabel controlId="template_content" label={t("NonPayment.templateContent")}>
                        <Form.Control as="textarea" placeholder={t("NonPayment.templateContent")} />
                    </FloatingLabel>
                </Offcanvas.Body>
                <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                    <Button variant="outline-primary" onClick={editChargeTemplateClose}>{t("button.cancel")}</Button>
                    <Button variant="primary" onClick={editChargeTemplateClose}>{t("button.save")}</Button>
                </div>
            </Form>
        </Offcanvas>
    </>
    )
}

export default EditChargeTemplate
