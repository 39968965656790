import React, { useEffect, useState } from "react";

import { Outlet, Link, useNavigate } from "react-router-dom";
import {
	Col,
	Row,
	Form,
	Button,
	FloatingLabel,
	Accordion,
	Alert,
	Card,
	Badge,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { billingTicketService } from "../../../../services/billingTicketService";
import { fetchBillingRule } from "../../../../redux/slice/BillingRuleSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import i18n from "../../../../i18n";
import { encrypt_Decrypt } from "../../../constants/utils";

const BillingTicket = () => {
	const dispatch = useDispatch<AppDispatch>();
	let emailFirstNotification = useSelector((state: RootState) => state.billingRule['billingRuleState']);
	const [t] = useTranslation();
	const navigate = useNavigate();
	const [userJson, setUserJson] = useState<any>([]);

	useEffect(() => {
		let userResult = encrypt_Decrypt();
		if (!userResult || userResult.indexOf(1040) === -1) {
			navigate("/dashboard");
		}
		setUserJson(userResult);
	}, [])

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useForm({});
	const [isChecked, setIsChecked] = useState(false);
	const [beforeAfterMin, setbeforeAfterMin] = useState({one : 1, two : 1 , three : 1});
	const [BillingRuleDays, setBillingRuleDays] = useState<any>({first_day : 0, second_day : 0, third_day : 0});
	const [notify, setNotify] = useState<any>({
		notify_label: [
			// { value: '', label: "{t("formLabel.select")}" },
			{ value: "1", label: "Before expiration" },
			{ value: "2", label: "On maturity" },
			{ value: "3", label: "After expiration" },
		],
	});

	useEffect(() => {
		if (i18n.language == "en") {
			setNotify({
				notify_label: [
					{ value: "1", label: "Before expiration" },
					{ value: "2", label: "On maturity" },
					{ value: "3", label: "After expiration" },
				],
			})
		} else {
			setNotify({
				notify_label: [
					{ value: "1", label: "Antes do vencimento" },
					{ value: "2", label: "No dia do vencimento" },
					{ value: "3", label: "Após o vencimento" },
				],
			})
		}
	}, [i18n.language]);

	useEffect(() => {
		(async () => {
			dispatch(fetchBillingRule());
		})();
	}, []);

	useEffect(() => {
		let vone = 0, vtwo = 0, vthree = 0;
		if (emailFirstNotification) {
			let { send_email_first_notification, email_first_day, email_first_period, send_email_second_notification, email_second_day, email_second_period, send_email_third_notification, email_third_day, email_third_period, send_email_fourth_notification, email_fourth_day, send_letter_first_notification, letter_first_day, send_letter_second_notification, letter_second_day } = emailFirstNotification
			setValue('email.first_check', send_email_first_notification)
			setValue('email.first_day', email_first_day) 
			if( email_first_day > 0 ){ vone = 1 }
			setValue('email.period_1', email_first_period)
			setValue('email.second_check', send_email_second_notification)
			setValue('email.second_day', email_second_day)
			if( email_second_day > 0 ){ vtwo = 1 }
			setValue('email.period_2', email_second_period)
			setValue('email.third_check', send_email_third_notification)
			setValue('email.third_day', email_third_day)
			if( email_third_day > 0 ){ vthree = 1 }
			setValue('email.period_3', email_third_period)
			setValue('email.fourth_check', send_email_fourth_notification)
			setValue('email.fourth_day', email_fourth_day)
			setValue('letter.first_check', send_letter_first_notification)
			setValue('letter.first_day', letter_first_day)
			setValue('letter.second_check', send_letter_second_notification)
			setValue('letter.second_day', letter_second_day)
			setbeforeAfterMin({ one : vone, two : vtwo, three : vthree })
		}
	}, [emailFirstNotification]);


	let defaultvalue = 0;
	let defaultcheck = false;

	const handleEdit = (e, type) => {
		let editBtn = e.target;
		let parentEle = e.target.parentElement.parentElement;
		let updateBtn = e.target.nextElementSibling;
		editBtn.setAttribute("hidden", "");
		updateBtn.removeAttribute("hidden");
		parentEle.querySelectorAll("input, select").forEach((item) => {
			item.removeAttribute("disabled");
		});
	};

	const cancelEdit = ( e , indx : any ) => {
		let editBtn : any = document.querySelector(`.form_${indx}`);
		editBtn.removeAttribute("hidden");
		let cancelUpdate1 : any = document.querySelector(`.cancelUpdate${indx}`);
		cancelUpdate1.setAttribute("hidden","");
		document.querySelectorAll(`.formField${indx}`).forEach((item) => {
			item.setAttribute("disabled","");
			item.nextElementSibling?.setAttribute("disabled",""); 
			item.firstElementChild?.setAttribute("disabled","");
		}); 
	}

	const onSubmit = (data, e, type) => {
		let parentEle = e.target;
		data = data[type];
		if( data.period_1 == 2 ){ data['first_day'] = 0 }
		if( data.period_2 == 2 ){ data['second_day'] = 0 }
		if( data.period_3 == 2 ){ data['third_day'] = 0 }

		let alertmsg : any = '';
		if( data.period_1 != 2 ){ 
			data['first_day'] = data['first_day'] < 0 ? ( -1 * data['first_day'] ) : data['first_day'];

			if( data['first_day'] == '' || data['first_day'] == 0 ){
				alertmsg = `${t("notifications.beforeExpiration")} or ${t("notifications.afterExpiration")}, ${t("notifications.validDay")}`;
			}
		}
		if( data.period_2 != 2 ){ 
			data['second_day'] = data['second_day'] < 0 ? ( -1 * data['second_day'] ) : data['second_day'] ;
			if( data['second_day'] == '' || data['second_day'] == 0 ){
				alertmsg = `${t("notifications.beforeExpiration")} or ${t("notifications.afterExpiration")}, ${t("notifications.validDay")}`;
			}
		}
		if( data.period_3 != 2 ){ 
			data['third_day'] = data['third_day'] < 0 ? ( -1 * data['third_day'] ) : data['third_day'] 
			if( data['third_day'] == '' || data['third_day'] == 0 ){
				alertmsg = `${t("notifications.beforeExpiration")} or ${t("notifications.afterExpiration")}, ${t("notifications.validDay")}`;
			}
		}
		if( data.fourth_check == 1 && ( data['fourth_day'] == '' || data['fourth_day'] == 0 ) ){ 
			alertmsg = `${t("billingrule.sendfourthnotification")} ${t("notifications.validDay")}`;
		} 
		console.log('data::::',data);
		if( alertmsg == '' ){
			billingTicketService
				.addBillingTicketNotifications(data, type)
				.then( async (res) => { 
					let editBtn = parentEle.querySelector(".Edit");
					editBtn.removeAttribute("hidden");
					editBtn.nextElementSibling.setAttribute("hidden", "");
					parentEle.querySelectorAll("input, select").forEach((item) => {
						item.setAttribute("disabled", "");
					});
					dispatch(fetchBillingRule());
				})
				.catch((error) => {
					console.log("catch-error", error);
				});
			Swal.fire({
				position: "center",
				title: t("Sweetalert.Update"),
				text: `${t("Sweetalert.updatesuccess")}`,
				showConfirmButton: false,
				timer: 2500,
			});
		} else {
			Swal.fire({
				position: "center",
				title: t("Sweetalert.Error"),
				text: alertmsg,
				showConfirmButton: false,
				timer: 2500,
			});
		}
	};
	


	const Optionsdrop = ({ type, count, onChange, classisName }) => {
		console.log('ddddddddd');
		return (
			<FloatingLabel controlId="period" label={t("billingrule.period")}>
				<Form.Select className={classisName}
					disabled
					aria-label="Period"
					{...register(`${type}.period_${count}`)}
					onChange={onChange}
				>
					{notify.notify_label.map((item) => (
						<option value={item.value}>{item.label}</option>
					))}
				</Form.Select>
			</FloatingLabel>
		);
	};

	const handleFirstDaySelectChange = (event) => {
		const selectedValue = event.target.value;
		const firstDayField: any = document.querySelector(`[name="email.first_day"]`);
		setbeforeAfterMin ({ ...beforeAfterMin , one : 1 });
		if (selectedValue == 2) {
			setbeforeAfterMin ({ ...beforeAfterMin , one : 0 });
			setValue('email.first_day', 0);
			firstDayField.setAttribute("disabled", "disabled");
		} else if ( firstDayField.value ) {
			let mt = emailFirstNotification.email_first_day == 0 ? 1 : emailFirstNotification.email_first_day;
			setValue('email.first_day', ( firstDayField.value > 0 ) ? firstDayField.value : mt  );
			firstDayField.removeAttribute("disabled");
		} else {
			if( emailFirstNotification.email_first_day ){
				setValue('email.first_day', emailFirstNotification.email_first_day  );
				firstDayField.removeAttribute("disabled");
			}
		}
	};

	const handleSecondDaySelectChange = (event) => {
		const selectedValue = event.target.value;
		const secondDayField: any = document.querySelector(`[name="email.second_day"]`);
		setbeforeAfterMin ({ ...beforeAfterMin , two : 1 });
		if (selectedValue == 2) {
			setbeforeAfterMin ({ ...beforeAfterMin , two : 0 });
			setValue('email.second_day', 0);
			secondDayField.setAttribute("disabled", "disabled");
		} else if ( secondDayField.value ) {
			let mt = emailFirstNotification.email_second_day == 0 ? 1 : emailFirstNotification.email_second_day;
			setValue('email.second_day', ( secondDayField.value > 0 ) ? secondDayField.value : mt  );
			secondDayField.removeAttribute("disabled");
		} else {
			setValue('email.second_day', emailFirstNotification.email_second_day );
			secondDayField.removeAttribute("disabled");
		}
	};

	const handleThirdDaySelectChange = (event) => {
		const selectedValue = event.target.value;
		const thirdDayField: any = document.querySelector(`[name="email.third_day"]`);
		setbeforeAfterMin ({ ...beforeAfterMin , three : 1 });
		if (selectedValue == 2) {
			setbeforeAfterMin ({ ...beforeAfterMin , three : 0 });
			setValue('email.third_day', 0);
			thirdDayField.setAttribute("disabled", "disabled");
		} else if ( thirdDayField.value ) {
			let mt = emailFirstNotification.email_third_day == 0 ? 1 : emailFirstNotification.email_third_day;
			setValue('email.third_day', ( thirdDayField.value > 0 ) ? thirdDayField.value : mt  );
			thirdDayField.removeAttribute("disabled");
		} else {
			setValue('email.third_day', emailFirstNotification.email_third_day );
			thirdDayField.removeAttribute("disabled");
		}
	};

	const checkIsValidValues = (val : any, type)=> {
		// console.log(val.target.value);
		// console.log( document.querySelector(`[name="email.period_1"]`) );
	}
	return (
		<>
			<Card className="mb-lg-4 mb-3 notificatiotxtnbyemail">
				<Card.Header>{t("billingrule.ticket")}</Card.Header>
				<Card.Body className="pb-1">
					<Card className="notificatiotxtnbyemail">
					<Card.Header>{t("billingrule.notificationbyemail")}</Card.Header>
					<Card.Body className="pb-1">
						<Form onSubmit={handleSubmit((data, e) => onSubmit(data, e, "email"))} className="notificationForm1">
							<div className="position-absolute end-0 top-0 mt-2 me-5 pe-1 z-index-5">
								<Button variant="link" size="sm" onClick={(e) => handleEdit(e, "email")} className="Edit form_1 fw-bold lh-1 text-primary">{t("button.edit")}</Button>
								<div hidden className="cancelUpdate1">
									<Button variant="link" className="fw-bold lh-1 text-primary" size="sm" type="submit" >{t("button.update")}</Button>
									<Button variant="link" className="pr-0 fw-bold lh-1 text-primary" size="sm" onClick={(e) => cancelEdit(e,1)} >{t("button.cancel")}</Button>
								</div>
							</div>
							<Alert variant="warning" className="mb-lg-4 mb-3">
								{t("billingrule.altermessage")}
							</Alert>
							<Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
								<Col md={12} xl>
									<Form.Check type="checkbox" disabled id="notification_1" label={t("billingrule.sendfirstnotification")} defaultChecked={defaultcheck} {...register('email.first_check')}  className="formField1"/>
								</Col>
								<Col md={5} xl={2}>
									<FloatingLabel controlId="Days" label={t("billingrule.days")}>
										<Form.Control type="number" disabled placeholder="Days" onKeyUp={(e) => { checkIsValidValues(e, 'email.period_1');}} min={beforeAfterMin.one} defaultValue={defaultvalue} {...register('email.first_day')} className="formField1"/>
									</FloatingLabel>
								</Col>
								<Col md xl={4}>
									{/* <Optionsdrop type="email" count="1" onChange={(e) => {handleFirstDaySelectChange(e);}}  classisName="formField1" /> */}

									<FloatingLabel controlId="period" label={t("billingrule.period")}>
										<Form.Select className="formField1"
											disabled
											aria-label="Period"
											{...register(`email.period_1`)}
											onChange={(e) => { handleFirstDaySelectChange(e);}}
										>
											{notify.notify_label.map((item) => (
												<option value={item.value}>{item.label}</option>
											))}
										</Form.Select>
									</FloatingLabel>
									
								</Col>
							</Row>
							<Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
								<Col md={12} xl>
									<Form.Check type="checkbox" disabled id="notification_2" label={t("billingrule.sendsecondnotification")} defaultChecked={defaultcheck} {...register('email.second_check')} className="formField1" />
								</Col>
								<Col md={5} xl={2}>
									<FloatingLabel controlId="Days" label={t("billingrule.days")}>
										<Form.Control type="number" disabled placeholder="Days" min={beforeAfterMin.two} defaultValue={defaultvalue} {...register('email.second_day')} className="formField1" />
									</FloatingLabel>
								</Col>
								<Col md xl={4}>
									{/* <Optionsdrop type="email" count="2" onChange={handleSecondDaySelectChange} classisName="formField1" /> */}

									<FloatingLabel controlId="period" label={t("billingrule.period")}>
										<Form.Select className="formField1"
											disabled
											aria-label="Period"
											{...register(`email.period_2`)}
											onChange={(e) => { handleSecondDaySelectChange(e);}}
										>
											{notify.notify_label.map((item) => (
												<option value={item.value}>{item.label}</option>
											))}
										</Form.Select>
									</FloatingLabel>

								</Col>
							</Row>
							<Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
								<Col md={12} xl>
									<Form.Check type="checkbox" disabled id="notification_3" label={t("billingrule.sendthirdnotification")} defaultChecked={defaultcheck} {...register('email.third_check')} className="formField1" />
								</Col>
								<Col md={5} xl={2}>
									<FloatingLabel controlId="Days" label={t("billingrule.days")}>
										<Form.Control type="number" disabled placeholder="Days" min={beforeAfterMin.three} defaultValue={defaultvalue}  {...register('email.third_day')} className="formField1"/>
									</FloatingLabel>
								</Col>
								<Col md xl={4}>
									{/* <Optionsdrop type="email" count="3" onChange={handleThirdDaySelectChange} classisName="formField1" /> */}

									<FloatingLabel controlId="period" label={t("billingrule.period")}>
										<Form.Select className="formField1"
											disabled
											aria-label="Period"
											{...register(`email.period_3`)}
											onChange={(e) => { handleThirdDaySelectChange(e);}}
										>
											{notify.notify_label.map((item) => (
												<option value={item.value}>{item.label}</option>
											))}
										</Form.Select>
									</FloatingLabel>

								</Col>
							</Row>
							<Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
								<Col md={12} xl>
									<Form.Check type="checkbox" disabled id="notification_4" label={t("billingrule.sendfourthnotification")} defaultChecked={defaultcheck}  {...register('email.fourth_check')} className="formField1" />
								</Col>
								<Col md xl={4}>
									<FloatingLabel controlId="Days after expiration" label={t("billingrule.daysafterexpiration")}>
										<Form.Control type="number" disabled placeholder="Days after expiration" min={0} defaultValue={defaultvalue}  {...register('email.fourth_day')} className="formField1" />
									</FloatingLabel>
								</Col>
							</Row>
						</Form>
					</Card.Body>
					</Card>

					<hr />

					<Card className="notificatiotxtnbyemail">
					<Card.Header>{t("billingrule.extrajudicialnotice")}</Card.Header>
					<Card.Body className="pb-1"> 
						<Form onSubmit={handleSubmit((data, e) => onSubmit(data, e, "letter"))} className="notificationForm2">
							<div className="position-absolute end-0 top-0 mt-2 me-5 pe-1 z-index-5">
								<Button
									variant="link"
									size="sm"
									onClick={(e) => handleEdit(e, "letter")}
									className="Edit form_2 lh-1 p-0 fw-bold text-primary"
								>
									{t("button.edit")}
								</Button>
								<div hidden className="cancelUpdate2">
									<Button variant="link" className="fw-bold lh-1 text-primary" size="sm" type="submit" >{t("button.update")}</Button>
									<Button variant="link" className="pr-0 fw-bold lh-1 text-primary" size="sm" onClick={(e) => cancelEdit(e , 2)} >{t("button.cancel")}</Button>
								</div>

								{/* <Button
									variant="link"
									size="sm"
									type="submit"
									className="p-0 fw-bold lh-1 text-primary"
									hidden
								>
									{t("button.update")}
								</Button> */}
							</div> 
							<Alert variant="warning" className="mb-lg-4 mb-3">
								{t("billingrule.systemwillautomatically")}{" "}
								<Link to={""} className="fw-bold">
									{t("billingrule.accesswillreceive")}
								</Link>
							</Alert>
							<Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
								<Col md={12} xl>
									<Form.Check className="formField2"
										type="checkbox"
										disabled
										id="notification_7"
										label={t("billingrule.sendfirstnotification")}
										defaultChecked={defaultcheck}
										{...register("letter.first_check")}
									/>
								</Col>
								<Col md xl={4}>
									<FloatingLabel
										controlId="Days"
										label={t("billingrule.daysafterexpiration")}
									>
										<Form.Control className="formField2"
											type="number"
											disabled
											placeholder="Days after expiration."
											defaultValue={defaultvalue}
											{...register("letter.first_day")}
										/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
								<Col md={12} xl>
									<Form.Check className="formField2"
										type="checkbox"
										disabled
										id="notification_8"
										label={t("billingrule.sendsecondnotification")}
										defaultChecked={defaultcheck}
										{...register("letter.second_check")}
									/>
								</Col>
								<Col md xl={4}>
									<FloatingLabel
										controlId="Days"
										label={t("billingrule.daysafterexpiration")}
									>
										<Form.Control className="formField2"
											type="number"
											disabled
											id="notification_7"
											placeholder={t("billingrule.sendfirstnotification")}
											defaultChecked={defaultcheck}
											{...register("letter.first_check")}
										/>
									</FloatingLabel>
								</Col>
								<Col md xl={4}>
									<FloatingLabel
										controlId="Days"
										label={t("billingrule.daysafterexpiration")}
									>
										<Form.Control
											type="text"
											disabled
											placeholder="Days after expiration."
											defaultValue={defaultvalue}
											{...register("letter.first_day")}
										/>
									</FloatingLabel>
								</Col>
							</Row>
								<Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
									<Col md={12} xl>
										<Form.Check
											type="checkbox"
											disabled
											id="notification_8"
											label={t("billingrule.sendsecondnotification")}
											defaultChecked={defaultcheck}
											{...register("letter.second_check")}
										/>
									</Col>
									<Col md xl={4}>
										<FloatingLabel
											controlId="Days"
											label={t("billingrule.daysafterexpiration")}
										>
											<Form.Control
												type="text"
												disabled
												placeholder="Days after expiration."
												defaultValue={defaultvalue}
												{...register("letter.second_day")}
											/>
										</FloatingLabel>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Card.Body>
			</Card> 
		</>
	)
}

export default BillingTicket;
