import { config } from "../../../../services/apiConfig";

export async function getAllBillingMethods(): Promise<
  Array<{
    id: number;
    name: string;
  }>
> {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(
    `${config.customerV2}/v2/contract/allBillingMethod`,
    {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        "connection-name": connectionName,
      },
      body: JSON.stringify({
        start: 0,
        end: 1000,
        userId: 1,
      }),
    }
  );

  const data = await response.json();

  return data.data;
}
