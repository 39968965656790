import React, { useEffect, useState } from 'react'
import { Accordion, Dropdown, FloatingLabel, InputGroup, Col, Row, Table, Modal, Form, Button, Card, Badge, ListGroup } from 'react-bootstrap';
import { updateProperty_serviceProvision, getProperty_serviceProvision } from "../../../../redux/slice/propertyDataSlice";
import { dotInsert } from '../Common/actionPerform';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ServiceAndProvision } from '../../../Interface';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import PropertiesServicesProvisions from './PropertiesServicesProvision';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { converter, convertToNumber } from '../../../constants/utils';
const Properties_serviceprov = ({ servShow, setServShow, transfer_day, guaranteed_transfer, condominium, adminfee, showServicesProvisionModal, setServicesProvisionModal }) => {
    const [t]       = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const propertyValidation = Yup.object().shape({});
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<ServiceAndProvision>();
    const { serviceProvision } = useSelector((state: RootState) => state.propertyData)
    const { id } = useParams();
    useEffect(() => {
        dispatch(getProperty_serviceProvision(id));
    }, [])

    /*------- Properties servies provision modal initialization --------*/
    const [chekuncheck, setchekuncheck] = useState({
        admtxt: false,
        leasetax: false
    });

    const servicesProvisionModalClose = () => {
        setServicesProvisionModal(false);
    }

    useEffect(() => {
        if (serviceProvision.length > 0) {
            let newobj: any = {
                ...serviceProvision[0]
            }
            let fields = Object.keys(newobj);
            fields.forEach((field: any) => setValue(field, newobj?.[field]));
        }
    }, [dispatch, serviceProvision])
    return (
        <>
            <Form>
                <ListGroup variant="flush">
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/dollar.svg"} alt="dollar" className="h-16 me-1" />{t("rentValue")}
                        </div>
                        <span className="text-secondary">{getValues('service_provision_rentvalue')}</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/dollar.svg"} alt="dollar" className="h-16 me-1" />{t("admTxt")}
                        </div>
                        <span className="text-secondary">{getValues('service_provision_admtxt')} ({chekuncheck.admtxt ? "Rs." : "%"})</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/dollar.svg"} alt="dollar" className="h-16 me-1" />{t("Adminfeeisleviedon")}:
                        </div>
                        <span className="text-secondary">
                            {
                                    adminfee.map(item => (
                                        item.value == Number(getValues("service_provision_adminfee")) &&
                                        item.label
                                    ))
                            }
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/dollar.svg"} alt="dollar" className="h-16 me-1" />{t("formLabel.transfer")}
                        </div>
                        <span className="text-secondary">
                            {
                                transfer_day.map(item => (
                                    item.value == Number(getValues("service_provision_transfer_day")) &&
                                    item.label
                                ))
                            }
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/dollar.svg"} alt="dollar" className="h-16 me-1" />{t("Leasetax")}
                        </div>
                        <span className="text-secondary">{chekuncheck.leasetax ? "(R$)" : "" } {getValues('service_provision_leasetax')}{!chekuncheck.leasetax && "(%)"}</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/dollar.svg"} alt="dollar" className="h-16 me-1" />{t("property.saleValue")}
                        </div>
                        <span className="text-secondary">{getValues('service_provision_salevalue') ? converter(`${convertToNumber(getValues('service_provision_salevalue'))}`) : "0,00"}</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/calendar-regular.svg"} alt="calendar" className="h-16 me-1" />{t("Monthsforexemptionfromterminationfine")}
                        </div>
                        <span className="text-secondary">{getValues('service_provision_termination')}</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/calendar-regular.svg"} alt="calendar" className="h-16 me-1" />{t("Maximumleaseterm")}
                        </div>
                        <span className="text-secondary">{getValues('service_provision_maxlease')}</span>
                    </ListGroup.Item>
                    <ListGroup.Item
                        className="d-sm-flex align-items-center justify-content-sm-between flex-sm-row flex column">
                        <div className="me-sm-4 me-md-5 d-flex align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/calendar-regular.svg"} alt="calendar" className="h-16 me-1" />{t("property.condominium")}
                        </div>
                        <span className="text-secondary">
                            {
                                condominium.map(item => (
                                    item.id == Number(getValues('service_provision_condominium')) &&
                                    item.name
                                ))
                            }
                        </span>
                    </ListGroup.Item>
                </ListGroup>
            </Form>
            <PropertiesServicesProvisions showServicesProvision={showServicesProvisionModal} servicesProvisionClose={servicesProvisionModalClose} transfer_day={transfer_day} guaranteed_transfer={guaranteed_transfer} condominium={condominium} adminfee={adminfee} chekuncheck={chekuncheck} setchekuncheck={setchekuncheck} />
        </>
    )
}

export default Properties_serviceprov