import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import BillingMessages from './BillingMessages';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BillingTicket from './BillingTicket';
import { encrypt_Decrypt } from '../../../constants/utils';

const BillingRule = () => {
    const [t] = useTranslation()
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(1040) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
    return (
        <section className="content-wrapper">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <h1 className="mb-0 lh-base text-truncate">{t("Links.billingRule")}</h1>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-0">
                            <ul className="list-inline mb-0">
                                {/* <Link to={"#"} className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1" onClick={() => window.print()}>
                                    <img src="assets/images/icons/print-light.svg" alt="Print"  className="h-20" />
                                </Link> */}
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Row className="gy-lg-4 gy-3">
                    <Col xl={7}>
                        <BillingTicket />
                    </Col>
                    <Col xl={5}>
                        <BillingMessages />
                    </Col>
                </Row>
            </main>
            {/*<!-- /Main content end -->*/}
        </section>
    )
}

export default BillingRule
