import React, { useEffect } from 'react';
import { Badge, Row, Col, Card, Dropdown, Button, Table, Form, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import customerService from '../../../../../services/customerService';
import { useDispatch, useSelector } from 'react-redux';
import { contractDatails } from '../../../../../redux/slice/contractDataSlice';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { config } from '../../../../../services/apiConfig';
import Swal from 'sweetalert2';

const NewEnvelope = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const [t] = useTranslation();

    const [show, setShow] = useState(false);
    const handleAttachModalClose = () => setShow(false);
    const handleAttachModalShow = () => setShow(true);
    const [electronicData, setElectronicData] = useState<any>([]);
    const [assinantesData, setAssinantesData] = useState<any>([]);
    const [showPropertyType, setShowPropertyType] = useState<string>('')
    const [electronicDocumentState, setElectronicDocumentState] = useState<any>([]);

    useEffect(() => {
        const envelopeDetail = async () => {
            const response = await customerService.singleElectronicEnvelope(id);
            if (response.data.resCode === 201 || response.data.resCode === 202) {
                let data = response.data.data;
                getContractDetails(data[0].ID_CONTRATO_CON);
                listElectronicEnvelopeDocument(data[0].ID_CONTRATO_CON);
                setElectronicData(data);
                const ST_DADOSASSINATURA_ENV = JSON.parse(data[0].ST_DADOSASSINATURA_ENV);
                setAssinantesData(ST_DADOSASSINATURA_ENV);

                const envelopeStatus = async () => {
                    const DOCUSIGN_ACCOUNT_ID = config.DOCUSIGN_ACCOUNT_ID;
                    ; const ST_IDENTIFICADOR_ENV = data[0].ST_IDENTIFICADOR_ENV
                    const CustomerApiBaseUrl = config.customerV2;
                    const DOCUSIGN_DEV_BASE_URL = config.DOCUSIGN_DEV_BASE_URL;
                    const response = await customerService.checkStatusAndUpdateApi(DOCUSIGN_ACCOUNT_ID, ST_IDENTIFICADOR_ENV, CustomerApiBaseUrl, DOCUSIGN_DEV_BASE_URL);
                }
                envelopeStatus();

            }
        }
        const getContractDetails = async (contractId) => {
            let data = await dispatch(contractDatails(contractId));
        }

        const listElectronicEnvelopeDocument = async (contractId) => {
            let data = await customerService.listElectronicEnvelopeDocument(contractId)
            setElectronicDocumentState(data.data.data[0].docusign_document);
        }
        envelopeDetail();
    }, []);

    const { singleContCount, contractDatail, expenseData, tenantsData, expenseStatus, guarantorData, status1, Property_Type, guarantors } = useSelector((state: RootState) => state.contractData)

    useEffect(() => {
        if (contractDatail && Array.isArray(contractDatail) && contractDatail.length > 0) {
            Property_Type?.forEach((proprtyType) => {
                if (proprtyType.value === contractDatail[0].proprty_type) {
                    setShowPropertyType(proprtyType?.label)
                }
            })
        }


    }, [contractDatail, guarantorData, Property_Type, guarantors]);

    function formatDateToDDMMYYYY(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }


    const handleBadgeClick = async (e, cmd) => {
        if (cmd == "Signed") {
            let token = await customerService.getAccessToken();
            let envelope_data = electronicData;
            token = token.data.data;
            let envelopeId = envelope_data[0].ST_IDENTIFICADOR_ENV;
            let signerName = assinantesData.assinantes[0].nome
            let signerEmail = assinantesData.assinantes[0].email
            let embedded_url: any = await customerService.createLinkViaEnvelope(token, envelopeId, signerName, signerEmail);
            if (embedded_url.data.resCode == 202 || embedded_url.data.resCode == 200) {
                embedded_url = embedded_url.data.data;
                window.open(embedded_url, '_blank');
            }
        }
        else {
            Swal.fire({
                title: "Info",
                text: "Please Complete The Signing Process To Access This Document",
                // icon: 'success',"question",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    };

    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <Link to={"/contracts"}><img src="../assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <img src="../assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
                <Card className="mb-lg-4 mb-3">
                    <Card.Body>
                        <Row>
                            <Col lg={8}>
                                <div className="d-flex align-items-center">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/envelop.svg"} alt="Folder" className="h-40 flex-shrink-0" />
                                    <div className="w-100 ms-2 ms-lg-3">
                                        <h1 className="h3 lh-base mb-0">
                                            Envelope {id} - Contract 4/1

                                            {electronicData && electronicData.length > 0 && electronicData[0].FL_STATUS_ENV == 1 ?
                                                <Badge className="text-dark ms-1" bg="success-30">Assinado</Badge> : ""
                                            }

                                            {electronicData && electronicData.length > 0 && electronicData[0].FL_STATUS_ENV == 0 ?
                                                <Badge className="ms-1 text-dark bg-warning-10">Pending shipping</Badge> : ""
                                            }

                                            <Badge className="ms-1 text-dark bg-danger-30">No legal validity</Badge>
                                        </h1>
                                        {contractDatail.length > 0 ?
                                            <Link to={"/property_detail/" + contractDatail[0].propertyId} className="mb-0">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/home_g.svg"} className="me-1" />
                                                {contractDatail.length > 0 ? showPropertyType + " " + "in" + " " + contractDatail[0].neighborhood + ',' + (contractDatail[0].address) + ' ' + contractDatail[0].number + ' ' + contractDatail[0].complement + ',' + "CEP:" + ' ' + contractDatail[0].zipcode : ''}
                                            </Link> : ""
                                        }
                                        {
                                            electronicData && electronicData.length > 0 ?
                                                <p className="mb-lg-0">Created on: {formatDateToDDMMYYYY(electronicData[0].DT_CRIACAO_ENV)} . </p> : ""
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <ul
                                    className="list-inline d-sm-flex align-items-center justify-content-start justify-content-lg-end mb-0">
                                    <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                        <Link className="btn btn-light btn-sm" to={'/'} type="button">
                                            Request subscriptions
                                        </Link>
                                    </li>
                                    <li className="list-inline-item mb-sm-0 mb-1">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                                {t('button.action')}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="mh-456 overflow-auto">
                                                <Dropdown.Item href="#">{t('historic')}</Dropdown.Item>
                                                <Dropdown.Item>Cancel envelope</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mb-lg-4 mb-3">
                    <Card.Header>Status of electronic signatures</Card.Header>
                    <Card.Body>
                        <Table responsive className="table-custom table-custom-bordered mb-0">
                            <tbody>
                                {
                                    assinantesData.assinantes && assinantesData.assinantes.length > 0 &&
                                    assinantesData.assinantes.map(item => {
                                        return (
                                            <tr>
                                                <td>
                                                    <h5><img src={process.env.PUBLIC_URL + "/assets/images/icons/user_g.svg"} alt="user" className="h-16 me-1" /><span className="text-dark">{item.nome}</span> {item.email}</h5>
                                                </td>
                                                <td>
                                                    {item.tipo}
                                                </td>
                                                {
                                                    item.status == "Assinado" ?
                                                        <td>
                                                            <Badge className="text-dark ms-1" bg="success-30"> {item.status}</Badge>
                                                        </td>
                                                        :
                                                        <td>
                                                            <Badge className="ms-1 text-dark bg-warning-10"> {item.status}</Badge>
                                                        </td>
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className="mb-lg-4 mb-3">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        Documents
                        <Button variant="link" size="sm" className="fw-bold" onClick={handleAttachModalShow}>Attach document</Button>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive className="table-custom table-custom-bordered mb-0">
                            <p></p>
                            {
                                electronicData && electronicData.length > 0 ?
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Form.Check type="checkbox" />
                                            </td>
                                            <td>
                                                {
                                                    (electronicData && electronicData.length > 0 && electronicData[0].FL_STATUS_ENV == 1) ?
                                                        <>
                                                            <img src="../assets/images/icons/pdf.png" className="h-40 me-1" alt="PDF" onClick={(e) => handleBadgeClick(e, "Signed")} /> {electronicData[0].ST_DOCUMENTO_EDOC}
                                                        </>
                                                        :
                                                        <>
                                                            <img src="../assets/images/icons/pdf.png" className="h-40 me-1" alt="PDF" onClick={(e) => handleBadgeClick(e, "Unsigned")} /> {electronicData[0].ST_DOCUMENTO_EDOC}
                                                        </>

                                                }
                                            </td>
                                            <td>
                                                {
                                                    (electronicData && electronicData.length > 0 && electronicData[0].FL_STATUS_ENV == 1) ?
                                                        <Badge className="text-dark ms-1" bg="success-30" onClick={(e) => handleBadgeClick(e, "Signed")}> Signed</Badge>
                                                        :
                                                        <Badge className="ms-1 text-dark bg-warning-10" onClick={(e) => handleBadgeClick(e, "Unsigned")}> Unsigned</Badge>

                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                    :
                                    ""
                            } 
                        </Table>

                        {electronicDocumentState && electronicDocumentState.length > 0 ?
                            <p className="text-secondary d-block py-2">Listing {electronicDocumentState.length} documents</p>
                            :
                            <p className="text-secondary d-block py-2">Listing 0 documents</p>}

                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t("formLabel.mark")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="">
                                            {t("formLabel.all")}
                                        </Dropdown.Item>
                                        <Dropdown.Item href="">
                                            {t("formLabel.none")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <span className="px-1">{t("formLabel.withTagged")}</span>
                            </li>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <Button
                                    variant="light"
                                    size="sm"
                                >
                                    Delete document
                                </Button>
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
            </main>
            {/* <!-- /Main content start end --> */}

            {/* Attach file popup start */}
            <Modal show={show} onHide={handleAttachModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Select the file</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="border-2 border border-dashed py-4 px-2 position-relative text-center rounded-4 mb-lg-4 mb-3">
                            <Form.Control type="file" className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1" />
                            <h4 className="text-primary fw-bold mb-2"><img src="assets/images/icons/upload-file-primary.svg" alt="Upload file" className="cursor-pe me-2" />{t("attachFile")}</h4>
                            <label className="mb-0 form-label fw-normal text-dark"><img src="assets/images/icons/info-dark.svg" alt="Info" className="h-16 cursor-pe me-1" />{t("newTransfer.supportedFormats")}</label>
                        </div>
                        <Card className="border">
                            <Card.Body className="d-flex align-items-center justify-content-between p-2">
                                <div className="w-100 d-flex align-items-center">
                                    <img src="assets/images/icons/pdf.png" className="h-40 me-1 flex-shrink-0" alt="PDF" /> Screen_9.Pdf
                                </div>
                                <img src="assets/images/icons/delete-danger.svg" className="h-18 ms-1" alt="PDF" />
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleAttachModalClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAttachModalClose}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Attach file popup end */}
        </section>
    )
}

export default NewEnvelope
