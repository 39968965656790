import axios from 'axios';
import CryptoJS from "crypto-js";

import { gettoken, config } from './apiConfig';
const companyV2_URL = config.companyV2_URL;
const notificationsV2URL = config.notificationsV2URL;
axios.defaults.headers.common['Content-Type'] = "application/json";
const Token = gettoken.Token;
axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");//Token;
axios.defaults.headers.common['connection-name'] = localStorage.getItem("userData");

export const billingTicketService = {
    addBillingTicketNotifications: async function (param: any, type: any) {
        const dataToSend = { ...param, type }; // Merge the 'param' object with the 'type' parameter
        let userData: any = localStorage.getItem("permissionData");
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const decryptedBytes = CryptoJS.AES.decrypt(userData, secretKey);
        const response = await axios.request({
            url: `${notificationsV2URL}/notifications/addBillingTicketNotifications`,
            method: "post",
            data: dataToSend
        })
        return response.data
    },
}
