import { postponeReadjustForm,newExtraChargeForm,newExpenseForm,contractSearchForm,historyForm, CustomersAndEmployeesForm, propertyForm, inspectionForm, maintenanceForm, envelopeForm, witnessForm, surveyorForm, ServiceAndProvision } from '../../component/Interface';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
import customerService from '../../services/customerService';
import Swal from 'sweetalert2';
import { converter } from '../../component/constants/utils';

export const STATUSES = Object.freeze({
  IDLE      : "idle",
  LOADING   : "loading",
  SUCEESS   : "success",
  ERROR     : 'error'
});

export interface Columns {
  id    : number;
  name  : string;
}

interface SliceState {
  propertyData: CustomersAndEmployeesForm[],
  propertyChargeData : propertyForm | any,
  propertyAllChargeData : propertyForm | any,
  singlePropertyChargeData : propertyForm | any,
  propertyExpenseData : newExpenseForm | any,
  singlePtyExpenseData : newExpenseForm | any,
  historyData: historyForm | any,
  managerData: Columns[],
  generateContractData : CustomersAndEmployeesForm[]|any,
  administData: Columns[],
  condoData: Columns[],
  brokerData: Columns[],
  ownerData: Columns[],
  propertyOwnerData: Columns[],
  serviceProvision: ServiceAndProvision[],
  status: string,
  chargeStatus : string,
  registryChargeShow: boolean,
  registryInspectionShow: boolean,
  registryMaintenanceShow: boolean,
  registryEnvelopeShow: boolean,
  witnessShow: boolean,
  surveyorShow: boolean,
  historyShow: boolean,
  propertyChargeId : string,
  propertyExpenseId : string,
  propertyExpShow : boolean,
  propertyExpStatus : string,
  propertyChargeStatus  : string,
}

const initialState: SliceState = {
  propertyData: [],
  propertyChargeData : [],
  propertyAllChargeData : [],
  singlePropertyChargeData : [],
  propertyExpenseData : [],
  singlePtyExpenseData : [],
  historyData: [],
  managerData: [],
  generateContractData : [],
  administData: [],
  condoData: [],
  brokerData: [],
  ownerData: [],
  propertyOwnerData : [],
  serviceProvision: [],
  registryChargeShow: false,
  registryInspectionShow: false,
  registryMaintenanceShow: false,
  registryEnvelopeShow: false,
  witnessShow: false,
  surveyorShow: false,
  historyShow: false,
  status: STATUSES.IDLE,
  chargeStatus : STATUSES.IDLE,
  propertyChargeId : "",
  propertyExpenseId : "",
  propertyExpShow : false,
  propertyExpStatus : STATUSES.IDLE,
  propertyChargeStatus : STATUSES.IDLE,
};
let user_id = gettoken.userId

const propertyDataSlice = createSlice({
  name: "propertyDataSlice",
  initialState,
  reducers: {
    chargeClose: (state, action) => {
      state.registryChargeShow = action.payload
    },
    chargeOpen: (state, action) => {
      state.registryChargeShow = action.payload
    },
    inspectionClose: (state, action) => {
      state.registryInspectionShow = action.payload
    },
    inspectionOpen: (state, action) => {
      state.registryInspectionShow = action.payload
    },
    maintenanceClose: (state, action) => {
      state.registryMaintenanceShow = action.payload
    },
    maintenanceOpen: (state, action) => {
      state.registryMaintenanceShow = action.payload
    },
    envelopeClose: (state, action) => {
      state.registryEnvelopeShow = action.payload
    },
    envelopeOpen: (state, action) => {
      state.registryEnvelopeShow = action.payload
    },
    witnessClose: (state, action) => {
      state.witnessShow = action.payload
    },
    witnessOpen: (state, action) => {
      state.witnessShow = action.payload
    },
    surveyorClose: (state, action) => {
      state.surveyorShow = action.payload
    },
    surveyorOpen: (state, action) => {
      state.surveyorShow = action.payload
    },
    historyClose: (state, action) => {
      state.historyShow = action.payload
    },
    historyOpen: (state, action) => {
      state.historyShow = action.payload
    },
    setPropertyChargeId: (state,action) => {
      state.propertyChargeId = action.payload
    },
    propertyExpOpen: (state, action) => {
      state.propertyExpShow = action.payload
    },
    propertyExpClose: (state, action) => {
      state.propertyExpShow = action.payload
    },
    setPropertyExpenseId: (state,action) => {
      state.propertyExpenseId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(registerData.fulfilled, (state, action: PayloadAction<any>) => {
        state.propertyData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(registerData.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllHistory.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.historyData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllHistory.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getManager.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getManager.fulfilled, (state, action: PayloadAction<any>) => {
        state.managerData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getManager.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getGenerateContract.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getGenerateContract.fulfilled, (state, action: PayloadAction<any>) => {
        state.generateContractData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getGenerateContract.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAdministrators.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAdministrators.fulfilled, (state, action: PayloadAction<any>) => {
        state.administData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAdministrators.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getCondos.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getCondos.fulfilled, (state, action: PayloadAction<any>) => {
        state.condoData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getCondos.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getBroker.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getBroker.fulfilled, (state, action: PayloadAction<any>) => {
        state.brokerData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getBroker.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getOwner.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getOwner.fulfilled, (state, action: PayloadAction<any>) => {
        state.ownerData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getOwner.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getPropertyOwner.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getPropertyOwner.fulfilled, (state, action: PayloadAction<any>) => {
        state.propertyOwnerData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getPropertyOwner.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });


    builder
      .addCase(updateProperty_serviceProvision.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateProperty_serviceProvision.fulfilled, (state, action: PayloadAction<any>) => {
        state.serviceProvision = [action.payload]
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateProperty_serviceProvision.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });

    builder
      .addCase(getProperty_serviceProvision.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getProperty_serviceProvision.fulfilled, (state, action: PayloadAction<any>) => {
        state.serviceProvision = [action.payload]
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getProperty_serviceProvision.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllPropertyCharge.pending, (state, action) => {
        state.propertyChargeStatus = STATUSES.LOADING;
      })
      .addCase(getAllPropertyCharge.fulfilled, (state, action: PayloadAction<any>) => {
        state.propertyAllChargeData = action.payload
        state.propertyChargeStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllPropertyCharge.rejected, (state, action: PayloadAction<any>) => {
        state.propertyChargeStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getSinglePropertyCharge.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getSinglePropertyCharge.fulfilled, (state, action: PayloadAction<any>) => {
        state.singlePropertyChargeData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getSinglePropertyCharge.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getPropertyExpense.pending, (state, action) => {
        state.propertyExpStatus = STATUSES.LOADING;
      })
      .addCase(getPropertyExpense.fulfilled, (state, action: PayloadAction<any>) => {
        state.propertyExpenseData = action.payload
        state.propertyExpStatus = STATUSES.SUCEESS;
      })
      .addCase(getPropertyExpense.rejected, (state, action: PayloadAction<any>) => {
        state.propertyExpStatus = STATUSES.ERROR;
      });
     builder
      .addCase(getSinglePropertyExpense.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getSinglePropertyExpense.fulfilled, (state, action: PayloadAction<any>) => {
        state.singlePtyExpenseData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getSinglePropertyExpense.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(updateProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertCharge.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertCharge.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertCharge.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertInspection.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertInspection.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertInspection.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertMaintenance.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertMaintenance.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertMaintenance.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertEnvelope.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertEnvelope.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertEnvelope.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertWitness.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertWitness.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertWitness.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertSurveyor.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertSurveyor.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertSurveyor.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertHistory.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertHistory.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertHistory.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertPostponeReadjust.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertPostponeReadjust.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertPostponeReadjust.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertPropertyExpense.pending, (state) => {
        state.propertyExpStatus = STATUSES.LOADING;
      })
      .addCase(insertPropertyExpense.fulfilled, (state, action) => {
        let response = action.payload
        state.propertyExpStatus = STATUSES.SUCEESS;
      })
      .addCase(insertPropertyExpense.rejected, (state, action) => {
        state.propertyExpStatus = STATUSES.ERROR;
    });
    builder
      .addCase(deleteHistory.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteHistory.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(deleteHistory.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getPropertyCharge.pending, (state, action) => {
        state.chargeStatus = STATUSES.LOADING;
      })
      .addCase(getPropertyCharge.fulfilled, (state, action: PayloadAction<any>) => {
        state.propertyChargeData = action.payload
        state.chargeStatus = STATUSES.SUCEESS;
      })
      .addCase(getPropertyCharge.rejected, (state, action: PayloadAction<any>) => {
        state.chargeStatus = STATUSES.ERROR;
      });
    builder
      .addCase(insertPropertyBillingCharge.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertPropertyBillingCharge.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertPropertyBillingCharge.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  }
})
export const { setPropertyExpenseId,propertyExpOpen,propertyExpClose,setPropertyChargeId,historyOpen, historyClose, surveyorOpen, surveyorClose, witnessOpen, witnessClose, chargeClose, chargeOpen, inspectionClose, inspectionOpen, maintenanceOpen, maintenanceClose, envelopeOpen, envelopeClose } = propertyDataSlice.actions;
export default propertyDataSlice.reducer


/*----------Insert the expense data api-----------*/

export const insertPropertyBillingCharge = createAsyncThunk(
  'contract/insertPropertyBillingCharge',
  async (postData:newExtraChargeForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertPropertyBillingCharge(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Get the single property details api-----------*/

export const registerData = createAsyncThunk(
  'property/fetchPropertyData',
  async (id: number) => {
    let resp = await customerService.getSingleProperty(id);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the single property expense api-----------*/

export const getSinglePropertyExpense = createAsyncThunk(
  'property/getSinglePropertyExpenseData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getPropertyExpense(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the property expense api-----------*/

export const getPropertyExpense = createAsyncThunk(
  'property/getPropertyExpenseData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getPropertyExpense(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the single property charge api-----------*/

export const getSinglePropertyCharge = createAsyncThunk(
  'property/getSinglePropertyChargeData',
  async (postData: contractSearchForm) => {
    let resp = await customerService.getPropertyCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the property charge listing api-----------*/

export const getPropertyCharge = createAsyncThunk(
  'property/getPropertyChargeData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getPropertyCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the history listing api-----------*/

export const getAllHistory = createAsyncThunk(
  'property/getAllHistory',
  async (postData: historyForm) => {
    postData.userId = gettoken.userId
    postData.historyType = 1;
    let resp = await customerService.getAllHistory(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the property charge api-----------*/

export const getAllPropertyCharge = createAsyncThunk(
  'property/getAllPropertyCharge',
  async (postData: historyForm) => {
    let resp = await customerService.getAllPropertyCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------delete the history api-----------*/

export const deleteHistory = createAsyncThunk(
  'property/deleteHistory',
  async (postData: historyForm) => {
    postData.userId = gettoken.userId
    postData.historyType = 1;
    let resp = await customerService.deleteHistory(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Get the manager listing api-----------*/

export const getManager = createAsyncThunk(
  'property/fetchManagerData',
  async () => {
    let mData = { manager: null, start: 0, end: 100 }
    let resp = await customerService.getAllManagers(mData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getGenerateContract = createAsyncThunk(
  'contract/fetchGenerateContractData',
  async () => {
    let contractData = { start: 0, end: 1000 }
    let resp = await customerService.getAllGenerateContract(contractData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the administrators listing api-----------*/

export const getAdministrators = createAsyncThunk(
  'property/fetchAdministratorsData',
  async () => {
    let adminData = { administrator: null, start: 0, end: 100 }
    let resp = await customerService.getAllAdministrators(adminData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the condos listing api-----------*/

export const getCondos = createAsyncThunk(
  'property/fetchCondosData',
  async () => {
    let conData = { condos: null, start: 0, end: 1000 }
    let resp = await customerService.getAllCondos(conData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the property owner listing api-----------*/

export const getPropertyOwner = createAsyncThunk(
  'property/fetchPropertOwnerData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getPropertyOwner(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the broker listing api-----------*/

export const getOwner = createAsyncThunk(
  'property/fetchOwnerData',
  async () => {
    let ownData = { owner: null, start: 0, end: 1000 }
    let resp = await customerService.getAllOwner(ownData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the broker listing api-----------*/

export const getBroker = createAsyncThunk(
  'property/fetchBrokerData',
  async () => {
    let broData = { broker: null, start: 0, end: 1000 }
    let resp = await customerService.getAllBroker(broData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the history data api-----------*/

export const insertPropertyExpense = createAsyncThunk(
  'property/insertPropertyExpenseData',
  async (postData: newExpenseForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertPropertyExpense(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the property charge data api-----------*/

export const insertCharge = createAsyncThunk(
  'property/insertCharge',
  async (postData: propertyForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the property inspection data api-----------*/

export const insertInspection = createAsyncThunk(
  'property/insertInspection',
  async (postData: inspectionForm) => {
    let resp = await customerService.insertInspection(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the property maintenance data api-----------*/

export const insertMaintenance = createAsyncThunk(
  'property/insertMaintenance',
  async (postData: maintenanceForm) => {
    let resp = await customerService.insertMaintenance(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the property envelope data api-----------*/

export const insertEnvelope = createAsyncThunk(
  'property/insertEnvelope',
  async (postData: envelopeForm) => {
    let resp = await customerService.insertEnvelope(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the witness data api-----------*/

export const insertWitness = createAsyncThunk(
  'property/envelope/insertWitness',
  async (postData: witnessForm) => {
    let resp = await customerService.insertWitness(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the surveyor data api-----------*/

export const insertSurveyor = createAsyncThunk(
  'property/inspection/insertSurveyor',
  async (postData: surveyorForm) => {
    let resp = await customerService.insertSurveyor(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the history data api-----------*/

export const insertHistory = createAsyncThunk(
  'property/property/insertHistory',
  async (postData: historyForm) => {
    postData.userId = gettoken.userId
    postData.historyType = 1;
    let resp = await customerService.insertHistory(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the contract postpone readjust data api-----------*/

export const insertPostponeReadjust = createAsyncThunk(
  'constact/constact/insertPostponeReadjust',
  async (postData: postponeReadjustForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertContractPostponeReadjust(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Update the property data api-----------*/

export const updateProperty = createAsyncThunk(
  'property/updateProperty',
  async (postData: CustomersAndEmployeesForm) => {
    const prtyData = {
      userId : Number(gettoken.userId),
      id: postData.id,
      managerId: postData.managerId,
      ownerId: postData.ownerId,
      fundraiserId: postData.fundraiserId,
      hobbyId: postData.hobbyId,
      garageId: postData.garageId,
      rent_amount: postData.rent_amount,
      sale_value: postData.sale_value,
      property_type: postData.property_type,
      zipcode: postData.zipcode,
      address: postData.address,
      number: postData.number,
      complement: postData.complement,
      neighborhood: postData.neighborhood,
      city: postData.city,
      state: postData.state,
      name: postData.name,
      owner_type: postData.owner_type,
      property: postData.property,
      administrator: postData.administrator,
      building: postData.building,
      admin_rate: postData.admin_rate,
      condominium: postData.condominium,
      sequential_code: postData.sequential_code,
      water_code: postData.water_code,
      energy_code: postData.energy_code,
      type_of_electrical: postData.type_of_electrical,
      gas_code: postData.gas_code,
      usable_area: postData.usable_area,
      size: postData.size,
      taxpayer_code: postData.taxpayer_code,
      garbage_fee_code: postData.garbage_fee_code,
      firefighter_code: postData.firefighter_code,
      spu_code: postData.spu_code,
      location: postData.location,
      listed_property: postData.listed_property,
      manager: postData.manager,
      registry: postData.registry,
      registration: postData.registration,
      fundraiser_name: postData.fundraiser_name,
      fundraiser_value: postData.fundraiser_value,
      fundraiser_type: postData.fundraiser_type,
      fundraiser_installments: postData.fundraiser_installments,
      branch: postData.branch,
      identification: postData.identification,
      observation: postData.observation,
      comments: postData.comments,
      huby_identification: postData.huby_identification,
      huby_observation: postData.huby_observation,
      owner : postData.owner,
      hobbybox: postData.hobbybox,
      property_identifier : postData.property_identifier
    }
    let resp = await customerService.updateProperty(prtyData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const deleteProperties = createAsyncThunk(
  'property/deleteProperties',
  async (postData: any) => {
    postData.userId = Number(gettoken.userId);
    let resp = await customerService.deleteProperty(postData);
    return resp
  }
);

export const getProperty_serviceProvision = createAsyncThunk(
  'property/getProperty_serviceProvision',
  async (postData: any) => {
    let resp = await customerService.getSingleProperty(postData);
    let newobj: any;

    if (resp.data.type == "success" || resp.data.type == "Success") {
      let dataresp = resp.data.data[0];
      newobj = {
        "service_provision_adminfee": dataresp.administration_fee,
        "service_provision_admtxt": dataresp.monthly_admin_rate,
        "service_provision_admtxt_percent": dataresp.monthly_admin_rate_percentage,
        "service_provision_condominium": dataresp.administratorsCondos,
        "service_provision_leasetax": dataresp.lease_fee !== "" ? dataresp.lease_fee : 0,
        "service_provision_leasetax_percent": dataresp.lease_fee_percentage,
        "service_provision_maxlease": dataresp.maximum_lease,
        "service_provision_rentvalue":  dataresp.rent_amount ? converter(`${dataresp.rent_amount}`) : "0,00",
        "service_provision_funding_date": dataresp.funding_date,
        "service_provision_salevalue":  dataresp.sale_value ? converter(`${dataresp.sale_value}`) : "0,00",
        "service_provision_termination": dataresp.fine_exemption,
        "service_provision_transfer_day": dataresp.transfer_day,
        "service_provision_day_to_transfer": dataresp.days_of_transfer,
        "service_provision_guaranteed_transfer": dataresp.transfer_guaranteed,
        "months"                               : dataresp.months
      }
    } else {
      newobj = {
        "service_provision_adminfee": "",
        "service_provision_admtxt": "",
        "service_provision_admtxt_percent": false,
        "service_provision_condominium": "",
        "service_provision_leasetax": "",
        "service_provision_leasetax_percent": false,
        "service_provision_maxlease": "",
        "service_provision_rentvalue": "",
        "service_provision_funding_date": "",
        "service_provision_salevalue": "",
        "service_provision_termination": "",
        "service_provision_transfer_day": "",
        "service_provision_day_to_transfer": "",
        "service_provision_guaranteed_transfer": "",
        "months" : ""
      }
    }
    return newobj;
  });

export const updateProperty_serviceProvision = createAsyncThunk(
  'property/updateProperty_serviceProvision',
  async (postData: any) => {
    let newobj = {
      id: postData.id,
      service_provision_adminfee: postData.service_provision_adminfee,
      service_provision_admtxt: postData.service_provision_admtxt,
      service_provision_admtxt_percent: postData.service_provision_admtxt_percent,
      service_provision_condominium: postData.service_provision_condominium,
      service_provision_leasetax: postData.service_provision_leasetax,
      service_provision_leasetax_percent: postData.service_provision_leasetax_percent,
      service_provision_maxlease: postData.service_provision_maxlease,
      service_provision_rentvalue: postData.service_provision_rentvalue,
      funding_date: postData.service_provision_funding_date,
      service_provision_salevalue: postData.service_provision_salevalue,
      service_provision_termination: postData.service_provision_termination,
      service_provision_transfer_day: postData.service_provision_transfer_day,
      service_provision_day_to_transfer: postData.service_provision_day_to_transfer,
      service_provision_guaranteed_transfer: postData.service_provision_guaranteed_transfer,
      months:postData.months
    }

    let resp = await customerService.postSingleProperty(newobj);
    return resp;
  }
);
