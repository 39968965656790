import React, { useEffect, useState } from "react";

import { Outlet, Link } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  Button,
  FloatingLabel,
  Accordion,
  Alert,
  Card,
  Badge,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { billingTicketService } from "../../../../services/billingTicketService";
import { fetchBillingRule } from "../../../../redux/slice/BillingRuleSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import i18n from "../../../../i18n";

const BillingTicket = () => {
  const dispatch = useDispatch<AppDispatch>();
  let emailFirstNotification = useSelector((state: RootState) => state.billingRule['billingRuleState']);
  const [t] = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({});
  const [isChecked, setIsChecked] = useState(false);
  const [notify, setNotify] = useState<any>({
    notify_label: [
      // { value: '', label: "{t("formLabel.select")}" },
      { value: "1", label: "Before expiration" },
      { value: "2", label: "On maturity" },
      { value: "3", label: "After expiration" },
    ],
  });

  useEffect(() => {
    if (i18n.language == "en") {
      setNotify({
        notify_label: [
          { value: "1", label: "Before expiration" },
          { value: "2", label: "On maturity" },
          { value: "3", label: "After expiration" },
        ],
      })
    } else {
      setNotify({
        notify_label: [
          { value: "1", label: "Antes do vencimento" },
          { value: "2", label: "No dia do vencimento" },
          { value: "3", label: "Após o vencimento" },
        ],
      })
    }
  }, [i18n.language]);

  useEffect(() => {
    (async () => {
      dispatch(fetchBillingRule());
    })();
  }, []);

  useEffect(() => {
    if (emailFirstNotification) {
      let { send_email_first_notification, email_first_day, email_first_period, send_email_second_notification, email_second_day, email_second_period, send_email_third_notification, email_third_day, email_third_period, send_email_fourth_notification, email_fourth_day, send_letter_first_notification, letter_first_day, send_letter_second_notification, letter_second_day } = emailFirstNotification
      setValue('email.first_check', send_email_first_notification)
      setValue('email.first_day', email_first_day)
      setValue('email.period_1', email_first_period)
      setValue('email.second_check', send_email_second_notification)
      setValue('email.second_day', email_second_day)
      setValue('email.period_2', email_second_period)
      setValue('email.third_check', send_email_third_notification)
      setValue('email.third_day', email_third_day)
      setValue('email.period_3', email_third_period)
      setValue('email.fourth_check', send_email_fourth_notification)
      setValue('email.fourth_day', email_fourth_day)
      setValue('letter.first_check', send_letter_first_notification)
      setValue('letter.first_day', letter_first_day)
      setValue('letter.second_check', send_letter_second_notification)
      setValue('letter.second_day', letter_second_day)
    }
  }, [emailFirstNotification]);


  let defaultvalue = 0;
  let defaultcheck = false;

  const handleEdit = (e, type) => {
    let editBtn = e.target;
    let parentEle = e.target.parentElement.parentElement;
    let updateBtn = e.target.nextElementSibling;
    editBtn.setAttribute("hidden", "");
    updateBtn.removeAttribute("hidden");
    parentEle.querySelectorAll("input, select").forEach((item) => {
      item.removeAttribute("disabled");
    });
  };

  const onSubmit = (data, e, type) => {
    let parentEle = e.target;
    let editBtn = parentEle.querySelector(".Edit");
    editBtn.removeAttribute("hidden");
    editBtn.nextElementSibling.setAttribute("hidden", "");
    parentEle.querySelectorAll("input, select").forEach((item) => {
      item.setAttribute("disabled", "");
    });
    data = data[type];
    billingTicketService
      .addBillingTicketNotifications(data, type)
      .then((res) => { })
      .catch((error) => {
        console.log("catch-error", error);
      });
    //Api Integration is remain
    Swal.fire({
      position: "center",
      // icon: 'success',"success",
      title: t("Sweetalert.Update"),
      text: t("Sweetalert.updatesuccess"),
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const Optionsdrop = ({ type, count, onChange }) => {
    return (
      <FloatingLabel controlId="period" label={t("billingrule.period")}>
        <Form.Select
          disabled
          aria-label="Period"
          {...register(`${type}.period_${count}`)}
          onChange={onChange}
        >
          {notify.notify_label.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </Form.Select>
      </FloatingLabel>
    );
  };

  const handleFirstDaySelectChange = (event) => {
    const selectedValue = event.target.value;
    const firstDayField: any = document.querySelector(`[name="email.first_day"]`);
    if (selectedValue == 2) {
      setValue('email.first_day', 0);
      firstDayField.setAttribute("disabled", "disabled");

    } else {
      setValue('email.first_day', '');
      firstDayField.removeAttribute("disabled");
    }
  };

  const handleSecondDaySelectChange = (event) => {
    const selectedValue = event.target.value;
    const secondDayField: any = document.querySelector(`[name="email.second_day"]`);
    if (selectedValue == 2) {
      setValue('email.second_day', 0);
      secondDayField.setAttribute("disabled", "disabled");
    } else {
      setValue('email.second_day', '');
      secondDayField.removeAttribute("disabled");
    }
  };

  const handleThirdDaySelectChange = (event) => {
    const selectedValue = event.target.value;
    const thirdDayField: any = document.querySelector(`[name="email.third_day"]`);
    if (selectedValue == 2) {
      setValue('email.third_day', 0);
      thirdDayField.setAttribute("disabled", "disabled");
    } else {
      setValue('email.third_day', '');
      thirdDayField.removeAttribute("disabled");
    }
  };



  return (
    <>
      <Card className="mb-lg-4 mb-3">
        <Card.Header>{t("billingrule.ticket")}</Card.Header>
        <Card.Body className="pb-1">
          <Accordion className="accordion-custom" defaultActiveKey="0">
            <Accordion.Item className="position-relative" eventKey="0">
              <Form onSubmit={handleSubmit((data, e) => onSubmit(data, e, "email"))}>
                <div className="position-absolute end-0 top-0 mt-2 me-5 pe-1 z-index-5">
                  <Button variant="link" size="sm" onClick={(e) => handleEdit(e, "email")} className="Edit fw-bold lh-1 text-primary p-0">{t("button.edit")}</Button>
                  <Button variant="link" className="p-0 fw-bold lh-1 text-primary" size="sm" type="submit" hidden>{t("button.update")}</Button>
                </div>
                <Accordion.Header>{t("billingrule.notificationbyemail")}</Accordion.Header>
                <Accordion.Body>
                  <Alert variant="warning" className="mb-lg-4 mb-3">
                    {t("billingrule.altermessage")}
                  </Alert>
                  <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col md={12} xl>
                      <Form.Check type="checkbox" disabled id="notification_1" label={t("billingrule.sendfirstnotification")} defaultChecked={defaultcheck} {...register('email.first_check')} />
                    </Col>
                    <Col md={5} xl={2}>
                      <FloatingLabel controlId="Days" label={t("billingrule.days")}>
                        <Form.Control type="text" disabled placeholder="Days" defaultValue={defaultvalue} {...register('email.first_day')} />
                      </FloatingLabel>
                    </Col>
                    <Col md xl={4}>
                      <Optionsdrop type="email" count="1" onChange={(e) => {
                        handleFirstDaySelectChange(e);
                      }} />
                    </Col>
                  </Row>
                  <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col md={12} xl>
                      <Form.Check type="checkbox" disabled id="notification_2" label={t("billingrule.sendsecondnotification")} defaultChecked={defaultcheck} {...register('email.second_check')} />
                    </Col>
                    <Col md={5} xl={2}>
                      <FloatingLabel controlId="Days" label={t("billingrule.days")}>
                        <Form.Control type="text" disabled placeholder="Days" defaultValue={defaultvalue} {...register('email.second_day')} />
                      </FloatingLabel>
                    </Col>
                    <Col md xl={4}>
                      <Optionsdrop type="email" count="2" onChange={handleSecondDaySelectChange} />
                    </Col>
                  </Row>
                  <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col md={12} xl>
                      <Form.Check type="checkbox" disabled id="notification_3" label={t("billingrule.sendthirdnotification")} defaultChecked={defaultcheck} {...register('email.third_check')} />
                    </Col>
                    <Col md={5} xl={2}>
                      <FloatingLabel controlId="Days" label={t("billingrule.days")}>
                        <Form.Control type="text" disabled placeholder="Days" defaultValue={defaultvalue}  {...register('email.third_day')} />
                      </FloatingLabel>
                    </Col>
                    <Col md xl={4}>
                      <Optionsdrop type="email" count="3" onChange={handleThirdDaySelectChange} />
                    </Col>
                  </Row>
                  <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col md={12} xl>
                      <Form.Check type="checkbox" disabled id="notification_4" label={t("billingrule.sendfourthnotification")} defaultChecked={defaultcheck}  {...register('email.fourth_check')} />
                    </Col>
                    <Col md xl={4}>
                      <FloatingLabel controlId="Days after expiration" label={t("billingrule.daysafterexpiration")}>
                        <Form.Control type="text" disabled placeholder="Days after expiration" defaultValue={defaultvalue}  {...register('email.fourth_day')} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Form>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="1">
                            <Accordion.Header>SMS notification</Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={handleSubmit((data, e) => onSubmit(data, e, "sms"))}>
                                    <Alert variant="warning" className="mb-lg-4 mb-3">
                                        <span className="d-block">You will be charged a fee of <Badge>R$ 0.10/SMS sent</Badge> directly on your monthly bill. SMS are automatically sent at 10:00 am, 3 days after you create a charge to avoid incorrect messages. You can check the sent messages in the <Link to={''} className="fw-bold">SMS history</Link>.</span>
                                        It is necessary that registered cell phones contain the area code.
                                    </Alert>
                                    <FloatingLabel controlId="sender" label="SMS sender" className="mb-lg-4 mb-3">
                                        <Form.Control type="text" defaultValue={defaultvalue} disabled placeholder="SMS sender"  {...register('sms.sender')} />
                                    </FloatingLabel>
                                    <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={12} xl>
                                            <Form.Check type="checkbox" disabled id="notification_5" defaultChecked={defaultcheck} label="Send first notification" {...register('sms.first_check')} />
                                        </Col>
                                        <Col md xl={4}>
                                            <Optionsdrop type="sms" count="1" />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={12} xl>
                                            <Form.Check type="checkbox" disabled id="notification_6" defaultChecked={defaultcheck} label="Send second notification" {...register('sms.second_check')} />
                                        </Col>
                                        <Col md={5} xl={2}>
                                            <FloatingLabel controlId="Days" label="Days">
                                                <Form.Control type="text" defaultValue={defaultvalue} disabled placeholder="Days" {...register('sms.second_day')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md xl={4}>
                                            <Optionsdrop type="sms" count="2" />
                                        </Col>
                                    </Row>
                                    <Button variant="light" size="sm" onClick={(e) => handleEdit(e, "sms")} className="Edit">Edit</Button>
                                    <Button variant="light" size="sm" type="submit" hidden >Update</Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item> */}
            <Accordion.Item className="position-relative" eventKey="3">
              <Form
                onSubmit={handleSubmit((data, e) =>
                  onSubmit(data, e, "letter")
                )}
              >
                <div className="position-absolute end-0 top-0 mt-2 me-5 pe-1 z-index-5">
                  <Button
                    variant="link"
                    size="sm"
                    onClick={(e) => handleEdit(e, "letter")}
                    className="Edit lh-1 p-0 fw-bold text-primary"
                  >
                    {t("button.edit")}
                  </Button>
                  <Button
                    variant="link"
                    size="sm"
                    type="submit"
                    className="p-0 fw-bold lh-1 text-primary"
                    hidden
                  >
                    {t("button.update")}
                  </Button>
                </div>
                <Accordion.Header>
                  {t("billingrule.extrajudicialnotice")}
                </Accordion.Header>
                <Accordion.Body>
                  <Alert variant="warning" className="mb-lg-4 mb-3">
                    {t("billingrule.systemwillautomatically")}{" "}
                    <Link to={""} className="fw-bold">
                      {t("billingrule.accesswillreceive")}
                    </Link>
                  </Alert>
                  <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col md={12} xl>
                      <Form.Check
                        type="checkbox"
                        disabled
                        id="notification_7"
                        label={t("billingrule.sendfirstnotification")}
                        defaultChecked={defaultcheck}
                        {...register("letter.first_check")}
                      />
                    </Col>
                    <Col md xl={4}>
                      <FloatingLabel
                        controlId="Days"
                        label={t("billingrule.daysafterexpiration")}
                      >
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Days after expiration."
                          defaultValue={defaultvalue}
                          {...register("letter.first_day")}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col md={12} xl>
                      <Form.Check
                        type="checkbox"
                        disabled
                        id="notification_8"
                        label={t("billingrule.sendsecondnotification")}
                        defaultChecked={defaultcheck}
                        {...register("letter.second_check")}
                      />
                    </Col>
                    <Col md xl={4}>
                      <FloatingLabel
                        controlId="Days"
                        label={t("billingrule.daysafterexpiration")}
                      >
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Days after expiration."
                          defaultValue={defaultvalue}
                          {...register("letter.second_day")}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Form>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
      {/* <Card className="mb-lg-4 mb-3">
                <Card.Header>Credit card</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit((data, e) => onSubmit(data, e, "card"))}>
                        <span className="mb-3 fw-bold d-block">Notification by email</span>
                        <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" id="notification_9" label="Send message when payment is successful" defaultChecked={defaultcheck} {...register('card.payment_check')} />
                        <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" id="notification_10" label="Send message when credit attempt fails" defaultChecked={defaultcheck}  {...register('card.credit_check')} />
                        <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" id="notification_11" label="Send last attempt message (sending an alternative payment slip)" defaultChecked={defaultcheck} {...register('card.last_attempt_check')} />
                        <Button variant="light" size="sm" onClick={(e) => handleEdit(e, "card")} className="Edit">Edit</Button>
                        <Button variant="light" size="sm" type="submit" hidden >Update</Button>
                    </Form>
                </Card.Body>
            </Card> */}
    </>
  )
}

export default BillingTicket;
