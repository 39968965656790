import { Form, Accordion, Modal, Button, FloatingLabel } from 'react-bootstrap'; 
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form"; 
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { maillingform } from '../../../Interface';
import Swal from 'sweetalert2'; 
import '../../../../App.css';

const MaillingLabel = (props) => {
    let {show} = props;
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [allsize, setallsize] = useState([
        { label: "Pimaco 8923" },
        { label: "MaxPrint 8923" },
        { label: "Pimaco 5580A" },
        { label: "Pimaco 5580M" },
        { label: "Pimaco 5580V" },
        { label: "Pimaco 6080" },
        { label: "Pimaco 6180" },
        { label: "Pimaco 6280" },
        { label: "Pimaco 62580" },
        { label: "Pimaco 6081" },
        { label: "Pimaco 6181" },
        { label: "Pimaco 6281" },
        { label: "Pimaco 62581" },
        { label: "Pimaco 6082" },
        { label: "Pimaco 6182" },
        { label: "Pimaco 6282" },
        { label: "Pimaco 62582" },
        { label: "Pimaco 6083" },
        { label: "Pimaco 6183" },
        { label: "Pimaco 6283" },
        { label: "Pimaco 6184" },
        { label: "Pimaco 6284" },
        { label: "Pimaco 6085" },
        { label: "Pimaco 6185" },
        { label: "Pimaco 6285" },
        { label: "Pimaco 6286" },
        { label: "Pimaco 6087" },
        { label: "Pimaco 6187" },
        { label: "Pimaco 6287" },
        { label: "Pimaco 6288" },
        { label: "Pimaco 6089" },
        { label: "Pimaco 6093" },
        { label: "Pimaco 6095" },
        { label: "Pimaco 8096" },
        { label: "Pimaco 8296" },
        { label: "Pimaco 8098" },
        { label: "Pimaco 8099F" },
        { label: "Pimaco 8099L" },
        { label: "Pimaco A4248" },
        { label: "Pimaco A4348" },
        { label: "Pimaco A4249" },
        { label: "Pimaco A4349" },
        { label: "Pimaco A4250" },
        { label: "Pimaco A4350" },
        { label: "Pimaco A4251" },
        { label: "Pimaco A4351" },
        { label: "Pimaco A4254" },
        { label: "Pimaco A4354" },
        { label: "Pimaco A4255" },
        { label: "Pimaco A4355" },
        { label: "Pimaco A4256" },
        { label: "Pimaco A4356" },
        { label: "Pimaco A4260" },
        { label: "Pimaco A4360" },
        { label: "Pimaco A4361" },
        { label: "Pimaco A4262" },
        { label: "Pimaco A4362" },
        { label: "Pimaco A4263" },
        { label: "Pimaco A4363" },
        { label: "Pimaco A4365" },
        { label: "Pimaco A4366" },
        { label: "Pimaco A4267" },
        { label: "Pimaco A4367" },
        { label: "Pimaco A4368" }
    ])
    const maturityClosed = () => {
    }
    const maturityValidation = Yup.object().shape({
        template: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    let frm = {
        resolver: yupResolver(maturityValidation)
    }
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<maillingform>(frm);
    const onSubmit = async (data: maillingform, e: any) => {
        e.preventDefault();
        Swal.fire({
            title:t("Sweetalert.Error"),
            text:t("Sweetalert.somethingWent"),
            // icon:"error",
            confirmButtonText:"OK",
            timer:2000})
    }
    return (
        <>
            {/* Change maturity modal start from here */}
            <Modal show={show} onHide={maturityClosed}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Mailing Label")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel className="mb-3" controlId="template" label={t('Template')}>
                            <Form.Select className={` ${errors.template ? 'is-invalid' : ''}`}  {...register('template')} aria-label={t('template')}>
                                <option value="">{t('formLabel.select')}</option>
                                {
                                    allsize.map((item, index) => (
                                        <option key={index} value="2">{item.label}</option>
                                    ))
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.template?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel className="mb-3" controlId="position" label={t("Position of the 1st label")}>
                            <Form.Control type="text" className={`form-control ${errors.position ? 'is-invalid' : ''}`} {...register('position')} placeholder={t("Position of the 1st label")} />
                            <Form.Control.Feedback type="invalid">{errors.position?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel className="mb-3" controlId="copies" label={t("No. of copies")}>
                            <Form.Control type="text" className={`form-control ${errors.copies ? 'is-invalid' : ''}`} {...register('copies')} placeholder={t("No. of copies")} />
                            <Form.Control.Feedback type="invalid">{errors.copies?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={maturityClosed}>
                            {t("button.close")}
                        </Button>
                        <Button className="min-w-auto" type="submit" variant="primary">
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change maturity modal end */}
        </>
    )
}
export default MaillingLabel;