import React, { useState, MouseEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ComProps, Readjustment } from '../../../Interface';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, FloatingLabel, Dropdown } from 'react-bootstrap';
import { AppDispatch, RootState } from '../../../../redux/store'
import { ServiceList } from './ServiceList';
import { enable_disable, fetchService, serviceTaxcod } from '../../../../redux/slice/ServiceSlice';
import { NewService } from './NewService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { encrypt_Decrypt } from '../../../constants/utils';

const Service = ({ submenu }: ComProps) => {
  const [t] = useTranslation()
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>();
  // Define state variables
  const [show2, setShow2] = useState(false);
  const [headingText, setHeadingText] = useState("Filter");
  // Define validation schema using Yup
  const filter_servicevalidationSchema = Yup.object().shape({
    status: Yup.string().trim().nullable().required('Status is required')
  });

  const navigate = useNavigate();
  const [userJson, setUserJson] = useState<any>([]);
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || userResult.indexOf(1070) === -1) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
  }, [])

  // The useForm hook is used to handle form registration, submission, and validation.
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(filter_servicevalidationSchema)
  });
  const [getId, setGetId] = useState([]);
  const filter_serviceClose = () => { setShow2(false); reset(); }
  const [addnewservice, setAddNewService] = useState([]);
  const [singleserv, setSingleServ] = useState([]);
  const serviceData: any = useSelector((state: RootState) => state.service);


  useEffect(() => {
    dispatch(serviceTaxcod());
  }, [])
  const handleMouseEvent = (e) => {
    e.preventDefault();
    var type = e.currentTarget.id;
    setHeadingText("Filter")
    // Set the initial heading text to "Filter"
    if (type === "service") {
      // If the type is "service", update the heading text to "Status"
      setHeadingText("Status")
    }
    setShow2(true)
  };

  const handleCheck = (e, type: string) => {
    let element: any = "";
    // Check if the form-check-input element exists in the table header
    if (document.querySelectorAll('.table-custom thead .form-check-input').length > 0) {
      element = document.querySelector('.table-custom thead .form-check-input');
    }
    // Check the element if it's not already checked
    if (type == "all") {
      if (!element?.checked) {
        element.click();
      }
    } else if (type == "none") {
      if (element?.checked) {
        element.click();
      } else {
        // Uncheck all the form-check-input elements in the table body
        if (document.querySelectorAll('.table-custom tbody .form-check-input').length > 0) {
          document.querySelectorAll('.table-custom tbody .form-check-input').forEach(item => {
            if (item["checked"]) {
              item["checked"] = false
            }
          });
        }
      }
    }
  }

  const [listcount, setListCount] = useState(0);

  const on_servicefilterSubmit = async (data) => {
    // Create a new object with the required data
    let newdata = {
      "status": data["status"],
      "service_id": [...getId]
    }
    // Check if any services are selected
    if (getId.length < 1) {
      // Display a warning message if no services are selected
      Swal.fire({
        title: `${t("Sweetalert.Warning")}`,
        text: `${t("Sweetalert.selectServices")}`,
        // icon: 'success','warning',
        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
      })
    } else {
      // Dispatch the enable/disable action and await the response
      let res = await dispatch(enable_disable(newdata));
      if (res["payload"]?.["data"]?.["type"] == "success" || res["payload"]?.["data"]?.["type"] == "Success") {
        filter_serviceClose();
        // Clear the selected services
        setAddNewService([]);
        // Display a success message
        Swal.fire({
          title: `${t("Sweetalert.Success")}`,
          text: `${t("Sweetalert.RecordUpdated")}`,
          // icon: 'success','success',
          confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
        })
      };
      // Show an error message
      if (res["payload"]?.["data"]?.["type"] == "error") {
        Swal.fire({
          title: `${t("Sweetalert.Error")}`,
          text: `${t("Sweetalert.cannotupdated")}`,
          // icon: 'success','error',
          confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
        })
      };
    }
  };

  return (
    <section className="content-wrapper content-wrapper-primary" id="captureElement">
      {/* <!-- Breadcrumb section start from here --> */}
      {submenu}
      {/* <!-- /Breadcrumb section end --> */}
      {/* <!-- Main content start from here --> */}
      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
        {/* Service list */}
        <ServiceList setSingleServ={setSingleServ} addnewservice={addnewservice} setGetIdarr={setGetId} setListCount={setListCount} />
        {/* More Items - Auto - All Items */}
        <p className="mt-lg-4 mt-3" id="listingCount">{t("formLabel.listing")} {listcount} {t("taxation.services")}.</p>
        {/* <!-- Nav listing start from here --> */}

        {
          userJson && userJson.length > 0 && (userJson.indexOf(1071) !== -1) &&
          <>
            <ul className="list-inline mb-0">
              <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                <Dropdown>
                  <Dropdown.Toggle variant="light" size="sm">
                    {t("formLabel.mark")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={(e: any) => handleCheck(e, "all")}>{t("formLabel.all")}</Dropdown.Item>
                    <Dropdown.Item onClick={(e: any) => handleCheck(e, "none")}>{t("formLabel.none")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"><span className="px-1">{t("formLabel.withTagged")}:</span></li>
              <li className="list-inline-item mb-lg-0 mb-1">
                <Button variant="light" size="sm" onClick={handleMouseEvent} id="service" >{t('ativarDesativar')}</Button>
              </li>
            </ul>
          </>
        }

        {/* <!-- /Nav listing  end --> */}
      </main>
      <NewService setSingleService={setSingleServ} singleservice={singleserv} setAddNewService={setAddNewService} />
      {/* <!-- /Main content start end --> */}
      {/* Service filter start from here */}
      <Modal show={show2} onHide={filter_serviceClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("dropdownItem.status")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(on_servicefilterSubmit)}>
          <Modal.Body>
            <FloatingLabel controlId="floatingInput_Field" label={t("dropdownItem.status")}>
              <Form.Select className={`${errors.status ? 'is-invalid' : ''}`}  {...register('status')}>
                <option value="">{t('dropdownItem.select')}</option>
                <option value="0">{t("active")}</option>
                <option value="1">{t("formReceipt.disable")}</option>
              </Form.Select>
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={filter_serviceClose}>{t("button.close")}</Button>
            <Button type="submit" variant="primary">{t("button.toSave")}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  )
}
export default Service;


