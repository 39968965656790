import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
// import { newChecklistItemClose,insertChecklists,getAllChecklists } from "../../../../redux/slice/expenditureDataSlice";
import { newChecklistItemClose, addChecklistItem } from "../../../../redux/slice/contractDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { checklistsForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { getAllChecklistItems, getAllContractChecklist, getAllChecklists } from "../../../../redux/slice/expenditureDataSlice";
import '../../../../App.css';

const ChecklistsService = (props) => {
	const { newChecklistItemShow } = useSelector((state: RootState) => state.contractData)
	const dispatch = useDispatch<AppDispatch>()
	const [t] = useTranslation();
	const { id } = useParams();
	const [double, setDouble] = useState(false);
	// newAgencyChecklistItem = if agency is adding new item in checklist not linked with contract.
	const { myChecklists, setMyChklist, contractId, newAgencyChecklistItem, selectedChecklistsitem, setselectedChklistitem } = props;
	const { checklistItems } = useSelector((state: RootState) => state.expenditureData)
	const checklistItemHide = () => {
		setDouble(false);
		setselectedChklistitem("");
		setMyChklist("");
		dispatch(newChecklistItemClose(false));
		reset();
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
		// notificationPeriod: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
		// items: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
		responsible: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired'))
	});
	var frm = {
		resolver: yupResolver(validationSchema)
	}
	const { register, handleSubmit, reset, setValue, setError, getValues, formState: { errors } } = useForm<checklistsForm>(frm);

	useEffect(() => {
		if (newChecklistItemShow) {
			// dispatch(getAllChecklistItems({ "start": 0, "end": 1000 }))
			if (selectedChecklistsitem && selectedChecklistsitem !== "") {
				setValue("name", selectedChecklistsitem.ST_NOMEITEM_CHI);
				setValue("responsible", selectedChecklistsitem.FL_RESPONSAVEL_CHI);
				setValue("observation", selectedChecklistsitem.ST_OBSERVACAO_CHI);
				setValue("deliverBy", selectedChecklistsitem.NM_DIASPARAENTREGA_CHI);
				setValue("mandatoryProof", selectedChecklistsitem.FL_COMPROVANTEOBRIGATORIO_CHI);
				setValue("showinOwliApp", selectedChecklistsitem.FL_EXIBIRNOAPP_CHI);
			}
		}
	}, [newChecklistItemShow])

	const onSubmit = async (data: checklistsForm, e: any) => {
		e.preventDefault();
		setDouble(true)
		data['contractId'] = contractId;
		data['checklistId'] = (myChecklists && myChecklists?.id) ? myChecklists.id : "";
		data['checklistitemId'] = (selectedChecklistsitem && selectedChecklistsitem?.ID_CHECKLISTITEM_CHI) ? selectedChecklistsitem.ID_CHECKLISTITEM_CHI : "";
		data['newAgencyChecklistItem'] = newAgencyChecklistItem;
		let response = await dispatch(addChecklistItem(data))
		if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
			setDouble(false);
			Swal.fire({
				// icon: 'success','success',
				title: t("Sweetalert.Success"),
				text: t("Sweetalert.successget"),
				confirmButtonText: "OK",
				timer: 2000
			})
			checklistItemHide();
			dispatch(getAllChecklistItems({ "start": 0, "end": 1000 }))
			dispatch(getAllChecklists({ "start": 0, "end": 10 }))
			dispatch(getAllContractChecklist({ id: id, start: 0, end: 10 }));
		}
		else {
			setDouble(false)
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: t("Sweetalert.successfailed"),
				// icon:"error",
				confirmButtonText: "OK",
				timer: 2000
			})
		}
	}
	return (
		<>
			{/* New checklist modal start from here */}
			<Modal show={newChecklistItemShow} onHide={checklistItemHide}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Modal.Header closeButton>
						<Modal.Title>{t("checklistother.newchecklistItems")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="gy-lg-4 gy-3 mb-3">
							<Col md={8}>
								<FloatingLabel controlId="floatingInput" label={t("checklistother.description") + " *"}>
									<Form.Control type="text" placeholder={t("formLabel.name") + " *"} className={`${errors.name ? 'is-invalid' : ''}`}  {...register('name')} />
									<Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
							<Col xs>
								<FloatingLabel controlId="floatingSelect" label={t("checklistother.responsible") + " *"}>
									<Form.Select className={`${errors.responsible ? 'is-invalid' : ''}`} {...register('responsible')} aria-label="Responsible">
										<option value="">{t('formLabel.select')}</option>
										<option value="2">{t('notifications.owner')}</option>
										<option value="1">{t('notifications.tenant')}</option>
										<option value="3">{t('realEstate')}</option>
										<option value="4">{t('Guarantor')}</option>
									</Form.Select>
									<Form.Control.Feedback type="invalid">{errors.responsible?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
						</Row>
						{!newAgencyChecklistItem &&
							<Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
								<Col md={5}>
									<Form.Group>
										<Form.Check type="checkbox"  {...register('is_notify')} label={t("checklistother.notificationperiod")} />
									</Form.Group>
								</Col>
								<Col md={7}>
									<FloatingLabel controlId="floatingSelect" label={t("checklistother.notificationperiod")}>
										<Form.Select className={`${errors.notificationPeriod ? 'is-invalid' : ''}`} {...register('notificationPeriod')} aria-label="Notification period">
											<option value="">{t('formLabel.select')}</option>
											<option value="1">Weekly</option>
											<option value="2">fortnightly</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid">{errors.notificationPeriod?.message}</Form.Control.Feedback>
									</FloatingLabel>
								</Col>
							</Row>
						}

						{/* ITEM DETAILS */}
						<Row className="gy-lg-4 gy-3 align-items-center">
							<Col md={12}>
								<FloatingLabel controlId="floatingInput" label={t("checklistother.observation")}>
									<Form.Control as="textarea" placeholder={t("checklistother.observation")} className={`${errors.observation ? 'is-invalid' : ''}`}  {...register('observation')} style={{ height: '100px' }} />
								</FloatingLabel>
							</Col>
							<Col md={12}>
								<FloatingLabel controlId="floatingInput" label={t("checklistother.deliverBy")}>
									{!newAgencyChecklistItem ?
										<Form.Control type="date" placeholder={t("checklistother.deliverBy")} 
											className={`form-control ${errors.deliverBy ? 'is-invalid' : ''}`} 
											{...register('deliverBy')} 
										/>
										:
										<Form.Control type="text" placeholder={t("checklistother.deliverBy")} maxLength={2}
											className={`form-control ${errors.deliverBy ? 'is-invalid' : ''}`}
											{...register('deliverBy', {
												pattern: {
													value: /^[0-9]*$/,
													message: t("Only numbers are allowed"),
												},
												maxLength: {
													value: 2,
													message: t("Maximum length is 2 characters"),
												}
											})}
										/>
									}
									<Form.Control.Feedback type="invalid">{errors.deliverBy?.message}</Form.Control.Feedback >
								</FloatingLabel>
							</Col>
							<Col md="auto">
								<Form.Group>
									<Form.Check type="checkbox"  {...register('mandatoryProof')} label={t("checklistother.mandatoryProof")} />
								</Form.Group>
							</Col>
							<Col md="auto">
								<Form.Group>
									<Form.Check type="checkbox"  {...register('showinOwliApp')} label={t("checklistother.showinOwliApp")} />
								</Form.Group>
							</Col>
						</Row>
						{/* <a className="text-primary fw-bold d-block">{t("formLabel.another")}</a> */}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-primary" onClick={checklistItemHide}>{t("button.close")}</Button>
						<Button disabled={double} type="submit" variant="primary" name="send">{t("button.toSave")}</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			{/* /New checklist modal end */}
		</>
	)
}
export default ChecklistsService;