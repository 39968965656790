import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { surveyorClose, inspectionOpen, insertSurveyor } from "../../../../redux/slice/propertyDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { surveyorForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF, handleKeyDown } from '../../../constants/utils';
import i18n from '../../../../i18n';

const SurveyorService = (surveyorForm) => {
  const dispatch = useDispatch<AppDispatch>()
  const { billingData } = useSelector((state: RootState) => state.expenditureData)
  useEffect(() => {
    if (surveyorShow) {
      dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
    }
  }, [dispatch]);

  const [hide1, setHide1] = useState(true);
  const [hide2, setHide2] = useState(false);
  const [hide3, setHide3] = useState(false);
  const [t] = useTranslation();
  const { surveyorShow } = useSelector((state: RootState) => state.propertyData)
  const step1 = () => { setHide1(true); setHide2(false); setHide3(false); }
  const step2 = () => { setHide1(false); setHide2(true); setHide3(false); }
  const step3 = () => { setHide1(false); setHide2(false); setHide3(true); }
  const newSurveyorClose = () => {
    let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
    zipCodeCheck?.["removeAttribute"]('disabled');
    setHide1(true); setHide2(false); setHide3(false); dispatch(inspectionOpen(true)); dispatch(surveyorClose(false)); reset();
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const envelopeValidationStep1 = Yup.object().shape({
    cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
  });
  const envelopeValidationStep2 = Yup.object().shape({
    name: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    mobile: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
      .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
    telephone: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
      .matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
    email: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
      .email(t('requiredMmessage.fieldNotValid')),
    rg: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    issuingAgency: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    shippingDate: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    sex: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    birth: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    maritalStatus: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    nationality: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    paymentMethod: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
  });
  let frm = {
    resolver: yupResolver(envelopeValidationStep1)
  }
  if (hide2) {
    frm = {
      resolver: yupResolver(envelopeValidationStep2)
    }
    openErrorbox();
  }
  const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<surveyorForm>(frm);
  const HandleInput = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('telephone');
    }
  }
  const HandleInputMob = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("mobile", {
        type: "manual",
        message: `${t("requiredMmessage.mobileError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('mobile');
    }
  }
  const onSubmit = async (data: surveyorForm, e: any) => {
    e.preventDefault();
    let response = await dispatch(insertSurveyor(data));
    if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:t("Sweetalert.successget"),
        confirmButtonText:"OK",
        timer:2000})
      newSurveyorClose();
    }
    else {
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }

  const handleCPF_CNJPVaue = async (e, type) => {
    let name = e.target.name;
    let checkCNJP = CNJP_CPFValidation(e.target.value, type);
    if (checkCNJP?.["status"] || e.target.value == "") {
      clearErrors(name)
      let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
      zipCodeCheck?.["setAttribute"]('disabled', "")
      if (e.target.value !== "") {
        let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
        if (duplicateData["data"] > 0) {
          zipCodeCheck?.["setAttribute"]('disabled', "")
          setError(name, {
            type: "manual",
            message: t("formLabel.ErrorCNPJCPF")
          });
        } else {
          zipCodeCheck?.["removeAttribute"]('disabled');
        }
      }
    } else {
      // errorCNPJCPF(e.target.value)
      setError(name, {
        type: "manual",
        message: checkCNJP?.["message"]
      });
    };
  }

  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={surveyorShow} onHide={newSurveyorClose}>
        <Modal.Header closeButton>
          <Modal.Title>New surveyor</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div data-step="1" style={{ display: hide1 ? 'block' : 'none' }}>
              <FloatingLabel label="CNPJ/CPF">
                <Form.Control type="text" id="cnpj" placeholder="CNPJ/CPF" className={`form-control ${errors.cnpj ? 'is-invalid' : ''}`} {...register("cnpj")} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div data-step="2" style={{ display: hide2 ? 'block' : 'none' }}>
              <FloatingLabel className="mb-3" label="Name">
                <Form.Control type="text" id="name" placeholder="Name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register("name")} onKeyDown={handleKeyDown} />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback >
              </FloatingLabel>
              <span className="d-block mb-3 fw-bold">Payment</span>
              <FloatingLabel className="mb-3" label="Default payment method">
                <Form.Select id="paymentMethod" aria-label="Default payment method" className={`form-control ${errors.paymentMethod ? 'is-invalid' : ''}`} {...register('paymentMethod')}>
                  <option value="">{t('dropdownItem.select')}</option>
                  {/* {
                    billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                        <option key={index} value={Number(item.id)}>{item.name}</option>
                    ))
                        : ''
                } */}
                  <option value="1">{t("defaultPaymentMethod1.chequedeposit")}</option>
                  <option value="2">{t("defaultPaymentMethod1.cashdeposit")}</option>
                  <option value="3">{t("defaultPaymentMethod1.pickupchequeonsite")}</option>
                  <option value="4">{t("defaultPaymentMethod1.withdrawmoneyonthespot")}</option>
                  <option value="5">{t("defaultPaymentMethod1.banktransfer")}</option>
                  <option value="6">{t("defaultPaymentMethod1.docted")}</option>
                  <option value="12">{t("defaultPaymentMethod1.pixboleto")}</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.paymentMethod?.message}</Form.Control.Feedback>
              </FloatingLabel>
              <span className="d-block mb-3 fw-bold">Contact</span>
              <Row>
                <Col>
                  <FloatingLabel className="mb-3" label="Mobile (DDD + Number)">
                    <Form.Control type="text" id="mobile" placeholder="Mobile (DDD + Number)" className={`form-control ${errors.mobile ? 'is-invalid' : ''}`} {...register("mobile")} onChange={HandleInputMob} />
                    <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="mb-3" label="Telephone">
                    <Form.Control type="text" id="telephone" placeholder="Telephone" className={`form-control ${errors.telephone ? 'is-invalid' : ''}`} {...register("telephone")} onChange={HandleInput} />
                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel className="mb-3" label="Email">
                <Form.Control type="email" id="email" placeholder="Email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email")} />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback >
              </FloatingLabel>
              <Accordion className="accordion-custom">
                <Accordion.Item eventKey="1" >
                  <Accordion.Header>More options</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <FloatingLabel className="mb-3" label="RG">
                          <Form.Control id="rg" type="text" placeholder="RG" className={`form-control ${errors.rg ? 'is-invalid' : ''}`} {...register('rg')} />
                          <Form.Control.Feedback type="invalid">{errors.rg?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col>
                        <FloatingLabel className="mb-3" label="Issuing agency">
                          <Form.Control id="issuingAgency" type="text" placeholder="Issuing agency" className={`form-control ${errors.issuingAgency ? 'is-invalid' : ''}`} {...register('issuingAgency')} />
                          <Form.Control.Feedback type="invalid">{errors.issuingAgency?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FloatingLabel className="mb-3" label="Shipping date">
                          <Form.Control id="shippingDate" type="date" placeholder="Shipping date" className={`form-control ${errors.shippingDate ? 'is-invalid' : ''}`} {...register('shippingDate')} />
                          <Form.Control.Feedback type="invalid">{errors.shippingDate?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col >
                        <FloatingLabel className="mb-3" label="Sex">
                          <Form.Select id="sex" aria-label="Sex" className={`form-control ${errors.sex ? 'is-invalid' : ''}`} {...register('sex')}>
                            <option value="">{t('dropdownItem.select')}..</option>
                            <option value="1">{t("formLabel.male")}</option>
                            <option value="2">{t("formLabel.female")}</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.sex?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FloatingLabel className="mb-3" label="Birth">
                          <Form.Control id="birth" type="date" placeholder="Birth" className={`form-control ${errors.birth ? 'is-invalid' : ''}`} {...register('birth')} />
                          <Form.Control.Feedback type="invalid">{errors.birth?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col >
                        <FloatingLabel className="mb-3" label="Marital status">
                          <Form.Select id="maritalStatus" aria-label="Marital status" className={`form-control ${errors.maritalStatus ? 'is-invalid' : ''}`} {...register('maritalStatus')}>
                            <option value="">{t('formLabel.select')}</option>
                                                        <option value="1">{t("formLabel.married")}</option>
                                                        <option value="2">{t("formLabel.single")}</option>
                                                        <option value="3">{t("formLabel.widower")}</option>
                                                        <option value="4">{t("formLabel.legallySeparated")}</option>
                                                        <option value="5">{t("formLabel.disquitado")}</option>
                                                        <option value="6">{t("formLabel.divorced")}</option>
                                                        <option value="7">{t("formLabel.stableunion")}</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.maritalStatus?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <FloatingLabel className="mb-3" label={t('formLabel.nationality')}>
                        <Form.Control type="text" id="nationality" placeholder={t('formLabel.nationality')} className={`form-control ${errors.nationality ? 'is-invalid' : ''}`} {...register("nationality")} />
                        <Form.Control.Feedback type="invalid">{errors.nationality?.message}</Form.Control.Feedback >
                      </FloatingLabel>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" >
                  <Accordion.Header>Observation</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <FloatingLabel label={t('formLabel.observation')} >
                          <Form.Control as="textarea" id="observation" placeholder="Observation" {...register('observation')} style={{ height: '100px' }} />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div data-step="3" style={{ display: hide3 ? 'block' : 'none' }}>
              <div className="text-center">
                <h3 className="mt-3 mb-2">{t('label.registeredSurveyor')}</h3>
                <Button type="button" variant="light" size="sm" className="mb-2 px-4">{t('label.inviteToApp')}</Button>
                <p className="text-secondary">{t('label.checkAvailability')}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={newSurveyorClose}>
              {t('button.close')}
            </Button>
            <Button variant="outline-primary" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)} className="js-btn-step">
              {t('button.previous')}
            </Button>
            {
              !hide3 ?
                <Button variant="primary" onClick={handleSubmit(hide1 ? step2 : step3)} className="min-w-auto js-btn-step zipCodeCheck btn-primary-focus-none">
                  {t('button.next')}
                </Button>
                :
                <Button variant="primary" type="submit" onClick={openErrorbox} className='btn-primary-focus-none'>
                  {t('button.save')}
                </Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default SurveyorService;