import React from "react";

import { BankAccountProvider } from "../BankAccountForm";
import { BankAccountDrawerContainer } from "./BankAccountDrawerContainer";

export function BankAccountDrawer() {
  return (
    <BankAccountProvider>
      <BankAccountDrawerContainer />
    </BankAccountProvider>
  );
}
