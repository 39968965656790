import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ClientCollaboratorsSettings = (props) => {
    const [t] = useTranslation()

    const { showCollaboratorsSetting, collaboratorsSettingClose, onSave, initialCheckboxState } = props;

    const [checkboxState, setCheckboxState] = useState(initialCheckboxState);
    const [unsavedCheckboxState, setUnsavedCheckboxState] = useState(initialCheckboxState);

    useEffect(() => {
        setUnsavedCheckboxState(initialCheckboxState);
    }, [initialCheckboxState]);

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setUnsavedCheckboxState((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    const handleSave = () => {
        onSave(unsavedCheckboxState);
        collaboratorsSettingClose();
    };

    const handleClose = () => {
        setUnsavedCheckboxState(initialCheckboxState);
        collaboratorsSettingClose();
    };

    return (
        <>
            <Modal show={showCollaboratorsSetting} onHide={handleClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("formLabel.settings")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span className="fw-bold d-block mb-3">{t("formLabel.clientCollaborators")}</span>
                        <Form.Check
                            type="checkbox"
                            id="cb1"
                            className="mb-lg-4 mb-3"
                            label={t("formLabel.Lockowner")}
                            checked={unsavedCheckboxState.cb1}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="cb2"
                            className="mb-lg-4 mb-3"
                            label={t("formLabel.Locktenant")}
                            checked={unsavedCheckboxState.cb2}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="cb3"
                            className="mb-lg-4 mb-3"
                            label={t("formLabel.Lockguarantor")}
                            checked={unsavedCheckboxState.cb3}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="cb4"
                            className="mb-lg-4 mb-3"
                            label={t("formLabel.Locksurveyor")}
                            checked={unsavedCheckboxState.cb4}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="cb5"
                            className="mb-lg-4 mb-3"
                            label={t("formLabel.Blockbroker")}
                            checked={unsavedCheckboxState.cb5}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="cb6"
                            className="mb-lg-4 mb-3"
                            label={t("formLabel.Alwaysreimburse")}
                            checked={unsavedCheckboxState.cb6}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="cb7"
                            className="mb-lg-4 mb-3"
                            label={t("formLabel.Alwaysreimburse")}
                            checked={unsavedCheckboxState.cb7}
                            onChange={handleCheckboxChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default ClientCollaboratorsSettings