import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Button, FloatingLabel, Row, Col, Alert, Offcanvas, InputGroup, Tooltip, OverlayTrigger, ListGroup } from 'react-bootstrap';
import { Outlet, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getContractSetting, InsertContractSettings } from '../../../../redux/slice/contractDataSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '../../../../redux/store';
import Swal from 'sweetalert2';
import { formatNumber, converter } from '../../../constants/utils';
const ContractSettings = (props) => {
    const [t] = useTranslation();
    const schema = yup.object().shape({
    });
    //Contract settings modal close open function
    const dispatch = useDispatch<AppDispatch>();
    const inputRef = useRef<any>(null);
    const { showContractSetting, contractSettingClose, contractSettingShow } = props;
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors, watch } = useForm<any>({
        resolver: yupResolver(schema),
    });
    const { ContractSettingData, serviceData } = useSelector((state: RootState) => state.contractData);
    useEffect(() => {
        dispatch(getContractSetting())
    }, [dispatch])

    // Watch checkbox state for enabling/disabling bank fee amount
    const isBankFeeChecked = watch("bank_fee_checkbox", false);

    useEffect(() => {
        if (ContractSettingData && ContractSettingData.length > 0 && showContractSetting) {
            const fields: string[] = [
                "allow_expense_posting_on_pending_and_paused_contracts",
                "rental_period_with_fixed_month",
                "proportional_value_of_the_1st_rent_based_on_the_days_of_the_month_in_which_the_contract_starts",
                "do_not_readjust_negative_indexes",
                "disp_contract_data_invoice",
                "use_contract_occupancy_date_to_calculate_1st_prorated_rent",
                "contract_expense_termination",
                "contract_status_active",
                "display_rental_period",
                "contrato_ir_auto",
                "contrato_dimob_auto",
                "ir_desconto_simplificado_loc",
                "ir_deduzirtxadm",
                "ir_deduzirdescounto",
                "contract_termination_extracharge"

            ];
            fields.forEach((field: any) =>
                setValue(field, String(ContractSettingData[0][field]) == "0" ? false : true)
            );
            const fields1: string[] = [
                "bank_fee_amount",
                "standard_credit",
                "fine_calculation"
            ];
            fields1.forEach((fields: any) =>
                setValue(fields, String(ContractSettingData[0][fields]))
            );
            // Check if there's a bank fee amount and set checkbox accordingly
            if (ContractSettingData[0].bank_fee_amount) {
                setValue("bank_fee_checkbox", true); // Set checkbox checked if amount exists
                setValue("bank_fee_amount", ContractSettingData[0].bank_fee_amount);
            } else {
                setValue("bank_fee_checkbox", false); // Uncheck if no amount
            }
            serviceData.forEach((item) => {
                if (item.id == Number(ContractSettingData[0].standard_service)) {
                    setValue("standard_service", item.name)
                }
            })
        }
    }, [ContractSettingData, showContractSetting])
    useEffect(() => {
        setSearchResults(serviceData);
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [serviceData]);

    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };

    const HandleBankAccount = (e: any, item) => {
        setValue('standard_service', item?.name)
        setIsInputFocused(false);
    }
    const handlefilterBillmethod = async (e) => {
        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = serviceData.filter((servicename) => {
                // Convert the service name to lowercase for case-insensitive comparison
                const serviceName = servicename.name.toLowerCase();
                return serviceName.includes(lowerSearchTerm);
            });
            setSearchResults(results)
        } else {
            setSearchResults(serviceData);
        }
    }


    // Enable or disable bank_fee_amount input based on checkbox state
    useEffect(() => {
        if (!isBankFeeChecked) {
            setValue("bank_fee_amount", ""); // Reset the bank fee amount if unchecked
        }
    }, [isBankFeeChecked, setValue]);



    // Tax refunds modal close open function
    const [showTaxRefundsModal, setTaxRefundsModal] = useState(false);
    const taxRefundsModalClose = () => {
        setTaxRefundsModal(false);
        contractSettingShow(true);
    }
    const taxRefundsModalShow = () => {
        setTaxRefundsModal(true);
        contractSettingClose(false);
    }
    // Change iss modal close open function
    const [showChangeRateModal, setChangeRateModal] = useState(false);
    const changeRateModalClose = () => {
        setChangeRateModal(false);
        contractSettingShow(true);
    }
    const changeRateModalShow = () => {
        setChangeRateModal(true);
        contractSettingClose(false);
    }
    //Set up invitations modal close open function
    const [showSetInvitationsModal, setSetInvitationsModal] = useState(false);
    const setInvitationsModalClose = () => {
        setSetInvitationsModal(false);
        contractSettingShow(true);
    }
    const setInvitationsModalShow = () => {
        setSetInvitationsModal(true);
        contractSettingClose(false);
    }
    //Change tracks modal close open function
    const [showChangeTracksModal, setChangeTracksModal] = useState(false);
    const changeTracksModalClose = () => {
        setChangeTracksModal(false);
        contractSettingShow(true);
    }
    const changeTracksModalShow = () => {
        setChangeTracksModal(true);
        contractSettingClose(false);
    }

    const formSubmit = async (data: any) => {
        let serviceId = '';
        serviceData.forEach((curElem: any, index: number) => {
            if (curElem.name == data.standard_service) {
                serviceId = curElem.id
            }
        })
        data["standard_service"] = serviceId

        if (data["standard_credit"] == "") {
            data["standard_credit"] = "3"
        }

        if (data["fine_calculation"] == "") {
            data["fine_calculation"] = "meses"
        }

        let response = await dispatch(InsertContractSettings(data));
        if (response.payload.resCode == 202) {
            // Show a success message using Swal
            Swal.fire({
                position: "center",
                // icon: 'success','success',
                title: `${t("Sweetalert.Success")}`,
                text: `${t("Sweetalert.RecordUpdated")}`,
                showConfirmButton: false,
                timer: 2000,
            });
            contractSettingClose(false);
            dispatch(getContractSetting())
        } else {
            Swal.fire({
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.somethingWent")}`,
                // icon: 'success',"error",
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
                timer: 2000,
            });
        }
    }

    const handlePrice = (e: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, "");
        setValue("bank_fee_amount", formatNumber(value));
    };


    return (
        <>
            {/* Contract settings modal start from here */}
            <Offcanvas show={showContractSetting} onHide={contractSettingClose} placement='end' className="border-start-0">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(formSubmit)}>
                    <Offcanvas.Header closeButton className="border-bottom">
                        <h4 className='fw-bold m-0'>{t("contractsettings.Configuration")}</h4>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <span className="d-block mb-3 fw-bold text-dark">{t("contracts")}</span>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-1" {...register("allow_expense_posting_on_pending_and_paused_contracts")} label={t("contractsettings.ExpensePausedContracts")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-2" {...register("contract_expense_termination")} label={t("contractsettings.ExpenseTerminatedContracts")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-3" {...register("contract_status_active")} label={t("contractsettings.ContractsActive")} />
                        <div className='d-sm-flex justify-content-between align-items-center'>
                            <Form.Check
                                type="checkbox"
                                className="mb-sm-0 mb-3"
                                id="cb-4"
                                label={t("contractsettings.ChargeBankFee")}
                                {...register("bank_fee_checkbox")}
                            />
                            <FloatingLabel controlId="bank_fee_amount" label={t('contractsettings.ChargeBankFee')}>
                                <Form.Control
                                    type="text"
                                    disabled={!isBankFeeChecked} // Disable when unchecked
                                    placeholder={t('contractsettings.ChargeBankFee')}
                                    {...register("bank_fee_amount")}
                                    onChange={handlePrice} // Add the onChange handler here
                                />
                            </FloatingLabel>
                        </div>
                        <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('newTransfer.charges')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-5" {...register('rental_period_with_fixed_month')} label={t("contractsettings.RentalPeriodClosedMonth")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-6" {...register("disp_contract_data_invoice")} label={t("contractsettings.DoNotDisplayContractData")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-7" {...register("display_rental_period")} label={t("contractsettings.DoNotDisplayRentalPeriod")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-8" {...register("contract_termination_extracharge")} label={t("contractsettings.AllowExtraCharge")} />
                        <Form.Check type="checkbox" id="cb-9" {...register("use_contract_occupancy_date_to_calculate_1st_prorated_rent")} label={t("contractsettings.CalculateFirstProportional")} />
                        <span className='d-block fs-12 text-secondary fw-normal mt-1'>{t("contractsettings.UncheckingThisOptio")}</span>
                        <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('contractsettings.Adjustments')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-10" {...register("do_not_readjust_negative_indexes")} label={t("contractsettings.DoNotReadjustNegative")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>

                        <Form.Check type="checkbox" id="cb-11" label={t("contractsettings.AdjustRentConsidering")} />
                        <span className='d-block fs-12 text-secondary fw-normal mt-1'>{t("contractsettings.UncheckingThisOptio")}</span>
                        <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('contractsettings.IncomeTaxWithheld')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-12" {...register("contrato_ir_auto")} label={t("contractsettings.automaticallymarkcontractforIR")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-13" {...register("contrato_dimob_auto")} label={t("contractsettings.MarkContractForDIMOB")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-14" {...register("ir_desconto_simplificado_loc")} label={t("contractsettings.SimplifiedDiscount")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-15" {...register("ir_deduzirdescounto")} label={t("contractsettings.DeductPunctualityDiscount")} />
                        <Form.Check type="checkbox" id="cb-16" {...register("ir_deduzirtxadm")} label={t("contractsettings.DeductAdministrationFee")} />
                        <span className='d-block fs-12 text-secondary fw-normal mt-1'>{t("contractsettings.SelectingThisOption")}</span>
                        <hr className="my-lg-4 my-3" />

                        <span className="d-block mb-3 fw-bold text-dark">{t('Terminationfine')}</span>
                        <FloatingLabel controlId="standard_credit" label={t('contractsettings.Standardcredit')} className="mb-lg-4 mb-3">
                            <Form.Select aria-label={t('contractsettings.Standardcredit')}  {...register("standard_credit")}>
                                <option value="3">{t("realState")}</option>
                                <option value="1">{t("Dashboard.Owner")}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <InputGroup className="mb-lg-4 mb-3">
                            {/* <FloatingLabel controlId="standard_service" label={t('contractsettings.Standardservice')} >
                                <Form.Control type="text" className='border-end-0' placeholder={t('contractsettings.Standardservice')} {...register("standard_service")} />
                            </FloatingLabel> */}
                            {/* <Form.Select className={`form-control ${errors.service ? 'is-invalid' : ''}`} {...register("standard_service")} >
                                <option value="">{t('dropdownItem.select')}..</option>
                                {serviceData && serviceData.length > 0 &&
                                    serviceData.map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Form.Select> */}
                            <Form.Floating className="mb-4" ref={inputRef} >
                                <Form.Control
                                    type="text"
                                    placeholder="Type to search..."
                                    {...register('standard_service')}
                                    onChange={(e) => handlefilterBillmethod(e)}
                                    onFocus={handleInputFocus}
                                    className={"form-control"} id="standard_service"
                                    autoComplete="off"
                                />
                                {isInputFocused && (
                                    <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}>
                                        {searchResults?.slice(0, 3).map((item: any, index) => (
                                            <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleBankAccount(e, item)}>
                                                {item.name}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>

                                )}
                                <Form.Label htmlFor="floatingInputValue">{t('contractsettings.Standardservice')}</Form.Label >
                            </Form.Floating>
                            <InputGroup.Text className='border-start-0'>
                                <img src="./assets/images/icons/search.svg" alt="search icon" className="h-16 cursor-pe" /></InputGroup.Text>
                        </InputGroup>
                        <FloatingLabel controlId="fine_calculation" label={t('contractsettings.Finecalculation')}>
                            <Form.Select aria-label={t('contractsettings.Finecalculation')}  {...register("fine_calculation")}>
                                <option value="meses">{t("inMonths")}</option>
                                <option value="dias">{t("inDays")}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={contractSettingClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t('button.toSave')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
            {/* /Contract settings modal end */}
            {/* Tax refunds modal start from here */}
            <Modal show={showTaxRefundsModal} onHide={taxRefundsModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('contractsettings.SettingupTaxRefundsforInvoices')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="minimum_refund" label={t('contractsettings.Minimumrefundamount')} className="mb-lg-4 mb-3">
                            <Form.Control type="text" placeholder={t('contractsettings.Minimumrefundamount')} disabled />
                        </FloatingLabel>
                        <FloatingLabel controlId="rate_calculation" label={t('contractsettings.Rateusedforcalculation')} className="mb-lg-4 mb-3">
                            <Form.Control type="text" placeholder={t('contractsettings.Rateusedforcalculation')} value="0,00" disabled />
                        </FloatingLabel>
                        <FloatingLabel controlId="service_issue" label={t('contractsettings.Serviceusedtoissuetherefund')} className="mb-lg-4 mb-3">
                            <Form.Control type="text" placeholder={t('contractsettings.Serviceusedtoissuetherefund')} disabled />
                        </FloatingLabel>
                        <span className="d-block mb-3 fw-bold">{t('contractsettings.Servicesusedforbasecalculation')}</span>
                        <FloatingLabel controlId="service" label={t('contractsettings.Service')}>
                            <Form.Control type="text" placeholder={t('contractsettings.Service')} disabled />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={taxRefundsModalClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" onClick={taxRefundsModalClose}>
                            {t('button.toSave')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Tax refunds modal end */}
            {/* Change iss modal start from here */}
            <Modal show={showChangeRateModal} onHide={changeRateModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('contractsettings.ConfigureISSReimbursementRates')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span className="d-block mb-3 fw-bold">{t('contractsettings.ALIQUOTS')}</span>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="branch" label={t("branch.branches")}>
                                    <Form.Select aria-label={t("branch.branches")}>
                                        <option value="1">Headquarters</option>
                                        <option value="2">Branch Training Base</option>
                                        <option value="3">Test</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="rate" label={`(%) ${t('contractsettings.Rate')}`}>
                                    <Form.Control type="text" placeholder={`(%) ${t('contractsettings.Rate')}`} value="0,00" />
                                </FloatingLabel>
                            </Col>
                            <Col md="auto">
                                <Button variant="link" className="bg-light border-light"><img src="../assets/images/icons/delete-light.svg" alt="Delete icon" className="h-16 cursor-pe" /></Button>
                            </Col>
                        </Row>
                        <span className="d-block fw-bold text-primary">{t("branch.another")}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeRateModalClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" onClick={changeRateModalClose}>
                            {t('contractsettings.Change')} {t('contractsettings.Rates')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change iss modal end */}
            {/* Set up invitations modal start from here */}
            <Modal show={showSetInvitationsModal} onHide={setInvitationsModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('contractsettings.Set up invitations')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="select_invitation" label={t('contractsettings.Select an invitation')}>
                                    <Form.Select aria-label={t('contractsettings.Select an invitation')}>
                                        <option value="1">Invitation to owner's area</option>
                                        <option value="2">Invitation to renter's area</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="identifier" label={t("identifier")}>
                                    <Form.Control type="text" placeholder={t("identifier")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <FloatingLabel controlId="title" label={t("formLabel.title")}>
                                        <Form.Control type="text" placeholder={t("formLabel.title")} value="Convite para área do proprietário" />
                                    </FloatingLabel>
                                    <InputGroup.Text className='border-start-0'>.00</InputGroup.Text>

                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="variables" label={t("formLabel.variables")}>
                                    <Form.Select aria-label={t("formLabel.variables")}>
                                        <option value="">{t("formLabel.select")}</option>
                                        <option value="1">hoje</option>
                                        <option value="2">dia_atual_extenso</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {/* <CKEditor editor={ClassicEditor} data="Testing" /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={setInvitationsModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={setInvitationsModalClose}>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Set up invitations modal end */}
            {/* Change tracks modal start from here */}
            <Modal show={showChangeTracksModal} onHide={changeTracksModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('contractsettings.MonthlyIRRFTable-Calculationbasis')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md>
                                <FloatingLabel controlId="unit" label={t("until")}>
                                    <Form.Control type="text" placeholder={t("until")} value="1.903,98" />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="aliquot" label={`${t('contractsettings.Aliquot')} (%)`}>
                                    <Form.Control type="text" placeholder={`${t('contractsettings.Aliquot')} (%)`} value="0,00" />
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="installment_deducted" label={`${t('contractsettings.Installmenttobededucted')} (R$)}`}>
                                    <Form.Control type="text" placeholder={`${t('contractsettings.Installmenttobededucted')} (R$)}`} value="0,00" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md>
                                <FloatingLabel controlId="in" label={t("in")}>
                                    <Form.Control type="text" placeholder={t("in")} value="1.903,98" />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="unit" label={t("until")}>
                                    <Form.Control type="text" placeholder={t("until")} value="2.826,65" />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="aliquot" label={`${t('contractsettings.Aliquot')} (%)`}>
                                    <Form.Control type="text" placeholder={`${t('contractsettings.Aliquot')} (%)`} value="7,50" />
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="installment_deducted" label={`${t('contractsettings.Installmenttobededucted')} (R$)}`}>
                                    <Form.Control type="text" placeholder={`${t('contractsettings.Installmenttobededucted')} (R$)}`} value="142,80" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Alert variant="warning" className="mb-0">
                            {t('contractsettings.TobetterunderstandhowtheIRRFcalculationisdone')}, <Link to={''}>{t('contractsettings.clickhere')}</Link>.
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeTracksModalClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" onClick={changeTracksModalClose}>
                            {t('button.toSave')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change tracks modal end */}
        </>
    )
}

export default ContractSettings
