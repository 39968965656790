import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FloatingLabel,
  Col,
  Row,
  Form,
  Button,
  Accordion,
  Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AddNewItem,
  deleteCate,
  deleteItem,
  getItem,
  updateItems,
  UpdateSubItems,
} from "../../../../redux/slice/AccountPlanSlice";
import Swal from "sweetalert2";
import Loader from "../Loader";
import { Languages } from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
import { encrypt_Decrypt } from "../../../constants/utils";
import AccountSetting from "./AccountSetting";

interface Item {
  item: string;
  description: string;
  account_category: number;
  childData: SubItem[];
}

interface SubItem {
  item: string;
  description: string;
  childData: SubSubItem[];
}

interface SubSubItem {
  item: string;
  description: string;
}

const AccountsPlan = ({ submenu }) => {
  // Importing the useDispatch hook from Redux and defining the type of the dispatch function.
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [userJson, setUserJson] = useState<any>([]);
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    // if (!userResult || userResult.indexOf(1020) === -1) {
    //     navigate("/dashboard");
    // }
    setUserJson(userResult);
  }, [])
  // Using the useTranslation hook to access the translation functionality.
  const { i18n, t } = useTranslation();
  const Schema = yup.object().shape({
    item: yup.string().required(t("requiredMmessage.fieldRequired")),
    description: yup.string().required(t("requiredMmessage.fieldRequired")),
    // account_category: yup
    //   .string()
    //   .required(t("requiredMmessage.fieldRequired")),
  });
  // Using the useSelector hook to access the initialItemState from the Redux store.
  const initialItemState = useSelector(
    (state: RootState) => state.accountplane.accountPlane
  );
  const getCategory = useSelector(
    (state: RootState) => state.accountplane.account_cateGory
  );
  const { status, filter } = useSelector((state: RootState) => state.accountplane);
  const [items, setItems] = useState<any[]>([...initialItemState]);
  const [ind, setInd] = useState<number>();
  // Using the useState hook to initialize the ind state as a number.
  const [show, setShow] = useState<boolean>(false);
  const [spamTag, setSpamTag] = useState<string[]>([]);
  const [defaultopenACRD, setdefaultopenACRD] = useState<any>(0);
  const newInputRef = useRef<any>(null);
  // Using the useForm hook from the react-hook-form library to handle form inputs and validations.
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<Item>({ resolver: yupResolver(Schema) });

  useEffect(() => {
    // Dispatch an action to get the items
    dispatch(getItem());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    // Filter the initialItemState based on the selected filter
    const updatedata = initialItemState.filter((item) => {
      return item.category == filter
    })
    // Set the filtered items to the state variable 'items'
    setItems(updatedata)
    // Filter the initialItemState array based on the selected category value
    // Get the last object from the updatedata array
    const lastChildItem = updatedata[0]?.childData[updatedata[0]?.childData.length - 1];
    if (lastChildItem !== undefined) {
      let parts = lastChildItem?.item?.split('.');
      let newFractionalPart = (parseInt(parts[1]) + 1).toString();
      let newValue = parts[0] + '.' + newFractionalPart;
      setValue("item", newValue);
    } else {
      setValue("item", filter + ".1");
    }
  }, [filter, initialItemState])

  const handleItemChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // Create a copy of the items array
    const newItems = [...items];
    // Update the item at the specified index with the new value
    newItems[index] = {
      ...newItems[index],
      item: event.target.value,
    };
    // Set the updated items array
    setItems(newItems);
  };

  const handleDescriptionChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // Create a copy of the items array
    const newItems = [...items];
    // Update the description of the item at the specified index with the new value
    newItems[index] = {
      ...newItems[index],
      description: event.target.value,
    };
    // Set the updated items array
    setItems(newItems);
  };

  // This function handles the change event for the item of a sub-item within an item in the `items` array.
  const handleSubItemChange = (
    itemIndex: number,
    subItemIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // Create a copy of the items array
    const newItems = [...items];
    // Map over the subItems and update the item value for the subItem at the specified subItemIndex

    if (newItems[0] && newItems[0].childData && newItems[0].childData[itemIndex]) {
      newItems[0].childData[itemIndex] = {
        ...newItems[0].childData[itemIndex],
        childData: (newItems[0].childData[itemIndex].childData || []).map((subItem, index) =>
          index === subItemIndex
            ? { ...subItem, item: event.target.value }
            : subItem
        ),
      };

      // Set the updated items array
      setItems([...newItems]);
    }

  };

  // This function handles the change event for the description of a sub-item within an item in the `items` array.
  const handleSubDescriptionChange = (
    itemIndex: number,
    subItemIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newItems = [...items];

    if (newItems[0] && newItems[0].childData && newItems[0].childData[itemIndex]) {
      newItems[0] = {
        ...newItems[0],
        childData: newItems[0].childData.map((item, index) =>
          index === itemIndex
            ? {
              ...item,
              childData: (item.childData || []).map((subItem, subIndex) =>
                subIndex === subItemIndex
                  ? { ...subItem, description: event.target.value }
                  : subItem
              ),
            }
            : item
        ),
      };

      setItems([...newItems]);
    }

  }

  // This function handles the change event for the item of a sub-sub-item within an item in the `items` array.
  const handleSubSubItemChange = (
    itemIndex: number,
    subItemIndex: number,
    subSubItemIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // Create a copy of the items array
    const newItems = [...items];
    // Update the specific item at itemIndex
    newItems[0].childData[itemIndex] = {
      ...newItems[0].childData[itemIndex],
      childData: newItems[0].childData[itemIndex].childData.map((subItem, index) => {
        if (index === subItemIndex) {
          return {
            ...subItem,
            childData: subItem.childData.map((subSubItem, subIndex) =>
              subIndex === subSubItemIndex
                ? { ...subSubItem, item: event.target.value }
                : subSubItem
            ),
          };
        }
        return subItem;
      }),
    };
    // Set the updated items array
    setItems(newItems);
  };

  // This function handles the change event for the description of a sub-sub-item within an item in the `items` array.
  const handleSubSubDescriptionChange = (
    itemIndex: number,
    subItemIndex: number,
    subSubItemIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // Create a deep copy of the items array
    const newItems = JSON.parse(JSON.stringify(items));

    newItems[0].childData[itemIndex] = {
      ...newItems[0].childData[itemIndex],
      childData: newItems[0].childData[itemIndex].childData.map((subItem, index) => {
        if (index === subItemIndex) {
          return {
            ...subItem,
            childData: subItem.childData.map((subSubItem, subIndex) =>
              subIndex === subSubItemIndex
                ? { ...subSubItem, description: event.target.value }
                : subSubItem
            ),
          };
        } else {
          return subItem;
        }
      }),
    };

    // Set the updated items array
    setItems(newItems);
  };

  // This code defines a function called handleAddItem. When called, it sets the value of "show"
  const handleAddItem = () => {
    setShow(true);
  };

  const handleAddSubItem = (e: any, cmd: string, itemIndex: number) => {
    // Check if items[0] and items[0].childData are defined
    if (!items[0] || !items[0]?.childData) {
      //console.error("items[0] or items[0].childData is undefined");
      return;
    }

    // Retrieve the current parent item using the provided itemIndex.
    // const currentItem = items[0].childData[itemIndex];
    // Retrieve the current parent item using the provided itemIndex.
    const currentItem = items[0]?.childData[itemIndex];
    // Access the 'childData' property of the parent item, which is an array of subitems.
    const subItems = currentItem?.childData;
    // Check if any of the subitems have empty values for the 'item' or 'description' properties.

    const isAnySubItemEmpty = subItems.some(
      (subItem) => subItem.item === "" || subItem.description === ""
    );
    if (isAnySubItemEmpty) {
      return;
    }
    // if (newInputRef.current) {
    //  newInputRef.current.focus()
    // }
    const newItems = [...items];
    let newValue = "";
    if (subItems.length > 0) {
      newItems[0].childData.forEach((item) => {

        let subitems = item.childData;
        let lastIndex = subitems.length - 1;
        let autofillvalue = subitems[lastIndex];
        if (autofillvalue && autofillvalue.item) {
          let currentItems = autofillvalue.item;
          let dotIndexs = currentItems.lastIndexOf(".");
          let incrementedItems =
            currentItems.substring(0, dotIndexs + 1) +
            (parseInt(currentItems.substring(dotIndexs + 1)) + 1);
          newValue = incrementedItems;
        }
      });
    } else {
      let currentItems = currentItem.item;
      let updatedItem = currentItems + ".1";
      newValue = updatedItem;
    }
    const updatedItem = {
      ...newItems[0].childData[itemIndex],
      childData: [
        ...newItems[0].childData[itemIndex].childData,
        {
          item: newValue,
          description: "",
          childData: [],
        },
      ],
    };

    const updatedChildData = [...newItems[0].childData];
    updatedChildData[itemIndex] = updatedItem;

    const updatedItems = [...newItems];
    updatedItems[0] = {
      ...newItems[0],
      childData: updatedChildData,
    };

    setItems(updatedItems);
    let thisDivform: any = e.target.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement.parentElement;
    // Set a timeout and perform actions based on the 'cmd' parameter.
    setTimeout(() => {
      if (cmd == "edit") {
        // Perform actions specific to the "edit" command.
        // Check for elements and modify their attributes accordingly.
        if (
          thisDivform.querySelectorAll(".subitem .account_plan_edit")) {
          let newlength = thisDivform.querySelectorAll(".subitem .account_plan_edit");
          thisDivform.querySelectorAll(".subitem .account_plan_edit").forEach((item, index) => {
            if (newlength.length - 1 == index) {
              item.querySelectorAll("input").forEach((inputItem) => {
                inputItem.removeAttribute("disabled");
                inputItem.focus(); // Add this line to set focus on the input element
              });
            }
          });
        }
        if (
          thisButton.querySelectorAll(".BtnAction.Update, .BtnAction.Edit ")
            .length > 0
        ) {
          // Remove hidden attribute from Update button and hide Edit button.
          // Remove hidden attribute from "others" button.
          thisButton
            .querySelector(".BtnAction.Update")
            ?.removeAttribute("hidden");
          thisButton
            .querySelector(".BtnAction.Edit")
            ?.setAttribute("hidden", "");
        }
        thisButton
          .querySelector(".BtnAction.others")
          ?.removeAttribute("hidden");
      }
    }, 50);
  };

  const handleAddSubSubItem = (
    e: any,
    cmd: string,
    itemIndex: number,
    subItemIndex: number
  ) => {
    let newthisdiv: any = e.target.parentElement.parentElement.parentElement;
    // Set a timeout and perform actions based on the 'cmd' parameter.
    setTimeout(() => {
      if (cmd == "edit") {
        if (
          newthisdiv.querySelectorAll(".subitem .sub_account_plan_edit")
            .length > 0
        ) {
          let newlength = newthisdiv.querySelectorAll(".subitem .sub_account_plan_edit");
          newthisdiv.querySelectorAll(".subitem .sub_account_plan_edit")
            .forEach((item, index) => {
              if (newlength.length - 1 == index) {
                item.querySelectorAll("input").forEach((item) => {
                  item.removeAttribute("disabled");
                  item.focus();
                });
              }
            });
        }
      }
    }, 50);

    const currentItem = items[0].childData[itemIndex];
    const subItems = currentItem.childData;
    const currentSubItem = subItems[subItemIndex];
    const subSubItems = currentSubItem.childData;

    // Check if any existing sub-subitem is empty
    const isAnySubSubItemEmpty = subSubItems.some(
      (subSubItem) => subSubItem.item === "" || subSubItem.description === ""
    );
    // if (newInputRef.current) {
    //   newInputRef.current.focus();
    // }
    // If any sub-subitem is empty, don't add a new sub-subitem
    if (isAnySubSubItemEmpty) {
      return;
    }
    // Create a deep copy of the items array
    let newItems = items.map(item => ({ ...item, childData: [...item.childData] }));
    let newItem = { ...newItems[0].childData[itemIndex] };
    let newSubItems = [...newItem.childData];
    let newSubItem = { ...newSubItems[subItemIndex] };
    let newSubSubItems = [...newSubItem.childData];

    let newval = "";
    if (newSubSubItems.length > 0) {
      newSubItems.forEach((item) => {
        let newarr = item.childData;
        let lastIndex = newarr.length - 1;
        let autofillvalue = newarr[lastIndex];

        if (autofillvalue && autofillvalue.item) {
          let currentItems = autofillvalue.item;
          let dotIndexs = currentItems.lastIndexOf(".");
          let incrementedItems =
            currentItems.substring(0, dotIndexs + 1) +
            (parseInt(currentItems.substring(dotIndexs + 1)) + 1);
          newval = incrementedItems;
        }
      });
    } else {
      let currentItems = currentSubItem.item;
      let updatedItem = currentItems + ".1";
      newval = updatedItem;
    }

    newSubSubItems.push({ item: newval, description: "", content: "new" });
    newSubItem.childData = newSubSubItems;
    newSubItems[subItemIndex] = newSubItem;
    newItem.childData = newSubItems;
    newItems[0].childData[itemIndex] = newItem;

    setItems(newItems);

  };

  // This function handles the deletion of an item.
  const handleDeleteItem = async (cmd: string, item: string, index: number) => {
    if (cmd === "itemDelete") {
      Swal.fire({
        title: t("Sweetalert.Areyousure"),
        text: t("Sweetalert.Youwontbeabletorevert"),
        // icon: 'success',"question",
        showCancelButton: true,
        confirmButtonText: `${t("Sweetalert.changeIt")}`,
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          const newItems = [...items];
          newItems.splice(index, 1);
          setItems(newItems);
          let obj = {
            ST_CONTA_CONT: item,
          };
          let res = await dispatch(deleteItem(obj));
          if (res.payload.type == "Success") {
            // Show a success message using Swal
            Swal.fire({
              // icon: 'success',"success",
              title: t("Sweetalert.Delete"),
              text: t("Sweetalert.deletesuccess"),
              showConfirmButton: false,
              timer: 2000,
            });
            dispatch(getItem());
          } else {
            Swal.fire({
              // icon: 'success',"error",
              title: t("Sweetalert.Error"),
              text: t("Sweetalert.somethingWent"),
            });
          }
        }
      });
    }
  };

  // This function handles the deletion of an sub item.
  const handleDeleteSubItem = async (
    cmd: string,
    subItem: any,
    subItemIndex: number,
    itemIndex: number
  ) => {
    if (cmd == "childdelete") {
      Swal.fire({
        title: t("Sweetalert.Areyousure"),
        text: t("Sweetalert.Youwontbeabletorevert"),
        // icon: 'success',"question",
        showCancelButton: true,
        confirmButtonText: `${t("Sweetalert.changeIt")}`,
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          let count = 0
          const newItems = items.map((item) => {
            if (item.childData && item.childData.length > 0) {
              const childDataArray = item.childData;
              const newChildData = childDataArray.map((childItem) => {
                if (childItem.childData && childItem.childData.length > 0) {
                  const grandChildData = childItem.childData.filter((grandChildItem) => grandChildItem.item !== subItem.item);
                  return { ...childItem, childData: grandChildData };
                }
                return childItem;
              });
              count++
              return { ...item, childData: newChildData };
            }
            return item;
          });
          setItems(newItems);

          // Perform any additional operations you need, such as dispatching the delete action
          let obj = {
            ST_CONTA_CONT: subItem.item,
          };

          let res = await dispatch(deleteItem(obj));
          // Show a success message using Swal
          if (res.payload.type == "Success" || res.payload.type == "success") {

            dispatch(getItem());
            Swal.fire({
              // icon: 'success',"success",
              title: t("Sweetalert.Delete"),
              text: t("Sweetalert.deletesuccess"),
              showConfirmButton: false,
              timer: 2000,
            })
          } else if (count == 1) {
            Swal.fire({
              // icon: 'success',"success",
              title: t("Sweetalert.Delete"),
              text: t("Sweetalert.deletesuccess"),
              showConfirmButton: false,
              timer: 2000,
            })

          } else {
            Swal.fire({
              // icon: 'success',"error",
              title: t("Sweetalert.Error"),
              text: t("Sweetalert.somethingWent"),
            });
          }
        }

      });
    }
  };

  const handleDeleteSubSubItem = async (
    cmd: string,
    itemIndex: number,
    subItemIndex: number,
    subSubItemIndex: number
  ) => {
    // Check if the command is for child data deletion
    let getUnsetData = items?.[subItemIndex]?.["childData"]?.[itemIndex]?.["childData"]?.[subSubItemIndex]?.["childData"]?.[subSubItemIndex]?.["content"];
    if (getUnsetData == "new") {
      let newItems = JSON.parse(JSON.stringify(items))
      // Retrieve the sub-item containing the sub-sub-item to be removed
      let subItem = newItems[0]?.childData[itemIndex].childData[subItemIndex];
      // Retrieve the item to be removed
      let splicedItem = subItem.childData[subSubItemIndex];
      // Make a shallow copy of the childData array within the sub-item
      let newChildData = [...subItem?.childData];
      // Remove the specified sub-sub-item from the newChildData array
      newChildData.splice(subSubItemIndex, 1);
      // Update the sub-item with the modified childData
      subItem = { ...subItem, childData: newChildData };
      // Update the corresponding sub-item in the newItems array
      newItems[0].childData[itemIndex].childData[subItemIndex] = subItem;
      // Update the state with the modified newItems array
      setItems(newItems);
    } else {
      if (cmd === "childdatadelete") {
        // Display a warning confirmation dialog
        Swal.fire({
          title: t("Sweetalert.Areyousure"),
          text: t("Sweetalert.Youwontbeabletorevert"),
          // icon: 'success',"question",
          showCancelButton: true,
          confirmButtonText: `${t("Sweetalert.changeIt")}`,
        }).then(async (result) => {
          // If the user confirms the deletion
          if (result.isConfirmed == true) {
            // Retrieve the item, subItem, and subSubItem to be deleted
            // Make a shallow copy of the items array
            let newItems = JSON.parse(JSON.stringify(items))
            // Retrieve the sub-item containing the sub-sub-item to be removed
            let subItem = newItems[0]?.childData[itemIndex].childData[subItemIndex];
            // Retrieve the item to be removed
            let splicedItem = subItem.childData[subSubItemIndex];
            // Make a shallow copy of the childData array within the sub-item
            let newChildData = [...subItem?.childData];
            // Remove the specified sub-sub-item from the newChildData array
            newChildData.splice(subSubItemIndex, 1);
            // Update the sub-item with the modified childData
            subItem = { ...subItem, childData: newChildData };
            // Update the corresponding sub-item in the newItems array
            newItems[0].childData[itemIndex].childData[subItemIndex] = subItem;
            // Update the state with the modified newItems array
            setItems(newItems);
            // Retrieve the spliced subSubItem
            // let splicedItem = subSubItem?.item;
            // Create an object with the spliced item for deletion in the API
            let obj = {
              ST_CONTA_CONT: splicedItem.item,
            };
            let res = await dispatch(deleteItem(obj));
            if (res.payload.type == "Success" || res.payload.type == "success") {
              dispatch(getItem());
              Swal.fire({
                // icon: 'success',"success",
                title: t("Sweetalert.Delete"),
                text: t("Sweetalert.deletesuccess"),
                showConfirmButton: false,
                timer: 2000,
              })
            } else {
              if (res.payload.resCode == "401") {
                dispatch(getItem());
                Swal.fire({
                  // icon: 'success',"warning",
                  title: t("Sweetalert.Error"),
                  text: t("Sweetalert.alreadyuse"),
                });
              } else {
                Swal.fire({
                  // icon: 'success',"error",
                  title: t("Sweetalert.Error"),
                  text: t("Sweetalert.somethingWent"),
                  showConfirmButton: true,
                  timer: 1000,
                });
              }
            }
          }
        });
      }
    }

  };

  const validateItem = (item: Item): string[] => {
    // Create an array to store spam tags
    const spamTags: string[] = [];
    // Check if item or description is empty
    if (!item.item || !item.description) {
      spamTags.push("Empty Field");
    }
    // Loop through each subItem
    for (const subItem of item.childData) {
      // Check if subItem or description is empty
      if (!subItem.item || !subItem.description) {
        spamTags.push("Empty Field");
      }
      for (const subSubItem of subItem.childData) {
        // Check if subSubItem or description is empty
        if (!subSubItem.item || !subSubItem.description) {
          spamTags.push("Empty Field");
        }
      }
    }
    // Return the spam tags
    return spamTags;
  };

  // State variable to track edit mode
  const [editName, setEditName] = useState(true);
  const handleEdit = (e: any, cmd: string, index: number, id: any) => {
    // Set the default open accordion index
    setdefaultopenACRD(index);
    // Select the element with id 'root' and store it in newdata variable
    let newdata: any = document.querySelector("#root");
    // Set an attribute 'chater_acount_old_id' with the value of id to newdata element
    newdata.setAttribute("chater_acount_old_id", id);
    if (cmd == "edit") {
      // If the command is 'edit'
      e.target.parentElement.setAttribute("hidden", "");
      e.target.parentElement.nextElementSibling.removeAttribute("hidden");
      setInd(index);
      if (
        !e.target.parentElement.parentElement.parentElement
          .querySelector(".accordion-collapse.collapse")
          .classList.contains("show")
      ) {
        // If the parent element of the parent element's parent element doesn't have the 'show' class
        e.target.parentElement.parentElement.parentElement
          .querySelector(".accordion-header button")
          .click();
      }
    } else {
      // If the command is not 'edit'
      e.target.parentElement.previousElementSibling.removeAttribute("hidden");
      e.target.parentElement.setAttribute("hidden", "");
      setInd(-1);
    }
    setEditName(!editName);
  };

  const EditSubItem = (e: any, cmd: string, id: any) => {
    // Get the parent div of the form
    let thisDivform: any =
      e.target.parentElement.parentElement.parentElement.parentElement;
    // Get the parent div of the button
    let newthisdiv: any = e.target.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement.parentElement;
    let newdata: any = document.querySelector("#root");
    // Set a custom attribute 'chater_acount_old_id' to the root element
    newdata.setAttribute("chater_acount_old_id", id);
    if (cmd == "edit") {
      // Check if there are any inputs with class 'account_plan_edit' in the parent form div
      if (
        thisDivform.querySelectorAll(".subitem .account_plan_edit").length > 0
      ) {
        thisDivform
          .querySelectorAll(".subitem .account_plan_edit input")
          .forEach((item: any, index: number) => {
            item.removeAttribute("disabled");
          });
      }
      // Check if there are buttons with class 'subItem.Edit' or 'subItem.update' in the parent button div
      if (
        thisButton.querySelectorAll(".subItem.Edit , .subItem.update").length >
        0
      ) {
        // Show the 'subItem.update' button and hide the 'subItem.Edit' button
        thisButton.querySelector(".subItem.update")?.removeAttribute("hidden");
        thisButton.querySelector(".subItem.Edit")?.setAttribute("hidden", "");
      }
    } else if (cmd == "subItemEdit") {
      newthisdiv.querySelectorAll("input").forEach((item) => {
        item.removeAttribute("disabled");
      });
      if (
        thisButton.querySelectorAll(".subItem.Edit , .subItem.update").length >
        0
      ) {
        thisButton.querySelector(".subItem.update")?.removeAttribute("hidden");
        thisButton.querySelector(".subItem.Edit")?.setAttribute("hidden", "");
      }
    } else if (cmd == "edititem") {
      // Enable all input fields in the new parent div
      newthisdiv.querySelectorAll("input").forEach((item) => {
        item.removeAttribute("disabled");

      });
      // Check if there are buttons with class 'subItem.Edit' or 'subItem.update' in the parent button div
      if (
        thisButton.querySelectorAll(".subItem.Edit, .subItem.update").length > 0
      ) {
        thisButton.querySelector(".subItem.update")?.removeAttribute("hidden");
        thisButton.querySelector(".subItem.Edit")?.setAttribute("hidden", "");
      }
    }
  };

  const handleSaveItem = async (e: any, cmd: string, index: number) => {
    let newthisdiv: any = e.target.parentElement.parentElement.parentElement;
    // Retrieve the item at the specified index
    const newItem = items[index];
    //  Validate the item for any spam tags
    const spamTags = validateItem(newItem);
    let newdata: any = document.querySelector("#root");
    newthisdiv.querySelectorAll("input").forEach((item) => {
      // console.log(item);
      item.setAttribute("disabled", "");
    });
    if (spamTags.length === 0) {
      // Prepare the object to send for updating the item
      let objsend: any = {
        updateArray: [
          {
            OLD_ST_CONTA_CONT: newdata.getAttribute("chater_acount_old_id"),
            ST_CONTA_CONT: newItem.item,
            ST_DESCRICAO_CONT: newItem.description,
          },
        ],
      };
      let res = await dispatch(updateItems(objsend));
      if (res.payload.type == "Success") {
        // Show a success message if the update was successful
        Swal.fire({
          position: "center",
          // icon: 'success',"success",
          title: t("Sweetalert.Update"),
          text: t("Sweetalert.updatesuccess"),
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: "center",
          // icon: 'success',"error",
          title: t("Sweetalert.Error"),
          text: t("Sweetalert.somethingWent"),
          showConfirmButton: true,
        });
      }

      setSpamTag([]);
    } else {
      // Item is not valid, show the spam tags
      setSpamTag(spamTags);
    }
  };

  const SaveSubItem = async (itemIndex: number, subItemIndex: number) => {
    // Make a copy of the 'items' array
    const newItems = [...items];
    // Retrieve the parent item at the specified index
    const newItem = { ...newItems[0].childData[itemIndex] };
    const updateitem = newItem.childData[subItemIndex];

    const spamTags = validateItem(newItem);
    if (spamTags.length === 0) {
      // Create an object with the updated item details
      let obj = {
        ST_CONTA_CONT: updateitem.item,
        ST_DESCRICAO_CONT: updateitem.description,
        ID_CONTA_CATEGORIA: newItem.category,
      };
      let res = await dispatch(AddNewItem(obj));

      if (res.payload.type == "Success") {
        // Show a success message to the user
        dispatch(getItem());
        Swal.fire({
          position: "center",
          // icon: 'success',"success",
          title: t("Sweetalert.Success"),
          text: t("Sweetalert.successget"),
        });
      }
    } else {
      // Log a message if the data cannot be saved due to spam tags
    }
  };
  // This function handles the blur event of an input element with the provided 'id'.
  const handleBlureInput = (id: any) => {
    // Find the element with the id 'root' and store it in the 'newdata' variable.
    let newdata: any = document.querySelector("#root");
    // Set an attribute named 'chater_acount_old_id' on the 'newdata' element with the value of the provided 'id'.
    newdata.setAttribute("chater_acount_old_id", id);
  };

  const UpdateSubItem = async (
    e: any,
    itemIndex: number,
    subItemIndex: number,
    itemData: string
  ) => {
    let newdata: any = document.querySelector("#root");
    let thisDivform: any =
      e.target.parentElement.parentElement.parentElement.parentElement;
    let updateobj: any = {};
    // Check if the itemData is "sbitem"
    if (itemData == "sbitem") {
      const newItems = [...items];
      const newItem = { ...newItems[0].childData[itemIndex] };
      const updateitem = newItem.childData[subItemIndex];
      // Prepare the update object
      let obj = {
        OLD_ST_CONTA_CONT: newdata.getAttribute("chater_acount_old_id"),
        ST_CONTA_CONT: updateitem.item,
        ST_DESCRICAO_CONT: updateitem.description,
      };
      updateobj = obj;
      // Disable input fields if found in the form
      if (thisDivform.querySelectorAll(".account_plan_edit").length) {
        thisDivform
          .querySelectorAll(".account_plan_edit input")
          .forEach((item: any, index: number) => {
            item.setAttribute("disabled", "");
          });
      }
    }
    let res = await dispatch(UpdateSubItems(updateobj));
    if (res.payload.type == "Success") {
      // Show a success message using Swal
      Swal.fire({
        position: "center",
        // icon: 'success',"success",
        title: t("Sweetalert.Update"),
        text: t("Sweetalert.updatesuccess"),
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      // Show error message when item addition fails
      Swal.fire({
        position: "center",
        // icon: 'success',"error",
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        showConfirmButton: true,
      });
    }
  };

  const UpdateSubSubItem = async (
    e: any,
    itemIndex: number,
    subItemIndex: number,
    subSubItemIndex: number
  ) => {
    let newdata: any = document.querySelector("#root");
    let newthisdiv: any = e.target.parentElement.parentElement.parentElement;
    // Create a copy of the items array
    const newItems = [...items];
    const newItem = { ...newItems[0].childData[itemIndex] };
    const updateitem = newItem.childData[subItemIndex];
    // Get the sub-subitem to be updated
    const updateChildItem = updateitem.childData[subSubItemIndex];
    // Validate the item for any spam tags
    const spamTags = validateItem(newItem);
    // Disable all input fields within the parent div
    newthisdiv.querySelectorAll("input").forEach((item) => {
      item.setAttribute("disabled", "");
    });
    // If there are no spam tags
    if (spamTags.length === 0) {
      // Prepare the object for updating sub-items
      let obj = {
        OLD_ST_CONTA_CONT: newdata.getAttribute("chater_acount_old_id"),
        ST_CONTA_CONT: updateChildItem.item,
        ST_DESCRICAO_CONT: updateitem.description,
      };
      // Dispatch the action to update sub-items
      let res = await dispatch(UpdateSubItems(obj));
      if (res.payload.type == "Success") {
        // If the update is successful
        Swal.fire({
          position: "center",
          // icon: 'success',"success",
          title: t("Sweetalert.Success"),
          text: t("Sweetalert.successget"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      // Show error message when item addition fails
      Swal.fire({
        position: "center",
        // icon: 'success',"error",
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        showConfirmButton: true,
      });
    }
  };
  // Set the 'show' state to false, hiding the component
  const handleClose = () => {
    setShow(false);
  };

  const SaveSubSubItem = async (
    itemIndex: number,
    subItemIndex: number,
    subSubItemIndex: number
  ) => {
    // Create a new array with a copy of the items
    const newItems = [...items];
    const newItem = { ...newItems[0].childData[itemIndex] };
    // Get the specific subitem at subItemIndex
    const updateitem = newItem.childData[subItemIndex];
    // Get the specific sub-subitem at subSubItemIndex
    const updateChildItem = updateitem.childData[subSubItemIndex];

    // Check for spam tags in the item
    const spamTags = validateItem(newItem);
    if (spamTags.length === 0) {
      // Prepare the object with updated item details
      let obj = {
        ST_CONTA_CONT: updateChildItem.item,
        ST_DESCRICAO_CONT: updateChildItem.description,
        ID_CONTA_CATEGORIA: newItem.category,
      };
      let res = await dispatch(AddNewItem(obj));
      if (res.payload.type == "Success") {
        // Dispatch an action to get the updated items
        dispatch(getItem());
        Swal.fire({
          position: "center",
          // icon: 'success',"success",
          title: t("Sweetalert.Success"),
          text: t("Sweetalert.successget"),
        });
      }
    } else {
      // Show error message when item addition fails
      Swal.fire({
        position: "center",
        // icon: 'success',"error",
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        showConfirmButton: true,
      });
    }
  };

  const onSubmit = async (data: any) => {
    const newItems = [...items]
    // Prepare object with data from the form
    const lastIndex = newItems.length - 1;

    let obj = {
      ST_CONTA_CONT: data.item,
      ST_DESCRICAO_CONT: data.description,
      // ID_CONTA_CATEGORIA: data.account_category,
    };
    // Dispatch API call to add a new item
    let res = await dispatch(AddNewItem(obj));
    if (res.payload.type == "success" || res.payload.type == "Success") {
      // Show success message when item is created
      Swal.fire({
        position: "center",
        // icon: 'success',"success",
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
      }).then((result) => {
        // Dispatch action to update item list

        if (result.isConfirmed) {
          // Reset the form fields and close the modal
          reset();
          setShow(false);
          dispatch(getItem());
        }
      });
    } else {
      // Show error message when item addition fails
      Swal.fire({
        position: "center",
        // icon: 'success',"error",
        title: t("Sweetalert.Addothernumber"),
        text: t("Sweetalert.AccountNoNeedtobeunique"),
      });
    }
  };

  // const SelectedCate = (e: any) => {
  //   // Get the selected value from the event target
  //   const value = e.target.value;
  //   // Filter the initialItemState array based on the selected category value
  //   const updatedata = initialItemState.filter((item) => {
  //     return item?.category == value;
  //   });
  //   // Get the last object from the updatedata array
  //   const lastChildItem = updatedata[0].childData[updatedata[0].childData.length - 1];
  //   if(lastChildItem !== undefined){
  //     let parts = lastChildItem?.item?.split('.');
  //     let newFractionalPart = (parseInt(parts[1]) + 1).toString();
  //     let newValue = parts[0] + '.' + newFractionalPart;
  //     setValue("item", newValue);
  //   }else{
  //     setValue("item", value + ".1");
  //   }
  // };


  const renderItemInputs = () => {
    return items && items.length > 0 ? (
      items[0].childData.length > 0 ?
        items[0].childData.map((item, index) => {

          const isItemInvalid = !item.item || !item.description;
          const isItemEmpty = item.item === "";
          const isDescriptionEmpty = item.description === "";
          return (
            <>
              <Accordion key={index} className="mb-3" defaultActiveKey={"0"}>
                <Accordion.Item eventKey={`1`} className="position-relative">
                  {/* <div className='BtnAction Edit'>
                                <Button variant="link" className="position-absolute end-0 top-0 mt-1 me-5 fw-bold z-index-5" onClick={(e) => handleEdit(e, "edit", index, item.item)}>{t("button.edit")}</Button>
                            </div> */}
                  {userJson &&
                    userJson.length > 0 &&
                    userJson.indexOf(1020) !== -1 && (
                      <div className="ThisButtonUse">
                        <div className="BtnAction Edit Notlist">
                          <Button
                            variant="link"
                            className="position-absolute end-0 top-0 mt-1 me-5 fw-bold z-index-5"
                            onClick={(e) =>
                              handleEdit(e, "edit", index, item.item)
                            }
                          >
                            {t("button.edit")}
                          </Button>
                        </div>
                        <div className="BtnAction Edit Show" hidden>
                          <Button
                            variant="link"
                            className="position-absolute end-0 top-0 mt-1 me-5 fw-bold z-index-5"
                            onClick={(e) =>
                              handleEdit(e, "show", index, item.item)
                            }
                          >
                            {t("Showlist")}
                          </Button>
                        </div>
                      </div>
                    )}
                  {/* <div className="BtnAction Update" hidden>
                                <Button variant="link" className="position-absolute end-0 top-0 mt-1 me-5 fw-bold z-index-5" onClick={(e) => handleSaveItem(e, "submit", index)} >{t("button.save")} </Button>
                            </div> */}
                  <Accordion.Header>
                    {" "}
                    {item.item + " " + item?.description}{" "}
                  </Accordion.Header>
                  <Accordion.Body className="hide">
                    {ind === index ? (
                      <>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 items">
                          <Col md={2} xxl={1} xs={4}>
                            <FloatingLabel
                              controlId="item"
                              label={t("formLabel.item") + " *"}
                            >
                              <Form.Control
                                type="text"
                                className={
                                  isItemInvalid && isItemEmpty ? "is-invalid" : ""
                                }
                                value={item.item}
                                onChange={(event: any) =>
                                  handleItemChange(index, event)
                                }
                                placeholder="Item"
                                disabled
                                onFocus={(event: any) =>
                                  handleBlureInput(item.item)
                                }
                              />
                              {isItemInvalid && isItemEmpty && (
                                <Form.Control.Feedback className="invalid-feedback">
                                  Item is required
                                </Form.Control.Feedback>
                              )}
                            </FloatingLabel>
                          </Col>
                          <Col md xs={8}>
                            <FloatingLabel
                              controlId="description"
                              label={t("formLabel.description") + " *"}
                            >
                              <Form.Control
                                type="text"
                                className={
                                  isItemInvalid && isDescriptionEmpty
                                    ? "is-invalid"
                                    : ""
                                }
                                value={item.description}
                                onChange={(event: any) =>
                                  handleDescriptionChange(index, event)
                                }
                                placeholder="Description"
                                disabled
                              />
                              {isItemInvalid && isDescriptionEmpty && (
                                <Form.Control.Feedback className="invalid-feedback">
                                  Description is required
                                </Form.Control.Feedback>
                              )}
                            </FloatingLabel>
                          </Col>
                          {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(1020) !== -1 && (
                              <>
                                <Col md="auto" xs="8">
                                  <Button
                                    type="button"
                                    variant="link"
                                    className="bg-light w-100"
                                    onClick={(e) =>
                                      handleAddSubItem(e, "edit", index)
                                    }
                                  >
                                    {t("button.additem")}
                                  </Button>
                                </Col>
                                <Col md="auto" xs="4">
                                  <div className="subItem Edit">
                                    <Button
                                      type="button"
                                      variant="link"
                                      className="bg-light w-100"
                                      onClick={(e) =>
                                        EditSubItem(e, "edititem", item.item)
                                      }
                                    >
                                      {t("button.edit")}
                                    </Button>
                                  </div>
                                  <div className="subItem update" hidden>
                                    <Button
                                      type="button"
                                      variant="link"
                                      className="bg-light w-100"
                                      onClick={(e) =>
                                        handleSaveItem(e, "submit", index)
                                      }
                                    >
                                      {t("button.update")}
                                    </Button>
                                  </div>
                                </Col>
                              </>
                            )}
                          {item.childData.length > 0
                            ? ""
                            : userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(1020) !== -1 && (
                              <Col md="auto" xs="4">
                                <Button
                                  type="button"
                                  variant="link"
                                  className="bg-light w-100"
                                  onClick={() =>
                                    handleDeleteItem(
                                      "itemDelete",
                                      item.item,
                                      index
                                    )
                                  }
                                >
                                  <img
                                    src="./assets/images/icons/delete-danger.svg"
                                    alt="Delete"
                                  />
                                </Button>
                              </Col>
                            )}
                        </Row>
                        {status == "loading" ? (
                          ""
                        ) : (
                          <> {renderSubItemInputs(item.childData, index)} </>
                        )}
                      </>
                    ) : (
                      <div>
                        {item.childData.map((curElem: any, index: number) => (
                          <div key={index}>
                            <ul style={{ listStyle: "none" }}>
                              <li>
                                {curElem.item} {curElem.description}
                              </li>
                            </ul>
                            {curElem.childData.map(
                              (subitem: any, index: number) => (
                                <ul key={index} style={{ listStyle: "none" }}>
                                  <li>
                                    {subitem.item} {subitem.description}
                                  </li>
                                </ul>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          );
        })
        :
        <p>{t("datanotfound")}</p>
    ) : (
      <p>{t("datanotfound")}</p>
    );
  };

  const renderSubItemInputs = (parentItems: SubItem[], itemIndex: number) => {
    return parentItems.map((subItem, subItemIndex) => {
      const isSubItemInvalid = !subItem.item || !subItem.description;
      const isSubItemEmpty = subItem.item === "";
      const isSubDescriptionEmpty = subItem.description === "";
      return (
        <div className="ms-md-3 ms-2 subitem" key={subItemIndex}>
          <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 account_plan_edit">
            <Col md={2} xxl={1} xs={4}>
              <FloatingLabel controlId="item" label={t("formLabel.item") + " *"}>
                <Form.Control
                  type="text"
                  className={
                    isSubItemInvalid && isSubItemEmpty ? "is-invalid" : ""
                  }
                  value={subItem.item}
                  onChange={(event: any) =>
                    handleSubItemChange(itemIndex, subItemIndex, event)
                  }
                  onFocus={(event: any) => handleBlureInput(subItem.item)}
                  placeholder="Sub Item"
                  disabled
                />
                {isSubItemInvalid && isSubItemEmpty && (
                  <Form.Control.Feedback className="invalid-feedback">
                    Item is required
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
            <Col md xs={8}>
              <FloatingLabel
                controlId="description"
                label={t("formLabel.description") + " *"}
              >
                <Form.Control
                  type="text"
                  className={
                    isSubItemInvalid && isSubDescriptionEmpty
                      ? "is-invalid"
                      : ""
                  }
                  value={subItem.description}
                  onChange={(event: any) =>
                    handleSubDescriptionChange(itemIndex, subItemIndex, event)
                  }
                  placeholder="Sub Description"
                  disabled
                />
                {isSubItemInvalid && isSubDescriptionEmpty && (
                  <Form.Control.Feedback className="invalid-feedback">
                    Description is required
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
            {userJson &&
              userJson.length > 0 &&
              userJson.indexOf(1020) !== -1 && (
                <Col md="auto" xs="4">
                  <Button
                    type="button"
                    variant="link"
                    className="bg-light w-100"
                    onClick={(e) =>
                      handleAddSubSubItem(e, "edit", itemIndex, subItemIndex)
                    }
                  >
                    {t("button.addsubaccount")}
                  </Button>
                </Col>
              )}
            {subItem["count"] === 1
              ? userJson &&
              userJson.length > 0 &&
              userJson.indexOf(1020) !== -1 && (
                <Col md="auto" xs="4">
                  <div className="subItem Edit">
                    <Button
                      type="button"
                      variant="link"
                      className="bg-light w-100"
                      onClick={(e) => EditSubItem(e, "edit", subItem.item)}
                    >
                      {t("button.edit")}
                    </Button>
                  </div>
                  <div className="subItem update" hidden>
                    <Button
                      type="button"
                      variant="link"
                      className="bg-light w-100"
                      onClick={(e) =>
                        UpdateSubItem(e, itemIndex, subItemIndex, "sbitem")
                      }
                    >
                      {t("button.update")}
                    </Button>
                  </div>
                </Col>
              )
              : userJson &&
              userJson.length > 0 &&
              userJson.indexOf(1020) !== -1 && (
                <Col md="auto" xs="4">
                  <div className="SaveButtons">
                    <Button
                      type="button"
                      variant="link"
                      className="bg-light w-100"
                      onClick={(e) => SaveSubItem(itemIndex, subItemIndex)}
                    >
                      {t("button.save")}
                    </Button>
                  </div>
                </Col>
              )}
            {subItem.childData.length > 0
              ? ""
              : userJson &&
              userJson.length > 0 &&
              userJson.indexOf(1020) !== -1 && (
                <Col md="auto" xs="4">
                  <Button
                    type="button"
                    variant="link"
                    className="bg-light w-100"
                    onClick={() =>
                      handleDeleteSubItem(
                        "childdelete",
                        subItem,
                        subItemIndex,
                        itemIndex
                      )
                    }
                  >
                    <img
                      src="./assets/images/icons/delete-danger.svg"
                      alt="Delete"
                    />
                  </Button>
                </Col>
              )}
          </Row>
          {renderSubSubItemInputs(subItem.childData, itemIndex, subItemIndex)}
          <input
            ref={newInputRef}
            style={{ opacity: 0, position: "absolute" }}
          />
        </div>
      );
    });
  };

  const renderSubSubItemInputs = (
    childItems: SubSubItem[],
    itemIndex: number,
    subItemIndex: number
  ) => {
    return childItems.map((subSubItem: any, subSubItemIndex) => {
      const isSubSubItemInvalid = !subSubItem.item || !subSubItem.description;
      const isSubSubItemEmpty = subSubItem.item === "";
      const isSubSubDescriptionEmpty = subSubItem.description === "";
      return (
        <div className="ms-md-3 ms-2" key={subSubItemIndex}>
          <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 sub_account_plan_edit">
            <Col md={2} xxl={1} xs={4}>
              <FloatingLabel controlId="item" label={t("formLabel.item") + " *"} >
                <Form.Control
                  type="text"
                  className={
                    isSubSubItemInvalid && isSubSubItemEmpty ? "is-invalid" : ""
                  }
                  value={subSubItem.item}
                  onChange={(event: any) =>
                    handleSubSubItemChange(
                      itemIndex,
                      subItemIndex,
                      subSubItemIndex,
                      event
                    )
                  }
                  placeholder="Sub Sub Item"
                  disabled
                  onFocus={(event: any) => handleBlureInput(subSubItem.item)}
                />
                {isSubSubItemInvalid && isSubSubItemEmpty && (
                  <Form.Control.Feedback className="invalid-feedback">
                    Item is required
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
            <Col md xs={8}>
              <FloatingLabel
                controlId="description"
                label={t("formLabel.description") + " *"}
              >
                <Form.Control
                  type="text"
                  className={
                    isSubSubItemInvalid && isSubSubDescriptionEmpty
                      ? "is-invalid"
                      : ""
                  }
                  value={subSubItem.description}
                  onChange={(event: any) =>
                    handleSubSubDescriptionChange(
                      itemIndex,
                      subItemIndex,
                      subSubItemIndex,
                      event
                    )
                  }
                  placeholder="Sub Sub Description"
                  disabled
                  autoFocus
                />
                {isSubSubItemInvalid && isSubSubDescriptionEmpty && (
                  <Form.Control.Feedback className="invalid-feedback">
                    Description is required
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
            {subSubItem["count"] == 1 ? userJson && userJson.length > 0 && (userJson.indexOf(1020) !== -1) && (
              <Col md="auto" xs="4">
                <div className="subItem Edit">
                  <Button
                    type="button"
                    variant="link"
                    className="bg-light w-100"
                    onClick={(e) =>
                      EditSubItem(e, "subItemEdit", subSubItem.item)
                    }
                  >
                    {t("button.edit")}
                  </Button>
                </div>
                <div className="subItem update" hidden>
                  <Button
                    type="button"
                    variant="link"
                    className="bg-light w-100"
                    onClick={(e) =>
                      UpdateSubSubItem(
                        e,
                        itemIndex,
                        subItemIndex,
                        subSubItemIndex
                      )
                    }
                  >
                    {t("button.update")}
                  </Button>
                </div>
              </Col>
            ) : userJson && userJson.length > 0 && (userJson.indexOf(1020) !== -1) && (
              <Col md="auto" xs="4">
                <div className="SaveButtons">
                  <Button
                    type="button"
                    variant="link"
                    className="bg-light w-100"
                    onClick={() =>
                      SaveSubSubItem(itemIndex, subItemIndex, subSubItemIndex)
                    }
                  >
                    {t("button.save")}
                  </Button>
                </div>
              </Col>
            )}

            {
              userJson && userJson.length > 0 && (userJson.indexOf(1020) !== -1) &&
              <Col md="auto" xs="4">
                <Button
                  type="button"
                  variant="link"
                  className="bg-light w-100"
                  onClick={() =>
                    handleDeleteSubSubItem(
                      "childdatadelete",
                      itemIndex,
                      subItemIndex,
                      subSubItemIndex
                    )
                  }
                >
                  <img
                    src="./assets/images/icons/delete-danger.svg"
                    alt="Delete"
                  />
                </Button>
              </Col>
            }

          </Row>
          <input ref={newInputRef} style={{ opacity: 0, position: 'absolute' }} />

        </div>
      );
    });
  };

  return (
    <div>
      <section className="content-wrapper content-wrapper-primary">
        <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3 mb-6">
          {submenu}
          {status == "loading" ? (
            <Loader />
          ) : (
            <div className="update_account_plan">{renderItemInputs()}</div>
          )}

          {
            userJson && userJson.length > 0 && (userJson.indexOf(1020) !== -1) &&
            <div className="text-end fixed-bottom bg-white p-2 border-top box-shadow">
              <Button variant="primary" onClick={handleAddItem}>
                {t("button.newItem")}
              </Button>
            </div>
          }
        </main>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("chartofaccount.newitem")}</Modal.Title>
          </Modal.Header>
          <h5 className="mx-5">{t("chartofaccount.heading")}</h5>
          <Modal.Body>
            <Row className="gy-3 mt-1">
              <Col md={3} xs={4}>
                <FloatingLabel controlId="item" label={t("formLabel.item") + " *"}>
                  <Form.Control
                    type="text"
                    className={`${errors.item ? "is-invalid" : ""}`}
                    {...register("item")}
                    placeholder="Item"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.item?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md xs={8}>
                <FloatingLabel
                  controlId="description"
                  label={t("formLabel.description") + " *"}
                >
                  <Form.Control
                    type="text"
                    className={`${errors.description ? "is-invalid" : ""}`}
                    {...register("description")}
                    placeholder="Description"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              {t("button.cancel")}
            </Button>
            <Button variant="primary" type="submit">
              {t("button.createitem")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <AccountSetting items={items} />
    </div>
  );
};

export default AccountsPlan;
