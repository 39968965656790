import React, { useEffect, useState } from "react";
import { ComProps } from "../../../Interface";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Newbranch,
  postBranch,
  addBranch,
  getBraches,
} from "../../../../redux/slice/branchSlice";
import { AppDispatch, RootState } from "../../../../redux/store";
import { handleClo } from "../../../../redux/slice/branchSlice";
import {
  Form,
  Modal,
  Button,
  Row,
  Col,
  FloatingLabel,
  InputGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import customerService from "../../../../services/customerService";
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from "../../../constants/utils";
interface Props {
  value: number;
  label: string;
}

const NewBranch = ({ submenu }: ComProps) => {
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>();
  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation();
  // Retrieve show, and status from the Redux state
  const { show, status } = useSelector((state: RootState) => state.branch);
  // Created a use state to conduct state shows
  const [AllStates, setAllStates] = useState([]);
  const [branchState, setBranchState] = useState({
    start: "0",
    end: "100",
  });
  // Pop up hide of new branch on click
  const handleClose = () => {
    let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
    zipCodeCheck?.["removeAttribute"]('disabled');
    dispatch(handleClo(false));
    reset();
  };
  // used it to show validation error on form
  const schema = yup.object().shape({
    branchname: yup.string().required(t("requiredMmessage.fieldRequired")),
    corporatereason: yup.string().required(t("requiredMmessage.fieldRequired")),
    CNPJ: yup.string().required(t("requiredMmessage.fieldRequired")),
    municipalregistration: yup
      .string()
      .required(t("requiredMmessage.fieldRequired")),
    stateregistration: yup.string().required(t("requiredMmessage.fieldRequired")),
    responsiblename: yup.string().required(t('requiredMmessage.fieldRequired')),
    CPF: yup.string().required(t("requiredMmessage.fieldRequired")),
    email: yup
      .string()
      .required(t("requiredMmessage.fieldRequired"))
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        'Invalid email address'
      ),
    telephone: yup.string().required(t("requiredMmessage.fieldRequired")).matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
    zipcode: yup.string().required(t("requiredMmessage.fieldRequired")),
    address: yup.string().required(t("requiredMmessage.fieldRequired")),
    number: yup.string().required(t("requiredMmessage.fieldRequired")),
    // complement: yup.string().required(t("requiredMmessage.fieldRequired")),
    neighborhood: yup.string().required(t("requiredMmessage.fieldRequired")),
    city: yup.string().required(t("requiredMmessage.fieldRequired")),
    state: yup.string().required(t("requiredMmessage.fieldRequired")),
  });
  // The useForm hook is used to handle form registration, submission, and validation.
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Newbranch>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // Execute the effect function asynchronously
    if (show) {
      (async () => {
        // Fetch the address data based on zipcode using the customerService
        let resp = await customerService.getAddressBasedOnZipcode(`states`);
        // Check if the response status is "success"
        if (resp.data.type == "Success" || resp.data.type == "success") {
          let loop = resp.data.data;
          let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
          // Sort the stateData alphabetically by label
          stateData.sort((a, b) => a.label.localeCompare(b.label));
          setAllStates(stateData);
        }
      })();
      let cpf = getValues('CPF')
      if(cpf) {
        clearErrors('CPF')
      }
      let cnpj = getValues('CNPJ')
      if(cnpj) {
        clearErrors('CNPJ')
      }
      let telephone = getValues('telephone')
      if(telephone) {
        clearErrors('telephone')
      }
      let zipcode = getValues('zipcode')
      if(zipcode) {
        clearErrors('zipcode')
      }
    }
  }, [show]);

  const getLocation = async (e, inputType) => {
    let str = e.target.value
    let newStr = str.replace('-', ''); // Removes the hyphen
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
    let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
    // Error validation
    // Retrieve address based on zipcode using customerService
    let resp = await customerService.getAddressBasedOnZipcode(
      `${inputType}/${newStr}`
    );
    // Get the DOM element for the zipcode input
    let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
    let zipCodeCheck: any = document.querySelector(".zipCodeCheck");
    if (value != "") {
      // Check if the response status is success
      if (resp?.["data"]["type"] == "Success") {
        let res = resp["data"]["data"];
        // Update the styling and enable zip code check
        querySel.style.border = "0px solid red";
        zipCodeCheck.removeAttribute("disabled");
        // Set values for city, neighborhood, state, and address
        if (res[0]["city"]) {
          setValue("city", res[0]["city"]);
        }
        if (res[0]["neigbohood"]) {
          setValue("neighborhood", res[0]["neigbohood"]);
        }
        if (res[0]["state"]) {
          setValue("state", res[0]["state"]);
        }
        if (res[0]["street"] || res[0]["streetType"]) {
          let data =
            (res[0]["streetType"] ? res[0]["streetType"] + " " : "") +
            (res[0]["street"] ?? "");
          setValue("address", data);
        }
        clearErrors("zipcode");
        clearErrors("city");
        clearErrors("neighborhood");
        clearErrors("address");
        clearErrors("state");
      } else {
        // Reset styling and enable zip code check when value is empty
        setError("zipcode", {
          type: "manual",
          message: `${t("invalidzipcode")}`,
        });
        querySel.style.border = "2px solid red";
        setValue("city", "");
        setValue("neighborhood", "");
        setValue("state", "");
        setValue("address", "");
        zipCodeCheck.setAttribute("disabled", "");
      }
    } else {
      // Reset styling and enable zip code check when value is empty
      querySel.style.border = "0px solid red";
      zipCodeCheck.removeAttribute("disabled");
    }
  };

  const onSubmit = async (data: Newbranch) => {
    // Dispatch a postBranch action to create a new branch
    let res = await dispatch(postBranch(data));
    if (res?.payload?.resCode == 202) {
      // Show success message if the branch creation is successful
      Swal.fire({
        position: "center",
        // icon: 'success',"success",
        title: `${t("Sweetalert.Success")}`,
        text: `${t("Sweetalert.insertRecord")}`,
        showConfirmButton: true,
        timer: 2000,
      });
      // Dispatch actions to update branches
      dispatch(getBraches(branchState));
      dispatch(addBranch(data));
      dispatch(handleClo(false));
    } else if(res?.payload?.data?.resCode == 211) {
      // Show error message if the branch creation fails
      Swal.fire({
        position: "center",
        // icon: 'success',"error",
        title: `${t("Sweetalert.Error")}`,
        text: `${t("Sweetalert.unqueEmails")}`,
        showConfirmButton: true,
        timer: 2000,
      });
    }else{
      Swal.fire({
        position: "center",
        // icon: 'success',"error",
        title: `${t("Sweetalert.Error")}`,
        text: `${t("Sweetalert.cannotInsert")}`,
        showConfirmButton: true,
        timer: 2000,
      });
    }
    // Dispatch handleClo action to close a form/modal
    // Reset the form fields
  };
  
  const HandleInput = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('telephone');
    }
  }

  const handleCPF_CNJPVaue = async (e, type) => {
    let name = e.target.name;
    let checkCNJP = CNJP_CPFValidation(e.target.value, type);
    if (checkCNJP?.["status"] || e.target.value == "") {
      clearErrors(name)
      let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
      zipCodeCheck?.["setAttribute"]('disabled', "")
      if (e.target.value !== "") {
        let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
        if (duplicateData["data"] > 0) {
          zipCodeCheck?.["setAttribute"]('disabled', "")
          setError(name, {
            type: "manual",
            message: t("formLabel.ErrorCNPJCPF")
          });
        } else {
          zipCodeCheck?.["removeAttribute"]('disabled');
        }
      }
    } else {
      // errorCNPJCPF(e.target.value)
      setError(name, {
        type: "manual",
        message: checkCNJP?.["message"]
      });
    };
  }
  const numericOnly = (e) => {
    const reg = /^[0-9\b]+$/
    let preval = e.target.value
    if (e.target.value === '' || reg.test(e.target.value)) return true
    else e.target.value = preval.substring(0, (preval ? preval.length - 1 : 0))
  }
  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={show} onHide={handleClose} id="newbranchtsx">
        <Modal.Header closeButton>
          <Modal.Title> {t("branches.newBranch")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
              <Col md={12}>
                <FloatingLabel controlId="name" label={t("formLabel.name")+ " *"}>
                  <Form.Control
                    type="text"
                    className={`${errors.branchname ? "is-invalid" : ""}`}
                    placeholder="Enter Your  Name"
                    {...register("branchname")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.branchname?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="corporateReason"
                  label={t("formLabel.corporateReason")+ " *"}
                >
                  <Form.Control
                    type="text"
                    className={`${errors.corporatereason ? "is-invalid" : ""}`}
                    placeholder="Corporate reason"
                    {...register("corporatereason")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporatereason?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="cnpj" label={t("formLabel.cnpj")+ " *"}>
                  <Form.Control
                    type="text"
                    className={`${errors.CNPJ ? "is-invalid" : ""}`}
                    id="cnpj"
                    placeholder="CNPJ"
                    {...register("CNPJ")}
                    onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.CNPJ?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="municipalregistration"
                  label={t("formLabel.municipalregistration")+ " *"}
                >
                  <Form.Control
                    type="text"
                    className={`${errors.municipalregistration ? "is-invalid" : ""
                      }`}
                    placeholder="Municipal registration"
                    {...register("municipalregistration")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.municipalregistration?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="stateregistration"
                  label={t("formLabel.stateregistration")+ " *"}
                >
                  <Form.Control
                    type="text"
                    className={`${errors.stateregistration ? "is-invalid" : ""
                      }`}
                    placeholder="State registration"
                    {...register("stateregistration")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.stateregistration?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <span className="d-block mb-3 fw-bold">{t("company.contact")}</span>
            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
              <Col md={6}>
                <FloatingLabel
                  controlId="responsiblename"
                  label={t("formLabel.responsiblename")+ " *"}
                >
                  <Form.Control
                    type="text"
                    className={`${errors.responsiblename ? "is-invalid" : ""}`}
                    placeholder="Responsible name"
                    {...register("responsiblename")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.responsiblename?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="cpf" label={t("formLabel.cpf")+ " *"}>
                  <Form.Control
                    type="text"
                    className={`${errors.CPF ? "is-invalid" : ""}`}
                    placeholder="CPF"
                    {...register("CPF")}
                    onChange={(e) => handleCPF_CNJPVaue(e, "cpf")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.CPF?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="email" label={t("formLabel.email")+ " *"}>
                  <Form.Control
                    type="email"
                    className={`${errors.email ? "is-invalid" : ""}`}
                    placeholder="Email"
                    {...register("email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="telephone"
                  label={t("formLabel.telephone")+ " *"}
                >
                  <Form.Control
                    type="tel"
                    className={`${errors.telephone ? "is-invalid" : ""}`}
                    placeholder="Telephone"
                    {...register("telephone")} onChange={HandleInput}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.telephone?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <span className="d-block mb-3 fw-bold">{t("company.address")}</span>
            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
              <Col md={6}>
                <InputGroup>
                  <FloatingLabel
                    label={t("formLabel.zipCode")+ " *"}
                    className="getzipcode"
                  >
                    <Form.Control
                      type="tel"
                      className={` ${errors.zipcode ? "is-invalid" : ""}`}
                      placeholder="Zip code"
                      {...register("zipcode")}
                      onChange={(e) => getLocation(e, "zipcode")}
                      maxLength={9} minLength={9} 
                    />
                    <Form.Control.Feedback type="invalid" className="w-100">
                      {errors.zipcode?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <InputGroup.Text>
                    <img
                      src="assets/images/icons/search.svg"
                      alt="Search"
                      className="h-14 cursor-pe"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="address"
                  label={t("formLabel.address")+ " *"}
                >
                  <Form.Control
                    type="text"
                    className={`${errors.address ? "is-invalid" : ""}`}
                    placeholder="Address"
                    {...register("address")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="number" label={t("formLabel.number")+ " *"}>
                  <Form.Control
                    type="number"
                    className={`${errors.number ? "is-invalid" : ""}`}
                    placeholder="Number"
                    {...register("number")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.number?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="complement"
                  label={t("formLabel.complement")}
                >
                  <Form.Control
                    type="text"
                    placeholder="Complement"
                    {...register("complement")}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.complement?.message}
                  </Form.Control.Feedback> */}
                </FloatingLabel>
              </Col>
              <Col md={5}>
                <FloatingLabel
                  controlId="neighborhood"
                  label={t("formLabel.neighborhood")+ " *"}
                >
                  <Form.Control
                    type="text"
                    className={`${errors.neighborhood ? "is-invalid" : ""}`}
                    placeholder="Neighborhood"
                    {...register("neighborhood")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.neighborhood?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel controlId="city" label={t("formLabel.city")+ " *"}>
                  <Form.Control
                    type="text"
                    className={`${errors.city ? "is-invalid" : ""}`}
                    placeholder="City"
                    {...register("city")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={3}>
                <FloatingLabel controlId="state" label={t("formLabel.state")+ " *"}>
                  <Form.Select
                    className={`${errors.state ? "is-invalid" : ""}`}
                    id="state"
                    {...register("state")}
                  >
                    <option value="">---type---</option>
                    {AllStates && AllStates.length > 0 &&
                      AllStates.map((item, index) => {
                        return (
                          <option key={index} value={item?.["label"]}>
                            {item?.["label"]}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.state?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={handleClose}
              type="button"
              id="closemodal"
            >
              {t("button.close")}
            </Button>
            <Button variant="primary zipCodeCheck" type="submit">
              {t("button.toSave")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  );
};

export default NewBranch;
