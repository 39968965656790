import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const EditFolder = (props) => {
    const [t] = useTranslation();
    //Edit folder modal close open function
    const { showEditFolder, editFolderClose } = props;
    return (
        <>
            <Modal show={showEditFolder} onHide={editFolderClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.createCopyOrEdit")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {t("DocumentTemplate.createCopyOrEditContent")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Link to={"/folder_detail"} className="btn btn-outline-primary" onClick={editFolderClose}>
                        {t("DocumentTemplate.editCurrentTemplate")}
                        </Link>
                        <Link to={"/folder_detail"} className="btn btn-primary" onClick={editFolderClose}>
                        {t("DocumentTemplate.createCopy")}
                        </Link>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default EditFolder
