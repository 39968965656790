import { create } from "zustand";

type BankAccountDrawerState = {
  bankAccountDrawerAction: "new" | "edit" | undefined;
  bankAccount: any;
  setShowBankAccountDrawer: ({
    action,
    bankAccount,
  }: {
    action: "new" | "edit" | undefined;
    bankAccount?: any;
  }) => void;
};

const useBankAccountStore = create<BankAccountDrawerState>((set) => ({
  bankAccountDrawerAction: undefined,
  bankAccount: undefined,

  setShowBankAccountDrawer: ({ action, bankAccount }) =>
    set({ bankAccountDrawerAction: action, bankAccount }),
}));

export function useBankAccountDrawer() {
  return useBankAccountStore((state) => state);
}
