import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const Delete = (props) => {
    const [t] = useTranslation();
    //Delete modal close open function
    const { showDelete, deleteClose } = props;
    return (
        <>
            <Modal show={showDelete} onHide={deleteClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("NonPayment.deleteProductOrService")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {t("NonPayment.whenDeletingActionCannotUndone")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={deleteClose}>
                        {t("button.cancel")}
                        </Button>
                        <Button variant="primary min-w-auto" onClick={deleteClose}>
                        {t("button.delete")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default Delete
