import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

const DEFAULT_PAGE_SIZE = 20

type UsePaginationParams = {
  totalItems?: number
  onChangePage: ({ start, end }: { start: number, end: number }) => void
}

export function usePagination({ onChangePage, totalItems = 0 }: UsePaginationParams) {
  const [page, setPage] = useState(1);

  const pageCount = useMemo(() => Math.ceil(totalItems / DEFAULT_PAGE_SIZE), [totalItems]);
  const pages = useMemo(() => Array.from({ length: pageCount }, (_, x) => x + 1), [pageCount]);
  const pagesToShow = useMemo(() => {
    const startPage = Math.max(0, page - 3);
    const endPage = Math.min(pageCount, page + 2);

    return pages.slice(startPage, endPage);
  }, [page, pageCount, pages]);

  const hasPreviousAndNextButton = useMemo(() => pageCount > 1, [pageCount]);
  const isOnFirstPage = useMemo(() => page === 1, [page]);
  const isOnLastPage = useMemo(() => page === pageCount, [page, pageCount]);
  const rangeOfItems = useMemo(() => {
    const start = ((page - 1) * DEFAULT_PAGE_SIZE) + 1
    const end = Math.min(
      page * DEFAULT_PAGE_SIZE,
      totalItems
    );

    return {
      start,
      end,
    }
  }, [totalItems, page]);

  const handleChangePage = useCallback((newPage: number) => {
    const start = (newPage - 1) * DEFAULT_PAGE_SIZE;
    const end = DEFAULT_PAGE_SIZE;

    setPage(newPage);
    onChangePage({ start, end });
  }, [onChangePage]);

  const goToPreviousPage = useCallback(() => {
    if (page > 1) {
      handleChangePage(page - 1);
    }
  }, [page, handleChangePage]);

  const goToNextPage = useCallback(() => {
    if (page < pageCount) {
      handleChangePage(page + 1);
    }
  }, [page, pageCount, handleChangePage]);

  function Pagination() {
    return (
      <Row>
        <Col xs={3} className="d-flex align-items-center">
          <span style={{ color: "#808080" }} className="fs-14">
            {totalItems > 0
              ? `Listando ${rangeOfItems.start} a ${rangeOfItems.end} de ${totalItems} ${totalItems > 1 ? 'itens' : 'item'}`
              : ""
            }
          </span>
        </Col>
        <Col className="d-flex flex-row w-full justify-content-center align-items-center gap-2">
          {hasPreviousAndNextButton &&
            <Button
              size="sm"
              disabled={isOnFirstPage}
              onClick={goToPreviousPage}
              className="rounded-pill btn-light"
            >
              Anterior
            </Button>
          }
          {pagesToShow.map(pageNumber => (
            <Button
              className={`d-flex align-items-center justify-content-center rounded-circle ${pageNumber !== page && "btn-light"} px-1`}
              key={pageNumber}
              onClick={() => handleChangePage(pageNumber)}
              size="sm"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              {pageNumber}
            </Button>
          ))}
          {hasPreviousAndNextButton &&
            <Button
              size="sm"
              disabled={isOnLastPage}
              onClick={(goToNextPage)}
              className="rounded-pill btn-light"
            >
              Próximo
            </Button>
          }
        </Col>
        <Col xs={3} />
      </Row>
    )
  }

  return {
    Pagination,
    totalItems,
    hasPreviousAndNextButton,
    isOnFirstPage,
    isOnLastPage,
    goToPreviousPage,
    goToNextPage,
    handleChangePage,
    page,
    pagesToShow,
    rangeOfItems
  }
}
