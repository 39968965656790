import { Table, Card, Dropdown, Button, Form, Modal, Row, Col, FloatingLabel, Collapse, Badge } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import '../../../../App.css';
import Pagination from '../../../Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getAllBillMethodListing, openTypeCustomer } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, PrintPDF, encrypt_Decrypt, checkDuplicateCNJP_CPF } from '../../../constants/utils';
import SendEmails from '../Common/SendEmails';
import { openEmailPop, tenantsOpen } from '../../../../redux/slice/contractDataSlice';
import TenantsService from '../Common/TenantsService';

const Tenants = (CustomersAndEmployeesForm) => {
    const dispatch = useDispatch<AppDispatch>()
    const { Property_Type } = useSelector((state: RootState) => state.contractData)

    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
    }, [dispatch]);

    const navigate = useNavigate();
    const location = useLocation();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2040) === -1) {
            navigate("/customer_and_employees");
        }
        setUserJson(userResult);
    }, [])

    const [t] = useTranslation();
    const [legalAndPhysical, setLegalAndPhysical] = useState<any>('')
    const [legalOwner, setLegalOwner] = useState<any>('')
    const [status, setStatus] = useState(true);
    const [filterData, setFilterData] = useState('')
    /*--------Mailing and Label permission state--------*/

    const [hide5, setHide5] = useState(false);
    const [mailText, setMailText] = useState("");
    const [show3, setShow3] = useState(false);

    /*--------Data table listing state & multiple checked--------*/

    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [sendMailData, setSendMailData] = useState<any>([])
    const [mailData, setMailData] = useState<any>([])
    const [personId, setPersonId] = useState<any>([]);
    const [legalTenant, setLegalTenant] = useState<any>([])

    const [firstLoad, setFirstLoad] = useState(false);

    const [state, setState] = useState({
        status: "",
        property_type: "",
        payment: "",
        start: 0,
        end: 20,
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(20);
    const [tenstate, settenState] = useState({
        tenant: "",
        person: "",
        status: "",
        payment: "",
        property_type: "",
        start: 0,
        end: PageSize,
        type: "TenantList"
    })

    useEffect(() => {
        getAllTenant(tenstate);
    }, []);

    /*--------Common modal function--------*/

    const formShow = () => {
        dispatch(tenantsOpen(true))
    }
    const mailingShow = () => {
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');
        data.forEach((item, index) => {
            if (item["checked"]) {
                count = true;
            }
        })
        if (count) {
            setShow3(true);
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }
    const mailingClose = () => { setShow3(false); reset(); }
    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');
        data.forEach((item, index) => {
            if (item["checked"]) {
                count = true
                sendMailData.forEach((element: any) => {
                    if (element.id == item.id) {
                        mailData.push(element.email);
                        personId.push(element.id);
                    }
                });
                // Push the mailData array into the emailArray
                setMailData(prevEmailArray => [...prevEmailArray, ...mailData]);
                setPersonId(prevEmailArray => [...prevEmailArray, ...personId]);
            }
        })
        if (count) {
            setMailText(e.currentTarget.name)
            if (e.currentTarget.name === "Send letter" || e.currentTarget.name === "Enviar carta") { setHide5(true); }
            else { setHide5(false); }
            // setShow2(true)
            dispatch(openEmailPop(true))
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    /*-------------Form validation-------------*/
    const mailValidationSchema = Yup.object().shape({
        identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        model: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        variable: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);
    /*-------------Mailing label form submit-------------*/
    const permissionSubmit = (data: CustomersAndEmployeesForm) => {
        let permissionData = {
            "administrative_access": data.administrative_access,
            "change_and_query": data.change_and_query,
            "closing_opening_month": data.closing_opening_month,
            "chart_accounts": data.chart_accounts,
            "settings_consultation": data.settings_consultation,
            "customers_notify": data.customers_notify,
            "collections_consultation": data.collections_consultation,
            "collections_amendment": data.collections_amendment,
            "collections_invalidate": data.collections_invalidate,
            "collections_settlement": data.collections_settlement,
            "collections_reverse": data.collections_reverse,
            "metrics_visualization": data.metrics_visualization,
            "box_consultation": data.box_consultation,
            "agreements_consultation": data.agreements_consultation,
            "monetary_amendment": data.monetary_amendment,
            "legal_consultation": data.legal_consultation,
            "invoices_consultation": data.invoices_consultation,
            "invoices_amendment": data.invoices_amendment,
            "services_consultation": data.services_consultation,
            "expenses_consultation": data.expenses_consultation
        }
    }
    const getAllTenant = (tenant: any) => {
        let tenantData = {
            tenant: tenant.tenant,
            start: tenant.start,
            end: tenant.end,
            person: tenant.person,
            status: tenant.status,
            payment: tenant.payment,
            property_type: tenant.property_type,
            type: "TenantList"
        }
        setStatus(true);

        if (location.state) {
            tenantData["person"] = "Legal";
             customerService.filterTenant(tenstate).then((res) => {
                setStatus(false);
                delete location.state;
                if (res.data.resCode === 201) {
                    setData(res?.data?.data || []);
                    setLegalTenant(res?.data.data || []);
                    setSendMailData(res?.data?.data || []);
                    setTotal(res.data.totalRow || 0);
                    setTotalPage(res.data.totalRow || 0)
                } else {
                    setData([]);
                    setLegalTenant([]);
                    setSendMailData([]);
                    setTotal(0);
                    setTotalPage(0)
                }
            }).catch(err => {
                setStatus(false);
            });
        } else {
            customerService.getAllTenant(tenantData).then(res => {
                setStatus(false);
                if (res.data.resCode === 201) {
                    setData(res.data.data);
                    setLegalTenant(res.data.data);
                    setSendMailData(res.data.data)
                    setTotal(res.data.totalRow);
                    setTotalPage(res.data.totalRow)
                }
                else {
                    setData([]);
                    setTotalPage(0);
                    setTotal(0);
                    setLegalTenant([]);
                    setSendMailData([])
                }
            }).catch(err => {
                setStatus(false);
            });
        }
        setFirstLoad(true);
    }

    /*-------Checked/Unchecked the multiple checkbox functionality--------*/
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }

                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })

                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)

        }
    };

    // Pagination start
    const [totalpage, setTotalPage] = useState(0)
    // Pagination End

    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
    });
    let typingTimer;
    const ActiveContract = (evt,) => {
        let value = evt.target.value;
        let name = evt.target.name;
        setFirstLoad(true);
        if (name == "search") {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                settenState({
                    ...tenstate,
                    tenant: value
                })
            }, 1000)
        } else {
            if (value == "All" || value == "") {
                // getAllTenant(tenstate);
                settenState({
                    ...tenstate,
                    [name]: ""
                })
            } else {
                settenState({
                    ...tenstate,
                    [name]: value
                })
                var selectElement: any = document.querySelector('select[name="property_type"]');
                var selectedIndex = selectElement?.selectedIndex;
                var selectedOption = selectElement?.options[selectedIndex];
                // Get the value of the data-action attribute
                var dataAction: any = selectedOption?.getAttribute('label');
                setFilterData(dataAction)
            }
        }

    }

    useEffect(() => {
        if (!firstLoad) {
            setTimeout(() => {
                setFirstLoad(true)
            }, 1500)
        }
        settenState({
            ...tenstate,
            ["start"]: currentPage == 1 || currentPage == 0 ? 0 : (currentPage - 1) * PageSize,
            ["end"]: PageSize
        })
    }, [currentPage])


    useEffect(() => {
        if (firstLoad && tenstate) {
            if (location.state) {
                tenstate["person"] = "Legal";
                delete location.state;
            }

            customerService.filterTenant(tenstate).then((res) => {
                if (res.data.resCode === 201) {
                    setData(res?.data?.data || []);
                    setLegalTenant(res?.data.data || []);
                    setSendMailData(res?.data?.data || []);
                    setTotal(res.data.totalRow || 0);
                    setTotalPage(res.data.totalRow || 0)
                } else {
                    setData([]);
                    setLegalTenant([]);
                    setSendMailData([]);
                    setTotal(0);
                    setTotalPage(0)
                }
            })
        }
    }, [tenstate])

    // useEffect(() => {
    //     if (location?.state?.type == "legal_entities") {
    //         setFirstLoad(true)
    //         settenState({
    //             ...tenstate,
    //             person: "Legal"
    //         })
    //     } else {
    //         setFirstLoad(false)
    //     }
    // }, [location.state]);

    const legalAndPhycal = (evt: any) => {
        let value = evt.target.value;
        settenState({
            ...tenstate,
            person: value == "1" ? "both" : value == "2" ? "Physical" : value == "3" ? "Legal" : "both",
            start: 0,
            end: PageSize,
        })

        let checkCnpjCpf: any = []
        let legalData: any = []
        legalTenant?.forEach((element: any) => {
            if (!value || value == 1) {
                legalData?.push(element)
            }
            let checkCNJP: any = CNJP_CPFValidation(`${element.cnpj}`, "both")
            checkCnpjCpf?.push(checkCNJP)
            checkCnpjCpf?.forEach(item => {
                if (item.cnpj == element?.cnpj && value == 3) {
                    legalData.push(element)
                }
                if (item?.cpf == element?.cnpj && value == 2) {
                    legalData.push(element)
                }
            })
        });
        setData(legalData)
    }
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);

    const [spouse, setspouse] = useState(false);
    const handleChangeMaritail = (e) => {
        if (e.target.value == "1") {
            setspouse(true);
        } else {
            setspouse(false);
        }
    }
    const handleSelectName = async (e, type, id) => {
        e.preventDefault();
        let data: any = {
            type: type
        }
        let typeClick: any = document.querySelector("body");
        typeClick.setAttribute("linkfor", type)
        typeClick.setAttribute("linkforid", "2040")
        navigate(`/profile_details/${id}`, { state: data });
    }
    const filterReset = () => {
        let setvalue: any = document.querySelector(".clearfilter");
        if (setvalue) {
            setvalue.value = ""
        }
        let setvalue1: any = document.querySelector(".allClear");
        if (setvalue1) {
            setvalue1.value = "All"
        }
        settenState({
            tenant: "",
            person: "",
            status: "",
            payment: "",
            property_type: "",
            start: 0,
            end: PageSize,
            type: ""
        });
    }
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start" id="cusdtodmderAndddEmplodyees">
                                <Link to={"/customer_and_employees"}>
                                    <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-xl-1">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t('customerAndEmployees.tenants')}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <div className="input-group">
                                <Form.Control type="text" id="search" name="search" placeholder={t('formLabel.typeSearch')} onChange={ActiveContract} />
                                <span className="input-group-text" id="basic-addon2">
                                    <img alt="img" src="assets/images/icons/search.svg" />
                                </span>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1) &&
                                    <>
                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                            <Link to={"#"} onClick={formShow} className="fw-bold">
                                                <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                                {t('customerAndEmployees.newTenant')}
                                            </Link>
                                        </li>
                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                            <Link to={"/tenant_area"} className=" fw-bold">
                                                <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                                {t('customerAndEmployees.tenantArea')}
                                            </Link>
                                        </li>
                                        <li className="list-inline-item mb-md-0 mb-1">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link" size="sm" className="p-0 dropdown-arrow-none">
                                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20 cursor-pe" onClick={PrintPDF} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">{t('formLabel.printScreen')}</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">{t('customerAndEmployees.tenantReport')}</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-1">{t('customerAndEmployees.detailedTenantReport')}</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">{t('customerAndEmployees.extractIncomeTaxReturn')}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </>
                                }
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Link to={"#"} className="fw-bold">
                                        <img src="./assets/images/icons/settings-light.svg" />
                                    </Link>
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3" id="tenantFilter">
                            <Col xl="auto" >
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" className={`${tenstate?.["person"] ? "show" : ""}`}>
                                        {tenstate?.["person"] == "both" ? t('dropdownItem.physicalAndLegal') : tenstate?.["person"] == "Physical" ? t('dropdownItem.physical') : tenstate?.["person"] == "Legal" ? t('dropdownItem.legal') : t('dropdownItem.person')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="px-4">
                                            <FloatingLabel controlId="floatingSelect" label={t('dropdownItem.status')} >
                                                <Form.Select id="status" aria-label={t('dropdownItem.status')} className={`clearfilter ${errors.model ? 'is-invalid' : ''}`} onChange={legalAndPhycal}>
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {/* <option value="1">{t('dropdownItem.physicalAndLegal')}</option> */}
                                                    <option value="2">{t('dropdownItem.physical')}</option>
                                                    <option value="3">{t('dropdownItem.legal')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1" className={`${tenstate?.["status"] ? "show" : ""}`}>
                                        {tenstate?.["status"] == "activewithContract" ? t('dropdownItem.activeContracts') : tenstate?.["status"] == "All" ? t('All') : t('dropdownItem.contractStatus')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="px-4">
                                            <FloatingLabel controlId="floatingSelect" label={t('dropdownItem.contractStatus')}>
                                                <Form.Select id="status" aria-label={t('dropdownItem.gruops')} name="status" onChange={ActiveContract} className='allClear'>
                                                    <option value="All">{t('all')}</option>
                                                    <option value="activewithContract">{t('dropdownItem.activeContracts')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto" >
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1" className={`${tenstate?.["payment"] ? "show" : ""}`}>
                                        {tenstate?.payment == "1" ? t("defaultPaymentMethod1.chequedeposit") : tenstate?.payment == "2" ? t("defaultPaymentMethod1.cashdeposit") : tenstate?.payment == "12" ? t("customerAndEmployees.pix") : tenstate?.payment == "0" ? t("customerAndEmployees.boleto") : t('formLabel.formOfPayment')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="px-4">
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.formOfPayment')}>
                                                <Form.Select id="status" aria-label={t('dropdownItem.gruops')} className={`clearfilter ${errors.model ? 'is-invalid' : ''}`} name="payment" onChange={ActiveContract}>
                                                    <option value="">{t('all')}</option>
                                                    <option value="1">{t("defaultPaymentMethod1.chequedeposit")}</option>
                                                    <option value="2">{t("defaultPaymentMethod1.cashdeposit")}</option>
                                                    <option value="12">{t("customerAndEmployees.pix")}</option>
                                                    <option value="0">{t("customerAndEmployees.boleto")}</option>
                                                    {/* <option value="">Credit card</option> */}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto" >
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1" className={`${tenstate?.["property_type"] ? "show" : ""}`}>
                                        {filterData ? t(`${filterData}`) : t('formLabel.propertyType')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="px-4 ">
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.propertyType')}>
                                                <Form.Select id="status" aria-label={t('dropdownItem.gruops')} className={`clearfilter ${errors.model ? 'is-invalid' : ''}`} name="property_type" onChange={ActiveContract}>
                                                    <option value="">{t("formLabel.all")}</option>
                                                    {Property_Type && Property_Type.map((item, index) => (
                                                        <option key={index} value={item.value} label={item.label}>{item.label}</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {tenstate?.["payment"] !== "" || tenstate?.["status"] || tenstate?.["person"] || tenstate?.["property_type"] ? <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                        {t("button.resetfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col> :
                                ""}
                        </Row>
                    </Form>
                </Collapse>
            </div>
            {/*<!-- Breadcrumb section end from here -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*<!-- Table start from here -->*/}
                {
                    status ?
                        <Loader />
                        :
                        <>
                            <Card>
                                <Card.Body className="pb-1">
                                    <Table responsive className="table table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th className="w-24">
                                                    {data && data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        : ''}
                                                </th>
                                                <th>{t('formLabel.name')}</th>
                                                <th>{t('formLabel.contact')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.length > 0 ? data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className={"check_Checkbox"}>
                                                        <Checkbox key={item.id} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                    </td>
                                                    <td>
                                                        <span className="d-block">
                                                            <img alt="img" src="assets/images/icons/user_g.svg" />
                                                            <Link to={'#'} onClick={(e) => handleSelectName(e, "Tenant", item.id)} className="ps-1 mr-2 text-dark-70">{item.name}</Link>
                                                            {item.tanentStatus !== 1 &&
                                                                <img style={{ width: '9px', marginLeft: '4px' }} alt="img" src="assets/images/icons/green-circle-icon.svg" />
                                                            }
                                                            <br />
                                                            <span>{legalAndPhysical ? legalAndPhysical : legalOwner ? legalOwner : ""}</span>
                                                            {item.tanentStatus == "1" &&
                                                                <span><Badge className="text-white ms-1" bg="secondary">{item?.["tanentStatus"] == "1" ? t("inActive") : ''}</Badge></span>
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href={`mailto:${item.email}`} className="d-block text-dark-70">{item.email}</a>
                                                        <span>{item.cellphone ? item.cellphone : item.cellphone}</span>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan={3}>
                                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            {
                                <div className="d-flex justify-content-center mt-lg-4 mt-3">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalpage}
                                        pageSize={PageSize}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                </div>
                            }
                        </>
                }
                {/*<!-- /Table end -->*/}
                {/* {t('formLabel.moreItems')} */}
                <p className="d-block py-2">{t('formLabel.listing')} {data && data.length} {t('customerAndEmployees.tenants')}.</p>
                {/*<!-- Nav listing start from here -->*/}
                {
                    userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1) &&
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                    {t('formLabel.mark')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.all')}</Dropdown.Item>
                                    <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('formLabel.none')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                            <span className="px-1">
                                {t('formLabel.withTagged')}
                            </span>
                        </li>
                        <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                            <Button type="button" onClick={handleMouseEvent} name={t('formLabel.sendEmail')} variant="light" size="sm" >
                                {t('formLabel.sendEmail')}
                            </Button>
                        </li>
                        <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                            <Button type="button" onClick={handleMouseEvent} name={t('formLabel.sendLetter')} variant="light" size="sm">
                                {t('formLabel.sendLetter')}
                            </Button>
                        </li>
                        <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                            <Button type="button" onClick={mailingShow} variant="light" size="sm">
                                {t('formLabel.mailingLabel')}
                            </Button>
                        </li>
                    </ul>
                }
                {/*<!-- /Nav listing  end -->*/}
            </main>
            <SendEmails mailText={mailText} mailData={mailData} personId={personId} customer_type="Tenant" />
            {/*---------------Mailing permision form modal------------*/}

            <Modal show={show3} onHide={mailingClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('formLabel.changeAccessPermi')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(permissionSubmit)}>
                    <Modal.Body>
                        <span className="d-block mb-3 fw-bold">
                            {t('formLabel.hits')}
                        </span>
                        <Button className="btn btn-light btn-sm me-3">
                            {t('button.selectAll')}
                        </Button>
                        <Button className="btn btn-light btn-sm">
                            {t('button.clearSelect')}
                        </Button>
                        <Row>
                            <Form.Group className="mb-3 gy-3">
                                <Form.Check type="checkbox" {...register('administrative_access')} label={t('checkboxLabel.administrativeAccess')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('change_and_query')} label={t('checkboxLabel.usersChangeQuery')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('closing_opening_month')} label={t('checkboxLabel.closingOpeningMonth')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('chart_accounts')} label={t('checkboxLabel.chartAccountsAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('settings_consultation')} label={t('checkboxLabel.settingsConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('customers_notify')} label={t('checkboxLabel.customersNotify')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_consultation')} label={t('checkboxLabel.collectionsConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_amendment')} label={t('checkboxLabel.collectionsAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_invalidate')} label={t('checkboxLabel.collectionsInvalidate')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_settlement')} label={t('checkboxLabel.collectionsSettlement')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_reverse')} label={t('checkboxLabel.collectionsReverse')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('metrics_visualization')} label={t('checkboxLabel.metricsVisualization')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('box_consultation')} label={t('checkboxLabel.boxConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('agreements_consultation')} label={t('checkboxLabel.agreementsConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('monetary_amendment')} label={t('checkboxLabel.monetaryUpdateAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('legal_consultation')} label={t('checkboxLabel.legalConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('invoices_consultation')} label={t('checkboxLabel.invoicesConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('invoices_amendment')} label={t('checkboxLabel.invoicesAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('services_consultation')} label={t('checkboxLabel.servicesConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('expenses_consultation')} label={t('checkboxLabel.expensesConsultation')} />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline" onClick={mailingClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" name="To save" >
                            {t('button.save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/*---------------OWNER FORM MODAL------------*/}
            <TenantsService getAllTenant={getAllTenant} tenstate={tenstate} />
        </section>
    )
}
export default Tenants;