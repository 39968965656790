import { config } from "../../../../services/apiConfig";

type CompanyInfos = {
  address: string;
  city: string;
  cnpj: string;
  company_name: string;
  complement: string;
  email: string;
  fantasy_name: string;
  neighborhood: string;
  number: string;
  state: string;
  telephone: string;
  zipcode: string;
};

export async function getCompanyInfos(): Promise<CompanyInfos> {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(`${config.companyV2_URL}/company/companyData`, {
    method: "POST",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      "connection-name": connectionName,
    },
  });

  const data = await response.json();

  return data.data[0];
}
