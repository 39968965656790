import { contractSearchForm, transferForm } from '../../component/Interface';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
import transferService from '../../services/transferService';
import Swal from 'sweetalert2';
let connectionName: any = '';
if (localStorage.getItem("userData")) {
	connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
	IDLE: "idle",
	LOADING: "loading",
	SUCEESS: "success",
	ERROR: "error"
});

export interface Columns {
	id: number;
	name: string;
	email: string;
	telephone: string;
	responsible: string;
	observation: string;
	status: number;
	transferLoad: number;
	itemName: string;
}

interface SliceState {
	singleTransferContractData: contractSearchForm[] | any,
	transferMadeContractData: contractSearchForm[] | any,
	transferContractData: contractSearchForm[] | any,
	transferContractCountData: contractSearchForm[] | any,
	toBePaidtransferData: contractSearchForm[] | any,
	toBePaidedtransferData: contractSearchForm[] | any,
	paidExpData:contractSearchForm[]|any,
	transferMadeCountData: contractSearchForm[] | any,
	dashboardData: contractSearchForm[] | any,
	pendenciesData: contractSearchForm[] | any,
	pendenciesData_internal: contractSearchForm[] | any,
	dashChargeData: contractSearchForm[] | any,
	dashIncomeData: contractSearchForm[] | any,
	dashMainData: contractSearchForm[] | any,
	transferSimulateData: contractSearchForm[] | any,
	dashGenerateData: contractSearchForm[] | any,
	dashGenerateStatus: string,
	dashboardStatus: string,
	pendenciesStatus: string,
	pendenciesStatus_internal: string,
	dashChargeStatus: string,
	dashIncomeStatus: string,
	dashMainStatus: string,
	transferFilterOpen: boolean,
	expenseType: string,
	transferOpen: boolean,
	maturityOpen: boolean,
	checkedId: string,
	transferLoad: string,
	transferStatus: string,
	transferSimulateStatus: string,
	transferCountStatus: string,
	transferMadeCountStatus: string,
	toBePaidStatus: string,
	toBePaidedStatus: string,
	paidExpStatus : string,
	transferToStatus: string,
	transferMadeStatus: string,
	filterType: boolean,
	status: string,
	transferId: string,
	stStartDate: string,
	stEndDate: string,
	TotalCharge: any,
	transferFilter: string
}

const initialState: SliceState = {
	singleTransferContractData: [],
	transferMadeContractData: [],
	transferContractData: [],
	transferContractCountData: [],
	toBePaidtransferData: [],
	toBePaidedtransferData: [],
	paidExpData:[],
	transferMadeCountData: [],
	dashboardData: [],
	pendenciesData: [],
	pendenciesData_internal: [],
	dashChargeData: [],
	dashIncomeData: [],
	dashMainData: [],
	transferSimulateData: [],
	dashGenerateData: [],
	dashGenerateStatus: STATUSES.IDLE,
	expenseType: "",
	checkedId: "",
	transferOpen: false,
	maturityOpen: false,
	transferFilterOpen: false,
	filterType: false,
	transferStatus: STATUSES.IDLE,
	transferSimulateStatus: STATUSES.IDLE,
	transferMadeCountStatus: STATUSES.IDLE,
	transferCountStatus: STATUSES.IDLE,
	toBePaidStatus: STATUSES.IDLE,
	toBePaidedStatus: STATUSES.IDLE,
	paidExpStatus:STATUSES.IDLE,
	transferToStatus: STATUSES.IDLE,
	transferLoad: STATUSES.IDLE,
	transferMadeStatus: STATUSES.IDLE,
	status: STATUSES.IDLE,
	dashboardStatus: STATUSES.IDLE,
	pendenciesStatus: STATUSES.IDLE,
	pendenciesStatus_internal: STATUSES.IDLE,
	dashChargeStatus: STATUSES.IDLE,
	dashIncomeStatus: STATUSES.IDLE,
	dashMainStatus: STATUSES.IDLE,
	transferId: "",
	stStartDate: "",
	stEndDate: "",
	TotalCharge: 0,
	transferFilter: ""
}

const user_id = gettoken.userId;
const transferDataSlice = createSlice({
	name: "transferDataSlice",
	initialState,
	reducers: {
		setTransferClose: (state, action) => {
			state.transferOpen = action.payload
		},
		setTransferOpen: (state, action) => {
			state.transferOpen = action.payload
		},
		setMaturityOpen: (state, action) => {
			state.maturityOpen = action.payload
		},
		setMaturityClose: (state, action) => {
			state.maturityOpen = action.payload
		},
		setCheckedId: (state, action) => {
			state.checkedId = action.payload
		},
		setTansferFilterOpen: (state, action) => {
			state.transferFilterOpen = action.payload
		},
		setTansferFilterClose: (state, action) => {
			state.transferFilterOpen = action.payload
		},
		setFilterType: (state, action) => {
			state.filterType = action.payload
		},
		setTransferId: (state, action) => {
			state.transferId = action.payload
		},
		setStartDate: (state, action) => {
			state.stStartDate = action.payload
		},
		setEndDate: (state, action) => {
			state.stEndDate = action.payload
		},
		getChargeData: (state, action) => {
			state.TotalCharge = action.payload
		},
		getTransferFilter: (state, action) => {
			state.transferFilter = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(transferContracts.pending, (state, action) => {
				state.transferStatus = STATUSES.LOADING;
			})
			.addCase(transferContracts.fulfilled, (state, action: PayloadAction<any>) => {
				state.transferContractData = action.payload
				state.transferStatus = STATUSES.SUCEESS;
			})
			.addCase(transferContracts.rejected, (state, action: PayloadAction<any>) => {
				state.transferStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getTransferSimulate.pending, (state, action) => {
				state.transferSimulateStatus = STATUSES.LOADING;
			})
			.addCase(getTransferSimulate.fulfilled, (state, action: PayloadAction<any>) => {
				state.transferSimulateData = action.payload
				state.transferSimulateStatus = STATUSES.SUCEESS;
			})
			.addCase(getTransferSimulate.rejected, (state, action: PayloadAction<any>) => {
				state.transferSimulateStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getTransferContractsCount.pending, (state, action) => {
				state.transferCountStatus = STATUSES.LOADING;
			})
			.addCase(getTransferContractsCount.fulfilled, (state, action: PayloadAction<any>) => {
				state.transferContractCountData = action.payload
				state.transferCountStatus = STATUSES.SUCEESS;
			})
			.addCase(getTransferContractsCount.rejected, (state, action: PayloadAction<any>) => {
				state.transferCountStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getTransferMadeCount.pending, (state, action) => {
				state.transferMadeCountStatus = STATUSES.LOADING;
			})
			.addCase(getTransferMadeCount.fulfilled, (state, action: PayloadAction<any>) => {
				state.transferMadeCountData = action.payload
				state.transferMadeCountStatus = STATUSES.SUCEESS;
			})
			.addCase(getTransferMadeCount.rejected, (state, action: PayloadAction<any>) => {
				state.transferMadeCountStatus = STATUSES.ERROR;
			});
		builder
			.addCase(transferToBePaid.pending, (state, action) => {
				state.toBePaidStatus = STATUSES.LOADING;
			})
			.addCase(transferToBePaid.fulfilled, (state, action: PayloadAction<any>) => {
				state.toBePaidtransferData = action.payload
				state.toBePaidStatus = STATUSES.SUCEESS;
			})
			.addCase(transferToBePaid.rejected, (state, action: PayloadAction<any>) => {
				state.toBePaidStatus = STATUSES.ERROR;
			});
		builder
			.addCase(transferToBePaided.pending, (state, action) => {
				state.toBePaidedStatus = STATUSES.LOADING;
			})
			.addCase(transferToBePaided.fulfilled, (state, action: PayloadAction<any>) => {
				state.toBePaidedtransferData = action.payload
				state.toBePaidedStatus = STATUSES.SUCEESS;
			})
			.addCase(transferToBePaided.rejected, (state, action: PayloadAction<any>) => {
				state.toBePaidedStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getPaidExpense.pending, (state, action) => {
				state.paidExpStatus = STATUSES.LOADING;
			})
			.addCase(getPaidExpense.fulfilled, (state, action: PayloadAction<any>) => {
				state.paidExpData = action.payload
				state.paidExpStatus = STATUSES.SUCEESS;
			})
			.addCase(getPaidExpense.rejected, (state, action: PayloadAction<any>) => {
				state.paidExpStatus = STATUSES.ERROR;
			});
		builder
			.addCase(singelTransferContract.pending, (state, action) => {
				state.transferLoad = STATUSES.LOADING;
			})
			.addCase(singelTransferContract.fulfilled, (state, action: PayloadAction<any>) => {
				state.singleTransferContractData = action.payload
				state.transferLoad = STATUSES.SUCEESS;
			})
			.addCase(singelTransferContract.rejected, (state, action: PayloadAction<any>) => {
				state.transferLoad = STATUSES.ERROR;
			});
		builder
			.addCase(insertMaturity.pending, (state) => {
				state.status = STATUSES.LOADING;
			})
			.addCase(insertMaturity.fulfilled, (state, action) => {
				let response = action.payload
				state.status = STATUSES.SUCEESS;
			})
			.addCase(insertMaturity.rejected, (state, action) => {
				state.status = STATUSES.ERROR;
			});
		builder
			.addCase(insertTransfer.pending, (state) => {
				state.status = STATUSES.LOADING;
			})
			.addCase(insertTransfer.fulfilled, (state, action) => {
				let response = action.payload
				state.status = STATUSES.SUCEESS;
			})
			.addCase(insertTransfer.rejected, (state, action) => {
				state.status = STATUSES.ERROR;
			});
		builder
			.addCase(transferMadeContracts.pending, (state, action) => {
				state.transferMadeStatus = STATUSES.LOADING;
			})
			.addCase(transferMadeContracts.fulfilled, (state, action: PayloadAction<any>) => {
				state.transferMadeContractData = action.payload
				state.transferMadeStatus = STATUSES.SUCEESS;
			})
			.addCase(transferMadeContracts.rejected, (state, action: PayloadAction<any>) => {
				state.transferMadeStatus = STATUSES.ERROR;
			});
		builder
			.addCase(transferToSwitchOff.pending, (state) => {
				state.transferToStatus = STATUSES.LOADING;
			})
			.addCase(transferToSwitchOff.fulfilled, (state, action) => {
				let response = action.payload
				state.transferToStatus = STATUSES.SUCEESS;
			})
			.addCase(transferToSwitchOff.rejected, (state, action) => {
				state.transferToStatus = STATUSES.ERROR;
			});
		builder
			.addCase(transferToPaid.pending, (state) => {
				state.transferToStatus = STATUSES.LOADING;
			})
			.addCase(transferToPaid.fulfilled, (state, action) => {
				let response = action.payload
				state.transferToStatus = STATUSES.SUCEESS;
			})
			.addCase(transferToPaid.rejected, (state, action) => {
				state.transferToStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getDashboard.pending, (state, action) => {
				state.dashboardStatus = STATUSES.LOADING;
			})
			.addCase(getDashboard.fulfilled, (state, action: PayloadAction<any>) => {
				state.dashboardData = action.payload
				state.dashboardStatus = STATUSES.SUCEESS;
			})
			.addCase(getDashboard.rejected, (state, action: PayloadAction<any>) => {
				state.dashboardStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getPendenciesData.pending, (state, action) => {
				state.pendenciesStatus = STATUSES.LOADING;
			})
			.addCase(getPendenciesData.fulfilled, (state, action: PayloadAction<any>) => {
				state.pendenciesData = action.payload
				state.pendenciesStatus = STATUSES.SUCEESS;
			})
			.addCase(getPendenciesData.rejected, (state, action: PayloadAction<any>) => {
				state.pendenciesStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getPendenciesData_internal.pending, (state, action) => {
				state.pendenciesStatus_internal = STATUSES.LOADING;
			})
			.addCase(getPendenciesData_internal.fulfilled, (state, action: PayloadAction<any>) => {
				state.pendenciesData_internal = action.payload
				state.pendenciesStatus_internal = STATUSES.SUCEESS;
			})
			.addCase(getPendenciesData_internal.rejected, (state, action: PayloadAction<any>) => {
				state.pendenciesStatus_internal = STATUSES.ERROR;
			});
		builder
			.addCase(getDashboardCharges.pending, (state, action) => {
				state.dashChargeStatus = STATUSES.LOADING;
			})
			.addCase(getDashboardCharges.fulfilled, (state, action: PayloadAction<any>) => {
				state.dashChargeData = action.payload.data;
				state.dashChargeStatus = STATUSES.SUCEESS;
			})
			.addCase(getDashboardCharges.rejected, (state, action: PayloadAction<any>) => {
				state.dashChargeStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getDashboardIncome.pending, (state, action) => {
				state.dashIncomeStatus = STATUSES.LOADING;
			})
			.addCase(getDashboardIncome.fulfilled, (state, action: PayloadAction<any>) => {
				state.dashIncomeData = action.payload
				state.dashIncomeStatus = STATUSES.SUCEESS;
			})
			.addCase(getDashboardIncome.rejected, (state, action: PayloadAction<any>) => {
				state.dashIncomeStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getGenerateDashboard.pending, (state, action) => {
				state.dashGenerateStatus = STATUSES.LOADING;
			})
			.addCase(getGenerateDashboard.fulfilled, (state, action: PayloadAction<any>) => {
				state.dashGenerateData = action.payload.data
				state.dashGenerateStatus = STATUSES.SUCEESS;
			})
			.addCase(getGenerateDashboard.rejected, (state, action: PayloadAction<any>) => {
				state.dashGenerateStatus = STATUSES.ERROR;
			});
		builder
			.addCase(getDashboardMainSource.pending, (state, action) => {
				state.dashMainStatus = STATUSES.LOADING;
			})
			.addCase(getDashboardMainSource.fulfilled, (state, action: PayloadAction<any>) => {
				state.dashMainData = action.payload
				state.dashMainStatus = STATUSES.SUCEESS;
			})
			.addCase(getDashboardMainSource.rejected, (state, action: PayloadAction<any>) => {
				state.dashMainStatus = STATUSES.ERROR;
			});
	}
});
export const { setEndDate, setStartDate, setTransferId, setFilterType, setTansferFilterOpen, setTansferFilterClose, setCheckedId, setMaturityClose, setMaturityOpen, setTransferClose, setTransferOpen, getChargeData, getTransferFilter } = transferDataSlice.actions;
export default transferDataSlice.reducer

/*----------Update the transfer contract maturity data api-----------*/

export const insertMaturity = createAsyncThunk(
	'contract/insertInsertMaturityData',
	async (postData: transferForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.insertMaturity(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Insert the transfer data api-----------*/

export const insertTransfer = createAsyncThunk(
	'contract/insertInsertTransferData',
	async (postData: transferForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.insertTransfer(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the tranfer made contracts api-----------*/

export const transferMadeContracts = createAsyncThunk(
	'property/fetchTransferMadeContractsData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getAllTransferMadeContracts(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the dashboard charges api-----------*/

export const getDashboardCharges = createAsyncThunk(
	'property/fetchDashboardChargesData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getDashboardCharges(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the dashboard main source api-----------*/

export const getDashboardMainSource = createAsyncThunk(
	'property/fetchDashboardMainSourceData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getDashboardMainSource(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the dashboard income api-----------*/

export const getDashboardIncome = createAsyncThunk(
	'property/fetchDashboardIncomeData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getDashboardIncome(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

export const getGenerateDashboard = createAsyncThunk(
	'property/fetchGenerateDashboardData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getGenerateDashboard(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the dashboard api-----------*/

export const getDashboard = createAsyncThunk(
	'property/fetchDashboardData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getDashboard(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the Pendencie data api-----------*/

export const getPendenciesData = createAsyncThunk(
	'property/fetchPendenciesData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getPendenciesData(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the Pendencie data api-----------*/

export const getPendenciesData_internal = createAsyncThunk(
	'property/fetchPendenciesData_internal',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getPendenciesData_internal(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Tranfer to paid api-----------*/

export const transferToPaid = createAsyncThunk(
	'property/insertTransferToPaidData',
	async (id: number | any) => {
		let resp = await transferService.transferToPaid(id);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Tranfer to switch of api-----------*/

export const transferToSwitchOff = createAsyncThunk(
	'property/insertTransferToSwitchOffData',
	async (id: number | any) => {
		let resp = await transferService.transferToSwitchOff(id);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);
export const transferToPostinFinancial = createAsyncThunk(
	'property/insertTransferToSwitchOffData',
	async (id: number | any) => {
		let resp = await transferService.transferToPostinFinancial(id);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

export const InsertIssueInvoice = createAsyncThunk(
	'property/InsertIssueInvoice',
	async (postData:any) => {
		let resp = await transferService.GenerateInvoce(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

export const transfergetInvoices = createAsyncThunk(
	'property/transfergetInvoices',
	async (postData:any) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getInvoices(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);
/*----------Get the tranfer contracts count api-----------*/

export const getTransferContractsCount = createAsyncThunk(
	'property/fetchTransferContractsCountData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getTransferContractsCount(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the tranfer contracts count api-----------*/

export const getTransferMadeCount = createAsyncThunk(
	'property/fetchTransferMadeCountData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getTransferMadeCount(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the tranfer contracts api-----------*/

export const transferContracts = createAsyncThunk(
	'property/fetchTransferContractsData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getAllTransferContracts(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the tranfer simulate api-----------*/

export const getTransferSimulate = createAsyncThunk(
	'property/fetchTransferSimulateData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getTransferSimulate(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the tranfer to paid  api-----------*/

export const transferToBePaided = createAsyncThunk(
	'property/fetchTransferToBePaidedData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getAllTransferToBePaided(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the tranfer to paid  api-----------*/

export const getPaidExpense = createAsyncThunk(
	'property/fetchPaidedExpenseData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getPaidExpenseData(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);

/*----------Get the tranfer to be paid  api-----------*/

export const transferToBePaid = createAsyncThunk(
	'property/fetchTransferToBePaidData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getAllTransferToBePaid(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);
/*----------Get the single tranfer contracts api-----------*/

export const singelTransferContract = createAsyncThunk(
	'property/fetchSingelTransferContractData',
	async (postData: contractSearchForm) => {
		postData.userId = gettoken.userId
		let resp = await transferService.getSingleTransferContracts(postData);
		let data = JSON.parse(JSON.stringify(resp)).data;
		return data;
	}
);