import React from "react"
import { Button, Card, Form, Table } from "react-bootstrap"

import { EmptyList } from "../../../../components/EmptyList"
import { Icon } from "../../../../components/Icon"
import { usePagination } from "../../../../hooks"
import { useDimob } from "../../DimobProvider"
import { SolveStatusModal, useSolveStatusModal } from "../SolveStatusModal"

import { StatusColumn } from "../StatusColumn/StatusColumn"
import { useContractsList } from "./ContractListProvider"
import Loader from "../../../../../component/Profile/Company/Loader"

export function ContractsList() {
  const { contracts } = useContractsList()
  const { isFetchingDimobContracts } = useDimob()
  const { handleChangePagination, countDimobContracts } = useDimob()
  const { handleOpenStatusModal } = useSolveStatusModal()
  const { Pagination } = usePagination({
    totalItems: countDimobContracts,
    onChangePage: handleChangePagination,
  })

  return (
    <>
      {isFetchingDimobContracts ? (
        <Loader />
      ) : (
        <>
          <Card>
            <Card.Body>
              <Table
                responsive="lg"
                className="table table-custom table-custom-bordered mb-0"
              >
                <thead>
                  <tr>
                    <th className="w-24">
                      <Form.Check type="checkbox" />
                    </th>
                    <th style={{ width: "10%" }} className="text-start">
                      Validade
                    </th>
                    <th className="text-start">Contrato</th>
                    <th style={{ width: "17%" }} className="text-start">
                      Status DIMOB
                    </th>
                    <th style={{ width: "17%" }} className="text-start">
                      Relatório IR
                    </th>
                    <th style={{ width: "17%" }} className="text-start">
                      <Button
                        variant="link"
                        className="p-0 d-flex align-items-center m-2"
                      >
                        <Icon name="share" color="#f82e52" />
                        <span className="m-2"> Enviar IR para todos</span>
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!contracts || contracts.length <= 0 ? (
                    <tr>
                      <td colSpan={6}>
                        <EmptyList text="Nenhum contrato encontrado." />
                      </td>
                    </tr>
                  ) : (
                    contracts.map((contract) => (
                      <tr key={contract.id}>
                        <td>
                          <Form.Check type="checkbox" />
                        </td>
                        <td className="text-dark-70">
                          {`${contract?.startDate} até ${contract?.endDate}`}
                        </td>
                        <td>
                          <div className="d-flex flex-column gap-1 fw-medium">
                            <div
                              className="d-flex align-items-center gap-1"
                              style={{ color: "#808080" }}
                            >
                              <span style={{ color: "#404040" }}>
                                {`${contract.property_identifier}/${contract.lease_count}`}
                              </span>
                              <span className="d-flex align-items-center gap-1">
                                <Icon name="home" />
                                {`${contract.address}, ${contract.city} - ${contract.zipcode}`}
                              </span>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              <Icon name="person" />
                              {contract.mainOwner.ST_NOME_PES}
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              <Icon name="key" />
                              {contract.tenants[0].ST_NOME_PES}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <StatusColumn
                              status={
                                contract?.status?.errors?.length > 0
                                  ? "error"
                                  : contract?.status?.alerts?.length > 0
                                  ? "alert"
                                  : "ready"
                              }
                            />
                            {contract.status &&
                              (contract.status?.errors?.length > 0 ||
                                contract.status?.alerts?.length > 0) && (
                                <Button
                                  variant="link"
                                  className="p-0 d-flex align-items-center gap-1"
                                  onClick={() =>
                                    handleOpenStatusModal(contract.status)
                                  }
                                >
                                  Solucionar
                                </Button>
                              )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <div
                              className="bg-success-10 rounded-2 px-1 text-dark-80 fs-12"
                              style={{ paddingBlock: "0.25rem" }}
                            >
                              {"Em desenvolvimento"}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <Button
                              variant="link"
                              className="p-0 d-flex align-items-center m-2"
                            >
                              Enviar
                            </Button>
                            <Button
                              variant="link"
                              className="p-0 d-flex align-items-center gap-1"
                            >
                              Visualizar
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <div className="mt-4">
            <Pagination />
          </div>
          <SolveStatusModal />
        </>
      )}
    </>
  )
}
