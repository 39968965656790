import React, { useEffect } from 'react'
import { Page, Text, Image, Document, StyleSheet, View, Font } from '@react-pdf/renderer'
import logo from './../../../logo.svg';
import { Col, Row } from 'react-bootstrap';

const styles = StyleSheet.create({
    body: {
        padding: "0",
        margin: "0",
        minWidth: "100%",
        width: "100%",
        background: "#ffffff",
        boxSizing: "border-box"
    },

    warpper: {
        backgroundColor: "#fff",
        width: "100%",
        margin: '0',
        padding: '0',
    },

    wrapper_body: {
        maxWidth: "800px",
        margin: "auto",
        width: "100%"
    },

    content: {
        margin: "0 15px",
        padding: "50px 20px",
        width: "100%"
    },

    textStyle: {
        textAlign: "left",
        fontSize: "14px",
        lineHeight: "2px",
        color: "#333333",
        fontWeight: "normal",
        marginBottom: "5px",
        marginTop: "40px",
        width: "100%"
    },

    textStyle2: {
        textAlign: "left",
        fontSize: "14px",
        color: "#333333",
        fontWeight: "normal",
        marginBottom: "5px",
        marginTop: "10px",
        width: "100%"
    },

    imagecontainer: {
        display: "flex",
        flexDirection: "row",
        position: "relative"
    },

    logoimage: {
        padding: "20px",
        width: 150,
        height: 40,
        objectFit: "cover"
    },

    header: {
        color: "#000",
        textAlign: "left",
        fontSize: "20px",
        paddingTop: "60px",
        marginBottom: "20px",
        fontWeight: 300,
        width: "100%",
        // position: "absolute",
        // top: "20px",
        alignItems: "center",
        // textAlign: "center"
    },
});

// Font.register({
//     family: 'Open Sans',
//     src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
// });

const KeyHandOver = ({ keyHandOverData }) => {
    // fontFamily: "'Sentinel', 'Lucida Grande', 'Lucida Sans Unicode'",

    useEffect(() => {
        // console.log({ logo }, "this is logo")
        // console.log({ keyHandOverData }, "This is handover")
    }, [keyHandOverData])
    return (
        <Document author="Kenlo"
            keywords="contract, leases, key, handover, term"
            subject="Key handover term"
            title="Handover File">
            <Page style={styles.body}>
                <View style={styles.warpper}>
                    <View style={styles.wrapper_body}>
                        <View style={styles.content}>
                            <View style={styles.imagecontainer}>
                                <View>
                                    {/* <Image src={'https://img.icons8.com/?size=1x&id=23265&format=png'} style={styles.logoimage} /> */}
                                    <Image src={process.env.PUBLIC_URL + "/logo.png"} style={styles.logoimage} />
                                </View>
                                <View style={{ alignItems: "center" }}>
                                    <Text style={styles.header}>
                                        Termo de entrega de chaves
                                    </Text>
                                </View>
                            </View>

                            <Text style={styles.textStyle}>
                                Na qualidade de locatário(a) do imóvel situado {keyHandOverData.property_location}, declaro para os devidos fins que estou entregando nesta data as chaves do referido
                                imóvel, o mesmo totalmente livre e desocupado de pessoas, coisas e bens.
                            </Text>
                            <Text style={styles.textStyle}>
                                Declaro ainda, que estou plenamente ciente de que sou responsável por todos os eventuais débitos e
                                encargos no imóvel até esta data, assim como pendências na vistoria final.
                            </Text>
                            <Text style={styles.textStyle}>
                                Por ser verdade, assino o presente termo de entrega de chaves e compromisso.
                            </Text>
                            <Text style={styles.textStyle}>
                                Campinas, {keyHandOverData.date ? keyHandOverData.date : ""}
                            </Text>
                            <div style={{ borderTop: "1px solid red", maxWidth: "100px" }}></div>
                            <View>
                                <Text style={styles.textStyle2}>
                                    Locatário: {keyHandOverData.tenant ? String(keyHandOverData.tenant) : ""}
                                </Text>
                                <Text style={styles.textStyle2}>
                                    CPF/CNPJ: {keyHandOverData.cpf_cnjp ? String(keyHandOverData.cpf_cnjp) : ""}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default KeyHandOver