import React, { useState } from 'react';
import { Accordion, FloatingLabel, Col, Row, Modal, Form, Button } from 'react-bootstrap';
import { openErrorbox } from '../../Contracts/Common/actionPerform';

const AnnualDebtStatment = (props) => {
    //New charge modal close open function
    const { showAnnualModal, annualModalClose, annualModalShow } = props;
    // Annual debt discharge statement steps initialization
    const [hide1, setHide1] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const step1 = () => {
        openErrorbox();
        setHide1(true);
        setHide2(false);
        setHide3(false);
    }
    const step2 = () => {
        openErrorbox();
        setHide1(false);
        setHide2(true);
        setHide3(false);
    }
    const step3 = () => {
        openErrorbox();
        setHide1(false);
        setHide2(false);
        setHide3(true);
    }
    return (
        <Modal show={showAnnualModal} onHide={annualModalClose}>
            <Form>
                <Modal.Header className="position-absolute top-0 end-0 z-index-1" closeButton></Modal.Header>
                <Modal.Body className="pb-2 pt-4">
                    <div style={{ display: hide1 ? "block" : "none" }}>
                        <h4 className="mb-3 mb-lg-4 mb-3 me-3">Which statement do you want to issue?</h4>
                        <Form.Check type="radio" className="mb-lg-4 mb-3" name="annual_dept_statement" label="Annual Debt Discharge Statement" id="annual_dept" />
                        <Form.Check type="radio" name="annual_dept_statement" label="Declaration of Default" id="declaration_default" />
                    </div>
                    <div style={{ display: hide2 ? "block" : "none" }}>
                        <h4 className="mb-3 mb-lg-4 mb-3 me-3">Do you want to edit the claim template before issuing?</h4>
                        <Button variant="light" size="sm">Edit declaration</Button>
                    </div>
                    <div style={{ display: hide3 ? "block" : "none" }}>
                        <h4 className="mb-3 mb-lg-4 mb-3 me-3">Statement options</h4>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="declaration_year" label="Declaration year">
                                    <Form.Select aria-label="Declaration year">
                                        <option value="1">2022</option>
                                        <option value="2">2023</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="notify_by" label="Notify by">
                                    <Form.Select aria-label="Notify by">
                                        <option value="1">Letter</option>
                                        <option value="2">Email</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Accordion className="accordion-custom" defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Filter by customers</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md>
                                            <FloatingLabel controlId="total" label="Total">
                                                <Form.Control type="text" placeholder="Total" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md="auto">
                                            <Button variant="link" className="bg-light border-light"><img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                        </Col>
                                    </Row>
                                    <a className="fw-bold">Another</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Print options</Accordion.Header>
                                <Accordion.Body>
                                    <Form.Check type="checkbox" className="mb-lg-4 mb-3" label="Back Addressing (Requires capable printer)" id="prnt_options_1" />
                                    <Form.Check type="checkbox" label="More lines (requires Legal II paper)" id="prnt_options_2" />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={annualModalClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={hide2 ? step1 : hide3 ? step2 : step1}>
                        Previous
                    </Button>
                    <Button variant="primary" className="me-0" style={{ display: hide3 ? "none" : "block" }} onClick={hide1 ? step2 : hide2 ? step3 : step2}>
                        Next
                    </Button>
                    <Button variant="primary" className="min-w-auto" style={{ display: hide3 ? "block" : "none" }} type="submit" onClick={openErrorbox}>
                        Issue
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AnnualDebtStatment
