import { Form, Modal, Button, Row, Col, InputGroup, Alert, Accordion, FloatingLabel, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { insertInsurance, getInsurance } from "../../../../redux/slice/contractDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { insuranceRegistrationForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import customerService from '../../../../services/customerService';
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import moment from 'moment';
import { add_Bank, getBankList } from '../../../../redux/slice/bankSlice';

const AddInsurence = (insuranceRegistrationForm) => {
    const navigate = useNavigate();
    const { } = useSelector((state: RootState) => state.contractData)
    const { } = useSelector((state: RootState) => state.expenditureData)
    const { } = useSelector((state: RootState) => state.branch)
    const { } = useSelector((state: RootState) => state.propertyData)

    const { insuranceData } = useSelector((state: RootState) => state.contractData)

    // Retrieve banklist, show1, and status from the Redux state
    const { banklist, show1, status } = useSelector((state: RootState) => state.bank)
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [allStates, setAllStates] = useState([]);
    const [issValue, setIssValue] = useState(1);
    const [agencyInsurance, setAgencyInsurency] = useState<any>('');
    const regexNotPattern = /^[0-9-]+$/;

    useEffect(() => {
        if (status != 'loading' && agencyInsurance == '') {
            dispatch(getInsurance({ "insurance": "", "start": 0, "end": 10 }));
            // gggg();
        }
    }, [dispatch, status]);

    useEffect(() => {
        let obj = {
            start: "0",
            end: "10",
            all: ""
        }
        dispatch(getBankList(obj))
        dispatch(add_Bank())
    }, [dispatch]);

    useEffect(() => {
        gggg();
    }, [insuranceData]);

    const gggg = () => {
        if (insuranceData && insuranceData.length > 0) {
            insuranceData.forEach((item: any, index: number) => {
                if (item["ST_SEGURANCA_EMP"] && item["ST_SEGURANCA_EMP"] !== "") {
                    let agencyDetails = item.AGENCY_DETAILS;
                    setAgencyInsurency(agencyDetails);
                    setValue('name', agencyDetails.ST_NOME_EMP)
                    setValue('corporateEmail', agencyDetails.ST_EMAIL_EMP)
                    setValue('corporatePhone', agencyDetails.ST_TELEFONE_EMP)

                    setValue('cnpj', agencyDetails.ST_CGC_EMP)
                    setValue('email', agencyDetails.ST_EMAIL_EMP)
                    setValue('phonenumber', agencyDetails.ST_TELEFONE_EMP)

                    setValue('zipcode', agencyDetails.ST_CEP_EMP)
                    setValue('address', agencyDetails.ST_ENDERECO_EMP)
                    setValue('city', agencyDetails.ST_CIDADE_EMP)
                    setValue('number', agencyDetails.ST_NUMERO_EMP)
                    setValue('neighborhood', agencyDetails.ST_BAIRRO_EMP)
                    setValue('state', agencyDetails.ST_ESTADO_EMP)
                }
            })
        }
    }

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, []);
    /*----------Set the contract form fields------------*/

    const [newContractShow2, setnewContractShow2] = useState(false);
    const newContractClose2btn = () => {
        setnewContractShow2(false)
    }

    /*--------------Form validation-------------*/
    let fmrrfm = {
        name: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        rg: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        cpfnumber: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        corporateEmail: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        corporatePhone: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        BusinessName: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        legalName: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        cnpj: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        email: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        phonenumber: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        zipcode: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        city: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        address: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        number: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        neighborhood: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        state: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        municipalRegistration: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        bank: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        bankName: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        agency: Yup.string().trim().required(t('requiredMmessage.fieldRequired')),
        account: Yup.string().trim()
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            })
            .required(t('requiredMmessage.fieldRequired')),
        digit: Yup.string().trim()
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            })
            .required(t('requiredMmessage.fieldRequired')),
        isReadterms: Yup.string().trim().required(t('requiredMmessage.fieldRequired'))
    }
    const contractValidation5 = Yup.object().shape(fmrrfm);
    let frm = {
        resolver: yupResolver(contractValidation5)
    }

    /*---------------Add/Edit the contract form---------------*/
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<insuranceRegistrationForm>(frm);
    const formSubmit = async (data: insuranceRegistrationForm, e: any) => {
        e.preventDefault();
        let mt = data.isReadterms;
        if (data.isReadterms == false || String(data.isReadterms) == 'false') {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: 'Please accept the terms',
                confirmButtonText: "OK",
                timer: 4000
            })
        } else {
            data["formIs"] = "main";
            let response = await dispatch(insertInsurance(data))
            if (typeof response.payload !== "undefined" && (response.payload.resCode === 202)) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: response.payload.message,
                    confirmButtonText: "OK",
                    timer: 4000
                })
                navigate("/dashboard");
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: response.payload.data ? response.payload.data : response.payload.message,
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 4000
                })
            }
        }
    }

    /*------------Set the billing address fied value----------*/
    const billingAddress = (evt) => {
        setValue('zipcode', '');
        setValue('address', '');
        setValue('number', '');
        // setValue('complement', '');
        setValue('neighborhood', '');
        setValue('city', '');
        setValue('state', '');
    }

    /*------------Set the contract fied value----------*/
    const issContract = (evt) => {
        // setIsssContract(true);
        // setIssValue(Number(evt.target.value));
        // if (evt.target.value == 'on' ) {
        //     setIssValue(Number(evt.target.value));
        //     // setIsssContract(false);
        // } else {
        //     setIssValue(Number(1));
        // }
    }

    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen

        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
        // let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                // zipCodeCheck.removeAttribute('disabled');
                // Set the city value if available in the response
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
                // zipCodeCheck.setAttribute('disabled', "")
            }
        } else {
            querySel.style.border = "0px solid red";
            // zipCodeCheck.removeAttribute('disabled');
        }
    }

    const HandleInput = (e: any, inputType) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if ((newValue == "" || newValue == null || newValue == undefined) && inputType !== "number") {
            setError(inputType, {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors(inputType);
        }
    }

    const selectBanksis = (e: any) => {
        setValue('bankName', e.target.value)
    }
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/insurers"}><img src="assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t('notifications.insurance')}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('newContractDev.savedConnectedInternet')}</Tooltip>}>
                                        <img src="assets/images/icons/cloud-arrow.svg" alt="cloud icon" className="h-20" />
                                    </OverlayTrigger>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary">
                                    <img src="assets/images/icons/arrow-double.svg" alt="cloud icon" className="h-14 me-2" /> {t('insurelist.closeAll')}
                                </li>
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
                <Form onSubmit={handleSubmit(formSubmit)} id="addtsxinsurance">
                    <Row className="gy-lg-4 gy-3 mb-lg-0 mb-5">

                        <Col lg={4}>
                            <Card className="overflow-hidden position-sticky top-0">
                                <Card.Header>
                                    Kenlo's benefits
                                </Card.Header>
                                <Card.Body className="px-0 pb-0">
                                    <div className="px-lg-4 px-3 pb-lg-4 pb-3">
                                        <p>{t("insurelist.inpartnershipwithTokio")}</p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.commissionsforeveryfire")}
                                        </p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.noobligation")}
                                        </p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.realtimequotesduring")}
                                        </p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.automaticcontractapproval")}
                                        </p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.automaticbilling")}
                                        </p><p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.securityfrom")}
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={8}>
                            <input type="hidden" {...register("id")} />
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/home-reqular.svg" alt="Property icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.aboutyou')}
                                            <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" />
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="g-3">

                                            <Col md={12}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.name")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.name")} className={`${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("RG")}>
                                                    <Form.Control type="text" placeholder={t("RG")} className={`${errors.rg ? 'is-invalid' : ''}`} {...register('rg')} />
                                                    <Form.Control.Feedback type="invalid">{errors.rg?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.cpf")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.cpf")} className={`${errors.cpfnumber ? 'is-invalid' : ''}`} {...register('cpfnumber')} />
                                                    <Form.Control.Feedback type="invalid">{errors.cpfnumber?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={8}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.Corporateemail")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.Corporateemail")} className={`${errors.corporateEmail ? 'is-invalid' : ''}`} {...register('corporateEmail')} />
                                                    <Form.Control.Feedback type="invalid">{errors.corporateEmail?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.phone")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.phone")} className={`${errors.corporatePhone ? 'is-invalid' : ''}`}  {...register('corporatePhone')} onChange={(e) => HandleInput(e, "corporatePhone")} />
                                                    <Form.Control.Feedback type="invalid">{errors.corporatePhone?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>



                                <Accordion.Item eventKey="1" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/user-light.svg" alt="Tenant icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.aboutReal')}
                                            <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" />
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="g-3">
                                            <Col md={12}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.BusinessName")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.BusinessName")} className={`${errors.BusinessName ? 'is-invalid' : ''}`} {...register('BusinessName')} />
                                                    <Form.Control.Feedback type="invalid">{errors.BusinessName?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.Legalname")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.Legalname")} className={`${errors.legalName ? 'is-invalid' : ''}`} {...register('legalName')} />
                                                    <Form.Control.Feedback type="invalid">{errors.legalName?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.cnpj")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.cnpj")} className={`${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} />
                                                    <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={8}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.email")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.email")} className={`${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.phone")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.phone")} className={`${errors.phonenumber ? 'is-invalid' : ''}`}  {...register('phonenumber')} onChange={(e) => HandleInput(e, "phonenumber")} />
                                                    <Form.Control.Feedback type="invalid">{errors.phonenumber?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/contract-detail.svg" alt="Contract details icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.aboutRealAddress')}
                                            <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" />
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col md={3}>
                                                <FloatingLabel className="mb-3 getzipcode" controlId="floatingSelect " label={t('zipCode')} >
                                                    <Form.Control type="text" placeholder={t('zipCode')} className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode")} />
                                                    <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={9}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('address')}>
                                                    <Form.Control type="text" placeholder={t('address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address')} />
                                                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('number')}>
                                                    <Form.Control type="text" placeholder={t('number')} className={`form-control ${errors.number ? 'is-invalid' : ''}`} {...register('number')} maxLength={10} onChange={numericOnly} />
                                                    <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('neighborhood')} className={`form-control ${errors.neighborhood ? 'is-invalid' : ''}`} {...register('neighborhood')} />
                                                    <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('city')}>
                                                    <Form.Control type="text" className={`form-control ${errors.city ? 'is-invalid' : ''}`} placeholder={t('city')} {...register('city')} />
                                                    <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('state')}>
                                                    <Form.Select className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state')} aria-label={t('state')}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        {allStates && allStates.length > 0 &&
                                                            allStates.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                )
                                                            })}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="3" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/warranty.svg" alt="Warranty icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.fiscalInfo')}
                                            <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" />
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="g-3 mt-0">
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('description')} >
                                                    <Form.Control type="text" placeholder={t('description')} className={`${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                                    <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("issRate")}>
                                                    <Form.Select className={`${errors.issRate ? 'is-invalid' : ''}`} aria-label="ISS Rate(%)"  {...register('issRate')}>
                                                        <option value="">{t('select')}</option>
                                                        <option value="5.0">5.0</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.issRate?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/fire-insurance.svg" alt="Fire insurance icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.bankData')}
                                            <img src="assets/images/icons/progress-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" />
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="mb-3">
                                            <Col md={6} className={`${errors.bank ? 'is-invalid' : ''}`}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t("banks.name")}>
                                                    <Form.Select className={`${errors.bank ? 'is-invalid' : ''}`} id="bank"  {...register("bank")} onChange={(e) => selectBanksis(e)}>
                                                        <option value=''>{t("formLabel.select")}</option>
                                                        <option key="1" value="1">BCO DO BRASIL S.A.</option>
                                                        <option key="2" value="3">BCO DA AMAZONIA S.A.</option>
                                                        <option key="3" value="4">BCO DO NORDESTE DO BRASIL S.A.</option>
                                                        <option key="4" value="33">BCO SANTANDER (BRASIL) S.A.</option>
                                                        <option key="5" value="47">BCO DO EST. DE SE S.A.</option>
                                                        <option key="6" value="70">BRB - BCO DE BRASILIA S.A.</option>
                                                        <option key="7" value="85">COOPCENTRAL AILOS</option>
                                                        <option key="8" value="104">CAIXA ECONOMICA FEDERAL</option>
                                                        <option key="9" value="133">CRESOL CONFEDERAÇÃO</option>
                                                        <option key="10" value="136">CONF NAC COOP CENTRAIS UNICRED</option>
                                                        <option key="11" value="212">BANCO ORIGINAL</option>
                                                        <option key="12" value="237">BCO BRADESCO S.A.</option>
                                                        <option key="13" value="241">BCO CLASSICO S.A.</option>
                                                        <option key="14" value="260">NU PAGAMENTOS - IP</option>
                                                        <option key="15" value="745">BCO CITIBANK S.A.</option>
                                                        <option key="16" value="748">BCO COOPERATIVO SICREDI S.A.</option>
                                                        <option key="17" value="756">BANCO SICOOB S.A.</option>
                                                        <option key="18" value="341">ITAÚ UNIBANCO S.A.</option>
                                                    </Form.Select >
                                                    <div className="invalid-feedback">{errors.bank?.message}</div>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6} className="mb-3">
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.bankName ? 'is-invalid' : ''}`} id="bankName"
                                                        placeholder="Bank" {...register("bankName")} readOnly />
                                                    <Form.Control.Feedback type="invalid">{errors.bankName?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.name")}</Form.Label>
                                                </Form.Floating>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.agency ? 'is-invalid' : ''}`} id="agency"
                                                        placeholder={t("banks.agency") + " " + t("banks.withDigit")} {...register("agency")} />
                                                    <Form.Control.Feedback type="invalid">{errors.agency?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.agency")}  </Form.Label>
                                                </Form.Floating>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.account ? 'is-invalid' : ''}`} id="account"
                                                        placeholder={t("banks.accountNo") + " " + t("banks.withDigit")} {...register("account")} />
                                                    <Form.Control.Feedback type="invalid">{errors.account?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.accountNo")} {t("banks.withDigit")}</Form.Label>
                                                </Form.Floating>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.digit ? 'is-invalid' : ''}`} id="digit"
                                                        placeholder={t("banks.withDigit")} {...register("digit")} />
                                                    <Form.Control.Feedback type="invalid">{errors.digit?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.withDigit")} </Form.Label>
                                                </Form.Floating>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <footer className="footer-fixed-lg bg-white border-top py-2 px-xl-5 px-md-4 px-3 mx-lg-0 mx-md-n4 mx-n3 mb-n3 mb-md-n4 mb-xl-0 mt-auto d-flex flex-wrap justify-content-lg-end">
                        {/* <Button variant="outline-primary" className="me-lg-3 me-2">{t('button.cancel')}</Button>  */}
                        <div className="p-2 min-h-56 d-flex flex-wrap align-items-center">
                            {/* checked={issValue === 0} */}
                            <Form.Check id="isReadterms" type="checkbox" {...register('isReadterms')} className={`${errors.isReadterms ? 'is-invalid' : ''}`} onClick={issContract} label={t('insurelist.readaccept')} />
                            <Form.Control.Feedback type="invalid">{errors.isReadterms?.message}</Form.Control.Feedback >
                        </div>
                        <Button variant="primary" type="submit" onClick={openErrorbox} value="qwewqe">{t('insurelist.submitRegistration')}</Button>
                    </footer>
                </Form>
                {/* </Modal> */}
            </main>
            {/* <!-- /Main content start end --> */}
            {/* Successfully modal start from here */}
            <Modal show={newContractShow2} onHide={newContractClose2btn}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="fs-12 d-block">{t("Sweetalert.SuccessfullyComplete")}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="text-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/thumb-up-light.svg"} alt="Documents add" className="h-40 cursor-pe" />
                            <h3 className="mt-3 mb-2">{t('contractGenerated')}</h3>
                            <ul className="list-inline d-sm-flex align-items-center justify-content-center mb-lg-4 mb-3">
                                <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                            {t('printOut')}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">{t('contractMirror')}</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">{t('contract')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                    <Button variant="light" size="sm">{t('launchRentalFee')}</Button>
                                </li>
                                <li className="list-inline-item mb-sm-0 mb-1">
                                    <Button variant="light" size="sm">{t('accessContract')}</Button>
                                </li>
                            </ul>
                            <a className="text-secondary">{t('registerAthContract')}</a>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={newContractClose2btn}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Successfully modal end here */}
        </section >
    )
}
export default AddInsurence;