import React, { useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { NonPaymentUndoAgreement, nonPaymentCharge, nonPaymentChargeAgreement } from '../../../../redux/slice/NonPaymentSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import Swal from 'sweetalert2';
import i18n from '../../../../i18n';

const UndoAgreement = (props) => {
    const [t] = useTranslation();
    //Undo agreement modal close open function
    const { showUndoAgreement, setconditionNonpayment, conditionNonpayment, undoAgreementClose, currentContract, REMIDD, setREMIDD, state, loading, setLoading } = props;
    const dispatch = useDispatch<AppDispatch>()

    const undoAgreementHandle = async () => {
        setLoading(true)
        let dataResult = await dispatch(NonPaymentUndoAgreement({
            resetData: REMIDD
        }));
        if (dataResult["payload"]["data"]["status"] == "success") {
            // await dispatch(nonPaymentCharge(state));
            await dispatch(nonPaymentChargeAgreement(state));
            setLoading(false);
            undoAgreementClose();
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: i18n.language == "en" ? "Agreement successfully undone" : "Acordo desfeito com sucesso",
                confirmButtonText: "OK",
            })
        } else {
            setLoading(false);
            Swal.fire({
                // icon: 'success',"error",
                title: t("Sweetalert.Error"),
                text: i18n.language == "en" ? "Failed to undo the agreement" : "Falha ao desfazer o acordo",
                confirmButtonText: "OK",
            })
        }
    }

    return (
        <>
            <Modal show={showUndoAgreement} onHide={undoAgreementClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("NonPayment.undoAgreement")}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("NonPayment.WhenUndoingAgreementDefaultersList")}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loading ?
                                <Button className="btn-width">
                                     <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary" onClick={undoAgreementClose}>
                                        {t("button.cancel")}
                                    </Button>
                                    <Button variant="primary min-w-auto" onClick={undoAgreementHandle}>
                                        {t("button.confirm")}
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default UndoAgreement
