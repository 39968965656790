import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
import axios from "axios";
let companyV2_URL = require('../../Api/Contact').config.companyV2_URL
let connectionName : any = '';
if( localStorage.getItem("userData") ){
  connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
    IDLE: "idle",
    LOADING: "loading",
    SUCEESS: "success",
    ERROR: "error",
  });

export interface ServicesForm {
    name: string;
    account_category: string;
    service_code_sku: string;
    debit: string;
    credit: string;
    pay_incom_task: boolean;
    admin_service: boolean;
    admn_deduct_service: boolean;
    issqn_collection_id: string;
    service_code_tax_id: string;
    percent_of_tax: string;
    not_taxable: boolean;
    invoice_dimob: string;
    status: boolean;
    supplier: string;
};

interface AuthState {
    servicelist: any,
    servicetaxcode: any,
    show: boolean,
    status: string;
    status1:boolean
}

let initialState: AuthState = {
    servicelist: [],
    servicetaxcode: [],
    show: false,
    status: STATUSES.IDLE,
    status1: false
};

const ServiceSlice = createSlice({
    name: "ServiceSlice",
    initialState,
    reducers: {
        ServiceShow: (state, action) => {
            state.show = action.payload
        },
    },
    extraReducers: (builder) => {
        //Getting all services
        builder.addCase(fetchService.pending, (state, { payload }) => {
            state.servicelist = [];
            state.status = STATUSES.LOADING;
        }).addCase(fetchService.fulfilled, (state, { payload }) => {
            state.servicelist = [...payload];
            state.status = STATUSES.SUCEESS;
        }).addCase(fetchService.rejected, (state, { payload }) => {
            state.servicelist = [];
            state.status = STATUSES.ERROR;
        });

        builder.addCase(serviceTaxcod.pending, (state, { payload }) => {
            state.servicetaxcode = [];
            state.status = STATUSES.LOADING;
        }).addCase(serviceTaxcod.fulfilled, (state, { payload }) => {
            state.servicetaxcode = payload;
            state.status = STATUSES.SUCEESS;
        }).addCase(serviceTaxcod.rejected, (state, { payload }) => {
            state.servicetaxcode = [];
            state.status = STATUSES.ERROR;
        });

        //Insert And Updating Service
        builder.addCase(insertService.pending, (state) => {
            state.status1 = false;
        }).addCase(insertService.fulfilled, (state, { payload }) => {
            state.servicelist = [...state.servicelist, payload];
            state.status1 = true;
        }).addCase(insertService.rejected, (state) => {
            state.status1 = false;
        });

        //Search Filter for Service
        builder.addCase(searchService.pending, (state) => {
            state.status = STATUSES.LOADING;
        }).addCase(searchService.fulfilled, (state, { payload }) => {
            state.servicelist = payload?.["data"]?.["data"];
            state.status = STATUSES.SUCEESS;
        }).addCase(searchService.rejected, (state) => {
            state.status = STATUSES.ERROR;
        });

        //Enable Disabled service
        builder.addCase(enable_disable.pending, (state) => {
            state.status = STATUSES.LOADING;
        }).addCase(enable_disable.fulfilled, (state, { payload }) => {
            // state.servicelist = payload?.["data"]?.["data"];
            state.status = STATUSES.SUCEESS;
        }).addCase(enable_disable.rejected, (state) => {
            state.status = STATUSES.ERROR;
        });

        //Delete single service
        builder.addCase(deleteSingleService.pending, (state) => {
            state.status = STATUSES.LOADING;
        }).addCase(deleteSingleService.fulfilled, (state, { payload }) => {
            // state.servicelist = payload?.["data"]?.["data"];
            state.status = STATUSES.SUCEESS;
        }).addCase(deleteSingleService.rejected, (state) => {
            state.status = STATUSES.ERROR;
        });
    }
})

export const { ServiceShow } = ServiceSlice.actions
export default ServiceSlice.reducer

export const fetchService = createAsyncThunk(
    "get/all_service", async (_, thunkAPI) => {
        try {
            let token:any = localStorage.getItem("tokenV2");
            const response = await axios({
                method: "get",
                url: `${companyV2_URL}/services`,
                headers: {
                    "x-auth-token": token,
                    'Content-type': 'application/json',
                    'connection-name' : connectionName
                },
            });

            let newData = response.data.data;
            let newArr: any = [];
            newData.forEach((item) => {
                if (item["FL_DESATIVADO_PRD"] == 0) {
                    newArr.push(item);
                }
            });
            const data = newArr;
            return await data
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: "Error Occur" });
        }
    });

    export const serviceTaxcod = createAsyncThunk(
        "get/serviceTaxcod", async (_, thunkAPI) => {
            try {
                let token:any = localStorage.getItem("tokenV2");
                const response = await axios({
                    method: "get",
                    url: `${companyV2_URL}/services/servicetaxcode`,
                    headers: {
                        "x-auth-token": token,
                        'Content-type': 'application/json',
                        'connection-name' : connectionName
                    },
                });
                return await response["data"]
            } catch (error) {
                return thunkAPI.rejectWithValue({ error: "Error Occur" });
            }
        });

export const insertService = createAsyncThunk(
    "insert_update/single_service", async (postData: any) => {
        try {
            let token:any = localStorage.getItem("tokenV2");
            const response = await axios({
                method: "post",
                url: `${companyV2_URL}/services/edit`,
                headers: {
                    "x-auth-token": token,
                    'Content-type': 'application/json',
                    'connection-name' : connectionName
                },
                data: postData
            });
            return response
        } catch (error) {
            return "Error Occur";
        }
    });

export const searchService = createAsyncThunk(
    "search/service", async (postData: any) => {
        try {
            let token:any = localStorage.getItem("tokenV2");
            const response = await axios({
                method: "post",
                url: `${companyV2_URL}/services/filter`,
                headers: {
                    "x-auth-token": token,
                    'Content-type': 'application/json',
                    'connection-name' : connectionName
                },
                data: postData
            });
            return await response
        } catch (error) {
            return "Error Occur";
        }
    });

export const enable_disable = createAsyncThunk(
    "enable_disable/service", async (postData: any) => {
        try {
            let token:any = localStorage.getItem("tokenV2");
            const response = await axios({
                method: "put",
                url: `${companyV2_URL}/services/enable_disable`,
                headers: {
                    "x-auth-token": token,
                    'Content-type': 'application/json',
                    'connection-name' : connectionName
                },
                data: postData
            });
            return await response
        } catch (error) {
            return "Error Occur";
        }
    });

export const singleService = createAsyncThunk(
    "single_service/service", async (postData: any) => {
        try {
            postData = {
                "service_id": postData
            }
            let token:any = localStorage.getItem("tokenV2");
            const response = await axios({
                method: "post",
                url: `${companyV2_URL}/services`,
                headers: {
                    "x-auth-token": token,
                    'Content-type': 'application/json',
                    'connection-name' : connectionName
                },
                data: postData
            });
            return await response
        } catch (error) {
            return "Error Occur";
        }
    });

export const deleteSingleService = createAsyncThunk(
    "delete/service", async (postData: any) => {
        try {
            postData = {
                "service_id": postData
            }
            let token:any = localStorage.getItem("tokenV2");
            const response = await axios({
                method: "delete",
                url: `${companyV2_URL}/services`,
                headers: {
                    "x-auth-token": token,
                    'Content-type': 'application/json',
                    'connection-name' : connectionName
                },
                data: postData
            });
            return await response
        } catch (error) {
            return "Error Occur";
        }
    });