import { useEffect, useState } from "react";
import { getAllOwners } from "../../services";

type Option = { value: string, label: string }

export function useFilters() {
  const [ownerOptions, setOwnerOptions] = useState<Option[]>([])
  const statusOptions = [
    { value: "alert", label: "Alerta" },
    { value: "error", label: "Erro" },
    { value: "ready", label: "Pronto" },
  ]

  useEffect(() => {
    async function fetchOwners() {
      const owners = await getAllOwners()

      const ownersFormatted = owners.data.map((owner: any) => {
        return {
          value: owner.id,
          label: owner.name
        }
      })

      setOwnerOptions(ownersFormatted)
    }

    fetchOwners()
  }, [])


  return {
    ownerOptions,
    statusOptions
  }
}


