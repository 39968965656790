import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const EditWatermark = (props) => {
    const [t] = useTranslation();
    //Edit watermark modal close open function
    const { showEditWatermark, editWatermarkClose } = props;
    return (
        <>
            <Modal show={showEditWatermark} onHide={editWatermarkClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.editWatermark")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                        <p className="mb-lg-4 mb-3">{t("DocumentTemplate.editWatermarkContent")}</p>
                        <div style={{ 'height': '300px', 'width': '216px' }} className="border-2 border border-dashed py-4 px-2 position-relative text-center rounded-4 mb-lg-4 mb-3 mx-auto">
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="fs-12 text-dark-70 me-2">{t("DocumentTemplate.supportedFormat")}</span>
                            <div className="position-relative mb-3">
                                <Button variant="primary">{t("DocumentTemplate.selectFile")}</Button>
                                <Form.Control type="file" className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1" />
                            </div>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}

export default EditWatermark
