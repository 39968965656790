import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AdministratorsSettings = (props) => {
    const [t] = useTranslation();
    //Administrators settings modal close open function
    const { showAdministratorsSetting, administratorsSettingClose } = props;
    return (
        <>
        <Modal show={showAdministratorsSetting} onHide={administratorsSettingClose}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-start">
                        <span className="fw-bold me-2 me-lg-3 me-xl-4">Administrators</span>
                        <Form.Check type="checkbox" id="cb-1" label="Block administrator registration without CNPJ" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={administratorsSettingClose}>
                    {t("button.close")}
                    </Button>
                    <Button variant="primary" onClick={administratorsSettingClose}>
                    {t("button.save")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
    )
}
export default AdministratorsSettings
