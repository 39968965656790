import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";

import { useBankAccountDrawer } from "../../../BankAccountDrawer";
import { useBankAccount } from "../../BankAccountProvider";

export function BankAccountActionButtons() {
  const { setShowBankAccountDrawer } = useBankAccountDrawer();
  const { isSubmitting } = useFormState();
  const { reset } = useFormContext();
  const { setShouldHomologate } = useBankAccount();

  return (
    <Row className="py-3 px-5">
      <Col sm={12} className="d-flex justify-content-end">
        <Button
          variant="link"
          onClick={() => {
            reset();
            setShouldHomologate(false);
            setShowBankAccountDrawer({
              action: undefined,
              bankAccount: undefined,
            });
          }}
          disabled={isSubmitting}
        >
          Cancelar
        </Button>

        <Button
          className="ms-2"
          type="submit"
          form="bankAccountForm"
          disabled={isSubmitting}
        >
          Salvar
        </Button>
      </Col>
    </Row>
  );
}
