import { Card, Dropdown, Table, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, InputGroup, FormControl, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useMemo, useRef } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import '../../../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../redux/store';
import { numericOnly, openErrorbox } from '../Common/actionPerform';
import { CNJP_CPFValidation, handleKeyDown, checkDuplicateCNJP_CPF, formatNumber, convertToNumber, converter } from '../../../constants/utils';
import i18n from '../../../../i18n';
import customerService from '../../../../services/customerService';
import { getContractSetting, GetRepresentativeService, openNewOwner, ownerClose, ownerhide, propertyOpen } from '../../../../redux/slice/contractDataSlice';
import { add_Bank } from '../../../../redux/slice/bankSlice';
import { registerData } from '../../../../redux/slice/companyDataSlice';
import { getManager } from '../../../../redux/slice/propertyDataSlice';
import { getAllSuplierCate } from '../../../../redux/slice/expenditureDataSlice';
import RepresentativeService from '../../NewDesigns/Contracts/RepresentativeService';
import { integrateOwner } from './services/splitPayments/integrateOwner';

const NewOwner = (props) => {
    const { iddata, type, getAllOwner, setownState, fromData, update, setUpdate, owner, getOwner } = props;
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [headingText, setHeadingText] = useState(t('customerAndEmployees.newOwner'));
    const [status, setStatus] = useState(true);
    const [editowner, setEditOwner] = useState(false)
    const [hideData, setHideData] = useState(false)
    const [show3, setShow3] = useState(true);
    const [hide5, setHide5] = useState(false);
    const [showPayment, setShowPayment] = useState(false)
    const [loader_on, setloader_on] = useState(false);
    const inputRef = useRef<any>(null);

    const [ isEmail,setIsEmail ] = useState(false);
    const [ isNewEmail,setIsNewEmail ] = useState("");
    const [ isOldEmail,setIsOldEmail ] = useState("");

    /*--------Data table listing state & multiple checked--------*/
    const [valueCon, setValueCon] = useState('')
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [AllStates, setAllStates] = useState([]);
    const [sendMailData, setSendMailData] = useState<any>([])
    const [pixTypeHide, setPixTypeHide] = useState(true)
    const [showHideField, setShowHideField] = useState<any>("")
    const [bankNotRequeire, setbankNotRequeire] = useState(false)
    const [bankhide, setBankHide] = useState(false)
    const [checkhide, setCheckHide] = useState(false)
    const [repreData, setRepreData] = useState([])
    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
    });
    const { banklist } = useSelector((state: RootState) => state.bank)
    const { show, hide, ContractSettingData } = useSelector((state: RootState) => state.contractData)
    const { companydata } = useSelector((state: RootState) => state.companyData)
    const { managerData } = useSelector((state: RootState) => state.propertyData)
    const { category } = useSelector((state: RootState) => state.expenditureData)
    useEffect(() => {
        if (show) {
            dispatch(getManager());
            dispatch(getAllSuplierCate({ "start": 0, "end": 1000 }))
            dispatch(getContractSetting())
            EditQwner(iddata);
            clearErrors("cnpj");
        }
    }, [show, data])

    useEffect(() => {
        if (ContractSettingData && ContractSettingData.length > 0) {
            if (ContractSettingData[0]?.ir_desconto_simplificado_loc == "1" && (headingText == "New Owner" || headingText == "Novo Proprietário")) {
                setValue("enableSimplifiedDeduction", true);
            } else {
                setValue("enableSimplifiedDeduction", false);
            }
        }
    }, [ContractSettingData])

    const [ state,setState ] = useState("");
    const formClose = () => {
        setState("");
        setIsOldEmail("");
        setIsNewEmail("");
        setloader_on(false);
        setIsEmail(false);
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        if (type == "properties") {
            dispatch(propertyOpen(true));
        }
        dispatch(ownerhide(false));
        dispatch(openNewOwner(false))
        setHideData(false)
        setHide2(false);
        setHide3(false);
        reset();
        setspouse(false)
        setIsInputFocused(false);
    }
    /*-------Step form function & validation--------*/
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => {
        dispatch(ownerhide(true)); setHide2(false); setHide3(false);
    }
    const step2 = () => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            dispatch(ownerhide(true))

        } else {
            dispatch(ownerhide(false));
            setHide2(true); setHide3(false);
        }
    }
    const step3 = () => { dispatch(ownerhide(false)); setHide2(false); setHide3(true); }

    /*-------------Form validation-------------*/
    const mailValidationSchema = Yup.object().shape({
        identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        model: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        variable: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    const mailLabelValidationSchema = Yup.object().shape({});

    /*-------------Form validation-------------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    let formValidationSchemaStep1:any = {
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    }
    let formValidationSchemaStep0:any = {
    }
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const regexNotPattern = /^[^a-z]*$/;
    const regexNotPatternBank = /^[0-9]+$/;
    let regexPattern = /^[^0-9./-]*$/;
    if (showHideField == "cnpj") {
        regexPattern = /^[a-zA-Z0-9 ]*$/;
    }
    let formValidationSchemaStep2:any = {
        name: Yup.string()
            .trim()
            .nullable()
            .required(t('requiredMmessage.fieldRequired')),
        payment: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        // password: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(passwordRules, { message: t("requiredMmessage.password") }),
        bankname: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bank_branchCode: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldBankRequired')),
        bank_acocunt: Yup.string().trim().nullable()
            // .matches(regexNotPattern, { message : t('requiredMmessage.Thevaluemustbeanumber') } )
            .test('is-valid', "Por favor, insira apenas números", (value: any) => {
                return regexNotPatternBank.test(value);
            })
            .required(t('requiredMmessage.fieldRequired')),

        bank_agency: Yup.string().trim().nullable()
            // .matches(regexNotPattern, { message : t('requiredMmessage.Thevaluemustbeanumber') } )
            .test('is-valid', "Por favor, insira apenas números", (value: any) => {
                return regexNotPatternBank.test(value);
            })
            .required(t("requiredMmessage.fieldRequired")).min(4, t("requiredMmessage.Agencyrequire")),
        bank_accountType: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        name_of: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        cnpjcpf: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        address: Yup.string().trim().nullable()
            .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
        otherwise: Yup.string().nullable(),
    }

    let formValidationSchemaStep4:any = {
        name: Yup.string()
            .trim()
            .nullable()
            .required(t('requiredMmessage.fieldRequired')),
        payment: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        bankname: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bank_acocunt: Yup.string().trim().nullable()
            .matches(regexNotPattern, { message: t('requiredMmessage.Thevaluemustbeanumber') })
            .required(t('requiredMmessage.fieldRequired')),
        bank_branchCode: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldBankRequired')),
        bank_agency: Yup.string().trim().nullable()
            .matches(regexNotPattern, { message: t('requiredMmessage.Thevaluemustbeanumber') })
            .required(t("requiredMmessage.fieldRequired")).min(4, t("requiredMmessage.Agencyrequire")),
        bank_accountType: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        name_of: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        cnpjcpf: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        address: Yup.string().trim().nullable()
            .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
        otherwise: Yup.string().nullable(),
    }

    let formValidationSchemaStep5:any = {
        name: Yup.string()
            .trim()
            .nullable()
            .required(t('requiredMmessage.fieldRequired')),
        payment: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        /* password: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(passwordRules, { message: t("requiredMmessage.password") }),
        */
        address: Yup.string().trim().nullable()
            .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
        otherwise: Yup.string().nullable(),
    }
    
    if (hide || hide2 || hide3) {
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),

            }
            formValidationSchemaStep1 = { ...formValidationSchemaStep1,...emailVaild}
        }
        let ownerValid = Yup.object().shape(formValidationSchemaStep1)
        frm = { resolver: yupResolver(ownerValid) }

        if (headingText == "Update supplier" || headingText == "Atualizar fornecedor") {
            if(isEmail){
                let emailVaild = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),

                }
                formValidationSchemaStep0 = { ...formValidationSchemaStep0,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep0)
            frm = { resolver: yupResolver(ownerValid) }
        }
        if (hide2) {
            if(isEmail){
                let emailVaild = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),

                }
                formValidationSchemaStep2 = { ...formValidationSchemaStep2,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep2)
            frm = { resolver: yupResolver(ownerValid) }
        }

        if (hide2 && (editowner || headingText == "New Owner" || headingText == "Novo Proprietário")) {
            if(isEmail){
                let emailVaild:any = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),

                }
                formValidationSchemaStep4 = { ...formValidationSchemaStep4,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep4)
            frm = { resolver: yupResolver(ownerValid) }
        }
        if (hide2 && (bankNotRequeire || !pixTypeHide)) {
            
            if(isEmail){
                let emailVaild = {
                    email: Yup.string().trim().nullable()
                    .required(t('requiredMmessage.fieldRequired'))
                    .email(t('requiredMmessage.fieldNotValid')),

                }
                if(isNewEmail!=isOldEmail){
                    let passwordValid = {
                        password: Yup.string().trim().nullable()
                        .required(t('requiredMmessage.fieldRequired'))
                        .matches(passwordRules, { message: t("requiredMmessage.password") }),
                    }
                    emailVaild = {...emailVaild,...passwordValid }
                }
                formValidationSchemaStep5 = { ...formValidationSchemaStep5,...emailVaild}
            }
            let ownerValid = Yup.object().shape(formValidationSchemaStep5)
            frm = { resolver: yupResolver(ownerValid) }
        }
    }
    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);
    let state1 = {
        owner: "",
        person: "",
        status: "",
        start: 1,
        end: 20
    }
    const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hide2) {
            setShow3(true)
            step1();
        } else if (hide3) {
            step2();
        } else {
            step1();
        }
    }
    const updateOwner = (owner) => {
        const cat = localStorage.getItem("Suppliers");
        // let ownerData = { owner: "", start: 0, end: 20, page: 'ownersandbenef' }
        let ownerData = { owner: "", start: 0, end: 20, status: "", person: "", page: 'ownersandbenef' }
        if (cat !== "Suppliers") {
            setStatus(true);
            ownerData["ownerId"] = iddata;
            customerService.getAllOwner(ownerData).then(res => {
                setStatus(false);
                if (res.data.resCode === 201) {
                    setData(res.data.data);
                    setSendMailData(res.data.data)
                    setTotal(res.data.totalRow)
                } else {
                    setData([]);
                    setTotal(0)
                }
            }).catch(err => {
                setStatus(false);
            });
        } else {
            let inspectorData = { supplier: "", start: 0, end: 20, activeContract: "All", personStatus: "" };
            customerService.getAllSuppliers(inspectorData).then(res => {
                if (res.data.resCode === 201) {
                    setData(res.data.data);
                }
            })
        }
    }
    useEffect(() => {
        dispatch(registerData());
        if (show) {
            updateOwner(owner);
        }
    }, [owner, show])

    /*-------Call the api & save the Customer and Employee data--------*/
    const [isThisAgency, setIsThisAgency] = useState(false);
    const formSubmit = (data: CustomersAndEmployeesForm) => {
        setloader_on(true);

        let bankName = '';
        let bankcode = '';
        banklist?.forEach((curElem: any, index: number) => {
            if (curElem.bank_name == data.bankname) {
                bankName = curElem.bank_name;
                bankcode = curElem.bank_code
            }
        })
        let bloquendo: any = convertToNumber(valueCon ? String(valueCon) : String(data.bloquendo));
        let owner = {
            id: data.id,
            type: headingText,
            cnpj: data.cnpj,
            name: data.name,
            corporateReason: data.corporateReason,
            stateRegistration: data.stateRegistration,
            municipalRegistration: data.municipalRegistration,
            payment: data.payment,
            mobile: data.mobile,
            telephone: data.telephone,
            email: data.email,
            password: data.password ? data.password : '',
            mother: data.mother,
            father: data.father,
            rg: data.rg,
            issue_agency: data.issue_agency,
            shipping_date: data.shipping_date,
            sex: data.sex,
            birth: data.birth,
            marital_status: data.marital_status,
            nationality: data.nationality,
            zipcode: data.zipcode,
            address: data.address,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            employment_company: data.employment_company,
            employment_profession: data.employment_profession,
            employment_field_activity: data.employment_field_activity,
            employment_monthly_income: data.employment_monthly_income,
            employment_zipcode: data.employment_zipcode,
            employment_address: data.employment_address,
            employment_number: data.employment_number,
            employment_complement: data.employment_complement,
            employment_neighborhood: data.employment_neighborhood,
            employment_city: data.employment_city,
            employment_state: data.employment_state,
            representative_name: data.representative_name,
            retention: data.retention,
            observation: data.observation,
            is_vendor: data.is_vendor,
            bank_Name: bankName,
            bankname: bankcode,
            bank_acocunt: `${data.bank_acocunt}-${data.bankAccountDigit ? data.bankAccountDigit : "0"}`,
            bank_agency: data.bank_agency,
            bank_accountType: data.bank_accountType,
            bank_branchCode: data.bank_branchCode,
            name_of: data.name_of,
            cnpjcpf: data.cnpjcpf,
            identifier: data.identifier,
            Organ_exp: data.Organ_exp,
            inss: data.inss,
            rpa: data.rpa,
            category: data.category,
            accounting_system: data.accounting_system,
            sup_KeyType: data.pixKeyType,
            Keyfor_addressing: data.pixKey,
            country: data.country,
            tax: data.Tax,
            aliquot: data.Aliquot,
            supplier_id: data.Supplier_id,
            favorecidoId: data.favorecidoId,
            spouse_name: data.spouse_name,
            spouse_cpf: data.spouse_cpf,
            spouse_rg: data.spouse_rg,
            spouse_issue_agency: data.spouse_issue_agency,
            spouse_nationality: data.spouse_nationality,
            spouse_sex: data.spouse_sex,
            spouse_profession: data.spouse_profession,
            spouse_cellphone: data.spouse_cellphone,
            spouse_telephone: data.spouse_telephone,
            spouse_email: data.spouse_email,
            spouse_observation: data.spouse_observation,
            isThisAgency: isThisAgency,
            ID_SACADO_SAC: data.ID_SACADO_SAC,
            ID_FAVORECIDO_FAV: data.ID_FAVORECIDO_FAV,
            pessoaId: data.pessoaId,
            new_added: {
                addState: addState,
                Renda: {
                    FL_SEMRENDA_PES: data.possiu ?? "",
                    FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                    ST_BENEFICIO_PES: data.beneficio ?? "",
                    VL_OUTROSRENDIMENTOS_PES: data.rendimentous ?? ""
                },
                Retencao_de_impostos: {
                    FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
                },
                Mais_opcoes: {
                    NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                    FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                    FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
                },
                Fornecedor: {
                    FL_SINCRONIZARSACADO_FAV: data.supplieradv ? data.supplieradv : ""
                },
                Pagemento: {
                    DT_DIAREPASSE_PES: data.Repassedia ?? "",
                    VL_SALDOBLOQUEADO_PES: bloquendo ?? ""
                }
            }
        }
        if (data.id !== "" && data.id !== undefined) {
            customerService.UpdateSingleOwner(owner).then(res => {
                if (res.data.resCode === 200) {
                    integrateOwner(data.id).catch(err => {
                      console.log("Falha ao integrar proprietário com pagamentos split", err)
                    })
                    setIsInputFocused(false);
                    formClose();
                    dispatch(propertyOpen(true));
                    let message:any = t("Sweetalert.successget");
                    getOwner(data.id)
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: message,
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                    setloader_on(false);
                } 
                else if(res.data.resCode === 520){
                    setloader_on(false);
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.successgetSsoError"),
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                }
                else {
                    let msg = '';
                    msg = res.data.data[0].message ? res.data.data[0].message : ((res.data?.data && res.data.data.length) || res.data.data) ? res.data.data[0].message ? res.data.data[0].message : res.data.message : '';
                    if (res.data.resCode == 409) {
                        msg = t("Sweetalert.unqueEmails");
                    }
                    if (res.data.resCode === 400 && res.data.message == 'Owneremail!=AgencyEmail') {
                        msg = t("ownerEmailnotEqualAgencyEmail");
                    }
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: msg ? msg : t("Sweetalert.somethingWent"),
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                }
                setloader_on(false);
            }).catch(error => {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    confirmButtonText: "OK",
                    timer: 3000
                });
                setloader_on(false);
            });
        } else {
            customerService.createOwner(owner).then(res => {
                if (res.data.resCode === 202 || res.data.resCode == 202) {
                    const ownerId = res.data.data.length > 1 ? res.data.data[1].insertedId : res.data.data[0].insertedId
                    integrateOwner(ownerId).catch(err => {
                      console.log("Falha ao integrar proprietário com pagamentos split", err)
                    })
                    setIsInputFocused(false);
                    let ownerData = {
                        owner: "",
                        person: "",
                        status: "",
                        start: 0,
                        end: 20
                    }
                    setTimeout(() => {
                        customerService.getAllOwner(ownerData)
                    }, 2000)
                    formClose();
                    let message:any = t("Sweetalert.successget");
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: message,
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                    setloader_on(false);
                }
                else if(res.data.resCode === 520 || res.data.resCode == 520){
                    setloader_on(false);
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.successgetSsoError"),
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                } 
                else {
                    let msg = res.data.data[0].message ? res.data.data[0].message : ((res.data?.data && res.data.data.length) || res.data.data) ? res.data.data : '';
                    if (res.data.resCode == 409) {
                        msg = res.data.message;
                    }
                    if (res.data.resCode === 400 && res.data.message == 'Owneremail!=AgencyEmail') {
                        msg = t("ownerEmailnotEqualAgencyEmail");
                    }
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: msg ? msg : t("Sweetalert.somethingWent"),
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                    setloader_on(false);
                }
            }).catch(error => {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    confirmButtonText: "OK",
                    timer: 3000
                });
                setloader_on(false);
            });
        }
    }

    const getBankCode = (bankCode) => {
        if (bankCode) {
            setValue("bank_branchCode", bankCode);
            clearErrors("bankname")
        } else {
            setValue("bank_branchCode", "");
        }

    }

    useEffect(() => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (zipvalue.zip == "" && zipvalue.empzip == "") {
            zipCodeCheck?.["removeAttribute"]('disabled');
        } else {
            zipCodeCheck?.["setAttribute"]('disabled', "")
        }
    }, [zipvalue])

    useEffect(() => {
        // getAllOwner(null);
        if (show) {
            (async () => {
                let resp = await customerService.getAddressBasedOnZipcode(`states`);
                if (resp.data.status == "success" || resp.data.type == "Success") {
                    let loop = resp.data.data;
                    let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                    // Sort the stateData alphabetically by label
                    stateData.sort((a, b) => a.label.localeCompare(b.label));
                    setAllStates(stateData);
                }
            })()
            dispatch(add_Bank())
        }
    }, [dispatch, show])

    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }
    useEffect(() => {
        if (show) {
            let lang = i18n.language
            if (lang == "pt") {
                setHeadingText("Novo Proprietário")
            }
            let val = getValues('payment')
            if (val == "3" || val == "4" || val == "2") {
                setbankNotRequeire(false)
            } else {
                setbankNotRequeire(true)
            }
        }
    }, [show])
    const [nextOpen, setNextOpen] = useState("false");
    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        setspouse(false);
        if (e.target.value !== "") {
            let checkCNJP = CNJP_CPFValidation(e.target.value, type);
            if (checkCNJP?.["status"] || e.target.value == "") {
                setShowHideField(checkCNJP?.["type"])
                clearErrors(name)
                let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
                zipCodeCheck?.["setAttribute"]('disabled', "")
                if (e.target.value !== "") {
                    let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, iddata ? iddata : "");
                    let boolVal = false
                    if (headingText == "New Owner" || headingText == "Novo Proprietário") {
                        boolVal = duplicateData["data"][0]?.FL_PROPRIETARIOBENEFICIARIO_PES == 1
                    } else {
                        if (duplicateData["data"][0]?.ID_PESSOA_PES == iddata) {
                            boolVal = false
                        } else {
                            boolVal = true
                        }
                    }
                    const fieldsMapping: any = {
                        email: "ST_EMAIL_PES",
                        name: "ST_NOME_PES",
                        mobile: "ST_CELULAR_PES",
                        complement: "ST_COMPLEMENTO_PES",
                        zipcode: "ST_CEP_PES",
                        city: "ST_CIDADE_PES",
                        address: "ST_ENDERECO_PES",
                        state: "ST_ESTADO_PES",
                        number: "ST_NUMERO_PES",
                        neighborhood: "ST_BAIRRO_PES",
                        telephone: "ST_TELEFONE_PES",
                        observation: "ST_OBSERVACAO_PES",
                        nationality: "ST_NACIONALIDADE_PES",
                        sex: "ST_SEXO_PES",
                        birth: "DT_NASCIMENTO_PES",
                        marital_status: "ST_ESTADOCIVIL_PES",
                        employment_company: "ST_TRABALHOCEP_PES",
                        employment_profession: "ST_PROFISSAO_PES",
                        employment_field_activity: "ST_RAMOATIVIDADE_PES",
                        employment_address: "ST_TRABALHOENDERECO_PES",
                        employment_number: "ST_TRABALHONUMERO_PES",
                        employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
                        employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
                        employment_city: "ST_TRABALHOCIDADE_PES",
                        employment_state: "ST_TRABALHOESTADO_PES",
                        employment_monthly_income: "VL_RENDAMENSAL_PES",
                        rg: "ST_RG_COJ",
                        retention: "VL_TXISSQN_PES",
                        mother: "ST_NOMEMAE_PES",
                        father: "ST_NOMEPAI_PES",
                        shipping_date: "DT_EXPEDICAORG_PES",
                        payment: "ID_FORMARECEBIMENTO_PES",
                        bankname: "ST_BANCO_PES",
                        bank_agency: "ST_AGENCIABANCO_PES",
                        bank_acocunt: "ST_CONTA_PES",
                        bank_accountType: "ST_TIPOCONTA_PES",
                        bank_branchCode: "ST_OPERACAO_PES",
                        name_of: "ST_NOMERECEBEDOR_PES",
                        cnpjcpf:"ST_CNPJRECEBEDOR_PES",
                        manager: "ID_GESTOR_GES",
                        issue_agency: "ST_ORGAO_PES",
                        corporateReason: "ST_NOME_PES",
                        stateRegistration: "ST_IE_PES",
                        municipalRegistration: "ST_INSCMUNICIPAL_PES",

                        spouse_name: 'ST_NOME_COJ',
                        spouse_cpf: 'ST_CPF_COJ',
                        spouse_rg: 'ST_RG_COJ',
                        spouse_issue_agency: 'ST_ORGAO_COJ',
                        spouse_profession: 'ST_PROFISSAO_COJ',
                        spouse_nationality: 'ST_NACIONALIDADE_COJ',
                        spouse_sex: 'ST_SEXO_COJ',
                        spouse_cellphone: 'ST_CELULAR_COJ',
                        spouse_telephone: 'ST_TELEFONE_COJ',
                        spouse_email: 'ST_EMAIL_COJ',
                        spouse_observation: 'ST_OBSERVACAO_COJ',


                    };
                    if (duplicateData["data"].length > 0) {
                        if (boolVal) {
                            zipCodeCheck?.["setAttribute"]('disabled', "")
                            setcnpjcpferrormessage({
                                name: name,
                                message: t("formLabel.ErrorCNPJCPF")
                            })
                            setError(name, {
                                type: "manual",
                                message: t("formLabel.ErrorCNPJCPF")
                            });
                        } else {
                            zipCodeCheck?.["removeAttribute"]('disabled');

                            // Assuming `duplicateData` is correctly populated
                            const data: any = duplicateData?.["data"]?.[0];

                            if (data) {

                                if (data?.marital_status == 1 || data?.marital_status == 7) {
                                    setspouse(true);
                                }

                                Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                    setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                    setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                                    setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
                                });
                            }

                        }
                    } else {
                        if (headingText == "New Owner" || headingText == "Novo Proprietário") {
                            Object?.entries(fieldsMapping)?.forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as keyof CustomersAndEmployeesForm, "")
                                setValue('ID_FAVORECIDO_FAV', "")
                                setValue('ID_SACADO_SAC', "")
                            });
                        } else {
                            // Assuming `duplicateData` is correctly populated
                            const data: any = duplicateData?.["data"]?.[0];

                            if (data) {
                                Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                    setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                    setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                                    setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
                                });
                            }
                        }
                        zipCodeCheck?.["removeAttribute"]('disabled');
                    }
                }
            } else {
                // errorCNPJCPF(e.target.value)
                setcnpjcpferrormessage({
                    name: name,
                    message: checkCNJP?.["message"]
                })
                setError(name, {
                    type: "manual",
                    message: checkCNJP?.["message"]
                });
            };
        }
    }

    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="employment_zipcode"]');

        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str,
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str
            })
        }

        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("address", data)
                    }
                } else {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("employment_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("employment_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("employment_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("employment_address", data)
                    }
                }

            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("city", "")
                    setValue("neighborhood", "")
                    setValue("state", "")
                    setValue("address", "")
                } else {
                    querySelsecond.style.border = "2px solid red"
                    setValue("employment_city", "")
                    setValue("employment_neighborhood", "")
                    setValue("employment_state", "")
                    setValue("employment_address", "")
                }
            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }
    
    const EditQwner = (id: number) => {
        dispatch(ownerhide(true));
        data?.forEach((item: any) => {
            if (item.id == id) {
                setHideData(true)
                setspouse(false);
                setEditOwner(true)
                setIsOldEmail(item?.email);
                const fields: string[] = ['id', 'cnpj', 'name', 'bank_acocunt', "bank_agency", 'Repassedia', 'payment', 'bank_accountType', 'bank_branchCode', 'name_of', 'cnpjcpf', 'mobile', 'telephone', 'email', 'mother', 'father', 'rg', 'issue_agency', 'shipping_date', 'sex', 'birth', 'marital_status', 'nationality', 'zipcode', "address", 'number', 'complement', 'neighborhood', 'city', 'state', 'employment_company', 'employment_profession', 'employment_field_activity', 'employment_monthly_income', 'employment_zipcode', 'employment_address', 'employment_number', 'employment_complement', 'employment_neighborhood', 'employment_city', 'employment_state', 'retention', 'observation', "password", 'Aliquot', 'Tax', 'category', 'sup_KeyType', 'Keyfor_addressing', 'Organ_exp', 'rg', 'inss', 'rpa', 'manager', 'country', 'accounting_system', 'identifier', 'Supplier_id', 'favorecidoId', 'pixKey', 'pixKeyType', 'corporateReason', 'stateRegistration', 'municipalRegistration', 'pessoaId', 'spouse_name', 'spouse_cpf', 'spouse_rg', 'spouse_issue_agency', 'spouse_profession', 'spouse_nationality', 'spouse_sex', 'spouse_cellphone', 'spouse_telephone', 'spouse_email', 'spouse_observation'];
                fields.forEach((field: any) => {
                    if (item.marital_status == 1 || item.marital_status == 7) {
                        setspouse(true);
                    }
                    if (item.favorecidoId) {
                        if (item.payment == 12) {
                            setPixTypeHide(false);
                        } else
                            if (item.payment == 8 || item.payment == 0) {
                                setPixTypeHide(true)
                            } else {
                                setPixTypeHide(false)
                                setBankHide(true)
                            }
                    } else {
                        if (item.payment == 12) {
                            setPixTypeHide(false);
                        }
                        if (item.payment == 4 || item.payment == 2) {
                            setBankHide(true)
                        }
                        if (item.payment == 3) {
                            setCheckHide(true)
                            setBankHide(false)
                        }
                    }

                    if (field === "bank_acocunt" && item?.["bank_acocunt"]) {
                      const [bankAccount, bankAccountDigit] = item["bank_acocunt"].split("-")
                      setValue("bank_acocunt", bankAccount)
                      setValue("bankAccountDigit", bankAccountDigit)
                      return
                    }

                    setValue(field, item?.[field])
                });

                setState(item.state)
                setValue("bloquendo", item.bloquendo ? converter(String(item.bloquendo)) : "0,00")
                let companyCnpj: any = (companydata[0]["cnpj"]);
                let matchCompany: any = companyCnpj?.replaceAll(".", "")?.replaceAll("/", "")?.replaceAll("-", "");
                let ownerCnpj: any = (item?.["cnpj"]);

                let repenData = item?.repersentive || [];
                if (repenData && repenData.length > 0) {
                    let allRepresentative: any = [];
                    repenData.forEach((item: any, index: number) => {
                        allRepresentative.push({ "name": item.ST_NOME_REJ })
                        let varDta: any = `representative.${index}.name`
                        setValue(varDta, item.ST_NOME_REJ);
                    })
                    addState["representative"] = allRepresentative;
                    setAddState(addState);
                }
                const filteredBanks = banklist.filter(bank => bank.bank_code == item?.bankname);
                setValue('bankname', filteredBanks[0]?.bank_name ?? '')
                let matchOwner: any = ownerCnpj?.replaceAll(".", "")?.replaceAll("/", "")?.replaceAll("-", "");
                let checkCNJP: any
                if (item.cnpj) {
                    checkCNJP = CNJP_CPFValidation(item?.cnpj, "both");
                }
                setShowHideField(checkCNJP?.type);
                if (String(matchCompany) == String(matchOwner)) {
                    document?.querySelector('[name="cnpj"]')?.setAttribute("disabled", "disabled");
                    document?.querySelector('[name="cnpj"]')?.setAttribute("read-only", "read-only");
                    setIsThisAgency(true);
                } else {
                    document?.querySelector('[name="cnpj"]')?.removeAttribute("disabled");
                    document?.querySelector('[name="cnpj"]')?.removeAttribute("read-only");
                    setIsThisAgency(false);
                }
                const emailInputs = document.querySelectorAll('.paymentsDis');
                emailInputs.forEach(element => {
                    element.setAttribute('readonly', 'true');
                    element.setAttribute('disabled', 'true');
                });

                const cat = localStorage.getItem("Suppliers");
                if (cat !== "Suppliers") {
                    setHeadingText(t('customerAndEmployees.updateowner'))
                    setHide5(false)
                } else {
                    setHide5(true)
                    setHide4(true)
                    setIsThisAgency(true);
                    setHeadingText(t('customerAndEmployees.updatesupplier'))
                }
            }
        })
    }
    
    const [spouse, setspouse] = useState(false);
    const handleChangeMaritail = (e) => {
        if (e.target.value == "1" || e.target.value == "7") {
            setspouse(true);
        } else {
            setspouse(false);
        }
    }
    const ownerPaymentHide = (evt) => {

        if (evt.target.value == 12) {
            setPixTypeHide(false);
        } else {
            setPixTypeHide(true);
        }
        if (evt.target.value == 4 || evt.target.value == 2) {
            setbankNotRequeire(false)
            setPixTypeHide(false);
            setBankHide(false)
            clearErrors('bankname')
            clearErrors('bank_acocunt')
            clearErrors('bank_agency')
            clearErrors('bank_accountType')
        } else if (evt.target.value == 3) {
            setPixTypeHide(false);
            setbankNotRequeire(false)
            setCheckHide(true)
            setBankHide(false)
            clearErrors('bankname')
            clearErrors('bank_acocunt')
            clearErrors('bank_agency')
            clearErrors('bank_accountType')
        } else {
            setCheckHide(false)
            setBankHide(false)
            setbankNotRequeire(false)
        }
    }
    const handleNameof = (e) => {
        let { value } = e.target
        setValue("name_of", value)
        // Error validation
        if (value == "" || value == null || value == undefined) {
            // Set an error message using setError or update your error state
            setError("name", {
                type: "manual",
                message: (t('requiredMmessage.fieldRequired'))
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('name');
        }
    }
    useEffect(() => {
        const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
        const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
        setSearchResults(filteredBanks);

        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [banklist]);

    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };

    const HandleBankAccount = (e: any, item) => {
        setValue('bankname', item?.bank_name)
        setValue("bank_branchCode", item.bank_code);
        setIsInputFocused(false);
    }
    const handlefilterBillmethod = async (e) => {

        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = banklist.filter((bank) => {
                // Convert the bank name to lowercase for case-insensitive comparison
                const bankName = bank.bank_name.toLowerCase();
                return bankName.includes(lowerSearchTerm);
            });
            setSearchResults(results)
        } else {
            const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
            const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
            setSearchResults(filteredBanks);
        }
    }


    useEffect(() => {
        if (show) {
            const fetchData = async () => {
                try {
                    const resp = await dispatch(GetRepresentativeService());
                    if (resp.payload.type.toLowerCase() === "success") {
                        setRepreData(resp.payload.data);
                    }
                } catch (error) {
                    console.error("Error fetching representative data:", error);
                }
            };

            fetchData();
        }
    }, [show, dispatch]);
    /*----------Open the new representative modal form------------*/
    const [showNewRepresentativeModal, setNewRepresentativeModal] = useState(false);
    const newRepresentativeModalClose = () => {
        setNewRepresentativeModal(false);
        setShow3(false)
        if (!hide) {
            step2()
        } else {
            step3()
        }
        dispatch(openNewOwner(true))
    }
    const newRepresentativeModalShow = () => {
        setNewRepresentativeModal(true);
        dispatch(openNewOwner(false))
    }

    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });

    const handleChangeInput = (e: any, index: any, type: string, field: string) => {
        let value = e.target.value;
        addState[type][index][field] = value;
        let allState: any = addState;
        setAddState(allState);
    }

    const removeAddAnother = (index: any, array: any, type: any) => {
        array.splice(index, 1);
        // reset(type);
        if (type == "retentionn") {
            array.forEach((item: any, index: number) => {
                let Tax: any = `retentionn.${index}.tax`;
                let Aliquot: any = `retentionn.${index}.Aliquot`;
                setValue(Tax, item.Tax);
                setValue(Aliquot, item.Aliquot);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "beneficiaries") {
            array.forEach((item: any, index: number) => {
                let name: any = `beneficiaries.${index}.name`;
                let transfer: any = `beneficiaries.${index}.transfer`;
                setValue(name, item.name);
                setValue(transfer, item.transfer);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "representative") {
            array.forEach((item: any, index: number) => {
                let name: any = `representative.${index}.name`;
                setValue(name, item.name);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }
    }

    const addAnother = (e: any, type: string) => {
        e.preventDefault();
        if (type == "retentionn") {
            let dataAdd: any = {
                ...addState,
                retentionn: [
                    ...addState["retentionn"],
                    { Tax: "", Aliquot: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "beneficiaries") {
            let dataAdd: any = {
                ...addState,
                beneficiaries: [
                    ...addState["beneficiaries"],
                    { name: "", transfer: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "representative") {
            let dataAdd: any = {
                ...addState,
                representative: [
                    ...addState["representative"],
                    { name: "" }
                ]
            }
            setAddState(dataAdd)
        }
    }
    const Handlebloquendo = (e) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        setValue("bloquendo", formatNumber(value))
        setValueCon(value)
    }
    const handleRepersen = (e: any, index) => {
        let target_name = e.target.name;
        let target_value = e.target.value;
        addState["representative"][`${index}`]["name"] = String(target_value);
        setAddState(addState)

    }
    const Handleaddress = (e) => {
        let { value } = e.target;
        let numberOfCharacters = value.length;
        // Handle the character length validation
        if (numberOfCharacters > 40) {
            setError("address", {
                type: "manual",
                message: t("requiredMmessage.40characters")
            });
        } else {
            clearErrors("address")
        }
    };

    const handleEmail = (e)=>{
        e.preventDefault();
        let vl = e.target.value;
        if(vl && vl!=""){
            setIsEmail(true);
            setIsNewEmail(vl)
        }
        else {
            setIsEmail(false)
            setIsNewEmail("")
        }
    }

    return (
        <>
            <Modal show={show} onHide={formClose} className='CustomersAnddEmployeestsx'>
                <Modal.Header closeButton>
                    <Modal.Title>{ headingText }</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Body>
                        <input type="text" {...register('ID_SACADO_SAC')} hidden />
                        <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden />
                        <input type="text" {...register('pessoaId')} hidden />
                        <div style={{ display: show3 ? "block" : "none" }}>
                            <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                                <input type="hidden" value={headingText} {...register('type')} />
                                <input type="hidden" {...register('favorecidoId')} />
                                <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf') + (headingText === "Atualizar fornecedor" ? "" : " *")}>
                                    <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={` ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                    <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </div>
                        </div>
                        <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                            {showHideField == "cnpj" ?
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.corporateReason")}>
                                            <Form.Control type="text" placeholder="Corporate reason" className={`form-control ${errors.corporateReason ? 'is-invalid' : ''}`} {...register('corporateReason')} />
                                            <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.fantasyName") + " *"}>
                                            <Form.Control type="text" placeholder="Fantasy name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleNameof(e)} />
                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.stateregistration")}>
                                            <Form.Control type="text" placeholder="State registration" className={`form-control ${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.municipalregistration")}>
                                            <Form.Control type="text" placeholder="Municipal registration" className={`form-control ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row> :
                                <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                                    <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            }
                            {hide5 && showHideField == "cpf" ?
                                <>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t("RG")}>
                                                <Form.Control type="text" placeholder={t("RG")} className={`form-control`} {...register('rg')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t("formLabel.Organexp")}>
                                                <Form.Control type="text" placeholder={t("formLabel.Organexp")} className={`form-control `} {...register('Organ_exp')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t("formLabel.INSS")}>
                                                <Form.Control type="text" placeholder={t("formLabel.INSS")} className={`form-control`} {...register('inss')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t("formLabel.RPA")}>
                                                <Form.Control type="text" placeholder="Municipal registration" className={`form-control`} {...register('rpa')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                            <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                <option value="">{t('formLabel.select')}</option>
                                                {managerData?.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Row>
                                </>
                                : ""}
                            <div  >
                                <span className="d-block mb-3 fw-bold">{t('formLabel.payment')}</span>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.defaultPaymentMethod') + " *"}>
                                    <Form.Select className={`${errors.payment ? 'is-invalid' : ''}`}  {...register('payment')} aria-label={t('formLabel.defaultPaymentMethod')} onChange={(e) => ownerPaymentHide(e)}>
                                        <option value="">{t('formLabel.select')}</option>
                                        {hide5 ?
                                            <>
                                                <option value="0">{t('customerAndEmployees.splitpaymentKenloPay')}</option>
                                                <option value="1" > {t('customerAndEmployees.Cheque')}</option>
                                                <option value="2" >{t('customerAndEmployees.money')}</option>
                                                <option value="3" >{t('customerAndEmployees.creditcard')}</option>
                                                <option value="4" >{t('customerAndEmployees.DebitCard')}</option>
                                                <option value="7" >{t('reportBanking.AutomaticDebit')}</option>
                                                <option value="8" >{t('customerAndEmployees.Transferbanking')}</option>
                                                <option value="9" >{t('reportBanking.DOC/TED')}</option>
                                                <option value="10" >{t('customerAndEmployees.others')}</option>
                                                <option value="12" >{t("customerAndEmployees.pix")}</option>
                                            </>
                                            :
                                            <>
                                                <option value="0">{t('customerAndEmployees.splitpaymentKenloPay')}</option>
                                                <option value="12">{t("customerAndEmployees.pix")}</option>
                                                <option value="5">{t('customerAndEmployees.bankTransfer')}</option>
                                                <option value="6">{t('customerAndEmployees.docTed')}</option>
                                                <option value="2">{t('customerAndEmployees.cashDeposit')}</option>
                                                <option value="1">{t('customerAndEmployees.chequeDeposit')}</option>
                                                <option value="3">{t('customerAndEmployees.pickUpCheque')}</option>
                                                <option value="4">{t('customerAndEmployees.withdrawMoney')}</option></>
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.payment?.message}</Form.Control.Feedback>
                                </FloatingLabel>

                                <Row>
                                    <Col>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('Repassedia')}>
                                            <Form.Control type="tel" placeholder={t('Repassedia')}   {...register('Repassedia')} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('bloquendo')}>
                                            <Form.Control type="text" placeholder={t('bloquendo')}   {...register('bloquendo')} onChange={(e) => Handlebloquendo(e)} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                {!bankhide ?
                                    <div>
                                        {!pixTypeHide ?
                                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">

                                                <Col md={6}>
                                                    <FloatingLabel controlId="pixKeyType" label={t("banks.pixkeyType")}>
                                                        <Form.Select className={` ${errors.pixKeyType ? 'is-invalid' : ''}`} {...register('pixKeyType')} aria-label={t("banks.pixkeyType")}>
                                                            <option value="">{t('dropdownItem.select')}</option>
                                                            <option value="1">CPF</option>
                                                            <option value="2">CNPJ</option>
                                                            <option value="3">Celular</option>
                                                            <option value="4">E-mail</option>
                                                            <option value="5">Aleatoria</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{errors.pixKeyType?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                {checkhide ?

                                                    <>
                                                        <Col md={6}>
                                                            <FloatingLabel controlId="name_of" label={t("customerAndEmployees.Inthenameof")}>
                                                                <Form.Control type="text" placeholder={t("customerAndEmployees.Inthenameof")} className={` ${errors.name_of ? 'is-invalid' : ''}`}  {...register('name_of')} />
                                                            </FloatingLabel>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FloatingLabel controlId="cnpjcpf" label={t("formLabel.cnpjCpf")}>
                                                                <Form.Control type="text" placeholder={t("formLabel.cnpjCpf")} className={` ${errors.cnpjcpf ? 'is-invalid' : ''}`}  {...register('cnpjcpf')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </>

                                                    :
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="pixKey" label={t("banks.pixKey")}>
                                                            <Form.Control type="text" placeholder={t('customerAndEmployees.PixKey')} className={` ${errors.pixKey ? 'is-invalid' : ''}`}  {...register('pixKey')} />
                                                            <Form.Control.Feedback type="invalid">{errors.pixKey?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                }
                                            </Row>
                                            : ""}

                                        {/* Bank Form Here */}
                                        {pixTypeHide ?
                                            <>
                                                <Form.Floating className="mb-4" ref={inputRef}>
                                                    <FormControl
                                                        type="text"
                                                        placeholder="Type to search..."
                                                        {...register('bankname')}
                                                        onChange={(e) => handlefilterBillmethod(e)}
                                                        onFocus={handleInputFocus}
                                                        className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"
                                                        autoComplete="off"
                                                    />
                                                    {isInputFocused && (
                                                        <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}>
                                                            {searchResults?.slice(0, 10).reverse().map((item: any, index) => (
                                                                <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleBankAccount(e, item)}>
                                                                    {item.bank_code} - {item.bank_name}
                                                                </ListGroup.Item>
                                                            ))}
                                                        </ListGroup>

                                                    )}
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.name") + " *"}</Form.Label >
                                                    <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback >
                                                </Form.Floating>
                                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                                    <Col md={6}>
                                                      <Form.Floating>
                                                        <Form.Control
                                                          className={`${errors.bank_acocunt ? 'is-invalid' : ''}`}
                                                          id="account"
                                                          placeholder="Conta"
                                                          {...register("bank_acocunt")}
                                                          onChange={(e: any) => {
                                                            const newValue = e.target.value;
                                                            setValue("bank_acocunt", newValue.replace(/\D/, ""))
                                                          }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors.bank_acocunt?.message}</Form.Control.Feedback>
                                                        <Form.Label htmlFor="floatingInputValue">Conta *</Form.Label>
                                                      </Form.Floating>
                                                    </Col>
                                                    <Col md={3}>
                                                      <Form.Floating>
                                                        <Form.Control
                                                          id="bankAccountDigit"
                                                          placeholder="Dígito"
                                                          maxLength={1}
                                                          {...register("bankAccountDigit")}
                                                        />
                                                        <Form.Label htmlFor="floatingInputValue">Dígito</Form.Label>
                                                      </Form.Floating>
                                                    </Col>
                                                    <Col md={3}>
                                                      <Form.Floating>
                                                        <Form.Control
                                                          className={`${errors.bank_agency ? 'is-invalid' : ''}`}
                                                          id="bank_agency"
                                                          placeholder="Agência"
                                                          {...register("bank_agency")}
                                                          maxLength={4}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors.bank_agency?.message}</Form.Control.Feedback>
                                                        <Form.Label htmlFor="floatingInputValue">Agência *</Form.Label>
                                                      </Form.Floating>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="floatingSelect" label={t('banks.accountType') + " *"}>
                                                            <Form.Select className={`${errors.bank_accountType ? 'is-invalid' : ''}`}  {...register('bank_accountType')} aria-label="Account Type">
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                <option value="0">{t('currentAccount')}</option>
                                                                <option value="1">{t('SavingAccount')}</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">{errors.bank_accountType?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="bank_branchCode" label={t("customerAndEmployees.BranchCode") + " *"}>
                                                            <Form.Control disabled type="text" placeholder={t("customerAndEmployees.BranchCode")} className={` ${errors.bank_branchCode ? 'is-invalid' : ''}`}  {...register('bank_branchCode')} />
                                                            <Form.Control.Feedback type="invalid">{errors.bank_branchCode?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="name_of" label={t("customerAndEmployees.Inthenameof") + " *"}>
                                                            <Form.Control type="text" placeholder={t("customerAndEmployees.Inthenameof")} className={` ${errors.name_of ? 'is-invalid' : ''}`}  {...register('name_of')} />
                                                            <Form.Control.Feedback type="invalid">{errors.name_of?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>

                                                    <Col md={6}>
                                                        <FloatingLabel controlId="cnpjcpf" label={t("formLabel.cnpjCpf") + " *"}>
                                                            <Form.Control type="text" placeholder={t("formLabel.cnpjCpf")} className={` ${errors.cnpjcpf ? 'is-invalid' : ''}`}  {...register('cnpjcpf')} />
                                                            <Form.Control.Feedback type="invalid">{errors.cnpjcpf?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>

                                                </Row>
                                            </>
                                            : ""}
                                    </div>
                                    : ""}
                            </div>

                            <span className="d-block mb-3 fw-bold">{t('formLabel.contact')}</span>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber') + " *"}>
                                        <Form.Control type="tel" placeholder={t('formLabel.mobileNumber')} className={` ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                        <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                        <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                <Form.Control type="email" placeholder={t('formLabel.email')} className={`${errors.email ? 'is-invalid' : ''}`}  {...register('email')} onKeyUp={(e) => handleEmail(e)}/>
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            {editowner ?
                                <>
                                    <FloatingLabel controlId="floatingInput" label={(headingText == 'New Tenant' || headingText == 'Novo Locatário') ? t('formLabel.mobilePassword') : t('formLabel.password')} className="mb-3" >
                                        <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`} {...register('password')} />
                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </>
                                :
                                <>
                                    <FloatingLabel controlId="floatingInput" label={(headingText == 'New Tenant' || headingText == 'Novo Locatário') ? t('formLabel.mobilePassword') : t('formLabel.password')} className="mb-3" >
                                        <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />
                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </>
                            }

                            <Accordion className="accordion-custom">
                                <Accordion className="accordion-custom">
                                    <Accordion.Item eventKey="15">
                                        <Accordion.Header>{t('Beneficiaries')}</Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                Object.keys(addState.beneficiaries).map((item: any, index: number) => {
                                                    return (
                                                        <>
                                                            <Row>
                                                                <Col md={5}>
                                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={t('formLabel.name')}
                                                                            {...register(`beneficary.${index}.name`)}
                                                                            onChange={(e) => handleChangeInput(e, index, "beneficiaries", "name")}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Col>
                                                                <Col md={5}>
                                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('transfer')}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={t('transfer')}
                                                                            {...register(`beneficary.${index}.transfer`)}
                                                                            onChange={(e) => handleChangeInput(e, index, "beneficiaries", "transfer")}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Col>
                                                                {
                                                                    Object.keys(addState.beneficiaries) &&
                                                                    Object.keys(addState.beneficiaries).length > 1 &&
                                                                    <Col md={2}>
                                                                        <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.beneficiaries, "beneficiaries")}>
                                                                            <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </>
                                                    )
                                                })
                                            }
                                            <Button variant="link" className="fw-bold p-0" onClick={(e) => addAnother(e, "beneficiaries")}  >
                                                {t("newContractDev.AddAnother")}
                                            </Button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                {(showHideField !== "cnpj" || (headingText === "Update supplier" || headingText === "Atualizar fornecedor")) && (
                                    <Accordion.Item eventKey="1" style={{ display: hide4 ? 'none' : 'block' }}>
                                        <Accordion.Header>{t('formLabel.affiliation')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.motherName')}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={t('formLabel.motherName')}
                                                            {...register('mother')}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fatherName')}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={t('formLabel.fatherName')}
                                                            {...register('father')}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}

                                {showHideField == "cpf" ?
                                    <Accordion.Item eventKey="2" style={{ display: hide5 ? 'none' : 'block' }}>
                                        <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                        <Form.Control type="text" placeholder={t('RG')}  {...register('rg')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('issue_agency')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                                        <Form.Control type="date" placeholder={t('formLabel.shippingDate')}  {...register('shipping_date')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                        <Form.Select {...register('sex')} aria-label={t('formLabel.sex')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t('masculine')}</option>
                                                            <option value="2">{t('feminine')}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.birth')}>
                                                        <Form.Control type="date" placeholder={t('formLabel.birth')}  {...register('birth')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                                        <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} onChange={(e) => handleChangeMaritail(e)}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t("formLabel.married")}</option>
                                                            <option value="2">{t("formLabel.single")}</option>
                                                            <option value="3">{t("formLabel.widower")}</option>
                                                            <option value="4">{t("formLabel.legallySeparated")}</option>
                                                            <option value="5">{t("formLabel.disquitado")}</option>
                                                            <option value="6">{t("formLabel.divorced")}</option>
                                                            <option value="7">{t("formLabel.stableunion")}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel className="mb-3" controlId="Birthplace" label={t('formLabel.Birthplace')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.birth')}  {...register('birth_place')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('nationality')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('dependentsNo')}>
                                                        <Form.Control type="text" placeholder={t('dependentsNo')}  {...register('dependentsNo')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} className='mt-1'>
                                                    <Accordion.Body>
                                                        <Form.Check type="checkbox" {...register('non_recedential')} label={t('non_recedential')} />
                                                    </Accordion.Body>
                                                </Col>
                                                <Col md={12} className='mt-1'>
                                                    <Form.Check type="checkbox" {...register('donotnotify')} label={t('donotnotify')} />
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                        <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                            <option value="">{t('formLabel.select')}</option>
                                                            {managerData?.map((item) => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    : ""}

                                {
                                    spouse &&
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>{t('formLabel.spouse')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('spouse_name')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.cpf')}  {...register('spouse_cpf')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                        <Form.Control type="text" placeholder={t('RG')}  {...register('spouse_rg')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('spouse_issue_agency')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.profession')}  {...register('spouse_profession')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                        <Form.Select {...register('spouse_sex')} aria-label={t('formLabel.sex')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t('masculine')}</option>
                                                            <option value="2">{t('feminine')}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cellPhone')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.cellPhone')}  {...register('spouse_cellphone')} onChange={HandleInputMob} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('spouse_telephone')} />

                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                        <Form.Control type="email" placeholder={t('formLabel.email')}  {...register('spouse_email')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('spouse_nationality')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.observation')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.observation')}  {...register('spouse_observation')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>{t('formLabel.address')} </Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                            <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "first")} />
                                        </FloatingLabel>
                                        <Row>
                                            {(headingText !== "Update supplier" && headingText !== "Atualizar fornecedor") ?
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                                                        >
                                                            <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} className={`${errors.address ? 'is-invalid' : ''}`} onChange={(e) => Handleaddress(e)} />
                                                        </OverlayTrigger>
                                                        <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                :
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} />
                                                    </FloatingLabel>
                                                </Col>
                                            }
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('number')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('complement')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                                        <option value="">{t('formLabel.select')}</option>
                                                        {
                                                            AllStates.map((item, index) => {
                                                                if(state == item?.["label"]){
                                                                    return (
                                                                        <option key={index} value={item?.["label"]} selected>{item?.["label"]}</option>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                    )
                                                                }                                                                
                                                            })
                                                        }
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13" style={{ display: hide5 ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.Retention')} </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="contractlistother" label={t('contractlistother.Tax')} >
                                                    <Form.Control type="text" placeholder={t('contractlistother.Tax')}   {...register('Tax')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="Aliquot" label={t('contractsettings.Aliquot')} >
                                                    <Form.Control type="text" placeholder={t('contractsettings.Aliquot')}   {...register('Aliquot')} />
                                                </FloatingLabel>
                                            </Col>

                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {showHideField == "cpf" &&
                                    <Accordion.Item eventKey="4" >
                                        <Accordion.Header>{t('formLabel.employmentData')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.company')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.company')}  {...register('employment_company')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.profession')} {...register('employment_profession')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fieldOfActivity')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.fieldOfActivity')}  {...register('employment_field_activity')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')}  {...register('employment_monthly_income')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "second")} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                        <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                        <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            {
                                                                AllStates.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                <Accordion.Item eventKey="5" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.representative')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {/* <Col xs={12}>
                                                <InputGroup>
                                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                                        <Form.Select {...register('representative_name')} onClick={handleRepresentative}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            {repreData?.map((curElem: any, index) => (
                                                                <option key={index} value={curElem.id}>{curElem.name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <InputGroup.Text onClick={newRepresentativeModalShow}>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col> */}

                                            {
                                                Object.keys(addState.representative).map((item: any, index: number) => {
                                                    return (
                                                        <Row className="mt-1">
                                                            <Col xs={11}>
                                                                <InputGroup>
                                                                    <FloatingLabel controlId="floatingSelect11111" label={t('formLabel.name')}>
                                                                        <Form.Select {...register(`representative.${index}.name`)} onChange={(e) => handleRepersen(e, index)}>
                                                                            <option value="">{t('formLabel.select')}</option>
                                                                            {repreData?.map((curElem: any, index) => (
                                                                                <option key={index} value={curElem.id}>{curElem.name}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                    <InputGroup.Text onClick={newRepresentativeModalShow}>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            </Col>
                                                            {
                                                                Object.keys(addState.representative) &&
                                                                Object.keys(addState.representative).length > 1 &&
                                                                <Col md={1}>
                                                                    <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.representative, "representative")}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                                </Col>
                                                            }
                                                            {/* <Col>
                                                            <span className="input-group-text">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} className="h-20" />
                                                            </span>
                                                        </Col> */}
                                                        </Row>
                                                    )
                                                })
                                            }
                                            < Col >
                                                <div className="text-center mt-1">
                                                    <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother(e, "representative")}>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.AddAnother')}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    showHideField == "cpf" &&
                                    <Accordion.Item eventKey="14">
                                        <Accordion.Header>{t('Renda')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col xs={12}>
                                                    <Accordion.Body>
                                                        <Form.Check type="checkbox" {...register('possiu')} label={t('possiu')} />
                                                    </Accordion.Body>
                                                </Col>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <FloatingLabel controlId="floatingSelect" label={t('vinculo')}>
                                                            <Form.Select {...register('vinculo')}>
                                                                <option value="">{t('formLabel.select')}</option>
                                                                {/* {repreData?.map((curElem: any, index) => (
                                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                                ))} */}
                                                                <option value={1}>select 1</option>
                                                                <option value={2}>select 2</option>
                                                                <option value={3}>select 3</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('beneficio')}>
                                                        <Form.Control type="text" placeholder={t('beneficio')}  {...register('beneficio')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('rendimentous')}>
                                                        <Form.Control type="text" placeholder={t('rendimentous')}  {...register('rendimentous')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                }

                                <Accordion.Item eventKey="6" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.taxesRetention')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Check type="checkbox" {...register('retention')} label={t('formLabel.withHoldIssqn')} />
                                        {
                                            showHideField == "cpf" &&
                                            <Form.Check type="checkbox"  {...register('enableSimplifiedDeduction')} label={t('newContractDev.enableSimplifiedDeduction')} className='mt-1' />
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7" style={{ display: hide5 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>{t('formLabel.Accountingintegration')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.Accountingintegration')}>
                                            <Form.Control type="text" placeholder={t('formLabel.Accountingintegration')} {...register('accounting_system')} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8" style={{ display: hide5 ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.identifier')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.identifier')}>
                                            <Form.Control type="text" placeholder={t('formLabel.identifier')} {...register('identifier')} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12" style={{ display: hide5 ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.category')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col xs={10}>
                                                <FloatingLabel className="mb-3" controlId="category" label={t('formLabel.category')}>
                                                    <Form.Select className={`form-control mb-4`}  {...register('category')} >
                                                        {category?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                            <div className="text-center">
                                <h3 className="mt-3 mb-2">{t("label.registeredSurveyor")}</h3>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={formClose}>
                                {t('button.close')}
                            </Button>
                            {hide && show3 ? '' : <Button variant="outline-primary" onClick={(e) => handlePrevious(e)} className="js-btn-step">
                                {t('button.previous')}
                            </Button>}

                            {!hide3 ?
                                <Button variant="primary" onClick={handleSubmit(hide ? step2 : step3)} className="min-w-auto js-btn-step zipCodeCheck btn-primary-focus-none">
                                    {t('button.next')}
                                </Button>
                                :
                                loader_on ?
                                    <Button className="btn-width">
                                        <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                    </Button>
                                    :
                                    <Button variant="primary" type="submit" onClick={openErrorbox} className="btn-primary-focus-none">
                                        {t('button.save')}
                                    </Button>
                            }
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <RepresentativeService showNewRepresentative={showNewRepresentativeModal} newRepresentativeClose={newRepresentativeModalClose} newRepresentativeShow={newRepresentativeModalShow} />
        </>
    )

}

export default NewOwner
