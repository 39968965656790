import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { newExpenseOpen, newRentalFeeOpen, newServiceClose, getAllService, getAllSuppliers } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import { getAccountCategory } from "../../../../redux/slice/financialDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { newServiceForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { getAllServiceCode } from "../../../../redux/slice/expenditureDataSlice";
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { insertService } from '../../../../redux/slice/ServiceSlice';
import { convertToNumber, formatNumber } from '../../../constants/utils';
const ServiceService = (newServiceForm) => {
  const { newServiceShow, suppliersData, serviceType, status } = useSelector((state: RootState) => state.contractData)
  const { serviceCodeData, serviceCodeStatus } = useSelector((state: RootState) => state.expenditureData)
  const { accountCatData } = useSelector((state: RootState) => state.financialData)
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const [hide, setHide] = useState(true);
    const [percentValues, setPercentValues] = useState({})
  useEffect(() => {
    if (newServiceShow) {
      dispatch(getAllSuppliers());
      dispatch(getAccountCategory());
      dispatch(getAllServiceCode({ start: 0, end: 100 }))
    }
  }, [dispatch])

  const serviceClose = () => {
    if (serviceType === "rentalFee") {
      dispatch(newRentalFeeOpen(true))
    }
    else {
      dispatch(newExpenseOpen(true));
    }
    dispatch(newServiceClose(false));
    setHide(true);
    reset();
  }

  const creditSuppliers = (val) => {
    setValue('supplier', '');
    if (val == 4) {
      setHide(false);
    }
    else {
      setHide(true);
    }
  }
  /*------------Form validation-----------*/
  let first = {
    name: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    accountCategory: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
   
  }
  let second = {}
  if (!hide) {
    second = {
      supplier: Yup.string().trim().nullable()
        .required(t('requiredMmessage.fieldRequired')),
    }
  }
  let third = { ...first, ...second }
  const serviceValidation = Yup.object().shape(third);
  let frm = {
    resolver: yupResolver(serviceValidation)
  }

  /*------------Submit rental fee form data-----------*/

  const handlePercentage = (e: any, key: any) => {
    let { value } = e.target;
    value = value.replace(/[^\d,]/g, '');

    if (value !== "") {
      const [intg, dec] = value.split(',')
      value = convertToNumber(intg)?.toString() + (dec !== undefined ? `,${dec}` : '')
      if (convertToNumber(value) > 100) value = '100,00'

      setValue(key, formatNumber(value))
      setPercentValues({ ...percentValues, [key]: value })
    } else {
      setValue(key, "")
      setPercentValues({ ...percentValues, [key]: '' })
    }
  }

  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<newServiceForm>(frm);
  const onSubmit = async (data: newServiceForm, e: any) => {
    e.preventDefault();
    setDouble(true)
   let  newobj = {
      "service_id": "",
      "name": data?.name,
      "account_category": data?.accountCategory,
      "service_code_sku": data?.serviceCode,
      "debit": data?.debit,
      "credit": data?.credit,
      "pay_incom_task": Boolean(data?.isPayIncomeTax),
      "admin_service": Boolean(data?.isCalAdminServ),
      "admn_deduct_service": Boolean(data?.isCalAdminDed),
      "issqn_collection_id": data?.issqnCollection,
      "service_code_tax_id": data?.serviceCodeTax,
      "percent_of_tax": data?.percentageTaxes,
      "not_taxable": Boolean(data?.isNotTaxable),
      "invoice_dimob": data?.incomeInvoice,
      "is_disabled": Boolean(false),
      "supplier": data?.supplier
    }

    Object.entries(percentValues).forEach(([key, value]) => {
      newobj[key] = value === '' ? null : convertToNumber(value)
    })

    let response = await dispatch(insertService(newobj));
    if ( response?.["payload"]?.["data"]?.["resCode"]===201 && (response?.["payload"]?.["data"]?.["type"] === "success" || response?.["payload"]?.["data"]?.["type"] === "Success")) {
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      dispatch(getAllService());
      serviceClose();
    }
    else if(response?.["payload"]?.["data"]?.["resCode"]===421){
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: response?.["payload"]?.["data"]?.["message"],
        // icon:"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
    else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }
  return (
    <>
      {/* <!-- New service start from here --> */}
      <Modal show={newServiceShow} onHide={serviceClose}>
        <Form onSubmit={handleSubmit(onSubmit)} id="serviceservicetsx">
          <Modal.Header closeButton>
            <Modal.Title>{t("services.newService")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
              <Col>
                <FloatingLabel controlId="floatingInput" label={t("formLabel.name")}>
                  <Form.Control type="text" placeholder={t("formLabel.name")} className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                  <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
              <Col>
                <FloatingLabel controlId="floatingSelect" label={t("formLabel.accountCategory")}>
                  <Form.Select className={`form-control ${errors.accountCategory ? 'is-invalid' : ''}`}  {...register('accountCategory')} aria-label="Account (category)">
                    <option value="">{t('dropdownItem.select')}</option>
                    {
                      accountCatData && accountCatData.length > 0 && accountCatData.map((item, index) => {
                        if ((item.childData).length > 0) {
                          return (
                            <optgroup key={index} label={`${item.ST_CONTA_CONT} ${item.ST_DESCRICAO_CONT}`}>
                              {
                                item.childData && (item.childData).length > 0 &&
                                item.childData.map((firstItem, index) => {
                                  if ((firstItem.childData).length > 0) {
                                    return (
                                      <React.Fragment key={index}>
                                        <option value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                        {
                                          (firstItem.childData).map((seconditem, index) => {
                                            if ((seconditem.childData).length > 0) {
                                              return (
                                                <React.Fragment key={index}>
                                                  <option value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                                  {
                                                    (seconditem.childData).map((thirditem, index) => {
                                                      if ((thirditem.childData).length > 0) {
                                                        (thirditem.childData).map((fourthitem, index) => {
                                                          return (
                                                            <React.Fragment key={index}>
                                                              <option value={fourthitem.ST_CONTA_CONT}>{fourthitem.ST_CONTA_CONT} {fourthitem.ST_DESCRICAO_CONT}</option>
                                                            </React.Fragment>
                                                          )
                                                        })
                                                      } else {
                                                        return (
                                                          <React.Fragment key={index}>
                                                            <option key={index} value={thirditem.ST_CONTA_CONT}>{thirditem.ST_CONTA_CONT} {thirditem.ST_DESCRICAO_CONT}</option>
                                                          </React.Fragment>
                                                        )
                                                      }
                                                    })
                                                  }
                                                </React.Fragment>
                                              )
                                            } else {
                                              return (
                                                <option key={index} value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                              )
                                            }
                                          })
                                        }
                                      </React.Fragment >
                                    )
                                  } else {
                                    return (
                                      <option key={index} value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                    )
                                  }
                                })
                              }
                            </optgroup>
                          )
                        }
                      })
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.accountCategory?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingInput" label={t("formLabel.serviceCode")}>
                  <Form.Control type="text" placeholder={t("formLabel.serviceCode")} className={`form-control ${errors.serviceCode ? 'is-invalid' : ''}`} {...register('serviceCode')} />
                  <Form.Control.Feedback type="invalid">{errors.serviceCode?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Accordion className="accordion-custom">
              <Accordion.Item eventKey="1">
                <Accordion.Header>{t("button.more")}</Accordion.Header>
                <Accordion.Body>
                  <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                    <Col md={hide ? 6 : 4}>
                      <FloatingLabel controlId="floatingSelect" label={t("formLabel.debit")}>
                        <Form.Select className={`form-control ${errors.debit ? 'is-invalid' : ''}`}  {...register('debit')} aria-label="Debit">
                          <option value="">{t("formLabel.select")}</option>
                          <option value="1">{t("services.owner")}</option>
                          <option value="2">{t("services.tenant")}</option>
                          <option value="3">{t("services.Imobiliária")}</option>
                          <option value="4">{t("services.Imóvel")}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.debit?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md={hide ? 6 : 4}>
                      <FloatingLabel controlId="floatingSelect" label={t("formLabel.credit")}>
                        <Form.Select className={`form-control ${errors.credit ? 'is-invalid' : ''}`}  {...register('credit')} onChange={(e) => creditSuppliers(e.target.value)} aria-label="Credit">
                          <option value="">{t('dropdownItem.select')}</option>
                          <option value="1">{t('notifications.owner')}</option>
                          <option value="2">{t('notifications.tenant')}</option>
                          <option value="3">{t('realEstate')}</option>
                          <option value="4">{t('services.third')}</option>
                          <option value="5">{t('immobile')}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.credit?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md={hide ? 6 : 4} style={{ display: hide ? 'none' : 'block' }}>
                      <FloatingLabel controlId="floatingSelect" label={t("formLabel.Supplier")}>
                        <Form.Select className={`form-control ${errors.supplier ? 'is-invalid' : ''}`} {...register('supplier')} aria-label="Supplier">
                          <option value="">{t('dropdownItem.select')}..</option>
                          {suppliersData && suppliersData.length > 0 && suppliersData.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}</option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.supplier?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Check type="checkbox" {...register('isPayIncomeTax')} label={t("services.payIncomeTax")} />
                  </Form.Group>
                  <br />
                  <Form.Group>
                    {/* <Form.Check type="checkbox" {...register('isTerminationCal')} label="Include expense in the termination calculation" /> */}
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>{t("formLabel.transfer")}</Accordion.Header>
                <Accordion.Body>
                  <Form.Group>
                    <Form.Check type="checkbox" {...register('isCalAdminServ')} label={t("services.calculateAdministration")} />
                  </Form.Group>
                  <br />
                  <Form.Group>
                    <Form.Check type="checkbox" {...register('isCalAdminDed')} label={t("services.calculateAdministrationFeeBeducting")} />
                  </Form.Group>
                  <br />
                  <Form.Group>
                    <Form.Check type="checkbox" {...register('isGuaranteed')} label={t("Guaranteeproductincontracts")} />
                  </Form.Group>
                  <br />

                  <Row className="gy-3 gy-lg-4">
                    <Col md={6} className={"supplier_head"}>
                      <FloatingLabel className="mb-lg-4 mb-3" controlId="name" label={t('fees')}>
                        <Form.Control type="text" placeholder={t('fees')} className={`${errors.percent_fee ? 'is-invalid' : ''}`}  {...register('percent_fee')} onChange={e => handlePercentage(e, 'percent_fee')} />
                        <Form.Control.Feedback type="invalid">{errors.percent_fee?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
  
                    <Col md={6} className={"supplier_head"}>
                      <FloatingLabel className="mb-lg-4 mb-3" controlId="name" label={t('trafficTicket')}>
                        <Form.Control type="text" placeholder={t('trafficTicket')} className={`${errors.percent_fine ? 'is-invalid' : ''}`}  {...register('percent_fine')} onChange={e => handlePercentage(e, 'percent_fine')} />
                        <Form.Control.Feedback type="invalid">{errors.percent_fine?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>{t("formLabel.taxation")}</Accordion.Header>
                <Accordion.Body>
                  <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                    <Col md={12}>

                      <FloatingLabel controlId="floatingInput" label={t('formLabel.ISSQNCollection')}>
                        <Form.Select className={`form-control ${errors.issqnCollection ? 'is-invalid' : ''}`} {...register('issqnCollection')} aria-label="ISSQN collection">
                        <option value="">{t("formLabel.select")}</option>
                        <option value="1">{t("services.alwaysInMunicipality")}</option>
                        <option value="2">{t("services.outsideTheMunicipality")}</option>
                        <option value="3">{t("services.free")}</option>
                        <option value="4">{t("services.immune")}</option>
                        <option value="5">{t("services.suspendedCourtDecision")}</option>
                        <option value="6">{t("services.suspendedDueAdministrative")}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.issqnCollection?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingSelect" label={t("formLabel.serviceCodeTax")}>
                        <Form.Select className={`form-control ${errors.serviceCodeTax ? 'is-invalid' : ''}`} {...register('serviceCodeTax')} aria-label="Service code (Tax)">
                          <option value="">{t('dropdownItem.select')}</option>
                          {
                            serviceCodeData.data && serviceCodeData.data.length > 0 ? serviceCodeData.data.map((item, index) => (
                              <option key={index} value={Number(item.id)}>{item.code} -- {item.name}</option>
                            ))
                              :
                              ''
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.serviceCodeTax?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                      <span className="mt-1 fs-12">{t("services.otherCNAES")}</span>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingInput" label={t("formLabel.percentageOfTaxes")}>
                        <Form.Control type="text" className={`form-control ${errors.percentageTaxes ? 'is-invalid' : ''}`} placeholder={t("formLabel.percentageOfTaxes")} {...register('percentageTaxes')} />
                        <Form.Control.Feedback type="invalid">{errors.percentageTaxes?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Check type="checkbox" {...register('isNotTaxable')} label={t("formLabel.notTaxable")}  />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>{t("formLabel.incomeInvoice")}</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <FloatingLabel controlId="floatingSelect" label={t("formLabel.invoiceanddimob")}>
                        <Form.Select className={`form-control ${errors.incomeInvoice ? 'is-invalid' : ''}`} {...register('incomeInvoice')} aria-label="Income, Invoice, Dimob">
                          <option value="">{t('dropdownItem.select')}</option>
                          <option value="0">{t("services.notInclude")}</option>
                          <option value="2">{t("services.includeAsIncome")}</option>
                          <option value="3">{t("services.issueAnInvoice")}</option>
                          <option value="4">{t("services.includeAsWithholding")}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.incomeInvoice?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-between">
            <div className="flex-shrink-0 ms-2">
              <Button className="me-2 me-lg-3" variant="outline-primary" onClick={serviceClose}>{t("button.close")}</Button>
              <Button disabled={double} type="submit" variant="primary" onClick={openErrorbox}>{t("button.toSave")}</Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* /New service modal end */}
    </>
  )
}
export default ServiceService;