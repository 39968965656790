import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Button, FloatingLabel, Accordion, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import { getOwner, getProperty, getTenants } from "../../../../redux/slice/contractDataSlice";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getBankList } from "../../../../redux/slice/bankSlice"
import eventBus from "./EventBus";
import { incomeService } from '../../../../services/incomeServices';
import { fetchService } from '../../../../redux/slice/ServiceSlice';
import { gettoken } from '../../../../Api/Contact';
import Swal from 'sweetalert2';
import { openErrorbox } from '../../Contracts/Common/actionPerform';
import moment from "moment-timezone";
import { converter, convertToNumber, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import { addBillingTicketCron } from '../../../../redux/slice/contractDataSlice';
import { chargeForm } from "../../../Interface";
import i18n from '../../../../i18n';
import { getAllAccounts } from '../../../../redux/slice/expenditureDataSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useBankAccounts } from '../../../Profile/Company/FormsOfReceipt/BankAccounts/hooks/useBankAccounts';
import { get_bank_slip, get_forms_of_receipts } from '../../../../redux/slice/formsOfReceiptSlice';
import { createHomologateBankSlip } from '../../../../newComponents/features/Billing/services/createHomologateBankSlip';

const ChargeService = (props, chargeForm) => {
    const { id } = useParams();
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    //New charge modal close open function
    const [ double,setDouble ] = useState(false);
    const [ loader_on,setloader_on ] = useState(false);

    const { show, onClose, getChargesSummery } = props;
    const { billingData, accountData } = useSelector((state: RootState) => state.expenditureData)
    const [saveanother, setSaveAnother] = useState(false);
    const { userbanklist } = useSelector((state: RootState) => state.bank)
    let SERVICELIST = useSelector((state: RootState) => state.service)
    const [singleChargedata, setSingleChargeData] = useState<any>([]);
    const [singleChargeproduct, setSingleChargeProduct] = useState<any>([]);
    const [chargeAction, setChargeAction] = useState("new");
    const [chargeSingle, setChargeSingle] = useState("")
    const [productinsertcount, setProductInsertCount] = useState(1);
    const [GetClient, setGetClient] = useState<any>([]);
    const [serviceList, setServiceList] = useState<any>([]);
    const [ContractId, setContractId] = useState()
    const [disabled, setDisabled] = useState(false);
    const [startDate, setStartDate] = useState<any>('')
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const [firstRun, setFirstRun] = useState(false);
    const [extraAndDiscount, setExtraAndDiscount] = useState<any>({});
    const [AddressError, setAddressError] = useState(false);
    const [isDiscountPercentage, setisDiscountPercentage] = useState<any>(false);
    const [isDiscountError, setisDiscountError] = useState<any>('');
    const [bankAccount, setBankAccount] = useState<any>([])
    const { tenantsData } = useSelector((state: RootState) => state.contractData);
    const { Bankslip, formdata } = useSelector((state: RootState) => state.forms);

    useEffect(() => {
        dispatch(get_bank_slip());
        dispatch(getTenants());
        dispatch(get_forms_of_receipts())
    }, [dispatch])

    const navigate = useNavigate();

    // New Charge Form 
    const form_validationSchema = Yup.object().shape({
        ID_SACADO_SAC: Yup.string().trim().required(t("requiredMmessage.fieldRequired")),
        DT_VENCIMENTO_RECB: Yup.date()
            .required(t("requiredMmessage.fieldRequired")),
        ID_CONTA_CB: Yup.string().trim().required(t("requiredMmessage.fieldRequired")),
        ID_FORMAPAGAMENTO_RECB: Yup.string().trim().required(t("requiredMmessage.fieldRequired")),
        vl_emitido_recb: Yup.string().trim().required(t("requiredMmessage.fieldRequired")),
        products: Yup.array().of(
            Yup.object().shape({
                ID_PRODUTO_PRD: Yup.string()
                    .required(t('requiredMmessage.fieldRequired')),
                VL_UNITARIO_PRD: Yup.string()
                    .required(t('requiredMmessage.fieldRequired')),
                ST_VALOR_COMP: Yup.string()
                    .required(t('requiredMmessage.fieldRequired'))
            })
        )
    });
    let frm = { resolver: yupResolver(form_validationSchema) }
    const { register, handleSubmit, setValue, getValues, reset, setError, clearErrors, formState: { errors } } = useForm<chargeForm>(frm);

    let obj = { start: "0", end: 10000, all: 'all' }
    useEffect(() => {
        (async () => {
            let response = await incomeService.getDiscountData(gettoken.userId);
            setExtraAndDiscount(response.data);
        })()
    }, [])
    useEffect(() => {
        dispatch(getProperty({ "start": 0, "end": 1000 }));
        dispatch(getOwner());
        dispatch(fetchService());
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
        eventBus.on("chargeIdis", (id) => {
            if (id != "") {
                incomeService.getEditCharge(id).then(res => {
                    if (res.type == "success" || res.type == "Success") {
                        let respData = res.data
                        setContractId(respData.data["ID_CONTRATO_CON"])
                        setSingleChargeData(respData);
                        setSingleChargeProduct(respData.products)
                        setProductInsertCount((respData.products.length > 0 ? respData.products.length : 1))
                        setFirstRun(true);
                    } else {
                        setSingleChargeData([]);
                        setSingleChargeProduct([])
                    }
                }).catch(err => {
                    setSingleChargeData([]);
                    setSingleChargeProduct([])
                });
                setFirstRun(true);
            } else {
                setFirstRun(false);
                setSingleChargeData([]);
                setSingleChargeProduct([])
            }
        });
    }, [dispatch])

    useEffect(() => {
        (async () => {
            setServiceList(SERVICELIST.servicelist);
        })()
    }, [dispatch, SERVICELIST, serviceList])

    useEffect(() => {
        setBankAccount(accountData?.data)
    }, [accountData?.data,])
    useEffect(() => {
        reset();
        (async () => {
            let GetClientData = await incomeService.getClients();
            setGetClient(GetClientData.data.clients);
        })()
        setTimeout(() => {
            let chargeAction: any = document.querySelector("body")?.getAttribute("new_charge_action");
            let chargeSingle: any = document.querySelector("body")?.getAttribute("single");
            setChargeAction(chargeAction);
            setChargeSingle(chargeSingle)
            if (chargeAction == "edit") {
                setDisabled(true);
            }
            if (chargeAction == "new") {
                setDisabled(false);
            }
        }, 0)
        setValue("VL_TXMULTA_RECB", Number(extraAndDiscount["fine_rate"]));
        setValue("VL_TXJUROS_RECB", Number(extraAndDiscount["monthly_interest"]));
        setValue("VL_TXDESCONTO_RECB", Number(extraAndDiscount["discount_rate"]));
        if (singleChargedata["data"] && singleChargedata["data"]) {
            if (firstRun) {
                let dat = singleChargedata["data"]?.DT_VENCIMENTO_RECB.split("T")[0]
                let dateis = new Date(`${dat}`);
                let yyyy: any = dateis.getFullYear();
                let mm: any = dateis.getMonth() + 1;
                let dd: any = dateis.getDate();
                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;
                let totalcnt = 0;
                singleChargedata["products"].forEach(item => {
                    totalcnt += item["ST_VALOR_COMP"];
                });
                let formattedToday = yyyy + "-" + mm + "-" + dd;
                // VL_TOTAL_RECB
                let unvalue = Number(singleChargedata["data"]?.vl_emitido_recb) / Number(singleChargedata["data"]?.NM_QUANTIDADE_COMP);
                let discount = String(Number(singleChargedata["data"].VL_TXDESCONTO_RECB)) !== "NaN" ? Number(singleChargedata["data"].VL_TXDESCONTO_RECB) : Number(extraAndDiscount["discount_rate"]);
                let fee = String(Number(singleChargedata["data"].VL_TXJUROS_RECB)) !== "NaN" ? Number(singleChargedata["data"].VL_TXJUROS_RECB) : Number(extraAndDiscount["monthly_interest"]);
                let trafic_ticket = String(Number(singleChargedata["data"].VL_TXMULTA_RECB)) !== "NaN" ? Number(singleChargedata["data"].VL_TXMULTA_RECB) : Number(extraAndDiscount["fine_rate"]);
                let FormValue = {
                    "ID_RECEBIMENTO_RECB": singleChargedata["data"].ID_RECEBIMENTO_RECB,
                    "ID_CONTRATO_CON": singleChargedata["data"].ID_CONTRATO_CON,
                    "ID_IMOVEL_IMO": singleChargedata["data"].ID_IMOVEL_IMO,
                    "ID_FILIAL_FIL": singleChargedata["data"].ID_FILIAL_FIL,

                    "ID_SACADO_SAC": singleChargedata["data"].ID_SACADO_SAC, //client id
                    "DT_VENCIMENTO_RECB": formattedToday,
                    "products": "",
                    "vl_emitido_recb": totalcnt > 0 ? converter(String(totalcnt)) : 0,
                    "ID_CONTA_CB": singleChargedata["data"].ID_CONTA_CB,
                    "ID_FORMAPAGAMENTO_RECB": singleChargedata["data"].ID_FORMAPAGAMENTO_RECB,
                    "ST_OBSERVACAOINTERNA_RECB": singleChargedata["data"].ST_OBSERVACAOINTERNA_RECB ?? "",
                    "ST_OBSERVACAOEXTERNA_RECB": singleChargedata["data"].ST_OBSERVACAOEXTERNA_RECB ?? "",
                    "VL_TXMULTA_RECB": trafic_ticket,
                    "VL_TXJUROS_RECB": fee,
                    "VL_TXDESCONTO_RECB": discount,
                }
                let fields: any = Object.keys(FormValue);
                fields.forEach(item => setValue(item, FormValue[item]))
                if (singleChargeproduct && singleChargeproduct.length > 0) {
                    singleChargeproduct.forEach((item, index) => {
                        setValue(`products.${index}.ID_COMPOSICAO_COMP`, item.ID_COMPOSICAO_COMP ?? "");
                        setValue(`products.${index}.ID_PRODUTO_PRD`, item.ID_PRODUTO_PRD);
                        setValue(`products.${index}.ST_COMPLEMENTO_COMP`, item.ST_COMPLEMENTO_COMP ?? "");
                        setValue(`products.${index}.NM_QUANTIDADE_COMP`, item.NM_QUANTIDADE_COMP ? item.NM_QUANTIDADE_COMP : 1);
                        setValue(`products.${index}.VL_UNITARIO_PRD`, chargeSingle && item.NM_QUANTIDADE_COMP ? converter(String(item.ST_VALOR_COMP / item.NM_QUANTIDADE_COMP)) : converter(String(item.ST_VALOR_COMP)));
                        setValue(`products.${index}.ST_VALOR_COMP`, converter(String(item.ST_VALOR_COMP)));
                    })
                }
                setFirstRun(false);
            }
            if (singleChargedata["data"].ID_FORMAPAGAMENTO_RECB == 0 || singleChargedata["data"].ID_FORMAPAGAMENTO_RECB == 12 || singleChargedata["data"].ID_FORMAPAGAMENTO_RECB == 28) {
                const filtered = accountData?.data?.filter(item => item.accountType == 0);
                setBankAccount(filtered)
            } else {
                const filtered = accountData?.data?.filter(item => item.accountType == 2);
                setBankAccount(filtered)
            }
        }

    }, [singleChargedata, extraAndDiscount])

    let oldVal = "";
    const handleCalculate = (e: any, type: any, index: any) => {
        let amt: number = 0;
        let uniamt: number = 0;
        if (type == "unitaryValue") {
            let { value } = e.target;
            if (value.includes("-")) {
                const regex = /^[-]?\d*([.,]\d*)*$/;
                const minusCount = (value.match(/-/g) || []).length;
                if (regex.test(value) && minusCount == 1) {
                    value = value.replace(/[^\d,.-]/g, '');
                    let splitminux = value.split("-");
                    const cleanedNumber = (splitminux[1]).replace(/\./g, '');
                    let format = '-' + formatNumber(cleanedNumber);
                    setValue(`products.${index}.VL_UNITARIO_PRD`, format);
                }
            } else {
                value = value.replace(/[^\d,]/g, '');
                setValue(`products.${index}.VL_UNITARIO_PRD`, formatNumber(value));
            }
        }
        if (type === "amount") {
            let amt2: number = convertToNumber(getValues(`products.${index}.VL_UNITARIO_PRD`));
            amt = Number(e.target.value);
            uniamt = Number(amt2 ? amt2 !== null ? amt2 : 0 : 0);
        } else {
            amt = Number(getValues(`products.${index}.NM_QUANTIDADE_COMP`) ? getValues(`products.${index}.NM_QUANTIDADE_COMP`) !== "" ? getValues(`products.${index}.NM_QUANTIDADE_COMP`) : 0 : 0);
            let amt2: number = convertToNumber(getValues(`products.${index}.VL_UNITARIO_PRD`));
            uniamt = Number(amt2);
        }
        let Totalamunt: number = (amt ? amt : 0) * (uniamt ? uniamt : 0);
        setValue(`products.${index}.ST_VALOR_COMP`, converter(Totalamunt));
        totalCount();
    }


    const handleCheckUncheck = (e, type) => {
        if (type == "rate_change") {
            let getCurrentCheck = e.target.checked;

            if (getCurrentCheck) {
                e.target.parentElement.parentElement.querySelectorAll(".extradiscount input").forEach(item => {
                    item.removeAttribute('disabled')
                })
            } else {
                e.target.parentElement.parentElement.querySelectorAll(".extradiscount input").forEach(item => {
                    item.setAttribute('disabled', "")
                })
            }
        } else {
            let getCurrentCheck = e.target.checked;
            setSaveAnother(getCurrentCheck);
        }
    }

    const formSubmit = (data: any) => {
        if ((data.vl_emitido_recb).includes("-")) {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: i18n.language == "en" ? `The charge value (${data.vl_emitido_recb}) is negative. Please ensure it is positive` :
                    `O valor da cobrança (${data.vl_emitido_recb}) é negativo. Por favor, certifique-se de que seja positivo`,
                confirmButtonText: "OK",
            })
        } else {
            for (const key in data.products) {
                if (data.products[key].hasOwnProperty('VL_UNITARIO_PRD') && data.products[key].hasOwnProperty('ST_VALOR_COMP')) {
                    data.products[key].VL_UNITARIO_PRD = convertToNumber(data.products[key].VL_UNITARIO_PRD);
                    data.products[key].ST_VALOR_COMP = convertToNumber(data.products[key].ST_VALOR_COMP);
                }
            }
            let dateis = new Date(dateBrazil);
            let yyyy: any = dateis.getFullYear();
            let mm: any = dateis.getMonth() + 1;
            let dd: any = dateis.getDate();
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let formattedToday = yyyy + "-" + mm + "-" + dd;
            data["ST_MESANO_COMP"] = formattedToday;
            let actIon;
            let prod = data.products;
            let vl_emitido_recb1: any = `${data.vl_emitido_recb}`;
            let vl_emitido_recb: any = convertToNumber(vl_emitido_recb1);
            data["vl_emitido_recb"] = vl_emitido_recb
            if (extraAndDiscount && Object.keys(extraAndDiscount).length > 0 && extraAndDiscount.use_percentage == true) {
                // setting in forms of receipt.
                data['isFixedDiscount'] = true;
            }
            if (prod[0].ID_PRODUTO_PRD == "" || prod[0].VL_UNITARIO_PRD == "" || prod[0].ST_VALOR_COMP == "" || prod[0].NM_QUANTIDADE_COMP == "") {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("ReceiveandReceived.Productsorservices") + ' ' + t("requiredMmessage.fieldRequired"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
            } else {
                setDouble(true);
                setloader_on(true);
                data.DT_VENCIMENTO_RECB = moment(data.DT_VENCIMENTO_RECB).format("YYYY-MM-DD");
                if (chargeSingle == "single" || !chargeSingle) {
                    actIon = incomeService.addCharge(data)
                } else {
                    //actIon = incomeService.updateSingleCharge(data)
                    actIon = incomeService.updatePaymentStatus(data)
                }
                actIon.then(async (res) => {
                  if (res.type == "success" || res.type == "Success") {
                    if (data.ID_FORMAPAGAMENTO_RECB === "28") {
                      try {
                        const homologatedBankAccount = bankAccounts.find(
                          (bankAccount) =>
                            bankAccount.id.toString() === data.ID_CONTA_CB
                        );

                        const homologatedAccount =
                          homologatedBankAccount?.homologatedAccount;

                        if (!homologatedAccount) {
                          Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            confirmButtonText: "OK",
                          });

                          return;
                        }

                        const sacadoInfos: any = tenantsData.find(
                          (tenant: any) =>
                            tenant.tenantId.toString() === data.ID_SACADO_SAC
                        );

                        if (!sacadoInfos) {
                          Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            confirmButtonText: "OK",
                          });

                          return;
                        }

                        const getSacadoAddress = () => {
                          return {
                            numero: sacadoInfos.number ?? "",
                            bairro:
                              sacadoInfos.neighborhood?.replace(
                                /[^a-zA-Z0-9\s]/g,
                                ""
                              ) ?? "",
                            cep:
                              sacadoInfos.zipcode?.replace(
                                /[^a-zA-Z0-9\s]/g,
                                ""
                              ) ?? "",
                            cidade: sacadoInfos.city ?? "",
                            complemento:
                              sacadoInfos.complement?.replace(
                                /[^a-zA-Z0-9\s]/g,
                                ""
                              ) ?? "",
                            endereco:
                              sacadoInfos.address?.replace(
                                /[^a-zA-Z0-9\s]/g,
                                ""
                              ) ?? "",
                            pais: "Brasil",
                            uf: sacadoInfos.state ?? "",
                          };
                        };

                        const {
                          numero,
                          bairro,
                          cep,
                          cidade,
                          complemento,
                          endereco,
                          pais,
                          uf,
                        } = getSacadoAddress();

                        const { Billing_Instructions, messageticket } =
                          Bankslip[0];

                        const dataEmissao = new Date();
                        dataEmissao.setDate(dataEmissao.getDate() - 1);

                        const dataCustomizada = (
                          dataString: string,
                          dias: number = 1
                        ) => {
                          const [dia, mes, ano] = dataString
                            .split("/")
                            .map(Number);
                          const data = new Date(ano, mes - 1, dia);

                          data.setDate(data.getDate() + dias);

                          const novoDia = String(data.getDate()).padStart(
                            2,
                            "0"
                          );
                          const novoMes = String(data.getMonth() + 1).padStart(
                            2,
                            "0"
                          );
                          const novoAno = data.getFullYear();

                          return `${novoDia}/${novoMes}/${novoAno}`;
                        };

                        const dataDeAplicacaoDoDesconto = (
                          dataString: string
                        ) => {
                          if (formdata.give_discount === 0) return dataString;

                          return dataCustomizada(
                            dataString,
                            formdata.give_discount
                          );
                        };

                        await createHomologateBankSlip({
                          CedenteContaNumero: homologatedAccount.conta,
                          CedenteContaNumeroDV: homologatedAccount.conta_dv,
                          CedenteConvenioNumero:
                            homologatedAccount.convenios?.at(0)
                              ?.numero_convenio ?? "",
                          CedenteContaCodigoBanco:
                            homologatedAccount.codigo_banco,
                          SacadoCPFCNPJ:
                            sacadoInfos.cnpj?.replace(/[.\-/]/g, "") ?? "",
                          SacadoEmail: sacadoInfos.email,
                          SacadoEnderecoNumero: numero,
                          SacadoEnderecoBairro: bairro,
                          SacadoEnderecoCEP: cep,
                          SacadoEnderecoCidade: cidade,
                          SacadoEnderecoComplemento: complemento,
                          SacadoEnderecoLogradouro: endereco,
                          SacadoEnderecoPais: pais,
                          SacadoEnderecoUF: uf,
                          SacadoNome: sacadoInfos.name,
                          SacadoTelefone: sacadoInfos.telephone
                            ? sacadoInfos.telephone.replace(/[\s(),-]/g, "")
                            : sacadoInfos.mobile.replace(/[\s(),-]/g, ""),
                          SacadoCelular: sacadoInfos.mobile.replace(
                            /[\s(),-]/g,
                            ""
                          ),
                          TituloDataEmissao:
                            dataEmissao.toLocaleDateString("pt-BR"),
                          TituloDataVencimento: data.DT_VENCIMENTO_RECB.split(
                            "-"
                          )
                            .reverse()
                            .join("/"),
                          TituloMensagem01: Billing_Instructions || "-",
                          TituloNossoNumero:
                            res.data.ID_RECEBIMENTO_RECB.toString(),
                          TituloNumeroDocumento:
                            res.data.ID_RECEBIMENTO_RECB.toString(),
                          TituloValor: Number(data.vl_emitido_recb)
                            .toFixed(2)
                            .replace(".", ","),
                          TituloLocalPagamento: "-",
                          IdRecebimento: res.data.ID_RECEBIMENTO_RECB,
                          ...(Number(data.VL_TXDESCONTO_RECB) > 0 && {
                            TituloCodDesconto: formdata.use_percentage ? 2 : 1,
                            TituloDataDesconto: dataDeAplicacaoDoDesconto(
                              data.DT_VENCIMENTO_RECB.split("-")
                                .reverse()
                                .join("/")
                            ),
                            TituloValorDescontoTaxa: Number(
                              data.VL_TXDESCONTO_RECB
                            )
                              .toFixed(2)
                              .replace(".", ","),
                            TituloValorDesconto: Number(data.VL_TXDESCONTO_RECB)
                              .toFixed(2)
                              .replace(".", ","),
                          }),
                          ...(Number(data.VL_TXMULTA_RECB) > 0 && {
                            TituloCodigoMulta: 2,
                            TituloDataMulta: dataCustomizada(
                              data.DT_VENCIMENTO_RECB.split("-")
                                .reverse()
                                .join("/")
                            ),
                            TituloValorMultaTaxa: Number(data.VL_TXMULTA_RECB)
                              .toFixed(2)
                              .replace(".", ","),
                          }),
                          ...(Number(data.VL_TXJUROS_RECB) > 0 && {
                            TituloCodigoJuros: 1,
                            TituloDataJuros: dataCustomizada(
                              data.DT_VENCIMENTO_RECB.split("-")
                                .reverse()
                                .join("/")
                            ),
                            TituloValorJuros: (
                              Number(data.VL_TXJUROS_RECB) / 30
                            )
                              .toFixed(2)
                              .replace(".", ","),
                          }),
                          ...(homologatedBankAccount.titulo_variacao_carteira && {
                            TituloVariacaoCarteira:
                              homologatedBankAccount.titulo_variacao_carteira,
                          }),
                          ...(homologatedBankAccount.titulo_doc_especie && {
                            TituloDocEspecie:
                              homologatedBankAccount.titulo_doc_especie,
                          }),
                          ...(homologatedBankAccount.titulo_aceite && {
                            TituloAceite: homologatedBankAccount.titulo_aceite,
                          }),
                          ...(homologatedBankAccount.titulo_codigo_baixa_devolucao && {
                            TituloCodBaixaDevolucao:
                              homologatedBankAccount.titulo_codigo_baixa_devolucao,
                          }),
                          ...(homologatedBankAccount.titulo_prazo_baixa && {
                            TituloPrazoBaixa:
                              homologatedBankAccount.titulo_prazo_baixa,
                          }),
                        });

                        Swal.fire({
                          title: t("Sweetalert.Success"),
                          text: t("Sweetalert.successget"),
                          confirmButtonText: "OK",
                          timer: 2000,
                        });
                      } catch (error: any) {
                        Swal.fire({
                          title: t("Sweetalert.Error"),
                          html: `
                                                <p>Ocorreu um erro ao gerar o boleto homologado. Por favor, acesse a página de cobranças a receber para editar as informações da cobrança e tente novamente.</p>
                                                <br />
                                                <strong>${error.message}</strong>
                                            `,
                          confirmButtonText: "OK",
                          showDenyButton: true,
                          denyButtonText: "Ir para cobranças a receber",
                        }).then((result) => {
                          if (result.isDenied) {
                            navigate("/receivable_charges");
                          }
                        });
                      } finally {
                        onsaveornot();
                        getChargesSummery();
                        dispatch(
                          addBillingTicketCron(res.data.ID_RECEBIMENTO_RECB)
                        );
                        setDisabled(false);
                        setDouble(false);
                        setloader_on(false);
                      }
                    } else {
                      onsaveornot();
                      getChargesSummery();
                      dispatch(
                        addBillingTicketCron(res.data.ID_RECEBIMENTO_RECB)
                      );
                      Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.successget"),
                        confirmButtonText: "OK",
                        timer: 2000,
                      });
                      setDisabled(false);
                      setDouble(false);
                      setloader_on(false);
                    }
                  } else {
                    setDouble(false);
                    setloader_on(false);
                    Swal.fire({
                      title: t("Sweetalert.Error"),
                      text: t("Sweetalert.somethingWent"),
                      confirmButtonText: "OK",
                    });
                  }
                });
            }
        }
    }

    function onsaveornot() {
        if (saveanother) {
            reset();
            setSaveAnother(false);
            setProductInsertCount(1);
        } else {
            onClose();
            setDouble(false);
            setloader_on(false);
        }
    }

    const onCloseModel = () => {
        document.querySelector("body")?.setAttribute("new_charge_action", "");
        reset();
        onClose();
        setDisabled(false)
        setProductInsertCount(1)
        setDouble(false);
        setloader_on(false);
    }

    const handleClear = () => {
        reset({
            "products": [{
                "ID_COMPOSICAO_COMP": "",
                "ID_PRODUTO_PRD": "",
                "ST_COMPLEMENTO_COMP": "",
                "NM_QUANTIDADE_COMP": "",
                "VL_UNITARIO_PRD": "",
                "ST_VALOR_COMP": ""
            }]
        });
        setProductInsertCount(1)
        totalCount();
    }

    let totalCount = () => {
        setTimeout(() => {
            let totalSum = 0;
            [...Array(productinsertcount)].forEach((item, id) => {
                if (getValues(`products.${id}.ST_VALOR_COMP`) !== undefined) {
                    totalSum += convertToNumber(getValues(`products.${id}.ST_VALOR_COMP`));

                }
            })
            setisDiscountError('');
            if (totalSum < extraAndDiscount.discount_rate && extraAndDiscount.use_percentage == true) {
                // If Fixed Discount, charge amount cannot be less htna fixed discount amount.
                setisDiscountError(t("bankTransaction.fixedDiscountError"));
            }

            setValue("vl_emitido_recb", totalSum ? converter(totalSum) : "0,00");
        }, 200)
    }
    useEffect(() => {
        let Val = getValues("vl_emitido_recb")
        if (Val !== "" || Val !== null) {
            clearErrors('vl_emitido_recb')
        }

    }, [singleChargeproduct])
    const ToSave = () => {
        if (!chargeSingle || chargeSingle == "single") {
            setChargeAction("new")
            setChargeSingle("edit")
            setDisabled(false)
        } else {
            Swal.fire({
                title: t("Alert"),
                // html: `${t("Editbilling")} ${ContractId} <a href="contract_details/${ContractId}">${t("enter")}</a>`,
                html: `${t("performThisAction")}`,
                // icon: 'success',"error",
                confirmButtonText: "OK",
            })
        }
    }

    const checkTenantAddress = (evt) => {
        setAddressError(false);
        evt.preventDefault();
        GetClient.forEach(element => {
            if (element.ID_SACADO_SAC == evt.target.value) {
                if (element.ST_ESTADO_SAC == null || element.ST_ENDERECO_SAC == null || element.ST_CIDADE_SAC == null || element.ST_CEP_SAC == null) {
                    setAddressError(true);
                }
            }
        });

    }
    const deleteServiceRow = async (index) => {
        // Logging the updated array to the console
        let arr: any = getValues("products")
        const updateServ = [...arr]
        updateServ.splice(index, 1);
        // setIPInputFields(updateServ);
        setValue("products", updateServ)
        setProductInsertCount(updateServ.length);
        totalCount();
    }
    const hanldMaturity = (e) => {
        let { value } = e.target;
        const account = accountData?.data?.find(acc => acc?.id == value);
        if (account) {
            setStartDate(account.date)
            const accountDate = new Date(account.date);
            // Get the value and convert to Date if it's a string
            const vencimentoRecbValue: any = getValues('DT_VENCIMENTO_RECB');
            const vencimentoDate = new Date(vencimentoRecbValue);
            // Compare the dates
            if (vencimentoDate < accountDate) {
                setError("DT_VENCIMENTO_RECB", {
                    type: "manual",
                    message: `${t("requiredMmessage.maturityDate")}`,
                });
            } else {
                clearErrors('DT_VENCIMENTO_RECB')
            }
        } else {
            setStartDate('')
        }


    }
    const handlDate = (e: any) => {
        const accountDate = new Date(startDate);
        // Get the value and convert to Date if it's a string
        const vencimentoRecbValue: any = e.target.value;
        const vencimentoDate = new Date(vencimentoRecbValue);
        // Compare the dates
        if (vencimentoDate < accountDate) {
            setError("DT_VENCIMENTO_RECB", {
                type: "manual",
                message: `${t("requiredMmessage.maturityDate")}`,
            });
        } else {
            clearErrors('DT_VENCIMENTO_RECB')
        }
    }
    const Hanldbank = (e: any) => {
        let neBks: any = [];
        accountData.data.forEach(element => {
            if ( ( e.target.value == 0 || e.target.value == 12 || e.target.value == 28) && element.isActiveinFormofReceipt == 0 ) {
                if (  element.accountType != 2 && element.accountType != null ) {
                    neBks.push(element);
                }
            } else {
                // CASH BANK
                if ( ( element.accountType == 2 || element.accountType == 13 || element.accountType == 14 ) && element.accountType != null  && element.isActiveinFormofReceipt == 0 ) {
                    neBks.push(element);
                }
            }
        }); 
        setBankAccount(neBks); 

    }
    const AnotherAdd = ({ count }) => {
        return (
            <>
                {
                    [...Array(count)].map((x, index) => (
                        <React.Fragment key={index}>
                            {count > 1 ?
                                <span className='d-flex justify-content-end mb-1'>
                                    <Button variant="link" className="fw-bold p-0" onClick={(e) => deleteServiceRow(index)}>
                                        {t("button.delete")}
                                    </Button>
                                </span>
                                : ""}
                            <span>
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={6} hidden>
                                        <FloatingLabel controlId="ID_COMPOSICAO_COMP" label="ID_COMPOSICAO_COMP">
                                            <Form.Control type="text" placeholder="ID_COMPOSICAO_COMP" {...register(`products.${index}.ID_COMPOSICAO_COMP`)} disabled={disabled} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="products" label={t("formLabel.products") + " *"}>
                                            <Form.Select aria-label="Products" className={`form-control ${errors?.products?.[index]?.ID_PRODUTO_PRD ? 'is-invalid' : ''}`}  {...register(`products.${index}.ID_PRODUTO_PRD`)} disabled={disabled}>
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {serviceList && serviceList.length > 0 &&
                                                    serviceList.map((item, index) => (
                                                        <option key={index} value={item.ID_PRODUTO_PRD}>{item.ST_DESCRICAO_PRD}</option>
                                                    ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors?.products?.[index]?.ID_PRODUTO_PRD?.message}</Form.Control.Feedback >
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="complement" label={t("formLabel.complement")}>
                                            <Form.Control type="text" placeholder={t("formLabel.complement")} {...register(`products.${index}.ST_COMPLEMENTO_COMP`)} disabled={disabled} />
                                        </FloatingLabel>
                                    </Col>
                                    {chargeAction == "new" || chargeSingle == "single" ?
                                        <>
                                            <Col md={6}>
                                                <FloatingLabel controlId="amount" label={t("formLabel.quantity")} >
                                                    <Form.Control type="text" placeholder={t("contractlistother.Amount")} {...register(`products.${index}.NM_QUANTIDADE_COMP`)} onChange={(e) => handleCalculate(e, "amount", index)} defaultValue={"1"} disabled={disabled} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="unitary_value" label={t("ReceiveandReceived.Unitaryvalue") + " *"}>
                                                    <Form.Control type="text" className={`form-control ${errors?.products?.[index]?.VL_UNITARIO_PRD ? 'is-invalid' : ''}`} placeholder={t("ReceiveandReceived.Unitaryvalue")}  {...register(`products.${index}.VL_UNITARIO_PRD`)} onChange={(e) => handleCalculate(e, "unitaryValue", index)} disabled={disabled} />
                                                    <Form.Control.Feedback type="invalid">{errors?.products?.[index]?.VL_UNITARIO_PRD?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="value" label={t("formLabel.value") + " *"}>
                                                    <Form.Control type="text" className={`form-control ${errors?.products?.[index]?.ST_VALOR_COMP ? 'is-invalid' : ''}`} placeholder={t("contractlistother.ValueBooklet")}  {...register(`products.${index}.ST_VALOR_COMP`)} onChange={(e) => handleCalculate(e, "amount", index)} disabled={disabled} />
                                                    <Form.Control.Feedback type="invalid">{errors?.products?.[index]?.ST_VALOR_COMP?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </>
                                        :
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t("formLabel.value")}>
                                                <Form.Control type="text" placeholder={t("formLabel.value")} {...register(`products.${index}.ST_VALOR_COMP`)} onChange={(e) => handleCalculate(e, "value", index)} disabled={disabled} />
                                            </FloatingLabel>
                                        </Col>
                                    }
                                </Row>
                            </span>
                        </React.Fragment>
                    ))
                }
            </>
        )
    } 

    const [selectedBillingMethod, setSelectedBillingMethod] = useState("");
    const [selectedBankAccount, setSelectedBankAccount] = useState("");

    // Watch for changes in the billing method to determine if bank account should default to main_ac
    useEffect(() => {
        if (selectedBillingMethod && bankAccount) {
            const mainAccount = bankAccount.find((curElem) => curElem.main_ac === 1)?.id;
            if (mainAccount) {
                setSelectedBankAccount(mainAccount);
                setValue("ID_CONTA_CB",mainAccount);
            }
        } 
        else {
            let ID_CONTA_CB = getValues("ID_CONTA_CB");
            if(typeof ID_CONTA_CB=="undefined"){
                setSelectedBankAccount(""); // Clear selection if billing method is not selected
                setValue("ID_CONTA_CB","");
            }
            else{
                setSelectedBankAccount(ID_CONTA_CB);
                setValue("ID_CONTA_CB",ID_CONTA_CB);
            }            
        }
    }, [selectedBillingMethod, bankAccount]);


    const handleBillingChange = (e) => {
        setSelectedBillingMethod(e.target.value);
        Hanldbank(e); // Call your handler function
    };

    const handleBankChange = (e) => {
        setSelectedBankAccount(e.target.value);
        hanldMaturity(e); // Call your handler function
    };

    const { bankAccounts } = useBankAccounts();
    const filteredBankAccounts = useMemo(() => {
      if (!bankAccount) return [];

      if (selectedBillingMethod !== "28") return bankAccount;

      return bankAccount.filter((bank_account) =>
        bankAccounts.find(
          (account) => account.id === bank_account.id && account.isHomologated
        )
      );
    }, [bankAccount, bankAccounts, selectedBillingMethod]);

    return (
        <>
            <Modal show={show} onHide={onCloseModel}>
                <Form onSubmit={handleSubmit(formSubmit)} className='template-chargetsxservice'>
                    <Modal.Header closeButton>
                        <Modal.Title>{chargeAction == "new" ? t("ReceiveandReceived.New") : t("button.edit")} {t("contractlistother.charge")} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Alert variant="warning" className="mb-lg-4 mb-3">
                            {t("ReceiveandReceived.Youmayprefertopostcharges")}.
                            <span className="d-block">{t("ReceiveandReceived.Todothisaddrecurringandone")}.</span>
                        </Alert> */}
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col sm={12} lg={6}>
                                <InputGroup>
                                    <FloatingLabel controlId="client" label={t("Client") + " *"}>
                                        <Form.Select aria-label="Client"  {...register('ID_SACADO_SAC')} className={`form-control ${errors.ID_SACADO_SAC ? 'is-invalid' : ''}`} disabled={disabled} onChange={(e) => checkTenantAddress(e)} >
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {GetClient && GetClient.length > 0 &&
                                                GetClient.map((item: any, index: any) => (
                                                    <option key={index} value={item.ID_SACADO_SAC}>{item.ST_NOME_SAC}</option>
                                                ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid"> {errors.ID_SACADO_SAC?.message} </Form.Control.Feedback>
                                        {AddressError &&
                                            <p className='text-danger'> {t("address")} {t("ReceiveandReceived.Invalidate")} </p>
                                        }
                                    </FloatingLabel>
                                </InputGroup>
                            </Col>
                            <Col sm={12} lg={6}>
                                <FloatingLabel controlId="billing_method" label={`${t("billingMethod")} *`}>
                                    <Form.Select
                                        aria-label="Billing method"
                                        className={`form-control ${errors.ID_FORMAPAGAMENTO_RECB ? "is-invalid" : ""}`}
                                        {...register("ID_FORMAPAGAMENTO_RECB")}
                                        onChange={handleBillingChange}
                                        disabled={disabled}
                                    >
                                        <option value="">{t("dropdownItem.select")}</option>
                                        {billingData.data && billingData.data.length > 0
                                            ? billingData.data.map((item) => (
                                                <option key={item.id} value={Number(item.id)}>
                                                    {item.name}
                                                </option>
                                            ))
                                            : ""}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ID_FORMAPAGAMENTO_RECB?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                        </Row>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col hidden>
                                <FloatingLabel controlId="charge_id" label="charge_id" hidden>
                                    <Form.Control type="text" placeholder="charge_id" {...register('ID_RECEBIMENTO_RECB')} />
                                    <Form.Control type="text" {...register('ID_IMOVEL_IMO')} />
                                    <Form.Control type="text" {...register('ID_CONTRATO_CON')} />
                                    <Form.Control type="text" {...register('ID_FILIAL_FIL')} />
                                </FloatingLabel>
                            </Col>

                            <Col sm={12} lg={6} md={6}>
                                <FloatingLabel controlId="bank_account" label={`${t("banks.bankAccounts")} *`}>
                                    <Form.Select
                                        aria-label="Bank account"
                                        className={`form-control ${errors.ID_CONTA_CB ? "is-invalid" : ""}`}
                                        {...register("ID_CONTA_CB")}
                                        onChange={handleBankChange}
                                        disabled={disabled}
                                        value={selectedBankAccount}
                                    >
                                        <option value="">{t("dropdownItem.select")}..</option>
                                        {filteredBankAccounts.length > 0 ? (
                                            filteredBankAccounts.map((curElem, index) => (
                                                <option key={index} value={curElem.id}>
                                                    {curElem.accountRef}
                                                </option>
                                            ))
                                        ) : (
                                            <h3 style={{ width: "100%", textAlign: "center", color: "red" }}>
                                                {t("OOPS")}
                                            </h3>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ID_CONTA_CB?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col sm={12} lg={6}>
                                <FloatingLabel controlId="maturity" label={t("maturity") + " *"}>
                                    <Form.Control type="date" placeholder={t("maturity")} min={startDate} className={`form-control ${errors.DT_VENCIMENTO_RECB ? 'is-invalid' : ''}`}  {...register('DT_VENCIMENTO_RECB')} onChange={(e) => handlDate(e)} disabled={disabled} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.DT_VENCIMENTO_RECB?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {/* <div className="d-flex align-items-center justify-content-between mb-3 d-block ">
                            <span className="fw-bold getzipcode">{t("ReceiveandReceived.Productsorservices")}</span>
                            {chargeAction == "new" ?
                                <Button variant="link" className="p-0 fw-bold" onClick={() => handleClear()}>{t("button.clear")}</Button>
                                :
                                ""}
                        </div> */}
                        <Row className="gy-lg-4 gy-3">
                            <AnotherAdd count={productinsertcount} />
                        </Row>
                        <div className='cursor-pe text-primary fw-bold mb-lg-4 mb-3' onClick={() => setProductInsertCount(productinsertcount + 1)} >
                            {
                                id && id !== "" ?
                                    <img src="../assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />

                                    :
                                    <img src="./assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />

                            }
                            {t("formLabel.another")}
                        </div>
                        <Col md={6} className="mb-2">
                            <FloatingLabel controlId="value" label={t("ReceiveandReceived.Totalvalue") + " *"}>
                                <Form.Control type="text" className={`form-control ${errors.vl_emitido_recb ? 'is-invalid' : ''}`} placeholder={t("ReceiveandReceived.Totalvalue")} disabled {...register('vl_emitido_recb')} />
                            </FloatingLabel>

                            {extraAndDiscount.use_percentage == true && isDiscountError != '' &&
                                <p className="text-danger">{isDiscountError}</p>
                            }

                        </Col>
                        <Accordion className="accordion-custom" defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("contractlistother.Extrasanddiscounts")}</Accordion.Header>
                                <Accordion.Body>
                                    <Form.Check className="mb-lg-4 mb-3" type="checkbox" id="charge_rate" label={t("ReceiveandReceived.Changerateforthischarge")} onChange={(e) => handleCheckUncheck(e, "rate_change")} />
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={4} className="extradiscount">
                                            <FloatingLabel controlId="traffic_ticket" label={t("formReceipt.trafficTicket") + "(%)"}>
                                                <Form.Control type="text" placeholder={t("formReceipt.trafficTicket") + "(%)"} disabled {...register('VL_TXMULTA_RECB')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4} className="extradiscount">
                                            <FloatingLabel controlId="fees" label={t("ReceiveandReceived.Interest") + "(%)"}>
                                                <Form.Control type="text" placeholder={t("ReceiveandReceived.Interest") + "(%)"} disabled {...register('VL_TXJUROS_RECB')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4} className="extradiscount">
                                            <FloatingLabel controlId="discount" label={`${t('formReceipt.discount')} ${isDiscountPercentage ? "(%)" : "(R$)"}`}>
                                                <Form.Control type="text" placeholder={`${t('formReceipt.discount')} ${isDiscountPercentage ? "(%)" : "(R$)"}`} disabled {...register('VL_TXDESCONTO_RECB')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                <Accordion.Body>
                                    <>

                                        <FloatingLabel className="mb-lg-4 mb-3" controlId="observation" label={t("contractlistother.Internalobservation")}>
                                            <Form.Control as="textarea" placeholder={t("contractlistother.Internalobservation")} {...register('ST_OBSERVACAOINTERNA_RECB')} disabled={disabled} />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="note" label={t("contractlistother.Noteforthecustomer")}>
                                            <Form.Control type="text" placeholder={t("contractlistother.Noteforthecustomer")} {...register('ST_OBSERVACAOEXTERNA_RECB')} disabled={disabled} />
                                        </FloatingLabel>
                                    </>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer className="d-flex align-items-center justify-content-end">
                        <Button variant="outline-primary" className="me-2 me-lg-3" onClick={onClose}>
                            {t('button.close')}
                        </Button>
                            {
                                (!AddressError && chargeAction == "new") ?
                                    loader_on ?
                                        <Button  variant="primary" disabled={double} >
                                            <span className="spinner-border spinner-border-sm"></span>
                                            <span className="px-1">{t('button.toSave')}</span>
                                        </Button>
                                    :
                                        <Button type="submit" disabled={double} variant="primary" name="save" onClick={openErrorbox} >
                                            {t('button.toSave')}
                                        </Button>
                                : ""
                            }
                            {(!AddressError && chargeAction == "edit") ?
                                <Button variant="primary" onClick={ToSave} >
                                    {t('button.toEdit')}
                                </Button>
                                : ""}
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Client modal start from here */}
            {/* <NewCustomer show={showClientModal} onClose={clientModalClose} /> */}
            {/* /Client modal end */}
        </>
    )
}

export default ChargeService
