import React, { useEffect, useState } from 'react';
import { Offcanvas, Form, Button, FloatingLabel, Card, Badge, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { nonpayment_recordField } from '../../../Interface';
import customerService from '../../../../services/customerService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Profile/Company/Loader';

const EditTemplate = (props: any) => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    //Email template modal close open function
    const { showEditTemplate, editTemplateClose, folderName } = props;
    const { register, handleSubmit, setValue, getValues, reset, clearErrors, setError, formState: { errors } } = useForm();
    const [desc, setDesc] = useState('');
    const [contractVariableData, setContractVariableData] = useState<any>({});
    const [loading, setloading] = useState(false);
    const [loading_btn, setloading_btn] = useState(false);
    useEffect(() => {
        if (showEditTemplate) {
            const fetchData = async () => {
                try {
                    const response = await customerService.getTemplates();
                    const variableResult = response.data.data[1];
                    const propertyVariableResult = response.data.data[2];
                    setContractVariableData(variableResult);
                    setPropertyVariableData(propertyVariableResult);
                } catch (error) {
                    setContractVariableData([])
                }
            };
            fetchData();
        }
    }, [showEditTemplate])

    useEffect(() => {
        if (showEditTemplate) {
            setloading(true)
            const fetchData = async () => {
                try {
                    const templateDta: any = await customerService.listTemplateContent_setting({ title: folderName });
                    if (templateDta && templateDta?.["data"]?.["data"] && templateDta?.["data"]?.["data"].length > 0) {
                        setDesc(templateDta?.["data"]?.["data"]?.["0"]?.["ST_TEXTO_MOH"] || "")
                        setValue('description', templateDta?.["data"]?.["data"]?.["0"]?.["ST_TEXTO_MOH"] || "");
                        setloading(false);
                    } else {
                        setDesc("")
                        setValue('description', "")
                        setloading(false);
                    }
                } catch (error) {
                    setDesc("")
                    setValue('description', "")
                    setloading(false);
                }
                getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
            };
            fetchData();
        }
    }, [showEditTemplate])

    const editTemplateCloseClick = () => {
        editTemplateClose()
        setDesc("");
        reset();
        setIdentifier("")
        setTemplateName("")
        setFirstSelection("");
        setSecondSelection('');
        setThirdSelection('');
    }

    const onSubmit = async (data: any) => {
        data["folderName"] = "Non Payment";
        setloading_btn(true);
        let response = await customerService.createNewTemplateFolder_new(data);
        if (response.data.resCode == 202 || response.data.resCode == 201) {
            let folder_id = response?.data?.data?.[0]?.["folder_id"];
            checkAndCreateTemplate(data, folderName, folder_id);
        }
    }

    const checkAndCreateTemplate = async (data_value: any, template_name: string, folder_id: string | number) => {
        let data: any = {
            templateName: template_name,
            title: template_name,
            documentIdentifier: "Contract",
            description: desc,
            model: null,
            folder: folder_id
        }
        let response = await customerService.updateTemplate(data);
        if (response.data.resCode == 202 || response.data.resCode == 200) {
            Swal.fire({
                title: t("Sweetalert.Success"),
                text: t('templateSaved'),
                confirmButtonText: "OK",
                timer: 2000
            })
            editTemplateCloseClick();
            setloading_btn(false);
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t('recordFailed'),
                confirmButtonText: "OK",
                timer: 2000
            })
            setloading_btn(false);
        }
    }

    // NEW HERE
    const [selectionData, setSelectionData] = useState<any>([]);
    const [jsonData, setJsonData] = useState<any>([])
    const [firstOptions, setFirstOptions] = useState<any>([]);
    useEffect(() => {
        if (selectionData) {
            const jsonData_result = selectionData;
            setJsonData(jsonData_result);
            const firstOptions_data: any = Array.from(new Set(jsonData_result.map(item => item.ST_NOME_TEH)));
            setFirstOptions(firstOptions_data);
        }
    }, [selectionData])

    const [secondOptionsData, setsecondOptionsData] = useState<any>({});
    const [thirdOptionsData, setthirdOptionsData] = useState<any>({});

    const [firstSelection, setFirstSelection] = useState('');
    const [secondSelection, setSecondSelection] = useState('');
    const [thirdSelection, setThirdSelection] = useState('');

    const [identifier, setIdentifier] = useState<any>("");
    const [templateName, setTemplateName] = useState<any>("");
    const [propertyVariableData, setPropertyVariableData] = useState<any>({});

    useEffect(() => {
        if (jsonData) {
            jsonData && jsonData.length > 0 &&
                jsonData.forEach((item: any) => {
                    secondOptionsData[item.ST_NOME_TEH] = Array.from(new Set(jsonData.filter(data => data.ST_NOME_TEH === item.ST_NOME_TEH).map(data => ({
                        value: data.ID_MODELO_MOH,
                        label: data.ST_NOME_MOH,
                        templateData: data.ST_TEXTO_MOH,
                        templateIdentifier: data.ST_IDENTIFICADOR_MOH
                    }))))

                    thirdOptionsData[item.ID_MODELO_MOH] = Array.from(new Set(jsonData.filter(data => data.ID_MODELO_MOH === item.ID_MODELO_MOH).map(data => ({
                        value: data.ST_IDENTIFICADOR_MOH,
                        label: data.ST_NOME_MOH
                    }))))
                });
        }
    }, [jsonData])

    const getAllElectronicEnvelopeTemplateWithIdentifierAndFolder = async () => {
        const response = await customerService.getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
        setSelectionData(response.data.data)
    }

    const handleFirstChange = (event: any) => {
        const selectedValue = event.target.value;
        setFirstSelection(selectedValue);
        setIdentifier("")
        setTemplateName("")
        setSecondSelection("");
        setThirdSelection('');
    };

    // Function to handle change in the second dropdown
    const handleSecondChange = (event: any, optionsData: number) => {
        const selectedValue: any = event.target.value;
        let templateData: any = optionsData;
        if (selectedValue && selectedValue !== "" && selectedValue.length > 0) {
            let obj = templateData.find((obj: any) => obj.value == selectedValue);
            if (obj && Object.keys(obj).length > 0) {
                setIdentifier(obj.templateIdentifier);
                setTemplateName(obj.label)
                setDesc(obj.templateData);
            }
            setSecondSelection(selectedValue);
            setThirdSelection('');
        } else {
            setIdentifier("")
            setTemplateName("")
            setSecondSelection("");
            setThirdSelection('');
        }

    };
    // Function to handle change in the third dropdown
    const handleThirdChange = (event) => {
        const selectedValue = event.target.value;
        setThirdSelection(selectedValue);
    };

    return (
        <>
            <Offcanvas className="border-sm-none" show={showEditTemplate} onHide={editTemplateCloseClick} placement="end">
                {
                    loading ? <Loader />
                        :
                        <>
                            <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                                <Offcanvas.Header className="border-bottom" closeButton>
                                    <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2 me-xl-4 me-lg-3" alt="Arrow left" onClick={editTemplateCloseClick} />
                                    {t("NonPayment.editAutomaticChargeTemplate")}
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <h6 className="mb-3 fw-bold">
                                        {t(folderName)}
                                    </h6>
                                    <Card className="mb-lg-4 mb-3 border">
                                        <Card.Body className="pb-1">
                                            <h6 className="mb-3 fw-bold">{t("formLabel.variables")}</h6>
                                            <p className="mb-3">{t("NonPayment.selectVariableYouWantTemplate")}</p>
                                            <Row className="gy-3 gy-lg-4">
                                                <Col md={6}>
                                                    {/* First dropdown */}
                                                    <FloatingLabel controlId="firstSelect" label={t('selectFolder')}>
                                                        <Form.Select className={`form-control ${errors.firstSelectionOption ? 'is-invalid' : ''}`} {...register('firstSelectionOption')} aria-label="firstSelectionOption" value={firstSelection} onChange={handleFirstChange} >
                                                            <option value="">Select</option>
                                                            {firstOptions && firstOptions.length > 0 &&
                                                                firstOptions.map((option: any, index: number) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{(errors.firstSelectionOption?.message as string)}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col md={6}>
                                                    {/* Second dropdown */}
                                                    {firstSelection && (
                                                        <FloatingLabel controlId="secondSelect" label={t('selectTemplate')}>
                                                            <Form.Select className={`form-control ${errors.secondSelectionOption ? 'is-invalid' : ''}`} {...register('secondSelectionOption')} aria-label="secondSelectionOption" value={secondSelection} onChange={(e) => { handleSecondChange(e, secondOptionsData[firstSelection]) }}>
                                                                <option value="">Select</option>
                                                                {secondOptionsData && firstSelection && secondOptionsData?.[firstSelection] &&
                                                                    secondOptionsData[firstSelection].length > 0 &&
                                                                    secondOptionsData[firstSelection].map((option: any, index: number) => (
                                                                        <option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">{(errors.secondSelectionOption?.message as string)}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    )}
                                                </Col>
                                                <Col md={6}>
                                                    {/* Third dropdown */}
                                                    {identifier && (identifier == "Contract") ?
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    const selectedVariable = e.target.value;
                                                                    const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {contractVariableData && Object.keys(contractVariableData).length > 0
                                                                    ? Object.keys(contractVariableData).map(key => (
                                                                        <option key={key} value={contractVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        :
                                                        ""}

                                                    {identifier && (identifier == "Property") ?
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    const selectedVariable = e.target.value;
                                                                    const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {propertyVariableData && Object.keys(propertyVariableData).length > 0
                                                                    ? Object.keys(propertyVariableData).map(key => (
                                                                        <option key={key} value={propertyVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        :
                                                        ""}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <Col md={12}>
                                        <Form.Control type="hidden" placeholder="documentText"  {...register('description')} />
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={desc}
                                            onReady={editor => {
                                                const data = editor.getData();
                                                setDesc(data)
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDesc(data)
                                            }}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData();
                                                setDesc(data)

                                            }}
                                            onFocus={(event, editor) => {
                                                const data = editor.getData();
                                                setDesc(data)
                                            }}
                                        />
                                    </Col>
                                </Offcanvas.Body>
                                <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                    {
                                        loading_btn ?
                                            <Button className="btn-width">
                                               <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                            </Button>
                                            :
                                            <>
                                                <Button variant="outline-primary" onClick={editTemplateClose}>{t("button.cancel")}</Button>
                                                <Button variant="primary" type="submit">{t("button.save")}</Button>
                                            </>
                                    }
                                </div>
                            </Form>
                            {/* <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                                <Offcanvas.Header className="border-bottom" closeButton>
                                    <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2 me-xl-4 me-lg-3" alt="Arrow left" onClick={editTemplateCloseClick} />
                                    {t("NonPayment.editAutomaticChargeTemplate")}
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <h6 className="mb-3 fw-bold">
                                        {folderName}
                                    </h6>
                                    <Card className="mb-lg-4 mb-3 border">
                                        <Card.Body className="pb-1">
                                            <h6 className="mb-3 fw-bold">{t("formLabel.variables")}</h6>
                                            <p className="mb-3">{t("NonPayment.selectVariableYouWantTemplate")}</p>
                                            <ul className="mb-0 list-inline">
                                                <li className="list-inline-item mb-lg-3 mb-md-2 mb-2">
                                                    <Badge className="border text-dark rounded-4" bg="white">{t("NonPayment.agreementNumber")}</Badge>
                                                </li>
                                                <li className="list-inline-item mb-lg-3 mb-md-2 mb-2">
                                                    <Badge className="border text-dark rounded-4" bg="white">{t("NonPayment.agreementAmount")}</Badge>
                                                </li>
                                                <li className="list-inline-item mb-lg-3 mb-md-2 mb-2">
                                                    <Badge className="border text-dark rounded-4" bg="white">{t("contractlistother.Numberofinstallments")}</Badge>
                                                </li>
                                                <li className="list-inline-item mb-lg-3 mb-md-2 mb-2">
                                                    <Badge className="border text-dark rounded-4" bg="white">{t("NonPayment.dueDateFirstInstallment")}</Badge>
                                                </li>
                                            </ul>
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                    onChange={(e) => {
                                                        const selectedVariable = e.target.value;
                                                        const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                    }}>
                                                    <option value="">{t('dropdownItem.select')}</option>
                                                    {contractVariableData && Object.keys(contractVariableData).length > 0
                                                        ? Object.keys(contractVariableData).map(key => (
                                                            <option key={key} value={contractVariableData[key]}>
                                                                {key}
                                                            </option>
                                                        ))
                                                        : <option disabled>{t("dimob.NoDataFound")}</option>
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Card.Body>
                                    </Card>
                                    <FloatingLabel controlId="template_content" label={""} >
                                        <Form.Control type="hidden" placeholder="documentText"  {...register('description')} />
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={desc}
                                            onReady={editor => {
                                                const data = editor.getData();
                                                setDesc(data)
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDesc(data);
                                                setValue('description', data); // Update the form value
                                            }}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData();
                                                setDesc(data);
                                                setValue('description', data); // Update the form value
                                            }}
                                            onFocus={(event, editor) => {
                                                const data = editor.getData();
                                                setDesc(data)
                                            }}
                                        />
                                    </FloatingLabel>
                                </Offcanvas.Body>
                                <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                    <Button variant="outline-primary" onClick={editTemplateClose}>{t("button.cancel")}</Button>
                                    <Button variant="primary" type="submit">{t("button.save")}</Button>
                                </div>
                            </Form> */}
                        </>
                }
            </Offcanvas>
        </>
    )
}

export default EditTemplate
