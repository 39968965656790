import React from "react";

import { Modal, } from "react-bootstrap";
import { useSolveStatusModal } from "./SolveStatusModalProvider";

export function SolveStatusModal() {
  const { statusList, openModal, clearStates } = useSolveStatusModal()

  return (
    <Modal
      show={openModal}
      onHide={clearStates}
    >
      <Modal.Header closeButton>
        <Modal.Title>Escolha o que você quer resolver agora:</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-3">
        {statusList?.errors.map((error, index) => (
          <div key={index} className="d-flex align-items-center gap-2 mb-2">
            <span style={{ color: "#404040" }}>{error.message}</span>
            <a href={error.link}>Solucionar</a>
          </div>
        ))}
        {statusList?.alerts.map((alert, index) => (
          <div key={index} className="d-flex align-items-center gap-2 mb-2">
            <span style={{ color: "#404040" }}>{alert.message}</span>
            <a href={alert.link}>Solucionar</a>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  )
}
