import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
	Button,
	Col,
	Collapse,
	Dropdown,
	Form,
	InputGroup,
	Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBankAccountDrawer } from "../newComponents/components/BankAccountDrawer";
import { getCateGory, getCategory } from "../redux/slice/AccountPlanSlice";
import { Filter_bank, getBankList, searching } from "../redux/slice/bankSlice";
import {
	branchSearching,
	Filter_branch,
	getBraches,
	handleOpn,
} from "../redux/slice/branchSlice";
import { registerCompanyOwner } from "../redux/slice/companyDataSlice";
import {
	fetchService,
	searchService,
	ServiceShow,
} from "../redux/slice/ServiceSlice";
import { AppDispatch, RootState } from "../redux/store";
import transferService from "../services/transferService";
import NewAccount from "././Profile/Company/AccountPlan/NewAccount";
import NotificationSetting from "././Profile/Company/Notification/notification_setting";
import {
	encrypt_Decrypt,
	encrypt_Decrypt_Plan,
	PrintPDF,
} from "./constants/utils";
import { ComProps } from "./Interface";
import AccountPlanSettingModel from "./Profile/Company/AccountPlan/AccountPlanSettingModel";
import AddSingleCate from "./Profile/Company/AccountPlan/AddSingleCate";
let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");

const SubNavBar = ({ title }: ComProps) => {
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();
	const [t] = useTranslation();
	const { account_cateGory } = useSelector(
		(state: RootState) => state.accountplane
	);
	const [tabactive, setActiveTab] = useState<number>(1);
	const [userstatus, setUserStatus] = useState("Status");
	const [compositefilter, setCompositeFilter] = useState<any>();
	const [Showsetting, setShowsetting] = useState(false);
	const [catItem, setCatItem] = useState<number>(0);
	const [state, setState] = useState({
		start: 0,
		end: 100,
	});
	const handleShow = () => {
		dispatch(handleOpn(true));
	};
	let newDate = new Date(dateBrazil);
	let currentDate = moment(newDate).format("MMMM YYYY");

	const [userPlanString, setUserPlanString] = useState("");
	useEffect(() => {
		let userPLAN = encrypt_Decrypt_Plan();
		// setUserPlanString(userPLAN)
		setUserPlanString("300");
	}, [location]);

	const navigate = useNavigate();
	const [userJson, setUserJson] = useState<any>([]);

	useEffect(() => {
		let userResult = encrypt_Decrypt();
		setUserJson(userResult);
		dispatch(registerCompanyOwner());
	}, []);

	useEffect(() => {
		(async () => {
			let firstcat = await dispatch(getCategory());
			if (firstcat?.payload?.data && firstcat.payload.data.length > 0) {
				setActiveTab(firstcat.payload.data[0]?.ST_CONTA_CONT);
				setCatItem(firstcat.payload.data[0]?.total_category_item);
			}
		})();
	}, [dispatch]);

	const formShow = () => {
		dispatch(ServiceShow(true));
	};

	//New Account modal initialization
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	
	const [BankStatusFilter, setBankStatusFilter] = useState('Active');

	const newAccountModalClose = () => {
		let newdata: any = document.querySelector("#root");
		newdata.removeAttribute("chater_category");
		newdata.removeAttribute("action_category");
		setShow(false);
		setShow1(false);
	};
	const newAccountModalShow = (cmd: string, type: string) => {
		setShow(true);
		let newdata: any = document.querySelector("#root");
		newdata?.setAttribute("chater_category", tabactive);

		let data: any = document.querySelector(
			".totalItemoncat button.btn-primary"
		);
		let itemcount = data?.parentElement?.getAttribute("dataitem"); // Check if data.parentElement exists before accessing its properties
		newdata?.setAttribute("total_category_item", itemcount);
		newdata?.setAttribute("action_category", type);
	};

	const addSinglrCateGory = () => {
		setShow1(true);
	};
	// filter branch
	const BranchFilter = (filterData: string, filter: string) => {
		let lable = 'All';
		if( filterData == "0" ){
			lable = 'Active';
		} else if( filterData == "1" ){
			lable = 'In Active';
		}
		setBankStatusFilter(lable);
		let end1: any = "";
		if (filter == "banks") {
			end1 = 1000;
		}
		let all_flt_data: any = {
			tablename: filter,
			start: "",
			end: end1,
			status: filterData,
		};
		if (filter == "branches") {
			dispatch(Filter_branch(all_flt_data));
		} else {
			dispatch(Filter_bank(all_flt_data));
		}
	};

	const [servicefilter, setServiceFilter] = useState({
		status: "",
		search: "",
	});

	let typingTimer;
	const ServiceFilter = (e: any, type: string, searchdata: any) => {
		e.preventDefault();
		if (type === "search") {
			clearTimeout(typingTimer);
			typingTimer = setTimeout(() => {
				doneTyping(type, searchdata);
			}, 500);
		} else {
			doneTyping(type, searchdata);
			var action: any = "Status";
			action = e.currentTarget.getAttribute("data-action");
			var tname: any = e.currentTarget.getAttribute("data-name");
			setCompositeFilter(action);
			if (action == "all") {
				tname = "Status";
			}
			setUserStatus(tname);
		}
	};

	const doneTyping = (type, searchdata) => {
		// The typing has finished; you can perform your search or other actions here
		setServiceFilter({
			...servicefilter,
			[type]: searchdata.toString().trim(),
		});
	};

	useEffect(() => {
		setTimeout(() => {
			if (servicefilter.search != "" || servicefilter.status != "") {
				dispatch(searchService(servicefilter));
			} else {
				dispatch(fetchService());
			}
		}, 1000);
	}, [servicefilter]);

	const HandleSearch = (e: any) => {
		let name = e.target.value;
		if (name.trim() != "") {
			dispatch(searching(name));
			dispatch(branchSearching(name));
		} else {
			let obj = {
				start: "0",
				end: "10",
				all: "",
			};
			dispatch(getBankList(obj));
		}
	};

	var anotherSearch: any = "";
	const HandleBranchSearch = (e: any) => {
		var name = e.target.value;
		clearTimeout(anotherSearch);
		anotherSearch = setTimeout(() => {
			if (name.trim() != "") {
				dispatch(branchSearching(name));
			} else {
				dispatch(getBraches(state));
			}
		}, 1000);
	};

	// category wise account plane
	const handleLoadType = (type, data) => {
		let newdata: any = document.querySelector("#root");
		newdata?.setAttribute("chater_category", type);
		newdata?.setAttribute("total_category_item", data);
		// dispatch(getCate(ty))
		setActiveTab(type);
		dispatch(getCateGory(type));
	};

	///Notification setting modal initialization
	const [showNotificationSettingModal, setNotificationSettingModal] =
		useState(false);
	const notificationSettingModalClose = () =>
		setNotificationSettingModal(false);
	const notificationSettingModalShow = () => setNotificationSettingModal(true);

	///Company setting modal initialization
	const [showCompanySettingModal, setCompanySettingModal] = useState(false);
	const companySettingModalClose = () => setCompanySettingModal(false);
	const companySettingModalShow = () => setCompanySettingModal(true);
	// Filters section
	const [openFilters, setFiltersOpen] = useState(false);

	const [query, setQuery] = useState("");
	const [suggestions, setSuggestions] = useState<any[]>([]);
	const [inputValue, setInputValue] = useState("");

	let typingTimerset;
	const HandleDashboardSearch = async (e: any) => {
		clearTimeout(typingTimerset);
		typingTimerset = setTimeout(async () => {
			const userInput = e.target.value;
			setQuery(userInput);
			if (userInput == "" || userInput == undefined) {
				return setSuggestions([]);
			}
			try {
				const response = await transferService.getDashboardSearch(userInput);
				if (
					response.data.data.length > 0 &&
					userInput !== "" &&
					userInput !== undefined
				) {
					console.log(response.data.data)
					setSuggestions(response.data.data); // Assuming the API response is an array of suggestions
				} else {
					setSuggestions([]);
				}
			} catch (error) {
				setSuggestions([]); // Clear suggestions in case of an error
			}
		}, 800)
	};

	const accountSettingModalShow = () => {
		setShowsetting(true);
	};

	const { setShowBankAccountDrawer } = useBankAccountDrawer();

	return (
		<>
			{title == "Dashboard" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
					<div className="w-100">
						<Row className="gx-xl-5 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: "auto", order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<h1 className="mb-0 lh-base text-truncate me-xl-1">
									{t("Dashboard.Dashboard")}
								</h1>
							</Col>
							<Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
								{/* Search bar */}
								<InputGroup>
									<Form.Control
										onChange={(e) => HandleDashboardSearch(e)}
										placeholder={t("formLabel.typeSearch")}
										aria-label="Search"
										aria-describedby="search_icon"
									/>
									<InputGroup.Text id="search_icon">
										<img
											src="./assets/images/icons/search.svg"
											alt="Search icon"
										/>
									</InputGroup.Text>
								</InputGroup>

								<div
									className="suggestions-container shadow rounded-4 overflow-auto bg-white position-absolute mh-456 mt-2 z-index-5"
									style={{
										width:
											"340px" /* Set the width of the suggestions container */,
									}}
								>
									{suggestions && suggestions.length > 0 && (
										<div className="p-xl-4 p-3">
											{
												suggestions.filter((suggestion) => suggestion.type === "owner") &&
												suggestions.filter((suggestion) => suggestion.type === "owner").length > 0 &&
												<>
													<h5 className="fw-bold">
														{t("customerAndEmployees.owners")}{" "}
													</h5>
													<ul className="list-unstyled text-secondary mb-0 ps-3">
														{suggestions
															.filter((suggestion) => suggestion.type === "owner") // Filter out suggestions without ST_APELIDO_USU
															.map((suggestion: any, index) => (
																<li key={index}>
																	<Link
																		to={
																			"/owner_details/" + suggestion.ID_PESSOA_PES
																		}
																		className="py-1 text-reset d-block"
																	>
																		{suggestion.ST_NOME_PES}
																	</Link>
																</li>
															))}
													</ul>
												</>
											}
											{
												suggestions.filter((suggestion) => suggestion.type === "tenant") &&
												suggestions.filter((suggestion) => suggestion.type === "tenant").length > 0 &&
												<>
													<h5 className="fw-bold">
														{t("customerAndEmployees.tenants")}
													</h5>
													<ul className="list-unstyled text-secondary mb-0 ps-3">
														{suggestions
															.filter((suggestion) => suggestion.type === "tenant") // Filter out suggestions without ST_APELIDO_USU
															.map((suggestion: any, index) => (
																<li key={index}>
																	<Link
																		to={
																			"/profile_details/" + suggestion.ID_PESSOA_PES
																		}
																		className="py-1 text-reset d-block"
																	>
																		{suggestion.ST_NOME_PES}
																	</Link>
																</li>
															))}
													</ul>
												</>
											}

											{
												suggestions.filter((suggestion) => suggestion.type === "contract") &&
												suggestions.filter((suggestion) => suggestion.type === "contract").length > 0 &&
												<>
													<h5 className="fw-bold">
														{t("Dashboard.Contract")}
													</h5>
													<ul className="list-unstyled text-secondary mb-0 ps-3">
														{suggestions
															.filter((suggestion) => suggestion.type === "contract") // Filter out suggestions without ST_APELIDO_USU
															.map((suggestion: any, index) => (
																<li key={index}>
																	<Link
																		to={
																			"/contract_details/" + suggestion.ID_CONTRATO_CON
																		}
																		className="py-1 text-reset d-block"
																	>
																		{t('Dashboard.Contract')} {suggestion.ST_IDENTIFICADOR_IMO} {(suggestion.ST_NOME_PES && suggestion.ST_NOME_PES !== "") ? "- (" + suggestion.ST_NOME_PES + ")" : ""}
																	</Link>
																</li>
															))}
													</ul>
												</>
											}

											{
												suggestions.filter((suggestion) => suggestion.type === "property") &&
												suggestions.filter((suggestion) => suggestion.type === "property").length > 0 &&
												<>
													<h5 className="fw-bold mt-3">{t("Links.properties")}</h5>
													<ul className="list-unstyled text-secondary mb-0 ps-3">
														{suggestions
															.filter(
																(suggestion) =>
																	suggestion.type === "property" &&
																	suggestion.ST_IDENTIFICADOR_IMO !== null &&
																	suggestion.ST_IDENTIFICADOR_IMO.trim() !== ""
															) // Filter out suggestions with ST_APELIDO_USU and ID_CONTRATO_CON
															.map((suggestion: any, index) => (
																<li key={index}>
																	<Link
																		className="py-1 text-reset d-block"
																		to={
																			"/property_detail/" + suggestion.ID_IMOVEL_IMO
																		}
																	>
																		{suggestion.ST_ENDERECO_IMO} ({suggestion.ST_CIDADE_IMO}),
																		{t("formLabel.propertyIdentifier")} - (
																		{suggestion.ST_IDENTIFICADOR_IMO}),{" "}
																		{t("customerAndEmployees.owners")} - (
																		{suggestion.ST_NOME_PES})
																	</Link>
																</li>
															))}
													</ul>
												</>
											}
										</div>
									)}
								</div>
							</Col>
							<Col
								xs={{ span: 12, order: 1 }}
								xl={{ order: 3 }}
								className="text-xl-end col-xl mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-xl-0 mb-2">
									<li className="list-inline-item me-xl-3 me-xxl-4 capitalize">
										{currentDate}
									</li>
									<li className="list-inline-item">
										<a
											href="https://suporte.kenlo.com.br/pt-BR/"
											className="text-primary fw-bold"
											target="_blank"
											rel="noreferrer"
										>
											<img
												src="assets/images/icons/question_icon.svg"
												alt="Help"
												className="h-20 me-1"
											/>{" "}
											{t("help")}
										</a>
									</li>
								</ul>
							</Col>
						</Row>
					</div>
				</div>
			)}

			{title == "profile" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100 mb-xl-4">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 2, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<Link to={"/properties"}>
									<img
										src="../assets/images/icons/arrow-left.svg"
										className="h-14"
										alt="Arrow left"
									/>
								</Link>
							</Col>
						</Row>
					</div>
				</div>
			)}

			{title == "Company" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 4, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<h1 className="mb-0 lh-base text-truncate">
									{t("Links.companyData")}
								</h1>
							</Col>
							<Col
								xs={{ order: 1 }}
								xl={{ span: 5, order: 3 }}
								className="text-xl-end mb-0"
							>
								<ul className="list-inline mb-0">
									<li className="list-inline-item mb-md-0 mb-1">
										<img
											src="assets/images/icons/print-light.svg"
											alt="Print"
											className="h-20 cursor-pe"
											onClick={PrintPDF}
										/>
									</li>
									{/* <li className="list-inline-item">
                    <img
                      src="assets/images/icons/settings-light.svg"
                      alt="Settings"
                      className="h-20 cursor-pe"
                      onClick={companySettingModalShow}
                    />
                    <CompanySettings
                      showCompanySetting={showCompanySettingModal}
                      companySettingClose={companySettingModalClose}
                    />
                  </li> */}
									{/*<li className="list-inline-item">
                    <img
                      src="assets/images/icons/question_icon.svg"
                      alt="Help"
                      className="h-20"
                    />
                  </li>*/}
								</ul>
							</Col>
						</Row>
					</div>
				</div>
			)}

			{title == "Branches" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100 mb-xl-4">
						<Row className="gx-xl-5 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: "auto", order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<div className="d-flex align-items-center justify-content-between">
									<h1 className="mb-0 lh-base text-truncate me-1">
										{t("Links.branches")}
									</h1>
									{/* Filter button visible in mobile devices */}
									<div className="d-xl-none">
										<Button
											variant="light"
											size="sm"
											className="text-nowrap"
											onClick={() => setFiltersOpen(!openFilters)}
											aria-controls="collpase-filter-text"
											aria-expanded={openFilters}
										>
											{t("dropdownItem.filters")}
											<img
												src="assets/images/icons/filter-icon.svg"
												alt="Filter"
												className="ms-1 h-12"
											/>
										</Button>
									</div>
								</div>
							</Col>
							<Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
								{/* Search bar */}
								<InputGroup>
									<Form.Control
										placeholder={t("formLabel.typeSearch")}
										aria-label="Search"
										aria-describedby="search_icon"
										onChange={(e) => HandleBranchSearch(e)}
									/>
									<InputGroup.Text id="search_icon">
										<img
											src="./assets/images/icons/search.svg"
											alt="Search icon"
										/>
									</InputGroup.Text>
								</InputGroup>
							</Col>

							<Col
								xs={{ span: 12, order: 1 }}
								xl={{ order: 3 }}
								className="text-xl-end col-xl mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-0">
									{userPlanString !== "100" && userPlanString !== "50" && (
										<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
											<a className="fw-bold cursor-pe" onClick={handleShow}>
												<img
													src="assets/images/icons/plus-light.svg"
													alt="Plus icon"
													className="me-1 h-20"
												/>
												{t("branches.newBranch")}
											</a>
										</li>
									)}
									<li className="list-inline-item">
										<img
											src="assets/images/icons/print-light.svg"
											alt="Print"
											className="h-20 cursor-pe"
											onClick={PrintPDF}
										/>
									</li>
									{/*<li className="list-inline-item mb-md-0 mb-1">
                    <img
                      src="assets/images/icons/question_icon.svg"
                      alt="Help"
                      className="h-20"
                    />
                  </li>*/}
								</ul>
							</Col>
						</Row>
					</div>


					{/* Filters start here */}
					<Collapse className="d-xl-block collpase-filters" in={openFilters}>
						<Form id="collpase-filter-branches" action="" method="">
							<div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
								<h5 className="mb-0 fw-bold">{t("dropdownItem.filters")}</h5>
								<span
									className="cursor-pe"
									onClick={() => setFiltersOpen(!openFilters)}
								>
									<img
										src="assets/images/icons/cross.svg"
										alt="Plus icon"
										className="ms-xxl-1 ms-auto h-14"
									/>
								</span>
							</div>
							<Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
								<Col xl="auto">
									<Dropdown className="radio--custom" autoClose="outside">
										<Dropdown.Toggle variant="light" size="sm">
											{t("dropdownItem.status")}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => BranchFilter("", "branches")}
											>
												{t("formLabel.all")}
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() => BranchFilter("0", "branches")}
											>
												{t("formReceipt.active")}
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() => BranchFilter("1", "branches")}
											>
												{t("formReceipt.disabled")}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
						</Form>
					</Collapse>
					{/* /Filters end */}
				</div>
			)}
			{title == "Bank Accounts" && (
				<div className="BankTabaccounts bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100 mb-xl-4">
						<Row className="gx-xl-5 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: "auto", order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<div className="d-flex align-items-center justify-content-between me-xl-1">
									<h1 className="mb-0 lh-base text-truncate">
										{t("banks.bankAccounts")}{" "}
									</h1>
									{/* Filter button visible in mobile devices */}
									<div className="d-xl-none">
										<Button
											variant="light"
											size="sm"
											className="text-nowrap"
											onClick={() => setFiltersOpen(!openFilters)}
											aria-controls="collpase-filter-text"
											aria-expanded={openFilters}
										>
											{t("dropdownItem.filters")}
											<img
												src="assets/images/icons/filter-icon.svg"
												alt="Filter"
												className="ms-1 h-12"
											/>
										</Button>
									</div>
								</div>
							</Col>
							<Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
								{/* Search bar */}
								<InputGroup>
									<Form.Control
										placeholder={t("formLabel.typeSearch")}
										aria-label="Search"
										aria-describedby="search_icon"
										onChange={(e) => HandleSearch(e)}
									/>
									<InputGroup.Text id="search_icon">
										<img
											src="./assets/images/icons/search.svg"
											alt="Search icon"
										/>
									</InputGroup.Text>
								</InputGroup>
							</Col>
							<Col
								xs={{ span: 12, order: 1 }}
								xl={{ order: 3 }}
								className="text-xl-end col-xl mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-0">
									<li className="list-inline-item">
										<img
											src="assets/images/icons/print-light.svg"
											alt="Print"
											className="h-20 cursor-pe"
											onClick={PrintPDF}
										/>
									</li>
								</ul>
							</Col>
						</Row>
					</div>
					{/* Filters section for desktop start here */}
					<Collapse className="d-xl-block collpase-filters" in={openFilters}>
						<Form id="collpase-filter-bank-account" action="" method="">
							<div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
								<h5 className="mb-0 fw-bold">{t("dropdownItem.filters")}</h5>
								<span
									className="cursor-pe"
									onClick={() => setFiltersOpen(!openFilters)}
								>
									<img
										src="assets/images/icons/cross.svg"
										alt="Plus icon"
										className="ms-xxl-1 ms-auto h-14"
									/>
								</span>
							</div>
							<Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
								<Col xl="auto">
									<Dropdown className="radio--custom" autoClose="outside">
										<Dropdown.Toggle variant="light" size="sm" className={`${BankStatusFilter !== "Status" ? "show" : ""}`} >
											{BankStatusFilter ? BankStatusFilter : t("dropdownItem.status")}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => BranchFilter("", "banks")}>
												{t("formLabel.all")}
											</Dropdown.Item>
											<Dropdown.Item onClick={() => BranchFilter("0", "banks")}>
												{t("formReceipt.active")}
											</Dropdown.Item>
											<Dropdown.Item onClick={() => BranchFilter("1", "banks")}>
												{t("formReceipt.disabled")}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
						</Form>
					</Collapse>
					{/* /Filters section for desktop end */}
				</div>
			)}

			{title == "Forms Of Receipts" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 5, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<h1
									className="mb-0 lh-base text-truncate"
									id="formsofreceipth1"
								>
									{t("formReceipt.Formsofreceipt")}
								</h1>
							</Col>
							<Col
								xs={{ order: 1 }}
								xl={{ span: 7, order: 3 }}
								className="text-xl-end mb-0"
							>
								<Button
									variant="link"
									onClick={() =>
										setShowBankAccountDrawer({
											action: "new",
										})
									}
								>
									<div className="d-flex align-items-center">
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/icons/plus-light.svg`}
											alt="Plus icon"
											className="me-1 h-20"
										/>
										Nova conta
									</div>
								</Button>
							</Col>
						</Row>
					</div>
				</div>
			)}

			{title == "Taxation" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 2, order: 0 }}
								className="mb-xl-0 "
							>
								<div className="d-flex align-items-center justify-content-between">
									<h1 className="mb-0 lh-base text-truncate me-2">
										{t("Links.taxation")}
									</h1>
									{/* Filter button visible in mobile devices */}
									<div className="d-xl-none">
										<Button
											variant="light"
											size="sm"
											className="text-nowrap"
											onClick={() => setFiltersOpen(!openFilters)}
											aria-controls="collpase-filter-text"
											aria-expanded={openFilters}
										>
											{t("dropdownItem.filters")}
											<img
												src="assets/images/icons/filter-icon.svg"
												alt="Filter"
												className="ms-1 h-12"
											/>
										</Button>
									</div>
								</div>
							</Col>
							<Col
								xs={{ order: 1 }}
								xl={{ span: 7, order: 3 }}
								className="text-xl-end mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-0">
									{/* <li className="list-inline-item">
                    <img
                      src="assets/images/icons/print-light.svg"
                      alt="Print"
                      className="h-20"
                      onClick={PrintPDF}
                    />
                  </li> */}
									{/* <li className="list-inline-item mb-md-0 mb-1">
                    <img
                      src="assets/images/icons/question_icon.svg"
                      alt="Help"
                      className="h-20"
                    />
                  </li>*/}
								</ul>
							</Col>
						</Row>
					</div>
					{/* Filters section for desktop start here */}
					{/* /Filters section for desktop end */}
				</div>
			)}

			{title == "Accounts Plan" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100 mb-xl-4">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 5, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<div className="d-flex align-items-center justify-content-between">
									<h1 className="mb-0 lh-base text-truncate me-2">
										{" "}
										{t("chartofaccount.chartOfAccounts")}{" "}
									</h1>
								</div>
							</Col>
							<Col
								xs={{ order: 1 }}
								xl={{ span: 7, order: 3 }}
								className="text-xl-end mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-0">
									<li className="list-inline-item me-4 me-xl-2 me-xxl-4 mb-md-0 mb-1 mr-2">
										<a
											className="fw-bold cursor-pe"
											onClick={(e) =>
												newAccountModalShow("editcategory", "edit")
											}
										>
											{t("button.edit")}
										</a>
									</li>
									<li className="list-inline-item mr-2">
										<a
											className="fw-bold cursor-pe"
											onClick={(e) => addSinglrCateGory()}
										>
											<img
												src="assets/images/icons/plus-light.svg"
												alt="Plus icon"
												className="me-1 h-20"
											/>
											{t("chartofaccount.newAccount")}
										</a>
									</li>
									{/* <li className="list-inline-item">
                    <img
                      src="assets/images/icons/print-light.svg"
                      alt="Print"
                      className="h-20 cursor-pe"
                      onClick={PrintPDF}
                    />
                  </li>
                  <li className="list-inline-item mx-1">
                    <img
                      src="assets/images/icons/settings-light.svg"
                      alt="Settings"
                      className="h-20 cursor-pe"
                      onClick={accountSettingModalShow}
                    />
                  </li> */}
								</ul>
							</Col>
						</Row>
						<NewAccount show={show} onClose={newAccountModalClose} />
						<AddSingleCate
							show1={show1}
							CloseSingle={newAccountModalClose}
							tabactive={tabactive}
						/>
					</div>

					{/* Filters start here */}
					<Form className="d-xl-block d-none w-100 mt-auto" action="" method="">
						<Row className="gx-2 gx-xxl-3">
							{account_cateGory &&
								account_cateGory.map((cateGory: any, index: number) => (
									<Col
										xl="auto"
										className="totalItemoncat"
										dataitem={cateGory.data}
										key={index}
									>
										<Button
											variant={
												cateGory.value == tabactive ? "primary" : "light"
											}
											size="sm"
											className="rounded-pill"
											onClick={(e) =>
												handleLoadType(cateGory?.value, cateGory?.data)
											}
										>
											{cateGory.category}
										</Button>
									</Col>
								))}
						</Row>
					</Form>
					{/* /Filters end */}
				</div>
			)}

			{title == "Dimob" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100 mb-xl-4">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 2, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<div className="d-flex align-items-center justify-content-between">
									<h1 className="mb-0 lh-base text-truncate me-2">DIMOB</h1>
									{/* Filter button visible in mobile devices */}
									<div className="d-xl-none">
										<Button
											variant="light"
											size="sm"
											className="text-nowrap"
											onClick={() => setFiltersOpen(!openFilters)}
											aria-controls="collpase-filter-text"
											aria-expanded={openFilters}
										>
											{t("dropdownItem.filters")}
											<img
												src="assets/images/icons/filter-icon.svg"
												alt="Filter"
												className="ms-1 h-12"
											/>
										</Button>
									</div>
								</div>
							</Col>
							<Col
								xs={{ order: 1 }}
								xl={{ span: 7, order: 3 }}
								className="text-xl-end mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-0">
									<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
										<a className="fw-bold cursor-pe">
											<img
												src="assets/images/icons/plus-light.svg"
												alt="Plus icon"
												className="me-1 h-20"
											/>
											{t("dimbo.ToSetup")}
										</a>
									</li>
									<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
										<a className="fw-bold cursor-pe">
											<img
												src="assets/images/icons/plus-light.svg"
												alt="Plus icon"
												className="me-1 h-20"
											/>
											{t("dimbo.SendExtract")}
										</a>
									</li>
									<li className="list-inline-item">
										<img
											src="assets/images/icons/print-light.svg"
											alt="Print"
											className="h-20"
										/>
									</li>
									{/*<li className="list-inline-item mb-md-0 mb-1">
                    <img
                      src="assets/images/icons/question_icon.svg"
                      alt="Help"
                      className="h-20"
                    />
                  </li>*/}
								</ul>
							</Col>
						</Row>
					</div>
					{/* Filters start here */}
					{/* Filters section for desktop start here */}
					<Collapse className="d-xl-block collpase-filters" in={openFilters}>
						<Form id="collpase-filter-dimob" action="" method="">
							<div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
								<h5 className="mb-0 fw-bold">{t("dropdownItem.filters")}</h5>
								<span
									className="cursor-pe"
									onClick={() => setFiltersOpen(!openFilters)}
								>
									<img
										src="assets/images/icons/cross.svg"
										alt="Plus icon"
										className="ms-xxl-1 ms-auto h-14"
									/>
								</span>
							</div>
							<Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
								<Col xl="auto">
									<Dropdown className="radio--custom" autoClose="outside">
										<Dropdown.Toggle variant="light" size="sm">
											Status
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<label className="d-block cursor-pe" htmlFor="ft1">
												<input type="radio" name="statusFilter" id="ft1" />
												<div className="radio-inner px-4 py-2">
													{t("formReceipt.active")}
												</div>
											</label>
											<label className="d-block cursor-pe" htmlFor="ft2">
												<input type="radio" name="statusFilter" id="ft2" />
												<div className="radio-inner px-4 py-2">
													{t("formReceipt.disable")}
												</div>
											</label>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
						</Form>
					</Collapse>
					{/* /Filters end */}
				</div>
			)}

			{title == "Service" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100 mb-xl-4">
						<Row className="gx-xl-5 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: "auto", order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<div className="d-flex align-items-center justify-content-between">
									<h1 className="mb-0 lh-base text-truncate me-1">
										{t("Links.services")}
									</h1>
									{/* Filter button visible in mobile devices */}
									<div className="d-xl-none">
										<Button
											variant="light"
											size="sm"
											className="text-nowrap"
											onClick={() => setFiltersOpen(!openFilters)}
											aria-controls="collpase-filter-text"
											aria-expanded={openFilters}
										>
											{t("dropdownItem.filters")}
											<img
												src="assets/images/icons/filter-icon.svg"
												alt="Filter"
												className="ms-1 h-12"
											/>
										</Button>
									</div>
								</div>
							</Col>
							<Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
								{/* Search bar */}
								<InputGroup>
									<Form.Control
										placeholder={t("formLabel.typeSearch")}
										aria-label="Search"
										aria-describedby="search_icon"
										onChange={(e) => ServiceFilter(e, "search", e.target.value)}
									/>
									<InputGroup.Text id="search_icon">
										<img
											src="./assets/images/icons/search.svg"
											alt="Search icon"
										/>
									</InputGroup.Text>
								</InputGroup>
							</Col>
							<Col
								xs={{ span: 12, order: 1 }}
								xl={{ order: 3 }}
								className="text-xl-end col-xl mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-0">
									{userJson &&
										userJson.length > 0 &&
										userJson.indexOf(1071) !== -1 && (
											<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
												<a className="fw-bold cursor-pe" onClick={formShow}>
													<img
														src="assets/images/icons/plus-light.svg"
														alt="Plus icon"
														className="me-1 h-20"
													/>{" "}
													{t("services.newService")}
												</a>
											</li>
										)}
									<li
										className="list-inline-item mb-md-0 mb-1 cursor-pe"
										onClick={PrintPDF}
									>
										<img
											src="assets/images/icons/print-light.svg"
											alt="Print"
											className="h-20"
										/>
									</li>
									{/* <li className="list-inline-item">
                    <img
                      src="assets/images/icons/print-light.svg"
                      alt="Print"
                      className="h-20"
                    />
                  </li> */}
									{/*<li className="list-inline-item mb-md-0 mb-1">
                    <img
                      src="assets/images/icons/question_icon.svg"
                      alt="Help"
                      className="h-20"
                    />
                  </li>*/}
								</ul>
							</Col>
						</Row>
					</div>
					{/* Filters section for desktop start here */}
					<Collapse className="d-xl-block collpase-filters" in={openFilters}>
						<Form id="collpase-filter-services" action="" method="">
							<div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
								<h5 className="mb-0 fw-bold">{t("dropdownItem.filters")}</h5>
								<span
									className="cursor-pe"
									onClick={() => setFiltersOpen(!openFilters)}
								>
									<img
										src="assets/images/icons/cross.svg"
										alt="Plus icon"
										className="ms-xxl-1 ms-auto h-14"
									/>
								</span>
							</div>
							<Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
								<Col xl="auto">
									<Dropdown autoClose="outside">
										<Dropdown.Toggle
											variant="light"
											size="sm"
											className={`${userstatus !== "Status" ? "show" : ""}`}
										>
											{userstatus}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{/* <Dropdown.Item onClick={() => ServiceFilter("status", "")}>{t("all")}</Dropdown.Item> */}
											<Dropdown.Item
												data-action="0"
												data-name={t("formReceipt.active")}
												onClick={(e: any) => ServiceFilter(e, "status", 0)}
											>
												{t("formReceipt.active")}
											</Dropdown.Item>
											<Dropdown.Item
												data-action="1"
												data-name={t("formReceipt.disabled")}
												onClick={(e: any) => ServiceFilter(e, "status", 1)}
											>
												{t("formReceipt.disabled")}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
						</Form>
					</Collapse>
					{/* /Filters section for desktop end */}
				</div>
			)}

			{title == "Notifications" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 2, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<h1 className="mb-0 lh-base text-truncate">
									{t("notifications.name")}
								</h1>
							</Col>
							<Col
								xs={{ order: 1 }}
								xl={{ span: 7, order: 3 }}
								className="text-xl-end mb-0"
							>
								<ul className="list-inline mb-0">
									<li className="list-inline-item aaaaaa">
										{/* <img
                      src="assets/images/icons/settings-light.svg"
                      alt="Settings"
                      className="h-20 cursor-pe"
                      onClick={notificationSettingModalShow}
                    /> */}
										<NotificationSetting
											showNotificationSetting={showNotificationSettingModal}
											notificationSettingClose={notificationSettingModalClose}
										/>
									</li>
									{/*<li className="list-inline-item">
                    <img
                      src="assets/images/icons/question_icon.svg"
                      alt="Help"
                      className="h-20"
                    />
                  </li>*/}
								</ul>
							</Col>
						</Row>
					</div>
				</div>
			)}

			{title == "All_Reports" && (
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 2, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<h1 className="mb-0 lh-base text-truncate">
									<span>
										Report: <span>[changeThisCont]</span>
									</span>
								</h1>
							</Col>
						</Row>
					</div>
				</div>
			)}

			<AccountPlanSettingModel
				Showsetting={Showsetting}
				setShowsetting={setShowsetting}
			/>
		</>
	);
};

export default SubNavBar;
