import { Accordion, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Table, Form, Button, Tooltip, Card, Collapse, ButtonGroup } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";

import { CNJP_CPFValidation, HITS, encrypt_Decrypt, checkDuplicateCNJP_CPF } from '../../constants/utils';
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import Liquidate2 from './liquidate2';
import LowerLiquid from './lowerliquid';
import BarcodeExpense from './barcode-expense';
import EditExpense from './edit-expense';
import ChangeMaturity from './change-maturity';
import SelectPayment from './select-payment';

import { setFinancialExpOpen } from "../../../redux/slice/financialDataSlice";
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Loader from '../../Profile/Company/Loader';
import FinancialExpenseService from '../Contracts/Common/FinancialExpenseService';
import { incomeService } from '../../../services/incomeServices';
import BankTransactionsListing from './BankTransactionsListing';
import { fetchBankTransaction } from '../../../redux/slice/BankTransactionDataSlice';
import { useForm } from 'react-hook-form';
import Pagination from "../../Pagination";
//let moment = require("moment");
import moment from "moment-timezone";
import { getAllAccounts } from '../../../redux/slice/expenditureDataSlice';
let getChargesSummery;
export { getChargesSummery };

const BankTransactions = () => {
    let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    // const {  } = useSelector((state: RootState) => state.financialData)
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3010) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const { bank_transaction } = useSelector((state: RootState) => state.bankTransaction)
    const { accountData } = useSelector((state: RootState) => state.expenditureData);
    const { handleSubmit, register, setValue, getValues, reset, clearErrors, setError, formState: { errors } } = useForm();
    const dispatch = useDispatch<AppDispatch>();

    const [bankTransList, setbankTransList] = useState<any>([]);
    const [originalData, setOriginalData] = useState<any>([]);
    const [isCheckId, setIsCheckId] = useState<any>([]);

    const [searchvalue, setSearchValue] = useState({});
    const [offFirstload, setOffFirstLoad] = useState(false);

    const financialExpOpened = () => {
        dispatch(setFinancialExpOpen(true));
    }

    // Multiple HITS select function
    const multipleHITSSelect = (item) => {
        setFilterHits(item);
    }

    const [filteredHits, setFilterHits] = useState([]);
    // Modal's initiate
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    // More filters modal
    const moreFiltersClose = () => setShow(false);
    const moreFiltersShow = () => setShow(true);

    //LiquidateModalShow modal
    const [showLowerLiquidModals, setLowerLiquidModals] = useState(false);
    const lowerliquidCloses = () => setLowerLiquidModals(false);
    const lowerLiquidateShows = () => setLowerLiquidModals(true);

    //Edit expense modal
    const [showEditExpenseModal, setEditExpenseModal] = useState(false);
    const editExpenseModalClose = () => setEditExpenseModal(false);
    const editExpenseModalShow = () => setEditExpenseModal(true);

    //Change expiration modal initialization
    const [showchangeExpirationModal, setchangeExpirationModal] = useState(false);
    const changeExpirationModalClose = () => setchangeExpirationModal(false);
    const changeExpirationModalShow = () => setchangeExpirationModal(true);

    //New release modal
    const [showNewReleaseModal, setNewReleaseModal] = useState(false);
    const newReleaseModalClose = () => setNewReleaseModal(false);
    const newReleaseModalShow = () => setNewReleaseModal(true);

    //Import expense modal
    const [showImportExpenseModal, setImportExpenseModal] = useState(false);
    const importExpenseModalClose = () => setImportExpenseModal(false);
    const importExpenseModalShow = () => setImportExpenseModal(true);

    //Barcode expense modal
    const [showBarcodeExpenseModal, setBarcodeExpenseModal] = useState(false);
    const barcodeExpenseModalClose = () => setBarcodeExpenseModal(false);
    const barcodeExpenseModalShow = () => setBarcodeExpenseModal(true);

    //New transfer modal
    const [showNewTransferModal, setNewTransferModal] = useState(false);
    const newTransferModalClose = () => setNewTransferModal(false);
    const newTransferModalShow = () => setNewTransferModal(true);

    //Issue check modal
    const [showIssueCheckModal, setIssueCheckModal] = useState(false);
    const issueCheckModalClose = () => setIssueCheckModal(false);
    const issueCheckModalShow = () => setIssueCheckModal(true);

    //authorization-date modal
    const [showAuthorizationDateModal, setAuthorizationDateModal] = useState(false);
    const authorizationDateModalClose = () => setAuthorizationDateModal(false);

    //new supplier modal
    const [showNewSupplierModal, setNewSupplierModal] = useState(false);
    const newSupplierModalClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        setNewSupplierModal(false);
    }
    const newSupplierModalShow = () => {
        setNewSupplierModal(true);
    }

    //new retention modal
    const [showNewRetentionModal, setNewRetentionModal] = useState(false);
    const newRetentionModalClose = () => {
        setNewRetentionModal(false);
    }

    // Mobile filters modal
    const mobileFiltersClose = () => setShow2(false);
    const mobileFiltersShow = () => setShow2(true);
    //Generate all modal
    const generateModalClose = () => setShow3(false);
    const generateModalShow = () => setShow3(true);

    const [data, setData] = useState('');
    const parentToChild = () => {
        setData("");
    }

    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');

    let headerDate = moment(newDate)
    const [state, setState] = useState<any>({
        start: 0,
        end: 20,
        bankAccount: "",
        status: "all",
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        filterby: "",
        processingstatus : "",
        oftheType : "",
        from : "banktransaction"
    })

    let PageSize = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpage, setTotalPage] = useState(0);
    const [bankFilter, setbankFilter] = useState('')
    useEffect(() => {
        state["start"] = (currentPage - 1) * state["end"];
        dispatch(fetchBankTransaction(state));
    }, [state, currentPage])

    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = moment(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt,
                ['searchDate']: searchDt,
                ['start']: 0
            });
            setCurrentPage(1);
        }
    }
    let [ startBalance,setStartBalance ] = useState(0);
    useEffect(() => {
        (async () => {
            if(location?.state?.ID_CONTRATO_CON){
                let data_array: any = [];
                if (originalData && originalData.data && originalData.data.length > 0) {
                    data_array = originalData.data.filter((element) => element.ID_CONTRATO_CON == state.ID_CONTRATO_CON);
                }
                setbankTransList({ data: data_array });
                window.history.replaceState(null, '');

            }
            else{
                setbankTransList(bank_transaction["list"]);
                setOriginalData(bank_transaction["list"])
                //setStartBalance(bank_transaction["list"] ? bank_transaction.list.data[0].VL_VALOR_MOV : 0)
            }
        })()
    }, [bank_transaction["list"], location.state])

    const handleAllCheck = (e, type) => {
        let value: any = "";
        if (type != "all") {
            if (type == "every") {
                value = true
            }
            if (type == "none") {
                value = false;
            }
        } else {
            value = e.target.checked;
        }

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            setTimeout(() => {
                let checkedId: any = [];
                data.forEach((item, index) => {
                    item["checked"] = value;
                    if (value) {
                        checkedId.push(item.getAttribute("data-id"));
                    } else {
                        checkedId = [];
                    }
                })
                setIsCheckId(checkedId);
            }, 10)
        }
    }

    const footerAction = (e, type) => {
        if (isCheckId.length > 0) {
            if (type == "liquidate") {
                setLowerLiquidModals(true)
            }

            if (type == "reverse") {

            }

            if (type == "issuse") {
                setIssueCheckModal(true)
            }

            if (type == "bordero") {
                setAuthorizationDateModal(true);
            }

            if (type == "mark") {

            }

            if (type == "unmark") {

            }

            if (type == "change_account") {
                setEditExpenseModal(true)
            }

            if (type == "change_expire") {
                setchangeExpirationModal(true)
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.Pleaseselectatleasttransaction"),
                // icon: 'success',"warning",
                confirmButtonText: "OK"
            });
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }


    const filterBy = (e: any) => {
        const val = e.target.value;
        let data_array: any = [];
        setState({
            ...state,
            ['filterby']: val,

        })
        if (val === "all") {
            // If "all" is selected, reset to the original data.
            if (originalData && originalData.data && originalData.data.length > 0) {
                data_array = originalData.data;
            }

        } else if (val === "receipts") {
            // If "receipts" is selected, filter the data.
            if (originalData && originalData.data && originalData.data.length > 0) {
                data_array = originalData.data.filter((element) => element.VL_VALOR_MOV < 0);
            }
        } else if (val === "payments") {
            // If "payments" is selected, filter the data.
            if (originalData && originalData.data && originalData.data.length > 0) {
                data_array = originalData.data.filter((element) => element.VL_VALOR_MOV > 0);
            }
        } else {
            if (originalData && originalData.data && originalData.data.length > 0) {
                data_array = originalData;
            }
        }

        // Update the state with the filtered data.
        setbankTransList({ data: data_array });

    }
    useEffect(() => {
      
    }, [location.state])

    const filterBankTrastions = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        })
        var selectElement: any = document.querySelector('select[name="bankAccount"]');
        var selectedIndex = selectElement?.selectedIndex;
        var selectedOption = selectElement?.options[selectedIndex];
        // Get the value of the data-action attribute
        var dataAction: any = selectedOption?.getAttribute('label');
        setbankFilter(dataAction)
    }

    useEffect(() => {
        if(bankTransList){
            setTotalPage(bankTransList["totalRow"])
            dispatch(getAllAccounts({ start: 0, end: 1000 }));
        }
    }, [bankTransList])
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    const filterReset = () => {
      setValue("status", "")
      setValue("filerby", "")
      setValue("processingstatus", "all")
      setValue("bankAccount", "all")
      setValue("oftheType", "all")
      setState({
        start: 0,
        end: 20,
        bankAccount: "",
        status: "all",
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        filterby: "",
        processingstatus : "",
        oftheType : "",
        from : "banktransaction"
    })
    setbankFilter('')
    }
    return (
        <>
            {
                <section className="content-wrapper content-wrapper-primary">
                    {/*<!-- Breadcrumb section start from here -->*/}
                    <div
                        className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                        <div className="w-100 mb-xl-4">
                        <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                                    <div className="d-flex align-items-center justify-content-between me-xl-1">
                                        <h1 className="mb-0 lh-base text-truncate">{t("bankTransaction.Banktransactionson")} <span className="capitalize">{state?.["searchDate"].format('MMM/YYYY')}</span></h1>
                                        {/* Filter button visible in mobile devices */}
                                        <div className="d-xl-none">
                                            <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                                {t("dropdownItem.filters")}
                                                <img
                                                    src="assets/images/icons/filter-icon.svg"
                                                    alt="Filter"
                                                    className="ms-1 h-12"
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                                    <div className="input-group">
                                        <input type="text" id="search" className="form-control" placeholder={t("formLabel.typeSearch")}
                                            aria-describedby="basic-addon2" />
                                        <span className="input-group-text" id="basic-addon2">
                                            <img src="assets/images/icons/search.svg" />
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                                    <ul className="list-inline mb-0 fw-bold text-primary">
                                        {/*<li className="list-inline-item me-3 me-xl-2 me-xxl-3 mb-md-0 mb-1">
                                            <div className="dropdown">
                                                {%<a data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-1 h-20 pe-1" onClick={moreFiltersShow} />{t("bankTransaction.NewRecipe")}
                                                </a>
                                                <ul className="dropdown-menu mh-456 overflow-auto" >
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("simulate")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Batchprocess")}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li> */}
                                        {
                                            userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) &&
                                            <li className="list-inline-item">
                                                <Link to={"#"} onClick={financialExpOpened}>
                                                    <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-1 h-20 pe-1" />{t("newExpense")}
                                                </Link>
                                            </li>
                                        }

                                        {/*<li className="list-inline-item me-3 me-xl-2 me-xxl-3 mb-md-0 mb-1">
                                            <div className="dropdown">
                                                <a data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-1 h-20 pe-1" />{t("newExpense")}                                                </a>
                                                <ul className="dropdown-menu mh-456 overflow-auto" >
                                                    <li>
                                                        <a className="dropdown-item" onClick={financialExpOpened}>{t("newExpense")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" onClick={barcodeExpenseModalShow}>{t("withBarcode")}</a>
                                                    </li>
                                                    <BarcodeExpense showBarcodeExpense={showBarcodeExpenseModal} barcodeExpenseClose={barcodeExpenseModalClose} />
                                                    <li>
                                                        <a className="dropdown-item" onClick={importExpenseModalShow}>{t("paidTobepaid.ImportNFe(xml)")}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="list-inline-item me-3 me-xl-2 me-xxl-3 mb-md-0 mb-1">
                                            <a>
                                                <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-1 h-20 pe-1" onClick={newTransferModalShow} />{t("bankTransaction.Newtransfer")}
                                            </a>
                                        </li>
                                        <li className="list-inline-item me-3 me-xl-2 me-xxl-3 mb-md-0 mb-1">
                                            <a>
                                                <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-1 h-20 pe-1" onClick={newReleaseModalShow} />{t("bankTransaction.Newreleseinbox")}
                                            </a>
                                        </li>
                                        <li className="list-inline-item me-3 me-xl-2 me-xxl-3 mb-md-0 mb-1">
                                            <div className="dropdown">
                                                <a>
                                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                                </a>
                                                <ul className="dropdown-menu mh-456 overflow-auto" >
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("formLabel.printScreen")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Transfersmade")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Guaranteedandrealizedtransfer")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Transferstobecarriedout")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Ownersextract")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Simplifiedownerstatement")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Transfersbybank")}</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">{t("TransferPage.Transferinvoices")}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="list-inline-item me-3 me-xl-2 me-xxl-3 mb-md-0 mb-1">
                                            <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                        </li>
                                        <li className="list-inline-item mb-md-0 mb-1">
                                            <a href="#">
                                                <img src="assets/images/icons/question_icon.svg" alt="Question icon" className="h-20" />
                                            </a>
                                        </li>*/}
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        {/* Filters start here */}
                        <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                            <Form id="collpase-filter-text" action="" method="">
                                <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                                    <h5 className="mb-0 fw-bold">Filters</h5>
                                    <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                        <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                                    </span>
                                </div>
                                <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                                    <Col xl="auto">
                                    <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                            <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                                {/* {state.startDate} */}
                                            </Button>
                                            <Button variant="primary" className="btn-date capitalize" disabled>
                                                 {state.searchDate.format('MMM/YYYY')}
                                            </Button>
                                            <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')}>
                                                {/* {state.endDate} */}
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col xl="auto">
                                        <Dropdown autoClose="outside">
                                            <Dropdown.Toggle variant="light" size="sm" className={`${state?.["filterby"] ? "show" : ""}`}>
                                        {state?.["filterby"] == "all" ? t("TransferPage.banktransactions") : state?.["filterby"] == "receipts" ? t("TransferPage.receipts") : state?.["filterby"] == "payments" ? t("TransferPage.payments") : t("TransferPage.Filterby")}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdwon-lg px-4">
                                                <FloatingLabel label={t("TransferPage.Filterby")}  >
                                                    <Form.Select aria-label="Filter by" {...register("filerby")} onChange={filterBy}>
                                                          <option value="">{t("formLabel.all")}</option>
                                                        <option value="all">{t("TransferPage.banktransactions")}</option>
                                                        <option value="receipts">{t("TransferPage.receipts")}</option>
                                                        <option value="payments">{t("TransferPage.payments")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col xl="auto">
                                        <Dropdown autoClose="outside">
                                            <Dropdown.Toggle variant="light" size="sm" className={state?.["status"] !== "all"  ? "show" : ""}>
                                               {state?.["status"] == "all" ? t("dropdownItem.status") : state?.["status"] == "0" ? t("pending") : state?.["status"] == "1" ? t("settled") :state?.["status"] == "2" ? t("checkstoclear") : t("dropdownItem.status")} 
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdwon-lg px-4">
                                                <FloatingLabel label="Status">
                                                    <Form.Select aria-label="Status" {...register("status")} onChange={filterBankTrastions}>
                                                        <option value="">{t("formLabel.all")}</option>
                                                        <option value="0">{t("pending")}</option>
                                                        <option value="1">{t("settled")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col xl="auto">
                                        <Dropdown autoClose="outside">
                                            <Dropdown.Toggle variant="light" size="sm" className={state?.["bankAccount"] ? "show" : ""}>{bankFilter ? bankFilter : t("banks.bankAccounts")}</Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdwon-lg px-4">
                                                <FloatingLabel label={t("bankAccount")}>
                                                    <Form.Select aria-label="Bank account" {...register("bankAccount")} onChange={filterBankTrastions}>
                                                        <option value="all">{t("formLabel.all")}..</option>
                                                        {accountData.data && accountData.data.length > 0
                                                            ? accountData.data.map((item, index) => (
                                                                <option key={index} value={Number(item.id)} label={item.accountRef}>
                                                                    {item.account} -- {item.accountRef}
                                                                </option>
                                                            ))
                                                            : ""}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col xl="auto">
                                        <Dropdown autoClose="outside">
                                            <Dropdown.Toggle variant="light" size="sm" className={state?.["oftheType"] ? "show" : ""}>
                                               {state?.["oftheType"] ? t("all") : state?.["oftheType"] == "1" ? t("Dashboard.Overdue") :  state?.["oftheType"] == "2" ? t("bankTransaction.Inday") : t("bankTransaction.Offthetype")} 
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdwon-lg px-4">
                                                <FloatingLabel label={t("bankTransaction.Offthetype")}>
                                                    <Form.Select aria-label="Off the type" {...register("oftheType")} onChange={filterBankTrastions}>
                                                        <option value="all">{t("formLabel.all")}</option>
                                                        <option value="1">{t("Dashboard.Overdue")}</option>
                                                        <option value="2">{t("bankTransaction.Inday")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    {state?.["status"] !== "all" || state?.["oftheType"] || state?.["bankAccount"] || state?.["processingstatus"]  || state?.["filterby"] ? <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                        {t("button.resetfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col> :
                                ""}
                                </Row>
                            </Form>
                        </Collapse>
                        {/* /Filters end */}
                    </div>
                    {/*<!-- /Breadcrumb section end -->*/}
                    {/*<!-- Main content start from here -->*/}
                    <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                                    <Card>
                                        <Card.Body className="pb-1">
                                            {/*<!-- Table start from here -->*/}
                                            <BankTransactionsListing startBalance={startBalance} bankTransList={bankTransList} setbankTransList={setbankTransList} isCheckId={isCheckId} setIsCheckId={setIsCheckId} handleAllCheck={handleAllCheck}  currentPage={currentPage} totalpage={totalpage} PageSize={PageSize} setCurrentPage={setCurrentPage} />
                                            </Card.Body>
                                    </Card>
                                            {/*<!-- /Table end -->*/}
                                            {/*{
                                                totalpage > 0 &&
                                                <div className="d-flex justify-content-center mt-lg-4 mt-3">
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={currentPage}
                                                        totalCount={totalpage}
                                                        pageSize={PageSize}
                                                        onPageChange={(page: any) => setCurrentPage(page)}
                                                    />
                                                </div>
                                            }*/}
                                            <p className="my-lg-4 my-3"> {t("formLabel.listing")} {bankTransList.totalRow} {t("TransferPage.Transactionsmade")}.</p>
                                            {/* <!-- Nav listing start from here -->*/}
                                            {
                                                userJson && userJson.length > 0 && (userJson.indexOf(3011) !== -1) &&
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                        <div className="dropdown">
                                                            <Button variant="light" size="sm" className="dropdown-toggle" type="button"
                                                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {t("formLabel.mark")}
                                                            </Button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" href="#" onClick={(e) => handleAllCheck(e, "every")}>{t("formLabel.all")}</a></li>
                                                                <li><a className="dropdown-item" href="#" onClick={(e) => handleAllCheck(e, "none")}>{t("formLabel.none")}</a></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">
                                                        {t("formLabel.withTagged")}:</span>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-0 mb-1">
                                                        <div className="dropdown dropup">
                                                            <Button variant="light" size="sm" className="dropdown-toggle" type="button"
                                                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {t("more")}
                                                            </Button>
                                                            <ul className="dropdown-menu mh-456 overflow-auto"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li>
                                                                    <a onClick={(e) => footerAction(e, "delete")} type="button" className="dropdown-item">{t("button.delete")}</a>
                                                                </li>
                                                                <SelectPayment showAuthorizationDate={showAuthorizationDateModal} authorizationDateClose={authorizationDateModalClose} />
                                                                <li>
                                                                    <a onClick={(e) => footerAction(e, "change_account")} type="button" className="dropdown-item">{t("paidTobepaid.Changebankaccount")}</a>
                                                                </li>
                                                                <EditExpense showEditExpense={showEditExpenseModal} editExpenseClose={editExpenseModalClose} />
                                                                <li>
                                                                    <a onClick={(e) => footerAction(e, "change_expire")} type="button" className="dropdown-item">{t("paidTobepaid.Changeexpiration")}</a>
                                                                </li>
                                                                <ChangeMaturity showchangeExpiration={showchangeExpirationModal} changeExpirationClose={changeExpirationModalClose} />
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            }
                                            {/*  <!-- /Nav listing  end -->*/}
                    </main>
                    {/*<!-- /Main content end -->*/}
                    {/*<!-- New expense modal start -->*/}

                    {/*<!-- New expense modal end -->*
                {/*<!-- New retention modal start -->*/}
                    <Modal show={showNewRetentionModal} onHide={newRetentionModalClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("bankTransaction.Newretention")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel controlId="tax" className="mb-3 mb-lg-4" label={t("contractlistother.Tax")}>
                                    <Form.Control type="text" placeholder={t("contractlistother.Tax")} />
                                </FloatingLabel>
                                <FloatingLabel controlId="code" className="mb-3 mb-lg-4" label={t("contractlistother.Code")}>
                                    <Form.Control type="text" placeholder={t("contractlistother.Code")} />
                                </FloatingLabel>
                                <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                    <Form.Select aria-label="Account (category)">
                                        <option value="">{t("TransferPage.Tocarryout")}</option>
                                        <option value="">{t("TransferPage.Performed")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel controlId="aliquot" className="mb-3 mb-lg-4" label={t("contractsettings.Aliquot") + "(%)"}>
                                    <Form.Control type="text" placeholder={t("contractsettings.Aliquot") + "(%)"} />
                                </FloatingLabel>
                                <InputGroup className="mb-3 mb-lg-4">
                                    <FloatingLabel label={t("bankTransaction.collectionagency")} >
                                        <Form.Select aria-label="collection agency" >
                                            <option value="">{t("formLabel.select")}</option>
                                            <option value="">{t("bankTransaction.collectionagency")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                    <InputGroup.Text title={t("formLabel.supplier")}>
                                        {/* <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20"  onClick={newSupplierModalShow}/> */}
                                    </InputGroup.Text>
                                </InputGroup>
                                <FloatingLabel controlId="ledger" className="mb-3 mb-lg-4" label={t("comparetolastmonth.ledgeraccount")}>
                                    <Form.Control type="text" placeholder={t("comparetolastmonth.ledgeraccount")} />
                                </FloatingLabel>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-sm-flex justify-content-end w-100 align-items-center">
                                    <Button variant="outline-primary" onClick={newRetentionModalClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button variant="primary" onClick={newRetentionModalClose}>
                                        {t("button.save")}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/*<!-- New retention modal end -->*/}
                    {/* New supplier modal start from here */}
                    <Modal show={showNewSupplierModal} onHide={newSupplierModalClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("customerAndEmployees.newSuppliers")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel controlId="cnpj" className="mb-3 mb-lg-4" label="CNPJ/CEI/CPF">
                                    <Form.Control type="text" placeholder="CNPJ/CEI/CPF" onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                </FloatingLabel>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-sm-flex justify-content-end w-100 align-items-center">
                                    <Button variant="outline-primary" onClick={newSupplierModalClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button variant="primary" onClick={newSupplierModalClose} className='zipCodeCheck'>
                                        {t("button.next")}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* /New supplier modal end */}
                    {/* New recipe modal start from here */}
                    <Modal show={show} onHide={moreFiltersClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("bankTransaction.NewRecipe")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel controlId="maturity" className="mb-3 mb-lg-4" label={t("maturity")}>
                                    <Form.Control type="date" placeholder={t("maturity")} />
                                </FloatingLabel>
                                <Accordion className="accordion-custom">
                                    <Accordion.Item eventKey="17">
                                        <Accordion.Header>{t("value")}</Accordion.Header>
                                        <Accordion.Body>
                                            <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                                <Form.Select aria-label="Account (category)">
                                                    <option value="">{t("TransferPage.Tocarryout")}</option>
                                                    <option value="">{t("TransferPage.Performed")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <FloatingLabel className="mb-3 mb-lg-4" label={t("complement")}>
                                                <Form.Control type="Text" aria-label="Complement" placeholder={t("complement")} />
                                            </FloatingLabel>
                                            <FloatingLabel className="mb-3 mb-lg-4" label={t("contractlistother.Amount")}>
                                                <Form.Control type="Text" aria-label="Amount" placeholder={t("contractlistother.Amount")} />
                                            </FloatingLabel>
                                            <FloatingLabel className="mb-3 mb-lg-4" label={t("value")}>
                                                <Form.Control type="Text" aria-label="Value" placeholder={t("value")} />
                                            </FloatingLabel>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="18">
                                        <Accordion.Header>{t("bankTransaction.Discounts")}</Accordion.Header>
                                        <Accordion.Body>
                                            <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                                <Form.Select aria-label="Account (category)">
                                                    <option value="">{t("TransferPage.Tocarryout")}</option>
                                                    <option value="">{t("TransferPage.Performed")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <FloatingLabel className="mb-3 mb-lg-4" label={t("complement")}>
                                                <Form.Control type="Text" aria-label="Complement" placeholder={t("complement")} />
                                            </FloatingLabel>
                                            <FloatingLabel className="mb-3 mb-lg-4" label={t("value")}>
                                                <Form.Control type="Text" aria-label="Value" placeholder={t("value")} />
                                            </FloatingLabel>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="19">
                                        <Accordion.Header>{t("formLabel.moreFields")}</Accordion.Header>
                                        <Accordion.Body>
                                            <FloatingLabel controlId="Issuance" className="mb-3 mb-lg-4" label={t("bankTransaction.Issuance")}>
                                                <Form.Control type="date" placeholder={t("bankTransaction.Issuance")} />
                                            </FloatingLabel>
                                            <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                                <Form.Select aria-label="Account (category)">
                                                    <option value="">{t("TransferPage.Tocarryout")}</option>
                                                    <option value="">{t("TransferPage.Performed")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <FloatingLabel className="mb-3 mb-lg-4" label={t("historic")}>
                                                <Form.Control type="Text" aria-label="Historic" placeholder={t("historic")} />
                                            </FloatingLabel>
                                            <FloatingLabel className="mb-3 mb-lg-4" label={t("formLabel.comments")}>
                                                <Form.Control type="Text" aria-label="Comments" placeholder={t("formLabel.comments")} />
                                            </FloatingLabel>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <FloatingLabel className="mb-3 mb-lg-4" label={t("contractlistother.Amount")}>
                                    <Form.Control type="Text" aria-label="Amount" placeholder={t("contractlistother.Amount")} />
                                </FloatingLabel>
                                <Form.Check type="checkbox" className="mb-lg-4 mb-3" id={`culturalEncouraging`} label={t("paidTobepaid.Ithasbeenpaid")} />
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-sm-flex justify-content-end w-100 align-items-center">
                                    <Button variant="outline-primary" onClick={moreFiltersClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button variant="primary" onClick={moreFiltersClose}>
                                        {t("button.done")}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* /New recipe modal end */}


                    {/* New release modal start from here */}
                    <Modal show={showNewReleaseModal} onHide={newReleaseModalClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("bankTransaction.Newrelease")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel controlId="Maturity" className="mb-3 mb-lg-4" label={t("maturity")}>
                                    <Form.Control type="Date" placeholder={t("maturity")} />
                                </FloatingLabel>
                                <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                    <Form.Select aria-label="Account (category)">
                                        <option value="">{t("TransferPage.Tocarryout")}</option>
                                        <option value="">{t("TransferPage.Performed")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel controlId="Complement" className="mb-3 mb-lg-4" label={t("complement")}>
                                    <Form.Control type="Date" placeholder={t("complement")} />
                                </FloatingLabel>
                                <FloatingLabel controlId="Value" className="mb-3 mb-lg-4" label={t("value")}>
                                    <Form.Control type="Date" placeholder={t("value")} />
                                </FloatingLabel>
                                <Form.Group>
                                    <Form.Check type="checkbox" label={t("paidTobepaid.Ithasbeenpaid")} />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-sm-flex justify-content-end w-100 align-items-center">
                                    <Button variant="outline-primary" onClick={newReleaseModalClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button variant="primary" onClick={newReleaseModalClose}>
                                        {t("expenditure.tothrow")}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* New transfer modal start from here */}
                    <Modal show={showNewTransferModal} onHide={newTransferModalClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("bankTransaction.Newtransfer")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel controlId="date" className="mb-3 mb-lg-4" label={t("bankTransaction.date")}>
                                    <Form.Control type="date" placeholder={t("bankTransaction.date")} />
                                </FloatingLabel>
                                <FloatingLabel controlId="value" className="mb-3 mb-lg-4" label={t("value")}>
                                    <Form.Control type="text" placeholder={t("value")} />
                                </FloatingLabel>
                                <FloatingLabel label={t("in")} className="mb-3 mb-lg-4">
                                    <Form.Select aria-label="In">
                                        <option value="">{t("TransferPage.Tocarryout")}</option>
                                        <option value="">{t("TransferPage.Performed")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel label={t("notifications.for")} className="mb-3 mb-lg-4">
                                    <Form.Select aria-label="For">
                                        <option value="">{t("TransferPage.Tocarryout")}</option>
                                        <option value="">{t("TransferPage.Performed")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <Accordion className="accordion-custom">
                                    <Accordion.Item eventKey="17">
                                        <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                        <Accordion.Body>
                                            <FloatingLabel controlId="history" className="mb-3 mb-lg-4" label={t("bankTransaction.Historycomplement")}>
                                                <Form.Control type="text" placeholder={t("bankTransaction.Historycomplement")} />
                                            </FloatingLabel>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-sm-flex justify-content-end w-100 align-items-center">
                                    <Button variant="outline-primary" onClick={newTransferModalClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button variant="primary" onClick={newTransferModalClose}>
                                        {t("formLabel.transfer")}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* /New transfer modal end */}
                    {/* Import expense modal start from here */}
                    <Modal show={showImportExpenseModal} onHide={importExpenseModalClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("bankTransaction.ImportexpenseviaNF-e")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel controlId="invoice" className="mb-3 mb-lg-4" label={t("bankTransaction.Invoice(Xml)")}>
                                    <Form.Control type="file" placeholder={t("bankTransaction.Invoice(Xml)")} />
                                </FloatingLabel>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-sm-flex justify-content-end w-100 align-items-center">
                                    <Button variant="outline-primary" onClick={importExpenseModalClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button variant="primary" onClick={importExpenseModalClose}>
                                        {t("button.next")}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* /Import expense modal end */}

                    {/* Filter modal for mobile screens start here */}
                    <Modal show={show2} onHide={mobileFiltersClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("bankTransaction.Filter")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Accordion className="accordion-custom">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>{t("TransferPage.Filterby")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Filterby")}>
                                                    <Form.Select aria-label="Filter by">
                                                        <option value="">{t("TransferPage.Tocarryout")}</option>
                                                        <option value="">{t("TransferPage.Performed")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>{t("Dashboard.Owner")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("Dashboard.Owner")}>
                                                    <Form.Select aria-label="Owner">
                                                        {/* <option value="">{t("dropdownItem.physicalAndLegal")}</option> */}
                                                        <option value="">{t("dropdownItem.physical")}</option>
                                                        <option value="">{t("dropdownItem.legal")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>{t("TransferPage.Withpaidcharges")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Withpaidcharges")}>
                                                    <Form.Select aria-label="With paid charges">
                                                        <option value="">{t("formLabel.all")}</option>
                                                        <option value="">{t("Dashboard.Overdue")}</option>
                                                        <option value="">{t("bankTransaction.Inday")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>{t("bankTransaction.Withchargessettlededbetween")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("bankTransaction.Withchargessettlededbetween")}>
                                                    <Form.Control type="date" aria-label="With charges settled between"></Form.Control>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>{t("TransferPage.Duetotheduedatebetween")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Duetotheduedatebetween")}>
                                                    <Form.Control type="date" aria-label="Due to the due date between"></Form.Control>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>{t("ofthecontract")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("ofthecontract")}>
                                                    <Form.Control type="date" aria-label="Of the contracts"></Form.Control>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>{t("TransferPage.Fromtheowners")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Fromtheowners")}>
                                                    <Form.Control type="date" aria-label="From the owners"></Form.Control>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>{t("TransferPage.Withouttheowners")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Withouttheowners")}>
                                                    <Form.Control type="date" aria-label="Without the owners"></Form.Control>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="15">
                                            <Accordion.Header>{t("bankTransaction.Withpaymentstatus")}</Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Select aria-label="With payment status">
                                                    <option value="">{t("formLabel.all")}</option>
                                                    <option value="">{t("ReceiveandReceived.Paid")}</option>
                                                    <option value="">{t("bankTransaction.Pendingpayment")}</option>
                                                    <option value="">{t("bankTransaction.clearingcheck")}</option>
                                                </Form.Select>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="16">
                                            <Accordion.Header>{t("paymentSplit")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel className="mb-3 mb-lg-4" label={t("paymentSplit")}>
                                                    <Form.Select aria-label="Payment split">
                                                        <option value="">{t("formLabel.all")}</option>
                                                        <option value="">{t("bankTransaction.AutomaticforwardingviaSplit")}</option>
                                                        <option value="">{t("bankTransaction.Manualtransfer")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>{t("bankTransaction.Withoutguaranteedstatus")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("bankTransaction.Withoutguaranteedstatus")}>
                                                    <Form.Select aria-label="Without guaranteed status">
                                                        <option value="">{t("formLabel.all")}</option>
                                                        <option value="">Guaranteed</option>
                                                        <option value="">Unsecured</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header>{t("TransferPage.Withoutfinancialstatusofthecontract")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Withoutfinancialstatusofthecontract")}>
                                                    <Form.Select aria-label="Without financial status of the contract">
                                                        <option value="">Normal</option>
                                                        <option value="">Legal</option>
                                                        <option value="">Pending resources</option>
                                                        <option value="">{t("formLabel.all")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="10">
                                            <Accordion.Header>{t("TransferPage.Withoutcontractstatus")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Withoutcontractstatus")}>
                                                    <Form.Select aria-label="Without contract status">
                                                        <option value="">{t("formLabel.all")}</option>
                                                        <option value="">Active</option>
                                                        <option value="">Pending</option>
                                                        <option value="">Paused</option>
                                                        <option value="">Terminated and terminated</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="11">
                                            <Accordion.Header>{t("formLabel.propertyType")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("formLabel.propertyType")}>
                                                    <Form.Select aria-label="Property type">
                                                        <option value="">{t("formLabel.all")}</option>
                                                        <option value="">Home</option>
                                                        <option value="">Apartment</option>
                                                        <option value="">Duplex apartment</option>
                                                        <option value="">Triplex apartment</option>
                                                        <option value="">Common area</option>
                                                        <option value="">corporate floor</option>
                                                        <option value="">Bungalow</option>
                                                        <option value="">Shed</option>
                                                        <option value="">Box/Garage</option>
                                                        <option value="">Commercial house</option>
                                                        <option value="">House in condominium</option>
                                                        <option value="">Two-story house</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="12">
                                            <Accordion.Header>{t("TransferPage.Ofthebranches")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Ofthebranches")}>
                                                    <Form.Control type="date" aria-label="Of the branches"></Form.Control>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="13">
                                            <Accordion.Header>{t("TransferPage.OftheManagers")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.OftheManagers")}>
                                                    <Form.Control type="date" aria-label="Of the Manager"></Form.Control>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="14">
                                            <Accordion.Header>{t("TransferPage.Bynumberofproeprtiesperowner")}</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t("TransferPage.Bynumberofproeprtiesperowner")}>
                                                    <Form.Select aria-label="By number of proeprties per owner">
                                                        <option value="">{t("formLabel.all")}</option>
                                                        <option value="">{t("customerAndEmployees.Only")} 1 {t("customerAndEmployees.propertie")}</option>
                                                        <option value="">{t("ReceiveandReceived.with")} 2 {t("Links.properties")} </option>
                                                        <option value="">{t("ReceiveandReceived.with")} 3 {t("Links.properties")} </option>
                                                        <option value="">{t("ReceiveandReceived.with")} 4 {t("Links.properties")} </option>
                                                        <option value="">{t("ReceiveandReceived.with")} 5 {t("Links.properties")} </option>
                                                        <option value="">{t("ReceiveandReceived.with")} 6 {t("customerAndEmployees.ormoreproperties")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-primary" onClick={mobileFiltersClose}>
                                    {t("button.close")}
                                </Button>
                                <Button variant="primary" onClick={mobileFiltersClose}>
                                    {t("button.toSave")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* /Filter modal for mobile screens end */}
                    {/* Generate all start here */}
                    <Modal show={show3} onHide={generateModalShow}>
                        <Form>
                            <Modal.Body>
                                {t("Links.generateCharges")}?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-primary" onClick={generateModalClose}>
                                    {t("button.close")}
                                </Button>
                                <Button variant="primary" onClick={generateModalClose}>
                                    {t("bankTransaction.ok")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* /Generate all end */}

                    {/* issue check start from here */}
                    <Modal show={showIssueCheckModal} onHide={issueCheckModalClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("paidTobepaid.Issuecheck")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel label={t("banks.bankAccounts")}>
                                    <Form.Select aria-label="Bank account">
                                        <option value="">{t("formLabel.all")}</option>
                                        <option value="">{t("customerAndEmployees.Only")} 1 {t("customerAndEmployees.propertie")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-primary" onClick={issueCheckModalClose}>
                                    {t("button.close")}
                                </Button>
                                <Button variant="primary" onClick={issueCheckModalClose}>
                                    {t("button.save")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {/* /issue check modal end */}
                    <FinancialExpenseService state={state} type = "bankTransaction" />
                </section>
            }
        </>
    )
}
export default BankTransactions;