import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Offcanvas, Form, Button, Row, Col, Dropdown, Table, FloatingLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DeleteChargeItem from "./DeleteChargeItem";
import { getTenants, addBillingTicketCron } from '../../../../../redux/slice/contractDataSlice';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import customerService from '../../../../../services/customerService';
import Loader from '../../../../Profile/Company/Loader';
import Swal from 'sweetalert2';
import { gettoken } from '../../../../../Api/Contact';
import { converter } from "../../../../constants/utils";
import { useBankAccounts } from "../../../../Profile/Company/FormsOfReceipt/BankAccounts/hooks/useBankAccounts";
import { get_bank_slip } from "../../../../../redux/slice/formsOfReceiptSlice";
import { createHomologateBankSlip } from "../../../../../newComponents/features/Billing/services/createHomologateBankSlip";

const NewChargeGenerate = (props) => {
    const { tenantsData, contractDatail } = useSelector((state: RootState) => state.contractData)
    const { newChargeGenerateModalShow, showNewChargeGenerate, newChargeGenerateClose, generateMeatData, getAllContractCharge } = props;

    const dispatch = useDispatch<AppDispatch>();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [double, setDouble] = useState(false);
    const [loader_on, setloader_on] = useState(false)
    //Toggle Edit button
    const [show, toggleShow] = React.useState(true);
    const [showPrint,setShowPrint] = useState(false);
    const { bankAccounts } = useBankAccounts();
    const { Bankslip, formdata } = useSelector((state: RootState) => state.forms);
    
    useEffect(() => {
        dispatch(getTenants());
        setDouble(false);
        setShowPrint(false);
        setloader_on(false);
        if (generateMeatData.totalRow == '0.00') {
            setDouble(true);
        } 
        customerService.addBillingTicketCron(26);
        setTimeout(() => {
            setShowPrint(true);
        },5000);
        dispatch(get_bank_slip());
    }, [dispatch, generateMeatData])

    if (generateMeatData && showNewChargeGenerate === true) {
        newChargeGenerateModalShow();
    }
    //Delete item modal close open function
    const [showDeleteItemModal, setDeleteItemModal] = useState(false);
    const deleteItemModalClose = () => setDeleteItemModal(false);
    const deleteItemModalShow = () => setDeleteItemModal(true);

    const chargeGenerate = () => {

        if (id && typeof id !== "undefined") {
            Swal.fire({
                title: t("Sweetalert.Areyousurewanttogenerateit"),
                text: t("Sweetalert.Youwontabletomodifyitlater"),
                // icon: 'success','info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Generate it!', 
                customClass: {
                    htmlContainer: "Areyousure2want2to2generate2it"
                },
            }).then((result) => {
                if (result.value) {
                    setDouble(true);
                    setloader_on(true);
                    let data = { id: id, userId: gettoken.userId };
                    getAllContractCharge();
                    customerService.chargeGenerates(data).then(async res => {
                        /*-------Check the api response--------*/
                        if (res.data.resCode === 202) {
                          const hasHomologateBillet = generateMeatData.data
                            ?.at(0)
                            ?.expense.find((expense) => expense.payment === 28);

                          if (hasHomologateBillet) {
                            try {
                              const shouldCreateCharge =
                                parseFloat(generateMeatData.totalRow) > 0;

                              if (!shouldCreateCharge) return;

                              const homologatedBankAccount = bankAccounts.find(
                                (bankAccount) =>
                                  bankAccount.nameReference ===
                                  generateMeatData.data[0].nameReference
                              );

                              const homologatedAccount =
                                homologatedBankAccount?.homologatedAccount;

                              console.log({
                                homologatedBankAccount,
                                homologatedAccount,
                              });

                              if (!homologatedAccount) return;

                              const sacadoInfos: any = tenantsData.find(
                                (tenant: any) =>
                                  tenant.id ===
                                  generateMeatData.data[0].datatenants
                                    .tenants[0].ID_PESSOA_PES
                              );

                              if (!sacadoInfos) return;

                              const getSacadoAddress = () => {
                                return {
                                  numero: sacadoInfos.number ?? "",
                                  bairro:
                                    sacadoInfos.neighborhood?.replace(
                                      /[^a-zA-Z0-9\s]/g,
                                      ""
                                    ) ?? "",
                                  cep:
                                    sacadoInfos.zipcode?.replace(
                                      /[^a-zA-Z0-9\s]/g,
                                      ""
                                    ) ?? "",
                                  cidade: sacadoInfos.city ?? "",
                                  complemento:
                                    sacadoInfos.complement?.replace(
                                      /[^a-zA-Z0-9\s]/g,
                                      ""
                                    ) ?? "",
                                  endereco:
                                    sacadoInfos.address?.replace(
                                      /[^a-zA-Z0-9\s]/g,
                                      ""
                                    ) ?? "",
                                  pais: "Brasil",
                                  uf: sacadoInfos.state ?? "",
                                };
                              };

                              const {
                                numero,
                                bairro,
                                cep,
                                cidade,
                                complemento,
                                endereco,
                                pais,
                                uf,
                              } = getSacadoAddress();

                              const { Billing_Instructions, messageticket } =
                                Bankslip[0];

                              const dataEmissao = new Date();
                              dataEmissao.setDate(dataEmissao.getDate() - 1);

                              const dataCustomizada = (
                                dataString: string,
                                dias: number = 1
                              ) => {
                                const [dia, mes, ano] = dataString
                                  .split("/")
                                  .map(Number);
                                const data = new Date(ano, mes - 1, dia);

                                data.setDate(data.getDate() + dias);

                                const novoDia = String(data.getDate()).padStart(
                                  2,
                                  "0"
                                );
                                const novoMes = String(
                                  data.getMonth() + 1
                                ).padStart(2, "0");
                                const novoAno = data.getFullYear();

                                return `${novoDia}/${novoMes}/${novoAno}`;
                              };

                              const dataDeAplicacaoDoDesconto = (
                                dataString: string
                              ) => {
                                if (formdata.give_discount === 0)
                                  return dataString;

                                return dataCustomizada(
                                  dataString,
                                  formdata.give_discount
                                );
                              };

                              const data = res.data.data[0];

                              await createHomologateBankSlip({
                                CedenteContaNumero: homologatedAccount.conta,
                                CedenteContaNumeroDV:
                                  homologatedAccount.conta_dv,
                                CedenteConvenioNumero:
                                  homologatedAccount.convenios?.at(0)
                                    ?.numero_convenio ?? "",
                                CedenteContaCodigoBanco:
                                  homologatedAccount.codigo_banco,
                                SacadoCPFCNPJ:
                                  sacadoInfos.cnpj?.replace(/[.\-/]/g, "") ??
                                  "",
                                SacadoEmail: sacadoInfos.email,
                                SacadoEnderecoNumero: numero,
                                SacadoEnderecoBairro: bairro,
                                SacadoEnderecoCEP: cep,
                                SacadoEnderecoCidade: cidade,
                                SacadoEnderecoComplemento: complemento,
                                SacadoEnderecoLogradouro: endereco,
                                SacadoEnderecoPais: pais,
                                SacadoEnderecoUF: uf,
                                SacadoNome: sacadoInfos.name,
                                SacadoTelefone: sacadoInfos.telephone
                                  ? sacadoInfos.telephone.replace(
                                      /[\s(),-]/g,
                                      ""
                                    )
                                  : sacadoInfos.mobile.replace(/[\s(),-]/g, ""),
                                SacadoCelular: sacadoInfos.mobile.replace(
                                  /[\s(),-]/g,
                                  ""
                                ),
                                TituloDataEmissao:
                                  dataEmissao.toLocaleDateString("pt-BR"),
                                TituloDataVencimento:
                                  data.DT_VENCIMENTO_RECB.split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/"),
                                TituloMensagem01: Billing_Instructions || "-",
                                TituloNossoNumero:
                                  data.ID_RECEBIMENTO_RECB.toString(),
                                TituloNumeroDocumento:
                                  data.ID_RECEBIMENTO_RECB.toString(),
                                TituloValor: Number(data.VL_TOTAL_RECB)
                                  .toFixed(2)
                                  .replace(".", ","),
                                TituloLocalPagamento: "-",
                                IdRecebimento: data.ID_RECEBIMENTO_RECB,
                                ...(Number(data.VL_TXDESCONTO_RECB) > 0 && {
                                  TituloCodDesconto: formdata.use_percentage
                                    ? 2
                                    : 1,
                                  TituloDataDesconto: dataDeAplicacaoDoDesconto(
                                    data.DT_VENCIMENTO_RECB.split("-")
                                      .reverse()
                                      .join("/")
                                  ),
                                  TituloValorDescontoTaxa: Number(
                                    data.VL_TXDESCONTO_RECB
                                  )
                                    .toFixed(2)
                                    .replace(".", ","),
                                  TituloValorDesconto: Number(
                                    data.VL_TXDESCONTO_RECB
                                  )
                                    .toFixed(2)
                                    .replace(".", ","),
                                }),
                                ...(Number(data.VL_TXMULTA_RECB) > 0 && {
                                  TituloCodigoMulta: 2,
                                  TituloDataMulta: dataCustomizada(
                                    data.DT_VENCIMENTO_RECB.split("-")
                                      .reverse()
                                      .join("/")
                                  ),
                                  TituloValorMultaTaxa: Number(
                                    data.VL_TXMULTA_RECB
                                  )
                                    .toFixed(2)
                                    .replace(".", ","),
                                }),
                                ...(Number(data.VL_TXJUROS_RECB) > 0 && {
                                  TituloCodigoJuros: 1,
                                  TituloDataJuros: dataCustomizada(
                                    data.DT_VENCIMENTO_RECB.split("-")
                                      .reverse()
                                      .join("/")
                                  ),
                                  TituloValorJuros: (
                                    Number(data.VL_TXJUROS_RECB) / 30
                                  )
                                    .toFixed(2)
                                    .replace(".", ","),
                                }),
                                ...(homologatedBankAccount.titulo_variacao_carteira && {
                                  TituloVariacaoCarteira:
                                    homologatedBankAccount.titulo_variacao_carteira,
                                }),
                                ...(homologatedBankAccount.titulo_doc_especie && {
                                  TituloDocEspecie:
                                    homologatedBankAccount.titulo_doc_especie,
                                }),
                                ...(homologatedBankAccount.titulo_aceite && {
                                  TituloAceite:
                                    homologatedBankAccount.titulo_aceite,
                                }),
                                ...(homologatedBankAccount.titulo_codigo_baixa_devolucao && {
                                  TituloCodBaixaDevolucao:
                                    homologatedBankAccount.titulo_codigo_baixa_devolucao,
                                }),
                                ...(homologatedBankAccount.titulo_prazo_baixa && {
                                  TituloPrazoBaixa:
                                    homologatedBankAccount.titulo_prazo_baixa,
                                }),
                              });
                            } catch (error: any) {
                              Swal.fire({
                                title: t("Sweetalert.Error"),
                                html: `
                                        <p>Ocorreu um erro ao gerar o boleto homologado. Por favor, acesse a página de cobranças a receber para editar as informações da cobrança e tente novamente.</p>
                                        <br />
                                        <strong>${error.message}</strong>
                                    `,
                                confirmButtonText: "OK",
                                showDenyButton: true,
                                denyButtonText: "Ir para cobranças a receber",
                              }).then((result) => {
                                if (result.isDenied) {
                                  navigate("/receivable_charges");
                                }
                              });
                            } finally {
                              setloader_on(false);
                              setDouble(false);
                              Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: t("Sweetalert.somethingWent"),
                                // icon:"error",
                                confirmButtonText: "OK",
                                timer: 2000,
                              });
                            }
                          }

                          setDouble(false);
                          setloader_on(false);
                          newChargeGenerateClose();
                          Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.successget"),
                            // icon:"success",
                            confirmButtonText: "OK",
                            timer: 2000,
                          });
                          customerService.addBillingTicketCron(
                            res.data.data[0].id
                          );
                        } else {
                          setloader_on(false);
                          setDouble(false);
                          Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon:"error",
                            confirmButtonText: "OK",
                            timer: 2000,
                          });
                        }
                    }).catch(error => {
                        /*-------Error exception handling--------*/
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon:"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    });
                }
            })
        }
    }
    const loadSimulate = (url)=>{
        if(url){
            const newWindow: any = window.open(url, '_blank');
            newWindow.addEventListener('load', () => {
                newWindow.print();
            });
        }
    }
    return (
        <>
            <Offcanvas
                className="border-sm-none"
                show={showNewChargeGenerate}
                onHide={newChargeGenerateClose}
                placement="end"
            >
                <Form className="d-flex flex-column h-100" id="templatenwChargeGnrate">
                    <Offcanvas.Header className="border-bottom">
                        <Offcanvas.Title className="fw-bold d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center w-100">
                            {t("chargeSimulation")}
                            <div className="text-primary ms-sm-auto d-flex justify-sm-end justify-content-between align-items-center">
                                {   generateMeatData.simulatePathUrl && showPrint ? 
                                        <span className="me-3 me-lg-4 fs-6 fw-bold" style={{ cursor:"pointer"}} onClick={()=>loadSimulate(generateMeatData.simulatePathUrl) }>{t("newTransfer.printSimulation")}</span>
                                    :
                                        ''
                                }
                                <span onClick={newChargeGenerateClose} className="cursor-pe">
                                    <img
                                        src="../assets/images/icons/cross.svg"
                                        alt="Close"
                                        className="h-16"
                                    />
                                </span>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="d-sm-flex align-items-center justify-content-between mb-3 mb-lg-4">
                            <h6 className="mb-0 fw-bold">
                                {t("dueDate")}  {t("from")} {generateMeatData.start ? generateMeatData.start : ''} {t("to")} { generateMeatData.end ? generateMeatData.end : ''}
                            </h6>
                            {/*<ul className="mb-0 list-inline fw-bold">
                            <li className="list-inline-item">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                        { t("NonPayment.sorting") }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Alphabetical order</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Vencimento mais próximo</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>*/}
                        </div>
                        {generateMeatData.data && generateMeatData.data.length > 0 ? generateMeatData.data.map((item: any, index) => (item.isRentShow == 1 || item.isExpneseShow == 1 ?

                            <div key={index} className="border border-light rounded-4 p-lg-4 p-3 mb-lg-4 mb-3">
                                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                                    <h6 className="mb-0 fw-bold">{t("contract")} {item.ST_IDENTIFICADOR_IMO ? item.ST_IDENTIFICADOR_IMO : item.id} / { item.lease_count ? item.lease_count : 1 }</h6>
                                    {/*<ul className="mb-0 list-inline fw-bold">
                                        <li className="list-inline-item">
                                            <Button
                                                variant="link"
                                                onClick={() => toggleShow(!show)}
                                                className="p-0 fw-bold"
                                            >
                                                {show ? "Edit" : "Save"}
                                            </Button>
                                        </li>
                                    </ul>*/}
                                </div>
                                <ul className="list-inline d-flex flex-wrap">
                                    <li className="list-inline-item border-end border-sm-none me-3 me-xl-4 pe-xl-4 pe-md-3">
                                        <p className="fs-12 text-secondary mb-1">{t("dueDate")}</p>
                                        <h6 className="mb-md-0">{item.rentDate}</h6>
                                    </li>
                                    <li className="list-inline-item border-end border-sm-none me-3 me-xl-4 pe-xl-4 pe-md-3">
                                        <p className="fs-12 text-secondary mb-1">{t("newTransfer.indentifier")}</p>
                                        <h6 className="mb-md-0">{item.ST_IDENTIFICADOR_IMO}</h6>
                                    </li>
                                    <li className="list-inline-item">
                                        <p className="fs-12 text-secondary mb-1">{t("tenant")}</p>
                                        <h6 className="mb-md-0">
                                            {
                                                // tenantsData && tenantsData.length > 0 &&
                                                item.datatenants?.["tenants"] &&
                                                item.datatenants?.["tenants"].map((itemsingle, index) => {
                                                    // let pickData = tenantsData && tenantsData.length > 0 && tenantsData.filter(item => Number(item?.["id"]) === Number(itemsingle?.["ID_PESSOA_PES"]));
                                                    // return (
                                                    //     <>
                                                    //         <Row key={index}>
                                                    //             {pickData?.["0"]?.["name"]}
                                                    //         </Row>
                                                    //     </>
                                                    // )
                                                    return (
                                                        <>
                                                            <Row key={index}>
                                                                { itemsingle.ST_NOME_PES }
                                                            </Row>
                                                        </>
                                                    )
                                                })
                                            }
                                        </h6>
                                    </li>
                                </ul>
                                <p className="fs-12 text-secondary mb-1">{t("Property")}</p>
                                <h6 className="mb-0">{item.propertyType} in {item.address}, {item.city} {item.state} {item.number}</h6>
                                <div className="d-block">
                                    {/*<hr className="w-20" />*/}
                                    <hr />
                                </div>
                                {show && (
                                    <>
                                        <Table borderless>
                                            <thead>
                                                <tr>
                                                    <th className="text-secondary ps-0 py-0">
                                                        {t("chargeDescription")}
                                                    </th>
                                                    <th className="text-secondary py-0">
                                                        {t("adminFee")} ({item.simble || 'R$'})
                                                    </th>
                                                    <th className="text-secondary text-end pe-0 py-0">
                                                        {t("formLabel.value")} (R$)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="tt-2251">
                                                <tr >
                                                    <td className="ps-0 pb-0">
                                                        <span className="d-block mb-1">
                                                            {t("newTransfer.standardRent")}
                                                        </span>
                                                    </td>
                                                    <td className="pb-0">
                                                        <span className="d-block text-nowrap">{item.simble == '%' ? `${converter(String(item.admPercentage))}%` : `R$ ${converter(String(item.admPercentage))}`}</span>
                                                    </td>
                                                    <td className="text-end pe-0 pb-0"><span className="d-block text-nowrap">R$ {converter(String(item.rent))}</span></td>
                                                </tr>
                                                {item.preMonthRent != 0 &&
                                                    <tr>
                                                        <td className="ps-0 pb-0">
                                                            <span className="d-block mb-1">
                                                                {t("proportionRent")}
                                                            </span>
                                                        </td>
                                                        <td className="pb-0">
                                                            R$ {converter(String("000"))}
                                                        </td>
                                                        <td className="text-end pe-0 pb-0"><span className="d-block text-nowrap">R$ {converter(String(item.preMonthRent))}</span></td>
                                                    </tr>
                                                }
                                                {
                                                    item.boletoFee > 0 && item.isRentShow &&
                                                    <tr >
                                                        <td className="ps-0 pb-0">
                                                            <span className="d-block mb-1">
                                                                {t('chargeBankFee')}
                                                            </span>
                                                        </td>
                                                        <td className="pb-0">
                                                            R$ {converter(String("000"))}
                                                        </td>
                                                        <td className="text-end pe-0 pb-0"><span className="d-block text-nowrap">R$ +{converter(String(item.boletoFee))}</span></td>
                                                    </tr>
                                                }
                                                {item.taxAmt ?
                                                    <tr >
                                                        <td className="ps-0 pb-0">
                                                            <span className="d-block mb-1">
                                                                IR
                                                            </span>
                                                        </td>
                                                        <td className="pb-0">
                                                            R$ {converter(String("000"))}
                                                        </td>
                                                        <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ -{converter(String(item.taxAmt))}</span></td>
                                                    </tr>
                                                    : ''
                                                } 
                                                {
                                                    item.expense && item.expense.length > 0 ?
                                                        item.expense.map((items: any, indexs) => {
                                                            return (
                                                                // <p key={indexs} className="d-block cursor-pe" >{items.productName + '  --  R$ '} {items.expenseValue}</p>
                                                                <>
                                                                    <tr key={indexs} >
                                                                        <td className="ps-0 pb-0">
                                                                            <span className="d-block mb-1">
                                                                                {items.productName ? ( items.productName +' ' +(items.complement && ( (items.complement).indexOf('1/1') >= 0 && (item.complement).indexOf(10) < 0 ) ? '' : items.complement ) ) : 'Service'}
                                                                            </span>
                                                                        </td>
                                                                        <td className="pb-0">
                                                                            <span className="d-block text-nowrap">{items.simble == '%' ? `${converter(String(items.admPercentage))}%` : `R$ ${converter(String(items.admPercentage))}`}</span>
                                                                        </td>
                                                                        <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(items.expenseSimAmt))}</span></td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        ''
                                                }
                                                <tr>
                                                    <td className="fw-bold ps-0 pb-0">{t("totalCharge")}</td>
                                                    <td colSpan={2} className="fw-bold text-end pe-0 pb-0">R$ {converter(String(item.totalRentExpnse))}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <hr />
                                        <Table borderless>
                                            <thead>
                                                <tr>
                                                    <th className="text-secondary ps-0 py-0">
                                                        {t("transferDescription")}
                                                    </th>
                                                    <th className="text-secondary py-0">
                                                        {t("adminFee")} ({item.simble || 'R$'})
                                                    </th>
                                                    <th className="text-secondary text-end pe-0 py-0">
                                                        {t("formLabel.value")} (R$)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="tt-2252">
                                                {
                                                    item.transferResult && item.transferResult.length > 0 ?
                                                        item.transferResult.map((items: any, indexs) => {
                                                            return (
                                                                // <p key={indexs} className="d-block cursor-pe" >{items.productName + '  --  R$ '} {items.expenseValue}</p>
                                                                <>
                                                                    <tr key={indexs} >
                                                                        <td className="ps-0 pb-0">
                                                                            <span className="d-block mb-1">
                                                                                {items.title && items.title == "Rent" ? t("newTransfer.standardRent") : items.title == "Interest" ? t('formReceipt.allFees') : items.title == "Fine" ? t('formReceipt.Fine') : items.title == "Boleto Fee" ? t('chargeBankFee') : items.title == "Bank Fee" ? t('chargeDocBankFee') : ( items.title +' ' + (items.complement && ( (items.complement).indexOf('1/1') >= 0 && (item.complement).indexOf(10) < 0 ) ? '' : items.complement ) ) }
                                                                            </span>
                                                                        </td>
                                                                        <td className="pb-0">
                                                                            <span className="d-block text-nowrap">{items.simble == '%' ? `${converter(String(items.admPercentage))}%` : `R$ ${converter(String(items.admPercentage))}`}</span>
                                                                        </td>
                                                                        <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(items.transferAmount)) }</span></td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        ''
                                                }
                                                <tr>
                                                    <td className="fw-bold ps-0 pb-0">{t("totalTransfer")}</td>
                                                    <td colSpan={2} className="fw-bold text-end pe-0 pb-0">R$ {converter(String(item.totalTransferAmt))}</td>
                                                </tr>

                                                {
                                                    item.totalNxtRentalFee ?
                                                        <tr>
                                                            <td className="mb-1 mb-sm-0">{t("nextRentalFee")}</td>
                                                            <td colSpan={2} className="fw-bold text-end pe-0 pb-0">R$ {Math.abs(item.totalNxtRentalFee)}</td>
                                                        </tr>
                                                        : ''
                                                }
                                            </tbody>
                                        </Table>
                                        <div className="d-block">
                                            <hr className="w-20" />
                                        </div>
                                        <div className="d-sm-flex align-items-center justify-content-between mb-3">
                                            <span className="mb-1 mb-sm-0">
                                                {t("Links.bankAccounts")}
                                            </span>
                                            <span>{item.nameReference + '-' + item.account}</span>
                                        </div>
                                        <Form.Group className="bg-light rounded-4 p-2 mt-3 mt-lg-4 min-h-56 d-flex flex-wrap align-items-center">
                                            <Form.Check id="verified_mark_2" label={t("newTransfer.markVerified")} />
                                        </Form.Group>
                                    </>
                                )}
                            </div>
                            :
                            ''
                        )) :
                            <div className="border border-light rounded-4 p-lg-4 p-3 mb-lg-4 mb-3">
                                <span>{t('formLabel.noRecordsFounds')}</span>
                            </div>
                        }

                        <h6 className="fw-bold mb-3 mb-lg-4 pt-lg-3 pt-2">{t("ReceiveandReceived.Summary")}</h6>
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td className="ps-0 py-0">{t("numberChargesGenerated")}</td>
                                    <td className="text-end pe-0 py-0">{generateMeatData.data && generateMeatData.data.length ? generateMeatData.data.length : 0}</td>
                                </tr>
                                <tr>
                                    <td className="ps-0 pb-0">{t("chargeGenerated")}</td>
                                    <td className="text-end pe-0 pb-0">{generateMeatData.data && generateMeatData.data.length ? generateMeatData.data.length : 0}</td>
                                </tr>
                                <tr>
                                    <td className="ps-0 pb-0">{t("clientBilled")}</td>
                                    <td className="text-end pe-0 pb-0">{generateMeatData.data && generateMeatData.data.length ? generateMeatData.data.length : 0}</td>
                                </tr>
                                <tr>
                                    <td className="ps-0 pb-0">{t("amountChargesGenerated")}</td>
                                    <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {generateMeatData.totalRow ? converter(String(generateMeatData.totalRow)) : '0,00'}</span></td>
                                </tr>
                                <tr>
                                    <td className="ps-0 pb-0">{t("amountWithholdings")}</td>
                                    <td className="text-end pe-0 pb-0">R$ 0,00</td>
                                </tr>
                                <tr>
                                    <td className="ps-0 pb-0">{t("numberCharges")}</td>
                                    <td className="text-end pe-0 pb-0">0,00</td>
                                </tr>
                                <tr>
                                    <td className="ps-0 pb-0">{t("amountCharges")}</td>
                                    <td className="text-end pe-0 pb-0">R$ 0,00</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {loader_on ?
                            <Button className="btn-width" disabled={double}>
                                <span className="spinner-border spinner-border-sm"></span>
                                <span className="px-1">{t("generateSimulation")}</span>
                            </Button>
                            :
                            <Button disabled={double}
                                type="button"
                                className="min-w-auto ms-2"
                                onClick={chargeGenerate}
                            >
                                {t("generateCharge")}
                            </Button>
                        }
                    </div>
                </Form>
            </Offcanvas>
            <DeleteChargeItem showDeleteItem={showDeleteItemModal} deleteItemClose={deleteItemModalClose} />
        </>
    )
}

export default NewChargeGenerate;