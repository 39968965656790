import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const SaveEditChanges = (props) => {
    //Save edit changes modal close open function
    const { showSaveEditChanges, saveEditChangesClose } = props;
    const [t] = useTranslation();
    return (
        <>
            <Modal show={showSaveEditChanges} onHide={saveEditChangesClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("newTransfer.validityTransferEdits")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-2 pt-0">
                    {t("newTransfer.validityTransferEditsContent")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={saveEditChangesClose}>
                        {t("newTransfer.allSubsequentTransfers")}
                        </Button>
                        <Button variant="primary" onClick={saveEditChangesClose}>
                        {t("newTransfer.onlyCurrentTransfer")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default SaveEditChanges
