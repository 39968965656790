import React from 'react';
import { Row, Col, FloatingLabel, Button } from 'react-bootstrap';

const CompanyDetailsForm = ({ getValues, errors, register, t, onEditAddress }) => (
    <>
        <Row className="mb-4">
            <Col md={6}>
                <p className="fs-12 mb-1 text-secondary">{t("Imobiliária")}</p>
                <h6 className="mb-0">{getValues('companyName')}</h6>
            </Col>
            <Col md={6}>
                <p className="fs-12 mb-1 text-secondary">{t("CNPJ")}</p>
                <h6 className="mb-0">{getValues('companyDocument')}</h6>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col md={6}>
                <p className="fs-12 mb-1 text-secondary">{t("Endereço")}</p>
                <h6 className="mb-0">{getValues('companyAdress')}</h6>
            </Col>
            <Col md={6}>
                <Button variant="link" className="text-primary fw-bold d-flex align-items-center" onClick={onEditAddress}>
                    <img src="assets/images/icons/edit-primary.svg" alt="Editar endereço" className="me-1 h-20" />
                    {t("Editar endereço")}
                </Button>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col md={4}>
                <p className="fs-12 mb-1 text-secondary">{t("CEP")}</p>
                <h6 className="mb-0">{getValues("companyZipCode") || "N/A"}</h6>
            </Col>
            <Col md={4}>
                <p className="fs-12 mb-1 text-secondary">{t("Cidade")}</p>
                <h6 className="mb-0">{getValues("companyCity") || "N/A"}</h6>
            </Col>
            <Col md={4}>
                <p className="fs-12 mb-1 text-secondary">{t("Estado")}</p>
                <h6 className="mb-0">{getValues("companyState") || "N/A"}</h6>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <FloatingLabel controlId="floatingInput" label={t("CPF do responsável pela imobiliária na Receita Federal")}>
                    <input
                        maxLength={14}
                        type="text"
                        className={`contractdetailinput form-control ${errors.companyResponsibleCPF ? "is-invalid" : ""}`}
                        {...register("companyResponsibleCPF")}
                    />
                </FloatingLabel>
            </Col>
        </Row>
    </>
);

export default CompanyDetailsForm;