import React, { useState } from 'react';
import { FloatingLabel, Col, Row, Modal, Form, Button, Accordion } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';

const ChargesFilter = (props) => {
  const [t] = useTranslation();
  //Desktop filters modal close open function
  const { showDesktopModal, desktopModalClose, desktopModalCloseDone, desktopModalShow, allmultipleFilter, filterData, setFilterData, allfilter, setAllFilter } = props;
  const { billingData } = useSelector((state: RootState) => state.expenditureData);
  const handleSubmit = (e) => {
    e.preventDefault();
    desktopModalCloseDone();
  };
  return (
    <Modal show={showDesktopModal} onHide={desktopModalClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t("dropdownItem.filters")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion className="accordion-custom">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("banks.bankAccounts")}</Accordion.Header>
              <Accordion.Body>
                <MultiSelect
                  options={allfilter.bank_account}
                  value={filterData.bank_account}
                  onChange={(item) => allmultipleFilter(item, "bank_account")}
                  labelledBy={t("banks.bankAccounts")}
                  overrideStrings={{
                    selectSomeItems: `${t("formLabel.select")}`,
                    selectAll: `${t("selectAll")}`,
                    search: `${t("formLabel.typeSearch")}`,
                    allItemsAreSelected: `${t("formLabel.allItemsSelected")}`,
                  }} // <- to override strings
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("ReceiveandReceived.Billingpaymentmethod")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="billing_payment" label={t("ReceiveandReceived.Billingpaymentmethod")}>
                  <Form.Select onChange={(e: any) => allmultipleFilter(e.target.value, "billing_payment")} aria-label="Billing Payment Method">
                    <option value="">{t("formLabel.select")}</option>
                    {/* {
                      allfilter.billing_payment.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{item.label}</option>
                      ))
                    } */}
                    {
                      billingData.data && billingData.data.length > 0 ? billingData.data.map((item) => (
                        <option key={item.id} value={Number(item.id)}>{item.name}</option>
                      )) : ''
                    }
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="2">
              <Accordion.Header>{t("ReceiveandReceived.Customerpaymentmethod")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="customer_payment" label={t("ReceiveandReceived.Customerpaymentmethod")}>
                  <Form.Select onChange={(e: any) => allmultipleFilter(e.target.value, "customer_payment")} aria-label="Customer payment method">
                    <option value="">{t("formLabel.select")}</option>
                    {
                      allfilter.customer_payment.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{item.label}</option>
                      ))
                    }
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item> */}
            {/* <Accordion.Item eventKey="3">
              <Accordion.Header>{t("branches.branch")}</Accordion.Header>
              <Accordion.Body>
                <MultiSelect
                  options={allfilter.branch}
                  value={filterData.branch}
                  onChange={(item) => allmultipleFilter(item, "branch")}
                  labelledBy={t("branches.branch")}
                />
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="4">
              <Accordion.Header>{t("ReceiveandReceived.Markedforshipment")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="marked_shipment" label={t("ReceiveandReceived.Markedforshipment")}>
                  <Form.Select onChange={(e: any) => allmultipleFilter(e.target.value, "marked_shipment")} aria-label="Marked for shipment">
                    <option value="">{t("formLabel.select")}</option>
                    {
                      allfilter.marked_shipment.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{t(`${item.label}`)}</option>
                      ))
                    }
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="5">
              <Accordion.Header>{t("ReceiveandReceived.Deliverymethods")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="delivery_method" label={t("ReceiveandReceived.Deliverymethods")}>
                  <Form.Select onChange={(e: any) => allmultipleFilter(e.target.value, "delivery_method")} aria-label="Delivery methods">
                    <option value="">{t("formLabel.select")}</option>
                    {
                      allfilter.delivery_method.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{item.label}</option>
                      ))
                    }
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item> 
            <Accordion.Item eventKey="6">
              <Accordion.Header>{t("ReceiveandReceived.Groundsforinvalidation")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="ground_invalid" label={t("ReceiveandReceived.Groundsforinvalidation")}>
                  <Form.Select onChange={(e: any) => allmultipleFilter(e.target.value, "ground_invalid")} aria-label="Grounds for invalidation">
                    <option value="">{t("formLabel.select")}</option>
                    {
                      allfilter.ground_invalid.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{item.label}</option>
                      ))
                    }
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item>*/}
            {/* <Accordion.Item eventKey="7">
              <Accordion.Header>{t("withSplit")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="with_split" label={t("withSplit")}>
                  <Form.Select onChange={(e: any) => allmultipleFilter(e.target.value, "with_split")} aria-label="With Split">
                    <option value="">{t("formLabel.select")}</option>
                    {
                      allfilter.with_split.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{t(`${item.label}`)}</option>
                      ))
                    }
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item> */}
            {/* <Accordion.Item eventKey="8">
              <Accordion.Header>{t("ReceiveandReceived.Typeofcreditcardfailure")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="credit_card_failure" label={t("ReceiveandReceived.Typeofcreditcardfailure")}>
                  <Form.Select onChange={(e: any) => allmultipleFilter(e.target.value, "credit_card_failure")} aria-label="Type of credit card failure">
                    <option value="">{t("formLabel.select")}</option>
                    {
                      allfilter.credit_card_failure.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>{item.label}</option>
                      ))
                    }
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>{t("ReceiveandReceived.Creditcardmistakes")}</Accordion.Header>
              <Accordion.Body>
                <MultiSelect
                  options={allfilter.credit_card_mistake}
                  value={filterData.credit_card_mistake}
                  onChange={(item) => allmultipleFilter(item, "credit_card_mistake")}
                  labelledBy={t("ReceiveandReceived.Creditcardmistakes")}
                />
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={desktopModalClose}>
            {t("button.resetfilter")}
          </Button>
          <Button variant="primary" type="submit">
            {t("button.done")}
          </Button>
          {/* <Button variant="primary" type='submit'>
            {t("button.toSave")} 
          </Button> */}
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ChargesFilter
