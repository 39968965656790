import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { witnessClose, insertWitness, envelopeOpen } from "../../../../redux/slice/propertyDataSlice";
import { newElectronicEnvelopeOpen } from "../../../../redux/slice/contractDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { witnessForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';

const WitnessService = (witnessForm) => {
  const [hide, setHide] = useState(false);
  const [hide1, setHide1] = useState(true);
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const { witnessShow } = useSelector((state: RootState) => state.propertyData)
  const { witnessType } = useSelector((state: RootState) => state.contractData)
  /*------------Property witness modal close-----------*/

  const step1 = () => { setHide(false); setHide1(true); }
  const step2 = () => { setHide(true); setHide1(false); }
  const newWitnessClose = () => {
    let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
    zipCodeCheck?.["removeAttribute"]('disabled');
    if (witnessType === "contractEvelope") {
      dispatch(newElectronicEnvelopeOpen(true));
    }
    else {
      setHide(false);
      setHide1(true);
      dispatch(envelopeOpen(true));
    }
    dispatch(witnessClose(false));
    reset();
  }

  /*------------Form validation-----------*/

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const envelopeValidationStep1 = Yup.object().shape({
    cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
  });
  const envelopeValidationStep2 = Yup.object().shape({
    name: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    mobile: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
      .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
    telephone: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
      .matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
    email: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
      .email(t('requiredMmessage.fieldNotValid')),
    rg: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    issuingAgency: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    shippingDate: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    sex: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    birth: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    maritalStatus: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    nationality: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
  });
  let frm = {
    resolver: yupResolver(envelopeValidationStep1)
  }
  if (hide) {
    frm = {
      resolver: yupResolver(envelopeValidationStep2)
    }
    openErrorbox()
  }

  /*------------Submit property witness form data-----------*/

  const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<witnessForm>(frm);
  const HandleInput = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('telephone');
    }
  }
  const HandleInputMob = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("mobile", {
        type: "manual",
        message: `${t("requiredMmessage.mobileError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('mobile');
    }
  }
  const onSubmit = async (data: witnessForm, e: any) => {
    e.preventDefault();
    let response = await dispatch(insertWitness(data));
    if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      newWitnessClose();
    }
    else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }

  const handleCPF_CNJPVaue = async (e, type) => {
    let name = e.target.name;
    let checkCNJP = CNJP_CPFValidation(e.target.value, type);
    if (checkCNJP?.["status"] || e.target.value == "") {
      clearErrors(name);
      let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value,  "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
    } else {
      // errorCNPJCPF(e.target.value)
      setError(name, {
        type: "manual",
        message: checkCNJP?.["message"]
      });
    };
  }

  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={witnessShow} onHide={newWitnessClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("envelop.Newwitness")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
            <div data-step="1" style={{ display: hide ? 'none' : 'block' }}>
              <FloatingLabel label="CNPJ/CPF">
                <Form.Control type="text" id="cnpj" placeholder="CNPJ/CPF" className={`form-control ${errors.cnpj ? 'is-invalid' : ''}`} {...register("cnpj")} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback >
              </FloatingLabel>
            </div>
            <div data-step="2" style={{ display: hide1 ? 'none' : 'block' }}>
              <FloatingLabel className="mb-3" label={t("formLabel.name")}>
                <Form.Control type="text" id="name" placeholder={t("formLabel.name")} className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register("name")} />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback >
              </FloatingLabel>
              <span className="d-block mb-3 fw-bold">{t("formLabel.contact")}</span>
              <Row>
                <Col>
                  <FloatingLabel className="mb-3" label={t("formLabel.mobileNumber")}>
                    <Form.Control type="text" id="mobile" placeholder={t("formLabel.mobileNumber")} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`} {...register("mobile")} onChange={HandleInputMob} />
                    <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="mb-3" label={t("formLabel.telephone")}>
                    <Form.Control type="text" id="telephone" placeholder={t("formLabel.telephone")} className={`form-control ${errors.telephone ? 'is-invalid' : ''}`} {...register("telephone")} onChange={HandleInput} />
                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel className="mb-3" label={t("formLabel.email")}>
                <Form.Control type="email" id="email" placeholder={t("formLabel.email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email")} />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback >
              </FloatingLabel>
              <Accordion className="accordion-custom">
                <Accordion.Item eventKey="1" >
                  <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <FloatingLabel className="mb-3" label={t("RG")}>
                          <Form.Control id="rg" type="text" placeholder={t("RG")} className={`form-control ${errors.rg ? 'is-invalid' : ''}`} {...register('rg')} />
                          <Form.Control.Feedback type="invalid">{errors.rg?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col>
                        <FloatingLabel className="mb-3" label={t("formLabel.issuingAgency")}>
                          <Form.Control id="issuingAgency" type="text" placeholder={t("formLabel.issuingAgency")} className={`form-control ${errors.issuingAgency ? 'is-invalid' : ''}`} {...register('issuingAgency')} />
                          <Form.Control.Feedback type="invalid">{errors.issuingAgency?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FloatingLabel className="mb-3" label={t("formLabel.shippingDate")}>
                          <Form.Control id="shippingDate" type="date" placeholder={t("formLabel.shippingDate")} className={`form-control ${errors.shippingDate ? 'is-invalid' : ''}`} {...register('shippingDate')} />
                          <Form.Control.Feedback type="invalid">{errors.shippingDate?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col >
                        <FloatingLabel className="mb-3" label={t("formLabel.sex")}>
                          <Form.Select id="sex" aria-label="Sex" className={`form-control ${errors.sex ? 'is-invalid' : ''}`} {...register('sex')}>
                            <option value="">{t('dropdownItem.select')}..</option>
                            <option value="1">{t('formLabel.male')}</option>
                            <option value="2">{t('formLabel.female')}</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.sex?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FloatingLabel className="mb-3" label={t("formLabel.birth")}>
                          <Form.Control id="birth" type="date" placeholder={t("formLabel.birth")} className={`form-control ${errors.birth ? 'is-invalid' : ''}`} {...register('birth')} />
                          <Form.Control.Feedback type="invalid">{errors.birth?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col >
                        <FloatingLabel className="mb-3" label={t("formLabel.maritalStatus")}>
                          <Form.Select id="maritalStatus" aria-label="Marital status" className={`form-control ${errors.maritalStatus ? 'is-invalid' : ''}`} {...register('maritalStatus')}>
                            <option value="">{t('formLabel.select')}</option>
                                                        <option value="1">{t("formLabel.married")}</option>
                                                        <option value="2">{t("formLabel.single")}</option>
                                                        <option value="3">{t("formLabel.widower")}</option>
                                                        <option value="4">{t("formLabel.legallySeparated")}</option>
                                                        <option value="5">{t("formLabel.disquitado")}</option>
                                                        <option value="6">{t("formLabel.divorced")}</option>
                                                        <option value="7">{t("formLabel.stableunion")}</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.maritalStatus?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <FloatingLabel className="mb-3" label={t("formLabel.nationality")}>
                      <Form.Control type="text" id="nationality" placeholder={t("formLabel.nationality")} className={`form-control ${errors.nationality ? 'is-invalid' : ''}`} {...register("nationality")} />
                      <Form.Control.Feedback type="invalid">{errors.nationality?.message}</Form.Control.Feedback >
                    </FloatingLabel>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" >
                  <Accordion.Header>{t("formLabel.observation")}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <FloatingLabel label={t('formLabel.observation')} >
                          <Form.Control as="textarea" id="observation" placeholder={t("formLabel.observation")} {...register('observation')} style={{ height: '100px' }} />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={newWitnessClose}>
              {t('button.close')}
            </Button>
            <Button variant="outline-primary" onClick={handleSubmit(step1)} className="js-btn-step">
              { t('button.previous') }
            </Button>
            {
              hide1 ?
                <Button variant="primary" onClick={handleSubmit(step2)} className="min-w-auto js-btn-step zipCodeCheck">
                  {t('button.next')}
                </Button>
                :
                <Button variant="primary" type="submit" onClick={openErrorbox}>
                  {t('button.save')}
                </Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default WitnessService;