import React from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EmptyFolder = () => {
    const [t] = useTranslation();
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 4, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/documents_list"}><img src="assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t("DocumentTemplate.nameNewFolder")}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                />
                                <InputGroup.Text id="search_icon">
                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 5, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe">
                                    <Link to={"/folder_detail"} className="text-reset"><img src="assets/images/icons/plus-light.svg" alt="cloud icon" className="h-18 me-2" />{t("DocumentTemplate.newTemplate")}</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3 text-center">
                  <img src="../assets/images/temp/empty_state.png" alt="Empty state" className="mx-auto img-fluid d-block my-lg-5 my-4" />
                  <h6 className="fw-bold">{t("DocumentTemplate.folderEmpty")}</h6>
                  <p className="text-secondary mb-4 pb-xl-2">{t("DocumentTemplate.folderEmptyContent")}</p>
                  <Link to={"/folder_detail"} className="btn btn-primary">{t("DocumentTemplate.newTemplate")}</Link>
            </main>
            {/* <!-- /Main content start end --> */}
        </section>
    )
}

export default EmptyFolder
