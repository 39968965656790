import { getLocalStorageItem } from "../../shared/utils/getLocalStorageItem";
import { config } from "../../../../services/apiConfig";

import axios from "axios";

export async function getContracts(filters?: any) {
  const token = getLocalStorageItem("tokenV2");
  const userData = getLocalStorageItem("userData");

  const response = await axios.post(
    `${config.CUSTOMER__API}/v1/dimob/contracts`,
    {
      ...filters,
      userId: 1,
      hasDimob: true,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
        "connection-name": userData,
      },
    }
  );

  return response.data;
}
