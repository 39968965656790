import { Table, Dropdown, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, Card, Collapse, Badge } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Loader from '../../../Profile/Company/Loader';
import '../../../../App.css';
import Pagination from '../../../Pagination';
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../redux/store';
import { getAllBillMethodListing, openTypeCustomer1 } from '../../../../redux/slice/expenditureDataSlice';
import { encrypt_Decrypt, } from '../../../constants/utils';
import { showSuppliers } from '../../../../redux/slice/contractDataSlice';
import NewSuppliers from '../Common/NewSuppliers';

const Suppliers = (CustomersAndEmployeesForm) => {
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
    }, [dispatch]);

    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2000) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const [t] = useTranslation()
    const [status, setStatus] = useState(true);
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [openFilters, setFiltersOpen] = useState(false);
    const [totalpage, setTotalPage] = useState(10)
    const [PageSize, setPageSize] = useState(20);
    const [state, setState] = useState({
        supplier: "",
        status: "",
        person: "",
        start: 0,
        end: PageSize,
        type: "SupplierList"
    });


    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        getAllSuppliers(state);
    }, [state]);

    useEffect(() => {
        setState({
            ...state,
            ["start"]: currentPage == 1 || currentPage == 0 ? 0 : (currentPage - 1) * PageSize,
            ["end"]: PageSize
        })
    }, [currentPage])

    /*--------Add new supplier function--------*/
    const formShow = () => {
        dispatch(showSuppliers(true))
    }
    /*-------Get suppliers listing--------*/
    const getAllSuppliers = (supplier) => {
        let supplierData = { supplier: supplier.supplier, start: supplier.start, end: supplier.end, activeContract: supplier.status, personStatus: supplier.person,  type: "SupplierList" };
        setStatus(true);
        customerService.getAllSuppliers(supplierData).then(res => {
            setStatus(false);
            if (res.data.resCode == 201) {
                setData(res.data.data);
                setTotal(res.data.totalRow)
                setTotalPage(res.data.totalRow)
            }
            else { setData([]); setTotal(0); setTotalPage(0) }
        }).catch(err => {
            setStatus(false);
            console.log("err", err)
        });
    }


    const contrStatus = (conStatus) => {
        state["activeContract"] = conStatus;
        getAllSuppliers(null);
    }
    const personStatus = (pStatus) => {
        state["personStatus"] = pStatus;
        getAllSuppliers(null);
    }

    let typingTimer;
    const ActiveContract = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        if (name == "search") {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                setState({
                    ...state,
                    supplier: value
                })
                //console.log('Typing finished:', value);
            }, 1000)
        } else {
            if (name == "person") {
                setState({
                    ...state,
                    [name]: value == "1" ? "both" : value == "2" ? "Physical" : value == "3" ? "Legal" : ""
                })
            } else {
                setState({
                    ...state,
                    [name]: value
                })
            }
        }
    }
    const handleSelectName = (e, type) => {
        dispatch(openTypeCustomer1(type))
    }
    const filterReset = () => {
        let setvalue: any = document.querySelector("#status");
        if (setvalue) {
            setvalue.value = ""
        }
        let setvalue1: any = document.querySelector("#contactstatus");
        if (setvalue1) {
            setvalue1.value = "All"
        }
        setState({
            supplier: "",
            status: "",
            person: "",
            start: 0,
            end: 20,
            type: "SupplierList"
            
        })
    }
    return (
        <section className=" customerAndSuppliers content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/customer_and_employees"}>
                                    <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-xl-1">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t('customerAndEmployees.suppliers')}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <div className="input-group">
                                <Form.Control type="text" id="search" name="search" onChange={ActiveContract} placeholder={t('formLabel.typeSearch')} />
                                <span className="input-group-text" id="basic-addon2">
                                    <img src="assets/images/icons/search.svg" />
                                </span>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(2001) !== -1) &&
                                    <li className="list-inline-item mb-md-0 mb-1">
                                        <Link to={"#"} onClick={formShow} className="fw-bold">
                                            <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                            {t('customerAndEmployees.newSuppliers')}
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </Col>
                    </Row>
                </div>
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1" className={`${state?.["person"] ? "show" : ""}`}>
                                        {state?.["person"] == "both" ? t('dropdownItem.physicalAndLegal') : state?.["person"] == "Physical" ? t('dropdownItem.physical') : state?.["person"] == "Legal" ? t('dropdownItem.legal') : t('dropdownItem.person')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="px-4">
                                            <FloatingLabel controlId="floatingSelect" label={t('dropdownItem.status')}>
                                                <Form.Select id="status" name="person" aria-label={t('dropdownItem.status')} onChange={ActiveContract}>
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {/* <option value="1">{t('dropdownItem.physicalAndLegal')}</option> */}
                                                    <option value="2">{t('dropdownItem.physical')}</option>
                                                    <option value="3">{t('dropdownItem.legal')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                          
                            {state?.["person"] || state?.["status"] ? <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                        {t("button.resetfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col> :
                                ""}
                        </Row>
                    </Form>
                </Collapse>
            </div>
            {/*<!-- Breadcrumb section end from here -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*<!-- Table start from here -->*/}
                {
                    status ?
                        <Loader />
                        :
                        <>
                            <Card>
                                <Card.Body className="pb-1">
                                    <Table responsive className="table table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th>{t('customerAndEmployees.suppliers')}</th>
                                                <th>{t('formLabel.contact')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.length > 0 ? data.map((item, index) => (
                                                <tr key={index}>
                                                    {/* <td className={"check_Checkbox"}>
                                                                        <Checkbox key={item.id} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                                    </td> */}
                                                    <td>
                                                        <span className="d-block text-dark-70">
                                                            <img alt="img" src="assets/images/icons/user_g.svg" />
                                                            <Link to={"/owner_details/" + item.id} onClick={(e) => handleSelectName(e, "Suppliers")} className="ps-1 text-secondary">{item.name}</Link>
                                                            {/* <br />
                                                                            <span>{legalAndPhysical ? legalAndPhysical : legalOwner ? legalOwner : ""}</span> */}
                                                            {item.statusowner == "1" &&
                                                                <span><Badge className="text-white ms-1" bg="secondary">{item?.["statusowner"] == "1" ? t("inActive") : ''}</Badge></span>
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href={`mailto:${item.email}`} className="d-block text-dark-70">{item.email}</a>
                                                        <span>{item.telephone ? item.telephone : item.cellphone}</span>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan={3}>
                                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            {
                                <div className="d-flex justify-content-center mt-lg-4 mt-3">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalpage}
                                        pageSize={PageSize}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                </div>
                            }
                        </>
                }
                {/*<!-- /Table end -->*/}
                {/* {t('formLabel.moreItems')} */}
                <p className="pb-2">{t('formLabel.listing')} {data && data.length} {t('customerAndEmployees.suppliers')}.</p>
                {/*<!-- Nav listing start from here --> */}
                {/*<!-- /Nav listing  end --> */}

            </main>
            {/* <!-- /Main content start end --> */}

            {/*---------------Supplier form modal------------*/}

            <NewSuppliers getAllSuppliers={getAllSuppliers} />
        </section>
    )
}
export default Suppliers;