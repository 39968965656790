import React, { useEffect, useState } from 'react';
import { Offcanvas, Form, Button, FloatingLabel } from 'react-bootstrap';
import EditTemplate from './EditTemplate';
import EditChargeTemplate from './EditChargeTemplate';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { nonPaymentSetting, nonPaymentSetting_post } from '../../../../redux/slice/NonPaymentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import Loader from '../../../Profile/Company/Loader';
import Swal from 'sweetalert2';
import customerService from '../../../../services/customerService';
import { ClllThis } from '../../Contracts/Readjustment/ReadjustmentIndexes';
import { fetch_allFinancialIndexing } from '../../../../redux/slice/expenditureDataSlice';

const Settings = (props: any) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let { nonPaymentSetting_Data } = useSelector((state: RootState) => state.nonPayment);
    let { fetch_allFinancialIndexing_data } = useSelector((state: RootState) => state.expenditureData);

    //Settings modal close open function
    const { showSettings, settingsClose, settingsShow, getNonPaymentSummery } = props;
    const { register, handleSubmit, setValue, getValues, reset, clearErrors, setError, formState: { errors } } = useForm();
    //Email template modal close open function
    const [showEditTemplateModal, setEditTemplateModal] = useState(false);
    const [editOrNot, setEditOrNot] = useState(true);
    const [disable, setDisable] = useState(true);
    const editTemplateModalClose = () => {
        settingsShow(true);
        setEditTemplateModal(false);
    }
    const [readName, setReadName] = useState<any>([]);
    const [loadingSet, setloadingSet] = useState(false);
    useEffect(() => {
        if (showSettings && nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0) {
            let ResultData = nonPaymentSetting_Data[0];
            setValue("monetary", ResultData["BL_MONETARY_NON"]);
            setValue("days_after", ResultData["TX_DAYSAFTER_NON"]);
            setValue("monetary_update", ResultData["SL_MONETARY_UPDATE_NON"]);
            setValue("do_not_consider", ResultData["BL_DONOTCONSIDER_NON"]);
            setValue("email_response", ResultData["TX_EMAILRESPONSE_AMOUNT_NON"]);
            setValue("interest_rate", ResultData["TX_INTERESTRATE_NUMBER_NON"]);
            setValue("allow_changing", ResultData["BL_ALLOWCHANGES_NON"]);
            setValue("block_collection", ResultData["BL_BLOCKCOLLECTION_NON"]);
            setValue("enable_third_party", ResultData["BL_ENABLETHIRDPARTY_NON"]);
            setValue("additional_instruction", ResultData["TX_ADDITIONALINSTRUCTION_NON"]);
            setValue("new_agreement", ResultData["BL_NEWAGREEMENT_NON"]);
            setValue("paid_agreement", ResultData["BL_PAIDAGREEMENT_NON"]);
            setValue("invoice_printing", ResultData["BL_INVOICEPRINTING_NON"]);
            setValue("email_billing", ResultData["BL_EMAILBILLING_NON"]);
            setValue("generation_of_delinquency", ResultData["BL_GENERATIONOFDELINQUENCY_NON"]);
        }
    }, [nonPaymentSetting_Data, showSettings])
    const [loading, setLoading] = useState(false);
    const [folderName, setFolderName] = useState("");
    const seditTemplateModalShow = async (e: any, name: string) => {
        setFolderName(name);
        settingsClose(true);
        setEditTemplateModal(true);
    }

    const CloseModel = () => {
        settingsClose();
        setEditOrNot(true);
        setDisable(true);
    }
    //Email charge template modal close open function
    const [showEditChargeTemplateModal, setEditChargeTemplateModal] = useState(false);
    const editChargeTemplateModalClose = () => {
        setEditChargeTemplateModal(false);
        settingsShow(true);
    }
    const editChargeTemplateModalShow = (e: any, name: string) => {
        setFolderName(name);
        setEditChargeTemplateModal(true);
        settingsClose(true);
    }

    const handleEdit = (type: any) => {
        switch (type) {
            case "edit":
                setEditOrNot(false);
                setDisable(false);
                break;
            case "close":
                setEditOrNot(true);
                setDisable(true);
                dispatch(nonPaymentSetting({}));
                break;
            default:
                break;
        }
    }

    const onSubmit = async (data: any) => {
        setLoading(true);
        let object = {
            BL_MONETARY_NON: data?.["monetary"],
            TX_DAYSAFTER_NON: data?.["days_after"],
            SL_MONETARY_UPDATE_NON: data?.["monetary_update"],
            BL_DONOTCONSIDER_NON: data?.["do_not_consider"],
            TX_EMAILRESPONSE_AMOUNT_NON: data?.["email_response"],
            TX_INTERESTRATE_NUMBER_NON: data?.["interest_rate"],
            BL_ALLOWCHANGES_NON: data?.["allow_changing"],
            BL_BLOCKCOLLECTION_NON: data?.["block_collection"],
            BL_ENABLETHIRDPARTY_NON: data?.["enable_third_party"],
            TX_ADDITIONALINSTRUCTION_NON: data?.["additional_instruction"],
            BL_NEWAGREEMENT_NON: data?.["new_agreement"],
            BL_PAIDAGREEMENT_NON: data?.["paid_agreement"],
            BL_INVOICEPRINTING_NON: data?.["invoice_printing"],
            BL_EMAILBILLING_NON: data?.["email_billing"],
            BL_GENERATIONOFDELINQUENCY_NON: data?.["generation_of_delinquency"],
        }

        let dataResp = await dispatch(nonPaymentSetting_post({ objData: object }));
        if (dataResp) {
            if (dataResp["payload"]["data"]["type"] == "success") {
                CloseModel();
                dispatch(nonPaymentSetting({}));
                getNonPaymentSummery();
                Swal.fire({
                    title: `${t('Sweetalert.Success')}`,
                    text: `${t("Sweetalert.deleteRecord")}`,
                    confirmButtonText: "OK",
                })
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    confirmButtonText: "OK",
                });
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
            if (dataOutPut["payload"]) {
                let result = await ClllThis(dataOutPut["payload"]["data"]);
                setReadName(result);
            }
        })()
    }, [])

    return (
        <>
            <Offcanvas className="border-sm-none" show={showSettings} onHide={CloseModel} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <>
                        <Offcanvas.Header className="border-bottom" closeButton>
                            {t("formLabel.settings")}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="d-flex justify-content-between mb-3">
                                <h6 className="mb-0 fw-bold">{t("NonPayment.monetaryUpdate")}</h6>
                                <span>
                                    {
                                        editOrNot ?
                                            <span className="fw-bold text-primary cursor-pe me-xl-4 me-lg-3 me-2" onClick={() => handleEdit("edit")}>{t("button.edit")}</span>
                                            :
                                            <span className="fw-bold text-primary cursor-pe me-xl-4 me-lg-3 me-2" onClick={() => handleEdit("close")}>{t("button.close")}</span>
                                    }
                                </span>
                            </div>
                            <Form.Group className="bg-light rounded-4 p-2 my-3 min-h-56 d-flex flex-wrap align-items-center">
                                <Form.Check id="monetary_update" label={t("NonPayment.processMonetaryUpdate")} {...register("monetary")} disabled={disable} />
                            </Form.Group>
                            <FloatingLabel controlId="values_due_date" label={t("NonPayment.daysUpdateValuesAfterDueDate")} className="mb-3">
                                <Form.Control type="text" placeholder={t("NonPayment.daysUpdateValuesAfterDueDate")} {...register("days_after")} disabled={disable} />
                            </FloatingLabel>
                            <FloatingLabel label={t("NonPayment.monetaryUpdateTable")} className="mb-3">
                                <Form.Select className="mh-336 overflow-auto" aria-label="monetary_update_table" {...register("monetary_update")} disabled={disable}>
                                <option value="">{t("select")}</option>
                                    {readName && readName.length > 0 &&
                                        readName.map((item: any, index: number) => {
                                            return <option value={item.slug} key={index}>{item.name}</option>
                                        })
                                    }
                                    {/* <option value="igpm">IGPM</option>
                                    <option value="inpc">INPC</option>
                                    <option value="tjrj">TJRJ</option>
                                    <option value="tjdf">TJDF</option>
                                    <option value="tjmg">TJMG</option>
                                    <option value="saving">Savings account</option>
                                    <option value="tjes">TJES</option>
                                    <option value="tr">TR</option>
                                    <option value="ipca">IPCA</option>
                                    <option value="igpdi">IGPDI</option>
                                    <option value="selic">SELIC</option>
                                    <option value="ufir">UFIR</option>
                                    <option value="encoge">ENCOGE</option>
                                    <option value="ortn/otn/btn/btn-tr">ORTN / OTN / BTN / BTN-TR</option>
                                    <option value="ipc/fipe">IPC / FIPE</option>
                                    <option value="ipc/fgv">IPC / FGV</option>
                                    <option value="tjro">TJRO</option> */}
                                </Form.Select>
                            </FloatingLabel>
                            <h6 className="mt-4 mb-3 fw-bold">{t("Reports.Nonpayment")}</h6>
                            <Form.Group className="bg-light rounded-4 p-2 my-3 min-h-56 d-flex flex-wrap align-items-center">
                                <Form.Check id="first_payment_charges" label={t("NonPayment.doNotConsiderFirstPaymentNonpayment")} {...register("do_not_consider")} disabled={disable} />
                            </Form.Group>
                            <FloatingLabel controlId="email_responsible" label={t("NonPayment.emailResponsibleForRespondingNonpaymentCharges")} className="mb-1">
                                <Form.Control type="text" placeholder={t("NonPayment.emailResponsibleForRespondingNonpaymentCharges")} {...register("email_response")} disabled={disable} />
                            </FloatingLabel>
                            <span className="d-block fw-medium fs-12 text-secondary mb-3 ms-3">{t("NonPayment.separateEmailAddressesSemicolon")} (;)</span>
                            <h6 className="mt-4 mb-3 fw-bold">{t("Dashboard.Agreement")}</h6>
                            <FloatingLabel controlId="interest_rate" label={t("NonPayment.interestRateInstallment")} className="mb-3">
                                <Form.Control type="text" placeholder={t("NonPayment.interestRateInstallment")} max={100} {...register("interest_rate")} disabled={disable} />
                            </FloatingLabel>
                            <Form.Group className="bg-light rounded-4 p-2 my-3 min-h-56 d-flex flex-wrap align-items-center">
                                <Form.Check id="changing_installment" label={t("NonPayment.allowChangingInstallmentInterestRate")} {...register("allow_changing")} disabled={disable} />
                            </Form.Group>
                            {/* <Form.Group className="bg-light rounded-4 p-2 my-3 min-h-56 d-flex flex-wrap align-items-center">
                                        <Form.Check id="collection_agreements" label={t("NonPayment.blockCollectionAgreementsLinkedToDispatch")} {...register("block_collection")} disabled={disable} />
                                    </Form.Group> */}
                            <Form.Group className="bg-light rounded-4 p-2 my-3 min-h-56 d-flex flex-wrap align-items-center">
                                <Form.Check id="third_party_expense" label={t("NonPayment.enableThirdPartyExpenseDeletionAgreementSource")} {...register("enable_third_party")} disabled={disable} />
                            </Form.Group>
                            <FloatingLabel controlId="additional_instructions_agreement" label={t("NonPayment.additionalInstructionsForAgreementCollections")} className="mb-3">
                                <Form.Control type="text" placeholder={t("NonPayment.additionalInstructionsForAgreementCollections")} {...register("additional_instruction")} disabled={disable} />
                            </FloatingLabel>
                            <h6 className="mt-4 mb-3 fw-bold">{t("NonPayment.automaticNotification")}</h6>
                            <Form.Group className="bg-light rounded-4 p-2 mb-3 min-h-56 d-flex align-items-center justify-content-between">
                                <Form.Check id="new_agreement" label={t("NonPayment.newAgreement")} {...register("new_agreement")} disabled={disable} />
                                {
                                    !disable &&
                                    <span className="fw-bold text-primary cursor-pe ms-1 flex-shrink-0" onClick={(e) => seditTemplateModalShow(e, "New Agreement")}>{t("notifications.editTemplate")}</span>
                                }
                            </Form.Group>
                            <Form.Group className="bg-light rounded-4 p-2 mb-3 min-h-56 d-flex align-items-center justify-content-between">
                                <Form.Check id="paid_agreement_charge" label={t("NonPayment.paidAgreementCharge")} {...register("paid_agreement")} disabled={disable} />
                                {
                                    !disable &&
                                    <span className="fw-bold text-primary cursor-pe ms-1 flex-shrink-0" onClick={(e) => seditTemplateModalShow(e, "Paid Agreement")}>{t("notifications.editTemplate")}</span>
                                }
                            </Form.Group>
                            <Form.Group className="bg-light rounded-4 p-2 mb-3 min-h-56 d-flex align-items-center justify-content-between">
                                <Form.Check id="invoice_printing" label={t("NonPayment.invoicePrinting")} {...register("invoice_printing")} disabled={disable} />
                                {
                                    !disable &&
                                    <span className="fw-bold text-primary cursor-pe ms-1 flex-shrink-0" onClick={(e) => seditTemplateModalShow(e, "Invoice Printing")}>{t("notifications.editTemplate")}</span>
                                }
                            </Form.Group>
                            <Form.Group className="bg-light rounded-4 p-2 mb-3 min-h-56 d-flex align-items-center justify-content-between">
                                <Form.Check id="email_billing_delivery" label={t("NonPayment.emailBillingDelivery")} {...register("email_billing")} disabled={disable} />
                                {
                                    !disable &&
                                    <span className="fw-bold text-primary cursor-pe ms-1 flex-shrink-0" onClick={(e) => seditTemplateModalShow(e, "Email Billing")}>{t("notifications.editTemplate")}</span>
                                }
                            </Form.Group>
                            {/* <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex align-items-center justify-content-between">
                                <Form.Check id="generation_delinquency" label={t("NonPayment.generationOfDelinquencyLetter")} {...register("generation_of_delinquency")} disabled={disable} />
                                {
                                    !disable &&
                                    <span className="fw-bold text-primary cursor-pe ms-1 flex-shrink-0" onClick={(e) => seditTemplateModalShow(e, "Generation of Delinquency")}>{t("notifications.editTemplate")}</span>
                                }
                            </Form.Group> */}
                        </Offcanvas.Body>
                        {
                            !editOrNot &&
                            <>
                                <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                    {
                                        loading ?
                                            <Button className="btn-width">
                                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                            </Button>
                                            :
                                            <>
                                                <Button variant="outline-primary" onClick={CloseModel}>{t("button.cancel")}</Button>
                                                <Button variant="primary" type="submit">{t("newTransfer.saveChanges")}</Button>
                                            </>
                                    }
                                </div>
                            </>
                        }
                    </>
                </Form>
            </Offcanvas>
            {/* Mddal start from here */}
            <EditTemplate showEditTemplate={showEditTemplateModal} editTemplateClose={editTemplateModalClose} folderName={folderName} />
            <EditChargeTemplate showEditChargeTemplate={showEditChargeTemplateModal} editChargeTemplateClose={editChargeTemplateModalClose} folderName={folderName} />
        </>
    )
}

export default Settings
