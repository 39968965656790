import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { Form, Button, Row, Col, Table, Accordion, Card } from 'react-bootstrap';
import { getBankList, Main_Account, Newbankaccount, Update_Main_Account, mainBankAcc } from '../../../../redux/slice/bankSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css// 
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { useTranslation } from 'react-i18next';
import { handleAction } from "../actions"
import { numericOnly, openErrorbox } from '../../../Pages/Contracts/Common/actionPerform';
import moment from "moment-timezone";
import { converter, encrypt_Decrypt, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import i18n from '../../../../i18n';
import { useNavigate } from 'react-router-dom';
import { getBraches } from '../../../../redux/slice/branchSlice';
import Loader from '../Loader';
// used it to show validation error on form
const MainAccount = () => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    // Getting data from redux toolkit and post is used dispatch
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [loader_on, setloader_on] = useState(false);

    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation()

    const Schema = yup.object().shape({
        nameReference: yup.string().required(t("requiredMmessage.fieldRequired")),
        bankname: yup.string().required(t("requiredMmessage.fieldRequired")),
        agency: yup.string().required(t("requiredMmessage.fieldRequired")).min(4, t("requiredMmessage.Agencyrequire")),
        account: yup.string().required(t("requiredMmessage.fieldRequired")),
        moneyaccount: yup.string().required(t("requiredMmessage.fieldRequired")),
        date: yup.string().required(t("requiredMmessage.fieldRequired")),
        accountType: yup.string().required(t("requiredMmessage.fieldRequired")),
        branchtype: yup.string().required(t("requiredMmessage.fieldRequired"))
    })
    // The useForm hook is used to handle form registration, submission, and validation.
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<Newbankaccount>({
        resolver: yupResolver(Schema)
    });

    // Retrieve banklist, branchlist, and status from the Redux state
    const { banklist, mainbank, status } = useSelector((state: RootState) => state.bank)
    const { branchList, } = useSelector((state: RootState) => state.branch)
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3110) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
    useEffect(() => {
        // Dispatch action to get the bank list
        let data_ount = dispatch(getBankList({
            start: "0",
            end: "10"
        }))
        // Dispatch action for main account
        dispatch(Main_Account())
    }, [dispatch])


    useEffect(() => {
        dispatch(getBraches({
            start: "0",
            end: "100"
        }))
    }, [mainbank, banklist, i18n.language])

    useEffect(() => {
        if (branchList) {
            const fields: string[] = ['id', 'nameReference', 'bankname', 'agency', 'account', 'accountType', 'date', 'branchtype', "main_ac"];
            // Check if mainbank array has any data
            if (mainbank && mainbank.length > 0) {
                setValue("moneyaccount", converter(`${mainbank[0]["moneyaccount"]}`))

                // Set form field values with data from mainbank
                fields.forEach((field: any) => {
                    if (field == "bankname") {
                        {
                            banklist &&
                                banklist?.["length"] > 0 &&
                                banklist.forEach((curElem, index) => {
                                    if (curElem.bank_code == mainbank[0][field]) {
                                        setValue(field, curElem.bank_name)
                                    }
                                })
                        }
                    } else {
                        setValue(field, mainbank[0][field])
                    }
                });
            } else {
                // Set form field values to empty if mainbank is empty
                fields.forEach((field: any) => setValue(field, ""));
            }
        }
    }, [branchList, mainbank, banklist, i18n.language])

    const onSubmit = async (data: Newbankaccount, e: any) => {
        // Dispatch the update main account action
        let bankName: any = data["bankname"];
        let databankCode: any = "";
        banklist &&
            banklist?.["length"] > 0 &&
            banklist.forEach((curElem, index) => {
                if (curElem.bank_name === bankName) {
                    databankCode = curElem["bank_code"];
                }
            })
        data["bankname"] = databankCode;
        let moneyaccounts: any = data.moneyaccount;
        if (moneyaccounts.includes(".")) {
            moneyaccounts = String(moneyaccounts).replaceAll(".", "");
        }
        if (moneyaccounts.includes(",")) {
            moneyaccounts = String(moneyaccounts).replaceAll(",", ".");
        }

        data["moneyaccount"] = moneyaccounts
        setloader_on(true);
        let res = await dispatch(Update_Main_Account(data))
        if (res.payload.type == 'Success') {
            // Show success message
            Swal.fire({
                position: 'center',
                // icon: 'success','success',
                title: `${t('Sweetalert.Success')}`,
                text: `${t('Sweetalert.RecordUpdated')}`,
                showConfirmButton: false,
                timer: 2000
            })
            // Dispatch the main bank account action
            dispatch(Main_Account())
            dispatch(getBankList({
                start: "0",
                end: "10"
            }))
            setloader_on(false);
        } else {
            // Show an error message
            Swal.fire({
                position: 'center',
                // icon: 'success','error',
                title: `${t('Sweetalert.Error')}`,
                text: res.payload.message,
                // text: `${t('Sweetalert.somethingWent')}`,
                showConfirmButton: true,
            })
            setloader_on(false);
        }
    }
    //The input field will be enabled when the user clicks on the edit button.
    const handleEdit = async (e, data) => {
        console.log(e.target);
    }

    const minDate = () => {
        const today = new Date(dateBrazil).toISOString().split('T')[0];
        return today;
    };
    const handleCancel = () => {
        dispatch(Main_Account())
    }

    const AddAmount = (e: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if (value !== "") {
            setValue("moneyaccount", formatNumber(value))
        }
    }
    return (
        <>

            <Col lg={6}>

                <Card>
                    {
                        !loader_on &&
                        <Card.Header className="d-flex align-items-center justify-content-between">{t("banks.mainAccount")}
                            {mainbank && mainbank.length > 0 ?
                                <>
                                    <span className='d-block TOEDIT MainAccounttsx'>
                                        <span>
                                            {userJson && userJson.length > 0 && (userJson.indexOf(3111) !== -1) &&
                                                <span>
                                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "Mainaccount")}>{t("button.edit")}</Button>
                                                </span>}
                                        </span>
                                    </span>
                                    <div className="d-none ACTION">
                                        <Row>
                                            <Col xl={6} sm={6}>
                                                <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleCancel()} >{t("button.cancel")}</Button>
                                            </Col>
                                            <Col xl={4} sm={4}>
                                                <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "update", "Mainaccount")} type="submit" >{t("button.toSave")}</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                                :
                                ""}
                        </Card.Header>
                    }
                    <Card.Body className="pb-1">
                        {
                            (loader_on) ?
                                <Loader /> :
                                <Form onSubmit={handleSubmit(onSubmit)} id="editform_companydata">
                                    {/* Bank account basic detail section start from here */}
                                    <Form.Floating className="mb-lg-4 mb-3 removeInsertDisable">
                                        <Form.Control type="text" id="floatingInputValue" className={`${errors.nameReference ? 'is-invalid' : ''}`}
                                            placeholder="Conta Santander (Homologada)"  {...register('nameReference')} disabled />
                                        <Form.Control.Feedback type="invalid" >{errors.nameReference?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">{t("formLabel.nameReference")+ " *"}</Form.Label>
                                    </Form.Floating>
                                    <Form.Floating className="mb-lg-4 mb-3">
                                        <Form.Control type="text" id="bank" className={`${errors.bankname ? 'is-invalid' : ''}`}
                                            placeholder="Não banco" {...register('bankname')} disabled readOnly />
                                        <Form.Control.Feedback type="invalid" >{errors.bankname?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">{t("banks.name")}</Form.Label >
                                    </Form.Floating >
                                    <Form.Floating className="mb-lg-4 mb-3">
                                        <Form.Control type="text" id="floatingInputValue" className={`${errors.agency ? 'is-invalid' : ''}`}
                                            placeholder="0000" {...register('agency')} disabled />
                                        <Form.Control.Feedback type="invalid" >{errors.agency?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">{t("banks.agency")} </Form.Label>
                                    </Form.Floating>
                                    <Form.Floating className="mb-lg-4 mb-3">
                                        <Form.Control type="text" id="account" className={`${errors.account ? 'is-invalid' : ''}`}
                                            placeholder="0000"  {...register('account')} disabled readOnly />
                                        <Form.Control.Feedback type="invalid" >{errors.account?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">{t("banks.accountNo")} {t("banks.withDigit")}</Form.Label>
                                    </Form.Floating>
                                    {/* /Bank account basic detail section end */}
                                    <Accordion className="accordion-custom">
                                        {/* Opening balance section start from here */}
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>{t("banks.openingBalance")}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Floating className='removeInsertDisable'>
                                                            <Form.Control type="text" id="floatingInputValue" className={`${errors.moneyaccount ? 'is-invalid' : ''}`} placeholder="0,00" {...register('moneyaccount')} disabled onChange={(e) => AddAmount(e)} />
                                                            <Form.Control.Feedback type="invalid" >{errors.moneyaccount?.message}</Form.Control.Feedback>
                                                            <Form.Label htmlFor="floatingInputValue">{t("banks.openingBalance")+ " *"}</Form.Label>
                                                        </Form.Floating>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Floating className='removeInsertDisable'>
                                                            <Form.Control type="date" data-date-format="YYYY/MM/DD" className={`${errors.date ? 'is-invalid' : ''}`} id="floatingInputValue" placeholder="01/01/2022" {...register('date')} disabled />
                                                            <Form.Control.Feedback type="invalid" >{errors.date?.message}</Form.Control.Feedback>
                                                            <Form.Label htmlFor="accountHome">{t("banks.accountHome")+ " *"}</Form.Label>
                                                        </Form.Floating>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body >
                                        </Accordion.Item >
                                        {/* /Opening balance section end */}
                                        {/* More option section start from here */}
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row className="gy-3 gy-lg-4">
                                                    <Col md={6}>
                                                        <Form.Floating className='removeInsertDisable'>
                                                            <Form.Select className={`${errors.accountType ? 'is-invalid' : ''}`}  {...register('accountType')}
                                                                aria-label="conta corrente" disabled >
                                                                <option value="0">{t("formLabel.select")}</option>
                                                                <option value="1">{t("banks.checkingAccount")}</option>
                                                                <option value="2">{t("banks.box")}</option>
                                                                <option value="3">{t("banks.investment")}</option>
                                                            </Form.Select >
                                                            <Form.Control.Feedback type="invalid" >{errors.accountType?.message}</Form.Control.Feedback>
                                                            <Form.Label htmlFor="accountType">{t("banks.accountType")}</Form.Label>
                                                        </Form.Floating>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Floating className='removeInsertDisable'>
                                                            <Form.Control type="date" className={`${errors.closingDate ? 'is-invalid' : ''}`} id="closingDate"
                                                                placeholder="Closing date" min={minDate()} {...register('closingDate')} disabled />
                                                            <Form.Control.Feedback type="invalid" >{errors.closingDate?.message}</Form.Control.Feedback >
                                                            <Form.Label htmlFor="closingDate">{t("banks.closingDate")+ " *"}</Form.Label>
                                                        </Form.Floating>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Floating className='removeInsertDisable'>
                                                            <Form.Select className={`${errors.branchtype ? 'is-invalid' : ''}`}  {...register('branchtype')} aria-label="Matriz"
                                                                disabled >
                                                                <option value="">{t("formLabel.select")}</option>
                                                                {branchList && branchList.length > 0 &&
                                                                    branchList.map((curElem: any, index: number) =>
                                                                        <option key={index} value={index + 1}>{curElem.branchname}</option>
                                                                    )}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid" >{errors.branchtype?.message}</Form.Control.Feedback>
                                                            <Form.Label htmlFor="branch">{t("formLabel.branch")+ " *"}</Form.Label>
                                                        </Form.Floating>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item >
                                        {/* /More option section end */}
                                    </Accordion>
                                    <Button className='submitForm' type="submit" variant="primary" onClick={openErrorbox} hidden >{t('button.update')}</Button>
                                    <Button className='fillModalwithThisData' onClick={(e) => handleEdit(e, "Mainaccount")} hidden>{t('button.edit')}</Button>
                                </Form>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}

export default MainAccount