import { Form, Accordion, Offcanvas, Modal, Button, Row, Col, FloatingLabel, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { tenantsClose, contractOpen, insertTenants, getTenants, setTenantId, GetRepresentativeService, tenantsOpen } from "../../../../redux/slice/contractDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { tenantsForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { openErrorbox } from '../../Contracts/Common/actionPerform';
import { checkDuplicateCNJP_CPF, CNJP_CPFValidation, handleKeyDown } from '../../../constants/utils';
import customerService from '../../../../services/customerService';
import RepresentativeService from './RepresentativeService';

const TenantsService = (tenantsForm) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showHideField, setShowHideField] = useState<any>("")
  const [spouseHide, setSpouseHide] = useState(false)
  const [double, setDouble] = useState(false);
  const [loader_on, setloader_on] = useState(false);
  const [repreData, setRepreData] = useState([])
  const [t] = useTranslation();
  const { newTenantsShow, tenantsData } = useSelector((state: RootState) => state.contractData)
  const newTenantsClose = () => {
    dispatch(tenantsClose(false))
    dispatch(contractOpen(true));
    setDouble(false);
    setHide1(false);
    // setHide2(false);
    setloader_on(false);
    setShowHideField("");
    setSpouseHide(false);
    reset();
  }
  const [addressError, setaddressError] = useState(false)
  const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
  const [hide1, setHide1] = useState(false);
  const [hide2, setHide2] = useState("");
  const [AllStates, setAllStates] = useState([]);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let regexPattern = /^[^0-9./-]*$/;
  if(showHideField == "cnpj"){
    regexPattern = /^[a-zA-Z0-9 ]*$/;
  }
  const tenantsValidation1 = Yup.object().shape({
    name: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    mobile: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
      .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
    // email: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired'))
    //   .email(t('requiredMmessage.fieldNotValid')),
    // password: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired'))
    //   .matches(passwordRules, { message: t("requiredMmessage.password") }),
    // address: Yup.string().trim().nullable()
    // .required(t('requiredMmessage.fieldRequired'))
    address: Yup.string().trim().nullable()
    .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
  otherwise: Yup.string().nullable(), 

  });

  let frm = {
    resolver: yupResolver(tenantsValidation1)
  }

  const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<tenantsForm>(frm);
  const onSubmit = async (data: tenantsForm, e: any) => {
    e.preventDefault();
    setloader_on(true);
    setDouble(true)
    let response = await dispatch(insertTenants(data))
    if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
      dispatch(getTenants());
      setDouble(false);
      setloader_on(false);
      let tenantId = response.payload.data[0].id;
      dispatch(setTenantId(tenantId));
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      newTenantsClose();

    }
    else {
      let msg = ((response.payload?.data && response.payload.data.length) || response.payload.data) ? response.payload.message : '';
      if (response.payload?.data[0]?.statusCode == 409) {
        msg = t("Sweetalert.unqueEmails");
      }
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: msg ? msg : t("Sweetalert.somethingWent"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000
      });
      setloader_on(false);
      setDouble(false)

    }
  }
  const HandleInput = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('telephone');
    }
  }
  const HandleInputMob = (e: any) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("mobile", {
        type: "manual",
        message: `${t("requiredMmessage.mobileError")}`
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('mobile');
    }
  }


  const handleCPF_CNJPVaue = async (e, type) => {
    let name = e.target.name;
    let checkCNJP = CNJP_CPFValidation(e.target.value, type);
    setSpouseHide(false);
    if (checkCNJP?.["status"]) {
      if (e.target.value !== "") {
        setShowHideField(checkCNJP?.["type"]);
        clearErrors(name)
        let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");

        setValue("ID_PESSOAS",duplicateData["data"][0]?.ID_PESSOA_PES);
        let boolVal = false
        boolVal = duplicateData["data"][0]?.FL_LOCATARIO_PES == 1
        const fieldsMapping: any = {
          email: "ST_EMAIL_PES",
          name: "ST_NOME_PES",
          mobile: "ST_CELULAR_PES",
          complement: "ST_COMPLEMENTO_PES",
          zipcode: "ST_CEP_PES",
          city: "ST_CIDADE_PES",
          address: "ST_ENDERECO_PES",
          state: "ST_ESTADO_PES",
          number: "ST_NUMERO_PES",
          neighborhood: "ST_BAIRRO_PES",
          telephone: "ST_TELEFONE_PES",
          observation: "ST_OBSERVACAO_PES",
          nationality: "ST_NACIONALIDADE_PES",
          sex: "ST_SEXO_PES",
          birth: "DT_NASCIMENTO_PES",
          marital_status: "ST_ESTADOCIVIL_PES",
          employment_company: "ST_TRABALHOCEP_PES",
          employment_profession: "ST_PROFISSAO_PES",
          employment_field_activity: "ST_RAMOATIVIDADE_PES",
          employment_address: "ST_TRABALHOENDERECO_PES",
          employment_number: "ST_TRABALHONUMERO_PES",
          employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
          employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
          employment_city: "ST_TRABALHOCIDADE_PES",
          employment_state: "ST_TRABALHOESTADO_PES",
          employment_monthly_income: "VL_RENDAMENSAL_PES",
          rg: "ST_RG_COJ",
          retention: "VL_TXISSQN_PES",
          mother: "ST_NOMEMAE_PES",
          father: "ST_NOMEPAI_PES",
          shipping_date: "DT_EXPEDICAORG_PES",
          block_balance: "VL_SALDOBLOQUEADO_PES",
          manager: "ID_GESTOR_GES",
          issue_agency: "ST_ORGAO_PES",
          corporateReason: "ST_NOME_PES",
          stateRegistration: "ST_IE_PES",
          municipalRegistration: "ST_INSCMUNICIPAL_PES"
        };
        if (duplicateData.data.length > 0) {
          setHide1(true);
          if (boolVal) {
            setError(name, {
              type: "manual",
              message: t("formLabel.ErrorCNPJCPF")
            });
            setcnpjcpferrormessage({
              name: name,
              message: t("formLabel.ErrorCNPJCPF")
            })
            setDouble(true);
          } else {
            setDouble(false);
            setHide1(true)

            // Assuming `duplicateData` is correctly populated
            const data: any = duplicateData?.["data"]?.[0];

            if (data) {
              Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                setValue(fieldName as any, data?.[dataKey as keyof typeof data]);
                setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
              });
            }

          }
        } else {
          Object?.entries(fieldsMapping)?.forEach(([fieldName, dataKey]) => {
            setValue(fieldName as any, "");
            setValue('ID_FAVORECIDO_FAV', "")
            setValue('ID_SACADO_SAC', "")
          });
          setHide1(true)
          // setHide1(checkCNJP?.["type"])
          setDouble(false);
        }
      }
    } else {
      setHide1(false)
    }
    if (checkCNJP?.["status"] || e.target.value == "") {

    } else {
      setDouble(true)
      setcnpjcpferrormessage({
        name: name,
        message: checkCNJP?.["message"]
      })
      setError(name, {
        type: "manual",
        message: checkCNJP?.["message"]
      });
    };
  }
  const handleChangeMaritail = (e) => {
    setSpouseHide(false);
    if (e.target.value == "1" || e.target.value == "7") {
      setSpouseHide(true);
    }
  }
  let [zipvalue, setZipvalue] = useState({
    zip: "",
    empzip: "",
  });
  const [update, setUpdate] = useState(1);

  useEffect(() => {
    (async () => {
      let resp = await customerService.getAddressBasedOnZipcode(`states`);
      if (resp.data.status == "success" || resp.data.type == "Success") {
        let loop = resp.data.data;
        let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
        // Sort the stateData alphabetically by label
        stateData.sort((a, b) => a.label.localeCompare(b.label));
        setAllStates(stateData);
      }
    })()
  }, [update]);

  const getLocation = async (e, inputType, forwhich) => {
    let str = e.target.value
    let newStr = str.replace('-', ''); // Removes the hyphen
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
    let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
    // Error validation
    // Retrieve address based on zipcode using customerService
    let resp = await customerService.getAddressBasedOnZipcode(
      `${inputType}/${newStr}`
    );
    let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
    let querySelsecond: any = document.querySelector('.accordion-custom [name="employment_zipcode"]');

    if (forwhich == "first") {
      setZipvalue({
        ...zipvalue,
        ["zip"]: str.trim(),
      })
    } else {
      setZipvalue({
        ...zipvalue,
        ["empzip"]: str.trim()
      })
    }

    if (newValue !== "" || newValue !== null || newValue !== undefined) {
      if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
        let res = resp["data"]["data"];
        if (forwhich == "first") {
          setZipvalue({
            ...zipvalue,
            ["zip"]: '',
          })
          querySelfirst.style.border = "0px solid red";
          if (res[0]["city"]) {
            setValue("city", res[0]["city"])
          }

          if (res[0]["neigbohood"]) {
            setValue("neighborhood", res[0]["neigbohood"])
          }

          if (res[0]["state"]) {
            setValue("state", res[0]["state"])
          }

          if (res[0]["street"] || res[0]["streetType"]) {
            let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
            setValue("address", data)
          }
        } else {
          setZipvalue({
            ...zipvalue,
            ["empzip"]: ''
          })
          querySelsecond.style.border = "0px solid red";
          if (res[0]["city"]) {
            setValue("employment_city", res[0]["city"])
          }

          if (res[0]["neigbohood"]) {
            setValue("employment_neighborhood", res[0]["neigbohood"])
          }

          if (res[0]["state"]) {
            setValue("employment_state", res[0]["state"])
          }

          if (res[0]["street"] || res[0]["streetType"]) {
            let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
            setValue("employment_address", data)
          }
        }

      } else {
        if (forwhich == "first") {
          querySelfirst.style.border = "2px solid red"
          setValue("city", "")
          setValue("neighborhood", "")
          setValue("state", "")
          setValue("address", "")
        } else {
          querySelsecond.style.border = "2px solid red"
          setValue("employment_city", "")
          setValue("employment_neighborhood", "")
          setValue("employment_state", "")
          setValue("employment_address", "")
        }
      }
    } else {
      if (forwhich == "first") {
        querySelfirst.style.border = "0px solid red";
      } else {
        querySelsecond.style.border = "0px solid red"
      }
    }
  }
  const handleNameof = (e) => {
    let { value } = e.target
    // Error validation
    if (value == "" || value == null || value == undefined) {
      // Set an error message using setError or update your error state
      setError("name", {
        type: "manual",
        message: (t('requiredMmessage.fieldRequired'))
      });
    } else {
      // Clear the telephone error if the value is not empty
      clearErrors('name');
    }
  }

  

  const Handleaddress = (e) => {
    let { value } = e.target;
    let numberOfCharacters = value.length;
    // Handle the character length validation
    if (numberOfCharacters > 40) {
      setError("address", {
        type: "manual",
        message: t("requiredMmessage.40characters")
      });
    } else {
      clearErrors("address")
    }
  };
  
  /*----------Open the new representative modal form------------*/
  const [showNewRepresentativeModal, setNewRepresentativeModal] = useState(false);
  const newRepresentativeModalClose = () => {
    setNewRepresentativeModal(false);
    dispatch(tenantsOpen(true));
  }
  const newRepresentativeModalShow = () => {
    setNewRepresentativeModal(true);
    dispatch(tenantsClose(false))
  }
  const [addState, setAddState] = useState({
    retentionn: [
      { Tax: "", Aliquot: "" },
    ],
    beneficiaries: [
      { name: "", transfer: "" }
    ],
    representative: [
      { name: "" }
    ]
  });

  const handleChangeInput = (e: any, index: any, type: string, field: string) => {
    let value = e.target.value;
    addState[type][index][field] = value;
    let allState: any = addState;
    setAddState(allState);
  }

  const addAnother = (e: any, type: string) => {
    e.preventDefault();
    if (type == "retentionn") {
      let dataAdd: any = {
        ...addState,
        retentionn: [
          ...addState["retentionn"],
          { Tax: "", Aliquot: "" }
        ]
      }
      setAddState(dataAdd)
    }

    if (type == "beneficiaries") {
      let dataAdd: any = {
        ...addState,
        beneficiaries: [
          ...addState["beneficiaries"],
          { name: "", transfer: "" }
        ]
      }
      setAddState(dataAdd)
    }

    if (type == "representative") {
      let dataAdd: any = {
        ...addState,
        representative: [
          ...addState["representative"],
          { name: "" }
        ]
      }
      setAddState(dataAdd)
    }
  }

  const removeAddAnother = (index: any, array: any, type: any) => {
    array.splice(index, 1);
    // reset(type);
    if (type == "retentionn") {
      array.forEach((item: any, index: number) => {
        let Tax: any = `retentionn.${index}.tax`;
        let Aliquot: any = `retentionn.${index}.Aliquot`;
        setValue(Tax, item.Tax);
        setValue(Aliquot, item.Aliquot);
      })
      setAddState({
        ...addState,
        retentionn: array
      })
    }

    if (type == "beneficiaries") {
      array.forEach((item: any, index: number) => {
        let name: any = `beneficiaries.${index}.name`;
        let transfer: any = `beneficiaries.${index}.transfer`;
        setValue(name, item.name);
        setValue(transfer, item.transfer);
      })
      setAddState({
        ...addState,
        retentionn: array
      })
    }

    if (type == "representative") {
      array.forEach((item: any, index: number) => {
        let name: any = `representative.${index}.name`;
        setValue(name, item.name);
      })
      setAddState({
        ...addState,
        retentionn: array
      })
    }
  }
  const handleRepersen = (e: any, index) => {
    let target_name = e.target.name;
    let target_value = e.target.value;
    addState["representative"][`${index}`]["name"] = String(target_value);
    setAddState(addState)

  }
  const handleRepresentative = async () => {
    let resp = await dispatch(GetRepresentativeService())
    if (resp.payload.type == "success" || resp.payload.type == "Success") {
      setRepreData(resp.payload.data)
    }
  }
  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Offcanvas className="border-sm-none" show={newTenantsShow} onHide={newTenantsClose} placement="end">
        <Form className="d-flex flex-column h-100" id="contractTenant" onSubmit={handleSubmit(onSubmit)} >
          <Offcanvas.Header className="border-bottom" closeButton>
            <Offcanvas.Title className="fw-semibold">
              <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />{t('customerAndEmployees.newTenant')}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p className="mb-lg-4 mb-3">{t('newContractDev.newTenantContent')}</p>
            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
              <input type="text" {...register('ID_SACADO_SAC')} hidden />
              <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden />
              <input type="text" {...register('ID_PESSOAS')} hidden  />

              <Col md={12}>
                <FloatingLabel controlId="floatingInput" label={t('newContractDev.cpfOrcnpj') + " *"}>
                  <Form.Control type="text" placeholder={t('newContractDev.cpfOrcnpj') + " *"} className={`${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                  <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            {hide1 ?
              <>
                <Row className="gy-lg-4 gy-3">
                  {showHideField !== "cpf" ?
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                      <Col md={6}>
                        <FloatingLabel controlId="floatingInput" label={t('newContractDev.corporateName')} >
                          <Form.Control type="text" placeholder={t('newContractDev.tradeName')} {...register('corporateReason')} />
                        </FloatingLabel>

                      </Col>
                      <Col md={6}>
                        <FloatingLabel controlId="floatingInput" label={t("formLabel.fantasyName") + " *"}>
                          <Form.Control type="text" placeholder={t('newContractDev.corporateName')} className={`${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                          <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.stateregistration')}>
                          <Form.Control type="text" maxLength={20} placeholder={t('formLabel.stateregistration')} className={`${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                          <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.municipalregistration')}>
                          <Form.Control type="text" maxLength={20} placeholder={t('formLabel.municipalregistration')} className={`${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                          <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row> :
                    <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                      <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                      <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                  }


                </Row>
                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.contactInformation')}</h6>
                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label={t('newContractDev.cellphoneNumber') + " *"}>
                      <Form.Control type="text" placeholder={t('newContractDev.cellphoneNumber')} className={`${errors.mobile ? 'is-invalid' : ''}`} {...register('mobile')} onChange={HandleInputMob} />
                      <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label={t('formLabel.telephone')}>
                      <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`${errors.telephone ? 'is-invalid' : ''}`} {...register('telephone')} onChange={HandleInput} />
                      <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput5" label={t('formLabel.email')}>
                  <Form.Control type="text" placeholder={t('formLabel.email')} className={`${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                  <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel controlId="floatingInput" label={t('formLabel.password')} className="mb-3" >
                  <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />
                  <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                </FloatingLabel>
                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.parentage')}</h6>
                <Row className="g-3 g-lg-4">
                  <Col>
                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.motherName')} >
                      <Form.Control type="text" placeholder={t('formLabel.motherName')} {...register('mother')} />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.fatherName')}>
                      <Form.Control type="text" placeholder={t('formLabel.fatherName')} {...register('father')} />
                    </FloatingLabel>
                  </Col>
                </Row>

                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.address')}</h6>
                <div className='accordion-custom'>
                  <Row className="gy-lg-4 gy-3">
                    <Col md={4}>
                      <InputGroup>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.zipCode')}>
                          <Form.Control type="text" placeholder={t('formLabel.zipCode')} {...register('zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "first")} />
                        </FloatingLabel>
                        <InputGroup.Text><img src="./assets/images/icons/search.svg" className="h-18" alt="Search icon" /></InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col md={8}>
                      <FloatingLabel controlId="floatingInput" label={t('formLabel.address')}>
                      <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                          >
                        <Form.Control type="text" placeholder={t('formLabel.address')} className={`${errors.address ? 'is-invalid' : ''}`} {...register('address')} onChange={(e) => Handleaddress(e)} />
                        </OverlayTrigger>
                        <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                        {/* {addressError ? <span className="text-primary"> <small>{t("requiredMmessage.40characters")}</small></span> : ""} */}
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t('number')}>
                        <Form.Control type="text" placeholder={t('number')} {...register('number')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={8}>
                      <FloatingLabel controlId="floatingInput" label={t('formLabel.complement')}>
                        <Form.Control type="text" placeholder={t('formLabel.complement')} {...register('complement')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                        <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                          <option value="">{t('formLabel.select')}</option>
                          {
                            AllStates.map((item, index) => {
                              return (
                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                              )
                            })
                          }
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t('formLabel.city')}>
                        <Form.Control type="text" placeholder={t('formLabel.city')} {...register('city')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t('formLabel.neighborhood')}>
                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')} {...register('neighborhood')} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.employmentInformation')}</h6>
                  <Row className="g-lg-4 g-3">
                    <Col md={6}>
                      <FloatingLabel controlId="floatingSelect" label={t('formLabel.company')}>
                        <Form.Control type="text" placeholder={t('formLabel.company')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingSelect" label={t('occupation')}>
                        <Form.Control type="text" placeholder={t('occupation')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingSelect" label={t('newContractDev.businessIndustry')}>
                        <Form.Control type="text" placeholder={t('newContractDev.businessIndustry')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                        <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <InputGroup>
                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                          <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "second")} />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} alt="Search" className="h-18 cursor-pe" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col md={8}>
                      <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                        <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={8}>
                      <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                        <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                        <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                          <option value="">{t('formLabel.select')}</option>
                          {
                            AllStates.map((item, index) => {
                              return (
                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                              )
                            })
                          }
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                        <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div>
                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.representatives')}</h6>
                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                  {
                    Object.keys(addState.representative).map((item: any, index: number) => {
                      return (
                        <Row className="mt-1">
                          <Col xs={11}>
                            <InputGroup>
                              <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                <Form.Select {...register(`representative.${index}.name`)} onClick={handleRepresentative} onChange={(e) => handleRepersen(e, index)}>
                                  <option value="">{t('formLabel.select')}</option>
                                  {repreData?.map((curElem: any, index) => (
                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                  ))}
                                </Form.Select>
                              </FloatingLabel>
                              <InputGroup.Text onClick={newRepresentativeModalShow}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                          {/* <Col>
                                                            <span className="input-group-text">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} className="h-20" />
                                                            </span>
                                                        </Col> */}
                          {
                            Object.keys(addState.representative) &&
                            Object.keys(addState.representative).length > 1 &&
                            <Col md={1}>
                              <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.representative, "representative")}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                            </Col>
                          }
                        </Row>
                      )
                    })
                  }
                  < Col >
                    <div className="text-center mt-1">
                      <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother(e, "representative")}>
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.AddAnother')}
                      </Button>
                    </div>
                  </Col>
                  {/* <Col xs>
                    <InputGroup>
                      <FloatingLabel controlId="floatingInput" label={t('formLabel.name')}>
                        <Form.Control type="text" placeholder={t('formLabel.name')} {...register('representatives_name')} />
                      </FloatingLabel>
                      <InputGroup.Text>
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col xs="auto">
                    <Button className="btn btn-link bg-light min-w-auto border-light rounded-4">
                      <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                    </Button>
                  </Col> */}
                </Row>

                {
                  showHideField == "cpf" ?
                    <>
                      <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('others')}</h6>
                      <Row className="g-3 g-lg-4">
                        <Col md={4}>
                          <FloatingLabel controlId="floatingSelect" label={t('newContractDev.RGNumber')}>
                            <Form.Control type="text" placeholder={t('newContractDev.RGNumber')} {...register('rg')} />
                          </FloatingLabel>
                        </Col>
                        <Col md={4}>
                          <FloatingLabel controlId="floatingSelect" label={t('newContractDev.issuingAuthority')}>
                            <Form.Control type="text" placeholder={t('newContractDev.issuingAuthority')} {...register('issue_agency')} />
                          </FloatingLabel>
                        </Col>
                        <Col md={4}>
                          <FloatingLabel controlId="floatingSelect" label={t('newContractDev.issuanceDate')}>
                            <Form.Control type="date" placeholder={t('newContractDev.issuanceDate')} {...register('shipping_date')} />
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel controlId="floatingSelect" label={t('newContractDev.gender')}  {...register('sex')}  >
                            <Form.Select aria-label={t('newContractDev.gender')}>
                              <option value="">{t('dropdownItem.select')}..</option>
                              <option value="1">{t('masculine')}</option>
                              <option value="2">{t('feminine')}</option>
                            </Form.Select>
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                            <Form.Select  {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} onChange={(e) => handleChangeMaritail(e)}>
                              <option value="">{t('dropdownItem.select')}..</option>
                              <option value="1">{t("formLabel.married")}</option>
                              <option value="2">{t("formLabel.single")}</option>
                              <option value="3">{t("formLabel.widower")}</option>
                              <option value="4">{t("formLabel.legallySeparated")}</option>
                              <option value="5">{t("formLabel.disquitado")}</option>
                              <option value="6">{t("formLabel.divorced")}</option>
                              <option value="7">{t("formLabel.stableunion")}</option>
                            </Form.Select>
                          </FloatingLabel>
                        </Col>
                        <Col md={4}>
                          <FloatingLabel controlId="floatingSelect" label={t('newContractDev.dateOfBirth')}>
                            <Form.Control type="date" {...register('birth')} placeholder={t('newContractDev.dateOfBirth')} />
                          </FloatingLabel>
                        </Col>
                        <Col md={4}>
                          <FloatingLabel controlId="floatingSelect" label={t('newContractDev.nacionality')}>
                            <Form.Control type="text" placeholder={t('newContractDev.nacionality')} {...register('nationality')} />
                          </FloatingLabel>
                        </Col>
                        <Col md={4}>
                          <FloatingLabel controlId="floatingSelect" label={t('newContractDev.placeOfBirth')}>
                            <Form.Control type="text" placeholder={t('newContractDev.placeOfBirth')} {...register('birth_place')} />
                          </FloatingLabel>
                        </Col>
                        <Col md={12}>
                          <Form.Group controlId="domiciled" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                            <Form.Check type="checkbox" label={t('newContractDev.notDomiciledBrazi')}  {...register('non_recedential')} />
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group controlId="billing_rule" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                            <Form.Check type="checkbox" {...register('isBillingRule')} label={t('newContractDev.doNotNotifyBillingRule')} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                    : ''
                }
                {
                  spouseHide ?
                    <>
                      <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.spouse')}</h6>
                      <Row className="mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                            <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('spouse_name')} />
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                            <Form.Control type="text" placeholder={t('formLabel.cpf')}  {...register('spouse_cpf')} />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                            <Form.Control type="text" placeholder={t('RG')}  {...register('spouse_rg')} />
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                            <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('spouse_issue_agency')} />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                            <Form.Control type="text" placeholder={t('formLabel.profession')}  {...register('spouse_profession')} />
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                            <Form.Select {...register('spouse_sex')} aria-label={t('formLabel.sex')}>
                              <option value="">{t('dropdownItem.select')}..</option>
                              <option value="1">{t('masculine')}</option>
                              <option value="2">{t('feminine')}</option>
                            </Form.Select>
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cellPhone')}>
                            <Form.Control type="text" placeholder={t('formLabel.cellPhone')}  {...register('spouse_cellphone')} onChange={HandleInputMob}/>
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                            <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('spouse_telephone')} onChange={HandleInput}/>
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                            <Form.Control type="email" placeholder={t('formLabel.email')}  {...register('spouse_email')} />
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                            <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('spouse_nationality')} />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </>
                    : ''
                }
                <Row className="mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                  <Col md={12}>
                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                      <Form.Control as="textarea" placeholder="Observation" {...register('observation')} style={{ height: '100px' }} />
                    </FloatingLabel>
                  </Col>
                </Row>
              </>
              :
              ""
            }
          </Offcanvas.Body>
          <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
            {/* <Form.Check type="checkbox" {...register('isAfter')} label="After saving register another" /> */}
            <Button variant="outline-primary" onClick={newTenantsClose}>{t('button.close')}</Button>
            {/* <Button variant="outline-primary" onClick={handleSubmit(hide2 ? step1 : step1)} className="js-btn-step me-2">Previous</Button>
                <Button variant="outline-primary" style={{ display: hide1 ? "block" : "none" }} onClick={handleSubmit(hide1 ? step2 : step2)} className="bmin-w-auto js-btn-step">
                  Next
                </Button> */}
            {loader_on ?
              <Button className="btn-width">
                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
              </Button>
              :
              <Button disabled={double} className="min-w-auto" type="submit" variant="primary" onClick={openErrorbox}>
                {t('button.save')}
              </Button>}
          </div>
        </Form>
      </Offcanvas>
      {/* <!-- /New branch modal end --> */}
      <RepresentativeService showNewRepresentative={showNewRepresentativeModal} newRepresentativeClose={newRepresentativeModalClose} newRepresentativeShow={newRepresentativeModalShow} />
    </>
  )
}
export default TenantsService;