import { Form, Alert, Modal, Button} from 'react-bootstrap';
import React from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { questionContractClose } from "../../../../redux/slice/expenditureDataSlice";
import { useTranslation } from 'react-i18next';
import '../../../../App.css';

const ContractQuestionService = (indexForm) => {
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const { newQuestionContract } = useSelector((state: RootState) => state.expenditureData)
  const questionContractClosed = () => {
    dispatch(questionContractClose(false));
  }
  return (
    <>
      {/* <!-- New Index modal start from here --> */}
      <Modal show={newQuestionContract} onHide={questionContractClosed}>
        <Form >
          <Modal.Header closeButton>
            <Modal.Title>Contract Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="warning" className="mb-3 mb-lg-4">
              <ul className="mb-0 ps-2">
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</li>
              </ul>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={questionContractClosed}>{t("button.close")}</Button>            
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New Index modal end --> */}
    </>
  )
}
export default ContractQuestionService;