import React, { useState } from 'react';
import { Offcanvas, Form, Button, FloatingLabel, Row, Col, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CosignerService = (props) => {
    const [t] = useTranslation();
    //New cosigner modal close open function
    const { showNewCosigner, newCosignerClose, newCosignerShow } = props;

    return (
        <>
            {/* Notified modal start from here */}
            <Offcanvas className="border-sm-none" show={showNewCosigner} onHide={newCosignerClose} placement="end">
                <Form className="d-flex flex-column h-100">
                    <Offcanvas.Header className="border-bottom" closeButton>
                        <Offcanvas.Title className="fw-semibold">
                            <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />{t('newContractDev.newCosigner')}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                            <p className="mb-3 mb-lg-4">{t('newContractDev.newCosignerContent')}</p>
                            <FloatingLabel controlId="cpf" label={t('newContractDev.cpfOrcnpj') + " *"} className="mb-lg-4 mb-3">
                                <Form.Control type="text" placeholder={t('newContractDev.cpfOrcnpj') + " *"} value="999.999.999-99 (CPF example)" />
                            </FloatingLabel>
                            <FloatingLabel controlId="name" label={t('formLabel.name') + " *"} className="mb-lg-4 mb-3">
                                <Form.Control type="text" placeholder={t('formLabel.name') + " *"} />
                            </FloatingLabel>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="corporate_name" label={t('newContractDev.corporateName') + " *"}>
                                        <Form.Control type="text" placeholder={t('newContractDev.corporateName') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="trade_name" label={t('newContractDev.tradeName')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.tradeName')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="state_registration" label={t('formLabel.stateregistration')}>
                                        <Form.Control type="text" placeholder={t('formLabel.stateregistration')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="municipal_registration" label={t('formLabel.municipalregistration')}>
                                        <Form.Control type="text" placeholder={t('formLabel.municipalregistration')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.contactInformation')}</h6>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="cellphone" label={t('newContractDev.cellphoneNumber')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.cellphoneNumber')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="telephone" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={12}>
                                    <FloatingLabel controlId="email" label={t('formLabel.email')}>
                                        <Form.Control type="email" placeholder={t('formLabel.email')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.parentage')}</h6>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="mother_name" label={t('formLabel.motherName')}>
                                        <Form.Control type="text" placeholder={t('formLabel.motherName')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="father_name" label={t('formLabel.fatherName')}>
                                        <Form.Control type="text" placeholder={t('formLabel.fatherName')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.address')}</h6>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={4}>
                                    <InputGroup>
                                        <FloatingLabel controlId="zipcode" label={t('formLabel.zipCode') + " *"} >
                                            <Form.Control type="text" placeholder={t('formLabel.zipCode') + " *"} />
                                        </FloatingLabel>
                                        <InputGroup.Text>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} alt="Search" className="h-18 cursor-pe" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="address" label={t('formLabel.address') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.address') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="number" label={t('number') + " *"}>
                                        <Form.Control type="text" placeholder={t('number') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="complement" label={t('formLabel.complement') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.complement') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="state" label={t('formLabel.state') + " *"}>
                                        <Form.Select aria-label={t('formLabel.state')}>
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="city" label={t('formLabel.city') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.city') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="neighborhood" label={t('formLabel.neighborhood') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood') + " *"} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.employmentInformation')}</h6>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="company" label={t('Links.company')}>
                                        <Form.Control type="text" placeholder={t('Links.company')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="occupation" label={t('occupation')}>
                                        <Form.Control type="text" placeholder={t('occupation')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="business_industry" label={t('newContractDev.businessIndustry')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.businessIndustry')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="monthly_income" label={t('formLabel.monthlyIncome')}>
                                        <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <InputGroup>
                                        <FloatingLabel controlId="zipcode" label={t('formLabel.zipCode') + " *"} >
                                            <Form.Control type="text" placeholder={t('formLabel.zipCode') + " *"} />
                                        </FloatingLabel>
                                        <InputGroup.Text>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} alt="Search" className="h-18 cursor-pe" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="address" label={t('formLabel.address') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.address') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="number" label={t('number') + " *"}>
                                        <Form.Control type="text" placeholder={t('number') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="complement" label={t('formLabel.complement') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.complement') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="state" label={t('formLabel.state') + " *"}>
                                        <Form.Select aria-label={t('formLabel.state')}>
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="city" label={t('formLabel.city') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.city') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="neighborhood" label={t('formLabel.neighborhood') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood') + " *"} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.representatives')}</h6>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col xs>
                                    <InputGroup>
                                        <FloatingLabel controlId="representatives_name" label={t('formLabel.name')}>
                                            <Form.Control type="text" placeholder={t('formLabel.name')} />
                                        </FloatingLabel>
                                        <InputGroup.Text>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="user add" className="h-20 cursor-pe" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col xs="auto">
                                    <Button className="btn btn-link bg-light min-w-auto border-light rounded-4"><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                </Col>
                            </Row>
                            <div className="text-center">
                                <Button variant='link' className="fw-bold p-0">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.addAnotherRepresentative')}
                                </Button>
                            </div>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.cosignerProperty')}</h6>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="property_type" label={t('formLabel.propertyType')}>
                                        <Form.Select aria-label={t('formLabel.propertyType')}>
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="property_value" label={t('formLabel.propertyValue') + " (R$)"}>
                                        <Form.Control type="text" placeholder={t('formLabel.propertyValue') + " (R$)"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <InputGroup>
                                        <FloatingLabel controlId="zipcode" label={t('formLabel.zipCode') + " *"} >
                                            <Form.Control type="text" placeholder={t('formLabel.zipCode') + " *"} />
                                        </FloatingLabel>
                                        <InputGroup.Text>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} alt="Search" className="h-18 cursor-pe" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="address" label={t('formLabel.address') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.address') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="number" label={t('number') + " *"}>
                                        <Form.Control type="text" placeholder={t('number') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="complement" label={t('formLabel.complement') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.complement') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="state" label={t('formLabel.state') + " *"}>
                                        <Form.Select aria-label={t('formLabel.state')}>
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="city" label={t('formLabel.city') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.city') + " *"} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="neighborhood" label={t('formLabel.neighborhood') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood') + " *"}/>
                                    </FloatingLabel>
                                </Col>

                                <Col md={6}>
                                    <FloatingLabel controlId="registry_office" label={t('propertyDev.registryOffice')}>
                                        <Form.Control type="text" placeholder={t('propertyDev.registryOffice')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="registration_number" label={t('propertyDev.registrationNumber')}>
                                        <Form.Control type="text" placeholder={t('propertyDev.registrationNumber')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={12}>
                                    <FloatingLabel controlId="building_condominium" label={t('formLabel.buildingCondominium')}>
                                        <Form.Control type="text" placeholder={t('formLabel.buildingCondominium')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <div className="text-center">
                                <Button variant='link' className="fw-bold p-0">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.addAnotherProperty')}
                                </Button>
                            </div>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('others')}</h6>
                            <Row className="gy-lg-4 gy-3">
                                <Col md={4}>
                                    <FloatingLabel controlId="rg" label={t('newContractDev.RGNumber')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.RGNumber')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="issuing_authority" label={t('newContractDev.issuingAuthority')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.issuingAuthority')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="issuance" label={t('newContractDev.issuanceDate')}>
                                        <Form.Control type="date" placeholder={t('newContractDev.issuanceDate')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="gender" label={t('newContractDev.gender')}>
                                        <Form.Select aria-label={t('newContractDev.gender')}>
                                            <option value="">{t("formLabel.select")}</option>
                                            <option value="1">{t("formLabel.male")}</option>
                                            <option value="2">{t("formLabel.female")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="marital_status" label={t('maritalStatus')}>
                                        <Form.Select aria-label={t('maritalStatus')}>
                                            <option value="">{t('formLabel.select')}</option>
                                                        <option value="1">{t("formLabel.married")}</option>
                                                        <option value="2">{t("formLabel.single")}</option>
                                                        <option value="3">{t("formLabel.widower")}</option>
                                                        <option value="4">{t("formLabel.legallySeparated")}</option>
                                                        <option value="5">{t("formLabel.disquitado")}</option>
                                                        <option value="6">{t("formLabel.divorced")}</option>
                                                        <option value="7">{t("formLabel.stableunion")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="dob" label={t('newContractDev.dateOfBirth')}>
                                        <Form.Control type="date" placeholder={t('newContractDev.dateOfBirth')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="nacionality" label={t('newContractDev.nacionality')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.nacionality')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="birth_place" label={t('newContractDev.placeOfBirth')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.placeOfBirth')}/>
                                    </FloatingLabel>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="domiciled_brazi" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                        <Form.Check type="checkbox" label={t('newContractDev.notDomiciledBrazi')} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <FloatingLabel controlId="floatingTextarea2" label={t('observation')} >
                                        <Form.Control as="textarea" placeholder={t('observation')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={newCosignerClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" className="min-w-auto" onClick={newCosignerClose}>
                            {t('button.save')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
            {/* /Notified modal end */}
        </>
    )
}

export default CosignerService
