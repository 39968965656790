import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";

let connectionName: any = '';
if (localStorage.getItem("userData")) {
    connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
    LOADING: "loading",
    SUCEESS: "success",
    ERROR: 'error'
});

export interface interface_nonpaymentCharge {
    arrayData: string[],
    totalData: number,
}

interface AuthState {
    nonpaymentsummery_get: any[],
    nonPaymentChargeData: interface_nonpaymentCharge,
    nonGetPaymentGetContact: any[],
    nonGetPaymentGetContactHistory: any[],
    nonPaymentExpenseChargeData: any[],
    nonPaymentExpenseCharge_installment_Data: any[]
    nonPayment_get_single_rece_expense_Data: any[]
    nonPaymentSetting_Data: any[]
}

const initialState: AuthState = {
    nonpaymentsummery_get: [],
    nonPaymentChargeData: {
        arrayData: [],
        totalData: 0,
    },
    nonGetPaymentGetContact: [],
    nonGetPaymentGetContactHistory: [],
    nonPaymentExpenseChargeData: [],
    nonPaymentExpenseCharge_installment_Data: [],
    nonPayment_get_single_rece_expense_Data: [],
    nonPaymentSetting_Data: []
};

let user_id = gettoken.userId
const nonPayment = createSlice({
    name: "NonPayment",
    initialState,
    reducers: {
        setEdit: (state, action) => {

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(nonPaymentSummery.pending, (state, action: PayloadAction<any>) => {
                state.nonpaymentsummery_get = []
            })
            .addCase(nonPaymentSummery.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonpaymentsummery_get = action["payload"]["data"];
            })
            .addCase(nonPaymentSummery.rejected, (state, action: PayloadAction<any>) => {
                state.nonpaymentsummery_get = []
            });

        builder
            .addCase(nonPaymentCharge.pending, (state, action: PayloadAction<any>) => {
                state.nonPaymentChargeData = {
                    arrayData: [],
                    totalData: 0,
                }
            })
            .addCase(nonPaymentCharge.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonPaymentChargeData = {
                    arrayData: action["payload"]["data"],
                    totalData: action["payload"]["totalRow"],
                }
            })
            .addCase(nonPaymentCharge.rejected, (state, action: PayloadAction<any>) => {
                state.nonPaymentChargeData = {
                    arrayData: [],
                    totalData: 0,
                }
            });

        builder
            .addCase(nonPaymentChargeAgreement.pending, (state, action: PayloadAction<any>) => {
                state.nonPaymentChargeData = {
                    arrayData: [],
                    totalData: 0,
                }
            })
            .addCase(nonPaymentChargeAgreement.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonPaymentChargeData = {
                    arrayData: action["payload"]["data"],
                    totalData: action["payload"]["totalRow"],
                }
            })
            .addCase(nonPaymentChargeAgreement.rejected, (state, action: PayloadAction<any>) => {
                state.nonPaymentChargeData = {
                    arrayData: [],
                    totalData: 0,
                }
            });

        builder
            .addCase(nonPaymentGetContact.pending, (state, action: PayloadAction<any>) => {
                state.nonGetPaymentGetContact = []
            })
            .addCase(nonPaymentGetContact.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonGetPaymentGetContact = action["payload"]["data"];
            })
            .addCase(nonPaymentGetContact.rejected, (state, action: PayloadAction<any>) => {
                state.nonGetPaymentGetContact = []
            });

        builder
            .addCase(getNonpaymentContactHistory.pending, (state, action: PayloadAction<any>) => {
                state.nonGetPaymentGetContactHistory = [];
            })
            .addCase(getNonpaymentContactHistory.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonGetPaymentGetContactHistory = action.payload.data;
            })
            .addCase(getNonpaymentContactHistory.rejected, (state, action: PayloadAction<any>) => {
                state.nonGetPaymentGetContactHistory = [];
            });

        builder
            .addCase(nonPaymentExpenseCharge.pending, (state, action: PayloadAction<any>) => {
                state.nonPaymentExpenseChargeData = [];
            })
            .addCase(nonPaymentExpenseCharge.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonPaymentExpenseChargeData = action.payload.data;
            })
            .addCase(nonPaymentExpenseCharge.rejected, (state, action: PayloadAction<any>) => {
                state.nonPaymentExpenseChargeData = [];
            });
        builder
            .addCase(nonPaymentExpenseCharge_installment.pending, (state, action: PayloadAction<any>) => {
                state.nonPaymentExpenseCharge_installment_Data = [];
            })
            .addCase(nonPaymentExpenseCharge_installment.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonPaymentExpenseCharge_installment_Data = action.payload.data;
            })
            .addCase(nonPaymentExpenseCharge_installment.rejected, (state, action: PayloadAction<any>) => {
                state.nonPaymentExpenseCharge_installment_Data = [];
            });
        builder
            .addCase(nonPayment_get_single_rece_expense.pending, (state, action: PayloadAction<any>) => {
                state.nonPayment_get_single_rece_expense_Data = [];
            })
            .addCase(nonPayment_get_single_rece_expense.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonPayment_get_single_rece_expense_Data = action.payload.data;
            })
            .addCase(nonPayment_get_single_rece_expense.rejected, (state, action: PayloadAction<any>) => {
                state.nonPayment_get_single_rece_expense_Data = [];
            });
        builder
            .addCase(nonPaymentSetting.pending, (state, action: PayloadAction<any>) => {
                state.nonPaymentSetting_Data = [];
            })
            .addCase(nonPaymentSetting.fulfilled, (state, action: PayloadAction<any>) => {
                state.nonPaymentSetting_Data = action.payload.data;
            })
            .addCase(nonPaymentSetting.rejected, (state, action: PayloadAction<any>) => {
                state.nonPaymentSetting_Data = [];
            });
    },
});

export const { setEdit } = nonPayment.actions
export default nonPayment.reducer

//POST - Get summery non payment list
export const nonPaymentSummery = createAsyncThunk(
    'non_payment_get',
    async () => {
        let connectionName: any = '';
        if (localStorage.getItem("userData")) {
            connectionName = localStorage.getItem("userData");
        }
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/get_summery`, {
            method: 'POST',
            body: "",
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json',
                'connection-name': connectionName,
            },
        });
        const data = await response.json();
        return data
    }
);

// POST - Get all non payment charge defaulter
export const nonPaymentCharge = createAsyncThunk(
    'non_payment_charges',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/getNonpayment`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

// POST - Get all non payment charge agreement
export const nonPaymentChargeAgreement = createAsyncThunk(
    'non_payment_charges_agrement',
    async (postData: any) => {
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/getNonpaymentAgreement`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': gettoken.Token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

// POST - Get all non payment charge
export const nonPaymentGetContact = createAsyncThunk(
    'non_payment_contact_get',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/getNonpaymentContact`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

export const getNonpaymentContactHistory = createAsyncThunk(
    'non_payment_contact_history_get',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/getNonpaymentContactHistory`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

// POST - Get all non payment charge
export const nonPaymentPostContact = createAsyncThunk(
    'non_payment_contact_post',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/postNonpaymentContact`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data;
    }
);

// POST - Get all non payment charge
export const nonPaymentPost_RecordAgreement = createAsyncThunk(
    'non_payment_post_record_agreement',
    async (postData: any) => {
        var raw = {
            "url": config.emailApi,
            "Authorization": "Basic Vmd3V3REeHFkN1B0NnpjOXI2dVhwdThOOmUlIWdYV1BJUUVZJVJteWIhNyNPOTVCbE1Pam1PbVBLJDVyOQ==",
            "type": "New Agreement",
            ["user_id"]: 1,
            "hit_url": config.companyV2_URL + "/send_email/send_email"
        };
        postData["submitData"]["emailData"]["email_url"] = raw;
        postData.userId = gettoken.userId;
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/postNonpayment_recordAgreement`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    });

export const nonPaymentExpenseCharge = createAsyncThunk(
    'non_payment_expense_charges',
    async (postData: any) => {
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/postNonpayment_recordAgreement_expense`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': gettoken.Token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

export const nonPaymentExpenseCharge_installment = createAsyncThunk(
    'non_payment_expense_installment_charges',
    async (postData: any) => {
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/nonPaymentExpenseCharge_installment`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': gettoken.Token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

export const NonPaymentUndoAgreement = createAsyncThunk(
    'nonPayment_undo_agreement',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/nonPayment_undo_agreement`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

export const NonPaymentFlagProtest = createAsyncThunk(
    'nonPayment_flag_protest',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/nonPayment_flag_protest`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

export const nonPayment_get_single_rece_expense = createAsyncThunk(
    'nonPayment_get_single_rece_expense',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/nonPayment_get_single_rece_expense`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

export const nonPayment_send_charge = createAsyncThunk(
    'nonPayment_send_charge',
    async (postData: any) => {
        var raw = JSON.stringify({
            "url": config.emailApi,
            "Authorization": "Basic Vmd3V3REeHFkN1B0NnpjOXI2dVhwdThOOmUlIWdYV1BJUUVZJVJteWIhNyNPOTVCbE1Pam1PbVBLJDVyOQ==",
            data: postData,
            ["user_id"]: 1
        });

        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/nonPayment_send_charge`, {
            method: 'POST',
            body: raw,
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

// POST - Get all non payment charge defaulter
export const nonPaymentSetting = createAsyncThunk(
    'non_payment_setting_get',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/non_payment_setting_get`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);

// POST - Get all non payment charge defaulter
export const nonPaymentSetting_post = createAsyncThunk(
    'non_payment_setting_post',
    async (postData: any) => {
        let token: any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.CUSTOMER_API}/nonpayment/non_payment_setting_post`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json'
            },
        });
        const data = await response.json();
        return data
    }
);