import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const Share = (props) => {
    const [t] = useTranslation();
    //Share modal close open function
    const { showShare, shareClose } = props;

    //Copy link modal close open function
    const [showCopyLinkModal, setCopyLinkModal] = useState(false);
    const copyLinkModalClose = () => setCopyLinkModal(false);
    const copyLinkModalShow = () => {
        shareClose(true)
        setCopyLinkModal(true);
    }

    return (
        <>
            <Modal show={showShare} onHide={shareClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.share")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {t("DocumentTemplate.shareContent")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={copyLinkModalShow}>
                        {t("DocumentTemplate.neutralTemplate")}
                        </Button>
                        <Button variant="primary" onClick={copyLinkModalShow}>
                        {t("DocumentTemplate.contractDocument")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showCopyLinkModal} onHide={copyLinkModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.share")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                        <p className="mb-lg-4 mb-3 text-dark-70">{t("DocumentTemplate.copyContent")}</p>
                        <Form.Group controlId="logoHeader" className="bg-white border rounded-4 p-2 min-h-56 d-flex justify-content-between align-items-center">
                            <span className="text-truncate">https://share.kenlolocacao.com.br/modelo/6g83kds98e7</span>
                            <span className="flex-shrink-0 text-primary ms-2 cursor-pe"><img src="assets/images/icons/copy.svg" alt="copy" className="me-1" /> {t("DocumentTemplate.copy")}</span>
                        </Form.Group>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}

export default Share
