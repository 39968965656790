import React, { useState, useEffect } from 'react'
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { FormsOfReceipts, get_forms_of_receipts, update_forms_of_receipts } from '../../../../redux/slice/formsOfReceiptSlice';
import { Card, Col, Form, Button, FloatingLabel, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { handleAction } from "../actions"
import { useTranslation } from 'react-i18next';
import { fetchService } from '../../../../redux/slice/ServiceSlice';
import { converter, convertToNumber, encrypt_Decrypt, formatNumber } from '../../../constants/utils';
import { Link, useNavigate } from "react-router-dom";
import i18n from '../../../../i18n';
interface BranchType {
    value: number;
    label: string;
}
// used it to show validation error on form
const Schema = yup.object().shape({
    monthly_interest: yup.string().required('Monthly interest rate (%) is required'),
    interest_and_fine: yup.string().required('Interest and fine instructions on the bill is required'),
    fine_rate: yup.string().required('Fine rate (%) is required'),
    discount_rate: yup.string().required('Discount rate is required'),
    give_discount: yup.string().required('Give discount until is required'),
    ticket_discount: yup.string().required('Ticket Discount Instructions< is required'),
    apply_discount_all: yup.string().required('Aplicar desconto em todos os serviços is required'),
    lawyer_fee: yup.string().required('Lawyer Fees is required'),
})

export const Practiced_rates = () => {
    // This line initializes the useDispatch hook and assigns it to the dispatch variable
    const dispatch = useDispatch<AppDispatch>()

    const navigate = useNavigate();
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation()
    // The useForm hook is used to handle form registration, submission, and validation.
    const { handleSubmit, register, setError, setValue, getValues, clearErrors, formState: { errors } } = useForm<FormsOfReceipts>({
        resolver: yupResolver(Schema),
    });
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3120) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
    // Define and initialize state using useState
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setdiscountedService([{
            value: "",
            label: ""
        }])
    };
    const handleCloseset = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    };
    const [inputFields, setInputFields] = useState({});
    const [opencount, setOpenCount] = useState(0);
    const [disService, setDisService] = useState(false);
    const [isMonthlyAdminRatePercentage, setMonthlyAdminRatePercentage] = useState(false);
    // Created a use state to conduct checkeddata shows
    const [checkeddata, setCheckData] = useState({
        tradename: false,
        bodyslip: false,
    })
    // Retrieve formdata,and serviceData show1, and status from the Redux state
    const { formdata } = useSelector((state: RootState) => state.forms)
    const { status } = useSelector((state: RootState) => state.forms)
    const serviceData = useSelector((state: RootState) => state.service);

    // Define and initialize state using useState
    const [Allservice, setAllService] = useState({
        service: [
            { value: 1, label: "Service 1" },
        ],
    })

    const [discountedService, setdiscountedService] = useState([
        {
            value: "",
            label: ""
        }
    ])

    useEffect(() => {
        // Dispatch an action to get forms of receipts
        dispatch(get_forms_of_receipts())
        // Dispatch an action to fetch services
        dispatch(fetchService());
    }, [dispatch])

    useEffect(() => {
        // Update the Allservice state when the serviceData "servicelist" changes
        if (serviceData["servicelist"]) {
            let store: any = [];
            serviceData["servicelist"].forEach(item => {
                store.push({ value: item.ID_PRODUTO_PRD, label: item.ST_DESCRICAO_PRD })
            })
            setAllService({
                ...Allservice,
                ["service"]: store
            })
        }
    }, [serviceData["servicelist"]])

    const allmultipleFilter = (value: any, index, type) => {
        // Update the discountedService state with the selected item and type
        let dataValue = Allservice["service"].filter((item) => item.value == value);
        discountedService[index]["value"] = String(dataValue[0]["value"]);
        discountedService[index]["label"] = dataValue[0]["label"];
        setdiscountedService([...discountedService])
    }

    useEffect(() => {
        // Fields to populate with values
        const percentFields: string[] = ['monthly_interest', 'fine_rate', 'discount_rate']
        const fields: string[] = ['monthly_interest', 'interest_and_fine', 'fine_rate', 'discount_rate', 'give_discount', 'ticket_discount', 'apply_discount_all', 'lawyer_fee', 'use_percentage'];
        fields.forEach((field: any) => {
            if (field == "monthly_interest") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, 1)
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "interest_and_fine") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, 1)
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "fine_rate") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, 10)
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "discount_rate") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, 0)
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "give_discount") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, "")
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "ticket_discount") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, 1)
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "lawyer_fee") {
                if (String(formdata?.[field]) == "" || String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, 0)
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "apply_discount_all") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setValue(field, 1)
                } else {
                    setValue(field, formdata?.[field])
                }
            } else if (field == "use_percentage") {
                if (String(formdata?.[field]) == "" || formdata?.[field] === null) {
                    setMonthlyAdminRatePercentage(false);
                    setPercentornot({
                        ...percentornot,
                        ["monthly_percent"]: false
                    })
                } else {
                    setMonthlyAdminRatePercentage(formdata?.[field]);
                    setPercentornot({
                        ...percentornot,
                        ["monthly_percent"]: formdata?.[field] ? false : true
                    })
                }
            } else {
                setValue(field, formdata?.[field])
            }
        });

        percentFields.forEach((field: any) => {
            const value = String(formdata?.[field]);

            if (value !== "" && value !== null) {
                setValue(field, converter(value));  // Apply converter if value is not null or empty
            } else {
                setValue(field, "0");  // Set a default value like "0" if value is null or empty
            }
        });

        let discountedServ = formdata?.["discounted_services"];
        let i = 0;
        if (discountedServ) {
            let mt: any = {};
            discountedServ.forEach(element => {
                discountedService[i] = {
                    value: element.value,
                    label: element.label
                }
                i++;
            });
            setdiscountedService([...discountedService])
        }

        // Check if apply_discount_all field is set to 2
        if (formdata && formdata?.["apply_discount_all"] == 2) {
            setDisService(true);
            setTimeout(() => {
                // Disable button with class 'replacehide removeInsertDisable'
                document.querySelector('.replacehide.removeInsertDisable button')?.setAttribute('disabled', "");
            }, 200)
        }
        if (formdata && formdata.length > 0) {
            let data: any = [];
            // Populate data array with untill value from discountSer array
            formdata[0]["discountSer"].forEach((item, index) => {
                data.push({
                    [`open_${index}`]: {
                        "untill": item.untill
                    }
                })
            })
            let inputField = [];
            // Convert data array into inputField object
            data.forEach(async (item, index) => {
                inputField[`${Object.keys(item)}`] = { ...item[`${Object.keys(item)}`] }
            })
            // Set the open count and input fields
            setOpenCount(data ? data.length : 0);
            setInputFields({ ...inputField })
        }
    }, [checkeddata, formdata])

    // culumn is added one by one from the discounted Service remove 
    const removeInputFields = (index) => {
        discountedService.splice(index, 1);
        setdiscountedService([...discountedService])
    }

    const addInputField = (e: any, TotalLength) => {
        setdiscountedService([
            ...discountedService,
            discountedService[TotalLength] = {
                value: "",
                label: ""
            }
        ])
    }

    const Addiscount = (data) => {
        let i: number = 0;
        let open_hr_arr: any = [];
        let item: any = {};
        // Loop through the inputFields
        for (i; i < opencount; i++) {
            // Get the current input field item
            item = inputFields[`open_${i}`];
            open_hr_arr.push(item)
        }
        // Assign the open_hr_arr to the 'discountSer' property of the data object
        data['discountSer'] = open_hr_arr;
    }

    const onSubmit = async (data: any, e: any) => {
        e.preventDefault();
        let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement;
        let thisButton: any = e.target.parentElement;
        // Construct the 'fees' object
        let fees = {
            fine_rate: data?.fine_rate,
            monthly_interest: data?.monthly_interest,
            interest_and_fine: data?.interest_and_fine,
            discount_rate: data?.discount_rate,
            give_discount: data?.give_discount,
            ticket_discount: data?.ticket_discount,
            apply_discount_all: data?.apply_discount_all,
            lawyer_fee: data?.lawyer_fee,
            use_percentage: data?.use_percentage,
        }

        if (percentornot.monthly_percent) {
            data.monthly_interest = convertToNumber(data.monthly_interest)
            data.fine_rate = convertToNumber(data.fine_rate)
            data.discount_rate = convertToNumber(data.discount_rate)
        }
        // Add 'discount_service' property to 'data' object
        data["discount_service"] = discountedService;
        // return false;/.;
        let response = await dispatch(update_forms_of_receipts(data))
        if (response.payload.type == 'Success') {
            // Show a success message using Swal
            Swal.fire({
                // icon: 'success','success',
                title: `${t('Sweetalert.Success')}`,
                text: `${t('Sweetalert.RecordUpdated')}`,
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 2000
            }).then(() => {
                document.querySelector('.replacehide.removeInsertDisable button')?.setAttribute('disabled', "");
            })
            if (thisDivform.querySelectorAll(".form-floating").length) {
                thisDivform.querySelectorAll(".form-floating input, select").forEach((item: any, index: number) => {
                    item.setAttribute("disabled", "");
                });
            }
            if (
                thisButton.querySelector(".BtnAction.Update") ||
                thisButton.querySelector(".BtnAction.Edit")
            ) {
                thisButton.querySelector(".BtnAction.Update")?.setAttribute("hidden", "");
                thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
            }
        } else {
            // Show an error message
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: `${t('Sweetalert.somethingWent')}`,
                // icon: 'success',"error",
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 2000
            })
        }
    }

    const handleEdit = async (e, data) => {
        //console.log(e.target, data);
    }

    const allDiscountApply = async (e) => {
        // Check if the selected value is 1
        if (e.target.value == 1 || e.target.value == "") {
            setDisService(false);
        } else {
            setDisService(true);
            // Delay the execution for 1 second before removing the 'disabled' attribute
            setTimeout(() => {
                document.querySelectorAll(".replacehide.removeInsertDisable").forEach((item) => {
                    // Remove the 'disabled' attribute
                    item.removeAttribute('disabled');
                });
            }, 1000)
        }
    }

    const [percentornot, setPercentornot] = useState({
        lease_percent: true,
        monthly_percent: true
    })

    const handleInputChange = (e: any, fieldName: any, isPercentage: any) => {
        let value = e.target.value;

        if (!isPercentage) {
            // If not a percentage field, limit the value to 100 and remove non-numeric characters
            if (value !== '') {
                value = parseFloat(value);
            }
        } else {
            // If a percentage field, remove non-numeric characters and leading zeros
            value = value.replace(/[^\d,]/g, '');
            if (value !== '') {
                value = formatNumber(value);
            } else {
                value = ''
            }
        }

        setValue(fieldName, value); // Assuming `setValue` is a function to set the field value
    };
    const handleCheckboxChange = (e, setState) => {
        const isChecked = e.target.checked;
        const nameTarget = e.target.name;
        let pickValue: any = e.target.parentElement.parentElement.parentElement;
        let valueofTarget: any = "";
        if (nameTarget === "use_percentage") {
            valueofTarget = pickValue.querySelector('[name=monthly_interest]');
            if (!isChecked) {
                if (Number(valueofTarget.value) > 100) {
                    setValue("monthly_interest", "100");
                    setValue("fine_rate", "100");
                    setValue('discount_rate', "100")
                }
                setPercentornot({
                    ...percentornot,
                    ["monthly_percent"]: true
                })
            } else {
                setPercentornot({
                    ...percentornot,
                    ["monthly_percent"]: false
                })
            }
        }
        setState(isChecked);
    };

    const handleCancel = (e: any) => {
        let thisDivform: any = e.target.parentElement.parentElement.parentElement;
        let thisButton: any = e.target.parentElement.parentElement;
        console.log(thisButton, "thisButton")
        dispatch(get_forms_of_receipts())

        if (thisDivform.querySelectorAll(".form-floating").length) {
            thisDivform.querySelectorAll(".form-floating input,select").forEach((item: any, index: number) => {
                item.setAttribute("disabled", "");
            });
        }
        if (thisDivform.querySelectorAll(".form-floating").length) {
            thisDivform.querySelectorAll(".form-check input[type='checkbox']").forEach((item: any) => {
                item.setAttribute("disabled", "");
            });
        }
        if (
            thisButton.querySelector(".BtnAction.Update") ||
            thisButton.querySelector(".BtnAction.Edit")
        ) {
            thisButton.querySelector(".BtnAction.Update")?.setAttribute("hidden", "");
            thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
        }
    }

    return (
        <>
            <Col lg={4} id="PracticedRatestsx">
                {/* Practiced rates section start from here */}
                <Card>
                    <Form onSubmit={handleSubmit(onSubmit)} id="Practiceform">
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div>
                                {t("formReceipt.practicedRates")}
                            </div>

                            <div className="BtnAction Edit">
                                {userJson && userJson.length > 0 && (userJson.indexOf(3121) !== -1) &&
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "Practicedrates")}>{t("button.edit")}</Button>}
                            </div>
                            <div className="BtnAction Update" hidden>
                                <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0 me-3" onClick={(e) => handleCancel(e)}>{t("button.cancel")}</Button>
                                <Button className="fw-bold lh-1 text-primary p-0" variant="link" size="sm" type="submit" id="bankslip"> {t("button.update")}</Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <span className="d-block mb-3 fw-bold">{t("formReceipt.allFees")}</span>

                            <FloatingLabel label={`${t('formReceipt.monthlyinterest')} ${percentornot.monthly_percent ? "(%)" : "(R$)"}` + " *"} className="mb-lg-4 mb-3 removeInsertDisable">
                                <Form.Control type="text" title="Please enter a valid number" className={`${errors.monthly_interest ? 'is-invalid' : ''}`} id="floatingInputValue" placeholder="1,00" {...register("monthly_interest")}
                                    onChange={(e) => handleInputChange(e, 'monthly_interest', percentornot.monthly_percent)} disabled />
                                <Form.Control.Feedback type="invalid">{errors.monthly_interest?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingSelect" label={t("formReceipt.interestandfine")} className="mb-lg-4 mb-3 removeInsertDisable ">
                                <Form.Select className={`${errors.interest_and_fine ? 'is-invalid' : ''}`} id="removeInsertDisable" aria-label="Exibir" {...register("interest_and_fine")} disabled>
                                    <option value="0">{t("formReceipt.display")}</option>
                                    <option value="1">{t("formReceipt.donotdisplay")}</option>

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.interest_and_fine?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <span className="d-block mb-3 fw-bold">{t("formReceipt.trafficTicket")}</span>
                            <FloatingLabel label={`${t('formReceipt.finerate')} ${percentornot.monthly_percent ? "(%)" : "(R$)"}` + " *"} className="mb-lg-4 mb-3 removeInsertDisable">
                                <Form.Control type="text" title="Please enter a valid number" className={`${errors.fine_rate ? 'is-invalid' : ''}`} id="floatingInputValue" placeholder="10,00"{...register("fine_rate")}
                                    onChange={(e) => handleInputChange(e, 'fine_rate', percentornot.monthly_percent)} disabled />
                                <Form.Control.Feedback type="invalid">{errors.fine_rate?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <span className="d-block mb-3 fw-bold">{t("formReceipt.discount") + " *"}</span>
                            <FloatingLabel label={`${t('formReceipt.discountrate')} ${percentornot.monthly_percent ? "(%)" : "(R$)"}`} className="mb-lg-4 mb-3 removeInsertDisable">
                                <Form.Control type="text" title="Please enter a valid number" className={`${errors.discount_rate ? 'is-invalid' : ''}`} id="floatingInputValue" placeholder="0,00" {...register("discount_rate")}
                                    onChange={(e) => handleInputChange(e, 'discount_rate', percentornot.monthly_percent)} disabled />
                                <Form.Control.Feedback type="invalid">{errors.discount_rate?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel label={t("formReceipt.givediscount") + " *"} className="mb-lg-4 mb-3 removeInsertDisable">
                                <Form.Control type="text" title="Please enter a valid number" className={`form-control ${errors.give_discount ? 'is-invalid' : ''}`} id="floatingInputValue" placeholder="0" {...register("give_discount")} disabled />
                                <Form.Control.Feedback type="invalid">{errors.give_discount?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingSelect" label={t("formReceipt.ticketDiscount")} className="mb-lg-4 mb-3 removeInsertDisable">
                                <Form.Select className={`${errors.ticket_discount ? 'is-invalid' : ''}`} id="removeInsertDisable" aria-label="Exibir" {...register('ticket_discount')} disabled>
                                    <option value=''>{t("formLabel.select")}</option>
                                    <option value="0">{t("formReceipt.display")}</option>
                                    <option value="1">{t("formReceipt.donotdisplay")}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.ticket_discount?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Check type="checkbox" className="mb-3 mb-lg-4 removeInsertDisable" {...register('use_percentage')}
                                checked={isMonthlyAdminRatePercentage}
                                onChange={(e) => handleCheckboxChange(e, setMonthlyAdminRatePercentage)}
                                id="newUserCh123" label={t("formReceipt.doNotUsePercentage")} disabled />
                            <FloatingLabel controlId="floatingSelect" label={t("formReceipt.applyAllDiscount") + " *"} className="mb-lg-4 mb-3 removeInsertDisable">
                                <Form.Select className={`${errors.apply_discount_all ? 'is-invalid' : ''}`} id="removeInsertDisable" aria-label="Produção" {...register("apply_discount_all")} disabled onChange={allDiscountApply}>
                                    <option value=''>{t("formLabel.select")}</option>
                                    <option value="0">{t("formReceipt.applyAllDiscount")}</option>
                                    <option value="1">{t("formReceipt.ApplyDiscountOnly")}</option>

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.apply_discount_all?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            {
                                disService ?
                                    <div className='replacehide removeInsertDisable'>
                                        <Button id="discountedServicesButton" variant="light" size="sm" onClick={handleShow}>
                                            {t("formReceipt.discountedServices")}
                                        </Button>
                                    </div>
                                    :
                                    ""
                            }


                            <span className="d-block mb-3 fw-bold mt-lg-4 mt-3">{t("formReceipt.lawyerFeesTitle")}</span>
                            <FloatingLabel label={t("formReceipt.lawyerFees") + " *"} className="mb-lg-4 mb-3 removeInsertDisable">
                                <Form.Control type="text" className={`${errors.lawyer_fee ? 'is-invalid' : ''}`} id="floatingInputValue" placeholder="10,00"{...register("lawyer_fee")} disabled />
                                <Form.Control.Feedback type="invalid">{errors.lawyer_fee?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Button className='submitForm' type="submit" variant="primary" hidden>{t('button.toSave')}</Button>
                            <Button className='fillModalwithThisData' onClick={(e) => handleEdit(e, "Practicedrates")} hidden>{t('button.edit')}</Button>
                        </Card.Body>
                    </Form>
                </Card>

                {/* /Practiced rates section end */}
            </Col>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("formReceipt.dicountTitle")}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(Addiscount)}>
                    <Modal.Body>
                        <h4>Services</h4>
                        {
                            discountedService && discountedService.length > 0 &&
                            discountedService.map((x, index) => (
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 d-flex align-items-center" data={index + "__this is"} key={index}>
                                    <Col className="delete" md={1} >
                                        {
                                            discountedService.length > 1 &&
                                            <img onClick={() => removeInputFields(index)} src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16 cursor-pe" />
                                        }
                                    </Col>
                                    <Col md={8}>
                                        <FloatingLabel label="Services">
                                            {/* {...register(`discounted_service.${index}.service`)} */}
                                            <Form.Select onChange={(e) => allmultipleFilter(e.target.value, index, "service")} value={discountedService[index]["value"]}

                                            >
                                                <option key={0} value="">{t("formLabel.select")}</option>
                                                {Allservice && Allservice["service"] && Allservice["service"].length > 0 &&
                                                    Allservice["service"].map((item, index) => (
                                                        <option key={index} value={item.value}>{item.label}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            ))
                        }
                        <Button variant="light" size="sm" onClick={(e) => addInputField(e, discountedService.length)}>{t("formLabel.another")}</Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseset}>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
