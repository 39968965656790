import React from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Privacy_policys = () => {

    return (
        <div>
            <header className="main-header bg-white fixed-top overflow-auto">
                <Navbar expand="xl" bg="white" className="h-100">
                    <Container fluid className="px-xl-5 px-md-4 px-3">
                        <Navbar.Toggle
                            className="position-absolute top-0 start-0 p-0 mt-3 ms-2 ms-md-4 border-0"
                            aria-controls="headerNav"
                        ></Navbar.Toggle>
                        <Link className="navbar-brand ps-5 ps-xl-0 me-2" to={"/dashboard"}>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
                                className="header-logo"
                                alt="Logo"
                            />
                        </Link>

                    </Container >
                </Navbar >
            </header >

            <div className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3 mt-5">
                <div className="privacy-section mt-5">
                    <Row>
                        <Col xl={10}>
                            <h2 className='text-primary'>Privacy policy</h2>
                            <p className="text-justify">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan, dolor nec venenatis auctor, odio felis auctor nulla, ut ultricies mauris leo sit amet mauris. Quisque ut ornare risus. Vivamus non felis vitae nunc sollicitudin pretium. Sed bibendum velit non sapien gravida, nec elementum leo tincidunt. Nulla facilisi.
                            </p>
                            <p className="text-justify">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vehicula ultricies nibh, sed gravida lorem. Vivamus ullamcorper, nisl eget efficitur dictum, nisi justo malesuada nulla, et posuere mi enim quis ligula. Etiam nec metus vel risus tincidunt auctor. Nulla facilisi. Curabitur ut leo vitae lectus venenatis bibendum nec nec metus. Fusce ac justo ac eros malesuada malesuada ut ut orci.
                            </p>
                            <p className="text-justify">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent facilisis nulla nec nunc varius, sed cursus ligula viverra. Integer bibendum purus nec justo ultricies bibendum. Nam eget quam in justo volutpat tincidunt. Ut at urna id ligula mollis egestas. Pellentesque sit amet est eu odio varius feugiat. Nam ullamcorper lorem ac libero volutpat, a tincidunt turpis convallis.
                            </p>
                            <p className="text-justify">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit:
                            </p>
                            <ul >
                                <li className="text-justify">
                                    <strong>Lorem Ipsum:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean facilisis, tortor vel vehicula lacinia, odio elit volutpat purus, sed convallis orci lacus a velit. Aliquam erat volutpat. Nam vel velit non est dignissim euismod. Phasellus sit amet urna nec mi tincidunt consectetur at sed arcu.
                                </li>
                                <li className="text-justify">
                                    <strong>Dolor Sit:</strong> Sed cursus felis eget magna viverra, non bibendum justo fermentum. Donec vitae tortor ut libero hendrerit laoreet ut id urna. Cras volutpat, arcu nec auctor faucibus, justo est ultrices ex, eget cursus ligula libero a lorem. Mauris non urna nec tortor malesuada gravida. Nunc et magna eget justo consequat dictum non vel turpis.
                                </li>
                                <li className="text-justify">
                                    <strong>Amet Consectetur:</strong> Phasellus tristique, quam in sodales fermentum, elit sem consequat justo, vel scelerisque magna justo eget justo. Nam aliquet tellus vel nisl efficitur, eget accumsan neque suscipit. Fusce vehicula auctor quam, nec convallis purus viverra nec. In hac habitasse platea dictumst. Ut ornare lorem at turpis ultrices, ac aliquam libero aliquam.
                                </li>
                                <li className="text-justify">
                                    <strong>Adipiscing Elit:</strong> Integer at ex ac nisi ullamcorper consequat nec id nunc. Pellentesque tincidunt purus nec lorem ultricies, at tempus nisi ultricies. Etiam at odio eu mauris vestibulum pretium at id dui. Nulla facilisi. In euismod urna ac dolor consectetur, et tempor arcu vulputate. Aliquam erat volutpat. Nam commodo augue nec leo luctus gravida.
                                </li>
                            </ul>
                            <p className="text-justify">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac justo ac eros malesuada malesuada ut ut orci. Nullam feugiat lectus a vehicula dapibus. Ut dignissim velit sed arcu congue, id viverra odio consequat. Etiam eget magna nisl. Vestibulum convallis, augue sed malesuada lobortis, arcu sapien auctor velit, nec faucibus turpis est eget ligula.
                            </p>

                        </Col>
                    </Row>
                    <Link to={'/dashboard'}> <button type="button" className="btn btn-outline-primary">Back</button></Link>
                </div>
            </div>


            <footer className="bg-primary text-white text-center py-3 fixed-bottom">
                <div className="container">
                    <p className="mb-0">&copy; 2024 Your Company Name. All rights reserved.</p>
                </div>
            </footer>

        </div>
    )
}

export default Privacy_policys