import { Dropdown, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, Card, ListGroup, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useRef } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import customerService from '../../../../services/customerService';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import '../../../../App.css';
import ClientCollaboratorsSettings from './ClientCollaboratorsSettings';
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../redux/store';
import { CreateCustomers, GetRepresentativeService, getContractSetting, getOwner, openConfigureEmail, openEmailPop, openNewOwner } from "../../../../redux/slice/contractDataSlice"
import { numericOnly, openErrorbox } from '../Common/actionPerform';
import { add_Bank, getBankList } from '../../../../redux/slice/bankSlice';
import { useSelector } from 'react-redux';
import { getAllBillMethodListing, getAllSuplierCate } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, encrypt_Decrypt, handleKeyDown, checkDuplicateCNJP_CPF, formatNumber, convertToNumber } from '../../../constants/utils';
import i18n from '../../../../i18n';
import ConfigureEmail from '../Common/ConfigureEmailService';
import NewOwner from './NewOwner';
import Loader from '../../../Profile/Company/Loader';
import { getManager } from '../../../../redux/slice/propertyDataSlice';
import SendEmails from '../Common/SendEmails';
import RepresentativeService from '../../NewDesigns/Contracts/RepresentativeService';
import { config } from '../../../../services/apiConfig';
import { integrateOwner } from './services/splitPayments/integrateOwner';
const CustomersAndEmployees = (CustomersAndEmployeesForm) => {
    const dispatch = useDispatch<AppDispatch>()
    const { category } = useSelector((state: RootState) => state.expenditureData)
    const { ownerData, ContractSettingData } = useSelector((state: RootState) => state.contractData)
    const { managerData } = useSelector((state: RootState) => state.propertyData)

    const [t] = useTranslation();
    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [hide, setHide] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [hide5, setHide5] = useState(false);
    const [hide6, setHide6] = useState(false);
    const [hide7, setHide7] = useState(false);
    const [hide8, setHide8] = useState(false);
    const [hide9, setHide9] = useState(true);
    const [hide10, setHide10] = useState(true);
    const [show3, setShow3] = useState(true);
    const [fieldhide, setFieldhide] = useState(false)
    const [bankFields, setbankFields] = useState(false);
    const [checkhide, setCheckHide] = useState(false)
    const [headingText, setHeadingText] = useState<any>("");
    const [data, setData] = useState({ brokers: 0, guarantors: 0, owners: 0, tenants: 0, suppliers: 0, inspectors: 0, legalOwner: 0, legaltenant: 0, legalGuarantors: 0 });
    const [AllStates, setAllStates] = useState([]);
    const [LegalOwner, setLegalOwner] = useState<number>(0)
    const [LegalTenant, setLegalTenant] = useState<number>(0)
    const [pixTypeHide, setPixTypeHide] = useState(true)
    const [HidePixAlso, setHidePixAlso] = useState(false)
    const [showHideField, setShowHideField] = useState<any>("")
    const [status, setStatus] = useState(false)
    const [loader_on, setloader_on] = useState(false);
    const inputRef = useRef<any>(null);
    const [formData, setFormData] = useState<any>([{ Property_Type: '', Property_Value: '', Neighborhood: '', Zip_code: '', Address: '', Number: '', Complement: '', state_of: '', City: '', Registry: '', Registration: '', Building_con: '', }]);
    const [bankNotRequeire, setbankNotRequeire] = useState(false)
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    const [repreData, setRepreData] = useState([])
    const [valueCon, setValueCon] = useState('')
    let [mailData, setMailData] = useState<any>([]);
    let [personId, setPersonId] = useState<any>([]);
    let [mailText, setMailText] = useState("");
    let [customer_type, set_customer_type] = useState("Owner");
    const regexNotPattern = /^[0-9-]+$/;
    const [userTypeIs, setuserType] = useState<any>("");
    const [addressError, setaddressError] = useState(false)
    const [agencyornot, setagencyornot] = useState("2");
    const [ isEmail,setIsEmail ] = useState(false);

    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(add_Bank())
        dispatch(getManager());
        dispatch(getAllSuplierCate({ "start": 0, "end": 1000 }))
        dispatch(getContractSetting())
    }, [dispatch]);

    useEffect(() => {
        let getAgencyOrNot: any = localStorage.getItem("role");
        setagencyornot(getAgencyOrNot);
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
    }, [])

    useEffect(() => {
        if (show) {
            if (ContractSettingData && ContractSettingData.length > 0) {
                if (ContractSettingData[0]?.ir_desconto_simplificado_loc == "1") {
                    setValue("enableSimplifiedDeduction", true);
                } else {
                    setValue("enableSimplifiedDeduction", false);
                }
            }
        }
    }, [show, ContractSettingData])
    const [update, setUpdate] = useState(1);
    useEffect(() => {
        getData();
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode('states');
            if (resp.data.status === 'success' || resp.data.type === 'Success') {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })();
    }, [update,]);

    useEffect(() => {
        let ownerData = {
            owner: "",
            person: "",
            status: "",
            start: 0,
            end: 20,
            page: 'ownersandbenef'
        }
        let cnpjCount = 0;
        let checkCnpjCpf: any = []
        customerService.getAllOwner(ownerData).then(res => {
            if (res?.data?.resCode === 201) {
                res?.data?.data?.forEach((element: any) => {
                    let checkCNJP: any = CNJP_CPFValidation(`${element.cnpj}`, "both")
                    checkCnpjCpf.push(checkCNJP)
                });
                for (const entry of checkCnpjCpf) {
                    if (entry.valid == 'CNPJ number') {
                        cnpjCount++;
                    }
                }
                setLegalOwner(cnpjCount)
            } else {
                setLegalOwner(0)
            }
        }).catch(err => {
            console.log("err", err)
        });
        // Legal Tenant
        let tenantcnpjCount = 0;
        let tenantCnpjCpf: any = []
        let tenantData = {
            tenant: "",
            person: "",
            status: "",
            type: "",
            start: 0,
            end: 20,

        }
        customerService.getAllTenant(tenantData).then(res => {
            if (res?.data?.resCode === 201) {
                res?.data?.data.forEach((element: any) => {
                    let checkCNJP: any = CNJP_CPFValidation(`${element.cnpj}`, "both")
                    tenantCnpjCpf.push(checkCNJP)
                });
                for (const entry of tenantCnpjCpf) {
                    if (entry.type === "cnpj") {
                        tenantcnpjCount++;
                    }
                }
                setLegalTenant(tenantcnpjCount)
            } else {
                setLegalTenant(0)
            }
        }).catch(err => {
            console.log("err", err)
        });
        handleRepresentative();
    }, [])

    const [PageSize, setPageSize] = useState(1000000);
    let [ownstate, setownState] = useState({
        owner: "",
        person: "",
        status: "",
        start: 0,
        end: PageSize
    })
    const [tenstate, settenState] = useState({
        tenant: "",
        person: "",
        status: "",
        payment: "",
        property_type: "",
        type: "",
        start: 0,
        end: PageSize
    })
    /*--------------Common modal function--------------*/
    const formClose = () => {
        setloader_on(false);
        setIsEmail(false);
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        reset(); setShow(false); setHide2(false); setHide3(false); setHide(true); setspouse(false)
        setIsInputFocused(false);
        setAddState({
            retentionn: [
                { Tax: "", Aliquot: "" },
            ],
            beneficiaries: [
                { name: "", transfer: "" }
            ],
            representative: [
                { name: "" }
            ]
        })
    }


    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        var userType: any = e.currentTarget.getAttribute('data-title');
        setuserType(userType);
        setbankFields(true);
        setShow3(true)
        e.preventDefault();
        reset();
        setHeadingText(e.currentTarget.name);
        setHide7(false);
        setHide8(false);
        setHide9(true);
        setHide10(false);
        if (e.currentTarget.name === "New Owner" || e.currentTarget.name === "Novo Proprietário") {
            setHide(true);
            setbankFields(true);
            setHide5(false)
            setHide8(true)
            setHide6(true);
        }
        if (e.currentTarget.name === "New Supplier" || e.currentTarget.name === "Novo Fornecedor") {
            setHide(true);
            setbankFields(true);
            setHide5(true);
            setHide4(true);
            setHide6(true);
            setHide9(false);

        }
        if (e.currentTarget.name === "New Inspector" || e.currentTarget.name === "Novo Vistoriador") {
            setHide4(true);
            setbankFields(false);
            setHide3(false);
            setHide5(false)
            setHide6(false)
            setFieldhide(true)
        } else if (e.currentTarget.name === "New Tenant" || e.currentTarget.name === "Novo Locatário") {
            // || e.currentTarget.name === "Novo Corretor"
            setHide2(false);
            setbankFields(false);
            setHide(true)
            setHide5(false)
            setHide6(true)
            setHide10(true);
        } else if (e.currentTarget.name === "New Guarantor" || e.currentTarget.name === "Novo Fiador") {
            setHide2(false);
            setbankFields(false);
            setHide(true)
            setHide5(false)
            setHide6(true)
            setHide7(true);
        } else if (e.currentTarget.name === "New Broker" || e.currentTarget.name == "Novo Corretor") {
            setHide2(false);
            setbankFields(false);
            setHide(true)
            setHide4(true)
            setHide6(false)
            setHide5(false)
        } else { }
        setShow(true);
        setIsInputFocused(false);
    }

    // Retrieve banklist, show1, and status from the Redux state
    const { banklist } = useSelector((state: RootState) => state.bank)

    /*------------------Form validation------------------*/
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let regexPattern = /^[^0-9./-]*$/;
    if (showHideField == "cnpj") {
        // regexPattern = /^[a-zA-Z0-9]*$/;
        regexPattern = /^[A-Za-z\s]+$/;
    }

    const formValidationSchemaStep0 = Yup.object().shape({
    });
    let formValidationSchemaStep1:any = {
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    }

    var formValidationSchemaStep2 =
        Yup.object().shape({
            name: Yup.string()
                .trim()
                .nullable()
                // .when([], {
                //     is: () => headingText !== "New Inspector" && headingText !== "Novo Vistoriador",
                //     then: (schema) => schema.matches(regexPattern, { message: t('requiredMmessage.numbernotallow') }),
                //   })
                .required(t('requiredMmessage.fieldRequired')),
            mobile: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
            email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
            payment: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
            bankname: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired')),
            bank_acocunt: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired')),
            bank_agency: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")).min(4, "Agency must be at least 4 characters long"),
            bank_accountType: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired')),
            name_of: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired')),
            // Aliquot: Yup.string().trim().nullable()
            //     .required(t('requiredMmessage.fieldRequired')),
            pixKeyType: Yup.string()
                .trim()
                .nullable()
                .when('payment', {
                    is: '12',
                    then: Yup.string().required(t('requiredMmessage.fieldRequired'))
                }),
            pixKey: Yup.string()
                .trim()
                .nullable()
                .when('payment', {
                    is: '12',
                    then: Yup.string().required(t('requiredMmessage.fieldRequired'))
                })
        });

    var formValidationSchemaStep3:any = {
        name: Yup.string()
            .trim()
            .nullable()
            // .when([], {
            //     is: () => headingText !== "New Inspector" && headingText !== "Novo Vistoriador",
            //     then: (schema) => schema.matches(regexPattern, { message: t('requiredMmessage.numbernotallow') }),
            //   })
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        payment: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        bankname: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bank_acocunt: Yup.string().trim().nullable()
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            })
            .required(t('requiredMmessage.fieldRequired')),
        bank_agency: Yup.string().trim().nullable()
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            }).required(t("requiredMmessage.fieldRequired")).min(4, "Agency must be at least 4 characters long"),
        bank_accountType: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        name_of: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // password: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(passwordRules, { message: t("requiredMmessage.password") }),
        address: Yup.string().trim().nullable()
            .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
        otherwise: Yup.string().nullable(),
    }

    let formValidationSchemaStep4:any = {
        name: Yup.string().trim().nullable()
            .when([], {
                is: () => headingText == "New Inspector" || headingText == "Novo Vistoriador",
                then: (schema) => schema.max(50, t('requiredMmessage.50characters')), // Custom max length message
            })
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        address: Yup.string().trim().nullable()
            .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
        otherwise: Yup.string().nullable(),
    };
    let formValidationSchemaStep5:any = {
        name: Yup.string().trim().nullable()
            // .when([], {
            //     is: () => headingText !== "New Inspector" && headingText !== "Novo Vistoriador",
            //     then: (schema) => schema.matches(regexPattern, { message: t('requiredMmessage.numbernotallow') }),
            //   })
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
    }
    let formValidationSchemaStep6:any = {
        name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        // .when([], {
        //     is: () => headingText !== "New Inspector" && headingText !== "Novo Vistoriador",
        //     then: (schema) => schema.matches(regexPattern, { message: t('requiredMmessage.numbernotallow') }),
        //   })
    }

    let formValidationSchemaStep7:any = {
        name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        // .when([], {
        //     is: () => headingText !== "New Inspector" && headingText !== "Novo Vistoriador",
        //     then: (schema) => schema.matches(regexPattern, { message: t('requiredMmessage.numbernotallow') }),
        //   })
        bankname: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bank_acocunt: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bank_agency: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")).min(4, "Agency must be at least 4 characters long"),
        bank_accountType: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        name_of: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }

    var frm = { resolver: yupResolver(formValidationSchemaStep0) }
    if (headingText !== "New Supplier" && headingText !== "Novo Fornecedor") {
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
            }
            formValidationSchemaStep1 = { ...formValidationSchemaStep1,...emailVaild}
        }
        let ownerValid = Yup.object().shape(formValidationSchemaStep1)
        var frm = { resolver: yupResolver(ownerValid) }
    }
    if (hide2 && headingText == "New Tenant" || hide2 && headingText == "Novo Locatário") {
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
                password: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .matches(passwordRules, { message: t("requiredMmessage.password") }),

            }
            formValidationSchemaStep4 = { ...formValidationSchemaStep4,...emailVaild}
        }
        let ownerValid = Yup.object().shape(formValidationSchemaStep4)
        var frm = { resolver: yupResolver(ownerValid) }
        openErrorbox();
    }
    if (hide2 && headingText == "New Inspector" || hide2 && headingText == "Novo Vistoriador") {
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
                password: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .matches(passwordRules, { message: t("requiredMmessage.password") }),
            }
            formValidationSchemaStep4 = { ...formValidationSchemaStep4,...emailVaild}
        }
        let ownerValid = Yup.object().shape(formValidationSchemaStep4)
        var frm = { resolver: yupResolver(ownerValid) }
    }
    if (hide2 && showHideField === "cnpj") {

        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
                password: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .matches(passwordRules, { message: t("requiredMmessage.password") }),
            }
            formValidationSchemaStep4 = { ...formValidationSchemaStep4,...emailVaild}
        }

        let ownerValid = Yup.object().shape(formValidationSchemaStep4)
        var frm = { resolver: yupResolver(ownerValid) }
    }
    if (bankNotRequeire && hide2 && (headingText === "New Supplier" || headingText === "Novo Fornecedor")) {
        
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),

            }
            formValidationSchemaStep7 = { ...formValidationSchemaStep7,...emailVaild}
        }

        let ownerValid = Yup.object().shape(formValidationSchemaStep7)
        var frm = { resolver: yupResolver(ownerValid) }
        openErrorbox();

    }
    if (hide2 && headingText == "New Guarantor" || hide2 && headingText == "Novo Fiador") {
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),

            }
            formValidationSchemaStep6 = { ...formValidationSchemaStep6,...emailVaild}
        }

        let ownerValid = Yup.object().shape(formValidationSchemaStep6)
        var frm = { resolver: yupResolver(ownerValid) }
    }
    if (hide2 && headingText == "New Broker" || hide2 && headingText == "Novo Corretor") {
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),

            }
            formValidationSchemaStep5 = { ...formValidationSchemaStep5,...emailVaild}
        }

        let ownerValid = Yup.object().shape(formValidationSchemaStep5)
        var frm = { resolver: yupResolver(ownerValid) }
    }
    if (hide2 && (headingText == "New Owner" || headingText == "Novo Proprietário") && pixTypeHide) {
        
        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
                password: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .matches(passwordRules, { message: t("requiredMmessage.password") }),
            }
            formValidationSchemaStep3 = { ...formValidationSchemaStep3,...emailVaild}
        }

        let ownerValid = Yup.object().shape(formValidationSchemaStep3)
        var frm = { resolver: yupResolver(ownerValid) }
    }
    if (hide2 && (headingText == "New Owner" || headingText == "Novo Proprietário") && !pixTypeHide) {
        // No Bank Required field form

        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),
                password: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .matches(passwordRules, { message: t("requiredMmessage.password") }),
            }
            formValidationSchemaStep4 = { ...formValidationSchemaStep4,...emailVaild}
        }

        let ownerValid = Yup.object().shape(formValidationSchemaStep4)
        var frm = { resolver: yupResolver(ownerValid) }
    }
    if (hide2 && (headingText == "New Supplier" || headingText == "Novo Fornecedor")) {


        if(isEmail){
            let emailVaild = {
                email: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired'))
                .email(t('requiredMmessage.fieldNotValid')),

            }
            formValidationSchemaStep6 = { ...formValidationSchemaStep6,...emailVaild}
        }

        let ownerValid = Yup.object().shape(formValidationSchemaStep6)
        var frm = { resolver: yupResolver(ownerValid) }

    }
    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);

    /*------------Step of form functions & validation--------*/
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => { setHide(true); setHide2(false); setHide3(false); }
    const step2 = () => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false); setHide2(true); setHide3(false);
        }
    }
    const step3 = () => {
        setHide(false); setHide2(false); setHide3(true);
    }
    /*------------Call the insert api & set the data fields--------*/
    const [optionRendaen, setOptionRendaen] = useState([
        { value: "1", label: "Retired or Pensioner" },
        { value: "2", label: "Self-employed" },
        { value: "3", label: "Businessperson" },
        { value: "4", label: "Trainee" },
        { value: "5", label: "Student" },
        { value: "6", label: "Public agent" },
        { value: "7", label: "CLT employee" },
        { value: "8", label: "Liberal Professional" },
        { value: "9", label: "Rental income" }
    ])

    const [optionRendapt, setOptionRendapt] = useState([
        { value: "1", label: "Aposentado ou Pensionista" },
        { value: "2", label: "Autônomo" },
        { value: "3", label: "Empresário" },
        { value: "4", label: "Estagiário" },
        { value: "5", label: "Estudante" },
        { value: "6", label: "Funcionário Público" },
        { value: "7", label: "Funcionário CLT" },
        { value: "8", label: "Profissional Liberal" },
        { value: "9", label: "Renda proveniente de aluguel" }
    ]);

    const formSubmit = async (data: CustomersAndEmployeesForm) => {
        setloader_on(true);
        let bankName = '';
        let bankcode = '';
        banklist.forEach((curElem: any, index: number) => {
            if (curElem.bank_name == data.bankname) {
                bankName = curElem.bank_name;
                bankcode = curElem.bank_code
            }
        })
        if (data.type == 'New Guarantor' || data.type == "New Supplier" || data.type == "New Broker") {
            data["password"] = process.env.REACT_APP_PASSWORD as string;
        }
        let bloquendo: any = convertToNumber(valueCon ? String(valueCon) : String(data.bloquendo));
        let owner = {
            ID_PESSOAS: data.ID_PESSOAS,
            type: userTypeIs,
            cnpj: data.cnpj,
            corporateReason: data.corporateReason,
            stateRegistration: data.stateRegistration,
            municipalRegistration: data.municipalRegistration,
            name: data.name,
            payment: data.payment,
            mobile: data.mobile,
            telephone: data.telephone,
            email: data.email,
            password: data.password ? data.password : '',
            mother: data.mother,
            father: data.father,
            rg: data.rg,
            issue_agency: data.issue_agency,
            shipping_date: data.shipping_date,
            sex: data.sex,
            birth: data.birth,
            marital_status: data.marital_status,
            nationality: data.nationality,
            zipcode: data.zipcode,
            address: data.address,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            employment_company: data.employment_company,
            employment_profession: data.employment_profession,
            employment_field_activity: data.employment_field_activity,
            employment_monthly_income: data.employment_monthly_income,
            employment_zipcode: data.employment_zipcode,
            employment_address: data.employment_address,
            employment_number: data.employment_number,
            employment_complement: data.employment_complement,
            employment_neighborhood: data.employment_neighborhood,
            employment_city: data.employment_city,
            employment_state: data.employment_state,
            representative_name: data.representative_name,
            retention: data.retention,
            observation: data.observation,
            is_vendor: data.is_vendor,

            bank_Name: bankName,
            bankname: bankcode,
            bank_acocunt: `${data.bank_acocunt}-${data.bankAccountDigit ? data.bankAccountDigit : "0"}`,
            bank_agency: data.bank_agency,
            bank_accountType: data.bank_accountType,
            bank_branchCode: data.bank_branchCode,
            name_of: data.name_of,
            cnpjcpf: data.cnpj,

            spouse_name: data.spouse_name,
            spouse_cpf: data.spouse_cpf,
            spouse_rg: data.spouse_rg,
            spouse_issue_agency: data.spouse_issue_agency,
            spouse_nationality: data.spouse_nationality,
            spouse_sex: data.spouse_sex,
            spouse_profession: data.spouse_profession,
            spouse_cellphone: data.spouse_cellphone,
            spouse_telephone: data.spouse_telephone,
            spouse_email: data.spouse_email,
            spouse_observation: data.spouse_observation,
            formData: data.formData,
            block_balance: data.block_balance,
            manager: data.manager,
            identifier: data.identifier,
            Organ_exp: data.Organ_exp,
            inss: data.inss,
            rpa: data.rpa,
            category: data.category,
            accounting_system: data.accounting_system,
            sup_KeyType: data.pixKeyType,
            Keyfor_addressing: data.pixKey,
            country: data.country,
            ID_SACADO_SAC: data.ID_SACADO_SAC,
            ID_FAVORECIDO_FAV: data.ID_FAVORECIDO_FAV,
            // tax: data.Tax,
            // aliquot: data.Aliquot ?? 123456
            new_added: {
                addState: addState,
                Renda: {
                    FL_SEMRENDA_PES: data.possiu ?? "",
                    FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                    ST_BENEFICIO_PES: data.beneficio ?? "",
                    VL_OUTROSRENDIMENTOS_PES: data.rendimentous
                },
                Retencao_de_impostos: {
                    FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
                },
                Mais_opcoes: {
                    NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                    FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                    FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
                },
                Fornecedor: {
                    FL_SINCRONIZARSACADO_FAV: data.supplieradv ?? ""
                },
                Pagemento: {
                    DT_DIAREPASSE_PES: data.Repassedia ?? "",
                    VL_SALDOBLOQUEADO_PES: bloquendo ?? ""
                }
            }
        }
        let customer = {
            cnpj_cpf: data.cnpj,
            person_name: data.name,
            address: data.address,
            neighborhood: data.neighborhood,
            zipcode: data.zipcode,
            city_name: data.city,
            state: data.state,
        }
        customerService.createOwner(owner).then(res => {
            if (res.data.resCode === 202) {
                if (owner.payment === "0") { // forma de pagamento repasse por split
                  let ownerId = String(res.data.data[0].insertedId)
                  if (res.data.data.length > 1) {
                    ownerId = String(res.data.data[1].insertedId)
                  }
                  integrateOwner(ownerId).catch(err => {
                    console.log("Falha ao integrar proprietário com pagamentos split", err)
                  })
                }
                setUpdate(1)
                formClose();
                setIsInputFocused(false);

                let message:any = t("Sweetalert.successget");
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: message,
                    confirmButtonText: "OK",
                    timer: 3000
                });
                if (isChecked == true) {
                    setShow(true);
                }
                setTimeout(() => {
                    customerService.getOwner().then(res => {
                        if (res?.data?.resCode === 201) {
                            setData(res.data.data[0]);
                        } else {
                        }
                    }).catch(err => {
                        console.log("err", err)
                    });
                }, 2000)
            } 
            else if(res.data.resCode === 520){
                setloader_on(false);
                setIsInputFocused(false);
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successgetSsoError"),
                    confirmButtonText: "OK",
                    timer: 3000
                });
            }
            else {
                setIsInputFocused(false);
                let msg = res.data.data[0].message ? res.data.data[0].message : ((res.data?.data && res.data.data.length) || res.data.data) ? res.data.data[0].message ? res.data.data[0].message : res.data.message : '';
                if (res.data.resCode == 409) {
                    msg = t("Sweetalert.unqueEmails");
                }
                if (res.data.resCode === 400 && res.data.message == 'Owneremail!=AgencyEmail') {
                    msg = t("ownerEmailnotEqualAgencyEmail");
                }

                setloader_on(false);
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: msg ? msg : t("Sweetalert.somethingWent"),
                    confirmButtonText: "OK",
                    timer: 3000
                });
            }
        }).catch(error => {
            setloader_on(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                confirmButtonText: "OK",
                timer: 3000
            });
            setloader_on(false);
        });
    }
    /*-------Get clients & collaborates cunt--------*/
    const getData = () => {
        setStatus(false)
        customerService.getOwner().then(res => {
            if (res?.data?.resCode === 201) {
                setStatus(true)
                setData(res.data.data[0]);
            } else {
                setStatus(true)
            }
        }).catch(err => {
            console.log("err", err)
        });
    }
    //Client collaborators setting modal initialization
    const [showClientSettingModal, setClientSettingModal] = useState(false);
    const clientSettingModalClose = () => setClientSettingModal(false);
    const clientSettingModalShow = () => setClientSettingModal(true);
    const [savedCheckboxState, setSavedCheckboxState] = useState({
        cb1: false,
        cb2: false,
        cb3: false,
        cb4: false,
        cb5: false,
        cb6: false,
        cb7: false,
    });
    const handleSaveSettings = (checkboxState) => {
        setSavedCheckboxState(checkboxState);
    };
    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
        goodofZip: "",
    });
    useEffect(() => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (zipvalue.zip == "" && zipvalue.empzip == "" || zipvalue.goodofZip == "") {
            zipCodeCheck?.["removeAttribute"]('disabled');
        } else {
            zipCodeCheck?.["setAttribute"]('disabled', "")
        }
    }, [zipvalue])
    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySelthird: any
        let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="employment_zipcode"]');
        if (formData.length > 0) {
            formData?.forEach((curElem, index) => {
                querySelthird = document.querySelector(`.accordion-custom [name="formData.${index}.Zip_code"]`);
            });
        }
        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str.trim(),
            })
        } else if (forwhich == "second") {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["goodofZip"]: str
            })
        }
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("address", data)
                    }
                } else if (forwhich == "second") {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("employment_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("employment_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("employment_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("employment_address", data)
                    }
                } else {
                    if (formData.length > 0) {
                        const updatedFormData = [...formData];
                        formData?.forEach((curElem, index) => {
                            querySelthird.style.border = "0px solid red";
                            if (res[0]["city"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["Zip_code"]: str };
                                setValue(`formData.${index}.Zip_code`, str)
                            }
                            if (res[0]["city"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["City"]: res[0]["city"] };
                                setValue(`formData.${index}.City`, res[0]["city"])
                            }
                            if (res[0]["neigbohood"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["Neighborhood"]: res[0]["neigbohood"] };
                                setValue(`formData.${index}.Neighborhood`, res[0]["neigbohood"])
                            }
                            if (res[0]["state"]) {
                                updatedFormData[index] = { ...updatedFormData[index], ["state_of"]: res[0]["state"] };
                                setValue(`formData.${index}.state_of`, res[0]["state"])
                            }
                            if (res[0]["street"] || res[0]["streetType"]) {
                                let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                                updatedFormData[index] = { ...updatedFormData[index], ["Address"]: data };
                                setValue(`formData.${index}.Address`, data)
                            }
                        })
                        setFormData(updatedFormData)
                    }
                }
            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("city", "")
                    setValue("neighborhood", "")
                    setValue("state", "")
                    setValue("address", "")
                } else if (forwhich == "second") {
                    querySelsecond.style.border = "2px solid red"
                    setValue("employment_city", "")
                    setValue("employment_neighborhood", "")
                    setValue("employment_state", "")
                    setValue("employment_address", "")
                }

            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else if (forwhich == "second") {
                querySelsecond.style.border = "0px solid red"
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }
    const getBankCode = (bankCode) => {
        if (bankCode !== "") {
            clearErrors("bankname")

        } else {
            setError("bankname", {
                type: "manual",
                message: `${(t('requiredMmessage.fieldRequired'))}`
            })
        }
        setValue("bank_branchCode", bankCode);
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }
    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if ((checkCNJP?.["status"] || e.target.value == "")) {
            setShowHideField(checkCNJP?.["type"])
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData: any = await checkDuplicateCNJP_CPF(e.target.value, "");

                setValue("ID_PESSOAS", duplicateData["data"][0]?.ID_PESSOA_PES);
                let boolVal = false
                if (headingText == "New Owner" || headingText == "Novo Proprietário") {
                    boolVal = duplicateData["data"][0]?.FL_PROPRIETARIOBENEFICIARIO_PES == 1
                }
                if ((headingText == "New Tenant" || headingText == "Novo Locatário") && duplicateData["data"][0]?.FL_STATUS_SAC != 1) {
                    boolVal = duplicateData["data"][0]?.FL_LOCATARIO_PES == 1
                }
                if (headingText == "New Guarantor" || headingText == "Novo Fiador") {
                    boolVal = duplicateData["data"][0]?.FL_FIADOR_PES == 1
                }
                if (headingText == "New Broker" || headingText == "Novo Corretor") {
                    boolVal = duplicateData["data"][0]?.FL_CORRETOR_PES == 1
                }
                if (headingText == "New Inspector" || headingText == "Novo Vistoriador") {
                    boolVal = duplicateData["data"][0]?.FL_VISTORIADOR_PES == 1
                }
                if (headingText == "New Supplier" || headingText == "Novo Fornecedor") {
                    let fav: any = duplicateData["data"][0]?.ID_FAVORECIDO_FAV
                    if (fav) {
                        boolVal = true
                    }
                    else {
                        boolVal = false
                    }
                }

                const fieldsMapping: any = {
                    email: "ST_EMAIL_PES",
                    name: "ST_NOME_PES",
                    mobile: "ST_CELULAR_PES",
                    complement: "ST_COMPLEMENTO_PES",
                    zipcode: "ST_CEP_PES",
                    city: "ST_CIDADE_PES",
                    address: "ST_ENDERECO_PES",
                    state: "ST_ESTADO_PES",
                    number: "ST_NUMERO_PES",
                    neighborhood: "ST_BAIRRO_PES",
                    telephone: "ST_TELEFONE_PES",
                    observation: "ST_OBSERVACAO_PES",
                    nationality: "ST_NACIONALIDADE_PES",
                    sex: "ST_SEXO_PES",
                    birth: "DT_NASCIMENTO_PES",
                    marital_status: "ST_ESTADOCIVIL_PES",
                    employment_company: "ST_TRABALHOCEP_PES",
                    employment_profession: "ST_PROFISSAO_PES",
                    employment_field_activity: "ST_RAMOATIVIDADE_PES",
                    employment_address: "ST_TRABALHOENDERECO_PES",
                    employment_number: "ST_TRABALHONUMERO_PES",
                    employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
                    employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
                    employment_city: "ST_TRABALHOCIDADE_PES",
                    employment_state: "ST_TRABALHOESTADO_PES",
                    employment_monthly_income: "VL_RENDAMENSAL_PES",
                    rg: "ST_RG_COJ",
                    retention: "VL_TXISSQN_PES",
                    mother: "ST_NOMEMAE_PES",
                    father: "ST_NOMEPAI_PES",
                    shipping_date: "DT_EXPEDICAORG_PES",
                    payment: "ID_FORMARECEBIMENTO_PES",
                    bankname: "ST_BANCO_PES",
                    bank_agency: "ST_AGENCIABANCO_PES",
                    bank_acocunt: "ST_CONTA_PES",
                    bank_accountType: "ST_TIPOCONTA_PES",
                    bank_branchCode: "ST_OPERACAO_PES",
                    name_of: "ST_NOMERECEBEDOR_PES",
                    block_balance: "VL_SALDOBLOQUEADO_PES",
                    manager: "ID_GESTOR_GES",
                    issue_agency: "ST_ORGAO_PES",
                    corporateReason: "ST_NOME_PES",
                    stateRegistration: "ST_IE_PES",
                    municipalRegistration: "ST_INSCMUNICIPAL_PES"
                };

                if (duplicateData["data"].length > 0) {
                    if (boolVal) {
                        zipCodeCheck?.["setAttribute"]('disabled', "")
                        setcnpjcpferrormessage({
                            name: name,
                            message: t("formLabel.ErrorCNPJCPF")
                        })
                        setError(name, {
                            type: "manual",
                            message: t("formLabel.ErrorCNPJCPF")
                        });
                    } else {
                        zipCodeCheck?.["removeAttribute"]('disabled');
                        // Assuming `duplicateData` is correctly populated
                        const data: any = duplicateData?.["data"]?.[0];
                        if (data) {
                            Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                                setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
                            });
                        }
                    }
                } else {
                    Object?.entries(fieldsMapping)?.forEach(([fieldName, dataKey]) => {
                        setValue(fieldName as keyof CustomersAndEmployeesForm, "");
                        setValue('ID_FAVORECIDO_FAV', "")
                        setValue('ID_SACADO_SAC', "")
                    });

                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    const ownerPaymentHide = (evt) => {
        setHidePixAlso(false);
        if (hide5) {
            if (evt.target.value == 12) {
                setPixTypeHide(false);
                setCheckHide(false)
                setHidePixAlso(false)
            } else
                if (evt.target.value == 8 || evt.target.value == 0) {
                    setPixTypeHide(true)
                } else {
                    setPixTypeHide(false)
                    setHidePixAlso(true)
                }
        } else {
            let payment = evt.target.value
            if (payment !== "") {
                clearErrors("payment")
            } else {
                setError(payment, {
                    type: "manual",
                    message: `${(t('requiredMmessage.fieldRequired'))}`
                })
            }
            if (evt.target.value == 12) {
                setPixTypeHide(false);
                setCheckHide(false)

            } else if (evt.target.value == 4) {
                setPixTypeHide(false);
                setHidePixAlso(true);
            }
            else if (evt.target.value == 3) {
                setPixTypeHide(false);
                setCheckHide(true)
            } else {
                setPixTypeHide(true);
                setCheckHide(false)
            }
            if (evt.target.value !== "0" || evt.target.value !== "12") {
                setbankNotRequeire(true)
                clearErrors('bankname')
                clearErrors('bank_acocunt')
                clearErrors('bank_agency')
                clearErrors('bank_accountType')
            } else {
                setbankNotRequeire(false)
            }
        }
    }

    const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hide2) {
            setShow3(true)
            step1();
        } else if (hide3) {
            step2();
        } else {
            step1();
        }
    }

    const handleConfigureEmail = (e: MouseEvent<HTMLButtonElement>) => {
        setMailText(e.currentTarget.name)
        e.preventDefault();
        // dispatch(openConfigureEmail(true));
    }

    const [spouse, setspouse] = useState(false);
    const handleChangeMaritail = (e) => {
        if (e.target.value == "1" || e.target.value == "7") {
            setspouse(true);
        } else {
            setspouse(false);
        }
    }

    const formShow = () => {
        dispatch(openNewOwner(true))
    }
    const handleNameof = (e) => {
        let { value } = e.target
        setValue("name_of", value)
        // Error validation
        if (value == "" || value == null || value == undefined) {
            // Set an error message using setError or update your error state
            setError("name", {
                type: "manual",
                message: (t('requiredMmessage.fieldRequired'))
            });

        } else {
            if (headingText == "New Inspector" || headingText == "Novo Vistoriador") {
                if (value.length > 50) {
                    setError("name", {
                        type: "manual",
                        message: (t('requiredMmessage.50characters'))
                    });

                } else {
                    clearErrors('name');
                }
            } else {
                clearErrors('name');
            }
            // Clear the telephone error if the value is not empty
        }
    }

    const handleRepresentative = async () => {
        let resp = await dispatch(GetRepresentativeService())
        if (resp?.payload?.type == "success" || resp?.payload?.type == "Success") {
            setRepreData(resp?.payload?.data)
        }
    }

    useEffect(() => {
        const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
        const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
        setSearchResults(filteredBanks);

        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [banklist]);


    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };

    const HandleBankAccount = (e: any, item) => {
        setValue('bankname', item?.bank_name)
        setValue("bank_branchCode", item.bank_code);
        setIsInputFocused(false);
    }
    const handlefilterBillmethod = async (e) => {
        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = banklist.filter((bank) => {
                // Convert the bank name to lowercase for case-insensitive comparison
                const bankName = bank.bank_name.toLowerCase();
                return bankName.includes(lowerSearchTerm);
            });
            setSearchResults(results)
        } else {
            const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
            const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code))
            setSearchResults(filteredBanks);
        }
    }


    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedFormData = [...formData]; // Create a copy of the formData array
        const dataIndex = name.split('.')[1]; // Extract the index from the name attribute
        if (dataIndex !== undefined) {
            // If the name attribute contains an index, update the corresponding nested object
            const nestedIndex = parseInt(dataIndex); // Parse the index to an integer
            updatedFormData[nestedIndex] = { ...updatedFormData[nestedIndex], [name.split('.')[2]]: value };
        } else {
            // If the name attribute does not contain an index, update the main object
            updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        }
        setFormData(updatedFormData); // Update the state with the modified array
    };


    const addInput = () => {
        setFormData([...formData, { Property_Type: '', Property_Value: '', Neighborhood: '', Zip_code: '', Address: '', Number: '', Complement: '', state_of: '', City: '', Registry: '', Registration: '', Building_con: '', }]);
    };


    const removeInput = (index) => {
        formData.splice(index, 1);
        setFormData([...formData]);
        formData.forEach((item, indx) => {
            setValue(`formData.${indx}.Property_Type`, item.Property_Type);
            setValue(`formData.${indx}.Property_Value`, item.Property_Value);
            setValue(`formData.${indx}.Zip_code`, item.Zip_code);
            setValue(`formData.${indx}.Neighborhood`, item.Neighborhood);
            setValue(`formData.${indx}.Address`, item.Address);
            setValue(`formData.${indx}.Number`, item.Number);
            setValue(`formData.${indx}.Complement`, item.Complement);
            setValue(`formData.${indx}.state_of`, item.state);
            setValue(`formData.${indx}.City`, item.City);
            setValue(`formData.${indx}.Registry`, item.Registry);
            setValue(`formData.${indx}.Registration`, item.Registration);
            setValue(`formData.${indx}.Building_con`, item.Building_con);
        })
        // If you need to update the state or any other variable with the updated data, do it here
    };

    const getAllOwner = (owner) => {
        if (owner["start"] == 1) {
            owner["start"] = 0;
        }
        let ownerData = { owner: owner.owner, start: owner.start, end: owner.end, status: owner.status, person: owner.person, page: 'ownersandbenef' }
        setStatus(true);
        customerService.filterOwner(ownerData).then(res => {
            let data: any = res.data?.data;
            let emails = data.map(obj => obj.email).filter(email => email !== null && email !== undefined);
            let ids = data.map(obj => obj.id).filter(id => id !== null && id !== undefined);
            mailData = emails;
            personId = ids;
            setMailData(prevEmailArray => [...prevEmailArray, ...mailData]);
            setPersonId(prevEmailArray => [...prevEmailArray, ...personId]);
        }).catch(err => {
            setStatus(false);
            console.log("err", err)
        });
    }

    const getAllTenant = (tenstate) => {
        customerService.filterTenant(tenstate).then(res => {
            let data: any = res.data?.data;
            let emails = data.map(obj => obj.email).filter(email => email !== null && email !== undefined);
            let ids = data.map(obj => obj.id).filter(id => id !== null && id !== undefined);
            mailData = emails;
            personId = ids;
            setMailData(prevEmailArray => [...prevEmailArray, ...mailData]);
            setPersonId(prevEmailArray => [...prevEmailArray, ...personId]);
        }).catch(err => {
            setStatus(false);
            console.log("err", err)
        });
    }


    const handleSendEmailActiveContracts = async (e, type) => {
        e.preventDefault();
        if (type == "owner") {
            let active_contract_owners = {
                owner: "",
                person: "",
                status: "activewithContract",
                start: 0,
                end: PageSize
            }
            setownState(active_contract_owners);
            await getAllOwner(active_contract_owners);
            setMailText(t('sendEmailActiveContracts'))
            dispatch(openEmailPop(true))
        } if (type == "tenant") {
            let active_contract_tenants = {
                tenant: "",
                person: "",
                status: "activewithContract",
                payment: "",
                property_type: "",
                type: "",
                start: 0,
                end: PageSize
            }
            await getAllTenant(active_contract_tenants);
            await set_customer_type("Tenant");
            setMailText(t('sendEmailActiveTenantContracts'))
            dispatch(openEmailPop(true))
        }
    };

    const handleSendEmailWithoutActiveContracts = async (e, type) => {
        e.preventDefault();
        if (type == "owner") {
            let non_active_contract_owners = {
                owner: "",
                person: "",
                status: "activewithoutContract",
                start: 0,
                end: PageSize
            }
            setownState(non_active_contract_owners);
            await getAllOwner(non_active_contract_owners);
            setMailText(t('sendEmailNonActiveContracts'))
            dispatch(openEmailPop(true))
        } if (type == "tenant") {
            let active_contract_tenants = {
                tenant: "",
                person: "",
                status: "activewithoutContract",
                payment: "",
                property_type: "",
                type: "",
                start: 0,
                end: PageSize
            }
            await getAllTenant(active_contract_tenants);
            await set_customer_type("Tenant");
            setMailText(t('sendEmailNonActiveTenantContracts'))
            dispatch(openEmailPop(true))
        };
    }

    /*----------Open the new representative modal form------------*/
    const [showNewRepresentativeModal, setNewRepresentativeModal] = useState(false);
    const newRepresentativeModalClose = () => {
        setNewRepresentativeModal(false);
        setShow3(false)
        if (!hide) {
            step2()
        } else {
            step3()
        }
        setShow(true)
    }
    const newRepresentativeModalShow = () => {
        setNewRepresentativeModal(true);
        setShow(false)
    }

    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });

    const handleChangeInput = (e: any, index: any, type: string, field: string) => {
        let value = e.target.value;
        addState[type][index][field] = value;
        let allState: any = addState;
        setAddState(allState);
    }

    const addAnother = (e: any, type: string) => {
        e.preventDefault();
        if (type == "retentionn") {
            let dataAdd: any = {
                ...addState,
                retentionn: [
                    ...addState["retentionn"],
                    { Tax: "", Aliquot: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "beneficiaries") {
            let dataAdd: any = {
                ...addState,
                beneficiaries: [
                    ...addState["beneficiaries"],
                    { name: "", transfer: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "representative") {
            let dataAdd: any = {
                ...addState,
                representative: [
                    ...addState["representative"],
                    { name: "" }
                ]
            }
            setAddState(dataAdd)
        }
    }

    const removeAddAnother = (index: any, array: any, type: any) => {
        array.splice(index, 1);
        // reset(type);
        if (type == "retentionn") {
            array.forEach((item: any, index: number) => {
                let Tax: any = `retentionn.${index}.tax`;
                let Aliquot: any = `retentionn.${index}.Aliquot`;
                setValue(Tax, item.Tax);
                setValue(Aliquot, item.Aliquot);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "beneficiaries") {
            array.forEach((item: any, index: number) => {
                let name: any = `beneficiaries.${index}.name`;
                let transfer: any = `beneficiaries.${index}.transfer`;
                setValue(name, item.name);
                setValue(transfer, item.transfer);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "representative") {
            array.forEach((item: any, index: number) => {
                let name: any = `representative.${index}.name`;
                setValue(name, item.name);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }
    }

    const handleActivLegal = async (e: any, type: string) => {
        e.preventDefault();
        let data: any = {
            type: "legal_entities"
        }
        if (type == "owner") {
            navigate(`/owners_and_beneficiaries`, { state: data });
        } else if (type == "tenants") {
            navigate(`/tenants`, { state: data });
        } else if (type == "guarantor") {
            navigate(`/guarantors`, { state: data });
        }
    }
    const Handlebloquendo = (e) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        setValue("bloquendo", formatNumber(value))
        setValueCon(value)
    }

    const Handleaddress = (e) => {
        let { value } = e.target;
        let numberOfCharacters = value.length;
        // Handle the character length validation
        if (numberOfCharacters > 40) {
            setError("address", {
                type: "manual",
                message: t("requiredMmessage.40characters")
            });
        } else {
            clearErrors("address")
        }
    }
    const handleRepersen = (e: any, index) => {
        let target_name = e.target.name;
        let target_value = e.target.value;
        addState["representative"][`${index}`]["name"] = String(target_value);
        setAddState(addState)
    }
    const handleInspector = (type: any) => {
        let data: any = {
            type: "inspectors"
        }
        navigate("/inspectors", { state: data })

    }
    
    const handleEmail = (e)=>{
        e.preventDefault();
        let vl = e.target.value;
        if(vl && vl!=""){
            setIsEmail(true);
        }
        else {
            setIsEmail(false)
        }
    }
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="d-flex justify-content-between align-items-center h-100">
                        <Col xs={{ span: 10, order: 12 }} sm={{ span: 6, order: 0 }}>
                            <div className="d-flex align-items-center justify-content-start">
                                <div className="w-100 overflow-hidden">
                                    <h1 className="mb-0 lh-base text-truncate">{t('customerAndEmployees.clientsAndCollaborators')}</h1>
                                </div>
                            </div>
                        </Col>
                        {/* <Col xxl={3} xl="auto" className="order-2 order-xl-2">
                        <div className="input-group">
                            <Form.Control type="text" id="search" placeholder={t('formLabel.typeSearch')} aria-describedby="basic-addon2" />
                            <span className="input-group-text" id="basic-addon2">
                                <img alt="img" src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} />
                            </span>
                        </div>
                    </Col> */}
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(1031) !== -1) &&
                            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 3 }} className="text-sm-end">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                        {/* <Button type="button" onClick={handleConfigureEmail} name={t('formLabel.configureEmail')} variant="link" className="p-0 fw-bold" size="sm">
                                            {t('formLabel.configureEmail')}
                                        </Button> */}

                                    </li>

                                    <li className="list-inline-item">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/settings-light.svg"} alt="settings" onClick={clientSettingModalShow} className="h-20 cursor-pe" />
                                        <ClientCollaboratorsSettings showCollaboratorsSetting={showClientSettingModal} collaboratorsSettingClose={clientSettingModalClose} onSave={handleSaveSettings} initialCheckboxState={savedCheckboxState} />
                                    </li>

                                    {/* <li className="list-inline-item mb-md-0 mb-1">
                                    <Link to={"#"} className="fw-bold">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                                    </Link>
                                </li> */}
                                </ul>
                            </Col>
                        }
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {status == false ?
                    <Loader />
                    :
                    <>
                        <Row className="g-md-4 g-3">
                            {
                                (userJson && userJson.length > 0 && (userJson.indexOf(2050) !== -1)) &&
                                <Col lg={4} md={6}>
                                    {/* <!-- Owners card start from here --> */}
                                    <Card className="h-100">
                                        <Card.Body>
                                            <Row className="h-100">
                                                <Col>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h3 className="mb-0 lh-1">
                                                            <img className="h-20 me-1" src={process.env.PUBLIC_URL + "/assets/images/icons/user-person.svg"} />
                                                            {t('customerAndEmployees.owners')}
                                                        </h3>
                                                        <div>
                                                            {
                                                                ((userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1)) || String(agencyornot) == "1") &&
                                                                <div className="d-block text-center">
                                                                    <Button type="button" variant="link" size="sm" data-title="New Owner" className="p-0 me-3 fw-bold" onClick={handleMouseEvent} name={t('customerAndEmployees.newOwner')} >
                                                                        {t('ReceiveandReceived.New')}
                                                                    </Button>
                                                                    <button type="button" className="btn btn-sm btn-link p-0 fw-bold dropdown-arrow-none dropdown-toggle"
                                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img className="h-20" src={process.env.PUBLIC_URL + "/assets/images/icons/settings-light.svg"} />
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><a className="dropdown-item" href="#" onClick={(e) => handleSendEmailActiveContracts(e, "owner")}>{t('sendEmailActiveContracts')}</a></li>
                                                                        <li><a className="dropdown-item" href="#" onClick={(e) => handleSendEmailWithoutActiveContracts(e, "owner")}>{t('customerAndEmployees.sendEmailWithoutActiveContracts')}</a></li>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Link to={"/owners_and_beneficiaries"} className="d-block text-dark h3 mt-4 mb-0 lh-1">{data.owners}</Link>
                                                    <span className="d-block text-secondary mt-4">{t('customerAndEmployees.active')}</span>
                                                    <span className="d-block text-secondary mt-4 cursor-pe" onClick={(e) => handleActivLegal(e, "owner")}>{data.legalOwner} {data.legalOwner == 0 || data.legalOwner == 1 ? t('customerAndEmployees.legal') : t('customerAndEmployees.legals')}</span>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    {/* <!-- /OWNERS card end --> */}
                                </Col>
                            }
                            {
                                (userJson && userJson.length > 0 && (userJson.indexOf(2040) !== -1)) &&
                                <Col lg={4} md={6}>
                                    {/*  <!-- Tenants card start from here --> */}
                                    <Card className="h-100">
                                        <Card.Body>
                                            <Row className="h-100">
                                                <Col>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h3 className="mb-0 lh-1">
                                                            <img className="h-20 me-1" src={process.env.PUBLIC_URL + "/assets/images/icons/key-light.svg"} />
                                                            {t('customerAndEmployees.tenants')}
                                                        </h3>
                                                        <div>
                                                            {
                                                                ((userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1)) || String(agencyornot) == "1") &&
                                                                <div className="d-block text-center">
                                                                    <Button type="button" variant="link" size="sm" data-title="New Tenant" className="p-0 me-3 fw-bold" onClick={handleMouseEvent} name={t('customerAndEmployees.newTenant')} >
                                                                        {t('ReceiveandReceived.New')}
                                                                    </Button>
                                                                    <button type="button" className="btn btn-sm btn-link p-0 fw-bold dropdown-arrow-none dropdown-toggle"
                                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img className="h-20" src={process.env.PUBLIC_URL + "/assets/images/icons/settings-light.svg"} />
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><a className="dropdown-item" href="#" onClick={(e) => handleSendEmailActiveContracts(e, "tenant")}>{t('customerAndEmployees.sendEmailActiveContracts')}</a></li>
                                                                        <li><a className="dropdown-item" href="#" onClick={(e) => handleSendEmailWithoutActiveContracts(e, "tenant")}>{t('customerAndEmployees.sendEmailWithoutTenantActiveContracts')}</a></li>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Link to={"/tenants"} className="d-block text-dark h3 mt-4 mb-0 lh-1">{data.tenants}</Link>
                                                    <span className="d-block text-secondary mt-4">{t('customerAndEmployees.active')}</span>
                                                    <span className="d-block text-secondary mt-4 cursor-pe" onClick={(e) => handleActivLegal(e, "tenants")}>{data.legaltenant} {data.legaltenant == 0 || data.legaltenant == 1 ? t('customerAndEmployees.legal') : t('customerAndEmployees.legals')}</span>

                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    {/* <!-- /Tenants card end --> */}
                                </Col>
                            }
                            {
                                (userJson && userJson.length > 0 && (userJson.indexOf(2060) !== -1)) &&
                                <Col lg={4} md={6}>
                                    {/* <!-- Guarantors card start from here --> */}
                                    <Card className="h-100">
                                        <Card.Body>
                                            <Row className="h-100">
                                                <Col>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h3 className="mb-0 lh-1">
                                                            <img className="h-20 me-1" src={process.env.PUBLIC_URL + "/assets/images/icons/shield-key.svg"} />
                                                            {t('customerAndEmployees.guarantors')}
                                                        </h3>
                                                        <div>
                                                            {
                                                                ((userJson && userJson.length > 0 && (userJson.indexOf(2061) !== -1)) || String(agencyornot) == "1") &&
                                                                <div className="d-block text-center">
                                                                    <Button type="button" variant="link" size="sm" data-title="New Guarantor" className="p-0 fw-bold me-3" onClick={handleMouseEvent} name={t('customerAndEmployees.newGuarantor')} >
                                                                        {t('ReceiveandReceived.New')}
                                                                    </Button>
                                                                    <Dropdown.Toggle variant="link" size="sm" className="dropdown-arrow-none p-0" id="dropdownMenuButton1">
                                                                        <img className="h-20" src={process.env.PUBLIC_URL + "/assets/images/icons/settings-light.svg"} />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#">{t('customerAndEmployees.guarantorsReport')}</Dropdown.Item>
                                                                        <Dropdown.Item href="#">{t('customerAndEmployees.notifyActiveContracts')}</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Link to={"/guarantors"} className="d-block text-dark h3 mt-4 mb-0 lh-1">{data.guarantors}</Link>
                                                    <span className="d-block text-secondary mt-4">{t('customerAndEmployees.active')}</span>
                                                    <span className="d-block text-secondary mt-4 cursor-pe" onClick={(e) => handleActivLegal(e, "guarantor")}>{data.legalGuarantors} {data.legalGuarantors == 0 || data.legalGuarantors == 1 ? t('customerAndEmployees.legal') : t('customerAndEmployees.legals')}</span>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    {/* <!-- /Guarantors card ned --> */}
                                </Col>
                            }

                            {
                                (userJson && userJson.length > 0 && (userJson.indexOf(3100) !== -1)) &&
                                <Col lg={4} md={6}>
                                    {/* <!-- Brokers card start from here -->*/}
                                    <Card className="h-100">
                                        <Card.Body >
                                            <Row className="h-100">
                                                <Col>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h3 className="mb-0 lh-1">
                                                            <img className="h-20 me-1" src={process.env.PUBLIC_URL + "/assets/images/icons/m-users.svg"} />
                                                            {t('customerAndEmployees.brokers')}
                                                        </h3>
                                                        <div>
                                                            {
                                                                ((userJson && userJson.length > 0 && (userJson.indexOf(3101) !== -1)) || String(agencyornot) == "1") &&
                                                                <div className="d-block text-center">
                                                                    <Button type="button" variant="link" size="sm" className="p-0 fw-bold" data-title="New Broker" onClick={handleMouseEvent} name={t('customerAndEmployees.newBroker')} >{t('ReceiveandReceived.New')}</Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Link to={"/brokers"} className="d-block text-dark h3 mt-4 mb-0 lh-1">{data.brokers}</Link>
                                                    <span className="d-block text-secondary mt-4">{t('customerAndEmployees.active')}</span>
                                                    <span className="d-block text-secondary mt-4 invisible">2 {t('customerAndEmployees.legal')}</span>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    {/* <!-- /Brokers card end -->*/}
                                </Col>
                            }

                            {
                                (userJson && userJson.length > 0 && (userJson.indexOf(2000) !== -1)) &&
                                <Col lg={4} md={6}>
                                    {/* <!-- Providers card start from here -->*/}
                                    <Card className="card h-100">
                                        <Card.Body>
                                            <Row className="h-100">
                                                <Col>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h3 className="mb-0 lh-1">
                                                            <img className="h-20 me-1" src={process.env.PUBLIC_URL + "/assets/images/icons/money-dark.svg"} />
                                                            {t('customerAndEmployees.suppliers')}
                                                        </h3>
                                                        <div>
                                                            {
                                                                ((userJson && userJson.length > 0 && (userJson.indexOf(2001) !== -1)) || String(agencyornot) == "1") &&
                                                                <div className="d-block text-center">
                                                                    <Button type="button" variant="link" size="sm" className="p-0 fw-bold" data-title="New Supplier" onClick={handleMouseEvent} name={t('customerAndEmployees.consultSupplier')} >
                                                                        {t('ReceiveandReceived.New')}
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Link to={"/suppliers"} className="d-block text-dark h3 mt-4 mb-0 lh-1" >{data.suppliers}</Link>
                                                    <span className="d-block text-secondary mt-4">{t('customerAndEmployees.active')}</span>
                                                    <span className="d-block text-secondary mt-4 invisible">2 {t('customerAndEmployees.legal')}</span>

                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    {/* <!-- /Providers card end -->*/}
                                </Col>
                            }

                            <Col lg={4} md={6}>
                                {/* <!-- Inspectors card start from here -->*/}
                                <Card className="h-100">
                                    <Card.Body>
                                        <Row className="h-100">
                                            <Col >
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h3 className="mb-0 lh-1">
                                                        <img className="w-24 me-1" src={process.env.PUBLIC_URL + "/assets/images/icons/eye.svg"} />
                                                        {t('customerAndEmployees.inspectors')}
                                                    </h3>
                                                    <div>
                                                        {/* {
                                                            ((userJson && userJson.length > 0 && (userJson.indexOf(3071) !== -1)) || String(agencyornot) == "1") && */}
                                                        <div className="d-block text-center">
                                                            <Button type="button" variant="link" size="sm" className="p-0 fw-bold" data-title="New Inspector" onClick={handleMouseEvent} name={t('customerAndEmployees.newInspector')} >{t('ReceiveandReceived.New')}</Button>
                                                        </div>
                                                        {/* } */}
                                                    </div>
                                                </div>
                                                <span className="d-block text-dark h3 mt-4 mb-0 lh-1 cursor-pe " onClick={() => handleInspector("inspectors")}>{data.inspectors}</span>
                                                <span className="d-block text-secondary mt-4">{t('customerAndEmployees.active')}</span>
                                                <span className="d-block text-secondary mt-4 invisible">2 {t('customerAndEmployees.legal')}</span>

                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                {/* <!-- /Inspectors card ned -->*/}
                            </Col>
                        </Row>
                    </>
                }
            </main>
            {/*<!-- /Main content start end -->*/}
            {/* <ConfigureEmail mailText={mailText} mailData={mailData} /> */}
            {/* <NewOwner fromData="direct" update={update} setUpdate={setUpdate} /> */}

            <Modal show={show} onHide={formClose} className='CustomerstsxAndEmployeestsx'>
                <Modal.Header closeButton>
                    <Modal.Title>{headingText} </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Body>
                        <input type="text" {...register('ID_SACADO_SAC')} hidden />
                        <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden />
                        <input type="text" {...register('ID_PESSOAS')} hidden />

                        <div style={{ display: show3 ? "block" : "none" }}>
                            <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                                <input type="hidden" value={userTypeIs} {...register('type')} />
                                <FloatingLabel controlId="floatingInput" label={headingText == "Novo Fornecedor" || headingText == "New Supplier" ? t('formLabel.cnpjCeiCpf') + " " : t('formLabel.cnpjCpf') + " *"} >
                                    <Form.Control type="text" placeholder={headingText == "Novo Fornecedor" || headingText == "New Supplier" ? t('formLabel.cnpjCeiCpf') : t('formLabel.cnpjCpf')} className={` ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                    <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </div>
                        </div>
                        <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                            {showHideField == "cnpj" ?
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.corporateReason")}>
                                            <Form.Control type="text" placeholder="Corporate reason" className={`form-control ${errors.corporateReason ? 'is-invalid' : ''}`} {...register('corporateReason')} />
                                            <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.fantasyName") + " *"}>
                                            <Form.Control type="text" placeholder="Fantasy name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleNameof(e)} />
                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.stateregistration")}>
                                            <Form.Control type="text" placeholder="State registration" maxLength={20} className={`form-control ${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.municipalregistration")}>
                                            <Form.Control type="text" placeholder="Municipal registration" maxLength={20} className={`form-control ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    {headingText == "New Supplier" || headingText == "Novo Fornecedor" ?
                                        <Col md={6}>
                                            <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {managerData?.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name} </option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        : ""}
                                </Row> :
                                <FloatingLabel controlId="name" label={t('formLabel.name') + " *"} className="mb-3" >
                                    <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            }
                            {
                                hide5 && showHideField == "cpf" ?
                                    <>
                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("RG")}>
                                                    <Form.Control type="text" placeholder={t("RG")} className={`form-control`} {...register('rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.Organexp")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.Organexp")} className={`form-control `} {...register('Organ_exp')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.INSS")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.INSS")} className={`form-control`} {...register('inss')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.RPA")}>
                                                    <Form.Control type="text" placeholder="Municipal registration" className={`form-control`} {...register('rpa')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        {
                                            hide7 && showHideField == "cpf" &&
                                            <Col xs={12}>
                                                <Accordion.Body>
                                                    <Form.Check type="checkbox" {...register('non_recedential')} label={t('non_recedential')} />
                                                </Accordion.Body>
                                            </Col>
                                        }
                                        <Row className='mt-1'>
                                            <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {managerData?.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name} "33333"</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Row>
                                    </>
                                    : ""}

                            <div style={{ display: bankFields ? "block" : "none" }} >
                                <span className="d-block mb-3 fw-bold">{t('formLabel.payment')}</span>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.defaultPaymentMethod') + " *"}>
                                    <Form.Select className={` ${errors.payment ? 'is-invalid' : ''}`}  {...register('payment')} aria-label={t('formLabel.defaultPaymentMethod')} onChange={(e) => ownerPaymentHide(e)}>
                                        <option value="">{t('formLabel.select')}</option>
                                        {hide5 ?
                                            <>
                                                <option value="0">{t('customerAndEmployees.boleto')}</option>
                                                <option value="1">{t('customerAndEmployees.Cheque')}</option>
                                                <option value="2" >{t('customerAndEmployees.money')}</option>
                                                <option value="3" >{t('customerAndEmployees.creditcard')}</option>
                                                <option value="4" >{t('reportBanking.Debitcard')}</option>
                                                <option value="7" >{t('reportBanking.AutomaticDebit')}</option>
                                                <option value="8" >{t('customerAndEmployees.Transferbanking')}</option>
                                                <option value="9" >{t('reportBanking.DOC/TED')}</option>
                                                <option value="10" >{t('customerAndEmployees.others')}</option>
                                                <option value="12" >{t("customerAndEmployees.pix")}</option>
                                            </>
                                            :
                                            <>
                                                <option value="0">{t('customerAndEmployees.splitpaymentKenloPay')}</option>
                                                <option value="12">{t("customerAndEmployees.pix")}</option>
                                                <option value="5">{t('customerAndEmployees.bankTransfer')}</option>
                                                <option value="6">{t('customerAndEmployees.docTed')}</option>
                                                <option value="2">{t('customerAndEmployees.cashDeposit')}</option>
                                                <option value="1">{t('customerAndEmployees.chequeDeposit')}</option>
                                                <option value="3">{t('customerAndEmployees.pickUpCheque')}</option>
                                                <option value="4">{t('customerAndEmployees.withdrawMoney')}</option>
                                            </>
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.payment?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                                {
                                    hide8 &&
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('Repassedia')}>
                                                <Form.Control type="tel" placeholder={t('Repassedia')}  {...register('Repassedia')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('bloquendo')}>
                                                <Form.Control type="text" placeholder={t('bloquendo')}   {...register('bloquendo')} onChange={(e) => Handlebloquendo(e)} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                }
                                <>

                                    {(!pixTypeHide && !HidePixAlso) ?
                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3" >
                                            <Col md={6}>
                                                <FloatingLabel controlId="pixKeyType" label={t("banks.pixkeyType")}>
                                                    <Form.Select className={` ${errors.pixKeyType ? 'is-invalid' : ''}`} {...register('pixKeyType')} aria-label={t("banks.pixkeyType")}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">CPF</option>
                                                        <option value="2">CNPJ</option>
                                                        <option value="3">Celular</option>
                                                        <option value="4">E-mail</option>
                                                        <option value="5">Aleatoria</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.pixKeyType?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            {checkhide ?
                                                <Col md={6} style={{ display: hide5 ? "none" : "block" }}>
                                                    <FloatingLabel controlId="name_of" label={t("customerAndEmployees.Inthenameof")}>
                                                        <Form.Control type="text" placeholder={t("customerAndEmployees.Inthenameof")}   {...register('name_of')} />
                                                    </FloatingLabel>
                                                </Col>
                                                :
                                                <Col md={6}>
                                                    <FloatingLabel controlId="pixKey" label={t("banks.pixKey")}>
                                                        <Form.Control type="text" placeholder={t('customerAndEmployees.PixKey')} className={` ${errors.pixKey ? 'is-invalid' : ''}`}  {...register('pixKey')} />
                                                        <Form.Control.Feedback type="invalid">{errors.pixKey?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>}
                                        </Row>
                                        : ""
                                    }

                                    {/* Bank Form Here */}
                                    {pixTypeHide ?
                                        <>
                                            <Form.Floating className="mb-4" ref={inputRef} >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type to search..."
                                                    {...register('bankname')}
                                                    onChange={(e) => handlefilterBillmethod(e)}
                                                    onFocus={handleInputFocus}
                                                    className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"
                                                    autoComplete="off"
                                                />
                                                {isInputFocused && (
                                                    <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}>
                                                        {searchResults?.slice(0, 10).reverse().map((item: any, index) => (
                                                            <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleBankAccount(e, item)}>
                                                                {item.bank_code} - {item.bank_name}
                                                            </ListGroup.Item>
                                                        ))}
                                                    </ListGroup>

                                                )}
                                                <Form.Label htmlFor="floatingInputValue">{t("banks.name") + (headingText !== "New Supplier" && headingText !== "Novo Fornecedor" ? " *" : "")}</Form.Label >
                                                <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback >
                                            </Form.Floating>

                                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                                <Col md={6}>
                                                    <Form.Floating>
                                                      <Form.Control
                                                        className={`${errors.bank_acocunt ? 'is-invalid' : ''}`}
                                                        id="account"
                                                        placeholder="Conta"
                                                        {...register("bank_acocunt")}
                                                        onChange={(e: any) => {
                                                          const newValue = e.target.value;
                                                          setValue("bank_acocunt", newValue.replace(/\D/, ""))
                                                        }}
                                                      />
                                                      <Form.Control.Feedback type="invalid">{errors.bank_acocunt?.message}</Form.Control.Feedback >
                                                      <Form.Label htmlFor="floatingInputValue">
                                                        Conta {headingText !== "New Supplier" && headingText !== "Novo Fornecedor" ? " *" : ""}
                                                      </Form.Label>
                                                    </Form.Floating>
                                                </Col>
                                                <Col md={3}>
                                                  <Form.Floating>
                                                    <Form.Control
                                                      id="bankAccountDigit"
                                                      placeholder="Dígito"
                                                      {...register("bankAccountDigit")}
                                                      maxLength={1}
                                                    />
                                                    <Form.Label htmlFor="floatingInputValue">Dígito</Form.Label>
                                                  </Form.Floating>
                                                </Col>
                                                <Col md={3}>
                                                  <Form.Floating>
                                                    <Form.Control
                                                      className={`${errors.bank_agency ? 'is-invalid' : ''}`}
                                                      id="bank_agency"
                                                      placeholder="Agência"
                                                      {...register("bank_agency")}
                                                      maxLength={4}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.bank_agency?.message}</Form.Control.Feedback>
                                                    <Form.Label htmlFor="floatingInputValue">
                                                      Agência{headingText !== "New Supplier" && headingText !== "Novo Fornecedor" ? " *" : ""}
                                                    </Form.Label>
                                                  </Form.Floating>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel controlId="floatingSelect" label={t('banks.accountType') + (headingText !== "New Supplier" && headingText !== "Novo Fornecedor" ? " *" : "")}>
                                                        <Form.Select className={`${errors.bank_accountType ? 'is-invalid' : ''}`}  {...register('bank_accountType')} aria-label="Account Type">
                                                            <option value="">{t('dropdownItem.select')}</option>
                                                            <option value="0">{t('currentAccount')}</option>
                                                            <option value="1">{t('SavingAccount')}</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{errors.bank_accountType?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6} style={{ display: hide5 ? "none" : "block" }}>
                                                    <FloatingLabel controlId="name_of" label={t("customerAndEmployees.Inthenameof") + (headingText !== "New Supplier" && headingText !== "Novo Fornecedor" ? " *" : "")}>
                                                        <Form.Control type="text" placeholder={t("customerAndEmployees.Inthenameof")} className={` ${errors.name_of ? 'is-invalid' : ''}`}  {...register('name_of')} />
                                                        <Form.Control.Feedback type="invalid">{errors.name_of?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </>
                                        : ""}

                                </>
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    {/* <Col md={6}>
                                        <Form.Floating>
                                            <Form.Control type="text" className={`${errors.bank_acocunt ? 'is-invalid' : ''}`} id="account"
                                                placeholder="Account Number (With digit)" {...register("bank_acocunt")} />
                                            <Form.Control.Feedback type="invalid">{errors.bank_acocunt?.message}</Form.Control.Feedback >
                                            <Form.Label htmlFor="floatingInputValue">{t("banks.accountNo")} {t("banks.withDigit")}</Form.Label>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Floating>
                                            <Form.Control type="text" className={`${errors.bank_agency ? 'is-invalid' : ''}`} id="bank_agency"
                                                placeholder={t("banks.agency")} {...register("bank_agency")} maxLength={8} />
                                            <Form.Control.Feedback type="invalid">{errors.bank_agency?.message}</Form.Control.Feedback >
                                            <Form.Label htmlFor="floatingInputValue">{t("banks.agency")}  </Form.Label>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingSelect" label={t('banks.accountType')}>
                                            <Form.Select className={`${errors.bank_accountType ? 'is-invalid' : ''}`}  {...register('bank_accountType')} aria-label="Account Type">
                                                <option value="">{t('dropdownItem.select')}</option>
                                                <option value="1">{t('currentAccount')}</option>
                                                <option value="2">{t('SavingAccount')}</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.bank_accountType?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="bank_branchCode" label={t("customerAndEmployees.BranchCode")}>
                                            <Form.Control disabled type="text" placeholder={t("customerAndEmployees.BranchCode")} className={` ${errors.bank_branchCode ? 'is-invalid' : ''}`}  {...register('bank_branchCode')} />
                                            <Form.Control.Feedback type="invalid">{errors.bank_branchCode?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6} style={{ display: hide5 ? "none" : "block" }}>
                                        <FloatingLabel controlId="name_of" label={t("customerAndEmployees.Inthenameof")}>
                                            <Form.Control type="text" placeholder={t("customerAndEmployees.Inthenameof")} className={` ${errors.name_of ? 'is-invalid' : ''}`}  {...register('name_of')} />
                                            <Form.Control.Feedback type="invalid">{errors.name_of?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col> */}
                                    <Col md={6} style={{ display: hide5 ? "block" : "none" }}>
                                        <FloatingLabel controlId="name_of" label={t("formLabel.operation")}>
                                            <Form.Control type="text" placeholder={t("formLabel.operation")}   {...register('operation')} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </div>

                            <span className="d-block mb-3 fw-bold">{t('formLabel.contact')}</span>
                            {
                                hide9 ?
                                    <>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber') + " *"}>
                                                    <Form.Control type="tel" placeholder={t('formLabel.mobileNumber')} className={` ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                                    <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>

                                        {/* <div className="text-center mt-1">
                                            <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother1(e)}>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.AddAnother')}
                                            </Button>
                                        </div> */}
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                <Form.Control type="tel" placeholder={t('formLabel.mobileNumber')} className={` ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                                <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                            }

                            <Row className='mt-1'>
                                <Col md={6}>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                        <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email') + (headingText !== "New Guarantor" && headingText !== "Novo Fiador" && headingText !== "New Supplier" && headingText !== "Novo Fornecedor" ? " " : "")}>
                                        <Form.Control type="email" placeholder={t('formLabel.email')} className={` ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} onKeyUp={(e) => handleEmail(e)} />
                                        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    {(headingText == 'New Tenant' || headingText == 'Novo Locatário' || headingText == "New Owner" || headingText == "Novo Proprietário" || headingText == "New Inspector" || headingText == "Novo Vistoriador") &&
                                        <FloatingLabel controlId="floatingInput" label={t('formLabel.password')} className="mb-3" >
                                            <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />
                                            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    }
                                </Col>
                            </Row>
                            {
                                hide8 &&
                                <>
                                    <Accordion className="accordion-custom">
                                        <Accordion.Item eventKey="15">
                                            <Accordion.Header>{t('Beneficiaries')}</Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    Object.keys(addState.beneficiaries).map((item: any, index: number) => {
                                                        return (
                                                            <>
                                                                <Row>
                                                                    <Col md={5}>
                                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder={t('formLabel.name')}
                                                                                {...register(`beneficary.${index}.name`)}
                                                                                onChange={(e) => handleChangeInput(e, index, "beneficiaries", "name")}
                                                                            />
                                                                        </FloatingLabel>
                                                                    </Col>
                                                                    <Col md={5}>
                                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('transfer')}>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder={t('transfer')}
                                                                                {...register(`beneficary.${index}.transfer`)}
                                                                                onChange={(e) => handleChangeInput(e, index, "beneficiaries", "transfer")}
                                                                            />
                                                                        </FloatingLabel>
                                                                    </Col>
                                                                    {
                                                                        Object.keys(addState.beneficiaries) &&
                                                                        Object.keys(addState.beneficiaries).length > 1 &&
                                                                        <Col md={2}>
                                                                            <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.beneficiaries, "beneficiaries")}>
                                                                                <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                            </>
                                                        )
                                                    })
                                                }

                                                <Button variant="link" className="fw-bold p-0" onClick={(e) => addAnother(e, "beneficiaries")}  >
                                                    {t("newContractDev.AddAnother")}
                                                </Button>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </>
                            }
                            <Accordion className="accordion-custom">
                                {showHideField == "cpf" ?
                                    <>
                                        {(headingText === 'New Broker' ||
                                            headingText === 'Novo Corretor' ||
                                            headingText === 'New Supplier') ?
                                            "" :
                                            (
                                                <Accordion.Item eventKey="1" >
                                                    <Accordion.Header>{t('formLabel.affiliation')}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row>
                                                            <Col>
                                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.motherName')}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder={t('formLabel.motherName')}
                                                                        {...register('mother')}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col>
                                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fatherName')}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder={t('formLabel.fatherName')}
                                                                        {...register('father')}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }

                                        <Accordion.Item eventKey="2" style={{ display: hide5 ? 'none' : 'block' }}>
                                            <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={"RG"}>
                                                            <Form.Control type="text" placeholder={"RG"}  {...register('rg')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('issue_agency')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                                            <Form.Control type="date" placeholder={t('formLabel.shippingDate')}  {...register('shipping_date')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                            <Form.Select {...register('sex')} aria-label={t('formLabel.sex')}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                <option value="1">{t('masculine')}</option>
                                                                <option value="2">{t('feminine')}</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.birth')}>
                                                            <Form.Control type="date" placeholder={t('formLabel.birth')}  {...register('birth')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                                            <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} onChange={(e) => handleChangeMaritail(e)}>
                                                                <option value="">{t('formLabel.select')}</option>
                                                                <option value="1">{t("formLabel.married")}</option>
                                                                <option value="2">{t("formLabel.single")}</option>
                                                                <option value="3">{t("formLabel.widower")}</option>
                                                                <option value="4">{t("formLabel.legallySeparated")}</option>
                                                                <option value="5">{t("formLabel.disquitado")}</option>
                                                                <option value="6">{t("formLabel.divorced")}</option>
                                                                <option value="7">{t("formLabel.stableunion")}</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} style={{ display: fieldhide ? 'none' : 'block' }}>
                                                        <FloatingLabel className="mb-3" controlId="Birthplace" label={t('formLabel.Birthplace')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.birth')}  {...register('birth_place')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                            <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('nationality')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    {
                                                        hide7 && showHideField == "cpf" &&
                                                        <Col md={12}>
                                                            <Form.Check type="checkbox" {...register('non_recedential')} label={t('non_recedential')} />
                                                        </Col>
                                                    }
                                                    {
                                                        (hide7 || hide8 || hide10) && showHideField == "cpf" &&
                                                        <Col md={6}>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('dependentsNo')}>
                                                                <Form.Control type="text" placeholder={t('dependentsNo')}  {...register('dependentsNo')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    }
                                                    {
                                                        ((hide8 || hide10) && showHideField == "cpf") &&
                                                        <>
                                                            <Col xs={12} className='mt-1'>
                                                                <Accordion.Body>
                                                                    <Form.Check type="checkbox" {...register('non_recedential')} label={t('non_recedential')} />
                                                                </Accordion.Body>
                                                            </Col>
                                                            <Col md={12} className='mt-1'>
                                                                <Form.Check type="checkbox" {...register('donotnotify')} label={t('donotnotify')} />
                                                            </Col>

                                                            <Col md={6} className='mt-1'>
                                                                <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                                    <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                                        <option value="">{t('formLabel.select')}</option>
                                                                        {managerData?.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                    : ""}
                                {headingText == 'New Tenant' || headingText == 'Novo Locatário' ?
                                    showHideField == "cnpj" &&
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Col md={12}>
                                                <Form.Check type="checkbox" {...register('donotnotify')} label={t('donotnotify')} />
                                            </Col>
                                            <Col md={12}>
                                                <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                    <Form.Select className={`form-control ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                        <option value="">{t('formLabel.select')}</option>
                                                        {managerData?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.manager?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    : ""
                                }

                                {
                                    spouse &&
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>{t('formLabel.spouse')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('spouse_name')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.cpf')}  {...register('spouse_cpf')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                        <Form.Control type="text" placeholder={t('RG')}  {...register('spouse_rg')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('spouse_issue_agency')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.profession')}  {...register('spouse_profession')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                        <Form.Select {...register('spouse_sex')} aria-label={t('formLabel.sex')}>
                                                            <option value="">{t('dropdownItem.select')}..</option>
                                                            <option value="1">{t('masculine')}</option>
                                                            <option value="2">{t('feminine')}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cellPhone')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.number')}  {...register('spouse_cellphone')} onChange={(e) => HandleInputMob(e)} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('spouse_telephone')} onChange={HandleInput} />

                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                        <Form.Control type="email" placeholder={t('formLabel.email')}  {...register('spouse_email')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('spouse_nationality')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.observation')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.observation')}  {...register('spouse_observation')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }

                                <Accordion.Item eventKey="3" style={{ display: hide4 ? hide6 ? 'block' : "none" : hide6 ? 'block' : "none" }}>
                                    <Accordion.Header>{t('formLabel.address')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {hide5 &&
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.country')} >
                                                        <Form.Control type="text" placeholder={t('formLabel.country')}   {...register('country')} />
                                                    </FloatingLabel>
                                                </Col>
                                            }
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                                    <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} maxLength={9} onChange={(e) => getLocation(e, "zipcode", "first")} />
                                                </FloatingLabel>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                {(headingText == 'New Tenant' || headingText == 'Novo Locatário' || headingText == "New Owner" || headingText == "Novo Proprietário") ?
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect345" label={t('formLabel.address')} >
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                                                        >
                                                            <Form.Control type="text" placeholder={t('formLabel.address')} className={`${errors.address ? 'is-invalid' : ''}`}   {...register('address')} onChange={(e) => Handleaddress(e)} />
                                                        </OverlayTrigger>
                                                        <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    :
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect345" label={t('formLabel.address')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} />
                                                    </FloatingLabel>
                                                }
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('number')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('complement')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                                        <option value="">{t('formLabel.select')}</option>
                                                        {
                                                            AllStates.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13" style={{ display: hide5 ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.Retention')} </Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            Object.keys(addState.retentionn).map((item: any, index: number) => {
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col md={5}>
                                                                <FloatingLabel className="mb-3" controlId="contractlistother" label={t('contractlistother.Tax')} >
                                                                    <Form.Select {...register(`retentionn.${index}.Tax`)} onChange={(e) => handleChangeInput(e, index, "retentionn", "Tax")} aria-label={t('contractlistother.Tax')}>
                                                                        <option value="slct">{t("formLabel.select")}</option>
                                                                        <option value="1">{t('IR')}</option>
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FloatingLabel className="mb-3" controlId="Aliquot" label={t('contractsettings.Aliquot')} >
                                                                    <Form.Control type="text" placeholder={t('contractsettings.Aliquot')} {...register(`retentionn.${index}.Aliquot`)} onChange={(e) => handleChangeInput(e, index, "retentionn", "Aliquot")} />
                                                                    {/* className={` ${errors.Aliquot ? 'is-invalid' : ''}`}
                                                            <Form.Control.Feedback type="invalid">{errors.Aliquot?.message}</Form.Control.Feedback> */}
                                                                </FloatingLabel>
                                                            </Col>
                                                            {
                                                                Object.keys(addState.retentionn) &&
                                                                Object.keys(addState.retentionn).length > 1 &&
                                                                <Col md={2}>
                                                                    <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.retentionn, "retentionn")}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </>
                                                )
                                            })
                                        }
                                        {
                                            Object.keys(addState.retentionn) &&
                                            Object.keys(addState.retentionn).length < 5 &&
                                            <Button variant="link" className="fw-bold p-0" onClick={(e) => addAnother(e, "retentionn")}  >
                                                {t("newContractDev.AddAnother")}
                                            </Button>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                {showHideField == "cpf" ?
                                    <Accordion.Item eventKey="4" style={{ display: hide4 ? 'none' : 'block' }}>
                                        <Accordion.Header>{t('formLabel.employmentData')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.company')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.company')}  {...register('employment_company')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.profession')} {...register('employment_profession')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fieldOfActivity')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.fieldOfActivity')}  {...register('employment_field_activity')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')}  {...register('employment_monthly_income')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} onChange={(e) => getLocation(e, "zipcode", "second")} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                        <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                        <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            {
                                                                AllStates.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    : ""}

                                <Accordion.Item eventKey="5" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.representative')}</Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            Object.keys(addState.representative).map((item: any, index: number) => {
                                                return (
                                                    <Row className="mt-1">
                                                        <Col xs={11}>
                                                            <InputGroup>
                                                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                                                    <Form.Select {...register(`representative.${index}.name`)} onClick={handleRepresentative} onChange={(e) => handleRepersen(e, index)}>
                                                                        <option value="">{t('formLabel.select')}</option>
                                                                        {repreData?.map((curElem: any, index) => (
                                                                            <option key={index} value={curElem.id}>{curElem.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                                <InputGroup.Text onClick={newRepresentativeModalShow}>
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Col>
                                                        {/* <Col>
                                                            <span className="input-group-text">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} className="h-20" />
                                                            </span>
                                                        </Col> */}
                                                        {
                                                            Object.keys(addState.representative) &&
                                                            Object.keys(addState.representative).length > 1 &&
                                                            <Col md={1}>
                                                                <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.representative, "representative")}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                )
                                            })
                                        }
                                        < Col >
                                            <div className="text-center mt-1">
                                                <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother(e, "representative")}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.AddAnother')}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {
                                    (hide7 || hide8) && showHideField == "cpf" &&
                                    <Accordion.Item eventKey="14">
                                        <Accordion.Header>{t('Renda')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col xs={12}>
                                                    <Accordion.Body>
                                                        <Form.Check type="checkbox" {...register('possiu')} label={t('possiu')} />
                                                    </Accordion.Body>
                                                </Col>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <FloatingLabel controlId="floatingSelect" label={t('vinculo')}>
                                                            <Form.Select {...register('vinculo')} onClick={handleRepresentative}>
                                                                <option value="">{t('formLabel.select')}</option>
                                                                {

                                                                    i18n.language == "en" ?
                                                                        optionRendaen.map((item: any, index: number) => {
                                                                            return (
                                                                                <option value={item.value}>{item.label}</option>
                                                                            )
                                                                        })
                                                                        :
                                                                        optionRendapt.map((item: any, index: number) => {
                                                                            return (
                                                                                <option value={item.value}>{item.label}</option>
                                                                            )
                                                                        })
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('beneficio')}>
                                                        <Form.Control type="text" placeholder={t('beneficio')}  {...register('beneficio')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('rendimentous')}>
                                                        <Form.Control type="text" placeholder={t('rendimentous')}  {...register('rendimentous')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                }


                                {(hide || hide8) && <Accordion.Item eventKey="6" style={{ display: ((headingText !== "New Guarantor" && headingText !== "Novo Fiador") || (headingText === "New Owner" || headingText === "Novo Proprietário")) ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.taxesRetention')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Check type="checkbox" {...register('retention')} label={t('formLabel.withHoldIssqn')} className='mt-1' />
                                        {
                                            hide8 && showHideField == "cpf" &&
                                            // <Form.Group controlId="simplified" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-lg-4 mb-3">
                                            <Form.Check type="checkbox"  {...register('enableSimplifiedDeduction')} label={t('newContractDev.enableSimplifiedDeduction')} className='mt-1' />
                                            //     <span className="d-block fs-12 text-secondary ms-4 ps-1">{t('newContractDev.enableSimplifiedDeductionContent')}</span>
                                            // </Form.Group>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                }
                                <Accordion.Item eventKey="7" style={{ display: hide5 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11" style={{ display: (hide5 || hide8) ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.Accountingintegration')}</Accordion.Header>
                                    <Accordion.Body>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip-bottom">{t("accountingcontable")}</Tooltip>}
                                        >
                                            <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.accountingsystem')}>
                                                <Form.Control type="text" placeholder={t('formLabel.accountingsystem')} {...register('accounting_system')} />
                                            </FloatingLabel>
                                        </OverlayTrigger>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8" style={{ display: hide5 ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.identifier')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.identifier')}>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="tooltip-bottom">{t("Supplieridentificationcode")}</Tooltip>}
                                            >
                                                <Form.Control type="text" placeholder={t('formLabel.identifier')} {...register('identifier')} />
                                            </OverlayTrigger>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9" style={{ display: headingText !== "New Guarantor" && headingText !== "Novo Fiador" ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.Goodoftheguarantor')}</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            {formData.map((_, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel label={t('newTransfer.typepProperty')}>
                                                                <Form.Select className={`form-control`} {...register(`formData.${index}.Property_Type`)} onChange={(e) => handleInputChange(index, e)}>
                                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                                    <option value="1">{t("propertyTypeData.Casa")}</option>
                                                                    <option value="4">{t("propertyTypeData.Apartamento")}</option>
                                                                    <option value="33">{t("propertyTypeData.ApartamentoDuplex")}</option>
                                                                    <option value="38">{t("propertyTypeData.ApartamentoTriplex")}</option>
                                                                    <option value="9">{t("propertyTypeData.Áreacomum")}</option>
                                                                    <option value="42">{t("propertyTypeData.Andarcorporativo")}</option>
                                                                    <option value="29">{t("propertyTypeData.Bangalo")}</option>
                                                                    <option value="13">{t("propertyTypeData.Barracao")}</option>
                                                                    <option value="40">{t("propertyTypeData.BoxGaragem")}</option>
                                                                    <option value="3">{t("propertyTypeData.Casacomercial")}</option>
                                                                    <option value="2">{t("propertyTypeData.Casaemcondominio")}</option>
                                                                    <option value="44">{t("propertyTypeData.CasaAssobradada")}</option>
                                                                    <option value="5">{t("propertyTypeData.Cobertura")}</option>
                                                                    <option value="14">{t("propertyTypeData.Chacara")}</option>
                                                                    <option value="25">{t("propertyTypeData.Conjunto")}</option>
                                                                    <option value="39">{t("propertyTypeData.Edicula")}</option>
                                                                    <option value="10">{t("propertyTypeData.Escritorio")}</option>
                                                                    <option value="20">{t("propertyTypeData.Fazenda")}</option>
                                                                    <option value="6">{t("propertyTypeData.Flat")}</option>
                                                                    <option value="16">{t("propertyTypeData.Galpao")}</option>
                                                                    <option value="8">{t("propertyTypeData.Garagem")}</option>
                                                                    <option value="43">{t("propertyTypeData.Garden")}</option>
                                                                    <option value="21">{t("propertyTypeData.Haras")}</option>
                                                                    <option value="35">{t("propertyTypeData.Hotel")}</option>
                                                                    <option value="31">{t("propertyTypeData.Ilha")}</option>
                                                                    <option value="7">{t("propertyTypeData.Kitnet")}</option>
                                                                    <option value="27">{t("propertyTypeData.Laje")}</option>
                                                                    <option value="30">{t("propertyTypeData.Loft")}</option>
                                                                    <option value="12">{t("propertyTypeData.Loja")}</option>
                                                                    <option value="41">{t("propertyTypeData.Pavilhao")}</option>
                                                                    <option value="32">{t("propertyTypeData.Penthouse")}</option>
                                                                    <option value="22">{t("propertyTypeData.Predio")}</option>
                                                                    <option value="28">{t("propertyTypeData.Pousada")}</option>
                                                                    <option value="19">{t("propertyTypeData.Ponto")}</option>
                                                                    <option value="18">{t("propertyTypeData.Quiosque")}</option>
                                                                    <option value="36">{t("propertyTypeData.Rancho")}</option>
                                                                    <option value="34">{t("propertyTypeData.Resort")}</option>
                                                                    <option value="15">{t("propertyTypeData.Salao")}</option>
                                                                    <option value="11">{t("propertyTypeData.Salacomercial")}</option>
                                                                    <option value="23">{t("propertyTypeData.Sitio")}</option>
                                                                    <option value="24">{t("propertyTypeData.Sobrado")}</option>
                                                                    <option value="37">{t("propertyTypeData.Studio")}</option>
                                                                    <option value="17">{t("propertyTypeData.Terreno")}</option>
                                                                    <option value="26">{t("propertyTypeData.Village")}</option>
                                                                    <option value="45">{t("propertyTypeData.Consultorio")}</option>
                                                                    <option value="1000">{t("propertyTypeData.Outro")}</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="propertyValue" label={t('newContractDev.propertyValue')}>
                                                                <Form.Control type="text" placeholder={t('newContractDev.propertyValue')} {...register(`formData.${index}.Property_Value`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="zipCode2" label={t('formLabel.zipCode')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register(`formData.${index}.Zip_code`)} onChange={(e) => getLocation(e, "zipcode", "third")} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="address2" label={t('formLabel.address')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.address')}  {...register(`formData.${index}.Address`)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="number2" label={t('formLabel.number')}>
                                                                <Form.Control type="number" placeholder={t('formLabel.number')}  {...register(`formData.${index}.Number`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="complement2" label={t('formLabel.complement')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register(`formData.${index}.Complement`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="neighborhood3" label={t('formLabel.neighborhood')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register(`formData.${index}.Neighborhood`)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="city2" label={t('formLabel.city')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.city')}  {...register(`formData.${index}.City`)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="state12" label={t('formLabel.state')}>
                                                                <Form.Select {...register(`formData.${index}.state_of`)} aria-label={t('formLabel.state')}>
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    {
                                                                        AllStates.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="registry" label={t('formLabel.registry')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.registry')}  {...register(`formData.${index}.Registry`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="registration" label={t('formLabel.registration')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.registration')}  {...register(`formData.${index}.Registration`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="buildingCondominium" label={t('formLabel.buildingCondominium')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.buildingCondominium')}  {...register(`formData.${index}.Building_con`)} onChange={(e) => handleInputChange(index, e)} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>

                                                    {(formData.length > 1) ?
                                                        <Button variant="link" className="p-0 fw-bold" onClick={() => removeInput(index)}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                        : ""}
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant="link" className="p-0 fw-bold" onClick={addInput}>{t("Add Another")}</Button>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Row style={{ display: headingText == "New Guarantor" || headingText == "Novo Fiador" || headingText == "New Tenant" || headingText == "Novo Locatário" ? 'block' : 'none' }}>
                                    <Col xs={4}>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.balanceblocked')}>
                                            <Form.Control type="number" placeholder={t('formLabel.balanceblocked')}  {...register('block_balance')} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Accordion.Item eventKey="10" style={{ display: hide5 ? 'block' : 'none' }}>
                                    <Accordion.Header>{t('formLabel.category')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col xs={10}>
                                                <FloatingLabel className="mb-3" controlId="category" label={t('formLabel.category')}>
                                                    <Form.Select className={`form-control mb-4`}  {...register('category')} >
                                                        {category?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </FloatingLabel>

                                            </Col>
                                        </Row>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {
                                (headingText == "Novo Fornecedor" || headingText == "New Supplier") &&
                                <Form.Group>
                                    <Form.Check type="checkbox"  {...register('supplieradv')} label={t("formLabel.Supplieradv")} />
                                </Form.Group>
                            }
                        </div>
                        <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                            <div className="text-center">
                                <h3 className="mt-3 mb-2">{t("label.registeredSurveyor")}</h3>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={formClose}>
                                {t('button.close')}
                            </Button>
                            {hide && show3 ? '' : <Button variant="outline-primary" onClick={(e) => handlePrevious(e)} className="js-btn-step">
                                {t('button.previous')}
                            </Button>}

                            {!hide3 ?
                                <Button variant="primary" onClick={handleSubmit(hide ? step2 : step3)} className="min-w-auto js-btn-step zipCodeCheck btn-primary-focus-none" >
                                    {t('button.next')}
                                </Button>
                                :
                                loader_on ?
                                    <Button className="btn-width">
                                        <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                    </Button>
                                    :
                                    <Button variant="primary" type="submit" onClick={openErrorbox} className="btn-primary-focus-none">
                                        {t('button.save')}
                                    </Button>
                            }
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {
                customer_type == "Tenant" ?
                    <SendEmails mailText={mailText} mailData={mailData} personId={personId} customer_type="Tenant" page_type="main" />
                    :
                    <SendEmails mailText={mailText} mailData={mailData} personId={personId} customer_type="Owner" page_type="main" />
            }
            {/*---------------Common Form Modal------------*/}
            <RepresentativeService showNewRepresentative={showNewRepresentativeModal} newRepresentativeClose={newRepresentativeModalClose} newRepresentativeShow={newRepresentativeModalShow} />
        </section >
    )
}
export default CustomersAndEmployees;
