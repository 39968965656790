import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { readjustOpen, readjustClose, insertReadjustContract, getAllIndexs, fetch_allFinancialIndexing } from "../../../../redux/slice/expenditureDataSlice";
import { setCheckedId, getReadContractData } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import RedjustmentSimulate from './RedjustmentSimulate';
import { indexForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { formatInput, formatNumberEnglish } from '../../../constants/utils';
import { ClllThis } from '../Readjustment/ReadjustmentIndexes';
import { converter, convertToNumber, formatNumber } from '../../../constants/utils';

const ReadjustmentService = (props: any) => {
  let { allCheckedItem } = props;
  const { indexData, indexStatus, readjustData, readjustStatus,
    newReadjustShow, readjustStatus_based_on_name, readjustData_based_on_name, fetch_allFinancialIndexing_data } = useSelector((state: RootState) => state.expenditureData)
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const { checkedId } = useSelector((state: RootState) => state.contractData)
  const [hide, setHide] = useState(false);
  const [err, setErr] = useState('');
  const [redjustOpen, setrejustOpen] = useState<any>(false);
  const [redjustData, setrejustData] = useState<any>({});
  const readjustClosed = () => {
    setHide(false)
    setErr('')
    setValue('indexId', '')
    setValue('index', '')
    dispatch(readjustClose(false));
    dispatch(setCheckedId([]));
    setDouble(false);
    reset();
  }

  useEffect(() => {
    dispatch(getAllIndexs({ "status": "", "start": 0, "end": 1000 }));
  }, [])

  /*------------Form validation-----------*/

  const indexValidation = Yup.object().shape({
    // index: Yup.string().trim().nullable()
    //   .required(t('Please choose atleast one of them.'))
  });
  let frm = {
    resolver: yupResolver(indexValidation)
  }

  const [redjustIndexData, setRedjustIndexData] = useState<any>([])
  const [readName, setReadName] = useState<any>([]);

  useEffect(() => {
    if (fetch_allFinancialIndexing_data) {
      (async () => {
        let result = await ClllThis(fetch_allFinancialIndexing_data);
        setReadName(result);
      })()
    }
  }, [fetch_allFinancialIndexing_data])

  useEffect(() => {
    if (newReadjustShow) {
      let redjustIndices: any = [];
      if (readName) {
        readName && readName.length > 0 &&
          readName.forEach((item: any, index: number) => {
            let upperCase = (item.slug).toUpperCase();
            let redjust = readjustData_based_on_name?.[`${upperCase}_total`];
            if (redjust && typeof redjust !== "undefined") {
              redjust = redjust.toFixed(2)
            }
            redjustIndices.push({
              "name": item.name,
              "value": redjust ? convertToNumber(redjust) : 0,
              "slug": item.slug
            })
          })
        setRedjustIndexData(redjustIndices);
      } else {
        readjustData.data && readjustData.data.length > 0 &&
          readjustData.data.forEach((item: any, index: number) => {
            let upperCase = (item.slug).toUpperCase();
            let redjust = readjustData_based_on_name?.[`${upperCase}_total`];
            if (redjust && typeof redjust !== "undefined") {
              redjust = redjust.toFixed(2)
            }
            redjustIndices.push({
              "name": item.indexName,
              "value": redjust ? convertToNumber(redjust) : 0,
              "slug": item.slug
            })
          })
        setRedjustIndexData(redjustIndices);
      }
    }
  }, [newReadjustShow, readName, fetch_allFinancialIndexing_data])

  const navigate = useNavigate();
  const simulate = async (e: any) => {
    e.preventDefault();

    let data = {
      contractId: checkedId,
      indexId: getValues('indexId'),
      readjustType: hide ? 1 : 0,
      index: "5",
      checkedItemAlldata: allCheckedItem,
      redjustIndices: redjustIndexData,
      isFixed: getValues("isFixed") ? true : false,
      fixed_value: getValues("fixed_value"),
      fixed_name: "fixed",
      readjustarr: getValues("readjustarr"),
      Readjust_index_name : readName
    }


    setrejustData(data);
    setrejustOpen(true);
    dispatch(readjustClose(false));
    // navigate('/readjustSimulate', { state: data });
  }

  /*------------Submit readjustment form data-----------*/
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<indexForm>(frm);
  const onSubmit = async (data: indexForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    data.contractId = checkedId;
    data["checkedItemAlldata"] = allCheckedItem;
    let response = await dispatch(insertReadjustContract(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title: t("Sweetalert.Success"),
        text: response.payload.message,
        confirmButtonText: "OK",
        timer: 2000
      })
      readjustClosed();
      dispatch(getReadContractData({ contract: "", "start": 0, "end": 10 }));
    }
    else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: response.payload.message,
        // icon:"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }

  /*-------Change the fix or index value---------*/
  const checkIsFixed = (evt) => {
    setHide(false)
    setValue('indexId', '')
    setValue('index', '')
    if (evt.target.checked) {
      setHide(true)
    }
  }

  /*-------Numeric value validation---------*/
  const NumericOnly = (e: any, type: string, index: number, id: any, nameindex: any) => {
    // const reg = /^[0-9]*[.,]?[0-9]*$/;
    const reg = /^-?[0-9]*[.,]?[0-9]*$/;
    let value: any = e.target.value;
    let name: string = e.target.name;

    if (type == "percentage") {
      let indexvalue = -1; // Default index if not found
      for (let i = 0; i < redjustIndexData.length; i++) {
        if (redjustIndexData[i].name === nameindex) {
          indexvalue = i; // Set the index if found
          break; // Stop iterating once found
        }
      }
      redjustIndexData[indexvalue]["value"] = convertToNumber(value)
    }

    // if (value !== '' && reg.test(value)) {
    if (value !== '') {
      if (type == "fixed" && reg.test(value)) {
        activeIndex(id, value);
        let formatInpute = formatInput(value);
        setValue(`fixed_value`, formatNumber(formatInpute));
      } else if (type == "percentage") {
        activeIndex(id, Number(value) > 100 ? 100 : value);
        setValue(`readjustarr.${index}.value`, Number(value) > 100 ? 100 : formatNumber(value));
      }
    } else {
      if (type == "fixed") {
        setValue(`fixed_value`, String(0));
      }
    }
  }

  /*-------Set the active index value---------*/
  const activeIndex = (id, val) => {
    setValue('indexId', id)
    setValue('index', val)
  }

  return (
    <>
      {/* <!-- New Index modal start from here --> */}
      <Modal show={newReadjustShow} onHide={readjustClosed}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value={id} {...register('indexId')} />
          <input type="hidden" value={hide ? 1 : 0} {...register('readjustType')} />
          <input type="hidden" {...register('index')} />
          <Modal.Header closeButton>
            <Modal.Title>{t('readjustContract')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <p className="error">{errors.index?.message}</p>
            <p className="error">{err} </p> */}
            <Row className="mb-lg-4 mb-3 gy-lg-4 gy-3 align-items-center">
              {/* readjustData.data && readjustData.data.length > 0 ?
                  readjustData.data.map((item: any, index: number) => { */}
              {
                !hide &&
                  readName &&
                  readName.length > 0 ?
                  readName.map((item: any, index: number) => {
                    let toCapital: any = (item.slug).toUpperCase();
                    let redjust = readjustData_based_on_name?.[`${toCapital}_total`];
                    // if (redjust && typeof redjust !== "undefined") {
                    //   redjust = formatNumber(redjust);
                    // }
                    // if (redjust && redjust !== "") {
                    if (index == 0) {
                      activeIndex(item.id, redjust)
                    }
                    return (
                      <Col md={6} key={index}>
                        <FloatingLabel label={item.name + " (%)"}>
                          <Form.Control type="text" placeholder={item.name} {...register(`readjustarr.${index}.name`)} defaultValue={(item.slug).toLowerCase()} readOnly hidden />
                          <Form.Control type="text" placeholder={item.name} {...register(`readjustarr.${index}.value`)} defaultValue={redjust ? converter(convertToNumber(redjust.toFixed(2))) : item?.indexValue} onChange={(e) => NumericOnly(e, "percentage", index, item.id, item.name)} />
                        </FloatingLabel>
                      </Col>
                    )
                    // }
                  })

                  : <Col md={12}>
                    <h5>Fixed (R$)</h5>
                    <FloatingLabel controlId="floatingInput" label="Valor">
                      <Form.Control type="text" {...register(`fixed_name`)} defaultValue={"fixed"} readOnly hidden />
                      <Form.Control type="text" {...register("fixed_value")} defaultValue={0} placeholder="0,00" onChange={(e) => NumericOnly(e, "fixed", 0, "", "fixed")} />
                    </FloatingLabel>
                  </Col>
              }

              {/* <Link to={"#"} className="mb-lg-4 mb-3 fw-bold text-nowrap text-center" onClick={simulate} >Simulate</Link> */}
              <Col md={6}>
                <Form.Group>
                  <Form.Check type="checkbox" {...register('isFixed')} onClick={checkIsFixed} label="Use fixed value for readjustment" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <h6 className="fw-bold">Total Selected Contract : {checkedId.length}</h6>
                <p className="mb-0">Marcados para reajustar</p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={readjustClosed}>Close</Button>
            <Button variant="primary" onClick={simulate}>Simulate</Button>
            {/* <Button disabled={double} type="submit" variant="primary">Readjust</Button> */}
          </Modal.Footer>
        </Form>
      </Modal>
      <RedjustmentSimulate redjustOpen={redjustOpen} setrejustOpen={setrejustOpen} redjustData={redjustData} />
      {/* <!-- /New Index modal end --> */}
    </>
  )
}
export default ReadjustmentService;