import React from "react";

import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";
import { z } from "zod";

export const SicoobSchema = z.object({
  ContaCodigoBeneficiario: z.string().min(1, "Campo obrigatório"),
  ConvenioNumero: z.string().min(1, "Campo obrigatório"),
  ConvenioDescricao: z.string().min(1, "Campo obrigatório"),
  ConvenioCarteira: z.string().min(1, "Campo obrigatório"),
  ConvenioPadraoCNAB: z.string().min(1, "Campo obrigatório"),
  carteira_codigo: z.string().optional(),
  ConvenioNumeroContrato: z.string().optional(),
});

type SicoobFormType = z.infer<typeof SicoobSchema>;

export function SicoobFields() {
  const { register } = useFormContext();
  const { errors } = useFormState<SicoobFormType>();

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ContaCodigoBeneficiario"
            label="Código beneficiário"
          >
            <Form.Control
              placeholder="Código beneficiário"
              {...register("ContaCodigoBeneficiario")}
              className={`${errors.ContaCodigoBeneficiario && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ContaCodigoBeneficiario?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioNumero" label="Convênio">
            <Form.Control
              placeholder="Convênio"
              {...register("ConvenioNumero")}
              className={`${errors.ConvenioNumero && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioNumero?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioDescricao"
            label="Descrição do convênio"
          >
            <Form.Control
              placeholder="Descrição do convênio"
              {...register("ConvenioDescricao")}
              className={`${errors.ConvenioDescricao && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioDescricao?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioPadraoCNAB" label="CNAB">
            <Form.Select {...register("ConvenioPadraoCNAB")}>
              <option disabled>CNAB</option>
              <option value="240" defaultChecked>
                240 (Recomendado)
              </option>
              <option value="400">400</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioCarteira" label="Tipo de carteira">
            <Form.Select {...register("ConvenioCarteira")}>
              <option disabled>Tipo de carteira</option>
              <option value="1" defaultChecked>
                1 - Simples Com Registro
              </option>
              <option value="3">3 - Garantida Caucionada</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          {/* TODO mapear campo de acordo com o backend */}
          <FloatingLabel
            controlId="carteira_codigo"
            label="Código da carteira (Opcional)"
          >
            <Form.Control
              placeholder="Código da carteira (Opcional)"
              {...register("carteira_codigo")}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioNumeroContrato"
            label="Número do contrato (Opcional)"
          >
            <Form.Control
              placeholder="Número do contrato (Opcional)"
              {...register("ConvenioNumeroContrato")}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
