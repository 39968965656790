import React from 'react';
import { Badge, Row, Col, Card, Dropdown, Button, Table, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const NewEnvelope = () => {
    const [t] = useTranslation();

    const [show, setShow] = useState(false);
    const handleAttachModalClose = () => setShow(false);
    const handleAttachModalShow = () => setShow(true);
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <Link to={"/contracts"}><img src="assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
                <Card className="mb-lg-4 mb-3">
                    <Card.Body>
                        <Row>
                            <Col lg={8}>
                                <div className="d-flex align-items-center">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/envelop.svg"} alt="Folder" className="h-40 flex-shrink-0" />
                                    <div className="w-100 ms-2 ms-lg-3">
                                        <h1 className="h3 lh-base mb-0">
                                            Envelope 8 - Contract 4/1
                                            <Badge className="ms-1 text-dark bg-warning-10">Pending shipping</Badge>
                                            <Badge className="ms-1 text-dark bg-danger-30">No legal validity</Badge>
                                        </h1>
                                        <p className="mb-0">Apartment in Flores, Ruas Jamim 1000, apartment 13, CEP: 13562-024</p>
                                        <p className="mb-lg-0">Created on: 06/17/2020 . </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <ul
                                    className="list-inline d-sm-flex align-items-center justify-content-start justify-content-lg-end mb-0">
                                    <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                        <Link className="btn btn-light btn-sm" to={'/'} type="button">
                                            Request subscriptions
                                        </Link>
                                    </li>
                                    <li className="list-inline-item mb-sm-0 mb-1">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                                {t('button.action')}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="mh-456 overflow-auto">
                                                <Dropdown.Item href="#">{t('historic')}</Dropdown.Item>
                                                <Dropdown.Item>Cancel envelope</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mb-lg-4 mb-3">
                    <Card.Header>Status of electronic signatures</Card.Header>
                    <Card.Body>
                        <Table responsive className="table-custom table-custom-bordered mb-0">
                            <tbody>
                                <tr>
                                    <td>
                                        <h5><img src={process.env.PUBLIC_URL + "/assets/images/icons/user_g.svg"} alt="user" className="h-16 me-1" /><span className="text-dark">Josefa da Costa</span> josefa@hotmail.com</h5>
                                    </td>
                                    <td>
                                        Locator
                                    </td>
                                    <td>
                                        <Badge className="ms-1 text-dark bg-warning-10">Pending shipping</Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h5><span className="text-dark"><img src={process.env.PUBLIC_URL + "/assets/images/icons/properties.svg"} alt="user" className="h-16 me-1" />Base Training Support LTDA</span></h5>
                                        ingaia@ingaia.com.br
                                    </td>
                                    <td>
                                        Real estate
                                    </td>
                                    <td>
                                        <Badge className="ms-1 text-dark bg-warning-10">Pending shipping</Badge>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card className="mb-lg-4 mb-3">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        Documents
                        <Button variant="link" size="sm" className="fw-bold" onClick={handleAttachModalShow}>Attach document</Button>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive className="table-custom table-custom-bordered mb-0">
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Check type="checkbox" />
                                    </td>
                                    <td>
                                        <img src="assets/images/icons/pdf.png" className="h-40 me-1" alt="PDF" /> Lease agreement
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <p className="text-secondary d-block py-2">Listing 1 document</p>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t("formLabel.mark")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="">
                                            {t("formLabel.all")}
                                        </Dropdown.Item>
                                        <Dropdown.Item href="">
                                            {t("formLabel.none")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <span className="px-1">{t("formLabel.withTagged")}</span>
                            </li>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <Button
                                    variant="light"
                                    size="sm"
                                >
                                    Delete document
                                </Button>
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
            </main>
            {/* <!-- /Main content start end --> */}

            {/* Attach file popup start */}
            <Modal show={show} onHide={handleAttachModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Select the file</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="border-2 border border-dashed py-4 px-2 position-relative text-center rounded-4 mb-lg-4 mb-3">
                            <Form.Control type="file" className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1" />
                            <h4 className="text-primary fw-bold mb-2"><img src="assets/images/icons/upload-file-primary.svg" alt="Upload file" className="cursor-pe me-2" />{t("attachFile")}</h4>
                            <label className="mb-0 form-label fw-normal text-dark"><img src="assets/images/icons/info-dark.svg" alt="Info" className="h-16 cursor-pe me-1" />{t("newTransfer.supportedFormats")}</label>
                        </div>
                        <Card className="border">
                            <Card.Body className="d-flex align-items-center justify-content-between p-2">
                                <div className="w-100 d-flex align-items-center">
                                    <img src="assets/images/icons/pdf.png" className="h-40 me-1 flex-shrink-0" alt="PDF" /> Screen_9.Pdf
                                </div>
                                <img src="assets/images/icons/delete-danger.svg" className="h-18 ms-1" alt="PDF" />
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleAttachModalClose}>
                        {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={handleAttachModalClose}>
                        {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Attach file popup end */}
        </section>
    )
}

export default NewEnvelope
