import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { contractDatails, insertQuote, quoteClose } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { RecChargesFotterForm } from '../../../Interface';
import { incomeService } from '../../../../services/incomeServices';

import Swal from 'sweetalert2';
import '../../../../App.css';
import i18n from '../../../../i18n';

const InvalidateBoletoService = (props,RecChargesFotterForm) => {
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { showInvalidationModal,cancelInvalidateClose,recevId,type } = props;
    
    const [ loader_on,setLoader_on ] = useState(false);
    const [ leaseError,setLeaseError ] = useState("");
    const [ maurityError,setMaurityError ] = useState("");
    const [ regenratedate,setregenratedate ] = useState(false);

    const boletoAction = (evt) => {
        setregenratedate(false);
        if (evt.target.value == 'CancelRegenrateBoleto') {
            setregenratedate(true);
        }
    };

    const options = [
        { value: 0, label_en: "Undue charge", label_pt: "Cobrança indevida" },
        { value: 1, label_en: "Replaced charge", label_pt: "Cobrança substituída" },
        { value: 2, label_en: "Cancellation", label_pt: "Cancelamento" },
        { value: 3, label_en: "Bonus", label_pt: "Bonificação" },
        { value: 6, label_en: "Proposal Cancelled", label_pt: "Proposta Cancelada" },
        { value: 7, label_en: "Provision for Doubtful Debts (PDD)", label_pt: "Provisão para Devedores Duvidosos (PDD)" },
        { value: 4, label_en: "Suspension", label_pt: "Suspensão" },
        { value: 8, label_en: "Negotiation", label_pt: "Negociação" },
        { value: 9, label_en: "Underpayment", label_pt: "Pagamento a menor" },
        { value: 5, label_en: "Others", label_pt: "Outros" }
    ];
    
    const cancelInvalidateClosed = () =>{
        cancelInvalidateClose();
        setDouble(false);
        setLoader_on(false);
    }   
    
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<RecChargesFotterForm>();
    const onSubmit = async (data: RecChargesFotterForm, e: any) => {
        e.preventDefault();
        let invalidObj:any = {
            FL_MOTIVOCANCELAR_RECB       : data.invalidationReason,
            FL_EXCLUIR_DESPESAS_TERCEIRO : data.deleteExpenses ?? false,
        }
        invalidObj.lease_period = "invalidate";
        let continueAllow = 1;
        if(type!=="Cash"){
            if(data.lease_period===null){
                setLeaseError(t("requiredMmessage.fieldRequired"));
                continueAllow = 0;
                invalidObj.lease_period = "";
            }
        }

        if(data.lease_period=="CancelRegenrateBoleto" && data.startDate==''){
            setMaurityError(t("requiredMmessage.fieldRequired"));
            continueAllow = 0;
            setLeaseError("")
        }
        else {
            if(data.lease_period=="CloneBoleto"){
                setMaurityError("");
                setLeaseError("")
                invalidObj.lease_period = data.lease_period;
                continueAllow = 1;
            }
            if(data.lease_period=="CancelRegenrateBoleto" && data.startDate!=''){
                invalidObj.startDate = data.startDate;
                invalidObj.lease_period = data.lease_period;
                setMaurityError("");
                setLeaseError("")
                continueAllow = 1;
            }
            if(data.lease_period=="CancelBoleto"){
                setMaurityError("");
                setLeaseError("")
                invalidObj.lease_period = data.lease_period;
                continueAllow = 1;
            }
        }    

        if(continueAllow){
            setDouble(true);
            setLoader_on(true);
            invalidObj.type = type;
            invalidObj["id_arr"] = recevId;
            let response = await incomeService.Invalidate_charge(invalidObj);
            if(response.resCode===200 && response.data.length){
                Swal.fire({
                    title:t("Sweetalert.Success"),
                    text:t("Sweetalert.statusUpdate"),
                    confirmButtonText:"OK",
                    timer:2000
                })
                cancelInvalidateClosed();
            }
            else {
                cancelInvalidateClosed();
                Swal.fire({
                    title:t("Sweetalert.Error"),
                    text:t("Sweetalert.somethingWent"),
                    confirmButtonText:"OK",
                    timer:2000
                })
            }
        }
    }
    return (
        <>
            {/* <!-- New quote modal start from here --> */}
            <Modal show={showInvalidationModal} onHide={cancelInvalidateClosed}>
                <Form onSubmit={handleSubmit(onSubmit)} id="invalidateForm">
                    <Modal.Header closeButton>
                        <Modal.Title>{ type==="Cash" ? t("ReceiveandReceived.Reasonforinvalidation") : t("CancelRegenrateBoleto") }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <Alert variant="warning" className="mb-lg-4 mb-3">
                            {t("ReceiveandReceived.usetheoptiononly")}.
                        </Alert>                    
                        { 
                            type!=="Cash" ?
                                <Row className="g-3 g-xl-4">
                                    <Col md>
                                        <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                            <Form.Check
                                                type="radio"
                                                {...register("lease_period")}
                                                value="CancelBoleto"
                                                onChange={boletoAction}
                                                label={t("CancelBoleto")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            :   ""
                        }
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="reason_invalidation" label={ type=="Cash" ? t("ReceiveandReceived.Reasonforinvalidation") : t("reasonForBaleto") }>
                            <Form.Select aria-label="Reason for invalidation" className={`form-control ${errors.invalidationReason ? 'is-invalid' : ''}`}  {...register('invalidationReason')} >
                                {
                                    options.map((item: any, index: number) => {
                                        return (
                                            <option key={index} value={item.value}>{item[`label_${i18n.language}`]}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                    <Form.Check
                                        type="radio"
                                        {...register("lease_period")}
                                        value="CancelRegenrateBoleto"
                                        onChange={boletoAction}
                                        label={t("CancelRegenrate")}
                                    />
                                </Form.Group>                                
                            </Col>
                        </Row>
                        {(regenratedate) &&
                            <>
                            <Row className="g-3 g-xl-4" >
                                <Col className="mb-lg-4 mb-3">
                                    <FloatingLabel label={t("maturity")}>
                                        <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <p className="error">
                                { maurityError ? maurityError : "" }
                            </p>
                            </>
                        }
                        <Row className="g-3 g-xl-4">
                            {/*<Col md>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                    <Form.Check
                                        type="radio"
                                        {...register("lease_period")}
                                        value="CloneBoleto"
                                        onChange={boletoAction}
                                        label={t("chargeClone")}
                                    />
                                </Form.Group>
                            </Col>*/}
                            <p className="error">
                                { leaseError ? leaseError : "" }
                            </p>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={cancelInvalidateClosed}>
                            { t("button.close") }
                        </Button>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{ t("save") }</span>
                                </Button>
                            :
                                <>
                                    <Button disabled={double} type="submit" variant="primary">
                                        { t("button.save") }
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /New quote modal end --> */}
        </>
    )
}
export default InvalidateBoletoService;