import styled from "styled-components";

export const ContainerImpersonate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
`;

export const LoginButton = styled.button`
  background-color: #f82e52;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;
