import React, { useEffect, useState } from 'react';
import { Offcanvas, Form, Table, Button, Badge } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import moment from 'moment';
import Loader from '../../../Profile/Company/Loader';
import ElectronicEnvelopeService from '../../Contracts/Common/ElectronicEnvelopeService';
import { newElectronicEnvelopeOpen } from '../../../../redux/slice/contractDataSlice';

const AgreementDetails = (props) => {
    const [t] = useTranslation();
    //Agreement details modal close open function
    let { showAgreementDetails, agreementDetailsClose, currentContract, loadingDetail, setLoadingDetail, singleCharge } = props;
    const [elementVisible, setElementVisible] = useState(false);
    const { nonPaymentExpenseCharge_installment_Data, nonPayment_get_single_rece_expense_Data } = useSelector((state: RootState) => state.nonPayment);
    const [argeementData, setAgreementData] = useState([]);
    const dispatch = useDispatch<AppDispatch>();


    const handleAgreementDetailsClose = () => {
        setAgreementData([]);
        agreementDetailsClose();
        setElementVisible(false);
    }

    const handleElectronicEnvelope = (e: any, contract_id: any) => {
        currentContract.ID_CONTRATO_CON = contract_id
        if (currentContract.ID_CONTRATO_CON && currentContract.ID_CONTRATO_CON !== "") { 
            dispatch(newElectronicEnvelopeOpen(true));
        } else {
            console.log("Cannot Send Electronic Envelope Without Contract Link");
        }
    }

    useEffect(() => {
        if (showAgreementDetails) {
            if (nonPaymentExpenseCharge_installment_Data && nonPaymentExpenseCharge_installment_Data.length > 0) {
                let FetchDta: any = nonPaymentExpenseCharge_installment_Data;
                setAgreementData(FetchDta);
            }
        }
    }, [showAgreementDetails && nonPaymentExpenseCharge_installment_Data])

    return (
        <>
            <Offcanvas className="border-sm-none" show={showAgreementDetails} onHide={handleAgreementDetailsClose} placement="end">
                <Form className="d-flex flex-column h-100">
                    <Offcanvas.Header className="border-bottom w-100" closeButton>
                        <div className="d-sm-flex justify-content-between w-100">
                            {t("NonPayment.agreementDetails")}
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Table responsive="xl" className="table-custom table-custom-bordered mb-0">
                            <thead>
                                <tr>
                                    <th className="pb-2 px-2 pt-0">Cod.</th>
                                    <th className="pb-2 px-2 pt-0">{t("dueDate")}</th>
                                    <th className="pb-2 px-2 pt-0">{t("dropdownItem.status")}</th>
                                    <th className="pb-2 px-2 pt-0">{t("contractlistother.Amount")} (R$)</th>
                                    <th className="pb-2 px-2 pt-0">{t("propertyDev.observations")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadingDetail ?
                                        <tr>
                                            <td className="px-2" colSpan={5} rowSpan={4}>
                                                <Loader />
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {
                                                argeementData && argeementData.length > 0 ?
                                                    argeementData.map((item: any, index: number) => {
                                                        let todayDate = new Date();
                                                        let maturityDate = new Date(`${item?.["DT_DATE_REAM"]?.split('T')[0]}`);
                                                        let Difference_In_Time = maturityDate.getTime() - todayDate.getTime();
                                                        let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
                                                        let MathSign = Math.sign(Difference_In_Days);
                                                        let dayAgo = "";
                                                        let upcomDay = "";

                                                        if (MathSign == -1) {
                                                            dayAgo = String(parseInt(Difference_In_Days)).replace("-", "");
                                                            if (Number(dayAgo) > 30) {
                                                                if ((Math.floor(Number(dayAgo) / 30)) > 12) {
                                                                    dayAgo = String(Math.floor((Number(dayAgo) / 30) / 12)) + " year";
                                                                } else {
                                                                    dayAgo = String(Math.floor(Number(dayAgo) / 30)) + " month";
                                                                }
                                                            } else {
                                                                if (dayAgo == "0") {
                                                                    dayAgo = t("ReceiveandReceived.Today");

                                                                } else {
                                                                    dayAgo = dayAgo + " " + t("formLabel.day");
                                                                }
                                                            }
                                                        } else {
                                                            upcomDay = String(parseInt(Difference_In_Days + 1));
                                                            if (Number(upcomDay) > 30) {
                                                                if ((Math.floor(Number(upcomDay) / 30)) > 12) {
                                                                    upcomDay = String(Math.floor((Number(upcomDay) / 30) / 12)) + " " + "year";
                                                                } else {
                                                                    upcomDay = String(Math.floor(Number(upcomDay) / 30)) + " " + t("TransferPage.month");
                                                                }
                                                            } else {
                                                                // upcomDay = upcomDay + "d " + upcomDay;
                                                                if (upcomDay == "0") {
                                                                    upcomDay = t("ReceiveandReceived.Today");

                                                                } else if (upcomDay == "1") {
                                                                    upcomDay = t("ReceiveandReceived.Tomorrow");
                                                                } else {
                                                                    upcomDay = upcomDay + " " + t("formLabel.day");
                                                                }
                                                            }
                                                        }
                                                        return (
                                                            <tr key={index}>
                                                                <td className="px-2">
                                                                    <span className="d-block text-dark">{item["ID_RECORD_AGREMENT_MAIN_REAM"]}</span>
                                                                </td>
                                                                <td className="px-2">
                                                                    <span className="d-block text-dark">{moment(item["DT_DATE_REAM"]).format("DD/MMM/YYYY")}</span>
                                                                </td>
                                                                <td className="px-2">
                                                                    {item?.["VL_STATUS_REA"] && item?.["VL_STATUS_REA"] == "1" ?
                                                                        <Badge bg="success-10" className="text-dark">{t("settled")}</Badge>
                                                                        :
                                                                        <>
                                                                            {
                                                                                dayAgo !== "" && dayAgo != "Today" ?
                                                                                    <>
                                                                                        <Badge bg="primary-20" className="text-dark">{t("Dashboard.Overdue")}</Badge>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <Badge bg="info-20" className="text-dark">{t("Remain")} {dayAgo == "Today" ? " 0 day" : upcomDay}</Badge>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {/* <span className="d-block fs-12 mt-1">10/aug/2023</span> */}
                                                                </td>
                                                                <td className="text-dark px-2">R$ {item?.["TX_TOTAL_AMOUNT_REAM"] && item["TX_TOTAL_AMOUNT_REAM"] !== "" && Number(item["TX_TOTAL_AMOUNT_REAM"]).toFixed(2)}</td>
                                                                <td className="text-dark px-2">{(item.ST_OBSERVACAOEXTERNA_RECB && item.ST_OBSERVACAOEXTERNA_RECB != "") ? item.ST_OBSERVACAOEXTERNA_RECB : "N/A"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <>
                                                        <tr>
                                                            <td className="px-2" colSpan={5}>
                                                                Agreement data not found
                                                            </td>
                                                        </tr>
                                                    </>
                                            }
                                        </>
                                }
                            </tbody>
                        </Table>
                       <div className="d-block text-primary fw-bold cursor-pe text-center" onClick={() => setElementVisible(!elementVisible)}>{elementVisible ? t("NonPayment.Closeoriginalcharges")  : t("NonPayment.openOriginalCharge")}</div>
                        {elementVisible ? (
                            <div>
                                <h6 className="fw-bold my-4">{t("NonPayment.originalCharges")}</h6>
                                <Table responsive="xl" className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th className="pb-2 px-2 pt-0">Cod.</th>
                                            <th className="pb-2 px-2 pt-0">{t("dueDate")}</th>
                                            <th className="pb-2 px-2 pt-0">{t("contractlistother.Amount")} (R$)</th>
                                            <th className="pb-2 px-2 pt-0">{t("newContractDev.TaxInvoice")}</th>
                                            <th className="pb-2 px-2 pt-0">{t("propertyDev.observations")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            nonPayment_get_single_rece_expense_Data && nonPayment_get_single_rece_expense_Data.length > 0 ?
                                                nonPayment_get_single_rece_expense_Data.map((item: any, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="px-2">
                                                                <span className="d-block text-dark">{item["ID_LANCAMENTO_IMOD"]}</span>
                                                            </td>
                                                            <td className="px-2">
                                                                <span className="d-block text-dark">{moment(item["DT_LANCAMENTO_IMOD"]).format("DD/MMM/YYYY")}</span>
                                                            </td>
                                                            <td className="px-2 text-dark">R$ {item?.["VL_VALOR_IMOD"] ?? 0}</td>
                                                            <td className="text-dark px-2">N/A</td>
                                                            <td className="text-dark px-2">{(item?.["ST_COMPLEMENTO_IMOD"] && item?.["ST_COMPLEMENTO_IMOD"] !== "") ? item?.["ST_COMPLEMENTO_IMOD"] : "N/A"}</td>
                                                        </tr>
                                                    )
                                                }) :
                                                <tr>
                                                    <td colSpan={5} className='text-center align-item-center'>
                                                        No record found
                                                    </td>
                                                </tr>
                                        }

                                    </tbody>
                                </Table>
                            </div>
                        ) : null}
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {
                            singleCharge && singleCharge?.["ID_CONTRATO_CON"] &&
                            <Button variant="outline-primary" onClick={(e) => handleElectronicEnvelope(e, singleCharge?.["ID_CONTRATO_CON"])}>{t("NonPayment.createElectronicEnvelope")}</Button>
                        }
                        {
                            singleCharge && singleCharge.BILLING_TYPE &&
                            <Button variant="primary" onClick={agreementDetailsClose}>{t("NonPayment.sendCharge")}</Button>
                        }
                    </div>
                </Form>
            </Offcanvas>
            {
                singleCharge && singleCharge?.["ID_CONTRATO_CON"] &&
                <ElectronicEnvelopeService currentContract={currentContract} />
            }
            {/* modal start */}
        </>
    )
}

export default AgreementDetails
