import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Card, Form, Button, FloatingLabel } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { MessageService } from '../../../../services/message';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt';
import i18n from '../../../../i18n';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { uploadImage } from '../../../../redux/slice/companyDataSlice';

const Message = () => {
    const [t] = useTranslation();
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm({});
    let navigate = useNavigate();
    const [idNotify, setIdNotify] = useState();
    const [variableData, setVariableData] = useState<any>({});
    const [firstSelection, setFirstSelection] = useState('');
    const [secondSelection, setSecondSelection] = useState('');
    const [thirdSelection, setThirdSelection] = useState('');
    const [selectionData, setSelectionData] = useState<any>([]);
    let [desc, setDesc] = useState('');
    let descriptionData = desc;
    const [templateName, setTemplateName] = useState<any>("");
    const [identifier, setIdentifier] = useState<any>("");
    const [contractVariableData, setContractVariableData] = useState<any>({})
    const [propertyVariableData, setPropertyVariableData] = useState<any>({});
    const location = useLocation();
    const editorRef: any = useRef();
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        let msgid: any = Number(localStorage.getItem('YhGUFY_hygft'));
        setIdNotify(msgid);

        const fetchData = async () => {
            try {
                const response = await customerService.getTemplates();
                const variableResult = response.data.data[1];
                setVariableData(variableResult);
                const propertyVariableResult = response.data.data[2];
                const ownerVariableResult = response.data.data[3];
                const tenantVariableResult = response.data.data[4];
                setContractVariableData(variableResult)
                setPropertyVariableData(propertyVariableResult);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }; 
        const getAllElectronicEnvelopeTemplateWithIdentifierAndFolder = async () => {
            const response = await customerService.getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
            setSelectionData(response.data.data)
        }
        fetchData();
        getAllElectronicEnvelopeTemplateWithIdentifierAndFolder(); 
    }, [])

    useEffect(() => {
        if (idNotify) {
            (async () => {
                let response = await MessageService.getSingleMessage(idNotify);
                    if ((response.data).length > 0) { 
                        setTimeout(() => { 
                            let fielddata = {
                                title: response?.data[0]["ST_TITULO_MOH"],
                                variable: response?.data[0]["ID_TEMPLATE_TEH"] || "",
                            }
                            if (response?.data[0]["ST_TEXTO_MOH"]?.type == "Buffer") {
                                let data = response?.data[0]["ST_TEXTO_MOH"]?.data;
                                let utf8decoder = new TextDecoder();
                                let u8arr = new Uint8Array(data);
                                let changeIntoString = utf8decoder.decode(u8arr);
                                descriptionData = changeIntoString;
                                setDesc(changeIntoString);
                            } else {
                                descriptionData = response?.data[0]["ST_TEXTO_MOH"];
                                setDesc(descriptionData);
                            }
                            let fields: string[] = Object.keys(fielddata);
                            fields.forEach((field: any) => setValue(field, fielddata?.[field]));
                        }, 2000);
                    } 
            })()
        }
    }, [idNotify])

    const saveCustomTemplate = async (data) => {
        setDesc(descriptionData)
        if (descriptionData !== "") {
            let ST_NOME_MOH = document.querySelector('#root')?.getAttribute('st_nome_moh');
            let ST_LABEL_MOH = document.querySelector('#root')?.getAttribute('st_label_moh');
            data.description = descriptionData;
            let newobj = {
                "ID_MODELO_MOH": idNotify,
                "ST_NOME_MOH": ST_NOME_MOH || "",
                "ST_TITULO_MOH": data.title,
                "ST_SUBTITULO_MOH": "",
                "ST_TEXTO_MOH": descriptionData,
                "ST_LABEL_MOH": ST_LABEL_MOH || "",
                "ID_TEMPLATE_TEH": null,
                "ST_IDENTIFICADOR_MOH": identifier,
            }
            let response = await MessageService.UpdateSingleMessage(newobj);
            if (response.type == "success" || response.type == "Success") {
                Swal.fire({
                    position: 'center', 
                    title: t("Sweetalert.Update"),
                    text: t("Sweetalert.updatesuccess"),
                    showConfirmButton: false,
                    timer: 2000
                })
                if (location.state && location.state.notification == "notification") {
                    navigate("/notifications");
                }
                else {
                    navigate("/billing_rule");
                }
            } else {
                Swal.fire({
                    position: 'center', 
                    title: t("Sweetalert.Update"),
                    text: t("Sweetalert.somethingWent"),
                    showConfirmButton: false,
                    timer: 2000
                })
            };
        } else {
            Swal.fire({
                position: 'center', 
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const jsonData = selectionData;
    // Extracting unique values for first, second, and third dropdowns
    const firstOptions: any = Array.from(new Set(jsonData.map(item => item.ST_NOME_TEH)));
    const secondOptionsData: any = {};
    const thirdOptionsData: any = {};
    jsonData.forEach(item => {
        secondOptionsData[item.ST_NOME_TEH] = Array.from(new Set(jsonData.filter(data => data.ST_NOME_TEH === item.ST_NOME_TEH).map(data => ({
            value: data.ID_MODELO_MOH,
            label: data.ST_NOME_MOH,
            templateData: data.ST_TEXTO_MOH,
            templateIdentifier: data.ST_IDENTIFICADOR_MOH
        }))));
        thirdOptionsData[item.ID_MODELO_MOH] = Array.from(new Set(jsonData.filter(data => data.ID_MODELO_MOH === item.ID_MODELO_MOH).map(data => ({
            value: data.ST_IDENTIFICADOR_MOH,
            label: data.ST_NOME_MOH
        }))));
    });

    // const firstOptions: any = Array.from(new Set(jsonData.map(item => item.ST_IDENTIFICADOR_MOH)));
    // const secondOptionsData: any = {};
    // const thirdOptionsData: any = {};

    // jsonData.forEach(item => {
    //     secondOptionsData[item.ST_IDENTIFICADOR_MOH] = Array.from(new Set(jsonData.filter(data => data.ST_IDENTIFICADOR_MOH === item.ST_IDENTIFICADOR_MOH).map(data => data.ST_NOME_TEH)));
    //     thirdOptionsData[item.ST_NOME_TEH] = Array.from(new Set(jsonData.filter(data => data.ST_NOME_TEH === item.ST_NOME_TEH).map(data => ({
    //         value: data.ID_MODELO_MOH,
    //         label: data.ST_NOME_MOH
    //     }))));
    // });

    // Function to handle change in the first dropdown
    const handleFirstChange = (event) => {
        const selectedValue = event.target.value;
        setFirstSelection(selectedValue);
        // Clear selections in second and third dropdowns
        setSecondSelection('');
        setThirdSelection('');
    };

    // Function to handle change in the second dropdown
    const handleSecondChange = (event, optionsData) => {
        const selectedValue = event.target.value;
        let templateData = optionsData;
        let obj = templateData.find(obj => obj.value == selectedValue);
        setIdentifier(obj.templateIdentifier);
        setTemplateName(obj.label)
        setDesc(obj.templateData);
        setSecondSelection(selectedValue);
        setThirdSelection('');
    };

    // Function to handle change in the third dropdown
    const handleThirdChange = (event) => {
        const selectedValue = event.target.value;
        setThirdSelection(selectedValue);
    };

    const handleEditorChange = (event: any, editor: ClassicEditor) => {
        const data = editor.getData();
        setDesc(data);
        setValue("description", desc);
    };

    const handleEditorReady = (editor: any) => {
        const data = editor.getData();
        setDesc(data);
        setValue("description", desc);
        uploadPlugin(editor);
    }

    const customUploadAdapter = (loader) => {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file
                        .then(async(file) => {
                            data.append('image', file);
                            const res = await dispatch(uploadImage(data))
                            if(res.payload.resCode === 202){
                                resolve({
                                    default: res.payload.data.url
                                });
                            }
                        });
                });
            },
        };
    };

    const uploadPlugin = (editor) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        };
    };

    return (
        <section className="content-wrapper template-message-mt">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <h1 className="mb-0 lh-base text-truncate">{t('SysTemplate')}</h1>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-0">
                            <ul className="list-inline mb-0"></ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Form onSubmit={handleSubmit(saveCustomTemplate)} className="d-flex row g-0 justify-content-center ">
                    <Card className="col-sm-9">
                        <Card.Body>
                            {/* <Col md={6}>
                                <FloatingLabel className="mb-lg-4 mb-3" controlId="title" label="Title">
                                    <Form.Control type="text" placeholder="Title" {...register('title')} />
                                </FloatingLabel>
                            </Col> */}

                            {
                                jsonData && jsonData.length > 0 ?
                                    <>
                                        <Row className="gy-3 gy-lg-4 messagetemplate-wt">

                                            {/* <Col className='mb-2'>
                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                    <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                        onChange={(e) => {
                                                            const selectedVariable = e.target.value;
                                                            const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                        }}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        {variableData && Object.keys(variableData).length > 0
                                                            ? Object.keys(variableData).map(key => (
                                                                <option key={key} value={variableData[key]}>
                                                                    {key}
                                                                </option>
                                                            ))
                                                            : <option disabled>{t("dimob.NoDataFound")}</option>
                                                        }
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col> */}

                                            <Col md={6}>
                                                {/* First dropdown */}
                                                <FloatingLabel controlId="firstSelect" label={t('selectFolder')}>
                                                    <Form.Select className={`form-control ${errors.firstSelectionOption ? 'is-invalid' : ''}`} {...register('firstSelectionOption')} aria-label="firstSelectionOption" value={firstSelection} onChange={handleFirstChange} >
                                                        <option value="">{t('select')}</option>
                                                        {firstOptions.map((option, index) => (
                                                            <option key={index} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{(errors.firstSelectionOption?.message as string)}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                            <Col md={6}>
                                                {/* Second dropdown */}
                                                {firstSelection && (
                                                    <FloatingLabel controlId="secondSelect" label={t('selectTemplate')}>
                                                        <Form.Select className={`form-control ${errors.secondSelectionOption ? 'is-invalid' : ''}`} {...register('secondSelectionOption')} aria-label="secondSelectionOption" value={secondSelection} onChange={(e) => { handleSecondChange(e, secondOptionsData[firstSelection]) }}>
                                                            <option value="">{t('select')}</option>
                                                            {secondOptionsData[firstSelection].map((option, index) => (
                                                                <option key={index} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{(errors.secondSelectionOption?.message as string)}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                )}
                                            </Col>

                                            {/* <Col md={6}>
                                                            {secondSelection && (
                                                                <FloatingLabel controlId="thirdSelect" label="Third Dropdown">
                                                                    <Form.Select className={`form-control ${errors.templateSelection ? 'is-invalid' : ''}`} {...register('templateSelection')} aria-label="templateSelection" value={thirdSelection} onChange={handleThirdChange}>
                                                                        <option value="">Select</option>
                                                                        {thirdOptionsData[secondSelection] && thirdOptionsData[secondSelection].map((option, index) => (
                                                                            <option key={index} value={option.value}>
                                                                                {option.value}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">{(errors.templateSelection?.message as string)}</Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            )}
                                                        </Col> */}

                                            <Col md={6}>
                                                {/* Third dropdown */}
                                                {identifier && (identifier == "Contract") ?
                                                    <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                        <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                            onChange={(e) => {
                                                                let selectedVariable = e.target.value;
                                                                selectedVariable = selectedVariable.toLowerCase()
                                                                if (editorRef.current) {
                                                                    const editor = editorRef.current.editor;
                                                                    editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                }
                                                            }}>
                                                            <option value="">{t('dropdownItem.select')}</option>
                                                            {contractVariableData && Object.keys(contractVariableData).length > 0
                                                                ? Object.keys(contractVariableData).map(key => (
                                                                    <option key={key} value={contractVariableData[key]}>
                                                                        {key}
                                                                    </option>
                                                                ))
                                                                : <option disabled>{t("dimob.NoDataFound")}</option>
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    :
                                                    ""}

                                                {identifier && (identifier == "Property") ?
                                                    <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                        <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                            onChange={(e) => {

                                                                let selectedVariable = e.target.value;
                                                            selectedVariable = selectedVariable.toLowerCase()
                                                            if (editorRef.current) {
                                                                const editor = editorRef.current.editor;
                                                                editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                            }
                                                                
                                                            }}>
                                                            <option value="">{t('dropdownItem.select')}</option>
                                                            {propertyVariableData && Object.keys(propertyVariableData).length > 0
                                                                ? Object.keys(propertyVariableData).map(key => (
                                                                    <option key={key} value={propertyVariableData[key]}>
                                                                        {key}
                                                                    </option>
                                                                ))
                                                                : <option disabled>{t("dimob.NoDataFound")}</option>
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    :
                                                    ""}

                                            </Col>

                                            <Col md={12} className="message_ckeditor">
                                                <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    language: {
                                                        ui: i18n.language, // UI language
                                                        content: i18n.language, // Content language
                                                    },
                                                }}
                                                data={desc}
                                                onReady={handleEditorReady}
                                                onChange={handleEditorChange}
                                                ref={editorRef}
                                                onBlur={handleEditorChange}
                                                onFocus={handleEditorChange}
                                                />
                                            </Col>
                                            <div className="mt-lg-4 mt-3 text-end">
                                                <Button variant="outline-primary" onClick={() => {
                                                    if (location.state && location.state.notification == "notification") {
                                                        navigate("/notifications");

                                                    } else {
                                                        navigate("/billing_rule")
                                                    }
                                                }
                                                }>{t('button.cancel')}</Button>
                                                <Button variant="primary" className="ms-xl-3 ms-xxl-4 ms-2" type='submit'>{t('button.save')}</Button>
                                            </div>


                                        </Row>
                                    </>
                                    :
                                    <Row>
                                        <Col className='mb-2'>
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                    onChange={(e) => {
                                                        let selectedVariable = e.target.value;
                                                            selectedVariable = selectedVariable.toLowerCase()
                                                            if (editorRef.current) {
                                                                const editor = editorRef.current.editor;
                                                                editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                            }
                                                        // const selectedVariable = e.target.value;
                                                        // const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                    }}>
                                                    <option value="">{t('dropdownItem.select')}</option>
                                                    {variableData && Object.keys(variableData).length > 0
                                                        ? Object.keys(variableData).map(key => (
                                                            <option key={key} value={variableData[key]}>
                                                                {key}
                                                            </option>
                                                        ))
                                                        : <option disabled>{t("dimob.NoDataFound")}</option>
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>

                                        <Col md={12} className="message_ckeditor">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={desc}
                                                onReady={handleEditorReady}
                                                onChange={handleEditorChange}
                                                ref={editorRef}
                                                onBlur={handleEditorChange}
                                                onFocus={handleEditorChange}
                                            />
                                        </Col>
                                        <div className="mt-lg-4 mt-3 text-end">
                                            <Button variant="outline-primary" onClick={() => {
                                                if (location.state && location.state.notification == "notification") {
                                                    navigate("/notifications");

                                                } else {
                                                    navigate("/billing_rule")
                                                }
                                            }
                                            }>{t('button.cancel')}</Button>
                                            <Button variant="primary" className="ms-xl-3 ms-xxl-4 ms-2" type='submit'>{t('button.save')}</Button>
                                        </div>
                                    </Row>
                            }
                        </Card.Body>
                    </Card>
                </Form>
            </main>
            {/*<!-- /Main content end -->*/}
        </section>
    )
}

export default Message
