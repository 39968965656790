import React, { useState } from 'react'
import { Button, FloatingLabel, Form, InputGroup, Modal, Offcanvas } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const SettingInvoice = (props) => {
 const [t] = useTranslation();
    const schema = yup.object().shape({
    });
     const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors, watch } = useForm<any>({
            resolver: yupResolver(schema),
        });
    let {showSetting, setShowSetting} = props
    const handleClose = () => setShowSetting(false);
 const formSubmit = async (data: any) => {
      console.log(data, "data")
    }
  return (
    <>
           <Offcanvas show={showSetting} onHide={handleClose} placement='end' className="border-start-0">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(formSubmit)}>
                    <Offcanvas.Header closeButton className="border-bottom">
                        <h4 className='fw-bold m-0'>{t("contractsettings.Configuration")}</h4>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <span className="d-block mb-3 fw-bold text-dark">{t("Invoices.NFEService")}</span>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-1" {...register("allow_expense_posting_on_pending_and_paused_contracts")} label={t("contractsettings.ExpensePausedContracts")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-2" {...register("contract_expense_termination")} label={t("contractsettings.ExpenseTerminatedContracts")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-3" {...register("contract_status_active")} label={t("contractsettings.ContractsActive")} />
                        <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('newTransfer.charges')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-5" {...register('rental_period_with_fixed_month')} label={t("contractsettings.RentalPeriodClosedMonth")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-6" {...register("disp_contract_data_invoice")} label={t("contractsettings.DoNotDisplayContractData")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-7" {...register("display_rental_period")} label={t("contractsettings.DoNotDisplayRentalPeriod")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-8" {...register("contract_termination_extracharge")} label={t("contractsettings.AllowExtraCharge")} />
                        <Form.Check type="checkbox" id="cb-9" {...register("use_contract_occupancy_date_to_calculate_1st_prorated_rent")} label={t("contractsettings.CalculateFirstProportional")} />
                        <span className='d-block fs-12 text-secondary fw-normal mt-1'>{t("contractsettings.UncheckingThisOptio")}</span>
                        <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('contractsettings.Adjustments')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-10" {...register("do_not_readjust_negative_indexes")} label={t("contractsettings.DoNotReadjustNegative")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>

                        <Form.Check type="checkbox" id="cb-11" label={t("contractsettings.AdjustRentConsidering")} />
                        <span className='d-block fs-12 text-secondary fw-normal mt-1'>{t("contractsettings.UncheckingThisOptio")}</span>
                        <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('contractsettings.IncomeTaxWithheld')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-12" {...register("contrato_ir_auto")} label={t("contractsettings.automaticallymarkcontractforIR")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-13" {...register("contrato_dimob_auto")} label={t("contractsettings.MarkContractForDIMOB")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-14" {...register("ir_desconto_simplificado_loc")} label={t("contractsettings.SimplifiedDiscount")} />
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-15" {...register("ir_deduzirdescounto")} label={t("contractsettings.DeductPunctualityDiscount")} />
                        <Form.Check type="checkbox" id="cb-16" {...register("ir_deduzirtxadm")} label={t("contractsettings.DeductAdministrationFee")} />
                        <span className='d-block fs-12 text-secondary fw-normal mt-1'>{t("contractsettings.SelectingThisOption")}</span>
                        <hr className="my-lg-4 my-3" />

                        <span className="d-block mb-3 fw-bold text-dark">{t('Terminationfine')}</span>
                        <FloatingLabel controlId="standard_credit" label={t('contractsettings.Standardcredit')} className="mb-lg-4 mb-3">
                            <Form.Select aria-label={t('contractsettings.Standardcredit')}  {...register("standard_credit")}>
                                <option value="3">{t("realState")}</option>
                                <option value="1">{t("Dashboard.Owner")}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <InputGroup className="mb-lg-4 mb-3">
                            <InputGroup.Text className='border-start-0'>
                                <img src="./assets/images/icons/search.svg" alt="search icon" className="h-16 cursor-pe" /></InputGroup.Text>
                        </InputGroup>
                        <FloatingLabel controlId="fine_calculation" label={t('contractsettings.Finecalculation')}>
                            <Form.Select aria-label={t('contractsettings.Finecalculation')}  {...register("fine_calculation")}>
                                <option value="meses">{t("inMonths")}</option>
                                <option value="dias">{t("inDays")}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={handleClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t('button.toSave')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
    </>
  )
}

export default SettingInvoice