import {
  Nav,
  Navbar,
  Button,
  NavDropdown,
  Container,
  Collapse,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Outlet, Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Languages } from "../constants/index";
import "../App.css";
import { commonService } from "../services/commonService";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { encrypt_Decrypt, encrypt_Decrypt_Plan } from "./constants/utils";
import { config } from "../services/apiConfig";
import Cookies from "universal-cookie";
import 'moment/min/locales'
import moment from "moment";
import momentTZ from "moment-timezone";
const MainNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  // Company submenu collapse initiation
  const [open, setOpen] = useState(false);
  // History submenu collapse initiation
  const [open2, setOpen2] = useState(false);
  const [agencyIns, setAgencyIns] = useState<any>("Ke");
  const [agencyId, setAgencyId] = useState<any>();
  const [userRole, setUserRole] = useState<number>(0);
  const [maskUser, setMaskUser] = useState<any>();
  const [defaultLang, setDefaultLang] = useState('')
  const locales = {
    en: 'en',
    pt: 'pt-br'
  }
  const handleClick = (e: any, type: string) => {
    e.preventDefault();
    if (type == "company") {
      document.querySelector(".company ul")?.classList.remove("show");
      setOpen(false);
    }
    let url = e.target.getAttribute("href");
    window.location.href = url;
    // window.open(url, '_blank');
  };
  const [userJson, setUserJson] = useState<any>([]);
  useEffect(() => {
    // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // let backCola: any = document.querySelector(".backGroundRandomColor");
    // backCola["style"]["backgroundColor"] = "#" + randomColor

    // let getData: any = localStorage.getItem("userData");
    // let parseData = JSON.parse(getData);
    // backCola["innerHTML"] = parseData["agencyInitials"];

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const clkaction = params.get("action");
    if (clkaction == "logout") {
      clearAllSessions();
    }
  }, [userJson]);
  useEffect(() => {
    let lang_code = localStorage.getItem("language") ? localStorage.getItem("language") : "pt";
    if (lang_code) {
      i18n.changeLanguage(lang_code);
      setDefaultLang(lang_code)
    }
  }, [])
  /*--------Harjinder----------*/
  const onChangeLanguages = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    moment.locale(locales[lang_code]);
    momentTZ.defineLocale(locales[lang_code], (moment.localeData() as any)?._config);
    momentTZ.locale(locales[lang_code]);

    i18n.changeLanguage(lang_code);
    localStorage.setItem("language", lang_code)
    setDefaultLang(lang_code)
  };

  const handleLogout = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Logout",
      text: "Are you sure want to logout? ",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        clearAllSessions();
      }
    });
  };

  const clearAllSessions = () => {
    localStorage.clear();
    localStorage.removeItem("userData");
    localStorage.removeItem("permissionData");
    localStorage.removeItem("role");
    localStorage.removeItem("loglevel");
    localStorage.removeItem("tokenV2");
    localStorage.removeItem("reportData");
    localStorage.removeItem("inspectionData");

    localStorage.removeItem("language")
    const cookies = new Cookies();
    let CUSTOMERAPI = config.CUSTOMER_API;
    let typeServer = "prod";
    if (CUSTOMERAPI.indexOf("dev") >= 0) {
      typeServer = "dev";
    }
    if (CUSTOMERAPI.indexOf("stg") >= 0) {
      typeServer = "staging";
    }
    cookies.set(`${typeServer}_logoutMe`, "1", { path: "/" });
    cookies.remove(`${typeServer}_mainSite`);
    cookies.remove(`${typeServer}_userData`);
    cookies.remove(`${typeServer}_permissionData`);
    cookies.remove(`${typeServer}_tokenV2`);
    cookies.remove(`${typeServer}_agencyInitials`);
    cookies.remove(`${typeServer}_role`);
    cookies.remove(`${typeServer}_agencyId`);
    navigate("/");
  };

  useEffect(() => {
    setAgencyId(localStorage.getItem("agencyId"));
    setAgencyIns(localStorage.getItem("agencyInitials"));
    if (localStorage.getItem("userData")) {
      let AgencyIns = localStorage.getItem("userData");
      if (AgencyIns) {
        try {
          let AgencyInsParse = JSON.parse(AgencyIns);
          let AgencyId = AgencyInsParse?.["agencyId"];
          setUserRole(AgencyInsParse.role);
          //setAgencyId(AgencyId)
          let AgencyInsName = AgencyInsParse
            ? AgencyInsParse["agencyInitials"]
            : "Ke";
          //setAgencyIns(AgencyInsName);

          let userDataa = JSON.stringify(AgencyInsParse);
          let permissionDataa = localStorage.getItem("permissionData");
          let tokenV2 = localStorage.getItem("tokenV2");
          let agencyInitials = localStorage.getItem("agencyInitials");
          let role = localStorage.getItem("role");
          let agencyId = localStorage.getItem("agencyId");
          let userEmailId: any = localStorage.getItem("userEmailId");
          if (userEmailId) {
            setMaskUser(userEmailId);
          } else {
            setMaskUser("")
          }
          const cookies = new Cookies();
          let CUSTOMERAPI = config.CUSTOMER_API;
          let typeServer = "prod";
          if (CUSTOMERAPI.indexOf("dev") >= 0) {
            typeServer = "dev";
          }
          if (CUSTOMERAPI.indexOf("stg") >= 0) {
            typeServer = "staging";
          }
          let siteUrls = window.location.href;
          cookies.set(`${typeServer}_logoutMe`, "0", { path: "/" });
          cookies.set(`${typeServer}_mainSite`, siteUrls, { path: "/" });
          cookies.set(`${typeServer}_userData`, userDataa, { path: "/" });
          cookies.set(`${typeServer}_permissionData`, permissionDataa, {
            path: "/",
          });
          cookies.set(`${typeServer}_tokenV2`, tokenV2, { path: "/" });
          cookies.set(`${typeServer}_agencyInitials`, agencyInitials, {
            path: "/",
          });
          cookies.set(`${typeServer}_role`, role, { path: "/" });
          cookies.set(`${typeServer}_agencyId`, agencyId, { path: "/" });
        } catch (error) {
          console.error("Failed to parse userData:", error);
        }
      }
    }
    let userResult = encrypt_Decrypt();

    if (userResult) {
      setUserJson(userResult);
    } else {
      Swal.fire({
        // icon: 'success','error',
        title: "Please Login",
        text: "Login to access pages",
        confirmButtonText: "Cancel",
        timer: 3000,
      });
      navigate("/");
    }
  }, []);

  //   const handleClick = (e: any) => {
  //     let url = e.target.getAttribute("href");
  //     window.open(url, '_blank');
  // }

  const [userPlanString, setUserPlanString] = useState("");
  useEffect(() => {
    let userPLAN = encrypt_Decrypt_Plan();
    // setUserPlanString(userPLAN)
    setUserPlanString("300")
    // if (location.pathname !== "/dashboard") {
    //   if (
    //     userPLAN === "" ||
    //     userPLAN === undefined ||
    //     userPLAN === "undefined" ||
    //     userPLAN == null ||
    //     userPLAN == "null" ||
    //     !userPLAN
    //   ) {
    //     navigate("/");
    //   }
    // }
  }, [location]);

  return (
    <>
      <header className="main-header bg-white fixed-top">
        <Navbar expand="xl" bg="white" className="h-100">
          <Container fluid className="px-xl-5 px-md-4 px-3">
            <Navbar.Toggle
              className="position-absolute top-0 start-0 p-0 mt-3 ms-2 ms-md-4 border-0"
              aria-controls="headerNav"
            ></Navbar.Toggle>
            <Link onClick={(e) => handleClick(e, "other")} className="navbar-brand ps-5 ps-xl-0 me-2" to={"/dashboard"}>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
                className="header-logo"
                alt="Logo"
              />
            </Link>
            <Navbar.Collapse className="z-index-1 p-3 p-xl-0" id="headerNav">
              <Nav className="mx-auto">
                <li className="nav-item me-lg-3 me-xl-6">
                  <NavLink onClick={(e) => handleClick(e, "other")} className="nav-link px-xl-0" to={"/dashboard"}>
                    {t("Links.dashboard")}
                  </NavLink>
                </li>
                <li className="nav-item dropdown me-lg-3 me-xl-6">
                  <Link
                    className="nav-link px-xl-0 dropdown-toggle"
                    to={"/dashboard"}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("Links.contracts")}
                  </Link>
                  <ul className="dropdown-menu">
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(2030) !== -1 &&
                      <li>
                        <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/contracts"}>
                          {t("Links.contracts")}
                        </Link>
                      </li>
                    }
                    {/* {userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" && ( */}
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(2070) !== -1 &&
                      <li>
                        <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/properties"}>
                          {t("Links.properties")}
                        </Link>
                      </li>
                    }
                    {/* )} */}
                    <li>
                      <Link onClick={(e) => handleClick(e, "other")}
                        className="dropdown-item"
                        to={"/readjustment_indexes"}
                      >
                        {t("Links.readjustmentIndexes")}
                      </Link>
                    </li>
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(3070) !== -1 &&
                      userPlanString !== "100" && userPlanString !== "50" && (
                        <li>
                          {/* <Link
                              //   className="dropdown-item"
                              //   to={
                              //     config.REPORTS_SERVER_URL +
                              //     "/dashboard?page=inspection"
                              //   }
                              // > */}
                          <Link
                            className="nav-link px-4 py-2 text-reset"
                            onClick={(e) => handleClick(e, "company")}
                            to={`${config.REPORTS_SERVER_URL}/${localStorage.getItem("inspectionData")}`}
                          >
                            {t("Links.Inspections")}
                          </Link>
                        </li>
                      )}
                    {/* {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(3090) !== -1 &&
                      userPlanString !== "100" && userPlanString !== "50" && ( */}
                    <li>
                      <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={`${config.REPORTS_SERVER_URL}/requests`} >
                        {t("Links.Requests")}
                      </Link>
                    </li>
                    {/* )} */}
                    <li>
                      <Link onClick={(e) => handleClick(e, "other")}
                        className="dropdown-item"
                        to={"/customer_and_employees"}
                      >
                        {t("Links.customersAndEmployees")}
                      </Link>
                    </li>
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(1090) !== -1 &&
                      <li>
                        {/* {userPlanString !== "100" && userPlanString !== "50" && ( */}
                        <Link onClick={(e) => handleClick(e, "other")}
                          className="dropdown-item"
                          to={"/documents_list"}
                        >
                          {t("Documentlist")}
                        </Link>
                        {/* )} */}
                      </li>
                    }
                  </ul >
                </li >
                <li className="nav-item dropdown me-lg-3 me-xl-6">
                  <Link 
                    className="nav-link px-xl-0 dropdown-toggle"
                    to={"/dashboard"}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("Links.income")}
                  </Link>
                  <ul className="dropdown-menu me-xl-6">
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(3020) !== -1 && (
                        <li>
                          <Link onClick={(e) => handleClick(e, "other")}
                            className="dropdown-item"
                            to={"/generate_charges"}
                          >
                            {t("Links.generateCharges")}
                          </Link>
                        </li>
                      )}
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(3030) !== -1 && (
                        <li>
                          <Link onClick={(e) => handleClick(e, "other")}
                            className="dropdown-item"
                            to={"/receivable_charges"}
                          >
                            {t("Links.toReceive")}
                          </Link>
                        </li>
                      )}
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(3030) !== -1 && (
                        <li>
                          <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/received"}>
                            {t("Links.received")}
                          </Link>
                        </li>
                      )}
                    <li>
                      <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/non_payment"}>
                        {t("Reports.Nonpayment")}
                      </Link>
                    </li>
                    {
                      userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" &&
                      <li>
                        <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/charge_invoices"}>
                          {t("Links.invoices")}
                        </Link>
                      </li>
                    }
                    {/*<li><Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={'/bank_return'}>{t('Links.bankReturn')}</Link></li>*/}

                    {/* <li><Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={'/charge_invoices'}>{t('Links.invoices')}</Link></li> */}
                    {/*<li><Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={'/bank_return'}>{t('Links.bankReturn')}</Link></li>*/}

                    {/*<li><Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={'/delinquency'}>{t('Links.delinquency')}</Link></li>*/}
                    {/*<li><Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={'/agreements'}>{t('Links.agreements')}</Link></li>*/}
                    {userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(1040) !== -1 &&
                      <li>
                        <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/billing_rule"}>
                          {t("Links.billingRule")}
                        </Link>
                      </li>
                    }
                  </ul>
                </li>

                <li className="nav-item dropdown me-lg-3 me-xl-6">
                  <Link
                    className="nav-link px-xl-0 dropdown-toggle"
                    to={"/dashboard"}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("Links.expenses")}
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/new_transfers"}>
                        {t("Links.transfers")}
                      </Link>
                    </li>
                    {
                      userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(3040) !== -1 && (
                        <li>
                          <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/toBePaid"}>
                            {t("Links.toBePaid")}
                          </Link>
                        </li>
                      )
                    }
                    {
                      userJson &&
                      userJson.length > 0 &&
                      userJson.indexOf(3040) !== -1 && (
                        <li>
                          <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item" to={"/paid"}>
                            {t("Links.paid")}
                          </Link>
                        </li>
                      )
                    }
                  </ul>
                </li>

                {
                  userJson &&
                  userJson.length > 0 &&
                  userJson.indexOf(3010) !== -1 && (
                    <li className="nav-item dropdown me-lg-3 me-xl-0">
                      <Link
                        className="nav-link px-xl-0 dropdown-toggle"
                        to={"/dashboard"}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {t("Links.financial")}
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/bank_transactions"}
                          >
                            {t("Links.bankTransactions")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )
                }
              </Nav >
            </Navbar.Collapse >
            <ul className="navbar-nav align-items-center flex-row ps-md-2">
              <li className="nav-item me-3 me-xl-4 d-none d-sm-inline-flex">
                <span
                  className="z-index-1 p-0 px-1 bg-secondary text-white rounded cursor-pe"
                  title="Agency id"
                >
                  {agencyId}
                </span>
              </li>
              <li className="nav-item me-3 me-xl-4 d-none d-sm-inline-flex">
                <select
                  className="lang"
                  value={defaultLang}
                  onChange={onChangeLanguages}
                >
                  {Languages &&
                    Languages.length > 0 &&
                    Languages.map(({ code, label, flug }) => (
                      <option key={code} value={code}>
                        {flug} {label}
                      </option>
                    ))}
                </select>
              </li>
              <li className="nav-item dropdown me-3 me-xl-4">
                {/* <Link onClick={(e) => handleClick(e, "other")} className="nav-link  p-0" to={`${userRole == 1 ? "https://garante.kenlo.io/sign-in?callbackUrl=/" : "https://areadocliente.kenlo.io/"}`}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/header/grid-dots.svg"} alt="Grid dots" />
                                </Link> */}
                <Link
                  className="nav-link px-xl-0 dropdown-toggle dropdown-arrow-none"
                  to={"/dashboard"}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/header/grid-dots.svg"
                    }
                    alt="Grid dots"
                  />
                </Link>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <Link
                      className="dropdown-item"
                      target="_blank"
                      to={"https://signin.valuegaia.com.br/?provider=imob"}
                    >
                      Kenlo IMOB
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      target="_blank"
                      to={"https://garante.kenlo.io/sign-in?callbackUrl=/"}
                    >
                      kenlo Garante
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      target="_blank"
                      to={"https://areadocliente.kenlo.io/"}
                    >
                      Kenlo User Area
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown company">
                <Link
                  className="nav-link p-0 dropdown-toggle dropdown-arrow-none d-flex align-items-center"
                  to={"/dashboard"}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div className="bg-dark-70 rounded-circle d-flex align-items-center wh-xs justify-content-center text-white flex-shrink-0 text-uppercase fs-12">
                    {agencyIns}
                  </div>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end mh-456 position-absolute overflow-auto">
                  {userJson &&
                    userJson.length > 0 &&
                    userJson.indexOf(1000) !== -1 && (
                      <li>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          onClick={(e) => handleClick(e, "company")}
                          to={"/all_users"}
                        >
                          <span className="nav-icon flex-shrink-0 me-1">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/header/users.svg"
                              }
                              alt="users"
                            />
                          </span>
                          {t("Links.users")}
                        </Link>
                      </li>
                    )}
                  {/* {
                      userJson && userJson.length > 0 && (userJson.indexOf(1010) !== -1) &&
                      <li>
                          <Link onClick={(e) => handleClick(e, "other")} className="dropdown-item d-flex align-items-center" onClick={(e) => handleClick(e, "company")} to={'/dashboard'}>
                              <span className="nav-icon flex-shrink-0 me-1"><img src={process.env.PUBLIC_URL + "/assets/images/header/kenlo-app.svg"} alt="Kenlo app" /></span>
                              {t('Links.kenloAppClients')}
                          </Link>
                      </li>
                  } */}

                  <li>
                    <div
                      className="dropdown-item cursor-pe d-flex align-items-center collapsed arrow-collapse"
                      onClick={() => setOpen(!open)}
                      aria-controls="collapseCompany"
                      aria-expanded={open}
                    >
                      <span className="nav-icon flex-shrink-0 me-1">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/header/company.svg"
                          }
                          alt="Company"
                        />
                      </span>
                      {t("Links.company")}
                    </div>
                    {/* Company submenu start from here */}
                    <Collapse in={open}>
                      <div id="collapseCompany" className="bg-light">
                        <ul className="nav flex-column py-1 fs-12">
                          <li className="nav-item">
                            <Link
                              className="nav-link px-4 py-2 text-reset"
                              onClick={(e) => handleClick(e, "company")}
                              to={"/company"}
                            >
                              {t("Links.companyData")}
                            </Link>
                          </li>
                          {
                            userPlanString === "300" &&
                            <li className="nav-item">
                              <Link
                                className="nav-link px-4 py-2 text-reset"
                                onClick={(e) => handleClick(e, "company")}
                                to={"/branches"}
                              >
                                {t("Links.branches")}
                              </Link>
                            </li>
                          }

                          {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(3110) !== -1 && (
                              <li className="nav-item">
                                <Link
                                  className="nav-link px-4 py-2 text-reset"
                                  onClick={(e) => handleClick(e, "company")}
                                  to={"/bank_accounts"}
                                >
                                  {t("Links.bankAccounts")}
                                </Link>
                              </li>
                            )}

                          {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(3120) !== -1 && (
                              <li className="nav-item">
                                <Link
                                  className="nav-link px-4 py-2 text-reset"
                                  onClick={(e) => handleClick(e, "company")}
                                  to={"/form_receipt"}
                                >
                                  {t("Links.formsOfReceipt")}
                                </Link>
                              </li>
                            )}

                          {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(2010) !== -1 && (
                              <li className="nav-item">
                                <Link
                                  className="nav-link px-4 py-2 text-reset"
                                  onClick={(e) => handleClick(e, "company")}
                                  to={"/taxation"}
                                >
                                  {t("Links.taxation")}
                                </Link>
                              </li>
                            )}

                          {/* {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(1020) !== -1 && ( */}
                          <li className="nav-item">
                            <Link
                              className="nav-link px-4 py-2 text-reset"
                              onClick={(e) => handleClick(e, "company")}
                              to={"/accounts_plan"}
                            >
                              {t("Links.accountsPlan")}
                            </Link>
                          </li>
                          {/* )} */}

                          {/* {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(2020) !== -1 && ( */}
                          <li className="nav-item">
                            <Link
                              className="nav-link px-4 py-2 text-reset"
                              onClick={(e) => handleClick(e, "company")}
                              to={`${config.REPORTS_SERVER_URL}/${localStorage.getItem("reportData")}`}
                              target="_blank"
                            >
                              {t("Links.reports")}
                            </Link>
                          </li>
                          {/* )} */}

                          {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(1070) !== -1 && (
                              <li className="nav-item">
                                <Link
                                  className="nav-link px-4 py-2 text-reset"
                                  onClick={(e) => handleClick(e, "company")}
                                  to={"/services"}
                                >
                                  {t("Links.services")}
                                </Link>
                              </li>
                            )}

                          {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(1040) !== -1 && (
                              <li className="nav-item">
                                <Link
                                  className="nav-link px-4 py-2 text-reset"
                                  onClick={(e) => handleClick(e, "company")}
                                  to={"/notifications"}
                                >
                                  {t("Links.notifications")}
                                </Link>
                              </li>
                            )
                          }
                        </ul>
                      </div>
                    </Collapse>
                    {/* /Company submenu end */}
                  </li>
                  <li>
                    <div
                      className="dropdown-item cursor-pe d-flex align-items-center collapsed arrow-collapse"
                      onClick={() => setOpen2(!open2)}
                      aria-controls="collapseHistory"
                      aria-expanded={open2}
                    >
                      <span className="nav-icon flex-shrink-0 me-1">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/header/history.svg"
                          }
                          alt="History"
                        />
                      </span>
                      {t("Links.history")}
                    </div>
                    {/* History submenu start from here */}
                    <Collapse in={open2} className="bg-light">
                      <div id="collapseHistory">
                        <ul className="nav flex-column py-1 text-dark fs-12">
                          <li className="nav-item">
                            <Link
                              className="nav-link px-4 py-2 text-reset"
                              onClick={(e) => handleClick(e, "company")}
                              to={"/dashboard"}
                            >
                              {t("Links.alterations")}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link px-4 py-2 text-reset"
                              onClick={(e) => handleClick(e, "company")}
                              to={"/sent_email"}
                            >
                              {t("Links.sentEmails")}
                            </Link>
                          </li>

                          {userJson &&
                            userJson.length > 0 &&
                            userJson.indexOf(1090) !== -1 && (
                              <li className="nav-item">
                                <Link
                                  className="nav-link px-4 py-2 text-reset"
                                  onClick={(e) => handleClick(e, "company")}
                                  to={"/dashboard"}
                                >
                                  {t("Links.prints")}
                                </Link>
                              </li>
                            )}
                        </ul>
                      </div>
                    </Collapse>
                    {/* /History submenu end */}
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to={"#"}
                      onClick={(e) => handleLogout(e)}
                    >
                      <span className="nav-icon flex-shrink-0 me-1">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/header/exit.svg"
                          }
                          alt="Exit"
                        />
                      </span>
                      {t("Links.exit")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Container >
        </Navbar >
      </header >
      <Outlet />
    </>
  );
};
export default MainNav;
