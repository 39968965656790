import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useForm }      from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Table, FloatingLabel, Col, Row, Modal, Form, Button, Alert, Card } from 'react-bootstrap';

import * as Yup from 'yup';
import { useTranslation }          from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';
import { AppDispatch,RootState }   from '../../../../redux/store';
import Loader                      from '../../../Profile/Company/Loader';
import { LaunchIndexForm }     from '../../../Interface';
import { userService } from '../../../../services/userService';

const TableIndex = (LaunchIndexForm) => {
    const [ t ]  = useTranslation();
    // Modal's initiate
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState("");
    // Launch index modal
    const launchIndexClose = () => setShow(false);
    const launchIndexShow = ()  => setShow(true);
    const [readjIndexes, setReADJIndexes] = useState<Array<any>>([]);

    const form_validationSchema = Yup.object().shape({
        postponeReadtoDate: Yup.string().trim().nullable()
        .required(t("requiredMmessage.fieldRequired")),
        index: Yup.string().trim().nullable()
        .required(t("requiredMmessage.fieldRequired"))
    });
    var frm = { resolver: yupResolver(form_validationSchema) }

    const {register, handleSubmit,setValue, reset,formState: { errors } } = useForm<LaunchIndexForm>(frm);
    const postReadjustment = (data: LaunchIndexForm) => {
        //console.log('Post Readjustment Form Submit',JSON.stringify(data))
    }

    useEffect(() => {
        getAllReadjustmentIndices({});
    }, [])
    
    const getAllReadjustmentIndices = (param) => {
        setStatus("loading");
        userService.getAllUsers({ userId: "", start:0 , end: 1000 }).then(res => {
            if (res.data) {
                setStatus("");
                setReADJIndexes(res.data);
            }
            else { setReADJIndexes([]); }
        }).catch(err => {
            console.log("err", err)
        });
    }

    return (
        <section className="content-wrapper">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <Link to={'/contracts_readjust'}>
                                    <img src="assets/images/icons/arrow-left.svg" alt="Filter" className="h-16" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2">
                                    <h1 className="mb-0 h4 lh-base text-truncate">IGPM Atu table indices</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Button variant="link" onClick={launchIndexShow} className="fw-bold p-0">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        Launch index
                                    </Button>
                                </li>
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/* <!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Alert className="mb-lg-4 mb-3" variant="warning">Enter the unaccrued monthly index .</Alert>
                {/*<!-- Table start from here -->*/}
                {
                (status == "loading") ?
                    <Loader />
                :
                <Card>
                    <Card.Body className="pb-1">
                    <Table responsive className="table-custom table-custom-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Month year</th>
                            <th>Index</th>
                        </tr>
                    </thead>
                    <tbody>
                        { readjIndexes && readjIndexes.length > 0 ? readjIndexes.map((item) => (
                        <tr>
                            <td>Jan/2023</td>
                            <td>0,00</td>
                        </tr>
                        )): 
                            <tr>
                                <td colSpan={2}>
                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
                    </Card.Body>
                </Card>
                
                }
                {/*<!-- /Table end -->*/}
              <p className="mt-lg-4 mt-3"> Listing {readjIndexes.length} indexes.</p>
            </main>
            {/*<!-- /Main content end -->*/}
            {/* Postpone readjustment modal start from here */}
            <Modal show={show} onHide={launchIndexClose}>
                <Form onSubmit={handleSubmit(postReadjustment)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Launch index</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3">
                            <Col sm={6}>
                                <FloatingLabel label="Postpone readjustment to">
                                    <Form.Control type="date" className={`${errors.postponeReadtoDate ? 'is-invalid' : ''}`}  {...register('postponeReadtoDate')} placeholder="Postpone readjustment to" />
                                </FloatingLabel>
                            </Col>
                            <Col sm={6}>
                                <FloatingLabel label="Index">
                                    <Form.Control type="text" className={`${errors.index ? 'is-invalid' : ''}`}  {...register('index')} placeholder="Index" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={launchIndexClose}>
                        {t("button.close")}
                        </Button>
                        <Button type="submit" variant="primary" name="save" >{t("button.save")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Postpone readjustment modal end */}
        </section>
    )
}

export default TableIndex