import { Accordion, Table, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Collapse, Card } from 'react-bootstrap';
import { propertyOpen, getContractTenants, getCities, getNeighborhood, getAllProperty, } from "../../../../redux/slice/contractDataSlice";
import { getManager, getAdministrators, getCondos, getPropertyOwner } from "../../../../redux/slice/propertyDataSlice";
import React, { useState, MouseEvent, useEffect, useMemo } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { dotInsert, openErrorbox } from '../Common/actionPerform';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import PropertySettings from './PropertySettings';
import { getBraches } from '../../../../redux/slice/branchSlice';
import PropertyService from '../Common/PropertyService';
import '../../../../App.css';
import Pagination from "../../../Pagination";
import { converter, encrypt_Decrypt } from '../../../constants/utils';

const Properties = (CustomersAndEmployeesForm) => {

    const [t] = useTranslation();
    const { contTenantsData, citiesData, neighborData, proprtyData, proprtyStatus, administproprty, Property_Type } = useSelector((state: RootState) => state.contractData)
    const { managerData, administData, condoData, propertyOwnerData, status } = useSelector((state: RootState) => state.propertyData)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const { branchList } = useSelector((state: RootState) => state.branch)
    const dispatch = useDispatch<AppDispatch>();
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [heading, setHeading] = useState("");
    const [option, setOption] = useState<any | null>(null);
    const [PropertyId, setPropertyId] = useState<number>()
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    const [moreFilter, setMoreFilter] = useState<Boolean>(false)
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2070) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
    /*----------Pagination---------*/

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setState({
            ...state,
            ['start']: (currentPage > 1 ? currentPage - 1 : 0) * PageSize,
            ['end']: 20
        })
    }, [currentPage])

    /*----------Property common modal form---------*/

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<CustomersAndEmployeesForm>({});
    const newPropertyOpen = () => { dispatch(propertyOpen(true)); }
    const filterShow = () => {
        setShow2(true);
        setFiltersOpen(false);
    }
    const filterClose = () => {
        setShow2(false); reset();
    }
    const changeAdminClose = () => { setShow(false); reset(); }

    /*----------Property filter modal form---------*/

    const filterSubmit = async (data: CustomersAndEmployeesForm) => {
        let moreFilterData: any = {
            owners: data.owner_type,
            condominium: data.condominium,
            propertyType: data.property_type,
            business: data.business_type,
            realEstate: data.real_estate,
            property: data.property,
            branch: data.branch,
            city: data.city,
            tenant: data.tenants,
            neighborhood: data.neighborhood,
            administrator: data.administrator,
            manager: data.manager,
            status: data.status,
            start: 0,
            end: 20
        }
        let res = await dispatch(getAllProperty(moreFilterData))
        if (res?.payload?.["type"] == "success" || res?.payload?.["type"] == "Success") {
            setMoreFilter(true)
        }
        filterClose();
    }
    /*----------Property search functionality---------*/
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
    const [state, setState] = useState({
        property: "",
        status: "",
        branch: "",
        city: "",
        tenants: "",
        neighborhood: "",
        administrator: "",
        manager: "",
        owner_type: "",
        condominium: "",
        business_type: "",
        property_type: "",
        real_estate: "",
        start: 0,
        end: 20
    })

    let typingTimer;
    const searchProperty = (evt: any, type: any) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            if (type != "onsite") {
                const name = "administrator";
                const value = administproprty[0]
                setState({
                    ...state,
                    [name]: value
                })
            } else {
                const name = evt.target.name;
                const value = evt.target.value;
                // value = evt.target.selectedOptions[0].dataset.label ; 
                setState({
                    ...state,
                    [name]: value
                })
            }
            var selectElement: any = document?.querySelector('select[name="manager"]');
            var selectedIndex = selectElement?.selectedIndex;
            var selectedOption = selectElement?.options[selectedIndex];
            // Get the value of the data-action attribute
            var dataAction: any = selectedOption?.getAttribute('data-action');
            // Log the value (you can modify this part to suit your needs)
            setFilterObj({
                ...filterObj,
                ["manager"]: dataAction
            })
            if (evt.target.name == "administrator") {
                const filteredObject = administData?.find(item => item?.id == evt.target.value)
                if (filteredObject) {
                    setFilterObj({
                        ...filterObj,
                        ["administrator"]: filteredObject?.name
                    })
                }
            }
        }, 800);
    }

    const searchPropertyStatus = (evt: any, type: any) => {
        let valueDta: any = evt.target.value;
        let name = evt.target.name;
        setState({
            ...state,
            [name]: valueDta
        })
    }

    // Modify this part of your tenantSubmit function:
    const tenantSubmit = (evt) => {
        const name = evt.target.name;
        let value = evt.target.value;

        // If "All" is selected, we handle it separately
        if (value === "All") {
            setFilterObj({
                ...filterObj,
                ["tenants"]: t('formLabel.all') // Display "All.." in Dropdown.Toggle
            });
        } else {
            value = parseInt(value);
            const filteredObject = contTenantsData?.find(item => item?.id == value);
            if (filteredObject) {
                setFilterObj({
                    ...filterObj,
                    ["tenants"]: filteredObject.name
                });
            }
        }

        let moreFilterData = {
            owners: "",
            condominium: "",
            propertyType: "",
            business: "",
            realEstate: "",
            property: "",
            branch: "",
            city: "",
            tenants: value,
            neighborhood: "",
            administrator: "",
            manager: "",
            status: "",
            start: 0,
            end: 20
        };
        dispatch(getAllProperty(moreFilterData));
    };


    /*----------Property status updaye---------*/

    const on_changeAdminSubmit = (data: CustomersAndEmployeesForm) => {
        var adminData = {
            status: data.status,
            type: data.adminType,
            isCheck: isCheck
        }
        customerService.changePermissionProperty(adminData).then(res => {
            if (res.data.resCode === 200) {
                changeAdminClose();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        });
    };

    /*----------Property filter modal form---------*/

    const handleMouseEvent = (e, type) => {
        e.preventDefault();
        reset();
        if (isCheck.length > 0) {
            let products = administData;
            if (type === "Change condominium" || type === "Alterar Condomínio") {
                products = condoData;
            }
            if (type === "Add manager" || type === "Adicionar gerente") {
                products = managerData;
            }
            if (type === "Remove manager" || type === "Remover Gestor") {
                products = managerData;
            }
            if (type === "Change Branch" || type === "Alterar filial") {
                products = branchList;
            }
            const productOptions = products?.map((item, index) => (
                <option key={index} value={item.id}>{item.name}</option>
            ))
            setOption(productOptions);
            setHeading(type);
            setShow(true)
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    };

    /*-------Checked/Unchecked multiple checkbox functionality--------*/

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(proprtyData.data?.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }
    };

    const handleClickClose = () => {
        let hide: any = document?.querySelector(".wrapper");
        hide.click();
        setTimeout(() => {
            window.print();
        }, 100)
    }
    /*----------Property on page load function---------*/
    useEffect(() => {
        dispatch(getBraches(branchState));
        dispatch(getManager());
        dispatch(getAdministrators())
        dispatch(getCondos())
        dispatch(getPropertyOwner({ "id": '', "start": 0, "end": 1000 }))
        dispatch(getContractTenants());
        dispatch(getCities());
        dispatch(getNeighborhood());
    }, [dispatch])
    const [filterObj, setFilterObj] = useState<any>({
        branchaname: "",
        administrator: "",
        manager: ""
    })
    useEffect(() => {
        dispatch(getAllProperty(state));

    }, [dispatch, state])

    useEffect(() => {
        const filteredObject = branchList?.find(item => item?.id == state?.["branch"]);
        if (filteredObject) {
            setFilterObj({
                ...filterObj,
                ["branchaname"]: filteredObject.branchname
            })
        }
        if (state.administrator) {

        }
    }, [state])
    /*----------End---------*/

    //Property setting modal initialization
    const filterClear = async () => {
        reset()
        let obj = {
            owners: "",
            condominium: "",
            propertyType: "",
            business: "",
            realEstate: "",
            start: 0,
            end: 20
        }
        let res = await dispatch(getAllProperty(obj))
        filterClose();
        setState({
            property: "",
            status: "",
            branch: "",
            city: "",
            tenants: "",
            neighborhood: "",
            administrator: "",
            manager: "",
            owner_type: "",
            condominium: "",
            business_type: "",
            property_type: "",
            real_estate: "",
            start: 0,
            end: 20
        })
        setFilterObj({
            branchaname: "",
            administrator: "",
            manager: ""
        })
    }
    useEffect(() => {
        if (administproprty?.[1]) {
            setTimeout(() => {
                let Element: any = document.querySelector(`.${administproprty?.[1]}`);
                Element.click();
                setValue('administrator', administproprty[0]);
                searchProperty("evt", "other");
            }, 2000)
        }
    }, [administproprty])
    const CloneProperty = (id) => {
        setPropertyId(id)
        dispatch(propertyOpen(true))
    }
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);

    const MoreItem = (evt) => {
        const selectedValue = parseInt(evt.target.value, 10);
        setState((prevState) => ({
            ...prevState,
            end: selectedValue,
        }));
        setPageSize(evt.target.value);
    }

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                <h1 className="mb-0 lh-base text-truncate">{t('property.Properties')}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control {...register('property')} onChange={(evt) => searchProperty(evt, "onsite")} placeholder={t("searchListName.contractsearch")} aria-label="Search" aria-describedby="search_icon" />
                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(2071) !== -1) &&
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                        <a role="button" onClick={newPropertyOpen} className="fw-bold">
                                            <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                            {t('property.newProperty')}
                                        </a>
                                    </li>
                                }
                                <li className="list-inline-item">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdownMenuButton1">
                                            {t('button.more')}
                                            <img src="assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className="dropdown-item" to={"/administrators"}>{t('button.administrators')}</Link>
                                            <Link className="dropdown-item" to={"/condos"}>{t('property.condos')}</Link>
                                            <Link className="dropdown-item" to={"/brokers"}>{t('customerAndEmployees.brokers')}</Link>
                                            <Link className="dropdown-item" to={"/managers"}>{t('property.managers')}</Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["status"] ? "show" : ""}`}>
                                        {state?.status && state?.status != "" ? state?.status : t('customerAndEmployees.active') ? t('customerAndEmployees.active') : t("dropdownItem.status")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="px-4">
                                        <FloatingLabel label={t('dropdownItem.status')}>
                                            <Form.Select {...register('status')} onChange={(evt) => searchPropertyStatus(evt, "onsite")} aria-label="Status">
                                                <option value="agrupados_all">{t('formLabel.all')}</option>
                                                <option value="ativos_active" selected data-label={t('customerAndEmployees.active')} >{t('customerAndEmployees.active')}</option>
                                                <option value="locados_active_rent" data-label={t('dropdownItem.activerent')} >{t('dropdownItem.activerent')}</option>
                                                {/* <option value="locacaopendente_pendinnglease" data-label={t('dropdownItem.pendinglease')} >{t('dropdownItem.pendinglease')}</option> */}
                                                <option value="vendidos_sold" data-label={t('dropdownItem.sold')} >{t('dropdownItem.sold')}</option>
                                                <option value="disponiveis_available" data-label={t('dropdownItem.available')} >{t('dropdownItem.available')}</option>
                                                <option value="divulgados_announced" data-label={t('dropdownItem.announced')} >{t('dropdownItem.announced')}</option>
                                                <option value="inativos_invalid" data-label={t('dropdownItem.invalid')} >{t('dropdownItem.invalid')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["city"] ? "show" : ""}`}>
                                        {state?.["city"] && state?.["city"] != "All" ? state?.["city"] : t('formLabel.city')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('formLabel.city')}>
                                            <Form.Select {...register('city')} onChange={(evt) => searchProperty(evt, "onsite")} aria-label="Cities">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {citiesData?.map((item, index) => (
                                                    <option key={index} value={item.name}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${filterObj?.["tenants"] ? "show" : ""}`}>
                                        {filterObj?.["tenants"] && state?.tenants != "" && state?.tenants != "All" ? filterObj?.["tenants"] : t('customerAndEmployees.tenants')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('customerAndEmployees.tenants')}>
                                            <Form.Select {...register('tenants')} onChange={tenantSubmit} aria-label="Tenants">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {contTenantsData && contTenantsData.length > 0 &&
                                                    contTenantsData?.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["neighborhood"] ? "show" : ""}`}>
                                        {state?.["neighborhood"] && state?.neighborhood != "" && state?.neighborhood != "All" ? state?.["neighborhood"] : t('formLabel.neighborhood')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('formLabel.neighborhood')}>
                                            <Form.Select {...register('neighborhood')} onChange={(evt) => searchProperty(evt, "onsite")} aria-label="Neighborhood">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {neighborData?.map((item, index) => (
                                                    <option key={index} value={item.name}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`Administrators ${state?.["administrator"] ? "show" : ""}`}>
                                        {filterObj?.["administrator"] && state?.administrator != "" && state?.administrator != "All" ? filterObj?.["administrator"] : t('button.administrators')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('button.administrators')}>
                                            <Form.Select {...register('administrator')} onChange={(evt) => searchProperty(evt, "onsite")} aria-label="Administrators">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {administData?.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["manager"] ? "show" : ""}`}>
                                        {filterObj?.["manager"] ? filterObj?.["manager"] : t('formLabel.manager')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('formLabel.manager')}>
                                            <Form.Select {...register('manager')} onChange={(evt) => searchProperty(evt, "onsite")} aria-label="Managers">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {managerData?.map((item, index) => (
                                                    <option key={index} value={item.id} data-action={item.name}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className={`d-flex align-items-center ${moreFilter ? "show" : ""}`} onClick={filterShow}>
                                        {t("moreFilters")}
                                        <img
                                            src={`assets/images/icons/${moreFilter ? "icons8-plus-24.png" : "plus-light.svg"}`}
                                            alt="Plus icon"
                                            className="ms-xl-1 ms-auto h-20"
                                        />
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col>
                        </Row>
                    </Form>
                    {/* /Filters end */}
                </Collapse>
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*<!-- Table start from here -->*/}
                {
                    (proprtyStatus == "loading") ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th><Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} /></th>

                                            <th>{t('property.immobile')}</th>
                                            <th>{t('property.saleValue')} <img src="assets/images/icons/brazil-real1.png" className="me-1" /></th>
                                            <th>{t('property.rentAmount')} <img src="assets/images/icons/brazil-real1.png" className="me-1" /> </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {proprtyData.data && proprtyData.data.length > 0 ? (
                                            proprtyData.data?.map((item, index) => {
                                                // const hasOwnerData = ownerData.some((item2) => item.id === item2.id);

                                                return (
                                                    <tr key={index}>
                                                        <td className="check_Checkbox">
                                                            <Checkbox key={index} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Link className="text-reset" to={"/property_detail/" + item.id}>
                                                                <span className="ps-1 d-inline text-dark-70">
                                                                    {item.property_identifier ? item.property_identifier + ' - ' : ''} {item.propertyType ? item.propertyType : ""} {t("in")} {item.neighborhood},
                                                                </span>
                                                                <div>
                                                                    <span className="ps-1 d-inline">
                                                                        {item.address},  {item.number} - {item.complement ? item.complement : ""} {t("CEP")} : {item.zipcode}
                                                                    </span>
                                                                </div>
                                                                {/* <span className="d-block">
                                                                {item.city} - {item.state}  {item.property_identifier ? '- ' + item.property_identifier : ''}
                                                            </span> */}
                                                            </Link>
                                                            <span className="d-inline" title={t("owner")}>
                                                                <img src="assets/images/icons/user_g.svg" className="me-1" />
                                                                {
                                                                    item?.ownerResult?.length > 0 && item?.ownerResult?.length > 0 ? item.ownerResult?.map((row, indx) => (
                                                                        <Link to={"/owner_details/" + row.ownerId} className="text-secondary text-nowrap " key={indx}>
                                                                            {row.ownerName}{indx < item.ownerResult.length - 1 && ' / '}
                                                                        </Link>

                                                                    )) : ''
                                                                }
                                                            </span>
                                                            <br />
                                                            <span className="d-inline" title={t("tenant")}>
                                                                {
                                                                    item.contractResult.length > 0 && item.contractResult[0].tenantsResult && item.contractResult[0].tenantsResult.length > 0 ?
                                                                        item.contractResult[0].tenantsResult.map((row: any, indx: any) => {
                                                                            return (
                                                                                <>
                                                                                    <img src="assets/images/icons/key_g.svg" className="me-1" />
                                                                                    <Link to={"/profile_details/" + row.tenetId} className="text-secondary text-nowrap " key={indx}>
                                                                                        {row.tenantName} {indx < item.contractResult[0].tenantsResult.length - 1 && ' / '}
                                                                                    </Link>
                                                                                </>
                                                                            )
                                                                        }) : ''
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span className="text-nowrap d-block"><img src="assets/images/icons/brazil-real3.png" /> {converter(`${item.sale_value}`)}</span>
                                                        </td>
                                                        <td>
                                                            <span className="text-nowrap d-block"><img src="assets/images/icons/brazil-real3.png" /> {converter(`${item.rent_amount}`)}</span>
                                                        </td>
                                                        <td className="text-end">
                                                            <div className="text-nowrap d-flex justify-content-end">
                                                                {
                                                                    item.contractResult.length > 0 &&
                                                                        userJson && userJson.length > 0 &&
                                                                        (userJson.indexOf(2030) !== -1) ?
                                                                        <Link to={"/contract_details/" + item.contractResult[0].contractId} className="fw-bold me-2 me-xxl-4 me-lg-3 text-nowrap">
                                                                            {t('button.seeContract')}
                                                                        </Link>
                                                                        :
                                                                        ''
                                                                }
                                                                <span className="icon me-2 me-xxl-4 me-lg-3">
                                                                    <img onClick={() => CloneProperty(item.id)} src="assets/images/icons/edit-icon.svg" alt="Edit" className="cursor-pe" title={t("cloneproperty")} />
                                                                </span>

                                                                <Link to={"/property_detail/" + item.id} className="fw-bold text-nowrap">
                                                                    <img src="assets/images/icons/arrow-right.svg" alt="icon" />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                }
                {/* Pagination Starts */}
                <div className="d-flex justify-content-center mt-lg-4 mt-3">
                    {
                        proprtyData.totalRow > 0 ?
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={proprtyData.totalRow > 0 ? proprtyData.totalRow : 0}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                            :
                            ''
                    }
                </div>
                {/* Pagination Ends */}
                {/*<!-- /Table end -->*/}
                <div className="d-flex mt-lg-4 mt-3">
                    {t("show")}
                    <select onChange={(e: any) => MoreItem(e)} className='px-1 mx-1'>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    {t("entries")}
                    {/* <p className='cursor-pe lg-ml-4' >{t('formLabel.allItem')}</p> */}
                </div>
                <p className="d-block py-2">{t('formLabel.listing')} {state.start} to {proprtyData.data && proprtyData.data.length > 0 ? state.start + proprtyData.data.length : state.start + 0} of {proprtyData.totalRow > 0 ? proprtyData.totalRow : 0} {t('property.properties')}.</p>
                {/* <!-- Nav listing start from here -->*/}
                {
                    userJson && userJson.length > 0 && 
                    (userJson.indexOf(2071) !== -1) &&
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t('formLabel.mark')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleSelectAll} href="#">{t('formLabel.all')}</Dropdown.Item>
                                    <Dropdown.Item onClick={noneHandleSelectAll} href="#">{t('formLabel.none')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t('formLabel.withTagged')} :</span></li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" type="button" onClick={(e) => handleMouseEvent(e, t('property.changeAdministrator'))} name={t('property.changeAdministrator')}>{t('property.changeAdministrator')}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" type="button" onClick={(e) => handleMouseEvent(e, t('property.changeCondominium'))} name={t('property.changeCondominium')}>{t('property.changeCondominium')}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" type="button" onClick={(e) => handleMouseEvent(e, t('property.addManager'))} name={t('property.addManager')}>{t('property.addManager')}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t('button.more')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={(e) => handleMouseEvent(e, t('property.removeManager'))} data-name={`${t('property.removeManager')}`}>{t('property.removeManager')}</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => handleMouseEvent(e, t('property.changeBranch'))} data-name={t('property.changeBranch')}>{t('property.changeBranch')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                }
                {/*<!-- /Nav listing  end -->*/}
            </main>
            {/*<!-- /Main content end -->*/}
            {/*<!-- Table filters start from here -->*/}
            <Modal show={show} onHide={changeAdminClose}>
                <Form onSubmit={handleSubmit(on_changeAdminSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{heading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" value={heading} {...register('adminType')} />
                        <FloatingLabel controlId="floatingSelect" label={heading}>
                            <Form.Select className={`form-control ${errors.status ? 'is-invalid' : ''}`}  {...register('status')} aria-label={heading}>
                                <option value="">{t('dropdownItem.select')}..</option>
                                {option}
                            </Form.Select>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeAdminClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" >
                            {t('button.toSave')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/*<!-- /Table filters end -->*/}

            {/* Filters modal start from here */}
            <Modal show={show2} onHide={filterClose}>
                {/* <Form onSubmit={handleSubmit(filterSubmit)}> */}
                <Form onSubmit={handleSubmit(filterSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('property.moreFilters')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('customerAndEmployees.owners')}>
                            <Form.Select aria-label={t('customerAndEmployees.owners')} {...register('owner_type')}>
                                <option value="">{t('dropdownItem.select')}..</option>
                                <option value="All">{t("formLabel.all")}</option>
                                {propertyOwnerData?.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('property.condominium')}>
                                    <Form.Select aria-label={t('property.condominium')} {...register('condominium')} >
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="All">{t("formLabel.all")}</option>
                                        {condoData?.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.propertyType')}>
                                    <Form.Select aria-label={t('formLabel.propertyType')} {...register('property_type')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="All">{t("formLabel.all")}</option>
                                        {Property_Type && Property_Type?.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClear}>
                            {t('button.resetfilter')}
                        </Button>
                        <Button type="submit" variant="primary" name="save" >

                            {t('button.toApply')}
                        </Button>
                        {/* <Button onClick={filterClose} variant="primary" name="save" >

                            {t('button.toApply')}
                        </Button> */}
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Filters modal end */}
            <PropertyService singlePropertyId={PropertyId} count={proprtyData.totalRow} />
        </section >
    )
}
export default Properties;