import { Dropdown, Table, Accordion, FloatingLabel, Col, Row, Modal, Form, Button, Card } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect } from 'react';
import { CustomersAndEmployeesForm } from '../../../../Interface';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../../services/customerService';
import Checkbox from '../../Common/Checkbox';
import Loader from '../../../../Profile/Company/Loader';
import '../../../../.././App.css';
import { openErrorbox } from '../../Common/actionPerform';
import AdministratorsSettings from './AdministratorsSettings';
import { admPropery, getAllProperty_administractor, openEmailPop, showEmail } from '../../../../../redux/slice/contractDataSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import SendEamilAdmini from './SendEamilAdmini';
import { CNJP_CPFValidation, PrintPDF, checkDuplicateCNJP_CPF } from '../../../../constants/utils';
import SendEmails from '../../Common/SendEmails';

const Administrators = (CustomersAndEmployeesForm) => {

    const [t] = useTranslation();
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(true);
    const formClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        setShow(false); reset();
    }
    const formShow = () => { setShow(true); }
    const navigate = useNavigate()
    /*--------Data table listing state--------*/
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [AllStates, setAllStates] = useState([]);
    useEffect(() => { getAllAdministrators(null); }, []);
    /*--------Email modal state--------*/
    const [sendMailData, setSendMailData] = useState<any>([]);
    const [mailData, setMailData] = useState<any>([]);
    const [hide5, setHide5] = useState(false);
    const [mailText, setMailText] = useState("");

    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');
        data?.forEach((item, index) => {
            let mailData: any = []; // Initialize mailData array for each iteration
            if (item["checked"]) {
                count = true
                sendMailData.forEach((element: any) => {
                    if (element.id == item.id) {
                        mailData.push(element.email);
                    }
                });

                // Push the mailData array into the emailArray
                setMailData(prevEmailArray => [...prevEmailArray, ...mailData]);
            }
            // setMailData(emailArray)
        });

        if (count) {
            setMailText(e.currentTarget.name)
            if (e.currentTarget.name === "Send letter" || e.currentTarget.name === "Enviar carta") {
                setHide5(true);
            } else {
                setHide5(false);
            }
            // setShow2(true)
            dispatch(openEmailPop(true))
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    /*-------------Form validation-------------*/

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const adminValidation = Yup.object().shape({
        admin_name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),

    });
    let frm = {
        resolver: yupResolver(adminValidation)
    }
    const { register, handleSubmit, reset, setValue, clearErrors, setError, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);

    /*-------------Call the api & save Administrators data-------------*/

    const formSubmit = (data: CustomersAndEmployeesForm) => {
        let AdministratorsData = {
            name: data.admin_name,
            cnpj: data.cnpj,
            responsible: data.admin_responsible,
            email: data.admin_email,
            telephone: data.admin_telephone,
            zipcode: data.zipcode,
            address: data.address,
            number: data.admin_number,
            complement: data.admin_complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            observation: data.admin_observation,
            is_vendor: data.admin_is_vendor
        }
        customerService.createAdministrators(AdministratorsData).then(res => {
            /*-------Check the api response--------*/
            if (res.data.resCode === 202) {
                getAllAdministrators(null);
                formClose();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }else if(res.data.resCode == 409){
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.unqueEmails"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        });
    };

    /*-------------Email form submit-------------*/

    let [desc, setDesc] = useState('');
    const mailSubmit = (data: CustomersAndEmployeesForm) => {
        let mailData: any = "";

        if (desc != "") {
            mailData = {
                identifier: data.admin_identifier,
                model: data.admin_model,
                caption: data.admin_caption,
                title: data.admin_title,
                desc: desc,
                variable: data.admin_variable
            }
        } else {
            mailData = {
                identifier: data.admin_identifier,
                model: data.admin_model,
                caption: data.admin_caption,
                title: data.admin_title,
                desc: '',
                variable: data.admin_variable
            }
        }

    }

    /*-------Get the Administrators listing--------*/

    const getAllAdministrators = (administrator) => {
        setStatus(true);
        let administratorData = { administrator: administrator, start: 0, end: 100 }
        customerService.getAllAdministrators(administratorData).then(res => {
            setStatus(false);
            if (res.data.type === "success" || res.data.type === "Success") {
                setData(res.data.data);
                setSendMailData(res?.data?.data || [])
                setTotal(res.data.totalRow)
            }
            else {
                setData([]);
                setSendMailData([])
                setTotal(0)
            }
        }).catch(err => {
            setStatus(false);
            console.log("err", err)
        });
    }
    /*-------Checked/Unchecked multiple checkbox functionality--------*/
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }

                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })

                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)

        }
    };
    const [numvalue, setnumValue] = useState('');
    //Administrators setting modal initialization
    const [showAdministratorsSettingModal, setAdministratorsSettingModal] = useState(false);
    const administratorsSettingModalClose = () => setAdministratorsSettingModal(false);
    const administratorsSettingModalShow = () => setAdministratorsSettingModal(true);

    useEffect(() => {
        // Execute the effect function asynchronously
        (async () => {
            // Fetch the address data based on zipcode using the customerService
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            // Check if the response status is "success"
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })();
    }, []);
    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
          `${inputType}/${newStr}`
        );
        // Get the DOM element for the zipcode input
        let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
        let zipCodeCheck: any = document.querySelector(".zipCodeCheck");
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            // Check if the response status is success
            if (resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                // Update the styling and enable zip code check
                querySel.style.border = "0px solid red";
                zipCodeCheck.removeAttribute("disabled");
                // Set values for city, neighborhood, state, and address

                if (res[0]["city"]) {
                    setValue("city", res[0]["city"]);
                }
                if (res[0]["neigbohood"]) {

                    setValue("neighborhood", res[0]["neigbohood"]);
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"]);
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data =
                        (res[0]["streetType"] ? res[0]["streetType"] + " " : "") +
                        (res[0]["street"] ?? "");
                    setValue("address", data);
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                // Reset styling and enable zip code check when value is empty
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                querySel.style.border = "2px solid red";
                setValue("city", "");
                setValue("neighborhood", "");
                setValue("state", "");
                setValue("address", "");
                zipCodeCheck.setAttribute("disabled", "");
            }
        } else {
            // Reset styling and enable zip code check when value is empty
            querySel.style.border = "0px solid red";
            zipCodeCheck.removeAttribute("disabled");
        }
    };


    const [uniqueSet, setUniqueSet] = useState([]);
    const [allPropertyCount, setallPropertyCount] = useState({});
    useEffect(() => {
        (async () => {
            let resp = await dispatch(getAllProperty_administractor({
                start: 0,
                end: 2000
            }));

            let uniqueValueCount: any = [];
            let allPropertyCount = {};
            let count = 1;
            data.forEach((dataItem) => {
                count = 1;
                uniqueValueCount.push(dataItem)
                resp.payload.data.forEach((userItem, index) => {

                    if (dataItem.id == userItem.addministrators_id) {
                        allPropertyCount[`${dataItem.id}`] = {
                            "count": count++,
                            "id": dataItem.id,
                            "name": dataItem.name
                        }
                    }
                });
            });
            setallPropertyCount(allPropertyCount);
            setUniqueSet(uniqueValueCount);
        })();
    }, [data]);
    const getAdmiProperty = (id: number, cmd: string) => {
        let newData = [id, cmd]
        dispatch(admPropery(newData))
        navigate("/properties")

    }

    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("admin_telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('admin_telephone')
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value,  "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    } 
    return (
        <section className="content-wrapper">
            {/*<!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
            <div className="w-100">
                <Row className="gx-xl-5 justify-content-between align-items-center">
                <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                        <div className="d-flex align-items-center justify-content-start">
                            <Link to={"/properties"}>
                                <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                            </Link>
                            <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                <h1 className="mb-0 h4 lh-base text-truncate">{t('property.administrators')}</h1>
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                        <div className="input-group">
                            <Form.Control type="text" id="search" placeholder={t('formLabel.typeSearch')} aria-describedby="basic-addon2" onChange={(e) => getAllAdministrators(e.target.value)} />
                            <span className="input-group-text" id="basic-addon2">
                                <img alt="img" src="./assets/images/icons/search.svg" />
                            </span>
                        </div>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end mb-xl-0 col-xl mb-2">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                <Link to={"#"} onClick={formShow} type="button"
                                    className="fw-bold">
                                    <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                    {t('property.newAdministrator')}
                                </Link>
                            </li>
                            <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                {/* <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" /> */}
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdownMenuButton1">
                                        <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item><Link to={""} onClick={PrintPDF}>Print the screen</Link></Dropdown.Item>
                                        <Dropdown.Item><Link to={""}>Administrators</Link></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                <img src="assets/images/icons/settings-light.svg" alt="settings" onClick={administratorsSettingModalShow} className="h-20 cursor-pe" />
                                <AdministratorsSettings showAdministratorsSetting={showAdministratorsSettingModal} administratorsSettingClose={administratorsSettingModalClose} />
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
            </div>
            {/*<!-- /Breadcrumb section end --> */}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*<!-- Table start -->*/}
                <div className="table-responsive">
                    {
                        status ?
                            <Loader />
                            :
                            <Card>
                                <Card.Body className="pb-1">
                                <Table className="table-custom table-custom-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th className="w-24">
                                            {data && data.length > 0 ?
                                                <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                : ''}
                                        </th>
                                        <th>{t('formLabel.name')}</th>
                                        <th></th>
                                        <th>{t('formLabel.contact')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uniqueSet && uniqueSet.length > 0 ? uniqueSet.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className={"check_Checkbox"}>
                                                    <Checkbox key={item["id"]} type="checkbox" name={item["name"]} id={item["id"]} handleClick={handleClick} isChecked={isCheck.includes(item["id"])} />
                                                </td>
                                                <td>
                                                    <span className="d-block">
                                                        <Link className="text-reset" to={`/Administrators_details/${item["id"]}`}>
                                                            {item["name"]}
                                                        </Link>
                                                    </span>
                                                </td>
                                                <td><p onClick={() => getAdmiProperty(item["id"], "Administrators")} className='cursor-pe'>{allPropertyCount[`${item["id"]}`] !== undefined ? allPropertyCount?.[`${item["id"]}`]?.["count"] : 0} property </p> </td>
                                                <td>
                                                    <span className="d-block text-dark-70">
                                                        {item["email"]}
                                                    </span>
                                                    <span>{item["telephone"] ? item["telephone"] : item["number"]}</span>
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                        <tr>
                                            <td colSpan={4}>
                                                <span>{t('formLabel.noRecordsFounds')}</span>
                                            </td>
                                        </tr>
                                    }

                                </tbody>
                            </Table>
                                </Card.Body>
                            </Card>
                    }
                </div>
                {/*<!-- /Table end --> */}
                <div className="mt-lg-4 mt-3">{t('formLabel.moreItems')}</div>
                <p className="d-block py-2">{t('formLabel.listing')} {uniqueSet.length} {t('property.administrators')}.</p>

                {/*<!-- Nav listing start from here --> */}
                <ul className="list-inline mb-0">
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                {t('formLabel.mark')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.all')}</Dropdown.Item>
                                <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('formLabel.none')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <span className="px-1">
                            {t('formLabel.withTagged')}
                        </span>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button type="button" variant="light" size="sm" onClick={handleMouseEvent} name={t('formLabel.sendEmail')}>{t('formLabel.sendEmail')}</Button>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1">
                        <Button type="button" variant="light" size="sm">{t("Managedproperties")}</Button>
                    </li>
                </ul>
                {/* <!-- /Nav listing  end --> */}
            </main>
            {/*<!-- /Main content start end -->*/}

            {/*---------------Administrator form modal------------*/}

            <Modal show={show} onHide={formClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('property.newAdministrator')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Body>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3 mb-lg-4">
                            <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.admin_name ? 'is-invalid' : ''}`}  {...register('admin_name')} />
                            <Form.Control.Feedback type="invalid">{errors.admin_name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <Row className="g-lg-4 g-3 mb-lg-4 bm-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cnpjCpf')}>
                                    <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={` ${errors.admin_cnpj ? 'is-invalid' : ''}`}  {...register('admin_cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_cnpj?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.responsible')}>
                                    <Form.Control type="text" placeholder={t('formLabel.responsible')} className={` ${errors.admin_responsible ? 'is-invalid' : ''}`}  {...register('admin_responsible')} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="text" placeholder={t('formLabel.email')} className={` ${errors.admin_email ? 'is-invalid' : ''}`}  {...register('admin_email')} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_email?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.admin_telephone ? 'is-invalid' : ''}`}  {...register('admin_telephone')} onChange={HandleInput} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_telephone?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="1" >
                                <Accordion.Header>{t('formLabel.address')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel className="mb-3 mb-lg-4 getzipcode" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={` ${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} defaultValue={numvalue} onChange={(e) => getLocation(e, "zipcode")}
                                            maxLength={9}
                                            minLength={9} />
                                        <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <Row className="g-lg-4 g-3">
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.address')}>
                                                <Form.Control type="text" placeholder={t('formLabel.address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`}  {...register('address')} />
                                                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.number')}>
                                                <Form.Control type="text" placeholder={t('formLabel.number')} className={` ${errors.admin_number ? 'is-invalid' : ''}`} {...register('admin_number')} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_number?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                                <Form.Control type="text" placeholder={t('formLabel.complement')} className={` ${errors.admin_complement ? 'is-invalid' : ''}`} {...register('admin_complement')} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_complement?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.neighborhood')}>
                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`form-control ${errors.neighborhood ? 'is-invalid' : ''}`}  {...register('neighborhood')} />
                                                <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.city')}>
                                                <Form.Control type="text" placeholder={t('formLabel.city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`}  {...register('city')} />
                                                <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.state')}>
                                                <Form.Select className={` ${errors.state ? 'is-invalid' : ''}`}  {...register('state')} aria-label={t('formLabel.state')}>
                                                    <option value="">{t('dropdownItem.select')}</option>
                                                    {AllStates.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')} >
                                        <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('admin_observation')} style={{ height: '100px' }} />
                                        <Form.Control.Feedback type="invalid">{errors.admin_observation?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer className="d-flex align-items-center justify-content-between">
                        <Form.Group>
                            <Form.Check type="checkbox" {...register('admin_is_vendor')} label={t("formLabel.adminvender")}  />
                        </Form.Group>
                        <div className="flex-shrink-0 ms-2">
                        <Button variant="outline-primary" className="me-2 me-lg-3" onClick={formClose}>
                            {t('button.close')}
                        </Button>
                        <Button className="zipCodeCheck" type="submit" variant="primary">
                            {t('button.save')}
                        </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>

            <SendEamilAdmini />
            <SendEmails mailText={mailText} mailData={mailData} />
        </section>
    )
}
export default Administrators;
