import React, { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { BankAccountDrawer } from "../../../../newComponents/components/BankAccountDrawer";
import { getBankList } from "../../../../redux/slice/bankSlice";
import {
  Bankslip,
  editBank,
  getFormsofReceipt,
  get_bank_slip,
  get_blocking_and_cancellation,
  update_bank_slip,
  update_blocking,
} from "../../../../redux/slice/formsOfReceiptSlice";
import { AppDispatch, RootState } from "../../../../redux/store";
import { encrypt_Decrypt } from "../../../constants/utils";
import { ComProps } from "../../../Interface";
import { numericOnly } from "../../../Pages/Contracts/Common/actionPerform";
import { BankAccounts } from "./BankAccounts";
import BankCredits from "./BankCredits";
import { Practiced_rates } from "./Practiced_rates";

const FormReceiptV2 = ({ submenu }: ComProps) => {
  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation();
  // Used Redux-Toolkit, Got the data and Destructors the data from forms of receipt slice inside the redux folder
  const { Bankslip, blocking } = useSelector((state: RootState) => state.forms);
  const [newwayShow, setNewWayShow] = useState(false);
  const [othersShow, setOthersShow] = useState(false);
  const [block, setBlock] = useState("");
  const [accounttype, setaccounttype] = useState("");
  const [check, setCheck] = useState<number>();
  // used it to show validation error on form
  const validationSchema = yup.object().shape({
    // Updatethevalue: yup.string().required("Update the value of the ticket on the second copy is required"),
    // sprated: yup.string().required("number is required"),
    // envirment: yup.string().required("envirment is required"),
  });
  const blockingSchema = yup.object().shape({
    day: yup.string().required("day is required"),
  });
  // used it to show validation error on form crate a variable
  var frm = {
    resolver: yupResolver(validationSchema),
  };
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [userJson, setUserJson] = useState<any>([]);
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || userResult.indexOf(3120) === -1) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
  }, []);
  const [crtsld, setcrtSld] = useState<any>({
    formid: [],
    bankid: [],
    status: "",
    accounttype: "",
  });
  // Represents the homologation and Production environment
  const [environments, setEnvironments] = useState([
    { value: "1", label: "Homologation" },
    { value: "2", label: "Production" },
  ]);

  const [healthInsurance, sethealthInsurance] = useState([
    // { value: "1", label: "Technospeed" },
    { value: "0", label: "Kenlo Pay" },
    { value: "2", label: "Manual" },
  ]);

  const [checkeddata, setCheckData] = useState({
    tradename: false,
    bodyslip: false,
  });

  if (block == "blocking") {
    // Use blockingSchema as the resolver for form validation
    var frm = {
      resolver: yupResolver(blockingSchema),
    };
  }

  // The useForm hook is used to handle form registration, submission, and validation.
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Bankslip>(frm);

  useEffect(() => {
    // Dispatching an asynchronous action to get bank list
    dispatch(getBankList(obj));
    (async () => {
      // Dispatching an immediately invoked asynchronous function to get forms of receipt
      dispatch(getFormsofReceipt());
      dispatch(get_bank_slip());
    })();
    dispatch(get_blocking_and_cancellation());
  }, [dispatch]);

  useEffect(() => {
    // Set field values from Bankslip data
    const fields: string[] = [
      "Updatethevalue",
      "sprated",
      "envirment",
      "Billing_Instructions",
      "messageticket",
    ];
    if (Bankslip && Bankslip.length > 0) {
      fields.forEach((field: any) =>
        setValue(field, Bankslip[0]?.[`${field}`])
      );
    }
    const fields1: string[] = [
      "tradename",
      "displaytheamount",
      "displaythetotal",
      "companylogo",
      "pendingcharges",
      "sortbillet",
      "donotdisplay",
    ];
    if (Bankslip && Bankslip.length > 0) {
      fields1?.forEach((field: any) =>
        setValue(field, Bankslip[0]?.[`${field}`] === "0" ? false : true)
      );
      setCheck(Bankslip[0]?.["displaytheamount"]);
    }
    // Set day and id values from blocking data
    const days: string[] = ["day", "id"];
    days.forEach((field: any) => setValue(field, blocking[0]?.[field]));
  }, [blocking, Bankslip]);

  //Getting the data shown when the page is rendered for the first time
  useEffect(() => {
    if (check == 1) {
      document
        .querySelector(".Show_simple_nacional")
        ?.removeAttribute("hidden");
    } else {
      document
        .querySelector(".Show_simple_nacional")
        ?.setAttribute("hidden", "");
    }
  }, [check]);
  const crltsetData = (formid, bankid, status, banktype) => {
    // Set the formid, bankid, status, and banktype in the crtSld state
    setcrtSld({
      formid: formid != "" ? [formid] : [],
      bankid: bankid != "" ? [bankid] : [],
      status: status,
      accounttype: banktype,
    });
    // Set the accounttype state with the banktype value
    setaccounttype(banktype);
    setValue("bankaccount", bankid);
  };

  const handleClose = () => {
    // Clearing the data and setting state values
    crltsetData("", "", "", "");
    setNewWayShow(false);
  };

  const Other_Action_close = () => {
    // Set the "othersShow" state to false, indicating that the "Others" action should be closed
    setOthersShow(false);
  };

  const way_of_rec = () => {
    // Check if accounttype is not empty
    if (accounttype !== "") {
      setNewWayShow(false);
      dispatch(editBank(true));
    } else {
      // Show an error message if accounttype is empty
      Swal.fire({
        title: `${t("Sweetalert.healthInsurance")}`,
        text: `${t("Sweetalert.selectHealthInsurance")}`,
        showConfirmButton: true,
      });
    }
  };

  const OtherAction = () => {
    // Hide the 'Others' section
    setOthersShow(false);
    // Dispatch an action to enable bank editing
    dispatch(editBank(true));
  };
  // Function to handle the account type change
  const accounttypeHandle = (e) => {
    setaccounttype("");
    // Check if the selected value is not empty
    if (e.target.value !== "") {
      setaccounttype(e.target.value);
    }
  };

  const HandleEdit = (e: any, cmd: string, data: string) => {
    // Set the block data
    setBlock(data);
    e.preventDefault();
    // Get references to the form and button elements
    let thisDivform: any =
      e.target.parentElement.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement.parentElement.parentElement;
    if (cmd == "edit") {
      // Enable input fields within form-floating elements
      if (thisDivform.querySelectorAll(".form-floating").length) {
        thisDivform
          .querySelectorAll(".form-floating input, textarea")
          .forEach((item: any, index: number) => {
            item.removeAttribute("disabled");
          });
        thisDivform
          .querySelectorAll('.form-check input[type="checkbox"]')
          .forEach((item: any, index: number) => {
            item.removeAttribute("disabled");
          });
        thisDivform
          .querySelectorAll(".form-floating select")
          .forEach((item: any, index: number) => {
            item.removeAttribute("disabled");
          });
      }
      // Update button visibility
      if (
        thisButton.querySelectorAll(".BtnAction.Update, .BtnAction.Edit")
          .length > 0
      ) {
        thisButton
          .querySelector(".BtnAction.Update")
          ?.removeAttribute("hidden");
        thisButton.querySelector(".BtnAction.Edit")?.setAttribute("hidden", "");
      }
    }
  };
  // Define an object with properties
  let obj = {
    start: "0",
    end: "100",
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    let thisDivform: any = e.target.parentElement.parentElement;
    let thisButton: any = e.target.parentElement;
    // Extract form data and create a bank slip object
    let bankslip = {
      sprated: data.sprated,
      messageticket: data.messageticket,
      tradename: data.tradename,
      Label_Type: data.Label_Type,
      Updatethevalue: data.Updatethevalue,
      donotdisplay: data.donotdisplay,
      Billing_Instructions: data.Billing_Instructions,
      displaytheamount: data.displaytheamount,
      displaythetotal: data.displaythetotal,
      companylogo: data.companylogo,
      sortbillet: data.sortbillet,
      congratulations: data.congratulations,
      pendingcharges: data.pendingcharges,
      envirment: data.envirment,
    };
    // Dispatch an action to update the form data
    let res = await dispatch(update_bank_slip(bankslip));
    if (res.payload.type == "Success") {
      Swal.fire({
        title: `${t("Sweetalert.Success")}`,
        text: `${t("Sweetalert.RecordUpdated")}`,
        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
        timer: 2000,
      });
      dispatch(get_bank_slip());
      if (thisDivform.querySelectorAll(".form-floating").length) {
        thisDivform
          .querySelectorAll(
            ".form-floating textarea, .form-check input[type='checkbox']"
          )
          .forEach((item: any) => {
            item.setAttribute("disabled", "");
          });
      }

      if (
        thisButton.querySelector(".BtnAction.Update") ||
        thisButton.querySelector(".BtnAction.Edit")
      ) {
        thisButton
          .querySelector(".BtnAction.Update")
          ?.setAttribute("hidden", "");
        thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
      }
    } else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000,
      });
    }
    // Show a success message to the user
  };

  //When we first have to show the data in the input field
  const submitCustom = async (data: any, e: any) => {
    let thisDivform: any =
      e.target.parentElement.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement;
    let Days = {
      day: data.day,
      // day: 60,
    };
    let res = await dispatch(update_blocking(Days));
    if (res.payload.type == "Success") {
      // Show a success message using Swal
      Swal.fire({
        position: "center",
        // icon: 'success','success',
        title: `${t("Sweetalert.Success")}`,
        text: `${t("Sweetalert.RecordUpdated")}`,
        showConfirmButton: false,
        timer: 2000,
      });

      if (thisDivform.querySelectorAll(".form-floating").length) {
        thisDivform
          .querySelectorAll(".form-floating input")
          .forEach((item: any, index: number) => {
            item.setAttribute("disabled", "");
          });
      }
      if (
        thisButton.querySelector(".BtnAction.Update") ||
        thisButton.querySelector(".BtnAction.Edit")
      ) {
        thisButton
          .querySelector(".BtnAction.Update")
          ?.setAttribute("hidden", "");
        thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
      }
    } else {
      // Show error message if the branch creation fails
      Swal.fire({
        position: "center",
        // icon: 'success','error',
        title: `${t("Sweetalert.Error")}`,
        text: `${t("Sweetalert.somethingWent")}`,
        showConfirmButton: true,
      });
    }
  };

  const handleCancel = (e, type) => {
    let thisDivform: any =
      e.target.parentElement.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement.parentElement;
    if (type == "blocking") {
      dispatch(get_blocking_and_cancellation());
    } else {
      dispatch(get_bank_slip());
    }
    if (thisDivform.querySelectorAll(".form-floating").length) {
      thisDivform
        .querySelectorAll(
          ".form-floating textarea, .form-check input[type='checkbox']"
        )
        .forEach((item: any) => {
          item.setAttribute("disabled", "");
        });
    }
    if (thisDivform.querySelectorAll(".form-floating").length) {
      thisDivform
        .querySelectorAll(".form-floating input")
        .forEach((item: any, index: number) => {
          item.setAttribute("disabled", "");
        });
    }
    if (
      thisButton.querySelector(".BtnAction.Update") ||
      thisButton.querySelector(".BtnAction.Edit")
    ) {
      thisButton.querySelector(".BtnAction.Update")?.setAttribute("hidden", "");
      thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
    }
  };

  const [totalTextCount, setTotalTextCount] = useState(0);
  const [totalTextCounta, setTotalTextCounta] = useState(0);

  useEffect(() => {
    let getValueLength = getValues("Billing_Instructions")?.length || 0;
    setTotalTextCount(250 - getValueLength);

    let getValueLengtha = getValues("messageticket")?.length || 0;
    setTotalTextCounta(250 - getValueLengtha);
  }, [getValues("Billing_Instructions"), getValues("messageticket")]);

  const handleCharacter = (e) => {
    let insertLenght = e.target.value.length;
    setTotalTextCount(250 - insertLenght);
  };

  const handleCharactera = (e) => {
    let insertLenght = e.target.value.length;
    setTotalTextCounta(250 - insertLenght);
  };

  return (
    <section className="content-wrapper Formreceipttsx" id="wayofreceivingtsx">
      {submenu}
      {/* <!-- Main content start from here --> */}
      <>
        <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
          <Row className="gy-3 gy-lg-4">
            <Col lg={8}>
              <BankAccounts />
              <Accordion
                className="mt-4"
                alwaysOpen
                defaultActiveKey={["0", "1", "2"]}
              >
                {/* Bank slip options section start from here */}
                <Accordion.Item className="position-relative" eventKey="1">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                      <div className="BtnAction Edit">
                        <Button
                          variant="link"
                          size="sm"
                          className="fw-bold lh-1 text-primary p-0 me-2 me-xl-3 me-xxl-4"
                        >
                          <Link to="/billing_rule">
                            {t("formReceipt.configureBillingRule")}{" "}
                          </Link>
                        </Button>
                        {userJson &&
                          userJson.length > 0 &&
                          userJson.indexOf(3121) !== -1 && (
                            <Button
                              variant="link"
                              size="sm"
                              className="fw-bold lh-1 text-primary p-0"
                              onClick={(e) => HandleEdit(e, "edit", "bankslip")}
                            >
                              {" "}
                              {t("button.edit")}
                            </Button>
                          )}
                      </div>
                      <div className="BtnAction Update" hidden>
                        <Button
                          variant="link"
                          size="sm"
                          className="fw-bold lh-1 text-primary p-0 me-3"
                          onClick={(e) => handleCancel(e, "bankslip")}
                        >
                          {t("button.cancel")}
                        </Button>
                        <Button
                          className="fw-bold lh-1 text-primary p-0"
                          variant="link"
                          size="sm"
                          type="submit"
                          id="bankslip"
                        >
                          {" "}
                          {t("button.update")}
                        </Button>
                      </div>
                    </div>
                    <Accordion.Header>
                      {t("formReceipt.bankSlipOptions")}
                    </Accordion.Header>
                    <Accordion.Body className="pb-1">
                      <FloatingLabel
                        controlId="floatingInputValue"
                        label={t("additionalbillinginstruct")}
                        className="mb-lg-4 mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          disabled
                          className={`${
                            errors.Billing_Instructions ? "is-invalid" : ""
                          } `}
                          {...register("Billing_Instructions")}
                          placeholder={t("additionalbillinginstruct")}
                          onChange={(e) => handleCharacter(e)}
                          maxLength={250}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.Billing_Instructions?.message}
                        </Form.Control.Feedback>
                        {totalTextCount}{" "}
                        {totalTextCount !== 0
                          ? t("formReceipt.characterremains")
                          : t("formReceipt.characterremain")}
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInputValue"
                        label={t("messageonalltickets")}
                        {...register("messageticket")}
                        className="mb-lg-4 mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          disabled
                          className={`${
                            errors.messageticket ? "is-invalid" : ""
                          } `}
                          placeholder="Cambui"
                          {...register("messageticket")}
                          onChange={(e) => handleCharactera(e)}
                          maxLength={250}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.messageticket?.message}{" "}
                        </Form.Control.Feedback>
                        {totalTextCounta}{" "}
                        {totalTextCounta !== 0
                          ? t("formReceipt.characterremains")
                          : t("formReceipt.characterremain")}
                      </FloatingLabel>
                      <Form.Check
                        className="mb-3 mb-lg-4"
                        disabled
                        {...register("donotdisplay")}
                        label={t("formReceipt.doNotDisplaycomposition")}
                        type="checkbox"
                        id="newUserCh11"
                      />
                    </Accordion.Body>
                  </Form>
                </Accordion.Item>
                {/* /Bank slip options section end */}
                {/* Blocking and cancelation section start from here */}
                <Accordion.Item className="position-relative" eventKey="2">
                  <Form onSubmit={handleSubmit(submitCustom)}>
                    <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                      <div className="BtnAction Edit">
                        {userJson &&
                          userJson.length > 0 &&
                          userJson.indexOf(3121) !== -1 && (
                            <Button
                              variant="link"
                              size="sm"
                              className="fw-bold lh-1 text-primary p-0"
                              onClick={(e) => HandleEdit(e, "edit", "blocking")}
                            >
                              {t("button.edit")}
                            </Button>
                          )}
                      </div>
                      <div className="BtnAction Update" hidden>
                        <Button
                          variant="link"
                          size="sm"
                          className="fw-bold lh-1 text-primary p-0 me-3"
                          onClick={(e) => handleCancel(e, "blocking")}
                        >
                          {t("button.cancel")}
                        </Button>
                        <Button
                          variant="link"
                          size="sm"
                          className="fw-bold lh-1 text-primary p-0"
                          type="submit"
                          id="blocking"
                        >
                          {t("button.update")}
                        </Button>
                      </div>
                    </div>
                    <Accordion.Header>
                      {t("formReceipt.blockingAndCancellation")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <FloatingLabel
                        controlId="floatingSelect"
                        label={t("daytounavailable") + " *"}
                      >
                        <Form.Control
                          type="text"
                          className={`${errors.day ? "is-invalid" : ""} `}
                          {...register("day")}
                          placeholder="Additional Billing Instructions"
                          disabled
                          onKeyDown={numericOnly}
                        />
                        <Form.Control.Feedback type="invalid">
                          {" "}
                          {errors.day?.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Accordion.Body>
                  </Form>
                </Accordion.Item>
                {/* /Blocking and cancelation section end */}
              </Accordion>
            </Col>
            <Practiced_rates />
          </Row>
        </main>
        {/* <!-- /Main content start end --> */}
        <BankCredits
          crtsld={crtsld}
          setcrtSld={setcrtSld}
          accounttype={accounttype}
          setaccounttype={setaccounttype}
        />
      </>

      <BankAccountDrawer />

      <Modal
        show={newwayShow}
        onHide={handleClose}
        className="FormofReceiptForm"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("formReceipt.selectwayofbankheader")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning" className="mb-lg-4 mb-3 fw-medium">
            <div>{t("formReceipt.selectwayofbankcontent")}</div>
          </Alert>
          <FloatingLabel
            controlId="floatingSelect"
            label={t("bankTransaction.Paymentmethod") + " *"}
            className="mt-4"
          >
            <Form.Select
              className={`${errors.envirment ? "is-invalid" : ""} `}
              {...register("accounttype")}
              onChange={(e) => accounttypeHandle(e)}
              aria-label="Produção"
            >
              <option value="">{t("formLabel.select")}</option>
              {healthInsurance &&
                healthInsurance.length > 0 &&
                healthInsurance.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.envirment?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            {t("button.toClose")}
          </Button>
          <Button variant="primary" onClick={way_of_rec}>
            {t("button.next")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={othersShow} onHide={Other_Action_close}>
        <Modal.Header closeButton>
          <Modal.Title>{t("formReceipt.HireKobana")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-lg-4 mb-3">
            {t("formReceipt.HireKobanatoreceivewithacreditcard")}?
          </h5>
          <Alert variant="warning" className="mb-0 fw-medium">
            <div>{t("formReceipt.Youcanissueslipsusingyou")}.</div>
            <strong>{t("formReceipt.WerecommendKobanaslips")}</strong>{" "}
            {t("formReceipt.astheyarefullyautomated")}.{" "}
            <a className="fw-bold">{t("formReceipt.knowmore")}</a>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={Other_Action_close}>
            {t("button.toClose")}
          </Button>
          <Button variant="primary" onClick={OtherAction}>
            {t("button.next")}
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default FormReceiptV2;
