import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import customerService from '../../../../../../services/customerService';
import Swal from 'sweetalert2';
import { contractDatails } from '../../../../../../redux/slice/contractDataSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../redux/store';

const CompleteTerminate = (props) => {
    const [t] = useTranslation();
    const { id }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    //Complete terminate contract modal close open function
    const { showCompleteTerminate, completeTerminateClose } = props;

    const HandleComplete = () => {
        let data = {
            contract_id: id
        }
        customerService.completeTermination(data).then(async res => {
            if (res?.["data"]?.["type"] == "success" || res?.["data"]?.["type"] == "Success") {
                await dispatch(contractDatails(id));
                Swal.fire({
                    // icon:'success',
                    title:t("Sweetalert.Success"),
                    text:t("Sweetalert.successget"),
                    confirmButtonText:"OK",
                    timer:2000})
                completeTerminateClose();
            } else {
                Swal.fire({
                    title:t("Sweetalert.Error"),
                    text:"Failedtocompleteterminationprocess.",
                    // icon:"error",
                    confirmButtonText:"OK",
                    timer:2000})
            }
        })
    }
    return (
        <Modal show={showCompleteTerminate} onHide={completeTerminateClose}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>{t("terminatelist.Completetermination")}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("terminatelist.Uponconfirmingcompletionthes")}.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={completeTerminateClose}>
                        {t("button.close")}
                    </Button>
                    <Button variant="primary" onClick={() => HandleComplete()}>
                        {t("terminatelist.Confirmandissueagreement")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default CompleteTerminate
