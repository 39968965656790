import i18n from "../../i18n";
import CryptoJS from "crypto-js";
import { config, secretKey } from "../../services/apiConfig";
import { userService } from "../../services/userService";
import customerService from "../../services/customerService";
import moment from "moment-timezone";
import { AnyAction } from "@reduxjs/toolkit";
export let IMAGEURLLINK =
  "https://storage.googleapis.com/download/storage/v1/b/bucket-kenlo-wegile/o/{logoname}?generation={generation}&alt=media";
export let HITS: any = [
  {
    key: "",
    value: "",
    label: "",
    indexKey: "",
  },
];

export let DAYS: any = [];
export let dummyselect: any = [];
export let LinkHttpChecklist =
  config["CUSTOMER__API"].replace("/api", "") + "/";
export let appPlan = localStorage.getItem("XUFER");

export function encrypt_Decrypt_Plan() {
  let appPlan = localStorage.getItem("XUFER");
  if (appPlan) {
    const decryptedBytes = CryptoJS.AES.decrypt(appPlan, secretKey);
    let parsedata: any = "";
    if (decryptedBytes) {
      const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      if (decryptedString && decryptedString !== "") {
        parsedata = JSON.parse(decryptedString);
        return parsedata;
      } else {
        return parsedata;
      }
    } else {
      return parsedata;
    }
  } else {
    return false;
  }
}

export function encrypt_Decrypt() {
  let userJsonData: any = localStorage.getItem("permissionData");
  let userData: any = localStorage.getItem("userData");
  if (userJsonData && userData) {
    const decryptedBytes = CryptoJS.AES.decrypt(userJsonData, secretKey);
    let parsedata: any = [];
    if (decryptedBytes) {
      const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      if (decryptedString && decryptedString !== "") {
        parsedata = JSON.parse(decryptedString);
        return parsedata;
      } else {
        return parsedata;
      }
    } else {
      return parsedata;
    }
  } else {
    return false;
  }
}

export function formatDateToDDMMYYYY(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  //return `${day}/${month}/${year}`;
  return moment(dateString).format("DD/MM/YYYY");
}

export function addBusinessDaysToDate(date: any, days: any) {
  let dir = days < 0 ? -1 : 1;
  days = Math.abs(days);
  while (days)
    ![6, 0].includes(
      date.getUTCDay(date.setUTCDate(date.getUTCDate() + dir))
    ) && days--;
  return date.toString().replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, "$2-$1-$3");
}
export function converter(valor) {
  if(valor !== "" && valor !== null){
  const stringValue = String(valor);
  let numberWithoutCommas = stringValue?.replace(/,/g, "");
  let changeLanguage = i18n.language;
  if (changeLanguage == "pt") {
    // Remove commas only

    // Convert to float and format in Portuguese locale
    let numero: any = parseFloat(numberWithoutCommas).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return numero;
  } else {
    let numero: any = parseFloat(numberWithoutCommas).toLocaleString(
      undefined,
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );
    return numero;
  }
}
}

export function formatNumber(value) {
  // Ensure only one comma is present
  const firstCommaIndex = value.indexOf(",");
  if (firstCommaIndex !== -1) {
    value = value.slice(0, firstCommaIndex + 1) + value.slice(firstCommaIndex + 1).replace(/,/g, "");
  }
  const parts = value.split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  // Limit decimal part to 2 digits
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].slice(0, 2);
  }
  return parts.join(",");
}


export function formatInput(value1: any) {
  let value = String(value1);
  value = value.replace(/[^\d,-]/g, "");
  value = value.replace(/^(-)?0+(?=\d)/, "$1");

  let [integerPart, decimalPart] = value.split(",");
  integerPart = integerPart.replace(/\D/g, "");
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decimalPart) {
    decimalPart = decimalPart.slice(0, 2);
  }
  value = integerPart + (decimalPart ? "," + decimalPart : "");
  return value;
}

export function formatNumberEnglish(numberString: any) {
  let numberString1 = numberString.toString();
  let normalizedNumber = "";

  var lastTwoDigits = numberString1.slice(-3, -1);
  var lastChar = numberString1.charAt(numberString1.length - 3);
  if (!numberString1.includes(",")) {
    normalizedNumber = numberString1.replace(/\./g, "");
  } else if (!numberString1.includes(".")) {
    normalizedNumber = numberString1.replace(/,/g, "");
  } else if (lastTwoDigits.includes(",")) {
    let commaIndex12 = numberString1.replace(/\./g, "");
    normalizedNumber = commaIndex12.replaceAll(",", ".");
  } else if (lastTwoDigits.includes(".")) {
    normalizedNumber = numberString1.replace(/,/g, "");
  } else {
    normalizedNumber = numberString1;
  }
  const number = parseFloat(normalizedNumber);
  const formattedNumber = number.toLocaleString("en-US");
  return formattedNumber ? formattedNumber : numberString1;
}

export function formatNumberPortuges(numberString: any) {
  const number = parseFloat(numberString);
  const formattedNumber = number.toLocaleString("pt-BR");
  return formattedNumber;
}

export const minDate = () => {
  const today = new Date().toISOString().split("T")[0];
  return today;
};

export const Text_Decoder = (blobText) => {
  if (
    blobText &&
    blobText !== "null" &&
    blobText !== "undefined" &&
    blobText != "" &&
    blobText.length > 0
  ) {
    let utf8decoder = new TextDecoder();
    let u8arr = new Uint8Array(blobText);
    let changeIntoString = utf8decoder.decode(u8arr);
    return changeIntoString;
  } else {
    return "";
  }
};

let duplicateData_Check = false;
export let checkDuplicateCNJP_CPF = async (inputVaue, id) => {
  duplicateData_Check = true;
  let datafile = {
    inputVaue: inputVaue ? inputVaue : "",
    id: id ? id : "",
  };
  let resp = await checkDuplicate(datafile);
  return resp;
};

export let CNJP_CPFValidation = (inputValue, typedata) => {
  // Function to format CNPJ as users enter it
  function errorCNPJ_CPF() {
    return {
      status: false,
      message:
        i18n.language == "en"
          ? `Please enter valid ${
              typedata == "both"
                ? "CNPJ/CPF"
                : typedata == "cnpj"
                ? "CNPJ"
                : "CPF"
            }`
          : `Por favor, digite um ${
              typedata == "both"
                ? "CNPJ/CPF"
                : typedata == "cnpj"
                ? "CNPJ"
                : "CPF"
            } válido`,
      valid: "Invalid number",
    };
  }

  function extraError() {
    return {
      status: false,
      message:
        i18n.language == "en"
          ? `Only dot(.) , hypen(-) ${
              typedata == "both"
                ? ", slash(/)"
                : typedata == "cnpj"
                ? ", slash(/)"
                : ""
            } and number are allow.`
          : `Apenas ponto(.), hífen(-) ${
              typedata == "both"
                ? ", barra(/)"
                : typedata == "cnpj"
                ? ", barra(/)"
                : ""
            } e números são permitidos.`,
      valid: "Invalid number",
    };
  }

  const checkCnpjFormat = (cnpj) => {
    // CNPJ format regular expression
    const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    const pattern = /^[./0-9-]*$/;

    let replaceunwanted = cnpj
      ?.replaceAll(".", "")
      ?.replaceAll("/", "")
      ?.replaceAll("-", "");
    if (replaceunwanted.length == 14 || cnpjRegex.test(cnpj)) {
      if (
        cnpj?.includes(".") ||
        cnpj?.includes("-") ||
        cnpj?.includes("/") ||
        !pattern.test(cnpj)
      ) {
        if (cnpjRegex.test(cnpj)) {
          return {
            status: true,
            message: "1",
            valid: "CNPJ number",
            cnpj: cnpj,
            type: "cnpj",
          };
        } else {
          if (!pattern.test(cnpj) && cnpj != "") {
            return extraError();
          } else {
            return errorCNPJ_CPF();
          }
        }
      } else {
        return {
          status: true,
          message: "",
          valid: "CNPJ number",
          type: "cnpj",
        };
      }
    } else {
      if (!pattern.test(cnpj) && cnpj != "") {
        return extraError();
      } else {
        return errorCNPJ_CPF();
      }
    }
  };

  const checkCpfFormat = (cpf) => {
    // CPF format regular expression
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    const pattern = /^[.\d-]+$/;
    let replaceunwanted = cpf?.replaceAll(".", "")?.replaceAll("-", "");
    if (replaceunwanted.length == 11 || cpfRegex.test(cpf)) {
      if (cpf.includes(".") || cpf.includes("-") || !pattern.test(cpf)) {
        if (cpfRegex.test(cpf)) {
          return {
            status: true,
            message: "0",
            valid: "CPF number",
            cpf: cpf,
            type: "cpf",
          };
        } else {
          if (!pattern.test(cpf) && cpf != "") {
            return extraError();
          } else {
            return errorCNPJ_CPF();
          }
        }
      } else {
        return {
          status: true,
          message: "",
          valid: "CPF number",
          type: "cpf",
        };
      }
    } else {
      if (!pattern.test(cpf) && cpf != "") {
        return extraError();
      } else {
        return errorCNPJ_CPF();
      }
    }
  };

  let CNPJ = checkCnpjFormat(inputValue);
  let CPF = checkCpfFormat(inputValue);

  if (CNPJ?.["status"] || CPF?.["status"]) {
    if (typedata == "both") {
      return CNPJ?.["status"] ? CNPJ : CPF;
    } else if (typedata == "cnpj") {
      return CNPJ;
    } else if (typedata == "cpf") {
      return CPF;
    }
  } else {
    return !CNPJ?.["status"] ? CNPJ : CPF;
  }
};

let checkDuplicate = async (inputValue) => {
  let { inputVaue, id } = inputValue;
  let type = "";
  const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
  const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

  let data = {
    value: "",
    value2: "",
    type: "",
    id: id ? id : "",
  };

  if (cnpjRegex.test(inputVaue) && inputVaue.length == 18) {
    let replace = inputVaue
      .replaceAll(".", "")
      .replaceAll("/", "")
      .replaceAll("-", "");
    type = "cnpj";
    data["value"] = inputVaue;
    data["value2"] = replace;
  }

  if (
    cpfRegex.test(inputVaue) &&
    inputVaue.length == 14 &&
    !cnpjRegex.test(inputVaue)
  ) {
    let replace = inputVaue.replaceAll(".", "").replaceAll("-", "");
    type = "cpf";
    data["value"] = inputVaue;
    data["value2"] = replace;
  }

  if (
    !cnpjRegex.test(inputVaue) &&
    inputVaue.length == 14 &&
    !cpfRegex.test(inputVaue)
  ) {
    type = "cnpj";
    data["value"] = inputVaue.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
    data["value2"] = inputVaue;
  }

  if (!cpfRegex.test(inputVaue) && inputVaue.length == 11) {
    type = "cpf";
    data["value"] = inputVaue.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      "$1.$2.$3-$4"
    );
    data["value2"] = inputVaue;
  }

  data["type"] = type;
  if (inputVaue !== "") {
    let dataResp = await customerService.checkDuplicateCPF_CNJP_SERVICE(data);
    return dataResp;
  } else {
    return true;
  }
};

export const generateRandomString = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};

export const getAll_Default_Permission = () => {
  userService
    .getAll_default_permission()
    .then((res) => {
      let pushAllResponse: any = [];
      res &&
        res.data.length > 0 &&
        res["data"].forEach((item, index) => {
          pushAllResponse.push({
            key: `${item["ID_ACCESS_ACCPER"]}`,
            value:
              i18n.language == "en"
                ? `${item["TX_ACCESS_NAME_ACCPER"]}`
                : `${item["TX_PT_ACCESS_NAME_ACCPER"]}`,
            label:
              i18n.language == "en"
                ? `${item["TX_ACCESS_NAME_ACCPER"]}`
                : `${item["TX_PT_ACCESS_NAME_ACCPER"]}`,
            indexKey: item["ID_ACCESS_ACCPER"],
          });
        });
      HITS = pushAllResponse;
      return pushAllResponse;
    })
    .catch((err) => {});
};

getAll_Default_Permission();

export const PrintPDF = (e: any) => {
  const body = document.body;
  const html = document.documentElement;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  // Your media query
  const mediaQuery = `@media print {
          html, body, .wrapper {
            height: 100%;
            overflow: visible !important;
            width: 100%
            min-height: 100%;
          }
          .main-header, .breadcrumb-section {
            display: none;
          }
          .content-wrapper {
            margin-top: 1rem;
          }

        }`;

  // Create a style element
  const style: any = document.createElement("style");
  style.type = "text/css";

  // Add your media query to the style element
  if (style.styleSheet) {
    style.styleSheet.cssText = mediaQuery;
  } else {
    style.appendChild(document.createTextNode(mediaQuery));
  }
  // Append the style element to the head of the document
  document.head.appendChild(style);
  // Trigger the print

  let bodySection: any = document.querySelector(
    "body .wrapper section.content-wrapper"
  );
  bodySection["style"]["overflow"] = "visible";
  setTimeout(() => {
    bodySection["style"]["overflow"] = "auto";
  }, 1500);
  return window.print();
};

export const handleKeyDown = (e) => {
  // if (e.ctrlKey || e.altKey) {
  //   e.preventDefault();
  // } else {
  //   const key = e.keyCode;
  //   if (
  //     !(
  //       key === 8 || // Backspace
  //       key === 32 || // Space
  //       key === 46 || // Delete
  //       (key >= 35 && key <= 40) || // Home, End, Left Arrow, Right Arrow, Up Arrow, Down Arrow
  //       (key >= 65 && key <= 90) || // Letters A-Z
  //       (key >= 112 && key <= 123) || // Function keys F1-F12
  //       (key >= 186 && key <= 192) || // Special characters
  //       (key >= 219 && key <= 222) || // Special characters
  //       (key === 219 || key === 221) // Square brackets []
  //     )
  //   ) {
  //     e.preventDefault();
  //   }
  // }
};
export const handleGreenCheckLogic = (e: any, type: any) => {
  let id = type;
  document.getElementById(`${id}`);
  var elements = document.getElementsByClassName(`${id}`);
  // Track variables for filled, partially filled, and all empty
  var allFilled = true;
  var anyFilled = false;
  // Iterate through the elements
  for (var i = 0; i < elements.length; i++) {
    var field: any = elements[i];
    var fieldValue = field.value;
    // Check if the field is empty
    if (fieldValue === "") {
      allFilled = false;
    } else {
      anyFilled = true;
    }
  }

  // Check the results
  if (allFilled) {
    return "all";
  }
  if (anyFilled) {
    return "some";
  } else {
    return "empty";
  }
};
DAYS = [
  { key: "1", value: "MondaytoFriday" },
  { key: "2", value: "Monday" },
  { key: "3", value: "Tuesday" },
  { key: "4", value: "Wednesday" },
  { key: "5", value: "Thursday" },
  { key: "6", value: "Friday" },
  { key: "7", value: "Saturday" },
  { key: "8", value: "Sunday" },
];
dummyselect = [
  { value: 1, label: "MondaytoFriday" },
  { value: 2, label: "Monday" },
  { value: 3, label: "Tuesday" },
  { value: 4, label: "Wednesday" },
  { value: 5, label: "Thursday" },
];
export const convertToNumber = (str: any) => {
  let value = str?.toString();
  if (value.includes(",") && value.includes(".")) {
    // Handle "1.500,56" or "1,500.00" formats
    if (value.indexOf(",") < value.indexOf(".")) {
      // American format: "1,500.00"
      return parseFloat(value.replace(/,/g, ""));
    } else {
      // European format: "1.500,56"
      return parseFloat(value.replace(/\./g, "").replace(",", "."));
    }
  } else if (value.includes(",") && !value.includes(".")) {
    // European format: "500,00"
    return parseFloat(value.replace(",", "."));
  } else if (value.includes(".") && !value.includes(",")) {
    // Format: "1.000.000"
    let decimalIndex = value.indexOf(".");
    // Get the number of digits after the decimal point
    let digitsAfterDecimal = value.length - decimalIndex - 1;
    // Check if there are exactly two digits after the decimal point
    let result = digitsAfterDecimal == 1 || digitsAfterDecimal == 2;
    if (result) {
      return parseFloat(value);
    } else {
      return parseFloat(value.replace(/\./g, ""));
    }
  } else {
    // Already a valid number format
    return parseFloat(value);
  }
};

export const  getMaxAssuredValue = (contractType:any, insuran:any) =>{
   // Return maximum assured value based on contract type
  if (contractType == "1") { // Residential
    if (insuran < 150000) {
          return 150000
        } else if (insuran > 5000000) {
            return 5000000
        } else {
            return insuran
        }
  } else if (contractType == "3") { // Commercial
   if (insuran < 150000) {
         return 150000
        } else if (insuran > 2500000) {
            return 2500000
        } else {
            return insuran
        }
  } else { // Other contract types
    return insuran; // No maximum limit for other types
  }
}


export const getTitles = () =>{
const items = [
  {
    contractType: 1,
    labels: ["Fire", "Fire", ""]
  },
  {
    contractType: 1,
    labels: ["lightning", "lightning", ""]
  },
  {
    contractType: 1,
    labels: ["explosion", "explosion", ""]
  },
  {
    contractType: 1,
    labels: ["smoke", "smoke", ""]
  },
  {
    contractType: 1,
    labels: ["aircraftcrash", "aircraftcrash", ""]
  },
  {
    contractType: 1,
    labels: ["Gale", "Gale", ""]
  },
  {
    contractType: 1,
    labels: ["hurricane", "hurricane", ""]
  },
  {
    contractType: 1,
    labels: ["cyclone", "cyclone", ""]
  },
  {
    contractType: 1,
    labels: ["tornado", "tornado", ""]
  },
  {
    contractType: 1,
    labels: ["hail", "hail", ""]
  },
  {
    contractType: 1,
    labels: ["", "electricaldamage", ""]
  },
  {
    contractType: 1,
    labels: ["", "Theftofgoods", ""]
  },
  {
    contractType: 1,
    labels: ["", "Locksmith", ""]
  },
  {
    contractType: 1,
    labels: ["", "unclogging", ""]
  },
  {
    contractType: 1,
    labels: ["", "Theftofgoods", ""]
  },
  {
    contractType: 1,
    labels: ["", "electrician", ""]
  },
  {
    contractType: 1,
    labels: ["", "plumber", ""]
  },{
    contractType: 1,
    labels: ["", "removalAndFurnitureStorage", ""]
  },
  {
    contractType: 1,
    labels: ["", "glazier", ""]
  },
  {
    contractType: 3,
    labels: ["Fire", "Fire", ""]
  },
  {
    contractType: 3,
    labels: ["lightning", "lightning", ""]
  },
  {
    contractType: 3,
    labels: ["explosion", "explosion", ""]
  },
  {
    contractType: 3,
    labels: ["smoke", "smoke", ""]
  },
  {
    contractType: 3,
    labels: ["aircraftcrash", "aircraftcrash", ""]
  },
  {
    contractType: 3,
    labels: ["Gale", "ResponsabilidadeCivil", ""]
  },
  {
    contractType: 3,
    labels: ["hurricane", "Theftofgoods", ""]
  },
  {
    contractType: 3,
    labels: ["cyclone", "Glass", ""]
  },
  {
    contractType: 3,
    labels: ["tornado", "mirrors", ""]
  },
  {
    contractType: 3,
    labels: ["hail", "marble", ""]
  },
  {
    contractType: 3,
    labels: ["", "Rentalexpenses", ""]
  },
  {
    contractType: 3,
    labels: ["", "Windstorms", ""]
  },
  {
    contractType: 3,
    labels: ["", "hurricane", ""]
  },
  {
    contractType: 3,
    labels: ["", "cyclone", ""]
  },
  {
    contractType: 3,
    labels: ["", "Documentrestoration", ""]
  },
  {
    contractType: 3,
    labels: ["", "Locksmith", ""]
  },
  {
    contractType: 3,
    labels: ["", "ElectricalLabor", ""]
  },
  {
    contractType: 3,
    labels: ["", "PlumbingLabor", ""]
  },
  {
    contractType: 3,
    labels: ["", "TemporaryRoofCovering", ""]
  },
  {
    contractType: 3,
    labels: ["", "InstallationofSimpleLocks", ""]
  },
  {
    contractType: 3,
    labels: ["", "amongOthers", ""]
  }
];
  
  return items;
}
export const checkIdExists = (contractType: any, id: any) => {
  console.log(contractType, id)
  const contractTypes = [
    {
      contractType: 1,
      ids: [1, 4, 9, 29, 33, 38, 44]
    },
    {
      contractType: 3,
      ids: [3, 11, 15]
    }
  ];
  
  // Find the object with the specified contractType
  const type = contractTypes.find(item => item?.contractType === contractType);

  // Check if the id exists in the ids array of the found object
  return type ? type.ids.includes(id) : false;
}







