import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, FloatingLabel, InputGroup, Offcanvas } from 'react-bootstrap';
import SuccessMessage from './SuccessMessage';
import CommonToast from './CommonToast';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import customerService from '../../../../../../services/customerService';
import Swal from 'sweetalert2';
import { Text_Decoder, minDate } from '../../../../../constants/utils';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../redux/store';
import { contractDatails } from '../../../../../../redux/slice/contractDataSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { Termination } from '../../../../../Interface';

const ScheduleMoveOutInspection = (props) => {
    const [t] = useTranslation();
    const [loader_on, setloader_on] = useState(false);
    const [typeSubmit, settypeSubmit] = useState("draft");
    const validationSchema = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // observation_first: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        inspector: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        time: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        inspection_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
    });

    const validationSchema1 = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });
    var frm = {
        resolver: yupResolver(validationSchema1),
    };
    if (typeSubmit === "done") {
        frm = {
            resolver: yupResolver(validationSchema),
        };
    }

    const { id }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const typeValue = "schedule_moveout_inpection_XYZ";
    //Schedule move out inspection contract modal close open function
    const { showScheduleInspectionModal, scheduleInspectionModalClose, ShowThisData, terminationResp, allresponsibleList } = props;
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<Termination>(frm);

    //Success modal initialization
    const [showSuccessModal, setSuccessModal] = useState(false);
    const successModalClose = () => setSuccessModal(false);
    const successModalShow = () => {
        setSuccessModal(true);
        scheduleInspectionModalClose(false);
    }

    const Close = () => {
        scheduleInspectionModalClose();
        reset();
    }

    const [statusOfChecklist, setStatusOfChecklist] = useState(1);
    useEffect(() => {
        if (showScheduleInspectionModal) {
            setValue("responsible", "1");
            setShowResponsible(allresponsibleList[0]["agency"]);
            (async () => {
                let dataObj = {
                    type: typeValue,
                    contract_id: id
                };
                customerService.get_terminationDefaultTerminationList(dataObj).then(async (res) => {
                    if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                        let post = res["data"]["data"][0];
                        if (post !== undefined) {
                            let bolbdecode = await Text_Decoder(post["TA_FIRST_OBSERVATION_TERPOP"]["data"]);
                            setValue("id", post["ID_TERMINATIONPOPUP_TERPOP"]);
                            setValue("responsible", post["SL_RESPONSIBLE_TERPOP"]);
                            setValue("limit_date", moment(post["DT_LIMITDATE_TERPOP"].split("T")[0]).format("YYYY-MM-DD"));
                            setValue("inspection_date", moment(post["DT_INSPECTION_DATE_TERPOP"].split("T")[0]).format("YYYY-MM-DD"));
                            setValue("time", post["TM_TIME_TERPOP"]);
                            setValue("inspector", post["TE_INSPECTOR_TERPOP"]);
                            setValue("observation_first", bolbdecode);
                            setStatusOfChecklist(Number(post["ST_STATUS_TERPOP"]));

                            handleResponsible(post["SL_RESPONSIBLE_TERPOP"], post["SL_RESPONSIBLE_PARTY_NAME_TERPOP"] ?? "", "load")

                            // if (post["ST_STATUS_TERPOP"] !== 1) {
                            //     document.querySelectorAll('.DisableThis').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     });

                            //     document.querySelectorAll('.DisableThis input').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     })
                            // }
                        }
                    }
                })
            })()
        }
    }, [showScheduleInspectionModal]);

    const handleSaveDraftClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('draft');
        // Submit the form
        handleSubmit((data, e) => onScheduleInspectionFormSubmit(data, e, "draft"))()
    };

    let [showResponsible, setShowResponsible] = useState([]);
    const handleResponsible = (e, rep_id, type) => {
        setValue("responsible_party_name", "");
        let id: any = 0;
        let arrayChange: any = [];
        if (type == "form") {
            id = Number(e.target.value);
        } else {
            id = Number(e);
        }

        if (id !== "") {
            if (id == 1) {
                arrayChange = allresponsibleList[0]["agency"];
            }

            if (id == 2) {
                arrayChange = allresponsibleList[0]["owner"]
            }

            if (id == 3) {
                arrayChange = allresponsibleList[0]["tenent"]
            }

            if (id == 4) {
                arrayChange = allresponsibleList[0]["manager"]
            }

            if (id == 5) {
                arrayChange = allresponsibleList?.[0]?.["gurantor"] ?? allresponsibleList?.[0]?.["guarantor"]
            }
        }

        if (type == "load") {
            setTimeout(() => {
                setValue("responsible_party_name", rep_id);
            }, 100)
        }

        if (type == "form") {
            if (arrayChange.length > 0 && arrayChange.length < 2) {
                setTimeout(() => {
                    setValue("responsible_party_name", arrayChange[0].value);
                }, 100)
            } else {
                setTimeout(() => {
                    setValue("responsible_party_name", rep_id);
                }, 100)
            }
        }

        setShowResponsible(arrayChange);
    }

    const handleSaveCompleteClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('done')
        // Submit the form
        Swal.fire({
            // icon: 'success','warning',
            title: t("Sweetalert.WantToSave"),
            text: t("Sweetalert.Areyousuretosaveit"),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('button.confirm')
        }).then(res => {
            if (res.isConfirmed) {
                handleSubmit((data, e) => onScheduleInspectionFormSubmit(data, e, "done"))();
            }
        })
    };

    //Form submit
    const onScheduleInspectionFormSubmit = (data, e, type) => {
        setloader_on(true)

        data["type"] = typeValue;
        data["contract_id"] = id;
        if (type == "done") {
            data["status"] = 0;
        }
        if (type == "draft") {
            data["status"] = 1;
        }
        customerService.post_terminationDefaultTerminationList(data).then(async res => {
            if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                settypeSubmit('draft')
                await dispatch(contractDatails(id));
                Swal.fire({
                    // icon:'success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                reset();
            } else {
                Swal.fire({
                    // icon:'error',
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
            reset();
            scheduleInspectionModalClose(false);
            setloader_on(false);
        })
    };

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = () => {
        setToastShow(true);
        setTimeout(() => {
            setToastShow(false);
        }, 5000);
    }

    return (
        <>
            <Offcanvas show={showScheduleInspectionModal} placement="end" onHide={Close}>
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit((data, e) => onScheduleInspectionFormSubmit(data, e, ""))}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className="d-flex align-items-center justify-content-between w-100 h4">
                            {t("terminatelist.Schedulemove-outinspection")}
                            {/* <span className="fw-bold text-primary h6 mb-0 mx-2">See inspection module</span> */}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                            <Form.Control type="text"  {...register("id")} hidden readOnly />
                            <Col md={6}>
                                <FloatingLabel controlId="responsible" label={t('formLabel.responsible') + " *"}>
                                    <Form.Select aria-label="Responsible" {...register("responsible")} className={`DisableThis ${errors.responsible ? "is-invalid" : ""}`} onChange={(e) => handleResponsible(e, "", "form")}>
                                        <option value="">{t("formLabel.select")}</option>
                                        {terminationResp && terminationResp.length > 0 &&
                                            terminationResp.map((item, index) => {
                                                return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback className="invalid-feedback">{errors.responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="limit_date" label={t('terminatelist.limit') + " " + t("terminatelist.date") + " *"}>
                                    <Form.Control type="date" min={minDate()} placeholder={t('terminatelist.limit') + " " + t("terminatelist.date")} {...register("limit_date")} className={`DisableThis ${errors.limit_date ? "is-invalid" : ""}`} />
                                    <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel controlId="responsible_party_name" label={t("terminatelist.Responsibleparty'sname") + " *"} className="mb-lg-4 mb-3">
                            <Form.Select aria-label="Responsible party's name" {...register("responsible_party_name")} className={`DisableThis ${errors.responsible_party_name ? "is-invalid" : ""}`} >
                                <option value="">{t("formLabel.select")}</option>
                                {showResponsible && showResponsible.length > 0 &&
                                    showResponsible.map((item, index) => {
                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback className="invalid-feedback">{errors.responsible_party_name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <span className="fw-bold mb-3 mb-lg-4 d-block">{t("Inspectiondata")}</span>
                        <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="inspection_date" label={t("Inspectiondata") + " *"}>
                                    <Form.Control type="date" min={minDate()} placeholder={t("Inspectiondata")} {...register("inspection_date")} className={`DisableThis ${errors.inspection_date ? "is-invalid" : ""}`} />
                                    <Form.Control.Feedback className="invalid-feedback">{errors.inspection_date?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="time" label={t("NonPayment.time") + " *"}>
                                    <Form.Control type="time" placeholder={t("NonPayment.time")} {...register("time")} className={`DisableThis ${errors.time ? "is-invalid" : ""}`} />
                                    <Form.Control.Feedback className="invalid-feedback">{errors.time?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <InputGroup className="mb-lg-4 mb-3">
                            <FloatingLabel controlId="inspector" label="Inspector *">
                                <Form.Control type="text" placeholder="Inspector" {...register("inspector")} className={`DisableThis ${errors.inspector ? "is-invalid" : ""}`} />
                                <Form.Control.Feedback className="invalid-feedback">{errors.inspector?.message}</Form.Control.Feedback>
                            </FloatingLabel >
                            {/* <InputGroup.Text>
                                <img title="reNewInsurance" src="../assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20 cursor-pe" />
                            </InputGroup.Text> */}
                        </InputGroup>
                        <FloatingLabel controlId="observation" label={t("formLabel.observation")}>
                            <Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("observation_first")} className={`DisableThis ${errors.observation_first ? "is-invalid" : ""}`} />
                            <Form.Control.Feedback className="invalid-feedback">{errors.observation_first?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Offcanvas.Body>
                    {/* {
                        statusOfChecklist === 1 && */}
                    <>
                        {/* <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                <Button variant="outline-primary" onClick={handleSaveDraftClick}>
                                    {t('terminatelist.Savedraft')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('terminatelist.Saveandcomplete')}
                                </Button>
                            </div> */}
                    </>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {loader_on ?
                            <Button className="btn-width">
                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                            </Button>
                            :
                            <>
                                <Button variant="outline-primary" onClick={Close}>
                                    {t('button.close')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('button.save')}
                                </Button>
                            </>
                        }
                    </div>
                    {/* } */}
                </Form>
            </Offcanvas>
            {/* Success modal */}
            <SuccessMessage showSuccess={showSuccessModal} successClose={successModalClose} />
            {/* Toast */}
            <CommonToast showCommonToast={showToast} commonToastClose={commonToastClose} />
        </>
    )
}

export default ScheduleMoveOutInspection
