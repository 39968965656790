import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import React, { useEffect, MouseEvent, useState } from 'react';
import { envelopeClose, witnessOpen, insertEnvelope } from "../../../../redux/slice/propertyDataSlice";
import { setWitnessType, getAllWitness } from "../../../../redux/slice/contractDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { envelopeForm } from '../../../Interface';
import Swal from 'sweetalert2';
import WitnessService from '../Common/WitnessService';
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';

const EnvelopeService = (envelopeForm) => {
	const [hide, setHide] = useState(false);
	const [hide1, setHide1] = useState(true);
	const dispatch = useDispatch<AppDispatch>()
	const [t] = useTranslation();
	const { registryEnvelopeShow } = useSelector((state: RootState) => state.propertyData)
	const { witnessStatus, witnessData } = useSelector((state: RootState) => state.contractData)

	useEffect(() => {
		dispatch(getAllWitness());
	}, [dispatch])
	const step1 = () => { setHide(false); setHide1(true); }
	const step2 = () => { setHide(true); setHide1(false); }
	const newEnvelopeClose = () => {
		let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
		zipCodeCheck?.["removeAttribute"]('disabled');
		dispatch(envelopeClose(false)); reset();
	}
	const newWitnessShow = () => { dispatch(setWitnessType("")); setHide(false); setHide1(true); dispatch(witnessOpen(true)); dispatch(envelopeClose(false)); }
	const envelopeValidationStep1 = Yup.object().shape({
		person: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		email: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
			.email(t('requiredMmessage.fieldNotValid')),
		witnessPerson: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		witnessEmail: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
			.email(t('requiredMmessage.fieldNotValid')),
		rg: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		cpf: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
	});
	const envelopeValidationStep2 = Yup.object().shape({
		model: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
	});
	let frm = {
		resolver: yupResolver(envelopeValidationStep1)
	}
	if (hide) {
		frm = {
			resolver: yupResolver(envelopeValidationStep2)
		}
		openErrorbox();
	}
	const { register, reset, handleSubmit, setValue, getValues, clearErrors, setError, formState: { errors } } = useForm<envelopeForm>(frm);
	const onSubmit = async (data: envelopeForm, e: any) => {
		e.preventDefault();
		let response = await dispatch(insertEnvelope(data));
		if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
			Swal.fire({
				// icon: 'success','success',
				title: t("Sweetalert.Success"),
				text: t("Sweetalert.successget"),
				confirmButtonText: "OK",
				timer: 2000
			})
			newEnvelopeClose();
		}
		else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: t("Sweetalert.successfailed"),
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000
			})
		}
	}

	const handleCPF_CNJPVaue = async (e, type) => {
		let name = e.target.name;
		let checkCNJP = CNJP_CPFValidation(e.target.value, type);
		if (checkCNJP?.["status"] || e.target.value == "") {
			clearErrors(name)
			let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
			zipCodeCheck?.["setAttribute"]('disabled', "")
			if (e.target.value !== "") {
				let duplicateData = await checkDuplicateCNJP_CPF(e.target.value,  "");
				if (duplicateData["data"] > 0) {
					zipCodeCheck?.["setAttribute"]('disabled', "")
					setError(name, {
						type: "manual",
						message: t("formLabel.ErrorCNPJCPF")
					});
				} else {
					zipCodeCheck?.["removeAttribute"]('disabled');
				}
			}
		} else {
			// errorCNPJCPF(e.target.value)
			setError(name, {
				type: "manual",
				message: checkCNJP?.["message"]
			});
		};
	}
	return (
		<>
			{/* <!-- New branch modal start from here --> */}
			<Modal show={registryEnvelopeShow} onHide={newEnvelopeClose}>
				<Modal.Header closeButton>
					<Modal.Title>{t("Newenvelope")}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
						<div data-step="1" style={{ display: hide ? 'none' : 'block' }}>
							<span className="d-block mb-3 fw-bold">{t("contractlistother.Selectsubscribers")}</span>
							<Row className="gx-4 g-3">
								<Col me={6}>
									<FloatingLabel label={t("envelop.Person's") + " *"}>
										<Form.Control type="text" id="person" placeholder={t("envelop.Person's")+ " *"} className={`form-control ${errors.person ? 'is-invalid' : ''}`} {...register("person")} />
										<Form.Control.Feedback type="invalid">{errors.person?.message}</Form.Control.Feedback >
									</FloatingLabel>
								</Col>
								<Col me={6}>
									<FloatingLabel label={t("envelop.E-mail") + " *"}>
										<Form.Control type="email" id="email" placeholder={t("envelop.E-mail")+ " *"} className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email")} />
										<Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback >
									</FloatingLabel>
								</Col>
								<Col md={10}>
									<FloatingLabel label={t("envelop.Type")+ " *"}>
										<Form.Control id="type" type="text" placeholder={t("envelop.Type")+ " *"}  {...register('type')} />
									</FloatingLabel>
								</Col>
								<Col>
									<button className="btn btn-link bg-light border-light rounded-4">
										<svg className="cursor-pe" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd" d="M7.5 1C8.32843 1 9 1.67157 9 2.5H6C6 1.67157 6.67157 1 7.5 1ZM7.5 0C6.11929 0 5 1.11929 5 2.5H0.5C0.223858 2.5 0 2.72386 0 3L0.008056 3.08988C0.050392 3.32312 0.25454 3.5 0.5 3.5H1.053L2.35006 14.7292C2.4665 15.7384 3.321 16.5 4.33688 16.5H10.6631C11.679 16.5 12.5335 15.7384 12.6499 14.7292L13.946 3.5H14.5C14.7761 3.5 15 3.27614 15 3C15 2.72386 14.7761 2.5 14.5 2.5H10C10 1.11929 8.88071 0 7.5 0ZM2.061 3.5H12.938L11.6565 14.6146C11.5983 15.1192 11.1711 15.5 10.6631 15.5H4.33688C3.82894 15.5 3.40169 15.1192 3.34347 14.6146L2.061 3.5ZM6 6C6.24546 6 6.44961 6.15477 6.49194 6.35886L6.5 6.4375V12.5625C6.5 12.8041 6.27614 13 6 13C5.75454 13 5.55039 12.8452 5.50806 12.6411L5.5 12.5625V6.4375C5.5 6.19588 5.72386 6 6 6ZM9.49194 6.35886C9.44961 6.15477 9.24546 6 9 6C8.72386 6 8.5 6.19588 8.5 6.4375V12.5625L8.50806 12.6411C8.55039 12.8452 8.75454 13 9 13C9.27614 13 9.5 12.8041 9.5 12.5625V6.4375L9.49194 6.35886Z" fill="#242424"></path>
										</svg>
									</button>
								</Col>
							</Row>
							<Accordion className="accordion-custom">
								<Accordion.Item eventKey="1" >
									<Accordion.Header>{t("envelop.Witnesses")}</Accordion.Header>
									<Accordion.Body>
										<Row>
											<Col>
												<InputGroup>
													<FloatingLabel controlId="floatingInput" label={t("contractsettings.Service")+ " *"}>
														<Form.Select className={`form-control ${errors.witnessPerson ? 'is-invalid' : ''}`} {...register('witnessPerson')} >
															<option value="">{t('dropdownItem.select')}..</option>
															{witnessData && witnessData.length > 0 && witnessData.map((item) => (
																<option key={item.id} value={item.id}>{item.name}</option>
															))}
														</Form.Select>
														<Form.Control.Feedback type="invalid">{errors.witnessPerson?.message}</Form.Control.Feedback >
													</FloatingLabel>
													<InputGroup.Text onClick={newWitnessShow} >
														<img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20" />
													</InputGroup.Text>

												</InputGroup>
											</Col>
											<Col>
												<FloatingLabel className="mb-3" label={t("envelop.E-mail")+ " *"}>
													<Form.Control id="witnessEmail" type="text" placeholder={t("envelop.E-mail")} className={`form-control ${errors.witnessEmail ? 'is-invalid' : ''}`} {...register('witnessEmail')} />
													<Form.Control.Feedback type="invalid">{errors.witnessEmail?.message}</Form.Control.Feedback>
												</FloatingLabel>
											</Col>
										</Row>
										<Row>
											<Col md={5}>
												<FloatingLabel className="mb-3" label="CPF">
													<Form.Control id="cpf" type="text" placeholder="CPF" className={`form-control ${errors.cpf ? 'is-invalid' : ''}`} {...register('cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
													<Form.Control.Feedback type="invalid">{errors.cpf?.message}</Form.Control.Feedback>
												</FloatingLabel>
											</Col>
											<Col md={5}>
												<FloatingLabel className="mb-3" label="RG *">
													<Form.Control id="rg" type="text" placeholder="RG" className={`form-control ${errors.rg ? 'is-invalid' : ''}`} {...register('rg')} />
													<Form.Control.Feedback type="invalid">{errors.rg?.message}</Form.Control.Feedback>
												</FloatingLabel>
											</Col>
											<Col>
												<button type="button" className="btn btn-link bg-light border-light rounded-4">
													<svg width="12px" height="15px" viewBox="0 0 10 13" version="1.1" xmlns="http://www.w3.org/2000/svg" >
														<title>user_g</title>
														<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g id="Contracts_Search_bar" transform="translate(-644.000000, -882.000000)" fill="#888888" fillRule="nonzero">
																<g id="user_g" transform="translate(644.000000, 882.000000)">
																	<path d="M8.5,6.5 C9.3284,6.5 10,7.1716 10,8 L10,8.5 C10,10.4714 8.1405,12.5 5,12.5 C1.8595,12.5 0,10.4714 0,8.5 L0,8 C0,7.1716 0.6716,6.5 1.5,6.5 L8.5,6.5 Z M5,0 C6.5188,0 7.75,1.2312 7.75,2.75 C7.75,4.2688 6.5188,5.5 5,5.5 C3.4812,5.5 2.25,4.2688 2.25,2.75 C2.25,1.2312 3.4812,0 5,0 Z" id="Shape">
																	</path>
																</g>
															</g>
														</g>
													</svg>
												</button>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2" >
									<Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
									<Accordion.Body>
										<Row>
											<Col>
												<Form.Group>
													<Form.Check type="checkbox" label={t("envelop.Insertrealestatesignature")} {...register('isSignature')} />
												</Form.Group>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
						<div data-step="2" style={{ display: hide1 ? 'none' : 'block' }}>
							<span className="d-block mb-3 fw-bold">{t("documents")}</span>
							<Row>
								<Col md={10}>
									<FloatingLabel className="mb-3" label={t("formLabel.model")} >
										<Form.Control id="model" type="text" placeholder={t("formLabel.model")} className={`form-control ${errors.model ? 'is-invalid' : ''}`} {...register('model')} />
										<Form.Control.Feedback type="invalid">{errors.model?.message}</Form.Control.Feedback>
									</FloatingLabel>
								</Col>
								<Col>
									<button className="btn btn-link bg-light border-light rounded-4">
										<svg className="cursor-pe" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd" d="M7.5 1C8.32843 1 9 1.67157 9 2.5H6C6 1.67157 6.67157 1 7.5 1ZM7.5 0C6.11929 0 5 1.11929 5 2.5H0.5C0.223858 2.5 0 2.72386 0 3L0.008056 3.08988C0.050392 3.32312 0.25454 3.5 0.5 3.5H1.053L2.35006 14.7292C2.4665 15.7384 3.321 16.5 4.33688 16.5H10.6631C11.679 16.5 12.5335 15.7384 12.6499 14.7292L13.946 3.5H14.5C14.7761 3.5 15 3.27614 15 3C15 2.72386 14.7761 2.5 14.5 2.5H10C10 1.11929 8.88071 0 7.5 0ZM2.061 3.5H12.938L11.6565 14.6146C11.5983 15.1192 11.1711 15.5 10.6631 15.5H4.33688C3.82894 15.5 3.40169 15.1192 3.34347 14.6146L2.061 3.5ZM6 6C6.24546 6 6.44961 6.15477 6.49194 6.35886L6.5 6.4375V12.5625C6.5 12.8041 6.27614 13 6 13C5.75454 13 5.55039 12.8452 5.50806 12.6411L5.5 12.5625V6.4375C5.5 6.19588 5.72386 6 6 6ZM9.49194 6.35886C9.44961 6.15477 9.24546 6 9 6C8.72386 6 8.5 6.19588 8.5 6.4375V12.5625L8.50806 12.6411C8.55039 12.8452 8.75454 13 9 13C9.27614 13 9.5 12.8041 9.5 12.5625V6.4375L9.49194 6.35886Z" fill="#242424">
											</path>
										</svg>
									</button>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-primary" onClick={newEnvelopeClose}>
							{t('button.close')}
						</Button>
						<Button variant="outline-primary" onClick={handleSubmit(step1)} className="js-btn-step">
							{t('button.previous')}
						</Button>
						{
							hide1 ?
								<Button variant="primary" onClick={handleSubmit(step2)} className="min-w-auto js-btn-step zipCodeCheck">
									{t('button.next')}
								</Button>
								:
								<Button variant="secondary" type="submit" onClick={openErrorbox}>
									{t('button.save')}
								</Button>
						}
					</Modal.Footer>
				</Form>
			</Modal>
			{/* <!-- /New branch modal end --> */}
			<WitnessService />
		</>
	)
}
export default EnvelopeService;