// Aaditya aaditya code
import { Form,Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { surveyorOpen,inspectionClose,insertInspection } from "../../../../redux/slice/propertyDataSlice";
import * as Yup        							from 'yup';
import { useForm  }    							from "react-hook-form";
import { useNavigate, useParams } 							from 'react-router-dom';
import { yupResolver } 							from '@hookform/resolvers/yup';
import React,{ useEffect,useState } from 'react';
import { useDispatch,useSelector } 	from 'react-redux';
import { AppDispatch,RootState }   	from '../../../../redux/store';
import { useTranslation }           from 'react-i18next';
import { inspectionForm }           from '../../../Interface';
import Swal                         from 'sweetalert2';
import SurveyorService              from '../Common/SurveyorService';
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import customerService from '../../../../services/customerService';
const InspectionService = (inspectionForm) => {
  const dispatch 									 = useDispatch<AppDispatch>()
  const [t] 											 = useTranslation();
  const { registryInspectionShow } = useSelector((state: RootState) => state.propertyData)
  const {id}:any                   = useParams();
  const newInspectionClose 	= () => {  dispatch(inspectionClose(false)); reset(); }
  const newSurveyorShow     = () => { dispatch(surveyorOpen(true)); dispatch(inspectionClose(false)); }
  const inspectionValidation = Yup.object().shape({
    date: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    hour: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    surveyor: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    powerMeter: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    energyReading: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    waterMeter: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    readingMeter: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    gasMeter: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    gasReading: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')), 
    furnished: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')), 
    cleaned: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')), 
    comments: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),       
  });
  let frm = {
    resolver: yupResolver(inspectionValidation)
  }
  const { register,reset,handleSubmit,setValue,getValues,formState: { errors } } = useForm<inspectionForm>(frm);
  const onSubmit = async (data: inspectionForm,e: any) => {
    e.preventDefault();
    let response = await dispatch(insertInspection(data));
    if(typeof response.payload !=="undefined" && response.payload.resCode===202){
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:t("Sweetalert.successget"),
        confirmButtonText:"OK",
        timer:2000})    
      newInspectionClose(); 
    }
    else {
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }
  const [surveyors, setSurveyors] = useState<{ id: number; fantasy_name: string }[]>([]);
  useEffect(() =>{
  setValue("property_id", id);
  customerService.getSurveyors().then((res) =>{
    if(res.data.resCode == 201){
      setSurveyors(res.data.data);
    }
  })
  },[]);

  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={registryInspectionShow} onHide={newInspectionClose}>
        <Modal.Header closeButton>
          <Modal.Title>New inspection</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Control type="text" {...register('property_id')} hidden />
            <Row>
	            <Col md={9}>
	                <FloatingLabel className="mb-3" label="Date">
	                    <Form.Control type="date" id="date" placeholder="Date" className={`form-control ${errors.date ? 'is-invalid' : ''}`} {...register("date")} />
	                    <Form.Control.Feedback type="invalid">{errors.date?.message}</Form.Control.Feedback >	                    
	                </FloatingLabel>
	            </Col>
	            <Col>
                  <FloatingLabel controlId="das" label="Hour" >
                    <Form.Control type="time" className={`form-control ${errors.hour ? 'is-invalid' : ''}`} placeholder="Hour"  {...register('hour')} />
                  </FloatingLabel>
	            </Col>
            </Row>            
            <Row>
              <Col>
                <InputGroup>
                  <FloatingLabel label="Surveyor">
                    {/* <Form.Control id="surveyor" type="text" placeholder="Surveyor" className={`form-control ${errors.surveyor ? 'is-invalid' : ''}`} {...register('surveyor')}/> */}
                    <Form.Select id="surveyor" aria-label="Furnished" className={`form-control ${errors.surveyor ? 'is-invalid' : ''}`} {...register('surveyor')}>
                    <option value="">{t('dropdownItem.select')}..</option>
                    {surveyors.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.fantasy_name}
                      </option>
                    ))}
							      </Form.Select>
                	</FloatingLabel>
                  
                  <InputGroup.Text onClick={newSurveyorShow}>
                    <img src={process.env.PUBLIC_URL+"/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20" />
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">{errors.surveyor?.message}</Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Row>
            <br/>
            <Accordion className="accordion-custom">
              <Accordion.Item eventKey="1" >
                <Accordion.Header>Readings</Accordion.Header>
	                <Accordion.Body>
		                <Row>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Power meter">
			                        <Form.Control id="powerMeter" type="text" placeholder="Power meter" className={`form-control ${errors.powerMeter ? 'is-invalid' : ''}`} {...register('powerMeter')}/>
			                        <Form.Control.Feedback type="invalid">{errors.powerMeter?.message}</Form.Control.Feedback>
			                    </FloatingLabel>
		                    </Col>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Energy reading">
			                        <Form.Control id="energyReading" type="text" placeholder="Energy reading" className={`form-control ${errors.energyReading ? 'is-invalid' : ''}`} {...register('energyReading')}/>
			                        <Form.Control.Feedback type="invalid">{errors.energyReading?.message}</Form.Control.Feedback>
			                    </FloatingLabel>
		                    </Col>
		                </Row>  
		                <Row>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Water meter">
			                        <Form.Control id="waterMeter" type="text" placeholder="Water meter" className={`form-control ${errors.waterMeter ? 'is-invalid' : ''}`} {...register('waterMeter')}/>
			                        <Form.Control.Feedback type="invalid">{errors.waterMeter?.message}</Form.Control.Feedback>
			                    </FloatingLabel>
		                    </Col>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Reading meter">
			                        <Form.Control id="readingMeter" type="text" placeholder="Reading meter" className={`form-control ${errors.readingMeter ? 'is-invalid' : ''}`} {...register('readingMeter')}/>
			                        <Form.Control.Feedback type="invalid">{errors.readingMeter?.message}</Form.Control.Feedback>
			                    </FloatingLabel>
		                    </Col>
		                </Row> 
		                <Row>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Gas meter">
			                        <Form.Control id="gasMeter" type="text" placeholder="Gas meter" className={`form-control ${errors.gasMeter ? 'is-invalid' : ''}`} {...register('gasMeter')}/>
			                        <Form.Control.Feedback type="invalid">{errors.gasMeter?.message}</Form.Control.Feedback>
			                    </FloatingLabel>
		                    </Col>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Gas reading">
			                        <Form.Control id="gasReading" type="text" placeholder="Gas reading" className={`form-control ${errors.gasReading ? 'is-invalid' : ''}`} {...register('gasReading')}/>
			                        <Form.Control.Feedback type="invalid">{errors.gasReading?.message}</Form.Control.Feedback>
			                    </FloatingLabel>
		                    </Col>
		                </Row>                 
	                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" >
                <Accordion.Header>Additional information</Accordion.Header>
	                <Accordion.Body>
		                <Row>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Furnished">
							                <Form.Select id="furnished" aria-label="Furnished" className={`form-control ${errors.furnished ? 'is-invalid' : ''}`} {...register('furnished')}>
							                    <option value="">{t('dropdownItem.select')}..</option>
							                    <option value="1">No</option>
		                              <option value="2">Yes</option>
							                </Form.Select>
							                <Form.Control.Feedback type="invalid">{errors.furnished?.message}</Form.Control.Feedback>
							            </FloatingLabel>
		                    </Col>
		                    <Col>
			                    <FloatingLabel className="mb-3" label="Cleaned">
							                <Form.Select id="cleaned" aria-label="Cleaned" className={`form-control ${errors.cleaned ? 'is-invalid' : ''}`} {...register('cleaned')}>
							                    <option value="">{t('dropdownItem.select')}..</option>
							                    <option value="1">No</option>
		                              <option value="2">Yes</option>
							                </Form.Select>
							                <Form.Control.Feedback type="invalid">{errors.cleaned?.message}</Form.Control.Feedback>
							            </FloatingLabel>
		                    </Col>
		                </Row>  
		                
		                <Row>
		                    <Col>
			                    <FloatingLabel label="Comments" >
			                        <Form.Control as="textarea" id="comments" placeholder="Comments" {...register('comments')} style={{ height: '100px' }} />
			                    </FloatingLabel>
			                </Col>
		                </Row>                 
	                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={newInspectionClose}>
              {t('button.close')}
            </Button>
            <Button type="submit" variant="primary" onClick={openErrorbox}>
              {t('button.toSave')}
            </Button>
          </Modal.Footer>            
        </Form>
      </Modal>
      <SurveyorService/>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default InspectionService;

// Aaditya aaditya code end