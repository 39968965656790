import React from "react";

type EmptyListProps = {
  text?: string
}

export function EmptyList({ text = "Nenhum resultado." }: EmptyListProps) {
  return (
    <div className="text-center">
      {text}
    </div>
  )
}
