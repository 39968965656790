import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FloatingLabel, Col, Row, Form, Button, Accordion, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { getAccountCategory } from '../../../../redux/slice/financialDataSlice';
import { AccountSettingsli, AddNewItem, getItem } from '../../../../redux/slice/AccountPlanSlice';
const AccountPlanSettingModel = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { accountCatData } = useSelector((state: RootState) => state.financialData);
    const { t } = useTranslation();

    const Schema = yup.object().shape({
        bank_fee: yup.string().required(t("requiredMmessage.fieldRequired")),
        bill_fee: yup.string().required(t("requiredMmessage.fieldRequired")),
    });
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ resolver: yupResolver(Schema) });
    const { Showsetting, setShowsetting } = props;
    useEffect(() => {
        dispatch(getAccountCategory());
    }, [dispatch]);

    const handleClosesetting = () => {
        setShowsetting(false);
    };

    const onSubmitsetting = async (data: any) => {
        // Prepare object with data from the form
        let obj = {
          DESCRICAO_CONTA_ESPECIAL_TARIFABANCARIA: data.bank_fee,
          DESCRICAO_CONTA_ESPECIAL_TARIFABOLETO: data.bill_fee,
        };
        // Dispatch API call to add a new item
        let res = await dispatch(AccountSettingsli(obj));
        if (res.payload.type == "success" || res.payload.type == "Success") {
          // Show success message when item is created
          Swal.fire({
            position: "center",
            title: t("Sweetalert.Success"),
            text: t("Sweetalert.successget"),
          }).then((result) => { 
            dispatch(getItem());
            if (result.isConfirmed) { 
                reset();
                //   setShow(false);
            }
          });
        } else {
          Swal.fire({
            position: "center",
            title: t("Sweetalert.Addothernumber"),
            text: t("Sweetalert.AccountNoNeedtobeunique"),
          });
        } 
    }; 
    return (
        <Modal show={Showsetting} onHide={handleClosesetting}>
            <Form onSubmit={handleSubmit(onSubmitsetting)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("formLabel.settings")}</Modal.Title>
                </Modal.Header>
                <h5 className="mx-4">{t("chartofaccount.specialcateforyaccount")}</h5>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <FloatingLabel label={t("chartofaccount.Categoryaccountforbankfees")} className={`mb-lg-4 mb-3`} >
                                <Form.Select className={`form-control`} aria-label={"Category account for bank fees"} {...register("bank_fee")}>
                                    <option value="">{t('dropdownItem.select')}..</option>
                                    {
                                        accountCatData && accountCatData.length > 0 &&
                                        accountCatData.map((item, index) => {
                                            if (item?.childData && (item.childData).length > 0) {
                                                return (
                                                    <optgroup key={index} label={`${item.ST_CONTA_CONT} ${item.ST_DESCRICAO_CONT}`}>
                                                        {
                                                            item?.childData && (item.childData).length > 0 &&
                                                            item.childData.map((firstItem, index) => {
                                                                if ((firstItem?.childData && firstItem.childData).length > 0) {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <option value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                                                            {
                                                                                firstItem.childData && (firstItem.childData).length > 0 &&
                                                                                (firstItem.childData).map((seconditem, index) => {
                                                                                    if (seconditem?.childData && (seconditem.childData).length > 0) {
                                                                                        return (
                                                                                            <React.Fragment key={index}>
                                                                                                <option value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                                                                                {
                                                                                                    seconditem?.childData && (seconditem.childData).length > 0 &&
                                                                                                    (seconditem.childData).map((thirditem, index) => {
                                                                                                        if (thirditem?.childData && (thirditem.childData).length > 0) {
                                                                                                            (thirditem.childData).map((fourthitem, index) => {
                                                                                                                return (
                                                                                                                    <option key={index} value={fourthitem.ST_CONTA_CONT}>{fourthitem.ST_CONTA_CONT} {fourthitem.ST_DESCRICAO_CONT}</option>
                                                                                                                )
                                                                                                            })
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <option key={index} value={thirditem.ST_CONTA_CONT}>{thirditem.ST_CONTA_CONT} {thirditem.ST_DESCRICAO_CONT}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <option key={index} value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <option key={index} value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </optgroup>
                                                )
                                            }
                                        })
                                    }
                                </Form.Select>
                                {/* <Form.Control.Feedback type="invalid">{errors.accountCategory?.message}</Form.Control.Feedback> */}
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FloatingLabel label={t("chartofaccount.Categoryaccountforbillfees")} className={`mb-lg-4 mb-3`} >
                                <Form.Select className={`form-control`} aria-label={"Category account for bill fees"}  {...register("bill_fee")}>
                                    <option value="">{t('dropdownItem.select')}..</option>
                                    {
                                        accountCatData && accountCatData.length > 0 &&
                                        accountCatData.map((item, index) => {
                                            if (item?.childData && (item.childData).length > 0) {
                                                return (
                                                    <optgroup key={index} label={`${item.ST_CONTA_CONT} ${item.ST_DESCRICAO_CONT}`}>
                                                        {
                                                            item?.childData && (item.childData).length > 0 &&
                                                            item.childData.map((firstItem, index) => {
                                                                if ((firstItem?.childData && firstItem.childData).length > 0) {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <option value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                                                            {
                                                                                firstItem.childData && (firstItem.childData).length > 0 &&
                                                                                (firstItem.childData).map((seconditem, index) => {
                                                                                    if (seconditem?.childData && (seconditem.childData).length > 0) {
                                                                                        return (
                                                                                            <React.Fragment key={index}>
                                                                                                <option value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                                                                                {
                                                                                                    seconditem?.childData && (seconditem.childData).length > 0 &&
                                                                                                    (seconditem.childData).map((thirditem, index) => {
                                                                                                        if (thirditem?.childData && (thirditem.childData).length > 0) {
                                                                                                            (thirditem.childData).map((fourthitem, index) => {
                                                                                                                return (
                                                                                                                    <option key={index} value={fourthitem.ST_CONTA_CONT}>{fourthitem.ST_CONTA_CONT} {fourthitem.ST_DESCRICAO_CONT}</option>
                                                                                                                )
                                                                                                            })
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <option key={index} value={thirditem.ST_CONTA_CONT}>{thirditem.ST_CONTA_CONT} {thirditem.ST_DESCRICAO_CONT}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <option key={index} value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <option key={index} value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </optgroup>
                                                )
                                            }
                                        })
                                    }
                                </Form.Select>
                                {/* <Form.Control.Feedback type="invalid">{errors.accountCategory?.message}</Form.Control.Feedback> */}
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClosesetting}>
                        {t("button.close")}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t("button.toSave")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AccountPlanSettingModel