import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button, Card, Dropdown } from 'react-bootstrap';
import { handleAction, handleSubmitData } from './notification';
import { useForm, } from "react-hook-form";
import { modal_reducer, insurance_reducer } from "../../../../redux/slice/notificationSlice";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Insurance = (props:any) => {
    let { dispatch, stateData , notificationData} = props;
    const navigate = useNavigate();

    // The useForm hook is used to handle form registration, submission, and validation.
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({});
    const [object, setObject] = useState([]);
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation()
    const { insurance } = stateData;

    let basicdata = {
        dispatch,
        stateData,
        modal_reducer,
        insurance_reducer
    }
    useEffect(() => {
        if(notificationData){
          setValue("send_notification_before", Number(notificationData["insurance_send_notification_before"]))
          setValue("days_before", Number(notificationData["insurance_days_before"]))
          setValue("send_notification_after", Number(notificationData["insurance_send_notification_after"]))
          setValue("days_after", Number(notificationData["insurance_days_after"]))
          
        }
      },[notificationData])

    useEffect(() => {
        // Set the initial value of 'object' to insurance.basic_data
        setObject(insurance.basic_data);
    }, [])

    useEffect(() => {
        // Get the fields from the 'birthday.basic_data' object
        let fields: string[] = Object.keys(insurance.basic_data);
        // Set the values of the fields using the 'setValue' function from the form library
        fields.forEach((field: any) => setValue(field, insurance?.basic_data?.[field]));
    }, [object, stateData])

    let page = "insurance";
    // Function to handle form submission
    const onSubmit = async (data: any) => {
        let checkSubmition = true;
        // Check if any input field value contains a '-'
        document.querySelectorAll(`.${page}.removeInsertDisable`).forEach(item => {
            if (item["value"].includes('-')) {
                checkSubmition = false;
            }
        })
        if (checkSubmition) {
            // Proceed with form submission
            handleSubmitData(data);
        } else {
            // Show an error message
            Swal.fire({
                position: 'center',
                // icon: 'success','error',
                title: `${t('Sweetalert.Error')}`,
                text: `${t('Sweetalert.negativeNumberNotAllowed')}`,
                showConfirmButton: true,
                timer: 1000
            })
        }
    }

    const handleEmailName = (id,NOME, LABEL) => {
        document.querySelector('#root')?.setAttribute('st_nome_moh', NOME);
        document.querySelector('#root')?.setAttribute('st_label_moh', LABEL);
        localStorage.setItem("YhGUFY_hygft",id);
        let data = {
          notification: "notification"
        }
        navigate("/message", { state: data });
    }

    return (
        <>
            <Col lg={6}>
                <Card className="h-100">
                    <Card.Header className="d-flex justify-content-between">
                        <div className="w-80 me-2">{t("notifications.insuranceExpiration")}<img src="assets/images/icons/info-light.svg" alt="Info icon" className="h-18 ms-2" /></div>
                        <span className='d-block TOEDIT'>
                            <span>
                                <span>
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "insurance", basicdata)}>{t("button.edit")}</Button>
                                </span>
                            </span>
                        </span>
                        <div className="d-none ACTION">
                            <Row>
                                <Col xl={4} sm={4}>
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "save", "insurance", basicdata)} >{t("button.save")}</Button>
                                </Col>
                                {/* <Col xl={4} sm={4}>
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "edit", "insurance", basicdata)}>Edit</Button>
                                </Col> */}
                                <Col xl={4} sm={4}>
                                    <Dropdown className="radio--custom" autoClose="outside">
                                        <Dropdown.Toggle className='p-0 text-nowrap' variant='link' size="sm">
                                            <b>{t("button.edit")}</b>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <label className="d-block cursor-pe" htmlFor="ft7" onClick={() => handleEmailName("23","O seguro do seu contrato de locação vence em breve", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft7" />
                                                <div className="radio-inner px-4 py-2">
                                                    <b>{t("notifications.beforeExpiration")}</b>
                                                </div>
                                            </label>
                                            <label className="d-block cursor-pe" htmlFor="ft8" onClick={() => handleEmailName("24","O seguro do seu contrato de locação venceu", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft8" />
                                                <div className="radio-inner px-4 py-2">
                                                    <b>{t("notifications.afterExpiration")}</b>
                                                </div>
                                            </label>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col xl={4} sm={4}>
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "cancel", "insurance", basicdata)} >{t("button.cancel")}</Button>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>

                    <Card.Body>
                        <Form onSubmit={handleSubmit(onSubmit)} >
                            <Form.Check className="mb-3 mb-lg-4 removeInsertDisable" {...register("send_notification_before")} type="checkbox" id={`default_8`} label={t("notifications.checkNotification")} disabled />
                            <Row className="gx-3 gy-xl-4 gy-3 align-items-center">
                                <Col sm={3} xl={2}>
                                    <Form.Control type="number" className={`${page} removeInsertDisable`} {...register("days_before")} aria-label="days" min="0" disabled />
                                </Col>
                                <Col className="col-sm">{t("notifications.daysBeforeExpiration")}</Col>
                            </Row>
                            <Form.Check className="my-3 my-lg-4 removeInsertDisable" {...register("send_notification_after")} type="checkbox" id={`default_9`} label={t("notifications.checkNotification")} disabled />
                            <Row className="gx-3 gy-xl-4 gy-3 align-items-center">
                                <Col sm={3} xl={2}>
                                    <Form.Control type="number" className={`${page} removeInsertDisable`} {...register("days_after")} aria-label="days" min="0" disabled />
                                </Col>
                                <Col className="col-sm">{t("notifications.daysAfterExpiration")}</Col>
                            </Row>
                            <Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}

export default Insurance