import React from 'react'; 
import { Page, Text, Image, Document, StyleSheet, View, Font } from '@react-pdf/renderer' 
import { Text_Decoder } from '../../../constants/utils';
const styles = StyleSheet.create({
    warpper:{backgroundColor:"#fff",width:"100%",margin:'0',padding:'0',},
    wrapper_body:{maxWidth:"800px",margin:"auto",width:"100%"},
    content:{margin:"015px",padding:"50px20px",width:"100%"},
    textStyle:{textAlign:"left",fontSize:"14px",lineHeight:"2px",color:"#333333",fontWeight:"normal",marginBottom:"5px",marginTop:"40px",width:"100%"},
    textStyle2:{textAlign:"left",fontSize:"14px",color:"#333333",fontWeight:"normal",marginBottom:"5px",marginTop:"10px",width:"100%"},
    textStyle3:{fontSize:"14px",color:"#333333",fontWeight:"normal",marginBottom:"25px",marginTop:"10px",width:"100%"},
    imagecontainer:{display:"flex",flexDirection:"row",position:"relative"},
    logoimage:{padding:"20px",width:150,height:40,objectFit:"cover"},
    body:{paddingTop:35,paddingBottom:65,paddingHorizontal:35,},
    title:{fontSize:24,textAlign:'center',fontFamily:'Oswald'},
    author:{fontSize:12,textAlign:'center',marginBottom:40,},
    subtitle:{fontSize:18,margin:12,fontFamily:'Oswald'},
    text:{margin:12,fontSize:14,textAlign:'justify',fontFamily:'Times-Roman'},
    image:{marginVertical:15,marginHorizontal:100,},
    header:{fontSize:12,marginTop:"30px",marginBottom:"15px",textAlign:'left',color:'grey',},
    pageNumber:{position:'absolute',fontSize:12,bottom:30,left:0,right:0,textAlign:'center',color:'grey',},
    container:{display:"flex",justifyContent:'space-between',marginBottom:10,marginTop:"10px"},
    label:{fontWeight:'bold',marginRight:20,fontSize:"10px",},
    value:{fontWeight:'bold',marginRight:20,fontSize:"10px",},
});
const EmailSeen = (props) => {
    let paraData
    let viewTransferData
    let { emailData, type } = props;
    if (type == "view Statement") {
        viewTransferData = emailData
    } else {
        let header = emailData?.[0]?.["ST_ASSUNTO_FE"];
        let htmlData = Text_Decoder(emailData?.[0]?.["ST_EML_FE"]?.["data"]);
        paraData = htmlData.replace(/<[^>]*>/g, '');
    }
    return (
        <>
            <Document author="Kenlo"
                keywords="email"
                subject="Email term"
                title="Email Details">
                <Page style={styles.body}>
                    <View style={styles.warpper}>
                        <View style={styles.wrapper_body}>
                            <View style={styles.content}>
                                <View style={styles.imagecontainer}>
                                    <View>
                                        <Image src={process.env.PUBLIC_URL + "/logo.png"} style={styles.logoimage} />
                                        <div style={{ borderTop: "1px solid red", maxWidth: "180px" }}></div>
                                    </View>
                                </View>
                                {type == "view Statement" ?
                                    <>
                                        {viewTransferData.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>

                                                    <Text style={{ fontSize: "10px", marginTop: "10px" }}>
                                                        Contrato {item.contractId}/1 - Apartamento no Nova Campinas, Acesso À Avenida José de Souza Campos 93 304 - Taxa adm 10,00%
                                                    </Text>
                                                    <Text style={{ fontSize: "10px" }}>
                                                        Locatário: {item?.tenant[0]?.ST_NOME_PES}
                                                    </Text>
                                                    <View>
                                                        <Text style={styles.textStyle2}>
                                                            Posição em 15/03/2024;
                                                        </Text>
                                                        <View style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            
                                                            marginBottom: "5px"
                                                        }}>
                                                            {/* Your content here */}

                                                            <Text style={styles.label}> Cobrança de 10/04/2024 liquidada em {item.liquidate}</Text>
                                                            <Text style={styles.label}>Value</Text>
                                                        </View>

                                                        <View style={{
                                                            flexDirection: 'row', justifyContent: 'space-between', 
                                                            backgroundColor: 'rgba(128, 128, 128, 0.1)',
                                                        }}>
                                                            <Text style={styles.value}>{item.newItems[0].ip1} {item.contractStart} até {item.liquidate}</Text>
                                                            <Text style={styles.value}>{item.newItems[0].ip2}</Text>
                                                        </View>
                                                        <View style={{
                                                            flexDirection: 'row', justifyContent: 'space-between', 
                                                            marginBottom: "5px",
                                                            borderBottomColor: 'gray', borderBottomWidth: 1,
                                                            marginTop:"5px"
                                                        }}>
                                                            <Text style={styles.label}> Taxa de administração </Text>
                                                            <Text style={styles.value}>{item.newItems[1].ip2}</Text>
                                                        </View>    
                                                        <View style={{
                                                            flexDirection: 'row', justifyContent: 'space-between', 
                                                            marginBottom: "5px"
                                                        }}>
                                                            <Text style={styles.label}> Total do repasse  </Text>
                                                            <Text style={styles.value}>{item.paid}</Text>
                                                        </View>                       
                                                    </View>

                                                </React.Fragment>
                                            );
                                        })}


                                    </>
                                    :
                                    <>
                                        <Text style={styles.textStyle}>
                                            Hello  {paraData}
                                        </Text>
                                        <View>
                                            <Text style={styles.textStyle3}>
                                                We would like to inform you that you have not yet completed the pending issues regarding the Entry checklist, see below what needs to be completed:
                                            </Text>
                                            <Text style={styles.textStyle2}>
                                                Remember that the items provided are very important for us to complete this process.
                                            </Text>
                                            <Text style={styles.textStyle2}>
                                                If you have any questions or clarifications, feel free to contact us:
                                            </Text>
                                            <Text style={styles.textStyle2}>
                                                Yours sincerely!
                                            </Text>
                                            <Text style={styles.textStyle2}>
                                                INGAIA
                                            </Text>
                                        </View></>
                                }

                            </View>
                        </View>
                    </View>
                </Page>
            </Document >
        </>
    )
}

export default EmailSeen