import React, { useState } from 'react';
import { Form, InputGroup, Col, Row } from 'react-bootstrap';
import Settings from './Settings';
import { useTranslation } from 'react-i18next';

const CommonHeader = (props) => {
    let { searchFilter, getNonPaymentSummery } = props;
    const [t] = useTranslation();
    //Settings modal close open function
    const [showSettingsModal, setSettingsModal] = useState(false);
    const settingsModalClose = () => setSettingsModal(false);
    const settingsModalShow = () => setSettingsModal(true);
    return (
        <>
            <div className="w-100 mb-xl-4">
                <Row className="gx-xl-5 justify-content-between align-items-center">
                    <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                        <h1 className="mb-0 lh-base text-truncate me-xl-1">{t("Reports.Nonpayment")}</h1>
                    </Col>
                    <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                        {/* Search bar */}
                        <InputGroup>
                            <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                onChange={(e) => searchFilter(e.target.value)} />
                            <InputGroup.Text id="search_icon" >
                                <img src="./assets/images/icons/search.svg" alt="Search icon" />
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                            </li>
                            <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                <img src="assets/images/icons/settings-light.svg" alt="Settings" onClick={settingsModalShow} className="h-20 cursor-pe" />
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
            {/* modal start */}
            <Settings showSettings={showSettingsModal} settingsClose={settingsModalClose}
                settingsShow={settingsModalShow} getNonPaymentSummery={getNonPaymentSummery} />
        </>
    )
}

export default CommonHeader
