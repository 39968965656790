import React, { useState, useEffect } from 'react';
import { FloatingLabel, Table, Form, Badge, Card, Dropdown, Col, Row, Modal, Button, ButtonGroup, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setEndDate, setStartDate, getTransferSimulate, transferToSwitchOff, getTransferContractsCount, setCheckedId, transferContracts, setTransferId, setMaturityOpen, setFilterType, setTansferFilterOpen, InsertIssueInvoice } from '../../../../redux/slice/transferDataSlice';
import { useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
//import moment from "moment";
import moment from "moment-timezone";
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import RevokeTransfers from './RevokeTransfers';
import Pagination from "../../../Pagination";
import Checkbox from '../../Contracts/Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import { AppDispatch, RootState } from '../../../../redux/store';
import { converter, encrypt_Decrypt, encrypt_Decrypt_Plan } from '../../../constants/utils';
import TransfersSimulate from './TransferSimulate/TransfersSimulate';
import transferService from '../../../../services/transferService';
import TransferEmail from './TransferEmail';
import { PdfGenerator, TransferCom, contractDatails, showEmail } from '../../../../redux/slice/contractDataSlice';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EmailSeen from '../../Contracts/Common/EmailSeen';
import { fetchService } from '../../../../redux/slice/ServiceSlice';
import { getAllAccounts, getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { config } from '../../../../services/apiConfig';
import i18n from '../../../../i18n';

const TansfersListing = (props: any) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

    const { filterParams, updateFilter, moreFiltersClose, state, setState, currentPage, setCurrentPage } = props;
    const [totalis, setTotalis] = useState<any>(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [simulateData, setSimulateData] = useState<any>([]);

    const [summaryData, setSummaryData] = useState<any>([]);
    const [financialData, setFinancialData] = useState<any>([]);
    const [totalSummaryAmt, setTotalSummaryAmt] = useState<any>(0);
    const [path, setPath] = useState<any>("");


    const [mailData, setMailData] = useState<any>([])
    const { transferContractCountData, transferCountStatus,
        transferContractData, transferStatus, transferFilter } = useSelector((state: RootState) => state.transferData);
    const { accountData, billingData } = useSelector((state: RootState) => state.expenditureData);
    const { TransferType } = useSelector((state: RootState) => state.contractData)
    const { taxadata, taxalist } = useSelector((state: RootState) => state.taxa)
    const [t] = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");

    const [loader_on, setloader_on] = useState(false);
    const [userPlanString, setUserPlanString] = useState("");
    useEffect(() => {
        let userPLAN = encrypt_Decrypt_Plan();
        // setUserPlanString(userPLAN)
        setUserPlanString("300")
    }, [location])

    useEffect(() => {
        if (filterParams?.["filterType"] == "resetfilter") {
            setState({
                //transferType: "",
                filterby: "",
                owner: "",
                Withpaidcharges: "",
                Withchargessettledbetween: "",
                Duetotheduedatebetween: "",
                startDate: startDt,
                searchDate: searchDt,
                endDate: endDt,
                filteSearchDate: "",
                filterEndDate: "",
                start: 0,
                end: 20
            })
        }
    }, [filterParams])

    //Revoke transfers modal close open function
    const [showRevokeTransfersModal, setRevokeTransfersModal] = useState(false);
    const revokeTransfersModalClose = () => setRevokeTransfersModal(false);
    const revokeTransfersModalShow = () => {
        if (isCheck.length) {
            setRevokeTransfersModal(true);
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    //Transfers simulate modal close open function
    const [showTransferSimulationModal, setTransferSimulationModal] = useState(false);
    const transferSimulationModalClose = () => {
        getTransferContracts(state);
        setIsCheck([]);
        setTransferSimulationModal(false);
    }
    const transferSimulationModalShow = () => setTransferSimulationModal(true);

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }
    };
    useEffect(() => {
        if (isCheck.length) {
            let total: number = 0;
            (transferContractData.data).forEach(trdata => {
                if (isCheck.indexOf(trdata.id) >= 0) {
                    if (trdata && trdata.total) {
                        var matchh = String(trdata.total).match(/[0-9,.]*/);
                        if (matchh !== null) {
                            var amount = parseFloat(matchh[0].replace(/,/g, '')); // replace , thousands separator
                            total += amount;
                        }
                    }
                }
            });
            setTotalis(total);
        }
    }, [handleClick])

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(transferContractData.data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false);
        setIsCheck([]);
    }

    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        setIsCheck([]);
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
        dispatch(getAllBillMethodListing({ start: 0, end: 1000 }));
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
    }, [])

    /*----------Pagination---------*/
    let PageSize = 20;
    useEffect(() => {
        setState({
            ...state,
            ['start']: (currentPage > 1 ? currentPage - 1 : 0) * 20,
            ['end']: 20
        })
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1);
        if (filterParams.transferType) {
            setState({
                ...state,
                ...filterParams,
            })
        }
        else {
            setState({
                ...state,
                ['start']: 0,
                ['end']: 20,
                ['transferType']: filterParams.transferType ? filterParams.transferType : "upcoming"
            })
        }
    }, [dispatch, updateFilter]);

    useEffect(() => {
        if (location.state == "completed") {
            dispatch(TransferCom("completed"))
        } else {
            dispatch(TransferCom("upcoming"))
        }
        window.history.replaceState(null, '');
    }, [])

    useEffect(() => {
        if (location.state && location?.state?.dashboard == "yes") {
            state["location"] = location.state
            filterParams["dueDate"] = location.state["searchForm"]
            filterParams["expireDate"] = location.state["endForm"]
            state["status"] = ["pending"]
            setState({
                ...state,
                ...filterParams
            })
        }
    }, [location]);

    useEffect(() => {
        if (location.state && location?.state?.dashboard == "yes") {
            state["location"] = location.state
            if (location.state.type == "overdue" || location.state.type == "dueToday") {
                state["status"] = ["pending"]
            }
            setTimeout(() => {
                getTransferContracts(state);
            }, 900)
        } else {
            getTransferContracts(state);
        }
    }, [dispatch, state]);

    const getTransferContracts = async (postData) => {
        dispatch(setStartDate(postData.startDate));
        dispatch(setEndDate(postData.endDate));
        let response = await dispatch(transferContracts(postData));
    }
    const [isLoading, setisLoading] = useState(false);
    const transferSimulate = async () => {
        if (isCheck.length > 0) {
            setSimulateData([]);
            setisLoading(true);
            await transferService.getTransferSimulate({ expenseId: isCheck })
                .then((res) => {
                    if (res.status == 200) {
                        setisLoading(false);
                        setSimulateData(res.data.data);
                        setSummaryData(res.data.summaryResult);
                        setFinancialData(res.data.financialResult);
                        setTotalSummaryAmt(res.data.totalSummaryAmt);
                        setPath(res.data.path)
                        transferSimulationModalShow();
                    }
                })
                .catch((err) => {
                    setisLoading(false);
                    console.log("err", err);
                });
        } else {
            setisLoading(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }


    const [fetchEmailData, setfetchEmaildata] = useState<any>([]);
    const [firstLoad, setFirstLoad] = useState(false);
    const handleMouseEvent = async (e: any, type) => {
        e.preventDefault();
        if (isCheck.length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            // Function to find owner name by ID
            if (type == "viewStatement") {
                setFirstLoad(true)
                await transferService.getTransferSimulate({ expenseId: isCheck })
                    .then((res) => {
                        if (res.status == 200) {
                            if (res.data.data && res.data.data.length) {
                                res.data.data.map((itm: any, index: number) => {
                                    res.data.data[index]['newItems'] = [];
                                    res.data.data[index]['newItems'].push({
                                        ip1: "Standard rent (21/24)",
                                        ip2: itm.total
                                    });
                                    let adminAmount = parseFloat(itm.adminAmount);
                                    res.data.data[index]['newItems'].push({
                                        ip1: "Administration fee",
                                        ip2: (-1 * adminAmount)
                                    });
                                    if (itm.fine > 0) {
                                        res.data.data[index]['newItems'].push({
                                            ip1: "Fee",
                                            ip2: (itm.fine)
                                        });
                                    }
                                    if (itm.interest > 0) {
                                        res.data.data[index]['newItems'].push({
                                            ip1: "Interest",
                                            ip2: (itm.interest)
                                        });
                                    }
                                    if (itm.newItemsAdded) {
                                        itm.newItemsAdded.map((nwitm, index) => {
                                            res.data.data[index]['newItems'].push(
                                                {
                                                    ip1: nwitm.ST_COMPLEMENTO_COMP,
                                                    ip2: nwitm.ST_VALOR_COMP ? nwitm.ST_VALOR_COMP : 0,
                                                    delete: "true"
                                                },
                                            );
                                        });
                                        // simulateData[index]['newItems'] = newitem;
                                    }
                                });
                            }
                            setfetchEmaildata(res.data.data)
                            // setSimulateData(res.data.data);
                        }
                    }).catch((err) => {
                        console.log("err", err);
                    });

            } else {
                dispatch(showEmail(true))

                let matchingData: any = [];
                isCheck.forEach((id) => {
                    transferContractData.data.forEach((data) => {
                        if (data.id == id && data.owner.length > 0) {
                            matchingData?.push(data);
                        }
                    });
                })
                setMailData(matchingData);
                data?.forEach((item, index) => {
                    let mailData: any = []; // Initialize mailData array for each iteration
                    if (item["checked"]) {
                    }
                });
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    const triggerDownload = async (url: any, filename: any) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filename}`
        link.target = '_blank'; // Opens in a new tab
        link.rel = 'noopener noreferrer'; // Security best practice
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [pdfUrl1, setPdfUrl1] = useState<any>(null);
    const [pdfUrl2, setPdfUrl2] = useState<any>(null);

    const fun = () => {
        let allTransferDta = transferContractData["data"];
        let result: any = allTransferDta.filter((item: any) => isCheck.includes(item.id));
        let PdfArr: any = [];
        const promises = result.map(async (item: any, index: number) => {
            let transferSimulateData: any = await transferService.getTransferSimulate({ expenseId: [item.id] });
            let summaryData = transferSimulateData?.["data"];
            if (summaryData) {
                let data = await dispatch(contractDatails(item.contractId));
                let dataResult = data?.["payload"]?.["data"]?.[0];
                let Rece_First_day_maturity: any = ""; // First Date
                let Period_second_date: any = ""; // Second Date
                let Period_billing_method = dataResult?.["paymentMethod"]; // 0 - expiry month 1 - month due

                if (Period_billing_method) {
                    //  ############### --------- Month Due ---------- ############ 
                    if (dataResult["lease_period"]) {
                        // FL_MESVENCIDO_CON ==1 -- close month
                        Rece_First_day_maturity = moment(item.maturity, "DD/MM/YYYY").startOf("month").format("DD/MM/YYYY");
                        Period_second_date = moment(item.maturity, "DD/MM/YYYY").endOf("month").format("DD/MM/YYYY");
                    } else {
                        // FL_MESVENCIDO_CON == 0 -- open month
                        Rece_First_day_maturity = moment(item.maturity, "DD/MM/YYYY").format("DD/MM/YYYY");
                        Period_second_date = moment(item.maturity, "DD/MM/YYYY").add(1, "month").format("DD/MM/YYYY");
                    }
                } else {
                    //  ############### --------- Expiry Month ---------- ############ 
                    if (dataResult["lease_period"]) {
                        // FL_MESVENCIDO_CON ==1 -- close month
                        Rece_First_day_maturity = moment(item.maturity, "DD/MM/YYYY").startOf("month").format("DD/MM/YYYY");
                        Period_second_date = moment(item.maturity, "DD/MM/YYYY").endOf("month").format("DD/MM/YYYY");
                    } else {
                        // FL_MESVENCIDO_CON == 0 -- open month
                        Rece_First_day_maturity = moment(item.maturity, "DD/MM/YYYY").subtract(1, "month").format("DD/MM/YYYY");
                        Period_second_date = moment(item.maturity, "DD/MM/YYYY").format("DD/MM/YYYY");
                    }
                }

                let periodDate: any = {
                    first: moment(Rece_First_day_maturity, "DD/MM/YYYY").format("DD/MM/YYYY"),
                    second: moment(Period_second_date, "DD/MM/YYYY").format("DD/MM/YYYY"),
                    maturity: moment(item.maturity, "DD/MM/YYYY").format("DD/MM/YYYY")
                }

                PdfArr.push({
                    data: data?.["payload"]?.["data"],
                    item: item,
                    accountData: accountData,
                    billingData: billingData,
                    summaryData: summaryData,
                    Period_billing_method: Period_billing_method,
                    period: periodDate
                });
            }
        });

        return {
            promises,
            PdfArr
        }
    }

    const handleViewStatement = async (e: any, type: any) => {
        e.preventDefault();
        setloader_on(true);
        if (isCheck && isCheck.length > 0) {
            let promisesDta = fun();
            let { promises, PdfArr } = promisesDta;
            // Wait for all promises to complete
            await Promise.all(promises).then(() => {
                RunAferComplete(PdfArr);
            }).catch((e: any) => {
                setloader_on(false);
                console.error("Failed to generate PDF: ", e);
            });

        } else {
            setloader_on(false);
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    let RunAferComplete = async (PdfArr: any) => {
        let PdfGeneratorDta = await dispatch(PdfGenerator({ CreatePdfData: PdfArr }));
        let fullPath = PdfGeneratorDta["payload"]["data"];
        // setPdfUrl1(`${config.CUSTOMER___API}/${fullPath["owner_extract"]}`);
        // setPdfUrl2(`${config.CUSTOMER___API}/${fullPath["transfer_statement"]}`);
        setloader_on(false);
        // let url = `${config.CUSTOMER___API}/${fullPath["owner_extract"]}`;
        let url = `${fullPath["gcpu_upload_file"]}`;
        window.open(url, "_blank")
    }

    let RunAferComplete1 = async (PdfArr: any) => {
        let PdfGeneratorDta = await dispatch(PdfGenerator({ CreatePdfData: PdfArr }));
        let fullPath = PdfGeneratorDta["payload"]["data"];
        let url = `${fullPath["gcpu_upload_file"]}`;
        return url
    }

    useEffect(() => {
        if (firstLoad) {
            setTimeout(() => {
                let email: any = document?.querySelector('.emailclicked');
                email.click();
            }, 500)
        }
    }, [fetchEmailData])
    const [loading, setLoading] = useState(false)
    const IssueInvoice = async () => {
        if (isCheck.length > 0) {
            if (isCheck.length < 2) {
                let message = `${t('Sweetalert.Alreadyconnected')} <br/><br/>`;
                const response = await transferService.getTransferSimulate({ expenseId: isCheck });
                if (response.status === 200) {
                   
                    response?.data?.data?.forEach((item:any) => {
                        message += `${item.ownerList[0].ST_NOME_PES} - ${converter(String(item?.adminAmount))}` ;
                    })
                }
                const item = transferContractData.data.find(item => item.id == isCheck);
                if (item.issueInvoce !== "" && item.contractIssur == 1) {
                    Swal.fire({
                        position: 'center',
                        title: `${t('Sweetalert.Error')}`,
                        html: message,
                        showCancelButton: true,
                        cancelButtonText: `${t("close")}`,
                        showConfirmButton: false
                    });
                } else if (item.issueInvoce == "" && item.contractIssur == 0) {
                    Swal.fire({
                        position: 'center',
                        title: `${t('Sweetalert.Error')}`,
                        html: message,
                        showCancelButton: true,
                        cancelButtonText: `${t("close")}`,
                        showConfirmButton: false

                    });
                } else {
                    try {
                        Swal.fire({
                            text: t("Sweetalert.confirmInvoice"),
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: t("Yesproceed"),
                            cancelButtonText: t("Nocancel"),
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                        setLoading(true)
                        const newData: any[] = [];
                        let message1 =''
                        const response = await transferService.getTransferSimulate({ expenseId: isCheck });
                        if (response.status === 200) {
                            response?.data?.data?.forEach(item => {
                                newData.push({
                                    recbId: item.id,
                                    contractId: item.contractId,
                                    propertyId: item.propertyId,
                                    ownerId: item.ownerList[0]?.ID_PESSOA_PES,
                                    recMaturity2: moment(item.liquidate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                    invoice: item.invoice,
                                    adminAmount: item.adminAmount,
                                    branchName: item.branchName
                                });
                                message1 += `${item.ownerList[0].ST_NOME_PES} - ${converter(String(item?.adminAmount))}` ;
                            });
                        }
                        // Dispatch InsertIssueInvoice action
                        const Trresponse = await dispatch(InsertIssueInvoice({ InvoiceData: newData }));
                        if (Trresponse.payload.resCode == "202") {
                            const { existingCount, nonInvoiceCount, insertedCount } = Trresponse?.payload?.data?.res;
                            let message = `${t('totalInvoice')} ${isCheck.length} ${t("Links.invoices")} : `;

                            if (existingCount > 0) {
                                message += ` ${existingCount}  ${t('Sweetalert.Alreadyconnected')} `;
                            }
                            if (insertedCount > 0) {
                                message += ` ${insertedCount} ${t("Sweetalert.insertRecord")} , `;
                            }
                            if (nonInvoiceCount > 0) {
                                message += `${t("remaining")} ${nonInvoiceCount}  ${t('Sweetalert.issueInvoce')} `;
                            }

                            if (existingCount === 0 && nonInvoiceCount === 0 && insertedCount <= 0) {
                                message = t("Sweetalert.somethingWent")
                            }

                            Swal.fire({
                                position: 'center',
                                title: insertedCount == 0 ? `${t('Sweetalert.Error')}` : `${t('Sweetalert.Success')}`,
                                html: `${message} <br/><br/>${message1}`,
                                showCancelButton: true,
                                cancelButtonText: `${t("close")}`,
                                showConfirmButton: false
                            });
                            getTransferContracts(state);
                            setLoading(false)

                        } else if (Trresponse.payload.resCode == "403") {
                            Swal.fire({
                                position: 'center',
                                title: `${t('Sweetalert.Error')}`,
                                html: `${t('Sweetalert.issueInvoce')} <br/><br/> ${message1}`,
                                showCancelButton: true,
                                cancelButtonText: `${t("close")}`,
                                showConfirmButton: false
                            });
                            setLoading(false)
                        } else if (Trresponse.payload.resCode == "209") {
                            Swal.fire({
                                position: 'center',
                                title: `${t('Sweetalert.Error')}`,
                                html: `${t('Sweetalert.Alreadyconnected')}<br/><br/> ${message1}`,
                                showCancelButton: true,
                                cancelButtonText: `${t("close")}`,
                                showConfirmButton: false
                            });
                            setLoading(false)
                        } else if (Trresponse.payload.resCode == "410") {
                            Swal.fire({
                                position: 'center',
                                title: `${t('Sweetalert.Error')}`,
                                html: `${t('Sweetalert.adminfee')} <br/><br/> ${message1}` ,
                                showCancelButton: true,
                                cancelButtonText: `${t("close")}`,
                                showConfirmButton: false
                            });
                            setLoading(false)
                        } else {
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                html: `${Trresponse.payload.message} <br/><br/> ${message1}` ,
                                timer: 2000,
                            });
                            setLoading(false)
                        }
                    }
                })
                    } catch (error) {
                        console.error("Error in IssueInvoice:", error);
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            timer: 2000,
                        });
                    }
                    setLoading(false)
                }
            } else {
                try {
                    Swal.fire({
                        text: t("Sweetalert.confirmInvoice"),
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t("Yesproceed"),
                        cancelButtonText: t("Nocancel"),
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                    setLoading(true)
                    const newData: any[] = [];
                    let message1 = ""
                    const response = await transferService.getTransferSimulate({ expenseId: isCheck });
                    if (response.status === 200) {
                        response?.data?.data?.forEach(item => {
                            newData.push({
                                recbId: item.id,
                                contractId: item.contractId,
                                propertyId: item.propertyId,
                                ownerId: item.ownerList[0]?.ID_PESSOA_PES,
                                recMaturity2: moment(item.liquidate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                invoice: item.invoice,
                                adminAmount: item.adminAmount ? item.adminAmount : 0,
                                branchName: item.branchName
                            });
                            message1 += `${item.ownerList[0].ST_NOME_PES} - ${converter(String(item?.adminAmount))} <br />` ;
                        });
                    }
        
                    // Dispatch InsertIssueInvoice action
                    const Trresponse = await dispatch(InsertIssueInvoice({ InvoiceData: newData }));
                    if (Trresponse.payload.resCode == "202") {
                        const { existingCount, nonInvoiceCount, insertedCount } = Trresponse?.payload?.data?.res;
                        let message = `${t('totalInvoice')} ${isCheck.length} ${t("Links.invoices")} : `;

                        if (existingCount > 0) {
                            message += ` ${existingCount}  ${t('Sweetalert.Alreadyconnected')} `;
                        }
                        if (insertedCount > 0) {
                            message += ` ${insertedCount} ${t("Sweetalert.insertRecord")} , `;
                        }
                        if (nonInvoiceCount > 0) {
                            message += `${t("remaining")} ${nonInvoiceCount}  ${t('Sweetalert.issueInvoce')} `;
                        }

                        if (existingCount === 0 && nonInvoiceCount === 0 && insertedCount <= 0) {
                            message = t("Sweetalert.somethingWent")
                        }

                        Swal.fire({
                            position: 'center',
                            title: insertedCount == 0 ? `${t('Sweetalert.Error')}` : `${t('Sweetalert.Success')}`,
                            html: `${message} <br/><br/> ${message1}`,
                            showCancelButton: true,
                            cancelButtonText: `${t("close")}`,
                            showConfirmButton: false
                        });
                        getTransferContracts(state);
                        setLoading(false)
                    } else if (Trresponse.payload.resCode == "403") {
                        Swal.fire({
                            position: 'center',
                            title: `${t('Sweetalert.Error')}`,
                            html: `${t('Sweetalert.issueInvoce')} <br/><br/> ${message1}`,
                            showCancelButton: true,
                            cancelButtonText: `${t("close")}`,
                            showConfirmButton: false
                        });
                        setLoading(false)
                    } else if (Trresponse.payload.resCode == "209") {
                        Swal.fire({
                            position: 'center',
                            title: `${t('Sweetalert.Error')}`,
                            html: `${t('Sweetalert.Alreadyconnected')} <br/><br/> ${message1}`,
                            showCancelButton: true,
                            cancelButtonText: `${t("close")}`,
                            showConfirmButton: false
                        });
                        setLoading(false)
                    } else if (Trresponse.payload.resCode == "410") {
                        Swal.fire({
                            position: 'center',
                            title: `${t('Sweetalert.Error')}`,
                            html: `${t('Sweetalert.adminfee')} <br/><br/> ${message1}`,
                            showCancelButton: true,
                            cancelButtonText: `${t("close")}`,
                            showConfirmButton: false
                        });
                        setLoading(false)
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: Trresponse.payload.message,
                            timer: 2000,
                        });
                        setLoading(false)
                    }
                }
            })
                } catch (error) {
                    console.error("Error in IssueInvoice:", error);
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.somethingWent"),
                        timer: 2000,
                    });
                }
                setLoading(false)
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.Noinvoiceselected"),
                icon: "warning",
                confirmButtonText: t("Sweetalert.ConfirmButtonText"),
                timer: 2000,
            });
            setLoading(false)
        }
    };


    return (
        <>
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
                {
                    (transferStatus == "loading") ?
                        <Loader />
                        :
                        <>
                            <div className='hidden' hidden>
                                <PDFDownloadLink document={<EmailSeen emailData={fetchEmailData} type={"view Statement"} />} fileName={`Email_${new Date(dateBrazil).getTime()}.pdf`}>
                                    <Button className={"emailclicked"}>
                                        email
                                    </Button>
                                </PDFDownloadLink>
                            </div>
                            <Card>
                                <Card.Body className="pb-1">
                                    <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th>{
                                                    transferContractData.data && transferContractData.data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        : ''
                                                }</th>
                                                <th>{t("transfer_forecast")}</th>
                                                <th>{t("value")}</th>
                                                <th>{t("newTransfer.charge")}</th>
                                                <th>{t("newTransfer.contract")}</th>
                                                <th>{t("dropdownItem.status")}</th>
                                                <th className="text-end"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                transferContractData.data &&
                                                    transferContractData.data.length > 0 ?
                                                    transferContractData.data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className={"check_Checkbox"}>
                                                                <Checkbox key={item.id} type="checkbox" name={item.id} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-dark">{item.recMaturity2}</span>
                                                                {
                                                                    item.FL_STATUS_RECB == '5' || item.FL_GARANTIDO_REP==1 &&
                                                                    <div className="d-block"><Badge bg="success-10" className="text-dark">{t("guaranteed")}</Badge></div>

                                                                }
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-dark fw-bold text-nowrap">R$ {converter(`${item.total}`)}</span>
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-dark text-nowrap"> {t("charge")} {item.id} • R$ {converter(`${item.transferAmount}`)}</span>
                                                                <span className="d-block">{t("newTransfer.settledIn")} {item.recMaturity}</span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    userJson && userJson.length > 0 && (userJson.indexOf(2030) !== -1) &&
                                                                    <span className="d-block text-dark">
                                                                        {item.contractId !== null ? item.contractId && <Link to={"/contract_details/" + item.contractId} > <span className="text-dark-70">{t("Dashboard.Contract")} {item.propertIndentifier}/ {item.lease_count ? item.lease_count : ""} </span> </Link> : ""}

                                                                        <img src="assets/images/icons/home_g.svg" />
                                                                        <span className="ps-1">
                                                                            {item.address ? item.address + ',' : ''}{" "}
                                                                            {item.city ? item.city + ',' : ''} {item.state ? item.state + ' -' : ''} {item.zipcode}
                                                                        </span>
                                                                    </span>
                                                                }
                                                                {
                                                                    item.owner.length > 0 ? item.owner.map((items: any, indx: number) => (
                                                                        <React.Fragment key={indx}>
                                                                            &nbsp;
                                                                            <Link to={'/'} className="text-reset check_Checkbox1" key={indx} ><img src="assets/images/icons/user_g.svg" className="me-1" /> {items.name}</Link>
                                                                        </React.Fragment>
                                                                    ))
                                                                        : ''
                                                                }
                                                            </td>
                                                            <td>
                                                                
                                                                {item && item.FL_STATUS_REP == 0 &&
                                                                    <Badge bg="success-40" className="text-dark">{t("newTransfer.Pending")}</Badge>
                                                                }
                                                                {item && (item.FL_STATUS_REP == 1 || item.FL_STATUS_REP == 4) && item.FL_STATUS_RECB != '4' &&
                                                                    <Badge bg="success-40" className="text-dark">{t("newTransfer.Settled")}</Badge>
                                                                }

                                                                {item && (item.FL_STATUS_REP == 2 || item.FL_STATUS_RECB == '4') && item.FL_STATUS_REP != 4 &&
                                                                    <Badge bg="success-40" className="text-dark">{t("newTransfer.Postedinfinancial")} </Badge>
                                                                }
                                                                {item && item.FL_STATUS_REP == 3 && item.FL_STATUS_RECB != '3' &&
                                                                    <Badge bg="success-40" className="text-dark">{t("newTransfer.revoke")}</Badge>
                                                                }

                                                                <br />
                                                                {item && item && (item.FL_STATUS_REP == 1 || item.FL_STATUS_REP == 2 || item.FL_STATUS_REP == 4) && item.FL_STATUS_RECB != '4' && item.issueInvoce !== "" &&
                                                                    <Badge className="text-white ms-1" bg="warning">{t("NFissued")} </Badge>
                                                                }
                                                                {item && item && (item.FL_STATUS_REP == 1 || item.FL_STATUS_REP == 2 || item.FL_STATUS_REP == 4) && item.FL_STATUS_RECB != '4' && item.issueInvoce == "" &&
                                                                    <Badge bg="warning-40" className="text-dark">{t("Pendinginvoice")}</Badge>
                                                                }
                                                            </td>
                                                            <td className="text-end">
                                                                <Link to={'/contract_details/' + item.contractId} className="text-primary fw-bold">{t("newTransfer.openContract")}</Link>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr>
                                                        <td colSpan={7} className="text-center">
                                                            <span>{t('formLabel.noRecordsFounds')}</span>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </>
                }

                <div className="d-flex justify-content-center mt-lg-4 mt-3">
                    {
                        (transferContractData.totalRow > 0 && location?.state?.dashboard !== "yes") ?
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={transferContractData.totalRow > 0 ? transferContractData.totalRow : 0}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                            :
                            ''
                    }
                </div>

            </main>
            {
                isCheck && isCheck.length > 0 &&
                <footer className="footer-fixed-lg bg-white border-top py-2 px-xl-5 px-md-4 px-3 mt-auto">
                    <ul className="mb-0 list-inline d-flex justify-content-md-end align-items-center flex-wrap">
                        <li className="list-inline-item border-sm-none border-end pe-md-3 pe-xl-4 me-lg-3 me-xl-4 text-lg-end">
                            {
                                isCheck.length ? t(isCheck.length === 1 ? 'TransferPage.select' : 'TransferPage.selects', { value: isCheck.length }) : ''
                            }
                            {filterParams && filterParams.transferType == 'upcoming' &&
                                <span className="d-block fw-bold">R$ ${totalis}</span>
                            }
                        </li>
                        {(filterParams && filterParams.transferType == 'completed' || TransferType == "completed") &&
                            userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) ?
                            <>
                                <li className="list-inline-item me-md-3 me-xl-4">
                                    <Button variant="link" className="px-0" onClick={(e) => handleMouseEvent(e, "sendEmails")}>{t("newTransfer.sendByEmail")}</Button>
                                </li>
                                <li className="list-inline-item me-md-3 me-xl-4 ">
                                    <Button variant="link" className="px-0" onClick={revokeTransfersModalShow}>{t("newTransfer.revoke")}</Button>
                                    <RevokeTransfers showRevokeTransfers={showRevokeTransfersModal} revokeTransfersClose={revokeTransfersModalClose} isCheck={isCheck} state={state} />
                                </li>
                                {
                                    userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" &&
                                    <li className="list-inline-item me-md-3 me-xl-4">
                                        {loading ?
                                            <>
                                                <span className="spinner-border spinner-border-sm"></span>
                                                <span className="px-1">{t("Processing")}</span>
                                            </> :
                                            <Button variant="outline-primary" onClick={IssueInvoice}>{t("newTransfer.issueInvoice")}</Button>}
                                    </li>
                                }
                                {/* <li className="list-inline-item">
                                <Button variant="primary" onClick={(e) => handleMouseEvent(e, "viewStatement")}> {t("newTransfer.viewStatement")}</Button>
                            </li> */}
                                <li className="list-inline-item">
                                    {
                                        loader_on ?
                                            <Button className="btn-width">
                                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                            </Button> :
                                            <Button variant="primary" onClick={(e) => handleViewStatement(e, "viewStatement")}> {t("newTransfer.viewStatement")}</Button>
                                    }
                                </li>

                            </>
                            :
                            <li className="list-inline-item">
                                {
                                    isLoading ?
                                        <Button className="btn-width">
                                            <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("TransferPage.Simulatetransfer")}</span>
                                        </Button>
                                        :
                                        isCheck.length &&
                                            userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) ?
                                            <Button variant="primary" onClick={transferSimulate}>{t("TransferPage.Simulatetransfer")}</Button>
                                            :
                                            ''
                                }
                            </li>
                        }

                        {/* <Link to={pdfUrl1} className='PdfUrlLinkOne' download={`Owner_Extract_${moment().format("YYYYMMDDHHssmm")}`}>Link Download</Link>
                    <Link to={pdfUrl2} className='PdfUrlLinkTwo' download={`Transfer_Statement_${moment().format("YYYYMMDDHHssmm")}`}>Link Download</Link> */}
                    </ul>
                </footer>
            }

            <TransfersSimulate showTransferSimulation={showTransferSimulationModal}
                transferSimulationClose={transferSimulationModalClose}
                simulateData={simulateData}
                summaryData={summaryData}
                financialData={financialData}
                totalSummaryAmt={totalSummaryAmt}
                isCheck={isCheck}
                path={path}
            />
            <TransferEmail mailData={mailData} fun={fun} RunAferComplete1={RunAferComplete1} />
        </>
    )
}

export default TansfersListing
