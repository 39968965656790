import axios from 'axios';
import { config } from './apiConfig';
import { gettoken } from './apiConfig';
axios.defaults.headers.common['Content-Type']    = "application/json";
axios.defaults.headers.common['x-auth-token']    = localStorage.getItem("tokenV2");//gettoken.Token;
axios.defaults.headers.common['connection-name'] = localStorage.getItem("userData");
export const MessageService = {
    getSingleMessage: async function (id) {
        const response = await axios.request({
            url: `${config.companyV2_URL}/notify/billing`,
            method: "POST",
            data: {
                user_id: gettoken.userId,
                ID_MODELO_MOH: id
            }
        })
        return response.data
    },
    UpdateSingleMessage: async function (data) {
        data['ID_EMPRESA_EMP'] = gettoken.userId;
        const response = await axios.request({
            url: `${config.companyV2_URL}/notify/billing`,
            method: "PUT",
            data: {
                ...data
            }
        })
        return response.data
    },
}