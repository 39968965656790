import { Dropdown, Table, Accordion, FloatingLabel, Col, Row, Modal, Form, Button } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect } from 'react';
import { CustomersAndEmployeesForm } from '../../../../Interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {  closeEmail, showEmail } from '../../../../../redux/slice/contractDataSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
const SendEamilAdmini = (CustomersAndEmployeesForm) => {
    const [t] = useTranslation();
    /*--------Data table listing state--------*/
    const dispatch = useDispatch<AppDispatch>()
    const {emailShow} = useSelector((state:RootState) => state.contractData)
    /*--------Email modal state--------*/
    // const [show2, setShow2] = useState(false);
    const [mailText, setMailText] = useState("Send Email");
    let frm: any = "";
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);
    const mailClose = () => { dispatch(closeEmail(false)); reset(); }
    /*-------------Form validation-------------*/
    const mailValidation = Yup.object().shape({
        identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        model: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        variable: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    useEffect(() => {
        if (emailShow === true) {
            frm = {
                resolver: yupResolver(mailValidation)
            }
        }
    }, [emailShow])
    let [desc, setDesc] = useState('');
    const mailSubmit = (data: CustomersAndEmployeesForm) => {
        let mailData: any = "";

        if (desc != "") {
            mailData = {
                identifier: data.admin_identifier,
                model: data.admin_model,
                caption: data.admin_caption,
                title: data.admin_title,
                desc: desc,
                variable: data.admin_variable
            }
        } else {
            mailData = {
                identifier: data.admin_identifier,
                model: data.admin_model,
                caption: data.admin_caption,
                title: data.admin_title,
                desc: '',
                variable: data.admin_variable
            }
        }

    }

    return (
        <>
            {/*---------------Mail/Letter form modal------------*/}
            <Modal show={emailShow} onHide={mailClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{mailText}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(mailSubmit)}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.title')}>
                                    <Form.Control type="text" placeholder={t('formLabel.title')} className={` ${errors.admin_title ? 'is-invalid' : ''}`}  {...register('admin_title')} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_title?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.identifier')}>
                                    <Form.Select aria-label={t('formLabel.identifier')} className={` ${errors.admin_model ? 'is-invalid' : ''}`}  {...register('admin_identifier')}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        <option value="1">A</option>
                                        <option value="2">B</option>
                                        <option value="3">C</option>
                                        <option value="4">D</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.admin_model?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.caption')}>
                                    <Form.Control type="text" placeholder={t('formLabel.caption')} className={` ${errors.admin_identifier ? 'is-invalid' : ''}`}  {...register('admin_caption')} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_identifier?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.variables')}>
                                    <Form.Select aria-label={t('formLabel.variables')} className={` ${errors.admin_variable ? 'is-invalid' : ''}`}  {...register('admin_variable')}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        <option value="1">A</option>
                                        <option value="2">B</option>
                                        <option value="3">C</option>
                                        <option value="4">D</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.admin_variable?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Control type="hidden" placeholder="description" className={`${errors.description ? 'is-invalid' : ''}`}  {...register('description')} />
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={desc}
                                    onReady={editor => {
                                        const data = editor.getData()
                                        setDesc(data)
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data)
                                    }}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data)

                                    }}
                                    onFocus={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline" onClick={mailClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" name="send">
                            {t('button.toSend')}
                        </Button>
                        <Button type="submit" variant="primary" name="save&send">
                            {t('button.saveSend')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            </>
    )
}
export default SendEamilAdmini;

