import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { listTemplate, listTemplatesInsideFolder } from '../../../../../redux/slice/SendEmailSlice';
import customerService from '../../../../../services/customerService';
import Swal from 'sweetalert2';

const MoveFolder = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        dispatch(listTemplate())
    }, [dispatch])
    const { showMoveFolder, moveFolderClose, currentFolderId, currentFileId, currentFileName } = props;
    const { listTemplatefolder } = useSelector((state: RootState) => state.emailTemplate)
    const [selectedFolderId, setSelectedFolderId] = useState<any>(null);
    // console.log("Listing",listTemplatefolder)
    // console.log("folder id current",currentFolderId);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            folder_id: selectedFolderId,
            file_id: currentFileId
        }
        // console.log("submit data", data);
        if (selectedFolderId !== null) {
            const response = await customerService.changeDocumentFolder(data);
            // console.log("response of move folder", response);
            if (response.data.resCode == 200 || response.data.resCode == 201) {
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: "Folder Moved",
                    confirmButtonText: "OK",
                    timer: 2000
                })
                await dispatch(listTemplatesInsideFolder(data.folder_id));
                moveFolderClose();
    
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: "There is some issue in moving file to folder",
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Please Select Option",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }

    }

    const handleRadioChange = (e) => {
        setSelectedFolderId(e.target.id);
    }


    return (
        <>
            <Modal show={showMoveFolder} onHide={moveFolderClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.move")} {currentFileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0 pb-2">
                        <p className="mb-lg-4 mb-3">{t("DocumentTemplate.moveContent")}</p>

                        {listTemplatefolder && listTemplatefolder.length > 0 ? (
                            listTemplatefolder.map((item) => (
                                <Form.Group
                                    key={item.ID_TEMPLATE_TEH}
                                    className="bg-white border rounded-4 p-2 min-h-56 d-flex justify-content-between align-items-center mb-3"
                                >
                                    <Form.Check
                                        name="radioCustom"
                                        type="radio"
                                        label={currentFolderId == item.ID_TEMPLATE_TEH ? `${item.ST_NOME_TEH} (current folder)` : item.ST_NOME_TEH}
                                        id={item.ID_TEMPLATE_TEH}
                                        disabled={currentFolderId == item.ID_TEMPLATE_TEH}
                                        onChange={handleRadioChange}
                                    />
                                </Form.Group>
                            ))
                        ) : (
                            <p>No Records Found</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="border-top">
                        <Button variant="outline-primary" className="me-md-3 me-2" onClick={moveFolderClose}>
                            {t("button.cancel")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t("button.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default MoveFolder
