import React, { ChangeEvent, useEffect, useState, useTransition } from "react";
import { Modal, Form, Button, FloatingLabel, Row, Col } from "react-bootstrap";
import {
  InsertCategory,
  UpdateCateGory,
  deleteCategory,
  deleteCate,
  getCategory,
  getItem,
} from "../../../../redux/slice/AccountPlanSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { add_category } from "../../../../redux/slice/AccountPlanSlice";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
// Define the Item interface with properties value, category, and account
interface Item {
  value: string;
  category: string;
  account: string;
}

const NewAccount = (props) => {
  const [t] = useTranslation();
  const [items, setItems] = useState<Item[]>([]);
  const [itemErrors, setItemErrors] = useState<string[]>([]);
  // Access the dispatch function from the Redux store
  const dispatch = useDispatch<AppDispatch>();
  // Get props for New account modal show and close function
  const { show, onClose } = props;
  const [showDelete, setShowDelete] = useState(false);
  // Retrieve the 'account_cateGory' from the Redux state using useSelector
  const { account_cateGory } = useSelector(
    (state: RootState) => state.accountplane
  );

  useEffect(() => {
    // Execute the effect only if show is true
    if (show === true) {
      let newcate: any = document?.querySelector("#root");
      // Get the action_category attribute from newcate
      var editData = newcate?.getAttribute("action_category");
      let getcate = newcate?.getAttribute("chater_category");
      let getCatItem = newcate?.getAttribute("total_category_item");
      let matchedObject: any = null;
      // Iterate through account_cateGory array
      let data = account_cateGory.forEach((item) => {
        // Find the item with value matching getcate
        if (item && item?.value == getcate) {
          matchedObject = item;
        }
      });

      if (editData === "edit") {
        // Create a new array with matchedObject as the only element
        var matchedObjectArray = [matchedObject];
        // Update the items state with matchedObjectArray
        setItems([...matchedObjectArray]);
        if (Number(getCatItem) > 0) {
          setShowDelete(false);
        } else {
          setShowDelete(true);
        }
      } else {
        // handle the case when editData !== "edit"
      }
    }
  }, [show, account_cateGory]);

  const handleItemChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // Create a copy of the 'items' array
    const newItems = [...items];
    // Update the value of the item at the specified index with the new value from the event
    newItems[index] = { ...newItems[index], value: event.target.value };
    // Update the 'items' state with the updated array
    setItems(newItems);
    // Create a copy of the 'itemErrors' array
    const newErrors = [...itemErrors];
    // Check if the new value is empty
    if (!event.target.value) {
      // If empty, set the error message for the corresponding index
      newErrors[index] = "Item is required";
    } else {
      newErrors[index] = "";
    }
    // Update the 'itemErrors' state with the updated array
    setItemErrors(newErrors);
  };

  const handleDescriptionChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // Create a copy of the 'items' array
    const newItems = [...items];
    // Update the 'category' property of the item at the specified index
    newItems[index] = { ...newItems[index], category: event.target.value };
    setItems(newItems);
    // Create a copy of the 'itemErrors' array
    const newErrors = [...itemErrors];

    if (!event.target.value) {
      newErrors[index] = "Description is required";
    } else {
      // If not empty, clear the error message for the corresponding index
      newErrors[index] = "";
    }
    // Set the updated 'itemErrors' array
    setItemErrors(newErrors);
  };
  // This function handles the addition of a new item.
  const handleAddItem = () => {
    // Check if all fields of existing items are filled
    const allFieldsFilled = items.every(
      (item) => item.value !== "" && item.category !== ""
    );
    // If all fields are filled, add a new item to the items array
    if (allFieldsFilled) {
      setItems([...items, { value: "", category: "", account: "" }]);
    } else {
      // Display an error message if any fields are incomplete
      Swal.fire({
        // icon: 'success',"error",
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
      });
    }
  };

  // This function handles the deletion of an item at a given index in the 'items' array
  // This function handles the deletion of an item at a given index in the 'items' array
  const handleDeleteItem = (index: number) => {
    // Create a new array 'newItems' by copying the 'items' array
    const newItems = [...items];
    // Remove the item at the specified index from 'newItems' using the 'splice' method
    // Remove the item at the specified index from 'newItems' using the 'splice' method
    newItems.splice(index, 1);
    // Update the 'items' state with the modified 'newItems' array
    setItems(newItems);
  };

  const handleDeleteCategory = async (cmd: string, index: number) => {
    // Check if the command is 'delete'
    if (cmd === "delete") {
      // Display a warning confirmation dialog
      Swal.fire({
        title: t("Sweetalert.Areyousure"),
        text: t("Sweetalert.Youwontbeabletorevert"),
        // icon: 'success',"question",
        showCancelButton: true,
        confirmButtonText: "OK",
      }).then(async (result) => {
        // Get the 'chater_category' attribute from the root element
        if (result.isConfirmed === true) {
          let newcate: any = document.querySelector("#root");
          let getcate = newcate.getAttribute("chater_category");
          // Create an object with the ID of the category to delete
          let obj = {
            ST_CONTA_CONT: Number(getcate),
          };
          // Remove the category from the 'items' array
          const newItems = [...items];
          newItems.splice(index, 1);
          setItems(newItems);

          let res = await dispatch(deleteCategory(obj));
          if (res.payload.type === "success" || res.payload.type === "Success") {
            // Display a success message
            Swal.fire({
              // icon: 'success',"success",
              title: t("Sweetalert.Delete"),
              text: t("Sweetalert.deletesuccess"),
              showConfirmButton: false,
              timer: 2000,
            });
            // Dispatch actions to update the state
            dispatch(deleteCate(obj));
            dispatch(getCategory());
            dispatch(getItem())
            setShowDelete(false);
            onClose()
          } else {
            // Display an error message
            Swal.fire({
              // icon: 'success',"error",
              title: t("Sweetalert.Error"),
              text: t("Sweetalert.somethingWent"),
              showConfirmButton: true,
              timer: 1000,
            });
          }
        }
      });
    }
  };

  const handleSaveItem = async () => {
    // Check if any items have empty fields
    const hasEmptyFields = items.some(
      (item) => item.value === "" || item.category === ""
    );

    let newcate: any = document.querySelector("#root");
    const newItem = [...items];
    // Splice the first item and store it in a new variable
    var firstItem = newItem.splice(0, 1)[0];
    // Prepare the update object for category update
    let updateobj = {
      ST_DESCRICAO_CONT: firstItem?.category,
      ST_CONTA_CONT: Number(newcate.getAttribute("chater_category")),
    };
    // Dispatch the category update action
    dispatch(UpdateCateGory(updateobj));
    let count = 0;
    let failed = 0;
    let success = 0;
    // Iterate through the remaining items
    for (let i = 0; i < newItem.length; i++) {
      let obj = {
        ST_CONTA_CONT: newItem[i].value,
        ST_DESCRICAO_CONT: newItem[i].category,
        account: "insert",
      };

      count++;
      // Dispatch the insert category action
      let res = await dispatch(InsertCategory(obj));
      if (res?.payload?.type == "success" || res?.payload?.type == "Success") {
        success++;
      } else {

        failed++;
      }
    }
    // Check if all items have been processed
    if (count == newItem.length) {
      Swal.fire({
        // icon: 'success',"success",
        title: t("Sweetalert.Success"),
        text: `${t("Sweetalert.Successfully")} (${success}) ${t(
          "Sweetalert.recordsinsertupdatedand"
        )} ${failed > 0 ? failed + t("Sweetalert.failedtoinsertupdate") : ""}`,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(getCategory());
          onClose();
        }
      });
    }
  };


  const renderItemInputs = () => {
    return items.map((item, index) => {
      const isItemInvalid = !item?.value || !item?.category;
      const isItemEmpty = item?.value === "";
      const isDescriptionEmpty = item?.category === "";
      return (
        <Row className="gy-3 mt-1" key={index}>
          {item != null ? (
            <>
              <Col md={3} xs={4}>
                
                <FloatingLabel controlId="item" label={t("formLabel.item")+ " *"}>
                  <Form.Control
                    type="text"
                    value={item?.value}
                    onChange={(event: any) => handleItemChange(index, event)}
                    placeholder="Item"
                    disabled={item.value.trim() !== ''}
                  />
                  {isItemEmpty && (
                    <span className="text-danger">Item is required</span>
                  )}
                </FloatingLabel>
              </Col>
              <Col md xs={8}>
                <FloatingLabel
                  controlId="description"
                  label={t("formLabel.description")+ " *"}
                >
                  <Form.Control
                    type="text"
                    value={item?.category}
                    onChange={(event: any) =>
                      handleDescriptionChange(index, event)
                    }
                    placeholder="Description"
                    className={
                      isItemInvalid && isDescriptionEmpty ? "invalid" : ""
                    }
                  />
                  {isDescriptionEmpty && (
                    <span className="text-danger">Description is required</span>
                  )}
                </FloatingLabel>
              </Col>
            </>
          ) : (
            ""
          )}
          {item && item?.account == "api_get" ? (
            <>
              {showDelete && (
                <Col md="auto" xs="4">
                  {item != null ? (
                    <Button
                      type="button"
                      variant="link"
                      className="bg-light w-100"
                      onClick={() => handleDeleteCategory("delete", index)}
                    >
                      <img
                        src="./assets/images/icons/delete-danger.svg"
                        alt="Delete"
                      />
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              )}
            </>
          ) : (
            <>
              <Col md="auto" xs="4">
                {item != null ? (
                  <Button
                    type="button"
                    variant="link"
                    className="bg-light w-100"
                    onClick={() => handleDeleteItem(index)}
                  >
                    <img
                      src="./assets/images/icons/delete-danger.svg"
                      alt="Delete"
                    />
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </>
          )}
        </Row>
      );
    });
  };

  return (
    <div>
      <>
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title> {t("chartofaccount.editCategoty")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("chartofaccount.categoryheading")}
            {renderItemInputs()}
            {/* <div className="d-flex align-items-center justify-content-center mt-lg-4 mt-3">
              <Button
                variant="link"
                className="p-0 fw-bold"
                type="button"
                onClick={handleAddItem}
              >
                {t("chartofaccount.newAccount")}
              </Button>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={onClose}>
              {t("button.close")}
            </Button>
            <Button variant="primary" onClick={() => handleSaveItem()}>
              {t("button.save")} {t("button.changes")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default NewAccount;
