import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { handleAction, handleSubmitData } from './notification';
import { useForm, } from "react-hook-form";
import { modal_reducer, birthday_reducer } from "../../../../redux/slice/notificationSlice";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Birthday = (props: any) => {
  let { dispatch, stateData,notificationData } = props;
  const navigate = useNavigate();

  // The useForm hook is used to handle form registration, submission, and validation.
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({});
  const [object, setObject] = useState([]);
  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation()
  const { birthday } = stateData;
  let basicdata = {
    dispatch,
    stateData,
    modal_reducer,
    birthday_reducer
  }
  useEffect(() => {
    if(notificationData){
      setValue("send_notification_birthday", Number(notificationData["send_notification_birthday"]))
      setValue("send_notification_active", Number(notificationData["send_notification_active_birthday"]))
      
    }
  },[notificationData])
  useEffect(() => {
    // Set the initial value of 'object' to 'birthday.basic_data'
    setObject(birthday.basic_data);
  }, [])

  useEffect(() => {
     // Get the fields from the 'birthday.basic_data' object
    let fields: string[] = Object.keys(birthday.basic_data);
     // Set the values of the fields using the 'setValue' function from the form library
    fields.forEach((field: any) => setValue(field, birthday?.basic_data?.[field]));
  }, [object, stateData])

  let page = "birthday";
  // Function to handle form submission
  const onSubmit = async (data: any) => {
    let checkSubmition = true;
      // Check if any input field value contains a '-'
    document.querySelectorAll(`.${page}.removeInsertDisable`).forEach(item => {
      if (item["value"].includes('-')) {
        checkSubmition = false;
      }
    })
    if (checkSubmition) {
      handleSubmitData(data);
    } else {
      // Show an error message
      Swal.fire({
        position: 'center',
        // icon: 'success','error',
        title: `${t('Sweetalert.Error')}`,
        text: `${t('Sweetalert.negativeNumberNotAllowed')}`,
        showConfirmButton: true,
        timer: 1000
      })
    }
  }

  const handleEmailName = (id,NOME, LABEL) => {
    document.querySelector('#root')?.setAttribute('st_nome_moh', NOME);
    document.querySelector('#root')?.setAttribute('st_label_moh', LABEL);
    localStorage.setItem("YhGUFY_hygft",id);
    let data = {
      notification: "notification"
    }
    navigate("/message", { state: data });
}

  return (
    <>
      <Col lg={6}>
        <Card className="h-100">
          <Card.Header className="d-flex justify-content-between">
            <div className="w-80 me-2">{t("notifications.birthdaysDay")}<img src="assets/images/icons/info-light.svg" alt="Info icon" className="h-18 ms-2" /></div>
            <span className='d-block TOEDIT'>
              <span>
                <span>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "birthday", basicdata)}>{t("button.edit")}</Button>
                </span>
              </span>
            </span>
            <div className="d-none ACTION">
              <Row>
                <Col xl={4} sm={4}>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "save", "birthday", basicdata)} >{t("button.save")}</Button>
                </Col>
                <Col xl={4} sm={4}>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={() => handleEmailName("31","Feliz aniversário", "notificacoescontratuais")}>{t("button.edit")}</Button>
                </Col>
                <Col xl={4} sm={4}>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "cancel", "birthday", basicdata)} >{t("button.cancel")}</Button>
                </Col>
              </Row>
            </div>
          </Card.Header>

          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)} >
              <Form.Check className="mb-3 mb-lg-4 removeInsertDisable" type="checkbox" {...register("send_notification_birthday")} id={`default_1`} label={t("notifications.sendNotificationBirthday")} disabled />
              <Form.Check className="mb-3 mb-lg-4 removeInsertDisable" type="checkbox" {...register("send_notification_active")} id={`default_2`} label={t("notifications.sendNotificationForActive")} disabled />
              <Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
            </Form>
          </Card.Body>
        </Card>
      </Col></>
  )
}

export default Birthday