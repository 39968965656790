import React, { useEffect, useState } from 'react';
import { Form, Button, FloatingLabel, Row, Col, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '../../../../../redux/store';
import Swal from 'sweetalert2';
import { fetchService, SetDIMOBConfigInServices, RemoveDIMOBConfigFromService } from '../../../../../redux/slice/ServiceSlice';
import { registerData, setResponsibleCPF } from '../../../../../redux/slice/companyDataSlice';
import { useNavigate } from 'react-router-dom';
import SearchableServiceSelect from './SearchableServiceSelect';
import OffcanvasHeader from './ConfigDrawerHeader';
import OffcanvasFooter from './ConfigDrawerFooter';
import CompanyDetailsForm from './CompanyDetailsForm';

const ConfigDrawer = ({ showDIMOBDrawer, dimobSettingClose }) => {
    const [t] = useTranslation();
    const schema = yup.object().shape({});
    const dispatch = useDispatch<AppDispatch>();

    const initialFormState = {
        companyResponsibleCPF: "",
        companyName: "",
        companyAdress: "",
        companyDocument: "",
        companyZipCode: "",
        companyCity: "",
        companyState: "",
        services: [],
    };

    const { register, unregister, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors, watch } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: initialFormState,
    });

    const [serviceList, setServiceList] = useState<any>([]);
    const [deletedItems, setDeletedItems] = useState<number[]>([]);
    const [configuredServiceList, setConfiguredServiceList] = useState<any>([]);

    const handleDrawerClose = () => {
        reset(initialFormState);
        dimobSettingClose();
    };

    useEffect(() => {
        dispatch(fetchService());
        dispatch(registerData());
    }, [dispatch])

    const { companydata }: any = useSelector((state: RootState) => state.companyData);
    const ServiceList = useSelector((state: RootState) => state.service);

    useEffect(() => {
        if (companydata?.length > 0) {
            setValue("companyName", companydata[0]?.company_name);
            setValue("companyAdress", `${companydata[0]?.address}, ${companydata[0]?.number}`);
            setValue("companyDocument", companydata[0]?.cnpj);
            setValue("companyZipCode", companydata[0]?.zipcode);
            setValue("companyCity", companydata[0]?.city);
            setValue("companyState", companydata[0]?.state);
            setValue("companyResponsibleCPF", companydata[0]?.agency_responsible_CPF);
        }
    }, [showDIMOBDrawer, companydata, setValue]);

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate("/company", { state: { from: "/dimob" } });
    };

    useEffect(() => {
        const originalServices = ServiceList?.servicelist || [];

        const filteredServices = originalServices.filter(
            (service: any) => !deletedItems.includes(service.ID_PRODUTO_PRD)
        );

        const configuredServices = filteredServices.filter(
            (service: any) => service.FL_DIMOB_PRD
        );

        const servicesToDisplay = [
            ...configuredServices
                .filter((service) => [2, 3, 4].includes(service.FL_DIMOB_PRD))
                .map((service) => ({
                    ...service,
                    dimobSetting: service.FL_DIMOB_PRD || "",
                    emulated: false,
                    uuid: generateUUID()
                })),
            ...Array(Math.max(4 - configuredServices.filter((service) => [2, 3, 4].includes(service.FL_DIMOB_PRD)).length, 0)).fill({
                ID_PRODUTO_PRD: "",
                dimobSetting: "",
                emulated: true,
                uuid: generateUUID()
            }),
        ];

        setServiceList(originalServices);
        setConfiguredServiceList(servicesToDisplay);

        servicesToDisplay.forEach((service, index) => {
            setValue(`services.${index}.ID_PRODUTO_PRD`, service.ID_PRODUTO_PRD || "");
            setValue(`services.${index}.dimobSetting`, service.dimobSetting || "");
        });

    }, [ServiceList, deletedItems]);

    const deleteServiceRow = async (service: any) => {

        const serviceID = service.ID_PRODUTO_PRD;
        const isEmulated = service.emulated;
        const uuid = service.uuid;

        if (isEmulated) {
            setConfiguredServiceList((prevList: any) => {
                const updatedList = [...prevList];


                const index = updatedList.findIndex((service) => service.uuid === uuid
                );

                if (index !== -1) {
                    updatedList.splice(index, 1);
                }

                return updatedList;
            });

            return;
        }

        Swal.fire({
            title: "Atenção",
            text: "Tem certeza de que deseja remover este serviço?",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                const payload = { service_id: serviceID };

                try {
                    const result: any = await dispatch(RemoveDIMOBConfigFromService(payload));
                    const response = result.payload?.data;

                    if (response?.resCode === 201) {

                        setDeletedItems((prevDeleted) => [...prevDeleted, serviceID]);

                        setConfiguredServiceList((prevList: any) => {
                            const updatedList = [...prevList];
                            const index = updatedList.findIndex((service) => service.ID_PRODUTO_PRD === serviceID
                            );

                            if (index !== -1) {

                                unregister(`services.${index}.ID_PRODUTO_PRD`);
                                unregister(`services.${index}.dimobSetting`);

                                updatedList.splice(index, 1);
                            }

                            return updatedList;
                        });

                        Swal.fire({
                            title: "Sucesso",
                            text: "Serviço removido com sucesso.",
                            confirmButtonText: "OK",
                            timer: 2000,
                        });
                    } else {
                        Swal.fire({
                            title: "Erro",
                            text: "Ocorreu um erro ao remover o serviço.",
                            icon: "error",
                            confirmButtonText: "OK",
                            timer: 2000,
                        });
                    }
                } catch (error) {
                    console.error("Erro ao remover o serviço:", error);
                    Swal.fire({
                        title: "Erro",
                        text: "Ocorreu um erro ao remover o serviço.",
                        icon: "error",
                        confirmButtonText: "OK",
                        timer: 2000,
                    });
                }
            }
        });
    };

    const addMoreLines = () => {
        setConfiguredServiceList((prevList) => [
            ...prevList,
            ...Array(1).fill(null).map(() => ({
                ID_PRODUTO_PRD: "",
                dimobSetting: "",
                emulated: true,
                uuid: generateUUID(),
            })),
        ]);
    };

    const generateUUID = (): string => {        
        return Math.random().toString(36).substring(2, 14)
    };

    const AnotherAdd = () => {
        return (
            <>
                {configuredServiceList.map((service: any, index: number) => (
                    <React.Fragment key={service.ID_PRODUTO_PRD || `temp-${index}`}>
                        <Row className="align-items-center mb-2 p-1">
                            <Col md={6}>
                                <SearchableServiceSelect
                                    key={index}
                                    serviceList={serviceList}
                                    register={register}
                                    index={index}
                                    errors={errors}
                                    defaultValue={service.ID_PRODUTO_PRD}
                                    setValue={setValue}
                                />
                            </Col>

                            <Col md={5}>
                                <FloatingLabel controlId={`services.${index}.dimobSetting`} label={t("formLabel.includeondimob")}>
                                    <Form.Select
                                        aria-label="Income, Invoice, Dimob"
                                        className={`form-control`}
                                        {...register(`services.${index}.dimobSetting`)} // Registra o campo no formulário
                                        defaultValue={service.dimobSetting || ""}>
                                        <option value="1">{t("services.notInclude")}</option>
                                        <option value="2">{t("services.includeAsIncome")}</option>
                                        <option value="3">{t("services.issueAnInvoice")}</option>
                                        <option value="4">{t("services.includeAsWithholding")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>

                            <Col md={1}>
                                <Button
                                    variant="link"
                                    className="p-0"
                                    onClick={() => deleteServiceRow(service)}>
                                    <img
                                        src="assets/images/icons/delete-danger.svg"
                                        alt="Excluir"
                                        title="Excluir"
                                        className="h-20 cursor-pe"
                                    />
                                </Button>
                            </Col>
                        </Row>
                    </React.Fragment>
                ))}
            </>
        );
    };

    const processCPF = (cpf) => {
        if (!cpf) {
            return { status: false, message: "CPF é obrigatório", formattedCPF: "" };
        }

        const cleanCPF = cpf.replace(/\D/g, "");

        if (cleanCPF.length !== 11) {
            return { status: false, message: "CPF deve conter 11 dígitos", formattedCPF: "" };
        }

        const cpfRegex = /^\d{11}$/;
        if (!cpfRegex.test(cleanCPF)) {
            return { status: false, message: "Formato de CPF inválido", formattedCPF: "" };
        }

        const formattedCPF = cleanCPF
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{2})$/, "$1-$2");

        return { status: true, message: "CPF válido", formattedCPF };
    };


    const formSubmit = async (data: any) => {

        const services = data.services
            .filter((product) => product.ID_PRODUTO_PRD && product.dimobSetting)
            .map((product) => ({
                serviceID: product.ID_PRODUTO_PRD,
                dimobSetting: product.dimobSetting,
            }));

        const { companyResponsibleCPF } = data;
        const cpfResult = processCPF(companyResponsibleCPF);

        if (!cpfResult.status) {
            Swal.fire({
                text: cpfResult.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }

        const payloadCompany = {
            cpf_document: cpfResult.formattedCPF,
        };

        const payloadServices = { services: services };

        try {
            const resultCompany: any = await dispatch(setResponsibleCPF(payloadCompany));
            const resultServices: any = await dispatch(SetDIMOBConfigInServices(payloadServices));
            const responseCompany = resultCompany.payload?.data;
            const responseServices = resultServices.payload?.data;

            if (responseCompany?.resCode === 200 && responseServices?.resCode === 201) {

                Swal.fire({
                    title: "Sucesso",
                    text: "Configurações efetivadas.",
                    confirmButtonText: "OK",
                    timer: 2000,
                });

                dispatch(fetchService());
                dispatch(registerData());
                dimobSettingClose();
            } else {
                Swal.fire({
                    title: "Erro",
                    text: "Ocorreu um erro ao gravar configurações.",
                    icon: "error",
                    confirmButtonText: "OK",
                    timer: 2000,
                });
            }
        } catch (error) {
            console.error("Erro ao gravar configurações:", error);
            Swal.fire({
                title: "Erro",
                text: "Ocorreu um erro ao gravar configurações.",
                icon: "error",
                confirmButtonText: "OK",
                timer: 2000,
            });
        }
    };

    return (
        <>
            <Offcanvas show={showDIMOBDrawer} onHide={handleDrawerClose} placement='end' className="border-start-0">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(formSubmit)}>
                    <OffcanvasHeader title={t("Configurações DIMOB")} onClose={handleDrawerClose} />
                    <Offcanvas.Body>
                        <CompanyDetailsForm
                            getValues={getValues}
                            errors={errors}
                            register={register}
                            t={t}
                            onEditAddress={handleRedirect}
                        />
                        <span className="d-block mt-5 mb-5 fw-bold text-dark">{t("Serviços")}</span>
                        <Row className="gy-lg-4 gy-3">
                            <AnotherAdd />
                        </Row>
                        <Row>
                            <Col md={12} className="text-start">
                                <Button variant="link" className="text-primary fw-bold d-flex align-items-center"
                                    onClick={addMoreLines} >
                                    <img src="assets/images/icons/plus-light.svg" alt="Novo serviço" className="me-1 h-20" />
                                    {t("button.addService")}
                                </Button>
                            </Col>
                        </Row>

                    </Offcanvas.Body>
                    <OffcanvasFooter
                        onCancel={handleDrawerClose}
                        onSave={() => handleSubmit(formSubmit)()}
                        saveText={t("button.toSave")}
                        cancelText={t("button.cancel")}
                    />
                </Form>
            </Offcanvas>
        </>
    )
}

export default ConfigDrawer