import { Form, Modal, Button, Row, Col, InputGroup, Alert, Accordion, FloatingLabel, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { insertInsurance, getInsurance } from "../../../../redux/slice/contractDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { insuranceRegistrationForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import customerService from '../../../../services/customerService';
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import moment from 'moment';
import { add_Bank, getBankList } from '../../../../redux/slice/bankSlice';
import { registerData } from '../../../../redux/slice/companyDataSlice';
import { CNJP_CPFValidation, handleGreenCheckLogic } from '../../../constants/utils';

const AddInsurence = (insuranceRegistrationForm) => {
    const navigate = useNavigate();
    const { } = useSelector((state: RootState) => state.contractData)
    const { } = useSelector((state: RootState) => state.expenditureData)
    const { } = useSelector((state: RootState) => state.branch)
    const { } = useSelector((state: RootState) => state.propertyData)

    const { insuranceData } = useSelector((state: RootState) => state.contractData)

    // Retrieve banklist, show1, and status from the Redux state
    const { banklist, show1, status } = useSelector((state: RootState) => state.bank)
    const { companydata }: any = useSelector((state: RootState) => state.companyData);
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [allStates, setAllStates] = useState([]);
    const [issValue, setIssValue] = useState(1);
    const [agencyInsurance, setAgencyInsurency] = useState<any>('');
    const regexNotPattern = /^[0-9-]+$/;

    useEffect(() => {
        if (status != 'loading' && agencyInsurance == '') {
            dispatch(getInsurance({ "insurance": "", "start": 0, "end": 10 }));
            // gggg();
        }
    }, [dispatch, status]);

    useEffect(() => {
        let obj = {
            start: "0",
            end: "10",
            all: ""
        }
        dispatch(getBankList(obj))
        dispatch(add_Bank())
        dispatch(getBankList(obj))
        dispatch(registerData());
    }, [dispatch]);


    useEffect(() => {
        if (companydata && companydata.length > 0) {
            setTimeout(() =>{
            setValue('corporateEmail', companydata[0].email)
            setValue('corporatePhone', companydata[0].telephone)
            setValue('legalName', companydata[0].company_name)
            setValue('BusinessName', companydata[0].fantasy_name)
            setValue('cnpj', companydata[0].cnpj)
            setValue('email', companydata[0].email)
            // setValue('phonenumber', companydata[0].telephone)
            setValue('zipcode', companydata[0].zipcode)
            setValue('address', companydata[0].address)
            setValue('city', companydata[0].city)
            setValue('number', companydata[0].number)
            setValue('neighborhood', companydata[0].neighborhood)
            setValue('state', companydata[0]["state"])
            if(companydata[0]?.email && companydata[0]?.email && companydata[0]?.telephone){
                const tick = handleGreenCheckLogic("1", "aboutname");
                setTick1(tick);
            }
            const tick = handleGreenCheckLogic("1", "aboutreal");
            setTick2(tick);
            const tick2 = handleGreenCheckLogic("2", "address");
            setTick3(tick2);
        },1500)
        }
    }, [companydata])
    useEffect(() => {
        gggg();
    }, [insuranceData]);

    const gggg = () => {
        if (insuranceData && insuranceData.length > 0) {
            insuranceData.forEach((item: any, index: number) => {
                if (item["ST_SEGURANCA_EMP"] && item["ST_SEGURANCA_EMP"] !== "") {
                    let agencyDetails = item.AGENCY_DETAILS;
                    setAgencyInsurency(agencyDetails);
                    setValue('name', agencyDetails.ST_NOME_EMP)
                    setValue('corporateEmail', agencyDetails.ST_EMAIL_EMP)
                    setValue('corporatePhone', agencyDetails.ST_TELEFONE_EMP)

                    setValue('cnpj', agencyDetails.ST_CGC_EMP)
                    setValue('email', agencyDetails.ST_EMAIL_EMP)
                    setValue('phonenumber', agencyDetails.ST_TELEFONE_EMP)

                    setValue('zipcode', agencyDetails.ST_CEP_EMP)
                    setValue('address', agencyDetails.ST_ENDERECO_EMP)
                    setValue('city', agencyDetails.ST_CIDADE_EMP)
                    setValue('number', agencyDetails.ST_NUMERO_EMP)
                    setValue('neighborhood', agencyDetails.ST_BAIRRO_EMP)
                    setValue('state', agencyDetails.ST_ESTADO_EMP)
                }
            })
        }
    }

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, []);
    /*----------Set the contract form fields------------*/

    const [newContractShow2, setnewContractShow2] = useState(false);
    const newContractClose2btn = () => {
        setnewContractShow2(false)
    }

    /*--------------Form validation-------------*/
    let fmrrfm = {
        name: Yup.string().trim().nullable().nullable().required(t('requiredMmessage.fieldRequired')).max(80, t('requiredMmessage.80characters', { length: 80 })),
        rg: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')).max(12, t('requiredMmessage.12characters', { length: 12 })),
        cpfnumber: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        corporateEmail: Yup.string().trim().nullable().email(t('requiredMmessage.validEmail')).required(t('requiredMmessage.fieldRequired')).matches(/\.com$/, t('requiredMmessage.fieldRequired')).max(50, t('requiredMmessage.50characters', { length: 50 })),
        corporatePhone: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        BusinessName: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')).max(80, t('requiredMmessage.80characters', { length: 80 })),
        legalName: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')).max(80, t('requiredMmessage.80characters', { length: 80 })),
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        email: Yup.string().trim().nullable().email().required(t('requiredMmessage.fieldRequired')).matches(/\.com$/, t('requiredMmessage.fieldRequired')).max(50, t('requiredMmessage.50characters', { length: 50 })),
        phonenumber: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        zipcode: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        city: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        address: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        number: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        neighborhood: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        state: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        municipalRegistration: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        bank: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        bankName: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        agency: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        account: Yup.string().trim().nullable()
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            })
            .required(t('requiredMmessage.fieldRequired')),
        digit: Yup.string().trim().nullable()
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            })
            .required(t('requiredMmessage.fieldRequired')),
        isReadterms: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired'))
    }
    const contractValidation5 = Yup.object().shape(fmrrfm);
    let frm = {
        resolver: yupResolver(contractValidation5)
    }

    /*---------------Add/Edit the contract form---------------*/
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<insuranceRegistrationForm>(frm);
    const formSubmit = async (data: insuranceRegistrationForm, e: any) => {
        e.preventDefault();
        let mt = data.isReadterms;
        if (data.isReadterms == false || String(data.isReadterms) == 'false') {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text:  t("checkboxLabel.Pleaseaccept"),
                confirmButtonText: "OK",
                timer: 4000
            })
        } else {
            data["formIs"] = "main";
            let response = await dispatch(insertInsurance(data))
            if (typeof response.payload !== "undefined" && (response.payload.resCode === 202)) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: response.payload.message,
                    confirmButtonText: "OK",
                    timer: 4000
                })
                navigate("/dashboard");
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: response.payload.data ? response.payload.data : response.payload.message,
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 4000
                })
            }
        }
    }

    /*------------Set the billing address fied value----------*/
    const billingAddress = (evt) => {
        setValue('zipcode', '');
        setValue('address', '');
        setValue('number', '');
        // setValue('complement', '');
        setValue('neighborhood', '');
        setValue('city', '');
        setValue('state', '');
    }

    /*------------Set the contract fied value----------*/
    const issContract = (evt) => {
        // setIsssContract(true);
        // setIssValue(Number(evt.target.value));
        // if (evt.target.value == 'on' ) {
        //     setIssValue(Number(evt.target.value));
        //     // setIsssContract(false);
        // } else {
        //     setIssValue(Number(1));
        // }
    }

    const getLocation = async (e, inputType, type2) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen

        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
        // let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                // zipCodeCheck.removeAttribute('disabled');
                // Set the city value if available in the response
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
                // zipCodeCheck.setAttribute('disabled', "")
            }
        } else {
            querySel.style.border = "0px solid red";
            // zipCodeCheck.removeAttribute('disabled');
        }
        const tick = handleGreenCheckLogic(e, type2);
        setTick3(tick); 
    }

    const HandleInput = (e: any, inputType, type) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if ((newValue == "" || newValue == null || newValue == undefined) && inputType !== "number") {
            setError(inputType, {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors(inputType);
        }

        if (type = "aboutname") {
            const tick = handleGreenCheckLogic(e, type);
            setTick1(tick);
        }
        if (type = "aboutreal") {
            const tick = handleGreenCheckLogic(e, type);
            setTick2(tick);
        }

    }

    const selectBanksis = (e: any, type, type1) => {
        if(type1 =="addbank"){
            setValue('bankName', e.target.value)
        }
        const tick = handleGreenCheckLogic(e, type);
        setTick5(tick);

    }

    const [tick1, setTick1] = useState<any>("");
    const [tick2, setTick2] = useState<any>("");
    const [tick3, setTick3] = useState<any>("");
    const [tick4, setTick4] = useState<any>("");
    const [tick5, setTick5] = useState<any>("");
    const handleCPF_CNJPVaue = async (e, type,type1) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9./-]/g, "");
        e.target.value = sanitizedValue;
        let checkCNJP = CNJP_CPFValidation(sanitizedValue, type);
        if (type == "cnpj") {
            if (checkCNJP?.status) {
                clearErrors("cnpj")
                let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
                zipCodeCheck?.["removeAttribute"]('disabled');
            } else {
                let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
                zipCodeCheck?.["setAttribute"]('disabled', "")
                setError("cnpj", {
                    type: "manual",
                    message: checkCNJP?.["message"]
                });
            }
        } else {
            if (checkCNJP?.status) {
                clearErrors("cpfnumber")
                let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
                zipCodeCheck?.["removeAttribute"]('disabled');
            } else {
                let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
                zipCodeCheck?.["setAttribute"]('disabled', "")
                setError("cpfnumber", {
                    type: "manual",
                    message: checkCNJP?.["message"]
                });
            }
            if(type1 == "aboutname"){
            const tick = handleGreenCheckLogic(e, type1);
            setTick1(tick);
            }
            if(type1 == "aboutreal"){
                const tick = handleGreenCheckLogic(e, type1);
                setTick2(tick);
                }
        }
    }
    const handleNameof = (e, type, type1) => {
        let { value } = e.target
        if (type == "name") {
            const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "")
            e.target.value = sanitizedValue;
            // Error validation
            if (value.length > 80) {
                // Set an error message using setError or update your error state
                setError("name", {
                    type: "manual",
                    message: (t('requiredMmessage.80characters'))
                });
            } else {
                // Clear the telephone error if the value is not empty
                clearErrors('name');
            }
        } else if (type == "rg") {
            if (value.length > 12) {
                // Set an error message using setError or update your error state
                setError("rg", {
                    type: "manual",
                    message: (t('requiredMmessage.12characters'))
                });
            } else {
                // Clear the telephone error if the value is not empty
                clearErrors('rg');
            }
        } else if (type == "corporateEmail") {
            if (value.length > 50) {
                // Set an error message using setError or update your error state
                setError("corporateEmail", {
                    type: "manual",
                    message: (t('requiredMmessage.50characters'))
                });
            } else {
                // Clear the telephone error if the value is not empty
                clearErrors('corporateEmail');
            }
        }
        const tick = handleGreenCheckLogic(e, type1);
        setTick1(tick);
    }

    const handleNameofRealStage = (e, type, type1) => {
        let { value } = e.target
        if (type == "BusinessName") {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, ""); 
            e.target.value = sanitizedValue;
            // Error validation
            if (value.length > 80) {
                // Set an error message using setError or update your error state
                setError("BusinessName", {
                    type: "manual",
                    message: (t('requiredMmessage.80characters'))
                });
            } else {
                // Clear the telephone error if the value is not empty
                clearErrors('BusinessName');
            }
        } else if (type == "legalName") {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, ""); 
            e.target.value = sanitizedValue;
            // Error validation
            if (value.length > 80) {
                // Set an error message using setError or update your error state
                setError("legalName", {
                    type: "manual",
                    message: (t('requiredMmessage.80characters'))
                });
            } else {
                // Clear the telephone error if the value is not empty
                clearErrors('legalName');
            }
        } else if (type == "email") {
            if (value.length > 50) {
                // Set an error message using setError or update your error state
                setError("email", {
                    type: "manual",
                    message: (t('requiredMmessage.50characters'))
                });
            } else {
                // Clear the telephone error if the value is not empty
                clearErrors('email');
            }
        }
        const tick = handleGreenCheckLogic(e, type1);
        setTick2(tick);
    }

    const handleAdressRealStage = (e,type) =>{
        const tick = handleGreenCheckLogic(e, type);
        setTick3(tick);  
    }
    const handleInformation = (e,type) =>{
        const tick = handleGreenCheckLogic(e, type);
        setTick4(tick);  
    }
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/insurers"}><img src="assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base">{t('notifications.insurance')}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('newContractDev.savedConnectedInternet')}</Tooltip>}>
                                        <img src="assets/images/icons/cloud-arrow.svg" alt="cloud icon" className="h-20" />
                                    </OverlayTrigger>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary">
                                    <img src="assets/images/icons/arrow-double.svg" alt="cloud icon" className="h-14 me-2" /> {t('insurelist.closeAll')}
                                </li>
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
                <Form onSubmit={handleSubmit(formSubmit)} id="addtsxinsurance">
                    <Row className="gy-lg-4 gy-3 mb-lg-0 mb-5">

                        <Col lg={4}>
                            <Card className="overflow-hidden position-sticky top-0">
                                <Card.Header>
                                   {t("KenloBenefits")}
                                </Card.Header>
                                <Card.Body className="px-0 pb-0">
                                    <div className="px-lg-4 px-3 pb-lg-4 pb-3">
                                        <p>{t("insurelist.inpartnershipwithTokio")}</p>
                                        <p className="d-flex align-items-start text-center">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.commissionsforeveryfire")}
                                        </p>
                                        <p className="d-flex align-items-start text-center">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.noobligation")}
                                        </p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.realtimequotesduring")}
                                        </p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.automaticcontractapproval")}
                                        </p>
                                        <p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.automaticbilling")}
                                        </p><p className="d-flex align-items-start">
                                            <img src="assets/images/icons/arrow-success.svg" className="h-18 me-1 mt-1" alt="arrow" />
                                            {t("insurelist.securityfrom")}
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={8}>
                            <input type="hidden" {...register("id")} />
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/home-reqular.svg" alt="Property icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.aboutyou')}
                                            {tick1 === "" || tick1 === "empty" ? (
                                                <img
                                                    src="assets/images/icons/circle1.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            {tick1 === "all" ? (
                                                <img
                                                    src="assets/images/icons/check-circle-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : tick1 === "some" ? (
                                                <img
                                                    src="assets/images/icons/progress-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="g-3">

                                            <Col md={12}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.name")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.name")} className={`aboutname ${errors.name ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleNameof(e, "name", "aboutname")} />
                                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("RG")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("RG")} className={`aboutname ${errors.rg ? 'is-invalid' : ''}`} {...register('rg')} onChange={(e) => handleNameof(e, "rg", "aboutname")} />
                                                    <Form.Control.Feedback type="invalid">{errors.rg?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.cpf")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.cpf")} className={`aboutname ${errors.cpfnumber ? 'is-invalid' : ''}`} {...register('cpfnumber')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf", "aboutname")} />
                                                    <Form.Control.Feedback type="invalid">{errors.cpfnumber?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={8}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.Corporateemail")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.Corporateemail")} className={`aboutname ${errors.corporateEmail ? 'is-invalid' : ''}`} {...register('corporateEmail')} onChange={(e) => handleNameof(e, "corporateEmail", "aboutname")} />
                                                    <Form.Control.Feedback type="invalid">{errors.corporateEmail?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.phone")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.phone")} className={`aboutname ${errors.corporatePhone ? 'is-invalid' : ''}`}  {...register('corporatePhone')} onChange={(e) => HandleInput(e, "corporatePhone", "aboutname")} />
                                                    <Form.Control.Feedback type="invalid">{errors.corporatePhone?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>



                                <Accordion.Item eventKey="1" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/user-light.svg" alt="Tenant icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.aboutReal')}
                                            {tick2 === "" || tick2 === "empty" ? (
                                                <img
                                                    src="assets/images/icons/circle1.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            {tick2 === "all" ? (
                                                <img
                                                    src="assets/images/icons/check-circle-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : tick2 === "some" ? (
                                                <img
                                                    src="assets/images/icons/progress-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="g-3">
                                            <Col md={12}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.BusinessName")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.BusinessName")} className={`aboutreal ${errors.BusinessName ? 'is-invalid' : ''}`} {...register('BusinessName')} onChange={(e) => handleNameofRealStage(e, "BusinessName", "aboutreal")} />
                                                    <Form.Control.Feedback type="invalid">{errors.BusinessName?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.Legalname")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.Legalname")} className={`aboutreal ${errors.legalName ? 'is-invalid' : ''}`} {...register('legalName')} onChange={(e) => handleNameofRealStage(e, "legalName", "aboutreal")} />
                                                    <Form.Control.Feedback type="invalid">{errors.legalName?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.cnpj")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.cnpj")} className={`aboutreal ${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj","aboutreal")} />
                                                    <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={8}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.email")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.email")} className={`aboutreal ${errors.email ? 'is-invalid' : ''}`} {...register('email')} onChange={(e) => handleNameofRealStage(e, "email", "aboutreal")} />
                                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.phone")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.phone")} className={`aboutreal ${errors.phonenumber ? 'is-invalid' : ''}`}  {...register('phonenumber')} onChange={(e) => HandleInput(e, "phonenumber", "aboutreal")} />
                                                    <Form.Control.Feedback type="invalid">{errors.phonenumber?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/contract-detail.svg" alt="Contract details icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.aboutRealAddress')}
                                            {tick3 === "" || tick3 === "empty" ? (
                                                <img
                                                    src="assets/images/icons/circle1.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            {tick3 === "all" ? (
                                                <img
                                                    src="assets/images/icons/check-circle-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : tick3 === "some" ? (
                                                <img
                                                    src="assets/images/icons/progress-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col md={3}>
                                                <FloatingLabel className="mb-3 getzipcode" controlId="floatingSelect " label={t('zipCode')+ " *"} >
                                                    <Form.Control type="text" placeholder={t('zipCode')} className={`form-control address ${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "address")} />
                                                    <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={9}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('address')+ " *"}>
                                                    <Form.Control type="text" placeholder={t('address')} className={`form-control address ${errors.address ? 'is-invalid' : ''}`} {...register('address')} onChange={(e) => handleAdressRealStage(e,  "address")}/>
                                                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('number')+ " *"}>
                                                    <Form.Control type="text" placeholder={t('number')} className={`form-control address ${errors.number ? 'is-invalid' : ''}`} {...register('number')} maxLength={10} onChange={(e) => handleAdressRealStage(e, "address")} />
                                                    <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('neighborhood')+ " *"}>
                                                    <Form.Control type="text" placeholder={t('neighborhood')} className={`form-control address ${errors.neighborhood ? 'is-invalid' : ''}`} {...register('neighborhood')} onChange={(e) => handleAdressRealStage(e,  "address")}/>
                                                    <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('city')+ " *"}>
                                                    <Form.Control type="text" className={`form-control address ${errors.city ? 'is-invalid' : ''}`} placeholder={t('city')} {...register('city')} onChange={(e) => handleAdressRealStage(e, "address")}/>
                                                    <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('state')+ " *"}>
                                                    <Form.Select className={`form-control address ${errors.state ? 'is-invalid' : ''}`} {...register('state')} aria-label={t('state')} onChange={(e) => handleAdressRealStage(e,  "address")}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        {allStates && allStates.length > 0 &&
                                                            allStates.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                )
                                                            })}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="3" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/warranty.svg" alt="Warranty icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.fiscalInfo')}
                                            {tick4 === "" || tick4 === "empty" ? (
                                                <img
                                                    src="assets/images/icons/circle1.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            {tick4 === "all" ? (
                                                <img
                                                    src="assets/images/icons/check-circle-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : tick4 === "some" ? (
                                                <img
                                                    src="assets/images/icons/progress-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="g-3 mt-0">
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('description')+ " *"} >
                                                    <Form.Control type="text" placeholder={t('description')} className={`municipalRegistration ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} onChange={(e) => handleInformation(e,  "municipalRegistration")} />
                                                    <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.ISSRate")+ " *"}>
                                                    <Form.Select className={`municipalRegistration ${errors.issRate ? 'is-invalid' : ''}`} aria-label=" ISS Rate"  {...register('issRate')} onChange={(e) => handleInformation(e,  "municipalRegistration")} >
                                                        <option value="">{t('select')}</option>
                                                        <option value="5.0">5.0</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.issRate?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4" className="mb-3">
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center justify-content-start w-100">
                                            <img src="assets/images/icons/fire-insurance.svg" alt="Fire insurance icon" className="me-xl-4 me-md-3 me-2 h-26" />{t('insurelist.bankData')}
                                            {tick5 === "" || tick5 === "empty" ? (
                                                <img
                                                    src="assets/images/icons/circle1.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            {tick5 === "all" ? (
                                                <img
                                                    src="assets/images/icons/check-circle-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : tick5 === "some" ? (
                                                <img
                                                    src="assets/images/icons/progress-success.svg"
                                                    alt="complete"
                                                    className="h-26 ms-auto me-xl-4 me-md-3 me-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="mb-3">
                                            <Col md={6} className={`${errors.bank ? 'is-invalid' : ''}`}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t("formLabel.Selectbankaccount") + " *"}>
                                                    <Form.Select className={`bankinfo ${errors.bank ? 'is-invalid' : ''}`} id="bank"  {...register("bank")} onChange={(e) => selectBanksis(e, "bankinfo", "addbank")}>
                                                        <option value=''>{t("formLabel.select")}</option>
                                                        {banklist && banklist.length > 0 &&
                                                            banklist?.map((curElem: any, index: number) =>
                                                                <option key={index} value={curElem.bank_code}>{curElem.bank_name}</option>
                                                            )}
                                                    </Form.Select >
                                                    <div className="invalid-feedback">{errors.bank?.message}</div>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6} className="mb-3">
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`bankinfo ${errors.bankName ? 'is-invalid' : ''}`} id="bankName"
                                                        placeholder="Bank" {...register("bankName")} readOnly onChange={(e) => selectBanksis(e, "bankinfo", "")}/>
                                                    <Form.Control.Feedback type="invalid">{errors.bankName?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.name")+ " *"}</Form.Label>
                                                </Form.Floating>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`bankinfo ${errors.agency ? 'is-invalid' : ''}`} id="agency"
                                                        placeholder={t("banks.agency") + " " + t("banks.withDigit")} {...register("agency")} onChange={(e) => selectBanksis(e, "bankinfo", "")}/>
                                                    <Form.Control.Feedback type="invalid">{errors.agency?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.agency")+ " *"}  </Form.Label>
                                                </Form.Floating>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`bankinfo ${errors.account ? 'is-invalid' : ''}`} id="account"
                                                        placeholder={t("banks.accountNo") + " " + t("banks.withDigit")} {...register("account")} onChange={(e) => selectBanksis(e, "bankinfo", "")}/>
                                                    <Form.Control.Feedback type="invalid">{errors.account?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.accountNo")+ " *"} {t("banks.withDigit")}</Form.Label>
                                                </Form.Floating>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`bankinfo ${errors.digit ? 'is-invalid' : ''}`} id="digit"
                                                        placeholder={t("banks.withDigit")} {...register("digit")} onChange={(e) => selectBanksis(e, "bankinfo", "")}/>
                                                    <Form.Control.Feedback type="invalid">{errors.digit?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.withDigit")+ " *"} </Form.Label>
                                                </Form.Floating>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <footer className="footer-fixed-lg bg-white border-top py-2 px-xl-5 px-md-4 px-3 mx-lg-0 mx-md-n4 mx-n3 mb-n3 mb-md-n4 mb-xl-0 mt-auto d-flex flex-wrap justify-content-lg-end">
                        {/* <Button variant="outline-primary" className="me-lg-3 me-2">{t('button.cancel')}</Button>  */}
                        <div className="p-2 min-h-56 d-flex flex-wrap align-items-center">
                            {/* checked={issValue === 0} */}
                            <Form.Check id="isReadterms" type="checkbox" {...register('isReadterms')} className={`${errors.isReadterms ? 'is-invalid' : ''}`} onClick={issContract} label={<span>{t('insurelist.readaccept')} <a
                             href="https://storage.googleapis.com/ingaia-insurance/terms-previews/NOMEA%C3%87%C3%83O%20E%20TERMOS%20DE%20CONDI%C3%87%C3%95ES%20-%202020120.pdf"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          className="cursor-pointer text-primary text-end"> <span style={{ color: '#f82e52', fontWeight: 'bold' }}>{t("insurelist.termsofservice")}</span></a></span>}  />
                            <Form.Control.Feedback type="invalid">{errors.isReadterms?.message}</Form.Control.Feedback >
                        </div>
                        <Button variant="primary" className='zipCodeCheck' type="submit" onClick={openErrorbox} value="qwewqe">{t('insurelist.submitRegistration')}</Button>
                    </footer>
                </Form>
                {/* </Modal> */}
            </main>
            {/* <!-- /Main content start end --> */}
            {/* Successfully modal start from here */}
            <Modal show={newContractShow2} onHide={newContractClose2btn}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="fs-12 d-block">{t("Sweetalert.SuccessfullyComplete")}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="text-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/thumb-up-light.svg"} alt="Documents add" className="h-40 cursor-pe" />
                            <h3 className="mt-3 mb-2">{t('contractGenerated')}</h3>
                            <ul className="list-inline d-sm-flex align-items-center justify-content-center mb-lg-4 mb-3">
                                <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                            {t('printOut')}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">{t('contractMirror')}</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">{t('contract')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                    <Button variant="light" size="sm">{t('launchRentalFee')}</Button>
                                </li>
                                <li className="list-inline-item mb-sm-0 mb-1">
                                    <Button variant="light" size="sm">{t('accessContract')}</Button>
                                </li>
                            </ul>
                            <a className="text-secondary">{t('registerAthContract')}</a>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={newContractClose2btn}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Successfully modal end here */}
        </section >
    )
}
export default AddInsurence;