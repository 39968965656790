import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const RemainingBalance = (props) => {
    const [t] = useTranslation();
  //Remaining balance modal close open function
  const { showRemainingBalance, remainingBalanceClose } = props;
    return (
        <>
            <Modal show={showRemainingBalance} onHide={remainingBalanceClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('newContractDev.remainingPlanBalance')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                        <p className="text-dark-70 mb-lg-4 mb-3">{t('newContractDev.remainingPlanBalanceContent')}</p>
                        <div className="border rounded-4 p-lg-4 p-3">
                                <h6 className="mb-0 fs-12 text-secondary">{t('newContractDev.balanceRemaining')}</h6>
                                <h4 className="d-block mb-0">{t('newContractDev.balanceRemainingContent')}</h4>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}

export default RemainingBalance
