import React from 'react';
import classnames from 'classnames';
import { usePagination } from './usePagination';
import './stylefile.css'

const Pagination = props => {
    let DOTS = ".."
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange: any = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange && paginationRange[paginationRange.length - 1];
    return (
        <ul className={classnames('pagination-container', { [className]: className })} >
            {/* Left navigation arrow */}
            <li className={classnames('pagination-item', { disabled: currentPage === 1 })} onClick={onPrevious}>
                <div className="arrow left" />
            </li>
            {paginationRange && paginationRange.length > 0 && paginationRange.map((pageNumber, index) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li key={index} className="pagination-item dots">&#8230;</li>;
                }

                // Render our Page Pills
                return (
                    <li key={index} className={classnames('pagination-item', { selected: pageNumber === currentPage })} onClick={() => pageNumber === "..." ? "" : onPageChange(pageNumber)}>{pageNumber}</li>
                );
            })}
            {/*  Right Navigation arrow */}
            <li className={classnames('pagination-item', { disabled: currentPage === lastPage })} onClick={onNext}>
                <div className="arrow right" />
            </li>
        </ul>
    );
};

export default Pagination;