import { Table, Form, Badge, Button, Modal, Tooltip, OverlayTrigger, ButtonGroup, Row, Col, Card, Dropdown, FloatingLabel, ProgressBar } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { reverseIdSet } from "../../../../redux/slice/expenditureDataSlice";
import ChargeService from '../ReceivableCharges/ChargeService';
import ChargePrintOut from '../ReceivableCharges/ChargePrintOut';
import ReverseCharge from './ReverseCharge';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { incomeService } from '../../../../services/incomeServices';
import Loader from '../../../Profile/Company/Loader';
import Checkbox from '../../../Pages/Contracts/Common/Checkbox';
import { useParams } from 'react-router-dom';
import eventBus from "../ReceivableCharges/EventBus";
import { Link } from 'react-router-dom';
import { converter, encrypt_Decrypt, formatDateToDDMMYYYY } from '../../../constants/utils';
import { fetchService } from '../../../../redux/slice/ServiceSlice';
import { getTaxation } from '../../../../redux/slice/taxationSlice';
import Swal from 'sweetalert2';
import { getChargeData } from '../../../../redux/slice/transferDataSlice';
import { showChargeData, showliquidData, showcreditData, showcancelData, showgeneratData, showChargeDate, showCreditDate, showLiqueDate } from '../../../../redux/slice/contractDataSlice';
//import moment from 'moment';
import moment from "moment-timezone";
import Pagination from '../../../Pagination';
import { useForm } from 'react-hook-form';
import RevereseService from '../../../Pages/Contracts/Common/RevereseService';


var getChargesSummery;
export { getChargesSummery }

const ReceivedListing = (props) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    let { parentToChild, showmodel, setShowmodel, searchvalue, offFirstload, setOffFirstLoad, search_type,
        stateChange, setStatechange, currentPage, setCurrentPage, status, setStatus, isCheckId, setIsCheckId,
        chargesList, setChargesDue, reasonInvalidationModalClose, setLoader_on1
    } = props;
    //New charges modal initialization
    const [showPrintOutModal, setPrintOutModal] = useState(false);
    const printOutModalClose = () => setPrintOutModal(false);
    const printOutModalShow = () => setPrintOutModal(true);
    const { taxalist } = useSelector((state: RootState) => state.taxa)
    const [TaxationServices, setTaxationServices] = useState<any>({})

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({});
    const { datepickershow, datepickercreditshow, datepickerliquidateshow } = useSelector((state: RootState) => state.contractData)
    //Reverse charge modal initialization

    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");

    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
    }, [])

    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [totalCharge, setTotalCharge] = useState(0)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [getServiceId, setGetServiceId] = useState<any>([])


    useEffect(() => {
        let compData: any = '';
        if (parentToChild && parentToChild != '') {
            compData = parentToChild ? JSON.parse(parentToChild) : '';
        }
        if (compData.action == 'selectall') { selectAllUsers() }
        else if (compData.action == 'unselectall') { unSelectAllUsers() }
        else if (compData.action == 'change_expiration') { }
        else if (compData.action == 'reason_invalidation') {
            if (isCheckId && isCheckId.length > 0) {
                let JsonDataIs = JSON.parse(parentToChild);
                JsonDataIs["id_arr"] = isCheckId;
                incomeService.Invalidate_charge(JsonDataIs).then(res => {
                    if (res.data) {
                        Swal.fire({
                            title: t("Sweetalert.addedService"),
                            text: t("Sweetalert.updatesuccess"),
                            icon: 'success',
                            timer: 2000,
                        });
                        reasonInvalidationModalClose();
                        setLoader_on1(false);
                    } else {
                        setLoader_on1(false);
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.updatefailed"),
                            icon: "error",
                            timer: 2000,
                        });
                    }
                }).catch(err => {
                    setLoader_on1(false);
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.updatefailed"),
                        icon: "error",
                        timer: 2000,
                    });
                });
            } else {
                setLoader_on1(false);
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.Pleasecheckedthe"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000,
                });
                reasonInvalidationModalClose();
            }
        } else { setStatus("loading") }
    }, [dispatch, parentToChild])

    let PageSize = stateChange.end;
    const [totalpage, setTotalPage] = useState(0);

    useEffect(() => {
        stateChange["start"] = (currentPage - 1) * stateChange["end"];
        getChargesSummery()
    }, [stateChange, currentPage])

    useEffect(() => {
        // if (Object.keys(searchvalue).length) {
        setStatechange({
            ...stateChange,
            ["filter"]: searchvalue,
            ["search_type"]: search_type
        })
        // }
    }, [searchvalue, search_type])

    // useEffect(() => {
    //     // if (search_type !== "") {
    //     setStatechange({
    //         ...stateChange,
    //         ["search_type"]: search_type
    //     })
    //     // }
    // }, [search_type])

    getChargesSummery = () => {
        let totalAmount: number = 0
        incomeService.getChargesSummery(stateChange).then(res => {
            if (res.data) {
                setChargesDue(res.data);
                res.data.forEach((elem) => {
                    totalAmount += elem.VL_TOTAL_RECB;
                })
                setTotalCharge(res.totalAmount)
                setTotalPage(res.totalRow);
                dispatch(getChargeData(totalAmount))
            } else {
                setChargesDue([]);
            }
            setStatus("");
        }).catch(err => {
            setStatus("");
        });
    }

    const newChargeModalShow = async (id) => {
        document.querySelector("body")?.setAttribute('new_charge_action', "edit");
        eventBus.dispatch("chargeIdis", id);
        setTimeout(() => {
            setShowmodel(true);
        }, 600)
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        if (!isCheckAll) { selectAllUsers(); } else { unSelectAllUsers(); }
    };

    const selectAllUsers = () => {
        setChargesDue(chargesList.map((topping: any) => ({ ...topping, isChecked: true })));
        let allId: any = [];
        chargesList.forEach(item => allId.push(item.ID_RECEBIMENTO_RECB))
        setIsCheckId(allId);
    }

    const unSelectAllUsers = () => {
        setChargesDue(chargesList.map((topping: any) => ({ ...topping, isChecked: false })))
        setIsCheckId([]);
    }

    // Check / Uncheck Single row event.
    const selectRow = async (e: any, index: any) => {
        let checkedDta: any = e.target.checked;
        let allId: any = [];
        let dataId: any = e.target.getAttribute("data-id");
        let allData: any = chargesList.map((topping: any) => ({
            ...topping,
            isChecked: String(topping.ID_RECEBIMENTO_RECB) === String(dataId) ?
                checkedDta : (topping?.isChecked || false)
        }))

        let count = 0;
        allData.forEach((item: any) => {
            let Status: any = item.isChecked;
            if (!Status) {
                setIsCheckAll(false);
            } else {
                count++;
                allId.push(Number(item.ID_RECEBIMENTO_RECB))
            }
        })

        if (count == allData.length) {
            setIsCheckAll(true);
        }
        setIsCheckId(allId);
        setChargesDue(allData);
    }

    useEffect(() => {
        // Asynchronous function within the useEffect hook
        (async () => {
            // Dispatch the fetchService action
            let newdispatch = await dispatch(fetchService());
            // Set the service state with the fetched data
            setGetServiceId(newdispatch.payload);
        })()
    }, [])

    useEffect(() => {
        dispatch(getTaxation())
    }, [dispatch])



    const filterClose = () => {
        dispatch(showChargeDate(false))
        dispatch(showCreditDate(false))
        dispatch(showLiqueDate(false))
        reset();
    }

    const dateSubmit = (data: any, e: any, type: string) => {
        let startDtt = moment(data.startDate).format("YYYY-MM-DD");
        let endDtt = moment(data.endDate).format("YYYY-MM-DD");
        setStatechange({
            ...stateChange,
            ['filteSearchDate']: startDtt,
            ['filterEndDate']: endDtt,
            ['ID_RECEBIMENTO_RECB']: "",
            ["typebetween"]: type
        })
        filterClose();
        if (type == "liquid") {
            dispatch(showliquidData(stateChange))
        } else if (type == "credit") {
            dispatch(showcreditData(stateChange))
        } else {
            dispatch(showChargeData(stateChange))
        }
    }

    // const reverseOpened = (id) => {
    //     dispatch(reverseIdSet(id))
    // }
    const [type, setType] = useState("");
    const [id, setId] = useState("");
    const [showReverseModal, setRevereseModal] = useState(false);
    const reverseOpened = (id) => {
        setType("recieved");
        setId(id)
        setRevereseModal(true)
    }
    const closeRevereseModal = () => {
        setRevereseModal(false);
        setId("");
        setType("");
        getChargesSummery();
    }

    return (
        <>
            <RevereseService showReverseModal={showReverseModal} closeRevereseModal={closeRevereseModal} type={type} id={id} />

            <Card className="mb-lg-4 mb-3">
                <Card.Header>{t("ReceiveandReceived.Summaryof")} <span className='capitalize'>{stateChange?.filteSearchDate && stateChange?.filterEndDate ? moment(stateChange?.filteSearchDate).format('DD/MM/YYYY') + ' ' + t("to") + ' ' + moment(stateChange?.filterEndDate).format('DD/MM/YYYY') : moment(stateChange?.searchDate).format('MMMM/YYYY')}</span> {chargesList.summary ? chargesList.summary : ""}</Card.Header>
                <Card.Body>
                    <Row className="gy-3 gx-xxl-5 gx-md-4">
                        <Col sm={4} className="border-end border-light border-xs-none">
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/brazil-real1.png" className="me-1" />{totalCharge ? converter(String(totalCharge)) : 0}</span>
                            <span className="text-secondary d-block">{totalpage ? totalpage : 0}  {chargesList.length == 1 ? `${t("ReceiveandReceived.Chargesduetext")}` : `${t("ReceiveandReceived.Chargesduetexts")}`} </span>
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
            {
                (status == "loading") ?
                    <Loader />
                    :
                    <Card>
                        <Card.Body className="pb-1">
                            <Table responsive className="table-custom table-custom-bordered mb-0" id="ReceivedChargesListingtsx">
                                <thead>
                                    <tr>
                                        <th className="w-24"><Checkbox type="checkbox" name="selectAll" id="selectAll"
                                            handleClick={handleSelectAll} isChecked={isCheckAll} /></th>
                                        <th>{t("maturity")}</th>
                                        <th>{t("Client")}</th>
                                        <th className="text-end">{t("value")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chargesList && chargesList.length > 0 &&
                                        chargesList.map((charges: any, index: any) => {
                                            let todayDate = new Date(dateBrazil);
                                            let maturityDate: any = new Date(`${charges.DT_VENCIMENTO_RECB?.split('T')[0]}`);
                                            let dueDate: any = new Date(`${charges?.DT_LIQUIDACAO_RECB?.split('T')[0]}`)
                                            let advandLate = "";

                                            // Calculate the difference in milliseconds
                                            const timeDifference = dueDate - maturityDate;

                                            // Convert milliseconds to days
                                            const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

                                            // Round the result to get the difference in whole days

                                            const roundedDaysDifference = Math.round(daysDifference);

                                            // Check if the second date is in advance, late, or the same compared to the first date
                                            let differenceStatus;
                                            if (timeDifference > 0) {
                                                differenceStatus = t("ReceiveandReceived.dlate");
                                            } else if (timeDifference < 0) {
                                                differenceStatus = t("ReceiveandReceived.dinadvance");
                                            } else {
                                                differenceStatus = t("ReceiveandReceived.Paidontheday");
                                            }

                                            // Output the result
                                            if (isNaN(roundedDaysDifference)) {
                                                //console.log(`Invalid date format.`);
                                            } else {
                                                advandLate = (` (${Math.abs(roundedDaysDifference) !== 0 ? Math.abs(roundedDaysDifference) : ""} ${differenceStatus})`);

                                            }

                                            let Difference_In_Time = maturityDate.getTime() - todayDate.getTime();
                                            let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
                                            let MathSign = Math.sign(Difference_In_Days);
                                            let dayAgo = "";
                                            let upcomDay = "";

                                            if (MathSign == -1) {
                                                dayAgo = String(parseInt(Difference_In_Days)).replace("-", "");
                                                if (Number(dayAgo) > 30) {
                                                    if ((Math.floor(Number(dayAgo) / 30)) > 12) {
                                                        dayAgo = `(${String(Math.floor((Number(dayAgo) / 30) / 12))})y`;
                                                    } else {
                                                        dayAgo = `(${String(Math.floor(Number(dayAgo) / 30))})m`;
                                                    }
                                                } else {
                                                    if (dayAgo == "0") {
                                                        dayAgo = t("ReceiveandReceived.Today");

                                                    } else if (dayAgo == "1") {
                                                        dayAgo = t("ReceiveandReceived.Yesterday");
                                                    } else {
                                                        dayAgo = `t(${dayAgo})d`;
                                                    }
                                                }
                                            } else {
                                                upcomDay = String(parseInt(Difference_In_Days + 1));
                                                if (Number(upcomDay) > 30) {
                                                    if ((Math.floor(Number(upcomDay) / 30)) > 12) {
                                                        upcomDay = `(${String(Math.floor((Number(upcomDay) / 30) / 12))})y`;
                                                    } else {
                                                        upcomDay = `(${String(Math.floor(Number(upcomDay) / 30))})m`;
                                                    }
                                                } else {
                                                    // upcomDay = upcomDay + "d " + upcomDay;
                                                    if (upcomDay == "0") {
                                                        upcomDay = t("ReceiveandReceived.Today");

                                                    } else if (upcomDay == "1") {
                                                        upcomDay = t("ReceiveandReceived.Tomorrow");
                                                    } else {
                                                        upcomDay = `(${upcomDay})d`;
                                                    }
                                                }
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td><Form.Check type="checkbox" data-id={charges.ID_RECEBIMENTO_RECB} onChange={(e) => selectRow(e, index)} checked={charges.isChecked} value={charges.ID_RECEBIMENTO_RECB} /></td>
                                                    {/* <th>
                                                <img src="assets/images/icons/circle-check-light.svg" alt="Clock" className="h-18" />
                                            </th> */}
                                                    <td>
                                                        {moment(charges.DT_VENCIMENTO_RECB).format("DD/MM/YYYY")}

                                                        {
                                                            userJson && userJson.length > 0 && (userJson.indexOf(3033) !== -1) &&
                                                            <span className="d-block">{t("ReceiveandReceived.Settledon")}</span>
                                                        }
                                                        <span className="d-block">
                                                            {moment(charges.DT_LIQUIDACAO_RECB).format("DD/MM/YYYY")}
                                                        </span>
                                                        {/* {
                                                    dayAgo !== "" ?
                                                        <>
                                                            <span className="d-block">{dayAgo} {t("ReceiveandReceived.delay")}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="d-block">{upcomDay} {t("ReceiveandReceived.NochargesFound")}</span>
                                                        </>
                                                } */}{advandLate}
                                                        {
                                                            charges?.ID_GARANTIACOBRANCA_GCO ?
                                                                <div className="d-block"><Badge bg="success-10" className="text-dark">{t("guaranteed")}</Badge></div>
                                                                : ''
                                                        }
                                                        {
                                                            charges?.ownerStatus ?
                                                                <div className="d-block"><Badge bg="success-10" className="text-dark">Agency owned</Badge></div>
                                                                : ''
                                                        }
                                                    </td>
                                                    <td>
                                                        <span className="text-dark-70">
                                                            {
                                                                charges.ID_CONTRATO_CON != null || charges.ST_LABEL_MENS != null ?
                                                                    <Link to={"/contract_details/" + charges.ID_CONTRATO_CON ? charges.ID_CONTRATO_CON : charges.ST_LABEL_MENS} >
                                                                        <span className="text-dark-70">{t("Dashboard.Contract")} {charges.ST_IDENTIFICADOR_IMO ? charges.ST_IDENTIFICADOR_IMO : charges.CUSTOMST_MARCADOR_RECB} {charges.lease_count ? '/ ' + charges.lease_count : ''} </span> </Link>
                                                                    :
                                                                    ""
                                                            }
                                                        </span>
                                                        <span className="ps-1">
                                                            <img src="assets/images/icons/user_g.svg" />
                                                            <span className="ps-1">{charges.ID_SACADO_SAC} - {charges.ST_NOMEREF_SAC}</span>
                                                        </span>
                                                        <span className="d-block mb-1">
                                                            <Badge bg="success">{t("Dashboard.Received")} </Badge> {t("TransferPage.collection")} {charges.ID_RECEBIMENTO_RECB},
                                                            {t("ReceiveandReceived.with")} {charges.BILLING_TYPE}, {t("ReceiveandReceived.creditedon")} {String(charges.DT_RECEBIMENTO_RECB ? charges.DT_RECEBIMENTO_RECB : charges.DT_LIQUIDACAO_RECB)?.split("T")[0]} &nbsp;
                                                            {t("formLabel.in")} {charges.ST_DESCRICAO_CB} {t("ReceiveandReceived.sara")} 05/19 {t("ReceiveandReceived.with")} NN: {charges.ST_NOSSONUMERO_RECB} .
                                                        </span>
                                                        {
                                                            charges.FL_STATUS_RECB == 1 && charges.ID_PLUGNOTAS_IMD !== null &&
                                                            <div className="w-75"><ProgressBar className="h-10 w-75" variant="warning" now={65} /></div>
                                                        }

                                                        {
                                                            charges.FL_STATUS_RECB !== 1 && charges.ID_PLUGNOTAS_IMD !== null &&
                                                            <div className="w-75"><ProgressBar className="h-10 w-75" variant="success" now={100} /></div>
                                                        }

                                                        {
                                                            charges.FL_STATUS_RECB !== 1 && charges.ID_PLUGNOTAS_IMD === null &&
                                                            <div className="w-75"><ProgressBar className="h-10 w-75" variant="secondary" now={30} /></div>
                                                        }

                                                        {
                                                            charges.FL_STATUS_RECB == 1 && charges.ID_PLUGNOTAS_IMD === null &&
                                                            <div className="w-75"><ProgressBar className="h-10 w-75" variant="primary" now={10} /></div>
                                                        }
                                                    </td>
                                                    <td className="text-end">
                                                        <span className="d-block text-nowrap">R$ {converter(String(charges.VL_TOTAL_RECB))}</span>
                                                        <Badge bg="success" hidden>{t("ReceiveandReceived.Manual")}</Badge>
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            {/*<OverlayTrigger placement="bottom" overlay={<Tooltip>PDF</Tooltip>}>
                                                                <img src="assets/images/icons/print-dark.svg" alt="PDF" onClick={printOutModalShow} className="cursor-pe h-16 me-2" />
                                                                </OverlayTrigger>
                                                                <ChargePrintOut show={showPrintOutModal} onClose={printOutModalClose} />
                                                                <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("button.edit")}</Tooltip>}>
                                                                    <img src="assets/images/icons/edit-light.svg" alt="Edit" onClick={() => newChargeModalShow(charges.ID_RECEBIMENTO_RECB)} className="cursor-pe h-16 me-2" />
                                                                </OverlayTrigger> 
                                                            */}
                                                            {
                                                                charges.FL_STATUS_RECB == 1 && charges.FL_STATUS_CON != 6 && charges.FL_STATUS_CON != 5 &&
                                                                    userJson && userJson.length > 0 && (userJson.indexOf(3034) !== -1) ?
                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("ReceiveandReceived.Reverse")}</Tooltip>}>
                                                                        <span className="icon cursor-pe" onClick={() => reverseOpened(charges.ID_RECEBIMENTO_RECB)} ><img src="assets/images/icons/reverse-primary.svg" alt="Reverse" className="h-20" /></span>
                                                                    </OverlayTrigger>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        }
                                        )}
                                    {!chargesList.length ? (
                                        <tr>
                                            <td colSpan={6} className="text-center"> {t("ReceiveandReceived.NochargesFound")}.</td>
                                        </tr>
                                    ) : (<></>)
                                    }
                                </tbody>
                            </Table >
                        </Card.Body>
                    </Card>
            }
            {
                totalpage > 0 &&
                <div className="d-flex justify-content-center my-lg-4 my-3">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalpage}
                        pageSize={PageSize}
                        onPageChange={(page: any) => setCurrentPage(page)}
                    />
                </div>
            }

            <p className="mt-lg-4 mt-3 mb-lg-4 mb-3">
                {/*{t("ReceiveandReceived.Marked")} 1 {t("ReceiveandReceived.collectionfrom")} 1 {t("ReceiveandReceived.customerinthetotalamountof")} R$ 112.87 .*/}
                {/*<span className="d-block mt-1">{t("formLabel.listing")} 10 {t("ReceiveandReceived.chargesfrom")} 8 {t("ReceiveandReceived.customersinthetotalamountof")} R$ 19,627.14 .</span>*/}
                <span className="d-block mt-1">{t("formLabel.listing")} {chargesList ? chargesList.length : 0} of {totalpage ? totalpage : 0} {t("ReceiveandReceived.charges")} {t("ReceiveandReceived.totalamountof")} R$ {totalCharge ? converter(String(totalCharge)) : 0}</span>
            </p>
            <ReverseCharge getChargesSummery={getChargesSummery} />
            <Modal show={datepickershow} onHide={filterClose}>
                <Form onSubmit={handleSubmit((data, e) => dateSubmit(data, e, "duedate"))}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to"  {...register("endDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={datepickerliquidateshow} onHide={filterClose}>
                <Form onSubmit={handleSubmit((data, e) => dateSubmit(data, e, "liquid"))}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.liquidatedatebetween")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to"  {...register("endDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={datepickercreditshow} onHide={filterClose}>
                <Form onSubmit={handleSubmit((data, e) => dateSubmit(data, e, "credit"))}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.crediteddatebetween")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to"  {...register("endDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* <ChargeService show={showChargeModal} onClose={newChargeModalClose} /> */}
        </>
    )
}

export default ReceivedListing
