import React, { useState, useEffect } from 'react'
import { Dropdown, InputGroup, Col, Row, Form, Button, Modal, Card, Table, FloatingLabel, Alert, Accordion, Badge, DropdownButton } from 'react-bootstrap';
import { Outlet, Link, useParams, useSearchParams } from 'react-router-dom';
import customerService from '../../../../services/customerService';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getAllProperty, getAllSuppliers } from '../../../../redux/slice/contractDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { handleAction } from '../../../Profile/Company/actions';
import { condosForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { getAdministrators } from '../../../../redux/slice/propertyDataSlice';
import { FORMERR } from 'dns';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';

const Condos_details = ({ submenu }) => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState<Number>();
    const [AllStates, setAllStates] = useState([]);
    const [admName, setAdmName] = useState<any>();
    const [show, setShow] = useState(false);
    const [allSuplier, setAllSuplier] = useState([])
    const { administData, status } = useSelector((state: RootState) => state.propertyData)

    const propertyValidation = Yup.object().shape({
        name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')), 
        telephone: Yup.string().trim().nullable()
            .required(t("requiredMmessage.fieldRequired"))
            .matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`), 
    });
    let frm = {
        resolver: yupResolver(propertyValidation)
    }
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors } = useForm<condosForm>(frm);
    useEffect(() => {
        let condosData = { condos: '', start: 0, end: 100 }
        customerService.getAllCondos(condosData)
            .then(res => {
                if (res.data.resCode === 201) {
                    res.data.data.forEach((item: any) => {
                        if (item.id == id) {
                            setAdmName(item?.name)
                            const fields: string[] = ['id', 'cnpj', 'name', 'fantasy_name', 'zipcode', 'address', 'number', 'neighborhood', 'city', 'telephone', 'email', 'billing_method', 'condo_due_date', 'trustee_name', 'trustee_email', 'trustee_mobile', 'trustee_telephone', 'caretaker_name', 'caretaker_email', 'caretaker_telephone', 'caretaker_mobile', 'attorney_name', 'lawyer_email', 'lawyer_telephone', 'lawyer_mobile', 'supplier_name'];
                            fields.forEach((field: any) => setValue(field, item[field]));
                            setTimeout(() => {
                                setValue("administrator_name", item['administrator_name'])
                                setValue('state', item["state"]);
                            }, 1000);
                        }
                    });
                } else {
                    // setData([]);
                    // setTotal(0)
                }
            })
            .catch(err => {
                console.log("err", err);
            });
    }, []);

    useEffect(() => {
        dispatch(getAdministrators())
    }, [dispatch])

    useEffect(() => {
        (async () => {
            let resp = await dispatch(getAllProperty({
                start: 0,
                end: 1000
            }));
            let allData: any = [];
            resp.payload.data.forEach((item) => {
                if (Number(item.condos_id) === Number(id)) {
                    allData.push(item)
                }
            });
            setData(allData)
            setTotal(allData?.length)
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, [])
    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
          `${inputType}/${newStr}`
        );
        // Retrieve address based on zipcode using customerService
        // Get the DOM element for the zipcode input
        let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            // Check if the response status is success
            if (resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                zipCodeCheck.removeAttribute('disabled');
                // Set the city value if available in the response
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                // Reset city, neighborhood, and address values
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
                zipCodeCheck?.setAttribute('disabled', "")
            }
        } else {
            querySel.style.border = "0px solid red";
            zipCodeCheck?.removeAttribute('disabled');
        }
    }

    const onSubmit = async (data: any) => {
        customerService.updateCondos(data).then(res => {
            /*-------Check the api response--------*/
            if (res.data.resCode === 200) {
                Swal.fire({
                    title: t("Sweetalert.Update"),
                    text: t("Sweetalert.updatesuccess"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        });
    }
    const handleEdit = (e: any, cmd: string) => {
    }

    useEffect(() => {
        (async () => {
            let allSuplier = await dispatch(getAllSuppliers())
            if (allSuplier.payload.resCode == 201) {
                setAllSuplier(allSuplier.payload.data)
            }
        })()
    }, [dispatch])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [multipleTenant, setMultipleTenant] = useState([
        {
            value: "",
            label: ""
        }
    ])
    const handleTenantChange = (e, index, type) => {
        if (type == "tenent") {
            let pickData = allSuplier.filter((item: any) => Number(item.id) === Number(e.target.value));
            multipleTenant[`${index}`]["value"] = pickData[0]["id"];
            multipleTenant[`${index}`]["label"] = pickData[0]["name"];
        }
        let newData = multipleTenant;
        setMultipleTenant(newData);
    }
    const addAnother = (e) => {
        setMultipleTenant([
            ...multipleTenant,
            {
                value: "",
                label: "",
            }
        ])
    }

    const handleDelete = (e, index) => {
        multipleTenant.splice(index, 1);
        setMultipleTenant([...multipleTenant]);
        multipleTenant.forEach((item, index) => {
            // setValue(`datatenants.tenants.${index}.id`, item.id);
            // setValue(`datatenants.tenants.${index}.name`, item.value);

        })
    }
    const addSupliers = (data) => {
        let newData = data["supplier_name"] = multipleTenant;
        //console.log(newData)
    }

    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
       
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document?.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, id);
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    const numericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) return true
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    return (
        <section className="content-wrapper">
            {submenu}
            {/*  <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <Link to={"/condos"}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                            </Link>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 ">
                    <Col xl={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col lg={8}>
                                        <div className="d-flex align-items-center">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/home-reqular.svg"} alt="Folder" className="h-40 flex-shrink-0" />
                                            <div className="w-100 ms-2 ms-lg-3">
                                                <h1 className="h3 lh-base mb-0">{admName}</h1>
                                                <p className="mb-0">{admName}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <ul
                                            className="list-inline d-sm-flex align-items-center justify-content-start justify-content-lg-end mb-0">
                                            <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="mh-456 overflow-auto">
                                                        <Dropdown.Item>Change log</Dropdown.Item>
                                                        <Dropdown.Item>Send email</Dropdown.Item>
                                                        <Dropdown.Item>Notify the condominium parties</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="list-inline-item mb-sm-0 mb-1">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic2">
                                                        Printout
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">Contract</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={6}>
                        <Card>
                            <Card.Header className="d-flex align-items-center justify-content-between">
                                Registration data
                                <span className="d-block TOEDIT">
                                    <span>
                                        <span>
                                            <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "administ")}>
                                                {t("button.edit")}
                                            </Button>
                                        </span>
                                    </span>
                                </span>
                                <div className="d-none ACTION">
                                    <Row>
                                        <Col xl={4} sm={4}>
                                            <Button variant="link " className="p-0 fw-bold text-nowrap zipCodeCheck" onClick={(e) => handleAction(e, "update", "administ")} type="submit">
                                                {t("button.toSave")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form
                                    onSubmit={handleSubmit(onSubmit)} id="editform_companydata">
                                    <Form.Control hidden type="text" placeholder="id"  {...register('id')} />

                                    <FloatingLabel controlId="Name" label="Name" className="mb-lg-4 mb-3 removeInsertDisable">
                                        <Form.Control disabled type="text" placeholder="Name" className={`${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                                        <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="cnpj" label="CNPJ" className="mb-lg-4 mb-3 removeInsertDisable">
                                        <Form.Control disabled type="text" placeholder="CNPJ" className={`${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")} />
                                        <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="fantasy_name" label="fantasy name" className="mb-lg-4 mb-3 removeInsertDisable">
                                        <Form.Control disabled type="text" placeholder="fantasy name" className={`${errors.fantasy_name ? 'is-invalid' : ''}`} {...register('fantasy_name')} />
                                        <Form.Control.Feedback type="invalid">{errors.fantasy_name?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.administrator')}>
                                        <Form.Select aria-label={t('formLabel.administrator')} className={`form-control ${errors.administrator_name ? 'is-invalid' : ''}`} {...register('administrator_name')} id='removeInsertDisable' disabled>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {administData && administData.length > 0 ? administData.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )) :
                                                <option value="">{t('formLabel.noRecordsFounds')}</option>
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.administrator?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <Accordion className="accordion-custom">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Address</Accordion.Header>
                                            <Accordion.Body>
                                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 removeInsertDisable">
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="zipCode" label="Zip code" className="mb-lg-4 mb-3  removeInsertDisable getzipcode">
                                                            <Form.Control type="text" placeholder="Zip code" className={`${errors.zipcode ? 'is-invalid' : ''}`} {...register("zipcode")}   onChange={(e) => getLocation(e, "zipcode")} maxLength={9} minLength={9}  disabled />
                                                            <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="address" label="Address" className="mb-lg-4 mb-3 removeInsertDisable" >
                                                            <Form.Control disabled type="text" placeholder="Address" className={`${errors.address ? 'is-invalid' : ''}`} {...register("address")} />
                                                            <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="number" label="Number" className="mb-lg-4 mb-3 removeInsertDisable" >
                                                            <Form.Control disabled type="text" placeholder="Number" className={`${errors.number ? 'is-invalid' : ''}`} {...register("number")} />
                                                            <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="complement" label="Complement" className="mb-lg-4 mb-3 removeInsertDisable">
                                                            <Form.Control disabled type="text" placeholder="Complement" className={`${errors.complement ? 'is-invalid' : ''}`} {...register("complement")} />
                                                            <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={5}>
                                                        <FloatingLabel controlId="neighborhood" label="Neighborhood" className="mb-lg-4 mb-3 removeInsertDisable">
                                                            <Form.Control disabled type="text" placeholder="Neighborhood" className={`${errors.neighborhood ? 'is-invalid' : ''}`} {...register("neighborhood")} />
                                                            <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FloatingLabel controlId="city" label="City" className="mb-lg-4 mb-3 removeInsertDisable">
                                                            <Form.Control disabled type="text" placeholder="City" className={`${errors.city ? 'is-invalid' : ''}`} {...register("city")} />
                                                            <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FloatingLabel controlId="state" label={t("formLabel.state")} >
                                                            <Form.Select className={`${errors.state ? 'is-invalid' : ''}`}  {...register('state')} id="removeInsertDisable" disabled>
                                                                <option value=''>--type--</option>
                                                                {AllStates.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                    )
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>

                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Contact</Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel controlId="Email" label="Email" className="mb-lg-4 mb-3 removeInsertDisable">
                                                    <Form.Control disabled type="email" placeholder="Email" className={`${errors.email ? 'is-invalid' : ''}`} {...register('email')} readOnly />
                                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                                <FloatingLabel controlId="telephone" label="Telephone" className="mb-lg-4 mb-3 removeInsertDisable">
                                                    <Form.Control disabled type="text" placeholder="Telephone" className={`${errors.telephone ? 'is-invalid' : ''}`} {...register('telephone')} onChange={HandleInput} />
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col md={6}>
                                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.billingMethod')}>
                                                            <Form.Select aria-label={t('formLabel.billingMethod')} {...register('billing_method')} id="removeInsertDisable" disabled>
                                                                <option value="">{t('dropdownItem.select')}..</option>
                                                                <option value="1">Expired month</option>
                                                                <option value="2">Month to expire</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.condoDueDate')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.condoDueDate')} {...register('condo_due_date')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>{t('property.trustee')}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col md={12}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.name')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.name')} {...register('trustee_name')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.telephone')}  {...register('trustee_telephone')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.mobileNumber')} {...register('trustee_mobile')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.email')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.email')} {...register('trustee_email')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>{t('formLabel.caretaker')}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col md={12}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.name')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.name')}  {...register('caretaker_name')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.telephone')} {...register('caretaker_telephone')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.mobileNumber')} {...register('caretaker_mobile')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.email')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.email')} {...register('caretaker_email')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>{t('formLabel.attorney')}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col md={12}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.name')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.name')}  {...register('attorney_name')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>{t('formLabel.lawyerContact')}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>

                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.telephone')} {...register('lawyer_telephone')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.mobileNumber')} {...register('lawyer_mobile')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.email')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.email')} {...register('lawyer_email')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>

                                    <Button className="submitForm zipCodeCheck" type="submit" variant="primary" hidden>
                                        {t("button.update")}
                                    </Button>
                                    <Button className="fillModalwithThisData" onClick={(e) => handleEdit(e, "administ")} hidden>
                                        {t("button.edit")}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={6}>

                        <Card>
                            <Card.Header className="d-flex align-items-center justify-content-between">
                                Recent payments
                                <span className="ms-2">
                                    <Button type="button" variant="link" size="sm" className="fw-bold p-0 edit">All payment</Button>
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Alert variant="warning" className="text-center my-lg-3 my-2">
                                    No payments found.
                                </Alert>
                            </Card.Body>
                        </Card>
                        <Card className="mt-4">
                            <Card.Header className="d-flex align-items-center justify-content-between">
                                Suppliers
                                <span className="ms-2">
                                    <Button type="button" variant="link" size="sm" className="fw-bold p-0 edit" onClick={handleShow}>To Add</Button>
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Alert variant="warning" className="text-center my-lg-3 my-2">
                                    No suppliers found.
                                </Alert>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Email list</Accordion.Header>
                        <Accordion.Body>
                            <Alert variant="warning" className="text-center my-lg-3 my-2">
                                No email in the mailing list.
                            </Alert>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Properties</Accordion.Header>
                        <Accordion.Body>
                            <Table responsive className="table-custom mb-0">
                                <thead>
                                    <tr>
                                        <th></th>

                                        <th>{t('property.immobile')}</th>
                                        <th></th>
                                        <th>{t('property.saleValue')}</th>
                                        <th>{t('property.rentAmount')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((item: any, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="check_Checkbox">
                                                        {/* <Checkbox key={item.id} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} */}
                                                        {/* /> */}
                                                    </td>
                                                    <td>
                                                        <Link className="text-reset" to={"/property_detail/" + item.id}>
                                                            <img src="../assets/images/icons/home_g.svg" />
                                                            <span className="ps-1 text-dark-70">
                                                                {item.number} - {item.address}, {item.zipcode}
                                                            </span>
                                                            <span className="d-block">
                                                                {item.city} - {item.state}
                                                            </span>
                                                        </Link>
                                                    </td>
                                                    <td className="text-dark-70">
                                                        <span className="d-block">
                                                            <img src="../assets/images/icons/user_g.svg" />

                                                            <Link to={"/profile/" + item.ownerId} className="text-secondary text-nowrap">
                                                                {item.ownerName}
                                                            </Link>

                                                        </span>
                                                        <span className="d-block">
                                                            <img src="../assets/images/icons/key_g.svg" />
                                                            <Link to={"/profile_details/" + item.tenetID} className="text-secondary text-nowrap">
                                                                {item.tenetName}
                                                            </Link>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {item.sale_value !== null ? (
                                                            <span className="d-block text-nowrap">R$ {item.sale_value}</span>
                                                        ) : (
                                                            <span>R$ {0}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.rent_amount !== null ? (
                                                            <span className="d-block text-nowrap">R$ {item.rent_amount}</span>
                                                        ) : (
                                                            <span>R$ {0}</span>
                                                        )}
                                                    </td>
                                                    <td className="text-end">

                                                        {
                                                            item.contractId ?
                                                                <Link to={"/contract_details/" + item.contractId} className="fw-bold me-2 text-nowrap">
                                                                    {t('button.seeContract')}
                                                                </Link>
                                                                :
                                                                ''
                                                        }
                                                        <Link to={"/property_detail/" + item.id} className="fw-bold text-nowrap">
                                                            {t('button.seeProperty')}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <span>{t('formLabel.noRecordsFounds')}</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
            {/* <!-- /Main content start end --> */}
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(addSupliers)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add suppliers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            multipleTenant && multipleTenant.length > 0 &&
                            multipleTenant.map((item, index) => (
                                <React.Fragment key={index}>
                                    <Row className="gy-3 gy-lg-4mb-lg-4 mb-3">
                                        <Col xs>
                                            <InputGroup>
                                                <FloatingLabel controlId="floatingInput" label={t('tenants')}>
                                                    <Form.Select {...register(`supplier_name.${index}`)} onChange={(e) => handleTenantChange(e, index, "tenent")}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {allSuplier.map((item: any, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {/* <Form.Control.Feedback type="invalid">{errors?.tenants?.message}</Form.Control.Feedback > */}
                                                </FloatingLabel>
                                                {/* <InputGroup.Text onClick={newTenantsOpen}>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                    </InputGroup.Text> */}
                                            </InputGroup>
                                        </Col>
                                        {
                                            multipleTenant.length > 1 &&
                                            <>

                                                <Col md={2}>
                                                    <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => handleDelete(e, index)}><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                                </Col>


                                            </>
                                        }
                                    </Row>
                                </React.Fragment>
                            ))
                        }
                        {

                            <Row>
                                <Col md={6}>
                                    <Button variant='link' onClick={(e) => addAnother(e)}>
                                        Another
                                    </Button>
                                </Col>
                            </Row>
                        }</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </section >

    )
}

export default Condos_details