import { Tab, Tabs, Container, Badge, FloatingLabel, Card, Table, Col, Row, Modal, Form, Button, Alert, } from "react-bootstrap";
import { getTickets, getDashboardMainSource, getDashboardIncome, getDashboardCharges, getDashboard, getPendenciesData, getTransferFilter, getPendenciesData_internal } from "../../redux/slice/transferDataSlice";
import React, { useState, MouseEvent, useEffect } from "react";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ComProps } from "../Interface";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import moment from "moment";
import Loader from "../Profile/Company/Loader";
import transferService from "../../services/transferService";
import { converter } from "../constants/utils";
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart';
import { partnershipSmallOpen } from "../../redux/slice/contractDataSlice";
import { incomeService } from "../../services/incomeServices";
import { getChargesSummery } from "./Income/ReceivableCharges/ReceivableChargesListing";
import InsurancePopups from './Contracts/Common/InsurancePopups';
import moment from "moment-timezone";
import { config } from "../../services/apiConfig";
const Dashboard = ({ submenu }: ComProps) => {
	let dateBrazil: any = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
	const {
		ticketData,
		ticketStatus, dashMainData, dashMainStatus, dashIncomeData, dashIncomeStatus,
		dashChargeStatus, dashChargeData, pendenciesData, pendenciesStatus,
		dashboardData, dashboardStatus, TotalCharge, pendenciesData_internal
	} = useSelector((state: RootState) => state.transferData);
	const [transfers, setTransfers] = useState<any>([]);
	const [insuranceStatus, setInsuranceStatus] = useState('0');
	const [expenses, setExpenses] = useState<any>([]);
	const [totalCharge, setTotalCharge] = useState<any>({
		totalReceivedCharge: 0,
		ReceivedChargeLength: ""
	})

	let userEmailId: any = localStorage.getItem("userEmailId");
	if (userEmailId) {
		window?.fcWidget?.user?.setEmail(userEmailId)
	}

	useEffect(() => {
		// Criar e adicionar o script
		const script = document.createElement('script');
		script.src = '//fw-cdn.com/12121290/4629993.js';
		script.setAttribute('chat', 'true');
		script.setAttribute('widgetId', 'fe6f031d-be20-4970-a00b-2ab84fe1cdd0');
		document.body.appendChild(script);

		return () => {
			// Remover o script quando o componente for desmontado
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (window?.fcWidget) {
			window.fcWidget.show();
		}
	}, [])

	const { t, i18n } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	/*-------------Load the Dashboard count----------*/
	useEffect(() => {
		dispatch(getDashboard({ name: "" }));
		dispatch(getDashboardCharges({ name: "charges" }));
		dispatch(getDashboardIncome({ name: "income" }));
		dispatch(getDashboardMainSource({ name: "main source" }));
		dispatch(getTickets({ start: 0, end: 1000, requestType: "tickets", main: "dashboard" }));
		getDashTransfers();
		getDashExpenses();
		let dataaaaa = getChargesSummery;

		// INSURANCE
		transferService.getAgencyInsuranceStatus()
			.then((res) => {
				if (res.resCode == 202) {
					setInsuranceStatus(res.data.FL_PRORATADIA_EMP);
				} else {
					setInsuranceStatus('0');
				}
			})
			.catch((err) => {
				// setInsuranceStatus('0');
				console.log("err", err);
			});

	}, [dispatch]);

	/*-------------Load the Pendencies listing----------*/

	const [state, setState] = useState({
		contract: "",
		limits: "",
		start: 0,
		end: 3,
	});
	const [mainsourcesValue, setMainsourceValue] = useState([
		{
			color: "#FFB0BE",
			title: "Agreement",
			value: 0,
		},
		{
			color: "#F82E52",
			title: "Sales commission",
			value: 0,
		},
		{
			color: "#FF667F",
			title: "Insurance commission",
			value: 0,
		},
		{
			color: "#AB2038",
			title: "Administration fee",
			value: 0,

		},
	])

	const [internal, setInternal] = useState(false);
	const searchContract = (evt, limit) => {
		setInternal(false);
		const value = evt;
		setState({
			...state,
			["contract"]: value,
			["limits"]: limit
		});
	};

	const searchContractInternal = (evt, limit) => {
		setInternal(true);
		const value = evt;
		setState({
			...state,
			["contract"]: value,
			["limits"]: limit
		});
	};

	const seeContract = (evt, limit) => {
		let boddy: any = document.querySelector("body");
		boddy.classList.add("pendencies");
		setTimeout(() => {
			boddy.classList.remove("pendencies");
		}, 2000)
		navigate("/contracts")
	}

	useEffect(() => {
		dispatch(getPendenciesData_internal(state));
	}, []);

	useEffect(() => {
		if (internal) {
			dispatch(getPendenciesData_internal(state));
		} else {
			dispatch(getPendenciesData(state));
		}
	}, [dispatch, state]);

	const getDashTransfers = () => {
		transferService.getDashboardTransfers({})
			.then((res) => {
				setTransfers(res.data);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	const getDashExpenses = () => {
		transferService
			.getDashboardExpenses({})
			.then((res) => {
				setExpenses(res.data);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	let data: any = {
		start: 0,
		end: 10
	}
	/*-------------End-----------*/
	// const formattedNumber = converter("29.88");
	let proprtyDataType = [
		{ id: 1, title: t('residential') },
		{ id: 2, title: t('nonResidential') },
		{ id: 3, title: t('commercial') },
		{ id: 4, title: t('industry') },
		{ id: 5, title: t('season') },
		{ id: 6, title: t('byOrder') },
		{ id: 7, title: t('mixed') }
	]
	const [changeProprtyType, setChangeProprtyType] = useState<any>(proprtyDataType)

	useEffect(() => {
		let changeLanguage = i18n.language;
		let typeofProperty: any = []
		if (changeLanguage == "pt") {
			pendenciesData?.data?.forEach(element => {
				proprtyDataType?.forEach((propery: any) => {
					if (element.type == propery.id) {
						typeofProperty.push(propery)
					} else {

					}
				})
				setChangeProprtyType(typeofProperty)
			});
		} else {
			pendenciesData?.data?.forEach(element => {
				proprtyDataType?.forEach((propery: any) => {
					if (element.type == propery.id) {
						typeofProperty.push(propery)
					}
				})
				setChangeProprtyType(typeofProperty)
			});
		}
	}, [i18n.language])

	useEffect(() => {
		let argument = {
			FL_STATUS_RECB: '1,2',
			type: "received",
			Maturity_Date: "2023-06",
			filter: {},
			filteSearchDate: "",
			filterEndDate: "",
		}
		let totalAmount: number = 0
		incomeService.getChargesSummery(argument).then(res => {
			if (res.data) {
				let totalAmount = 0; // Initialize totalAmount before the loop
				res.data.forEach(elem => {
					totalAmount += Number(elem.vl_emitido_recb) || 0; // Add vl_emitido_recb to totalAmount, use 0 if vl_emitido_recb is undefined
				});

				setTotalCharge({
					totalReceivedCharge: totalAmount,
					ReceivedChargeLength: res.data.length
				})
			}
		}).catch(err => {
		});

	}, [])

	const openKenloPartLargepopup = (e, agencyInfo) => {
		dispatch(partnershipSmallOpen(agencyInfo))
	}
	useEffect(() => {
		if (dashMainData?.data) {
			const numericData = dashMainData?.data?.map(item => ({
				"admFeeMainSource": parseFloat(item?.admFeeMainSource.replace(/,/g, '')),
				"salesCommMainSource": parseFloat(item.salesCommMainSource.replace(/,/g, '')),
				"insCommMainSource": parseFloat(item.insCommMainSource.replace(/,/g, '')),
				"agrMainSource": parseFloat(item.agrMainSource.replace(/,/g, ''))
			}));
			// Calculate total for each category
			const totals = {
				"admFeeMainSource": numericData.reduce((acc, item) => acc + item.admFeeMainSource, 0),
				"salesCommMainSource": numericData.reduce((acc, item) => acc + item.salesCommMainSource, 0),
				"insCommMainSource": numericData.reduce((acc, item) => acc + item.insCommMainSource, 0),
				"agrMainSource": numericData.reduce((acc, item) => acc + item.agrMainSource, 0)
			};

			// Calculate percentages
			const totalMainSource = Object.values(totals).reduce((total, value) => total + value, 0);

			// Calculate the percentages for each category
			const percentages: any = {};
			for (const category in totals) {
				percentages[category] = (totals[category] / totalMainSource) * 100;
			}

			// const percentages: any = {};
			setMainsourceValue((prevValues) => [
				...prevValues.slice(0, 3),
				{ ...prevValues[3], value: percentages?.admFeeMainSource },
				...prevValues.slice(4),
			]);
			setMainsourceValue((prevValues) => [
				...prevValues.slice(0, 2),
				{ ...prevValues[2], value: percentages?.salesCommMainSource },
				...prevValues.slice(3),
			]);
			setMainsourceValue((prevValues) => [
				...prevValues.slice(0, 1),
				{ ...prevValues[1], value: percentages?.insCommMainSource },
				...prevValues.slice(2),
			]);
			setMainsourceValue((prevValues) => [
				...prevValues.slice(0, 0),
				{ ...prevValues[0], value: percentages?.agrMainSource },
				...prevValues.slice(1),
			]);
		}
	}, [dashMainData])

	const TransferFilter = (e: any, type: any) => {
		e.preventDefault();
		let searchVal = "";
		let endval = "";
		switch (type) {
			case "overdue":
				searchVal = moment().startOf("months").format("YYYY-MM-DD");
				endval = moment().subtract(1, "days").format("YYYY-MM-DD");
				break;
			case "dueToday":
				searchVal = moment().format("YYYY-MM-DD");
				endval = moment().format("YYYY-MM-DD");
				break;
			case "thisWeek":
				searchVal = moment().startOf("week").format("YYYY-MM-DD");
				endval = moment().endOf("week").format("YYYY-MM-DD");
				break;
			case "nextmonths":
				searchVal = moment().add(1, "months").startOf("months").format("YYYY-MM-DD");
				endval = moment().add(3, "months").endOf("months").format("YYYY-MM-DD");
				break;
			default:
				break;
		}
		dispatch(getTransferFilter(type))
		// ?type=${type}&dashboard=true
		navigate(`/new_transfers`, {
			state: {
				type: type,
				dashboard: "yes",
				searchFrom: searchVal,
				endFrom: endval
			}
		})
	}
	// Function to convert date to desired format

	const handleCharge = (e: any, type: any) => {
		e.preventDefault();
		let searchVal = "";
		let endval = "";
		switch (type) {
			case "overdue":
				searchVal = moment().startOf("months").format("YYYY-MM-DD");
				endval = moment().subtract(1, "days").format("YYYY-MM-DD");
				break;
			case "dueToday":
				searchVal = moment().format("YYYY-MM-DD");
				endval = moment().subtract(1, "days").format("YYYY-MM-DD");
				break;
			case "received":
				searchVal = moment().startOf("months").format("YYYY-MM-DD");
				endval = moment().endOf("months").format("YYYY-MM-DD");
				break;
			default:
				break;
		}

		if (type == "received") {
			navigate(`/received`, {
				state: {
					type: type,
					dashboard: "yes",
					searchFrom: searchVal,
					endFrom: endval
				}
			})
		} else {
			navigate(`/receivable_charges`, {
				state: {
					type: type,
					dashboard: "yes",
					searchFrom: searchVal,
					endFrom: endval
				}
			})
		}
	}

	const handleExpenseclick = (e: any, type: any) => {
		e.preventDefault();
		let searchVal = "";
		let endval = "";
		switch (type) {
			case "overdue":
				searchVal = moment().startOf("months").format("YYYY-MM-DD");
				endval = moment().subtract(1, "days").format("YYYY-MM-DD");
				break;
			case "dueToday":
				searchVal = moment().format("YYYY-MM-DD");
				endval = moment().format("YYYY-MM-DD");
				break;
			case "thisWeek":
				searchVal = moment().startOf("week").format("YYYY-MM-DD");
				endval = moment().endOf("week").format("YYYY-MM-DD");
				break;
			case "3months":
				searchVal = moment().add(1, "months").startOf("months").format("YYYY-MM-DD");
				endval = moment().add(3, "months").endOf("months").format("YYYY-MM-DD");
				break;
			default:
				break;
		}

		navigate(`/toBePaid`, {
			state: {
				type: type,
				dashboard: "yes",
				searchFrom: searchVal,
				endFrom: endval
			}
		})
	}

	const goToExpense = (e: any, ID_REPASSE_REP) => {
		e.preventDefault();
		let data: any = {
			ID_REPASSE_REP: ID_REPASSE_REP
		};
		navigate(`/toBePaid`, { state: data });
	}
	return (
		<section className="content-wrapper">
			{submenu}
			<Container
				fluid
				className="px-xl-5 px-md-4 px-3 pt-3 pb-xl-5 pb-md-4 pb-3"
			>
				<Row className="gx-md-4 gy-md-3 g-3">
					<Col md={8}>
						<Row className="h-100 gy-lg-0 gy-3">
							<Col md={4}>
								<Card className="h-100">
									<Card.Body>
										<Link
											to={"/contractsSearch/Active"}
											className=" w-100 h-100 d-flex flex-column"
										>
											<span className="d-flex align-items-center text-dark h3 mb-lg-4 mb-2">
												<img src="assets/images/icons/circle-check-light.svg" alt="icon" className="me-1" />
												<span className="lh-1">
													{dashboardData.data && dashboardData.data.length > 0
														? dashboardData.data[0].activeContract
														: 0}
												</span>
											</span>
											<span className="text-secondary d-block mt-auto lh-1">
												{t("Dashboard.ActiveContracts")}
											</span>
										</Link>
									</Card.Body>
								</Card>
							</Col>
							<Col md={4}>
								<Card className="h-100">
									<Card.Body>
										<Link
											to={"/contractsSearch/Insurance"}
											className=" w-100 h-100 d-flex flex-column"
										>
											<span className="d-flex align-items-center text-dark h3 mb-lg-4 mb-2">
												<img src="./assets/images/icons/trophy-exclamation.svg" alt="icon" className="me-1" />
												<span className="lh-1">
													{dashboardData.data && dashboardData.data.length > 0
														? dashboardData.data[0].withoutInsurance
														: 0}
												</span>
											</span>
											<span className="text-secondary d-block mt-auto lh-1">
												{t("Dashboard.Contractswithoutinsurance")}
											</span>
										</Link>
									</Card.Body>
								</Card>
							</Col>
							<Col md={4}>
								<Card className="h-100">
									<Card.Body>
										<Link
											to={"/contractsSearch/Warranty"}
											className=" w-100 h-100 d-flex flex-column"
										>
											<span className="d-flex align-items-center text-dark h3 mb-lg-4 mb-2">
												<img src="assets/images/icons/bag.svg" alt="icon" className="me-1" />
												<span className="lh-1">
													{dashboardData.data && dashboardData.data.length > 0
														? dashboardData.data[0].withoutWarranty
														: 0}
												</span>
											</span>
											<span className="text-secondary d-block mt-auto lh-1">
												{t("Dashboard.Contractswithoutwarranty")}
											</span>
										</Link>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
					<Col md={4}>


						{/* <!-- Overview section start from here --> */}
						{(insuranceStatus == '0') &&
							<Card
								className="img-box Garantiasbox"
								style={{ background: "#CBDFEE" }}
							>
								<Card.Body className="position-relative cursor-pe">
									<Link
										to={"/add_insurance"}
										className=" position-absolute start-0 top-0 w-100 h-100"
									></Link>
									<div className="d-flex align-items-center justify-content-between mb-1 mb-lg-3">
										<h6 className="card-title mb-0">
											<img src="assets/images/icons/Kenlo.svg" alt="icon" className="me-1" />
											{t("Dashboard.Wanttoincreaseyourincome1")}
										</h6>
										<img src="assets/images/icons/arrow-right.svg" alt="icon" />
									</div>
									<span>
										{/* {" "} */}
										{/* {t("Dashboard.Increaseyourrevenuebyupto")} */}
										{/* <span className="fw-bold">25%</span> */}
										{/* {t("Dashboard.Wanttoincreaseyourincome1")} */}
									</span>
								</Card.Body>
							</Card>
						}

						<InsurancePopups />

						{(insuranceStatus != '0') &&
							<Card className={
								(insuranceStatus == '1' || insuranceStatus == '2') ? 'bg-warning-10 img-box' :
									insuranceStatus == "3" || insuranceStatus == "4" ? 'bg-warning-40 img-box' :
										insuranceStatus == "5" ? 'bg-success-40 img-box' : 'bg-warning-10'
							} >
								<Card.Body className="position-relative cursor-pe">
									<div className="d-flex align-items-center justify-content-between mb-1" onClick={(e) => openKenloPartLargepopup(e, insuranceStatus)}  >
										<h4 className="card-title mb-0">
											{insuranceStatus == "1" &&
												<img src="assets/images/icons/Frame14428.svg" alt="icon" className="me-1" />
											}
											{insuranceStatus == "2" &&
												<img src="assets/images/icons/Frame16079.svg" alt="icon" className="me-1" />
											}
											{insuranceStatus == "3" &&
												<img src="assets/images/icons/Frame14429.svg" alt="icon" className="me-1" />
											}
											{insuranceStatus == "4" &&
												<img src="assets/images/icons/ErrorCircle.svg" alt="icon" className="me-1" />
											}
											{insuranceStatus == "5" &&
												<img src="assets/images/icons/CheckmarkCircle.svg" alt="icon" className="me-1" />
											}
											{t(`Dashboard.kenloInsurance${insuranceStatus}T1`)}
										</h4>
										<img src="assets/images/icons/arrow-right.svg" alt="icon" />
									</div>
									<span
										dangerouslySetInnerHTML={{
											__html: t(`Dashboard.kenloInsurance${insuranceStatus}T2`)
												.replace(/(Kenlo Seguros|receba comissões|Kenlo Insurance|earn commissions)/g, "<strong>$1</strong>")
										}}
									/>
								</Card.Body>
							</Card>
						}

						{/* <!-- /Overview section end --> */}
					</Col>
					{/* <!-- Pendencies start here--> */}
					<Col sm={12}>
						<Card>
							<Card.Header className="d-lg-flex justify-content-between">
								<span className="col">{t("Dashboard.Pendencies")}</span>
								<ul className="nav nav-pills mt-lg-0 mt-3">
									<li className="nav-item me-3 mb-lg-0 mb-2">
										<a
											onClick={() => searchContract("Contracts", null)}
											className="nav-link h-40 d-flex align-items-center active"
											aria-current="page"
											data-bs-toggle="tab"
											href="#tab1"
										>
											{t("Dashboard.Contracts")}
											<Badge className="rounded-circle ms-2">
												{dashboardData &&
													dashboardData.data &&
													dashboardData.data.length > 0 &&
													dashboardData.data[0].pendenciesResult.length
													? dashboardData.data[0].pendenciesResult[0]
														.totalContract
													: 0}
											</Badge>
										</a>
									</li>
									<li
										onClick={() => searchContract("Warranties", null)}
										className="nav-item me-3 mb-lg-0 mb-2"
									>
										<a className="nav-link h-40 d-flex align-items-center" data-bs-toggle="tab" href="#tab2">
											{t("Dashboard.Warranties")}
											<Badge className="rounded-circle ms-2">
												{dashboardData &&
													dashboardData.data &&
													dashboardData.data.length > 0 &&
													dashboardData.data[0].pendenciesResult.length
													? dashboardData.data[0].pendenciesResult[0]
														.totalWarranty
													: 0}
											</Badge>
										</a>
									</li>
									<li
										onClick={() => searchContract("Insurances", null)}
										className="nav-item me-3 mb-lg-0 mb-2"
									>
										<a className="nav-link h-40 d-flex align-items-center" data-bs-toggle="tab" href="#tab3">
											{t("Dashboard.Insurances")}
											<Badge className="rounded-circle ms-2">
												{dashboardData &&
													dashboardData.data &&
													dashboardData.data.length > 0 &&
													dashboardData.data[0].pendenciesResult.length
													? dashboardData.data[0].pendenciesResult[0]
														.totalInsurance
													: 0}
											</Badge>
										</a>
									</li>
									<li
										onClick={() => searchContract("Expenses", null)}
										className="nav-item me-3 mb-lg-0 mb-2"
									>
										<a className="nav-link h-40 d-flex align-items-center" data-bs-toggle="tab" href="#tab4">
											{t("Dashboard.Expenses")}
											<Badge className="rounded-circle ms-2">
												{dashboardData &&
													dashboardData.data &&
													dashboardData.data.length > 0 &&
													dashboardData.data[0].pendenciesResult.length
													? dashboardData.data[0].pendenciesResult[0]
														.totalExpense
													: 0}
											</Badge>
										</a>
									</li>
									<li onClick={() => searchContractInternal("Internal", null)}
										className="nav-item me-3 mb-lg-0 mb-2">
										<a className="nav-link h-40 d-flex align-items-center" data-bs-toggle="tab" href="#tab5">{t("Dashboard.Internal")}
											<Badge className="rounded-circle ms-2">
												{pendenciesData_internal &&
													pendenciesData_internal?.data &&
													pendenciesData_internal.data.length > 0
													? pendenciesData_internal.data.length > 3 ? 3 : pendenciesData_internal.totalRow
													: 0
												}
												{/* pendenciesData_internal.data.length  */}
											</Badge>
										</a>
									</li>
									<li
										className="nav-item me-3 mb-lg-0 mb-2">
										<a className="nav-link h-40 d-flex align-items-center" data-bs-toggle="tab" href="#tab6">{t("Links.Requests")}
											<Badge className="rounded-circle ms-2">
												{ticketData &&
													ticketData?.data &&
													ticketData.data.length > 0
													? ticketData.totalRow
													: 0
												}
												{/* pendenciesData_internal.data.length  */}
											</Badge>
										</a>
									</li>
								</ul>
							</Card.Header>
							<Card.Body className="pt-1">
								<div className="tab-content">
									<div className="tab-pane container-fluid active" id="tab1">
										{/* <!-- table start --> */}
										{pendenciesStatus == "loading" ? (
											<Loader />
										) : (
											<Table responsive className="mb-0">
												<thead>
													<tr className="justify-content-between">
														<th className="py-3">{t("Dashboard.Maturity")}</th>
														<th className="py-3">{t("Dashboard.Category")}</th>
														<th className="py-3">{t("Dashboard.Contract")}</th>
														<th className="py-3">{t("Dashboard.Renter")}</th>
														<th className="py-3">{t("Dashboard.Owner")}</th>
														<th className="py-3">{t("Dashboard.Rent-amfree")}</th>
														<th className="text-end py-3">
															{
																pendenciesData.totalRow && pendenciesData.totalRow > 3 ?
																	<Link to={"#"} onClick={() => seeContract("Contracts", "all")} >{t("Dashboard.Seeall")}</Link>
																	:
																	''
															}
														</th>
													</tr>
												</thead>
												<tbody>
													{pendenciesData.data &&
														pendenciesData.data.length > 0 ? (
														pendenciesData.data.map((item, index) => {
															// Parse the input date using moment
															const parsedDate = moment(item.created);
															// Format the parsed date using the desired format
															let formattedDate: any = parsedDate
															const MaturityDate = parsedDate.toDate(); // Convert moment object to Date
															const todayDate = new Date(dateBrazil);
															const yesterdayDate = new Date(todayDate);
															yesterdayDate.setDate(yesterdayDate.getDate() - 1);
															const tomorrowDate = new Date(todayDate);
															tomorrowDate.setDate(tomorrowDate.getDate() + 1);

															const beforedays = new Date(todayDate);
															beforedays.setDate(beforedays.getDate() + 2);

															// Format the date in the desired format
															formattedDate = parsedDate.format("DD/MMM/YYYY").toLowerCase();

															let dayAgo = "";
															let highlightClass = "";

															if (MaturityDate.toDateString() === todayDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Today");
																highlightClass = "bg-warning-10";
															} else if (MaturityDate.toDateString() === tomorrowDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Tomorrow");
																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === yesterdayDate.toDateString()) {
																dayAgo = formattedDate//formattedDate;

																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === beforedays.toDateString()) {
																dayAgo = formattedDate//formattedDate;
																highlightClass = "bg-success-40";
															} else {
																dayAgo = formattedDate//formattedDate;
																highlightClass = "bg-success-40";
															}
															// Using toString() method

															//let typeName = changeProprtyType?.filter(items => Number(items.id) == Number(item.type))
															return (

																<tr key={index}>
																	<td className="py-3 ps-0">
																		<Badge
																			className={`px-2 text-dark ${highlightClass}`}
																		>
																			{dayAgo}
																		</Badge>
																	</td>
																	<td className="text-dark-70 py-3">{item.propertyType} </td>
																	<td className="py-3">{item.id}/{item.propertyId}</td>
																	<td className="py-3">
																		{
																			item.tenantsName.length > 0 ? item.tenantsName.map((items, indx) => (
																				<>
																					<span key={indx}>
																						<img src="assets/images/icons/key_g.svg" />
																						<span className="ps-1 pe-2">
																							{items.ST_NOME_PES}
																						</span>
																					</span>
																				</>
																			))
																				: ''
																		}
																	</td>
																	<td className="py-3">
																		{
																			item.ownerName.length > 0 ? item.ownerName.map((items, indx) => (
																				<>
																					<span key={indx}>
																						&nbsp;
																						<img src="assets/images/icons/user_g.svg" />
																						<span className="ps-1">
																							{items.ST_NOME_PES}
																						</span>
																					</span>
																				</>
																			))
																				: ''
																		}
																	</td>
																	<td className="py-3">
																		<span className="d-block text-nowrap">R$ {item.rent_amount ? converter(item.rent_amount) : 0} - R${" "}
																			{item.admin_rate ? converter(item.admin_rate) : 0}</span>
																	</td>
																	<td className="text-end py-3">
																		<Link to={"/contract_details/" + item.id}>
																			<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																		</Link>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td className="py-3" colSpan={7}>
																<span>{t("formLabel.noRecordsFounds")}</span>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										)}
										{/* <!-- table end --> */}
									</div>
									<div className="tab-pane container-fluid fade" id="tab2">
										{/* <!-- table start --> */}
										{pendenciesStatus == "loading" ? (
											<Loader />
										) : (
											<Table responsive className="mb-0">
												<thead>
													<tr className="justify-content-between">
														<th className="py-3">{t("Dashboard.Maturity")}</th>
														<th className="py-3">{t("Dashboard.Category")}</th>
														<th className="py-3">{t("Dashboard.Contract")}</th>
														<th className="py-3">{t("Dashboard.Tenant")}</th>
														<th className="py-3">{t("Dashboard.Owner")}</th>
														<th className="py-3">{t("Dashboard.Rent-amfree")}</th>
														<th className="text-end py-3">
															{
																pendenciesData.totalRow && pendenciesData.totalRow > 3 ?
																	<Link to={"#"} onClick={() => searchContract("Warranties", "all")} >{t("Dashboard.Seeall")}</Link>
																	:
																	''
															}
														</th>
													</tr>
												</thead>
												<tbody>
													{pendenciesData.data &&
														pendenciesData.data.length > 0 ? (
														pendenciesData.data.map((item, index) => {
															const parsedDate = moment(item.created);
															// Format the parsed date using the desired format
															let formattedDate: any = parsedDate
															const MaturityDate = parsedDate.toDate(); // Convert moment object to Date
															const todayDate = new Date(dateBrazil);
															const yesterdayDate = new Date(todayDate);
															yesterdayDate.setDate(yesterdayDate.getDate() - 1);
															const tomorrowDate = new Date(todayDate);
															tomorrowDate.setDate(tomorrowDate.getDate() + 1);

															const beforedays = new Date(todayDate);
															beforedays.setDate(beforedays.getDate() + 2);

															// Format the date in the desired format
															formattedDate = parsedDate.format("DD/MMM/YYYY").toLowerCase();

															let dayAgo = "";
															let highlightClass = "";

															if (MaturityDate.toDateString() === todayDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Today");
																highlightClass = "bg-warning-10";
															} else if (MaturityDate.toDateString() === tomorrowDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Tomorrow");
																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === yesterdayDate.toDateString()) {
																dayAgo = formattedDate;//formattedDate;
																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === beforedays.toDateString()) {
																dayAgo = formattedDate;//formattedDate;
																highlightClass = "bg-success-40";
															} else {
																dayAgo = formattedDate;//formattedDate;
																highlightClass = "bg-success-40";
															}
															//let typeName = changeProprtyType?.filter(items => Number(items.id) == Number(item.type))
															return (
																<tr key={index}>
																	<td className="py-3 ps-0">
																		<span
																			className={`badge text-dark ${highlightClass}`}
																		>
																			{dayAgo}
																		</span>
																	</td>
																	<td className="text-dark-70 py-3">{item.propertyType} </td>                                  <td className="py-3">
																		{item.id}/{item.propertyId}
																	</td>
																	<td className="py-3">
																		{
																			item.tenantsName.length > 0 ? item.tenantsName.map((items, indx) => (
																				<>
																					<span key={indx}>
																						<img src="assets/images/icons/key_g.svg" />
																						<span className="ps-1 pe-2">
																							{items.ST_NOME_PES}
																						</span>
																					</span>
																				</>
																			))
																				: ''
																		}
																	</td>
																	<td className="py-3">
																		{
																			item.ownerName.length > 0 ? item.ownerName.map((items, indx) => (
																				<>
																					<span key={indx}>
																						&nbsp;
																						<img src="assets/images/icons/user_g.svg" />
																						<span className="ps-1 pe-2">
																							{items.ST_NOME_PES}
																						</span>
																					</span>
																				</>
																			))
																				: ''
																		}
																	</td>
																	<td className="py-3">
																		<span className="d-block text-nowrap">R$ {item.rent_amount ? converter(item.rent_amount) : 0} - R${" "}
																			{item.admin_rate ? converter(item.admin_rate) : 0}</span>
																	</td>
																	<td className="text-end py-3">
																		<Link to={"/contract_details/" + item.id}>
																			<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																		</Link>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td className="py-3" colSpan={7}>
																<span>{t("formLabel.noRecordsFounds")}</span>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										)}
										{/*  <!-- table end --> */}
									</div>
									<div className="tab-pane container-fluid fade" id="tab3">
										{/*  <!-- table start --> */}
										{pendenciesStatus == "loading" ? (
											<Loader />
										) : (
											<Table responsive className="mb-0">
												<thead>
													<tr className="justify-content-between">
														<th className="py-3">{t("Dashboard.Maturity")}</th>
														<th className="py-3">{t("Dashboard.Category")}</th>
														<th className="py-3">{t("Dashboard.Contract")}</th>
														<th className="py-3">{t("Dashboard.Renter")}</th>
														<th className="py-3">{t("Dashboard.Owner")}</th>
														<th className="py-3">{t("Dashboard.Rent-amfree")}</th>
														<th className="text-end py-3">
															{
																pendenciesData.totalRow && pendenciesData.totalRow > 3 ?
																	<Link to={"#"} onClick={() => searchContract("Insurances", "all")} >{t("Dashboard.Seeall")}</Link>
																	:
																	''
															}
														</th>
													</tr>
												</thead>
												<tbody>
													{pendenciesData.data &&
														pendenciesData.data.length > 0 ? (
														pendenciesData.data.map((item, index) => {
															const parsedDate = moment(item.created);
															// Format the parsed date using the desired format
															let formattedDate: any = parsedDate
															const MaturityDate = parsedDate.toDate(); // Convert moment object to Date
															const todayDate = new Date(dateBrazil);
															const yesterdayDate = new Date(todayDate);
															yesterdayDate.setDate(yesterdayDate.getDate() - 1);
															const tomorrowDate = new Date(todayDate);
															tomorrowDate.setDate(tomorrowDate.getDate() + 1);

															const beforedays = new Date(todayDate);
															beforedays.setDate(beforedays.getDate() + 2);

															// Format the date in the desired format
															formattedDate = parsedDate.format("DD/MMM/YYYY").toLowerCase()
															let dayAgo = "";
															let highlightClass = "";
															if (MaturityDate.toDateString() === todayDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Today");
																highlightClass = "bg-warning-10";
															} else if (MaturityDate.toDateString() === tomorrowDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Tomorrow");
																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === yesterdayDate.toDateString()) {
																dayAgo = formattedDate;//formattedDate;
																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === beforedays.toDateString()) {
																dayAgo = formattedDate;//formattedDate;
																highlightClass = "bg-success-40";
															} else {
																dayAgo = formattedDate;//formattedDate;
																highlightClass = "bg-success-40";
															}
															//let typeName = changeProprtyType?.filter(items => Number(items.id) == Number(item.type))
															return (
																<tr key={index}>
																	<td className="py-3">
																		<span
																			className={`badge text-dark ${highlightClass}`}
																		>
																			{dayAgo}
																		</span>
																	</td>
																	<td className="text-dark-70 py-3">{item.propertyType}  </td>
																	<td className="py-3">
																		{item.id}/{item.propertyId}
																	</td>
																	<td className="py-3">
																		{
																			item.tenantsName.length > 0 ? item.tenantsName.map((items, indx) => (
																				<>
																					<span key={indx}>
																						<img src="assets/images/icons/key_g.svg" />
																						<span className="ps-1 pe-2">
																							{items.ST_NOME_PES}
																						</span>
																					</span>
																				</>
																			))
																				: ''
																		}
																	</td>
																	<td className="py-3">
																		{
																			item.ownerName.length > 0 ? item.ownerName.map((items, indx) => (
																				<>
																					<span key={indx}>
																						&nbsp;
																						<img src="assets/images/icons/user_g.svg" />
																						<span className="ps-1 pe-2">
																							{items.ST_NOME_PES}
																						</span>
																					</span>
																				</>
																			))
																				: ''
																		}
																	</td>
																	<td className="py-3">
																		<span className="d-block text-nowrap">R$ {item.rent_amount ? converter(item.rent_amount) : 0} - R${" "}
																			{item.admin_rate ? converter(item.admin_rate) : 0}</span>
																	</td>
																	<td className="text-end py-3">
																		<Link to={"/contract_details/" + item.id}>
																			<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																		</Link>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td className="py-3" colSpan={7}>
																<span>{t("formLabel.noRecordsFounds")}</span>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										)}
										{/*  <!-- table end --> */}
									</div>
									<div className="tab-pane container-fluid fade" id="tab4">
										{/* <!-- table start --> */}
										{pendenciesStatus == "loading" ? (
											<Loader />
										) : (
											<Table responsive className="mb-0">
												<thead>
													<tr className="justify-content-between">
														<th className="py-3">{t("Dashboard.Maturity")}</th>
														<th className="py-3">{t("Dashboard.Category")}</th>
														<th className="py-3">{t("Property")}</th>
														<th className="py-3">{t("Dashboard.Formofpayment")}</th>
														<th className="py-3">{t("Dashboard.Responsible")}</th>
														<th className="py-3">{t("Dashboard.amount")}</th>
														<th className="text-end py-3">
															{
																pendenciesData.totalRow && pendenciesData.totalRow > 3 ?
																	<Link to={"/toBePaid"} onClick={() => searchContract("Expense", "all")}>{t("Dashboard.Seeall")}</Link>
																	:
																	''
															}
														</th>
													</tr>
												</thead>
												<tbody>
													{pendenciesData.data &&
														pendenciesData.data.length > 0 ? (
														[...pendenciesData.data].reverse().map((item, index) => {

															const parsedDate = moment(item.created);
															// Format the parsed date using the desired format
															let formattedDate: any = parsedDate
															const MaturityDate = parsedDate.toDate(); // Convert moment object to Date
															const todayDate = new Date(dateBrazil);
															const yesterdayDate = new Date(todayDate);
															yesterdayDate.setDate(yesterdayDate.getDate() - 1);
															const tomorrowDate = new Date(todayDate);
															tomorrowDate.setDate(tomorrowDate.getDate() + 1);

															const beforedays = new Date(todayDate);
															beforedays.setDate(beforedays.getDate() + 2);

															// Format the date in the desired format
															formattedDate = parsedDate.format("DD/MMM/YYYY").toLowerCase();

															let dayAgo = "";
															let highlightClass = "";

															if (MaturityDate.toDateString() === todayDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Today");
																highlightClass = "bg-warning-10";
															} else if (MaturityDate.toDateString() === tomorrowDate.toDateString()) {
																dayAgo = t("ReceiveandReceived.Tomorrow");
																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === yesterdayDate.toDateString()) {
																dayAgo = formattedDate //formattedDate;
																highlightClass = "bg-success-40";
															} else if (MaturityDate.toDateString() === beforedays.toDateString()) {
																dayAgo = formattedDate //formattedDate;
																highlightClass = "bg-success-40";
															} else {
																dayAgo = formattedDate //formattedDate;
																highlightClass = "bg-success-40";
															}
															let typeName = changeProprtyType?.filter(items => Number(items.id) == Number(item.type))
															return (
																<tr key={index}>
																	<td className="py-3">
																		<span
																			className={`badge text-dark ${highlightClass}`}
																		>
																			{dayAgo}
																		</span>
																	</td>
																	<td className="text-dark-70 py-3">
																		{item.propertyType ? item.propertyType : "-"}
																	</td>
																	<td className="py-3">
																		{item.id ? item.id : item.indentifier}{item.propertyId ? "/" + item.propertyId : "-"}
																	</td>
																	<td className="py-3">{item.formPayment}</td>
																	<td className="py-3">
																		{
																			item.ownerName.length > 0 ? item.ownerName.map((items, indx) => (
																				<>
																					<span key={indx}>
																						<img src="assets/images/icons/key_g.svg" />
																						<span className="ps-1 pe-2">
																							{items.ST_NOME_PES}
																						</span>
																					</span>
																				</>
																			))
																				: ''
																		}
																	</td>
																	<td className="py-3">
																		<span className="d-block text-nowrap">R$ {item.rent_amount ? converter(item.rent_amount) : 0} - R${" "}
																			{item.admin_rate ? converter(item.admin_rate) : 0}</span>
																	</td>
																	<td className="text-end py-3">
																		{
																			item.contractId && item.tab != "Expenses" ?
																				<Link to={"/contract_details/" + item.contractId}>
																					<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																				</Link>
																				:
																				item.propertyId && item.tab != "Expenses" ?
																					<Link to={"/property_detail/" + item.propertyId}>
																						<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																					</Link>
																					:
																					item.ID_REPASSE_REP && item.tab == "Expenses" ?
																						<Link to={"#"} onClick={(e) => goToExpense(e, item.ID_REPASSE_REP)} >
																							<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																						</Link>
																						: ''

																		}
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td className="py-3" colSpan={7}>
																<span>{t("formLabel.noRecordsFounds")}</span>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										)}
										{/* <!-- table end --> */}
									</div>
									<div className="tab-pane container-fluid fade" id="tab5">
										{/* <!-- table start --> */}
										{pendenciesStatus == "loading" ? (
											<Loader />
										) : (
											<Table responsive className="mb-0">
												<thead>
													<tr className="justify-content-between">
														<th className="py-3">{t("Dashboard.Maturity")}</th>
														<th className="py-3">{t("Dashboard.Category")}</th>
														<th className="py-3"></th>
														<th className="py-3"></th>
														<th className="py-3">{t("Dashboard.Responsible")}</th>
														<th className="py-3">{t("Dashboard.amount")}</th>
														<th className="text-end py-3">
															{
																pendenciesData_internal.data && pendenciesData_internal.data.length > 3 ?
																	<Link to={"/non_payment"}>{t("Dashboard.Seeall")}</Link>
																	:
																	''
															}
														</th>
													</tr>
												</thead>
												<tbody>
													{pendenciesData_internal.data &&
														pendenciesData_internal.data.length > 0 ? (
														pendenciesData_internal.data.map((item, index) => {
															if (index < 3) {
																const parsedDate = moment(item.DT_VENCIMENTO_RECB);
																// Format the parsed date using the desired format
																let formattedDate: any = parsedDate
																const MaturityDate = parsedDate.toDate(); // Convert moment object to Date
																const todayDate = new Date(dateBrazil);
																const yesterdayDate = new Date(todayDate);
																yesterdayDate.setDate(yesterdayDate.getDate() - 1);
																const tomorrowDate = new Date(todayDate);
																tomorrowDate.setDate(tomorrowDate.getDate() + 1);

																const beforedays = new Date(todayDate);
																beforedays.setDate(beforedays.getDate() + 2);

																// Format the date in the desired format
																formattedDate = parsedDate.format("DD/MMM/YYYY").toLowerCase();

																let dayAgo: any = "";
																let highlightClass = "";

																if (MaturityDate.toDateString() === todayDate.toDateString()) {
																	dayAgo = t("ReceiveandReceived.Today");
																	highlightClass = "bg-warning-10";
																} else if (MaturityDate.toDateString() === tomorrowDate.toDateString()) {
																	dayAgo = t("ReceiveandReceived.Tomorrow");
																	highlightClass = "bg-success-40";
																} else if (MaturityDate.toDateString() === yesterdayDate.toDateString()) {
																	dayAgo = formattedDate //formattedDate;
																	highlightClass = "bg-success-40";
																} else if (MaturityDate.toDateString() === beforedays.toDateString()) {
																	dayAgo = formattedDate //formattedDate;
																	highlightClass = "bg-success-40";
																} else {
																	dayAgo = formattedDate //formattedDate;
																	highlightClass = "bg-success-40";
																}
																//let typeName = changeProprtyType?.filter(items => Number(items.id) == Number(item.type))

																return (
																	<tr key={index}>
																		<td className="py-3">
																			<span
																				className={`badge text-dark ${highlightClass}`}
																			>
																				{dayAgo}
																			</span>
																		</td>
																		<td className="text-dark-70 py-3">
																			{t("Reports.Nonpayment")}
																		</td>
																		<td className="py-3"></td>
																		<td className="py-3"></td>
																		<td className="py-3">
																			<span>
																				<img src="assets/images/icons/key_g.svg" />
																				<span className="ps-1 pe-2">
																					{item.ST_NOMEREF_SAC}
																				</span>
																			</span>
																		</td>
																		<td className="py-3">
																			<span className="d-block text-nowrap">R$ {item.vl_emitido_recb}</span>
																		</td>
																		<td className="text-end py-3">
																			{/* {item.contractId ?
																			<Link to={"/contract_details/" + item.contractId}>
																				<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																			</Link>
																			:
																			<Link to={"/property_detail/" + item.propertyId}>
																				<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																			</Link>
																		} */}
																		</td>
																	</tr>
																);
															}
														})
													) : (
														<tr>
															<td className="py-3" colSpan={7}>
																<span>{t("formLabel.noRecordsFounds")}</span>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										)}
										{/* <!-- table end --> */}
									</div>
									<div className="tab-pane container-fluid fade" id="tab6">
										<div className="table-responsive">
											{ticketStatus == "loading" ? (
												<Loader />
											) : (
												<Table className="table table-custom table-custom-bordered mb-0">
													<thead>
														<tr>
															<th className="pb-2">{t("Requester")}</th>
															<th className="pb-2">{t("Dashboard.Category2")}</th>
															<th className="pb-2">{t("formLabel.responsible")}</th>
															<th className="pb-2">{t("Priority")}</th>
															<th className="pb-2">{t("ResolveBy")}</th>
															<th className="pb-2">{t("dropdownItem.status")}</th>
															<th>
																{
																	ticketData.data && ticketData.totalRow > 3 ?
																		<Link
																			to={`${config.REPORTS_SERVER_URL}/requests?start=0&end=1000&requestType=tickets&main=dashboard`}
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			{t("Dashboard.Seeall")}
																		</Link>


																		: ''
																}
															</th>
														</tr>
													</thead>
													<tbody>
														{
															ticketData.data && ticketData.data.length ? ticketData.data.map((item, index) => (
																<tr>
																	<td>
																		{
																			item.requester_type === 0 ?
																				<span className="d-block  fs-12">{t("Dashboard.Tenant")}</span>
																				: item.requester_type === 6 ?
																					<span className="d-block  fs-12">{t("Dashboard.Owner")}</span>
																					:
																					<span className="d-block  fs-12">{t("terminatelist.Others")}</span>
																		}
																		<span className="d-block text-dark-70">{item.RequesterName}</span>
																	</td>
																	<td className="text-dark-70">
																		{
																			item.request_identifier == "tickets" ?
																				<span className="d-block text-dark-70">{item.department_name}</span>
																				:
																				<span className="d-block text-dark-70">{item.category_name}</span>

																		}
																	</td>
																	<td>
																		<span className="d-block text-dark-70">{item.other_user}</span>
																	</td>
																	<td>
																		{
																			item.request_priority === 3 ?
																				<Badge bg="primary-20" className="text-dark fw-medium">{t("high")}</Badge>
																				: item.request_priority === 2 ?
																					<Badge bg="warning-5" className="text-dark fw-medium">{t("medium")}</Badge>
																					:
																					<Badge bg="warning-5" className="light" style={{ backgroundColor: '#DFF5E5', color: '#2E7D32' }}>{t("low")}</Badge>
																		}
																	</td>
																	<td>
																		{item.end_date}
																	</td>
																	<td>
																		{item.status === 0 ?
																			<Badge bg="light" className="text-dark fw-medium">{t("NewRequest")}</Badge>
																			:
																			item.status === 1 ?
																				<Badge bg="warning-5" className="text-dark fw-medium">{t("Delayed")}</Badge>
																				: item.status === 2 ?
																					<Badge bg="light" className="text-dark fw-medium">{t("Pending")}</Badge>
																					: item.status === 3 ?
																						<Badge bg="light" className="text-dark fw-medium">{t("Inprogress")}</Badge>
																						: item.status === 4 ?
																							<Badge bg="light" className="text-dark fw-medium">{t("BudgetRegistered")}</Badge>
																							: item.status === 5 ?
																								<Badge bg="light" className="text-dark fw-medium">{t("BudgetForwarded")}</Badge>
																								: item.status === 6 ?
																									<Badge bg="primary-20" className="text-dark fw-medium">{t("BudgetExpired")}</Badge>
																									: item.status === 7 ?
																										<Badge bg="light" className="text-dark fw-medium">{t("BudgetApproved")}</Badge>
																										: item.status === 8 ?
																											<Badge bg="success-10" className="text-dark fw-medium">{t("Completed")}</Badge>
																											: item.status === 9 ?
																												<Badge bg="light" className="text-dark fw-medium">{t("Invalidated")}</Badge>
																												: item.status === 10 ?
																													<Badge bg="light" className="text-dark fw-medium">{t("ReopenRequest")}</Badge>
																													:
																													<Badge bg="light" className="text-dark fw-medium">{t("status")}</Badge>
																		}
																	</td>
																	<td>
																		{
																			item.request_identifier == "tickets" ? <Link to={`${config.REPORTS_SERVER_URL}/ticket-details/` + item.request_id}>
																				<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																			</Link> :
																				<Link to={`${config.REPORTS_SERVER_URL}/mainteinance-detail/` + item.request_id}>
																					<img src="assets/images/icons/arrow-right.svg" alt="icon" />
																				</Link>
																		}
																	</td>
																</tr>
															)) : (
																<tr>
																	<td className="py-3" colSpan={7}>
																		<span>{t("formLabel.noRecordsFounds")}</span>
																	</td>
																</tr>
															)}
													</tbody>
												</Table>
											)}
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
					{/* <!-- Pendencies end here--> */}
					{/* <!-- charges start here --> */}
					<Col lg={4}>
						<Card className="h-100">
							<Card.Body>
								<h4 className="mb-lg-4 mb-3">{t("Dashboard.Charges")}</h4>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block">{t("Dashboard.Overdue")}</span>
										<Badge className="badge text-dark bg-warning-40 px-2 ms-1">
											{
												dashChargeData.length ?
													dashChargeData[0].overDueCount
													:
													0
											}
										</Badge>
									</span>
									<div className="d-flex align-items center justify-content-end">
										<span className="fw-bold me-1">
											R${" "}
											{
												dashChargeData.length ?
													dashChargeData[0].overDueCharge
													:
													0
											}
										</span>
										<Link to={"/receivable_charges"} onClick={(e) => handleCharge(e, "overdue")}>
											<span className="icon h-20 w-20px"><img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" /></span>
										</Link>
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block">{t("Dashboard.Duesoon")}</span>
										<Badge className="badge text-dark bg-warning-10 px-2 ms-1">
											{
												dashChargeData.length ?
													dashChargeData[0].dueSoonCount
													:
													0
											}
										</Badge>
									</span>
									<div className="d-flex align-items center justify-content-end">
										<span className="fw-bold me-1">
											R${" "}
											{
												dashChargeData.length ?
													dashChargeData[0].dueSoonCharge
													:
													0
											}
										</span>
										<Link to={"/receivable_charges"} onClick={(e) => handleCharge(e, "dueToday")}>
											<span className="icon h-20 w-20px"><img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" /></span>
										</Link>
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between">
									<span>
										<span className="d-inline-block">{t("Dashboard.Received")}</span>
										<Badge className="badge text-dark bg-success-40 px-2 ms-1">
											{
												dashChargeData.length ?
													dashChargeData[0].receivedCount
													:
													0
											}
										</Badge>
									</span>
									<div className="d-flex align-items center justify-content-end">
										<span className="fw-bold me-1">
											R${" "}
											{
												dashChargeData.length ?
													dashChargeData[0].receivedCharge
													:
													0
											}
										</span>
										<Link to={"/received"} onClick={(e) => handleCharge(e, "received")}>
											<span className="icon h-20 w-20px"><img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" /></span>
										</Link>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
					{/* <!-- charges end here --> */}
					<Col lg={8}>
						<Card>
							<Card.Body>
								<div className="d-flex justify-content-between mb-lg-4 mb-3">
									<h4 className="mb-0">{t("Dashboard.Nextmaturities")}</h4>
									<ul className="nav nav-pills nav-pills-border border border-light rounded-pill">
										<li className="nav-item">
											<a
												className="nav-link active"
												aria-current="page"
												data-bs-toggle="tab"
												href="#tab6"
											>
												{t("Dashboard.Current")}
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												data-bs-toggle="tab"
												href="#tab7"
											>
												3 {t("Dashboard.months")}
											</a>
										</li>
									</ul>
								</div>
								<div className="tab-content">
									<div className="tab-pane active" id="tab6">
										<Row className="g-lg-4 g-3">
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/currentContract"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].pendenciesResult.length
															? dashboardData.data[0].pendenciesResult[0]
																.totalContract
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Contracts")}
													</span>
												</div>
											</Col>
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/currentWarranty"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].pendenciesResult.length
															? dashboardData.data[0].pendenciesResult[0]
																.totalWarranty
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Warranties")}
													</span>
												</div>
											</Col>
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/currentInsurance"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].pendenciesResult.length
															? dashboardData.data[0].pendenciesResult[0]
																.totalInsurance
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Insurances")}
													</span>
												</div>
											</Col>
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/currentKenloInsurance"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].pendenciesResult.length
															? dashboardData.data[0].pendenciesResult[0]
																.totalKenloInsurance
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Kenlo")} {t("Dashboard.Insurances")}
													</span>
												</div>
											</Col>
										</Row>
									</div>
									<div className="tab-pane fade" id="tab7">
										<Row className="g-lg-4 g-3">
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/nextContract"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].maturitiesResult.length
															? dashboardData.data[0].maturitiesResult[0]
																.totalContract
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Contracts")}{" "}
													</span>
												</div>
											</Col>
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/nextWarranty"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].maturitiesResult.length
															? dashboardData.data[0].maturitiesResult[0]
																.totalWarranty
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Warranties")}
													</span>
												</div>
											</Col>
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/nextInsurance"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].maturitiesResult.length
															? dashboardData.data[0].maturitiesResult[0]
																.totalInsurance
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Insurances")}
													</span>
												</div>
											</Col>
											<Col md={3} sm={6}>
												<div className="border rounded-4 px-lg-4 py-lg-3  p-3 position-relative">
													<Link
														to={"/contractsSearch/nextKenloInsurance"}
														className=" position-absolute start-0 top-0 w-100 h-100"
													></Link>
													<span className="d-block text-dark h3 lh-base mb-0">
														{dashboardData.data &&
															dashboardData.data.length > 0 &&
															dashboardData.data[0].maturitiesResult.length
															? dashboardData.data[0].maturitiesResult[0]
																.totalKenloInsurance
															: 0}
													</span>
													<span className="text-secondary d-block">
														{t("Dashboard.Kenlo")} {t("Dashboard.Insurances")}
													</span>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={8}>
						<Card className="h-100">
							<Card.Body className="d-flex flex-column h-100">
								<div className="d-flex justify-content-between mb-3 mb-lg-4">
									<h4 className="mb-0">{t("Dashboard.Income")}</h4>
									<Link to={"/received"} className="h6 m-0 text-primary fw-bold">
										{t("Dashboard.Seeall")}
									</Link>
								</div>
								<Row className="gy-3 mb-3 mb-lg-4">
									<Col
										xl={3}
										sm={6}
										className="border-end border-light border-xs-none"
									>
										<span className="text-secondary d-block mt-2 mt-lg-3 mb-1">
											{t("Dashboard.Foreseen")}
										</span>
										<span className="d-block text-truncate text-dark h3 lh-base mb-lg-3 mb-2">
											R${" "}
											{dashIncomeData.data && dashIncomeData.data.length > 0
												? converter(dashIncomeData.data[0].foreseenIncome)
												: "0:00"}
										</span>
									</Col>
									<Col
										xl={3}
										sm={6}
										className="border-end border-light border-xs-none border-lg-none"
									>
										<span className="text-secondary d-block mt-2 mt-lg-3 mb-1">
											{t("Dashboard.Received")}
										</span>
										<span className="d-block text-truncate text-dark h3 lh-base mb-lg-3 mb-2">
											R${" "}
											{/*{totalCharge.totalReceivedCharge ? converter(totalCharge.totalReceivedCharge.toFixed(2)) : 0}*/}
											{dashIncomeData.data && dashIncomeData.data.length > 0
												? converter(dashIncomeData.data[0].receivedIncome)
												: "0:00"}
										</span>
									</Col>
									<Col className=" border-end border-light border-xs-none">
										<span className="text-secondary d-block mt-2 mt-lg-3 mb-1">
											{t("Dashboard.Averageadmfee")}
											<span className="text-dark">
												•{" "}
												{dashIncomeData.data && dashIncomeData.data.length > 0
													? converter(dashIncomeData.data[0].avgAdmFeeIncomePer)
													: "0:00"}
												%
											</span>
										</span>
										<span className="d-block text-truncate text-dark h3 lh-base mb-lg-3 mb-2">
											R${" "}
											{dashIncomeData.data && dashIncomeData.data.length > 0
												? converter(dashIncomeData.data[0].avgAdmFeeIncome)
												: "0:00"}
										</span>
									</Col>
									<Col xl={3} sm={6}>
										<span className="text-secondary d-block mt-2 mt-lg-3 mb-1">
											{t("Dashboard.Totaladmfee")}{" "}
											<span className="text-dark">
												•{" "}
												{dashIncomeData.data && dashIncomeData.data.length > 0
													? converter(dashIncomeData.data[0].ttlAdmFeeIncomePer)
													: "0:00"}
												%
											</span>
										</span>
										<span className="d-block text-truncate text-dark h3 lh-base mb-lg-3 mb-2">
											R${" "}
											{dashIncomeData.data && dashIncomeData.data.length > 0
												? converter(dashIncomeData.data[0].totalAdmFeeIncome)
												: "0:00"}
										</span>
									</Col>
								</Row>
								<div className="border rounded-4 pt-2 px-lg-4 py-lg-3 p-3 mt-auto">
									<h4 className="mb-3 pb-xl-3 pb-1">{t("Dashboard.Mainsources")}</h4>
									<Row className="g-lg-4 g-3 align-items-center justify-content-between">
										<Col sm={3}>
											<PieChart
												center={[50, 50]}
												data={mainsourcesValue}
												lengthAngle={360}
												lineWidth={50}
												paddingAngle={0}
												radius={50}
												startAngle={0}
												viewBoxSize={[100, 100]}
											/>
										</Col>
										<Col sm={8}>
											<Row className="g-lg-4 g-3 mb-xl-6 mb-lg-5 mb-4">
												<Col sm={6} className="d-flex ">
													<img src="assets/images/icons/administration-fee.svg" alt="icon" className="me-1 wh-xxs mt-1" />
													<div>
														<span className="d-block pb-1 text-dark">
															{t("Dashboard.Administrationfee")}
														</span>
														<span className="h3 mb-0 lh-1 d-xl-flex text-truncate">
															R${" "}
															{dashMainData.data && dashMainData.data.length > 0
																? converter(dashMainData.data[0].admFeeMainSource)
																: 0}
															<span className="badge text-dark bg-success-40 ms-1"> + {mainsourcesValue[3].value.toFixed()} %</span>
														</span>
													</div>
												</Col>
												<Col sm={6} className="d-flex">
													<img src="assets/images/icons/sales-commission.svg" alt="icon" className="me-1 wh-xxs mt-1" />
													<div>
														<span className="d-block pb-1 text-secondary">
															{t("Dashboard.Salescommission")}
														</span>
														<span className="h3 mb-0 lh-1 d-xl-flex text-truncate">
															R${" "}
															{dashMainData.data && dashMainData.data.length > 0
																? converter(dashMainData.data[0].salesCommMainSource)
																: 0}
															<span className="badge text-dark bg-success-40 ms-1">+ {mainsourcesValue[2].value.toFixed()} %</span>
														</span>
													</div>
												</Col>
											</Row>
											<Row className="g-lg-4 g-3">
												<Col sm={6} className="d-flex">
													<img src="assets/images/icons/insurance-commission.svg" alt="icon" className="me-1 wh-xxs mt-1" />
													<div>
														<span className="d-block pb-1  text-secondary">
															{t("Dashboard.Insurancecommission")}
														</span>
														<span className="h3 mb-0 lh-1 d-xl-flex text-truncate">
															R${" "}
															{dashMainData.data && dashMainData.data.length > 0
																? converter("0.00")
																: 0}
															{/*<span className="badge text-dark bg-warning-40 ms-1">+ 12%</span>*/}
														</span>
													</div>
												</Col>
												<Col sm={6} className="d-flex">
													<img src="assets/images/icons/agreement.svg" alt="icon" className="me-1 wh-xxs mt-1" />
													<div>
														<span className="d-block pb-1  text-secondary">
															{t("Dashboard.Agreement")}
														</span>
														<span className="h3 mb-0 lh-1 d-xl-flex text-truncate">
															R${" "}
															{dashMainData.data && dashMainData.data.length > 0
																? converter(dashMainData.data[0].agrMainSource)
																: 0}
															{/*<span className="badge text-dark bg-success-40 ms-1">+ 12%</span>*/}
														</span>
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={4}>
						<Card className="mb-3">
							<Card.Body>
								<h4 className="mb-lg-4 mb-3">{t("Dashboard.Transfers")}</h4>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block" >{t("Dashboard.Overdue")}</span>
										<Badge className="badge text-dark bg-warning-40 px-2 ms-1">{transfers[0]?.OVERDUE_COUNT > 0 ? transfers[0]?.OVERDUE_COUNT : "0"}</Badge>
									</span>
									<div>
										<span className="fw-bold">
											R$ {transfers[0]?.OVERDUE_COUNT > 0 ? converter(transfers[0]?.OVERDUE_AMOUNT) : '0.00'}
										</span>

										{
											transfers[0]?.OVERDUE_COUNT > 0 ?
												<Link to={"/new_transfers"} className="ms-3" onClick={(e) => TransferFilter(e, "overdue")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"#"} className="ms-3 d">
												</Link>
										}
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block" >{t("Dashboard.duetoday")}</span>
										<Badge bg="warning-10" className="text-dark px-2 ms-1">
											{transfers[0]?.PAYTOD_COUNT > 0 ? transfers[0]?.PAYTOD_COUNT : 0}
										</Badge>

									</span>
									<div>
										<span className="fw-bold">
											R$ {transfers[0]?.PAYTOD_COUNT > 0 ? converter(transfers[0]?.PAYTOD_AMOUNT) : '0.00'}
										</span>
										{
											transfers[0]?.PAYTOD_COUNT > 0 ?
												<Link to={"/new_transfers"} className="ms-3" onClick={(e) => TransferFilter(e, "dueToday")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"#"} className="ms-3 d">
												</Link>
										}
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block" >{t("Dashboard.duethisweek")}</span>
										<Badge bg="success-40" className="text-dark px-2 ms-1">
											{transfers[0]?.DUEWEEK_COUNT > 0 ? transfers[0]?.DUEWEEK_COUNT : 0}
										</Badge>
									</span>
									<div>
										<span className="fw-bold">
											R$ {transfers[0]?.DUEWEEK_COUNT > 0 ? converter(transfers[0]?.DUEWEEK_AMOUNT) : '0'}
										</span>
										{
											transfers[0]?.DUEWEEK_COUNT > 0 ?
												<Link to={"/new_transfers"} className="ms-3" onClick={(e) => TransferFilter(e, "thisWeek")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"#"} className="ms-3 d">
												</Link>
										}
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between">
									<span>{t("Dashboard.Next")} 3 {t("Dashboard.months")}
										<Badge bg="dark-50" className="text-dark px-2 ms-1">{transfers[0]?.NEXTMONTH_COUNT > 0 ? transfers[0]?.NEXTMONTH_COUNT : '0'}</Badge>
									</span>
									<div>
										<span className="fw-bold">R$  {transfers[0]?.NEXTMONTH_COUNT > 0 ? transfers[0]?.NEXTMONTH_AMOUNT : '0.00'}</span>
										{
											transfers[0]?.NEXTMONTH_COUNT > 0 ?
												<Link to={"/new_transfers"} className="ms-3" onClick={(e) => TransferFilter(e, "nextmonths")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"#"} className="ms-3 d">
												</Link>
										}
									</div>
								</div>
							</Card.Body>
						</Card>
						<Card>
							<Card.Body>
								<h4 className="mb-lg-4 mb-3">{t("Dashboard.Expenses")}</h4>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block">{t("Dashboard.Overdue")}</span>
										<Badge bg="warning-40" className="text-dark px-2 ms-1">
											{expenses[0]?.OVERDUE_COUNT > 0 && expenses[0]?.OVERDUE_COUNT > 0 ? expenses[0]?.OVERDUE_COUNT : '0'}
										</Badge>
									</span>
									<div>
										<span className="fw-bold">
											R$ {expenses[0]?.OVERDUE_COUNT > 0 && expenses[0]?.OVERDUE_AMOUNT ? converter(expenses[0]?.OVERDUE_AMOUNT) : '0.00'}
										</span>
										{
											expenses[0]?.OVERDUE_COUNT > 0 ?
												<Link to={"/receivable_charges"} className="ms-3" onClick={(e) => handleExpenseclick(e, "overdue")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"/receivable_charges"} className="ms-3">
												</Link>
										}
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block">{t("Dashboard.Payabletoday")}</span>
										{
											<Badge bg="warning-10" className="text-dark px-2 ms-1">
												{expenses[0]?.PAYTOD_COUNT > 0 ? expenses[0]?.PAYTOD_COUNT : 0}
											</Badge>
										}
									</span>
									<div>
										{
											<span className="fw-bold">
												R$ {expenses[0]?.PAYTOD_COUNT > 0 ? converter(expenses[0]?.PAYTOD_AMOUNT) : '0.00'}
											</span>
										}
										{
											expenses[0]?.PAYTOD_COUNT > 0 ?
												<Link to={"/receivable_charges"} className="ms-3" onClick={(e) => handleExpenseclick(e, "dueToday")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"/receivable_charges"} className="ms-3">
												</Link>
										}
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between mb-2 mb-lg-3">
									<span>
										<span className="d-inline-block" >{t("Dashboard.duethisweek")}</span>
										<Badge bg="success-40" className="text-dark px-2 ms-1">
											{expenses[0]?.DUEWEEK_COUNT > 0 ? expenses[0]?.DUEWEEK_COUNT : '0'}
										</Badge>
									</span>
									<div>

										<span className="fw-bold">
											R$ {expenses[0]?.DUEWEEK_COUNT > 0 ? converter(expenses[0]?.DUEWEEK_AMOUNT) : '0.00'}
										</span>
										{
											expenses[0]?.DUEWEEK_COUNT > 0 ?
												<Link to={"/received"} className="ms-3" onClick={(e) => handleExpenseclick(e, "thisWeek")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"/receivable_charges"} className="ms-3">
												</Link>
										}
									</div>
								</div>
								<div className="d-flex w-100 justify-content-between">
									<span>{t("Dashboard.Next")} 3 {t("Dashboard.months")} {expenses[0]?.NEXTMONTH_COUNT > 0 ? <Badge bg="dark-50" className="text-dark px-2 ms-1">{expenses[0]?.NEXTMONTH_COUNT}</Badge> : ""}</span>
									<div>
										<span className="fw-bold">R$ {expenses[0]?.NEXTMONTH_COUNT > 0 && expenses[0]?.NEXTMONTH_AMOUNT ? converter(expenses[0]?.NEXTMONTH_AMOUNT) : '0.00'}</span>
										{
											expenses[0]?.NEXTMONTH_COUNT > 0 ?
												<Link to={"/receivable_charges"} className="ms-3" onClick={(e) => handleExpenseclick(e, "3months")}>
													<img src="assets/images/icons/arrow-right.svg" className="h-12" alt="icon" />
												</Link>
												:
												<Link to={"/receivable_charges"} className="ms-3">
												</Link>
										}
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Dashboard;