import { Accordion, FloatingLabel, Modal, Form, Button, InputGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { HandleSubmit } from '../Contracts/Common/actionPerform';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { ChangeDate } from '../../../redux/slice/toBePaidSlice';
import { useTranslation } from 'react-i18next';
import { encrypt_Decrypt } from '../../constants/utils';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  maturity: yup.string().required('Maturity is required'),
});

const ChangeMaturity = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3010) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const { showchangeExpiration, changeExpirationClose, changeExpirationModalShow, checkboxClicked } = props;

  const onSubmit = (data) => {
    dispatch(ChangeDate(data));
  }

  
  return (
    <Modal show={showchangeExpiration} onHide={changeExpirationClose}>
      {checkboxClicked ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("TransferPage.Changematurity")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId="maturity" label={t("maturity")}>
              <Form.Control type="date" placeholder={t("maturity")} {...register("maturity")} />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={changeExpirationClose}>
            {t("button.close")}
            </Button>
            <Button variant="primary" type="submit">
            {t("button.toSave")}
            </Button>
          </Modal.Footer>
        </Form>
      ) : (
        <div>
          <p className='p-4'>{t("paidTobepaid.Checkboxisnotchecked")}!</p>
          {/* You can add additional elements or styling here */}
        </div>
      )}
    </Modal>
  )
}

export default ChangeMaturity;