import { Accordion,Table, ButtonGroup, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Card, Alert, ProgressBar, Tooltip, OverlayTrigger, Collapse } from 'react-bootstrap';
import { getSettle,settleClose,setSettleId,settleOpen } from "../../../redux/slice/contractDataSlice";
import React,{ useEffect,useState }   from 'react';
import { Liquidate,Edit_new_expense } from '../../../redux/slice/toBePaidSlice';
import { useSelector,useDispatch }    from 'react-redux';
import { AppDispatch,RootState }      from '../../../redux/store'; 
import { Outlet,Link,useNavigate }    from 'react-router-dom';
import { openErrorbox,numericOnly }   from '../Contracts/Common/actionPerform';
import { settleForm }       from '../../Interface';
import customerService      from '../../../services/customerService';
import Loader               from '../../Profile/Company/Loader';
import Swal                 from 'sweetalert2';
import { useTranslation }   from 'react-i18next';
import { transferToBePaid } from '../../../redux/slice/transferDataSlice';
import { useForm }          from "react-hook-form";
import { yupResolver }      from '@hookform/resolvers/yup';
import * as yup from 'yup';

const Settle = (props,settleForm) => {
    const { settleData,newSettleShow,settleId }     = useSelector((state: RootState) => state.contractData)
    const { accountData,accountStatus,billingData } = useSelector((state: RootState) => state.expenditureData)
    const [t] = useTranslation();
    const Schema = yup.object().shape({
        paymentMethod  : yup.string().required(t("requiredMmessage.fieldRequired")),
        saleOf         : yup.string().required(t("requiredMmessage.fieldRequired")),
        value          : yup.string().required(t("requiredMmessage.fieldRequired")),
        //checkNumber    : yup.string().required(t("requiredMmessage.fieldRequired")),
        bankAccount    : yup.string().required(t("requiredMmessage.fieldRequired")),
    })
    const dispatch = useDispatch<AppDispatch>();
    const { register, handleSubmit,getValues, setValue,reset, formState: { errors } } = useForm<settleForm>({
        resolver: yupResolver(Schema)
    });

    const [files, setFiles] = useState<{ name: string; data: string }[]>([]);
    useEffect(() => {
        if(settleId){
            getSettleData(settleId);
            setValue('discount','0');
        }        
    }, [dispatch,settleId])

    let [ paid,setPaid ] = useState('0');
    let [ original,setOriginal ] = useState(0);
    let [ discount,setDiscount ] = useState('0.00');
    let [ addition,setAddition ] = useState('0.00');
    
    let [ gettingFiles,setgettingFiles ] = useState('');
    let [ allFiles,setAllFiles ] = useState<any>('');

     const settleClosed = () => {
        dispatch(setSettleId(""));
        reset();
        setPaid('0');
        setOriginal(0);
        setDiscount('0.00');
        setValue('discount','0');
        setValue('addition','0');
        dispatch(settleClose(false));
        setAllFiles('');
        setgettingFiles('');          
    }

    const getSettleData = async(settleId) =>{
        const fields: string[] = ['id','contractId','value','paid','saleOf','bankAccount','paymentMethod'];
        let data = await dispatch(getSettle(settleId));
        if (data.payload.type == "success" || data.payload.type == "Success") {
            if (typeof data.payload.data !== "undefined") {
                dispatch(settleOpen(true));
                let searchData = { "ID_CONTRATO_CON":data.payload.data[0]['contractId'],"id":data.payload.data[0]['id'],"id_etiqueta_eti":1,type:"settle"}
                getSettleDocument(searchData);
                setPaid(data.payload.data[0]['value']);
                setOriginal(data.payload.data[0]['value']);
                fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));
            }
        }
    }

    const getSettleDocument = async(searchData)=>{
        setgettingFiles('loading');
        setAllFiles('');
        const response = await customerService.gettoBePaidDocuments(searchData); 
        if( response.data.resCode === 201 ){ 
            setAllFiles(response.data.data); 
            setgettingFiles('');  
        }   
        else {
            setgettingFiles('');  
        }     
    }
    const onSubmit = async(data: any) => {
        if(String(data["paid"]) === "0"){
            data["paid"] = "0"
        }

        if(String(data["value"]) === "0"){
            data["value"] = "0"
        }

        if(String(data["paymentMethod"]) === "0"){
            data["paymentMethod	"] = "0"
        }

        if(String(data["discount"]) === "0"){
            data["discount"] = "0"
        }
        const response = await customerService.insertSettle(data); 
        if(typeof response !== "undefined" && (response.data.resCode === 202 || response.data.resCode === 200)){
            Swal.fire({
                title : t("Sweetalert.Success"),
                text  : response.data.message,
                icon  : "success",
                confirmButtonText: "OK",
                timer : 2000
            });
            dispatch(transferToBePaid(props.state));
            settleClosed();
        }
        else {
            Swal.fire({
                title : t("Sweetalert.Error"),
                text  : response.data.message,
                icon  : "error",
                confirmButtonText: "OK",
                timer : 2000
            });
        }        
    }
    
    const settleCalculation = async(evt) => {
        let settleAmount = evt.target.value ? evt.target.value : 0;
        if(settleData.length>0 && settleAmount < parseFloat(settleData[0].value)){
            let paidAmount = parseFloat(settleData[0].value) - parseFloat(settleAmount);
            setPaid(settleAmount);
            setValue('paid',settleAmount);
            setValue('discount',paidAmount);
            setValue('addition','0');
            setDiscount(paidAmount.toFixed(2));            
            setAddition('0.00');
        }
        else if(settleData.length>0 && settleAmount > parseFloat(settleData[0].value)){
            let paidAmount = parseFloat(settleAmount) - parseFloat(settleData[0].value);
            setPaid(settleAmount);            
            setValue('paid',settleAmount);
            setValue('addition',paidAmount.toFixed(2));
            setValue('discount','0');
            setAddition(paidAmount.toFixed(2));            
            setDiscount('0.00');
        }
        else {
            setPaid(settleAmount);    
            setValue('paid',settleAmount);
            setValue('addition','0');
            setValue('discount','0');
            setDiscount('0.00');
            setAddition('0.00');
        }
    }

    const handleChange = async (e) => {
        let files = e.target.files;
        let formdata = new FormData();
        let selectedFile = files[0];
        if(selectedFile){
            formdata.append("id", getValues('id'));
            formdata.append("image", selectedFile,selectedFile?.name);
            formdata.append('contractId', getValues('contractId'));
            formdata.append('ID_ETIQUETA_ETI','1');
            let response = await customerService.uploadTobepaidDocuments(formdata);
            /*-------Check the api response--------*/
            if (typeof response !== "undefined" && (response.data.resCode === 202 || response.data.resCode === 200)) {
                let searchData = { "ID_CONTRATO_CON":getValues('contractId'),"id":getValues('id'),"id_etiqueta_eti":1,type:"settle"}
                getSettleDocument(searchData);
                Swal.fire({
                    title : t("Sweetalert.Success"),
                    text  : 'File uploaded successfully!',
                    icon  : "success",
                    confirmButtonText: "OK",
                    timer : 2000
                });
            }
            else {
                Swal.fire({
                    title : t("Sweetalert.Error"),
                    text  : t("Sweetalert.somethingWent"),
                    icon  : "error",
                    confirmButtonText: "OK",
                    timer : 2000
                });
            }           
        }
    }
    const handleImageDelete = async (e,item) => {
        let data = { 
            ID_CONTRATO_CON : getValues('contractId'),
            ID_ARQUIVO_ARQ  : item.ID_ARQUIVO_ARQ
        }
        await customerService.deleteTobepaidDocuments(data);
        let searchData = { "ID_CONTRATO_CON":getValues('contractId'),"id":getValues('id'),"id_etiqueta_eti":1,type:"settle"}
        getSettleDocument(searchData);
    }

    return (
        <Modal show={newSettleShow} onHide={settleClosed} id="toBePaidSettle">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("newTransfer.settle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="hidden" {...register("contractId")} />
                    <input type="hidden" {...register("id")} />
                    <input type="hidden" {...register("paid")} />
                    <input type="hidden" {...register("discount")} />
                    <input type="hidden" {...register("addition")} />
                    <FloatingLabel label={t("bankTransaction.Paymentmethod")} className="mb-3 mb-lg-4" >
                        <Form.Select aria-label={t("bankTransaction.Paymentmethod")} className={`${errors.paymentMethod?.message ? 'is-invalid' : ''}`} {...register("paymentMethod")}>
                            <option value="">{t("formLabel.select")}..</option>
                            {billingData.data && billingData.data.length > 0 ? billingData.data.map((item: any, index: number) => (
                                <option key={index} value={Number(item.id)}>
                                    {item.name}
                                </option>
                            ))
                                : ""
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.paymentMethod?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="sale-off" label={t("ReceiveandReceived.saleoff")} className="mb-3 mb-lg-4">
                        <Form.Control type="date" placeholder={t("ReceiveandReceived.saleoff")} className={`${errors.saleOf?.message ? 'is-invalid' : ''}`}   {...register("saleOf")} />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                    <FloatingLabel controlId="value" label={t("Dashboard.value")} className="mb-3 mb-lg-4">
                        <Form.Control type="text" placeholder={t("Dashboard.value")} className={`${errors.value?.message ? 'is-invalid' : ''}`}  {...register("value")} onKeyUp={(event) => { settleCalculation(event); }} />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                    <FloatingLabel controlId="authorization-date" label={t("ReceiveandReceived.Checknumber")} className="mb-3 mb-lg-4">
                        <Form.Control type="text" placeholder={t("ReceiveandReceived.Checknumber")} className={`${errors.checkNumber?.message ? 'is-invalid' : ''}`}  {...register("checkNumber")} />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">{errors.checkNumber?.message}</Form.Control.Feedback>
                    {/* <FloatingLabel controlId="attach-file" label="Attach file" className="mb-3 mb-lg-4">
                        <Form.Control type="file" placeholder="Attach file" />
                    </FloatingLabel> */}
                    {/*<Button variant="outline-primary" onClick={handleAddFileControl}>
                        Add File
                    </Button>
                    */}
                    {/*<input type="file" id='logoUploadfiledd' className="form-control p-4" onChange={handleChange} hidden />
                    <label htmlFor='logoUploadfiledd' title='Add File' className='fw-bold text-primary' id='imglabel' style={{ cursor: "pointer" }}>
                        Add File
                    </label>

                    {   
                        allFiles && allFiles.length > 0 ? allFiles.map((item, index) => (
                            <Col md={3} key={index}>
                                <div className='w-100 text-center'> 
                                    <Button variant="link" data-rowid={index} onClick={(e) =>
                                        handleImageDelete(e, item) } >
                                        <img className="h-14" src="./assets/images/icons/delete-light.svg" alt="Delete"/>
                                    </Button>
                                    <img src={item.ST_HASH_ARQ} alt="Filter" className="w-100"/>
                                    <small>{item.ST_NOME_ARQ}</small>
                                </div>
                            </Col>
                        ))
                        : ""
                    }
                    {
                        (gettingFiles == "loading") &&
                            <Col md={3}><Loader /></Col>
                    }*/}
                    <div className="border-2 border border-dashed position-relative text-center rounded-4 py-4 px-2 mb-lg-4 mb-3">
                    <Form.Control type="file" id='logoUploadfiledd' className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1" onChange={handleChange} hidden />
                        <label htmlFor='logoUploadfiledd' title='Add File' id='imglabel' className="cursor-pe d-block mb-0">
                            <h4 className='text-primary fw-bold mb-2 h4 d-block'><img src="assets/images/icons/upload-file-primary.svg" alt="Upload file" className="cursor-pe me-2" />{t("AddFile")}</h4>
                            <span className="mb-0 d-block fw-normal text-dark"><img src="assets/images/icons/info-dark.svg" alt="Info" className="h-16 cursor-pe me-1" />{t("SupportedFormats")}</span>
                        </label>
                    </div>
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 mt-3">
                        {   
                            allFiles && allFiles.length > 0 ? allFiles.map((item, index) => (
                                <Col md={6} lg={4} key={index}>
                                   <div className='w-100 text-center border rounded p-3 position-relative'> 
                                        <Button  variant="link" className='bg-primary position-absolute rounded-circle wh-xs-1 d-flex align-items-center justify-content-center end-0 mt-n3 me-n3 top-0 z-index-3 p-0' data-rowid={index} onClick={(e) =>
                                            handleImageDelete(e, item) } >
                                             <img className="h-10" src="./assets/images/icons/times-white.svg" alt="Delete"/>
                                        </Button>
                                        <img src={item.ST_HASH_ARQ} alt="Filter" style={{ height: "100px" }} className="w-100 object-fit-contain"/>
                                        <h6 className="fw-semibold fs-14 text-truncate mt-2 mb-0">{item.ST_NOME_ARQ}</h6>
                                    </div>
                                </Col>
                            ))
                            : ""
                        }
                        {
                            (gettingFiles == "loading") &&
                                <Col md={12}><Loader /></Col>
                        }
                    </Row>
                    <Accordion className="accordion-custom">
                        <Accordion.Item eventKey="0">
                           <Accordion.Header>{t("formLabel.moreFields")}</Accordion.Header>
                            <Accordion.Body>
                                  <FloatingLabel label={t("Links.bankAccounts")}>
                                    <Form.Select aria-label={t("Links.bankAccounts")} className={`${errors.bankAccount?.message ? 'is-invalid' : ''}`} {...register('bankAccount')}>
                                        <option value="">{t("formLabel.select")}..</option>
                                        { accountData.data && accountData.data.length > 0
                                                    ? accountData.data.map((item, index) => (
                                                        <option key={index} value={Number(item.id)}>
                                                            {item.accountRef}
                                                        </option>
                                                    ))
                                                    : ""}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.bankAccount?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                     <div className="h4">{t("Invoices.summary")}</div>                   <table className="table table-custom table-custom-bordered mb-0">
                        <thead className="p-0 m-0">
                            <tr>
                                <th className="p-0 m-0"></th>
                                <th className="p-0 m-0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="d-block">{t("OriginalValue")}</span>
                                    <span className="d-block">{t("Additions")}</span>
                                    <span className="d-block">{t("formReceipt.discount")}</span>
                                </td>
                                <td className="text-end">
                                    <span className="d-block">-{ original }</span>
                                    <span className="d-block">{ addition }</span>
                                    <span className="d-block">{ discount }</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="d-block">{t("Links.paid")} </span>                                </td>
                                <td className="text-end">
                                    <span className="d-block">-{ paid }</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={settleClosed}>
                    {t("button.close")}
                    </Button>
                    <Button variant="primary" type='submit' onClick={openErrorbox}>
                        
                    {t("newTransfer.settle")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default Settle;