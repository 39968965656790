import { FloatingLabel, Card, Table, ProgressBar, ButtonGroup, Modal, Accordion, InputGroup, Dropdown, Col, Row, Collapse, Form, Button, Alert } from 'react-bootstrap';
import { transferToBePaided, transferToSwitchOff, setCheckedId, transferContracts, setTransferId, setMaturityOpen, setFilterType, setTansferFilterOpen } from '../../../redux/slice/transferDataSlice';
import React, { useState, MouseEvent, useEffect } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Contracts/Common/Checkbox';
import Loader from '../../Profile/Company/Loader';
import Swal from 'sweetalert2';
import { getAllSuppliers, getOwner } from "../../../redux/slice/contractDataSlice";
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAccounts, getAllBillMethodListing } from "../../../redux/slice/expenditureDataSlice";
//import moment from "moment";
import moment from "moment-timezone";
import Pagination from "../../Pagination";
import '../../../App.css';
import { getBankList } from '../../../redux/slice/bankSlice';
import { converter, encrypt_Decrypt } from '../../constants/utils';
import i18n from '../../../i18n';
import FinancialExpenseService from '../Contracts/Common/FinancialExpenseService';
import { setFinancialExpOpen } from '../../../redux/slice/financialDataSlice';
import customerService from '../../../services/customerService';
import RevereseService from '../Contracts/Common/RevereseService';
import { contractForm } from "../../Interface";

const Paid = (contractForm) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const location = useLocation();
    const { toBePaidedtransferData, toBePaidedStatus } = useSelector((state: RootState) => state.transferData)
    const { accountData, billingData } = useSelector((state: RootState) => state.expenditureData)
    const { userbanklist } = useSelector((state: RootState) => state.bank)
    const [show, setShow] = useState(true);
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [allSuplier, setAllSuplier] = useState([])
    const [paidList, setPaidList] = useState<any>([])
    const [bankFilter, setbankFilter] = useState('')
    const [paidwith, setPaidwith] = useState('')
    const [supplier, setSupplier] = useState('')
    const [paidDetailState, setPaidDetailState] = useState<any>([]);

    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');

    const [expenseState, setExpenseState] = useState<any>({
        start: 0,
        end: 20,
        bankAccount: "",
        status: "all",
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        filterby: "",
        processingstatus: "",
        oftheType: "",
        from: "banktransaction"
    })


    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3040) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
    const getToBePaid = async (postData) => {
        if (location?.state) {
            postData["receid"] = location?.state;
            await dispatch(transferToBePaided(postData));
            location.state = null
            window.history.replaceState(null, '');
        } else {
            let response = await dispatch(transferToBePaided(postData));
        }
    }
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(toBePaidedtransferData.data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false);
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }
    };

    const [state, setState] = useState<any>({
        contract: "",
        owner: "",
        bankAccount: "",
        paidwith: "",
        supplier: "",
        bankAccountType: "",
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        start: 0,
        end: 40,
        filterby: "",
        typeofexpenses: "",
        searchType: "",
    })

    /*----------Pagination---------*/

    let PageSize = 20;
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setState({
            ...state,
            ['start']: (currentPage > 1 ? currentPage - 1 : 0) * 20,
            ['end']: 20
        })
    }, [currentPage])

    let typingTimer;
    const filterChargeDue = (evt: any, type: any) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            const name = evt.target.name;
            const value = evt.target.value;
            setState({
                ...state,
                [name]: value
            })
            if (type == "bankAccount") {
                var selectElement: any = document.querySelector('select[name="bankAccount"]');
                var selectedIndex = selectElement?.selectedIndex;
                var selectedOption = selectElement?.options[selectedIndex];
                // Get the value of the data-action attribute
                var dataAction: any = selectedOption?.getAttribute('label');
                setbankFilter(dataAction)
            } else if (type == "paidwith") {
                var selectElement: any = document.querySelector('select[name="paidwith"]');
                var selectedIndex = selectElement?.selectedIndex;
                var selectedOption = selectElement?.options[selectedIndex];
                // Get the value of the data-action attribute
                var dataAction: any = selectedOption?.getAttribute('label');
                setPaidwith(dataAction)
            } else {
                var selectElement: any = document.querySelector('select[name="supplier"]');
                var selectedIndex = selectElement?.selectedIndex;
                var selectedOption = selectElement?.options[selectedIndex];
                // Get the value of the data-action attribute
                var dataAction: any = selectedOption?.getAttribute('label');
                setSupplier(dataAction)
            }
        }, 800);
    }

    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['searchDate']: searchDt,
                ['endDate']: endDt,
            })
        }
    }
    useEffect(() => {
        getToBePaid(state);
    }, [dispatch, state]);

    let obj = {
        start: "0",
        end: 20,
        all: ""
    }

    useEffect(() => {
        dispatch(getBankList(obj))
    }, [dispatch, paidDetailState])

    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }));
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
    }, [dispatch])

    useEffect(() => {
        setPaidList(toBePaidedtransferData);
    }, [toBePaidedtransferData]);


    useEffect(() => {
        (async () => {
            let allSuplier = await dispatch(getAllSuppliers())
            if (allSuplier.payload.resCode == 201) {
                setAllSuplier(allSuplier.payload.data)
            }
        })()
    }, [dispatch])
    const typeOfExpeneses = (e: any) => {
        const name = e.target.name
        const val = e.target.value;
        setState({
            ...state,
            [name]: val

        })
        let data_array: any = [];
        if (val === "all") {
            // If "all" is selected, reset to the original data.
            if (toBePaidedtransferData && toBePaidedtransferData?.data && toBePaidedtransferData?.data?.length > 0) {
                data_array = toBePaidedtransferData?.data;
                getToBePaid(state);
            }
        } else if (val === "splitTransfers") {
            if (toBePaidedtransferData && toBePaidedtransferData?.data && toBePaidedtransferData?.data?.length > 0) {
                // If "splitTransfers" is selected, filter the data where owner length is greater than 1.
                data_array = toBePaidedtransferData?.data?.filter((element: any) => element?.owner?.length > 1);
            }
        } else if (val === "currentAccount") {
            if (toBePaidedtransferData && toBePaidedtransferData?.data && toBePaidedtransferData?.data?.length > 0) {
                for (const element of toBePaidedtransferData?.data || []) {
                    for (const ele of element.owner) {
                        if (ele.aacountType === 1) {
                            data_array.push(element);
                        }
                    }
                }
            }
        } else if (val === "savingAccount") {
            if (toBePaidedtransferData && toBePaidedtransferData?.data) {
                if (toBePaidedtransferData?.data.length > 0) {
                    for (const element of toBePaidedtransferData?.data || []) {
                        for (const ele of element.owner) {
                            if (ele.aacountType === 2) {
                                data_array.push(element);
                            }
                        }
                    }
                }
            }
        } else {
            // Handle unexpected filter values here, e.g., set data_array to the original data.
            if (toBePaidedtransferData?.data.length > 0) {
                data_array = toBePaidedtransferData?.data;
            }
        }
        // Update the state with the filtered data.
        setPaidList({ data: data_array })
    };
    //console.log()
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    const FilterBy = (e: any) => {
        let value = e.target.value
        setState({
            ...state,
            ['filterby']: value,

        })
    }
    const filterReset = () => {
        let setvalue: any = document.querySelector("#status");
        if (setvalue) {
            setvalue.value = ""
        }
        let setvalue1: any = document.querySelector("#typeofexpanse");
        if (setvalue1) {
            setvalue1.value = ""
        }
        let bakAccount: any = document.querySelector("#bakAccount");
        if (bakAccount) {
            bakAccount.value = ""
        }
        let paidwith: any = document.querySelector("#paidwith");
        if (paidwith) {
            paidwith.value = ""
        }
        let supplier: any = document.querySelector("#supplier");
        if (supplier) {
            supplier.value = "All"
        }
        let bankAccountType: any = document.querySelector("#bankAccountType");
        if (bankAccountType) {
            bankAccountType.value = "all"
        }
        setState({
            contract: "",
            owner: "",
            bankAccount: "",
            paidwith: "",
            supplier: "",
            bankAccountType: "",
            startDate: startDt,
            searchDate: searchDt,
            endDate: endDt,
            start: 0,
            end: 20,
            type: "normal",
            filterby: "",
            typeofexpenses: "",
        })
        setbankFilter('')
        setSupplier('')
    }


    const handlePencilClick = async (e: any, item: any) => {
        await setPaidDetailState(item); // Set the state synchronously
        await dispatch(setFinancialExpOpen(true));
    }

    const [type, setType] = useState("");
    const [id, setId] = useState("");
    const [showReverseModal, setRevereseModal] = useState(false);
    const reverseOpened = (id) => {
        setId(id);
        setType("paid");
        setRevereseModal(true);
    }

    const closeRevereseModal = () => {
        setRevereseModal(false);
        setId("");
        setType("");
        getToBePaid(state);
    }
    const contractValidation = Yup.object().shape({
        startDate: Yup.string().trim().required(t("requiredMmessage.fieldRequired")),
        endDate: Yup.date().typeError(t("requiredMmessage.fieldRequired"))
            .when("startDate", (startDate, schema) =>
                moment(startDate).isValid()
                    ? schema.min(startDate, t("formLabel.EnsureMoveDateAfterStartDate"))
                    : schema
            ),
    });
    let frm = {
        resolver: yupResolver(contractValidation)
    }
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<contractForm>(frm);
    const [minValue, setMinValue] = useState('')
    const startDate = (e: any) => {
        let { value } = e.target
        setMinValue(value)
    }
    const [showperiodfilter, setShowPeriodFilter] = useState(false)
    const handleShow = () => {
        setShowPeriodFilter(true)
    }
    const peridfilterClose = () => {
        setShowPeriodFilter(false)
        reset()
    }

    const dateSubmit = (data) => {
        let start2 = data.startDate; // Assign within function
        let endDt2 = data.endDate;

        setState({
            ...state,
            ['startDate']: start2,
            ['endDate']: endDt2,
            ['searchType']: 'period'
        })
        setShowPeriodFilter(false)
        reset();
    }
    return (
        <section className="content-wrapper content-wrapper-primary">
            <RevereseService showReverseModal={showReverseModal} closeRevereseModal={closeRevereseModal} type={type} id={id} />
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                <h1 className="mb-0 lh-base text-truncate">{t("paid")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control onChange={e => filterChargeDue(e, '')} name="contract" onKeyUp={e => filterChargeDue(e, '')} placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                />
                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {/* {
                                    userJson && userJson.length > 0 && (userJson.indexOf(3031) !== -1) &&
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                        <a role="button" className="fw-bold">
                                            <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                            {t("expenditure.newCharge")}
                                        </a>
                                    </li>
                                } */}
                                {/*<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a role="button" className="fw-bold">
                                        {t("ReceiveandReceived.Shipping")}
                                    </a>
                                </li>*/}

                                {/*<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                    </a>
                                </li>
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/question_icon.svg" alt="Question icon" className="h-20" />
                                    </a>
                                </li>*/}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                        {/* {state.startDate} */}
                                    </Button>
                                    <Button variant="primary" className="btn-date capitalize" disabled >
                                        {moment(state.searchDate).format("MMM/YYYY")}
                                    </Button>
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')}>
                                        {/* {state.endDate} */}
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["filterby"] ? "show" : ""}`}>
                                        {state?.["filterby"] == "all" ? t("TransferPage.banktransactions") : state?.["filterby"] == "receipts" ? t("TransferPage.receipts") : state?.["filterby"] == "payments" ? t("TransferPage.payments") : t("TransferPage.Filterby")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("TransferPage.Filterby")}>
                                            <Form.Select aria-label="Filter by" onChange={FilterBy} id="status" >
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                <option value="PaidTransfers">{t("paidTobepaid.PaidTransfers")}</option>
                                                <option value="Realestatexpenses">{t("paidTobepaid.Realestatexpenses")}</option>
                                                <option value="Contractexpenses">{t("paidTobepaid.Contractexpenses")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>

                                    {/* filterChargeDue */}
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("dropdownItem.status")}
                                </Dropdown.Toggle>
                                 <Dropdown.Menu className="px-4">
                                <FloatingLabel label="Status">
                                                <Form.Select aria-label="Status" name="status" onChange={filterChargeDue}>
                                                    <option value="All">{t("all")}</option>
                                                    <option value="pending">{t("pending")}</option>
                                                    <option value="settled">{t("settled")}</option>
                                                    <option value="checkstoclear">{t("checkstoclear")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                            {/* <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["typeofexpenses"] ? "show" : ""}>
                                        {state?.["typeofexpenses"] == "all" ? t("formLabel.all") : state?.["typeofexpenses"] == "splitTransfers" ? t("formLabel.splitTransfers") : t("dropdownItem.typeOfExpenses")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("dropdownItem.typeOfExpenses")}>
                                            <Form.Select aria-label="Cities" name="typeofexpenses" onChange={typeOfExpeneses} id="typeofexpanse">
                                                <option value="">{t("formLabel.select")}</option>
                                                <option value="all">{t("formLabel.all")}</option>
                                                <option value="splitTransfers">{t("formLabel.splitTransfers")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}
                            {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                {t("paidTobepaid.Processingstatus")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                            <FloatingLabel label={t("paidTobepaid.Processingstatus")}>
                                                <Form.Select aria-label="Processing status">
                                                    <option value="">{t("formLabel.all")}</option>
                                                    <option value="">{t("paidTobepaid.Unmarked")}</option>
                                                    <option value="">{t("marked")}</option>
                                                    <option value="">{t("paidTobepaid.Sent")}</option>
                                                    <option value="">{t("paidTobepaid.Scheduled")}</option>
                                                    <option value="">{t("paidTobepaid.Witherror")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={bankFilter ? "show" : ""}>{bankFilter ? bankFilter : t("banks.bankAccounts")}</Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("bankAccount")}>
                                            <Form.Select aria-label="Bank account" name="bankAccount" onChange={(e) => filterChargeDue(e, "bankAccount")} id="bakAccount">
                                                <option value="">{t("formLabel.all")}..</option>
                                                {userbanklist && userbanklist.length > 0
                                                    ? userbanklist.map((item, index) => (
                                                        <option key={index} value={Number(item.id)} label={item.nameReference}>
                                                            {item.nameReference}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["paidwith"] ? "show" : ""}>
                                        {paidwith ? paidwith : t("paidTobepaid.Paidwith")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4" >
                                        <FloatingLabel label={t("paidTobepaid.Paidwith")}>
                                            <Form.Select aria-label="paidwith" name="paidwith" onChange={(e) => filterChargeDue(e, "paidwith")} id="paidwith">
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {
                                                    billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                                        <option key={index} value={Number(item.id)} label={item.name}>{item.name}</option>
                                                    ))
                                                        : ''
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/*  */}
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["supplier"] ? "show" : ""}>
                                        {supplier ? supplier : t("formLabel.Supplier")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("formLabel.Supplier")} >
                                            <Form.Select aria-label="Supplier" name="supplier" onChange={(e) => filterChargeDue(e, "supplier")} id="supplier">
                                                <option value="All">{t("formLabel.all")}..</option>
                                                {allSuplier && allSuplier.length > 0
                                                    ? allSuplier.map((item: any, index) => (
                                                        <option key={index} value={Number(item?.id)} label={item?.name}>
                                                            {item?.name}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["bankAccountType"] ? "show" : ""}>
                                        {state?.["bankAccountType"] == "all" ? t("all") : state?.["bankAccountType"] == "currentAccount" ? t("currentAccount") : state?.["bankAccountType"] == "savingAccount" ? t("SavingAccount") : t("dropdownItem.bankAccountType")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("dropdownItem.bankAccountType")}>
                                            <Form.Select aria-label="Cities" name="bankAccountType" onChange={typeOfExpeneses} id="bankAccountType">
                                                <option value="all">{t("All")}</option>
                                                <option value="currentAccount">{t("currentAccount")}</option>
                                                <option value="savingAccount">{t("SavingAccount")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}

                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["type"] == "periodic" ? "show" : ""} onClick={handleShow}>
                                        {t("periodfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </Col>

                            {state?.["filterby"] || state?.["typeofexpenses"] || bankFilter || state?.["paidwith"] || state?.["supplier"] || state?.["bankAccountType"] || state?.["type"] == "periodic" ? <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                        {t("button.resetfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col> :
                                ""}
                            {/* <Col xl="auto">
                            <Button variant="light" size="sm">
                                {t("moreFilters")}
                                <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-1 h-20" />
                            </Button>
                        </Col> */}
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Alert className="mb-lg-4 mb-3" variant="warning" onClose={() => setShow(false)} dismissible>
                    <h6>{t("ReceiveandReceived.Questionsaboutcreditcarderrorcodes")}?</h6>
                    <Link to={'/receivable_charges'}>{t("ReceiveandReceived.Clickhere")}</Link> {t("ReceiveandReceived.orHelpintheupperrightcornerandlearnmore")}!
                </Alert>
                {/* <!-- overview -->*/}
                <Card className="mb-lg-4 mb-3">
                    <Card.Header>{t("ReceiveandReceived.Summaryof")}  <span className="capitalize">{moment(state.searchDate).format("MMM/YYYY")}</span></Card.Header>
                    <Card.Body>
                        <Row className="gy-3 gx-xxl-5 gx-md-4">
                            <Col sm={4} className="border-end border-light border-xs-none">
                                <span className="d-block text-dark h3 lh-1 mb-2">R$ {toBePaidedtransferData.totalRow ? converter(`${toBePaidedtransferData.totalRow.chargeReceivedAmount}`) : 0}</span>
                                <span className="text-secondary d-block">{toBePaidedtransferData.totalRow ? toBePaidedtransferData.totalRow.chargeReceivedCount : 0} {t("overDueExpense")}</span>
                            </Col>
                            <Col sm={4} className="border-end border-light border-xs-none">
                                <span className="d-block text-dark h3 lh-1 mb-2">R$ {toBePaidedtransferData.totalRow ? converter(`${toBePaidedtransferData.totalRow.paidAmount}`) : 0} </span>
                                <span className="text-secondary d-block">{toBePaidedtransferData.totalRow ? toBePaidedtransferData.totalRow.paidCount : 0} {t("paidTobepaid.collectionsreceivable")}</span>
                            </Col>
                            <Col sm={4}>
                                <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/eye_close.svg" alt="Dollar" className="me-1 h-20" />{toBePaidedtransferData.totalRow ? toBePaidedtransferData.totalRow.unPaidCount : 0}</span>
                                <span className="text-secondary d-block">{t("paidTobepaid.Unseencharges")}</span>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {/* <!-- /overview -->*/}
                {/*<!-- Table start from here -->*/}
                {
                    (toBePaidedStatus == "loading") ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w-24">
                                                {
                                                    paidList.data && paidList.data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        : ''
                                                }
                                            </th>
                                            <th>{t("transfer_forecast")}</th>
                                            <th>{t("Client")}</th>
                                            <th>{t("value")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paidList.data && paidList.data.length > 0 ? paidList.data.map((item, index) => (
                                            <tr key={index}>
                                                <td className={"check_Checkbox"}>
                                                    <Checkbox key={item.ID_CONTABANCO_MOV} type="checkbox" name={item.ID_CONTABANCO_MOV} id={item.ID_CONTABANCO_MOV} handleClick={handleClick} isChecked={isCheck.includes(item.ID_CONTABANCO_MOV)} />
                                                </td>
                                                <td>
                                                    {item.DT_LIQUIDACAO_MOV}
                                                    {/*<span className="d-block">Late for 23d</span>*/}
                                                    {
                                                        item.FL_REVERSE_MOV == 1 ?
                                                            <span className="d-block text-primary fw-bold">{t("paidTobepaid.Reversed")}</span>
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    <span className="ps-1">
                                                        {item.ST_HISTORICO_MOV}
                                                    </span>
                                                    <br />
                                                    <span className="ps-1">
                                                        {t("paidTobepaid.Expense")} - {item.ID_CONTABANCO_MOV}
                                                    </span>


                                                    <span className="d-block">
                                                        <span className="ps-1">
                                                            <small>Com : </small> {item.ST_FORMA_PAG} <small>Banco:</small> {item.ST_DESCRICAO_CB} <small>Conta :</small> {item.ST_CONTA_CB}
                                                        </span>
                                                    </span>
                                                    <div className="w-75 mt-1"><ProgressBar className="h-10" variant="success" now={100} /></div>
                                                </td>
                                                <td className="text-danger-40">
                                                    <span className="text-nowrap d-block">R$ {Math.sign(item.VL_VALOR_MOV) == -1 ? item.VL_VALOR_MOV : "-" + converter(`${item.VL_VALOR_MOV}`)} </span>
                                                </td>
                                                <td className="text-nowrap text-end">
                                                    <div className="d-flex justify-content-end">
                                                        {/* <img src="assets/images/icons/print-dark.svg" alt="Print" className="cursor-pe h-16 me-2" /> */}
                                                        {/* <span className="icon cursor-pe me-2 me-xl-3 me-xxl-4"> <img src="assets/images/icons/edit-light.svg" alt="Edit" className="h-20" /></span> */}
                                                        {
                                                            userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) &&
                                                            <span className="icon cursor-pe me-2 me-xl-3 me-xxl-4" onClick={(e) => { handlePencilClick(e, item) }}>
                                                                <img src="assets/images/icons/info-light.svg" alt="Edit" className="h-20" />
                                                            </span>
                                                        }
                                                        {/*<span className=" icon cursor-pe"><img src="assets/images/icons/circle-check-light.svg" alt="liquidate" className="h-20" /></span>*/}

                                                        {
                                                            // item.expId && (item.credit == 4 || item.credit == 3) ?
                                                            item.VL_VALOR_MOV <= 0 && item.FL_STATUS_CON != 6 &&
                                                                userJson && userJson.length > 0 && (userJson.indexOf(3043) !== -1) ?
                                                                <span className="icon cursor-pe me-2 me-xxl-4 me-lg-3" onClick={() => reverseOpened(item.id)} >
                                                                    <img src="assets/images/icons/reverse-primary.svg" alt="Reverse" className="h-20" />
                                                                </span>
                                                                : ''
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )) :
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                }
                {/*<!-- /Table end -->*/}
                <div className="d-flex justify-content-center mt-3 mt-lg-4">
                    {
                        (toBePaidedStatus != "loading") && toBePaidedtransferData && toBePaidedtransferData.totalRow && toBePaidedtransferData.totalRow.totalRow > 0 ?
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={toBePaidedtransferData && toBePaidedtransferData.totalRow.totalRow > 0 ? toBePaidedtransferData.totalRow.totalRow : 0}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                            :
                            ''
                    }
                </div>
                {/*More Items - Auto - All Items*/}
                <p className="my-lg-4 my-3">
                    {t('formLabel.listing')} {state.start} to {paidList.data && paidList.data.length > 0 ? state.start + paidList.data.length : state.end + 0} of  {toBePaidedtransferData && toBePaidedtransferData.totalRow && toBePaidedtransferData.totalRow.totalRow > 0 ? toBePaidedtransferData.totalRow.totalRow : 0} {t("Links.properties")}.</p>
                {/* <!-- Nav listing start from here -->*/}

                {
                    userJson && userJson.length > 0 && (userJson.indexOf(3040) !== -1) &&
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t('formLabel.mark')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleSelectAll} href="">{t('formLabel.all')}</Dropdown.Item>
                                    <Dropdown.Item onClick={noneHandleSelectAll} href="">{t('formLabel.none')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("expenditure.withTagged")}:</span></li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm">{t("printOut")}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm">{t("TransferPage.Sendbyemail")}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm">{t("TransferPage.Changeexpiration")}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("button.more")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item data-name="Remove Manager">{t("property.removeManager")}</Dropdown.Item>
                                    <Dropdown.Item data-name="Change branch">{t("property.changeBranch")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                }
                {/*<!-- /Nav listing  end -->*/}
            </main>
            <FinancialExpenseService state={state} type="paid" paidDetail={paidDetailState} />

            <Modal show={showperiodfilter} onHide={peridfilterClose}>
                <Form onSubmit={handleSubmit(dateSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From" className={` ${errors.startDate ? "is-invalid" : ""
                                        }`} {...register("startDate")} onChange={startDate}></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.startDate?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to" min={minValue} className={` ${errors.endDate ? "is-invalid" : ""
                                        }`} {...register("endDate")} ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.endDate?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={peridfilterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/*<!-- /Main content end -->*/}
        </section>
    )
}
export default Paid;