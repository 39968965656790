import { Table, Dropdown, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, ListGroup, Card } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useMemo, useRef } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import '../../../../App.css';
import Pagination from '../../../Pagination';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { openErrorbox } from '../Common/actionPerform';
import { add_Bank, getBankList } from '../../../../redux/slice/bankSlice';
import { useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { getAllBillMethodListing, openTypeCustomer1 } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, encrypt_Decrypt, checkDuplicateCNJP_CPF, handleKeyDown } from '../../../constants/utils';
import i18n from '../../../../i18n';
import { openEmailPop } from '../../../../redux/slice/contractDataSlice';
import SendEmails from '../Common/SendEmails';
import { config } from '../../../../services/apiConfig';

const Inspectors = (CustomersAndEmployeesForm) => {
    const dispatch = useDispatch<AppDispatch>()
    const { billingData } = useSelector((state: RootState) => state.expenditureData)
    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(add_Bank())
    }, [dispatch]);

    const navigate = useNavigate();
    const location = useLocation();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3070) === -1) {
            navigate("/customer_and_employees");
        }
        setUserJson(userResult);
    }, [])

    const [t] = useTranslation();
    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(true);
    const [headingText, setHeadingText] = useState(t('customerAndEmployees.newInspector'));
    const [status, setStatus] = useState(true);
    const [pixTypeHide, setPixTypeHide] = useState(false)
    /*--------Email modal state--------*/
    const [legalAndPhysical, setLegalAndPhysical] = useState<any>('')
    const [legalOwner, setLegalOwner] = useState<any>('')
    const [show2, setShow2] = useState(false);
    const [hide5, setHide5] = useState(false);
    const [mailText, setMailText] = useState("");
    const [showHideField, setShowHideField] = useState<any>("")
    /*--------Data table listing state--------*/
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const inputRef = useRef<any>(null);
    const [PageSize, setPageSize] = useState(20);
    const [state, setState] = useState({
        inspector: "",
        status: "",
        person: "",
        start: 0,
        end: PageSize,
    });
    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });
    useEffect(() => {
        getAllInspector(state);
    }, [state]);

    /*--------Common modal function--------*/

    const [sendMailData, setSendMailData] = useState<any>([]);
    const [loader_on, setloader_on] = useState(false);
    const [mailData, setMailData] = useState<any>([]);
    const formShow = () => { setShow(true); setHide(true); }
    const formClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        setShow(false); setHide(false); setHide2(false); setHide3(false); reset();
        setIsInputFocused(false);
    }
    const mailClose = () => { setShow2(false); reset(); }
    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');

        data?.forEach((item, index) => {
            let mailData: any = []; // Initialize mailData array for each iteration
            if (item["checked"]) {
                count = true
                sendMailData.forEach((element: any) => {
                    if (element.id == item.id) {
                        mailData.push(element.email);
                    }
                });

                // Push the mailData array into the emailArray
                setMailData(prevEmailArray => [...prevEmailArray, ...mailData]);
            }
            // setMailData(emailArray)
        });

        if (count) {
            setMailText(e.currentTarget.name)
            if (e.currentTarget.name === "Send letter" || e.currentTarget.name === "Enviar carta") {
                setHide5(true);
            } else {
                setHide5(false);
            }
            // setShow2(true)
            dispatch(openEmailPop(true))
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    /*-------Step form function & validation--------*/
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => { setHide2(false); setHide3(false); setHide(true) }
    const step2 = () => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false)
            setHide2(true); setHide3(false);
        }
    }
    const step3 = (data: CustomersAndEmployeesForm) => { setHide(false); setHide2(false); setHide3(true); }

    // Retrieve banklist, show1, and status from the Redux state
    const { banklist } = useSelector((state: RootState) => state.bank)

    /*-------------Form validation-------------*/
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let regexPattern = /^[^0-9./-]*$/;
    if (showHideField == "cnpj") {
        regexPattern = /^[a-zA-Z0-9 ]*$/;
    }
    const mailValidationSchema = Yup.object().shape({
        identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        model: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        variable: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    const formValidationSchemaStep1 = Yup.object().shape({
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    const formValidationSchemaStep2 = Yup.object().shape({
        name: Yup.string().trim().nullable()
        .when([], {
            is: () => headingText == "New Inspector" || headingText == "Novo Vistoriador",
            then: (schema) => schema.max(50, t('requiredMmessage.50characters')), // Custom max length message
          })
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        // email: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .email(t('requiredMmessage.fieldNotValid')),
        // password: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(passwordRules, { message: t("requiredMmessage.password") }),
    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    if (hide || hide2 || hide3) {
        frm = {
            resolver: yupResolver(formValidationSchemaStep1)
        }
        if (hide2) {
            frm = {
                resolver: yupResolver(formValidationSchemaStep2)
            }
        }
        openErrorbox()
    }
    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);

    /*-------------Email form submit-------------*/

    let [desc, setDesc] = useState('');

    /*-------------Call the api to save new Inspector data-------------*/

    const formSubmit = (data: CustomersAndEmployeesForm) => {
        let bankName = '';
        let bankcode = '';
        setloader_on(true);
        banklist.forEach((curElem: any, index: number) => {
            if (curElem.bank_name == data.bankname) {
                bankName = curElem.bank_name;
                bankcode = curElem.bank_code
            }
        })
        let owner = {
            type: "New Inspector",
            cnpj: data.cnpj,
            name: data.name,
            payment: data.payment,
            mobile: data.mobile,
            telephone: data.telephone,
            email: data.email,
            password: data.password,
            rg: data.rg,
            issue_agency: data.issue_agency,
            shipping_date: data.shipping_date,
            sex: data.sex,
            birth: data.birth,
            marital_status: data.marital_status,
            nationality: data.nationality,
            observation: data.observation,
            is_vendor: data.is_vendor,

            cnpjcpf: data.cnpj,

            spouse_name: data.spouse_name,
            spouse_cpf: data.spouse_cpf,
            spouse_rg: data.spouse_rg,
            spouse_issue_agency: data.spouse_issue_agency,
            spouse_nationality: data.spouse_nationality,
            spouse_sex: data.spouse_sex,
            spouse_profession: data.spouse_profession,
            spouse_cellphone: data.spouse_cellphone,
            spouse_telephone: data.spouse_telephone,
            spouse_email: data.spouse_email,
            spouse_observation: data.spouse_observation,
            ID_SACADO_SAC: data.ID_SACADO_SAC,
            ID_FAVORECIDO_FAV: data.ID_FAVORECIDO_FAV,
            corporateReason: data.corporateReason,
            stateRegistration: data.stateRegistration,
            municipalRegistration: data.municipalRegistration,
            new_added: {
                addState: addState,
                Renda: {
                    FL_SEMRENDA_PES: data.possiu ?? "",
                    FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                    ST_BENEFICIO_PES: data.beneficio ?? "",
                    VL_OUTROSRENDIMENTOS_PES: data.rendimentous ?? ""
                },
                Retencao_de_impostos: {
                    FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
                },
                Mais_opcoes: {
                    NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                    FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                    FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
                },
                Fornecedor: {
                    FL_SINCRONIZARSACADO_FAV: data.supplieradv ?? ""
                },
                Pagemento: {
                    DT_DIAREPASSE_PES: data.Repassedia ?? "",
                    VL_SALDOBLOQUEADO_PES: data.bloquendo ?? ""
                }
            }
        }
        customerService.createOwner(owner).then(res => {
            if (res.data.resCode === 202) {
                getAllInspector(state);
                formClose();
                setIsInputFocused(false);
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else {
                let msg = ((res.data?.data && res.data.data.length) || res.data.data) ? res.data.message : '';
                if (res.data.resCode == 409) {
                    msg = t("Sweetalert.unqueEmails");
                }
                if (res.data.resCode === 400 && res.data.message == 'Owneremail!=AgencyEmail') {
                    msg = t("ownerEmailnotEqualAgencyEmail");
                }
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: msg ? msg : t("Sweetalert.somethingWent"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            setloader_on(false);
        }).catch(error => {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                confirmButtonText: "OK",
                timer: 2000
            });
            setloader_on(false);
        });
    }

    /*-------Get the Inspector listing--------*/

    const getAllInspector = (inspector) => {
        let inspectorData = { inspector: inspector.inspector, start: inspector.start, end: inspector.end };
        setStatus(true);
        customerService.getAllInspector(inspectorData).then(res => {
            setStatus(false);
            if (res.data.resCode === 201) {
                setData(res.data.data);
                setTotal(res.data.totalRow);
                setSendMailData(res?.data?.data || [])
            }
            else {
                setData([]);
                setTotal(0);
                setSendMailData([]);
            }
        }).catch(err => {
            setStatus(false);
            console.log("err", err)
        });
    }
    const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hide2) {
            step1();
        } else if (hide3) {
            step2();
        } else {
            step1();
        }
    }
    /*-------Checked/Unchecked multiple checkbox functionality--------*/

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }

                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })

                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)

        }
    };

    // Pagination start
    const [currentPage, setCurrentPage] = useState(1);
    // const currentTableData = useMemo(() => {
    //     const firstPageIndex = (currentPage - 1) * PageSize;
    //     const lastPageIndex = firstPageIndex + PageSize;
    //     let newarr = data.slice(firstPageIndex, lastPageIndex);
    //     setTotal(newarr.length);
    //     return newarr;
    // }, [currentPage, data]);
    // Pagination End

    useEffect(() => {
        setState({
            ...state,
            ["start"]: currentPage == 1 || currentPage == 0 ? 0 : (currentPage - 1) * PageSize,
            ["end"]: PageSize
        })
    }, [currentPage])

    const getBankCode = (bankCode) => {
        setValue("bank_branchCode", bankCode);
    }

    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            setShowHideField(checkCNJP?.["type"])
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                let boolVal = false
                if (headingText == "New Inspector" || headingText == "Novo Vistoriador") {
                    boolVal = duplicateData["data"][0]?.FL_VISTORIADOR_PES == 1
                }
                const fieldsMapping: any = {
                    email: "ST_EMAIL_PES",
                    name: "ST_NOME_PES",
                    mobile: "ST_CELULAR_PES",
                    complement: "ST_COMPLEMENTO_PES",
                    zipcode: "ST_CEP_PES",
                    city: "ST_CIDADE_PES",
                    address: "ST_ENDERECO_PES",
                    state: "ST_ESTADO_PES",
                    number: "ST_NUMERO_PES",
                    neighborhood: "ST_BAIRRO_PES",
                    telephone: "ST_TELEFONE_PES",
                    observation: "ST_OBSERVACAO_PES",
                    nationality: "ST_NACIONALIDADE_PES",
                    sex: "ST_SEXO_PES",
                    birth: "DT_NASCIMENTO_PES",
                    marital_status: "ST_ESTADOCIVIL_PES",
                    employment_company: "ST_TRABALHOCEP_PES",
                    employment_profession: "ST_PROFISSAO_PES",
                    employment_field_activity: "ST_RAMOATIVIDADE_PES",
                    employment_address: "ST_TRABALHOENDERECO_PES",
                    employment_number: "ST_TRABALHONUMERO_PES",
                    employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
                    employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
                    employment_city: "ST_TRABALHOCIDADE_PES",
                    employment_state: "ST_TRABALHOESTADO_PES",
                    employment_monthly_income: "VL_RENDAMENSAL_PES",
                    rg: "ST_RG_COJ",
                    retention: "VL_TXISSQN_PES",
                    mother: "ST_NOMEMAE_PES",
                    father: "ST_NOMEPAI_PES",
                    shipping_date: "DT_EXPEDICAORG_PES",
                    block_balance: "VL_SALDOBLOQUEADO_PES",
                    manager: "ID_GESTOR_GES",
                    issue_agency: "ST_ORGAO_PES",
                    corporateReason: "ST_NOME_PES",
                    stateRegistration: "ST_IE_PES",
                    municipalRegistration: "ST_INSCMUNICIPAL_PES"
                };
                if (duplicateData["data"].length > 0) {
                    if (boolVal) {
                        zipCodeCheck?.["setAttribute"]('disabled', "")
                        setcnpjcpferrormessage({
                            name: name,
                            message: t("formLabel.ErrorCNPJCPF")
                        })
                        setError(name, {
                            type: "manual",
                            message: t("formLabel.ErrorCNPJCPF")
                        });
                    } else {
                        zipCodeCheck?.["removeAttribute"]('disabled');


                        // Assuming `duplicateData` is correctly populated
                        const data: any = duplicateData?.["data"]?.[0];

                        if (data) {
                            Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                                setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
                            });
                        }
                    }
                } else {
                    Object?.entries(fieldsMapping)?.forEach(([fieldName, dataKey]) => {
                        setValue(fieldName as keyof CustomersAndEmployeesForm, "");
                        setValue('ID_FAVORECIDO_FAV', "")
                        setValue('ID_SACADO_SAC', "")
                    });

                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    const [spouse, setspouse] = useState(false);
    const handleChangeMaritail = (e) => {
        if (e.target.value == "1") {
            setspouse(true);
        } else {
            setspouse(false);
        }
    }

    let typingTimer;
    const ActiveContract = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        if (name == "search") {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                setState({
                    ...state,
                    inspector: value
                })
            }, 1000)
        } else {
            if (name == "person") {
                setState({
                    ...state,
                    [name]: value == "1" ? "both" : value == "2" ? "Physical" : value == "3" ? "Legal" : ""
                })
            } else {
                setState({
                    ...state,
                    [name]: value
                })
            }
        }
    }
    const ownerPaymentHide = (evt) => {
        if (evt.target.value == 12) {
            setPixTypeHide(true);
        } else {
            setPixTypeHide(false);
        }

    }
    const handleNameof = (e) => {
        let { value } = e.target
        setValue("name_of", value)
        // Error validation
        if (value == "" || value == null || value == undefined) {
            // Set an error message using setError or update your error state
            setError("name", {
                type: "manual",
                message: (t('requiredMmessage.fieldRequired'))
            });
        } else {
            if (headingText == "New Inspector" || headingText == "Novo Vistoriador") {
                console.log(headingText, "headingText", value.length)
                if (value.length > 50) {
                    setError("name", {
                        type: "manual",
                        message: (t('requiredMmessage.50characters'))
                    });

                } else {
                    clearErrors('name');
                }
            }
        }
    }
    const handleSelectName = async (e, type, id) => {
        e.preventDefault();
        let data: any = {
            type: type
        }
        let typeClick: any = document.querySelector("body");
        typeClick.setAttribute("linkfor", type)
        typeClick.setAttribute("linkforid", "3070")
        navigate(`/profile_details/${id}`, { state: data });
    }
    useEffect(() => {
        const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
        const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
        setSearchResults(filteredBanks);

        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [banklist]);

    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };

    const HandleBankAccount = (e: any, item) => {
        setValue('bankname', item?.bank_name)
        setValue("bank_branchCode", item.bank_code);
        setIsInputFocused(false);
    }
    const handlefilterBillmethod = async (e) => {

        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = banklist.filter((bank) => {
                // Convert the bank name to lowercase for case-insensitive comparison
                const bankName = bank.bank_name.toLowerCase();
                return bankName.includes(lowerSearchTerm);
            });
            setSearchResults(results)
        } else {
            const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
            const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
            setSearchResults(filteredBanks);
        }
    }

    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={location?.state?.type == "inspectors" ? "/customer_and_employees"  : `${config.REPORTS_SERVER_URL}/configurations` }>
                                    <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-xl-1">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t('customerAndEmployees.inspectors')}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <div className="input-group">
                                <Form.Control type="text" id="search" name="search" placeholder={t('formLabel.typeSearch')}
                                    aria-describedby="basic-addon2" onChange={ActiveContract} />
                                <span className="input-group-text" id="basic-addon2">
                                    <img alt="img" src="./assets/images/icons/search.svg" />
                                </span>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end mb-xl-0 col-xl mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(3071) !== -1) &&
                                    <li className="list-inline-item mb-md-0 mb-1">
                                        <Link to={"#"} onClick={formShow}
                                            className="fw-bold">
                                            <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                            {t('customerAndEmployees.newSurveyor')}
                                        </Link>
                                    </li>
                                }
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li> */}
                                {/* <li className="list-inline-item mb-md-0 mb-1">
                                    <Link to={"#"} className="fw-bold">
                                        <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                    </Link>
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- Table start from here --> */}
                {
                    status ?
                        <Loader />
                        :
                        <>
                            <Card>
                                <Card.Body className="pb-1">
                                    <Table responsive className="table table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th className="w-24">
                                                    {data && data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        : ''}
                                                </th>
                                                <th>{t('formLabel.name')}</th>
                                                <th>{t('formLabel.contact')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.length > 0 ? data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className={"check_Checkbox"}>
                                                        <Checkbox key={item.id} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                    </td>
                                                    <td>
                                                        <span className="d-block text-dark-70">
                                                            <img alt="img" src="assets/images/icons/user_g.svg" />
                                                            <Link to={"#"} onClick={(e) => handleSelectName(e, "Inspectors", item.id)} className="ps-1 text-secondary">{item.name}</Link>
                                                            {/* <br />
                                                        <span>{legalAndPhysical ? legalAndPhysical : legalOwner ? legalOwner : ""}</span> */}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <a href={`mailto:${item.email}`} className="d-block text-dark-70">{item.email}</a>
                                                        <span>{item.telephone ? item.telephone : item.cellphone}</span>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan={3}>
                                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>


                            <div className="d-flex justify-content-center mt-lg-4 mt-3">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={total}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </>
                }
                {/* <!-- /Table end --> */}
                {/* {t('formLabel.moreItems')} */}
                <p className="d-block py-2">{t('formLabel.listing')} {data && data.length} {t('customerAndEmployees.inspectors')}.</p>
                {/* <!-- Nav listing start from here --> */}
                {
                    userJson && userJson.length > 0 && (userJson.indexOf(3071) !== -1) &&
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                    {t('formLabel.mark')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.all')}</Dropdown.Item>
                                    <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('formLabel.none')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t('formLabel.withTagged')}</span></li>
                        <li className="list-inline-item mb-lg-0 mb-1">
                            <Button type="button" variant="light" size="sm" onClick={handleMouseEvent} name={t('formLabel.sendEmail')} >{t('formLabel.sendEmail')}</Button>
                        </li>
                    </ul>
                }
                {/* <!-- /Nav listing end --> */}
            </main>
            {/* <!-- /Main content start end --> */}

            {/*---------------Inspector form modal------------*/}

            <Modal show={show} onHide={formClose} className='CustomersInpAndEmployeestsx'>
                <Modal.Header closeButton>
                    <Modal.Title>{headingText}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Body>
                        <input type="text" {...register('ID_SACADO_SAC')} hidden />
                        <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden />
                        <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                            <input type="hidden" value={headingText} {...register('type')} />
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf') + " *"}>
                                <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={` ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </div>

                        <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>

                            {/*<FloatingLabel controlId="floatingInput" label={t('formLabel.name')} className="mb-3" >
                            <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) =>handleNameof(e)} />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </FloatingLabel>*/}

                            {showHideField == "cnpj" ?
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.corporateReason")}>
                                            <Form.Control type="text" placeholder="Corporate reason" className={`form-control ${errors.corporateReason ? 'is-invalid' : ''}`} {...register('corporateReason')} />
                                            <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.fantasyName") + " *"}>
                                            <Form.Control type="text" placeholder="Fantasy name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleNameof(e)} />
                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.stateregistration")}>
                                            <Form.Control type="text" placeholder="State registration" className={`form-control ${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.municipalregistration")}>
                                            <Form.Control type="text" placeholder="Municipal registration" className={`form-control ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                            <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row> :
                                <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                                    <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            }

                            <span className="d-block mb-3 fw-bold">{t('formLabel.contact')}</span>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber') + " *"}>
                                        <Form.Control type="tel" placeholder={t('formLabel.mobileNumber')} className={` ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                        <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                        <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email') + " *"}>
                                <Form.Control type="email" placeholder={t('formLabel.email')} className={` ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label={t('formLabel.password') + " *"} className="mb-3" >
                                <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />
                                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <Accordion className="accordion-custom">
                                {showHideField == "cpf" ?
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                        <Form.Control type="text" placeholder={t('RG')}  {...register('rg')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('issue_agency')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                                        <Form.Control type="date" placeholder={t('formLabel.shippingDate')}  {...register('shipping_date')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                        <Form.Select {...register('sex')} aria-label={t('formLabel.sex')}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t('masculine')}</option>
                                                            <option value="2">{t('feminine')}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.birth')}>
                                                        <Form.Control type="date" placeholder={t('formLabel.birth')}  {...register('birth')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                                        <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} onChange={(e) => handleChangeMaritail(e)}>
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t("formLabel.married")}</option>
                                                            <option value="2">{t("formLabel.single")}</option>
                                                            <option value="3">{t("formLabel.widower")}</option>
                                                            <option value="4">{t("formLabel.legallySeparated")}</option>
                                                            <option value="5">{t("formLabel.disquitado")}</option>
                                                            <option value="6">{t("formLabel.divorced")}</option>
                                                            <option value="7">{t("formLabel.stableunion")}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('nationality')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    : ""}
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </div>
                        <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                            <div className="text-center">
                                <h3 className="mt-3 mb-2">{t('label.registeredSurveyor')}</h3>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={formClose}>
                                {t('button.close')}
                            </Button>
                            {hide ? '' : <Button variant="outline-primary" onClick={(e) => handlePrevious(e)} className="js-btn-step">
                                {t('button.previous')}
                            </Button>}

                            {!hide3 ?
                                <Button variant="primary" onClick={handleSubmit(hide ? step2 : step3)} className="min-w-auto js-btn-step zipCodeCheck btn-primary-focus-none" >
                                    {t('button.next')}
                                </Button>
                                :
                                loader_on ?
                                    <Button className="btn-width">
                                        <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                    </Button>
                                    :
                                    <Button variant="primary" type="submit" onClick={openErrorbox} className="btn-primary-focus-none">
                                        {t('button.save')}
                                    </Button>
                            }
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/*---------------Mail/Letter form modal------------*/}
            <SendEmails mailText={mailText} mailData={mailData} />

        </section>
    )
}
export default Inspectors;