import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom'
const ContractRegistered = (props: any) => {
    const [t] = useTranslation();
    const location = useLocation();
    //Contract registered modal close open function
    const { showContractRegistered, contractRegisteredClose, nagivatefile } = props;
    const navigate = useNavigate();
    const opentask = () => {
        if (nagivatefile) {
            navigate(`/contract_details/${nagivatefile}`)
        } else {
            contractRegisteredClose()
        }
    }
    return (
        <>
            <Modal show={showContractRegistered} onHide={contractRegisteredClose}>
                <Form>
                    <Modal.Header closeButton className="pb-2"></Modal.Header>
                    <Modal.Body className="pt-0 pb-2 text-center">
                        {
                            location.pathname == "/contract" ?
                                <img src="assets/images/icons/circle-check-success.svg" alt="cloud icon" className="min-h-56 mx-auto d-block" />
                                :
                                <img src="../assets/images/icons/circle-check-success.svg" alt="cloud icon" className="min-h-56 mx-auto d-block" />
                        }
                        <h4 className="mt-lg-4 mt-3 mb-3 pt-xl-1">{t('newContractDev.contractRegistered')}</h4>
                        <p className="text-dark-70 mb-0">{t('newContractDev.contractRegisteredContent')}<br />{t('newContractDev.contractRegisteredContentRemember')}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={contractRegisteredClose}>
                            {t('registerAthContract')}
                        </Button>
                        <Button variant="primary" onClick={opentask}>
                            {t('newTransfer.openContract')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default ContractRegistered
