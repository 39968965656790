import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const BaseYearModal = ({ show, onClose, onSelectYear }) => {

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i).filter(year => year <= currentYear - 1 && year >= 2024);

    const [selectedYear, setSelectedYear] = useState('');
    const [error, setError] = useState('');

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        setError('');
    };

    const handleSubmit = () => {
        if (!selectedYear) {
            setError('Ano de competência é obrigatório.');
            return;
        }

        if (onSelectYear) {
            onSelectYear(selectedYear);
        }
        onClose();
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Selecione o Ano de competência</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="yearSelect">
                        <Form.Label>Ano</Form.Label>
                        <Form.Select value={selectedYear} onChange={handleYearChange} isInvalid={!!error}>
                            <option value="">Selecione um ano</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </Form.Select>
                        {error && <Form.Text className="text-danger">{error}</Form.Text>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BaseYearModal;
