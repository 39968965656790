import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getCertificate, GetCityCode, postCertificate_data } from '../../../../redux/slice/taxationSlice';
import { AsyncThunkAction, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import Swal from 'sweetalert2';
import { Invoice } from '../../../Interface';
import moment from "moment-timezone";
import { incomeService } from '../../../../services/incomeServices';

const InvoicesOverview = (props) => {
    let { isCheck } = props
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const [t] = useTranslation()
    //Transmit notes modal initialization
    const schema = yup.object().shape({
        Webservicepassword: yup.string().required(t("requiredMmessage.fieldRequired")),
        Certificatepassword: yup.string().required(t("requiredMmessage.fieldRequired")),
    });
    const schema1 = yup.object().shape({
        Certificatepassword: yup.string().required(t("requiredMmessage.fieldRequired")),
    });
    const [showTransmitNoteModal, setTransmitNoteModal] = useState(false);
    const dispatch = useDispatch<AppDispatch>()
    const transmitModalClose = () => setTransmitNoteModal(false);
    const [show, setShow] = useState<any>(0)
    const [cityCodes, setCityCode] = useState<any>('')
    const [expireDate, setExpireDate] = useState<Boolean>(false)
    const { certificate } = useSelector((state: RootState) => state.taxa)
    const { companydata } = useSelector((state: RootState) => state.companyData);

    useEffect(() => {
        dispatch(getCertificate());
    }, [dispatch])

    const transmitModalShow = (e: any, cmd: any) => {
        if (isCheck.length > 0) {
            if (cmd == "tagandtransmit") {
                setShow(1)
            } else {
                setShow(2)
            }
            setTransmitNoteModal(true);
        } else {
            Swal.fire({
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.pleaseSelectInvoice")}`,
                // icon: 'success',"error",
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
            });
        }

    }
    var frm = { resolver: yupResolver(schema1) }
    if (show == 2) {
        var frm = { resolver: yupResolver(schema1) }
    }
    if (show == 1) {
        var frm = { resolver: yupResolver(schema) }
    }

    const { register, handleSubmit, reset, setValue, formState: { errors }, setError, clearErrors, } = useForm<Invoice>(frm);

    useEffect(() => {
        (async () => {
            if (certificate && certificate?.data && certificate?.data?.length > 0) {
                let plugnotaId = certificate.data[0].ST_TOKEN_CERT;
                let respo = await dispatch(postCertificate_data(plugnotaId))

                const certificateDate = new Date(respo.payload.vencimento);
                const currentDate = new Date(dateBrazil);

                // Calculate the difference in months
                const monthsDiff = (certificateDate.getFullYear() - currentDate.getFullYear()) * 12 +
                    (certificateDate.getMonth() - currentDate.getMonth());

                if (certificateDate < currentDate) {
                    // Certificate has already expired
                    const formattedExpirationDate = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')
                        }/${currentDate.getDate().toString().padStart(2, '0')
                        }/${currentDate.getFullYear()
                        }`;

                    // const message = `The current certificate expired ${Math.abs(monthsDiff)} months ago (${formattedExpirationDate}).`;
                    setExpireDate(false);
                } else {
                    // Certificate will expire in the future
                    const formattedExpirationDate = `${(certificateDate.getMonth() + 1).toString().padStart(2, '0')
                        }/${certificateDate.getDate().toString().padStart(2, '0')
                        }/${certificateDate.getFullYear()
                        }`;
                    // const message = `The current certificate will expire in ${monthsDiff} months on (${formattedExpirationDate}).`;
                    setExpireDate(true);
                }
            }
        })()
    }, [certificate])
    useEffect(() => {
        if (companydata.length) {
            cityCode()
        }
    }, [isCheck, companydata])

    const cityCode = async () => {
        let res = await dispatch(GetCityCode())
        if (res.payload.length > 0) {
            const filteredData = res?.payload?.filter(item => item?.nome == companydata[0]?.city && item?.uf == companydata[0]?.state);
            setCityCode(filteredData[0]?.id)
        }
    }
    const onSubmit = (data: any) => {
        let data1 = {
            isCheck: isCheck,
            city_code: cityCodes
        };
        
        // plugnotasgenerate
        incomeService.GeneratePlungnota(data1).then((res) => {
            // Segregate the success and error details
            const successDetails = res.data.details.filter((detail: any) => detail.status == 'success');
            const errorDetails = res.data.details.filter((detail: any) => detail.status == 'error');
    
            // Separate messages by status
            let successMessages: string[] = [];
            let errorMessages: string[] = [];
    
            // Organize messages based on their status
            res.data.details.forEach((detail: any) => {
                if (detail.status === "success") {
                    successMessages.push(detail.message);
                } else if (detail.status === "error") {
                    errorMessages.push(`${JSON.stringify(detail.data?.data?.fields || {})}`);
                }
            });
    
            const alertMessage = `
            ${successDetails.length > 0 ? '<b>Success:</b><br>' + successMessages.join('<br />') + '<br /><br />' : ''}
            ${errorDetails.length > 0 ? '<b>Errors:</b><br>' + errorMessages.join('<br />') : ''}
        `;
    
            // Show single SweetAlert with both success and error messages
            Swal.fire({
                position: "center",
                icon:  errorDetails.length > 0 ?   "error" : 'success', // Show 'error' if there are any error details
                title: errorDetails.length > 0 ? `${t("Sweetalert.Error")}` : `${t("Sweetalert.Success")}`,
                html: alertMessage, // using 'html' to support line breaks and formatting
                showCloseButton: true
            });
               setTransmitNoteModal(false);
        reset();
        });
    // 
        // Uncomment if you want to reset and close modal on success
     
    };
    

    return (
        <>
            {/* <!-- overview -->*/}
            <Card className="mb-lg-4 mb-3">
                <Card.Header>{t("Invoices.summary")}</Card.Header>
                <Card.Body>
                    <Row className="gy-3 gx-xxl-5 gx-md-4">
                        <Col sm={4} className="border-end border-light border-xs-none">
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/pin-light.svg" alt="Pin" className="me-1 h-22" />{props?.totalRow}</span>
                            <span className="text-secondary d-block">{t("Invoices.tobemarked")}</span>
                            <a className="fw-bold cursor-pe" onClick={(e) => transmitModalShow(e, "tagandtransmit")}>{t("Invoices.tagandtransmit")}</a>
                        </Col>
                        <Col sm={4} className="border-end border-light border-xs-none">
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/sync-light.svg" alt="Sync" className="me-1 h-22" />{t("Invoices.none")}</span>
                            <span className="text-secondary d-block">{t("Invoices.shippingpending")}</span>
                            <span className="d-block">{t("Invoices.fromotherperiods")}</span>
                            <a className="fw-bold cursor-pe" onClick={(e) => transmitModalShow(e, "transmitnotes")}>{t("Invoices.transmitnotes")}</a>
                        </Col>
                        {expireDate ? "" : <Col sm={4}>
                            <span className="d-block text-dark h3 lh-1 mb-2">{t("Invoices.expiredcertificate")}</span>
                            <span className="text-secondary d-block">{t("Invoices.Tocontinueissuinginvoices")}</span>
                            <Link className="fw-bold" to={"/taxation"}>{t("Invoices.Addthecertificate")}</Link>
                        </Col>}
                    </Row>
                </Card.Body>
            </Card>
            {/* <!-- /overview -->*/}
            {/* Transmit notes modal start from here */}
            <Modal show={showTransmitNoteModal} onHide={transmitModalClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Invoices.Transmitnotes")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3">
                            {show == 1 ?
                                <Col md={6}>
                                    <FloatingLabel controlId="webservice_password" label={t("formLabel.webservicepassword")}>
                                        <Form.Control type="password" className={`${errors.Webservicepassword ? "is-invalid" : ""}`} placeholder="Webservice password" {...register("Webservicepassword")} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.Webservicepassword?.message}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                : ""
                            }
                            <Col md={6}>
                                <FloatingLabel controlId="certificate_password" label={t("formLabel.certificatepassword")} >
                                    <Form.Control type="password" className={`${errors.Certificatepassword ? "is-invalid" : ""}`} placeholder="Certificate password" {...register("Certificatepassword")} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.Certificatepassword?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={transmitModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Transmit notes modal end */}
        </>
    )
}

export default InvoicesOverview


