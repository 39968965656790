import { config } from "../../../../services/apiConfig";

export async function getHomologateAgency(): Promise<boolean> {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(
    `${config.TRANSFER_API}/homologate/bankslip/agency`,
    {
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        "connection-name": connectionName,
      },
    }
  );

  const data = await response.json();

  return data.resCode === 201 || data.resCode === 200;
}
