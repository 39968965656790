import React, { useEffect, useState } from 'react';
import { Form, Badge, Button, Row, Col, InputGroup, Card, Dropdown, Toast, ToastContainer } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteTemplate from './DeleteTemplate';
import RenameTemplate from './RenameTemplate';
import MoveFolder from './MoveFolder';
import EditFolder from './EditFolder';
import ContinueWithoutTemplate from '../ContinueWithoutTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { listTemplatesInsideFolder } from "../../../../../redux/slice/SendEmailSlice";
import { AppDispatch, RootState } from '../../../../../redux/store';
import customerService from '../../../../../services/customerService';
import Swal from 'sweetalert2';
import moment from 'moment';
import { encrypt_Decrypt } from '../../../../constants/utils';


const FolderListing = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    const [documentFileId, setDocumentFileId] = useState<any>("");
    const [documentFileName, setDocumentFileName] = useState<any>("");
    const [userJson, setUserJson] = useState<any>([]);

    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || (userResult.indexOf(1090) === -1)) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    useEffect(() => {
        dispatch(listTemplatesInsideFolder(id));
    }, [dispatch]);

    //Rename template modal close open function
    const [showRenameTemplateModal, setRenameTemplateModal] = useState(false);
    const renameTemplateModalClose = () => setRenameTemplateModal(false);

    //Delete template modal close open function
    const [showDeleteTemplateModal, setDeleteTemplateModal] = useState(false);
    const deleteTemplateModalClose = () => setDeleteTemplateModal(false);
    const deleteTemplateModalShow = async (file_id) => {
        await setDocumentFileId(file_id);
        setDeleteTemplateModal(true);
    }

    //Move folder modal close open function
    const [showMoveFolderModal, setMoveFolderModal] = useState(false);
    const moveFolderModalClose = () => setMoveFolderModal(false);
    const moveFolderModalShow = async (file_id, file_title) => {

        await setDocumentFileId(file_id);
        await setDocumentFileName(file_title);
        await setMoveFolderModal(true);
    }

    const renameTemplateModalShow = async (file_id, file_title) => {
        await setDocumentFileId(file_id);
        await setDocumentFileName(file_title);
        await setRenameTemplateModal(true);
    }

    //Edit folder modal close open function
    const [showEditFolderModal, setEditFolderModal] = useState(false);
    const editFolderModalClose = () => setEditFolderModal(false);
    const editFolderModalShow = () => setEditFolderModal(true);

    //Continue without template detail modal close open function
    const [showContinueWithoutTemplateModal, setContinueWithoutTemplateModal] = useState(false);
    const continueWithoutTemplateModalClose = () => setContinueWithoutTemplateModal(false);
    const continueWithoutTemplateModalShow = () => setContinueWithoutTemplateModal(true);

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = async (template_id) => {
        //console.log("template id", template_id);
        let data = {
            template_id: template_id
        }
        let response = await customerService.duplicateEmailTemplate(data);
        //console.log("duplicate data response", response);
        if (response.data.resCode == 202 || response.data.resCode == 200) {
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: "Record Duplicated Successfully",
                confirmButtonText: "OK",
                timer: 2000
            })
            dispatch(listTemplatesInsideFolder(id));

        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t('recordFailed'),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
        setToastShow(true);
        setTimeout(() => {
            setToastShow(false);
        }, 2000);
    }

    const { listTemplateInsideFolder } = useSelector((state: RootState) => state.emailTemplate)
    // console.log(listTemplateInsideFolder, "list");



    const handleNewPageNavigate = async () => {

        let data: any = {
            folder_id: id,
        }
        if (location.state && location.state.contract_id !== "" && location.state.contract_id !== null) {
            data = {
                folder_id: id,
                contract_id: location.state.contract_id,
            }
        }
        //console.log("data,data data", data);

        navigate(`/folder_detail`, { state: data });
    }

    const handlePreExistPageNavigate = async (template_id) => {
        let data: any = {
            folder_id: id,
            template_id: template_id,
        }
        if (location.state && location.state.contract_id !== "" && location.state.contract_id !== null) {
            data = {
                folder_id: id,
                template_id: template_id,
                contract_id: location.state.contract_id,
            }
        }
        //console.log("data,data data", data);

        navigate(`/folder_detail`, { state: data });
    }

    const today = moment().format("DD/MM/YYYY");



    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 4, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/documents_list"}><img src="../assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{listTemplateInsideFolder[1]}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                />
                                <InputGroup.Text id="search_icon">
                                    <img src="../assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        {(userJson && userJson.length > 0 &&
                            (userJson.indexOf(1091) !== -1)) &&
                            <Col xs={{ order: 1 }} xl={{ span: 5, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe">
                                        <div onClick={handleNewPageNavigate}>
                                            <img src="../assets/images/icons/plus-light.svg" alt="cloud icon" className="h-18 me-2" />
                                            {t("DocumentTemplate.newTemplate")}
                                        </div>
                                        {/* <Link to={`/folder_detail/${contract_id}`}><img src="../assets/images/icons/plus-light.svg" alt="cloud icon" className="h-18 me-2" />{t("DocumentTemplate.newTemplate")}</Link> */}
                                    </li>
                                    <li className="list-inline-item mb-md-0 mb-1">
                                        <img src="../assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                    </li>
                                </ul>
                            </Col>
                        }
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
                <div>
                    <Row className="g-lg-4 g-3 h-100">

                        {
                            listTemplateInsideFolder[0] && listTemplateInsideFolder[0].length > 0 ?
                                listTemplateInsideFolder[0].map((item) => (
                                    <Col lg={4} xl={3} md={6}>
                                        <Card className="h-100">
                                            <Card.Header className="bg-secondary bg-opacity-25 position-relative">
                                                <Badge className="bg-success-10 text-dark rounded-4 position-absolute top-0 end-0 z-index-1 me-3 mt-3">{t("DocumentTemplate.internalTemplate")}</Badge>
                                                <img src="../assets/images/temp/pdf.jpg" alt="image" style={{ 'height': '140px' }} className="w-100 object-fit-cover rounded-top-4" />
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-start align-items-start flex-column">
                                                <div className="d-flex justify-content-between align-items-start w-100 mb-lg-3 mb-2">
                                                    <h4 className="text-dark mb-0">{item.ST_NOME_MOH}</h4>
                                                    {
                                                        (userJson && userJson.length > 0 &&
                                                            (userJson.indexOf(1091) !== -1)) &&
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="link" className="flex-shrink-0 ms-md-2 ms-1 p-0 dropdown-arrow-none" >
                                                                <img src="../assets/images/icons/dots.svg" alt="Dots" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item>
                                                                    {/* <Link to={`/folder_detail/${contract_id}`} className="text-reset d-block">{t("button.open")}</Link> */}
                                                                    <a
                                                                        href="#"
                                                                        className="text-reset d-block"
                                                                        onClick={(e) => { handlePreExistPageNavigate(item.ID_MODELO_MOH) }}
                                                                    >
                                                                        {t("button.open")}
                                                                    </a>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    {/* <Link to={`/folder_detail/${contract_id}`} className="text-reset d-block">{t("button.open")}</Link> */}
                                                                    <a
                                                                        href="#"
                                                                        className="text-reset d-block"
                                                                        onClick={(e) => { commonToastShow(item.ID_MODELO_MOH) }}
                                                                    >
                                                                        {t("DocumentTemplate.duplicate")}
                                                                    </a>
                                                                </Dropdown.Item>
                                                                {/* <Dropdown.Item onClick={commonToastShow} >{t("DocumentTemplate.duplicate")}</Dropdown.Item> */}
                                                                {/* <Dropdown.Item onClick={editFolderModalShow}>{t("button.edit")}</Dropdown.Item> */}
                                                                <Dropdown.Item onClick={(e) => { moveFolderModalShow(item.ID_MODELO_MOH, item.ST_NOME_MOH) }}>{t("DocumentTemplate.moveFolders")}</Dropdown.Item>
                                                                <Dropdown.Item onClick={(e) => { renameTemplateModalShow(item.ID_MODELO_MOH, item.ST_NOME_MOH) }}>{t("DocumentTemplate.rename")}</Dropdown.Item>
                                                                <Dropdown.Item onClick={(e) => { deleteTemplateModalShow(item.ID_MODELO_MOH) }}>{t("button.delete")}</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    }
                                                </div>
                                                <div className="mt-auto w-100 text-secondary fs-12 d-flex align-items-center">
                                                    <img src="../assets/images/icons/cloud-edit.svg" alt="Help" className="h-14 me-1" />
                                                    {
                                                        item.DT_CREATED_MOH && item.ST_USUARIO_USU && item.DT_CREATED_MOH !== "" && item.ST_USUARIO_USU !== "" &&
                                                        <div className="text-truncate"> {
                                                            moment(item.DT_CREATED_MOH).format("DD/MM/YYYY") == today ?
                                                                "Today"
                                                                :
                                                                moment(item.DT_CREATED_MOH).format("DD/MM/YYYY")
                                                        } • {item.ST_USUARIO_USU}</div>
                                                    }
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <section className="content-wrapper">
                                    {/* <!-- Breadcrumb section start from here --> */}
                                    <div
                                        className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                                        <div className="w-100">
                                            <Row className="gx-2 justify-content-between align-items-center">
                                                <Col xs={{ order: 12 }} xl={{ span: 4, order: 0 }} className="mb-xl-0 mb-3">
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <Link to={"/documents_list"}><img src="../assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                                        <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                                            <h1 className="mb-0 h4 lh-base text-truncate">{t("DocumentTemplate.nameNewFolder")}</h1>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                                                    {/* Search bar */}
                                                    <InputGroup>
                                                        <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                                        />
                                                        <InputGroup.Text id="search_icon">
                                                            <img src="../assets/images/icons/search.svg" alt="Search icon" />
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={{ order: 1 }} xl={{ span: 5, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe">
                                                            <div onClick={handleNewPageNavigate}>
                                                                <img src="../assets/images/icons/plus-light.svg" alt="cloud icon" className="h-18 me-2" />
                                                                {t("DocumentTemplate.newTemplate")}
                                                            </div>
                                                            {/* <Link to={"/folder_detail"} className="text-reset"><img src="../assets/images/icons/plus-light.svg" alt="cloud icon" className="h-18 me-2" />{t("DocumentTemplate.newTemplate")}</Link> */}
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {/* <!-- /Breadcrumb section end --> */}
                                    {/* <!-- Main content start from here --> */}
                                    <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-xl-5 pb-md-4 pb-3 text-center">
                                        <img src="../assets/images/temp/empty_state.png" alt="Empty state" className="mx-auto img-fluid d-block my-lg-5 my-4" />
                                        <h6 className="fw-bold">{t("DocumentTemplate.folderEmpty")}</h6>
                                        <p className="text-secondary mb-4 pb-xl-2">{t("DocumentTemplate.folderEmptyContent")}</p>
                                        <div>
                                            <Button className="btn btn-primary" onClick={handleNewPageNavigate}>{t("DocumentTemplate.newTemplate")}</Button>
                                        </div>
                                    </main>
                                    {/* <!-- /Main content start end --> */}
                                </section>
                        }


                        {/* <Col lg={4} xl={3} md={6}>
                            <Card className="h-100">
                                <Card.Header className="bg-secondary bg-opacity-25 position-relative">
                                    <Badge className="bg-primary-20 text-dark rounded-4 position-absolute top-0 end-0 z-index-1 me-3 mt-3">{t("DocumentTemplate.kenloTemplate")}</Badge>
                                    <img src="../assets/images/temp/pdf.jpg" alt="image" style={{ 'height': '140px' }} className="w-100 object-fit-cover rounded-top-4" />
                                </Card.Header>
                                <Card.Body className="d-flex justify-content-start align-items-start flex-column">
                                    <div className="d-flex justify-content-between align-items-start w-100 mb-lg-3 mb-2">
                                        <h4 className="text-dark mb-0">Two-room house</h4>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="link" className="flex-shrink-0 ms-md-2 ms-1 p-0 dropdown-arrow-none" >
                                                <img src="../assets/images/icons/dots.svg" alt="Dots" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item><Link to={"/folder_detail"} className="text-reset d-block">{t("button.open")}</Link></Dropdown.Item>
                                                <Dropdown.Item onClick={commonToastShow} >{t("DocumentTemplate.duplicate")}</Dropdown.Item>
                                                <Dropdown.Item onClick={editFolderModalShow}>{t("button.edit")}</Dropdown.Item>
                                                <Dropdown.Item onClick={moveFolderModalShow}>{t("DocumentTemplate.moveFolders")}</Dropdown.Item>
                                                <Dropdown.Item onClick={renameTemplateModalShow}>{t("DocumentTemplate.rename")}</Dropdown.Item>
                                                <Dropdown.Item onClick={deleteTemplateModalShow}>{t("button.delete")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="mt-auto w-100 text-secondary fs-12 d-flex align-items-center">
                                        <img src="../assets/images/icons/cloud-edit.svg" alt="Help" className="h-14 me-1" />
                                        <div className="text-truncate">2 months ago • José Francisco Fran...</div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                </div>
                {/* <footer className="footer-fixed-lg bg-white border-top py-2 px-xl-5 px-md-4 px-3 mx-lg-0 mx-md-n4 mx-n3 mb-n3 mb-md-n4 mb-xl-0 mt-auto d-flex flex-wrap justify-content-lg-end">
                    <Button variant="outline-primary" className="me-lg-3 me-2" onClick={continueWithoutTemplateModalShow}>{t("DocumentTemplate.continueWithoutTemplate")}</Button>
                    <Link to={"/folder_detail"} className="btn btn-primary min-w-auto">{t("button.confirm")}</Link>
                </footer> */}
            </main>
            {/* <!-- /Main content start end --> */}

            {/* modal added */}
            <RenameTemplate showRenameTemplate={showRenameTemplateModal} renameTemplateClose={renameTemplateModalClose} currentFileId={documentFileId} currentFileName={documentFileName} currentFolderId={id} />
            <DeleteTemplate showDeleteTemplate={showDeleteTemplateModal} deleteTemplateClose={deleteTemplateModalClose} currentFileId={documentFileId} currentFolderId={id} />
            <MoveFolder showMoveFolder={showMoveFolderModal} moveFolderClose={moveFolderModalClose} currentFolderId={id} currentFileId={documentFileId} currentFileName={documentFileName} />
            <EditFolder showEditFolder={showEditFolderModal} editFolderClose={editFolderModalClose} />
            <ContinueWithoutTemplate showContinueWithoutTemplate={showContinueWithoutTemplateModal} continueWithoutTemplatelClose={continueWithoutTemplateModalClose} />
            <ToastContainer className="p-3 position-fixed z-index-2" position="bottom-end">
                <Toast bg="dark" className="rounded-4 text-white border-0" show={showToast} onClick={commonToastClose}>
                    <Toast.Body className="d-flex align-items-center p-3">
                        <span className="me-2">{t("DocumentTemplate.documentTemplateDuplicated")}</span>
                        <span className="ms-auto cursor-pe" onClick={commonToastClose}><img src="../assets/images/icons/cross-white.svg" alt="arrow right" /></span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </section>
    )
}

export default FolderListing
