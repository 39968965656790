import { AsyncThunkAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { config, gettoken } from "../../Api/Contact";
let connectionName : any = '';
if( localStorage.getItem("userData") ){
  connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
  LOADING: "loading",
  SUCEESS: "success",
  ERROR: 'error'
});

export interface Newbranch {
  branchname: string,
  corporatereason: string,
  CNPJ: string,
  municipalregistration: string,
  stateregistration: string,
  responsiblename: string,
  CPF: string,
  email: string,
  telephone: number
  zipcode: number,
  address: string,
  number: number,
  complement: string,
  neighborhood: string,
  city: string
  state: Object
}
interface AuthState {
  newBranch: any,
  branchList: Newbranch | any
  totalRow :number
  status: any,
  show: boolean,
  show1: boolean
}

const initialState: AuthState = {
  newBranch: [],
  branchList: [],
  totalRow : 0,
  status: STATUSES.LOADING,
  show: false,
  show1: false
};

const branchSlice = createSlice({
  name: "branchSlice",
  initialState,
  reducers: {
    EditBranch: (state, action) => {
      
      state.branchList = state.branchList.map((el) =>
        el.id == action.payload.branch_id ? { ...action.payload } : el
      );
      state.status = STATUSES.SUCEESS
    },
    addBranch: (state, action) => {
      state.branchList = [action.payload, ...state.branchList]
    },
    handleClo: (state, action) => {
      state.show = action.payload
    },
    handleOpn: (state, action) => {
      state.show = action.payload
    },
   
    delete_branche: (state, action) => {
      state.branchList = state.branchList.filter((el) => el.id != action.payload);
    },
    Edit_branch: (state, action) => {
      state.show1 = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBraches.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getBraches.fulfilled, (state, action: PayloadAction<any>) => {
        state.totalRow  = action.payload.data.totalRow
        state.branchList = action.payload.data.result
        state.status = STATUSES.SUCEESS
      })
      .addCase(getBraches.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(postBranch.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(postBranch.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(postBranch.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(Update_Branch.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(Update_Branch.fulfilled, (state, action: PayloadAction<any>) => {
        state.branchList = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(Update_Branch.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(Filter_branch.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(Filter_branch.fulfilled, (state, action: PayloadAction<any>) => {
        state.totalRow  = action.payload.data.totalRow
        state.branchList = action.payload.data.result
        state.status = STATUSES.SUCEESS
      })
      .addCase(Filter_branch.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(Edit_branches.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(Edit_branches.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(Edit_branches.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      });
      builder
      .addCase(delete_branch.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(delete_branch.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(delete_branch.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(branchSearching.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(branchSearching.fulfilled, (state, action: PayloadAction<any>) => {
        state.totalRow  = action.payload.data.totalRow
        state.branchList = action.payload.data.result
        state.status = STATUSES.SUCEESS
      })
      .addCase(branchSearching.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
  },
})


export const { addBranch, delete_branche, handleClo, handleOpn, Edit_branch, EditBranch } = branchSlice.actions
export default branchSlice.reducer

// GET - BRANCH LIST - API
export const getBraches = createAsyncThunk(
  'getdata/branches',
  async (obj:any) => {
    let connectionName = localStorage.getItem("userData");
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/branch/branchList`, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name' : connectionName ? connectionName : ''
      },
    });
    const data = await response.json();
    return data
  }
);

// PUT NEW BRANCH ADD API
export const postBranch = createAsyncThunk(
  'posts/updatePost',
  async (NewBrach: Newbranch) => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/branch/branchNew`, {
      method: 'PUT',
      body: JSON.stringify(NewBrach),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);


export const Edit_branches = createAsyncThunk(
  'branch/editbranch',
  async (branchId: number) => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/branch/editbranch`, {
      method: 'POST',
      body: JSON.stringify({
        "branch_id":branchId
      }),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

// POST EDIT BRANCHES -API
export const Update_Branch = createAsyncThunk(
  'get_update branch',
  async (branchData: any) => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/branch/branches_update`, {
      method: 'PUT',
      body: JSON.stringify(branchData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);
// DETELE USER BANK ACCOUNT - API
export const delete_branch = createAsyncThunk(
  'delete_Single_branch',
  async (id: number) => {
    let raw = JSON.stringify({
      "branch_id": id
    });
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/branch/branches_delete`, {
      method: 'DELETE',
      body: raw,
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);


// GET BRANCHA FILTER - API
export const Filter_branch = createAsyncThunk(
  'filter_branch',
  async (filterdata: string) => {
    var raw = JSON.stringify(filterdata);
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/all/filter`, {
      method: 'POST',
      body: raw,
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);

// API SEACHING DATA FROM BANKS 
export const branchSearching = createAsyncThunk(
  'Search_filter',
  async (searchData: string) => {     
     let bodyContent = JSON.stringify({
       search:searchData
     });
     let token:any = localStorage.getItem("tokenV2");
     let response = await fetch(`${config.companyV2_URL}/branch/search`, { 
       method: "POST",
       body: bodyContent,
       headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
     });
     let data = await response.json();
      return data
  }
);
