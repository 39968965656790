import axios from 'axios';
import { gettoken, config } from './apiConfig';
const companyV2_URL = config.companyV2_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";
const Token = gettoken.Token;
 
const dataLocalStroage = localStorage.getItem("userData");
axios.defaults.headers.common['x-auth-token']    = localStorage.getItem("tokenV2");
axios.defaults.headers.common['connection-name'] = dataLocalStroage;
export const commonService = {
    // Working
    isUserBankConfigured: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + '/user/isBankConfigured',
            method: "POST",
            data: param
        }) 
        return response.data
    },
    getmyIP() {
        return axios.get('https://localemv.com:8887/');
    },
    logOut: async () => {
        let parseData = dataLocalStroage && typeof dataLocalStroage == "string" ? JSON.parse(dataLocalStroage) : dataLocalStroage;
        // await axios.request({
        //     url: config.CUSTOMER__API + '/agency/logout/customerLogout', // http://3.220.88.200:8082
        //     method: "POST",
        //     data: parseData
        // })
        // await axios.request({
        //     url: config.TRANSFER_API + '/agency/logout/transferLogout', // http://3.220.88.200:8082
        //     method: "POST",
        //     data: parseData
        // })
        // const response = await axios.request({
        //     url: companyV2_URL + '/agency/logout/FromEverywhere', // http://3.220.88.200:8082
        //     method: "POST",
        //     data: parseData
        // })
        // let Storeresponse = response.data;
        // if (String(Storeresponse.resCode) == String(200) && (Storeresponse.status == "success" || Storeresponse.status == "Success")) {
            localStorage.clear();
            localStorage.removeItem("userData");
            localStorage.removeItem("permissionData");
            localStorage.removeItem("role");
            localStorage.removeItem("loglevel");
            localStorage.removeItem("tokenV2");
            localStorage.removeItem("reportData");
            localStorage.removeItem("language");
            
        // }
        // return response.data
    }
}  