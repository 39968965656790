import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Card, Modal, Form, Button, Alert, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { encrypt_Decrypt } from '../../../constants/utils';

const BillingMessages = () => {
    //SMS motification modal initialization
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm({});
    const [showSMSNotificationModal, setSMSNotificationModal] = useState(false);
    const [totalTextCount, setTotalTextCount] = useState(0);
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);

    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(1040) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const smsNotificationModalClose = () => {
        document.querySelector('#message_card')?.setAttribute('data-sms-id', '')
        setSMSNotificationModal(false);
        setTotalTextCount(0);
    }
    const [t] = useTranslation()
    const smsNotificationModalShow = (id) => {
        setSMSNotificationModal(true);
        document.querySelector('#message_card')?.setAttribute('data-sms-id', id)
        setValue("notify_unpain_ticked", "Here will be onload value");
        let getValueLength = getValues('notify_unpain_ticked')?.length || 0;
        setTotalTextCount(130 - getValueLength);
    };

    const handleEmailName = (id, NOME, LABEL) => {
        document.querySelector('#root')?.setAttribute('st_nome_moh', NOME);
        document.querySelector('#root')?.setAttribute('st_label_moh', LABEL);
        localStorage.setItem("YhGUFY_hygft", id);
    }

    const handleTextCount = (e) => {
        let textCount = (e.target.value).length;
        let value = 130 - textCount;
        setTotalTextCount(value);
    }

    const onSubmit = (data) => {
        let id = document.querySelector('#message_card')?.getAttribute("data-sms-id");
        let getsmsmsg = getValues('notify_unpain_ticked');

        //Api SMS integration remain 
    }
    return (
        <>
            <Card id="message_card" data-sms-id="" className='notificmcationexpiration'>
                <Card.Header>{t("billingrule.messages")}</Card.Header>
                <Card.Body>
                    <span className="d-block mb-3 fw-bold">{t("billingrule.ticket")}</span>
                    <div className="d-flex justify-content-between align-items-center mb-3 mb-lg-4">
                        {t("billingrule.notificationexpiration")}
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(1041) !== -1) &&
                            <>
                                <div className="me-2 flex-shrink-0">
                                    {/* 999999997 */}
                                    <Link to={'/message'} id="" onClick={() => handleEmailName("1000000008", "Primeira notificação de boleto", "notificação de boleto")} className="fw-bold"><img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-primary.svg"} className="h-20" alt={t("formLabel.email")} /></Link>
                                </div>
                            </>
                        }
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3 mb-lg-4">
                        {t("billingrule.notificationboletosexpiration")}
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(1041) !== -1) &&
                            <>
                                <div className="me-2 flex-shrink-0">
                                    {/* 999999998 */}
                                    <Link to={'/message'} onClick={() => handleEmailName("1000000016", "Segunda notificação de boleto", "notificação de boleto")} className="fw-bold"><img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-primary.svg"} className="h-20" alt={t("formLabel.email")} /></Link>
                                </div>
                            </>
                        }
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3 mb-lg-4">
                        {t("billingrule.notificationtextexpiration")}
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(1041) !== -1) &&
                            <>
                                <div className="me-2 flex-shrink-0">
                                    {/* 999999999 */}
                                    <Link to={'/message'} onClick={() => handleEmailName("1000000015", "Terceira notificação de boleto", "notificação de boleto")} className="fw-bold"><img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-primary.svg"} className="h-20" alt={t("formLabel.email")} /></Link>
                                </div>
                            </>
                        }
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        {t("billingrule.lastnotificationtext")}
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(1041) !== -1) &&
                            <>
                                <div className="me-2 flex-shrink-0">
                                    <Link to={'/message'} onClick={() => handleEmailName("999999972", "Ultima notificação", "Ultima notificação")} className="fw-bold"><img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-primary.svg"} className="h-20" alt={t("formLabel.email")} /></Link>
                                </div>
                            </>
                        }
                    </div> 
                </Card.Body>
            </Card>
            {/* sms notification modal start from here */}
            <Modal show={showSMSNotificationModal} onHide={smsNotificationModalClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("billingrule.SMSNotification")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="warning" className="mb-lg-4 mb-3">
                            <span className="d-block">{t("billingrule.tosendyourcustomer")}</span>
                            {t("billingrule.tosendyourvariable")}
                        </Alert>
                        <FloatingLabel className="mb-1" controlId="notification" label={t("billingrule.notificationforunpaidtickets")}>
                            <Form.Control as="textarea" maxLength={130} placeholder="Notification for unpaid tickets" {...register('notify_unpain_ticked')} onChange={(e) => handleTextCount(e)} />
                        </FloatingLabel>
                        {totalTextCount} {t("billingrule.charactersleft")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={smsNotificationModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /sms notification modal end */}
        </>
    )
}

export default BillingMessages
