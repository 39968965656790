import React from "react";

import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";

import { useBankAccountDrawer } from "../../../BankAccountDrawer";
import { Divider } from "../../../Divider";
import { AccountType } from "../../types";
import { useDefaultFields } from "./useDefaultFields";

export function DefaultFields() {
  const {
    branches,
    accountVariation,
    setAccountVariation,
    bankSearchRef,
    setIsBankSearchFocused,
    setSearchedBank,
    isBankSearchFocused,
    banksToShow,
    setAccountType,
    showHomologateField,
    setShouldHomologate,
    shouldHomologate,
    isBankNotAbleToHomologate,
    initialBalance,
    setInitialBalance,
    errors,
    defaultValues,
    register,
    handleChangeBank,
    enableEditDate,
    setEnableEditDate,
  } = useDefaultFields();
  const { bankAccountDrawerAction, bankAccount } = useBankAccountDrawer();
  const isDisabledWhenItContainsTransactions =
    bankAccountDrawerAction === "edit" &&
    bankAccount &&
    bankAccount.hasTransactions;

  return (
    <>
      <Row>
        <Col sm={6}>
          <FloatingLabel controlId="nameReference" label="Nome de referência">
            <Form.Control
              placeholder="Nome de referência"
              {...register("nameReference")}
              className={`${errors.nameReference && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.nameReference?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col sm={6}>
          {branches.length > 0 ? (
            <FloatingLabel controlId="branchtype" label="Filial">
              <Form.Select
                {...register("branchtype")}
                className={`${errors.branchtype && "is-invalid"}`}
              >
                <option disabled>Filial</option>
                {branches.length > 0 &&
                  branches.map((branch) => (
                    <option
                      key={branch.id}
                      value={branch.id}
                      defaultValue={defaultValues?.branchtype ?? branches[0].id}
                    >
                      {branch.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.branchtype?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          ) : (
            <p style={{ marginBottom: 0 }}>Buscando filiais...</p>
          )}
        </Col>
      </Row>

      <Row className="my-5">
        <Col sm={12}>
          <Divider />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm={3}>
          <Form.Label className="fw-bold">Tipo de caixa</Form.Label>
        </Col>

        <Col sm={9}>
          <Form.Check
            inline
            label="Conta bancária"
            type="radio"
            defaultChecked={accountVariation === AccountType.CORRENTE}
            id="isBankAccount"
            {...register("accountVariation")}
            onChange={() => setAccountVariation(AccountType.CORRENTE)}
            value={AccountType.CORRENTE}
            disabled={bankAccountDrawerAction === "edit"}
          />
          <Form.Check
            inline
            label="Caixa físico (Manual)"
            type="radio"
            defaultChecked={accountVariation === AccountType.MANUAL}
            id="isManualAccount"
            {...register("accountVariation")}
            onChange={() => setAccountVariation(AccountType.MANUAL)}
            value={AccountType.MANUAL}
            disabled={bankAccountDrawerAction === "edit"}
          />
        </Col>
      </Row>

      {accountVariation === AccountType.CORRENTE && (
        <>
          <Row className="mb-2">
            <Col sm={6}>
              {banksToShow.length > 0 ? (
                <InputGroup>
                  <FloatingLabel
                    controlId="bankname"
                    label="Banco"
                    ref={bankSearchRef}
                  >
                    <Form.Control
                      placeholder="Banco"
                      className={`mb-1 ${errors.bankname && "is-invalid"}`}
                      onFocus={() => setIsBankSearchFocused(true)}
                      {...register("bankname")}
                      onChange={(e) => setSearchedBank(e.target.value)}
                      disabled={isDisabledWhenItContainsTransactions}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankname?.message}
                    </Form.Control.Feedback>

                    {isBankSearchFocused && (
                      <ListGroup
                        className="shadow position-absolute z-index-5 overflow-auto"
                        style={{ maxHeight: "350px" }}
                      >
                        {banksToShow.map((bank) => (
                          <ListGroup.Item
                            key={bank.bank_code}
                            className="p-2 cursor-pe"
                            onClick={() => handleChangeBank(bank)}
                          >
                            {bank.bank_code} - {bank.bank_name}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </FloatingLabel>
                </InputGroup>
              ) : (
                <p style={{ marginBottom: 0 }}>Buscando bancos...</p>
              )}
            </Col>

            <Col sm={6}>
              <FloatingLabel controlId="accountType" label="Tipo de conta">
                <Form.Select
                  className="mb-3"
                  {...register("accountType")}
                  onChange={(e) =>
                    setAccountType(e.target.value as AccountType)
                  }
                  disabled={bankAccountDrawerAction === "edit"}
                >
                  <option disabled>Tipo de conta</option>
                  <option
                    value={AccountType.CORRENTE}
                    defaultValue={
                      defaultValues?.accountType ?? AccountType.CORRENTE
                    }
                  >
                    Corrente
                  </option>
                  <option
                    value={AccountType.INVESTIMENTO}
                    defaultValue={
                      defaultValues?.accountType ?? AccountType.INVESTIMENTO
                    }
                  >
                    Investimento
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col sm={6}>
              <FloatingLabel controlId="agency" label="Agência">
                <Form.Control
                  placeholder="Agência"
                  {...register("agency")}
                  className={`${errors.agency && "is-invalid"}`}
                  disabled={isDisabledWhenItContainsTransactions}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.agency?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>

            <Col sm={6}>
              <FloatingLabel
                controlId="account"
                label="Conta (com dígito)"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Conta (com dígito)"
                  className={`${errors.account && "is-invalid"}`}
                  {...register("account")}
                  disabled={isDisabledWhenItContainsTransactions}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.account?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
        </>
      )}

      <Row className="mb-1">
        <Col sm={12}>
          <Form.Label className="fw-bold">Balanço inicial</Form.Label>
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <FloatingLabel controlId="moneyaccount" label="Valor">
            <Form.Control
              placeholder="Valor"
              {...register("moneyaccount")}
              value={initialBalance}
              className={`${errors.moneyaccount && "is-invalid"}`}
              disabled={isDisabledWhenItContainsTransactions}
              onChange={(e) => {
                const value = e.target.value;

                const cleanedValue = value.replace(/\D/g, "");

                if (cleanedValue === "") return setInitialBalance("R$ 0,00");

                const numero = parseFloat(cleanedValue) / 100;

                const formattedValue = numero.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                });

                setInitialBalance(formattedValue);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.moneyaccount?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col sm={bankAccountDrawerAction === "new" ? 4 : 6}>
          <FloatingLabel controlId="date" label="Data abertura">
            <Form.Control
              placeholder="Data abertura"
              type="date"
              {...register("date")}
              className={`${errors.date && "is-invalid"}`}
              disabled={bankAccountDrawerAction === "edit" || !enableEditDate}
            />
            <Form.Control.Feedback type="invalid">
              {errors.date?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        {bankAccountDrawerAction === "new" && (
          <Col sm={2}>
            <Button
              variant="link"
              className="p-0 h-100"
              onClick={() => setEnableEditDate(true)}
            >
              Editar
            </Button>
          </Col>
        )}
      </Row>

      {showHomologateField && (
        <>
          <Row className="my-5">
            <Col sm={12}>
              <Divider />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={4}>
              <Form.Label className="fw-bold">Homologar conta?</Form.Label>
            </Col>

            <Col sm={6}>
              <Form.Check
                inline
                label="Sim"
                type="radio"
                defaultChecked={shouldHomologate}
                id="shouldHomologate"
                {...register("shouldHomologate")}
                onChange={() => setShouldHomologate(true)}
                value="1"
                disabled={
                  shouldHomologate && defaultValues?.shouldHomologate === "1"
                }
              />
              <Form.Check
                inline
                label="Não"
                type="radio"
                defaultChecked={!shouldHomologate}
                id="shouldNotHomologate"
                {...register("shouldHomologate")}
                onChange={() => setShouldHomologate(false)}
                value="0"
                disabled={
                  shouldHomologate && defaultValues?.shouldHomologate === "1"
                }
              />
            </Col>
          </Row>
        </>
      )}

      {isBankNotAbleToHomologate && (
        <>
          <Row className="my-5">
            <Col sm={12}>
              <Divider />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Alert variant="secondary" show={true}>
                <div className="d-flex align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/triangle-exclamation.svg`}
                    alt="alert"
                    className="me-3"
                    style={{ height: "25px", width: "25px" }}
                  />
                  <p className="mb-0">
                    Este banco ainda não esta disponível para homologação. Você
                    pode utilizá-lo junto do nosso serviço Kenlo Pay.
                  </p>
                </div>
              </Alert>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
