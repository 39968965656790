import { Form, Modal, Button, Row, Col, InputGroup, Table, Accordion, FloatingLabel, Card, Dropdown, Alert, ButtonGroup, Collapse } from "react-bootstrap";
import React, { useState, MouseEvent, useEffect, useMemo } from "react";
import { readjustClose, readjustOpen, getAllReadjustIndexs, getAllReadjustIndexs_based_on_name, getAllIndexs, getThreemonth, fetch_allFinancialIndexing } from "../../../redux/slice/expenditureDataSlice";
import { setCheckedId, getReadContractData } from "../../../redux/slice/contractDataSlice";
import { insertPostponeReadjust } from "../../../redux/slice/propertyDataSlice";
import { ComProps, postponeReadjustForm } from "../../Interface";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Loader from "../../Profile/Company/Loader";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Checkbox from "../Contracts/Common/Checkbox";
import ReadjustmentService from "../Contracts/Common/ReadjustmentService";
import Pagination from "../../Pagination";
import "../../../App.css";
import { encrypt_Decrypt } from "../../constants/utils";
import moment from "moment-timezone";
import { ClllThis } from "./Readjustment/ReadjustmentIndexes";
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const ReadjustContracts = (postponeReadjustForm) => {
  const { readContractData, readStatus } = useSelector((state: RootState) => state.contractData);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [t] = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  let newDate = new Date(dateBrazil);
  let currentMonth = moment(newDate).format("YYYY-MM");
  const { indexData, indexStatus, readjustData, readjustStatus, readjustStatus_based_on_name, readjustData_based_on_name, readjustData_threemonth } = useSelector((state: RootState) => state.expenditureData);
  const navigate = useNavigate();
  const [userJson, setUserJson] = useState<any>([]);
  const [readName, setReadName] = useState<any>([]);

  let startDt = moment(newDate).subtract(1, 'M');
  let searchDt = moment(newDate);
  let endDt = moment(newDate).add(1, 'M');
  let yearDt = moment(newDate).format("YYYY");

  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || userResult.indexOf(2030) === -1 && userResult.indexOf(2070) === -1) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
    (async () => {
      let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
      if (dataOutPut["payload"]) {
        let result = await ClllThis(dataOutPut["payload"]["data"]);
        setReadName(result);
      }
    })()
  }, [])

  useEffect(() => {
    dispatch(getAllIndexs({ "status": "", "start": 0, "end": 10 }));
  }, [dispatch])

  const [state, setState] = useState({
    contract: "",
    start: 0,
    end: 20,
    startDate: startDt,
    searchDate: searchDt,
    endDate: endDt,
    redjust: false
  });

  /*----------Pagination---------*/

  let PageSize = 20;
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (location && location?.state && location?.state !== "") {
      setState({
        ...state,
        ['start']: (currentPage > 1 ? currentPage - 1 : 0) * 20,
        ['end']: 20,
        ["redjust"]: location.state
      })
      location.state = null
      window.history.replaceState(null, '');
    } else {
      setState({
        ...state,
        ['start']: (currentPage > 1 ? currentPage - 1 : 0) * 20,
        ['end']: 20,
        ["redjust"]: false
      })
    }
  }, [currentPage])

  useEffect(() => {
    dispatch(setCheckedId([]));
    dispatch(readjustClose(false));
  }, [dispatch, state])

  useEffect(() => {
    if (readName) {
      getContractsData(state);
    }
  }, [dispatch, state, readName])

  useEffect(() => {
    dispatch(getAllReadjustIndexs_based_on_name({ collectionName: "", month: state.searchDate.format('MM/DD/YYYY') }))
    dispatch(getAllReadjustIndexs({ month: state.searchDate.format('MM/DD/YYYY'), currentMonth: currentMonth, start: 0, end: 1000 }))
    dispatch(getThreemonth({
      ...state,
      month: state.searchDate.format('MM/DD/YYYY'),
      endDate: state.endDate.format('MM/YYYY'),
      startDate: state.startDate.format('MM/YYYY')
    }));
  }, [state])

  const handleIndexClick = (e: any, id: any, name: string) => {
    e.preventDefault();
    navigate(`/ReadjustmentedIndexes/${id}`, { state: name })
  }


  const getContractsData = async (contData) => {
    contData["Readjust_index_name"] = readName;
    let response = await dispatch(getReadContractData(contData));
  };

  const searchContract = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    if (location.state) {
      location.state = null;
      window.history.replaceState(null, '');
      setState({
        ...state,
        [name]: value,
        ["redjust"]: false
      });
    } else {
      setState({
        ...state,
        [name]: value,
        ["redjust"]: false
      });
    }
  };
  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(readContractData.data.map((li: any) => li.id));
    let pushallStuff: any = [];
    readContractData && readContractData?.data &&
      readContractData.data.length > 0 &&
      readContractData.data.forEach((item: any, index: number) => {
        pushallStuff.push({ [`${item.id}`]: item })
      })
    setallCheckedItem(pushallStuff);
    if (isCheckAll) {
      setIsCheck([]);
      setallCheckedItem([])
    }
  };
  const noneHandleSelectAll = (e) => {
    setIsCheckAll(false);
    setIsCheck([]);
  };
  const [allCheckedItem, setallCheckedItem] = useState<any>([]);
  const handleClick = (e: any, item: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    setallCheckedItem([
      ...allCheckedItem,
      { [`${id}`]: item }
    ])

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
      // delete allCheckedItem[`${id}`];
      let index = -1; // Default index if not found
      allCheckedItem && allCheckedItem.length > 0 &&
        allCheckedItem.forEach((obj, i) => {
          if (Object.keys(obj)[0] === id) { // Check if the key is '12'
            index = i; // Set the index if found
          }
        });

      allCheckedItem.splice(index, 1);
      setallCheckedItem(allCheckedItem);
    }

    if (document.querySelectorAll(".check_Checkbox input").length > 0) {
      let data = document.querySelectorAll(".check_Checkbox input");
      let count_allCheck = 0;
      setTimeout(() => {
        data && data.length > 0 &&
          data.forEach((item, index) => {
            if (item["checked"]) {
              count_allCheck++;
            }
            if (!item["checked"]) {
              setIsCheckAll(false);
            }
          });
        if (count_allCheck == data.length) {
          setIsCheckAll(true);
        }
      }, 10);
    }
  };


  const readjustment = () => {
    if (isCheck.length > 0) {
      dispatch(getAllReadjustIndexs({ currentMonth: currentMonth, start: 0, end: 100 }))
      dispatch(setCheckedId(isCheck));
      dispatch(readjustOpen(true));
    } else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.Pleasecheckedthe"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000,
      });
    }
  }

  const readjustValidation = Yup.object().shape({
    postDate: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
  });
  let frm = {
    resolver: yupResolver(readjustValidation)
  }
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<postponeReadjustForm>(frm);

  const [double, setDouble] = useState(false);
  const [showNewCostCenterModal, setNewCostCenterModal] = useState(false);
  const newCostCenterClose = () => setNewCostCenterModal(false);
  const newCostCenterShow = () => {
    setNewCostCenterModal(true);
    setDouble(false);
    setValue("observation", "");
  }
  const postponeContract = async (e, id) => {
    e.preventDefault();
    setValue("id", id);
    newCostCenterShow();
  }

  const onSubmit = async (data: postponeReadjustForm, e: any) => {
    e.preventDefault();
    setDouble(true);
    let response = await dispatch(insertPostponeReadjust(data));
    if (typeof response.payload !== "undefined" && response.payload.resCode === 200) {
      setDouble(false);
      Swal.fire({
        // icon: 'success',
        title: t("Sweetalert.Success"),
        text: response.payload.message,
        confirmButtonText: "OK",
        timer: 2000
      })
      dispatch(getAllReadjustIndexs({ currentMonth: currentMonth, start: 0, end: 100 }))
      newCostCenterClose();
    }
    else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: response.payload.message,
        // icon: "error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }

  /*-------Harjinder end----------*/

  // Filters section
  const [openFilters, setFiltersOpen] = useState(false);
  const [currentSummery, setCurrentSummery] = useState({
    previous: 0,
    current: 0,
    next: 0,
    previous_date: moment().subtract(1, "month"),
    current_date: moment(),
    next_date: moment().add(1, "month"),
  })

  useEffect(() => {
    if (readjustData_threemonth && readjustData_threemonth?.["data"] && readjustData_threemonth?.["data"]?.[0]) {
      let dataResp = readjustData_threemonth["data"][0];
      setCurrentSummery({
        ...currentSummery,
        previous: dataResp.previousTotal,
        current: dataResp.todayTotal,
        next: dataResp.nextTotal,
      })
    }
  }, [readjustData_threemonth]);

  // const filterDate = (dt, tp) => {
  //   if (typeof tp !== "undefined") {
  //     let currentDate = new Date(dt);
  //     if (tp == "Subtract") {
  //       startDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
  //       searchDt = moment(startDt).add(1, 'M').format("MMM/YYYY");
  //       endDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
  //     }
  //     else {
  //       startDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
  //       searchDt = moment(startDt).add(1, 'M').format("MMM/YYYY");
  //       endDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
  //     }
  //     setState({
  //       ...state,
  //       ['startDate']: startDt,
  //       ['searchDate']: searchDt,
  //       ['endDate']: endDt
  //     })
  //   }
  // }


  // const filterDate = (dt, tp) => {
  //   let currentDate = moment(dt, "MMM/YYYY");
  //   let startDt, searchDt, endDt;

  //   if (tp === "Subtract") {
  //     currentDate.subtract(1, 'M');
  //   } else if (tp === "Add") {
  //     currentDate.add(1, 'M');
  //   }



  //   startDt = currentDate.clone().subtract(1, 'M').format("MMM/YYYY");
  //   searchDt = currentDate.format("MMM/YYYY");
  //   endDt = currentDate.clone().add(1, 'M').format("MMM/YYYY");

  //   setState({
  //     ...state,
  //     ['startDate']: startDt,
  //     ['searchDate']: searchDt,
  //     ['endDate']: endDt
  //   });


  //   dispatch(getThreemonth(state));

  //   // Update currentSummery state with MM/YYYY format for translation
  //   const previous_date = moment(searchDt, "MMM/YYYY").subtract(1, 'M').format("MMM/YYYY");
  //   const current_date = moment(searchDt, "MMM/YYYY").format("MMM/YYYY");
  //   const next_date = moment(searchDt, "MMM/YYYY").add(1, 'M').format("MMM/YYYY");

  //   setCurrentSummery({
  //     previous: currentSummery.previous,  // Update based on your specific requirements
  //     current: currentSummery.current,    // Update based on your specific requirements
  //     next: currentSummery.next,          // Update based on your specific requirements
  //     previous_date: previous_date,
  //     current_date: current_date,
  //     next_date: next_date
  //   });

  // };

  const filterDate = async (dt, tp) => {
    if (typeof tp !== "undefined") {
      let currentDate = moment(dt);
      if (tp == "Subtract") {
        startDt = moment(currentDate).subtract(1, 'M');
        searchDt = moment(startDt).add(1, 'M');
        endDt = moment(currentDate).add(1, 'M');
      }
      else {
        startDt = moment(currentDate).subtract(1, 'M');
        searchDt = moment(startDt).add(1, 'M');
        endDt = moment(currentDate).add(1, 'M');
      }



      setState({
        ...state,
        ['startDate']: startDt,
        ['searchDate']: searchDt,
        ['endDate']: endDt
      })

      let response = await dispatch(getThreemonth({
        ...state,
        month: state.searchDate.format('MM/DD/YYYY'),
        endDate: state.endDate.format('MM/YYYY'),
        startDate: state.startDate.format('MM/YYYY')
      }));

      // Update currentSummery state with MM/YYYY format for translation
      const previous_date = moment(searchDt, "MMM/YYYY").subtract(1, 'M');
      const current_date = moment(searchDt, "MMM/YYYY");
      const next_date = moment(searchDt, "MMM/YYYY").add(1, 'M');

      setCurrentSummery({
        previous: currentSummery.previous,  // Update based on your specific requirements
        current: currentSummery.current,    // Update based on your specific requirements
        next: currentSummery.next,          // Update based on your specific requirements
        previous_date: previous_date,
        current_date: current_date,
        next_date: next_date
      });
    }
  };





  return (
    <section className="content-wrapper content-wrapper-primary hReadjustContracts">
      {/* <!-- Breadcrumb section start from here -->*/}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
        <div className="w-100 mb-xl-4">
          <Row className="gx-xl-5 justify-content-start align-items-center">
            <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
              <div className="d-flex align-items-center justify-content-between me-xl-1">
                <h1 className="mb-0 lh-base text-truncate">
                  {t("readjustments")}
                </h1>
                <div className="d-xl-none">
                  <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                    {t("dropdownItem.filters")}
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/icons/filter-icon.svg"}
                      alt="Filter"
                      className="ms-1 h-12"
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
              {/* Search bar */}
              <InputGroup>
                <Form.Control onChange={searchContract} name="contract" onKeyUp={(e) => searchContract(e)} placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon" />
                <InputGroup.Text id="search_icon">
                  <img src="./assets/images/icons/search.svg" alt="Search icon" />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            {/* <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2" >
              <ul className="list-inline mb-0">                
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                </li>
                <li className="list-inline-item mb-md-0 mb-1">
                  <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                </li>
              </ul>
            </Col> */}
          </Row>
        </div>
        <Collapse className="d-xl-block collpase-filters" in={openFilters}>
          <Form id="collpase-filter-text" action="" method="">
            <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
              <Col xl="auto">
                <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                  <Button variant="primary" className="btn-arrow" onClick={() => filterDate(state.startDate, 'Subtract')}>
                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                    {/* {state.startDate} */}
                  </Button>
                  <Button variant="primary" className="btn-date capitalize" disabled>
                    {state.searchDate.format('MMM/YYYY')}
                  </Button>
                  <Button variant="primary" className="btn-arrow" onClick={() => filterDate(state.endDate, 'Add')}>
                    {/* {state.endDate} */}
                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                  </Button>
                </ButtonGroup>
              </Col>
              <Col>
              </Col>
            </Row>
          </Form>
        </Collapse>
      </div>
      {/* <!-- /Breadcrumb section end -->*/}
      {/* <!-- Main content start from here -->*/}
      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
        <div>
          <Row className="gy-3 gx-xxl-5 gx-md-4 mb-lg-4 mb-3">
            <Col>
              <Card className="h-100">
                <Card.Header>{t('contractReadjust')}</Card.Header>
                <Card.Body className="d-flex flex-column">
                  <Row className="gy-3 gx-xxl-5 gx-md-4 mb-3">
                    <Col md className="border-end border-light border-xs-none">
                      <Link to={"#"}>
                        <span className="d-block text-dark h3 mb-1 lh-1">{currentSummery.previous}</span>
                        <span className="text-secondary d-block"><h4 className="capitalize">{currentSummery.previous_date.format("MMM")}</h4></span>
                      </Link>
                    </Col>
                    <Col md className="border-end border-light border-xs-none">
                      <Link to={"#"}>
                        <span className="d-block text-dark h3 mb-1 lh-1">{currentSummery.current}</span>
                        <span className="text-secondary d-block"><h4 className="capitalize">{currentSummery.current_date.format("MMM")}</h4></span>
                      </Link>
                    </Col>
                    <Col md>
                      <Link to={"#"}>
                        <span className="d-block text-dark h3 mb-1 lh-1">{currentSummery.next}</span>
                        <span className="text-secondary d-block"><h4 className="capitalize">{currentSummery.next_date.format("MMM")}</h4></span>
                      </Link>
                    </Col>
                  </Row>
                  <span className="fw-bold text-primary d-block text-center mt-auto">
                    <Link to={"#"} onClick={readjustment}>{t('simulateReadjust')}</Link>
                  </span>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="h-100">
                <Card.Header>{t('adjustmentIndices')}</Card.Header>
                <Card.Body>
                  <Row className="gy-3 gx-xxl-5 gx-md-4">
                    {
                      // indexData && indexData.data &&
                      // indexData.data.length > 0 &&
                      // indexData.data.map((row: any, index: number) => {
                      readName &&
                        readName.length > 0 ?
                        readName.map((row: any, index: number) => {
                          let toCapital: any = (row.slug).toUpperCase();
                          let redjust = readjustData_based_on_name?.[`${toCapital}_total`];
                          let redjustValue = readjustData && readjustData?.data && readjustData?.data?.filter((item: any) => item.indexName == row.slug || (item.indexName).toLowerCase() == row.slug) || [{ indexValue: "0,00" }];
                          if (((row.slug).toLowerCase() == "igpm" || (row.slug).toLowerCase() == "ipca" || (row.slug).toLowerCase() == "inpc")) {
                            return (
                              <Col key={index} md className="border-end border-light border-xs-none">
                                <Link to={"#"} onClick={(e) => handleIndexClick(e, row.id, row.slug)}>
                                  <span className="d-block text-dark h3 mb-1 lh-1">{redjust ? Number(redjust).toFixed(2) : redjustValue?.[0]?.["indexValue"]}</span>
                                  <span className="text-secondary d-block"><h4>{row.name}</h4></span>
                                </Link>
                              </Col>
                            )
                          }
                        }) :
                        <Col className="border-end border-light border-xs-none text-center">
                          <span className="spinner-border spinner-border-sm"></span>
                          <span className="px-1" style={{ fontSize: "45px" }}>Loading...</span>
                        </Col>
                    }
                  </Row>
                  {
                    readName &&
                    readName.length > 0 &&
                    <>
                      <span className="fw-bold text-primary d-block text-center mt-3">
                        <Link to={"/readjustment_indexes"}>{t('seeAllIndices')}</Link></span>
                      {/* <Alert variant="warning" className="mb-0 text-center mt-lg-3 mt-2 lh-base h6 py-2 px-3 rounded-3">Adjustment indices are not automatically updated. <Link to={"/"}>Click here</Link> to access the index screen and update</Alert> */}
                    </>
                  }
                </Card.Body>
              </Card>
            </Col>
            {/* <Col lg={4} md={12}>
              <Card className="h-100">
                <Card.Header>Readjust status</Card.Header>
                <Card.Body className="d-flex flex-column">
                  <Row className="gy-3 gx-xxl-5 gx-md-4 mb-3">
                    <Col md className="border-end border-light border-xs-none">
                      <Link to={"#"}>
                        <span className="d-block text-dark h3 mb-1 lh-1">{currentSummery.previous}</span>
                        <span className="text-secondary d-block"><h4>Done</h4></span>
                      </Link>
                    </Col>
                    <Col md className="border-end border-light border-xs-none">
                      <Link to={"#"}>
                        <span className="d-block text-dark h3 mb-1 lh-1">{currentSummery.current}</span>
                        <span className="text-secondary d-block"><h4>Todo</h4></span>
                      </Link>
                    </Col>
                  </Row>
                  <span className="fw-bold text-primary d-block text-center mt-auto">
                    <Link to={"#"}>{t('simulateReadjust')}</Link>
                  </span>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </div>
        <Card>
          <Card.Header> {t('listOfReadjustContacts')}</Card.Header>
          <Card.Body className="pb-1">
            {/* <!-- Table start from here -->*/}
            {
              (readStatus == "loading") ?
                <Loader />
                :
                <Table
                  responsive
                  className="table table-custom table-custom-bordered mb-0"
                >
                  <thead>
                    <tr>
                      <th className="w-24">
                        {
                          readContractData.data && readContractData.data.length > 0 ? (
                            <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                          ) : (
                            ""
                          )
                        }
                      </th>
                      <th>{t("maturity")}</th>
                      <th>{t("contract")}</th>
                      <th>{t("rent")} (R$)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      readContractData && readContractData.data &&
                        readContractData.data.length > 0 ?
                        readContractData.data.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={"check_Checkbox"}>
                              <Checkbox key={index} type="checkbox" name={item.id} id={item.id} handleClick={(e) => handleClick(e, item)} isChecked={isCheck.includes(item.id)} />
                            </td>
                            <td className="text-dark-70">
                              {moment(item.expireDate).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              <span className="text-dark-70">
                                {item.type} {item.id}/{item.lease_count ? item.lease_count : "0"}
                                {/* {item.type} {item.id}/{item.propertyId} */}
                              </span>
                              <span className="mx-1">
                                <img src="assets/images/icons/home_g.svg" />
                                <span className="ps-1">
                                  {item.propertyType} in {item.address},{" "}
                                  {item.city},{item.state} - {item.zipcode}
                                </span>
                              </span>
                              <span className="d-block my-1" title="Owner">
                                {
                                  item.owners.length > 0 ? item.owners.map((items, indx) => (
                                    <Link to={"/profile_details/" + items.ID_PESSOA_PES} className="text-secondary text-nowrap me-2" key={indx} >
                                      <img src={process.env.PUBLIC_URL + "/assets/images/icons/user_g.svg"} className="me-1" />
                                      {items.ST_NOME_PES}
                                    </Link>
                                  ))
                                    : ""
                                }
                              </span>
                              <span className="d-flex flex-wrap" title="Tenant">
                                {
                                  item.tenates.length > 0 ? item.tenates.map((items, indx) => (
                                    <Link to={"/profile_details/" + items.ID_PESSOA_PES} className="text-secondary text-nowrap me-2" key={indx} >
                                      <img src={process.env.PUBLIC_URL + "/assets/images/icons/key_g.svg"} className="me-1" />
                                      {items.ST_NOME_PES}
                                    </Link>
                                  ))
                                    : ""
                                }
                              </span>
                            </td>
                            <td>
                              <span className="d-block pb-1 text-dark-70 text-nowrap">
                                R$ {item.rent_amount}
                              </span>
                              <span className="d-block text-nowrap">
                                {t("adminFee")} R$ {item.admin_rate}
                              </span>
                            </td>
                            <td className="text-end">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="d-flex align-itmes-center justify-content-end me-2 me-xxl-4 me-lg-3" onClick={(e) => postponeContract(e, item.id)} >
                                  <span className="img-primary icon cursor-pe" title={t("postponeAdjustment")}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/calendar-regular.svg"} className="h-20" />
                                  </span>
                                </div>
                                {
                                  userJson && userJson.length > 0 && (userJson.indexOf(2030) !== -1) &&
                                  <Link to={"/contract_details/" + item.id} className="fw-bold me-2 me-xxl-4 me-lg-3 text-nowrap" > {t("seeContract")}</Link>
                                }

                                {
                                  userJson && userJson.length > 0 && (userJson.indexOf(2070) !== -1) &&
                                  <Link to={"/property_detail/" + item.propertyId} className="fw-bold text-nowrap"> {t("seeProperty")} </Link>
                                }
                              </div>
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan={5}>
                            <span>{t("formLabel.noRecordsFounds")}</span>
                          </td>
                        </tr>
                    }
                  </tbody>
                </Table>
            }
            {/* <!-- /Table end -->*/}
          </Card.Body>
        </Card>
        {/* Pagination Starts */}
        <div className="d-flex justify-content-center mb-1 mt-lg-4 mt-3">
          {
            readContractData.totalRow > 0 ?
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={readContractData.totalRow > 0 ? readContractData.totalRow : 0}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
              : ''
          }
        </div>
        {/* Pagination Ends */}
        {/*{t("formLabel.moreItems")}*/}
        <p className="text-secondary d-block py-2">
          {t("formLabel.listing")} {state.start} {t("formLabel.to")} {readContractData.data && readContractData.data.length > 0 ? state.start + readContractData.data.length : state.start + 0} {t("formLabel.of")} {readContractData.totalRow > 0 ? readContractData.totalRow : 0}.
        </p>
        {/* <!-- Nav listing start from here -->*/}

        {
          userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
          <ul className="list-inline mb-0">
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
              <Dropdown>
                <Dropdown.Toggle variant="light" size="sm">
                  {t("formLabel.mark")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSelectAll} href="">
                    {t("formLabel.all")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={noneHandleSelectAll} href="">
                    {t("formLabel.none")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
              <Button variant="light" size="sm" onClick={readjustment}>
                {t("button.reajust")}
              </Button>
            </li>
          </ul>
        }
        {/*  <!-- /Nav listing  end -->*/}

        {/* <!-- /List of contracts end -->*/}
      </main>
      <ReadjustmentService allCheckedItem={allCheckedItem} />

      <Modal show={showNewCostCenterModal} className="hReadjustContractsPopup" onHide={newCostCenterClose} >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("postponeAdjustment")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input type="hidden" {...register('id')} />
            <FloatingLabel controlId="name" className="mb-3 mb-lg-4" label={t("postponeAdjustmentFor")}>
              <Form.Control type="date" placeholder={t("postponeAdjustmentFor")} className={`form-control ${errors.postDate ? 'is-invalid' : ''}`} {...register('postDate')} />
              <Form.Control.Feedback type="invalid">{errors.postDate?.message?.toString()}</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel label={t("observation")} >
              <Form.Control rows={3} as="textarea" placeholder={t("observation")} {...register('observation')} style={{ height: '100px' }} />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={newCostCenterClose}>
              {t("button.close")}
            </Button>
            <Button disabled={double} type="submit" variant="primary" >
              {t("button.save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};
export default ReadjustContracts;