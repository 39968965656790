import { config } from "../../../../services/apiConfig";
import { getLocalStorageItem } from "../../shared/utils/getLocalStorageItem";

export async function getAllOwners(params = { owner: null, start: 0, end: 1000 }) {
  const token = getLocalStorageItem("tokenV2");

  const response = await fetch(
    `${config.customerV2}/v2/contract/allOwners`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    }
  );

  const data = await response.json();

  return data;
}


