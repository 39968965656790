import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation }           from 'react-i18next';
const ChecklistSettings = (props) => {
    //Checklist settings modal close open function
    const [t]                  = useTranslation();
    const { showChecklistSetting, checklistSettingClose } = props;
    return (
        <>
            <Modal show={showChecklistSetting} onHide={checklistSettingClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("formLabel.settings")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <div className="d-flex align-items-center justify-content-start">
                       <span className="fw-bold me-2 me-lg-3 me-xl-4">{t("checklist")}</span>
                        <Button variant="light" size="sm">{t("checklistother.editemailtemplate")}</Button>
                       </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={checklistSettingClose}>
                        {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={checklistSettingClose}>
                        {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default ChecklistSettings
