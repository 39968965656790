import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import { getBankList } from "../../../redux/slice/bankSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { useVerifyApprovedCity } from "../../../services/hooks";
import { addNewBillingMethod } from "../../features/Billing/services/addNewBillingMethod";
import { getAllBillingMethods } from "../../features/Billing/services/getAllBillingMethods";
import { useBankAccountDrawer } from "../BankAccountDrawer";
import { useBankAccount } from "./BankAccountProvider";
import { createHomologateAgency } from "./services/createHomologateAgency";
import { createHomologateBank } from "./services/createHomologateBank";
import { createNewBankAccount } from "./services/createNewBankAccount";
import { getCompanyInfos } from "./services/getCompanyInfos";
import { getHomologateAgency } from "./services/getHomologateAgency";
import { updateBankAccount } from "./services/updateBankAccount";
import { updateHomologateBankAccount } from "./services/updateHomologateBankAccount";
import { AccountType } from "./types";
import { convertCurrencyToFloat } from "./utils/convertCurrencyToFloat";

type CompanyInfos = {
  address: string;
  city: string;
  cnpj: string;
  company_name: string;
  complement: string;
  email: string;
  fantasy_name: string;
  neighborhood: string;
  number: string;
  state: string;
  telephone: string;
  zipcode: string;
};

type BillingMethod = {
  id: number;
  name: string;
};

export function useBankAccountForm() {
  const [companyInfos, setCompanyInfos] = useState<CompanyInfos>(
    {} as CompanyInfos
  );
  const [isAgencyAlreadyCreated, setIsAgencyAlreadyCreated] = useState(false);
  const [isBankAlreadyCreated, setIsBankAlreadyCreated] = useState(false);
  const [recentlyCreatedBank, setRecentlyCreatedBank] = useState<any>({});
  const { reset } = useFormContext();
  const { setShowBankAccountDrawer, bankAccountDrawerAction, bankAccount } =
    useBankAccountDrawer();
  const {
    accountVariation,
    shouldHomologate,
    accountType,
    selectedBank,
    resetContextValues,
  } = useBankAccount();
  const { getCityCode } = useVerifyApprovedCity();
  const { userbanklist } = useSelector((state: RootState) => state.bank);
  const dispatch = useDispatch<AppDispatch>();
  const [allBillingMethods, setAllBillingMethods] = useState<
    Array<BillingMethod>
  >([]);

  useEffect(() => {
    getCompanyInfos().then((data) => setCompanyInfos(data));
    getHomologateAgency().then((data) => setIsAgencyAlreadyCreated(data));
    getAllBillingMethods().then((data) => setAllBillingMethods(data));
  }, []);

  async function onSubmit(data) {
    const {
      account,
      agency,
      bankname,
      branchtype,
      date,
      moneyaccount,
      nameReference,
      ContaCodigoBeneficiario,
      ConvenioNumero,
      ConvenioDescricao,
      ConvenioCarteira,
      ConvenioPadraoCNAB,
      ConvenioNumeroContrato,
      carteira_codigo,
      ConvenioVersaoLayoutArquivo,
      ContaCodigoEmpresa,
    } = data;

    const bankAccountPayload = {
      account: account ?? "",
      accountType:
        accountVariation === AccountType.MANUAL
          ? accountVariation
          : accountType,
      agency: agency ?? "",
      bankname: bankname?.split(" ")[0] ?? "",
      branchtype,
      date,
      documentNo: companyInfos.cnpj.replace(/[.-]/g, ""),
      moneyaccount: convertCurrencyToFloat(moneyaccount),
      nameReference,
      nameinBank: nameReference,
      user_id: 1,
    };

    const homologateAgencyPayload = {
      CedenteRazaoSocial: companyInfos.company_name,
      CedenteNomeFantasia:
        companyInfos.fantasy_name || companyInfos.company_name,
      CedenteCPFCNPJ: companyInfos.cnpj.replace(/[.\-/]/g, ""),
      CedenteEnderecoLogradouro: companyInfos.address,
      CedenteEnderecoNumero: companyInfos.number,
      CedenteEnderecoComplemento: companyInfos.complement ?? "",
      CedenteEnderecoBairro: companyInfos.neighborhood,
      CedenteEnderecoCEP: companyInfos.zipcode.replace("-", ""),
      CedenteEnderecoCidadeIBGE: getCityCode(
        companyInfos.city,
        companyInfos.state
      ).toString(),
      CedenteTelefone: companyInfos.telephone,
      CedenteEmail: companyInfos.email,
    };

    const homologateBankPayload = {
      ContaCodigoBanco: bankname?.split(" ")[0]?.padStart(3, "0"),
      ContaAgencia: agency?.split("-")[0],
      ContaAgenciaDV: agency?.split("-")[1] ?? "",
      ContaNumero: account?.split("-")[0],
      ContaNumeroDV: account?.split("-")[1] ?? "",
      ContaTipo: "CORRENTE",
      ContaCodigoBeneficiario: ContaCodigoBeneficiario?.split("-")[0],
      ConvenioNumero,
      ConvenioDescricao,
      ConvenioCarteira,
      ConvenioEspecie: "R$",
      ConvenioPadraoCNAB,
      ...(ConvenioNumeroContrato && { ConvenioNumeroContrato }),
      ...(carteira_codigo && { carteira_codigo }),
      ...(ConvenioVersaoLayoutArquivo && { ConvenioVersaoLayoutArquivo }),
      ...(ConvenioNumeroContrato && { ConvenioNumeroContrato }),
      ...(ContaCodigoEmpresa && { ContaCodigoEmpresa }),
    };

    const recentlyCreatedBankId = userbanklist.find(
      (bank) => bank.nameReference === recentlyCreatedBank.nameReference
    )?.id;

    const isHomologatedBillingMethodAlreadyCreated = allBillingMethods.find(
      (method) => method.name === "Boleto - Conta Homologada"
    );

    const bankActionToPerformAtKenlo =
      isBankAlreadyCreated || bankAccountDrawerAction === "edit"
        ? updateBankAccount
        : createNewBankAccount;

    const bankActionToPerformAtHomologate = bankAccount?.isHomologated
      ? updateHomologateBankAccount
      : createHomologateBank;

    const successMessageAtKenlo =
      bankAccountDrawerAction === "edit"
        ? "Conta bancária editada com sucesso!"
        : "Conta bancária criada com sucesso!";

    const successMessageAtHomologate =
      bankAccountDrawerAction === "edit"
        ? "Conta bancária editada e homologada com sucesso!"
        : "Conta bancária criada e homologada com sucesso!";

    const errorMessageAtKenlo =
      bankAccountDrawerAction === "edit"
        ? "Erro na atualização da conta bancária"
        : "Erro na criação da conta bancária";

    await bankActionToPerformAtKenlo({
      ...bankAccountPayload,
      id: bankAccount?.id || recentlyCreatedBankId,
    })
      .then(async () => {
        setIsBankAlreadyCreated(true);
        setRecentlyCreatedBank(bankAccountPayload);

        dispatch(
          getBankList({
            start: "0",
            end: "100",
          })
        );

        if (accountVariation === AccountType.MANUAL) {
          Swal.fire({
            title: "Sucesso!",
            text: successMessageAtKenlo,
          }).then(() => {
            reset();
            resetContextValues();
            setShowBankAccountDrawer({
              action: undefined,
              bankAccount: undefined,
            });
          });

          return;
        }

        if (accountType === AccountType.INVESTIMENTO) {
          Swal.fire({
            title: "Sucesso!",
            text: successMessageAtKenlo,
          }).then(() => {
            reset();
            resetContextValues();
            setShowBankAccountDrawer({
              action: undefined,
              bankAccount: undefined,
            });
          });

          return;
        }

        if (!shouldHomologate) {
          Swal.fire({
            title: "Sucesso!",
            text: successMessageAtKenlo,
          }).then(() => {
            reset();
            resetContextValues();
            setShowBankAccountDrawer({
              action: undefined,
              bankAccount: undefined,
            });
          });

          return;
        }

        if (isAgencyAlreadyCreated) {
          await bankActionToPerformAtHomologate({
            ...homologateBankPayload,
            id: bankAccount?.homologatedAccount?.id,
          })
            .then(() => {
              if (!isHomologatedBillingMethodAlreadyCreated)
                addNewBillingMethod({
                  billingMethod: "Boleto - Conta Homologada",
                });

              Swal.fire({
                title: "Sucesso!",
                text: successMessageAtHomologate,
              }).then(() => {
                reset();
                resetContextValues();
                setShowBankAccountDrawer({
                  action: undefined,
                  bankAccount: undefined,
                });
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "Erro na homologação da conta bancária",
                text: error.message,
                confirmButtonText: "Corrigir",
              });
            });

          return;
        }

        await createHomologateAgency(homologateAgencyPayload)
          .then(() => {
            bankActionToPerformAtHomologate({
              ...homologateBankPayload,
              id: bankAccount?.homologatedAccount?.id,
            })
              .then(() => {
                if (!isHomologatedBillingMethodAlreadyCreated)
                  addNewBillingMethod({
                    billingMethod: "Boleto - Conta Homologada",
                  });

                Swal.fire({
                  title: "Sucesso!",
                  text: successMessageAtHomologate,
                }).then(() => {
                  reset();
                  resetContextValues();
                  setShowBankAccountDrawer({
                    action: undefined,
                    bankAccount: undefined,
                  });
                });
              })
              .catch((error) => {
                Swal.fire({
                  title: "Erro na homologação da conta bancária",
                  text: error.message,
                  confirmButtonText: "Corrigir",
                });
              });
          })
          .catch((error) => {
            Swal.fire({
              title: "Erro na homologação da Imobiliária",
              html: `
                <p>${error.message}/p>
                <strong>Contate o suporte</strong>
              `,
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "Fechar",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: errorMessageAtKenlo,
          text: error.message,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Fechar",
        });
      });
  }

  const showHomologateFields =
    accountVariation === AccountType.CORRENTE &&
    accountType === AccountType.CORRENTE &&
    shouldHomologate;

  return {
    onSubmit,
    selectedBank,
    showHomologateFields,
  };
}
