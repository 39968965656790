import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import customerService from '../../../../services/customerService';
import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import { AppDispatch } from '../../../../redux/store';
import { listTemplate } from '../../../../redux/slice/SendEmailSlice';


const RenameFolder = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    //Rename folder modal close open function
    const { showRenameFolder, renameFolderClose, folderId, folderName } = props;

    const [folderNameInput, setFolderNameInput] = useState<any>();

    useEffect(() => {
        setFolderNameInput(folderName)
    }, [folderName]);


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        let data = {
            folder_id: folderId,
            folder_name: folderNameInput
        }
        if (data.folder_name !== "" && data.folder_name !== null) {
            const response = await customerService.renameDocumentFolder(data);
            if (response.data.resCode == 200 || response.data.resCode == 201) {
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: "Folder Renamed",
                    confirmButtonText: "OK",
                    timer: 2000
                })
                await dispatch(listTemplate())

                renameFolderClose();

            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: "There is some issue in renaming the folder",
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Folder Name Cannot Be Empty",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }

    };

    return (
        <>
            <Modal show={showRenameFolder} onHide={renameFolderClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.enterFolderName")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="folder_name" label={t("DocumentTemplate.folderName")}>
                            <Form.Control type="text" placeholder={t("DocumentTemplate.folderName")} value={folderNameInput} onChange={(e) => setFolderNameInput(e.target.value)} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={renameFolderClose}>
                            {t("button.cancel")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {t("button.confirm")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default RenameFolder
