import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  Card,
  Dropdown,
  Toast,
  ToastContainer,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditWatermark from "./EditWatermark";
import NewFolder from "./NewFolder";
import RenameFolder from "./RenameFolder";
import DeleteFolder from "./DeleteFolder";
import ContinueWithoutTemplate from "./ContinueWithoutTemplate";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { listTemplate } from "../../../../redux/slice/SendEmailSlice";
import { encrypt_Decrypt, encrypt_Decrypt_Plan } from "../../../constants/utils";

const DocumentsList = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch<AppDispatch>()
  //Edit watermark modal close open function
  const [showEditWatermarkModal, setEditWatermarkModal] = useState(false);
  const [randomNumber, setRandomNumber] = useState('');
  const [folderId, setFolderId] = useState<any>("");
  const [folderName, setFolderName] = useState<any>("");
  const navigate = useNavigate();
  const location = useLocation();

  const numbers: any = [1, 2, 3, 4, 5, 6, 7];
  // Function to generate a random number
  const generateRandomNumber = () => {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    setRandomNumber(numbers[randomIndex]);
  };

  const editWatermarkModalClose = () => setEditWatermarkModal(false);
  const editWatermarkModalShow = () => setEditWatermarkModal(true);

  //New folder modal close open function
  const [showNewFolderModal, setNewFolderModal] = useState(false);
  const newFolderModalClose = () => setNewFolderModal(false);
  const newFolderModalShow = () => setNewFolderModal(true);

  //Rename folder modal close open function
  const [showRenameFolderModal, setRenameFolderModal] = useState(false);
  const renameFolderModalClose = () => setRenameFolderModal(false);

  const renameFolderModalShow = async (folder_id, folder_name) => {
    await setFolderId(folder_id);
    await setFolderName(folder_name);
    await setRenameFolderModal(true);
  }

  //Delete folder modal close open function
  const [showDeleteFolderModal, setDeleteFolderModal] = useState(false);
  const deleteFolderModalClose = () => setDeleteFolderModal(false);
  const deleteFolderModalShow = async (folder_id) => {
    await setFolderId(folder_id);
    setDeleteFolderModal(true);
  }

  const [userPlanString, setUserPlanString] = useState("");
  const [userJson, setUserJson] = useState<any>([]);

  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || (userResult.indexOf(1090) === -1)) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
  }, [])

  useEffect(() => {
    let userPLAN = encrypt_Decrypt_Plan();
    // setUserPlanString(userPLAN)
    setUserPlanString("300")
  }, [location])

  //Continue without template detail modal close open function
  const [showContinueWithoutTemplateModal, setContinueWithoutTemplateModal] = useState(false);
  const continueWithoutTemplateModalClose = () => setContinueWithoutTemplateModal(false);
  const continueWithoutTemplateModalShow = () => setContinueWithoutTemplateModal(true);

  //Common toast
  const [showToast, setToastShow] = useState(false);
  const commonToastClose = () => setToastShow(false);
  const commonToastShow = () => {
    setToastShow(true);
    setTimeout(() => {
      setToastShow(false);
    }, 2000);
  };

  useEffect(() => {
    generateRandomNumber();
    dispatch(listTemplate())
  }, [dispatch])

  const { listTemplatefolder } = useSelector((state: RootState) => state.emailTemplate)
  const handleOpenClick = async (folder_id) => {
    let data: any = {
      folder_id: folder_id,
    }
    if (location.state && location.state.contract_id !== "" && location.state.contract_id !== null) {
      data = {
        folder_id: folder_id,
        contract_id: location.state.contract_id
      }
    }
    navigate(`/folder_listing/${folder_id}`, { state: data });
  }

  return (
    <section className="content-wrapper">
      {/* <!-- Breadcrumb section start from here --> */}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
        <div className="w-100">
          <Row className="gx-2 justify-content-between align-items-center">
            <Col
              xs={{ order: 12 }}
              xl={{ span: 4, order: 0 }}
              className="mb-xl-0 mb-3"
            >
              <h1 className="mb-0 lh-base text-truncate">{t("DocumentTemplate.documentTemplates")}</h1>
            </Col>
            <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
              {/* Search bar */}
              <InputGroup>
                <Form.Control
                  placeholder={t("formLabel.typeSearch")}
                  aria-label="Search"
                  aria-describedby="search_icon"
                />
                <InputGroup.Text id="search_icon">
                  <img
                    src="assets/images/icons/search.svg"
                    alt="Search icon"
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            {
              (userJson && userJson.length > 0 &&
                (userJson.indexOf(1091) !== -1)) &&
              <Col
                xs={{ order: 1 }}
                xl={{ span: 5, order: 3 }}
                className="text-xl-end mb-xl-0 mb-2"
              >
                <ul className="list-inline mb-0">
                  {
                    userPlanString !== "100" && userPlanString !== "50" &&
                    <>
                      <li
                        className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe"
                        onClick={editWatermarkModalShow}
                      >
                        <img
                          src="assets/images/icons/edit-primary.svg"
                          alt="Edit"
                          className="h-18 me-2 cursor-pe"
                        />
                        {t("DocumentTemplate.editWatermark")}
                      </li>
                      <li
                        className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe"
                        onClick={newFolderModalShow}
                      >
                        <img
                          src="assets/images/icons/plus-light.svg"
                          alt="cloud icon"
                          className="h-18 me-2"
                        />
                        {t("DocumentTemplate.newFolder")}
                      </li>
                    </>
                  }
                </ul>
              </Col>
            }
          </Row>
        </div>
      </div>
      {/* <!-- /Breadcrumb section end --> */}
      {/* <!-- Main content start from here --> */}
      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
        <Row className="g-3">

          {
            listTemplatefolder && listTemplatefolder.length > 0 ?
              listTemplatefolder.map((item: any) => {
                if ((item.ST_NOME_TEH).toLowerCase() !== "lease agreement" && (item.ST_NOME_TEH).toLowerCase() !== "agreement" && (item.ST_NOME_TEH).toLowerCase() !== "agreements" && (item.ST_NOME_TEH).toLowerCase() !== "lease agreements" && (item.ST_NOME_TEH).toLowerCase() !== "contrato de arrendamento" && (item.ST_NOME_TEH).toLowerCase() !== "contrato" && (item.ST_NOME_TEH).toLowerCase() !== "contratos" && (item.ST_NOME_TEH).toLowerCase() !== "contratos de arrendamento"
                ) {
                  return (
                    <Col lg={4} md={6}>
                      <Card>
                        <Card.Body className="d-flex justify-content-start align-items-start">
                          <img
                            src={`assets/images/temp/documents/${randomNumber}.png`}
                            alt="Icon"
                            className="flex-shrink-0 me-md-3 me-2"
                          />
                          <div className="w-100 overflow-hidden">
                            <h4 className="mb-1 text-dark text-truncate" onClick={(e) => { handleOpenClick(item.ID_TEMPLATE_TEH) }}>
                              {item.ST_NOME_TEH}
                            </h4>
                            <span className="d-block text-secondary">{item.count} {t("DocumentTemplate.templates")}</span>
                          </div>
                          {
                            (userJson && userJson.length > 0 &&
                              (userJson.indexOf(1091) !== -1)) &&
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="link"
                                className="flex-shrink-0 ms-md-2 ms-1 mt-1 p-0 dropdown-arrow-none"
                              >
                                <img src="assets/images/icons/dots.svg" alt="Dots" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <Button
                                    type="button"
                                    className="btn btn-link text-reset d-block p-0 m-0 border-0 bg-transparent"
                                    onClick={(e) => { handleOpenClick(item.ID_TEMPLATE_TEH) }}
                                  >
                                    {t("button.open")}
                                  </Button>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={commonToastShow}>
                                  {t("DocumentTemplate.duplicate")}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => { renameFolderModalShow(item.ID_TEMPLATE_TEH, item.ST_NOME_TEH) }}>
                                  {t("DocumentTemplate.rename")}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => { deleteFolderModalShow(item.ID_TEMPLATE_TEH) }}>
                                  {t("button.delete")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          }
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                } else {
                  if (userPlanString !== "50" && userPlanString !== "100") {
                    return (
                      <Col lg={4} md={6}>
                        <Card>
                          <Card.Body className="d-flex justify-content-start align-items-start">
                            <img
                              src={`assets/images/temp/documents/${randomNumber}.png`}
                              alt="Icon"
                              className="flex-shrink-0 me-md-3 me-2"
                            />
                            <div className="w-100 overflow-hidden">
                              <h4 className="mb-1 text-dark text-truncate" onClick={(e) => { handleOpenClick(item.ID_TEMPLATE_TEH) }}>
                                {item.ST_NOME_TEH}
                              </h4>
                              <span className="d-block text-secondary">{item.count} {t("DocumentTemplate.templates")}</span>
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="link"
                                className="flex-shrink-0 ms-md-2 ms-1 mt-1 p-0 dropdown-arrow-none"
                              >
                                <img src="assets/images/icons/dots.svg" alt="Dots" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <Button
                                    type="button"
                                    className="btn btn-link text-reset d-block p-0 m-0 border-0 bg-transparent"
                                    onClick={(e) => { handleOpenClick(item.ID_TEMPLATE_TEH) }}
                                  >
                                    {t("button.open")}
                                  </Button>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={commonToastShow}>
                                  {t("DocumentTemplate.duplicate")}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => { renameFolderModalShow(item.ID_TEMPLATE_TEH, item.ST_NOME_TEH) }}>
                                  {t("DocumentTemplate.rename")}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => { deleteFolderModalShow(item.ID_TEMPLATE_TEH) }}>
                                  {t("button.delete")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  }
                }
              })
              :
              <p className="text-center h2">No Records Found</p>
          }
        </Row> 
      </main>
      {/* <!-- /Main content start end --> */}
      <EditWatermark
        showEditWatermark={showEditWatermarkModal}
        editWatermarkClose={editWatermarkModalClose}
      />
      <NewFolder
        showNewFolder={showNewFolderModal}
        newFolderClose={newFolderModalClose}
      />
      <RenameFolder
        showRenameFolder={showRenameFolderModal}
        renameFolderClose={renameFolderModalClose}
        folderId={folderId}
        folderName={folderName}
      />
      <DeleteFolder
        showDeleteFolder={showDeleteFolderModal}
        deleteFolderClose={deleteFolderModalClose}
        folderId={folderId}
      />
      <ContinueWithoutTemplate
        showContinueWithoutTemplate={showContinueWithoutTemplateModal}
        continueWithoutTemplatelClose={continueWithoutTemplateModalClose}
      />
      <ToastContainer
        className="p-3 position-fixed z-index-2"
        position="bottom-end"
      >
        <Toast
          bg="dark"
          className="rounded-4 text-white border-0"
          show={showToast}
          onClick={commonToastClose}
        >
          <Toast.Body className="d-flex align-items-center p-3">
            <span className="me-2">{t("DocumentTemplate.folderDuplicated")}</span>
            <span className="ms-auto cursor-pe" onClick={commonToastClose}>
              <img
                src="assets/images/icons/cross-white.svg"
                alt="arrow right"
              />
            </span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </section>
  );
};

export default DocumentsList;
