import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
const CommonToast = (props) => {
     //Custom toast close open function
     const { showCommonToast, commonToastClose } = props;
    return (
        <>
            <ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
                <Toast bg="dark" className="rounded-4 text-white border-0" show={showCommonToast} onClick={commonToastClose}>
                    <Toast.Body className="d-flex align-items-center p-3">
                        <img className="me-2 me-xxl-4 me-xl-3" src="../assets/images/icons/check-circle-success.svg" alt="arrow right" />
                        <span className="me-2">Item updated.</span>
                        <span className="ms-auto cursor-pe" onClick={commonToastClose}><img src="../assets/images/icons/cross-white.svg" alt="arrow right" /></span>
                    </Toast.Body>
                </Toast>
            </ToastContainer> 
        </>
    )
}
export default CommonToast