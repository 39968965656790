import { Form, Button, Row, Col, FloatingLabel, InputGroup, Offcanvas, OverlayTrigger, Tooltip, FormControl, ListGroup } from 'react-bootstrap';
import { condosOpen, brokerOpen, ownerOpen, administratorOpen, propertyClose, contractOpen, insertProperty, getProperty, getAllProperty, managerOpen, contractClose, propertyOpen } from "../../../../redux/slice/contractDataSlice";
import { getManager, getAdministrators, getCondos, getBroker, getOwner } from "../../../../redux/slice/propertyDataSlice";
import { registerDatacomapny } from '../../../../redux/slice/companyDataSlice';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { contractPropertyForm } from '../../../Interface';
import Swal from 'sweetalert2';
import AdministratorService from './AdministratorService';
import CondosService from './CondosService';
import OwnerService from './OwnerService';
import BrokerService from './BrokerService';
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import customerService from '../../../../services/customerService';
import '../../../../App.css';
import { convertToNumber, formatNumber, formatNumberEnglish, generateRandomString } from '../../../constants/utils';
import moment from "moment-timezone";
import ManagerService from '../../Contracts/Common/ManagerService';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const PropertyService = (contractPropertyForm, props) => {
    const { managerData, administData, condoData, brokerData, ownerData, } = useSelector((state: RootState) => state.propertyData)
    const { id } = useParams();
    const [isMonthlyAdminRatePercentage, setMonthlyAdminRatePercentage] = useState(false);
    const [isLeaseFeePercentage, setLeaseFeePercentage] = useState(false);
    const [fixedday, setfixeday] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false);
    const [double, setDouble] = useState(false);
    const [loader_on, setloader_on] = useState(false);
    const [inputdisb, setInputdisb] = useState(true);
    const [gurnty, setGurnty] = useState(true);
    const [admtype, setAdmType] = useState('')
    const { newPropertyShow } = useSelector((state: RootState) => state.contractData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    const dispatch = useDispatch<AppDispatch>();
    const [t] = useTranslation();
    const inputRef = useRef<any>(null);
    // Inside your component
    const [hasIdentifierGenerated, setHasIdentifierGenerated] = useState(false);

    const [state, setState] = useState({
        property: "",
        status: "",
        branch: "",
        city: "",
        tenants: "",
        neighborhood: "",
        administrator: "",
        manager: "",
        owner_type: "",
        condominium: "",
        business_type: "",
        property_type: "",
        real_estate: "",
        start: 0,
        end: 20
    })
    const newPropertyClose = () => {
        setHasIdentifierGenerated(false);
        dispatch(propertyClose(false))
        dispatch(contractOpen(true));
        setDouble(false);
        setloader_on(false);
        setTnsfr(false);
        reset();
        setMultipleOwner([{
            value: "",
            label: "",
            main: "-1",
            percentage: "100",
            focus: false
        }])
        setIsInputFocused([{ focus: false }])
        setInputdisb(true)

    }

    const newOwnerOpen = () => {
        dispatch(ownerOpen(true))
        dispatch(propertyClose(false))
        setDisplay(false)
        isInputFocused[0]["focus"] = false
    }

    const newBrokerOpen = () => {
        dispatch(brokerOpen(true))
        dispatch(propertyClose(false))
    }

    useEffect(() => {
        if (newPropertyShow) {
            dispatch(getManager());
            dispatch(getAdministrators())
            dispatch(getCondos())
            dispatch(getBroker())
            setTimeout(() => {
                dispatch(getOwner())
            }, 2000)

        }
    }, [dispatch, newPropertyShow])

    useEffect(() => {
        dispatch(getAllProperty(state));
    }, [dispatch, state])
    const [multipleOwner, setMultipleOwner] = useState<any>([
        {
            value: "",
            label: "",
            main: "-1",
            percentage: "100",
            focus: false
        }
    ])
    const [multipleHobby, setmMultipleHobby] = useState([{
        huby_identification: "",
        huby_observation: "",
    }])
    const propertyValidation = Yup.object().shape({
        rent_amount: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        monthly_admin_rate: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        property_type: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        zipcode: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        address: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')).max(40, t("requiredMmessage.40characters")),
        number: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        neighborhood: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        city: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        state: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        property_identifier: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),

        // owner: Yup.array().of(
        //     Yup.object().shape({
        //         name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //         main_owner: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
        //         percentage_on_receipt: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
        //     })
        // ).nullable().required(t("requiredMmessage.fieldRequired")),
    });


    // const validationOwner = () => {
    //     let vaid = true;
    //     multipleOwner.forEach((item, indexs) => {
    //         let name = `owner.${indexs}.name`;
    //         let percent = `owner.${indexs}.percentage_on_receipt`;
    //         let ownerBene: any = document.querySelector('.ownersAndBeneficiariestsx  button');
    //         let ownerSelect: any = document.querySelector('.owner [name="' + name + '"]');
    //         let percentSelect: any = document.querySelector('.percentage [name="' + percent + '"]');
    //         if (!item.value && multipleOwner.length == 1 && ownerSelect != null) {
    //             ownerSelect.style.border = "1px solid red";
    //             ownerBene.style.color = "red";
    //             vaid = false;
    //         }
    //         else if (!item.value && multipleOwner.length > 1 && ownerSelect != null) {
    //             ownerSelect.style.border = "1px solid red";
    //             ownerBene.style.color = "red";
    //             vaid = false;
    //         }
    //         else if (!item.percentage && multipleOwner.length > 1 && percentSelect != null) {
    //             percentSelect.style.border = "1px solid red";
    //             ownerBene.style.color = "red";
    //             vaid = false;
    //         }
    //         else {
    //             if (percentSelect != null) {
    //                 percentSelect.style.border = "0px solid red";
    //                 if (ownerBene != null) {
    //                     ownerBene.style.color = "";
    //                 }
    //             }
    //             if (ownerSelect != null) {
    //                 if (ownerBene != null) {
    //                     ownerBene.style.color = "";
    //                 }
    //                 ownerSelect.style.border = "0px solid red";
    //             }

    //         }
    //     })
    //     return vaid;
    // }

    const validationOwner = () => {
        let vaid = true;
        multipleOwner.forEach((item: any, indexs) => {
            let name: any = `owner.${indexs}.name`;
            let percent: any = `owner.${indexs}.percentage_on_receipt`;
            let mainOwner: any = `owner.${indexs}.main_owner`;

            let ownerBene: any = document.querySelector('.ownersAndBeneficiariestsx  button');
            if (!item.value && multipleOwner.length >= 1) {
                setError(name, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }
            if (!item.percentage && multipleOwner.length >= 1) {
                setError(percent, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }
            if (!item.main && multipleOwner.length >= 1) {
                setError(mainOwner, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }
        })
        return vaid;
    }

    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<contractPropertyForm>({ resolver: yupResolver(propertyValidation) });

    const [percentornot, setPercentornot] = useState({
        lease_percent: true,
        monthly_percent: true
    })

    const handleCheckboxChange = (e, setState) => {
        const isChecked = e.target.checked;
        const nameTarget = e.target.name;
        let pickValue: any = e.target.parentElement.parentElement.parentElement.parentElement;
        let valueofTarget: any = "";
        if (nameTarget == "monthly_admin_rate_percentage") {
            valueofTarget = pickValue.querySelector('[name=monthly_admin_rate]');
            if (!isChecked) {
                if (Number(valueofTarget.value) > 100) {
                    setValue("monthly_admin_rate", "100");
                }
                setPercentornot({
                    ...percentornot,
                    ["monthly_percent"]: true
                })
            } else {
                setPercentornot({
                    ...percentornot,
                    ["monthly_percent"]: false
                })
            }
        }

        if (nameTarget == "lease_fee_percentage") {
            valueofTarget = pickValue.querySelector(`[name=lease_fee]`);
            if (!isChecked) {
                if (Number(valueofTarget.value) > 100) {
                    setValue("lease_fee", "100");
                }
                setPercentornot({
                    ...percentornot,
                    ["lease_percent"]: true
                })
            } else {
                setPercentornot({
                    ...percentornot,
                    ["lease_percent"]: false
                })
            }
        }
        setState(isChecked);
    };

    const [use_real_state, setuse_real_state] = useState(false);
    const [ownerDDta, setownerDDta] = useState<any>([]);
    const [companyData, setCompanydtaa] = useState<any>({ payload: { data: [] } });

    useEffect(() => {
        (async () => {
            let data: any = await dispatch(registerDatacomapny());
            setCompanydtaa(data);
        })()
    }, [])

    const ownerActionproperties = async (e: any, setState: any) => {
        const isChecked = e.target.checked;
        const nameTarget = e.target.name;
        setState(isChecked);


        if (isChecked == false) {
            setuse_real_state(false);
            setValue("use_real_state", false);
            setownerDDta([]);
            setValue("owner.0.name", "");
        } else {
            setValue("owner.0.percentage_on_receipt", "100");
            if (companyData?.["payload"]?.["data"].length > 0) {
                setValue("owned_properties", false);
                if (companyData?.["payload"]?.type == "Success") {
                    let companyData1 = companyData["payload"]["data"][0];
                    let { email, cnpj } = companyData1;
                    let ownerdatacompany: any = ownerData.filter(item => item["cnpj"] == cnpj);
                    setownerDDta(ownerdatacompany);
                    setTimeout(() => {
                        setValue("owner.0.name", ownerdatacompany[0]?.id);
                        setMultipleOwner([{
                            value: ownerdatacompany[0]?.id ? ownerdatacompany[0]?.id : "",
                            label: ownerdatacompany[0].name ? ownerdatacompany[0].name : ownerdatacompany[0].fantasy ? ownerdatacompany[0].fantasy : "",
                            main: "-1",
                            percentage: "100",
                            focus: false
                        }])
                    }, 900);


                }
            }
        }
        setIsInputFocused([{ focus: false }])
    };

    const handleInputChange = (e: any, fieldName: any, isPercentage: any) => {
        let inputValue = e.target.value;
        if (!isPercentage) {
            // Remove non-numeric characters except comma and allow backspace
            let filteredValue = inputValue.replace(/[^\d,]|(?<=,)[$,.]/g, '');
            // Replace all commas with dots to maintain numeric value
            const normalizedValue = filteredValue.replace(/,/g, '.');
            // Check if the input is a valid number with up to two decimal places
            let isValid = /^\d*\.?\d{0,2}$/.test(normalizedValue);
            if (isValid) {
                // Ensure maximum value is 100.0
                if (parseFloat(normalizedValue) > 100) {
                    filteredValue = '100,0';
                } else {
                    // Format to two decimal places if less than 100
                    filteredValue = normalizedValue.replace('.', ',');
                }
                inputValue = filteredValue
            }
        } else if (isPercentage) {
            let { value } = e.target;
            value = value.replace(/[^\d,]/g, '');
            if (value !== '') {
                inputValue = formatNumber(value);
            }
        }
        setValue(fieldName, inputValue);
    };


    const generatrindetifier = () => {
        // Get the current timestamp in milliseconds
        const timestamp = Date.now();
        // Convert the timestamp to a string
        const timestampStr = timestamp.toString();
        // Get the last 7 digits of the timestamp
        const last7Digits = timestampStr.slice(-7);
        setValue("property_identifier", last7Digits)
    }
    useEffect(() => {
        if (newPropertyShow && !hasIdentifierGenerated) {
            const fetchData = async () => {
                let identifierData = getValues('property_identifier');
                let data = {
                    identifier: identifierData
                }
                let responseidentifier = await customerService.checkPropertyIdentifier(data);
                if (responseidentifier.data.type == "success") {
                    const timestamp = Date.now();
                    const timestampStr = timestamp.toString();
                    const last7Digits = timestampStr.slice(-7);
                    setValue('property_identifier', last7Digits);
                } else {
                    generatrindetifier();
                }
            };
            fetchData();

            multipleOwner.forEach((item, index) => {
                setValue(`owner.${index}.percentage_on_receipt`, "100");
            });

            setHasIdentifierGenerated(true); // Set flag to true after generation
        }
    }, [newPropertyShow, hasIdentifierGenerated]);

    useEffect(() => {
        setValue(`owner.0.main_owner`, "-1");
    }, [])

    function removeBlankEntries(data) {
        return data.filter(entry => Object.values(entry).some(value => value !== ""));
    }
    const onSubmit = async (data: contractPropertyForm, e: any) => {
        e.preventDefault();
        let valid = validationOwner();
        if (!valid) {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Ownerarerequired"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
        else {
            const cleanedData = removeBlankEntries(multipleHobby);
            // if (multipleOwner.length == 1) {
            //     multipleOwner[0]["main"] = "-1";
            //     multipleOwner[0]["percentage"] = "100";
            //     setValue(`owner.0.main_owner`, "-1");
            //     setValue(`owner.0.percentage_on_receipt`, 100);
            // }
            data.hobbybox = cleanedData
            let salevalue: any = `${data.sale_value}`;
            let salevalue_Val: any = convertToNumber(salevalue);
            let rentAmount: any = `${data.rent_amount}`;
            let rentAmount_val: any = convertToNumber(rentAmount);
            let monthly_admin_rates: any = `${data.monthly_admin_rate}`;
            let monthly_admin_rates_Val: any = convertToNumber(monthly_admin_rates);
            let lease_fee_perce: any = `${data.lease_fee}`;
            let lease_fee_perce_val: any = convertToNumber(lease_fee_perce);
            let fundraiser_val: any = `${data.fundraiser_value}`;
            let fundraiser_value: any = convertToNumber(fundraiser_val);
            let admin_rate_val: any = `${data.admin_rate}`;
            let admin_rate_value: any = convertToNumber(admin_rate_val);
            data["rent_amount"] = rentAmount_val
            data["sale_value"] = salevalue_Val
            data["lease_fee"] = lease_fee_perce_val
            data["monthly_admin_rate"] = monthly_admin_rates_Val;
            data["fundraiser_value"] = fundraiser_value;
            data["admin_rate"] = admin_rate_value;
            if (data.address.length > 70) {
                data["address"] = data.address.slice(0, 70)
            }

            const totalPercentage = multipleOwner?.reduce((acc, owner) => acc + parseFloat(owner.percentage), 0);
            if (totalPercentage < 100) {
                Swal.fire({
                    // icon: 'success', 
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.lesstotalpercentage"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setloader_on(false)
                setDouble(false)
            } else if (totalPercentage > 100) {
                Swal.fire({
                    // icon: 'success', 
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.moretotalpercentage"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setloader_on(false)
                setDouble(false)
            } else {
                setloader_on(true)
                setDouble(true)

                data.owner = multipleOwner;
                let response = await dispatch(insertProperty(data))
                if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
                    let newId = response.payload.data;
                    localStorage.setItem('newPropertId', newId);
                    setDouble(false);
                    setloader_on(false)
                    Swal.fire({
                        // icon: 'success','success',
                        title: t("Sweetalert.Success"),
                        text: response.payload.message,
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                    newPropertyClose();
                    setValue("use_real_state", false);
                    setuse_real_state(false)
                    dispatch(getProperty({ id: id, contract: "contract", start: 0, end: 1000 }));
                    dispatch(getAllProperty({ start: 0, end: 20 }));
                } else {
                    setDouble(false)
                    setloader_on(false)
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: response.payload.message,
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                }
            }
        }
    }
    /*-----------Multiple owner----------*/

    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState([{ focus: false }]);
    const [display, setDisplay] = useState(false);

    const handleOwnerChange = (e: any, index: any, type: any) => {
        if (type == "owner" && e.target.value) {
            let pickData = ownerData && ownerData.length > 0 && ownerData.filter(item => Number(item.id) === Number(e.target.value));
            multipleOwner[`${index}`]["value"] = pickData[0]["id"];
            multipleOwner[`${index}`]["label"] = pickData[0]["name"];
            setValue(`owner.${index}.value`, pickData[0]["id"]);
            setValue(`owner.${index}.label`, pickData[0]["name"]);
            setValue(`owner.${index}.name`, use_real_state ? pickData[0]["id"] : pickData[0]["name"]);
            setDisplay(false)
        }

        if (type == "main") {
            document.querySelectorAll(".singleMainowner input").forEach((item) => {
                item["checked"] = false;
            })

            // multipleOwner.forEach((item, indexs) => {
            //     setValue(`owner.${indexs}.main_owner`, false);
            //     multipleOwner[`${indexs}`]["main"] = 0;
            // }) 
            // e.target.checked = true;
            setValue(`owner.${index}.main_owner`, e.target.value);
            multipleOwner[`${index}`]["main"] = e.target.value;
        }
        if (type == "percentage") {
            if (Number(e.target.value) < 100) {
                multipleOwner[`${index}`]["percentage"] = e.target.value;
            } else {
                multipleOwner[`${index}`]["percentage"] = "100";
            }
            setValue(`owner.${index}.percentage_on_receipt`, multipleOwner[`${index}`]["percentage"]);

            let totalPercent: number = 0;
            multipleOwner.forEach((item, indexss) => {
                if (item.percentage !== "") {
                    totalPercent += Number(item.percentage);
                    if (indexss !== multipleOwner.length - 1) {
                        let exceed = totalPercent + Number(e?.target?.value);
                        if (totalPercent > 100) {
                            multipleOwner[`${index}`]["percentage"] = "";
                            setValue(
                                `owner.${index}.percentage_on_receipt`,
                                ""
                            );
                        } else {
                            if (totalPercent > 100) {
                                let num = 100 - totalPercent;
                                multipleOwner[`${index}`]["percentage"] = num;
                                setValue(
                                    `owner.${index}.percentage_on_receipt`,
                                    `${num}`
                                );
                            }
                        }
                    }
                }
            })

            if (totalPercent > 100) {
                multipleOwner[`${index}`]["percentage"] = "";
                setValue(`owner.${index}.percentage_on_receipt`, '');
            }
        }
        let newData = multipleOwner;
        setMultipleOwner(newData);
    }

    useEffect(() => {
        if (!display) {
            isInputFocused.forEach((item: any, index: any) => {
                isInputFocused[index]["focus"] = false
            })
            setIsInputFocused(isInputFocused);
        }
    }, [display])

    const addAnother = (e) => {
        setMultipleOwner([
            ...multipleOwner,
            {
                value: "",
                label: "",
                main: "1",
                percentage: "",
                focus: false
            }
        ])

        setIsInputFocused([
            ...isInputFocused,
            { focus: false }
        ])
        setInputdisb(false)
        setValue(`owner.${multipleOwner.length}.main_owner`, "1");
    }

    const handleDelete = (e, index) => {
        multipleOwner.splice(index, 1);
        reset({
            owner: [{ value: "", main: "", percentage: "" }],
        });
        setMultipleOwner([...multipleOwner]);
        multipleOwner.forEach((item, indx) => {
            setValue(`owner.${indx}.name`, item.label ? item.label : item.value);
            setValue(`owner.${indx}.main_owner`, item.main);
            setValue(`owner.${indx}.percentage_on_receipt`, item.percentage);
        })
    }

    const [tnsf, setTnsfr] = useState(false);
    const transfer = (e) => {
        if (e.target.value !== "2") {
            setTnsfr(true)
            setfixeday(true)
            setValue("transfer_day", "2");

        } else {
            setTnsfr(false)
            setfixeday(false)
            setValue("transfer_day", "");
        }
        if (e.target.value == 1) {
            setGurnty(false);
        } else {
            setGurnty(true);
        }
    }
    const propertyIdentifier = async (evt) => {
        if (evt.target.value) {
            let data = {
                identifier: evt.target.value
            }
            let response = await customerService.checkPropertyIdentifier(data);
            if (response.data && response.data.data.length > 0) {
                setDouble(true);
                setError("property_identifier", {
                    type: "manual",
                    message: t("Sweetalert.Thisidentifier"),
                });
            }
            else {
                setDouble(false);
                clearErrors("property_identifier")
            }
        }
    }

    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        // Get the DOM element for the zipcode input
        let querySel: any = document.querySelector(
            '.accordion-custom [name="zipcode"]'
        );
        // Check if the value is not empty
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            // Check if the response status is success
            if (resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                // Set the city value if available in the response
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"]);
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"]);
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data =
                        (res[0]["streetType"] ? res[0]["streetType"] + " " : "") +
                        (res[0]["street"] ?? "");
                    setValue("address", data);
                }

                if (res[0]["state"]) {
                    setValue("state", res[0]["state"]);
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address")
                clearErrors("state")
            } else {
                // Add red border to the zipcode input
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                querySel.style.border = "2px solid red";
                // Reset city, neighborhood, and address values
                setValue("city", "");
                setValue("neighborhood", "");
                setValue("address", "");
            }
        } else {
            // Remove red border from the zipcode input
            querySel.style.border = "0px solid red";
        }
    };
    const [update, setUpdate] = useState(1);
    const [AllStates, setAllStates] = useState([]);

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, [update]);

    const fineExemption = (e) => {
        const input = e.target.value.replace(/\D/g, '');
        if (parseInt(input) > 30) {
            setValue("fine_exemption", "30")
            setShowTooltip(true);
        } else {
            setValue("fine_exemption", input)
            setShowTooltip(false);
        }
    };
    const handleRentValue = (e: any, type) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if (value) {
            if (type == "rentAmount") {
                setValue("rent_amount", formatNumber(value))

            } else if (type == "saleValue") {
                setValue("sale_value", formatNumber(value))
            } else {
                setValue("admin_rate", formatNumber(value))
            }
        }
    }

    const TransferDay = (e: any) => {
        let value = e.target.value
        if (value == "2") {
            setfixeday(true)
        } else {
            setfixeday(false)
        }
    }
    const handleHoobyBox = (e, index) => {
        let value = e.target.value;
        let name: any = (e.target.name).split(".");
        let finalName: any = name[name.length - 1] // hobbybox.0.huby_identification
        if (value !== "") {
            multipleHobby[`${index}`][finalName] = e.target.value;
        }
    }

    const addAnotherhobby = (e) => {
        reset();
        let AddNewForm = [
            ...multipleHobby,
            {
                huby_identification: "",
                huby_observation: "",
            }
        ]

        AddNewForm && AddNewForm.length > 0 &&
            AddNewForm.forEach((item: any, index: number) => {
                let huby_identification: any = `hobbybox.${index}.huby_identification`;
                let huby_observation: any = `hobbybox.${index}.huby_observation`;
                setValue(huby_identification, item["huby_identification"]);
                setValue(huby_observation, item["huby_observation"]);
            })
        setmMultipleHobby(AddNewForm);
    }

    const HandleDeleteHobbybox = (e, index) => {
        multipleHobby.splice(index, 1);
        setmMultipleHobby([...multipleHobby]);
        multipleHobby.forEach((item, indx) => {
            setValue(`hobbybox.${indx}.huby_identification`, item.huby_identification);
            setValue(`hobbybox.${indx}.huby_observation`, item.huby_observation);
        })
    }

    const newManagerOpen = () => {
        dispatch(managerOpen(true));
        newPropertyClose()
    };

    const handleAddress = (e) => {
        let { value } = e.target;
        let numberOfCharacters = value.length;
        if (numberOfCharacters > 40) {
            setError("address", {
                type: "manual",
                message: t("requiredMmessage.40characters")
            });
        } else {
            clearErrors("address")
        }
    }

    const handleInputFocus = (indexs: any) => {
        isInputFocused.forEach((item: any, index: any) => {
            if (indexs == index) {
                isInputFocused[index]["focus"] = true
            } else {
                isInputFocused[index]["focus"] = false
            }
        })
        setIsInputFocused(isInputFocused);
        setDisplay(true);
        if (ownerData && ownerData.length > 0) {
            setSearchResults(ownerData)
        }
    };

    const handlefilterBillmethod = async (e) => {
        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = ownerData.filter((itemName: any) => {
                const ownerName = (itemName["name"]).toLowerCase();
                let list = ownerName.includes(lowerSearchTerm);
                return list
            });
            setSearchResults(results)
        } else {
            if (ownerData && ownerData.length > 0) {
                setSearchResults(ownerData)
            }
        }
    }

    const handleValue = (evt: any) => {
        let { value } = evt.target;
        value = value.replace(/[^\d,]/g, '');
        if (value !== "") {
            setValue("fundraiser_value", formatNumber(value))
        } else {
            setValue("fundraiser_value", '')
        }
    }
    const newCondosOpen = () => {
        dispatch(condosOpen(true))
        dispatch(propertyOpen(false))
    }
    const newAdministratorOpen = () => {
        dispatch(administratorOpen(true))
        dispatch(propertyClose(false))
        setAdmType('property')
    }
    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Offcanvas className="border-sm-none propertyServiceTemplatetsx" show={newPropertyShow} onHide={newPropertyClose} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <Offcanvas.Header className="border-bottom" closeButton>
                        <Offcanvas.Title className="fw-semibold">{t('property.newProperty')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p className="mb-lg-4 mb-3">{t('propertyDev.insertNewProperty')}</p>
                        <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.rentAmount') + " *"}>
                                    <Form.Control className={`${errors.rent_amount ? 'is-invalid' : ''}`} type="text" {...register('rent_amount')} placeholder={t('propertyDev.rentAmount')} onChange={(e) => handleRentValue(e, "rentAmount")} />
                                    <Form.Control.Feedback type="invalid">{errors.rent_amount?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.sellingPrice')}>
                                    <Form.Control type="text" className={`${errors.sale_value ? 'is-invalid' : ''}`} {...register('sale_value')} placeholder={t('propertyDev.sellingPrice')} onChange={(e) => handleRentValue(e, "saleValue")} />
                                    <Form.Control.Feedback type="invalid">{errors.sale_value?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={`${t('formLabel.monthlyAdminRate') + " *"} ${percentornot.monthly_percent ? "(%)" : "(R$)"}`}>
                                    <Form.Control
                                        type="text"
                                        className={`${errors.monthly_admin_rate ? 'is-invalid' : ''}`} //working here
                                        {...register('monthly_admin_rate')}
                                        placeholder="Monthly Admin Rate"
                                        onChange={(e) => handleInputChange(e, 'monthly_admin_rate', isMonthlyAdminRatePercentage)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.monthly_admin_rate?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                    <Form.Check
                                        type="checkbox" {...register('monthly_admin_rate_percentage')}
                                        checked={isMonthlyAdminRatePercentage}
                                        onChange={(e) => handleCheckboxChange(e, setMonthlyAdminRatePercentage)}
                                        label={t('propertyDev.fixedValue')}

                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel label={`${t('propertyDev.rentFee')} ${percentornot.lease_percent ? "(%)" : "(R$)"}`}>
                                    <Form.Control
                                        type="text"
                                        className={`${errors.lease_fee ? 'is-invalid' : ''}`}
                                        {...register('lease_fee')}
                                        placeholder="Lease Fee"
                                        onChange={(e) => handleInputChange(e, 'lease_fee', isLeaseFeePercentage)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lease_fee?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                    <Form.Check
                                        type="checkbox" {...register('lease_fee_percentage')}
                                        checked={isLeaseFeePercentage}
                                        onChange={(e) => handleCheckboxChange(e, setLeaseFeePercentage)}
                                        label={t('propertyDev.fixedValue')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.address')}
                        </h6>
                        <Row className="gy-lg-4 gy-3 accordion-custom">
                            <Col md={4}>
                                <InputGroup>
                                    <FloatingLabel label={t('formLabel.zipCode') + " *"}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} maxLength={9} onChange={(e) =>
                                            getLocation(e, "zipcode")
                                        } />
                                        <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text>
                                        <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel label={t('formLabel.address') + " *"}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                                    >
                                        <Form.Control type="text" placeholder={t('formLabel.address')} className={`${errors.address ? 'is-invalid' : ''}`}  {...register('address')} onChange={(e) => handleAddress(e)} />
                                    </OverlayTrigger>
                                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel label={t('formLabel.number') + " *"}>
                                    <Form.Control type="text" placeholder={t('formLabel.number')} className={`${errors.number ? 'is-invalid' : ''}`}  {...register('number')} />
                                    <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel label={t('formLabel.complement')}>
                                    <Form.Control type="text" placeholder={t('formLabel.complement')} className={`${errors.complement ? 'is-invalid' : ''}`}  {...register('complement')} />
                                    <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                    <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                        <option value="">{t('formLabel.select')}</option>
                                        {
                                            AllStates.map((item, index) => {
                                                return (
                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel label={t('formLabel.city') + " *"}>
                                    <Form.Control type="text" placeholder={t('formLabel.city')} className={`${errors.city ? 'is-invalid' : ''}`}  {...register('city')} />
                                    <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel label={t('formLabel.neighborhood') + " *"}>
                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`${errors.neighborhood ? 'is-invalid' : ''}`}  {...register('neighborhood')} />
                                    <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel label={t('formLabel.propertyType') + " *"}>
                                    <Form.Select className={`mb-2 ${errors.property_type ? 'is-invalid' : ''}`} {...register('property_type')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t("propertyTypeData.Casa")}</option>
                                        <option value="4">{t("propertyTypeData.Apartamento")}</option>
                                        <option value="33">{t("propertyTypeData.ApartamentoDuplex")}</option>
                                        <option value="38">{t("propertyTypeData.ApartamentoTriplex")}</option>
                                        <option value="9">{t("propertyTypeData.Áreacomum")}</option>
                                        <option value="42">{t("propertyTypeData.Andarcorporativo")}</option>
                                        <option value="29">{t("propertyTypeData.Bangalo")}</option>
                                        <option value="13">{t("propertyTypeData.Barracao")}</option>
                                        <option value="40">{t("propertyTypeData.BoxGaragem")}</option>
                                        <option value="3">{t("propertyTypeData.Casacomercial")}</option>
                                        <option value="2">{t("propertyTypeData.Casaemcondominio")}</option>
                                        <option value="44">{t("propertyTypeData.CasaAssobradada")}</option>
                                        <option value="5">{t("propertyTypeData.Cobertura")}</option>
                                        <option value="14">{t("propertyTypeData.Chacara")}</option>
                                        <option value="25">{t("propertyTypeData.Conjunto")}</option>
                                        <option value="39">{t("propertyTypeData.Edicula")}</option>
                                        <option value="10">{t("propertyTypeData.Escritorio")}</option>
                                        <option value="20">{t("propertyTypeData.Fazenda")}</option>
                                        <option value="6">{t("propertyTypeData.Flat")}</option>
                                        <option value="16">{t("propertyTypeData.Galpao")}</option>
                                        <option value="8">{t("propertyTypeData.Garagem")}</option>
                                        <option value="43">{t("propertyTypeData.Garden")}</option>
                                        <option value="21">{t("propertyTypeData.Haras")}</option>
                                        <option value="35">{t("propertyTypeData.Hotel")}</option>
                                        <option value="31">{t("propertyTypeData.Ilha")}</option>
                                        <option value="7">{t("propertyTypeData.Kitnet")}</option>
                                        <option value="27">{t("propertyTypeData.Laje")}</option>
                                        <option value="30">{t("propertyTypeData.Loft")}</option>
                                        <option value="12">{t("propertyTypeData.Loja")}</option>
                                        <option value="41">{t("propertyTypeData.Pavilhao")}</option>
                                        <option value="32">{t("propertyTypeData.Penthouse")}</option>
                                        <option value="22">{t("propertyTypeData.Predio")}</option>
                                        <option value="28">{t("propertyTypeData.Pousada")}</option>
                                        <option value="19">{t("propertyTypeData.Ponto")}</option>
                                        <option value="18">{t("propertyTypeData.Quiosque")}</option>
                                        <option value="36">{t("propertyTypeData.Rancho")}</option>
                                        <option value="34">{t("propertyTypeData.Resort")}</option>
                                        <option value="15">{t("propertyTypeData.Salao")}</option>
                                        <option value="11">{t("propertyTypeData.Salacomercial")}</option>
                                        <option value="23">{t("propertyTypeData.Sitio")}</option>
                                        <option value="24">{t("propertyTypeData.Sobrado")}</option>
                                        <option value="37">{t("propertyTypeData.Studio")}</option>
                                        <option value="17">{t("propertyTypeData.Terreno")}</option>
                                        <option value="26">{t("propertyTypeData.Village")}</option>
                                        <option value="45">{t("propertyTypeData.Consultorio")}</option>
                                        <option value="1000">{t("propertyTypeData.Outro")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.property_type?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {/* <h6 className="ownersAndBeneficiariestsx ">
                            <button type="button" aria-expanded="true" className="accordion-button fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('contaCorr')}</button>
                        </h6>
                        <Form.Check type="checkbox" {...register('contaProprietario')} label={t("util")} />
                        <span>{t("utilDesc")}</span> */}
                        <h6 className="ownersAndBeneficiariestsx">
                            <button type="button" aria-expanded="true" className="accordion-button fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.ownerAndBenef')}</button>
                        </h6>
                        <Form.Check
                            type="checkbox"
                            className="mb-lg-4 mb-3"
                            {...register('use_real_state')}
                            checked={use_real_state}
                            onChange={(e) => ownerActionproperties(e, setuse_real_state)}
                            label={t('formLabel.userealstate')}
                        />

                        {
                            !use_real_state && multipleOwner && multipleOwner.length > 0 &&
                            multipleOwner.map((item: any, index: number) => (
                                <React.Fragment key={index}>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={12} xs={12}>
                                            <InputGroup>
                                                {/* <FloatingLabel className="owner" label={t('formLabel.name')}> */}
                                                {/* <Form.Select className={`${errors.name ? 'is-invalid' : ''}`} {...register(`owner.${index}.name`)} onChange={(e) => handleOwnerChange(e, index, "owner")}>
                                                        < option value="">{t('dropdownItem.select')}..</option>
                                                        {ownerDDta && ownerDDta.length > 0 ?
                                                            ownerDDta.map((item, index) => (
                                                                <option key={index} value={item?.["id"]}>{item?.["name"]}</option>
                                                            ))
                                                            :
                                                            ownerData.map((item, index) => {
                                                                let companyData1 = companyData?.["payload"]?.["data"]?.[0];
                                                                if (companyData1) {
                                                                    let { email, cnpj } = companyData1;
                                                                    if (item["cnpj"] !== cnpj) {
                                                                        return (
                                                                            <option key={index} value={item?.["id"]}>{item?.["name"]}</option>
                                                                        )
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    </Form.Select> */}

                                                <Form.Floating className="mb-0" ref={inputRef}>
                                                    {/* className={`form-control ${errors?.objdata?.[index]?.service ? 'is-invalid' : ''}`} */}
                                                    <FormControl
                                                        type="text"
                                                        placeholder="Type to search..."
                                                        {...register(`owner.${index}.name`)}
                                                        onChange={(e) => handlefilterBillmethod(e)}
                                                        onFocus={() => handleInputFocus(index)}
                                                        className={`${errors?.owner?.[index]?.name ? 'is-invalid' : ''}`} id="bank"
                                                        autoComplete="off" />
                                                    {isInputFocused[index]["focus"] && display && (
                                                        <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0" style={{ maxHeight: "260px", display: (isInputFocused[index]["focus"] && display) ? "block" : "none" }}>
                                                            {searchResults && searchResults.length > 0 &&
                                                                searchResults?.slice(0, 1000).reverse().map((item: any, indexs: number) => {
                                                                    let companyData1 = companyData?.["payload"]?.["data"]?.[0];
                                                                    if (companyData1) {
                                                                        let { email, cnpj } = companyData1;
                                                                        if (item["cnpj"] !== cnpj) {
                                                                            return (
                                                                                <ListGroup.Item className="px-3 cursor-pe" key={indexs} onClick={() => handleOwnerChange({
                                                                                    target: {
                                                                                        value: item.id,
                                                                                        name: item.name
                                                                                    }
                                                                                }, index, "owner")}>
                                                                                    {item.name}
                                                                                </ListGroup.Item>
                                                                            )
                                                                        }
                                                                    }
                                                                })}
                                                        </ListGroup>
                                                    )}
                                                    <Form.Label htmlFor="floatingInputValue">{t('formLabel.name') + " *"}</Form.Label >
                                                    {/* <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback > */}
                                                    <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.name?.message}</Form.Control.Feedback >
                                                </Form.Floating>
                                                <Form.Control.Feedback type="invalid">{errors?.name?.message}</Form.Control.Feedback >
                                                {/* </FloatingLabel> */}
                                                <InputGroup.Text onClick={newOwnerOpen} title={t('customerAndEmployees.newOwner')}>
                                                    <img src="assets/images/icons/user-edit.svg" alt="add owner" className="h-20 cursor-pe" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <FloatingLabel className='singleMainowner' label={t('formLabel.type')}>
                                                <Form.Select {...register(`owner.${index}.main_owner`)} className={`${errors?.owner?.[index]?.main_owner ? 'is-invalid singleMainowner' : 'singleMainowner'}`} onChange={(e) => handleOwnerChange(e, index, "main")} disabled={inputdisb}>
                                                    <option value="-1">{t("formLabel.Mainowner")}</option>
                                                    <option value="1">{t("owner")}</option>
                                                    <option value="2">{t("reportSystem.beneficiary")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.main_owner?.message}</Form.Control.Feedback >
                                            </FloatingLabel>
                                            {/* <Form.Group controlId="domiciled" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                                        <Form.Check className="singleMainowner" type="checkbox" {...register(`owner.${index}.main_owner`)} label="Proprietário principal" onChange={(e) => handleOwnerChange(e, index, "main")} />
                                                    </Form.Group> */}
                                        </Col>
                                        <Col md xs>
                                            <FloatingLabel className="percentage  muiltipeMainowner" controlId="floatingSelect " label={t('propertyPer')} >
                                                <Form.Control type="text" placeholder={t('propertyPer')}  {...register(`owner.${index}.percentage_on_receipt`)} className={`${errors?.owner?.[index]?.percentage_on_receipt ? 'is-invalid' : ''}`} onChange={(e) => handleOwnerChange(e, index, "percentage")} disabled={inputdisb} />
                                                <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.percentage_on_receipt?.message}</Form.Control.Feedback >
                                            </FloatingLabel>
                                        </Col>
                                        {

                                            multipleOwner.length > 1 &&
                                            <Col md="auto" xs="auto">
                                                <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => handleDelete(e, index)}><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                            </Col>
                                        }
                                    </Row>
                                </React.Fragment>
                            ))
                        }
                        {
                            use_real_state && multipleOwner && multipleOwner.length > 0 && multipleOwner.map((item, index) => (
                                <React.Fragment key={index}>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={12} xs={12}>
                                            <InputGroup>
                                                <FloatingLabel className="owner" label={t('formLabel.name') + " *"}>
                                                    <Form.Select className={`${errors?.owner?.[index]?.name ? 'is-invalid' : ''}`} {...register(`owner.${index}.name`)} onChange={(e) => handleOwnerChange(e, index, "owner")}>
                                                        < option value="">{t('dropdownItem.select')}..</option>
                                                        {ownerDDta && ownerDDta.length > 0 ?
                                                            ownerDDta.map((item, index) => (
                                                                <option key={index} value={item?.["id"]}>{item?.["name"]}</option>
                                                            ))
                                                            :
                                                            ownerData.map((item, index) => {
                                                                let companyData1 = companyData?.["payload"]?.["data"]?.[0];
                                                                if (companyData1) {
                                                                    let { email, cnpj } = companyData1;
                                                                    if (item["cnpj"] !== cnpj) {
                                                                        return (
                                                                            <option key={index} value={item?.["id"]}>{item?.["name"]}</option>
                                                                        )
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.name?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                                <InputGroup.Text onClick={newOwnerOpen} title={t('customerAndEmployees.newOwner')}>
                                                    <img src="assets/images/icons/user-edit.svg" alt="add owner" className="h-20 cursor-pe" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <FloatingLabel className='singleMainowner' label={t('formLabel.type')}>
                                                <Form.Select {...register(`owner.${index}.main_owner`)} className={`${errors?.owner?.[index]?.main_owner ? 'is-invalid singleMainowner' : 'singleMainowner'}`} onChange={(e) => handleOwnerChange(e, index, "main")} disabled={inputdisb}>
                                                    <option value="-1">{t("formLabel.Mainowner")}</option>
                                                    <option value="1">{t("owner")}</option>
                                                    <option value="2">{t("reportSystem.beneficiary")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.main_owner?.message}</Form.Control.Feedback >
                                            </FloatingLabel>
                                            {/* <Form.Group controlId="domiciled" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                                        <Form.Check className="singleMainowner" type="checkbox" {...register(`owner.${index}.main_owner`)} label="Proprietário principal" onChange={(e) => handleOwnerChange(e, index, "main")} />
                                                    </Form.Group> */}
                                        </Col>
                                        <Col md xs>
                                            <FloatingLabel className="percentage  muiltipeMainowner" controlId="floatingSelect " label={t('propertyPer')} >
                                                <Form.Control type="text" placeholder={t('propertyPer')}  {...register(`owner.${index}.percentage_on_receipt`)} className={`${errors?.owner?.[index]?.percentage_on_receipt ? 'is-invalid' : ''}`} onChange={(e) => handleOwnerChange(e, index, "percentage")} disabled={inputdisb} />
                                                <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.percentage_on_receipt?.message}</Form.Control.Feedback >
                                            </FloatingLabel>
                                        </Col>
                                        {
                                            multipleOwner.length > 1 && ownerDDta.length == 0 &&
                                            <Col md="auto" xs="auto">
                                                <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => handleDelete(e, index)}><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                            </Col>
                                        }
                                    </Row>
                                </React.Fragment>
                            ))
                        }
                        {
                            multipleOwner.length < 6 && ownerDDta.length == 0 &&
                            <div className="text-center">
                                <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother(e)}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('propertyDev.addOwner')}
                                </Button>
                            </div>
                        }
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.transfer')}
                        </h6>
                        <Row className="gy-lg-4 gy-3">
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.guaranteedTransfer')}>
                                    <Form.Select {...register('transfer_guaranteed')} onChange={(e) => transfer(e)}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="2">{t("doesNotHave")}</option>
                                        <option value="1">{t("guaranteeFewMonths")}</option>
                                        <option value="-1">{t("guaranteeContract")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6} style={{ display: gurnty ? "none" : "block" }}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label={t("newContractDev.GuaranteedMonths")}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder={t("newContractDev.GuaranteedMonths")}
                                        {...register("months")}
                                        onChange={(e) => {
                                            numericOnly(e);
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.transferDay')}>
                                    <Form.Select disabled={tnsf ? true : false} {...register('transfer_day')} className={`${errors.transfer_day ? 'is-invalid' : ''}`} onChange={(e) => TransferDay(e)}> //working here
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t("businessPayment")}</option>
                                        <option value="2">{t("fixedDay")}</option>
                                        <option value="3">{t("calendarPayment")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.transfer_day?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={fixedday ? t("formLabel.transferDay") : t('formLabel.daysOfTransfer')}>
                                    <Form.Control type="text" placeholder={t('propertyDev.transferProcessed')} {...register('days_of_transfer')} onChange={numericOnly} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('propertyDev.serviceContract')}
                        </h6>
                        <Row className="gy-lg-4 gy-3">
                            <Col md={6}>
                                <FloatingLabel label={t('dateCapture')}>
                                    <Form.Control type="date" placeholder={t('dateCapture')} {...register('funding_date')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <OverlayTrigger
                                    placement="bottom"
                                    show={showTooltip || undefined}
                                    overlay={<Tooltip id="tooltip-bottom">{t(showTooltip ? "Maximummonths" : "exemptionFineTooltip")}</Tooltip>}
                                >
                                    <FloatingLabel label={t('formLabel.fineExemption')}>
                                        <Form.Control type="text" placeholder={t('formLabel.fineExemption')} {...register('fine_exemption')} onKeyPress={numericOnly} onChange={fineExemption} />
                                    </FloatingLabel>
                                </OverlayTrigger>
                            </Col>
                            <Col md={6}>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip id="tooltip-maximum-lease">{t('formLabel.maximumLeaseTooltip')}</Tooltip>}
                                >
                                    <FloatingLabel label={t('formLabel.maximumLease')}>
                                        <Form.Control type="text" placeholder={t('formLabel.maximumLease')} {...register('maximum_lease')} onChange={numericOnly} />
                                    </FloatingLabel>
                                </OverlayTrigger>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.admFee')}>
                                    <Form.Select {...register('administration_fee')} className={`${errors.administration_fee ? 'is-invalid' : ''}`}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="0">{t("undefined")}</option>
                                        <option value="1">{t("focusonlyonrent")}</option>
                                        <option value="2">{t("focusonrent")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.administration_fee?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.identifier')}
                        </h6>
                        <FloatingLabel label={t('formLabel.propertyIdentifier') + " *"}>
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="tooltip-property-id">{t('propertyIdentifierID')}</Tooltip>}
                            >
                                <Form.Control className={`${errors.property_identifier ? 'is-invalid' : ''}`} type="text" placeholder={t('formLabel.propertyIdentifier')} {...register('property_identifier')} onKeyUp={propertyIdentifier} />
                            </OverlayTrigger>

                            <Form.Control.Feedback type="invalid">{errors.property_identifier?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <span className="d-block fs-12 text-secondary fw-meidum mt-1 ms-lg-3 ms-2">{t('propertyDev.propertyAnotherSystem')}</span>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('property.condominium')}
                        </h6>
                        <Row className="gy-lg-4 gy-3 align-items-center">
                            <Col md={6}>
                                <InputGroup>
                                    <FloatingLabel label={t('formLabel.Condominiumadministration')} >
                                        <Form.Select className={`${errors.administrator ? 'is-invalid' : ''}`}  {...register('administrator')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {administData.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.administrator?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text onClick={newAdministratorOpen} title={t('property.property')}>
                                        <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <FloatingLabel label={t('formLabel.buildingCondominium')}>
                                        <Form.Select className={`${errors.building ? 'is-invalid' : ''}`}  {...register('building')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {condoData.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.building?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text onClick={newCondosOpen}>
                                        <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.amount')}>
                                    <Form.Control type="text" placeholder={t('propertyDev.amount')} className={`${errors.admin_rate ? 'is-invalid' : ''}`}  {...register('admin_rate')} onChange={(e) => handleRentValue(e, "admin_rate")} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_rate?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.Condominiumadministration')}>
                                    <Form.Select {...register('condominium')}>
                                        <option value="1">{t("Report-Property.NotManagedByTheRealEstateAsgency")}</option>
                                        <option value="2">{t("Report-Property.ManagedByTheRealEstateAgency")}</option>
                                        <option value="3">{t("Report-Property.ManagedAndPaidByTheRealEstateAgency")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>

                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.propertyDetails')}
                        </h6>
                        <Row className="gy-lg-4 gy-3">
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.sequentialCode')}>
                                    <Form.Control type="text" placeholder={t('formLabel.sequentialCode')} {...register('sequential_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.waterCode')}>
                                    <Form.Control type="text" placeholder={t('formLabel.waterCode')} {...register('water_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.energyCode')}>
                                    <Form.Control type="text" placeholder={t('formLabel.energyCode')} {...register('energy_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.electricalConnection')}>
                                    <Form.Select {...register('type_of_electrical')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="0">{t("uninformed")}</option>
                                        <option value="1">{t("singlephase")}</option>
                                        <option value="2">{t("biphasic")}</option>
                                        <option value="3">{t("threephase")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.gasCode')}>
                                    <Form.Control type="text" placeholder={t('formLabel.gasCode')} {...register('gas_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.usableArea')}>
                                    <Form.Control type="text" placeholder={t('formLabel.usableArea')} {...register('usable_area')} onChange={numericOnly} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.size')}>
                                    <Form.Control type="text" placeholder={t('formLabel.size')} {...register('size')} onChange={numericOnly} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.contributorCode')}>
                                    <Form.Control type="text" placeholder={t('propertyDev.contributorCode')} {...register('taxpayer_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.garbageFeeCode')}>
                                    <Form.Control type="text" placeholder={t('formLabel.garbageFeeCode')} {...register('garbage_fee_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.firefighterCode')}>
                                    <Form.Control type="text" placeholder={t('formLabel.firefighterCode')} {...register('firefighter_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.spuCode')}>
                                    <Form.Control type="text" placeholder={t('formLabel.spuCode')} {...register('spu_code')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.location')}>
                                    <Form.Select {...register('location')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="U">{t('urbanproperty')}</option>
                                        <option value="R">{t('ruralproperty')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.propertyPurpose')}>
                                    <Form.Select {...register('property_destination')}>
                                        <option value="0">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t("residential")}</option>
                                        <option value="2">{t("nonResidential")}</option>
                                        <option value="3">{t("comercial")}</option>
                                        <option value="4">{t("indústria")}</option>
                                        <option value="5">{t("temporada")}</option>
                                        <option value="6">{t("porEncomenda")}</option>
                                        <option value="7">{t("misto")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.historicalProperty')}>
                                    <Form.Select {...register('listed_property')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t("contractlistother.Not")} </option>
                                        <option value="2">{t("yes")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('property.managers')}
                        </h6>
                        <Row>
                            <InputGroup className="mb-3">
                                <FloatingLabel label={t('formLabel.manager')} >
                                    <Form.Select className={`form-control ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {managerData && managerData.length > 0 &&
                                            managerData.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.manager?.message}</Form.Control.Feedback>

                                </FloatingLabel>
                                <InputGroup.Text onClick={newManagerOpen}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/user-edit.svg"
                                        }
                                        alt="Documents add"
                                        className="h-20 cursor-pe"
                                    />
                                </InputGroup.Text>
                            </InputGroup>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.registry')}
                        </h6>
                        <Row className="gy-lg-4 gy-3">
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.registry')}>
                                    <Form.Control type="text" placeholder={t('formLabel.registry')} {...register('registry')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.registration')}>
                                    <Form.Control type="text" placeholder={t('formLabel.registration')} {...register('registration')} />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.fundraiser')}
                        </h6>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col xs>
                                <InputGroup>
                                    <FloatingLabel label={t('formLabel.name')}>
                                        <Form.Select className={`${errors.fundraiser_name ? 'is-invalid' : ''}`} {...register('fundraiser_name')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {brokerData.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.fundraiser_name?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text onClick={newBrokerOpen} title={t('customerAndEmployees.newBroker')}>
                                        <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20 cursor-pe" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col xs="auto">
                                <Button className="btn btn-link bg-light min-w-auto border-light rounded-4">
                                    <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                                </Button>
                            </Col>
                            <div className="w-100 p-0 m-0"></div>
                            <Col md={6}>
                                <FloatingLabel label={t('propertyDev.commissionAmount')}>
                                    <Form.Control className={`${errors.fundraiser_value ? 'is-invalid' : ''}`} type="text" placeholder={t('propertyDev.commissionAmount')} {...register('fundraiser_value')} onChange={(e) => handleValue(e)} />
                                    <Form.Control.Feedback type="invalid">{errors.fundraiser_value?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            <Col md={6}>
                                <FloatingLabel label={t('formLabel.installments')}>
                                    <Form.Select className={`${errors.fundraiser_installments ? 'is-invalid' : ''}`} {...register('fundraiser_installments')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="-1">{t("foreverycontract")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.fundraiser_installments?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="fixed_value" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                    <Form.Check type="checkbox" label={t('propertyDev.fixedValue')} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Button variant='link' className="p-0 fw-bold">
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('propertyDev.addAgent')}
                            </Button>
                        </div>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.branch')}
                        </h6>
                        <FloatingLabel label={t('formLabel.branch')}>
                            <Form.Select {...register('branch')}>
                                <option value="">{t('dropdownItem.select')}..</option>
                                {branchList?.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.branch?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.garages')}
                        </h6>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col xs>
                                <FloatingLabel label={t('formLabel.identification')} >
                                    <Form.Control type="text" placeholder={t('formLabel.identification')}  {...register('identification')} />
                                </FloatingLabel>
                            </Col>
                            <Col xs="auto">
                                <Button className="btn btn-link bg-light min-w-auto border-light rounded-4">
                                    <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                                </Button>
                            </Col>
                            <Col xs={12}>
                                <FloatingLabel label={t('formLabel.observation')} >
                                    <Form.Control type="text" placeholder={t('formLabel.observation')} {...register('observation')} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Button variant='link' className="p-0 fw-bold">
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('propertyDev.addGarage')}
                            </Button>
                        </div>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.hobbyBox')}
                        </h6>
                        {multipleHobby && multipleHobby.length > 0 &&
                            multipleHobby.map((item, index) => (
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={5}>
                                        <FloatingLabel label={t('formLabel.identification')}>
                                            <Form.Control type="text" placeholder={t('formLabel.identification')} {...register(`hobbybox.${index}.huby_identification`)} onChange={(e) => handleHoobyBox(e, index)} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={5}>
                                        <FloatingLabel label={t('formLabel.observation')}>
                                            <Form.Control type="text" placeholder={t('formLabel.observation')} {...register(`hobbybox.${index}.huby_observation`)} onChange={(e) => handleHoobyBox(e, index)} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={2}>
                                        {multipleHobby.length > 1 ?
                                            <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => HandleDeleteHobbybox(e, index)}>
                                                <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                                            </Button>
                                            : ""}
                                    </Col>

                                </Row>

                            )
                            )}

                        <div className="text-center">
                            <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnotherhobby(e)}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('propertyDev.addHobby')}
                            </Button>
                        </div>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                            {t('formLabel.comments')}
                        </h6>
                        <FloatingLabel label={t('formLabel.observation')}>
                            <Form.Control as="textarea" {...register('comments')} rows={3} />
                        </FloatingLabel>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={newPropertyClose}>
                            {t('button.close')}
                        </Button>
                        {
                            loader_on ?
                                <Button className="btn-width" disabled={double}>
                                    <span className="spinner-border spinner-border-sm"></span>
                                    <span className="px-1">
                                        {t('button.toSave')}
                                    </span>
                                </Button>
                                :
                                <Button type="submit" disabled={double} variant="primary" onClick={openErrorbox}>
                                    {t('button.toSave')}
                                </Button>
                        }
                    </div>
                </Form>
            </Offcanvas >
            <AdministratorService title={admtype} />
            <CondosService />
            <OwnerService />
            <BrokerService />
            <ManagerService type={"properties"} />
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default PropertyService;