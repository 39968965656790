import { useEffect, DependencyList } from 'react'

export function useDebounceEffect(
    // Function to be executed after the debounce
    fn: () => void,
    // Wait time in milliseconds
    waitTime: number,
    deps?: any,
) {
    useEffect(() => {
        const t = setTimeout(() => {
             // Execute the provided function with dependencies
            fn.apply(undefined, deps)
        }, waitTime)

        return () => {
            // Clear the timeout on component unmount or dependency change
            clearTimeout(t)
        }
    }, deps)
}
