import { Form,Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { maintenanceClose,insertMaintenance } from "../../../../redux/slice/propertyDataSlice";
import * as Yup        							from 'yup';
import { useForm  }    							from "react-hook-form";
import { useNavigate } 							from 'react-router-dom';
import { yupResolver } 							from '@hookform/resolvers/yup';
import React,{ useEffect,useState }	from 'react';
import { useDispatch, useSelector }	from 'react-redux';
import { AppDispatch, RootState }  	from '../../../../redux/store';
import { useTranslation }           from 'react-i18next';
import { maintenanceForm }         	from '../../../Interface';
import Swal                        	from 'sweetalert2';
import '../../../../App.css';

const MaintenanceService = (maintenanceForm) => {
  const dispatch 										= useDispatch<AppDispatch>()
  const [t]      										= useTranslation();
  const { registryMaintenanceShow } = useSelector((state: RootState) => state.propertyData)
  const newMentenanceClose = () => { dispatch(maintenanceClose(false)); reset(); }
  const maintenanceValidation = Yup.object().shape({
    requestDate: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    priority: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    category: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    situation: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    suggestedDate: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    suggestedHour: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    requester: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    deliveryForecast: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    deliveryHour: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))      
  });
  let frm = {
    resolver: yupResolver(maintenanceValidation)
  }
  const { register,reset,handleSubmit,setValue,getValues,formState: { errors } } = useForm<maintenanceForm>(frm);
  const onSubmit = async (data: maintenanceForm,e: any) => {
    e.preventDefault();
    let response = await dispatch(insertMaintenance(data));
    if(typeof response.payload !=="undefined" && response.payload.resCode===202){
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:t("Sweetalert.successget"),
        confirmButtonText:"OK",
        timer:2000})    
      newMentenanceClose(); 
    }
    else {
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }
  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={registryMaintenanceShow} onHide={newMentenanceClose}>
        <Modal.Header closeButton>
          <Modal.Title>New maintenance</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
            <Row>
	            <Col>
	                <FloatingLabel className="mb-3" label="Request date">
	                    <Form.Control type="date" id="requestDate" placeholder="Request date" className={`form-control ${errors.requestDate ? 'is-invalid' : ''}`} {...register("requestDate")} />
	                    <Form.Control.Feedback type="invalid">{errors.requestDate?.message}</Form.Control.Feedback >	                    
	                </FloatingLabel>
	            </Col>
	            <Col>
	                <FloatingLabel className="mb-3" label="Priority">
					    <Form.Select id="priority" aria-label="Priority" className={`form-control ${errors.priority ? 'is-invalid' : ''}`} {...register('priority')}>
					        <option value="">{t('dropdownItem.select')}..</option>
					        <option value="1">Normal</option>
                            <option value="2">Low</option>
                            <option value="3">Urgent</option>
					    </Form.Select>
					    <Form.Control.Feedback type="invalid">{errors.priority?.message}</Form.Control.Feedback>
					</FloatingLabel>
	            </Col>
            </Row>  
            <Row>
	            <Col>
	              <FloatingLabel className="mb-3" label="Category">
                    <Form.Select id="category" aria-label="Category" className={`form-control ${errors.category ? 'is-invalid' : ''}`} {...register('category')}>
                        <option value="">{t('dropdownItem.select')}..</option>
                        <option value="1">General services</option>
                                  <option value="2">Heaters</option>
                                  <option value="3">Air conditioning</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.category?.message}</Form.Control.Feedback>
                </FloatingLabel>
	            </Col>
	            <Col>
	                <FloatingLabel className="mb-3" label="Situation">
					    <Form.Select id="situation" aria-label="Situation" className={`form-control ${errors.situation ? 'is-invalid' : ''}`} {...register('situation')}>
					        <option value="">{t('dropdownItem.select')}..</option>
					        <option value="1">Open</option>
                            <option value="2">Under review by owner</option>
                            <option value="3">Approved</option>
					    </Form.Select>
					    <Form.Control.Feedback type="invalid">{errors.situation?.message}</Form.Control.Feedback>
					</FloatingLabel>
	            </Col>
            </Row>          
            <Row>
              <Col>
                <FloatingLabel className="mb-3" label="Owner">
                  	<Form.Control id="owner" type="text" placeholder="Owner" value="Regina Duarte" {...register('owner')}/>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
	            <Col md={10}>
	                <FloatingLabel className="mb-3" label="Tenant">
	                  	<Form.Control id="tenant" type="text" placeholder="Tenant" value="Ana Maria Braga" {...register('tenant')}/>
	                </FloatingLabel>
	            </Col>
	            <Col>
	            	<button type="button" className="btn btn-link bg-light border-light rounded-4">
                        <svg width="12px" height="15px" viewBox="0 0 10 13" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                            <title>user_g</title>
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Contracts_Search_bar" transform="translate(-644.000000, -882.000000)" fill="#888888" fillRule="nonzero">
                                    <g id="user_g" transform="translate(644.000000, 882.000000)">
                                        <path d="M8.5,6.5 C9.3284,6.5 10,7.1716 10,8 L10,8.5 C10,10.4714 8.1405,12.5 5,12.5 C1.8595,12.5 0,10.4714 0,8.5 L0,8 C0,7.1716 0.6716,6.5 1.5,6.5 L8.5,6.5 Z M5,0 C6.5188,0 7.75,1.2312 7.75,2.75 C7.75,4.2688 6.5188,5.5 5,5.5 C3.4812,5.5 2.25,4.2688 2.25,2.75 C2.25,1.2312 3.4812,0 5,0 Z"
                                                        id="Shape">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
	            </Col>
            </Row>
            <Row>
		        <Col>
			        <FloatingLabel label="Maintenance" >
			            <Form.Control as="textarea" id="maintenance" placeholder="Maintenance" {...register('maintenance')} style={{ height: '100px' }} />
			        </FloatingLabel>
			    </Col>
		    </Row>
		    <span className="d-block mb-3 fw-bold">Suggestions for execution</span>
            <Row>
	            <Col md={5}>
	                <FloatingLabel className="mb-3" label="Suggested date">
	                    <Form.Control type="date" id="suggestedDate" placeholder="Suggested date" className={`form-control ${errors.suggestedDate ? 'is-invalid' : ''}`} {...register("suggestedDate")} />
	                    <Form.Control.Feedback type="invalid">{errors.suggestedDate?.message}</Form.Control.Feedback >	                    
	                </FloatingLabel>
	            </Col>
	            <Col md={5}>
	                <FloatingLabel className="mb-3" label="Hour">
	                  <Form.Control id="suggestedHour" type="text" placeholder="Hour" className={`form-control ${errors.suggestedHour ? 'is-invalid' : ''}`} {...register('suggestedHour')} />
	                  <Form.Control.Feedback type="invalid">{errors.suggestedHour?.message}</Form.Control.Feedback>
	                </FloatingLabel>
	            </Col>
	            <Col>
	            	<button className="btn btn-link bg-light border-light rounded-4">
                        <svg className="cursor-pe" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	                        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 1C8.32843 1 9 1.67157 9 2.5H6C6 1.67157 6.67157 1 7.5 1ZM7.5 0C6.11929 0 5 1.11929 5 2.5H0.5C0.223858 2.5 0 2.72386 0 3L0.008056 3.08988C0.050392 3.32312 0.25454 3.5 0.5 3.5H1.053L2.35006 14.7292C2.4665 15.7384 3.321 16.5 4.33688 16.5H10.6631C11.679 16.5 12.5335 15.7384 12.6499 14.7292L13.946 3.5H14.5C14.7761 3.5 15 3.27614 15 3C15 2.72386 14.7761 2.5 14.5 2.5H10C10 1.11929 8.88071 0 7.5 0ZM2.061 3.5H12.938L11.6565 14.6146C11.5983 15.1192 11.1711 15.5 10.6631 15.5H4.33688C3.82894 15.5 3.40169 15.1192 3.34347 14.6146L2.061 3.5ZM6 6C6.24546 6 6.44961 6.15477 6.49194 6.35886L6.5 6.4375V12.5625C6.5 12.8041 6.27614 13 6 13C5.75454 13 5.55039 12.8452 5.50806 12.6411L5.5 12.5625V6.4375C5.5 6.19588 5.72386 6 6 6ZM9.49194 6.35886C9.44961 6.15477 9.24546 6 9 6C8.72386 6 8.5 6.19588 8.5 6.4375V12.5625L8.50806 12.6411C8.55039 12.8452 8.75454 13 9 13C9.27614 13 9.5 12.8041 9.5 12.5625V6.4375L9.49194 6.35886Z" fill="#242424">
	                        </path>
                        </svg>
                    </button>
	            </Col>
            </Row>     
            <span className="d-block mb-3 fw-bold">More Options</span>
            <Row>
            	<Col>
            		<FloatingLabel className="mb-3" label="Requester">
						<Form.Select id="requester" aria-label="Requester" className={`form-control ${errors.requester ? 'is-invalid' : ''}`} {...register('requester')}>
							<option value="">{t('dropdownItem.select')}..</option>
							<option value="1">Tenant</option>
                            <option value="2">Owner</option>
                            <option value="3">Real estate</option>                    
						</Form.Select>
						<Form.Control.Feedback type="invalid">{errors.requester?.message}</Form.Control.Feedback>
					</FloatingLabel>
            	</Col>
            </Row> 
            <Row>
	            <Col md={9}>
	                <FloatingLabel className="mb-3" label="Delivery forecast">
	                    <Form.Control type="date" id="deliveryForecast" placeholder="Delivery forecast" className={`form-control ${errors.deliveryForecast ? 'is-invalid' : ''}`} {...register("deliveryForecast")} />
	                    <Form.Control.Feedback type="invalid">{errors.deliveryForecast?.message}</Form.Control.Feedback >	                    
	                </FloatingLabel>
	            </Col>
	            <Col>
	                <FloatingLabel className="mb-3" label="Hour">
	                  <Form.Control id="deliveryHour" type="text" placeholder="Hour" className={`form-control ${errors.deliveryHour ? 'is-invalid' : ''}`} {...register('deliveryHour')} />
	                  <Form.Control.Feedback type="invalid">{errors.deliveryHour?.message}</Form.Control.Feedback>
	                </FloatingLabel>
	            </Col>
            </Row>     
          </Modal.Body>
          	<Modal.Footer>
          		<div className="d-sm-flex justify-content-end w-100 align-items-center">
			        <Button variant="outline-primary" onClick={newMentenanceClose}>
			              {t('button.close')}
			        </Button>
			        <Button type="submit" variant="primary" >
			              {t('button.toSave')}
			        </Button>			        
                </div> 
          	</Modal.Footer>            
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default MaintenanceService;