import axios from "axios";

import { config } from "../../../../../../services/apiConfig";

export async function deleteBankAccount(bankAccountId: number) {
  try {
    const response = await axios.delete(
      `${config.companyV2_URL}/bank_accounts/${bankAccountId}`
    );

    if (response.status !== 204) {
      throw new Error();
    }

    return {
      type: "success",
    };
  } catch (err: any) {
    console.log("err", err);
    if (err.response && err.response.data) {
      return {
        type: "error",
        message: err.response.data.data[0] as string,
      };
    }

    return {
      type: "error",
      message:
        "Falha ao deletar conta bancária. Verifique suas informações e tente novamente.",
    };
  }
}
