import { configureStore } from '@reduxjs/toolkit';
import bankSlice from './slice/bankSlice';
import branchSlice from './slice/branchSlice';
import companyDataSlice from './slice/companyDataSlice';
import formsOfReceiptSlice from './slice/formsOfReceiptSlice';
import loginSlice from './slice/loginSlice';
import profileSlice from './slice/profileSlice';
import taxationSlice from './slice/taxationSlice';
import ServiceSlice from './slice/ServiceSlice';
import AccountPlanSlice from "./slice/AccountPlanSlice"
import notificationSlice from './slice/notificationSlice';

import propertyDataSlice from "./slice/propertyDataSlice";
import contractDataSlice from "./slice/contractDataSlice";
import expenditureDataSlice from "./slice/expenditureDataSlice";
import transferDataSlice from "./slice/transferDataSlice";
import toBePaidSlice from "./slice/toBePaidSlice";
import financialDataSlice from "./slice/financialDataSlice";
import BankTransactionDataSlice from "./slice/BankTransactionDataSlice"
import SendEmailSlice from './slice/SendEmailSlice';
import NonPaymentSlice from './slice/NonPaymentSlice';
import BillingRuleSlice from './slice/BillingRuleSlice';

export const store = configureStore({
  reducer: {
    auth: loginSlice,
    companyData: companyDataSlice,
    branch: branchSlice,
    bank: bankSlice,
    forms: formsOfReceiptSlice,
    taxa: taxationSlice,
    accountplane: AccountPlanSlice,
    profile: profileSlice,
    service: ServiceSlice,
    notification: notificationSlice,
    toBePaid: toBePaidSlice,
    propertyData: propertyDataSlice,
    contractData: contractDataSlice,
    expenditureData: expenditureDataSlice,
    transferData: transferDataSlice,
    financialData: financialDataSlice,
    bankTransaction: BankTransactionDataSlice,
    emailTemplate: SendEmailSlice,
    nonPayment: NonPaymentSlice,
    billingRule: BillingRuleSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})


export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch