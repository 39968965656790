import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const SuccessMessage = (props) => {
    //Success modal close open function
    const { showSuccess, successClose, successShow } = props;
    return (
        <Modal show={showSuccess} onHide={successClose}>
            <Form>
                <Modal.Header className="pb-0" closeButton></Modal.Header>
                <Modal.Body className="py-2 text-center">
                <img title="reNewInsurance" src="../assets/images/icons/thumb-up-light.svg" alt="Check" className="min-h-56 mb-lg-4 mb-3 cursor-pe" />
                    <h4>Inspection successfully scheduled!</h4>
                    You can edit and track details through the inspection module.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={successClose}>
                    See inspection module
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default SuccessMessage
