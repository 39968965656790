import React from "react";
import { Badge } from "react-bootstrap";

type BankAccountStatusBadgeProps = {
  isActive: boolean;
  isMainAccount?: boolean;
};

const statusDefinitions = {
  inactive: {
    text: "Inativo",
    bgColor: "bg-light-20",
    img: `${process.env.PUBLIC_URL}/assets/images/icons/circle-check-light.svg`,
  },
  active: {
    text: "Ativo",
    bgColor: "bg-success-10",
    img: `${process.env.PUBLIC_URL}/assets/images/icons/circle-check-success.svg`,
  },
};

export function BankAccountStatusBadge(props: BankAccountStatusBadgeProps) {
  const { isActive, isMainAccount } = props;
  const status = isActive ? "active" : "inactive";
  const currentStatus = statusDefinitions[status];

  return (
    <Badge className={`${currentStatus.bgColor} text-dark-80`}>
      <div className="d-flex align-items-center gap-1">
        {isMainAccount && (
          <img
            width={16}
            height={16}
            src={currentStatus.img}
            className="header-logo"
            alt="Checkmark Circle"
          />
        )}
        {currentStatus.text}
      </div>
    </Badge>
  );
}
