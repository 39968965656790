import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";

type StatusInfo = {
  message: string
  link: string
}

type StatusList = { alerts: StatusInfo[], errors: StatusInfo[] }

type SolveStatusModalContextType = {
  clearStates: () => void
  handleOpenStatusModal: (statusList: StatusList) => void
  openModal: boolean
  statusList: StatusList | null
};

const SolveStatusModalContext = createContext({} as SolveStatusModalContextType);

export function SolveStatusModalProvider({ children }: { children: ReactNode }) {
  const [statusList, setStatusList] = useState<StatusList | null>(null)
  const [openModal, setOpenModal] = useState(false)

  function clearStates() {
    setOpenModal(false)
    setStatusList(null)
  }

  function handleOpenStatusModal(statusList: StatusList) {
    setOpenModal(true)
    setStatusList(statusList)
  }

  return (
    <SolveStatusModalContext.Provider value={{
      clearStates,
      handleOpenStatusModal,
      openModal,
      statusList,
    }}>
      {children}
    </SolveStatusModalContext.Provider>
  );
}

export function useSolveStatusModal() {
  const context = useContext(SolveStatusModalContext);

  if (!context) {
    throw new Error("useSolveStatusModal must be used within a Provider");
  }

  return context;
}
