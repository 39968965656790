import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { converter } from '../../../constants/utils';

const Overview = (props) => {
    let { summery } = props;
    const [t] = useTranslation();
    return (
        <Row className="g-3 g-lg-4 mb-lg-4 mb-3">
            <Col lg={3}>
                <Card>
                    <Card.Header>{t("NonPayment.totalOverdue")}</Card.Header>
                    <Card.Body>
                        <h4 className="mb-0"><img src="assets/images/icons/money-cross.svg" alt="money" className="me-1" />R$ {converter(String(summery ? summery?.["totalamount"] : "0.00"))}</h4>
                        <span className="d-block text-secondary">{t("Reports.of")} {summery ? summery?.["total_contract"] : "0"} {t("contracts")}</span>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={9}>
                <Card>
                    <Card.Header>{t("NonPayment.correlationBetweenDelayTimeAmounts")}</Card.Header>
                    <Card.Body>
                        <Row className="g-3 g-lg-4 g-xl-5 g-xxl-6">
                            <Col md className="border-light border-end border-sm-none">
                                <h4 className="mb-0">R$ {converter(String(summery ? summery?.["1to5"] : "0.00)"))}</h4>
                                <span className="d-block text-secondary">1 {t("to")} 5 {t("NonPayment.days")}</span>
                            </Col>
                            <Col md className="border-light border-end border-sm-none">
                                <h4 className="mb-0">R$ {converter(String(summery ? summery?.["6to15"] : "0.00)"))}</h4>
                                <span className="d-block text-secondary">6 {t("to")} 15 {t("NonPayment.days")}</span>
                            </Col>
                            <Col md className="border-light border-end border-sm-none">
                                <h4 className="mb-0">R$ {converter(String(summery ? summery?.["16to30"] : "0.00)"))}</h4>
                                <span className="d-block text-secondary">16 {t("to")} 30 {t("NonPayment.days")}</span>
                            </Col>
                            <Col md className="border-light border-end border-sm-none">
                                <h4 className="mb-0">R$ {converter(String(summery ? summery?.["31to60"] : "0.00)"))}</h4>
                                <span className="d-block text-secondary">31 {t("to")} 60 {t("NonPayment.days")}</span>
                            </Col>
                            <Col md>
                                <h4 className="mb-0">R$ {converter(String(summery ? summery?.["61to120"] : "0.00)"))}</h4>
                                <span className="d-block text-secondary">61 {t("to")} 120 {t("NonPayment.days")}</span>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default Overview
