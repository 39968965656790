import React, { useState, useRef, useEffect } from "react";
import { Col, InputGroup, FloatingLabel, Form, ListGroup } from "react-bootstrap";

interface SearchableServiceSelectProps {
  serviceList: any[];
  register: Function;
  index: number;
  errors: any;
  defaultValue: string;
  setValue: Function;
}

const SearchableServiceSelect: React.FC<SearchableServiceSelectProps> = ({
  serviceList,
  register,
  index,
  errors,
  defaultValue,
  setValue,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue) {
      const service = serviceList.find((item) => item.ID_PRODUTO_PRD === defaultValue);
      if (service) {
        setSearchTerm(service.ST_DESCRICAO_PRD);
      }
    }
  }, [defaultValue, serviceList]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target as Node) &&
        !inputRef.current?.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredServices = serviceList.filter((service) =>
    service.ST_DESCRICAO_PRD.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectService = (service: any) => {
    setSearchTerm(service.ST_DESCRICAO_PRD);
    setIsDropdownVisible(false);
    setValue(`services.${index}.ID_PRODUTO_PRD`, service.ID_PRODUTO_PRD);
  };

  return (
    <Col sm={12}>
      <InputGroup>
        <FloatingLabel controlId={`services.${index}.ID_PRODUTO_PRD`} label="Serviço">
          <Form.Control
            className={`mb-1 ${errors?.[`services.${index}.ID_PRODUTO_PRD`] && "is-invalid"}`}
            value={searchTerm}
            ref={inputRef}
            onFocus={() => setIsDropdownVisible(true)}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoComplete="off"
          />
          {isDropdownVisible && (
            <div
              ref={dropdownRef}
              className="shadow position-absolute z-index-5 bg-white overflow-auto"
              style={{ maxHeight: "200px", width: "100%" }}
            >
              {filteredServices.length > 0 ? (
                <ListGroup>
                  {filteredServices.map((service) => (
                    <ListGroup.Item
                      key={service.ID_PRODUTO_PRD}
                      className="p-2 cursor-pointer"
                      onClick={() => handleSelectService(service)}
                    >
                      {service.ST_DESCRICAO_PRD}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <ListGroup.Item className="text-danger text-center">
                  Nenhum serviço encontrado
                </ListGroup.Item>
              )}
            </div>
          )}
          <Form.Control.Feedback type="invalid">
            {errors?.[`services.${index}.ID_PRODUTO_PRD`]?.message}
          </Form.Control.Feedback>
        </FloatingLabel>
        <InputGroup.Text id="search_icon">
          <img
            src="./assets/images/icons/search.svg"
            alt="Search icon"
            style={{ width: "16px", height: "16px" }}
          />
        </InputGroup.Text>
      </InputGroup>
    </Col>
  );
};

export default SearchableServiceSelect;