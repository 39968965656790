import { DrawerBody } from "./DrawerBody";
import { DrawerFooter } from "./DrawerFooter";
import { DrawerHeader } from "./DrawerHeader";
import { DrawerRoot } from "./DrawerRoot";

export const Drawer = {
  Header: DrawerHeader,
  Body: DrawerBody,
  Root: DrawerRoot,
  Footer: DrawerFooter,
};
