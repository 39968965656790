import React from "react";

import { ContractsList, SubHeader, Summary } from "./components";
import { ContractsListProvider } from "./components/ContractsList/ContractListProvider";
import { SolveStatusModalProvider } from "./components/SolveStatusModal";
import { DimobProvider } from "./DimobProvider";

export function DimobPage() {
  return (
    <DimobProvider>
      <div className="content-wrapper">
        <SubHeader />
        <main className="container-fluid px-xl-5 px-md-4 mt-4 px-3 pt-6 pt-md-6 pb-xl-5 pb-md-4 pb-3">
          <section>
            <Summary />
          </section>
          <section>
            <ContractsListProvider>
              <SolveStatusModalProvider>
                <ContractsList />
              </SolveStatusModalProvider>
            </ContractsListProvider>
          </section>
        </main>
      </ div>
    </DimobProvider>
  );
}
