import axios from "axios";
import Swal from "sweetalert2";
import { fetchNotification, modal_reducer, insert_update_notifiy } from "../../../../redux/slice/notificationSlice";
import i18n from "../../../../i18n";
import { notificationService } from "../../../../services/notificationService";

// Function to toggle the "disabled" attribute of an element
function has_Attr_Data(item) {
    // Check if the element has the "disabled" attribute
    if (item.hasAttribute("disabled")) {
        // If the attribute is present, remove the "disabled" attribute
        item.removeAttribute("disabled");
    } else {
        // If the attribute is not present, set the "disabled" attribute
        item.setAttribute("disabled", "");
    }
}

let basicdata: any = "";
let pagedata: string = "";
let dispatchdata: any = "";

export const handleAction = async (e: any, action: string, page: string, basic: any) => {
    // console.log(action)
    basicdata = basic;
    pagedata = page;
    let { dispatch, stateData, modal_reducer } = basic;
    dispatchdata = dispatch;
    let currElement: any = (e.target).parentElement.parentElement.parentElement.parentElement;
    let disableElement: any = (e.target).parentElement.parentElement.parentElement.parentElement.parentElement;
        // Handle actions for "toedit" and "cancel"
    if (action == "toedit" || action == "cancel") {
        if (action == "toedit") {
              // Show the "toedit" elements and hide the "action" elements
            currElement.querySelector(".TOEDIT")?.classList.remove('d-block');
            currElement.querySelector(".TOEDIT")?.classList.add('d-none');
            currElement.querySelector(".ACTION")?.classList.remove('d-none');
            currElement.querySelector(".ACTION")?.classList.add('d-block');
        }
        if (action == "cancel") {
            // Show the "toedit" elements and hide the "action" elements
            currElement.querySelector(".TOEDIT")?.classList.remove('d-none');
            currElement.querySelector(".TOEDIT")?.classList.add('d-block');
            currElement.querySelector(".ACTION")?.classList.remove('d-block');
            currElement.querySelector(".ACTION")?.classList.add('d-none');
        }
 // Enable form controls
        disableElement.querySelectorAll(".card-body .removeInsertDisable.form-control").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable.form-select").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable  input").forEach((item) => {
            has_Attr_Data(item)
        });
    }
    // Handle other actions
    if (action !== "toedit" && action !== "cancel") {
        if (action == "edit") {
            let currentpage = document.querySelector("#CurrentPage");
            currentpage?.setAttribute("data-page", page);
            dispatchdata(modal_reducer(true));
        }

        if (action == "save") {
            let submit: any = disableElement.querySelector(".submitForm");
            submit.click();
        }
    }
}

export const handleSubmitData = (data: any, basic = basicdata, page = pagedata) => {
    let lang = i18n.language
    let type = "basic_data";
      // Show a confirmation dialog using Swal
    Swal.fire({
        title: lang == "en" ? "Update?" :"Atualizar",
        text: lang == "en" ? "Are you sure you want to update the message?" : "Deseja mesmo atualizar a mensagem?",
        // icon: 'success','info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: lang == "en"  ? "Change it":  "Alterar",
        cancelButtonText : lang == "en"  ? "Cancel" : "Cancelar"
    }).then(async (result) => {
        if (result.isConfirmed) {
            // Prepare the object to be dispatched
            let allobj = { data, type, page }
                // Get the object data for dispatching
            let objectData = `${page}_reducer`;
            // Dispatch the data using the basic reducer function
            dispatchdata(basic[`${objectData}`](allobj));
              // Show a success message using Swal
              notificationService.addNotificationRecords(allobj).then((res)=>{

              }).catch((error)=>{
                  console.log('catch-error',error)
              })
            Swal.fire({
                position: 'center',
                // icon: 'success','success',
                title: lang == "en"  ? "Your details have been saved successfully." : "Seus detalhes foram salvos com sucesso.",
                showConfirmButton: false,
                timer: 1000
            })
        }
    })
}

export const handleSubmitPopup = async (data: any, basic = basicdata, page = pagedata) => {
    let lang = i18n.language
     // Set the type and create the object for dispatch
    let type = "popdata";
    let allobj = { data, type, page }
    // Store the original value of page for later use
    let act = page;
    // Determine the page type based on the value of 'page'
    if (page.includes("contract")) {
        page = "contract";
    } else if (page.includes("insurance")) {
        page = "insurance";
    } else if (page.includes("gurantees")) {
        page = "gurantees";
    }
 // Dispatch the data and store the result in 'res'
    // console.log(data,"This is after submiting popdata")
    let res = await dispatchdata(basic[`${page}_reducer`](allobj));
    // Freeze the 'basic.stateData' object and create a deep copy
    Object.freeze(basic.stateData);
    let newobj = JSON.parse(JSON.stringify(basic.stateData));
     // Update the 'popdata' property based on the page type
    if (page.includes("contract") || page.includes("insurance") || page.includes("gurantees")) {
        let spltval = act.split('-');
        if (page.includes("contract")) {
            newobj[`${spltval[0]}`][`${spltval[1]}`][`${spltval[2]}`]["popdata"] = { ...res.payload.data };
        } else if (page.includes("insurance")) {
            newobj[`${spltval[0]}`][`${spltval[1]}`]["popdata"] = { ...res.payload.data };
        } else if (page.includes("gurantees")) {
            newobj[`${spltval[0]}`][`${spltval[1]}`]["popdata"] = { ...res.payload.data };
        }
    } else {
        newobj[`${page}`]["popdata"] = { ...res.payload.data };
    }
     // Dispatch the 'insert_update_notifiy' action and store the result in 'submitfinalres'
    let submitfinalres = await dispatchdata(insert_update_notifiy(data));
    // Check the type of the result and show a success or error message accordingly
    // console.log(submitfinalres, "THis is increeeeeeeee");
    if (submitfinalres.payload.data.type == "Success") {
        dispatchdata(modal_reducer(false));
        Swal.fire({
            position: 'center',
            // icon: 'success','success',
            title: lang == "en"  ? "Your details have been saved successfully." : "Seus detalhes foram salvos com sucesso.",
            showConfirmButton: false,
            timer: 1000
        })
    } else {
        // Show an error message
        Swal.fire({
            position: 'center',
            // icon: 'success','error',
            title: lang == "en" ?  "Something went wrong!" : "Algo deu errado"
    })
}
}

export const noNegativeValue = (e) => {
    // Check if the key pressed is a number (numpad or keyboard) or the backspace key
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8)) {
        // Return false to prevent entering negative values or non-numeric characters
        return false;
    }
}

export const fillModalwithThisData = (data: any) => {
    // console.log("From controller data", data)
}