import React, { useState, MouseEvent, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useForm }      from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, Table, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Card, ListGroup, Alert } from 'react-bootstrap';

import { setContractLabel,contractLabelOpen,setContractStNumber,setCheckedId,setContractStTitle,contractStatusOpen,contractOpen,contractsData,getTenants,getInsurance } from "../../../../redux/slice/contractDataSlice"; 
import * as Yup from 'yup';
import { useTranslation }          from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';
import { AppDispatch,RootState }   from '../../../../redux/store';
import { getBraches }              from '../../../../redux/slice/branchSlice';
import Loader                      from '../../../Profile/Company/Loader';
import Checkbox                    from '../../Contracts/Common/Checkbox';
import { ComProps, PostponeReadjustmentForm }     from '../../../Interface';
import { userService } from '../../../../services/userService';
import { openErrorbox } from '../../Contracts/Common/actionPerform';

const ContractsReadjust = (PostponeReadjustmentForm) => {
    const { newContractShow,contractData,tenantsData,insuranceData,status } = useSelector((state: RootState) => state.contractData)
    const [ t ]  = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [ isCheckAll,setIsCheckAll ] = useState(false);
    const [ isCheck,setIsCheck ]       = useState<any[]>([]);
    const [ finalRentValue,setfinalRentValue ] = useState("");
    const [ reIndexName,setReIndexName ] = useState<any>({});
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100"
      });
    const { branchList } = useSelector((state: RootState) => state.branch)
    useEffect(() => {
        dispatch(getBraches(branchState));
        // dispatch(getManager());
        dispatch(getTenants());
        // dispatch(getInsurance({ "insurance":"","start":0, "end":1000 }));
        getContractsData({ "start": 0,"end": 10 });              
    }, [dispatch])
    const newContractOpen   = () => { 
        dispatch(contractOpen(true)); 
    }
    const getContractsData  =  async(contData) => {
        let response = await dispatch(contractsData(contData)) 
    }
    const [readjIndexes, setReADJIndexes] = useState<Array<any>>([]);
    const [ContReadjust, setContractsReadjust] = useState<Array<any>>([]);

    // Modal's initiate
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    // Mobile filters modal
    const mobileFiltersClose = () => setShow(false);
    const mobileFiltersShow = () => setShow(true);
    // simulate contracts modal
    const simulateContractsModalClose = () => setShow2(false);
    const simulateContractsModalShow = () => setShow2(true);
    // postpone readjustment modal
    const postponeReadjustmentModalClose = () => setShow3(false);
    const postponeReadjustmentModalShow = (e: MouseEvent<HTMLButtonElement>) => {
        setShow3(true);
        e.preventDefault();
        var RowId: any = e.currentTarget.getAttribute('data-rowid');
        setReIndexName({rowId: RowId, indxName : '', indxValue : ''});
    }
    // readjust contracts modal
    const readjustContractsModalClose = () => setShow4(false);
    const readjustContractsModalShow = (e: MouseEvent<HTMLButtonElement>) => {
        setShow4(true);
        e.preventDefault();
        var rowId: any = e.currentTarget.getAttribute('data-rowid');
        var rent: any = e.currentTarget.getAttribute('data-rent');
        calculateRentvalue(rowId, rent);
    }
    // Readjustment indices modal
    const ReadjustmentIndicesModalClose = () => setShow5(false);
    const ReadjustmentIndicesModalShow = () => setShow5(true);

    const [state, setState] = useState({
        contract     : "",
        status       : "",
        branch       : "",
        landlord     : "",
        tenants      : "",
        bankAccount  : "",
        paymentSplit : "",
        manager      : "",
        start        : 0,
        end          : 10
    })
    const [open,setOpen] = useState(false);
    const searchContract = (evt) => {
        const name  = evt.target.name;
        const value = evt.target.value;
        setState({
          ...state,
          [name]: value
        })
        setOpen(false)
    }
    if( !open && (state.bankAccount || state.branch || state.contract || state.landlord || state.manager || state.paymentSplit || state.status || state.tenants)){
        setOpen(true);
        getContractsData(state)
    }
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(contractData.data.map(
            li => li.id)
        );
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e =>{
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck,parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }
    };
    const groupform_validationSchema = Yup.object().shape({
        // rentValue : Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // igpmValue : Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
        // useFixedValue : Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // useFixedValueReadjustment : Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")), 
    });
    var frm = { resolver: yupResolver(groupform_validationSchema) }
    if( show3 ){
        const form_validationSchema = Yup.object().shape({
            postponeReadtoDate: Yup.string().trim().nullable()
                .required(t("requiredMmessage.fieldRequired")),
                observation: Yup.string().trim().nullable()
                .required(t("requiredMmessage.fieldRequired"))
        });
        frm = { resolver: yupResolver(form_validationSchema) }
    }
    if( show2 ){
        const form_IndexesSchema = Yup.object().shape({ });
        frm = { resolver: yupResolver(form_IndexesSchema) }
    }
    const {register, handleSubmit,setValue, reset,formState: { errors } } = useForm<PostponeReadjustmentForm>(frm);
    const postReadjustment = (data: PostponeReadjustmentForm) => { 
    }

    const getAllReadjustmentIndices = (param) => {
        userService.getAllUsers({ userId: "", start:0 , end: 1000 }).then(res => {
			if (res.data) {
				setReADJIndexes(res.data);
			}
			else { setReADJIndexes([]); }
		}).catch(err => {
			console.log("err", err)
		});
    }

    useEffect(() => { 
        calculateRentvalue(null,''); 
        getAllReadjustmentIndices({});

        let ContractsReadjust = [
            {  month : 'February', ContractsCount : '39'  },
            {  month : 'March', ContractsCount : '43'  },
            {  month : 'April', ContractsCount : '36'  }
        ]
        setContractsReadjust(ContractsReadjust);
    }, []);

    // On submit Re
	const readjustFormContracts = (data: PostponeReadjustmentForm) => {
		// console.log('Readjust Form Contracts...');
		// console.log(JSON.stringify(data))
	}

    // Update Readjustment Indexes
	const updateReIndexes = (data: PostponeReadjustmentForm) => {
		// console.log('Readjust ------- Form Contracts...');
		// console.log(JSON.stringify(data))
	}

    const calculateRentvalue = (RowId, action) => {
        let changevalue = RowId;
        if( reIndexName.rowId ){
            RowId = parseInt(reIndexName.rowId);
        }
        // re get user value by id
        if( contractData && contractData.data && contractData?.data.length ){
            const conDetails = (contractData.data).filter(contract => contract.id == parseInt(RowId));
            // conDetails[0]['lasrReadjustment'] = 'IPSA'
            // conDetails[0]['lasrReadjustmentValue'] = '10.00'
            let indexName = 'IPSAA'; let indexValue = '10.00'
            if( action == 'inputfield' ){
                indexValue  = changevalue // Coming from Onchange input field
            }
            let rentAmt = conDetails[0]?.rent_amount;
            let percntcal : any = ( rentAmt * parseFloat(indexValue) )/ 100;
            let ttl = parseFloat(rentAmt) + parseFloat(percntcal);
            setReIndexName({rowId: RowId, indxName : indexName, indxValue : indexValue});
            setfinalRentValue(`${rentAmt} + ${percntcal} =  ${ ttl }`);
        }
    }
    const getReIndexes = () => {
        //console.log('REtreive Indexes...')
    }

    const updateReindexValue = (value, lnIndex) => {
        let mti = readjIndexes;
        mti[lnIndex]['ID_USUARIO_USU'] = value;
        mti.forEach((item, i) => { if (i == lnIndex) {
                setReADJIndexes([ ...readjIndexes,(mti[i].ID_USUARIO_USU = value) ]); 
            }
        });
    };

    return (
        <section className="content-wrapper content-wrapper-primary" data-page="Contracts readjust">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end filters border-bottom py-3 px-xl-5 px-md-4 px-3">
               <div className="w-100 mb-xl-4">
                <Row className="gx-xl-5 justify-content-between align-items-center">
                <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <Link to={'/generate_charges'}>
                                    <img src="assets/images/icons/arrow-left.svg" alt="Filter" className="h-16" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2">
                                    <h1 className="mb-0 h4 lh-base text-truncate">Contracts readjust</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" onClick={mobileFiltersShow} className="text-nowrap">
                                        Filters
                                        <img src="assets/images/icons/filter-icon.svg" alt="Filter" className="ms-1 h-12" />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li> */}
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                    </a>
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Form className="d-xl-block d-none w-100" action="" method="">
                    <Row className="gx-2 gx-xxl-3">
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Status
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="px-4">
                                    <div className="py-2">
                                        To readjust
                                    </div>
                                    <div className="py-2">
                                        Readjusted
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Contract
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel label="Contracts">
                                        <Form.Select aria-label="Contracts">
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Branch
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel label="Branches">
                                        <Form.Select aria-label="Branches">
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Tenant
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel label="Tenants">
                                        <Form.Select aria-label="Tenants">
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Owner
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel label="Managers">
                                        <Form.Select aria-label="Managers">
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Billing method
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel label="Billing method">
                                        <Form.Select aria-label="Billing method">
                                            <option value="">{t("formLabel.select")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Form>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/* <!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- overview -->*/}
                <div className="mb-lg-4 mb-3">
                    <Row className="gy-3 gy-lg-4 h-100">
                        <Col xl={6}>
                            <Card className="h-100">
                                <Card.Header>Contracts to readjust</Card.Header>
                                <Card.Body className="d-flex flex-column">
                                    <Row className="gy-3 gx-xxl-5 gx-md-4">
                                        <Col sm={4} className="border-end border-light border-xs-none">
                                            <span className="d-block text-dark h3 lh-1 mb-2">39</span>
                                            <span className="text-secondary d-block">Febuary</span>
                                        </Col>
                                        <Col sm={4} className="border-end border-light border-xs-none">
                                            <span className="d-block text-dark h3 lh-1 mb-2">43</span>
                                            <span className="text-secondary d-block">March</span>
                                        </Col>
                                        <Col sm={4}>
                                            <span className="d-block text-dark h3 lh-1 mb-2">36</span>
                                            <span className="text-secondary d-block">April</span>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-auto">
                                    <Button variant="link" onClick={simulateContractsModalShow} className="p-0 mt-2 mt-lg-3 fw-bold" size="sm">Simulate &amp; readjust</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card className="h-100">
                                <Card.Header>Readjustment indices</Card.Header>
                                <Card.Body className="d-flex flex-column">
                                    <Row className="gy-3 gx-xxl-5 gx-md-4">
                                        { readjIndexes && readjIndexes.length > 0 ? readjIndexes.map((item, i:number) => (
                                            i  < 4 && 
                                            <Col sm={3} className="border-end border-light border-xs-none">
                                            <Link to={'/table_index'}>
                                            <span className="d-block text-dark h3 lh-1 mb-2">{item.ID_USUARIO_USU}</span>
                                                <span className="text-secondary d-block">{item.ST_NOME_USU}</span>
                                            </Link>
                                            </Col>
                                        ) ) : 
                                        <Col sm={3} className="border-end border-light border-xs-none"> 
                                        <span className="text-secondary d-block">No record Found</span>
                                        </Col> 
                                        }
                                    </Row>
                                    <div className="text-center mt-auto">
                                    <Button variant="link" onClick={ReadjustmentIndicesModalShow} className="p-0 mt-2 mt-lg-3 fw-bold" size="sm">See all indexes</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                {/* <!-- /overview -->*/}
                {/*<!-- Table start from here -->*/}
                {
                (status == "loading") ?
                    <Loader />
                :
                <Card>
                <Card.Body className="pb-1">
                <Table responsive className="table-custom table-custom-bordered mb-0">
                    <thead>
                        <tr>
                            <th className="w-24">
                            { 
                                contractData.data && contractData.data.length > 0 ?
                                    <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                :''
                            }
                            </th>
                            <th>Last Readjustment</th>
                            <th>Contract</th>
                            <th>Rent amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { contractData.data && contractData.data.length > 0 ? contractData.data.map((item) => (
                            <tr key={item.id}>
                                <td className={"check_Checkbox"}>
                                    <Checkbox key={item.id} type="checkbox" name={item.id} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                </td>
                                <td>
                                    Mar/2022
                                    <span className="d-block">Test</span>
                                </td>
                                <td>
                                    <span className="text-dark-70">Contract 310/1 </span><span
                                        className="mx-1"><img src="assets/images/icons/home_g.svg" /><span
                                            className="ps-1">{item.address}, {item.city}, {item.zipcode}, {item.state}</span></span>
                                    <span className="d-block"><img src="assets/images/icons/user_g.svg" /><span
                                        className="ps-1">{item.complement}</span></span>
                                    <span className="d-block"><img src="assets/images/icons/key_g.svg" /><span
                                        className="ps-1">Dudu</span></span>
                                </td>
                                <td>
                                <span className="text-nowrap d-block">{item.rent_amount}</span>
                                    <span className="text-dark-70 text-nowrap d-block">Adm fee R$ 89.06</span>
                                </td>
                                <td className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                    <Button variant="link" className="bg-transparent sm p-0 me-2 me-xxl-4 me-lg-3" onClick={readjustContractsModalShow} data-rowid={item.id} data-rent={item.rent_amount} >
                                     <span className="img-primary cursor-pe icon"><img src="assets/images/icons/chart-line2.svg" alt="chart line icon" className="h-16"/></span>
                                    </Button>

                                    <Button variant="link" className="bg-transparent sm p-0 me-2 me-xxl-4 me-lg-3" onClick={postponeReadjustmentModalShow} data-rowid={item.id}>
                                       <span className="img-primary cursor-pe icon"> <img src="assets/images/icons/calendar-regular.svg" alt="Calendar icon" className="h-16" /></span>
                                    </Button>
                                    <Link to={"/contract_details"} className="fw-bold text-nowrap me-2 me-xxl-4 me-lg-3">See contract</Link>
                                    <Link to={"/property_detail"} className="fw-bold text-nowrap">See property</Link>
                                    </div>
                                </td>
                            </tr>
                        )) : 
                            <tr>
                                <td colSpan={5}>
                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
                </Card.Body>
                </Card>
                }
                {/*<!-- /Table end -->*/}
               <div className="mt-lg-4 mt-3"> More Items - Auto - All Items</div>
                <p className="text-secondary d-block py-2">Listing 50 properties.</p>
                {/* <!-- Nav listing start from here -->*/}
                <ul className="list-inline mb-0">
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm">
                                Mark
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleSelectAll} href="">{t('formLabel.all')}</Dropdown.Item>
                                    <Dropdown.Item onClick={noneHandleSelectAll} href="">{t('formLabel.none')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">With tagged:</span></li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button variant="light" onClick={readjustContractsModalShow} size="sm" data-rowid="" data-rent="">Readjust</Button>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button variant="light" onClick={postponeReadjustmentModalShow} size="sm">Postpone readjustment</Button>
                    </li>
                </ul>
                {/*<!-- /Nav listing  end -->*/}
            </main>
            {/*<!-- /Main content end -->*/}
            {/* Filter modal for mobile screens start here */}
            <Modal show={show} onHide={mobileFiltersClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Status</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label="Status">
                                            <Form.Select aria-label="Status">
                                                <option value="">{t('dropdownItem.select')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Contracts</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label="Contracts">
                                            <Form.Select aria-label="Contracts">
                                                <option value="">{t('dropdownItem.select')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Branches</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label="Branches">
                                            <Form.Select aria-label="Branches">
                                                <option value="">{t('dropdownItem.select')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Tenants</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label="Tenants">
                                            <Form.Select aria-label="Tenants">
                                                <option value="">{t('dropdownItem.select')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Owners</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label="Owners">
                                            <Form.Select aria-label="Owners">
                                                <option value="">{t('dropdownItem.select')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Billing method</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label="Billing method">
                                            <Form.Select aria-label="Billing method">
                                                <option value="">{t('dropdownItem.select')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={mobileFiltersClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={mobileFiltersClose}>
                            To save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Filter modal for mobile screens end */}
            {/* Simulate contracts modal start from here */}
            <Modal show={show2} onHide={simulateContractsModalClose}>
                <Form onSubmit={handleSubmit(updateReIndexes)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Readjust contracts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert className="mb-lg-4 mb-3" variant="warning">
                            There are adjustment indexes that have not been updated. <Link to="" onClick={getReIndexes}>Click here to update.</Link>
                        </Alert>
                        <Row className="gy-lg-4 gy-3">
                            { readjIndexes && readjIndexes.length > 0 ? readjIndexes.map((item,index) => (
                                item.ST_NOME_USU != undefined  &&  
                                <Col xl={12}>
                                    <FloatingLabel label={`${item.ST_NOME_USU} %`}>
                                        <Form.Control type="text" value={item.ID_USUARIO_USU} placeholder={`${item.ST_NOME_USU} %`} onChange={(e) => updateReindexValue(e.target.value,index )} data-id={`${item.ID_USUARIO_USU}`} />
                                    </FloatingLabel> 
                                </Col>
                            )) : '' }  
                        </Row>
                        <div className="text-center my-lg-4 my-3">
                            <h4 className="mb-0">3 will be readjusted</h4>
                            <Link to="" onClick={getReIndexes} className="fw-bold">Simulate readjustment</Link>
                        </div>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>More options</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3 align-items-center">
                                        <Col sm={5}>
                                            <FloatingLabel label="Notification type">
                                                <Form.Select aria-label="Notification type"
                                                {...register('notificationType')}>  
                                                    <option value="1">Email and letter</option>
                                                    <option value="0">Letter</option>
                                                    <option value="2">do not notify</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col sm={7}>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" {...register('useFixedValueReadjustment')} label="Use fixed value for readjustment" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={simulateContractsModalClose}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary" name="save" onClick={openErrorbox} >Readjust</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Simulate contracts modal end */}


            {/* WORKING */}
            {/* Postpone readjustment modal start from here */}
            <Modal show={show3} onHide={postponeReadjustmentModalClose}>
                <Form onSubmit={handleSubmit(postReadjustment)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Postpone readjustment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel className="mb-lg-4 mb-3" label="Postpone readjustment to">
                            <Form.Control type="date" className={`${errors.postponeReadtoDate ? 'is-invalid' : ''}`}  {...register('postponeReadtoDate')} placeholder="Postpone readjustment to" /> 
                        </FloatingLabel>
                        <FloatingLabel label={t('formLabel.observation')}>
                            <Form.Control as="textarea" className={`${errors.observation ? 'is-invalid' : ''}`} placeholder="Observation" {...register('observation')}  />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={postponeReadjustmentModalClose}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary" name="save" >To save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Postpone readjustment modal end */}

            {/* Readjust contracts modal start from here */}
            <Modal show={show4} onHide={readjustContractsModalClose}>
                <Form onSubmit={handleSubmit(readjustFormContracts)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Readjust contracts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert className="mb-lg-4 mb-3" variant="warning">
                            The IGPM test 2 index is not up to date. <Link to={''}>Click here to update.</Link>
                        </Alert>
                        <Row className="gy-lg-4 gy-3 align-items-center">
                            <Col sm={4}>
                                <FloatingLabel label="Rent value">
                                    <Form.Control type="text" value="1.000,00" disabled placeholder="Rent value" />
                                </FloatingLabel>
                            </Col>
                            <Col sm={4}>
                                <FloatingLabel label={reIndexName.indxName + ' test 2 (%)'} >
                                    <Form.Control type="text" value={reIndexName.indxValue} onChange={(e) => calculateRentvalue(e.target.value,'inputfield')} placeholder={reIndexName.indxName + ' test 2 (%)'} />
                                </FloatingLabel>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="formBasicCheckbox2">
                                    <Form.Check type="checkbox" {...register('useFixedValue')} label="Use fixed value" />
                                </Form.Group>
                            </Col>
                        </Row> 
                        <h4 className="text-center my-lg-4 my-3">{finalRentValue} 
                        <span className="fs-12"> Rent value after adjustment</span></h4>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>More options</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3 align-items-center">
                                        <Col sm={5}>
                                            <FloatingLabel label="Notification type">
                                                <Form.Select aria-label="Notification type" {...register('notificationType')}>  
                                                <option value="1">Email and letter</option>
                                                <option value="0">Letter</option>
                                                <option value="2">do not notify</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col sm={7}>
                                            <Form.Group controlId="formBasicCheckbox3">
                                                <Form.Check {...register('useFixedValueReadjustment')} type="checkbox" label="Use fixed value for readjustment" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={readjustContractsModalClose}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary" name="save" onClick={openErrorbox}>Readjust</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Readjust contracts modal end */}
            {/* Readjustment indices modal start from here */}
            <Modal show={show5} onHide={ReadjustmentIndicesModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Readjustment indices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup variant="flush">
                        { readjIndexes && readjIndexes.length > 0 ? readjIndexes.map((item) => (
                            item.ST_NOME_USU != undefined && 
                            <ListGroup.Item>{item.ST_NOME_USU} : {item.ID_USUARIO_USU}</ListGroup.Item>
                        )) : '<p></p>' }
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={ReadjustmentIndicesModalClose}>
                        {t("button.close")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Readjustment indices modal end */}
        </section>
    )
}

export default ContractsReadjust