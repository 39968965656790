export const ACCOUNT_TYPES = {
  "1": "Conta corrente",
  "2": "Caixa",
  "3": "Investimento",
};

export const RECEIVIMENT_METHOD_TYPES = {
  0: "Kenlo pay",
  1: "Homologada",
  2: "Caixa manual",
};
