import { insertReverseCharge,reverseIdSet,reverseOpen,reverseClose } from "../../../../redux/slice/expenditureDataSlice";
import { Modal,Form,Button,Alert }  from 'react-bootstrap';
import React,{ useState,useEffect } from 'react';
import { useTranslation }           from "react-i18next";
import { useDispatch,useSelector }  from 'react-redux';
import { AppDispatch,RootState }    from '../../../../redux/store';
import { reverseForm }              from '../../../Interface';
import { useForm }                  from "react-hook-form";
import Swal                         from 'sweetalert2';
import { incomeService }            from '../../../../services/incomeServices';

const ReverseCharge = (props,reverseForm) => {
    const { reverseId,reverseShow } = useSelector((state: RootState) => state.expenditureData)
    const dispatch              = useDispatch<AppDispatch>();
    const [double,setDouble]    = useState(false);
    const [t]                   = useTranslation();
    const [status, setStatus]   = useState("");
    const { getChargesSummery } = props;
    const { register,handleSubmit,setValue,getValues,reset,formState:{ errors } } = useForm<reverseForm>();
    const onSubmit = async(data: reverseForm, e: any) => {
        e.preventDefault();
        setDouble(true) 
        let response = await dispatch(insertReverseCharge(data))  
        if(typeof response.payload !=="undefined" && (response.payload.resCode===412 || response.payload.resCode===414)){
            setDouble(false);
            reverseClosed();
            Swal.fire({
                icon  : 'success',
                title : t("Sweetalert.Success"),
                text  : t("Sweetalert.chargereverse"),
                confirmButtonText: "OK",
                //timer : 2000
            })
        }
        else {
            setDouble(false)
            Swal.fire({
                title : t("Sweetalert.Error"),
                text  : `${t("Sweetalert.somethingWent")}`,
                icon  : "error",
                confirmButtonText: "OK",
                //timer : 2000
            })
        }        
    }
    useEffect(()=>{
        if(reverseId){
            setValue("id",reverseId)
            dispatch(reverseOpen(true));
        }        
    },[dispatch,reverseId]);    
    
    const reverseClosed = () => {
        setDouble(false);
        reset();
        dispatch(reverseIdSet(""));
        dispatch(reverseClose(false));
        getChargesSummery();
    }
    return (
        <Modal show={reverseShow} onHide={reverseClosed}>
            <Form onSubmit={handleSubmit(onSubmit)} id="receivedReverseCharge">
                <input type="hidden" {...register("id")} />
                <Modal.Header closeButton>
                    <Modal.Title>{t("ReceiveandReceived.Reversecharge")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="warning">
                        {t("ReceiveandReceived.Doyoureallywanttoreversethecharges")}?
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={reverseClosed}>
                        {t("button.close")}
                    </Button>
                    <Button disabled={double} type="submit" variant="primary" >
                        {t("button.toSave")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ReverseCharge
