import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { AccountType } from "./types";

type BankAccountContextType = {
  accountVariation: string;
  setAccountVariation: Dispatch<SetStateAction<AccountType>>;
  accountType: string;
  setAccountType: Dispatch<SetStateAction<AccountType>>;
  selectedBank: string;
  setSelectedBank: (value: string) => void;
  shouldHomologate: boolean;
  setShouldHomologate: (value: boolean) => void;
  resetContextValues: () => void;
};

const BankAccountContext = createContext({} as BankAccountContextType);

export function BankAccountProvider({ children }: { children: ReactNode }) {
  const [accountVariation, setAccountVariation] = useState(
    AccountType.CORRENTE
  );
  const [accountType, setAccountType] = useState(AccountType.CORRENTE);
  const [selectedBank, setSelectedBank] = useState("");
  const [shouldHomologate, setShouldHomologate] = useState(false);

  function resetContextValues() {
    setAccountVariation(AccountType.CORRENTE);
    setAccountType(AccountType.CORRENTE);
    setSelectedBank("");
    setShouldHomologate(false);
  }

  const value = () => ({
    accountVariation,
    setAccountVariation,
    accountType,
    setAccountType,
    selectedBank,
    setSelectedBank,
    shouldHomologate,
    setShouldHomologate,
    resetContextValues,
  });

  return (
    <BankAccountContext.Provider value={value()}>
      {children}
    </BankAccountContext.Provider>
  );
}

export function useBankAccount() {
  const bankAccount = useContext(BankAccountContext);

  if (!bankAccount) {
    throw new Error("useBankAccount must be used within a BankAccountProvider");
  }

  return bankAccount;
}
