import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { gettoken } from "../../Api/Contact";
import axios from "axios";
let companyV2_URL = require("../../Api/Contact").config.companyV2_URL;
let notificationsV2URL = require("../../Api/Contact").config.notificationsV2URL;
let connectionName: any = "";
if (localStorage.getItem("userData")) {
  connectionName = localStorage.getItem("userData");
}
let initialState: any = {
  billingRuleState: {
    email_first_notification: {},
    email_first_day: {},
    email_first_period: {},
    email_second_notification: {},
    email_second_day: {},
    email_second_period: {},
    email_third_notification: {},
    email_third_day: {},
    email_third_period: {},
    email_final_notification: {},
    email_final_period: {},
    letter_first_notification: {},
    letter_first_day: {},
    letter_second_notification: {},
    letter_second_day: {},
  },
  show: false,
};

const BillingRuleSlice = createSlice({
  name: "BillingRule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Getting all services

    builder
      .addCase(fetchBillingRule.pending, (state, { payload }) => {
        state["loading"] = true;
        state.billingRuleState = {};
      })
      .addCase(fetchBillingRule.fulfilled, (state, { payload }) => {
        state["loading"] = false;
        state.billingRuleState = payload[0];
      })
      .addCase(fetchBillingRule.rejected, (state, { payload }) => {
        state["loading"] = false;
        state.billingRuleState = {};
      });
  },
});

export const {} = BillingRuleSlice.actions;
export default BillingRuleSlice.reducer;

function onBasicChange(state: any, page: string, data: any, type: any) {
  //Update work is here
  let splitdata = page.split("-");
  if (splitdata.length === 3) {
    if (type === "popdata") {
      state[`${splitdata[0]}`][`${splitdata[1]}`][`${splitdata[2]}`][
        `${type}`
      ] = data;
    } else {
      state[`${splitdata[0]}`][`${type}`] = data;
    }
  } else if (splitdata.length === 2) {
    if (type === "popdata") {
      state[`${splitdata[0]}`][`${splitdata[1]}`][`${type}`] = data;
    } else {
      state[`${splitdata[0]}`][`${type}`] = data;
    }
  } else {
    state[`${page}`][`${type}`] = data;
  }
}

export const fetchBillingRule = createAsyncThunk(
  "notify/fetch_billing_rule",
  async (_, thunkAPI) => {
    try {
      let user_id = gettoken.userId;
      let token:any = localStorage.getItem("tokenV2");
      const response = await axios({
        method: "post",
        url: `${notificationsV2URL}/notifications/fetchBillingRule`,
        headers: {
          "x-auth-token": token,
          "Content-type": "application/json",
          "connection-name": connectionName,
        },
        data: { user_id },
      });

      let ResponseData = response?.data?.data;
      return await ResponseData;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: "Error Occur" });
    }
  }
);

export const insert_update_notifiy = createAsyncThunk(
  "notify/=insert_update_notification",
  async (postData: any, thunkAPI) => {
    try {
      let newdata = {
        user_id: gettoken.userId,
        post_data: postData,
      };
      let token:any = localStorage.getItem("tokenV2");
      const response = await axios({
        method: "put",
        url: `${companyV2_URL}/notify`,
        headers: {
          "x-auth-token": token,
          "Content-type": "application/json",
          "connection-name": connectionName,
        },
        data: newdata,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: "Error Occur" });
    }
  }
);

export const onCancelForm = createAsyncThunk(
  "notify/cancel_notification_update",
  async (postData: any, thunkAPI) => {
    try {
      return postData;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: "Error Occur" });
    }
  }
);
