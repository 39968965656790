import { Form, Accordion, Modal, Alert, Button } from 'react-bootstrap';
import { newLiquidateClose, insertLiquidate } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { newLiquidateForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
const LiquidateService = (newLiquidateForm) => {
  const { newLiquidateShow, liquidateType, liquidateTitle, status } = useSelector((state: RootState) => state.contractData)
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const liquidateClose = () => {
    dispatch(newLiquidateClose(false));
    reset();
  }
  const liquidateValidation = Yup.object().shape({});
  let frm = {
    resolver: yupResolver(liquidateValidation)
  }
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<newLiquidateForm>(frm);
  const onSubmit = async (data: newLiquidateForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    let response = await dispatch(insertLiquidate(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:t("Sweetalert.successget"),
        confirmButtonText:"OK",
        timer:2000})
      liquidateClose();
    }
    else {
      setDouble(false)
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }
  return (
    <>
      {/* <!-- New liquidate modal start from here --> */}
      <Modal show={newLiquidateShow} onHide={liquidateClose}>
        <Modal.Header closeButton>
          <Modal.Title>{liquidateTitle == "Liquidate" ?
            t("expenditure.liquidate")
            : liquidateTitle == "To throw" ?
              t("expenditure.tothrow")
              : liquidateTitle
          }</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="d-flex align-items-center">
              <Form.Group className="me-2 me-lg-3" style={{ display: liquidateType ? "block" : "none" }}>
                <Form.Check type="checkbox" {...register('settleDueDate')} label={t("expenditure.settleontheduedate")} />
              </Form.Group>
              <Form.Group className="me-2 me-lg-3" style={{ display: liquidateType ? "block" : "none" }}>
                <Form.Check type="checkbox" {...register('printReceipt')} label={t("expenditure.printreceipt")} />
              </Form.Group>
              <Form.Group style={{ display: liquidateType ? "none" : "block" }}>
                <Form.Check type="checkbox" {...register('launchExpiration')} label={t("expenditure.launchontheexpirationday")} />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={liquidateClose}>
              {t("close")}
            </Button>
            <Button disabled={double} type="submit" variant="primary">
              {liquidateTitle == "Liquidate" ?
                t("expenditure.liquidate")
                : liquidateTitle == "To throw" ?
                  t("expenditure.tothrow")
                  : liquidateTitle}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New liquidate modal end --> */}
    </>
  )
}
export default LiquidateService;