import React, { useEffect, useState } from 'react';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { addTaxation, addNewTaxation, Newtaxclose, newTaxCodeModal, Edit_taxation ,Update_Taxation, getTaxation } from "../../../../redux/slice/taxationSlice";
import { Modal, Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { numericOnly } from '../../../Pages/Contracts/Common/actionPerform';
import { converter, convertToNumber, formatNumber } from '../../../constants/utils';
import i18n from '../../../../i18n';

const NewTaxCode = ({ editId, showTaxation }) => {
    // This line initializes the useDispatch hook and assigns it to the dispatch variable
    const dispatch = useDispatch<AppDispatch>();
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation();
    const [valueCon, setValueCon] = useState('')
    // used it to show validation error on form
    const Schema = yup.object().shape({
        CNAE: yup.string().required(t("requiredMmessage.fieldRequired")),
        servicecode: yup.string().required(t("requiredMmessage.fieldRequired")),
        ISSQNrate: yup.string().required(t("requiredMmessage.fieldRequired")),
        description: yup.string().required(t("requiredMmessage.fieldRequired")),
        taxcode: yup.string().required(t("requiredMmessage.fieldRequired"))
    })
    // The useForm hook is used to handle form registration, submission, and validation.
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm<newTaxCodeModal>({
        resolver: yupResolver(Schema),
    });
    const [ double,setDouble ] = useState(false);
    const [ loader_on,setloader_on ] = useState(false);

    // Used Redux-Toolkit, Got the data and Destructors the data from taxationSlice inside the redux folder
    const { show, status, taxadata } = useSelector((state: RootState) => state.taxa)
    // Pop up hide of new taxa service code on click
    const handleClose = () => {
        dispatch(Newtaxclose())
        setDouble(false);
        setloader_on(false);
    }
    //Getting the data shown when the page is rendered for the first time --main bank account and listing all bank
    useEffect(() => {   
        (async () => {
           if(showTaxation === false){
            let data = await dispatch(Edit_taxation(editId))
            if(data?.payload?.data.length>0){
            const fields: string[] = ['CNAE', 'id', 'servicecode', 'description', 'taxcode','payINSS', 'payIRRF', 'incurfederal'];
            fields?.forEach((field: any) => setValue(field, data?.payload.data?.[0]?.[field]));
            setValue('ISSQNrate', converter(String(data?.payload.data?.[0].ISSQNrate)))
            }
           }else{
            reset()
           }
        })();
    }, [dispatch, editId, showTaxation, i18n.language])
    // Used to added new taxation service code data and submit
    const onSubmit = async (data: any,) => {
        data["cnpj"] = taxadata[0].CNPJ
        let ISSQNrate_val: any = convertToNumber(valueCon ? valueCon : String(data.ISSQNrate));
        data["ISSQNrate"] = ISSQNrate_val;
        setDouble(true);
        setloader_on(true);
        if (data.id !== "" && data.id !== undefined) {
            let res = await dispatch(Update_Taxation(data))
            if (res.payload.type == "Success") {
                // Show a success message using Swal
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    showConfirmButton: false,
                    timer: 1000
                })          
                dispatch(getTaxation())
                handleClose()
            } else {
                // Show an error message
                Swal.fire({
                    // icon: 'success','error',
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    showConfirmButton: true,
                })
            }
        } else {
            let res = await dispatch(addNewTaxation(data))
            if (res.payload.type == "Success") {
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    showConfirmButton: false,
                    timer: 1000
                })
                dispatch(getTaxation())
                reset()
                handleClose()
            } else {
                Swal.fire({
                    // icon: 'success','error',
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    showConfirmButton: true,
                })
            }
        }

    };
    const handleISSQNrate = (e:any) =>{
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if(value !== ""){
            setValue("ISSQNrate", formatNumber(value))
            setValueCon(value)
        }else{
            setValue("ISSQNrate", "") 
        }
    }

    return (
                <>
                    {/* New service tax code modal start from here */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title> {showTaxation == true ? t("taxation.newTaxservice") : t("taxation.editTaxation")}</Modal.Title>
                        </Modal.Header>
                        <Form onSubmit={handleSubmit(onSubmit)} id="taxationFrm">
                            <Modal.Body>
                                <Row className="gy-lg-4 gy-3">
                                    <Col md={12}>
                                        <Form.Check inline type="checkbox" className={`${errors.incurfederal ? 'is-invalid' : ''}`} {...register('incurfederal')} id="inlineCheckbox1" label={t("taxation.Incurfederal")} />
                                        <Form.Check inline type="checkbox" className={`${errors.incurfederal ? 'is-invalid' : ''}`} {...register('payIRRF')} id="inlineCheckbox2" label={t("taxation.PayIRRF")} />
                                        <Form.Check inline type="checkbox" className={`${errors.incurfederal ? 'is-invalid' : ''}`} {...register('payINSS')} id="inlineCheckbox3"  label={t("taxation.PayINSS")} />
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel controlId="CNAE" label={t("taxation.CNAE")+ " *"}>
                                            <Form.Control type="text" className={`${errors.CNAE ? 'is-invalid' : ''}`} placeholder="CNAE" {...register("CNAE")} />
                                            <Form.Control.Feedback type="invalid">{errors.CNAE?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={8}>
                                        <FloatingLabel controlId="serviceCode" label={t("taxation.serviceCode")+ " *"}>
                                            <Form.Control type="text" className={`${errors.servicecode ? 'is-invalid' : ''}`} placeholder="Service Code" {...register("servicecode")} />
                                            <Form.Control.Feedback type="invalid">{errors.servicecode?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={4}>
                                        <FloatingLabel controlId="issqn" label={t("taxation.ISSQNRate")+ " *"}>
                                            <Form.Control type="text" className={`${errors.ISSQNrate ? 'is-invalid' : ''}`} placeholder="ISSQN Rate (%)" {...register("ISSQNrate")} onChange={(e) =>handleISSQNrate(e)}/>
                                            <Form.Control.Feedback type="invalid">{errors.ISSQNrate?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel controlId="description" label={t("taxation.description")+ " *"}>
                                            <Form.Control type="text" className={`${errors.description ? 'is-invalid' : ''}`} placeholder="Description registration" {...register("description")} />
                                            <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel controlId="municipalTax" label={t("taxation.municipalTaxCode")+ " *"}>
                                            <Form.Control type="text" className={`${errors.taxcode ? 'is-invalid' : ''}`} placeholder="Municipal Tax Code" {...register("taxcode")} />
                                            <Form.Control.Feedback type="invalid">{errors.taxcode?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer >
                            {showTaxation == true ?
                                <Form.Group className="me-sm-2 mb-sm-0 mb-2 me-2">
                                    {/* <Form.Check id="flexCheckDefault" label={t("taxation.Aftersaving")} type="checkbox" className={`${errors.termsAndConditions ? 'is-invalid' : ''}`} {...register('termsAndConditions')} />
                                    <Form.Control.Feedback type="invalid">{errors.termsAndConditions?.message}</Form.Control.Feedback> */}
                                </Form.Group>
                                : "" }
                                <div className='d-flex justify-content-end'>
                                    <Button variant="outline-primary" className="me-lg-3 me-2" onClick={handleClose}>{t("button.close")}</Button>
                                    {
                                        loader_on ?
                                            <Button className="btn-width" disabled={double}>
                                              <span className="spinner-border spinner-border-sm"></span>
                                              <span className="px-1">{t("button.toSave")}</span>
                                            </Button>
                                        :
                                            <Button variant="primary" disabled={double}  type='submit'>{t("button.toSave")} </Button>
                                    }
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    {/* /New service tax code modal end */}
                </>
    )
}

export default NewTaxCode

