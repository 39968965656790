import React from 'react';
import { Button } from 'react-bootstrap';

const OffcanvasFooter = ({ onCancel, onSave, saveText, cancelText }) => (
    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
        <Button variant="outline-primary" onClick={onCancel}>
            {cancelText}
        </Button>
        <Button variant="primary" onClick={onSave}>
            {saveText}
        </Button>
    </div>
);

export default OffcanvasFooter;