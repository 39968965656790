import React, { useState } from 'react';
import { Form, Dropdown, Col, Row, Badge, Nav, Table, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommonHeader from './CommonHeader';
import UndoAgreement from './UndoAgreement';
import AgreementDetails from './AgreementDetails';

const NonPaymentAgreements = () => {
    const [t] = useTranslation();
    //Undo agreement modal close open function
    const [showUndoAgreementModal, setUndoAgreementModal] = useState(false);
    const undoAgreementModalClose = () => setUndoAgreementModal(false);
    const undoAgreementModalShow = () => setUndoAgreementModal(true);
    //Agreement details modal close open function
    const [showAgreementDetailsModal, setAgreementDetailsModal] = useState(false);
    const agreementDetailsModalClose = () => setAgreementDetailsModal(false);
    const agreementDetailsModalShow = () => setAgreementDetailsModal(true);
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <CommonHeader />
                {/* Filters start here */}
                <Form className="w-100 mt-lg-0 mt-2" action="" method="">
                    <Row className="gx-2 gy-1 gx-xxl-3">
                        <Col xs="auto" className="border-end border-xs-none">
                            <Nav variant="pills" className="flex-row nav-pills-border border rounded-pill">
                                <Nav.Item>
                                    <Nav.Link><Link className="text-reset" to={"/non_payment"}>{t("NonPayment.defaulters")}</Link></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="active"><Link className="text-reset" to={"/non_payment_agreements"}>{t("Links.agreements")}</Link></Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col xs="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                {t("dropdownItem.status")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4 custom-checkbox mh-336 overflow-auto">
                                    <label htmlFor="cc1" className="d-block">
                                        <input type="checkbox" name="status" id="cc1" />
                                        <div className="chechbox-inner py-2 cursor-pe">{t("NonPayment.onTime")}</div>
                                    </label>
                                    <label htmlFor="cc2" className="d-block">
                                        <input type="checkbox" name="status" id="cc2" />
                                        <div className="chechbox-inner py-2 cursor-pe">{t("NonPayment.overdueInstallment")}</div>
                                    </label>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Form>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="px-xl-5 px-md-4 px-3 pt-4 pb-xl-5 pb-md-4 pb-3 container-fluid">
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                    {t("NonPayment.listOfAgreedContracts")}
                        <Dropdown align="end" className="ms-2">
                            <Dropdown.Toggle variant="light" size="sm" className="flex-shrink-0 fw-bold">
                            {t("NonPayment.sorting")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>{t("NonPayment.alphabeticalByClient")}</Dropdown.Item>
                                <Dropdown.Item>{t("NonPayment.highestValue")}</Dropdown.Item>
                                <Dropdown.Item>{t("NonPayment.LowestValue")}</Dropdown.Item>
                                <Dropdown.Item>{t("NonPayment.statusUrgency")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive="xl" className="table-custom table-custom-bordered mb-0">
                            <thead>
                                <tr>
                                    <th className="pb-2">
                                        <div className="d-flex align-items-center">{t("Dashboard.Contract")}<Badge bg="light" className="wh-xs-1 d-flex align-items-center justify-content-center ms-1 fs-12 rounded-circle text-dark">3</Badge></div>
                                    </th>
                                    <th className="pb-2">{t("dropdownItem.status")}</th>
                                    <th className="pb-2">{t("NonPayment.generated")}</th>
                                    <th className="pb-2">{t("total")} (R$)</th>
                                    <th className="pb-2">{t("NonPayment.paidInstallments")}</th>
                                    <th className="pb-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="d-block text-dark-70">Contract 4765</span>
                                        <span className="d-block">Joana Dalva de Almeida Costa e Silva</span>
                                    </td>
                                    <td>
                                        <Badge bg="primary-20" className="text-dark">{t("NonPayment.overdueInstallment")}</Badge>
                                    </td>
                                    <td>
                                        <span className="d-block text-dark-70">12/sep/2023</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-dark-70">1.270,00</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-dark-70">1 of 3</span>
                                    </td>
                                    <td className="text-end">
                                      <div className="d-flex align-items-center justify-content-end flex-nowrap">
                                            <span onClick={agreementDetailsModalShow} className="text-primary fw-bold cursor-pe text-nowrap">{t("newTransfer.openDetails")}</span>
                                            <span onClick={undoAgreementModalShow} className="text-primary fw-bold cursor-pe text-nowrap ms-2 ms-xl-3">{t("NonPayment.undoAgreement")}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-dark-70">Contract 4765</span>
                                        <span className="d-block">Fernando Hans da Costa</span>
                                    </td>
                                    <td>
                                        <Badge bg="success-10" className="text-dark">{t("NonPayment.onTime")}</Badge>
                                    </td>
                                    <td>
                                        <span className="d-block text-dark-70">07/jul/2023</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-dark-70">2.200,00</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-dark-70">2 of 3</span>
                                    </td>
                                    <td className="text-end">
                                        <div className="d-flex align-items-center justify-content-end flex-nowrap">
                                            <span onClick={agreementDetailsModalShow} className="text-primary fw-bold cursor-pe text-nowrap">{t("newTransfer.openDetails")}</span>
                                            <span onClick={undoAgreementModalShow} className="text-primary fw-bold cursor-pe text-nowrap ms-2 ms-xl-3">{t("NonPayment.undoAgreement")}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </main>
            {/* modal start */}
            <UndoAgreement showUndoAgreement={showUndoAgreementModal} undoAgreementClose={undoAgreementModalClose} />
            <AgreementDetails showAgreementDetails={showAgreementDetailsModal} agreementDetailsClose={agreementDetailsModalClose} />
        </section>
    )
}

export default NonPaymentAgreements
