import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { setMaturityClose, insertMaturity, transferContracts } from "../../../../redux/slice/transferDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { transferForm } from '../../../Interface';
import Swal from 'sweetalert2';
import moment from "moment";
import '../../../../App.css';

const TransferMaturityService = (props,transferForm) => {
  const { maturityOpen, checkedId } = useSelector((state: RootState) => state.transferData)
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  
  let { state } = props;
  const maturityClosed = () => {    
    dispatch(setMaturityClose(false));
    setDouble(false);
    reset();
  }
  const maturityValidation = Yup.object().shape({
    maturity: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
  });
  let frm = {
    resolver: yupResolver(maturityValidation)
  }
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<transferForm>(frm);
  const onSubmit = async (data: transferForm, e: any) => {
    e.preventDefault();
    data.checkedId = checkedId;
    setDouble(true)
    let response = await dispatch(insertMaturity(data))
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      dispatch(transferContracts(state));
      maturityClosed();
    }
    else {
      setDouble(false)
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }
  return (
    <>
      {/* Change maturity modal start from here */}
      <Modal show={maturityOpen} onHide={maturityClosed}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("TransferPage.Changematurity")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId="maturity" label={t("maturity")}>
              <Form.Control type="date" className={`form-control ${errors.maturity ? 'is-invalid' : ''}`} {...register('maturity')} placeholder={t("maturity")} />
              <Form.Control.Feedback type="invalid">{errors.maturity?.message}</Form.Control.Feedback>
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={maturityClosed}>
              {t("button.close")}
            </Button>
            <Button disabled={double} className="min-w-auto" type="submit" variant="primary">
              {t("button.toSave")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* /Change maturity modal end */}
    </>
  )
}
export default TransferMaturityService;