import React, { ReactNode } from "react";

import { Offcanvas } from "react-bootstrap";

export function DrawerHeader({ children }: { children: ReactNode }) {
  return (
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>{children}</Offcanvas.Title>
    </Offcanvas.Header>
  );
}
