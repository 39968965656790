import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { newRentalFeeClose, contractDatails, getAllService, setServiceType, newServiceOpen, insertRentalFee, getAllExpense } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { newExpenseForm } from '../../../Interface';
import Swal from 'sweetalert2';
import ServiceService from './ServiceService';
import { getAllAccounts } from "../../../../redux/slice/expenditureDataSlice";
//import moment from "moment";
import moment from "moment-timezone";
import '../../../../App.css';
import { convertToNumber, converter, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import i18n from '../../../../i18n';

const RentalFeeService = (newExpenseForm) => {
  let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD");

  const { newRentalFeeShow, contractDatail, serviceData, status } = useSelector((state: RootState) => state.contractData)
  const { accountData, accountStatus } = useSelector((state: RootState) => state.expenditureData)
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
  let searchDt = moment(newDate).format("MMM/YYYY")
  let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");
  const [valueCon, setValueCon] = useState('')
  const [dateVal, setDateVal] = useState<any>()
  const [state, setState] = useState({
    id: id,
    startDate: startDt,
    searchDate: searchDt,
    endDate: endDt,
    expense: "",
    start: 0,
    end: 10
  })

  const [PortionData, setPortionData] = useState<any>([]);
  const [previousExpense, setpreviousExpense] = useState<any>([]);
  let [minDt, setMinDt] = useState(dateBrazil)
  let contractStDate = contractDatail && contractDatail.length > 0 ? contractDatail[0].maturityDate : moment.tz('America/Sao_Paulo').format("YYYY-MM-DD");
  useEffect(() => {
    if (newRentalFeeShow) {
      dispatch(getAllAccounts({ "start": 0, "end": 1000 }))
      dispatch(getAllService());
    }
  }, [newRentalFeeShow, contractDatail])

  useEffect(() => {
    if (newRentalFeeShow) {
      if (contractDatail && contractDatail.length > 0 && contractDatail[0].start2) {
        setMinDt(contractDatail[0].start2);
      }
      let rentValue = contractDatail.length > 0 ? converter(`${contractDatail[0].rent_value}`) : '';
      let bankValue = contractDatail.length > 0 ? contractDatail[0].bank_account : '';
      setValueCon(String(rentValue))
      setValue("value", rentValue)
      setValue("bankAccount", bankValue)
      //setValue('service', '3');
      setValue('credit', '3');
      setValue('portion', '1');
      setValue('in', '1');
      handleExpensesChange({ target: { value: 1 } }, "in")
      let debitmonths = moment(newDate).format("YYYY-MM-DD");
      setValue("maturity", debitmonths)
      setDateVal(debitmonths)
      serviceData.forEach((item, index) => {
        if (item.ST_CONTA_CONT == '1.1.5') {
          let serId: any = item.id;
          setValue('service', serId);
        }
      })
    }
  }, [newRentalFeeShow, contractDatail, serviceData])
  const rentalFeeClose = () => {
    reset();
    setValue("in", "");
    setValue("portion", "");
    setPortionData([]);
    dispatch(newRentalFeeClose(false));
    setDouble(false);
    //dispatch(contractDatails(Number(id)));
  }
  const serviceOpen = (evt) => {
    dispatch(setServiceType(evt));
    dispatch(newServiceOpen(true));
    dispatch(newRentalFeeClose(false));
  }
  const rentalFeeValidation = Yup.object().shape({
    maturity: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    value: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    service: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    credit: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    bankAccount: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),

  });
  let frm = {
    resolver: yupResolver(rentalFeeValidation)
  }
  const { register, reset, handleSubmit, setValue, getValues, watch, setError, clearErrors, formState: { errors } } = useForm<newExpenseForm>(frm);
  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    for (let key in data.data) {
      const installment = data.data[key];
      if (installment.hasOwnProperty('maturity') && !installment.maturity) {
        setDouble(false)
        Swal.fire({
          // icon: 'success','success',
          title: t("Sweetalert.Error"),
          text: t('requiredMmessage.fieldRequired'),
          confirmButtonText: "OK",
          // timer: 2000
        })
        return;
      }
    }
  
    setDouble(true);
    if (String(getValues("in")) == "0") {
      setError("in", {
        type: "manual",
        message: `${t("requiredMmessage.fieldRequired")}`
      })
    }
    if (String(getValues("in")) !== "0") {
      setDouble(true)
      for (let key in data.data) {
        if (data?.data[key].hasOwnProperty('value')) {
          data.data[key].value = convertToNumber(`${data.data[key].value}`);
        }
      }
      let values: any = convertToNumber(valueCon ? String(valueCon) : String(data.value));
      data["value"] = values
      data.PortionData = PortionData.length;

      let response = await dispatch(insertRentalFee(data));
      if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
        setDouble(false);
        Swal.fire({
          // icon: 'success','success',
          title: t("Sweetalert.Success"),
          text: response.payload.message,
          confirmButtonText: "OK",
          // timer: 2000
        })
        dispatch(getAllExpense({ ...state }));
        rentalFeeClose();
      } else {
        setDouble(false)
        Swal.fire({
          title: t("Sweetalert.Error"),
          text: response.payload.message,
          // icon:"error",
          confirmButtonText: "OK",
          timer: 2000
        })
      }
    }
  }

  const handleExpensesChange = (e: any, type: string) => {
    let arrayportion: any = [];
    setPortionData([]);
    let Value: any = "";
    // if (type === "maturity") {
    //     setValue("debitMonth", moment(e.target.value).format("YYYY-MM"))
    // }
    if (type == "in" || type == "portionchange") {
      clearErrors("in");
      Value = e.target.value;
      if (type == "portionchange") {
        setValue('portion', Value)
      }
      if (type == "in") {
        setValue('in', Value)
      }
    } else {
      Value = getValues("in");
    }
    if ((type == "in" || type == "portionchange") && Number.isInteger(parseInt(Value))) {
      let portionValue = getValues("portion");
      if (type != "in") {
        portionValue = getValues("in")
      }
      if (Value < 40) {
        if (Number(Value) < Number(portionValue)) {
          if (type == "portionchange") {
            setValue('portion', Value);
          }
          else {
            setValue('in', Value);
          }
        } else {
          //setValue('in', Value);
        }
      } else {
        if (Number(Value) < Number(portionValue)) {
          setValue('portion', "40");
          setValue('in', "40");
          Value = "40"
        } else {
          setValue('in', "40");
          Value = "40"
        }
      }
    }
    if (type == "value") {
      let { value } = e.target;
      value = value.replace(/[^\d,]/g, '');
      setValue("value", formatNumber(value));
      setValueCon(value);
    }
    if (type == "portionchange") {
      Value = getValues("in");
    }
    if (type == "maturity") {
      setDateVal(e.target.value)
    }
    if (Value !== "") {
      if (getValues('maturity') && getValues('value')) {
        let currentDate = moment(getValues('maturity')).format('YYYY-MM-DD');
        let PortionData: any = getValues("portion");
        //PortionData = 1;
        let dividewithThis = Number(getValues("in")) - Number(PortionData);
        let amt = 0;
        let amt2: any = convertToNumber(getValues("value"));
        if (amt2) {
          amt2 = convertToNumber(getValues("value"))
        }
        else {
          amt2 = convertToNumber(getValues("value"))
        }
        amt = Number(amt2);
        [...Array(Number(Value))].forEach((item, index) => {
          if (index + 1 >= Number(PortionData)) {
            setPortionData([]);
            const totalAmount = amt;
            const numberOfInstallments = dividewithThis + 1;
            let installmentAmount: any = totalAmount / numberOfInstallments;
            if (installmentAmount % 1 !== 0) {
              installmentAmount = parseFloat(installmentAmount).toFixed(2);
            }
            let expValue: any = index == numberOfInstallments - 1 ? totalAmount - (numberOfInstallments - 1) * installmentAmount : installmentAmount;
            if (expValue % 1 !== 0) {
              expValue = parseFloat(expValue).toFixed(2);
            }
            expValue = expValue.toString();
            expValue = converter(expValue);
            let addt = new Date(dateBrazil)
            addt = dateVal
            let installmentDate = new Date(addt); // Start with the initial date
            installmentDate = addMonthsPreserveEndOfMonth(installmentDate, index);
            arrayportion.push({
              maturity: formatDate1(installmentDate),
              value: expValue,
              complement: `Parcela ${index + 1} de ${Value}`
            })
            // var futureMonth = moment(currentDate).add(1, 'months').calendar();
            // let futureMonthnew = new Date(futureMonth);
            // let yyyy: any = futureMonthnew.getFullYear();
            // let mm: any = futureMonthnew.getMonth() + 1;
            // let dd: any = futureMonthnew.getDate();
            // if (dd < 10) dd = '0' + dd;
            // if (mm < 10) mm = '0' + mm;
            // let formattedToday = yyyy + "-" + mm + "-" + dd;
            // currentDate = formattedToday
          }
        })
      };
      if (arrayportion && arrayportion.length && arrayportion.length == 1) {
        arrayportion[0].complement = '';
      }
      setPortionData(arrayportion);
    } else {
      setPortionData([]);
    }
  }
  function formatDate1(d) {
    let year = d.getFullYear();
    let month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Function to add months to a date, preserving end-of-month dates
  function addMonthsPreserveEndOfMonth(date, months) {
    let d = new Date(date.getTime()); // Clone the date
    d.setMonth(d.getMonth() + months);

    // Check if we need to adjust to the end of the month
    if (d.getDate() < date.getDate()) {
      // If the new month has fewer days, set to the last day of the month
      d.setDate(0); // Set to the last day of the previous month
    }

    return d;
  }
  useEffect(() => {
    if (newRentalFeeShow && PortionData && PortionData.length > 0) {
      setTimeout(() => {
        PortionData.forEach((item: any, index: number) => {
          setValue(`data.install_${index}.maturity`, item?.["maturity"]);
          setValue(`data.install_${index}.value`, item?.["value"]);
          setValue(`data.install_${index}.complement`, item?.["complement"]);
        })
      }, 100)
    }
  }, [PortionData, watch("maturity"), watch("value"), watch("portion"), watch("in")])

  useEffect(() => {
    minDate();
  }, [getValues("maturity")])

  const minDate = () => {
    let today: any = "";
    if (getValues("maturity")) {
      today = getValues("maturity");
    } else {
      today = new Date(contractStDate).toISOString().split('T')[0];
    }
    return today
  };

  const HandleDate = (e: any, index) => {
    const updatedPortionData = [...PortionData]; // Create a copy of the array
    updatedPortionData[index].maturity = e.target.value; // Update the specific item
    setPortionData(updatedPortionData); // Set the new state
  }

  return (
    <>
      {/* <!-- New rental fee modal start from here --> */}
      <Modal show={newRentalFeeShow} onHide={rentalFeeClose} id="rentalmFeetsxService">
        <Modal.Header closeButton>
          <Modal.Title>{t("rentalFee")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value={id} {...register('id')} />
          <input type="hidden" value={contractDatail.length > 0 ? contractDatail[0].propertyId : ''} {...register('propertyId')} />
          <Modal.Body>
            <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label={t("maturity") + " *"}>
                  <Form.Control type="date" min={minDt} placeholder={t("formLabel.maturity")} className={`form-control ${errors.maturity ? 'is-invalid' : ''}`} {...register('maturity')} onChange={(e) => handleExpensesChange(e, "maturity")} />
                  <Form.Control.Feedback type="invalid">{errors.maturity?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label={t("formLabel.value") + " *"}>
                  <Form.Control type="text" placeholder={t("formLabel.value")} className={`form-control ${errors.value ? 'is-invalid' : ''}`} {...register('value')} onChange={(e) => handleExpensesChange(e, "value")} />
                  <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <InputGroup>
                  <FloatingLabel controlId="floatingInput" label={t("contractsettings.Service") + " *"}>
                    <Form.Select disabled className={`form-control ${errors.service ? 'is-invalid' : ''}`} {...register('service')} >
                      <option value="">{t('dropdownItem.select')}..</option>
                      {
                        serviceData && serviceData.map((item, index) => (
                          <option key={index} value={item.id} >{item.name}</option>
                        ))
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.service?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                  <InputGroup.Text onClick={() => serviceOpen("rentalFee")} id="basic-addon2">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20 cursor-pe" />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="floatingSelect" label={t('formLabel.credit') + " *"}>
                  <Form.Select disabled className={`form-control ${errors.credit ? 'is-invalid' : ''}`} {...register('credit')} aria-label="Credit">
                    <option value="">{t('dropdownItem.select')}..</option>
                    <option value="1">{t('notifications.owner')}</option>
                    <option value="2">{t('notifications.tenant')}</option>
                    <option value="3">{t('realEstate')}</option>
                    <option value="4">{t('services.third')}</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.credit?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={3} xs={6}>
                <FloatingLabel controlId="floatingInput" label={t('formLabel.portion')}>
                  <Form.Control type="text" placeholder={t('formLabel.portion')} className={`form-control ${errors.portion ? 'is-invalid' : ''}`} {...register('portion')} onChange={(e) => handleExpensesChange(e, "portionchange")} disabled={(previousExpense && previousExpense.length > 0) ? true : false} />
                  <Form.Control.Feedback type="invalid">{errors.portion?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={3} xs={6}>
                <FloatingLabel controlId="floatingInput" label={t('formLabel.in')}>
                  <Form.Control type="text" placeholder={t('formLabel.in')} className={`form-control ${errors.in ? 'is-invalid' : ''}`} {...register('in')} onChange={(e) => handleExpensesChange(e, "in")} disabled={(previousExpense && previousExpense.length > 0) ? true : false} />
                  <Form.Control.Feedback type="invalid">{errors.in?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Alert variant="warning" className="mb-3 mb-lg-4">
              <span className="d-block">{t("LeaseDetailsRent")}</span>
              {t("intheamountof")} R$ {contractDatail.length > 0 ? converter(`${contractDatail[0].rent_value}`) : ''}
            </Alert>
            <FloatingLabel controlId="floatingSelect" label={t('bankAccount') + " *"}>
              <Form.Select className={`form-control ${errors.bankAccount ? 'is-invalid' : ''}`} {...register('bankAccount')} aria-label="Bank account">
                <option value="">{t('dropdownItem.select')}..</option>
                {
                  accountData.data && accountData.data.length > 0 ? accountData.data.map((item, index) => (
                    <option key={index} value={Number(item.id)}>{item.account} -- {item.accountRef}</option>
                  ))
                    :
                    ''
                }
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.bankAccount?.message}</Form.Control.Feedback>
            </FloatingLabel>

            {
              PortionData && PortionData.length > 0 &&
              <>
                <hr />
                <h4 className='mb-3'>{t("rentalFee")}</h4>
              </>
            }

            {
              PortionData && PortionData.length > 0 &&
              PortionData.map((item, index) => {
                return (
                  <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3" key={index}>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t("maturity")}>
                        <Form.Control type="date" placeholder={t("maturity")} className={`form-control ${item.maturity == "" ? 'is-invalid' : ''}`} {...register(`data.install_${index}.maturity`)} disabled={previousExpense && previousExpense.length > 0 ? true : false} onChange={(e) => HandleDate(e, index)} />
                          {item.maturity == "" ? <Form.Control.Feedback type="invalid">{t('requiredMmessage.fieldRequired')}</Form.Control.Feedback> : ""}
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t("value")}>
                        <Form.Control type="text" placeholder={t("value")} className={`form-control`} {...register(`data.install_${index}.value`)} disabled={previousExpense && previousExpense.length > 0 ? true : false} required />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t("complement")}>
                        <Form.Control type="text" placeholder={t("complement")} className={`form-control`} {...register(`data.install_${index}.complement`)} disabled={previousExpense && previousExpense.length > 0 ? true : false} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                )
              })
            }
          </Modal.Body>
          <Modal.Footer>
            {/*<Form.Check type="checkbox" {...register('isVendor')} label={t("formLabel.afterSaving")} />*/}
            <Button variant="outline-primary" onClick={rentalFeeClose}>
              {t('button.close')}
            </Button>
            <Button disabled={double} type="submit" variant="primary">
              {t('button.toSave')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ServiceService />
      {/* <!-- /New rental fee modal end --> */}
    </>
  )
}
export default RentalFeeService;