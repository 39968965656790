import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
import Swal from 'sweetalert2';
let connectionName: any = '';
if (localStorage.getItem("userData")) {
  connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCEESS: "success",
  ERROR: 'error'
});
export interface IPerson {
  fantasy_name: string;
  company_name: string;
  telephone: number;
  email: string;
  site: string;
  zipcode: number;
  address: string;
  number: number;
  complement: string;
  neighborhood: string;
  state: string;
  city: string;
  cnpj: number;
  opening_hours: any;
  statess: string
}

export interface Footer {
  line1: string
  line2: string
  line3: string
}
interface SliceState {
  companydata: IPerson[],
  footerOfReport: Footer[]
  getlogo: any;
  status: string
}

const initialState: SliceState = {
  companydata: [],
  footerOfReport: [],
  getlogo: {},
  status: STATUSES.IDLE,
};

let user_id = gettoken.userId

const companyDataSlice = createSlice({
  name: "companyDataSlice",
  initialState,
  reducers: {
    deleteImg: (state, action) => {
      action.payload = {
        ...action.payload,
        ["logo"]: ""
      }
      state.companydata = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(registerData.fulfilled, (state, action: PayloadAction<any>) => {
        state.companydata = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(registerData.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(updatePost.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getComapnyLogo.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getComapnyLogo.fulfilled, (state, action: PayloadAction<any>) => {
        state.getlogo = action.payload.data[0]
        state.status = STATUSES.SUCEESS
      })
      .addCase(getComapnyLogo.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(NewLogoInsert.pending, (state,) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(NewLogoInsert.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(NewLogoInsert.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(deleteLogo.pending, (state,) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteLogo.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(deleteLogo.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(footer_data.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(footer_data.fulfilled, (state, action: PayloadAction<any>) => {
        state.footerOfReport = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(footer_data.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(update_footer_report.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(update_footer_report.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS;

      })
      .addCase(update_footer_report.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
  }
})


export const { deleteImg, } = companyDataSlice.actions;
export default companyDataSlice.reducer


// GET - COMPANY DATA API
export const registerData = createAsyncThunk(
  'user/fetchUserData',
  async (_, thunkAPI) => {
    let connectionName = localStorage.getItem("userData");
    let token: any = localStorage.getItem("tokenV2");
    let postData = JSON.stringify({
      user_id
    });
    const response = await fetch(`${config.companyV2_URL}/company/companyData`,
      {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'x-auth-token': token,//gettoken.Token,
          'connection-name': connectionName ? connectionName : ''
        },
      });

    const data = await response.json();
    return data;
  }
);

export const registerDatacomapny = createAsyncThunk(
  'user/fetchUserData',
  async (_, thunkAPI) => {
    let connectionName = localStorage.getItem("userData");
    let token: any = localStorage.getItem("tokenV2");
    let postData = JSON.stringify({
      user_id
    });
    const response = await fetch(`${config.companyV2_URL}/company/companyData`,
      {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'x-auth-token': token,//gettoken.Token,
          'connection-name': connectionName ? connectionName : ''
        },
      });

    const data = await response.json();
    return data;
  }
);

export const registerCompanyOwner = createAsyncThunk(
  'user/registerCompanyOwner',
  async (_, thunkAPI) => {
    let connectionName = localStorage.getItem("userData");
    let token: any = localStorage.getItem("tokenV2");
    let postData = JSON.stringify({
      user_id
    });
    const response = await fetch(`${config.companyV2_URL}/company/insert_ownerforcompany`,
      {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'x-auth-token': token,//gettoken.Token,
          'connection-name': connectionName ? connectionName : ''
        },
      });

    const data = await response.json();
    return data;
  }
);


// POST  -  UPDATE COMPANY DATA API
export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async (postData: IPerson) => {
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/company_update`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,//gettoken.Token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name': connectionName
      },
    });

    const data = await response.json();

    return data
  }
);

// GET COMPANY LOGO API

export const getComapnyLogo = createAsyncThunk(
  'user/getlogo',
  async (_, thunkAPI) => {
    let connectionName = localStorage.getItem("userData");
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/logo`,
      {
        method: 'POST',
        body: JSON.stringify(
          {
            logo_type: "company",
            user_id: user_id
          }),
        headers: {
          'x-auth-token': token,//gettoken.Token,
          'Content-type': 'application/json',
          'connection-name': connectionName ? connectionName : ''
        },
      });
    const data = await response.json();
    return data
  }
);

// PUT - UPDATE COMPANY LOGO API
export const updateComapnyLogo = createAsyncThunk(
  'update_comapny_logo',
  async (postlogo: any) => {
    postlogo["user_id"] = user_id;
    var raw = JSON.stringify(postlogo);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/logoOld`,
      {
        method: 'PUT',
        headers: {
          'x-auth-token': token,//gettoken.Token,
          'Content-type': 'application/json',
          'connection-name': connectionName
        },
        body: raw,
      });
    const data = await response.json();
    return data
  }
);

// PUT INSERT NEW LOGO API
export const NewLogoInsert = createAsyncThunk(
  'new_logo_insert',
  async (logo: any) => {
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/logoNew`,
      {
        method: 'PUT',
        body: logo,
        headers: {
          'x-auth-token': token,//gettoken.Token,
          'connection-name': connectionName
        },

      });
    const data = await response.json();
    return data
  }
);


export const footer_data = createAsyncThunk(
  'get_footer_of',
  async (_, thunkAPI) => {
    let raw = JSON.stringify({
      "user_id": user_id
    });
    let connectionName = localStorage.getItem("userData");
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/get_companyFooter/`, {
      method: 'POST',
      body: raw,
      headers: {
        'x-auth-token': token,//gettoken.Token,
        "Content-Type": "application/json",
        'connection-name': connectionName ? connectionName : ''
      },
    });
    const data = await response.json();
    return data
  }
);


// POST  -  UPDATE COMPANY DATA API
export const update_footer_report = createAsyncThunk(
  'posts/updatefooter',
  async (postData: Footer) => {
    postData['user_id'] = user_id
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/update_companyFooter`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,//gettoken.Token,
        'Content-type': 'application/json',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

export const deleteLogo = createAsyncThunk(
  'posts/deltelogo',
  async () => {
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/deleteLogo`, {
      method: 'DELETE',
      body: JSON.stringify(
        {
          user_id: user_id
        }),
      headers: {
        'x-auth-token': token,//gettoken.Token,
        'Content-type': 'application/json',
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data
  }
);