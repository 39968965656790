import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ContractCost = (props) => {
    const [t] = useTranslation();
    //Contractt cost modal close open function
    const { showContractCost, contractCostClose } = props;
    return (
        <>
            <Modal show={showContractCost} onHide={contractCostClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("newContractDev.costThisContract")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                        <p className="text-dark-70 mb-lg-4 mb-3">{t("newContractDev.costOFThisContractContent")}</p>
                        <div className="border rounded-4 p-lg-4 p-3 mb-lg-4 mb-3">
                                <h6 className="mb-1 fw-bold"><img src="assets/images/icons/money-check.svg" alt="Knelo Pay" className="h-20 me-1" />{t("newContractDev.kenloPay")}</h6>
                                <span className="d-block text-secondary">{t("newContractDev.kenloPayContent")}</span>
                        </div>
                        <div className="border rounded-4 p-lg-4 p-3 mb-lg-4 mb-3">
                                <h6 className="mb-1 fw-bold"><img src="assets/images/icons/insurance-check.svg" alt="Fire insurance" className="h-20 me-1" />{t("newContractDev.kenloInsuranceforFireInsurance")}</h6>
                                <span className="d-block text-secondary">{t("newContractDev.kenloInsuranceforFireInsuranceContent")}</span>
                        </div>
                        <span className="text-dark-70 d-block">{t("newContractDev.newContractsSettingsArea")}</span>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}
export default ContractCost
