import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { indexDatails, setIndexId, indexOpen, indexClose, insertIndexs, getAllIndexs } from "../../../../redux/slice/expenditureDataSlice";
import { useTranslation } from 'react-i18next';
import { indexForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
const IndexService = (indexForm) => {
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const { singleIndexData, newIndexShow, indexId } = useSelector((state: RootState) => state.expenditureData)
  useEffect(() => {
    getIndexData(indexId)
  }, [indexId])

  const getIndexData = async (id) => {
    if (id) {
      if (id == 0) {
        dispatch(indexOpen(true))
      }
      else {
        let data = await dispatch(indexDatails(Number(id)));
        const fields: string[] = ['id', 'name', 'slug'];
        if (data.payload.data.length > 0) {
          setTimeout(() => {
            let disable_readonly: any = document.querySelector('[name="slug"]');
            disable_readonly?.setAttribute("readonly", true);
            disable_readonly?.setAttribute("disabled", true);
          }, 300)
          fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));
          dispatch(indexOpen(true))
        }
      }
    }
    else {
      newIndexClose();
    }
  }

  const newIndexClose = () => {
    let disable_readonly: any = document.querySelector('[name="slug"]');
    disable_readonly?.removeAttribute("readonly");
    disable_readonly?.removeAttribute("disabled");
    dispatch(setIndexId(''))
    dispatch(indexClose(false));
    reset();
    setValue('id', '')
    setDouble(false);
  }

  /*------------Form validation-----------*/
  const indexValidation = Yup.object().shape({
    name: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    slug: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
  });

  let frm = {
    resolver: yupResolver(indexValidation)
  }

  /*------------Submit warranty form data-----------*/
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<indexForm>(frm);
  const onSubmit = async (data: indexForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    let response = await dispatch(insertIndexs(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:t("Sweetalert.successget"),
        confirmButtonText:"OK",
        timer:2000})
      newIndexClose();
      dispatch(getAllIndexs({ "status": "", "start": 0, "end": 10 }));
    } else {
      setDouble(false)
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:response.payload.message,
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }

  const handleChangeName = (e, type) => {
    let readonly: any = document.querySelector('[name="slug"]')?.hasAttribute("readonly");
    let disable: any = document.querySelector('[name="slug"]')?.hasAttribute("disabled");
    if(!readonly && !disable){
      if (type == "name") {
        let value = (e.target.value).trim().replaceAll(" ","-");
        setValue("slug", value.toLowerCase());
      }
      if (type == "slug") {
        let value = (e.target.value).trim().replaceAll(" ","-");
        setValue("slug", value.toLowerCase());
      }
    }
  }

  return (
    <>
      {/* <!-- New Index modal start from here --> */}
      <Modal show={newIndexShow} onHide={newIndexClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('id')} />
          <Modal.Header closeButton>
            <Modal.Title>{t("readjIndex.NewIndex")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId="floatingInput" label={t("readjIndex.Nameofthetable")}>
              <Form.Control type="text" placeholder={t("readjIndex.Nameofthetable")} className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleChangeName(e, "name")} />
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback >
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label={t("NonPayment.slug")} className='mt-3 mt-lg-4'>
              <Form.Control type="text" placeholder={t("readjIndex.Nameofthetable")} className={`form-control ${errors.slug ? 'is-invalid' : ''}`} {...register('slug')} onChange={(e) => handleChangeName(e, "slug")} />
              <Form.Control.Feedback type="invalid">{errors.slug?.message}</Form.Control.Feedback >
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer className="d-flex aling-items-center justify-content-end">
            <Button variant="outline-primary" className="me-2 me-lg-3" onClick={newIndexClose}>{t("button.close")}</Button>
            <Button disabled={double} type="submit" variant="primary">{t("button.save")}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New Index modal end --> */}
    </>
  )
}
export default IndexService;