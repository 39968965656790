import React, { useState } from 'react';
import { Modal, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import customerService from '../../../../services/customerService';
import { transferContracts } from '../../../../redux/slice/transferDataSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';

const RevokeTransfers = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
     //Revoke transfers modal close open function
    const { showRevokeTransfers, revokeTransfersClose,revokeTransfersShow,isCheck,state } = props;
        //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = async() => {
        // setToastShow(true);
        // revokeTransfersClose(false);
        // setTimeout(() => {
        //     setToastShow(false);
        // }, 5000);
        if(isCheck.length){
            let response = await customerService.reverseCharge({ id: isCheck })
            if (response.data && response.data.resCode == 200) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.updatesuccess"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
                revokeTransfersClose(false);
                await dispatch(transferContracts(state));
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: response.data.message,
                    confirmButtonText: "OK",
                    timer: 2000,
                });
            }
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }
    return (
        <>
        <Modal show={showRevokeTransfers} onHide={revokeTransfersClose}>
            <Form id="RevokeForm">
                <Modal.Header className="pb-0" closeButton>{t("newTransfer.revokeTransfers")}</Modal.Header>
                <Modal.Body>
                   {t("newTransfer.revokeTransfersContent")} 
                </Modal.Body>
                <Modal.Footer>
                <Button variant="outline-primary" onClick={revokeTransfersClose}>
                {t("button.close")}
                    </Button>
                    <Button variant="primary" onClick={commonToastShow}>
                    {t("button.confirm")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
        <ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
                <Toast bg="dark" className="rounded-4 text-white border-0" show={showToast} onClick={commonToastClose}>
                    <Toast.Body className="d-flex align-items-center p-3">
                        <img className="me-2 me-xxl-4 me-xl-3" src="assets/images/icons/check-circle-success.svg" alt="arrow right" />
                        <span className="me-2">{t("newTransfer.transfersRegisteredRevoked")}</span>
                        <span className="ms-auto cursor-pe" onClick={commonToastClose}><img src="assets/images/icons/cross-white.svg" alt="arrow right" /></span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>       
        </>
    )
}
export default RevokeTransfers