import React, { useState, MouseEvent, useEffect } from 'react';
import { Outlet, Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Form, Modal, Button, Row, Col, Dropdown, Table, Badge, FloatingLabel, Collapse, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Profile/Company/Loader';
import IndexedService from '../Common/IndexedService';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_allFinancialIndexing, getAllLunchIndexs, getAllLunchIndexs_based_on_Name, indexedOpen, insertIndexs1, insertLunchIndex1 } from "../../../../redux/slice/expenditureDataSlice";
import { encrypt_Decrypt } from '../../../constants/utils';
import moment from 'moment-timezone';
import { ClllThis } from './ReadjustmentIndexes';

const ReadjustmentedIndexes = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { indexedData, indexedStatus, indexedData_based_on_name, indexedStatus_based_on_name } = useSelector((state: RootState) => state.expenditureData)
    const { id } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const [userJson, setUserJson] = useState<any>([]);
    const [allowEdit, setAllowEdit] = useState(true);
    const [loaderListing, setloaderListing] = useState(true);

    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2030) === -1 && userResult.indexOf(2070) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    useEffect(() => {
        setloaderListing(true);
        (async () => {
            let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
            if (dataOutPut["payload"]) {
                let dataOutPut_result = await ClllThis(dataOutPut["payload"]["data"]);
                if (dataOutPut_result && dataOutPut_result.length > 0) {
                    let filterID = dataOutPut_result.filter((item: any) => item.id == id);
                    let obj = filterID[0];
                    let response = await dispatch(insertIndexs1(obj));
                    if (response && response?.["payload"]?.["data"]) {
                        callThis(obj);
                    }
                }
            }
        })()
    }, [dispatch])

    async function callThis(obj: any) {
        let dataMongoGetArr = [];
        if (location.state !== "") {
            let statelowercase = location.state;
            let dataMongoGet = await dispatch(getAllLunchIndexs_based_on_Name({ "collectionName": (statelowercase && statelowercase !== "") && statelowercase.toLowerCase() }));
            dataMongoGetArr = dataMongoGet?.["payload"]?.["data"]?.["result"];
        }

        if (dataMongoGetArr && dataMongoGetArr.length == 0) {
            setAllowEdit(true);
            let dataAllLunch = await dispatch(getAllLunchIndexs({ "id": id, "status": "", "start": 0, "end": 10 }));
            let dataRead = dataAllLunch?.["payload"]?.["data"];
            setloaderListing(false);
            if (dataRead && dataRead.length == 0) {
                let todayDateis = moment().subtract(12, "months");
                let i = 0;
                let createObject: any = [];
                while (i <= 11) {
                    i++;
                    createObject.push([todayDateis.add(1, "months").format("YYYY-MM-01").toLowerCase(), "0.00", "1"])
                }
                let objectis: any = {
                    createObject: createObject
                }
                await dispatch(insertLunchIndex1(objectis));
                dispatch(getAllLunchIndexs({ "id": id, "status": "", "start": 0, "end": 100 }));
            }
        } else {
            setAllowEdit(false);
            setloaderListing(false);
        }
    };

    const indexedOpened = () => {
        dispatch(indexedOpen(true))
    }

    const handleEdit = (e: any, date: any, type: any) => {
        let dateis = moment(date, "MMM/YYYY").format("YYYY-MM-DD HH:mm:ss");
        document.querySelector("body")?.setAttribute("date_index", dateis);
        indexedOpened();
    }

    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 5, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <div className="w-100 overflow-hidden">
                                    <h1 className="mb-0 lh-base text-truncate">{t('readjIndex.readjustment_indexes')}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        {allowEdit &&
                            <Col xs={{ order: 1 }} xl={{ span: 6, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                        <a className="fw-bold cursor-pe" onClick={indexedOpened}>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="Plus icon" className="me-1 h-20" />{t('readjIndex.launchIndex')}
                                        </a>
                                    </li>
                                    {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                 <img src={process.env.PUBLIC_URL + "/assets/images/icons/print-light.svg"} alt="Print" className="h-20" />
                             </li>
                             <li className="list-inline-item">
                                 <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                             </li> */}
                                </ul>
                            </Col>
                        }
                    </Row>
                </div>
            </div>
            {/*<!-- /Breadcrumb section end --> */}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*<!-- Table start from here --> */}
                {
                    (loaderListing) ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("Monthyear")}</th>
                                            {/* <th>{t("bankTransaction.date")}</th> */}
                                            <th>{t("index")}</th>
                                            {allowEdit &&
                                                <th></th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {indexedData_based_on_name && indexedData_based_on_name?.result &&
                                            indexedData_based_on_name?.result?.length > 0 ?
                                            indexedData_based_on_name.result.map((row: any, index: number) => {
                                                let Result = moment.tz(row.data, "America/Sao_Paulo").add(1, "months").format("MMM/YYYY");
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <span className="d-block text-dark-70 capitalize">{Result}</span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block text-dark-70">{(row.valor && row.valor != "") && (row.valor).toFixed(2)}</span>
                                                        </td>
                                                        {allowEdit &&
                                                            <td>
                                                                <img src="../assets/images/icons/edit-light.svg" alt="Edit" className="h-20" onClick={(e) => handleEdit(e, Result, "normal")} />
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                            :
                                            <>
                                                {indexedData.data && indexedData.data.length > 0 ?
                                                    indexedData.data.map((row: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span className="d-block text-dark-70">{row.indexDate}</span>
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-dark-70">{row.indexValue}</span>
                                                            </td>
                                                            {allowEdit &&
                                                                <td>
                                                                    <img src="../assets/images/icons/edit-light.svg" alt="Edit" className="h-20" onClick={(e) => handleEdit(e, row.indexDate, "withmoment")} />
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <span>{t('formLabel.noRecordsFounds')}</span>
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                }
                {/*<!-- /Table end --> */}
                {
                    indexedData_based_on_name && indexedData_based_on_name?.result && indexedData_based_on_name?.result?.length > 0 ?
                        <>
                            <p className="mt-lg-4 mt-3">{t("listing")} {indexedData_based_on_name.totalRow > 0 ? indexedData_based_on_name.totalRow : 0} {t("readjIndex.readjustment_indexes")}</p>
                        </>
                        :
                        <>
                            <p className="mt-lg-4 mt-3">{t("listing")} {indexedData.totalRow > 0 ? indexedData.totalRow : 0} {t("readjIndex.readjustment_indexes")}</p>
                        </>
                }
            </main>
            {/*<!-- /Main content start end -->*/}
            {/* New index modal start from here */}
            <IndexedService />
        </section>
    )
}
export default ReadjustmentedIndexes;