import React, { useEffect, useState } from 'react';
import { Offcanvas, Form, Card, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';

import RegisterNewContact from './RegisterNewContact';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { getNonpaymentContactHistory } from '../../../../../redux/slice/NonPaymentSlice';
import ScheduleContact from '../Contract/ScheduleContact';
import moment from 'moment';

const ContractHistroy = (props: any) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    //Contract history modal close open function
    const { showContractHistroy, contractHistroyClose, contractHistroyShow,
        recebimento_id, contactHistoryData, singleContact, state, loading, setLoading } = props;
    //Register new contact modal close open function
    const [showRegisterNewContactModal, setRegisterNewContactModal] = useState(false);
    const registerNewContactModalClose = () => {
        setRegisterNewContactModal(false);
        contractHistroyShow(recebimento_id);
    }

    const [editor, setEditor] = useState([""]);
    const registerNewContactModalShow = (type: any) => {
        setEditor(type);
        contractHistroyClose(true);
        setRegisterNewContactModal(true);
    }

    useEffect(() => {
        if (showContractHistroy) {
            const getContactHistory = async (recebimento_id: any) => {
                if (recebimento_id !== "") {
                    // console.log("contactHistoryDataQWERTY", contactHistoryData);
                }
            }
            getContactHistory(recebimento_id);
        }
    }, [recebimento_id, contactHistoryData, showContractHistroy])

    const [storeData, setStoreData] = useState([]);
    useEffect(() => {
        if (showContractHistroy) {
            setStoreData(singleContact);
        }
    }, [showContractHistroy, showContractHistroy])

    function formatDate(isoDateString: any) {
        const inputDate = new Date(isoDateString);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedDate = `${("0" + inputDate.getUTCDate()).slice(-2)}/${months[inputDate.getUTCMonth()]}/${inputDate.getUTCFullYear()} ${("0" + inputDate.getUTCHours()).slice(-2)}:${("0" + inputDate.getUTCMinutes()).slice(-2)}`;
        return formattedDate;
    }



    return (
        <>
            <Offcanvas className="border-sm-none" show={showContractHistroy} onHide={contractHistroyClose} placement="end">
                <Form className="d-flex flex-column h-100">
                    <Offcanvas.Header className="border-bottom w-100" closeButton>
                        <div className="d-sm-flex justify-content-between w-100 align-items-center">
                            {t("NonPayment.contactHistory")}
                            <span className="fs-6 fw-bold text-primary cursor-pe mx-sm-3" onClick={() => registerNewContactModalShow([""])}>{t("NonPayment.registerNewContact")}</span>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {
                            contactHistoryData && contactHistoryData.length > 0 ?
                                contactHistoryData.map((item: any, index: number) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <h6 className="text-dark-70 fw-bold d-flex align-items-center mb-3">
                                                <img src="assets/images/icons/calendar-regular.svg" alt="calendar" className="me-1 me-sm-2" />
                                                {moment(item.DT_DATE_SCH).format("DD/MMM/YYYY")}
                                            </h6>
                                            {/* <h6 className="text-dark-70 fw-bold d-flex align-items-center mb-3"><img src="assets/images/icons/calendar-regular.svg" alt="calendar" className="me-1 me-sm-2" />04/sep/2023</h6> */}
                                            <Card className="border border-dark border-opacity-10 mb-lg-4 mb-3">
                                                <Card.Body>
                                                    <div className='d-flex justify-content-between'>
                                                         <h6 className="fw-bold">{t("scheduled")} {t("TransferPage.on")} {moment(item.DT_DATE_SCH).format("DD/MMM/YYYY")}</h6>
                                                        {/* <h6 className="fw-bold">{t("scheduled")} {t("TransferPage.on")} {moment(item.DT_DATE_SCH).format("DD/MMM/YYYY")} - [{item.ID_SCHEDULE_SCH}] <sup>ID</sup></h6> */}
                                                        
                                                        <span className='d-flex justify-content-end'>
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == 0 || item["BL_CONTACT_STATUS_SCH"] == null) &&
                                                                <Badge bg="primary-20" className="text-dark">{t("NonPayment.noActionTake")}</Badge>
                                                            }
                                                            {
                                                                item["BL_CONTACT_STATUS_SCH"] == 1 &&
                                                                <Badge bg="secondary-custom" className="text-dark">{t("NonPayment.scheduledContact")}</Badge>
                                                            }
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == "1.1" || item["BL_CONTACT_STATUS_SCH"] == "1.2") &&
                                                                <Badge bg="success-20" className="text-dark">{t("NonPayment.effectiveContact")}</Badge>
                                                            }
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == "2.1" || item["BL_CONTACT_STATUS_SCH"] == "2.2" || item["BL_CONTACT_STATUS_SCH"] == "2.3") &&
                                                                <Badge bg="info-20" className="text-dark">{t("NonPayment.inconclusiveContact")}</Badge>
                                                            }
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == "3.1" || item["BL_CONTACT_STATUS_SCH"] == "3.2" || item["BL_CONTACT_STATUS_SCH"] == "3.3" || item["BL_CONTACT_STATUS_SCH"] == "3.4") &&
                                                                <Badge bg="pending-20" className="text-dark">{t("NonPayment.contactnotMade")}</Badge>
                                                            }
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>{t("Editbilling")}</Tooltip>}>
                                                                <span className="icon cursor-pe me-2 me-xl-3 me-xxl-4" onClick={() => registerNewContactModalShow(["edit", item["ID_SCHEDULE_SCH"]])}>
                                                                    <img src="assets/images/icons/edit-primary.svg" alt="Edit" className="h-20" />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    </div>
                                                    {/* <h6 className="fw-bold">Scheduled contact for 05/sep • 14h via phone</h6> */}
                                                    <hr className="w-40px border-dark border-opacity-50" />
                                                    <p className="mb-3">
                                                        
                                                    </p>
                                                    <p className="mb-3">
                                                        {item.ST_OBSERVACAO_SCH}
                                                    </p>
                                                    <span className="text-secondary d-block text-end">{item.TX_SACADO_NAME_SCH}</span>
                                                </Card.Body>
                                            </Card>
                                        </React.Fragment>
                                    )
                                })
                                :
                                <div className='text-dark-70 fw-bold m-3 h2 text-center'>
                                    Empty contact history......
                                </div>
                        }



                        {/* <h6 className="text-dark-70 fw-bold d-flex align-items-center mb-3"><img src="assets/images/icons/calendar-regular.svg" alt="calendar" className="me-1 me-sm-2" />04/sep/2023</h6>
                        <Card className="border border-dark border-opacity-10 mb-lg-4 mb-3">
                            <Card.Body>
                                <h6 className="fw-bold">Scheduled contact for 05/sep • 14h via phone</h6>
                                <hr className="w-40px border-dark border-opacity-50" />
                                <p className="mb-3">If the customer mentions financial issues, be prepared to suggest flexible negotiation options, as agreed internally.</p>
                                <span className="text-secondary d-block text-end">Fabiana Guedes Dias</span>
                            </Card.Body>
                        </Card> */}



                    </Offcanvas.Body>
                </Form>
            </Offcanvas>
            {/* modal start */}
            {/* <RegisterNewContact showRegisterNewContact={showRegisterNewContactModal} registerNewContactClose={registerNewContactModalClose} registerNewContactShow={registerNewContactModalShow} /> */}
            <ScheduleContact
                showScheduleContact={showRegisterNewContactModal}
                scheduleContactClose={registerNewContactModalClose}
                storeData={storeData[0] ?? storeData}
                type="reschedule"
                other={editor}
                setEditor={setEditor}
                state={state}
                loading={loading}
                setLoading={setLoading}
            />
        </>
    )
}

export default ContractHistroy
