import { Form, Offcanvas, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { brokerClose, propertyOpen, insertBroker } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getBroker } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { ownerForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { openErrorbox } from '../../Contracts/Common/actionPerform';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation } from '../../../constants/utils';
import i18n from '../../../../i18n';

const BrokerService = (ownerForm) => {

    const dispatch = useDispatch<AppDispatch>()
    const { billingData } = useSelector((state: RootState) => state.expenditureData)
    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
    }, [dispatch]);

    const [loader_on, setloader_on] = useState(false);
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const [t] = useTranslation();
    const { newBrokerShow } = useSelector((state: RootState) => state.contractData)
    const [hide, setHide] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(true);


    /*------------History modal hide function-----------*/

    const newBrokerClose = () => {
        dispatch(propertyOpen(true));
        dispatch(brokerClose(false));
        setHide2(false);
        setHide3(false);
        setHide(true);
        setDouble(false);
        reset();
    }

    /*------------Form validation-----------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const condosValidation1 = Yup.object().shape({
        cnpj: Yup.string().required(t('requiredMmessage.fieldRequired')),
    });
    const condosValidation2 = Yup.object().shape({
        name: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        telephone: Yup.string()
            .required(t("requiredMmessage.fieldRequired"))
            .matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
        email: Yup.string().trim()
            .required(t('requiredMmessage.fieldRequired'))
            .email(t('requiredMmessage.fieldNotValid'))
    });
    let frm = {
        resolver: yupResolver(condosValidation1)
    }
    if (hide2) {
        frm = {
            resolver: yupResolver(condosValidation2)
        }
        openErrorbox();
    }

    /*------------Submit history form data-----------*/

    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<ownerForm>(frm);
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => { setHide(true); setHide2(false); setHide3(false); }
    const step2 = (data: ownerForm) => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false); setHide2(true); setHide3(false);
        }
    }
    const step3 = (data: ownerForm) => { setHide(false); setHide2(false); setHide3(true); }
    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });
    const onSubmit = async (data: ownerForm, e: any) => {
        e.preventDefault();
        // setDouble(true)
        setloader_on(true)
        data["new_added"] = {
            addState: addState,
            Renda: {
                FL_SEMRENDA_PES: data.possiu ?? "",
                FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                ST_BENEFICIO_PES: data.beneficio ?? "",
                VL_OUTROSRENDIMENTOS_PES: data.rendimentous ?? ""
            },
            Retencao_de_impostos: {
                FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
            },
            Mais_opcoes: {
                NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
            },
            Fornecedor: {
                FL_SINCRONIZARSACADO_FAV: ""
            },
            Pagemento: {
                DT_DIAREPASSE_PES: data.Repassedia ?? "",
                VL_SALDOBLOQUEADO_PES: data.bloquendo ?? ""
            }
        }

        let response = await dispatch(insertBroker(data));
        if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
            setDouble(false);
            Swal.fire({
                // icon: 'success',"success",
                title: t("Sweetalert.Success"),
                text: response.payload.message,
                confirmButtonText: "OK",
                timer: 2000
            })
            dispatch(getBroker())
            newBrokerClose();
            setloader_on(false)
        } else {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: response.payload.message,
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
            setloader_on(false)
        }
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }

    const handleCPF_CNJPVaue = (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
        } else {
            // errorCNPJCPF(e.target.value)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Offcanvas className="border-sm-none" show={newBrokerShow} onHide={newBrokerClose} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <Offcanvas.Header className="border-bottom" closeButton>
                        <Offcanvas.Title className="fw-semibold">
                            <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />{t('newContractDev.newRealEstateAgent')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p className="mb-lg-4 mb-3">{t('newContractDev.newRealEstateAgentContent')}</p>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf') + " *"} className="mb-lg-4 mb-3">
                            <Form.Control type="text" placeholder={t('formLabel.cnpjCpf') + " *"} className={`${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                            <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-lg-4 mb-3">
                            <Form.Control type="text" placeholder={t('formLabel.name') + " *"} className={`${errors.name ? 'is-invalid' : ''}`}  {...register('name')} />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </FloatingLabel>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.payment')}</h6>
                        <FloatingLabel controlId="floatingSelect" label={t('newContractDev.standardFormPayment')}>
                            <Form.Select className={`${errors.payment ? 'is-invalid' : ''}`}  {...register('payment')} aria-label={t('formLabel.defaultPaymentMethod')}>
                                <option value="">{t('dropdownItem.select')}</option>
                                {/* {
                                        billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                            <option key={index} value={Number(item.id)}>{item.name}</option>
                                        ))
                                            : ''
                                    } */}
                                <option value="1">{t("defaultPaymentMethod1.chequedeposit")}</option>
                                <option value="2">{t("defaultPaymentMethod1.cashdeposit")}</option>
                                <option value="3">{t("defaultPaymentMethod1.pickupchequeonsite")}</option>
                                <option value="4">{t("defaultPaymentMethod1.withdrawmoneyonthespot")}</option>
                                <option value="5">{t("defaultPaymentMethod1.banktransfer")}</option>
                                <option value="6">{t("defaultPaymentMethod1.docted")}</option>
                                <option value="12">{t("defaultPaymentMethod1.pixboleto")}</option>
                            </Form.Select>
                            <div className="invalid-feedback">{errors.payment?.message}</div>
                        </FloatingLabel>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.contactInformation')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.cellphoneNumber')}>
                                    <Form.Control type="text" placeholder={t('newContractDev.cellphoneNumber')} className={`${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                    <div className="invalid-feedback">{errors.mobile?.message}</div>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                    <div className="invalid-feedback">{errors.telephone?.message}</div>
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="email" placeholder={t('formLabel.email')} className={`${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {/* <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.affiliation')}</h6>
                            <Row className="g-lg-4 g-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.motherName')}>
                                        <Form.Control type="text" placeholder={t('formLabel.motherName')}  {...register('mother')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.fatherName')}>
                                        <Form.Control type="text" placeholder={t('formLabel.fatherName')}  {...register('father')} />
                                    </FloatingLabel>
                                </Col>
                            </Row> */}
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('others')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.RGNumber')}>
                                    <Form.Control type="text" placeholder={t('newContractDev.RGNumber')} {...register('rg')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.issuingAuthority')}>
                                    <Form.Control type="text" placeholder={t('newContractDev.issuingAuthority')} {...register('issue_agency')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.issuanceDate')}>
                                    <Form.Control type="date" placeholder={t('newContractDev.issuanceDate')} {...register('shipping_date')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.gender')}>
                                    <Form.Select {...register('sex')} aria-label={t('newContractDev.gender')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t('formLabel.male')}</option>
                                        <option value="2">{t('formLabel.female')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                    <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')}>
                                        <option value="">{t('formLabel.select')}</option>
                                        <option value="1">{t("formLabel.married")}</option>
                                        <option value="2">{t("formLabel.single")}</option>
                                        <option value="3">{t("formLabel.widower")}</option>
                                        <option value="4">{t("formLabel.legallySeparated")}</option>
                                        <option value="5">{t("formLabel.disquitado")}</option>
                                        <option value="6">{t("formLabel.divorced")}</option>
                                        <option value="7">{t("formLabel.stableunion")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.dateOfBirth')}>
                                    <Form.Control type="date" placeholder={t('newContractDev.dateOfBirth')} {...register('birth')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.nacionality')}>
                                    <Form.Control type="text" placeholder={t('newContractDev.nacionality')} {...register('nationality')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.placeOfBirth')}>
                                    <Form.Control type="text" placeholder={t('newContractDev.placeOfBirth')} {...register('nationality')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                    <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {/* <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.address')} </h6>
                            <Row className="g-lg-4 g-3">
                                <Col md={4}>
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                            <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} />
                                        </FloatingLabel>
                                        <InputGroup.Text>
                                            <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.address')}>
                                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.number')}>
                                        <Form.Control type="text" placeholder={t('formLabel.number')}  {...register('number')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                        <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('complement')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.state')}>
                                        <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            <option value="1">B.C</option>
                                            <option value="2">AL</option>
                                            <option value="3">AM</option>
                                            <option value="4">AP</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.city')}>
                                        <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.employmentData')}</h6>
                            <Row className="g-lg-4 g-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.company')}>
                                        <Form.Control type="text" placeholder={t('formLabel.company')}  {...register('employment_company')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.profession')}>
                                        <Form.Control type="text" placeholder={t('formLabel.profession')} {...register('employment_profession')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.fieldOfActivity')}>
                                        <Form.Control type="text" placeholder={t('formLabel.fieldOfActivity')}  {...register('employment_field_activity')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                                        <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')}  {...register('employment_monthly_income')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                  <InputGroup>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} />
                                    </FloatingLabel>
                                    <InputGroup.Text>
                                        <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                    </InputGroup.Text>
                                </InputGroup>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.address')}>
                                        <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.number')}>
                                        <Form.Control type="text" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={8}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                        <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.state')}>
                                        <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            <option value="1">B.C</option>
                                            <option value="2">AL</option>
                                            <option value="3">AM</option>
                                            <option value="4">AP</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.city')}>
                                        <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={4}>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.representative')}</h6>
                            <InputGroup>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                    <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('representative_name')} />
                                </FloatingLabel>
                                <InputGroup.Text>
                                    <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.taxesRetention')}</h6>
                            <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                            <Form.Check type="checkbox" {...register('retention')} label={t('formLabel.withHoldIssqn')} />
                            </Form.Group> */}
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {/* <div className="me-sm-2 mb-sm-0 mb-2 d-flex">
                                <Form.Group>
                                    <Form.Check type="checkbox" {...register('isAfter')} label={t('button.afterSaving')} />
                                </Form.Group>
                            </div> */}
                        <Button variant="outline-primary" onClick={newBrokerClose}>
                            {t('button.close')}
                        </Button>
                        {/* <Button variant="secondary" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)} className="btn btn-outline-primary js-btn-step me-2" >
                                    {t('button.previous')}
                                </Button>
                                    <Button variant="secondary" onClick={handleSubmit(hide ? step2 : step3)} className="btn btn-primary min-w-auto js-btn-step" >
                                        {t('button.next')}
                                    </Button> */}

                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <Button variant="primary" type="submit" className="min-w-auto" onClick={openErrorbox} >
                                    {t('button.save')}
                                </Button>
                        }
                    </div>
                </Form>
            </Offcanvas>
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default BrokerService;