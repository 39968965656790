import React, { useEffect, useState } from 'react';
import { FloatingLabel, Col, Row, Form, Button, Accordion, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { openAccountSetting } from '../../../../redux/slice/AccountPlanSlice';

interface Item {
    bank_rate: string;
    ticket_rate: string;
}

const AccountSetting = (props: any) => {
    const { openSetting } = useSelector((state: RootState) => state.accountplane)
    let { items } = props;
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const Schema = yup.object().shape({
        // item: yup.string().required(t("requiredMmessage.fieldRequired")),
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<Item>({ resolver: yupResolver(Schema) });

    useEffect(() => {
        if (openSetting) {
            if (items && items.length > 0) {
                recursion1(items);
            }
        }
    }, [items && openSetting])

    let [arrDta1, setarrDta1] = useState<any>([]);
    function recursion1(arrDta: any) {
        if (!arrDta || arrDta.length === 0) {
            return []; // Base case: return an empty array if input is invalid
        }

        if (arrDta && arrDta.length > 0) {
            arrDta.forEach((item: any, index: number) => {
                if (item["childData"] && item["childData"].length > 0) {
                    recursion1(item["childData"]);
                } else {
                    let newObject: any = {
                        accid: item.item,
                        description: item.description,
                        fullname: `${item.item} - ${item.description}`
                    };
                    setarrDta1((prevState: any) => [...prevState, newObject]);
                }
            })
        }
    }

    const settingSubmit = (data: any) => {
        console.log(data, "This is dataaa");
    }

    const closeSettingSubmit = () => {
        dispatch(openAccountSetting(false))
        setarrDta1([]);
        reset();
    }

    return (
        <>
            <Modal show={openSetting} onHide={closeSettingSubmit}>
                <Form onSubmit={handleSubmit(settingSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Setting")}</Modal.Title>
                    </Modal.Header>
                    <h4 className="mx-4">{t("Accounts categories special")}</h4>
                    <Modal.Body>
                        &nbsp;
                        <h5>Bank rate</h5>
                        <Row className="gy-3 mt-1">
                            <Col>
                                <FloatingLabel
                                    controlId="bank_rate"
                                    label={t("Account category for bank rate")}
                                >
                                    {/* <Form.Control
                                        type="text"
                                        className={`${errors.bank_rate ? "is-invalid" : ""}`}
                                        {...register("bank_rate")}
                                        placeholder="Account category for bank rate"
                                    /> */}
                                    <Form.Select {...register("bank_rate")} aria-label="Status">
                                        <option value="All">{t("All")}</option>
                                        {arrDta1.map((item: any, index: number) => (
                                            <option key={index} value={item.accid}>{item.fullname}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.bank_rate?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        &nbsp;
                        <h5>Ticket rates</h5>
                        <Row className="gy-3 mt-1">
                            <Col>
                                <FloatingLabel
                                    controlId="ticket_rate"
                                    label={t("Account category for ticket rates")}
                                >
                                    <Form.Select {...register("ticket_rate")} aria-label="Status">
                                        <option value="All">{t("All")}</option>
                                        {arrDta1.map((item: any, index: number) => (
                                            <option key={index} value={item.accid}>{item.fullname}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ticket_rate?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeSettingSubmit}>
                            {t("button.cancel")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t("button.createitem")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AccountSetting