import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
import axios from "axios";
let companyV2_URL = require('../../Api/Contact').config.companyV2_URL;
let connectionName : any = '';
if( localStorage.getItem("userData") ){
  connectionName = localStorage.getItem("userData");
}
let initialState:any = {
    bank_transaction: {
        list : []
    },
    loading: false,
};

const BankTransactionDataSlice = createSlice({
    name: "Bank Transaction",
    initialState,
    reducers: {
        bankTrans: (state, action) => {
            state.loading = action.payload
        },
        filterData:(state, action) =>{
        }
    },
    extraReducers: (builder) => {
        //Getting all services
        builder.addCase(fetchBankTransaction.pending, (state, { payload }) => {
            state['loading'] = true;
        }).addCase(fetchBankTransaction.fulfilled, (state, { payload }) => {
            state.bank_transaction.list = payload;
            state['loading'] = false;
        }).addCase(fetchBankTransaction.rejected, (state, { payload }) => {
            state.servicelist = [];
            state['loading'] = false;
        });
    }
})

export const { bankTrans, filterData } = BankTransactionDataSlice.actions
export default BankTransactionDataSlice.reducer

export const fetchBankTransaction = createAsyncThunk(
    "get/bank_transaction", async (postData:any) => {
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(`${companyV2_URL}/finance/bank_transactions/complete`, {
          method: 'POST',
          body: JSON.stringify(postData),
          headers: {
            'x-auth-token': token,
            'Content-type': 'application/json',
            'connection-name' : connectionName
          },
        });
        const data = await response.json();
        return data 
    });