import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { newChecklistClose, insertChecklist, addNewChecklist } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { set, useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { newChecklistForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { getAllChecklistItems, getAllContractChecklist, getAllChecklists } from "../../../../redux/slice/expenditureDataSlice";
import '../../../../App.css';
const ChecklistService = (props) => {
    const { newChecklistShow, status } = useSelector((state: RootState) => state.contractData)
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const { myChecklists, contractId, newAgencyChecklist, checkListId, setCheckListId, checkListType } = props;
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { checklistData } = useSelector((state: RootState) => state.expenditureData)
    const [isActive, setIsActive] = useState(0);
    useEffect(() => {
        if (checkListId !== undefined) {
            editChecklist(checkListId)
            setValue("id", checkListId)
            let checkData = { "name": "", "status": "All", "start": 0, "end": 10 }
            dispatch(getAllChecklists(checkData))
        } 
    }, [checkListId])
    /*------------Checklist modal hide function-----------*/
    const newChecklistsClose = () => {
        dispatch(newChecklistClose(false));
        reset(); 
        setDouble(false);
        let checkData = { "name": "", "status": "All", "start": 0, "end": 10 }
        dispatch(getAllChecklists(checkData))
        // setCheckListId(0)
    }
    /*------------Form validation-----------*/
    var checklistValidation = Yup.object().shape({
        checklistId: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(checklistValidation)
    }
    if (newAgencyChecklist && (newAgencyChecklist == 'true' || newAgencyChecklist == true)) {
        var checklistNewValidation = Yup.object().shape({
            name: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired')),
        });
        frm = {
            resolver: yupResolver(checklistNewValidation)
        }
    }
    /*------------Submit checklist form data-----------*/
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<newChecklistForm>(frm);
    const onSubmit = async (data: newChecklistForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        data['contractId'] = contractId;
        let response: any = '';
        if (newAgencyChecklist && newAgencyChecklist == true && data.id == "" && contractId == '') {
            data['addNewChecklist'] = true;
            response = await dispatch(addNewChecklist(data));
        } else if ( data.id !== "" && contractId == '' ){
         data["checklistId"] = data.id
           response = await dispatch(addNewChecklist(data));
        }else{
            response = await dispatch(insertChecklist(data));
        }
        if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
            setDouble(false);
            Swal.fire({
            // icon:'success',
            title:t("Sweetalert.Success"),
            text:t("Sweetalert.successget"),
            confirmButtonText:"OK",
            timer:2000})
            dispatch(getAllContractChecklist({ id: id, start: 0, end: 10 }))
            newChecklistsClose();
        }
        else {
            setDouble(false)
            Swal.fire({
            title:t("Sweetalert.Error"),
            text:response.payload.data?response.payload.data:t("Sweetalert.successfailed"),
            // icon:"error",
            confirmButtonText:"OK",
            timer:2000})
        }
    }

    const editChecklist = (id) => {
        checklistData?.data?.forEach((curElem) => {
            if (curElem.id == id) {
                const fields: string[] = ['id', 'name']
                fields.forEach((field: any) => setValue(field, curElem?.[field]));
            }
        });
    }

    const issActive = (evt, type) => {
        setIsActive(Number(evt.target.value));
    }
    return (
        <>
            {/* <!-- New checklist modal start from here --> */}
            <Modal show={newChecklistShow} onHide={newChecklistsClose} id="checklisttsxServiceTemplate">
                <Modal.Header closeButton>
                    <Modal.Title>{t("checklistother.newchecklist")}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" value={id} {...register('id')} />
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3">

                            {!newAgencyChecklist && newAgencyChecklist == ''  &&
                                <Col xs>
                                    <FloatingLabel controlId="floatingSelect" label={t("envelop.Type")+ " *"}>
                                        <Form.Select className={`form-control ${errors.checklistId ? 'is-invalid' : ''}`} {...register('checklistId')} aria-label="Type">
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {myChecklists && myChecklists.length > 0 ? myChecklists.map((item, index) => (
                                                <option key={index} value={item.ID_CHECKLIST_CHK}>{item.ST_NOME_CHK}</option>
                                            ))
                                                :
                                                ''
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.checklistId?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            }

                            {/* Field to add new checklist for agency */}
                            {newAgencyChecklist && newAgencyChecklist == true &&
                                <Col xs>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.name")+ " *"}>
                                        <Form.Control type="text" className={`${errors.name ? 'is-invalid' : ''}`}  {...register('name')} placeholder={t("formLabel.name")} />
                                        <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            }
                            {checkListId ?  
                            <Col md={12}>
                            <Row className="gy-lg-4 gy-3">
                             <Col md="auto">
                                 <Form.Group>
                                     <Form.Check
                                         checked={isActive === 1}
                                         type="radio"
                                         {...register("is_active")}
                                         onChange={(e) =>
                                             issActive(e, "contractsituationinput")
                                         }
                                         value="1"
                                         label={t("active")}
                                     />
                                 </Form.Group>                             
                             </Col>
                             <Col md="auto">
                                 <Form.Group>
                                     <Form.Check
                                         checked={isActive === 0}
                                         type="radio"
                                         {...register("is_active")}
                                         onChange={(e) =>
                                             issActive(e, "contractsituationinput")
                                         }
                                         value="0"
                                         label={t("pending")}
                                     />
                                 </Form.Group>

                             </Col>
                            </Row>
                            </Col>:
                         ""
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={newChecklistsClose}>{t("button.close")}</Button>
                        <Button disabled={double} type="submit" variant="primary">{t("button.toSave")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /New checklist modal end --> */}
        </>
    )
}
export default ChecklistService;