import React from "react";

import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";
import { z } from "zod";

export const CaixaEconomicaSchema = z.object({
  ContaCodigoBeneficiario: z.string().min(1, "Campo obrigatório"),
  ConvenioNumero: z.string().min(1, "Campo obrigatório"),
  ConvenioDescricao: z.string().min(1, "Campo obrigatório"),
  ConvenioCarteira: z.string().min(1, "Campo obrigatório"),
  ConvenioPadraoCNAB: z.string().min(1, "Campo obrigatório"),
  TituloCodBaixaDevolucao: z.string().min(1, "Campo obrigatório"),
  TituloPrazoBaixa: z.string().min(1, "Campo obrigatório"),
});

type CaixaEconomicaFormType = z.infer<typeof CaixaEconomicaSchema>;

export function CaixaEconomicaFields() {
  const { register } = useFormContext();
  const { errors } = useFormState<CaixaEconomicaFormType>();

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ContaCodigoBeneficiario"
            label="Código beneficiário"
          >
            <Form.Control
              placeholder="Código beneficiário"
              {...register("ContaCodigoBeneficiario")}
              className={`${errors.ContaCodigoBeneficiario && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ContaCodigoBeneficiario?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioNumero" label="Convênio">
            <Form.Control
              placeholder="Convênio"
              {...register("ConvenioNumero")}
              className={`${errors.ConvenioNumero && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioNumero?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioDescricao"
            label="Descrição do convênio"
          >
            <Form.Control
              placeholder="Descrição do convênio"
              {...register("ConvenioDescricao")}
              className={`${errors.ConvenioDescricao && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioDescricao?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioPadraoCNAB" label="CNAB">
            <Form.Select {...register("ConvenioPadraoCNAB")}>
              <option disabled>CNAB</option>
              <option value="240" defaultChecked>
                240 (Recomendado)
              </option>
              <option value="400">400</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioCarteira" label="Tipo de carteira">
            <Form.Select {...register("ConvenioCarteira")}>
              <option disabled>Tipo de carteira</option>
              <option value="14" defaultChecked>
                14 - Cobrança com registro (SIGCB)
              </option>
              <option value="24">24 - Cobrança sem registro (SIGCB)</option>
              <option value="RG">RG - Cobrança com registro</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="TituloCodBaixaDevolucao" label="Código para baixa de devolução">
            <Form.Select {...register("TituloCodBaixaDevolucao")}>
              <option disabled>Código para baixa de devolução</option>
              <option value="1" defaultChecked>
              1 - Baixar/Devolver
              </option>
              <option value="2">2 - Não baixar / Não devolver</option>
              <option value="3">3 - Cancelar prazo para baixa / Devolução</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="TituloPrazoBaixa"
            label="Prazo para baixa em dias (exemplo: 30)"
          >
            <Form.Control
              placeholder="Prazo para baixa em dias (exemplo: 30)"
              {...register("TituloPrazoBaixa")}
              className={`${errors.TituloPrazoBaixa && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.TituloPrazoBaixa?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
