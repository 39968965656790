import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import CommonToast from './CommonToast';

const SaveChange = (props) => {
    //Success modal close open function
    const { showSaveChange, saveChangeClose, saveChangeShow } = props;
        //Form submit
        const saveChangeFormSubmit = (e) => {
            e.preventDefault();
            saveChangeClose();
        };
    
        //Common toast
        const [showToast, setToastShow] = useState(false);
        const commonToastClose = () => setToastShow(false);
        const commonToastShow = () => {
            setToastShow(true);
            setTimeout(() => {
                setToastShow(false);
            }, 5000);
        }
    return (
        <>
        <Modal show={showSaveChange} onHide={saveChangeClose}>
            <Form onSubmit={saveChangeFormSubmit}>
                <Modal.Header className="pb-0" closeButton>
                Save changes?
                </Modal.Header>
                <Modal.Body>
                    If you don't save, the changes made will be discarded.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={saveChangeClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={commonToastShow}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
         {/* Toast start from here */}
         <CommonToast showCommonToast={showToast} commonToastClose={commonToastClose} />
         {/* Toast end here */}
         </>
    )
}

export default SaveChange
