import { config } from "../../../../services/apiConfig";

type NewBankAccountPayload = {
  account: string;
  accountType: string;
  agency: string;
  bankname: string;
  branchtype: string;
  date: string;
  documentNo: string;
  moneyaccount: number;
  nameReference: string;
  nameinBank: string;
  user_id: number;
};

export async function createNewBankAccount({
  ...newBankAccountPayload
}: NewBankAccountPayload) {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(`${config.companyV2_URL}/bank/bank_accountNew`, {
    method: "PUT",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      "connection-name": connectionName,
    },
    body: JSON.stringify(newBankAccountPayload),
  });

  const data = await response.json();

  if (data.resCode === 201 || data.resCode === 200) return data.data;

  throw new Error(data.data?.message || data.data);
}
