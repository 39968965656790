import React, { useState, useEffect, MouseEvent } from 'react';
import { Table, Form, Button, Badge, ProgressBar, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import NewServiceNote from './NewServiceNote';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { userService } from '../../../../services/userService';
import Loader from '../../../Profile/Company/Loader';
import Checkbox from '../../../Pages/Contracts/Common/Checkbox';
import eventBus from "../ReceivableCharges/EventBus";
import { incomeService } from '../../../../services/incomeServices';
import { getBraches } from '../../../../redux/slice/branchSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { DownloadsPdf, getPendingInvoice } from '../../../../redux/slice/taxationSlice';
import { converter } from '../../../constants/utils';
import Swal from 'sweetalert2';
import InvoicesOverview from './InvoicesOverview';
import customerService from '../../../../services/customerService';
interface DATA {
    id: any;
    first_name: string;
    last_name: string;
    email: string;
    isChecked: boolean
}

const InvoicesListing = ({ params, getInvioceList }) => {
    //New charges modal initialization
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [showNewServiceNoteModal, setNewServiceNoteModal] = useState(false);
    const newServiceNoteModalClose = () => setNewServiceNoteModal(false);
    const newServiceNoteModalShow = () => setNewServiceNoteModal(true);
    const [allInvoices, setInvoices] = useState<Array<DATA>>([]);
    const [getSingleInvoice, setGetSingleInvoice] = useState<any>([])
    const [tenantData, setTenantData] = useState('')
    // const [ chargesList,setAllInvoices ] = useState<any>([]);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [status, setStatus] = useState("");
    const [downloadStatus, setDownloadStatus] = useState("ab");
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });

    const updateRecord = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const charge_Id = e.currentTarget.name;
        setNewServiceNoteModal(true);
        eventBus.dispatch("chargeIdis", charge_Id);
    }

    // Get All Invoices
    const getAllInvoices = (param) => {
        let groupData = { groupId: param.invoiceId, page: 1, end: 10 }
        userService.getAllGroups(groupData).then(res => {
            if (res?.data) { setInvoices(res?.data); }
            else { setInvoices([]) }
            setStatus("");
        }).catch(err => {
            setStatus("");
        });
    }

    useEffect(() => {
        // Dispatch the action to get branches when the component mounts
        dispatch(getBraches(branchState))
    }, [dispatch]);

    const [clientData, setClientData] = useState<any>([])
    // Get Invoices
    useEffect(() => {
        let compData: any = '';
        if (params) {
            compData = params ? JSON?.parse(params) : '';
        }
        if (compData.action == 'selectall') {
            selectAllRows();
        } else if (compData.action == 'unselectall') {
            unSelectAllRows();
        } else if (compData.action == 'change_expiration') {
            //console.log('Change Expiration Funtion with API')
        } else {
            setStatus("loading");
            getAllInvoices({ invoiceId: '' });
        }

    }, []);

    useEffect(() => {
        (async () => {
            // let GetClientData = await incomeService?.getClients();
            // if (GetClientData?.data?.clients.length > 0) {
            //     console.log(GetClientData?.data?.clients, "11111")
            //     setClientData(GetClientData.data.clients)
            // }
            (async () => {
                let ownerData = { owner: "", start: 0, end: 1000, status: "", person: "", page: 'ownersandbenef', invoice: "invoiceList" }
                customerService.getAllOwner(ownerData).then(res => {
                    if (res?.data?.resCode == 201) {
                        const matchingData: any = res?.data?.data.filter(item =>
                            res?.data?.data.some(otherItem => item?.id == otherItem?.idtenant)
                        );
                        setClientData(matchingData);
                    } else {
                        setClientData([]);
                    }
                }).catch(err => {
                });

            })()
        })()
    }, [])
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(getInvioceList?.map(li => li.ID_NOTA_NOT));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const handleClick = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
        }
        if (document.querySelectorAll(".check_Checkbox input").length > 0) {
            let data = document.querySelectorAll(".check_Checkbox input");

            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++;
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                });
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10);
        }
    };
    const selectAllRows = () => {
        setInvoices(allInvoices?.map(topping => ({ ...topping, isChecked: true })))
    }
    const unSelectAllRows = () => {
        setInvoices(allInvoices?.map(topping => ({ ...topping, isChecked: false })))
    }
    // Check / Uncheck Single row event.
    const selectRow = async (e) => {
        setInvoices([])
        await allInvoices?.map(data => {
            if (data && parseInt(e.target.value) === data?.id) {
                data['isChecked'] = !data.isChecked ? true : false;
            }
            return data;
        });
        await setInvoices(allInvoices);
    }

    function convertDateFormat(inputDateString) {
        if (!inputDateString) {
            return ""; // Handle null or undefined case, or return a default value
        }

        const originalDate = new Date(inputDateString);

        // Check if the date is valid
        if (isNaN(originalDate.getTime())) {
            return "Invalid Date";
        }

        const month = originalDate.getMonth() + 1;
        const day = originalDate.getDate();
        const year = originalDate.getFullYear();

        // Create the formatted date string
        const formattedDateString = `${day}/${month}/${year}`;

        return formattedDateString;
    }

    const DownloadPdf = async (id) => {
        setDownloadStatus(id)
        let respanse = await dispatch(getPendingInvoice(id))
        let res: any = await dispatch(DownloadsPdf({ id: id, status: respanse?.payload?.status }))
        if (res.payload.error) {
            Swal.fire({
                title: t("Sweetalert.Success"),
                text: res?.payload?.error?.message,
                // icon: 'success',"success",
                confirmButtonText: "OK",
                timer: 2000
            });
            setDownloadStatus("ab")
        } else {
            // Create a Blob from the response data
            const blob = await res.payload.blob();
            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);
            // Create a link element and trigger a click to download the file
            const a = document.createElement("a");
            a.href = url;
            a.download = `downloaded_${id}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setDownloadStatus("ab")
        }
    };



    const EditInvoice = (id) => {
        setNewServiceNoteModal(true)
        let invoice = {
            invoice_id: id
        }

        incomeService.EditInvoice(invoice).then((res) => {
            if (res.type == "success" || res.type == "Success") {
                setGetSingleInvoice(res.data)
            }
        })
    }

    return (
        <>

            {
                (status == "loading") ?
                    <Loader />
                    : <>
                        <InvoicesOverview getInvioceList={getInvioceList} totalRow={getInvioceList.length} isCheck={isCheck} />
                        <NewServiceNote showServiceNote={showNewServiceNoteModal} serviceNoteShow={newServiceNoteModalShow} serviceNoteClose={newServiceNoteModalClose} getSingleInvoice={getSingleInvoice} />
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0 w-100">
                                    <thead>
                                        <tr>
                                            <th className="w-24">
                                                <Checkbox
                                                    type="checkbox"
                                                    name="selectAll"
                                                    id="selectAll"
                                                    handleClick={handleSelectAll}
                                                    isChecked={isCheckAll}
                                                />
                                            </th>
                                            <th>{t("Invoices.issuance")}</th>
                                            <th>{t("Invoices.client")}</th>
                                            <th></th>
                                            <th>{t("Invoices.value")}</th>
                                            <th>{t("Invoices.Pdf")}</th>
                                            <th className="text-end"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getInvioceList.length > 0 ? (
                                            getInvioceList.map((invoice: any, index) => (
                                                <tr key={index}>
                                                    <td className="check_Checkbox">
                                                        <Checkbox
                                                            key={index}
                                                            type="checkbox"
                                                            name={invoice.ID_NOTA_NOT}
                                                            id={invoice.ID_NOTA_NOT}
                                                            handleClick={handleClick}
                                                            isChecked={isCheck.includes(invoice.ID_NOTA_NOT)}
                                                        />
                                                    </td>
                                                    <td>{convertDateFormat(invoice.DT_EMISSAO_NOT)}</td>
                                                    <td>
                                                        <span className="d-block">
                                                            <img src="assets/images/icons/user_g.svg" alt="User Icon" />
                                                            {" "}
                                                            {invoice?.ID_CLIENTE_NOT} - {invoice?.ST_NOME_PES}
                                                        </span>
                                                        <span className="d-block mb-1">Id {invoice?.ID_NOTA_NOT}</span>
                                                        {(invoice.ID_PLUGNOTAS_ID || invoice.ID_NFELOTE_NOT) && invoice?.statusRespo === "CONCLUIDO" ? (
                                                            <div className="w-75">
                                                                <ProgressBar className="h-10 w-75" variant="success" now={100} />
                                                            </div>
                                                        ) : invoice?.API_RESPONSE && JSON.parse(invoice.API_RESPONSE)?.res?.error ? (
                                                            <div className="w-75">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="error-tooltip">
                                                                            {JSON.parse(invoice.API_RESPONSE)?.res?.error?.message}
                                                                            <br />
                                                                            {Object.entries(JSON.parse(invoice.API_RESPONSE)?.res?.error?.data?.fields || {}).map(
                                                                                ([field, error]: any, index) => (
                                                                                    <div key={index}>
                                                                                        <strong>{field}:</strong> {error}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <ProgressBar className="h-10 w-75" variant="danger" now={100} />
                                                                </OverlayTrigger>
                                                            </div>
                                                        ) : (
                                                            <div className="w-75">
                                                                <ProgressBar className="h-10 w-75" variant="warning" now={50} />
                                                            </div>
                                                        )}

                                                    </td>
                                                    <td className="text-end">
                                                        {invoice.ST_CIDADE_PES &&
                                                            invoice.ST_BAIRRO_PES &&
                                                            invoice.ST_NUMERO_PES &&
                                                            invoice.ST_ENDERECO_PES ||
                                                            invoice.ST_CNPJ_PES ||
                                                            invoice.ST_EMAIL_PES ? (
                                                            <div className="d-flex justify-content-end">
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip>{t("Invoices.exclamation")}</Tooltip>}
                                                                >
                                                                    <span className="cursor-pe icon ">
                                                                        <svg fill="#FF0000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.996 123.996"  stroke="#FF0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M9.821,118.048h104.4c7.3,0,12-7.7,8.7-14.2l-52.2-92.5c-3.601-7.199-13.9-7.199-17.5,0l-52.2,92.5 C-2.179,110.348,2.521,118.048,9.821,118.048z M70.222,96.548c0,4.8-3.5,8.5-8.5,8.5s-8.5-3.7-8.5-8.5v-0.2c0-4.8,3.5-8.5,8.5-8.5 s8.5,3.7,8.5,8.5V96.548z M57.121,34.048h9.801c2.699,0,4.3,2.3,4,5.2l-4.301,37.6c-0.3,2.7-2.1,4.4-4.6,4.4s-4.3-1.7-4.6-4.4 l-4.301-37.6C52.821,36.348,54.422,34.048,57.121,34.048z"></path> </g> </g></svg>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span className="d-block text-nowrap">
                                                            R$ {converter(`${invoice.VL_VALOR_NOT}`)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {invoice?.statusRespo === "CONCLUIDO" && (
                                                            <span
                                                                className="cursor-pe icon"
                                                                onClick={() =>
                                                                    DownloadPdf(
                                                                        invoice.ID_PLUGNOTAS_ID
                                                                            ? invoice.ID_PLUGNOTAS_ID
                                                                            : invoice.ID_NFELOTE_NOT
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src="assets/images/icons/download-arrow-danger.svg"
                                                                    alt="Download"
                                                                    className="h-18"
                                                                />
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center">
                                                    {t("NoRecords")}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </>
            }
        </>
    )
}

export default InvoicesListing
