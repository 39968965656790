import React, { useState } from 'react';
import { Badge, OverlayTrigger, Table, Tooltip, Modal, Form, Button, Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const SendEmailListing = () => {
    const [t] = useTranslation();
    //Email viewed modal initialization
    const [showEmailViewedModal, setEmailViewedModa] = useState(false);
    const emailViewedModalModalClose = () => setEmailViewedModa(false);
    const emailViewedModalModalShow = () => setEmailViewedModa(true);

     //Display modal initialization
     const [showDisplayModal, setDisplayModal] = useState(false);
     const displayModalClose = () => setDisplayModal(false);
     const displayModalShow = () => setDisplayModal(true);
    return (
        <>
        <Card>
            <Card.Body className="pb-1">
           <Table responsive className="table-custom table-custom-bordered mb-0">
                <thead>
                    <tr>
                        <th className="w-24"><input type="checkbox" className="form-check-input" /></th>
                        <th>{t("sendEmail.Recipient")}</th>
                        <th>{t("sendEmail.Message")}</th>
                        <th>{t("dropdownItem.status")}</th>
                        <th className="text-end"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="checkbox" className="form-check-input" /></td>
                        <td>
                            <span className="d-block">paulo.herrera@ingaia.com.br</span>
                            <Badge bg="success">01:27</Badge>
                        </td>
                        <td>
                            67 {t("sendEmail.NotificationsOfDefault")}: 67
                        </td>
                        <td>
                            <span onClick={emailViewedModalModalShow} className="cursor-pe"><img src="assets/images/icons/eye.svg" alt="Eye" className="cursor-pe h-12 me-1" />{t("sendEmail.hasAlreadySeen")}</span>
                        </td>
                        <td className="text-nowrap text-end">
                            <ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("sendEmail.EmailDisplay")}</Tooltip>}>
                               <span className="icon cursor-pe"> <img src="assets/images/icons/detail-search-primary.svg" alt="Detail" onClick={displayModalShow} className="h-20" /></span>
                            </OverlayTrigger>
                                </li>
                                <li className="list-inline-item">
                                <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("button.delete")}</Tooltip>}>
                                <span className="icon cursor-pe"><img src="assets/images/icons/delete-danger.svg" alt="Delete" className="h-20" /></span>
                            </OverlayTrigger>
                                </li>
                            </ul>
                            
                        </td>
                    </tr>
                </tbody>
            </Table>
            </Card.Body>
        </Card>
            {/* Email viewed modal start from here */}
            <Modal show={showEmailViewedModal} onHide={emailViewedModalModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("sendEmail.EmailViewed")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive="lg" className="table-custom table-custom-bordered mb-0">
                            <tbody>
                                <tr>
                                    <td className="px-2"><img src="assets/images/icons/eye.svg" alt="Eye" className="cursor-pe h-12 me-1" /></td>
                                    <td className="px-2">14 {t("sendEmail.hoursAgo")}</td>
                                    <td className="px-2"><img src="assets/images/icons/clock-light.svg" alt="Time" className="cursor-pe h-14 me-1" />01:26:57</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={emailViewedModalModalClose}>
                        {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={emailViewedModalModalClose}>
                        {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Email viewed modal end */}
            {/* Display modal start from here */}
            <Modal show={showDisplayModal} onHide={displayModalClose}>
               <Form>
               <Modal.Header closeButton>
                    <Modal.Title>{t("formLabel.email")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <embed src="./assets/pdf/preview.pdf" width="100%" height="350px" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={displayModalClose}>
                    {t("button.close")}
                    </Button>
                    <Button variant="primary" onClick={displayModalClose}>
                    {t("button.save")}
                    </Button>
                </Modal.Footer>
               </Form>
            </Modal>
            {/* /Display modal end */}
        </>
    )
}

export default SendEmailListing
