import React, { useState, useEffect, useRef } from 'react';
import { FloatingLabel, Table, Form, Badge, InputGroup, Dropdown, Col, Row, Modal, Button, Alert, ButtonGroup, ListGroup, FormControl } from 'react-bootstrap';
import { setEndDate, setStartDate, transferToSwitchOff, getTransferContractsCount, setCheckedId, transferContracts, setTransferId, setMaturityOpen, setFilterType, setTansferFilterOpen } from '../../../../redux/slice/transferDataSlice';

import { Outlet, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import moment from "moment";
import moment from "moment-timezone";
import { useLocation } from 'react-router-dom';
import TansfersListing from './TansfersListing';
import TransfersMoreFilters from './TransfersMoreFilters';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getBraches } from '../../../../redux/slice/branchSlice';
import { getManager } from "../../../../redux/slice/propertyDataSlice";
import { TransferCom, getAllProperty, getOwner } from "../../../../redux/slice/contractDataSlice";

const NewTransfers = () => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const { ownerData } = useSelector((state: RootState) => state.propertyData)
    const [searchParams, setsearchParams] = useState<any>({});
    const [filterParams, setfilterParams] = useState<any>({});
    const [updateFilter, setupdateFilter] = useState<any>(0);
    const [timePeriod, setTimePeriod] = useState("");
    const [t] = useTranslation();
    const location = useLocation();
    const inputRef = useRef<any>(null);

    const dispatch = useDispatch<AppDispatch>();
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100"
    });
    //More filters modal close open function
    const [showMoreFiltersModal, setMoreFiltersModal] = useState(false);
    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [display, setDisplay] = useState(false);

    const [ownerSearch, setOwnerSearch] = useState("");
    const [ownerDataReport, setownerDataReport] = useState([]);

    const moreFiltersModalShow = () => setMoreFiltersModal(true);
    const { TransferType } = useSelector((state: RootState) => state.contractData)
    const handleChange = (e: any, trfType: any) => {
        if (trfType == 'upcoming' || trfType == 'completed') {
            searchParams['transferType'] = trfType;
        }
        if (trfType == 'guaranteed') {
            searchParams['guaranteed'] = e.target.value;

        }
        if (trfType == 'charges') {
            searchParams['charges'] = e.target.value;
        }
        if (trfType == 'timePeriod') {
            searchParams['timePeriod'] = e.target.value;
            setTimePeriod(e.target.value);
        }
        if (trfType == 'status') {
            const { id, checked } = e.target;
            if (e.target.checked) {
                searchParams['status'].push(e.target.value);
                setCheckboxes(prevState => ({
                    ...prevState,
                    [id]: true
                }));
            } else {
                let indexIs = searchParams["status"].indexOf(e.target.value);
                searchParams["status"].splice(1, indexIs);
                // searchParams['status'][`${e.target.value}`]
                // delete searchParams['status']['e.target.value'];
                setCheckboxes(prevState => ({
                    ...prevState,
                    [id]: false
                }));
            }
        }
        setfilterParams(searchParams);
        let newDt = moment().toDate().getTime();
        setupdateFilter(newDt);
        if (trfType == "upcoming") {
            dispatch(TransferCom("upcoming"))
        }
        if (trfType == "search") {
            let name = e.target.value;
            if (name.trim() !== "" && e.target.value !== "" && e.target.value) {
                const lowerSearchTerm = name.toLowerCase();
                const results = ownerDataReport.filter((itemName: any) => {
                    const ownerName = (itemName["name"]).toLowerCase();
                    let list = ownerName.includes(lowerSearchTerm);
                    return list
                });
                setSearchResults(results)
            } else {
                if (ownerDataReport && ownerDataReport.length > 0) {
                    setSearchResults(ownerDataReport)
                }
            }
            searchParams['search'] = e.target.value;
        }
    }

    const handleInputFocus = () => {
        setIsInputFocused(true);
        setDisplay(true);
        if (ownerDataReport && ownerDataReport.length > 0) {
            setSearchResults(ownerDataReport)
        }
    };

    const HandleOwnerCLick = (e: any, item: any) => {
        searchParams['search'] = item.name
        setsearchParams(searchParams)
        let classNameSelect: any = document.querySelector(".ownerNameChage");
        classNameSelect["value"] = item.name;
        setIsInputFocused(false);
        setSearchResults(ownerDataReport)
        // setValue('owner', item?.bank_name)

        setState({
            ...state,
            ...filterParams
        })
    }

    useEffect(() => {
        if (location.state == "completed") {
            dispatch(TransferCom("completed"))
        } else {
            dispatch(TransferCom("upcoming"))
        }
        window.history.replaceState(null, '');
    }, [])


    useEffect(() => {
        if (location.state && location?.state?.dashboard == "yes") {
            state["location"] = location.state
            filterParams["dueDate"] = location.state["searchForm"]
            filterParams["expireDate"] = location.state["endForm"]
            state["status"] = ["pending"]
            setState({
                ...state,
                ...filterParams
            })
        }
    }, [location]);

    useEffect(() => {
        (async () => {
            let dataAre: any = await dispatch(getOwner());
            if (dataAre) {
                setownerDataReport(dataAre["payload"]["data"]);
            } else {
                let ownerD: any = ownerData;
                setownerDataReport(ownerD);
            }
        })()
    }, [dispatch])

    useEffect(() => {
        dispatch(getBraches(branchState));
        dispatch(getManager());

        dispatch(getTransferContractsCount({ start: 0, end: 100 }));

        // let newDate = new Date(dateBrazil);
        // let startDt = moment(newDate).format("MMM/YYYY");
        // let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");

        // startDt = "Jan/2024";
        // endDt    = "Feb/2024";

        let newDate = new Date(dateBrazil);
        let startDt = moment(newDate).subtract(1, 'M');
        let searchDt = moment(newDate)
        let endDt = moment(newDate).add(1, 'M');

        setsearchParams({
            transferType: TransferType !== "" ? TransferType : 'upcoming',
            filterby: "",
            owner: "",
            Withpaidcharges: "",
            Withchargessettledbetween: "",
            Duetotheduedatebetween: "",
            startDate: startDt,
            endDate: endDt,
            searchDate: searchDt,
            filteSearchDate: "",
            filterEndDate: "",
            start: 0,
            end: 20,
            timePeriod: '',
            guaranteed: '',
            charges: '',
            status: [],
            branch: '',
            dueDate: '',
            expireDate: '',
            manager: '',
            number: '',
            propertyType: '',
            search: ''
        })
        setfilterParams(searchParams);
        let newDt = moment().toDate().getTime();
        setupdateFilter(newDt);
    }, [dispatch]);


    const filtersAre = (ee) => {
        searchParams['branch'] = ee.branch;
        searchParams['dueDate'] = ee.dueDate;
        searchParams['expireDate'] = ee.expireDate;
        searchParams['manager'] = ee.manager;
        searchParams['number'] = ee.number;
        searchParams['propertyType'] = ee.propertyType;
        searchParams['formOfPayment'] = ee.formOfPayment;
        setfilterParams(searchParams);
        let newDt = moment().toDate().getTime();
        setupdateFilter(newDt);
    }
    const [checkboxes, setCheckboxes] = useState({
        status1: false,
        status2: false,
        status3: false,
        status4: false
    });
    const moreFiltersModalClose = () => {
        let setvalue: any = document.querySelector("#guaranteed");
        if (setvalue) {
            setvalue.value = ""

        }
        let setvalue1: any = document.querySelector("#contactstatus");
        if (setvalue1) {
            setvalue1.value = '1'

        }
        setMoreFiltersModal(false)
        setfilterParams({
            transferType: TransferType !== "" ? TransferType : 'upcoming',
            filterby: "",
            owner: "",
            Withpaidcharges: "",
            Withchargessettledbetween: "",
            Duetotheduedatebetween: "",
            startDate: "",
            endDate: '',
            filteSearchDate: "",
            filterEndDate: "",
            start: 0,
            end: 20,
            timePeriod: '',
            branch: '',
            status: [],
            guaranteed: '',
            charges: '',
            dueDate: '',
            expireDate: '',
            manager: '',
            number: '',
            propertyType: '',
            search: '',
            filterType: "resetfilter"
        })

        searchParams['guaranteed'] = '';
        searchParams['charges'] = '';
        searchParams['status'] = [];
        searchParams['timePeriod'] = "";
        setfilterParams(searchParams);
        setCheckboxes({
            status1: false,
            status2: false,
            status3: false,
            status4: false
        });
        setTimePeriod("")

    };

    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');

    const [state, setState] = useState({
        transferType: "upcoming",
        filterby: "",
        owner: "",
        Withpaidcharges: "",
        Withchargessettledbetween: "",
        Duetotheduedatebetween: "",
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        filteSearchDate: "",
        filterEndDate: "",
        start: 0,
        end: 20
    })

    const [currentPage, setCurrentPage] = useState(1);
    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = moment(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt,
                ['searchDate']: searchDt,
                ['start']: 0
            });
            setCurrentPage(1);
        }
    }

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="mb-0 lh-base text-truncate">{t("newTransfer.transfers")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap">
                                        {t("bankTransaction.Filter")}
                                        <img src="assets/images/icons/filter-icon.svg" alt="Filter" className="ms-1 h-12" />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Form className="d-xl-block d-none w-100" action="" method="">
                    <Row className="gx-2 gx-xxl-3">
                        <Col xl="auto">
                            <div className="border rounded-pill slide-radio">
                                <input onClick={(e) => handleChange(e, 'upcoming')} type="radio" className="btn-check upcomingchk" name="options-base" id="option5" checked={searchParams.transferType === 'upcoming'} />
                                <label className="btn rounded-pill fw-medium" htmlFor="option5">{t("newTransfer.upcoming")}</label>
                                <input onClick={(e) => handleChange(e, 'completed')} type="radio" className="btn-check completedchk" name="options-base" id="option6" checked={searchParams.transferType === 'completed'} />
                                <label className="btn rounded-pill fw-medium" htmlFor="option6"> {t("newTransfer.completed")}</label>
                            </div>
                        </Col>
                        <Col xl="auto" className="border-end border-xs-none pe-xxl-3">
                            <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                    {/* {state.startDate} */}
                                </Button>
                                <Button variant="primary" className="btn-date capitalize" onClick={() => filterCharge(state.startDate, 'Subtract')} disabled>
                                    {state.searchDate.format('MMM/YYYY')}
                                </Button>
                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')}>
                                    {/* {state.endDate} */}
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                </Button>
                            </ButtonGroup>
                        </Col>
                        {/* <Col xl="auto" className="ps-xxl-3">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm" className={`${filterParams?.["timePeriod"] ? "show" : ""}`}>
                                    {t("newTransfer.timePeriod")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel controlId="time_period" label={t("newTransfer.timePeriod")}>
                                        <Form.Control type="date" placeholder="Time period" onChange={(e) => handleChange(e, 'timePeriod')} id="timeperiod" value={timePeriod} />
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm" className={`${filterParams?.["guaranteed"] ? "show" : ""}`}>
                                    {filterParams?.["guaranteed"] == "All" ? t("newTransfer.alltransfers") : filterParams?.["guaranteed"] == "1" ? t("newTransfer.Guaranteedtransfers") : filterParams?.["guaranteed"] == "0" ? t("newTransfer.nonguaranteed") : t("newTransfer.guaranteed")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel label={t("newTransfer.guaranteed")}>
                                        <Form.Select aria-label="Guaranteed" onChange={(e) => handleChange(e, 'guaranteed')} id="guaranteed">
                                            <option value="">{t("newTransfer.alltransfers")}</option>
                                            <option value="1">{t("newTransfer.Guaranteedtransfers")}</option>
                                            <option value="0">{t("newTransfer.nonguaranteed")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm" className={`${filterParams?.["charges"] ? "show" : ""}`}>
                                    {filterParams?.["charges"] == "1" ? t("newTransfer.Allcharges") : filterParams?.["charges"] == "2" ? t("newTransfer.Settledcharges") : filterParams?.["charges"] == "3" ? t("newTransfer.Settledchargesdelay") : filterParams?.["charges"] == "4" ? t("newTransfer.Settledwithoutdelay") : filterParams?.["charges"] == "5" ? t("newTransfer.Notsettledcharges") : t("newTransfer.charges")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <FloatingLabel label={t("newTransfer.charges")} >
                                        <Form.Select aria-label="Charges" onChange={(e) => handleChange(e, 'charges')} id="contactstatus">
                                            <option value="1">{t("newTransfer.Allcharges")}</option>
                                            <option value="2">{t("newTransfer.Settledcharges")}</option>
                                            <option value="3">{t("newTransfer.Settledchargesdelay")}</option>
                                            <option value="4">{t("newTransfer.Settledwithoutdelay")}</option>
                                            <option value="5">{t("newTransfer.Notsettledcharges")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm" className={filterParams?.["search"] ? "show" : ""}>
                                    {t("owner")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <InputGroup>
                                        {/* <FloatingLabel controlId="floatingInput" label={t("owner")}>
                                            <Form.Control type="text" placeholder="tenants"
                                                onChange={(e) => handleChange(e, 'search')}
                                                onFocus={() => handleInputFocus()} />
                                            {isInputFocused && (
                                                <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}>
                                                    {searchResults?.slice(0, 1000).reverse().map((item: any, index) => (
                                                        <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleOwnerCLick(e, item.id)}>
                                                            {item.name}
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            )}
                                        </FloatingLabel> */}

                                        <Form.Floating className="mb-4" ref={inputRef} >
                                            <Form.Control
                                                type="text"
                                                placeholder="Type to search..."
                                                className='ownerNameChage'
                                                onChange={(e) => handleChange(e, 'search')}
                                                onFocus={handleInputFocus}
                                                autoComplete="off"
                                            />
                                            {isInputFocused && (
                                                <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}>
                                                    {searchResults?.slice(0, 10).reverse().map((item: any, index) => (
                                                        <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleOwnerCLick(e, item)}>
                                                            {item.name}
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            )}
                                            <Form.Label htmlFor="floatingInputValue">{t("owner")}</Form.Label >
                                        </Form.Floating>
                                        <InputGroup.Text>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm" className={filterParams?.["status"]?.length > 0 ? "show" : ""}>
                                    {t("dropdownItem.status")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                    <Form.Check value="pending" onChange={(e) => handleChange(e, 'status')} type="checkbox" label={t("newTransfer.Pending")} className="mb-3" id="status1" checked={checkboxes.status1} />
                                    <Form.Check value="verified" onChange={(e) => handleChange(e, 'status')} type="checkbox" label={t("newTransfer.verified")} className="mb-3" id="status2" checked={checkboxes.status2} />
                                    <Form.Check value="postedinfinancial" onChange={(e) => handleChange(e, 'status')} type="checkbox" label={t("newTransfer.Postedinfinancial")} className="mb-3" id="status3" checked={checkboxes.status3} />
                                    <Form.Check value="settled" onChange={(e) => handleChange(e, 'status')} type="checkbox" label={t("newTransfer.Settled")} id="status4" checked={checkboxes.status4} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                        <Col xl="auto">
                            <Dropdown autoClose="outside" className="moreFilter">
                                <Dropdown.Toggle variant="light" size="sm" className={`d-flex align-items-center ${searchParams['branch'] || searchParams['dueDate'] || searchParams['expireDate'] || searchParams['manager'] || searchParams['number'] || searchParams['propertyType'] || searchParams['formOfPayment'] ? "show" : ""}`} onClick={moreFiltersModalShow}>
                                    {t("moreFilters")}
                                    <img
                                        src={`assets/images/icons/${searchParams['branch'] || searchParams['dueDate'] || searchParams['expireDate'] || searchParams['manager'] || searchParams['number'] || searchParams['propertyType'] || searchParams['formOfPayment'] ? "icons8-plus-24.png" : "plus-light.svg"}`}
                                        alt="Plus icon"
                                        className="ms-xl-1 ms-auto h-20"
                                    />
                                </Dropdown.Toggle>
                            </Dropdown>

                            <TransfersMoreFilters showMoreFilters={showMoreFiltersModal} moreFiltersClose={moreFiltersModalClose}
                                filtersAre={filtersAre} />
                        </Col>
                    </Row>
                </Form>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <TansfersListing
                filterParams={filterParams}
                updateFilter={updateFilter}
                state={state}
                setState={setState}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </section>
    )
}

export default NewTransfers
