import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { add_Bank, getBankList } from "../../../../../../redux/slice/bankSlice";
import { getFormsofReceipt } from "../../../../../../redux/slice/formsOfReceiptSlice";
import type { AppDispatch, RootState } from "../../../../../../redux/store";
import { converter } from "../../../../../constants/utils";
import { ACCOUNT_TYPES } from "../constants";
import type { ReceivingMethod } from "../definitions";
import { useHomologatedBankAccounts } from "./useHomologatedBankAccounts";

const GET_BANK_LIST_DEFAULT_VALUES = {
  start: "0",
  end: "100",
};

export function useBankAccounts() {
  const { homologatedBankAccounts, getHomologatedBankAccounts } =
    useHomologatedBankAccounts();
  const dispatch = useDispatch<AppDispatch>();

  const { wayofreceiving } = useSelector((state: RootState) => state.forms);
  const { userbanklist, banklist } = useSelector(
    (state: RootState) => state.bank
  );

  const refreshBankAccounts = useCallback(async () => {
    return dispatch(getBankList(GET_BANK_LIST_DEFAULT_VALUES));
  }, [dispatch]);

  const refreshReceivingMethods = useCallback(async () => {
    return dispatch(getFormsofReceipt());
  }, [dispatch]);

  const refreshBanks = useCallback(async () => {
    return dispatch(add_Bank());
  }, [dispatch]);

  const bankAccounts = useMemo(() => {
    return userbanklist.map((userBankAccount) => {
      const receivingMethods = wayofreceiving
        .filter(
          (receivingMethod) =>
            receivingMethod["ID_CONTA_CB"] === userBankAccount.id
        )
        .reduce(
          (acc, receivingMethod) => {
            if (receivingMethod["FL_BOLETOSUPERLOGICA_FRECB"] === 0) {
              return {
                ...acc,
                kenloPay: {
                  id: receivingMethod["ID_FORMA_FRECB"],
                  type: receivingMethod["FL_BOLETOSUPERLOGICA_FRECB"],
                  isActive: receivingMethod["FL_PRODUCAO_FRECB"] === 0,
                },
              };
            }

            if (receivingMethod["FL_BOLETOSUPERLOGICA_FRECB"] === 1) {
              return {
                ...acc,
                homologated: {
                  id: receivingMethod["ID_FORMA_FRECB"],
                  type: receivingMethod["FL_BOLETOSUPERLOGICA_FRECB"],
                  isActive: receivingMethod["FL_PRODUCAO_FRECB"] === 0,
                },
              };
            }

            return {
              ...acc,
              manualCash: {
                id: receivingMethod["ID_FORMA_FRECB"],
                type: receivingMethod["FL_BOLETOSUPERLOGICA_FRECB"],
                isActive: receivingMethod["FL_PRODUCAO_FRECB"] === 0,
              },
            };
          },
          {} as {
            kenloPay: ReceivingMethod;
            manualCash: ReceivingMethod;
            homologated: ReceivingMethod;
          }
        );
      const accountType =
        userBankAccount &&
        userBankAccount.accountType &&
        userBankAccount.accountType.length
          ? userBankAccount.accountType[0]
          : "";
      const homologatedAccount = homologatedBankAccounts?.find(
        (homologatedBankAccount) =>
          String(userBankAccount.bankname).padStart(3, "0") ===
            homologatedBankAccount.codigo_banco &&
          String(userBankAccount.agency).replace(/\D/, "") ===
            `${homologatedBankAccount.agencia}${homologatedBankAccount.agencia_dv ? homologatedBankAccount.agencia_dv : ""}` &&
          String(userBankAccount.account).replace(/\D/, "") ===
            `${homologatedBankAccount.conta}${homologatedBankAccount.conta_dv ? homologatedBankAccount.conta_dv : ""}`
      );
      return {
        ...userBankAccount,
        accountType,
        bankFullName: `${userBankAccount.bankname} - ${
          banklist.find(
            (bank) => bank.bank_code === String(userBankAccount.bankname)
          )?.bank_name
        } `,
        balance: converter(userBankAccount.moneyaccount),
        accountTypeDescription: ACCOUNT_TYPES[accountType],
        isHomologated: !!homologatedAccount,
        homologatedAccount,
        receivingMethods,
      };
    });
  }, [banklist, homologatedBankAccounts, userbanklist, wayofreceiving]);

  useEffect(() => {
    refreshBankAccounts();
    refreshBanks();
    refreshReceivingMethods();
  }, [refreshBankAccounts, refreshBanks, refreshReceivingMethods]);

  useEffect(() => {
    if (userbanklist.length === 0) return;

    getHomologatedBankAccounts();
  }, [getHomologatedBankAccounts, userbanklist]);

  return {
    bankAccounts,
    refreshBankAccounts,
  };
}
