import { Accordion,FloatingLabel,Modal,Form,Button } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectPayment = (props) =>  {
    const [t] = useTranslation();
    const { showAuthorizationDate, authorizationDateClose, authorizationDateModalShow, checkboxClicked } = props;
 
    return (

            <Modal show={showAuthorizationDate} onHide={authorizationDateClose}>
                {checkboxClicked ? (
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("paidTobepaid.BorderoSelectpaymentauthorizationdate")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="authorization-date" className="mb-3 mb-lg-4" label={t("paidTobepaid.Authorizationdate")}>
                            <Form.Control type="date" placeholder={t("paidTobepaid.Authorizationdate")} />
                        </FloatingLabel> 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={authorizationDateClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={authorizationDateClose}>
                        {t("paidTobepaid.Generatebordero")}
                        </Button>
                    </Modal.Footer>
                </Form>
                ) : (
                    <div>
                        <p>{t("paidTobepaid.Checkboxisnotchecked")}!</p>
                        {/* You can add additional elements or styling here */}
                    </div>
                )
                }
            </Modal>
    )
}

export default SelectPayment