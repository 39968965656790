import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Table, Button, Row, Col, Card, Dropdown, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { contractDatails, contractsData, getDocuments, getPersonDocuments, openEmailPop } from '../../../../../redux/slice/contractDataSlice';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useLocation, useParams } from 'react-router-dom';
import customerService from '../../../../../services/customerService';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../Profile/Company/Loader';
import { config } from '../../../../../services/apiConfig';
import { emailPost } from '../../../../../redux/slice/SendEmailSlice';
import SendDocumentEmail from '../../Common/SendDocumentEmail';
import moment from "moment-timezone";
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

interface Document {
  contract_id: string;
  file_name: string;
  file_size: number;
  send_date: any;
  file_extension: any;
  file_hash: any;
}

const Documents: React.FC = () => {
  const location = useLocation();
  const [t] = useTranslation();
  const linkRef: any = useRef(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [documentList, setDocumentList] = useState<Document[]>([]);
  const [publicationData, SetPublicationData] = useState<any>({})
  const [statusDoc, setStatusDoc] = useState<boolean>(true)
  const [convertSize, setConvertSize] = useState<any>('')
  const [mailText, setMailText] = useState(t("Senddocument"));
  const [documentType, setDocumentType] = useState<any>("contract")
  const [template, setTemplate] = useState<any>(`<p>${t("DownloadableFileLinkBelow")}</p>`)



  const dispatch = useDispatch<AppDispatch>();
  // const { id } = useParams<{ id?: any }>();
  let id = location.state.contract_id;

  const { status, documents, contractData, contractDatail } = useSelector((state: RootState) => state.contractData)
  const [state, setState] = useState({
    start: 0,
    end: 20,
  });
  const getFileExtension = (filename: string): string => {
    return filename.slice(((filename.lastIndexOf('.') - 1 >>> 0) + 2));
  };

  let ownerEmails = contractDatail[0]?.ownerResult?.map(obj => obj.ownerEmail) || [];
  let tenantEmails = contractDatail[0]?.["datatenants"]?.["tenates"].map(obj => obj.tenantEmail) || [];
  let allEmails = [...ownerEmails, ...tenantEmails];
  const [otherEmails, setOtherEmails] = useState<any>([]);

  const calculateFileHash = async (file) => {
    // Read the contents of the file as an ArrayBuffer
    const fileBuffer = await file.arrayBuffer();
    // Convert the ArrayBuffer to a Uint8Array
    const uint8Array = new Uint8Array(fileBuffer);
    // Use the SubtleCrypto API to calculate the hash (SHA-256) of the Uint8Array
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', uint8Array);
    // Convert the hash result (ArrayBuffer) to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  function formatDateToISOString(date) {
    // Step 1: Adjust the time zone offset to convert to UTC
    const offsetInMinutes = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() + offsetInMinutes * 60 * 1000);
    // Step 2: Format the resulting date as a string in the desired format
    const formattedDate = adjustedDate.toISOString();
    return formattedDate;
  }

  const [openOnce, setOpenOnce] = useState(true);
  const handleUpload = async () => {
    if (openOnce) {
      setOpenOnce(false);
      let AddDoc: any = document.querySelector('.attachedDocument');
      AddDoc.click();
    }
  };

  const [documentData, setDocumentData] = useState([]);
  const documentData1 = async () => {
    let data = await dispatch(getDocuments(location.state.contract_id));
    setDocumentData(data.payload)
  }

  const peopleDocumentData = async (id: any) => {
    let data = await dispatch(getPersonDocuments(id));
    if (location.state && location.state.person_id !== null && location.state.person_id !== "") {
      setDocumentData(data.payload)
    }
  }


  const getContractDetails = async (contractId) => {
    let data = await dispatch(contractDatails(contractId));
  }
  useEffect(() => {
    if (location?.state?.type == "contract_Doc") {
      if (id && id !== "null" && id !== "") {
        documentData1();
        getContractDetails(id);
      }
    } else {
      if (location.state && location.state.person_id !== null && location.state.person_id !== "") {
        if (location.state.person_type == "owner") {
          setDocumentType("owner");
          setOtherEmails([location.state.person_email]);
          peopleDocumentData(location.state.person_id);

        } if (location.state.person_type == "tenant") {
          setDocumentType("tenant");
          setOtherEmails([location.state.person_email]);
          peopleDocumentData(location.state.person_id);
        }

        if (location.state.person_type == "guarantor") {
          setDocumentType("guarantor");
          setOtherEmails([location.state.person_email]);
          peopleDocumentData(location.state.person_id);
        }
        if (location.state.person_type == "broker") {
          setDocumentType("broker");
          setOtherEmails([location.state.person_email]);
          peopleDocumentData(location.state.person_id);
        }
        if (location.state.person_type == "supplier") {
          setDocumentType("supplier");
          setOtherEmails([location.state.person_email]);
          peopleDocumentData(location.state.person_id);
        }
        setStatusDoc(false)
      }
    }

  }, [])

  useEffect(() => {
    if (id && id !== "null" && id !== "") {
      getContractsData(state);
    }
  }, [state])

  const getContractsData = async (contData) => {

    let response = await dispatch(contractsData(contData));
    if (response.payload.resCode === 201) {
      response.payload.data.forEach(element => {
        if (element.id === Number(id)) {
          SetPublicationData(element)
          setStatusDoc(false)
        }
      });
    }
  };



  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const handleDelete = (documentId: number) => {
    if (documentId) {
      Swal.fire({
        title: t("Sweetalert.Areyousuretodeleteitem"),
        text: t("Sweetalert.Areyousuretodeleteitem"),
        // icon: 'success',"question",
        showCancelButton: true,
        confirmButtonText: t("bankTransaction.ok"),
        cancelButtonText: `${t("button.cancel")}`,
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          setStatusDoc(true)
          let res = await customerService.deleteContractDetailDocuments(documentId);
          if (res.data.resCode == 408) {
            setStatusDoc(false)
            // Show a success message using Swal
            Swal.fire({
              // icon: 'success','success',
              title: t("Sweetalert.Success"),
              text: t("Sweetalert.deletesuccess"),
              showConfirmButton: false,
              timer: 2000
            })
            if (location?.state?.type == "contract_Doc") {
              if (id && id !== "null" && id !== "") {
                documentData1();
              }
            } else {
              if (location.state && location.state.person_id !== null && location.state.person_id !== "") {
                peopleDocumentData(location.state.person_id);
              }
            }
          } else {
            Swal.fire({
              // icon: 'success','error',
              title: t("Sweetalert.Error"),
              text: t("Sweetalert.somethingWent"),
              timer: 2000
            })
          }
        }
      })
    }
  };
  function formatFileSize(fileSizeInBytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (fileSizeInBytes < kilobyte) {
      return fileSizeInBytes + ' B';
    } else if (fileSizeInBytes < megabyte) {
      return (fileSizeInBytes / kilobyte).toFixed(2) + ' KB';
    } else {
      return (fileSizeInBytes / megabyte).toFixed(2) + ' MB';
    }
  }

  const handleOptionChange = async (optionId, documentId) => {
    // Do something with the selected option ID

    const response = await customerService.documentStatusUpdate(optionId, documentId);
    if (response.data.resCode === 200 || response.data.resCode === "200") {
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.RecordUpdated"),
        confirmButtonText: "OK",
        timer: 2000
      })

      if (location.state.type == "contract_Doc") {
        setOpenOnce(true);
        if (id && id !== "null" && id !== "") {
          documentData1();
        }
      } else {
        if (location.state && location.state.person_id !== null && location.state.person_id !== "") {
          peopleDocumentData(location.state.person_id);
        }
      }
    }
    else {
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
      setOpenOnce(true);
    }
  };

  const handleFileChange = async (event: any) => {
    const file: any = event.target.files[0];
    setSelectedFile(file);
    handleFormSubmitformm(file)
  };


  const handleFormSubmitformm = async (fileData: any) => {
    if (fileData && fileData !== null) {
      const currentDate = new Date(dateBrazil);
      const formData = new FormData();
      formData.append('file', fileData);
      if (id && id !== "null" && id !== "") {
        formData.append('contract_id', id);
      } else {
        id = null
        formData.append('contract_id', id);
      }
      formData.append('file_name', fileData.name);
      formData.append('file_size', fileData.size.toString());
      formData.append('send_date', formatDateToISOString(currentDate));
      formData.append('file_extension', getFileExtension(fileData.name));
      if (location.state && location.state.person_id !== null && location.state.person_id !== "") {
        formData.append('owner_id', location.state.person_id);
      }
      setStatusDoc(true)
      setOpenOnce(true);
      const response = await customerService.uploadContractDetailDocuments(formData);
      
      if (typeof response !== "undefined" && response.data.resCode === 202) {
        setStatusDoc(false)
        Swal.fire({
          // icon:'success',
          title:t("Sweetalert.Success"),
          text:t("Sweetalert.successget"),
          confirmButtonText:"OK",
          timer:2000});
        if (location.state.type == "contract_Doc") {
          if (location.state && location.state.contract_id !== null && location.state.contract_id !== "") {
            documentData1();
          }
        } else {
          if (location.state && location.state.person_id !== null && location.state.person_id !== "") {
            peopleDocumentData(location.state.person_id);
          }
        }
      } else {
        Swal.fire({
          title:t("Sweetalert.Error"),
          text:t("Sweetalert.somethingWent"),
          // icon:"error",
          confirmButtonText:"OK",
          timer:2000});
        setOpenOnce(false);
        setOpenOnce(true);
      }
    } else {
      setOpenOnce(false);
      setOpenOnce(true);
    }
  };


  let emailCollection = [allEmails, ownerEmails, tenantEmails, otherEmails];
  const handleSendEmail = async (e, file_hash, file_type) => {
      const newTemplate = `
    <p>${t("DownloadableFileLinkBelow")}</p>

    <p><a href="${file_hash}" style="color: green;">${t("DownloadFile")}</a></p>
    
    `
        setTemplate(newTemplate)
        await dispatch(openEmailPop(true))
  }
  // console.log(publicationData.id , "11111", publicationData.propertyId)
  return (
    <section className="content-wrapper" id="documentstsxtemplate">
      {/*  <!-- Breadcrumb section start from here --> */}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
        <div className="w-100">
        <Row className="gx-xl-5 justify-content-between align-items-center">
          <Col xs={{ order: 12 }} xl={{ order: 0 }} className="mb-xl-0 mb-3 col-xl">
          <div className="d-flex align-items-center justify-content-start">
              {(id && id !== "null" && id !== "") ?
                <Link to={"/contract_details/" + publicationData?.id}>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                </Link>
                :
                <Link to={"/customer_and_employees"}>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                </Link>
              }
              <h1 className="mb-0 h4 lh-base ms-2 ms-lg-3 ms-xl-4 text-nowrap fw-medium">{t("contractlistother.Publicationofdocuments")}
              {(id && id !== "null" && id !== "" && publicationData?.id !== undefined && publicationData?.propertyId !== undefined ) ?
                      <Badge bg="light" className="text-dark ms-1 fw-medium"> {publicationData?.id !== null ? publicationData?.type + " " + publicationData?.id + "" + "/" + publicationData?.propertyId : ""}</Badge>

                      :
                      ""}

              </h1>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} xl={{ order: 3, span: "auto" }} className="text-xl-end mb-xl-0 mb-2">
              {(id && id !== "null" && id !== "" && publicationData?.id !== undefined && publicationData?.propertyId !== undefined ) ?
                  <Link className="btn btn-link p-0 fw-bold" to={"/contract_details/" + publicationData?.id}>{publicationData?.id !== null ? publicationData?.type + " " + publicationData?.id + "" + "/" + publicationData?.propertyId : ""} </Link>
                  :
                  ""
                }
            </Col>
          </Row>
        </div>
      </div>
      {/* <!-- /Breadcrumb section end --> */}
      {/* <!-- Main content start from here --> */}

      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between">
            {t("documents")}
            {/* <div className="position-relative" >
           <input type="file" onChange={handleFileChange} className="position-absolute left-0 end-0 attachedDocument" hidden />
             <Button variant="link" size="sm" className="fw-bold position-relative z-index-1" onClick={handleUpload}>{t("contractlistother.Attachdocuments")}</Button>
           </div> */}

            <form>
              <input type="file" onChange={handleFileChange} className="position-absolute left-0 end-0 attachedDocument" hidden />
              {/* <Button variant="link" type="submit" size="sm" className="fw-bold position-relative z-index-1 DocumentSubmitfilxx d-none">hidden</Button> */}
            </form>
            <Button variant="link" size="sm" className="fw-bold position-relative p-0 z-index-1" onClick={handleUpload}>{t("contractlistother.Attachdocuments")}</Button>


          </Card.Header>
          {statusDoc ?
            <Loader />
            :
            <Card.Body>
              <Table responsive="lg" className="table table-custom table-custom-bordered mb-0">
                <thead>
                  <tr>
                    <th className="pb-0">{t("contractlistother.DocumentNo")}</th>
                    <th className="pb-0">{t("contractlistother.documentsName")} </th>
                    <th className="pb-0">{t("contractlistother.SizeUploaded")}</th>
                    <th className="pb-0">{t("contractlistother.SendDate")}</th>
                    <th className="pb-0">{t("contractlistother.Share")}</th>
                    <th className="pb-0 text-end">{t("button.action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {documentData ? [...documentData].reverse().map((document: any, index) => (
                    <tr key={index}>
                      <td>{document.file_id}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {(typeof document.file_hash == "string" && document.file_hash !== "") ?

                            (<>
                              <a className='text-dark' href={document.file_hash}>
                                <img className="me-1 flex-shrink-0 " style={{ width: '40px' }} src={`assets/images/icons/${document.file_extension}.png`} alt="send" /> {document.file_name}
                              </a>
                            </>) : (
                              <Link className='text-dark'
                                to={`${config.CUSTOMER___API}/checklist/${document.file_name}`}
                                ref={linkRef}
                              >
                                <img className="me-1 flex-shrink-0 " style={{ width: '40px' }} src={`assets/images/icons/${document.file_extension}.png`} alt="send" /> {document.file_name} </Link>
                            )}

                        </div>
                      </td>
                      <td>{formatFileSize(document.file_size)}</td>
                      <td>{formatDate(document.send_date)}</td>
                      <td>

                        <Dropdown>
                          <Dropdown.Toggle size="sm" variant="link" className="border-0 bg-primary-20 text-dark rounded-3 fs-12 px-2" id="dropdown-basic">
                            {document.file_type === 1 ? t("customerAndEmployees.tenants") + " " + t("customerAndEmployees.and") + " " + t("customerAndEmployees.owners") :
                              document.file_type === 2 ? t("customerAndEmployees.owners") :
                                document.file_type === 3 ? t("customerAndEmployees.tenants") :
                                  document.file_type === 5 ? t("customerAndEmployees.guarantors") :
                                    document.file_type === 6 ? t("customerAndEmployees.brokers") :
                                      document.file_type === 7 ? t("customerAndEmployees.suppliers")
                                        :
                                        document.file_type === 4 ? t("contractlistother.Not") + " " + t("contractlistother.Share") :
                                          t("contractlistother.Toshare")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="mh-456 overflow-auto">
                            {documentType === "contract" && (
                              <>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(1, document.file_id)}
                                  className={document.file_type === 1 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.tenants") + " " + t("customerAndEmployees.and") + " " + t("customerAndEmployees.owners")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(2, document.file_id)}
                                  className={document.file_type === 2 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.owners")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(3, document.file_id)}
                                  className={document.file_type === 3 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.tenants")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(4, document.file_id)}
                                  className={document.file_type === 4 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("contractlistother.Not")} {t("contractlistother.Share")}
                                </Dropdown.Item>
                              </>
                            )}

                            {documentType === "owner" && (
                              <>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(2, document.file_id)}
                                  className={document.file_type === 2 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.owners")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(4, document.file_id)}
                                  className={document.file_type === 4 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("contractlistother.Not")} {t("contractlistother.Share")}
                                </Dropdown.Item>
                              </>
                            )}

                            {documentType === "tenant" && (
                              <>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(3, document.file_id)}
                                  className={document.file_type === 3 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.tenants")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(4, document.file_id)}
                                  className={document.file_type === 4 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("contractlistother.Not")} {t("contractlistother.Share")}
                                </Dropdown.Item>
                              </>
                            )}

                            {documentType === "guarantor" && (
                              <>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(5, document.file_id)}
                                  className={document.file_type === 5 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.guarantors")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(4, document.file_id)}
                                  className={document.file_type === 4 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("contractlistother.Not")} {t("contractlistother.Share")}
                                </Dropdown.Item>
                              </>
                            )}

                            {documentType === "broker" && (
                              <>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(6, document.file_id)}
                                  className={document.file_type === 6 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.brokers")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(4, document.file_id)}
                                  className={document.file_type === 4 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("contractlistother.Not")} {t("contractlistother.Share")}
                                </Dropdown.Item>
                              </>
                            )}

                            {documentType === "supplier" && (
                              <>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(6, document.file_id)}
                                  className={document.file_type === 7 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("customerAndEmployees.suppliers")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleOptionChange(4, document.file_id)}
                                  className={document.file_type === 4 ? 'active bg-primary text-white' : ''}
                                >
                                  {t("contractlistother.Not")} {t("contractlistother.Share")}
                                </Dropdown.Item>
                              </>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>

                      </td>
                      <td className="text-end">
                        <Button variant="link" className="p-0 me-2 me-xxl-4 me-lg-3" onClick={(e) => handleSendEmail(e, document.file_hash, document.file_type)}>
                          <img className="h-20" src="assets/images/icons/send-dark.svg" alt="send" />
                        </Button>
                        <Button variant="link" className="p-0" onClick={() => handleDelete(document.file_id)}>
                          <img className="h-20" src="assets/images/icons/delete-danger.svg" alt="Delete" />
                        </Button>
                      </td>
                    </tr>
                  )) : <p>{t("datanotfound")}</p>}
                </tbody>
              </Table>
              {
                documentData ? <p className='mb-0'>{t("formLabel.listing")} {documentData.length} {t("documents")}</p> : ""
              }
              {/* <p className="mb-0">Listing 3 documents.</p> */}
            </Card.Body>
          }
        </Card>

      </main>

      <SendDocumentEmail mailText={mailText} mailData={emailCollection} type={documentType} template={template} />
      {/* <SendDocumentEmail mailText={mailText} mailData={allEmails} contract_id = {id} /> */}

    </section>

  );
};

export default Documents;

