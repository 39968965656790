import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { contractSearchForm } from '../../../Interface';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { getBraches } from '../../../../redux/slice/branchSlice';
import { getManager } from "../../../../redux/slice/propertyDataSlice";
import { MultiSelect } from 'react-multi-select-component';

const TransfersMoreFilters = (props) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm<contractSearchForm>();
    const dispatch = useDispatch<AppDispatch>()
    const { billingData } = useSelector((state: RootState) => state.expenditureData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    const { managerData } = useSelector((state: RootState) => state.propertyData)
    const { proprtyData, Property_Type } = useSelector((state: RootState) => state.contractData)

    const [listProperty, setListProperty] = useState<any>([]);
    const [listPropertydata, setListPropertydata] = useState<any>([]);
    const [ListPropertyType, setListPropertyType] = useState<any>([]);
    const [listPropertytypeData, setListPropertyTypeData] = useState<any>([]);
    const [listBranchlist, setListBranchList] = useState<any>([]);
    const [listBranchlistData, setListBranchListData] = useState<any>([]);
    const [listManagerlist, setListManagerList] = useState<any>([]);
    const [listManagerData, setListManagerData] = useState<any>([]);
    const [double, setDouble] = useState(false);
    const [t] = useTranslation();
    //More filters modal close open function
    const { showMoreFilters, moreFiltersClose, moreFiltersShow, filtersAre } = props;
    useEffect(() => {
        if (showMoreFilters) {
            dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        }
    }, [dispatch]);
    useEffect(() => {
        if (proprtyData && proprtyData.data && proprtyData.data.length > 0) {
            let allPropertyOption: any = [];
            proprtyData.data.filter(item => item.property_identifier !== '').forEach((item, index) => {
                allPropertyOption.push({
                    value: item.id, label: `${item.property_identifier ? item.property_identifier : ''}`,
                });
            });
            setListProperty(allPropertyOption);
        }
        if (Property_Type && Property_Type && Property_Type.length > 0) {
            let allProperty_Type: any = [];
            Property_Type.forEach((item, index) => {
                allProperty_Type.push({
                    value: item.value, label: `${item.label ? item.label : ''}`,
                });
            });
            setListPropertyType(allProperty_Type);
        }
        if (branchList && branchList && branchList.length > 0) {
            let allBranchlistOption: any = [];
            branchList.forEach((item, index) => {
                allBranchlistOption.push({
                    value: item.id, label: `${item.name ? item.name : ''}`,
                });
            });
            setListBranchList(allBranchlistOption);
        }
        if (managerData && managerData && managerData.length > 0) {
            let allManagerOption: any = [];
            managerData.forEach((item, index) => {
                allManagerOption.push({
                    value: item.id, label: `${item.name ? item.name : ''}`,
                });
            });
            setListManagerList(allManagerOption);
        }
    }, [proprtyData, branchList, managerData]);

    const propertyOwner = (evt, cmd) => {
        if (cmd === "contractType") {
            setListPropertydata(evt);
        } else if (cmd === "propertyType") {
            setListPropertyTypeData(evt);
        } else if (cmd === "branchType") {
            setListBranchListData(evt)
        } else if (cmd === "manager") {
            setListManagerData(evt)
        }
        let Close: any = document.querySelector('.dropdown-container[aria-labelledby="Immobile"] .dropdown-content');

        if (Close) {
            Close.setAttribute("hidden", "");
            Close.click();
        }
    }

    const onSubmit = async (data: contractSearchForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        data["contractType"] = listPropertydata.length ? listPropertydata[0].value : '';
        data["propertyType"] = listPropertytypeData.length ? listPropertytypeData[0].value : '';
        data["branch"] = listBranchlistData.length ? listBranchlistData[0].value : '';
        data["manager"] = listManagerData.length ? listManagerData[0].value : '';
        filtersAre(data);
    }

    const moreFilters_Close = () => {
        setDouble(false);
        reset();
        setListPropertydata([])
        setListPropertyTypeData([])
        setListBranchListData([])
        setListManagerData([])

        let data: any = {};
        data["contractType"] = '';
        data["branch"] = '';
        data["dueDate"] = '';
        data["expireDate"] = '';
        data["manager"] = '';
        data["number"] = '';
        data["propertyType"] = '';
        data["formOfPayment"] = '';
        data["status"] = [];
        filtersAre(data);
        moreFiltersClose()
    }

    return (
        <Modal show={showMoreFilters} onHide={moreFiltersClose} id="transfersMoreFiltersts">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("moreFilters")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-lg-4 gy-3">
                        <Col md={6}>
                            <FloatingLabel controlId="charge_settlement" label={t("TransferPage.startDate")}>
                                <Form.Control type="date" placeholder="Charge settlement" {...register('expireDate')}/>
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel controlId="charge_due_date" label={t("TransferPage.endDate")}>
                                <Form.Control type="date" placeholder="Charge due date" {...register('dueDate')}/>
                            </FloatingLabel>
                        </Col>
                        {/* <Col md={12}>
                            <FloatingLabel label="Number of properties per owner">
                                <Form.Select aria-label="Number of properties per owner" {...register('number')}>
                                    <option value="1">All</option>
                                    <option value="2">Only 1 property</option>
                                    <option value="3">With 2 properties</option>
                                    <option value="4">With 3 properties</option>
                                    <option value="5">With 4 properties</option>
                                    <option value="6">With 5 properties</option>
                                    <option value="7">With 6 or more properties</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col> */}
                        {/* <Col md={6}> */}
                            {/* <FloatingLabel controlId="floatingInput" label={t('ofthecontract')} hidden>
                                <Form.Select {...register('contractType')} onChange={(e) => propertyOwner(e, "contractType")} >
                                    <option value="">{t('dropdownItem.select')}..</option>
                                    <option value="">{t('selectAll')}</option>
                                    {proprtyData && proprtyData.data && proprtyData.data.length > 0
                                        ? proprtyData.data.filter(item => item.property_identifier !== '').map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.property_identifier ? item.property_identifier : ''}
                                            </option>
                                        ))
                                        : ""}
                                </Form.Select>
                            </FloatingLabel> */}
                            {/* <FloatingLabel controlId="floatingInput" label={''}>
                                <MultiSelect
                                    options={listProperty}
                                    value={listPropertydata}
                                    onChange={(e) => propertyOwner(e, "contractType")}
                                    labelledBy={"ofthecontract"}
                                    overrideStrings={{
                                        selectSomeItems: t('ofthecontract'), // Set the placeholder text here
                                    }}
                                />
                            </FloatingLabel> */}
                        {/* </Col> */}
                        <Col md={6}>
                            <InputGroup>
                                <FloatingLabel label={t("formLabel.propertyType")} hidden>
                                    <Form.Select className={`form-control`} {...register('propertyType')} onChange={(e) => propertyOwner(e, "propertyType")}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="">{t('selectAll')}</option>
                                        {Property_Type && Property_Type && Property_Type.length > 0
                                            ? Property_Type.map((item, index) => (
                                                <option key={index} value={item.value}>
                                                    {item.label ? item.label : ''}
                                                </option>
                                            ))
                                            : ""}
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingInput" label={''}>
                                    <MultiSelect
                                        options={ListPropertyType}
                                        value={listPropertytypeData}
                                        onChange={(e) => propertyOwner(e, "propertyType")}
                                        labelledBy={t('immobile')}
                                        overrideStrings={{
                                            selectSomeItems: t('formLabel.propertyType'), // Set the placeholder text here
                                        }}
                                    />
                                </FloatingLabel>
                            </InputGroup>
                        </Col>
                        {/* <Col md={6}>
                            <InputGroup>
                                <FloatingLabel label={t("TransferPage.Ofthebranches")} hidden>
                                    <Form.Select {...register('branch')} onChange={(e) => propertyOwner(e, "branchType")}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="">{t('selectAll')}</option>
                                        {branchList && branchList && branchList.length > 0
                                            ? branchList.map((item, index) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                            : ""}
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingInput" label={''}>
                                    <MultiSelect
                                        options={listBranchlist}
                                        value={listBranchlistData}
                                        onChange={(e) => propertyOwner(e, "branchType")}
                                        labelledBy={t('immobile')}
                                        overrideStrings={{
                                            selectSomeItems: t('TransferPage.Ofthebranches'), // Set the placeholder text here
                                        }}
                                    />
                                </FloatingLabel>
                            </InputGroup>
                        </Col> 
                        <Col md={6}>
                            <InputGroup>
                                <FloatingLabel label={t("TransferPage.OftheManagers")} hidden>
                                    <Form.Select className={`form-contro`} {...register('manager')} onChange={(e) => propertyOwner(e, "manager")}>
                                        <option value="">{t('formLabel.propertyType')}..</option>
                                        <option value="">{t('selectAll')}</option>
                                        {managerData && managerData && managerData.length > 0
                                            ? managerData.map((item, index) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                            : ""}
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingInput" label={''}>
                                    <MultiSelect
                                        options={listManagerlist}
                                        value={listManagerData}
                                        onChange={(e) => propertyOwner(e, "manager")}
                                        labelledBy={t('TransferPage.OftheManagers')}
                                        overrideStrings={{
                                            selectSomeItems: t('TransferPage.OftheManagers'), // Set the placeholder text here
                                        }}
                                    />
                                </FloatingLabel>
                            </InputGroup>
                        </Col> */}
                        <Col md={6}>
                        <FloatingLabel label={t("Dashboard.Formofpayment")}>
                                <Form.Select aria-label="Form of payment" {...register('formOfPayment')}>
                                    <option value="">{t('dropdownItem.select')}</option>
                                    {/* {
                                    billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                        <option key={index} value={Number(item.id)}>{item.name}</option>
                                    ))
                                    :''
                                } */}
                                <option value="1">{t("customerAndEmployees.chequeDeposit")}</option>
                                <option value="2">{t("customerAndEmployees.cashDeposit")}</option>
                                <option value="3">{t("customerAndEmployees.pickUpCheque")}</option>
                                <option value="4">{t("customerAndEmployees.withdrawMoney")}</option>
                                <option value="5">{t("customerAndEmployees.bankTransfer")}</option>
                                <option value="6">{t("customerAndEmployees.docTed")}</option>
                                <option value="12">{t("customerAndEmployees.pix")}/{t("customerAndEmployees.boleto")}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        {/* <Col md={6}>
                        <FloatingLabel label={t("newTransfer.cutOffDate")}>
                                <Form.Select aria-label="Cut-off date">
                                    <option value="1">{t("newTransfer.Lastdayofthemonth")}</option>
                                    <option value="2">{t("newTransfer.Selectdate")}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col> */}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={moreFilters_Close}>
                        {t("button.resetfilter")}
                    </Button>
                    <Button variant="primary" type="submit" onClick={moreFiltersClose}>
                        {t("button.done")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default TransfersMoreFilters