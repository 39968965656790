import React, { useEffect, useState } from 'react';
import { ComProps } from '../../Interface';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';

import Welcome from './Notification/welcome';
import Birthday from './Notification/birthday';
import Contract from './Notification/contract';
import Insurance from './Notification/Insurance';
import Gurantees from './Notification/gurantees';
import Guaranto from './Notification/guaranto';
import ModelNotify from './Notification/model';
import { fetchNotification, fetchNotifications } from '../../../redux/slice/notificationSlice';
import { notificationService } from "../../../services/notificationService";

function Notifications({ submenu }: ComProps) {
    const dispatch = useDispatch<AppDispatch>();
    let stateData:any = useSelector((state: RootState) => state.notification);
    let notificationData:any = useSelector((state: RootState) => state.notification["all_notification"]);

    useEffect(() => {
        (async () => {
            let datalist = await dispatch(fetchNotification());
            let notificationsData = await dispatch(fetchNotifications());
            stateData = datalist.payload;
            notificationData = notificationsData.payload;
        })()
    }, [])
    
    return ( 
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            {submenu}
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3 templateNotifuvt" id="CurrentPage" data-page="">
                <div>
                    <ModelNotify dispatch={dispatch} stateData={stateData} />
                    <Row className="g-md-4 g-3 h-100">
                        {/* <!-- Welcome card start from here --> */}
                        <Welcome dispatch={dispatch} stateData={stateData} notificationData = {notificationData} />
                        {/* <!-- Welcome card end --> */}
                        {/* <!-- Birthday card start from here --> */}
                        <Birthday dispatch={dispatch} stateData={stateData} notificationData = {notificationData} />
                        {/* <!-- Birthday card end --> */}
                        {/* <!-- Contact card start from here --> */}
                        <Contract dispatch={dispatch} stateData={stateData}  notificationData = {notificationData}/>
                        {/* <!-- Contact card end --> */}
                        {/* <!-- Insurance card start from here --> */}
                        <Insurance dispatch={dispatch} stateData={stateData} notificationData = {notificationData}/>
                        {/* <!-- Insurance card end --> */}
                        {/* <!-- Expiration Gurantees card start from here --> */}
                        <Gurantees dispatch={dispatch} stateData={stateData} notificationData = {notificationData}/>
                        {/* <!-- Expiration Gurantees card end --> */}
                        {/* <!-- Guarantor card start from here --> */}
                        <Guaranto dispatch={dispatch} stateData={stateData} notificationData = {notificationData} />
                        {/* <!-- Guarantor card end --> */}
                    </Row>
                </div>
            </main>
            {/* <!-- /Main content start end --> */}
        </section>
    )
}

export default Notifications