import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { contractDatails, insertQuote, quoteClose } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { reversedForm } from '../../../Interface';
import transferService from '../../../../services/transferService';

import Swal from 'sweetalert2';
import '../../../../App.css';
import i18n from '../../../../i18n';
import { getAllAccounts } from "../../../../redux/slice/expenditureDataSlice";
import customerService from '../../../../services/customerService';

const RevereseService = (props,reversedForm) => {
    const [double, setDouble] = useState(false);
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { showReverseModal,closeRevereseModal,id,type } = props;    
    const [ loader_on,setLoader_on ] = useState(false);
    const [ leaseError,setLeaseError ] = useState("");
    
    const { accountData } = useSelector((state: RootState) => state.expenditureData);
    
    useEffect(() => {
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
    }, [dispatch]);

    const cancelRevereseClosed = () =>{
        closeRevereseModal();
        setDouble(false);
        setLoader_on(false);
        reset();
    }   
    const formValidation = Yup.object().shape({
        bank_account: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
        maturityDate: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(formValidation)
    }
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<reversedForm>(frm);
    const onSubmit = async (data: reversedForm, e: any) => {
        e.preventDefault();
        data.type = type;
        data.id   = id;
        if(type==="recieved" && id){
            let response = await transferService.insertReverseCharge(data)  
            if(response.data && typeof response.data !=="undefined" && (response.data.resCode===412 || response.data.resCode===414)){
                cancelRevereseClosed();
                Swal.fire({
                    title : t("Sweetalert.Success"),
                    text  : t("Sweetalert.chargereverse"),
                    confirmButtonText: "OK",
                })
            }
            else {
                cancelRevereseClosed();
                Swal.fire({
                    title : t("Sweetalert.Error"),
                    text  : `${t("Sweetalert.somethingWent")}`,
                    confirmButtonText: "OK",
                })
            }  
        }
        else if(type==="paid" && id){
            let response = await customerService.reverseExpense(data)
            if (response.data && response.data.resCode == 200) {
                cancelRevereseClosed();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.updatesuccess"),
                    confirmButtonText: "OK",
                });
            }
            else {
                cancelRevereseClosed();
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: response.data.message,
                    confirmButtonText: "OK",
                });
            }
        }
        else {
            cancelRevereseClosed();
            Swal.fire({
                title : t("Sweetalert.Error"),
                text  : `${t("Sweetalert.somethingWent")}`,
                confirmButtonText: "OK",
            })
        } 
    }
    return (
        <>
            {/* <!-- New quote modal start from here --> */}
            <Modal show={showReverseModal} onHide={cancelRevereseClosed}>
                <Form onSubmit={handleSubmit(onSubmit)} id="reverseForm">
                    <Modal.Header closeButton>
                        <Modal.Title>{ type==="paid" ? t("reverseTransfer") : t("reverseRecieved") }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="reason_invalidation" label={t("bankAccount") + "*"}>
                            <Form.Select aria-label="Reason for invalidation" className={`form-control ${errors.bank_account ? 'is-invalid' : ''}`}  {...register('bank_account')} >
                                <option value=""> {t("dropdownItem.select")}..</option>
                                { 
                                    accountData.data && accountData.data.length > 0 ? accountData.data.map((item, index) => (
                                      <option key={index} value={Number(item.id)}>
                                        {item.account} -- {item.accountRef}
                                      </option>
                                    ))
                                    : ""
                                }
                            </Form.Select>
                            {/*<Form.Control.Feedback type="invalid">{errors.bank_account?.message}</Form.Control.Feedback>*/}
                        </FloatingLabel>
                        
                        <FloatingLabel label={t("maturity")+ "*"}>
                            <Form.Control type="date" aria-label="From" className={`form-control ${errors.maturityDate ? 'is-invalid' : ''}`}  {...register("maturityDate")}></Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.maturityDate?.message}</Form.Control.Feedback>
                        </FloatingLabel>               
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={cancelRevereseClosed}>
                            { t("button.close") }
                        </Button>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{ t("save") }</span>
                                </Button>
                            :
                                <>
                                    <Button disabled={double} type="submit" variant="primary">
                                        { t("reverse") }
                                    </Button>

                                    <Button disabled={double} type="submit" variant="primary">
                                        { t("undo") }
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /New quote modal end --> */}
        </>
    )
}
export default RevereseService;