import React from 'react';
import { Badge, OverlayTrigger, Table, Tooltip, Card } from 'react-bootstrap';

const SentMessageListing = () => {
    return (
        <Card>
            <Card.Body className="pb-1">
           <Table responsive className="table-custom table-custom-bordered mb-0">
        <thead>
            <tr>
                <th className="w-24"><input type="checkbox" className="form-check-input" /></th>
                <th>Recipient</th>
                <th>Message</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><input type="checkbox" className="form-check-input" /></td>
                <td>
                    <span className="d-block">(19) 75366-9364</span>
                    <Badge bg="danger">Erro</Badge>
                </td>
                <td>
                <span className="d-block text-dark">Training: Your ticket expires today. Go to http://superlogi.ca/?c=kq6qG to view</span>
                <span className="d-block">Recipient mobile is incomplete or invalid - 05/10/2023 00:30:10</span>
                </td>
                <td className="text-nowrap">
                   <div className="d-flex justify-content-end">
                   <OverlayTrigger placement="bottom" overlay={<Tooltip>Delete</Tooltip>}>
                    <span className="icon img-primary cursor-pe"><img src="assets/images/icons/delete-light.svg" alt="Delete" className="h-20" /></span>
                    </OverlayTrigger>
                   </div>
                </td>
            </tr>
        </tbody>
    </Table>
    </Card.Body>
        </Card>
    )
}

export default SentMessageListing
