import React from "react";
import { Button } from "react-bootstrap";
import { useBankAccountDrawer } from "../../../../../../newComponents/components/BankAccountDrawer";

export function BankAccountsNotFound() {
  const { setShowBankAccountDrawer } = useBankAccountDrawer();

  return (
    <div className="d-flex justify-content-center align-items-center gap-1">
      Nenhuma conta adicionada.
      <Button
        variant="link"
        size="sm"
        className="fw-bold p-0"
        onClick={() => setShowBankAccountDrawer({ action: "new" })}
      >
        Nova conta
      </Button>
    </div>
  );
}
