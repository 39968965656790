import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ContinueWithoutTemplate = (props) => {
    const [t] = useTranslation();
    //Continue without template detail modal close open function
    const { showContinueWithoutTemplate, continueWithoutTemplatelClose } = props;
    return (
        <>
            <Modal show={showContinueWithoutTemplate} onHide={continueWithoutTemplatelClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.whatDoYouWant")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {t("DocumentTemplate.whatDoYouWantContent")}
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Link to={"/folder_detail"} className="btn btn-outline-primary" onClick={continueWithoutTemplatelClose}>
                        {t("DocumentTemplate.draftStandaloneDocument")}
                        </Link>
                        <Button variant="primary" onClick={continueWithoutTemplatelClose}>
                        {t("DocumentTemplate.createTemplate")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default ContinueWithoutTemplate
