import { config } from "../../../../services/apiConfig";

export async function getBanksList(): Promise<
  Array<{
    bank_code: string;
    bank_name: string;
  }>
> {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(`${config.companyV2_URL}/bank/bankList`, {
    method: "POST",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      "connection-name": connectionName,
    },
  });

  const data = await response.json();

  return data.data;
}
