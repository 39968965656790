import { config } from "../../../../services/apiConfig";

type CreateHomologateBankSlipPayload  ={
  CedenteContaNumero: string;
  CedenteContaNumeroDV: string;
  CedenteConvenioNumero: string;
  CedenteContaCodigoBanco: string;
  SacadoCPFCNPJ: string;
  SacadoEmail: string;
  SacadoEnderecoNumero: string;
  SacadoEnderecoBairro: string;
  SacadoEnderecoCEP: string;
  SacadoEnderecoCidade: string;
  SacadoEnderecoComplemento: string;
  SacadoEnderecoLogradouro: string;
  SacadoEnderecoPais: string;
  SacadoEnderecoUF: string;
  SacadoNome: string;
  SacadoTelefone: string;
  SacadoCelular: string;
  TituloDataEmissao: string;
  TituloDataVencimento: string;
  TituloMensagem01: string;
  TituloMensagem02?: string;
  TituloMensagem03?: string;
  TituloNossoNumero: string;
  TituloNumeroDocumento: string;
  TituloValor: string;
  TituloLocalPagamento: string;
  IdRecebimento: number;
  Contrato?: string;
  TituloCodDesconto?: number;
  TituloDataDesconto?: string;
  TituloValorDescontoTaxa?: string;
  TituloValorDesconto?: string;
  TituloCodigoMulta?: number;
  TituloDataMulta?: string;
  TituloValorMultaTaxa?: string;
  TituloCodigoJuros?: number;
  TituloDataJuros?: string;
  TituloValorJuros?: string;
  TituloVariacaoCarteira?: string;
  TituloDocEspecie?: string;
  TituloAceite?: string;
  TituloCodBaixaDevolucao?: string;
  TituloPrazoBaixa?: string;
}

function nossoNumeroGenerator(
  createHomologateBankSlipPayload: CreateHomologateBankSlipPayload
) {
  const { CedenteContaCodigoBanco, TituloNossoNumero, CedenteConvenioNumero } =
    createHomologateBankSlipPayload;

  // Banco do Brasil
  if (CedenteContaCodigoBanco === "001") {
    if (CedenteConvenioNumero.length === 4) return TituloNossoNumero.slice(-10);

    if (CedenteConvenioNumero.length === 6) return TituloNossoNumero.slice(-5);

    if (CedenteConvenioNumero.length === 7) return TituloNossoNumero.slice(-2);
  }

  return TituloNossoNumero;
}

export async function createHomologateBankSlip({
  ...createHomologateBankSlipPayload
}: CreateHomologateBankSlipPayload) {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(
    `${config.TRANSFER_API}/homologate/bankslip/bankslip`,
    {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        "connection-name": connectionName,
      },
      body: JSON.stringify({
        ...createHomologateBankSlipPayload,
        TituloNossoNumero: nossoNumeroGenerator(
          createHomologateBankSlipPayload
        ),
      }),
    }
  );

  const data = await response.json();

  if (data.resCode === 201 || data.resCode === 200) return data.data;

  const invalidPayloadError = data.data?.errorData?.length > 0 ? `${data.data.errorData[0]._campo || ''} - ${data.data.errorData[0]._erro || ''}` : '';
  const homologateError = data.data?.message;
  const genericError = data.data;

  throw new Error(invalidPayloadError || homologateError || genericError);
}
