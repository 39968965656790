import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Icon } from "../../../components/Icon";
import { useDimob } from "../DimobProvider";
import { getDimobDashboard } from "../services/getDimobDashboard";
import BaseYearModal from "./BaseYearModal";
import { handleExport } from "../helpers/exportHelper";

type Dashboard = {
  alertsAmount: number
  contractsAmount: number
  errorsAmount: number
  ownersAmount: number
  readyAmount: number
}

export function Summary() {

  const { dimobContracts } = useDimob();
  const [showModal, setShowModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");

  const handleOpenModal = () => setShowModal(true);

  const handleConfirmYear = (year: string) => {
    setSelectedYear(year);
    handleExport(dimobContracts, year);
    setShowModal(false);
  };


  const { filters } = useDimob()
  const [dashboard, setDashboard] = useState<Dashboard>({
    alertsAmount: 0,
    contractsAmount: 0,
    errorsAmount: 0,
    ownersAmount: 0,
    readyAmount: 0,
  })
  useEffect(() => {
    async function fetchDashboard() {
      try {
        const response = await getDimobDashboard(filters.dimobYear)
        const {
          alertsAmount,
          contractsAmount,
          errorsAmount,
          ownersAmount,
          readyAmount,
        } = response.data

        setDashboard({
          alertsAmount: alertsAmount ?? 0,
          contractsAmount: contractsAmount ?? 0,
          errorsAmount: errorsAmount ?? 0,
          ownersAmount: ownersAmount ?? 0,
          readyAmount: readyAmount ?? 0,
        })
      } catch (err) {
        console.error("Failed fetching dashboard:", err)
      }
    }
    fetchDashboard()
  }, [filters.dimobYear])

  return (
    <><Card className="mb-lg-4 mb-3">
      <Card.Header className="text-dark-80">Resumo</Card.Header>
      <Card.Body className="text-dark-80">
        <Row className="gy-3 gx-xxl-5 gx-md-4 ">
          <Col className="border-end border-light border-xs-none">
            <div className="d-flex align-items-center gap-1">
              <Icon name="documentText" />
              <strong className="fs-3">{dashboard.contractsAmount}</strong>
            </div>
            <p className="mt-1 mb-0">Contratos</p>
          </Col>
          <Col className="border-end border-light border-xs-none">
            <div className="d-flex align-items-center gap-1">
              <Icon name="person" />
              <strong className="fs-3">{dashboard.ownersAmount}</strong>
            </div>
            <p className="mt-1 mb-0">Proprietários</p>
          </Col>

          <Col className="border-end border-light border-xs-none">
            <div className="d-flex align-items-center gap-1">
              <Icon name="errorCircle" />
              <strong className="fs-3">{dashboard.alertsAmount}</strong>
            </div>
            <p className="mt-1 mb-0">Alertas</p>
          </Col>
          <Col className="border-end border-light border-xs-none">
            <div className="d-flex align-items-center gap-1">
              <Icon name="dismissSquare" />
              <strong className="fs-3">{dashboard.errorsAmount}</strong>
            </div>
            <p className="mt-1 mb-0">Erros</p>
          </Col>
          <Col>
            <div className="d-flex align-items-center gap-1">
              <Icon name="checkmarkCircleOutline" />
              <strong className="fs-3">{dashboard.readyAmount}</strong>
            </div>
            <p className="mt-1 mb-0">Prontos para envio</p>
          </Col>
          <Col>
            <Button
              className="w-100 d-flex align-items-center justify-content-center"
              variant="outline-primary"
              onClick={handleOpenModal}
            >
              <Icon name="documentArrowDown" />
              <span className="ms-1">Exportar</span>
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card><BaseYearModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSelectYear={handleConfirmYear} />
    </>
  );
}
