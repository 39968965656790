import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { PropertyContract } from "./interfaces/contracts";
import { getContracts } from "./services";
import { FilterParams } from "./types";
import { CURRENT_DIMOB_YEAR } from "./utils";

type DimobContextState = {
  dimobContracts: PropertyContract[]
  isFetchingDimobContracts: boolean
  updateFilters: (filters: FilterParams) => void
  handleChangePagination: ({ start, end }: { start: number, end: number }) => void
  countDimobContracts: number
  filters: FilterParams
}

const DimobContext = createContext<DimobContextState>({} as DimobContextState)

export function DimobProvider({ children }: { children: ReactNode }) {
  const [isFetchingDimobContracts, setIsFetchingDimobContracts] = useState(false)
  const [dimobContracts, setDimobContracts] = useState<PropertyContract[]>([])
  const [countDimobContracts, setCountDimobContracts] = useState(0)
  const [filters, setFilters] = useState<FilterParams>({
    dimobYear: CURRENT_DIMOB_YEAR
  })
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(20)

  const updateFilters = useCallback((filters: FilterParams) => {
    setFilters(currentFilters => ({ ...currentFilters, ...filters }))
  }, [])

  const handleChangePagination = useCallback(({ start, end }: { start: number, end: number }) => {
    setStart(start)
    setEnd(end)
  }, [])


  useEffect(() => {
    async function fetchContractsForDimob() {
      setIsFetchingDimobContracts(true)
      try {
        const response = await getContracts({ ...filters, start, end })
        setDimobContracts(response.data.contracts ?? [])
        setCountDimobContracts(response.totalRow)
      } catch (err) {
        console.error("Failed fetching dimob contracts:", err)
      } finally {
        setIsFetchingDimobContracts(false)
      }
    }

    fetchContractsForDimob()
  }, [start, end, filters])

  return (
    <DimobContext.Provider value={
      {
        dimobContracts,
        isFetchingDimobContracts,
        updateFilters,
        handleChangePagination,
        countDimobContracts,
        filters
      }
    }>
      {children}
    </DimobContext.Provider>
  )
}

export const useDimob = () => {
  const context = useContext(DimobContext);

  if (context === undefined) {
    throw new Error(
      "useDimob must be used within a DimobProvider"
    );
  }

  return context;
};
