import React, { useEffect } from 'react';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Card, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { Footer, footer_data, registerData, update_footer_report } from "../../../../redux/slice/companyDataSlice";
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { handleAction } from '../actions';
import Swal from 'sweetalert2';
// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  line1: Yup.string().trim().nullable().required("Line1 is required"),
  line2: Yup.string().trim().nullable().required("Line2 is required"),
  line3: Yup.string().trim().nullable().required("Line3 is required"),
})
// Export the Footerofreports component
export const Footerofreports = () => {
  // Initialize dispatch from Redux store
  const dispatch = useDispatch<AppDispatch>()
  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation();
  // The useForm hook is used to handle form registration, submission, and validation.
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<Footer>({
    resolver: yupResolver(validationSchema)
  });
  // Retrieve footerOfReport and status from Redux store
  const { footerOfReport, status } = useSelector((state: RootState) => state.companyData);
  useEffect(() => {
    // Dispatch action to fetch footer data
    dispatch(footer_data())
    // Dispatch action to fetch registration data
    dispatch(registerData())

  }, [dispatch])

  useEffect(() => {
    // Specify the fields to set values for
    const fields: string[] = ['line1', 'line2', 'line3'];
    // Set values for each field from footerOfReport
      fields.forEach((field: any) => setValue(field, footerOfReport?.[field]));
  }, [footerOfReport])

  const onSubmit = (data: Footer, e: any) => {
    let newformate: any = {
      "footer1": data?.line1 ?? "",
      "footer2": data?.line2 ?? "",
      "footer3": data?.line3 ?? ""
    }
    Swal.fire({
      title: `${t('Sweetalert.Warning')}`,
      text: `${t('Sweetalert.updatefooter')}`,
      // icon: 'success',"question",
      showCancelButton: true,
      confirmButtonText: `${t('Sweetalert.changeIt')}`,
      cancelButtonText: `${t("button.cancel")}`
    }).then(async (result) => {
     if(result.isConfirmed == true){
      let res = await dispatch(update_footer_report(newformate))
      if (res.payload.type == "Success") {
        Swal.fire({
          position: 'center',
          // icon: 'success','success',
          title: `${t('Sweetalert.Success')}`,
          text: `${t("Sweetalert.footerupdate")}`,
          confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
          timer: 2000
        })
      } else {
        Swal.fire({
          title:`${t('Sweetalert.Error')}`,
          text: `${t('Sweetalert.somethingWent')}`,
          // icon: 'success',"error",
          confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
          timer: 2000
        })
      }   
     }else{
      dispatch(footer_data())
     }
    })


    // Dispatch action to update footer report

  }

  const handleEdit = async (e, data) => {
    // Handle edit action
    console.log(e.target, data);
  }

  const handleCancel = () =>{
    dispatch(footer_data())
  }
  return (
    <>
      {
        status == "loading" ?
          ""
          :
          <Card className="mt-lg-4 mt-3">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <div>{t('company.baseboard')}</div>
              <span className='d-block TOEDIT'>
                <span>
                  <span>
                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "footerData")}>{t('button.edit')}</Button>
                  </span>
                </span>
              </span>
              <div className="d-none ACTION">
                <Row>
                <Col xl={6} sm={4}>
                        <Button
                          variant="link"
                          className="p-0 fw-bold text-nowrap"
                          onClick={(e) =>
                            handleCancel()
                          }
                          type="submit"
                        >
                          {t("button.cancel")}
                        </Button>
                      </Col>
                  <Col xl={4} sm={4}>
                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "update", "footerData")} >{t("button.toSave")}</Button>
                  </Col>
                </Row>
              </div>
            </Card.Header>
            <Card.Body className="pb-0">
              <span className="d-block mb-3 fw-bold">{t('company.footerofofter')}</span>
              {/* Baseboard reports start from here */}
              <Form onSubmit={handleSubmit(onSubmit)} id="updateFooter">
                <FloatingLabel label={t("formLabel.line1") + "*"} className="mb-sm-0 mb-lg-4 mb-3 removeInsertDisable">
                  <Form.Control type="text" className={`${errors?.line1 ? 'is-invalid' : ''}`}
                    placeholder="Please Fill This" title={getValues("line1")} {...register('line1')} disabled />
                  <Form.Control.Feedback className="invalid-feedback">{errors.line1?.message}</Form.Control.Feedback >

                </FloatingLabel>
                <FloatingLabel label={t("formLabel.line2")+ "*"} className="mb-sm-0 mb-lg-4 mb-3 removeInsertDisable">
                  <Form.Control type="text" className={`${errors.line2 ? 'is-invalid' : ''}`} placeholder="Please Fill This"
                    {...register('line2')} disabled title={getValues("line2")} />
                  <Form.Control.Feedback className="invalid-feedback">{errors.line2?.message}</Form.Control.Feedback  >

                </FloatingLabel >
                <FloatingLabel label={t("formLabel.line3")+ "*"} className="mb-sm-0 mb-lg-4 mb-3 removeInsertDisable">
                  <Form.Control type="text" className={`${errors.line3 ? 'is-invalid' : ''}`}
                    placeholder="Please Fill This" {...register('line3')} title={getValues("line3")} disabled />
                  <Form.Control.Feedback className="invalid-feedback">{errors.line3?.message}</Form.Control.Feedback >

                </FloatingLabel >
                <Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
                <Button className='fillModalwithThisData' onClick={(e) => handleEdit(e, "footerData")} hidden>{t('button.edit')}</Button>
              </Form>
              {/* /Baseboard reports end */}
            </Card.Body>
          </Card>
      }

    </>
  )
}
