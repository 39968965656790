import { getLocalStorageItem } from "../../shared/utils/getLocalStorageItem";
import { config } from "../../../../services/apiConfig";

export async function getDimobIntegrationFile(payload: { baseYear: number, contracts: number[] }) {
  const token = getLocalStorageItem("tokenV2");

  const response = await fetch(
    `${config.CUSTOMER__API}/v1/dimob/report`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Falha ao obter o arquivo");
  }

  const blob = await response.blob();
  return blob;
}
