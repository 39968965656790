import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { managerClose, contractOpen, insertManager, propertyOpen } from "../../../../redux/slice/contractDataSlice";
import { getManager } from "../../../../redux/slice/propertyDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { tenantsForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';

const ManagerService = (tenantsForm: any) => {
  let { type } = tenantsForm
  const dispatch = useDispatch<AppDispatch>();
  const [double, setDouble] = useState(false);
  const [t] = useTranslation();
  const { newManagerShow } = useSelector((state: RootState) => state.contractData)
  const newManagerClose = () => {
    let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
    zipCodeCheck?.["removeAttribute"]('disabled');
    if (type !== "properties") {
      dispatch(managerClose(false))
      dispatch(contractOpen(true));
    } else {
      dispatch(managerClose(false));
      dispatch(propertyOpen(true))
    }
    setDouble(false);
    setHide1(true);
    setHide2(false);
    reset();
  }
  const [hide1, setHide1] = useState(true);
  const [hide2, setHide2] = useState(false);
  const step1 = () => {
    setHide1(true)
    setHide2(false)
  }
  const step2 = () => {
    setHide1(false)
    setHide2(true)
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const tenantsValidation1 = Yup.object().shape({
    // cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
  });
  const tenantsValidation2 = Yup.object().shape({
    // corporateReason: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired')),
    fantasyName: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    // stateRegistration: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired')),
    // municipalRegistration: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired')),
    // mobile: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired'))
    //   .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
    // telephone: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired'))
    //   .matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
    // email: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired'))
    //   .email(t('requiredMmessage.fieldNotValid')),
  });
  let frm = {
    resolver: yupResolver(tenantsValidation1)
  }
  if (hide2) {
    frm = {
      resolver: yupResolver(tenantsValidation2)
    }
    openErrorbox()
  }
  const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<tenantsForm>(frm);
  const onSubmit = async (data: tenantsForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    let response = await dispatch(insertManager(data))
    if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      newManagerClose();
      dispatch(getManager());
    }
    else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }
  const HandleInput = (e: any) => {
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
    if (newValue == "" || newValue == null || newValue == undefined) {
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`
      });
    } else {
      clearErrors('telephone');
    }
  }
  const HandleInputMob = (e: any) => {
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
    if (newValue == "" || newValue == null || newValue == undefined) {
      setError("mobile", {
        type: "manual",
        message: `${t("requiredMmessage.mobileError")}`
      });
    } else {
      clearErrors('mobile');
    }
  }

  const handleCPF_CNJPVaue = async (e, type) => {
    let name = e.target.name;
    let checkCNJP = CNJP_CPFValidation(e.target.value, type);
    if (checkCNJP?.["status"] || e.target.value == "") {
      clearErrors(name)
      let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
      zipCodeCheck?.["setAttribute"]('disabled', "")
      if (e.target.value !== "") {
        let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
        if (duplicateData["data"] > 0) {
          zipCodeCheck?.["setAttribute"]('disabled', "")
          setError(name, {
            type: "manual",
            message: t("formLabel.ErrorCNPJCPF")
          });
        } else {
          zipCodeCheck?.["removeAttribute"]('disabled');
        }
      }
    } else {
      // errorCNPJCPF(e.target.value)
      setError(name, {
        type: "manual",
        message: checkCNJP?.["message"]
      });
    };
  }
  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={newManagerShow} onHide={newManagerClose}>
        <Form onSubmit={handleSubmit(onSubmit)} >
          <Modal.Header closeButton>
            <Modal.Title>{t('property.newManager')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div data-step="1" style={{ display: hide1 ? "block" : "none" }}>
              <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf')}>
                <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={`form-control ${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('formLabel.corporateReason')}>
                    <Form.Control type="text" placeholder={t('formLabel.corporateReason')} className={`form-control ${errors.corporateReason ? 'is-invalid' : ''}`} {...register('corporateReason')} />
                    <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('formLabel.fantasyName') + ' *'}>
                    <Form.Control type="text" placeholder={t('formLabel.fantasyName')} className={`form-control ${errors.fantasyName ? 'is-invalid' : ''}`} {...register('fantasyName')} />
                    <Form.Control.Feedback type="invalid">{errors.fantasyName?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('formLabel.stateregistration')}>
                    <Form.Control type="text" placeholder={t('formLabel.stateregistration')} className={`form-control ${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                    <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('formLabel.municipalregistration')}>
                    <Form.Control type="text" placeholder={t('formLabel.municipalregistration')} className={`form-control ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                    <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <span className="d-block fw-bold mb-3">{t('formLabel.contact')}</span>
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('formLabel.mobileNumber')}>
                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`} {...register('mobile')} onChange={HandleInputMob} />
                    <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('formLabel.telephone')}>
                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`form-control ${errors.telephone ? 'is-invalid' : ''}`} {...register('telephone')} onChange={HandleInput} />
                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput" label={t('formLabel.email')}>
                <Form.Control type="text" placeholder={t('formLabel.email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
              </FloatingLabel>
              <Accordion className="accordion-custom">
                <Accordion.Item eventKey="14">
                  <Accordion.Header>{t('observation')}</Accordion.Header>
                  <Accordion.Body>
                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                      <Form.Control as="textarea" placeholder={t('observation')} {...register('observation')} style={{ height: '100px' }} />
                    </FloatingLabel>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-sm-flex justify-content-between w-100 align-items-center">
              <Form.Check type="checkbox" {...register('isAfter')} label={t('button.afterSaving')} />
              <Button variant="outline-primary" onClick={newManagerClose}>{t('close')}</Button>
              <div className="flex-shrink-0 d-flex ms-2">
                <Button variant="outline-primary" onClick={handleSubmit(hide2 ? step1 : step1)} className="js-btn-step">
                  {t('previous')}
                </Button>
                <Button variant="primary" style={{ display: hide1 ? "block" : "none" }} onClick={handleSubmit(hide1 ? step2 : step2)} className="bmin-w-auto js-btn-step">
                  {t('next')}
                </Button>
                <Button disabled={double} className="min-w-auto zipCodeCheck" type="submit" style={{ display: hide2 ? "block" : "none" }} variant="primary" onClick={openErrorbox} >
                  {t('save')}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default ManagerService;