import React from "react";
import { Col, Row } from "react-bootstrap";

import { useChargesToReceive } from "../../ChargesToReceiveProvider";
import { SummaryCard } from "./SummaryCard";
import { RemittanceCard } from "./RemittanceCard/RemittanceCard";
import { useHomologatedChargesList } from "../HomologatedChargesList";

export function Summary() {
  const { allChargesSummary } = useChargesToReceive();
  const {
    totalPaidAmountHomologateCharges,
    totalPaidQuantityHomologateCharges,
    totalPendingAmountHomologateCharges,
    totalPendingQuantityHomologateCharges,
  } = useHomologatedChargesList();

  return (
    <Row>
      <Col>
        <SummaryCard title="Resumo de cobranças" summary={allChargesSummary} />
      </Col>
      <Col>
        <SummaryCard
          title="Conta homologada"
          summary={{
            amountReceived: totalPaidAmountHomologateCharges,
            countReceived: totalPaidQuantityHomologateCharges,
            amountReceivable: totalPendingAmountHomologateCharges,
            countReceivable: totalPendingQuantityHomologateCharges,
          }}
        />
      </Col>
      <Col>
        <RemittanceCard />
      </Col>
    </Row>
  );
}
