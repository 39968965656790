import React, { useEffect, useState, useTransition } from 'react'
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { handleAction, handleSubmitData } from './notification';
import { useForm, } from "react-hook-form";
import { modal_reducer, welcome_reducer } from "../../../../redux/slice/notificationSlice";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Welcome = (props: any) => {
  let { dispatch, stateData,notificationData } = props;
  // The useForm hook is used to handle form registration, submission, and validation.
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({});
  const [object, setObject] = useState([]);
  const navigate = useNavigate();
  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation()
  let { welcome } = stateData;
  let basicdata = {
    dispatch,
    stateData,
    modal_reducer,
    welcome_reducer
  }
  

  useEffect(() => {
    // Set the initial value of 'object' to welcome.basic_data
    setObject(welcome.basic_data);
  }, [])

  useEffect(() => {
     // Get the fields from the 'birthday.basic_data' object
    let fields: string[] = Object.keys(welcome.basic_data);
    fields.forEach((field: any) => setValue(field, welcome?.basic_data?.[field]));
  }, [object, stateData])

  

  let page = "welcome";
  // Function to handle form submission
  const onSubmit = async (data: any) => {
    let checkSubmition = true;
     // Check if any input value includes a negative number
    document.querySelectorAll(`.${page}.removeInsertDisable`).forEach(item => {
      if (item["value"].includes('-')) {
        checkSubmition = false;
      }
    })
    if (checkSubmition) {
        // If all input values are valid, submit the data
      handleSubmitData(data);
    } else {
      // Show an error message
      Swal.fire({
        position: 'center',
        // icon: 'success','error',
        title: `${t('Sweetalert.Error')}`,
        text: `${t('Sweetalert.negativeNumberNotAllowed')}`,
        showConfirmButton: true,
        timer: 1000
      })
    }
  }

  useEffect(() => {
    if(notificationData){
      setValue("send_notification_start", Number(notificationData["welcome_send_notifications"]))
      setValue("days_start", Number(notificationData["welcome_no_of_days"]))
    }
  },[notificationData])

  const handleEmailName = (id,NOME, LABEL) => {
    document.querySelector('#root')?.setAttribute('st_nome_moh', NOME);
    document.querySelector('#root')?.setAttribute('st_label_moh', LABEL);
    localStorage.setItem("YhGUFY_hygft",id);
    let data = {
      notification: "notification"
    }
    navigate("/message", { state: data });
}

  return (
    <>
      <Col lg={6}>
        <Card className="h-100">
          <Card.Header className="d-flex justify-content-between">
            <div className="w-80 me-2">{t("notifications.welcome")}<img src="assets/images/icons/info-light.svg" alt="Info icon" className="h-18 ms-2" /></div>
            <span className='d-block TOEDIT'>
              <span>
                <span>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "welcome", basicdata)}>{t("button.edit")}</Button>
                </span>
              </span>
            </span>
            <div className="d-none ACTION">
              <Row>
                <Col xl={4} sm={4}>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "save", "welcome", basicdata)} >{t("button.save")}</Button>
                </Col>
                <Col xl={4} sm={4}>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={() => handleEmailName("14","Seja bem vindo", "notificacoescontratuais")}>{t("button.edit")}</Button>
                </Col>
                <Col xl={4} sm={4}>
                  <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "cancel", "welcome", basicdata)} >{t("button.cancel")}</Button>
                </Col>
              </Row>
            </div>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)} >
              <Form.Check className="mb-3 mb-lg-4 removeInsertDisable" type="checkbox" {...register("send_notification_start")} id={`default_10`} label={t("notifications.checkNotification")} disabled />
              <Row className="gx-3 gy-xl-4 gy-3 align-items-center">
                <Col xl={2} sm={3}>
                  <Form.Control type="number" className={`${page} removeInsertDisable`} {...register("days_start")} aria-label="days" min="0" disabled />
                </Col>
                <Col sm>{t("notifications.welcomeDayAfter")}</Col>
              </Row>
              <Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

export default Welcome