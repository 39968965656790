import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, FloatingLabel, Offcanvas } from 'react-bootstrap';
import CommonToast from './CommonToast';
import SaveChange from './SaveChange';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import customerService from '../../../../../../services/customerService';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Text_Decoder, minDate } from '../../../../../constants/utils';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../redux/store';
import { contractDatails } from '../../../../../../redux/slice/contractDataSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { Termination } from '../../../../../Interface';

const PropertyActions = (props) => {
    const [t] = useTranslation();
    const [loader_on, setloader_on] = useState(false);
    const [typeSubmit, settypeSubmit] = useState("draft");
    const validationSchema = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        party_responsible_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        party_responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // observation_first: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });
    const validationSchema1 = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });
    var frm = {
        resolver: yupResolver(validationSchema1),
    };
    if (typeSubmit === "done") {
        frm = {
            resolver: yupResolver(validationSchema),
        };
    }
    const { id }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const typeValue = "property_action_XYZ";
    //Property actions contract modal close open function
    const { showPropertyActionsModal, propertyActionsModalClose, ShowThisData, terminationResp, allresponsibleList } = props;
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<Termination>(frm);
    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = () => {
        setToastShow(true);
        setTimeout(() => {
            setToastShow(false);
        }, 5000);
    }

    //Save changes modal initialization
    const [showSaveChangesModal, setSaveChangesModal] = useState(false);
    const saveChangesModalClose = () => setSaveChangesModal(false);
    const saveChangesModalShow = () => {
        // setSaveChangesModal(true);
        propertyActionsModalClose(false)
        reset();
    }

    const [statusOfChecklist, setStatusOfChecklist] = useState(1);

    useEffect(() => {
        if (showPropertyActionsModal) {
            setValue("responsible", "1");
            setValue("party_responsible", "1");
            setShowResponsible_party(allresponsibleList[0]["agency"]);
            setShowResponsible(allresponsibleList[0]["agency"]);
            (async () => {
                let dataObj = {
                    type: typeValue,
                    contract_id: id
                };
                customerService.get_terminationDefaultTerminationList(dataObj).then(async (res) => {
                    if (res["data"]["type"] == "success") {
                        let post = res["data"]["data"][0];
                        if (post !== undefined) {
                            let bolbdecode = await Text_Decoder(post["TA_FIRST_OBSERVATION_TERPOP"]["data"]);
                            let bolbdecodesec = await Text_Decoder(post["TA_SECOND_OBSERVATION_TERPOP"]["data"]);
                            setValue("id", post["ID_TERMINATIONPOPUP_TERPOP"]);
                            setValue("responsible", post["SL_RESPONSIBLE_TERPOP"]);
                            setValue("limit_date", moment(post["DT_LIMITDATE_TERPOP"].split("T")[0]).format("YYYY-MM-DD"));
                            setValue("party_responsible", post["SL_PARTY_RESPONSIBLE_TERPOP"]);
                            setValue("observation_first", bolbdecode);
                            setValue("observation_second", bolbdecodesec);
                            setValue("property_checkbox", post["CB_PROPERTYCHECKBOX_TERPOP"]);
                            setStatusOfChecklist(Number(post["ST_STATUS_TERPOP"]));

                            let lop = [post["SL_RESPONSIBLE_TERPOP"] ? post["SL_RESPONSIBLE_TERPOP"] : "", post["SL_PARTY_RESPONSIBLE_TERPOP"] ? post["SL_PARTY_RESPONSIBLE_TERPOP"] : ""];
                            lop.forEach((item, index) => {
                                if (item !== "") {
                                    handleResponsible(index == 0 ? post["SL_RESPONSIBLE_TERPOP"] : post["SL_PARTY_RESPONSIBLE_TERPOP"], index == 0 ? post["SL_RESPONSIBLE_PARTY_NAME_TERPOP"] ?? "" : post["SL_PARTY_RESPONSIBLE_NAME_TERPOP"] ?? "", index == 0 ? "load_form" : "load_form_party")
                                }
                            });

                            // if (post["ST_STATUS_TERPOP"] !== 1) {
                            //     document.querySelectorAll('.DisableThis').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     });

                            //     document.querySelectorAll('.DisableThis input').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     })
                            // }
                        }
                    }
                })
            })()
        }
    }, [showPropertyActionsModal]);


    const handleSaveDraftClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('draft');
        // Submit the form
        handleSubmit((data, e) => onPropertyActionsFormSubmit(data, e, "draft"))();
    };

    let [showResponsible, setShowResponsible] = useState([]);
    let [showResponsible_party, setShowResponsible_party] = useState([]);
    const handleResponsible = (e, rep_id, type) => {
        if (type == "load_form" || type == "load_form_party") {
            if (type == "load_form") {
                setValue("responsible_party_name", "");
            }
            if (type == "load_form_party") {
                setValue("party_responsible_name", "");
            }
        } else {
            if (type == "form") {
                setValue("responsible_party_name", "");
            }
            if (type == "form_party") {
                setValue("party_responsible_name", "");
            }
        }

        let id: any = 0;
        let arrayChange: any = [];

        if (type == "form" || type == "form_party") {
            id = Number(e.target.value);
        } else {
            id = Number(e);
        }

        if (id !== "") {
            if (id == 1) {
                arrayChange = allresponsibleList[0]["agency"];
            }

            if (id == 2) {
                arrayChange = allresponsibleList[0]["owner"]
            }

            if (id == 3) {
                arrayChange = allresponsibleList[0]["tenent"]
            }

            if (id == 4) {
                arrayChange = allresponsibleList[0]["manager"]
            }

            if (id == 5) {
                arrayChange = allresponsibleList?.[0]?.["gurantor"] ?? allresponsibleList?.[0]?.["guarantor"]
            }
        }


        if (type == "load_form" || type == "form") {
            setShowResponsible(arrayChange);
            if (arrayChange.length > 0 && arrayChange.length < 2) {
                setTimeout(() => {
                    setValue("responsible_party_name", arrayChange[0].value);
                }, 100)
            } else {
                setTimeout(() => {
                    setValue("responsible_party_name", rep_id);
                }, 100)
            }
        } else {
            setShowResponsible_party(arrayChange);
            if (arrayChange.length > 0 && arrayChange.length < 2) {
                setTimeout(() => {
                    setValue("party_responsible_name", arrayChange[0].value);
                }, 100)
            } else {
                setTimeout(() => {
                    setValue("party_responsible_name", rep_id);
                }, 100)
            }
        }
    }

    const handleSaveCompleteClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('done');
        // Submit the form
        Swal.fire({
            // icon: 'success','warning',
            title: t("Sweetalert.WantToSave"),
            text: t("Sweetalert.Areyousuretosaveit"),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('button.confirm')
        }).then(res => {
            if (res.isConfirmed) {
                handleSubmit((data, e) => onPropertyActionsFormSubmit(data, e, "done"))();
            }
        })
    };

    //Form submit
    const onPropertyActionsFormSubmit = (data, e, type) => {
        setloader_on(true)

        data["type"] = typeValue;
        data["contract_id"] = id;

        if (type == "done") {
            data["status"] = 0;
        }

        if (type == "draft") {
            data["status"] = 1;
        }

        customerService.post_terminationDefaultTerminationList(data).then(async res => {
            if (res["data"]["type"] == "success") {
                settypeSubmit('draft')
                await dispatch(contractDatails(id));
                Swal.fire({
                    // icon:'success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                reset();
            } else {
                Swal.fire({
                    // icon:'error',
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
            propertyActionsModalClose();
            setloader_on(false);
        })
    };

    return (
        <>
            <Offcanvas show={showPropertyActionsModal} placement="end" onHide={saveChangesModalShow}>
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit((data, e) => onPropertyActionsFormSubmit(data, e, ""))}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className="h4">{t("terminatelist.Propertyactions")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                            <Form.Control type="text"  {...register("id")} hidden readOnly />
                            <Col md={6}>
                                <FloatingLabel controlId="responsible" label={t('formLabel.responsible') + " *"}>
                                    <Form.Select aria-label="Responsible" {...register("responsible")} className={`DisableThis ${errors.responsible ? "is-invalid" : ""}`} onChange={(e) => handleResponsible(e, "", "form")}>
                                        <option value="">{t("formLabel.select")}</option>
                                        {terminationResp && terminationResp.length > 0 &&
                                            terminationResp.map((item, index) => {
                                                return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback className="invalid-feedback">{errors.responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="limit_date" label={t('terminatelist.limit') + " *" + " " + t("terminatelist.date") + " *"}>
                                    <Form.Control type="date" min={minDate()} placeholder={t('terminatelist.limit') + " " + t("terminatelist.date")} {...register("limit_date")} className={`DisableThis ${errors.limit_date ? "is-invalid" : ""}`} />
                                    <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel controlId="responsible_party_name" label={t("terminatelist.Responsibleparty'sname") + " *"} className="mb-lg-4 mb-3">
                            <Form.Select aria-label="Responsible party's name" {...register("responsible_party_name")} className={`DisableThis ${errors.responsible_party_name ? "is-invalid" : ""}`}>
                                <option value="">{t("formLabel.select")}</option>
                                {showResponsible && showResponsible.length > 0 &&
                                    showResponsible.map((item, index) => {
                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback className="invalid-feedback">{errors.responsible_party_name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <span className="fw-bold mb-3 mb-lg-4 d-block">{t('terminatelist.Partyresponsiblefor')} {t('terminatelist.electricitypayment')} </span>
                        <FloatingLabel controlId="responsible" label={t('formLabel.responsible') + " *"} className="mb-lg-4 mb-3">
                            <Form.Select aria-label="Responsible" {...register("party_responsible")} className={`DisableThis ${errors.party_responsible ? "is-invalid" : ""}`} onChange={(e) => handleResponsible(e, "", "form_party")}>
                                <option value="">{t("formLabel.select")}</option>
                                {terminationResp && terminationResp.length > 0 &&
                                    terminationResp.map((item, index) => {
                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback className="invalid-feedback">{errors.party_responsible?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="observation" label={t("formLabel.observation")}>
                            <Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("observation_first")} className={`DisableThis ${errors.observation_first ? "is-invalid" : ""}`} />
                            <Form.Control.Feedback className="invalid-feedback">{errors.observation_first?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                        <span className="fw-bold mb-3 mb-lg-4 d-block">{t('terminatelist.Partyresponsiblefor')} {t('terminatelist.waterpayment')} </span>
                        <FloatingLabel controlId="party_responsible_name" label={t('formLabel.responsible') + " *"} className="mb-lg-4 mb-3">
                            <Form.Select aria-label="Responsible party's name" {...register("party_responsible_name")} className={`DisableThis ${errors.party_responsible_name ? "is-invalid" : ""}`}>
                                <option value="">{t("formLabel.select")}</option>
                                {terminationResp && terminationResp.length > 0 &&
                                    terminationResp.map((item, index) => {
                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback className="invalid-feedback">{errors.responsible_party_name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="observation" label={t("formLabel.observation")}>
                            <Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("observation_second")} className={`DisableThis ${errors.observation_second ? "is-invalid" : ""}`} />
                            <Form.Control.Feedback className="invalid-feedback">{errors.observation_second?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                            <Form.Check id="property_reactivated" label={t('terminatelist.Thepropertylistinghasbeenreactivatedonthewebsite')} {...register("property_checkbox")} className='DisableThis' />
                        </Form.Group>
                    </Offcanvas.Body>

                    {/* {
                        statusOfChecklist === 1 && */}
                    <>
                        <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                            {/* <Button variant="outline-primary" onClick={handleSaveDraftClick}>
                                    {t('terminatelist.Savedraft')}
                                </Button> */}
                            {
                                loader_on ?
                                    <Button className="btn-width">
                                        <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                    </Button>
                                    :
                                    <>
                                        <Button variant="outline-primary" onClick={saveChangesModalShow}>
                                            {t('button.close')}
                                        </Button>
                                        <Button variant="primary" onClick={handleSaveCompleteClick}>
                                            {t('button.save')}
                                        </Button>
                                    </>
                            }
                        </div>
                    </>
                    {/* } */}

                </Form>
            </Offcanvas>
            {/* Toast start from here */}
            <CommonToast showCommonToast={showToast} commonToastClose={commonToastClose} />
            {/* Toast end here */}
            {/* Save changes modal */}
            <SaveChange showSaveChange={showSaveChangesModal} saveChangeClose={saveChangesModalClose} saveChangeShow={saveChangesModalShow} />
        </>
    )
}

export default PropertyActions
