import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
let connectionName: any = '';
if (localStorage.getItem("userData")) {
  connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCEESS: "success",
  ERROR: "error",
});

export interface acc_category {
  value: number;
  data?: number;
  category: string;

}

interface AuthState {
  account_cateGory: acc_category[];
  accountPlane: any;
  filter: any;
  status: string;
  selectCat: any;
}

const initialState: AuthState = {
  account_cateGory: [],
  filter: 0,
  accountPlane: [],
  status: STATUSES.IDLE,
  selectCat: 0,
};

const AccountPlanSlice = createSlice({
  name: "AccountPlanSlice",
  initialState,
  reducers: {
    add_category: (state, action) => {
      // state.account_cateGory = [action.payload, ...state.account_cateGory];
      state.status = STATUSES.SUCEESS;
    },
    addItems: (state, action) => {
      state.accountPlane = [action.payload, ...state.accountPlane];
      state.status = STATUSES.SUCEESS;
    },
    getCateGory: (state, action) => {
      state.filter = action.payload;
      state.status = STATUSES.SUCEESS;
    },
    deleteCate: (state, action) => {
      state.account_cateGory = state.account_cateGory.filter(
        (el) => el.value != action.payload
      );
      state.status = STATUSES.SUCEESS;
    },
    getCate: (state, action) => {
      state.selectCat = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItem.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getItem.fulfilled, (state, action) => {

        let categoryData = "";

        const modifyLabels = (data: any) => {
          const updatedData: any = [];
          for (const item of data) {
            let itemStcoumt = item.ST_CONTA_CONT
            if (itemStcoumt.length == 1) {
              categoryData = itemStcoumt;
            }

            const updatedItem: any = {
              item: item.ST_CONTA_CONT,
              description: item.ST_DESCRICAO_CONT,
              category: categoryData,
              childData: item.childData ? modifyLabels(item.childData) : [],
              count: 1,
            };
            updatedData.push(updatedItem);
          }
          return updatedData;
        };
        const updatedData: any = {
          data: modifyLabels(action.payload.data),
        };
        state.accountPlane = updatedData.data;
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getItem.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(AddNewItem.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(AddNewItem.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(AddNewItem.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(updateItems.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateItems.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateItems.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(UpdateSubItems.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(UpdateSubItems.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(UpdateSubItems.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(deleteItem.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteItem.fulfilled, (state) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(deleteItem.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getCategory.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        let cate: any = [];
        action.payload.data.forEach((item, index) => {
          cate.push({
            value: item.ST_CONTA_CONT,
            category: item.ST_DESCRICAO_CONT,
            data: item.total_category_item,
            account: "api_get",
          });
        });
        state.account_cateGory = cate;
        if (cate.length > 0) {
          state.filter = cate[0]["value"];
        }
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getCategory.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(InsertCategory.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(InsertCategory.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(InsertCategory.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(UpdateCateGory.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(UpdateCateGory.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(UpdateCateGory.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(deleteCategory.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { add_category, getCateGory, addItems, deleteCate, getCate } = AccountPlanSlice.actions;
export default AccountPlanSlice.reducer;

export const getItem = createAsyncThunk("get_items", async (_, thunkAPI) => {
  let token: any = localStorage.getItem("tokenV2");
  const response = await fetch(`${config.companyV2_URL}/company/coa/getCOA`, {
    method: "GET",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      'connection-name': connectionName
    }
  });
  const data = await response.json();
  return data;
});

export const AddNewItem = createAsyncThunk(
  "add_new_item",
  async (NewItem: any) => {
    let bodyContent = JSON.stringify(NewItem);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/coa/insertCOA`, {
      method: "PUT",
      body: bodyContent,
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);

export const updateItems = createAsyncThunk(
  "update_item",
  async (updateItem: any) => {
    var bodyContent = JSON.stringify(updateItem);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/coa/updateMultipleCOA`, {
      method: "PATCH",
      body: bodyContent,
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);

export const UpdateSubItems = createAsyncThunk(
  "updateSubItme",
  async (UpdateSub: any) => {
    var bodyContent = JSON.stringify(UpdateSub);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/coa/updateCOA`, {
      method: "PATCH",
      body: bodyContent,
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);

export const deleteItem = createAsyncThunk(
  "delete_item",
  async (deleteitem: any) => {
    var bodyContent = JSON.stringify(deleteitem);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/coa/deleteCOA`, {
      method: "DELETE",
      body: bodyContent,
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);

// GET CATEGORY
export const getCategory = createAsyncThunk("get_category", async () => {
  let token: any = localStorage.getItem("tokenV2");

  const response = await fetch(`${config.companyV2_URL}/company/coa/getCOACategories`, {
    method: "GET",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      'connection-name': connectionName
    },
  });

  const responseitem = await fetch(`${config.companyV2_URL}/company/coa/getCOA`, {
    method: "GET",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      'connection-name': connectionName
    },
  });

  const dataitem = await responseitem.json();
  const data = await response.json();
  data.data.forEach((cat) => {
    let total_category_item = 0;
    dataitem.data.forEach((item) => {
      if (Number(cat.ST_CONTA_CONT) == Number(item.ST_CONTA_CONT)) {
        if (item.childData.length > 0) {
          total_category_item++;
        } else {
          total_category_item = 0
        }
        cat["total_category_item"] = total_category_item;
      }
    });

  });

  return data;
});

export const InsertCategory = createAsyncThunk(
  "insert_category",
  async (newCate: any) => {
    var bodyContent = JSON.stringify(newCate);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/coa/insertCOACategory`, {
      method: "PUT",
      body: bodyContent,
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);

export const UpdateCateGory = createAsyncThunk(
  "update_category",
  async (updateCate: any) => {
    var bodyContent = JSON.stringify(updateCate);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/coa/updateCOACategory`, {
      method: "PATCH",
      body: bodyContent,
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);

export const deleteCategory = createAsyncThunk(
  "delete_category",
  async (deleteitem: any) => {
    var bodyContent = JSON.stringify(deleteitem);
    let token: any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/company/coa/deleteCOACategory`, {
      method: "DELETE",
      body: bodyContent,
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        'connection-name': connectionName
      },
    });
    const data = await response.json();
    return data;
  }
);
