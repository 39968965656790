import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { administratorClose, propertyOpen, insertAdministrator } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getAdministrators } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { administratorForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { numericOnly, openErrorbox } from './actionPerform';
import customerService from '../../../../services/customerService';
import '../../../../App.css';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';

const AdministratorService = (administratorForm) => {
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { newAdministratorShow } = useSelector((state: RootState) => state.contractData)
    /*------------History modal hide function-----------*/
    const newAdministratorClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        dispatch(propertyOpen(true));
        dispatch(administratorClose(false));
        setDouble(false);
        reset();
    }
    /*------------Form validation-----------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const administratorValidation = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    let frm = {
        resolver: yupResolver(administratorValidation)
    }
    /*------------Submit history form data-----------*/
    const { register, reset, handleSubmit, setValue, setError, clearErrors, formState: { errors } } = useForm<administratorForm>(frm);
    const onSubmit = async (data: administratorForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        let response = await dispatch(insertAdministrator(data));
        if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: response.payload.message,
                confirmButtonText: "OK"
                // timer : 2000
            })
            dispatch(getAdministrators())
            newAdministratorClose();
        } else if(response.payload.resCode == 409){
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.unqueEmails"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
        else {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: response.payload.message,
                // icon: 'success',"error",
                confirmButtonText: "OK"
                //timer : 2000
            })
        }
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const [btn2, setBtn2] = useState(false);
    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
          `${inputType}/${newStr}`
        );
        let querySel: any = document.querySelector('.validZipcode [name="zipcode"]');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["type"] == "Success") {
                setBtn2(false);
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                setBtn2(true);
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
            }
        } else {
            setBtn2(false);
            querySel.style.border = "0px solid red";
        }
    }
    const [allStates, setAllStates] = useState([]);
    useEffect(() => {
        if (newAdministratorShow) {
            (async () => {
                let resp = await customerService.getAddressBasedOnZipcode(`states`);
                if (resp.data.status == "success" || resp.data.type == "Success") {
                    let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
                }
            })()
        }
    }, [newAdministratorShow]);

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name);
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Modal show={newAdministratorShow} onHide={newAdministratorClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('property.newAdministrator')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                            <Form.Control type="text" placeholder={t('formLabel.name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cnpjCpf') }>
                                    <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={`form-control ${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")} />
                                    <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.responsible')}>
                                    <Form.Control type="text" placeholder={t('formLabel.responsible')} className={`form-control ${errors.responsible ? 'is-invalid' : ''}`} {...register('responsible')} />
                                    <Form.Control.Feedback type="invalid">{errors.responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="email" placeholder={t('formLabel.email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`form-control ${errors.telephone ? 'is-invalid' : ''}`} {...register('telephone')} onChange={HandleInput} />
                                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="1" >
                                <Accordion.Header>{t('formLabel.address')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel className="mb-3 validZipcode" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} onChange={(e) => getLocation(e, "zipcode")} maxLength={9} minLength={9} />
                                        <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                <Form.Control type="text" placeholder={t('formLabel.address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address')} />
                                                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                <Form.Control type="text" placeholder={t('formLabel.number')} className={`form-control ${errors.number ? 'is-invalid' : ''}`} {...register('number')} onChange={numericOnly} />
                                                <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                <Form.Control type="text" placeholder={t('formLabel.complement')} className={`form-control ${errors.complement ? 'is-invalid' : ''}`} {...register('complement')} />
                                                <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`form-control ${errors.neighborhood ? 'is-invalid' : ''}`} {...register('neighborhood')} />
                                                <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                <Form.Control type="text" placeholder={t('formLabel.city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`} {...register('city')} />
                                                <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                <Form.Select className={`form-control ${errors.state ? 'is-invalid' : ''}`}  {...register('state')} aria-label={t('formLabel.state')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    {allStates && allStates.length > 0 &&
                                                        allStates.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                            )
                                                        })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')} >
                                        <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer className="d-flex w-100 align-items-center justify-content-between">
                        <Form.Group>
                            <Form.Check type="checkbox" {...register('isAfter')} label={t("formLabel.adminvender")} />
                        </Form.Group>
                    <div className="flex-shrink-0">
                        <Button variant="outline-primary" className="me-2 me-lg-3" onClick={newAdministratorClose}>
                            {t('button.close')}
                        </Button>
                        <Button disabled={btn2 || double ? true : false} type="submit" variant="primary" onClick={openErrorbox} className='zipCodeCheck' >
                            {t('button.save')}
                        </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default AdministratorService;