import { getLocalStorageItem } from "../../shared/utils/getLocalStorageItem";
import { config } from "../../../../services/apiConfig";

import axios from "axios";

export async function getDimobBigNumbers() {
  const token = getLocalStorageItem("tokenV2");
  const userData = getLocalStorageItem("userData");

  const response = await axios.get(
    `${config.CUSTOMER__API}/v1/dimob/contractDashboard`,
    {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
        "connection-name": userData,
      },
    }
  );

  return response.data;
}
