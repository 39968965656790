import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';
import eventBus from "./EventBus";
import { useTranslation } from 'react-i18next';
import { incomeService } from '../../../../services/incomeServices';
import { config } from '../../../../services/apiConfig';
import Swal from 'sweetalert2';

const ChargePrintOut = (props) => {
    const [t] = useTranslation();
    //Charge liquidate modal close function
    const { show, onClose, formValues, isCheckId, setIsCheckId, unSelectAllUsers } = props;
    const [loader_on, setLoader_on] = useState(false);
    // const onSubmitForm = () => {
    //     eventBus.remove("couponApply", {});
    //     eventBus.dispatch("couponApply", { message: "coupone applied" });
    // };

    const onSubmitForm = () => {
        setLoader_on(true);
        const ID_CONTRATO_CON = "";
        incomeService.getChargeInvoice({ ID_RECEBIMENTO_RECB: isCheckId, ID_CONTRATO_CON: ID_CONTRATO_CON }).then(res => {
            if (res.data && res.data && (res.data.message && res.data.message.status == 'success')) {
                let url = `${config.CUSTOMER___API}/${res.data.message.boletoGCurl}`;
                window.open(url, '_blank');
                setLoader_on(false);
                setIsCheckId([]);
                onClose();
                unSelectAllUsers();
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.serverError"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setLoader_on(false);
                setIsCheckId([]);
            }
        });
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>{t("printOut")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form.Check type="checkbox" id="rd1" className="mb-lg-4 bm-3" label={t("ReceiveandReceived.BackAddressingRequires")} />
                    <Form.Check type="checkbox" id="rd2" className="mb-lg-4 bm-3" label={t("ReceiveandReceived.Morelinesrequires")} />
                    <Form.Check type="checkbox" id="rd3" className="mb-lg-4 bm-3" label={t("ReceiveandReceived.RegisterinbillingCRM")} /> */}
                    <FloatingLabel controlId="maturity" className="mb-lg-4 bm-3" label={t("maturity")}>
                        <Form.Control type="date" placeholder={t("maturity")} />
                    </FloatingLabel>
                    <Alert className="mb-0" variant="warning">
                        {t("ReceiveandReceived.Expirationwillonlychangeon")}.
                    </Alert>
                </Modal.Body>
                <Modal.Footer>

                    {loader_on ?
                        <Button className="btn-width">
                            <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                        </Button> :
                        <>
                            <Button variant="outline-primary" onClick={onClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={onSubmitForm}>
                                {t("button.toSave")}
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ChargePrintOut
