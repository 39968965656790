import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { gettoken } from "../../services/apiConfig";
import { config } from "../../services/apiConfig";
let connectionName : any = '';
if( localStorage.getItem("userData") ){
  connectionName = localStorage.getItem("userData");
}
function convertDateFormat(dateString) {
    var parts = dateString.split('-'); // Split the string by '-'
    var formattedDate = parts[2] + '/' + parts[1] + '/' + parts[0]; // Rearrange the parts
    return formattedDate;
}

export const STATUSES = Object.freeze({
    LOADING: "loading",
    SUCEESS: "success",
    ERROR: 'error'
});

export interface NewExpenses {
    id: number,
    maturity: Date,
    supplier: string,
    payment_method: string,
    account_category: string,
    complement: string,
    amount: string,
    value: number,
    issuance: Date,
    type: number
    doc_no: number,
    bank_account: string,
    cost_center: string,
    participation: string
    price: number,
    no_of_installments: number,
    repeat: string,
    historic: string,
    comments: string
    termsAndConditions: boolean
    values: {
        account_category: string,
        complement: string,
        amount: string,
        value: number
    },
    withoutholding: {
        maturity: string,
        tax: number,
        value: number,
        bank_account: number,
        complement: string
    },

}

export interface Liquidate {
    id:number;
    payment_method: number,
    date: Date,
    value: number,
    check_number: number,
    attach_file: File,
    bank_account: number
}

interface toBePaidState {
    supplierList: any
    expenses_list: any
    status: string,
    show: boolean
}

const initialState: toBePaidState = {
    supplierList: [],
    expenses_list: [{
        "id": 1,
        "checkbox": false,
        "date": "20/06/2023",
        "maturity": "2023-05-25",
        "supplier": "1",
        "payment_method": "1",
        "account_category": "0",
        "complement": "Lorem Ipsum",
        "amount": "1000",
        "value": "340",
        "issuance": "2023-05-30",
        "type": "1",
        "doc_no": "123456",
        "bank_account": "2",
        "cost_center": "0",
        "participation": "10000",
        "price": "10000",
        "no_of_installments": "345678",
        "repeat": "2345678",
        "historic": "Contract AP0106/1 - Condominium payment - to City Hall",
        "comments": "Lorem Ipsum",
        "values": {
            "account_category": "0",
            "complement": "10000",
            "amount": "10000",
            "value": "10000"
        },
        "withoutholding": {
            "maturity": "2023-05-26",
            "tax": "0",
            "value": "10000",
            "bank_account": "0",
            "complement": "10000"
        },
        "daysLeft": 90,
        "document_payment": false,
        "status": 2,
    },
    {
        "id": 2,
        "checkbox": false,
        "date": "07/06/2023",
        "maturity": "2023-05-25",
        "supplier": "2",
        "payment_method": "1",
        "account_category": "0",
        "complement": "Lorem Ipsum",
        "amount": "1000",
        "value": "1000",
        "issuance": "2023-05-30",
        "type": "1",
        "doc_no": "123456",
        "bank_account": "0",
        "cost_center": "0",
        "participation": "10000",
        "price": "10000",
        "no_of_installments": "345678",
        "repeat": "2345678",
        "historic": "Contract 10/1 - Condominium payment - for Elite Administrator",
        "comments": "Lorem Ipsum",
        "values": {
            "account_category": "0",
            "complement": "10000",
            "amount": "10000",
            "value": "10000"
        },
        "withoutholding": {
            "maturity": "2023-05-26",
            "tax": "0",
            "value": "10000",
            "bank_account": "0",
            "complement": "10000"
        },
        "daysLeft": 90,
        "document_payment": true,
        "status": 1,
    },
    {
        "id": 3,
        "checkbox": false,
        "date": "05/07/2023",
        "maturity": "2023-06-15",
        "supplier": "5",
        "payment_method": "1",
        "account_category": "1",
        "complement": "Lorem Ipsum",
        "amount": "1200",
        "value": "900",
        "issuance": "2023-06-02",
        "type": "1",
        "doc_no": "456789",
        "bank_account": "1",
        "cost_center": "2",
        "participation": "6000",
        "price": "6000",
        "no_of_installments": "234567",
        "repeat": "123456",
        "historic": "Contract AP0106/3 - Software license fee - to Tech Solutions",
        "comments": "Lorem Ipsum",
        "values": {
        "account_category": "1",
        "complement": "6000",
        "amount": "6000",
        "value": "6000"
        },
        "withoutholding": {
        "maturity": "2023-06-20",
        "tax": "0",
        "value": "6000",
        "bank_account": "1",
        "complement": "6000"
        },
        "daysLeft": 10,
        "document_payment": false,
        "status": 0,
        },
        {
        "id": 4,
        "checkbox": false,
        "date": "01/07/2023",
        "maturity": "2023-06-10",
        "supplier": "6",
        "payment_method": "2",
        "account_category": "2",
        "complement": "Lorem Ipsum",
        "amount": "1800",
        "value": "1500",
        "issuance": "2023-06-01",
        "type": "1",
        "doc_no": "345678",
        "bank_account": "2",
        "cost_center": "1",
        "participation": "8000",
        "price": "8000",
        "no_of_installments": "345678",
        "repeat": "234567",
        "historic": "Contract 10/4 - Advertising campaign - for XYZ Agency",
        "comments": "Lorem Ipsum",
        "values": {
        "account_category": "2",
        "complement": "8000",
        "amount": "8000",
        "value": "8000"
        },
        "withoutholding": {
        "maturity": "2023-06-15",
        "tax": "0",
        "value": "8000",
        "bank_account": "2",
        "complement": "8000"
        },
        "daysLeft": 5,
        "document_payment": true,
        "status": 1,
        },
        {
        "id": 5,
        "checkbox": true,
        "date": "12/07/2023",
        "maturity": "2023-06-22",
        "supplier": "7",
        "payment_method": "3",
        "account_category": "1",
        "complement": "Lorem Ipsum",
        "amount": "900",
        "value": "800",
        "issuance": "2023-06-05",
        "type": "2",
        "doc_no": "567890",
        "bank_account": "1",
        "cost_center": "1",
        "participation": "7000",
        "price": "7000",
        "no_of_installments": "345678",
        "repeat": "123456",
        "historic": "Contract AP0106/5 - Maintenance service - to Support Solutions",
        "comments": "Lorem Ipsum",
        "values": {
        "account_category": "1",
        "complement": "7000",
        "amount": "7000",
        "value": "7000"
        },
        "withoutholding": {
        "maturity": "2023-06-27",
        "tax": "0",
        "value": "7000",
        "bank_account": "1",
        "complement": "7000"
        },
        "daysLeft": 10,
        "document_payment": false,
        "status": 2,
        }
    ],

    status: STATUSES.LOADING,
    show: false,
};

const toBePaidSlice = createSlice({
    name: "toBePaidSlice",
    initialState,
    reducers: {
        Show_New_expenses: (state, action) => {
            state.show = action.payload
        },
        Add_new_expenses: (state, action) => {
            state.expenses_list = [action.payload, ...state.expenses_list]
            state.status = STATUSES.SUCEESS
        },
        Edit_new_expense: (state, action) => {
            state.expenses_list = state.expenses_list.map((el) =>
                el.id == action.payload ? { ...action.payload } : el
            );

        },
        ChangeDate: (state, action) => {
            state.expenses_list.forEach((item) => {
                if (item.checkbox == true) {
                    // convertDateFormat(action.payload.maturity)
                    item.date = convertDateFormat(action.payload.maturity);
                }
            })
        },
        toggleCheckbox: (state, action) => {
            const { index } = action.payload;
            state.expenses_list[index].checkbox = !state.expenses_list[index].checkbox;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(all_suppliers.pending, (state) => {
                state.status = STATUSES.LOADING
            })
            .addCase(all_suppliers.fulfilled, (state, action) => {
                state.supplierList = action.payload.data
                state.status = STATUSES.SUCEESS
            })
            .addCase(all_suppliers.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
    },
});

export const { Show_New_expenses, Add_new_expenses, Edit_new_expense, ChangeDate, toggleCheckbox } = toBePaidSlice.actions
export default toBePaidSlice.reducer

// GET CATEGORY 
export const all_suppliers = createAsyncThunk(
    'get_category',
    async () => {
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(config.customerV2 + "/v2/contract/allSuppliers", {
            method: "POST",
            headers: {
                //'x-auth-token': gettoken.Token,
                'x-auth-token'    : token,
                'Content-type'    : 'application/json',
                'connection-name' : connectionName
            },
            body: JSON.stringify({
                "start": 0,
                "end": 10,
                "supplier": ""
            })
        })
        const data = await response.json();
        return data
    }
)


