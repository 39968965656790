import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal, Form, Button, Row, Col, FloatingLabel, Accordion } from 'react-bootstrap';
import { modal_reducer } from "../../../../redux/slice/notificationSlice";
import { handleSubmitPopup } from './notification';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
// used it to show validation error on form
const validationSchema = Yup.object().shape({
    ST_TITULO_MOH: Yup.string().trim().nullable()
        .required('Title is required'),
});

const ModelNotify = ({ dispatch, stateData }) => {
    const { show } = stateData; // All State data is here
    let setTimeData: any = "";
    const [desc, setDesc] = useState<any>("");
    const [perviewdata, setPerviewData] = useState(false);
    let descriptionData = desc;
    const [userid, setUserId] = useState();
    const [variableData, setVariableData] = useState<any>({});
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation()
    // The useForm hook is used to handle form registration, submission, and validation.
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await customerService.getTemplates();
                const variableResult = response.data.data[1];
                setVariableData(variableResult);
                // const propertyVariableResult = response.data.data[2];
                // const ownerVariableResult = response.data.data[3];
                // const tenantVariableResult = response.data.data[4];
                // setOwnerVariableData(ownerVariableResult)
                // setTenantVariableData(tenantVariableResult)

                // setContractVariableData(variableResult)
                // setPropertyVariableData(propertyVariableResult);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [])


    const handleClose = () => {
        // Reset border color
        let BorderClr: any = document.querySelector(".ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline");
        BorderClr.style.border = "1px solid #ccced1";
        // Reset attributes of preDefinemodal
        let preDefinemodal: any = document.querySelector("#CurrentPage");
        preDefinemodal.setAttribute("data-page", "")
        preDefinemodal.setAttribute("st_nomo_moh", "")
        // Reset replaceData innerHTML
        let replaceData: any = document.querySelector(".ForEditTemplate");
        replaceData["innerHTML"] = "Edit template";
        // Dispatch modal_reducer action to close the modal
        dispatch(modal_reducer(false));
        clearTimeout(setTimeData);
        setDesc("");
        reset();
        descriptionData = "";
    }
    // Set a timeout to delay the execution of the code
    useEffect(()=> {
        setTimeData = setTimeout(() => {
            // Check if the element with id "CurrentPage" exists
            if (document.querySelectorAll("#CurrentPage").length > 0) {
                let preDefinemodal: any = document.querySelector("#CurrentPage");
                // Get the actual modal name from the data attribute
                let actualModalName = preDefinemodal.getAttribute("data-page");
                // Check if the actual modal name is not empty
                if (actualModalName != "") {
                    let GetObject: any = ""
                    // Check if the element with class "ForEditTemplate" exists
                    if (document.querySelectorAll(".ForEditTemplate").length > 0) {
                        let replaceData: any = document.querySelector(".ForEditTemplate");
                        // Update the innerHTML of the replaceData element with translated strings
                        replaceData["innerHTML"] = `${t("notifications.editTemplate")}  ${t("notifications.for")}  ${t("notifications." + actualModalName)}`;
                        // Set attribute and translated strings based on the actual modal name
                        if (actualModalName == "welcome") {
                            preDefinemodal.setAttribute("st_nomo_moh", "Seja bem vindo")
                        } else if (actualModalName == "birthday") {
                            preDefinemodal.setAttribute("st_nomo_moh", "Feliz aniversário")
                        }
                        // Handle different modal names and update attribute and translated strings accordingly
                        if (actualModalName.includes("contract")) {
                            replaceData["innerHTML"] = `${t("notifications.editTemplate")}  ${t("notifications.for")}  ${t("notifications.contract")}`;
                            // currentpage?.setAttribute("data-page", "contract");
                            if (actualModalName.includes("tenent")) {
                                // 17
                                if (actualModalName.includes("before")) {
                                    preDefinemodal.setAttribute("st_nomo_moh", "Seu contrato de locação vence em breve")
                                } else {
                                    preDefinemodal.setAttribute("st_nomo_moh", "Seu contrato de locação venceu")
                                }
                            } else {
                                // 15
                                if (actualModalName.includes("before")) {
                                    preDefinemodal.setAttribute("st_nomo_moh", "Seu contrato de locação vence em breve")
                                } else {
                                    preDefinemodal.setAttribute("st_nomo_moh", "Seu contrato de locação venceu")
                                }
                            }
                        } else if (actualModalName.includes('insurance')) {
                            replaceData["innerHTML"] = `${t("notifications.editTemplate")}  ${t("notifications.for")}  ${t("notifications.insurance")}`;
                            // Set the notification message for insurance
                            if (actualModalName.includes("before")) {
                                preDefinemodal.setAttribute("st_nomo_moh", "O seguro do seu contrato de locação vence em breve")
                            } else {
                                preDefinemodal.setAttribute("st_nomo_moh", "O seguro do seu contrato de locação venceu")
                            }
                            // currentpage?.setAttribute("data-page", "insurance");
                        } else if (actualModalName.includes('gurantees')) {
                            replaceData["innerHTML"] = `${t("notifications.editTemplate")}  ${t("notifications.for")}  ${t("notifications.gurantees")}`;
                            // Set the notification message for guarantees
                            if (actualModalName.includes("before")) {
                                preDefinemodal.setAttribute("st_nomo_moh", "A garantia do seu contrato de locação vence em breve")
                            } else {
                                preDefinemodal.setAttribute("st_nomo_moh", "A garantia do seu contrato de locação venceu")
                            }
                            // currentpage?.setAttribute("data-page", "gurantees");
                        }
                    }
    
    
                    if (actualModalName.includes("contract") || actualModalName.includes("insurance") || actualModalName.includes("gurantees")) {
                        // Check if the actualModalName includes "contract", "insurance", or "gurantees"
                        let splitvalue = actualModalName.split('-');
                        if (actualModalName.includes("contract")) {
                            // If actualModalName includes "contract", access the corresponding object
                            GetObject = stateData[`${splitvalue[0]}`][`${splitvalue[1]}`][`${splitvalue[2]}`]["popdata"];
                        } else {
                            // If actualModalName includes "insurance" or "gurantees", access the corresponding object
                            GetObject = stateData[`${splitvalue[0]}`][`${splitvalue[1]}`]["popdata"];
                        }
                    } else {
                        // If actualModalName doesn't match any of the conditions above, access the corresponding object
                        GetObject = stateData[`${actualModalName}`]["popdata"];
                    }
                    let description: any = "";
                    // Check if the type of ST_TEXTO_MOH is "Buffer"
                    if (GetObject?.ST_TEXTO_MOH?.type == "Buffer") {
                        let data = GetObject["ST_TEXTO_MOH"]["data"];
                        let utf8decoder = new TextDecoder();
                        let u8arr = new Uint8Array(data);
                        let changeIntoString = utf8decoder.decode(u8arr);
                        description = changeIntoString;
                    } else {
                        description = GetObject?.ST_TEXTO_MOH;
                    }
    
                    let fields: string[] = Object.keys(GetObject);
                    fields.forEach((field: any) => setValue(field, GetObject[field]));
                    setDesc(description);
                }
            }
    
        }, 120)
    },[document.querySelectorAll("#CurrentPage")])


    const onSubmit = (data: any) => {
        // Check if descriptionData is not empty
        if (descriptionData.trim() != "") {
            // Get the CurrentPage element and assign additional properties to data
            let nomo_moh: any = document.querySelector('#CurrentPage')
            data["ST_TEXTO_MOH"] = descriptionData;
            data["ST_LABEL_MOH"] = "notificacoescontratuais";
            data["ID_EMPRESA_EMP"] = userid;
            data["ST_NOME_MOH"] = nomo_moh.getAttribute("st_nomo_moh")
            // Handle form submission
            handleSubmitPopup(data);
            // Reset form fields and descriptionData
            setDesc("");
            reset();
            descriptionData = "";
        } else {
            setTimeout(() => {
                // Add border color to the CKEditor element if descriptionData is empty
                let BorderClr: any = document.querySelector(".ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline");
                BorderClr.style.border = "1px solid red";
            }, 80)

        }
    }
    // Function to set preview data to true
    const Preview = () => {
        setPerviewData(true);
    }
    // Function to set preview data to false
    const closePreivew = () => {
        setPerviewData(false);
    }


    const PreviewData = () => {
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title className='ForEditTemplate'>{t("notifications.previewTemplate")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("notifications.companyLogo")}<br /> <br /> <br /><br />

                    {t("formLabel.title")}: {getValues("title")} <br /> <br />

                    {t("formLabel.identifier")}: {getValues("identifier")} <br /> <br />

                    {t("formLabel.caption")}: {getValues("caption")} <br /> <br />

                    {t("formLabel.variables")}: {getValues("variables")} <br /> <br />

                    {t("formLabel.description")} :  {desc.replace(/(<([^>]+)>)/ig, '')} <br /> <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" onClick={closePreivew}>{t("button.close")} {t("notifications.preview")}</Button>
                </Modal.Footer>
            </Form>
        )
    }

    const handleCKEditorChange = async (e) => {
        const selectedVariable = e.target.value;
        const editor = document.querySelector('.ck-editor__editable');
        if (editor) {
            const currentContent = editor.innerHTML;
            const appendedContent = `${currentContent} %${selectedVariable}%`;
            editor.innerHTML = appendedContent;
            // setDesc(editor.innerHTML);
        }
    }

    const EditTemplate = () => {
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title className='ForEditTemplate'>{t("notifications.editTemplate")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-lg-4 gy-3">
                        <Col md={6}>
                            <FloatingLabel className="mb-lg-4 mb-3" controlId="title" label={t("formLabel.title")}>
                                <Form.Control type="text" placeholder="Title" className={`${errors?.ST_TITULO_MOH ? 'is-invalid' : ''}`}  {...register('ST_TITULO_MOH')} />
                            </FloatingLabel>
                        </Col>

                        <Col md={6}>
                            <FloatingLabel className="mb-lg-4 mb-3" controlId="identifier" label={t("formLabel.identifier")}>
                                <Form.Control type="text" placeholder="Identifier" className={`${errors?.ST_IDENTIFICADOR_MOH ? 'is-invalid' : ''}`}  {...register('ST_IDENTIFICADOR_MOH')} />
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel className="mb-lg-4 mb-3" controlId="caption" label={t("formLabel.caption")}>
                                <Form.Control type="text" placeholder="Caption" className={`${errors.ST_SUBTITULO_MOH ? 'is-invalid' : ''}`}  {...register('ST_SUBTITULO_MOH')} />
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel controlId="variables" label={t("formLabel.variables")}>
                                <Form.Select
                                    aria-label="Variables"
                                    {...register('variable')}
                                    onChange={(e) => handleCKEditorChange(e)}
                                >

                                    <option value="">{t('dropdownItem.select')}</option>
                                    {variableData && Object.keys(variableData).length > 0
                                        ? Object.keys(variableData).map(key => (
                                            <option key={key} value={variableData[key]}>
                                                {key}
                                            </option>
                                        ))
                                        : <option disabled>{t("dimob.NoDataFound")}</option>
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <Col md={12}>
                        <Form.Control type="hidden" placeholder="description" className={`${errors.ST_TEXTO_MOH ? 'is-invalid' : ''}`}  {...register('ST_TEXTO_MOH')} />
                        <CKEditor
                            editor={ClassicEditor}
                            data={desc}
                            onReady={editor => {
                                const data = editor.getData();
                                descriptionData = data;
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                descriptionData = data;
                            }}
                            onBlur={(event, editor) => {
                                const data = editor.getData();
                                descriptionData = data;
                            }}
                            onFocus={(event, editor) => {
                                const data = editor.getData();
                                descriptionData = data;
                            }}
                        />
                    </Col>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>{t("button.close")}</Button>
                    <Button variant="secondary" onClick={Preview} className='text-capitalize'>{t("notifications.preview")}</Button>
                    <Button type="submit" variant="primary" >{t("button.toSave")}</Button>
                </Modal.Footer>
            </Form>
        )
    }

    return (
        <Modal show={show} onHide={perviewdata ? closePreivew : handleClose} dialogClassName="modalnotify">
            {
                (perviewdata) ? <PreviewData /> : <EditTemplate />
            }
        </Modal>
    )
}

export default ModelNotify