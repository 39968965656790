import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Dropdown,
  Col,
  Row,
  Button,
  Nav,
  ButtonGroup,
} from "react-bootstrap";
import {
  nonPaymentCharge,
  nonPaymentChargeAgreement,
  nonPaymentSetting,
  nonPaymentSummery,
} from "../../../../redux/slice/NonPaymentSlice";
import { incomeService } from "../../../../services/incomeServices";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Profile/Company/Loader";
import NonPaymentListing from "./NonPaymentListing";
import { useTranslation } from "react-i18next";
import Pagination from "../../../Pagination";
import CommonHeader from "./CommonHeader";
import MoreFilters from "./MoreFilters";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Overview from "./Overview";
import moment from "moment-timezone";
import { getAllLunchIndexs_based_on_Name } from "../../../../redux/slice/expenditureDataSlice";
import { encrypt_Decrypt, encrypt_Decrypt_Plan } from "../../../constants/utils";
let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");

const NonPayment = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [firstLoad, setfirstLoad] = useState(true);
  const {
    nonpaymentsummery_get,
    nonPaymentChargeData,
    nonPaymentSetting_Data,
  } = useSelector((state: RootState) => state.nonPayment);
  const { billingData } = useSelector(
    (state: RootState) => state.expenditureData
  );

  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).subtract(1, "M").format("MMM/YYYY");
  let searchDt = moment(newDate).format("MMM/YYYY");
  let endDt = moment(newDate).add(1, "M").format("MMM/YYYY");

  //More filters template modal close open function
  const [showMoreFiltersModal, setMoreFiltersModal] = useState<any>(false);
  const moreFiltersModalClose = () => setMoreFiltersModal(false);
  const moreFiltersModalShow = () => setMoreFiltersModal(true);

  const [userPlanString, setUserPlanString] = useState("");
  const [userJson, setUserJson] = useState<any>([]);

  useEffect(() => {
    let userPLAN = encrypt_Decrypt_Plan();
    //  setUserPlanString(userPLAN)
    setUserPlanString("300")
  }, [location])

  useEffect(() => {
    let userResult = encrypt_Decrypt();
    setUserJson(userResult);
  }, [])

  useEffect(() => {
    dispatch(nonPaymentSummery());
    checkmonth(state.endDate);
    dispatch(nonPaymentSetting({}));
  }, []);

  const [conditionNonpayment, setconditionNonpayment] = useState<any>({
    non_payment: "defaulter", // by default (defaulter) and another (agreements)
    showNext: true,
  });

  const [state, setState] = useState<any>({
    FL_STATUS_RECB: 0,
    filter: {},
    filteSearchDate: moment(searchDt).format("YYYY-MM-DD"),
    filterEndDate: moment(new Date(dateBrazil))
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    search_type: "",
    start: 0,
    end: 20,
    startDate: startDt,
    searchDate: searchDt,
    endDate: endDt,
    sorting: "RECEB.DT_VENCIMENTO_RECB DESC",
    type: "defaulter",
  });

  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = state.end;
  const [total, setTotal] = useState(0);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * state.end;
    const lastPageIndex = firstPageIndex + state.end;
    let newarr = nonPaymentChargeData["arrayData"].slice(
      firstPageIndex,
      lastPageIndex
    );
    return newarr;
  }, [currentPage, nonPaymentChargeData]);

  const [loading, setLoading] = useState(false);
  const handleChangeSort = (sortType: any) => {
    setCurrentPage(1);
    setState({
      ...state,
      ["sorting"]: sortType,
    });
  };

  let getNonPaymentSummery = async () => {
    setLoading(true);
    if (state.type == "defaulter") {
      let dataouptu: any = await dispatch(nonPaymentCharge(state));
      if (dataouptu) {
        setLoading(false);
      }
    } else {
      let dataouptu: any = await dispatch(nonPaymentChargeAgreement(state));
      if (dataouptu) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    state["start"] = (currentPage - 1) * state["end"];
    getNonPaymentSummery();
  }, [state, dispatch]);

  const [nonpaymentsummery, setnonpaymentsummery] = useState<any>({
    nonpaymentsummery: {
      totalamount: "00",
      "1to5": "0,00",
      "6to15": "0,00",
      "16to30": "0,00",
      "31to60": "0,00",
      "61to120": "0,00",
      total_contract: "0",
    },
  });

  useEffect(() => {
    setnonpaymentsummery({
      ...nonpaymentsummery,
      ["nonpaymentsummery"]: nonpaymentsummery_get[0],
    });
  }, [nonpaymentsummery_get]);

  const filterCharge = (dt, tp) => {
    setfirstLoad(false);
    if (typeof tp !== "undefined") {
      let currentDate = new Date(dt);
      if (tp == "Subtract") {
        startDt = moment(currentDate).subtract(1, "M").format("MMM/YYYY");
        searchDt = moment(currentDate).format("MMM/YYYY");
        endDt = moment(currentDate).add(1, "M").format("MMM/YYYY");
      } else {
        startDt = moment(currentDate).subtract(1, "M").format("MMM/YYYY");
        searchDt = moment(currentDate).format("MMM/YYYY");
        endDt = moment(currentDate).add(1, "M").format("MMM/YYYY");
      }

      checkmonth(endDt);
      setCurrentPage(1);
      setState({
        ...state,
        ["startDate"]: startDt,
        ["searchDate"]: searchDt,
        ["endDate"]: endDt,
      });
    }
  };

  let setConditionValueDatea = (value: boolean) => {
    setconditionNonpayment({
      ...conditionNonpayment,
      ["showNext"]: value,
    });
  };

  let checkmonth = (endDate: any) => {
    let currentDateYear: any = moment(new Date(dateBrazil)).year();
    let searchDateYear: any = moment(endDate).year();

    let currentDateMonth: any = moment(new Date(dateBrazil)).month();
    let searchDateMonth: any = moment(endDate).month();

    if (Number(currentDateYear) < Number(searchDateYear)) {
      setConditionValueDatea(false);
    } else {
      if (Number(currentDateYear) == Number(searchDateYear)) {
        if (Number(currentDateMonth) >= Number(searchDateMonth)) {
          state["filteSearchDate"] = moment(new Date(endDate))
            .subtract(1, "M")
            .startOf("month")
            .format("YYYY-MM-DD");
          state["filterEndDate"] = moment(new Date(endDate))
            .subtract(1, "M")
            .endOf("month")
            .format("YYYY-MM-DD");
          setConditionValueDatea(true);
        } else {
          if (!firstLoad) {
            state["filteSearchDate"] = moment(new Date(dateBrazil))
              .startOf("month")
              .format("YYYY-MM-DD");
            state["filterEndDate"] = moment(new Date(dateBrazil))
              .subtract(1, "days")
              .format("YYYY-MM-DD");
          }
          if (state["type"] != "defaulter") {
            state["filteSearchDate"] = moment(new Date(endDate))
              .subtract(1, "M")
              .startOf("month")
              .format("YYYY-MM-DD");
            state["filterEndDate"] = moment(new Date(endDate))
              .subtract(1, "M")
              .endOf("month")
              .format("YYYY-MM-DD");
            setConditionValueDatea(true);
          } else {
            setConditionValueDatea(false);
          }
        }
      } else {
        state["filteSearchDate"] = moment(new Date(endDate))
          .subtract(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        state["filterEndDate"] = moment(new Date(endDate))
          .subtract(1, "M")
          .endOf("month")
          .format("YYYY-MM-DD");
        setConditionValueDatea(true);
      }
    }
  };

  const handleTabChange = (e: any, type: any) => {
    e.preventDefault();
    setconditionNonpayment({
      ...conditionNonpayment,
      ["non_payment"]: type,
    });

    setState({
      ...state,
      filteSearchDate: moment(searchDt).format("YYYY-MM-DD"),
      filterEndDate: moment(new Date(dateBrazil))
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      startDate: startDt,
      searchDate: searchDt,
      filter: {},
      endDate: endDt,
      ["type"]: type,
    });

    setLoading(true);
    let parentLink: any = document.querySelector(`.${type}`);
    parentLink?.classList?.add("active");
    if (type == "defaulter") {
      type = "agreements";
    } else {
      type = "defaulter";
    }
    let parentLink1: any = document.querySelector(`.${type}`);
    parentLink1?.classList?.remove("active");
  };

  useEffect(() => {
    checkmonth(state.endDate);
  }, [state]);

  let typingTimer: any = "";
  const searchFilter = (searchValue: any) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      setState({
        ...state,
        ["search_type"]: searchValue,
      });
    }, 800);
  };

  const handleFilter = (e: any, data: any, type: string) => {
    switch (type) {
      case "status":
        if (data == "1.1") {
          data = `"1.1", "1.2"`;
        }
        if (data == `2.1`) {
          data = `"2.1", "2.2", "2.3"`;
        }
        if (data == `3.1`) {
          data = `"3.1", "3.2", "3.3", "3.4"`;
        }
        setState({
          ...state,
          [`filter`]: {
            ...state.filter,
            ["status"]: data,
          },
        });
        break;
      default:
        if (type && type != "" && type !== "reset") {
          setState({
            ...state,
            [`filter`]: {
              ...state.filter,
              [`${type}`]: data,
            },
          });
        } else {
          setState({
            ...state,
            [`filter`]: {},
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0) {
      let ResultData = nonPaymentSetting_Data[0];
      if (
        ResultData &&
        ResultData?.["SL_MONETARY_UPDATE_NON"] &&
        ResultData?.["SL_MONETARY_UPDATE_NON"].length > 0
      ) {
        dispatch(
          getAllLunchIndexs_based_on_Name({
            collectionName: ResultData["SL_MONETARY_UPDATE_NON"],
            month: 36,
          })
        );
      }
    }
  }, [nonPaymentSetting_Data]);

  return (
    <section className="content-wrapper content-wrapper-primary">
      {/*<!-- Breadcrumb section start from here -->*/}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
        <CommonHeader
          searchFilter={searchFilter}
          getNonPaymentSummery={getNonPaymentSummery}
        />
        {/* Filters start here */}
        <Form className="w-100 mt-lg-0 mt-2" action="" method="">
          <Row className="gx-2 gy-1 gx-xxl-3">
            <Col xs="auto">
              <Nav
                variant="pills"
                className="flex-row nav-pills-border border rounded-pill"
              >
                <Nav.Item>
                  {/* to={"/non_payment"} */}
                  <Nav.Link className="active defaulter">
                    <Link
                      className="text-reset"
                      to={""}
                      onClick={(e) => handleTabChange(e, "defaulter")}
                    >
                      {t("NonPayment.defaulters")}
                    </Link>
                  </Nav.Link>
                </Nav.Item>
                {
                  userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" &&
                  userJson && userJson.length > 0 &&
                  (userJson.indexOf(3050) !== -1) &&
                  <Nav.Item>
                    {/* to={"/non_payment_agreements"} */}
                    <Nav.Link
                      className="agreements"
                      disabled={userPlanString == "100" ? true : false}
                    >
                      <Link
                        className="text-reset"
                        to={"#"}
                        onClick={(e) => handleTabChange(e, "agreements")}
                      >
                        {t("Links.agreements")}
                      </Link>
                    </Nav.Link>
                  </Nav.Item>
                }

              </Nav>
            </Col>
            <Col xs="auto" className="border-end border-xs-none pe-xxl-3">
              {/* <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                </Button>
                                <Button variant="primary" className="btn-date" disabled>
                                    {t(`half_month.${(state.searchDate).split("/")[0]}`) + "/" + (state.searchDate).split("/")[1]}
                                </Button>
                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')} disabled={!conditionNonpayment.showNext}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                </Button>
                            </ButtonGroup> */}
            </Col>

            <Col xs="auto" className="ps-xxl-3">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="light"
                  size="sm"
                  className={`${state?.["filter"]?.["status"] &&
                    state?.["filter"]?.["status"] !== ""
                    ? "show"
                    : ""
                    }`}
                >
                  {t("dropdownItem.status")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdwon-lg px-4 custom-checkbox mh-336 overflow-auto">
                  {conditionNonpayment["non_payment"] === "defaulter" ? (
                    <>
                      <label htmlFor="cc1" className="d-block">
                        <input type="radio" name="status" id="cc1" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "1", "status")}
                        >
                          {t("NonPayment.scheduledContact")}
                        </div>
                      </label>
                      <label htmlFor="cc2" className="d-block">
                        <input type="radio" name="status" id="cc2" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "null", "status")}
                        >
                          {t("NonPayment.noActionTake")}
                        </div>
                      </label>
                      <label htmlFor="cc3" className="d-block">
                        <input type="radio" name="status" id="cc3" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "3.1", "status")}
                        >
                          {t("NonPayment.contactnotMade")}
                        </div>
                      </label>
                      <label htmlFor="cc4" className="d-block">
                        <input type="radio" name="status" id="cc4" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "2.1", "status")}
                        >
                          {t("NonPayment.inconclusiveContact")}
                        </div>
                      </label>
                      <label htmlFor="cc5" className="d-block">
                        <input type="radio" name="status" id="cc5" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "1.1", "status")}
                        >
                          {t("NonPayment.effectiveContact")}
                        </div>
                      </label>
                      {/* <label htmlFor="cc6" className="d-block">
                                                    <input type="radio" name="status" id="cc6" />
                                                    <div className="chechbox-inner py-2 cursor-pe" onClick={(e) => handleFilter(e, "", "status")}>{t("NonPayment.manualNotification")}</div>
                                                </label>
                                                <label htmlFor="cc7" className="d-block">
                                                    <input type="radio" name="status" id="cc7" />
                                                    <div className="chechbox-inner py-2 cursor-pe" onClick={(e) => handleFilter(e, "", "status")}>{t("NonPayment.automaticNotification")}</div>
                                                </label> */}
                    </>
                  ) : (
                    <>
                      <label htmlFor="cc1" className="d-block">
                        <input type="radio" name="status" id="cc1" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "", "status")}
                        >
                          {t("All")}
                        </div>
                      </label>
                      <label htmlFor="cc2" className="d-block">
                        <input type="radio" name="status" id="cc2" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "0", "status")}
                        >
                          {t("Unpaid")}
                        </div>
                      </label>
                      <label htmlFor="cc3" className="d-block">
                        <input type="radio" name="status" id="cc3" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) => handleFilter(e, "1", "status")}
                        >
                          {t("Paid")}
                        </div>
                      </label>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            {conditionNonpayment["non_payment"] === "defaulter" && (
              <>
                {/* <Col xs="auto">
                                    <Dropdown autoClose="outside">
                                        <Dropdown.Toggle variant="light" size="sm">
                                            {t("NonPayment.individualLegalEntity")}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdwon-lg px-4 custom-radio mh-336 overflow-auto">
                                            <label htmlFor="cc8" className="d-block">
                                                <input type="radio" name="legal_entity" id="cc8" />
                                                <div className="chechbox-inner py-2 cursor-pe" onClick={(e) => handleFilter(e, "both", "person")}>{t("NonPayment.individualLegalEntity")}</div>
                                            </label>
                                            <label htmlFor="cc9" className="d-block">
                                                <input type="radio" name="legal_entity" id="cc9" />
                                                <div className="chechbox-inner py-2 cursor-pe" onClick={(e) => handleFilter(e, "physical", "person")}>{t("NonPayment.individualsOnly")}</div>
                                            </label>
                                            <label htmlFor="cc10" className="d-block">
                                                <input type="radio" name="legal_entity" id="cc10" />
                                                <div className="chechbox-inner py-2 cursor-pe" onClick={(e) => handleFilter(e, "legal", "person")}>{t("NonPayment.legalEntitiesOnly")}</div>
                                            </label>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col> */}
                <Col xs="auto">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={`${state?.["filter"]?.["days"] &&
                        state?.["filter"]?.["days"] !== ""
                        ? "show"
                        : ""
                        }`}
                    >
                      {t("NonPayment.delayTime")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdwon-lg px-4 custom-radio mh-336 overflow-auto">
                      <label htmlFor="cc11" className="d-block">
                        <input type="radio" name="delay_time" id="cc11" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) =>
                            handleFilter(
                              1,
                              "DATEDIFF(CURRENT_DATE, RECEB.DT_VENCIMENTO_RECB) BETWEEN 1 AND 5",
                              "days"
                            )
                          }
                        >
                          1 {t("to")} 5 {t("NonPayment.days")}
                        </div>
                      </label>
                      <label htmlFor="cc12" className="d-block">
                        <input type="radio" name="delay_time" id="cc12" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) =>
                            handleFilter(
                              2,
                              "DATEDIFF(CURRENT_DATE, RECEB.DT_VENCIMENTO_RECB) BETWEEN 6 AND 15",
                              "days"
                            )
                          }
                        >
                          6 {t("to")} 15 {t("NonPayment.days")}
                        </div>
                      </label>
                      <label htmlFor="cc13" className="d-block">
                        <input type="radio" name="delay_time" id="cc13" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) =>
                            handleFilter(
                              3,
                              "DATEDIFF(CURRENT_DATE, RECEB.DT_VENCIMENTO_RECB) BETWEEN 16 AND 30",
                              "days"
                            )
                          }
                        >
                          16 {t("to")} 30 {t("NonPayment.days")}
                        </div>
                      </label>
                      <label htmlFor="cc14" className="d-block">
                        <input type="radio" name="delay_time" id="cc14" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) =>
                            handleFilter(
                              4,
                              "DATEDIFF(CURRENT_DATE, RECEB.DT_VENCIMENTO_RECB) BETWEEN 31 AND 60",
                              "days"
                            )
                          }
                        >
                          31 {t("to")} 60 {t("NonPayment.days")}
                        </div>
                      </label>
                      <label htmlFor="cc15" className="d-block">
                        <input type="radio" name="delay_time" id="cc15" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) =>
                            handleFilter(
                              5,
                              "DATEDIFF(CURRENT_DATE, RECEB.DT_VENCIMENTO_RECB) BETWEEN 61 AND 120",
                              "days"
                            )
                          }
                        >
                          61 {t("to")} 120 {t("NonPayment.days")}
                        </div>
                      </label>
                      <label htmlFor="cc16" className="d-block">
                        <input type="radio" name="delay_time" id="cc16" />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          onClick={(e) =>
                            handleFilter(
                              6,
                              "DATEDIFF(CURRENT_DATE, RECEB.DT_VENCIMENTO_RECB) >= 121",
                              "days"
                            )
                          }
                        >
                          120 {t("NonPayment.days")} {t("NonPayment.or")}{" "}
                          {t("more")}
                        </div>
                      </label>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </>
            )}
            <Col xs="auto">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="light"
                  size="sm"
                  className={`${state?.["filter"]?.["paymentmethod"] &&
                    state?.["filter"]?.["paymentmethod"] !== ""
                    ? "show"
                    : ""
                    }`}
                >
                  {t("bankTransaction.Paymentmethod")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdwon-lg px-4 custom-radio mh-336 overflow-auto">
                  {billingData.data &&
                    billingData.data.length > 0 &&
                    billingData.data.map((item: any, index: number) => (
                      <label
                        htmlFor={`cc1${7 + index}`}
                        className="d-block"
                        key={index}
                      >
                        <input
                          type="radio"
                          name="payment_method"
                          id={`cc1${7 + index}`}
                        />
                        <div
                          className="chechbox-inner py-2 cursor-pe"
                          data-value={item.id}
                          onClick={(e) =>
                            handleFilter(e, item.id, "paymentmethod")
                          }
                        >
                          {item.name}
                        </div>
                      </label>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {conditionNonpayment["non_payment"] === "defaulter" && (
              <>
                <Col xs="auto">
                  <Dropdown autoClose="outside" className="moreFilter">
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={`d-flex align-items-center ${(state?.["filter"]?.["channel"] &&
                        state?.["filter"]?.["channel"] !== "") ||
                        (state?.["filter"]?.["channel"] &&
                          state?.["filter"]?.["reason"] !== "")
                        ? "show"
                        : ""
                        }`}
                      onClick={moreFiltersModalShow}
                    >
                      {t("moreFilters")}
                      <img
                        src={`assets/images/icons/${(state?.["filter"]?.["channel"] &&
                          state?.["filter"]?.["channel"] !== "") ||
                          (state?.["filter"]?.["channel"] &&
                            state?.["filter"]?.["reason"] !== "")
                          ? "icons8-plus-24.png"
                          : "plus-light.svg"
                          }`}
                        alt="Plus icon"
                        className="ms-xl-1 ms-auto h-20"
                      />
                    </Dropdown.Toggle>
                  </Dropdown>
                  {/* <Button variant="light" size="sm" onClick={moreFiltersModalShow} >
                                        {t("moreFilters")} <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-1 h-20" />
                                    </Button> */}
                </Col>
              </>
            )}
            {state?.["filter"]?.["days"] == "0" ||
              (state?.["filter"] && Object.keys(state?.["filter"]).length > 0) ? (
              <Col xl="auto">
                <Dropdown autoClose="outside" className="moreFilter">
                  <Dropdown.Toggle
                    variant="light"
                    size="sm"
                    className="d-flex align-items-center"
                    onClick={() => handleFilter("", "", "reset")}
                  >
                    {t("button.resetfilter")}
                  </Dropdown.Toggle>
                </Dropdown>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Form>
        {/* /Filters end */}
      </div>
      {/*<!-- /Breadcrumb section end -->*/}
      {/*<!-- Main content start from here -->*/}
      <main className="px-xl-5 px-md-4 px-3 pt-4 pb-xl-5 pb-md-4 pb-3 container-fluid">
        {conditionNonpayment["non_payment"] === "defaulter" && (
          <Overview summery={nonpaymentsummery["nonpaymentsummery"]} />
        )}
        <NonPaymentListing
          conditionNonpayment={conditionNonpayment}
          setconditionNonpayment={setconditionNonpayment}
          listData={nonPaymentChargeData}
          summery={nonpaymentsummery["nonpaymentsummery"]}
          handleChangeSort={handleChangeSort}
          loading={loading}
          setLoading={setLoading}
          setStateClear={setState}
          state={state}
          currentTableData={currentTableData}
        />

        {nonPaymentChargeData.arrayData &&
          nonPaymentChargeData.arrayData.length > 0 && (
            <div className="d-flex justify-content-center my-1">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={nonPaymentChargeData["totalData"]}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          )}
      </main>
      {/* modal start */}
      <MoreFilters
        showMoreFilters={showMoreFiltersModal}
        moreFiltersClose={moreFiltersModalClose}
        handleFilter={handleFilter}
      />
    </section>
  );
};

export default NonPayment;
