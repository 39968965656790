import { Col, Card, Button, Form, Modal, Row } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect } from 'react';
import { Owners_areaForm } from '../../../Interface';
import customerService from '../../../../services/customerService';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import '../../../../App.css';

const Owners_area = (Owners_areaForm) => {
    const [t] = useTranslation();
    const [show, setShow] = useState(false);
    const [photo, setPhoto] = useState("");
    const [id, setId] = useState("");
    const [data, setData] = useState<any[]>([]);
    const [file, setFile] = useState<any | null>(null);
    useEffect(() => { getImage(); }, []);

    /*-------Common modal function--------*/

    const settingShow = () => { setShow(true); }
    const settingClose = () => { setShow(false); reset(); }

    /*-------Upload image functionality--------*/

    let formdata = new FormData();
    const getHeightAndWidthFromDataUrl = async (dataURL: string) =>
        new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                formdata.append("width", `${img.width}`);
                formdata.append("height", `${img.height}`);
                resolve({
                    height: img.height,
                    width: img.width,
                });
            };
            img.src = dataURL;
        });
    const handleChange = async (e) => {
        let files = e.target.files;
        const fileLoaded = URL.createObjectURL(files[0]);
        const dimensions = await getHeightAndWidthFromDataUrl(fileLoaded);
        setFile(fileLoaded);
        formdata.append("id", id);
        formdata.append("image", files[0]);
        formdata.append("user_id", "1");
        formdata.append("img_type", "owner");
        formdata.append("photo", photo);
        customerService.updateImageOwner(formdata).then(res => {
            /*-------Check the api response--------*/
            if (res.data.resCode === 202 || res.data.resCode === 200) {
                getImage();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        });
    }

    /*-------Get the Owners area background image--------*/

    const getImage = () => {
        let img = { img_type: "owner", user_id: 1 }
        customerService.getImage(img).then(res => {
            if (res.data.resCode === 201) {
                setId(res.data.data[0].id);
                setData(res.data.data);
                setPhoto(res.data.data[0].img_name);
            }
            else {
                setData([]);
            }
        }).catch(err => {
            console.log("err", err)
            setData([]);
        });
    }

    /*-------Setting Form data--------*/

    const { register, handleSubmit, reset, formState: { errors } } = useForm<Owners_areaForm>();
    const settingSubmit = (data: Owners_areaForm) => {
        //console.log("setting Form", JSON.stringify(data))
    }

    const handleEdit = (e, data) => {
        if (data == "edit") {
            document.querySelectorAll('.owner_area input[type="checkbox"]').forEach((item) => {
                item.removeAttribute('disabled')
            })

            document.querySelector('.Edit_btn')?.setAttribute("hidden", "");
            document.querySelector('.Update_btn')?.removeAttribute("hidden");

        } else {
            document.querySelectorAll('.owner_area input[type="checkbox"]').forEach((item) => {
                item.setAttribute('disabled', "")
            })

            document.querySelector('.Edit_btn')?.removeAttribute("hidden");
            document.querySelector('.Update_btn')?.setAttribute("hidden", "");
        }

    }


    return (<section className="content-wrapper">
        {/*<!-- Breadcrumb section start from here -->*/}
        <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
            <div className="w-100">
            <Row className="gx-xl-5 justify-content-between align-items-center">
            <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                    <div className="d-flex align-items-center justify-content-start">
                        <Link to={"/owners_and_beneficiaries"}>
                            <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                        </Link>
                        <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-xl-1">
                            <h1 className="mb-0 h4 lh-base text-truncate">{t("formLabel.ownerArea")}</h1>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end mb-xl-0 col-xl mb-2">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item mb-md-0 mb-1">
                            <Link to={"#"} className="fw-bold">
                                <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                {t("customerAndEmployees.Seehowitturnedout")}
                            </Link>
                        </li>
                        {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1" hidden>
                            <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                        </li> */}
                        {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1" hidden>
                            <img src="./assets/images/icons/settings-light.svg" />
                        </li> */}

                        {/* <li className="list-inline-item mb-md-0 mb-1">
                            <Link to={"#"} className="fw-bold">
                                <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                            </Link>
                        </li> */}
                    </ul>
                </Col>
            </Row>
            </div>
        </div>
        {/*<!-- /Breadcrumb section end -->*/}
        {/*<!-- Main content start from here -->*/}
        <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
            <Row className="h-100 gy-3 gy-lg-4">
                <Col lg={8}>
                    <Card>
                        <Card.Header>
                            <div className="d-flex align-items-center justify-content-between">
                                <span>{t("formLabel.settings")}</span>
                                <Button variant="link" className="p-0 Edit_btn" onClick={(e) => handleEdit(e, "edit")}>{t("button.edit")}</Button>
                                <Button variant="link" className="p-0 Update_btn" onClick={(e) => handleEdit(e, "update")} hidden>{t("button.update")}</Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <span>{t("customerAndEmployees.Ownersareaamenites")}</span>
                            <hr />
                            <Form className='owner_area'>
                                <div className="form-check mb-3 mb-lg-4 ps-0">
                                    <Form.Group controlId="formBasicCheckbox_1">
                                        <Form.Check type="checkbox" label={t("customerAndEmployees.Disableonlinetransferextracts")} disabled />
                                    </Form.Group>
                                </div>
                                <div className="form-check mb-3 mb-lg-4 ps-0">
                                    <Form.Group controlId="formBasicCheckbox_2">
                                        <Form.Check type="checkbox" label={t("customerAndEmployees.Disablefilesonline")} disabled />
                                    </Form.Group>
                                </div>
                                <div className="form-check mb-3 mb-lg-4 ps-0">
                                    <Form.Group controlId="formBasicCheckbox_3">
                                        <Form.Check type="checkbox" label={t("customerAndEmployees.Disableonlinerealestate")} disabled />
                                    </Form.Group>
                                </div>
                                <div className="form-check mb-3 mb-lg-4 ps-0">
                                    <Form.Group controlId="formBasicCheckbox_4">
                                        <Form.Check type="checkbox" label={t("customerAndEmployees.DeactivateextractforIRdeclaration")} disabled />
                                    </Form.Group>
                                </div>
                                <div className="form-check ps-0">
                                    <Form.Group controlId="formBasicCheckbox_5">
                                        <Form.Check type="checkbox" label={t("customerAndEmployees.Deactivateonlineregistrationupdate")} disabled defaultChecked />
                                    </Form.Group>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4} >
                    <Col sm={12}>
                        <Card>
                            <Card.Header>{t("customerAndEmployees.Backgroundimage")}</Card.Header>
                            <Card.Body className="text-center">
                                <div className="w-100 d-block">
                                    {
                                        file ?
                                            <img alt="img" src={file} width="350" />
                                            :
                                            data.length > 0
                                                ?
                                                <img alt="img" src={data[0].img_url} width="350" />
                                                :
                                                <svg id="Group_5" data-name="Group 5" xmlns="http://www.w3.org/2000/svg" width="156" height="128" viewBox="0 0 156 128">
                                                    <g id="Group_6" data-name="Group 6">
                                                        <rect id="Rectangle" width="156" height="128" rx="12" fill="#f82e52" />
                                                        <g id="Path_2" data-name="Path 2" transform="translate(46 30)">
                                                            <path id="Path" d="M9,52.938c2.043-2.009,4.086-4.019,6.128-6.058q16.786-16.736,33.6-33.531c1.865-1.86,3.7-3.689,5.566-5.519a4.256,4.256,0,0,1,5.24-.84,4.386,4.386,0,0,1,.74,6.9Q50.285,23.83,40.322,33.773c-.533.54-1.1,1.05-1.717,1.68.415.45.711.87,1.066,1.2L63,59.926a5.4,5.4,0,0,1,1.983,3.359,4.2,4.2,0,0,1-2.4,4.319,4.1,4.1,0,0,1-4.707-.87q-11.99-12.1-24.01-24.234c-.355-.36-.74-.69-1.184-1.14-1.3,1.29-2.546,2.489-3.789,3.719Q19.125,54.8,9.385,64.515c-.77.75-1.51,1.53-2.28,2.249a3.971,3.971,0,0,1-4.5.87A3.905,3.905,0,0,1,0,63.705V39.561Q0,22.106.03,4.68C.03,1.741,1.747-.059,4.5,0A4.089,4.089,0,0,1,8.408,3.3,11.966,11.966,0,0,1,8.615,5.61V52.788A1.476,1.476,0,0,0,9,52.938Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                    }
                                    <div className="mt-4 d-flex justify-content-center">
                                            <input type="file" id='logoUploadfiledd' className="form-control p-4" onChange={handleChange} hidden />
                                            <label htmlFor='logoUploadfiledd' title='Upload Company Logo' className='fw-bold cursor-pe text-primary' id='imglabel'>
                                                {t("customerAndEmployees.Uploadnew")}
                                            </label>
                                    </div>
                                </div>
                                {/*<Button className="btn btn-light btn-sm mt-3">Change the image</Button>*/}

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} className="mt-lg-4 mt-3">
                        <Card>
                            <Card.Header>{t("customerAndEmployees.Whatshouldthebackgroundimagelooklike")}?</Card.Header>
                            <Card.Body>
                                <img alt="img" src="assets/images/bg_area.jpg" width="390" height="250" />
                            </Card.Body>
                        </Card>
                    </Col>
                </Col>
            </Row>
        </main>
        {/* <!-- /Main content start end --> */}
        {/*---------Owners area setting form modal---------*/}
        <Modal show={show} onHide={settingClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("formLabel.settings")}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(settingSubmit)}>
            <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" {...register('email_update_chart_account')} label={t("customerAndEmployees.Emailadministratorswhenupdatingchartofaccounts")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" {...register('enable_daily_cashier')} label={t("customerAndEmployees.Enabledailycashier")} />
                    </Form.Group>
                    <span className="d-block mb-3 fw-bold">Taxation</span>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" {...register('active_taxes_simple_national')} label={t("customerAndEmployees.Activateoptiontoselecttheinci")} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-prinary" onClick={settingClose}>
                        {t("button.close")}
                    </Button>
                    <Button type="submit" variant="primary" name="save&send" >
                        {t("button.toSave")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </section>
    )
}
export default Owners_area;