import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { gettoken } from "../../Api/Contact";
import axios from "axios";
let companyV2_URL = require("../../Api/Contact").config.companyV2_URL;
let notificationsV2URL = require("../../Api/Contact").config.notificationsV2URL;
let connectionName: any = "";
if (localStorage.getItem("userData")) {
  connectionName = localStorage.getItem("userData");
}
let initialState: any = {
  welcome: { basic_data: {}, popdata: {} },
  birthday: { basic_data: {}, popdata: {} },
  contract: {
    basic_data: {},
    tenant: {
      before: { popdata: {} },
      after: { popdata: {} },
    },
    owner: {
      before: { popdata: {} },
      after: { popdata: {} },
    },
  },
  insurance: {
    basic_data: {},
    before: { popdata: {} },
    after: { popdata: {} },
  },
  gurantees: {
    basic_data: {},
    before: { popdata: {} },
    after: { popdata: {} },
  },
  guaranto: { basic_data: {}, popdata: {} },
  all_notification: {},
  show: false,
};

const NotificationSlice = createSlice({
  name: "Notification",
  initialState,
  reducers: {
    modal_reducer: (state, action) => {
      state.show = action.payload;
    },
    welcome_reducer: (state, { payload }) => {
      let { page, type, data } = payload;
      onBasicChange(state, page, data, type);
    },
    birthday_reducer: (state, { payload }) => {
      let { page, type, data } = payload;
      onBasicChange(state, page, data, type);
    },
    contract_reducer: (state, { payload }) => {
      let { page, type, data } = payload;
      onBasicChange(state, page, data, type);
    },
    insurance_reducer: (state, { payload }) => {
      let { page, type, data } = payload;
      onBasicChange(state, page, data, type);
    },
    gurantees_reducer: (state, { payload }) => {
      let { page, type, data } = payload;
      onBasicChange(state, page, data, type);
    },
    guaranto_reducer: (state, { payload }) => {
      let { page, type, data } = payload;
      onBasicChange(state, page, data, type);
    },
  },
  extraReducers: (builder) => {
    //Getting all services
    builder
      .addCase(fetchNotification.pending, (state, { payload }) => {
        state["loading"] = true;
      })
      .addCase(fetchNotification.fulfilled, (state, { payload }) => {
        state["loading"] = false;

        payload.forEach((item) => {
          if (item.ST_NOME_MOH == "Seja bem vindo") {
            state.welcome.popdata = item;
          }
          if (item.ST_NOME_MOH == "Feliz aniversário") {
            state.birthday.popdata = item;
          }
          if (item.ST_NOME_MOH == "Seu contrato de locação vence em breve") {
            state.contract.tenant.before.popdata = item;
          }
          if (item.ST_NOME_MOH == "Seu contrato de locação venceu") {
            state.contract.tenant.after.popdata = item;
          }
          if (item.ST_NOME_MOH == "Seu contrato de locação vence em breve") {
            state.contract.owner.before.popdata = item;
          }
          if (item.ST_NOME_MOH == "Seu contrato de locação venceu") {
            state.contract.owner.after.popdata = item;
          }
          if (
            item.ST_NOME_MOH ==
            "O seguro do seu contrato de locação vence em breve"
          ) {
            state.insurance.before.popdata = item;
          }
          if (
            item.ST_NOME_MOH == "O seguro do seu contrato de locação venceu"
          ) {
            state.insurance.after.popdata = item;
          }
          if (
            item.ST_NOME_MOH ==
            "A garantia do seu contrato de locação vence em breve"
          ) {
            state.gurantees.before.popdata = item;
          }
          if (
            item.ST_NOME_MOH == "A garantia do seu contrato de locação venceu"
          ) {
            state.gurantees.after.popdata = item;
          }
        });
      })
      .addCase(fetchNotification.rejected, (state, { payload }) => {
        state["loading"] = false;
      });

    builder
      .addCase(fetchNotifications.pending, (state, { payload }) => {
        state["loading"] = true;
        state.all_notification = {};
      })
      .addCase(fetchNotifications.fulfilled, (state, { payload }) => {
        state["loading"] = false;
        state.all_notification = payload[0]; 
      })
      .addCase(fetchNotifications.rejected, (state, { payload }) => {
        state["loading"] = false;
        state.all_notification = {};
      });
  },
});

export const {
  modal_reducer,
  welcome_reducer,
  birthday_reducer,
  contract_reducer,
  insurance_reducer,
  gurantees_reducer,
  guaranto_reducer,
} = NotificationSlice.actions;
export default NotificationSlice.reducer;

function onBasicChange(state: any, page: string, data: any, type: any) {
  //Update work is here
  let splitdata = page.split("-");
  if (splitdata.length === 3) {
    if (type === "popdata") {
      state[`${splitdata[0]}`][`${splitdata[1]}`][`${splitdata[2]}`][
        `${type}`
      ] = data;
    } else {
      state[`${splitdata[0]}`][`${type}`] = data;
    }
  } else if (splitdata.length === 2) {
    if (type === "popdata") {
      state[`${splitdata[0]}`][`${splitdata[1]}`][`${type}`] = data;
    } else {
      state[`${splitdata[0]}`][`${type}`] = data;
    }
  } else {
    state[`${page}`][`${type}`] = data;
  }
}

export const fetchNotification = createAsyncThunk(
  "notify/all_notification",
  async (_, thunkAPI) => {
    try {
      let user_id = gettoken.userId;
      let token:any = localStorage.getItem("tokenV2");
      const response = await axios({
        method: "post",
        url: `${companyV2_URL}/notify`,
        headers: {
          "x-auth-token": token,
          "Content-type": "application/json",
          "connection-name": connectionName,
        },
        data: { user_id },
      });

      let ResponseData = response?.data?.data;
      // id based modal
      //welcome id = 14
      //birthday id = 31
      //contract id =
      // 1) 15
      // 2) 16
      // 3) 17
      // 4) 18
      //insurance id =
      // 1) 23
      // 2) 24
      //gurantees id =
      // 1) 27
      // 2) 28

      return await ResponseData;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: "Error Occur" });
    }
  }
);
export const fetchNotifications = createAsyncThunk(
  "notify/fetch_all_notification",
  async (_, thunkAPI) => {
    try {
      let user_id = gettoken.userId;
      let token:any = localStorage.getItem("tokenV2");
      const response = await axios({
        method: "post",
        url: `${notificationsV2URL}/notifications/fetchNotifications`,
        headers: {
          "x-auth-token": token,
          "Content-type": "application/json",
          "connection-name": connectionName,
        },
        data: { user_id },
      });

      let ResponseData = response?.data?.data; 
      return await ResponseData;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: "Error Occur" });
    }
  }
);

export const insert_update_notifiy = createAsyncThunk(
  "notify/=insert_update_notification",
  async (postData: any, thunkAPI) => {
    try {
      let newdata = {
        user_id: gettoken.userId,
        post_data: postData,
      };
      let token:any = localStorage.getItem("tokenV2");
      const response = await axios({
        method: "put",
        url: `${companyV2_URL}/notify`,
        headers: {
          "x-auth-token": token,
          "Content-type": "application/json",
          "connection-name": connectionName,
        },
        data: newdata,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: "Error Occur" });
    }
  }
);

export const onCancelForm = createAsyncThunk(
  "notify/cancel_notification_update",
  async (postData: any, thunkAPI) => {
    try {
      return postData;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: "Error Occur" });
    }
  }
);
