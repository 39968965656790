import { Form,Modal,Badge,Button,Row,Col,InputGroup,Table,Accordion,FloatingLabel,Card,Dropdown,Collapse } from "react-bootstrap";
import React,{ useState,MouseEvent,useEffect,useMemo } from "react";
import { Outlet,Link,useNavigate,useParams }    from "react-router-dom";
import { encrypt_Decrypt,formatDateToDDMMYYYY } from "../../constants/utils";
import { getContractSearchData }  from "../../../redux/slice/contractDataSlice";
import { useForm }        from "react-hook-form";
import { yupResolver }    from "@hookform/resolvers/yup";
import * as Yup           from "yup";
import Loader             from "../../Profile/Company/Loader";
import Swal               from "sweetalert2";
import { useTranslation } from "react-i18next";
import Checkbox           from "../Contracts/Common/Checkbox";
import Pagination         from "../../Pagination";
import { ComProps,contractSearchForm } from "../../Interface";
import { AppDispatch,RootState }       from "../../../redux/store";
import { useDispatch,useSelector }     from "react-redux";
import "../../../App.css";
import moment from "moment-timezone";
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const ContractsSearch = (contractSearchForm) => {
  const { contractData,status } = useSelector((state: RootState) => state.contractData);
  const [userJson, setUserJson] = useState<any>([]);
  const navigate = useNavigate();
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || userResult.indexOf(2030) === -1) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
  }, [])
  const { id }   = useParams();
  const [t]      = useTranslation();
  const dispatch = useDispatch<AppDispatch>();  
  const [isCheckAll, setIsCheckAll]         = useState(false);
  const [isCheck, setIsCheck]               = useState<any[]>([]);
  const [totalAdminRate, setTotalAdminRate] = useState<any>(0); 
  const [state, setState] = useState({
    contract : id,
    start    : 0,
    end      : 20,
  });
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(contractData.data && contractData.data.length > 0 && contractData.data.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const noneHandleSelectAll = (e) => {
    setIsCheckAll(false);
    setIsCheck([]);
  };
  const handleClick = (e) => {
    const { id,checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
    }
    if (document.querySelectorAll(".check_Checkbox input").length > 0) {
      let data = document.querySelectorAll(".check_Checkbox input");
      let count_allCheck = 0;
      setTimeout(() => {
        data.forEach((item, index) => {
          if (item["checked"]) {
            count_allCheck++;
          }
          if (!item["checked"]) {
            setIsCheckAll(false);
          }
        });
        if (count_allCheck == data.length) {
          setIsCheckAll(true);
        }
      }, 10);
    }
  };  
  // Pagination start
  let PageSize                        = state.end;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpage, setTotalPage]     = useState(0);
  let id_arr: any = [];
  useEffect(() => {
    if (contractData?.["totalRow"] !== undefined) {
      let count = Number(contractData?.["totalRow"]);
      setTotalPage(count)
    }
  }, [contractData, currentPage]);
  useEffect(() => {
    state["start"] = (currentPage - 1) * state["end"];
    getContractsData(state);
  }, [currentPage, state]);
  useEffect(() => {
    if (contractData?.["data"]) {
      const keyToConvert = "admin_rate"; // Specify the key to convert to a number
      let total = 0;
      for (const item of contractData?.["data"]) {
        const value = parseFloat(item[keyToConvert]);
        if (!isNaN(value)) {
          total += value;
        }
      }
      // Format the total to have two decimal places
      const formattedTotal = total.toFixed(2);
      setTotalAdminRate(formattedTotal);
    }
  }, [contractData]);
  const getContractsData = async (contData) => {
    let response = await dispatch(getContractSearchData(contData));
  };
  
  return (
    <section className="content-wrapper">
      {/* <!-- Breadcrumb section start from here -->*/}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
        <div className="w-100">
          <Row className="gx-2 justify-content-between align-items-center">
            <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
              <Link to={"/dashboard"}>
                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
              </Link>
            </Col>
            <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
              <ul className="list-inline mb-0">
                {/*<li className="list-inline-item mb-md-0 mb-1">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                </li>*/}
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      {/* <!-- /Breadcrumb section end -->*/}
      {/* <!-- Main content start from here -->*/}
      <main className="container-fluid px-xl-5 px-md-4 px-3 pb-xl-5 pb-md-4 pb-3">
        <Card className="mt-lg-4 mt-3" id="listOftsxContracts">
          <Card.Header>{t("listOfContracts")}</Card.Header>
          <Card.Body className="pb-1">
            {/* <!-- Table start from here -->*/}
            {status == "loading" ? (
              <Loader />
            ) : (
              <Table
                responsive
                className="table table-custom table-custom-bordered mb-0"
              >
                <thead>
                  <tr>
                    <th className="w-24">
                      {contractData.data && contractData.data.length > 0 ? (
                        <Checkbox
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          handleClick={handleSelectAll}
                          isChecked={isCheckAll}
                        />
                      ) : (
                        ""
                      )}
                    </th>
                    <th>{t("maturity")}</th>
                    <th>{t("contract")}</th>
                    <th>{t("rent")} (R$)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {contractData?.["data"] && contractData?.["data"].length > 0 ? (
                    contractData?.["data"].map((item: any, index) => {
                      let todayDate = new Date(dateBrazil);
                      let maturityDate = new Date(
                        `${item?.endDate?.split("T")[0]}`
                      );
                      let Difference_In_Time =
                        maturityDate.getTime() - todayDate.getTime();
                      let Difference_In_Days: any =
                        Difference_In_Time / (1000 * 3600 * 24);
                      let MathSign = Math.sign(Difference_In_Days);
                      let dayAgo = "";

                      if (MathSign == -1) {
                        dayAgo = String(parseInt(Difference_In_Days)).replace(
                          "-",
                          ""
                        );
                        if (Number(dayAgo) > 30) {
                          if (Math.floor(Number(dayAgo) / 30) >= 12) {
                            let YearAgo = `${String(
                              Math.floor(Number(dayAgo) / 30 / 12)
                            )}`;
                            if (YearAgo === "1") {
                              dayAgo = `${YearAgo} year`;
                            } else {
                              dayAgo = `${YearAgo} years`;
                            }
                          } else {
                            let monthsAgo = `${String(
                              Math.floor(Number(dayAgo) / 30)
                            )}`;
                            if (monthsAgo === "1") {
                              dayAgo = `${monthsAgo} month`;
                            } else {
                              dayAgo = `${monthsAgo} months`;
                            }
                          }
                        } else {
                          if (dayAgo == "0") {
                            dayAgo = "Today";
                          } else if (dayAgo == "1") {
                            dayAgo = "Yesterday";
                          } else {
                            dayAgo = `${dayAgo} days`;
                          }
                        }
                      }
                      return (
                        <tr key={index}>
                          <td className={"check_Checkbox"}>
                            <Checkbox
                              key={index}
                              type="checkbox"
                              name={item.id}
                              id={item.id}
                              handleClick={handleClick}
                              isChecked={isCheck.includes(item.id)}
                            />
                          </td>
                          <td className="text-dark-70">
                            { item?.endDate }
                            {dayAgo !== "" ? (
                              <>
                                <span className="block"><Badge bg="danger-30">
                                  {" "}
                                  expired {dayAgo} ago
                                </Badge></span>
                              </>
                            ) : (
                              <>
                                {/* <span className="d-block">{upcomDay} remain</span> */}
                              </>
                            )}
                            {(item.suspended == 1) &&
                              <span className="block"><Badge className="bg-opacity-75" bg="dark">Suspended</Badge></span>
                            }
                          </td>
                          <td>
                            <span className="text-dark-70">
                              {item.property_identifier ? t("contract") + ' ' + item.property_identifier + "/" + item.lease_count : t("contract") + ' ' + ""}
                            </span>
                            <span className="mx-1">

                              <img src={ process.env.PUBLIC_URL +"/assets/images/icons/home_g.svg" } />
                              <span className="ps-1">
                                {item.type}  {item.address ? item.address + ',' : ''}{" "}
                                {item.city ? item.city + ',' : ''} {item.state ? item.state + ' -' : ''} {item.zipcode}
                              </span>
                            </span>
                            <span className="d-block my-1" title="Owner">
                              {item.owners && item.owners.length > 0 ? item.owners.map((items, indx) => (
                                <Link
                                  to={
                                    "/profile_details/" + items.ID_PESSOA_PES
                                  }
                                  className="text-secondary text-nowrap me-2"
                                  key={indx}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/user_g.svg"
                                    }
                                    className="me-1"
                                  />
                                  {items.ST_NOME_PES}
                                </Link>
                              ))
                                : ""}
                            </span>
                            <span className="d-flex flex-wrap" title="Tenant">
                              {item.tenates && item.tenates.length > 0
                                ? item.tenates.map((items, indx) => (
                                  <Link
                                    to={
                                      "/profile_details/" + items.ID_PESSOA_PES
                                    }
                                    className="text-secondary text-nowrap me-2"
                                    key={indx}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/key_g.svg"
                                      }
                                      className="me-1"
                                    />
                                    {items.ST_NOME_PES}
                                  </Link>
                                ))
                                : ""}
                            </span>
                          </td>
                          <td>
                            <span className="d-block pb-1 text-dark-70 text-nowrap">
                              R$ {item.rent_amount}
                            </span>
                            <span className="d-block text-nowrap">
                              {t("adminFee")} R$ {item.admin_rate}
                            </span>
                          </td>
                          <td className="text-end">
                            {
                              userJson && userJson.length > 0 && (userJson.indexOf(2030) !== -1) &&
                              <Link
                                to={"/contract_details/" + item.id}
                                className="fw-bold  me-2 me-xxl-4 me-lg-3 text-nowrap"
                              >
                                {t("button.seeContract")}
                              </Link>
                            }

                            {
                              userJson && userJson.length > 0 && (userJson.indexOf(2070) !== -1) &&
                              <Link
                                to={"/property_detail/" + item.propertyId}
                                className="fw-bold text-nowrap"
                              >
                                {t("button.seeProperty")}
                              </Link>
                            }
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <span>{t("formLabel.noRecordsFounds")}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
             </Card.Body>
        </Card>
            {
              contractData.totalRow ?
                <div className="d-flex justify-content-center mb-1 mt-lg-4 mt-3">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalpage}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                : ''
            }
             
            {/* <!-- /Table end -->*/}
          {t("formLabel.moreItems")}
            <p className="text-secondary d-block py-2">
              {t("formLabel.listing")}{" "}
              { contractData?.["totalRow"] && contractData?.["totalRow"] ? contractData?.["totalRow"] : '0' } {t("contracts")}.
            </p>
            {/* <!-- Nav listing start from here -->*/}
            
            {/*  <!-- /Nav listing  end -->*/}
        
        {/* <!-- /List of contracts end -->*/}
      </main>
    </section>
  );
};
export default ContractsSearch;