import React, { useState } from 'react';
import { Form, Modal, Button, FloatingLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { contractPauseForm } from "../../../../Interface";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { pauseContractClose,contractPause } from "../../../../../redux/slice/expenditureDataSlice";
import { contractDatails } from "../../../../../redux/slice/contractDataSlice";
const PauseContract = (props,contractPauseForm) => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [t] = useTranslation();
  const [double, setDouble] = useState(false);
  const { newPauseContract, typeOfContract } = useSelector((state: RootState) => state.expenditureData)
  const pauseValidation = Yup.object().shape({
    // comments: Yup.string().trim().nullable()
    //   .required(t('requiredMmessage.fieldRequired'))
  });
  let frm = {
    resolver: yupResolver(pauseValidation)
  }
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<contractPauseForm>(frm);
  const constractPauseSubmit = async (data: contractPauseForm, e: any) => {
    e.preventDefault();
    if(typeOfContract == "Active"){
      data["status"] = 1
    }else{
      data["status"] = 4
    }
    setDouble(true);
    let response = await dispatch(contractPause(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.Recordupdated"),
        confirmButtonText: "OK",
        // timer: 2000
      })
      pauseContractClosed();
      dispatch(contractDatails(Number(id)));
    } else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        // timer: 2000
      })
    }
  }
  const pauseContractClosed = ()=>{
    setDouble(false);
    reset(); 
    dispatch(pauseContractClose(false));
  }
  return (
    <Modal show={newPauseContract} onHide={pauseContractClosed}>
      <Form onSubmit={handleSubmit(constractPauseSubmit)}>
        <input type="hidden" {...register('id')} value={id} />
        <Modal.Header closeButton>
          <Modal.Title> { typeOfContract == "Active" ? t("activeContract") :t("contractlistother.Pausecontract")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="reason_pause" label={typeOfContract == "Active" ? t("contractlistother.Reasonforactive") : t("contractlistother.Reasonforpause")}>
            <Form.Control as="textarea" {...register('comments')} className={`form-control ${errors.comments ? 'is-invalid' : ''}`}  placeholder={t("contractlistother.Reasonforpause")} />
            <Form.Control.Feedback type="invalid">{errors.comments?.message}</Form.Control.Feedback>
          </FloatingLabel>
          <span className="mt-1 fs-12 ms-2">100 {t("contractlistother.charactersleft")}.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={pauseContractClosed}>
            {t("button.close")}
          </Button>
          <Button disabled={double} type="submit" variant="primary" >
            {typeOfContract == "Active" ? t("active") : t("contractlistother.Pause")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default PauseContract
