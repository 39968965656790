import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { getPropertyExpense, getPropertyCharge, getSinglePropertyCharge, chargeOpen, setPropertyChargeId, chargeClose, insertCharge } from "../../../../redux/slice/propertyDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { propertyForm } from '../../../Interface';
import { openErrorbox } from './actionPerform';
import Swal from 'sweetalert2';
import moment from "moment";
const ChargeService = (propertyForm) => {
  const { propertyChargeId, registryChargeShow } = useSelector((state: RootState) => state.propertyData)
  const { serviceData, suppliersData } = useSelector((state: RootState) => state.contractData)
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const [double, setDouble] = useState(false);
  const [hide, setHide] = useState(true);
  const [hideCredit, setHideCredit] = useState(true);
  const [payResp, setPayResp] = useState(false);
  const { id } = useParams();

  const chargeManage = (e) => {
    setPayResp(false)
    if (e.target.checked) {
      setPayResp(true)
    }
  }
  /*-------------Page onload function-----------*/

  useEffect(() => {
    setValue('debit', '1')
    getPropertyCharges(propertyChargeId, id)
  }, [dispatch, propertyChargeId])

  /*-------------Get the single propert charge info-----------*/

  const getPropertyCharges = async (propertyChargeId, id) => {
    if (!propertyChargeId && propertyChargeId != 0) {
      dispatch(chargeOpen(true))
    }
    if (propertyChargeId && propertyChargeId != 0) {
      const fields: string[] = ['amount', 'maturity', 'numberInstallments', 'id', 'propertyId', 'chargeCategory', 'chargeCode', 'chargeType', 'formPayment', 'fraction', 'service', 'debit', 'credit', 'supplier', 'calculateAdministration', 'chargeManaged', 'cashValue', 'responsiblePayment', 'comments']
      let data = await dispatch(getSinglePropertyCharge({ "id": Number(propertyChargeId), "propertyId": Number(id), "start": 0, "end": 10 }))

      if (data.payload.data.length > 0) {
        fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));
        if (data.payload.data[0]['credit'] == 4) {
          setHide(false);
        }
        handleExpensesChange(data.payload.data[0]['amount'], 'amount')
        handleExpensesChange(data.payload.data[0]['numberInstallments'], 'numberInstallments')
        handleExpensesChange(data.payload.data[0]['maturity'], 'maturity')

        dispatch(chargeOpen(true))
      }
    }
  }

  /*-------------Close the property charge popup-----------*/

  const newChargeClose = () => {
    dispatch(chargeClose(false));
    dispatch(setPropertyChargeId('0'));
    setDouble(false);
    setPortionData([]);
    reset();
  }

  /*-------------Onchange credit functionality-----------*/

  const creditSuppliers = (val) => {
    setValue('supplier', '');
    setHideCredit(true);
    if (val == 4) {
      setHide(false);
      setHideCredit(false);
    }
    else {
      setHide(true);
      setValue('cashValue', '');
      setValue('responsiblePayment', '');
      setValue('comments', '');
      setValue('chargeManaged', false);
      setValue('calculateAdministration', false);
      setPayResp(false);
    }
  }

  /*-------------Form validation-----------*/

  const form1 = {
    chargeCategory: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    chargeCode: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    chargeType: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    formPayment: Yup.number()
      .required(t('requiredMmessage.fieldRequired'))
      .positive()
      .typeError(t('requiredMmessage.Thevaluemustbeanumber'))
      .integer()
      .min(1, t('requiredMmessage.Pleaseenteravaluemorethan1'))
      .max(10, t('requiredMmessage.Pleaseenteravaluelessthan12')),
    amount: Yup.number()
      .required(t('requiredMmessage.fieldRequired'))
      .typeError(t('requiredMmessage.Thevaluemustbeanumber'))
      .integer(t('requiredMmessage.Thevaluemustbeanumber'))
      .min(2, "Please enter a value more than 2 digit"),
    service: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    debit: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    credit: Yup.string().trim().nullable()
      .notOneOf([Yup.ref('debit'), null], t("requiredMmessage.Doesnotmatchwithdebit"))
      .required(t('requiredMmessage.fieldRequired')),
    maturity: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
  }
  let form4 = { ...form1 };
  let form2 = {
    supplier: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))
  }
  let form3 = {
    responsiblePayment: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
  }
  if (!hide) {
    openErrorbox()
    if (payResp) {
      form4 = { ...form1, ...form2, ...form3 };
    }
    else {
      form4 = { ...form1, ...form2 };
    }
  }
  else {
    if (payResp) {
      form4 = { ...form1, ...form3 };
    }
  }

  const chargeValidation = Yup.object().shape(form4);
  let frm = {
    resolver: yupResolver(chargeValidation)
  }

  /*-------------Insert/Update the property charge data-----------*/

  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<propertyForm>(frm);
  const onSubmit = async (data: propertyForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    let response = await dispatch(insertCharge(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      dispatch(getPropertyCharge({ "id": '', "propertyId": Number(id), "start": 0, "end": 10 }))
      dispatch(getPropertyExpense({ "id": "", "propertyId": Number(id), "start": 0, "end": 10 }))
      newChargeClose();
    }
    else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.successfailed"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }

  const [PortionData, setPortionData] = useState([]);
  const handleExpensesChange = (e, type) => {
    let targetValue = "";
    if (typeof e.target != "undefined") {
      targetValue = e.target.value;
    }
    else {
      targetValue = e;
    }
    let arrayportion: any = [];
    let Value: any = "";
    if (type == "numberInstallments") {
      Value = targetValue;
    } else {
      if (getValues("numberInstallments")) {
        Value = Number(getValues("numberInstallments"));
      }
      if (type == "amount") {
        setValue("amount", targetValue);
      } else {
        setValue("maturity", targetValue);
      }
    }
    if (Value !== "" && !isNaN(Value)) {
      if (getValues('maturity') && getValues('amount')) {
        let currentDate = moment(getValues('maturity')).format('YYYY-MM-DD');
        [...Array(Number(Value))].forEach((item, index) => {
          arrayportion.push({
            maturity: currentDate,
            value: (Number(getValues("amount")) / Number(Value)).toFixed(2),
            complement: `Parcela ${index + 1} de ${Value}`
          })
          var futureMonth = moment(currentDate).add(1, 'months').calendar();
          let futureMonthnew = new Date(futureMonth);
          let yyyy: any = futureMonthnew.getFullYear();
          let mm: any = futureMonthnew.getMonth() + 1;
          let dd: any = futureMonthnew.getDate();
          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          let formattedToday = yyyy + "-" + mm + "-" + dd;
          currentDate = formattedToday
        })
      };
      if( arrayportion && arrayportion.length && arrayportion.length  == 1){
        arrayportion[0].complement = '';
      }
      setPortionData(arrayportion)
    } else {
      setPortionData([]);
    }
  }
  useEffect(() => {
    if (PortionData && PortionData.length > 0) {
      setTimeout(() => {
        PortionData.forEach((item, index) => {
          setValue(`data.install_${index}.maturity`, item?.["maturity"]);
          setValue(`data.install_${index}.value`, item?.["value"]);
          setValue(`data.install_${index}.complement`, item?.["complement"]);
        })
      }, 100)
    }
  }, [PortionData])

  /*-------------End-----------*/

  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={registryChargeShow} className="NewChargetsx" onHide={newChargeClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("contractlistother.RegisterTax")}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value={id} {...register('propertyId')} />
          <input type="hidden" {...register('id')} />
          <Modal.Body>
            <Row>
              <Col>
                <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput" label={t("maturity")+ " *"}>
                  <Form.Control type="date" placeholder={t("maturity")} className={`form-control ${errors.maturity ? 'is-invalid' : ''}`} {...register('maturity')} onChange={(e) => handleExpensesChange(e, "maturity")} />
                  <Form.Control.Feedback type="invalid">{errors.maturity?.message}</Form.Control.Feedback >
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="mb-3" label={t("contractlistother.Chargecategory")+ " *"}>
                  <Form.Select id="chargeCategory" aria-label="Charge category" className={`form-control ${errors.chargeCategory ? 'is-invalid' : ''}`}  {...register('chargeCategory')}>
                    <option value="">{t('dropdownItem.select')}..</option>
                    <option value="1">IPTU</option>
                    <option value="2">Garbage fee</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.chargeCategory?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="mb-3" label={t("contractlistother.Chargecode")+ " *"}>
                  <Form.Control id="chargeCode" type="text" placeholder="Charge code" className={`form-control ${errors.chargeCode ? 'is-invalid' : ''}`}  {...register('chargeCode')} />
                  <Form.Control.Feedback type="invalid">{errors.chargeCode?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel className="mb-3" label={t("contractlistother.Chargetype")+ " *"}>
                  <Form.Select id="chargeType" aria-label="Charge type" className={`form-control ${errors.chargeType ? 'is-invalid' : ''}`}  {...register('chargeType')}>
                    <option value="">{t('dropdownItem.select')}..</option>
                    <option value="1">Main</option>
                    <option value="2">Garage</option>
                    <option value="3">Additional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.chargeType?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="mb-3" label={t("contractlistother.Formofpayment")+ " *"}>
                  <Form.Select id="formPayment" aria-label="Form of payment" className={`form-control ${errors.formPayment ? 'is-invalid' : ''}`}  {...register('formPayment')}>
                    <option value="">{t('dropdownItem.select')}..</option>
                    <option value="1">In cash</option>
                    <option value="2">Installments</option>
                    <option value="4">Free</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.formPayment?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel className="mb-3" label={t("contractlistother.Amount")+ " *"}>
                  <Form.Control id="amount" type="text" placeholder={t("contractlistother.Amount")} className={`form-control ${errors.amount ? 'is-invalid' : ''}`}  {...register('amount')} onChange={(e) => handleExpensesChange(e, "amount")} />
                  <Form.Control.Feedback type="invalid">{errors.amount?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="mb-3" label={t("contractlistother.Numberofinstallments")}>
                  <Form.Control id="numberInstallments" type="text" placeholder={t("contractlistother.Numberofinstallments")} className={`form-control ${errors.numberInstallments ? 'is-invalid' : ''}`}  {...register('numberInstallments')} onChange={(e) => handleExpensesChange(e, "numberInstallments")} />
                  <Form.Control.Feedback type="invalid">{errors.numberInstallments?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel className="mb-3" label={t("contractlistother.Fraction") + "%"}>
                  <Form.Control id="fraction" type="text" placeholder="Fraction %" className={`form-control ${errors.fraction ? 'is-invalid' : ''}`}  {...register('fraction')} />
                  <Form.Control.Feedback type="invalid">{errors.fraction?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingInput" label={t("contractsettings.Service")+ " *"}>
                  <Form.Select className={`form-control ${errors.service ? 'is-invalid' : ''}`} {...register('service')} >
                    <option value="">{t('dropdownItem.select')}..</option>
                    {serviceData && serviceData.length > 0 &&
                      serviceData.map((item, index) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.service?.message}</Form.Control.Feedback >
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel className="mb-3" label={t("formLabel.debit")+ " *"}>
                  <Form.Select disabled id="debit" aria-label="Debit" className={`form-control ${errors.debit ? 'is-invalid' : ''}`}  {...register('debit')}>
                    <option value="">{t('dropdownItem.select')}..</option>
                    <option value="1">{t('notifications.owner')}</option>
                    <option value="2">{t('notifications.tenant')}</option>
                    <option value="3">{t('realEstate')}</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.debit?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel className="mb-3" label={t("formLabel.credit")+ " *"}>
                  <Form.Select id="credit" aria-label="Credit" className={`form-control ${errors.credit ? 'is-invalid' : ''}`}  {...register('credit')} onChange={(e) => creditSuppliers(e.target.value)}>
                    <option value="">{t('dropdownItem.select')}..</option>
                    <option value="1">{t('notifications.owner')}</option>
                    <option value="3">{t('realEstate')}</option>
                    <option value="4">{t('services.third')}</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.credit?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row style={{ display: hide ? 'none' : 'block' }}>
              <Col>
                <FloatingLabel controlId="floatingSelect" label={t("formLabel.Supplier")+ " *"}>
                  <Form.Select className={`form-control ${errors.supplier ? 'is-invalid' : ''}`} {...register('supplier')} aria-label="Supplier">
                    <option value="">{t('dropdownItem.select')}..</option>
                    {suppliersData && suppliersData.length > 0 &&
                      suppliersData.map((item, index) => (
                        <option key={item.id} value={item.favorecidoId}>{item.name}</option>
                      ))
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.supplier?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Accordion className="accordion-custom" >
              <Accordion.Item eventKey="1" >
                <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Check type="checkbox" label={t("services.calculateAdministration")} {...register('calculateAdministration')} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Check type="checkbox" label={t("contractlistother.Chargenotmanagedbytherealestate")} {...register('chargeManaged')} onChange={chargeManage} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FloatingLabel className="mb-3" label={t("contractlistother.Cashvalue")}>
                        <Form.Control id="cashValue" type="text" placeholder={t("contractlistother.Cashvalue")} className={`form-control ${errors.cashValue ? 'is-invalid' : ''}`}  {...register('cashValue')} />
                        <Form.Control.Feedback type="invalid">{errors.cashValue?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel className="mb-3" label={t("contractlistother.Responsibleforpayment") + " *"}>
                        <Form.Select id="responsiblePayment" aria-label="Responsible for payment" className={`form-control ${errors.responsiblePayment ? 'is-invalid' : ''}`}  {...register('responsiblePayment')}>
                          <option value="">{t('dropdownItem.select')}..</option>
                          <option value="1">{t('notifications.owner')}</option>
                          <option value="2">{t('notifications.tenant')}</option>
                          <option value="3">{t('realEstate')}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.responsiblePayment?.message}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FloatingLabel label={t("formLabel.comments")} >
                        <Form.Control as="textarea" id="comments" placeholder={t("formLabel.comments")} {...register('comments')} style={{ height: '100px' }} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {
              PortionData && PortionData.length > 0 &&
              <>
                <hr />
                <h4 className='mb-3'>{t("contractlistother.Launchcharge")}</h4>
              </>
            }
            {
              PortionData && PortionData.length > 0 && PortionData.map((item, index) => {
                return (
                  <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3" key={index}>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t("maturity")}>
                        <Form.Control type="date" placeholder={t("maturity")} className={`form-control`} {...register(`data.install_${index}.maturity`)} disabled />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t("value")}>
                        <Form.Control type="text" placeholder={t("value")} className={`form-control`} {...register(`data.install_${index}.value`)} disabled />
                      </FloatingLabel>
                    </Col>
                    <Col md={4}>
                      <FloatingLabel controlId="floatingInput" label={t("complement")}>
                        <Form.Control type="text" placeholder={t("complement")} className={`form-control`} {...register(`data.install_${index}.complement`)} disabled />
                      </FloatingLabel>
                    </Col>
                  </Row>
                )
              })
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={newChargeClose}>
              {t('button.close')}
            </Button>
            <Button type="submit" variant="primary" disabled={double} onClick={openErrorbox}>
              {t('button.toSave')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default ChargeService;