import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const SendEmailOverview = () => {
    const [t] = useTranslation();
    return (
        <>
            <Card className="mb-lg-4 mb-3">
                <Card.Header>{t("sendEmail.SummaryFrom")} 05/01/2023 t{t("to")} 05/30/2023</Card.Header>
                <Card.Body>
                    <Row className="gy-3 gx-xxl-5 gx-md-4">
                        <Col sm={4} className="border-end border-light border-xs-none">
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/arrow-clockwise-light.svg" alt="Bouncing emails" className="me-1 h-20" />2324</span>
                            <span className="text-secondary d-block">{t("sendEmail.BouncingEmails")}</span>
                        </Col>
                        <Col sm={4} className="border-end border-light border-xs-none">
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/circle-check-light.svg" alt="Sent emails" className="me-1 h-20" />1435</span>
                            <span className="text-secondary d-block">{t("sendEmail.SentEmails")}</span>
                        </Col>
                        <Col sm={4}>
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/send-light.svg" alt="Deliverability" className="me-1 h-20" />38.99%</span>
                            <span className="text-secondary d-block">{t("sendEmail.OfDeliverability")}</span>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default SendEmailOverview
