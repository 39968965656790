import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { condosClose, administratorOpen, propertyOpen, insertCondos } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getCondos, getAdministrators } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { condosForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import AdministratorService from '../Common/AdministratorService';
import { numericOnly, openErrorbox } from './actionPerform';
import customerService from '../../../../services/customerService';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';

const CondosService = (condosForm) => {
    const { administData, status } = useSelector((state: RootState) => state.propertyData)
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { newCondosShow } = useSelector((state: RootState) => state.contractData)
    const [allStates, setAllStates] = useState([]);
    useEffect(() => {
        if (newCondosShow) {
            (async () => {
                let resp = await customerService.getAddressBasedOnZipcode(`states`);
                if (resp.data.status == "success" || resp.data.type == "Success") {
                    let loop = resp.data.data;
                    let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                    // Sort the stateData alphabetically by label
                    stateData.sort((a, b) => a.label.localeCompare(b.label));
                    setAllStates(stateData);
                }
            })()
        }
    }, [newCondosShow]);
    const [hide1, setHide1] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [adminData, setAdminData] = useState<any[]>([])
    const step1 = () => { setHide1(true); setHide2(false); setHide3(false); }
    const step2 = (data: condosForm) => { setHide1(false); setHide2(true); setHide3(false); }
    const step3 = (data: condosForm) => { setHide1(false); setHide2(false); setHide3(true); }
    /*------------History modal hide function-----------*/
    const newCondosClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        dispatch(propertyOpen(true));
        dispatch(condosClose(false));
        setDouble(false);
        setHide1(true);
        setHide2(false);
        setHide3(false);
        reset();
    }

    /*------------Form validation-----------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const condosValidation1 = Yup.object().shape({
        // cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    const condosValidation2 = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
        // fantasy_name: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),

        // administrator: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // zipcode: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // address: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // number: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(phoneRegExp,t('requiredMmessage.fieldNotValid'))
        //     .min(10, t('requiredMmessage.fieldTooShort'))
        //     .max(10, t('requiredMmessage.fieldTooLong')),
        // email: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .email(t('requiredMmessage.fieldNotValid')),
        // neighborhood: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // city: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // state: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
 
    });

    let frm = {
        resolver: yupResolver(condosValidation1)
    }
    if (hide2) {
        frm = {
            resolver: yupResolver(condosValidation2)
        }
        openErrorbox();
    }
    /*------------Submit history form data-----------*/
    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<condosForm>(frm);
    const onSubmit = async (data: condosForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        let response = await dispatch(insertCondos(data));
        if (response?.payload?.type == "success" || response?.payload?.type == "Success" ) {
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.Recordinserted"),
                confirmButtonText: "OK"
                //timer : 2000
            })
            dispatch(getCondos())
            newCondosClose();
        }
        else if(response?.payload?.resCode == 409) {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.unqueEmails"),
                // icon: 'success',"error",
                confirmButtonText: "OK"
                // timer : 2000
            })
        }else{
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK"
                // timer : 2000
            })
        }
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone');
        }
    }
    const [btn2, setBtn2] = useState(false);
    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
        goodofZip: "",
    });
    useEffect(() => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (zipvalue.zip == "" && zipvalue.empzip == "" || zipvalue.goodofZip == "") {
            zipCodeCheck?.["removeAttribute"]('disabled');
        } else {
            zipCodeCheck?.["setAttribute"]('disabled', "")
        }
    }, [zipvalue])
    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        let querySelthird: any = ''
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="liquidator_zipcode"]');

        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str,
            })
        } else if (forwhich == "second") {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["goodofZip"]: str
            })
        }

        if (value != "") {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("address", data)
                    }
                    clearErrors("zipcode");
                    clearErrors("city");
                    clearErrors("neighborhood");
                    clearErrors("address")
                    clearErrors("state")
                } else if (forwhich == "second") {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("liquidator_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("liquidator_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("liquidator_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("liquidator_address", data)
                    }
                } 

            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("city", "")
                    setValue("neighborhood", "")
                    setValue("state", "")
                    setValue("address", "")
                    setError("zipcode", {
                        type: "manual",
                        message: `${t("requiredMessage.zipcodeError")}`
                      });

                } else {
                    querySelsecond.style.border = "2px solid red"
                    setValue("liquidator_city", "")
                    setValue("liquidator_neighborhood", "")
                    setValue("liquidator_state", "")
                    setValue("liquidator_address", "")
                    setError("liquidator_zipcode", {
                        type: "manual",
                        message: `${t("requiredMessage.zipcodeError")}`
                      });
                }
            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }
      useEffect(() => { getAllAdministrators(null) }, []);
      const getAllAdministrators = (administrator) => {
        let administratorData = { administrator: administrator, start: 0, end: 100 }
        customerService.getAllAdministrators(administratorData).then(res => {
            if (res.data.resCode === 201) {
                setAdminData(res.data.data);
            }
            else {
                setAdminData([]);
            }
        }).catch(err => {
            setAdminData([]);
        });
    }


    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value,  "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Modal show={newCondosShow} onHide={newCondosClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('property.newCondos')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body className="accordion-custom">
                        <Row data-step="1" style={{ display: hide1 ? "block" : "none" }}>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cnpj')}>
                                <Form.Control type="text" placeholder={t('formLabel.cnpj')} className={`form-control ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")} />
                                <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Row>
                        <Row data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                            <Row>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name') + " *"}>
                                    <Form.Control type="text" placeholder={t('formLabel.name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <InputGroup>
                                        <FloatingLabel label={t('formLabel.fantasyName')}>
                                            <Form.Control type="text" placeholder={t('formLabel.fantasyName')} className={`form-control ${errors.fantasy_name ? 'is-invalid' : ''}`} {...register('fantasy_name')} />
                                            <Form.Control.Feedback type="invalid">{errors.fantasy_name?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        {/*<InputGroup.Text onClick={newAdministratorOpen} title="condos">
                                    <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                </InputGroup.Text>*/}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.administrator')}>
                                        <Form.Select aria-label={t('formLabel.administrator')} className={`form-control ${errors.administrator ? 'is-invalid' : ''}`} {...register('administrator')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {adminData && adminData.length > 0 ? adminData.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )) :
                                                <option value="">{t('formLabel.noRecordsFounds')}</option>
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.administrator?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <span className="d-block mb-3 fw-bold">{t('formLabel.address')}</span>
                            <Row>
                                <FloatingLabel className="mb-3 validZipcode removeInsertDisable" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                    <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')}  onChange={(e) => getLocation(e, "zipcode", "first")} maxLength={9} minLength={9} />
                                    <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                        <Form.Control type="text" placeholder={t('formLabel.address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address')} />
                                        <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                        <Form.Control type="text" placeholder={t('formLabel.number')} className={`form-control ${errors.number ? 'is-invalid' : ''}`} {...register('number')} onChange={numericOnly} />
                                        <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                        <Form.Control type="text" placeholder={t('formLabel.complement')} className={`form-control ${errors.complement ? 'is-invalid' : ''}`} {...register('complement')} />
                                        <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`form-control ${errors.neighborhood ? 'is-invalid' : ''}`} {...register('neighborhood')} />
                                        <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                        <Form.Control type="text" placeholder={t('formLabel.city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`} {...register('city')} />
                                        <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                        <Form.Select aria-label={t('formLabel.state')} className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {allStates && allStates.length > 0 &&
                                                allStates.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                    )
                                                })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="1" >
                                    <Accordion.Header>{t('formLabel.contact')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('telephone')} onChange={HandleInput} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder={t('formLabel.email')} {...register('email')} />
                                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" >
                                    <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.billingMethod')}>
                                                    <Form.Select aria-label={t('formLabel.billingMethod')} {...register('billing_method')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        <option value="1">{t("expiredMonth")}</option>
                                                        <option value="2">{t("Monthtoexpire")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.condoDueDate')}>
                                                    <Form.Control type="date" placeholder={t('formLabel.condoDueDate')} {...register('due_date')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Row>
                        <Row data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                            <span className="d-block mb-3 fw-bold">{t('property.trustee')}</span>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                        <Form.Control type="text" placeholder={t('formLabel.name')} {...register('trustee_name')} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                        <Form.Control type="text" placeholder={t('RG')} {...register('trustee_rg')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                        <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('trustee_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('trustee_telephone')} onChange={HandleInput} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                        <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('trustee_mobile')} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                        <Form.Control type="email" placeholder={t('formLabel.email')} {...register('trustee_email')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="4" >
                                    <Accordion.Header>{t('formLabel.liquidatorAddress')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3 liquidator" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`form-control ${errors.liquidator_zipcode ? 'is-invalid' : ''}`}  {...register('liquidator_zipcode')} onChange={(e) => getLocation(e, "zipcode", "second")} maxLength={9} minLength={9}  />
                                                <Form.Control.Feedback type="invalid">{errors.liquidator_zipcode?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.address')} {...register('liquidator_address')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.number')} {...register('liquidator_number')} onChange={numericOnly} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')} {...register('liquidator_complement')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')} {...register('liquidator_neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')} {...register('liquidator_city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select aria-label={t('formLabel.state')} {...register('liquidator_state')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {allStates && allStates.length > 0 &&
                                                            allStates.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                )
                                                            })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>{t('formLabel.caretaker')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('caretaker_name')} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                    <Form.Control type="text" placeholder={t('RG')} {...register('caretaker_rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('caretaker_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6" >
                                    <Accordion.Header>{t('formLabel.caretakerContact')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                <Form.Control type="text" placeholder={t('formLabel.telephone')} {...register('caretaker_telephone')} onChange={HandleInput} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('caretaker_mobile')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="email" placeholder={t('formLabel.email')} {...register('caretaker_email')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7" >
                                    <Accordion.Header>{t('formLabel.attorney')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                <Form.Control type="text" placeholder={t('formLabel.name')} {...register('attorney_name')} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                    <Form.Control type="text" placeholder={t('RG')} {...register('attorney_rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('attorney_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8" >
                                    <Accordion.Header>{t('formLabel.lawyerContact')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                <Form.Control type="text" placeholder={t('formLabel.telephone')} {...register('lawyer_telephone')} onChange={HandleInput} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('lawyer_mobile')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="email" placeholder={t('formLabel.email')} {...register('lawyer_email')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-between w-100 align-items-center">
                            <div className="me-sm-2 mb-sm-0 mb-2 d-flex">
                                <Form.Group>
                                    <Form.Check type="checkbox" {...register('isAfter')} label={t("button.afterSaving")} />
                                </Form.Group>
                            </div>
                            <div className="flex-shrink-0">
                                <Button variant="outline-primary" onClick={newCondosClose}>
                                    {t('button.close')}
                                </Button>
                                <Button variant="outline-primary" className="js-btn-step" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)}  >
                                    {t('button.previous')}
                                </Button>
                                {!hide3 ?
                                    <Button disabled={btn2 ? true : false} variant="primary" className="min-w-auto js-btn-step zipCodeCheck" onClick={handleSubmit(hide1 ? step2 : step3)}  >
                                        {t('button.next')}
                                    </Button>
                                    :
                                    <Button  type="submit" variant="primary" onClick={openErrorbox}>
                                        {t('button.save')}
                                    </Button>
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <AdministratorService />
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default CondosService;