import React, { useEffect, useState } from "react";
import { IPerson } from "../../../../redux/slice/companyDataSlice";
import {
  registerData,
  updatePost,
  getComapnyLogo,
} from "../../../../redux/slice/companyDataSlice";
import { ComProps, company, Branch } from "../../../Interface";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { Footerofreports } from "./Footerofreports";
import { Card, Col, Row, Form, Button, FloatingLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "../Loader";
import Updatelogo from "./Updatelogo";
import { handleAction } from "../actions";
import customerService from "../../../../services/customerService";
import Swal from "sweetalert2";
import i18n from "../../../../i18n";
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from "../../../constants/utils";


const CompanyData = ({ submenu }: ComProps) => {
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>();
  // State to hold the selected value
  const [selectedVal, setSelectedVal] = useState();
  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation();
  // Define validation rules for the object
  const validationSchema = Yup.object().shape({
    // Field must be a non-empty string
    telephone: Yup.string().trim().nullable()
      .required(t("requiredMmessage.fieldRequired")),
    email: Yup.string().trim().nullable()
      .email("Email must be a valid email")
      .required(t("requiredMmessage.fieldRequired")),
    zipcode: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    address: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")).max(40, t("requiredMmessage.40characters")),
    number: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    neighborhood: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    city: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    statess: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
  });

  // Form-related hooks and functions for validation
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IPerson>({
    resolver: yupResolver(validationSchema),
  });

  const [days, setDays] = useState<any>([]);
  useEffect(() => {
    setDays([
      { value: 1, label: t("company.monday") },
      { value: 2, label: t("company.tuesday") },
      { value: 3, label: t("company.wednesday") },
      { value: 4, label: t("company.thursday") },
      { value: 5, label: t("company.friday") },
      { value: 6, label: t("company.saturday") },
      { value: 7, label: t("company.sunday") },
      { value: 8, label: t("company.mondayToFriday") },
    ])
  }, [i18n.language])
  // State to hold the days
  // Effect for selected value change
  useEffect(() => { }, [selectedVal]);
  // Retrieve company Data, and status from the Redux state
  const { companydata, status } = useSelector(
    (state: RootState) => state.companyData
  );
  // State for input fields
  const [inputFields, setInputFields] = useState({});
  const [opencount, setOpenCount] = useState(1);
  const [dayError, setDayError] = useState("");
  const [dasError, setDaysError] = useState("");
  const [untillError, setUntillError] = useState("");
  const [update, setUpdate] = useState(1);
  const [allStates, setAllStates] = useState([]);
  const [openCount1, setOpenCount1] = useState<any>(false)

  useEffect(() => {
    // Dispatch the registerData action
    dispatch(registerData());
  }, [dispatch, days]);

  useEffect(() => {
    const fields: string[] = [
      "fantasy_name",
      "company_name",
      "telephone",
      "email",
      "site",
      "zipcode",
      "number",
      "complement",
      "user_id",
      "city",
      "neighborhood",
      "address",
      "cnpj",
    ];


    fields.forEach((field: any) => setValue(field, companydata?.[0]?.[field]));

    // Set values for form fields
    if (companydata && companydata.length > 0) {
      let data: any = [];
      setValue("statess", companydata[0]?.["state"]);
      // Iterate through opening hours data
      if (companydata.length && companydata[0]['opening_hours'] != null && companydata[0]['opening_hours'].length > 0) {
        companydata[0]["opening_hours"].forEach((item, index) => {
          data.push({
            [`open_${index}`]: {
              day: item.day,
              das: item.das,
              untill: item.untill,
            },
          });
        });
      }
      let inputField = [];
      if (data.length > 0) {
        // Map data to input fields
        data.forEach(async (item, index) => {
          const _key = `${Object.keys(item)}`
          const { das, untill } = item[_key] ?? {}
          const start = Number(das.replace(':', ''))
          const end = Number(untill.replace(':', ''))

          inputField[_key] = {
            ...item[_key],
            ...(start < end ? {} : { das: untill, untill: das })
          };
        });
        setSelectedVal(data[0]["open_0"]?.day)
        if (data[0]["open_0"]?.day == 8) {
          setOpenCount1(true)
        } else {
          setOpenCount1(false)
        }
      }
      // Update open count and input fields state
      setOpenCount(data.length ? data.length : 1);
      setInputFields({ ...inputField });
    }
  }, [companydata]);

  const addInputField = (e: any) => {
    // Check if the count is less than 7
    if (opencount < 8) {
      // Increment the open count
      setOpenCount(opencount + 1);
      // Create a new item with dynamic property name
      let newitem = [
        {
          [`open_${opencount}`]: {
            day: "",
            das: "",
            untill: "",
          },
        },
      ];
      // Update the input fields with the new item
      if (newitem.length) {
        setInputFields({ ...inputFields, ...newitem[0] });
      }
      // Get the select elements and type cast them to HTMLSelectElement

      setTimeout(() => {
        let thisDivform: any =
          e.target.parentElement.parentElement.parentElement;
        let thisButton: any =
          e.target.parentElement.parentElement.parentElement;
        // Check if the form has "#editform_companydata" elements
        if (thisDivform.querySelectorAll(".opening_hourData").length) {
          thisDivform
            .querySelectorAll(".opening_hourData input")
            .forEach((item: any, index: number) => {
              item.removeAttribute("disabled");
            });
          // Enable select fields
          thisDivform
            .querySelectorAll(".opening_hourData select")
            .forEach((item: any, index: number) => {
              item.removeAttribute("disabled");
            });
        }
        // Get the select elements
        // Get the select elements and type cast them to HTMLSelectElement



        // Show delete buttons
        thisDivform
          .querySelectorAll(".delete")
          .forEach((item: any, index: number) => {
            item?.removeAttribute("hidden");
          });


      }, 200);
    }
  };


  const removeInputFields = (index) => {
    // Create a shallow copy of the inputFields object
    let dataall: any = [{ ...inputFields }];
    // Convert the values of the dataall[0] object into an array
    let newInputField: any = Object.values(dataall[0]);
    // Create an empty array to store the modified data
    let data: any = [];
    // Remove the element at the specified index from the newInputField array
    newInputField.splice(index, 1);
    // Iterate over the newInputField array to restructure the data
    newInputField.forEach(async (item, index) => {
      data.push({
        [`open_${index}`]: {
          day: item.day,
          das: item.das,
          untill: item.untill,
        },
      });
    });
    // Create an empty object to store the final input fields
    let inputField = [];
    if (data.length) {
      data.forEach(async (item, index) => {
        inputField[`${Object.keys(item)}`] = { ...item[`${Object.keys(item)}`] };
      });
    }
    setOpenCount(opencount - 1);
    setInputFields({ ...inputField });
    setOpenCount1(false)
  };

  const handleChange = (index, evnt, datadd) => {
    // Extract the 'name' and 'value' from the event target
    const { name, value } = evnt.target;
    if (datadd == "change") {
      setSelectedVal(value);
    }
    if (value == "8") {
      setOpenCount1(true)
    } else {
      setOpenCount1(false)
    }
    // Check if 'datadd' is set to "nochange"
    if (datadd == "nochange") {
      setDayError("");
      setDaysError("");
      setUntillError("");
    }
    // Create a copy of the data at the specified index
    let data = { ...inputFields[`open_${index}`] };
    // Update the input fields state
    setInputFields({
      ...inputFields,
      [`open_${index}`]: {
        ...data,
        [name]: value
      },
    });
  };

  const getLocation = async (e: any, inputType) => {
    let str = e.target.value
    let newStr = str.replace('-', ''); // Removes the hyphen
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
    let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
    // Error validation
    // Retrieve address based on zipcode using customerService
    let resp = await customerService.getAddressBasedOnZipcode(
      `${inputType}/${newStr}`
    );
    // Get the DOM element for the zipcode input
    let querySel: any = document.querySelector(
      '.removeInsertDisable [name="zipcode"]'
    );
    // Check if the value is not empty
    if (newValue !== "" || newValue !== null || newValue !== undefined) {
      // Check if the response status is success
      if (resp?.["data"]["type"] == "Success") {
        let res = resp["data"]["data"];
        querySel.style.border = "0px solid red";
        // Set the city value if available in the response
        if (res[0]["city"]) {
          setValue("city", res[0]["city"]);
        }
        if (res[0]["neigbohood"]) {
          setValue("neighborhood", res[0]["neigbohood"]);
        }
        if (res[0]["state"]) {
          setValue("statess", res[0]["state"])
        }
        if (res[0]["street"] || res[0]["streetType"]) {
          let data =
            (res[0]["streetType"] ? res[0]["streetType"] + " " : "") +
            (res[0]["street"] ?? "");
          setValue("address", data);
        }
        clearErrors("zipcode");
        clearErrors("city");
        clearErrors("neighborhood");
        clearErrors("address")
        clearErrors("statess")
      } else {
        // Add red border to the zipcode input
        setError("zipcode", {
          type: "manual",
          message: t("invalidzipcode"),
        });
        querySel.style.border = "2px solid red";
        // Reset city, neighborhood, and address values
        setValue("city", "");
        setValue("neighborhood", "");
        setValue("address", "");
        setValue("statess", "")
      }
    } else {
      // Remove red border from the zipcode input
      querySel.style.border = "0px solid red";
    }
  };

  useEffect(() => {
    (async () => {
      let resp = await customerService.getAddressBasedOnZipcode(`states`);
      if (resp.data.status == "success" || resp.data.type == "Success") {
        let loop = resp.data.data;
        let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
        // Sort the stateData alphabetically by label
        stateData.sort((a, b) => a.label.localeCompare(b.label));
        setAllStates(stateData);
      }
    })()
  }, [update]);

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    let i: number = 0;
    let open_hr_arr: any = [];
    let item: any = {};
    // Loop through the input fields and populate the open_hr_arr array
    for (i; i < opencount; i++) {
      item = inputFields[`open_${i}`];
      open_hr_arr.push(item);
    }
    data["opening_hours"] = open_hr_arr;
    data["state"] = data["statess"];
    // Iterate over each item in open_hr_arr
    Swal.fire({
      title: `${t('Sweetalert.Warning')}`,
      text: `${t('Sweetalert.updatefooter')}`,
      // icon: 'success',"question",
      showCancelButton: true,
      confirmButtonText: `${t('Sweetalert.changeIt')}`,
      cancelButtonText: `${t("button.cancel")}`
    }).then(async (result) => {
      if (result.isConfirmed == true) {
        let res = await dispatch(updatePost(data))
        if (res.payload.type == "Success") {
          Swal.fire({
            position: 'center',
            title: `${t('Sweetalert.Success')}`,
            text: `${t('infosuccesschange')}`,
            confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
            timer: 2000
          })
        } else {
          Swal.fire({
            title: `${t('Sweetalert.Error')}`,
            text: res.payload.message,
            confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
            timer: 2000
          })
        }
      } else {
        dispatch(registerData());
      }
    })
  };

  const handleEdit = async (e, data) => { };
  const HandleInput = (e) => {
    const inputValue = e.target.value;
    // Remove non-numeric characters from the input
    const numericInput = inputValue.replace(/\D/g, '');

    // Format the numeric input as per your requirements
    if (numericInput.length === 10) {
      // For a 10-digit number, format it as (12) 3456-7891
      const formattedNumber: any = `(${numericInput.slice(0, 2)}) ${numericInput.slice(2, 6)}-${numericInput.slice(6, 10)}`;
      setValue("telephone", formattedNumber);
      clearErrors("telephone");
    } else if (numericInput.length === 11) {
      // For an 11-digit number, format it as (12) 34567-8910
      const formattedNumber: any = `(${numericInput.slice(0, 2)}) ${numericInput.slice(2, 7)}-${numericInput.slice(7, 11)}`;
      setValue("telephone", formattedNumber);
      clearErrors("telephone");
    } else {
      // For other lengths, display the input as-is
      setError("telephone", {
        type: "manual",
        message: `${t("requiredMmessage.TelephoneError")}`,
      });
    }
  };

  const handleCancel = (e, cmd, dsm) => {
    // dispatch(registerData());
    dispatch(registerData());
  }


  const HandleInputZipcode = (e: any, inputType) => {
    // Input processing and formatting
    let value = e.target.value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})/);
    let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

    // Error validation
    if (newValue == "" || newValue == null || newValue == undefined) {
      // Set an error message using setError or update your error state
      setError("zipcode", {
        type: "manual",
        message: `${t("requiredMessage.zipcodeError")}`
      });
    } else {
      // Clear the zipcode error if the value is not empty
      clearErrors('zipcode');
    }
  }

  useEffect(() => {
    if (companydata && companydata.length) {
      setValue("statess", companydata[0]?.["state"]);
    }
  }, [allStates, companydata])

  const handleAddress = (e) => {
    let { value } = e.target;
    let numberOfCharacters = value.length;
    if (numberOfCharacters > 40) {
      setError("address", {
        type: "manual",
        message: t("requiredMmessage.40characters")
      });
    } else {
      clearErrors("address")
    }
  }
  return (
    <section className="content-wrapper" id="idOfYourDiv">
      {submenu}
      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
        <Row className="gy-3 gy-lg-4">
          {status == "loading" ? (
            <Loader />
          ) : (
            <Col lg={8}>
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <div>{t("company.registerData")} </div>
                  <span className="d-block TOEDIT">
                    <span>
                      <span>
                        <Button
                          variant="link"
                          className="p-0 fw-bold text-nowrap"
                          onClick={(e) =>
                            handleAction(e, "toedit", "registerData")
                          }
                        >
                          {t("button.edit")}
                        </Button>
                      </span>
                    </span>
                  </span>
                  <div className="d-none ACTION">
                    <Row>
                      <Col xl={6} sm={4}>
                        <Button
                          variant="link"
                          className="p-0 fw-bold text-nowrap"
                          onClick={(e) =>
                            handleCancel(e, "cancel", "registerData")
                          }
                          type="submit"
                        >
                          {t("button.cancel")}
                        </Button>
                      </Col>

                      <Col xl={4} sm={4}>
                        <Button
                          variant="link"
                          className="p-0 fw-bold text-nowrap"
                          onClick={(e) =>
                            handleAction(e, "update", "registerData")
                          }
                          type="submit"
                        >
                          {t("button.toSave")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card.Header>
                <Card.Body className='pb-0'>
                  <Form className="companyDataForm"
                    onSubmit={handleSubmit(onSubmit)}
                    id="editform_companypdata"
                  >
                    <Row className="mb-lg-4 mb-3">
                      <Col md={5}>
                        <FloatingLabel
                          controlId="fantasy_name"
                          label={t("formLabel.fantasyName")}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter Your Fantasy Name"
                            {...register("fantasy_name")}
                            disabled
                          />
                        </FloatingLabel>
                      </Col>
                      <div className="w-100"></div>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="fantasy_name"
                          label={t("formLabel.companyName")}
                          className="mb-lg-4 mb-3 "
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter Your company Name"
                            {...register("company_name")}
                            disabled
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                      <div className="w-100"></div>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="fantasy_name"
                          label={t("formLabel.cnpjCpf")}
                          className="mb-lg-4 mb-3 "
                        >
                          <Form.Control
                            type="text"
                            className={`${errors.fantasy_name ? "is-invalid" : ""
                              }`}
                            placeholder="Enter Your cnpj"
                            {...register("cnpj")}
                            disabled
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <span className="d-block mb-3 fw-bold">
                      {t("company.contact")}{" "}
                    </span>
                    <Row>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="telephone"
                          label={t("formLabel.telephone") + " *"}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="tel"
                            className={`${errors.telephone ? "is-invalid" : ""
                              }`}
                            placeholder="Enter Telephone Number"
                            {...register("telephone")}
                            onChange={HandleInput}
                            maxLength={15}
                            minLength={10}
                            disabled
                          />
                          <Form.Control.Feedback className="invalid-feedback">
                            {errors.telephone?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <div className="w-100"></div>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="email"
                          label={t("formLabel.email")}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="email"
                            className={`${errors.email ? "is-invalid" : ""}`}
                            placeholder="Enter Your Email"
                            {...register("email")}
                            disabled
                          />
                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                            {errors.email?.message}{" "}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <div className="w-100"></div>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="site"
                          label={t("formLabel.site")}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="text"
                            className={`${errors.site ? "is-invalid" : ""}`}
                            placeholder="Enter Your Site"
                            {...register("site")}
                            disabled
                          />
                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                            {errors.site?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <span className="d-block mb-3 fw-bold">
                      {t("company.address")}{" "}
                    </span>
                    <Row className="mt-1">
                      <Col md={5}>
                        <FloatingLabel
                          controlId="zipcode"
                          label={t("formLabel.zipCode") + " *"}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="tel"
                            className={`${errors.zipcode ? "is-invalid" : ""}`}
                            placeholder="Enter Zip Code"
                            {...register("zipcode")}
                            maxLength={9}
                            onChange={(e) => getLocation(e, "zipcode")}
                            disabled
                          />

                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                            {errors.zipcode?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="address"
                          label={t("formLabel.address") + " *"}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                          >
                            <Form.Control
                              type="text"
                              className={`${errors.address ? "is-invalid" : ""}`}
                              placeholder="Enter Address"
                              {...register("address")}
                              onChange={(e) => handleAddress(e)}
                              disabled
                            />
                          </OverlayTrigger>
                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                            {errors.address?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={2}>
                        <FloatingLabel
                          controlId="number"
                          label={t("formLabel.number") + " *"}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="number"
                            className={`${errors.number ? "is-invalid" : ""}`}
                            placeholder="Enter Number"
                            {...register("number")}
                            disabled
                          />
                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                            {errors.number?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="complement"
                          label={t("formLabel.complement")}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter Complement"
                            {...register("complement")}
                            disabled
                          />
                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="neighborhood"
                          label={t("formLabel.neighborhood") + " *"}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="text"
                            className={`${errors.neighborhood ? "is-invalid" : ""
                              }`}
                            placeholder="Cambui"
                            {...register("neighborhood")}
                            disabled
                          />
                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                            {errors.neighborhood?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={2}>
                        <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.state')}>
                          <Form.Select {...register('statess')} aria-label={t('formLabel.state')} disabled>
                            <option value="">{t('formLabel.select')}</option>
                            {allStates && allStates.length > 0 &&
                              allStates.map((item, index) => {
                                return (
                                  <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                )
                              })}
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={5}>
                        <FloatingLabel
                          controlId="city"
                          label={t("formLabel.city") + " *"}
                          className="mb-lg-4 mb-3 removeInsertDisable"
                        >
                          <Form.Control
                            type="text"
                            className={`${errors.city ? "is-invalid" : ""}`}
                            placeholder="Campinas"
                            {...register("city")}
                            disabled
                          />
                          <Form.Control.Feedback className="invalid-feedback">
                            {" "}
                            {errors.city?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>

                    </Row>
                    <span className="d-block fw-bold mb-3">
                      {t("company.openingHours")}{" "}
                    </span>

                    <div className="opening_hourData">
                      {opencount > 0 &&
                        [...Array(opencount)].map((x, index) => (
                          <Row
                            key={index}
                            className="gy-lg-4 gy-3 mb-lg-4 mb-3 d-flex openhr_data"
                            data={index + "__this is"}
                          >
                            <Col md={5} key={index}>
                              <FloatingLabel
                                controlId="day"
                                label={t("formLabel.day")}
                                className="removeInsertDisable"
                                key={index}
                              >
                                <Form.Select
                                  key={index}
                                  onChange={(evnt) =>
                                    handleChange(index, evnt, "change")
                                  }
                                  value={
                                    inputFields?.[`open_${index}`]?.["day"]
                                  }
                                  name="day"
                                  aria-label="Account type"
                                  disabled
                                >
                                  <option key={index} value="">{t("formLabel.select")}</option>
                                  {days.map((curElem: any, index) => {

                                    return (
                                      <option value={curElem.value}>
                                        {curElem.label}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </FloatingLabel>
                            </Col>

                            <Col md>
                              <FloatingLabel
                                controlId="das"
                                label={t("formLabel.das")}
                              >
                                <Form.Control
                                  type="time"
                                  className="removeInsertDisable"
                                  placeholder="Enter Das"
                                  onChange={(evnt) =>
                                    handleChange(index, evnt, "nochange")
                                  }
                                  name="das"
                                  value={
                                    inputFields?.[`open_${index}`]?.["das"]
                                  }
                                  disabled
                                />
                              </FloatingLabel>

                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="until"
                                label={t("formLabel.until")}
                              >
                                <Form.Control
                                  type="time"
                                  className="removeInsertDisable"
                                  placeholder="Enter Until"
                                  onChange={(evnt) =>
                                    handleChange(index, evnt, "nochange")
                                  }
                                  value={
                                    inputFields?.[`open_${index}`]?.["untill"]
                                  }
                                  name="untill"
                                  disabled
                                />
                              </FloatingLabel>
                            </Col>
                            {opencount !== 1 ? (
                              <Col className="delete" md="auto" hidden>
                                <Button
                                  onClick={() => removeInputFields(index)}
                                  variant="light"
                                  className="bg-light text-dark rounded-4 border-light "
                                >
                                  <img
                                    src="./assets/images/icons/delete-light.svg"
                                    alt="Delete"
                                    className="h-16"
                                  />
                                </Button>
                              </Col>
                            ) : (
                              ""
                            )}
                            {index === opencount - 1 ? (

                              <Col className="add" md="auto" hidden>
                                <Button
                                  onClick={addInputField}
                                  variant="light"
                                  className="bg-light text-dark rounded-4 border-light opening_hourData_Button"
                                >
                                  <img
                                    src="../assets/images/icons/add-light.svg"
                                    alt="Add"
                                    className="h-16"
                                  />
                                </Button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        ))}
                    </div>

                    {opencount < 7 ? (
                      <div className="BtnAction others" hidden>
                        <Button
                          variant="link"
                          className="fw-bold px-0 pt-0 pb-4"
                          onClick={(e) => addInputField(e)}
                        >
                          {t("formLabel.another")}
                        </Button>
                      </div>
                    ) : (
                      <div className="BtnAction others" hidden>
                        <Button variant="link" className="fw-bold p-0"></Button>
                      </div>
                    )}

                    <Button
                      className="submitForm"
                      type="submit"
                      variant="primary"
                      hidden
                    >
                      {t("button.update")}
                    </Button>
                    <Button
                      className="fillModalwithThisData"
                      onClick={(e) => handleEdit(e, "registerData")}
                      hidden
                    >
                      {t("button.edit")}
                    </Button>
                  </Form>
                </Card.Body>
                <Card.Footer className="d-flex align-items-center justify-content-end py-0">
                  <span className="d-none TOEDIT">
                    <span>
                      <Button
                        variant="link"
                        className="p-0 fw-bold text-nowrap"
                        onClick={(e) =>
                          handleAction(e, "toedit", "registerData")
                        }
                      >
                        {t("button.edit")}
                      </Button>
                    </span>
                  </span>
                  <div className="d-none ACTION">
                    <Row>
                      <Col xl={6} sm={4}>
                        <Button
                          variant="link"
                          className="p-0 fw-bold text-nowrap"
                          onClick={(e) => handleCancel(e, "cancel", "registerData")}
                          type="submit" >
                          {"Cancel"}
                        </Button>
                      </Col>

                      <Col xl={4} sm={4}>
                        <Button
                          variant="link"
                          className="p-0 fw-bold text-nowrap zipCodeCheck"
                          onClick={(e) =>
                            handleAction(e, "update", "registerData")
                          }
                          type="submit"
                        >
                          {t("button.toSave")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )}
          <Col lg={4}>
            <Updatelogo />
            <Footerofreports />
          </Col>
        </Row>
      </main>
    </section>
  );
};

export default CompanyData;
