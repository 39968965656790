import moment from "moment";
import React, { createContext, ReactNode, useContext, useMemo } from "react";

import { useDimob } from "../../DimobProvider";
import { Owner, PropertyContract } from "../../interfaces/contracts";

type Contract = PropertyContract & {
  mainOwner: Owner
}

type ContractsListState = {
  isFetchingContracts: boolean
  contracts: Contract[]
}

const ContractsListContext = createContext<ContractsListState>({} as ContractsListState)

export function ContractsListProvider({ children }: { children: ReactNode }) {
  const { isFetchingDimobContracts, dimobContracts } = useDimob()

  const contracts = useMemo(() => {
    const contractsList = dimobContracts.map((contract) => {
      const mainOwner = contract.owners.find(owner => owner.FL_PRINCIPAL_PRB === 1) ?? contract.owners[0]

      return {
        ...contract,
        startDate: moment(contract.startDate).startOf("day").format("DD/MM/YYYY"),
        endDate: moment(contract.endDate).startOf("day").format("DD/MM/YYYY"),
        mainOwner,
      }
    })

    return contractsList
  }, [dimobContracts])

  return (
    <ContractsListContext.Provider value={{ isFetchingContracts: isFetchingDimobContracts, contracts }}>
      {children}
    </ContractsListContext.Provider>
  )
}

export const useContractsList = () => {
  const context = useContext(ContractsListContext);

  if (context === undefined) {
    throw new Error(
      "useContractsList must be used within a ContractsListProvider"
    );
  }

  return context;
};
