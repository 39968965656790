import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, FloatingLabel, Card, Badge, Offcanvas } from 'react-bootstrap';
import CommonToast from './CommonToast';
import Swal from 'sweetalert2';
import customerService from '../../../../../../services/customerService';
import { Text_Decoder, minDate } from '../../../../../constants/utils';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { ModifyRecurring, Termination } from '../../../../../Interface';
import { gettoken } from '../../../../../../services/apiConfig';
import { contractDatails, contractStatusClose } from '../../../../../../redux/slice/contractDataSlice';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../redux/store';
import { useDispatch } from 'react-redux';
import { fetchService } from '../../../../../../redux/slice/ServiceSlice';
import moment from "moment-timezone";
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const ModifyRecurringExpenses = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    let SERVICELIST = useSelector((state: RootState) => state.service)
    const [serviceList, setServiceList] = useState<any>([]);
    const [loader_on, setloader_on] = useState(false);
    const [typeSubmit, settypeSubmit] = useState("draft");
    const validationSchema = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // observation_first: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });
    const validationSchema1 = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });
    var frm = {
        resolver: yupResolver(validationSchema1),
    };
    if (typeSubmit === "done") {
        frm = {
            resolver: yupResolver(validationSchema),
        };
    }

    const { id }: any = useParams();
    const typeValue = "modify_recurring_XYZ";
    const { contractDatail, serviceData } = useSelector((state: RootState) => state.contractData);
    //Modify recurring expenses modal close open function
    const { showRecurringExpensesModal, recurringExpensesModalClose, recurringExpensesModalShow, ShowThisData, terminationResp, allresponsibleList } = props;
    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<ModifyRecurring>(frm);

    const [modifyRecurring, setmodifyRecurring] = useState([{
        id: "",
        serviceid: "",
        debit: "",
        credit: "",
        next_due_date: "",
        end_of_expense: "",
        value: "",
        status: "1"
    }])

    const [OptionData, setOptionData] = useState<any>([
        { value: 1, label: "Owner" },
        { value: 2, label: "Tenant" },
        { value: 3, label: "Real estate" },
        { value: 4, label: "Third" },
    ])

    const [runOncee, setrunOnce] = useState(true);
    const [statusOfChecklist, setStatusOfChecklist] = useState(1);
    const [allRecuringExpense, setAllrecuringExpense] = useState<any>([]);

    useEffect(() => {
        (async () => {
            setServiceList(SERVICELIST.servicelist);
        })()
    }, [dispatch, SERVICELIST, serviceList])

    useEffect(() => {
        let data = dispatch(fetchService());
        if (showRecurringExpensesModal && runOncee) {
            setValue("responsible", "1");
            setShowResponsible(allresponsibleList[0]["agency"]);
            (async () => {
                let dataObj = {
                    type: typeValue,
                    contract_id: id
                };

                let dataresp = await customerService.getAllExpense({
                    userId: gettoken.userId,
                    id: id,
                    startDate: moment(new Date(dateBrazil)).format("MMM/YYYY"),
                    searchDate: moment(new Date(dateBrazil)).format("MMM/YYYY"),
                    endDate: undefined,
                    type: "",
                    expense: "",
                    start: 0,
                    end: 1000
                })

                let RecurringExpenses: any = [];
                let all_recuringExpense: any = [];
                if (dataresp?.["data"] && dataresp?.["data"]?.["data"].length > 0) {
                    dataresp?.["data"]?.["data"].forEach((item, index) => {
                        if (item["type"] == "New recurring expense") {
                            const nextDueDate = new Date(moment().add(1, "M").format(`YYYY-MM-${item?.["debitmonth"].split("-")[2]}`));
                            const endDate = new Date(moment(item?.["maturityDate_full"]).format("YYYY-MM-DD"));
                            let setnext_due_date: any = ""
                            if (nextDueDate == endDate) {
                                setnext_due_date = "last day";
                            } else {
                                let todayDate = new Date(dateBrazil);
                                if (todayDate > endDate) {
                                    setnext_due_date = "expired";
                                } else {
                                    if (nextDueDate > endDate) {
                                        setnext_due_date = endDate;
                                    } else {
                                        setnext_due_date = nextDueDate;
                                    }
                                }
                            }
                            RecurringExpenses.push({
                                id: item?.["id"],
                                serviceid: item?.["serviceId"],
                                debit: item?.["debitId"],
                                credit: item?.["creditId"],
                                next_due_date: moment(setnext_due_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(setnext_due_date).format("YYYY-MM-DD") : setnext_due_date,
                                end_of_expense: moment(item?.["maturityDate_full"]).format("YYYY-MM-DD"),
                                value: item?.["value"],
                                status: "1"
                            })

                            item["status"] = "1";
                            all_recuringExpense.push(item);
                        }
                    })
                }

                setAllrecuringExpense(all_recuringExpense)
                RecurringExpenses && RecurringExpenses.length > 0 &&
                    RecurringExpenses.forEach((item, index) => {
                        setValue(`recurring.${index}.id`, item?.["id"]);
                        setValue(`recurring.${index}.serviceid`, item?.["serviceId"]);
                        setValue(`recurring.${index}.debit`, item?.["debit"]);
                        setValue(`recurring.${index}.credit`, item?.["credit"]);
                        setValue(`recurring.${index}.next_due_date`, item["next_due_date"]);
                        setValue(`recurring.${index}.end_of_expense`, item["end_of_expense"]);
                        setValue(`recurring.${index}.value`, Number((item?.["value"]).replace(',', '')));
                        setValue(`recurring.${index}.status`, item?.["status"]);
                    })

                setmodifyRecurring(RecurringExpenses);
                customerService.get_terminationDefaultTerminationList(dataObj).then(async (res) => {
                    if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                        let post = res["data"]?.["data"]?.[0];
                        if (post !== undefined) {
                            reset();
                            setmodifyRecurring([])
                            let bolbdecode = await Text_Decoder(post["TA_FIRST_OBSERVATION_TERPOP"]["data"]);
                            setValue("id", post["ID_TERMINATIONPOPUP_TERPOP"]);
                            setValue("responsible", post["SL_RESPONSIBLE_TERPOP"]);
                            setValue("limit_date", moment(post["DT_LIMITDATE_TERPOP"].split("T")[0]).format("YYYY-MM-DD"));
                            setValue("observation_first", bolbdecode);
                            setStatusOfChecklist(Number(post["ST_STATUS_TERPOP"]));
                            handleResponsible(post["SL_RESPONSIBLE_TERPOP"], post["SL_RESPONSIBLE_PARTY_NAME_TERPOP"] ?? "", "load");

                            let InsertModifyData: any = [];
                            post?.["modify_recurring_condos_fee"] && post?.["modify_recurring_condos_fee"].length > 0 &&
                                post?.["modify_recurring_condos_fee"].forEach((item, index) => {
                                    InsertModifyData.push({
                                        serviceid: item?.["SL_SERVICE_TERSERCAL"],
                                        debit: item?.["SL_DEBIT_TERSERCAL"],
                                        credit: item?.["SL_CREDIT_TERSERCAL"],
                                        next_due_date: item?.["DT_NEXT_DUE_DATE_TERCAL"] ? moment(item?.["DT_NEXT_DUE_DATE_TERCAL"].split("T")[0]).format("YYYY-MM-DD") : null,
                                        end_of_expense: item?.["DT_END_DATE_EXPENSE_TERCAL"] ? moment(item?.["DT_END_DATE_EXPENSE_TERCAL"].split("T")[0]).format("YYYY-MM-DD") : null,
                                        value: item?.["TX_TOTAL_AMOUNT_TERSERCAL"],
                                        status: "2"
                                    });
                                })

                            InsertModifyData.forEach((item, index) => {
                                setValue(`recurring.${index}.serviceid`, item?.["serviceid"]);
                                setValue(`recurring.${index}.debit`, item?.["debit"]);
                                setValue(`recurring.${index}.credit`, item?.["credit"]);
                                setValue(`recurring.${index}.next_due_date`, item["next_due_date"]);
                                setValue(`recurring.${index}.end_of_expense`, item["end_of_expense"]);
                                setValue(`recurring.${index}.value`, Number((item?.["value"]).replace(',', '')));
                                setValue(`recurring.${index}.status`, item?.["status"]);
                            })
                            setmodifyRecurring(InsertModifyData);

                            // if (post["ST_STATUS_TERPOP"] !== 1) {
                            //     document.querySelectorAll('.DisableThis').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     });

                            //     document.querySelectorAll('.DisableThis input').forEach((item, index) => {
                            //         item.setAttribute("disabled", "");
                            //         item.setAttribute("read-only", "");
                            //     })
                            // }
                        }
                    }
                })
            })()
            setrunOnce(false);
        }
    }, [showRecurringExpensesModal]);


    const handleSaveDraftClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('done-draft');

        let count = 1;
        modifyRecurring.forEach((item, index) => {
            if (item["status"] == "2") {
                count = 2;
            }
        })
        // Submit the form
        if (count > 1) {
            handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, "draft"))();
        } else {
            Swal.fire({
                // icon: 'success','warning',
                title: t("Addtotermination"),
                text: t("Norecordsareaddedtotermination"),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('NonPayment.continue')
            }).then(res => {
                if (res.isConfirmed) {
                    handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, "draft"))();
                }
            })
        }
    };

    let [showResponsible, setShowResponsible] = useState([]);
    const handleResponsible = (e, rep_id, type) => {
        setValue("responsible_party_name", "");
        let id: any = 0;
        let arrayChange: any = [];
        if (type == "form") {
            id = Number(e.target.value);
        } else {
            id = Number(e);
        }

        if (id !== "") {
            if (id == 1) {
                arrayChange = allresponsibleList[0]["agency"];
            }

            if (id == 2) {
                arrayChange = allresponsibleList[0]["owner"]
            }

            if (id == 3) {
                arrayChange = allresponsibleList[0]["tenent"]
            }

            if (id == 4) {
                arrayChange = allresponsibleList[0]["manager"]
            }

            if (id == 5) {
                arrayChange = allresponsibleList?.[0]?.["gurantor"] ?? allresponsibleList?.[0]?.["guarantor"]
            }
        }

        if (type == "load") {
            setTimeout(() => {
                setValue("responsible_party_name", rep_id);
            }, 100)
        }

        if (type == "form") {
            if (arrayChange.length > 0 && arrayChange.length < 2) {
                setTimeout(() => {
                    setValue("responsible_party_name", arrayChange[0].value);
                }, 100)
            } else {
                setTimeout(() => {
                    setValue("responsible_party_name", rep_id);
                }, 100)
            }
        }

        setShowResponsible(arrayChange);
    }

    const handleSaveCompleteClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('done');
        let count = 1;
        modifyRecurring.forEach((item, index) => {
            if (item["status"] == "2") {
                count = 2;
            }
        })
        if (count > 1) {
            // Submit the form
            Swal.fire({
                // icon: 'success','warning',
                title: t("Sweetalert.WantToSave"),
                text: t("Sweetalert.Areyousuretosaveit"),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('button.confirm')
            }).then(res => {
                if (res.isConfirmed) {
                    setloader_on(true)
                    handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, "done"))();
                }
            })
        } else {
            Swal.fire({
                // icon: 'success','warning',
                title: t("Addtotermination"),
                text: t("Norecordsareaddedtotermination"),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('NonPayment.continue')
            }).then(res => {
                // Submit the form
                Swal.fire({
                    // icon: 'success','warning',
                    title: t("Sweetalert.WantToSave"),
                    text: t("Sweetalert.Areyousuretosaveit"),
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('button.confirm')
                }).then(res => {
                    if (res.isConfirmed) {
                        handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, "done"))();
                    }
                })
            })
        }
    };

    //Form submit
    const onRecurringExpensesFormSubmit = (data, e, type) => {
        setloader_on(true)

        data["type"] = typeValue;
        data["contract_id"] = id;

        if (type == "done") {
            data["status"] = 0;
        }

        if (type == "draft") {
            data["status"] = 1;
        }

        let formData_recurring: any = [];
        data["sub_type"] = "modify_recurring_calculation_XYZ";
        modifyRecurring.forEach((item, index) => {
            if (item["status"] == "2") {
                formData_recurring.push(item)
            }
        })

        data["recurring"] = formData_recurring;
        // let dataPush: any = [];
        const formData = new FormData();
        Object.keys(data) && Object.keys(data).length > 0 &&
            Object.keys(data).forEach((item, index) => {
                if (item == "recurring") {
                    // data[`${item}`].forEach((singleitem, index) => {
                    //     let changeThisData = allRecuringExpense.filter((item, index) => item.id == singleitem.id);
                    //     changeThisData[index]["creditId"] = singleitem?.["credit"] ?? "";
                    //     changeThisData[index]["debitId"] = singleitem?.["debit"] ?? "";
                    //     changeThisData[index]["credit"] = OptionData.filter((item) => String(item.value) == String(singleitem["credit"]))[0]["label"];
                    //     changeThisData[index]["debit"] = OptionData.filter((item) => String(item.value) == String(singleitem["debit"]))[0]["label"];
                    //     dataPush.push(changeThisData[0]);
                    // })
                    formData.append(`${item}`, JSON.stringify(data[`${item}`]));
                } else {
                    formData.append(`${item}`, data[`${item}`]);
                }
            })

        customerService.post_terminationDefaultTerminationList(formData).then(async res => {
            if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                settypeSubmit('draft')
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                await dispatch(contractDatails(id));
                reset();
                setmodifyRecurring([])
                setrunOnce(true);
                recurringExpensesModalClose();
            } else {
                Swal.fire({
                    // icon: 'success','error',
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
            setloader_on(false);
        })
    };

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = () => {
        setToastShow(true);
        setTimeout(() => {
            setToastShow(false);
        }, 5000);
    }

    //Delete expense modal initialization
    const [showDeleteExpenseModal, setDeleteExpenseModal] = useState(false);
    const [currentAction, setCurrentAction] = useState({
        index: "",
        action: ""
    });
    const add_remove_undo_ExpenseModalShow = (index, type) => {
        setCurrentAction({
            index: index,
            action: `${type}`
        })
        if (type == "delete") {
            setDeleteExpenseModal(true);
            recurringExpensesModalClose(false)
        }

        if (type == "add") {
            setAddTerminationModal(true);
            recurringExpensesModalClose(false)
        }

        if (type == "undo") {
            modifyRecurring[`${index}`]["status"] = "1";
            setmodifyRecurring(modifyRecurring)
        }
    }

    //Add to termination modal initialization
    const [showAddTerminationModal, setAddTerminationModal] = useState(false);
    const add_remove_undo_TerminationModalClose = (type) => {
        if (type == "add") {
            modifyRecurring[`${currentAction.index}`]["status"] = "2";
            setAddTerminationModal(false);
            recurringExpensesModalShow()
        }

        if (type == "delete") {
            modifyRecurring[`${currentAction.index}`]["status"] = "0";
            setDeleteExpenseModal(false);
            recurringExpensesModalShow()
        }

        if (type == "add_close") {
            setAddTerminationModal(false);
            recurringExpensesModalShow()
        }

        if (type == "delete_close") {
            setDeleteExpenseModal(false);
            recurringExpensesModalShow()
        }
        setTimeout(() => {
            setmodifyRecurring([...modifyRecurring]);
        }, 100)
    }

    const Close = () => {
        reset();
        setmodifyRecurring([])
        setrunOnce(true);
        recurringExpensesModalClose();
    }

    const handleAddAnother = (e) => {
        setmodifyRecurring([
            ...modifyRecurring,
            {
                id: "",
                serviceid: "",
                debit: "",
                credit: "",
                next_due_date: "",
                end_of_expense: "",
                value: "",
                status: "1"
            }
        ])
    }

    const handleRecurring = (e) => {
        let splitData = String(e.target.name).split(".");
        let type: any = splitData[0];
        let index = splitData[1];
        let field = splitData[2];
        let valueIs = e.target.value;

        if (type == "recurring") { type = modifyRecurring }
        let particulatIndex = {
            ...type[index],
            [`${field}`]: valueIs
        }
        type[index] = particulatIndex;
        if (type == "recurring") {
            setmodifyRecurring([...type]);
        }
    }
    return (
        <>
            <Offcanvas show={showRecurringExpensesModal} placement="end" onHide={Close}>
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, "done"))}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className="h4">{t("terminatelist.Modifyrecurringexpenses")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                            <Form.Control type="text"  {...register("id")} hidden readOnly />
                            <Col md={6}>
                                <FloatingLabel controlId="responsible" label={t('formLabel.responsible') + " *"}>
                                    <Form.Select aria-label="Responsible" {...register("responsible")} onChange={(e) => handleResponsible(e, "", "form")} className={`DisableThis ${errors.responsible ? "is-invalid" : ""}`}>
                                        <option value="">{t("formLabel.select")}</option>
                                        {terminationResp && terminationResp.length > 0 &&
                                            terminationResp.map((item, index) => {
                                                return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback className="invalid-feedback">{errors.responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="limit_date" label={t('terminatelist.limit') + " " + t("terminatelist.date") + " *"}>
                                    <Form.Control type="date" min={minDate()} placeholder={t('terminatelist.limit') + " " + t("terminatelist.date")} {...register("limit_date")} className={`DisableThis ${errors.responsible ? "is-invalid" : ""}`} />
                                    <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel controlId="responsible_party_name" label={t("terminatelist.Responsibleparty'sname") + " *"} className="mb-lg-4 mb-3">
                            <Form.Select aria-label="Responsible party's name" {...register("responsible_party_name")} className={`DisableThis ${errors.responsible_party_name ? "is-invalid" : ""}`}>
                                <option value="">{t("formLabel.select")}</option>
                                {showResponsible && showResponsible.length > 0 &&
                                    showResponsible.map((item, index) => {
                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback className="invalid-feedback">{errors.responsible_party_name?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                        <div className="d-flex align-items-center fw-bold mb-lg-4 mb-3">
                            <p className="mb-0 me-2 me-lg-4 me-sm-3">{t("otherstran.recurring")}</p>
                            {
                                statusOfChecklist === 1 &&
                                <span className="cursor-pe text-primary ms-auto" onClick={(e) => handleAddAnother(e)}>{t("newContractDev.AddAnother")}</span>
                            }
                        </div>
                        {
                            modifyRecurring && modifyRecurring.length > 0 &&
                            modifyRecurring.map((item, index) => {
                                let FileterService: any = [];
                                if (item?.["serviceid"] !== undefined && item?.["serviceid"] !== "") {
                                    FileterService = serviceData.filter((items) => Number(items?.["id"]) == Number(item?.["serviceid"]));
                                    setValue(`recurring.${index}.serviceid`, FileterService[0]["id"]);
                                }
                                if (item["status"] == "1") {
                                    return (
                                        <Card className="border mb-lg-4 mb-3" key={index}>
                                            <Card.Body>
                                                <div className="d-sm-flex align-items-center fw-bold mb-lg-4 mb-3">
                                                    <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                                        <p className="mb-sm-0">
                                                            {t("contractsettings.Service")} {FileterService && FileterService.length > 0 && "- " + FileterService?.[0]?.["name"]}
                                                        </p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-auto text-primary">
                                                        <span className="me-lg-4 me-sm-3 me-2 cursor-pe" onClick={() => add_remove_undo_ExpenseModalShow(index, "add")}>{t("Addtotermination")}</span>
                                                        <span className="cursor-pe" onClick={() => add_remove_undo_ExpenseModalShow(index, "delete")}>{t("Sweetalert.Delete")}</span>
                                                    </div>
                                                </div>
                                                <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                                                    <Form.Control type="text" {...register(`recurring.${index}.id`)} hidden />
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="products" label={t("formLabel.products")}>
                                                            <Form.Select aria-label="Products"  {...register(`recurring.${index}.serviceid`)} onChange={(e) => handleRecurring(e)}>
                                                                <option value="">{t('dropdownItem.select')}..</option>
                                                                {serviceList && serviceList.length > 0 &&
                                                                    serviceList.map((item, index) => (
                                                                        <option key={index} value={item.ID_PRODUTO_PRD}>{item.ST_DESCRICAO_PRD}</option>
                                                                    ))}
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="debit" label={t("formLabel.debit")} >
                                                            <Form.Select aria-label={t("formLabel.debit")} {...register(`recurring.${index}.debit`)} onChange={(e) => handleRecurring(e)} >
                                                                <option value="">{t("formLabel.select")}</option>
                                                                {OptionData && OptionData.length > 0 &&
                                                                    OptionData.map((item: any, index: number) => {
                                                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="credit" label={t("formLabel.credit")}>
                                                            <Form.Select aria-label={t("formLabel.credit")} {...register(`recurring.${index}.credit`)} onChange={(e) => handleRecurring(e)} >
                                                                <option value="">{t("formLabel.select")}</option>
                                                                {OptionData && OptionData.length > 0 &&
                                                                    OptionData.map((item: any, index: number) => {
                                                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row className="gy-lg-4 gy-3">
                                                    <Col sm={4}>
                                                        {/* <span className="d-block text-secondary mb-1">Next due date</span>
                                                        <span className="d-block">{moment(getValues(`recurring.${index}.next_due_date`)).format("DD/MM/YYYY") === "Invalid date" ? getValues(`recurring.${index}.next_due_date`) : moment(getValues(`recurring.${index}.next_due_date`)).format("DD/MM/YYYY")}</span> */}
                                                        <FloatingLabel controlId="limit_date" label={t('terminatelist.limit') + " " + t("terminatelist.date")}>
                                                            <Form.Control type="date" min={minDate()} placeholder="Next due date" {...register(`recurring.${index}.next_due_date`)} onChange={(e) => handleRecurring(e)} />
                                                            {/* <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback> */}
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col sm={4}>
                                                        {/* <span className="d-block text-secondary mb-1">End of expense</span>
                                                        <span className="d-block">{moment(getValues(`recurring.${index}.end_of_expense`)).format("DD/MM/YYYY")}</span> */}
                                                        <FloatingLabel controlId="limit_date" label={t("Endofexpense")}>
                                                            <Form.Control type="date" min={minDate()} placeholder={t("Endofexpense")} {...register(`recurring.${index}.end_of_expense`)} onChange={(e) => handleRecurring(e)} />
                                                            {/* <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback> */}
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col sm={4}>
                                                        {/* <span className="d-block text-secondary mb-1">Value</span>
                                                        <span className="d-block">R${getValues(`recurring.${index}.value`)}</span> */}
                                                        <FloatingLabel controlId="limit_date" label={t("value")}>
                                                            <Form.Control type="number" placeholder={t("value")} {...register(`recurring.${index}.value`)} onChange={(e) => handleRecurring(e)} />
                                                            {/* <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback> */}
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )
                                } else {
                                    return (
                                        <Card className="border mb-lg-4 mb-3" key={index}>
                                            <Card.Body>
                                                <div className="d-sm-flex align-items-center fw-bold">
                                                    <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                                        <p className="mb-sm-0">{t("contractsettings.Service")}  <Badge className={`${item["status"] == "0" ? " bg-danger-30" : "bg-warning-10"} text-dark ms-lg-4 ms-md-3 ms-2`}> {item["status"] == "0" ? t("Deletedrecurringexpense") : t("Addtotermination")}</Badge></p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-auto text-primary cursor-pe" onClick={() => add_remove_undo_ExpenseModalShow(index, "undo")}>
                                                        {t("TransferPage.UNDO")}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            })
                        }

                        {/* <Card className="border mb-lg-4 mb-3">
                            <Card.Body>
                                <div className="d-sm-flex align-items-center fw-bold mb-lg-4 mb-3">
                                    <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                        <p className="mb-sm-0">Rent discount</p>
                                    </div>
                                    <div className="flex-shrink-0 ms-sm-auto text-primary">
                                        <span className="me-lg-4 me-sm-3 me-2 cursor-pe" onClick={addTerminationModalShow}>Add to termination</span>
                                        <span className="cursor-pe" onClick={()add_remove_undo_ExpenseModalShow(1, "undo")}>Delete</span>
                                    </div>
                                </div>
                                <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                                    <Col md={6}>
                                        <FloatingLabel controlId="debit" label="Debit">
                                            <Form.Select aria-label="Debit" {...register("rent.debit")}>
                                                <option value="">{t("formLabel.select")}</option>
                                                {OptionData && OptionData.length > 0 &&
                                                    OptionData.map((item, index) => {
                                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="credit" label="Credit" {...register("rent.debit")}>
                                            <Form.Select aria-label="Credit">
                                                <option value="">{t("formLabel.select")}</option>
                                                {OptionData && OptionData.length > 0 &&
                                                    OptionData.map((item, index) => {
                                                        return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="gy-lg-4 gy-3">
                                    <Col sm={4}>
                                        <span className="d-block text-secondary mb-1">Next due date</span>
                                        <span className="d-block">07/05/2023</span>
                                    </Col>
                                    <Col sm={4}>
                                        <span className="d-block text-secondary mb-1">End of expense</span>
                                        <span className="d-block">07/09/2023</span>
                                    </Col>
                                    <Col sm={4}>
                                        <span className="d-block text-secondary mb-1">Value</span>
                                        <span className="d-block">R$ 650,00</span>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border mb-lg-4 mb-3">
                            <Card.Body>
                                <div className="d-sm-flex align-items-center fw-bold">
                                    <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                        <p className="mb-sm-0">Rent discount  <Badge className="bg-warning-10 text-dark ms-lg-4 ms-md-3 ms-2">Deleted rent || Added to termination</Badge></p>
                                    </div>
                                    <div className="flex-shrink-0 ms-sm-auto text-primary">
                                        Undo
                                    </div>
                                </div>
                            </Card.Body>
                        </Card> */}
                        <FloatingLabel controlId="observation" label={t("formLabel.observation")}>
                            <Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("observation_first")} className={`DisableThis ${errors.observation_first ? "is-invalid" : ""}`} />
                            <Form.Control.Feedback className="invalid-feedback">{errors.observation_first?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Offcanvas.Body>
                    {/* {
                        statusOfChecklist === 1 &&
                        <>
                            <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                <Button variant="outline-primary" onClick={handleSaveDraftClick}>
                                    {t('terminatelist.Savedraft')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('terminatelist.Saveandcomplete')}
                                </Button>
                            </div>
                        </>
                    } */}

                    {/* {
                        statusOfChecklist === 1 && */}
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {loader_on ?
                            <Button className="btn-width">
                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                            </Button>
                            :
                            <>
                                <Button variant="outline-primary" onClick={Close}>
                                    {t('button.close')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('button.save')}
                                </Button>
                            </>
                        }
                    </div>
                    {/* } */}
                </Form>
            </Offcanvas>
            {/* Toast start from here */}
            <CommonToast showCommonToast={showToast} commonToastClose={commonToastClose} />
            {/* Toast end here */}
            {/* Delete expense modal start from here */}
            <Modal show={showDeleteExpenseModal} onHide={() => add_remove_undo_TerminationModalClose("delete_close")}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Deleteexpense")}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("BydeletingServiceth")}.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => add_remove_undo_TerminationModalClose("delete_close")}>
                            {t("close")}
                        </Button>
                        <Button variant="primary" onClick={() => add_remove_undo_TerminationModalClose("delete")}>
                            {t("Sweetalert.Delete")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Delete expense modal end */}
            {/* Add termination modal start from here */}
            <Modal show={showAddTerminationModal} onHide={() => add_remove_undo_TerminationModalClose("add_close")}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Addtotermination")}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("ByaddingServicetheterminationdocument")}.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => add_remove_undo_TerminationModalClose("add_close")}>
                            {t("close")}
                        </Button>
                        <Button variant="primary" onClick={() => add_remove_undo_TerminationModalClose("add")}>
                            {t("Toadd")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Add termination modal end */}
        </>
    )
}

export default ModifyRecurringExpenses
