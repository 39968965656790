import { Modal, Form, Button, FloatingLabel, Accordion, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getAllAccounts, getAllBillMethodListing } from "../../../../redux/slice/expenditureDataSlice";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { liquidateForm } from '../../../Interface';
import { incomeService } from '../../../../services/incomeServices';
import { gettoken } from '../../../../Api/Contact';
import Swal from 'sweetalert2';
import { openErrorbox } from '../../Contracts/Common/actionPerform';
//import moment, { weekdays } from "moment";
import moment, { weekdays } from "moment-timezone";

import { fetchService } from '../../../../redux/slice/ServiceSlice';
import { ServiceRecord, getTaxation } from '../../../../redux/slice/taxationSlice';
import { addBusinessDaysToDate, convertToNumber, converter, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import {deleteBillingTicketCron } from '../../../../redux/slice/contractDataSlice';

const ChargeLiquidate = (props, liquidateForm) => {
	let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
	const [loader_on, setloader_on] = useState(false)
	const [ diffAmt,setDiffAmt ] = useState(0);
	const [ diffHide,setDiffHide ] = useState(false);
	const [ diffDateHide,setDiffDateHide ] = useState(false);
    const  [totalRecb, setTotalRecb] = useState('')
	const { billingData, billingStatus,accountData } = useSelector((state: RootState) => state.expenditureData)
	const dispatch = useDispatch<AppDispatch>()
	const [t] = useTranslation();
	const [double, setDouble] = useState(false);
	const [chargeData, setChargeData] = useState<any>([])
	const [AdminFee, setAdminFee] = useState<number>()
	useEffect(() => {
		// if(show){
		dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
		dispatch(getAllAccounts({ start: 0, end: 1000 }));
		setDiffAmt(0);
		setDiffHide(false);
		setDiffDateHide(false);
		// }
	}, [dispatch])

	const { show, onClose, getChargesSummery } = props;
	const { userbanklist, status } = useSelector((state: RootState) => state.bank)
	const { taxadata, taxalist } = useSelector((state: RootState) => state.taxa)

	const [hide, setHide] = useState(false);
	let first = {
		DT_LIQUIDACAO_RECB: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		//DT_RECEBIMENTO_RECB: Yup.string().trim().nullable()
		//	.required(t('requiredMmessage.fieldRequired')),
		DT_RECEBIMENTO_RECB: Yup.date()
			.typeError(t('requiredMmessage.fieldRequired'))
			.when(
				'DT_LIQUIDACAO_RECB',
				(DT_LIQUIDACAO_RECB, schema) => (moment(DT_LIQUIDACAO_RECB).isValid() ? schema.min(DT_LIQUIDACAO_RECB, t("EnsureCreditDateAfterSaleDate")) : schema),
			),
		VL_TOTAL_RECB: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		ID_FORMAPAGAMENTO_RECB: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
	}
	let second = {
		NM_CHEQUE_PRE: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		ST_BANCO_PRE: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		ST_AGENCIA_PRE: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		ST_CONTA_PRE: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
	}
	let fourth = {
		chargeDate: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
	}
	if(diffDateHide){
		first = {...first,...fourth}
	}
	
	let third = { ...first }
	if (hide) {
		third = { ...first, ...second }
	}
	const formValidation = Yup.object().shape(first)
	let frm = {
		resolver: yupResolver(formValidation)
	}
	const { register, handleSubmit, setValue, getValues, reset, formState: { errors },setError, clearErrors } = useForm<liquidateForm>(frm);
	const [singleLiquidity, setSingleLiquidity] = useState<any>([]);
	const [firstRun, setFirstRun] = useState(false);

	const [extraAndDiscount, setExtraAndDiscount] = useState({});
	
	const [calculateData, setCalculateData] = useState({
		total: 0,
		fine: 0, // traffic_ticket
		interest: 0, // fee
		monetary: 0,
		discount: 0,
		due: 0,
		paid: 0,
		credited: 0
	})

	useEffect(() => {
		(async () => {
			let response = await incomeService.getDiscountData(gettoken.userId);
			setExtraAndDiscount(response.data);
		})()
	}, [taxadata])

	useEffect(() => {
		setHide(false);
		let currentLiquidityId = document.querySelector("body")?.getAttribute("charge_liquidity_id");
		if (currentLiquidityId !== null && currentLiquidityId !== undefined && currentLiquidityId !== "") {
			incomeService.getEditCharge(currentLiquidityId).then(res => {
				if (res.type == "success" || res.type == "Success") {
					if (res.data.data["ID_FORMAPAGAMENTO_RECB"] == 1) {
						setHide(true);
					}
					
					setSingleLiquidity(res.data);
					setFirstRun(true);
				} else {
					setSingleLiquidity([]);
				}
			}).catch(err => {
				setSingleLiquidity([]);
			});
		}

	}, [show,])

	const [dis, disSet] = useState(false);
	const [fin, finSet] = useState(false);

	useEffect(() => {
		finSet(false);
		disSet(false);
		setDiffAmt(0);
		setDiffHide(false);
		setDiffDateHide(false);
		setDouble(true);
		reset();
		if (Object.keys(singleLiquidity).length > 0) {
			setDouble(false);
			if (firstRun) {
				let liquidityData = singleLiquidity["data"];
				let discountAll = singleLiquidity["discounts"].apply_discount_all;
				let interestApply = singleLiquidity["discounts"].use_percentage;
				let discountService = singleLiquidity["discounts"].discountedService;
				let discountResult:[]|any = discountService.find(item => item.ID_PRODUTO_PRD === liquidityData["ID_PRODUTO_PRD"]);
				
				let total = parseFloat(liquidityData["vl_emitido_recb"]);
				let totalAmt: any = parseFloat(liquidityData["vl_emitido_recb"]);
				let currentDate = moment.utc(dateBrazil).format("YYYY-MM-DD");
				let maturityDate = moment.utc(liquidityData["DT_VENCIMENTO_RECB"]).format("YYYY-MM-DD");

				let monthlyInterest = String(Number(liquidityData["VL_TXJUROS_RECB"])) !== "NaN" && Number(liquidityData["VL_TXJUROS_RECB"]) != 0 && liquidityData["VL_TXJUROS_RECB"] ? Number(liquidityData["VL_TXJUROS_RECB"]) : Number(extraAndDiscount["monthly_interest"]) ? Number(extraAndDiscount["monthly_interest"]) : 1;
				let fineRate = String(Number(liquidityData["VL_TXMULTA_RECB"])) !== "NaN" && Number(liquidityData["VL_TXMULTA_RECB"]) != 0 && liquidityData["VL_TXMULTA_RECB"] ? Number(liquidityData["VL_TXMULTA_RECB"]) : Number(extraAndDiscount["fine_rate"]) ? Number(extraAndDiscount["fine_rate"]) : 10;
				let discount = String(Number(liquidityData["VL_TXDESCONTO_RECB"])) !== "NaN" && Number(liquidityData["VL_TXDESCONTO_RECB"]) != 0 && liquidityData["VL_TXDESCONTO_RECB"] ? Number(liquidityData["VL_TXDESCONTO_RECB"]) : Number(extraAndDiscount["discount_rate"]) ? Number(extraAndDiscount["discount_rate"]) : 0;

				if (liquidityData["NM_TXJUROS_CON"] && liquidityData["NM_TXMULTA_CON"] && liquidityData["NM_TXDESCONTO_CON"]) {
					monthlyInterest = String(Number(liquidityData["NM_TXJUROS_CON"])) !== "NaN" && Number(liquidityData["NM_TXJUROS_CON"]) != 0 && liquidityData["NM_TXJUROS_CON"] ? Number(liquidityData["NM_TXJUROS_CON"]) : Number(extraAndDiscount["monthly_interest"]) ? Number(extraAndDiscount["monthly_interest"]) : 1;
					fineRate = String(Number(liquidityData["NM_TXMULTA_CON"])) !== "NaN" && Number(liquidityData["NM_TXMULTA_CON"]) != 0 && liquidityData["NM_TXMULTA_CON"] ? Number(liquidityData["NM_TXMULTA_CON"]) : Number(extraAndDiscount["fine_rate"]) ? Number(extraAndDiscount["fine_rate"]) : 10;
					discount = String(Number(liquidityData["NM_TXDESCONTO_CON"])) !== "NaN" && Number(liquidityData["NM_TXDESCONTO_CON"]) != 0 && liquidityData["NM_TXDESCONTO_CON"] ? Number(liquidityData["NM_TXDESCONTO_CON"]) : Number(extraAndDiscount["discount_rate"]) ? Number(extraAndDiscount["discount_rate"]) : 0;
				}
				let calMonthlyInterest: any = 0;
				let calFineRate: any = 0;
				let calMonetary: any = 0;
				let calDiscount: any = 0;

				let disApplied = 0;
				if(discountResult !==undefined || discountAll==1){
					disApplied = 1;
				}

				let discountDate = maturityDate;
				if (extraAndDiscount["give_discount"]) {
					let dys = extraAndDiscount["give_discount"] ? Number(extraAndDiscount["give_discount"]) : 0;
					discountDate = moment.utc(maturityDate).subtract(dys, 'days').format('YYYY-MM-DD');
				}
				
				let grDys = liquidityData["NM_CARENCIA_CON"] ? Number(liquidityData["NM_CARENCIA_CON"]) : 0;
				let	graceFineDate = moment.utc(maturityDate).add(grDys, 'days').format('YYYY-MM-DD');
				
				if (currentDate > graceFineDate) {
					const oneDay = 24 * 60 * 60 * 1000;
					const fstDate = new Date(maturityDate);
					const scdDate = new Date(currentDate);
					const differenceMs = Math.abs(Number(fstDate) - Number(scdDate));
					const numDays = Math.round(differenceMs / oneDay)
					disSet(true);
					calMonthlyInterest = interestApply===true ? monthlyInterest :  (totalAmt * monthlyInterest / 100) / Number(moment.utc(liquidityData["DT_VENCIMENTO_RECB"]).endOf("month").format("DD"));
					calMonthlyInterest = calMonthlyInterest * numDays;
					calFineRate = interestApply===true ? fineRate  : totalAmt * fineRate / 100;
					calMonetary = 0;
					totalAmt = totalAmt + calMonthlyInterest + calFineRate + calMonetary;
				}
				if (disApplied==1 && currentDate < discountDate) {
					finSet(true);
					calDiscount = interestApply===true ? discount : totalAmt * discount / 100;
					totalAmt = totalAmt - calDiscount;
				}
				if (disApplied==1 && currentDate == discountDate && Number(extraAndDiscount["monthly_interest"]) == 0) {
					finSet(true);
					calDiscount = interestApply===true ? discount : totalAmt * discount / 100;
					totalAmt = totalAmt - calDiscount;
				}

				if (!Number.isInteger(totalAmt)) {
					totalAmt = totalAmt.toFixed(2);
				}
				if (!Number.isInteger(calMonthlyInterest)) {
					calMonthlyInterest = calMonthlyInterest.toFixed(2);
				}
				if (!Number.isInteger(calFineRate)) {
					calFineRate = calFineRate.toFixed(2);
				}
				if (!Number.isInteger(calDiscount)) {
					calDiscount = calDiscount.toFixed(2);
				}
				setCalculateData({
					total: total,
					interest: calMonthlyInterest, // traffic_ticket
					fine: calFineRate, // fee
					monetary: calMonetary,
					discount: calDiscount,
					due: totalAmt,
					paid: totalAmt,
					credited: totalAmt
				})
				let paidAmount = totalAmt;
				setTotalRecb(paidAmount)
				let data = {
					"action": "update",
					"DT_GERACAO_RECB":graceFineDate,
					"DT_COMPETENCIA_RECB":maturityDate,
					"ID_RECEBIMENTO_RECB": liquidityData["ID_RECEBIMENTO_RECB"],
					"DT_LIQUIDACAO_RECB": currentDate, //sale off
					"DT_RECEBIMENTO_RECB": currentDate,// moment(currentDate["DT_VENCIMENTO_RECB"]).add(0, "days").format('YYYY-MM-DD'), //credit
					"VL_TOTAL_RECB": converter(`${paidAmount}`), //amount
					"settleAmount":0,
					"origionalAmount":totalAmt,
					"VL_DESCONTOCALCULADO_RECB": calDiscount,//converter(`${calDiscount}`), //discount ----- notGetting
					"ID_FORMAPAGAMENTO_RECB": liquidityData["ID_FORMAPAGAMENTO_RECB"], //Form of Payment
					"print_receipt": false, //print_receipt
					"debit_bank_fee": false, //debit_bank_fee
					"VL_TXJUROS_RECB": converter(String(calMonthlyInterest)) ,//VL_TXJUROS_RECB
					"VL_TXMULTA_RECB": converter(String(calFineRate)),//legal_trafic_ticket VL_TXMULTA_RECB
					"vl_taxacobranca_recb": converter(String(calMonetary)),//legal_monetary vl_descontocalculado_recb ----- notGetting
					"ID_CHEQUE_PRE": liquidityData["ID_CHEQUE_PRE"] ?? null,
					"NM_CHEQUE_PRE": liquidityData["NM_CHEQUE_PRE"] ?? "", //check_number ----- notGetting
					"ST_BANCO_PRE": liquidityData["ST_BANCO_PRE"] ?? "",//check_bank_number ----- notGetting
					"ST_AGENCIA_PRE": liquidityData["ST_AGENCIA_PRE"] ?? "",//check_agency ----- notGetting
					"ST_CONTA_PRE": liquidityData["ST_CONTA_PRE"] ?? "",//check_account ----- notGetting
					"DT_VENCIMENTO_PRE": liquidityData["DT_VENCIMENTO_PRE"] ?? "",//check_good ----- notGetting
					"ST_OBSERVACAOINTERNA_RECB": liquidityData["ST_OBSERVACAOINTERNA_RECB"] !== null ? liquidityData["ST_OBSERVACAOINTERNA_RECB"] : "",//internal_observation
					"ID_CONTA_CB": liquidityData["ID_CONTA_CB"],
					"ID_CONTRATO_CON" : liquidityData["ID_CONTRATO_CON"] ? liquidityData["ID_CONTRATO_CON"] : ""
				}
				let fields = Object.keys(data);
				fields.forEach((field: any) => setValue(field, data?.[field]));
			}
		}

	}, [firstRun, singleLiquidity])


	const recalculate = (e, type) => {
		let liquidityData = singleLiquidity["data"];
		let discountAll = singleLiquidity["discounts"].apply_discount_all;
		let interestApply = singleLiquidity["discounts"].use_percentage;
		let discountService = singleLiquidity["discounts"].discountedService;
		let discountResult:[]|any = discountService.find(item => item.ID_PRODUTO_PRD === liquidityData["ID_PRODUTO_PRD"]);
		
		finSet(false);
		disSet(false);
		let current_val = e.target.value;
		let maturityDate = getValues('DT_RECEBIMENTO_RECB');
		let saleOffDate = liquidityData.DT_VENCIMENTO_RECB; //getValues('DT_LIQUIDACAO_RECB')
		let total = calculateData["total"];
		setValue("settleAmount",0);
		
		if (type == "saleOff") {
			saleOffDate = current_val
			setValue("DT_RECEBIMENTO_RECB",moment.utc(saleOffDate).add(1,'days').format("YYYY-MM-DD"));
		}
		if (type == "credit") {
			maturityDate = current_val
		}
		if (type == "amount") {
			total = current_val
		}
		if (type == "interest" || type == "fine" || type == "discount") {
			saleOffDate = getValues("DT_LIQUIDACAO_RECB");
			maturityDate = liquidityData.DT_VENCIMENTO_RECB;//getValues("DT_RECEBIMENTO_RECB");
		}
		if (current_val == "") {
			current_val = 0;
		}
		let monthlyInterest = String(Number(liquidityData["VL_TXJUROS_RECB"])) !== "NaN" && Number(liquidityData["VL_TXJUROS_RECB"]) != 0 && liquidityData["VL_TXJUROS_RECB"] ? Number(liquidityData["VL_TXJUROS_RECB"]) : Number(extraAndDiscount["monthly_interest"]) ? Number(extraAndDiscount["monthly_interest"]) : 1;
		let fineRate = String(Number(liquidityData["VL_TXMULTA_RECB"])) !== "NaN" && Number(liquidityData["VL_TXMULTA_RECB"]) != 0 && liquidityData["VL_TXMULTA_RECB"] ? Number(liquidityData["VL_TXMULTA_RECB"]) : Number(extraAndDiscount["fine_rate"]) ? Number(extraAndDiscount["fine_rate"]) : 10;
		let discount = String(Number(liquidityData["VL_TXDESCONTO_RECB"])) !== "NaN" && Number(liquidityData["VL_TXDESCONTO_RECB"]) != 0 && liquidityData["VL_TXDESCONTO_RECB"] ? Number(liquidityData["VL_TXDESCONTO_RECB"]) : Number(extraAndDiscount["discount_rate"]) ? Number(extraAndDiscount["discount_rate"]) : 0;

		if (liquidityData["NM_TXJUROS_CON"] && liquidityData["NM_TXMULTA_CON"] && liquidityData["NM_TXDESCONTO_CON"]) {
			monthlyInterest = String(Number(liquidityData["NM_TXJUROS_CON"])) !== "NaN" && Number(liquidityData["NM_TXJUROS_CON"]) != 0 && liquidityData["NM_TXJUROS_CON"] ? Number(liquidityData["NM_TXJUROS_CON"]) : Number(extraAndDiscount["monthly_interest"]) ? Number(extraAndDiscount["monthly_interest"]) : 1;
			fineRate = String(Number(liquidityData["NM_TXMULTA_CON"])) !== "NaN" && Number(liquidityData["NM_TXMULTA_CON"]) != 0 && liquidityData["NM_TXMULTA_CON"] ? Number(liquidityData["NM_TXMULTA_CON"]) : Number(extraAndDiscount["fine_rate"]) ? Number(extraAndDiscount["fine_rate"]) : 10;
			discount = String(Number(liquidityData["NM_TXDESCONTO_CON"])) !== "NaN" && Number(liquidityData["NM_TXDESCONTO_CON"]) != 0 && liquidityData["NM_TXDESCONTO_CON"] ? Number(liquidityData["NM_TXDESCONTO_CON"]) : Number(extraAndDiscount["discount_rate"]) ? Number(extraAndDiscount["discount_rate"]) : 0;
		}
		let calMonthlyInterest:any = interestApply===true ? monthlyInterest : Number(total) * Number(monthlyInterest) / 100;
		let calFineRate:any = interestApply===true ? fineRate : Number(total) * Number(fineRate) / 100;
		let calMonetary:any = 0;
		let calDiscount:any = interestApply===true ? discount : Number(total) * Number(discount) / 100;
		
		let discountDate = moment.utc(liquidityData["DT_VENCIMENTO_RECB"]).format("YYYY-MM-DD");
		if (extraAndDiscount["discount_rate"]) {
			let dys = extraAndDiscount["give_discount"] ? Number(extraAndDiscount["give_discount"]) : 0;
			discountDate = moment(discountDate).subtract(dys, 'days').format('YYYY-MM-DD');
		}

		if (type == "interest") {
			calMonthlyInterest = Number(current_val)
		}
		if (type == "fine") {
			calFineRate = Number(current_val)
		}
		if (type == "monetary") {
			calMonetary = Number(current_val)
		}
		if (type == "discount" && current_val < total) {
			calDiscount = Number(current_val)
		}
		
		let paidAmount: any = total;

		let grDys = liquidityData["NM_CARENCIA_CON"] ? Number(liquidityData["NM_CARENCIA_CON"]) : 0;
		let graceFineDate = moment.utc(liquidityData.DT_VENCIMENTO_RECB).add(grDys, 'days').format('YYYY-MM-DD');
		
		let disApplied = 0;
		if(discountResult !==undefined || discountAll==1){
			disApplied = 1;
		}

		if ( (type == "interest" || type == "fine" || type == "monetary") || saleOffDate > graceFineDate) {
			const oneDay = 24 * 60 * 60 * 1000;
			const fstDate = new Date(saleOffDate);
			const scdDate = new Date(graceFineDate);
			const differenceMs = Math.abs(Number(fstDate) - Number(scdDate));
			const numDays = Math.round(differenceMs / oneDay)
			disSet(true);
			if (type == "interest") {
				calMonthlyInterest = Number(convertToNumber(String(current_val)));
				calFineRate = Number(convertToNumber(String(getValues("VL_TXMULTA_RECB"))));
			}
			else if (type == "fine") {
				calMonthlyInterest = Number(convertToNumber(String(getValues("VL_TXJUROS_RECB"))));
				calFineRate = Number(convertToNumber(String(current_val)));
			}
			else if (type == "monetary") {
				calMonthlyInterest = Number( convertToNumber(String(getValues("VL_TXJUROS_RECB"))));
				calFineRate = Number(convertToNumber(String(getValues("VL_TXMULTA_RECB"))));
				calMonetary = Number(convertToNumber(String(current_val)));
			}
			else {
				calMonthlyInterest = calMonthlyInterest / Number(moment.utc(liquidityData.DT_VENCIMENTO_RECB).endOf("month").format("DD")) * numDays;
				calFineRate = calFineRate;
			}
			calDiscount = 0;
			paidAmount = total + calMonthlyInterest + calFineRate + calMonetary;
		}
		// else if (saleOffDate < discountDate && Number(extraAndDiscount["give_discount"]) > 0 && total > calDiscount) {
		else if (type == "discount" || disApplied==1 && saleOffDate < discountDate && total > calDiscount) {
			finSet(true);
			calMonthlyInterest = 0;
			calFineRate = 0;
			calMonetary = 0;
			paidAmount = Number(total) - Number(calDiscount);
		}
		//else if (saleOffDate == discountDate && Number(extraAndDiscount["give_discount"]) == 0 && total > calDiscount) {
		else if (type == "discount" || disApplied ==1 && saleOffDate == discountDate && total > calDiscount) {
			finSet(true);
			calMonthlyInterest = 0;
			calFineRate = 0;
			calMonetary = 0;
			paidAmount = Number(total) - Number(calDiscount);
		}
		else {
			calMonthlyInterest = 0;
			calFineRate = 0;
			calMonetary = 0;
			calDiscount = 0;
			paidAmount = Number(total);
		}
		if (!Number.isInteger(calMonthlyInterest)) {
			calMonthlyInterest = calMonthlyInterest.toFixed(2);
		}
		if (!Number.isInteger(calFineRate)) {
			calFineRate = calFineRate.toFixed(2);
		}
		if (!Number.isInteger(calMonetary)) {
			calMonetary = calMonetary.toFixed(2);
		}
		if (!Number.isInteger(calDiscount)) {
			calDiscount = calDiscount.toFixed(2);
		}
		if (!Number.isInteger(paidAmount)) {
			paidAmount = paidAmount.toFixed(2);
		}
		setCalculateData({
			total 		: total,
			interest 	: calMonthlyInterest,
			fine 		: calFineRate,
			monetary 	: calMonetary,
			discount 	: calDiscount,
			due 		: paidAmount,
			paid 		: paidAmount,
			credited 	: paidAmount
		})
		if (type !== "interest" && type !== "fine" && type !== "discount" && type !== "monetary") {
			setValue('VL_DESCONTOCALCULADO_RECB', calDiscount)
			setValue('VL_TXJUROS_RECB', calMonthlyInterest)
			setValue('VL_TXMULTA_RECB', calFineRate)
			setValue('vl_taxacobranca_recb', calMonetary)
		}
		setValue('VL_TOTAL_RECB',converter(`${paidAmount}`));
		setValue('origionalAmount',paidAmount);
		setValue('DT_GERACAO_RECB',graceFineDate);
		setValue('DT_COMPETENCIA_RECB',maturityDate);
		setTotalRecb(paidAmount)
	}
	const settleAmtCalculate = async(e, type) =>{
		let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
		if(e.target.value){
			setDiffHide(false);
			setDiffDateHide(false);
			setValue("isCharge",false)
			setValue("VL_TOTAL_RECB", formatNumber(value))
			setTotalRecb(value)
			let orgAmt = getValues("origionalAmount");
			let totalVal: any = convertToNumber(String(e.target.value));
		
			let pendAmt:any = totalVal - orgAmt;
			pendAmt = pendAmt.toFixed(2);
			setDiffAmt(pendAmt ? pendAmt : 0);
			if(pendAmt==0){
				setDiffAmt(0);
				setValue("settleAmount",0);
				setDiffHide(false);
				setDiffDateHide(false);
				setValue("isCharge",true)
			}
			else {
				if(Math.sign(pendAmt)==-1){
					setDiffHide(true);
				}			
				setValue("settleAmount",totalVal);
			}			
		}
	}

	const issCharge = async(e,type)=>{
		setDiffDateHide(false);
		setDiffHide(false);
		if(type=="createCharge"){
			setDiffDateHide(true);
			setDiffHide(true);
		}
	}

	const handleClose = () => {
		onClose();
		setDouble(false);
		setloader_on(false);
		setSingleLiquidity([]);
	}
	const onSubmit = (data: any) => {
		
		if(data.settleAmount!=0 && (data.isCharge==null ||data.isCharge==false)){
			setError("isCharge", {
                type: "manual",
                message: t('requiredMmessage.fieldRequired'),
            });
		}
		else {
			clearErrors("isCharge")
			setDouble(true);
			setloader_on(true);
			let TOTAL_RECB: any = formatNumberEnglish(data?.["VL_TOTAL_RECB"]);
			if (TOTAL_RECB.includes(",")) {
                if (TOTAL_RECB) {
                	TOTAL_RECB = String(TOTAL_RECB).replaceAll(",", "");
                }
            }

            if(data.isCharge==3){
            	data.isCharge = 0;
            }
            
			let transferType = singleLiquidity["data"]["FL_DIAFIXOREPASSE_CON"] ? singleLiquidity["data"]["FL_DIAFIXOREPASSE_CON"] : 0; // Transfer type
			let transferDay = singleLiquidity["data"]["NM_DIAREPASSE_CON"] ? singleLiquidity["data"]["NM_DIAREPASSE_CON"] : 0; // Transfer Day Value
			let liquidateDate: any = moment(data["DT_LIQUIDACAO_RECB"] ? data["DT_LIQUIDACAO_RECB"] : new Date(dateBrazil)).format("YYYY-MM-DD");
			
			if (transferType == 0 || transferType == "0") {
				// Business day after rent payment
				let filterFromThis = liquidateDate ? new Date(liquidateDate) : new Date(dateBrazil);
				var GetDate = addBusinessDaysToDate(filterFromThis, Number(transferDay));
				liquidateDate = moment(GetDate).format(`YYYY-MM-DD`);
			}

			if (transferType == 1 || transferType == "1") {
				// Fixed day
				let sartDt = moment(liquidateDate).format("DD");
				let endDt  = moment(liquidateDate,"YYYY-MM-DD").daysInMonth();
				let nm:any = 1;
				if(Number(sartDt) < Number(transferDay) && Number(transferDay) <= Number(endDt)){
					nm = 0;
				}				
				liquidateDate = moment(liquidateDate).add(Number(nm),"months").format(`YYYY-MM-${String(transferDay).length < 3 ? String("0" + transferDay) : transferDay}`);
			}

			if (transferType == 2 || transferType == "2") {
				// Calendar day after rent payment
				liquidateDate = moment(liquidateDate).add(transferDay, "days").format(`YYYY-MM-DD`);
			}

			data["DT_VENCIMENTO_RECB"]  = moment(data["DT_LIQUIDACAO_RECB"]).format("YYYY-MM-DD");			
			data["DT_LIQUIDACAO_RECB"]  = moment(liquidateDate).format("YYYY-MM-DD");
			data["DT_RECEBIMENTO_RECB"] = moment(data["DT_RECEBIMENTO_RECB"]).format("YYYY-MM-DD");
			
			let changeVal = totalRecb ? totalRecb : data.VL_TOTAL_RECB;
			let totalRecbvalue: any = convertToNumber(`${changeVal}`);
			data["VL_TOTAL_RECB"] = totalRecbvalue ;
			let VL_TXJUROS_RECB = String(data.VL_TXJUROS_RECB);
			let VL_TXJUROS_RECB_VAL = convertToNumber(VL_TXJUROS_RECB);
			let VL_TXMULTA_RECB = String(data.VL_TXMULTA_RECB);
			let VL_TXMULTA_RECB_VAL = convertToNumber(VL_TXMULTA_RECB);
			let vl_taxacobranca_recb = String(data.vl_taxacobranca_recb);
			let vl_taxacobranca_recb_VAL = convertToNumber(vl_taxacobranca_recb);
			data["VL_TXJUROS_RECB"]= VL_TXJUROS_RECB_VAL;
			data["VL_TXMULTA_RECB"] = VL_TXMULTA_RECB_VAL;
			data["vl_taxacobranca_recb"] = vl_taxacobranca_recb_VAL;
			data["DT_GERACAO_RECB"] = data.DT_GERACAO_RECB;
			data["DT_COMPETENCIA_RECB"] = data.DT_COMPETENCIA_RECB;
			
			incomeService.postLiquidity(data).then((res) => {
				setloader_on(false);
				if (res.type == "success" || res.type == "Success") {
					dispatch(deleteBillingTicketCron(data.ID_RECEBIMENTO_RECB))
					setDouble(false);
					reset();
					onClose();
					getChargesSummery();
					Swal.fire({
						title: t("Sweetalert.Success"),
						text: t("Sweetalert.successget"),
						// icon: 'success',"success",
						confirmButtonText: "OK",
					})
				} else {
					Swal.fire({
						title: t("Sweetalert.Error"),
						text: t("Sweetalert.somethingWent"),
						// icon: 'success',"error",
						confirmButtonText: "OK",
					})
				}
			})
		}
	}

	let currentDate = moment().format("YYYY-MM-DD");
	return (
		<Modal show={show} id="toReceiveLiquidate" onHide={handleClose}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Header closeButton>
					<Modal.Title>{t("expenditure.liquidate")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
					<input type="hidden" {...register('ID_CONTRATO_CON')} />
						<Col hidden>
							<FloatingLabel controlId="ID_RECEBIMENTO_RECB" label="ID_RECEBIMENTO_RECB">
								<Form.Control type="text" placeholder="ID_RECEBIMENTO_RECB" {...register("ID_RECEBIMENTO_RECB")} />
								<Form.Control type="text" placeholder="DT_GERACAO_RECB" {...register("DT_GERACAO_RECB")} />
								<Form.Control type="text" placeholder="DT_COMPETENCIA_RECB" {...register("DT_COMPETENCIA_RECB")} />
							</FloatingLabel>
							<FloatingLabel controlId="origionalAmount" label="origionalAmount">
								<Form.Control type="text" placeholder="origionalAmount" {...register("origionalAmount")} />
							</FloatingLabel>
							<FloatingLabel controlId="settleAmount" label="settleAmount">
								<Form.Control type="text" placeholder="settleAmount" {...register("settleAmount")} />
							</FloatingLabel>
						</Col>
						
						<Col md={6}>
							<FloatingLabel controlId="sale_off" label={t("ReceiveandReceived.Saleoff")}>
								<Form.Control type="date" className={`form-control ${errors.DT_LIQUIDACAO_RECB ? 'is-invalid' : ''}`} placeholder={t("ReceiveandReceived.Saleoff")} {...register("DT_LIQUIDACAO_RECB")} onChange={(e) => recalculate(e, "saleOff")} />
								<Form.Control.Feedback type="invalid">
									{errors.DT_LIQUIDACAO_RECB?.message}
								</Form.Control.Feedback>
							</FloatingLabel>
						</Col>
						<Col md={6}>
							<FloatingLabel controlId="credit" label={t("formLabel.credit")}>
								<Form.Control type="date" className={`form-control ${errors.DT_RECEBIMENTO_RECB ? 'is-invalid' : ''}`} placeholder={t("formLabelcredit")} {...register("DT_RECEBIMENTO_RECB")} />
								<Form.Control.Feedback type="invalid">
									{errors.DT_RECEBIMENTO_RECB?.message}
								</Form.Control.Feedback>
							</FloatingLabel>
						</Col>
						<Col md={6}>
							<FloatingLabel controlId="amount_paid" label={t("ReceiveandReceived.Amountpaid")}>
								<Form.Control type="text" className={`form-control ${errors.VL_TOTAL_RECB ? 'is-invalid' : ''}`} placeholder={t("ReceiveandReceived.Amountpaid")} {...register("VL_TOTAL_RECB")} onChange={(e) => settleAmtCalculate(e, "VL_TOTAL_RECB")} />
								<Form.Control.Feedback type="invalid">
									{errors.VL_TOTAL_RECB?.message}
								</Form.Control.Feedback>
							</FloatingLabel>
						</Col>
						<Col md={6}>
							<FloatingLabel controlId="discount" label={t("formReceipt.discount")}>
								<Form.Control type="text" placeholder={t("formReceipt.discount")} {...register("VL_DESCONTOCALCULADO_RECB")} readOnly={dis ? true : false} onChange={(e) => recalculate(e, "discount")} />
							</FloatingLabel>
						</Col>
						<Col md={6}>
							<FloatingLabel controlId="form_payment" label={t("formLabel.formOfPayment")}>
								<Form.Select aria-label="Form of payment" className={`form-control ${errors.ID_FORMAPAGAMENTO_RECB ? 'is-invalid' : ''}`} {...register("ID_FORMAPAGAMENTO_RECB")}>
									<option value="">{t("formLabel.select")}</option>
									{
										billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
											<option key={index} value={Number(item.id)}>{item.name}</option>
										))
											: ''
									}
								</Form.Select>
								<Form.Control.Feedback type="invalid">
									{errors.ID_FORMAPAGAMENTO_RECB?.message}
								</Form.Control.Feedback>
							</FloatingLabel>
						</Col>
						<Col md={6}>
							<Form.Check type="checkbox" id="print_receipt" label={t("expenditure.printreceipt")} {...register("print_receipt")} />
						</Col>
					</Row>
					<Form.Check type="checkbox" className="mb-lg-4 mb-3" id="debit_bank_fee" label={t("ReceiveandReceived.Debitbankfee")} {...register("debit_bank_fee")} />
					
					<div className="bg-light p-3 rounded-4" style={{display: diffAmt ? "block" : "none" }}>
						<p>{ t("diffAmt") } <span className="text-danger-40"> <span className='ms-1'> R$ </span> { diffAmt ? converter(String(diffAmt)) : "0,00" }</span></p>
						<p className="text-danger">{ errors.isCharge?.message }</p>
                         	<Form.Group className="mb-3">
                                <Form.Check type="radio" value="3" label={t("toDisregard")} {...register("isCharge")} onChange={(e) =>issCharge(e, "toDisregard")}/>
                           </Form.Group>                        
                            <Form.Group className="mb-3">
                                <Form.Check type="radio" value="1" label={t("lunchCharge")} {...register("isCharge")} onChange={(e) =>issCharge(e, "lunchCharge")} />
                            </Form.Group>
                        
                        { 
                        	diffHide ? 
                        	<>	
	                         	<Form.Group>
	                                <Form.Check type="radio" value="2" label={t("createCharge")} {...register("isCharge")} onChange={(e) =>issCharge(e, "createCharge")}/>
	                           	</Form.Group>	                           	                
	                        </>
	                        :  ''
	                    }
                    </div>			
                    <br/>
                    <FloatingLabel controlId="credit" label={t("chargeDate")} style={{display: diffDateHide ? "block" : "none" }}>
						<Form.Control type="date" className={`form-control ${errors.chargeDate ? 'is-invalid' : ''}`} placeholder={t("chargeDate")} {...register("chargeDate")} />
						<Form.Control.Feedback type="invalid">
							{ errors.chargeDate?.message }
						</Form.Control.Feedback>
					</FloatingLabel>	
                    <Accordion className="accordion-custom">
						<Accordion.Item eventKey="0">
							<Accordion.Header>{t("ReceiveandReceived.Legaladditions")}</Accordion.Header>
							<Accordion.Body>
								<Row className="gy-lg-4 gy-3">
									<Col md={4}>
										<FloatingLabel controlId="fees" label={t("ReceiveandReceived.Interest")}>
											<Form.Control type="text" placeholder={t("ReceiveandReceived.Interest")} {...register("VL_TXJUROS_RECB")} readOnly={fin ? true : false} onChange={(e) => recalculate(e, "interest")} />
										</FloatingLabel>
									</Col>
									<Col md={4}>
										<FloatingLabel controlId="traffic_ticket" label={t("formReceipt.trafficTicket")}>
											<Form.Control type="text" placeholder={t("formReceipt.trafficTicket")} {...register("VL_TXMULTA_RECB")} readOnly={fin ? true : false} onChange={(e) => recalculate(e, "fine")} />
										</FloatingLabel>
									</Col>
									<Col md={4}>
										<FloatingLabel controlId="monetary" label={t("formReceipt.atmonetary")}>
											<Form.Control type="text" placeholder={t("formReceipt.atmonetary")} {...register("vl_taxacobranca_recb")} readOnly={fin ? true : false} onChange={(e) => recalculate(e, "monetary")} />
										</FloatingLabel>
									</Col>
								</Row>

							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>{t("terminatelist.Check")}</Accordion.Header>
							<Accordion.Body>
								<Row className="gy-lg-4 gy-3">
									<Col hidden>
										<FloatingLabel controlId="ID_CHEQUE_PRE" label="ID_CHEQUE_PRE" >
											<Form.Control type="text" placeholder="ID_CHEQUE_PRE" {...register("ID_CHEQUE_PRE")} />
										</FloatingLabel>
									</Col>
									<Col md={4}>
										<FloatingLabel controlId="check_number" label={t("ReceiveandReceived.Checknumber")}>
											<Form.Control type="number" className={`form-control ${errors.NM_CHEQUE_PRE ? 'is-invalid' : ''}`} placeholder={t("ReceiveandReceived.Checknumber")} {...register("NM_CHEQUE_PRE")} />
											<Form.Control.Feedback type="invalid">
												{errors.NM_CHEQUE_PRE?.message}
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md={4}>
										<FloatingLabel controlId="bank_number" label={t("ReceiveandReceived.Banknumber")}>
											<Form.Control type="number" className={`form-control ${errors.ST_BANCO_PRE ? 'is-invalid' : ''}`} placeholder={t("ReceiveandReceived.Banknumber")} {...register("ST_BANCO_PRE")} />
											<Form.Control.Feedback type="invalid">
												{errors.ST_BANCO_PRE?.message}
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md={4}>
										<FloatingLabel controlId="agency" label={t("banks.agency")}>
											<Form.Control type="number" className={`form-control ${errors.ST_AGENCIA_PRE ? 'is-invalid' : ''}`} placeholder={t("banks.agency")} {...register("ST_AGENCIA_PRE")} />
											<Form.Control.Feedback type="invalid">
												{errors.ST_AGENCIA_PRE?.message}
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md={6}>
										<FloatingLabel controlId="account" label={t("banks.accountNo")}>
											<Form.Control type="number" className={`form-control ${errors.ST_CONTA_PRE ? 'is-invalid' : ''}`} placeholder={t("banks.accountNo")} {...register("ST_CONTA_PRE")} />
											<Form.Control.Feedback type="invalid">
												{errors.ST_CONTA_PRE?.message}
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md={6}>
										<FloatingLabel controlId="good_for" label={t("ReceiveandReceived.Goodfor")}>
											<Form.Control type="date" placeholder={t("ReceiveandReceived.Goodfor")} {...register("DT_VENCIMENTO_PRE")} />
										</FloatingLabel>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>{t("formLabel.moreFields")}</Accordion.Header>
							<Accordion.Body>
								<FloatingLabel className="mb-lg-4 mb-3" controlId="observation" label={t("contractlistother.Internalobservation")}>
									<Form.Control as="textarea" placeholder={t("contractlistother.Internalobservation")} {...register("ST_OBSERVACAOINTERNA_RECB")} />
								</FloatingLabel>
								<FloatingLabel controlId="bank_account" label={t("bankAccount")}>
									<Form.Select aria-label={t("bankAccount")} {...register("ID_CONTA_CB")}>
										<option value="">{t('dropdownItem.select')}..</option>
										{accountData.data && accountData.data?.length > 0 ?
											accountData.data.map((curElem: any, index) => {
												return (
													<option key={index} value={curElem.id}>{curElem.accountRef}</option>
												)
											})
											: <>
												<h3 style={{ width: "100%", textAlign: "center", color: "red" }}>{t("OOPS")}!</h3>
											</>
										}
									</Form.Select>
								</FloatingLabel>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<span className="d-block mb-3 fw-bold">{t("ReceiveandReceived.Summary")}</span>
					{
						calculateData &&
						<Row>
							<Col md={4} className="border-sm-none border-end">
								<span className="d-block"> {t("ReceiveandReceived.Issued")}: {converter(`${calculateData.total}`)}</span>
								<span className="d-block">(+) {t("ReceiveandReceived.Fine")}: {converter(`${calculateData.fine}`)}</span>
								<span className="d-block">(+) {t("ReceiveandReceived.Interest")}: {converter(`${calculateData.interest}`)}</span>
								<span className="d-block">(+) {t("formReceipt.atMonetary")}: {converter(`${calculateData.monetary}`)}</span>
								<span className="d-block">(-) {t("formReceipt.discount")}: {converter(`${calculateData.discount}`)}</span>
							</Col>
							<Col md={4} className="border-sm-none border-end">
								<span className="d-block">(=) {t("ReceiveandReceived.Due")} {converter(`${calculateData.due}`)}</span>
								<span className="d-block">(=) {t("ReceiveandReceived.Paid")} {converter(`${calculateData.paid}`)}</span>
								{/* <span className="d-block">(-) Bank rate</span> */}
							</Col>
							<Col md={4}>
								<span className="d-block">(=) {t("ReceiveandReceived.Credited")} {converter(`${calculateData.credited}`)}</span>
							</Col>
						</Row>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-primary" onClick={handleClose}>
						{t("button.close")}
					</Button>
					{   loader_on ?
                            <Button className="btn-width" disabled={double}>
                                <span className="spinner-border spinner-border-sm"></span>
                                <span className="px-1">{t("expenditure.liquidate")}</span>
                            </Button>
                        :
							<Button disabled={double} variant="primary" type="submit" onClick={openErrorbox}>
								{t("expenditure.liquidate")}
							</Button>
					}
				</Modal.Footer>
			</Form>
		</Modal>
	)
}
export default ChargeLiquidate