import React, { useState } from "react";
import { Card, Dropdown, Form, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useBankAccountDrawer } from "../../../../../newComponents/components/BankAccountDrawer";

import {
  AccountChange,
  updateStatus,
} from "../../../../../redux/slice/bankSlice";
import {
  addUpdateWayof,
  getFormsofReceipt,
  updateStatesWayof,
} from "../../../../../redux/slice/formsOfReceiptSlice";
import { AppDispatch } from "../../../../../redux/store";
import {
  BankAccountInfo,
  BankAccountsNotFound,
  SuccessToast,
} from "./components";
import { RECEIVIMENT_METHOD_TYPES } from "./constants";
import type { ReceivingMethod } from "./definitions";
import { useBankAccounts } from "./hooks/useBankAccounts";
import { deleteBankAccount, deleteHomologatedBankAccount } from "./services";

export function BankAccounts() {
  const [successToast, setSuccessToast] = useState({
    visible: false,
    message: "",
  });
  const { bankAccounts, refreshBankAccounts } = useBankAccounts();
  const { setShowBankAccountDrawer } = useBankAccountDrawer();
  const dispatch = useDispatch<AppDispatch>();

  async function bankAccountModal({
    title,
    subtitle,
    confirmButtonText,
    bankAccount,
  }: {
    title: string;
    subtitle: string;
    confirmButtonText: string;
    bankAccount: any;
  }) {
    return Swal.fire({
      title,
      html: `
        <h6>${subtitle}</h6>
        <div style="color: #808080">
            <p class="mt-2 mb-1" style="color: #404040">${bankAccount.nameReference}</p>
            <p class="mb-1 fs-12"><strong>Banco: </strong>${bankAccount.bankFullName}</p>
            <p class="mb-1 fs-12">
                <strong>Tipo de conta: </strong>${bankAccount.accountTypeDescription}
            </p>
            <p class="fs-12">
                <strong>Agência: </strong>${bankAccount.agency}
                <strong class="ms-2"> Conta: </strong>${bankAccount.account}
            </p>
        </div>
        `,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText,
      showCloseButton: true,
    });
  }

  async function handleMakeBankAccountAsMain(bankAccount: any) {
    if (bankAccount.main_ac === 1) {
      Swal.fire({
        title: "Atenção",
        text: "Esta ação não pode ser realizada. A conta selecionada já é a principal.",
        confirmButtonText: "Ok",
      });

      return;
    }

    bankAccountModal({
      title: "Tornar esta minha conta principal",
      subtitle:
        "Ao continuar, você tornará a seguinte conta sua conta principal:",
      bankAccount,
      confirmButtonText: "Continuar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(AccountChange(bankAccount.id));
        if (response.payload.type === "Success") {
          await refreshBankAccounts();
          setSuccessToast({
            visible: true,
            message: "Conta principal alterada",
          });
        }
      }
    });
  }

  async function handleDeleteBankAccount(bankAccount: any) {
    if (bankAccount.main_ac === 1) {
      Swal.fire({
        title: "Atenção",
        text: "Esta ação não pode ser realizada. A conta selecionada é a principal.",
        confirmButtonText: "Ok",
      });

      return;
    }

    if (
      Object.values(bankAccount.receivingMethods).some(
        (receivingMethod: any) => receivingMethod.isActive,
      )
    ) {
      Swal.fire({
        title: "Atenção",
        text: "Esta ação não pode ser realizada. A conta selecionada está vinculada a alguma forma de recebimento ativa.",
        confirmButtonText: "Ok",
      });

      return;
    }

    bankAccountModal({
      title: "Excluir conta bancária",
      subtitle:
        "Ao continuar, você excluirá todos os dados bancários da seguinte conta:",
      bankAccount,
      confirmButtonText: "Deletar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await deleteBankAccount(bankAccount.id);
          if (result.type === "error") {
            throw new Error(result.message);
          }

          await refreshBankAccounts();

          setSuccessToast({
            visible: true,
            message: "Conta bancária deletada com sucesso.",
          });

          if (bankAccount.homologatedAccount) {
            await deleteHomologatedBankAccount(
              bankAccount.homologatedAccount.id,
            );
          }
        } catch (err: any) {
          Swal.fire({
            title: "Erro",
            text: err.message,
            confirmButtonText: "Ok",
          });
        }
      }
    });
  }

  async function handleUpdateBankAccountStatus(bankAccount: any) {
    if (
      Object.values(bankAccount.receivingMethods).some(
        (receivingMethod: any) => receivingMethod.isActive,
      )
    ) {
      Swal.fire({
        title: "Atenção",
        text: "Esta ação não pode ser realizada. A conta selecionada está vinculada a alguma forma de recebimento ativa.",
        confirmButtonText: "Ok",
      });

      return;
    }

    const isActive = bankAccount.status !== 1;

    bankAccountModal({
      title: `${isActive ? "Desativar" : "Ativar"} conta bancária`,
      subtitle: `Ao continuar, você ${
        isActive ? "desativará" : "ativará"
      } a seguinte conta:`,
      confirmButtonText: isActive ? "Desativar" : "Ativar",
      bankAccount,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          id: [bankAccount.id],
          status: isActive ? 1 : 0,
        };
        const response = await dispatch(updateStatus(data));

        if (response.payload.type === "Success") {
          await refreshBankAccounts();
          setSuccessToast({
            visible: true,
            message: `Conta bancária ${
              isActive ? "desativada" : "ativada"
            } com sucesso`,
          });
        }
      }
    });
  }

  function isReceivingMethodAllowedForAccountType(
    accountType: string,
    receivingMethod: number,
  ) {
    const allowedReceivingMethodsByAccountType = [
      { accountType: "1", receivingMethods: [0, 1, 2] },
      { accountType: "2", receivingMethods: [2] },
      { accountType: "3", receivingMethods: [0, 2] },
    ];

    const selectedAccountType = allowedReceivingMethodsByAccountType.find(
      (data) => data.accountType === accountType,
    );

    if (!selectedAccountType) {
      return false;
    }

    return selectedAccountType.receivingMethods.includes(receivingMethod);
  }

  async function handleUpdateReceivimentMethodStatus({
    element,
    bankAccount,
    receivingMethod,
    type,
  }: {
    element: HTMLInputElement;
    bankAccount: any;
    receivingMethod?: ReceivingMethod;
    type: number;
  }) {
    if (
      !isReceivingMethodAllowedForAccountType(bankAccount.accountType, type)
    ) {
      return;
    }

    let receivingMethodToUpdate = receivingMethod ?? ({} as ReceivingMethod);

    try {
      element.disabled = true;
      if (!receivingMethod) {
        const data = {
          ID_CONTA_CB: bankAccount.id,
          FL_BOLETOSUPERLOGICA_FRECB: type,
          action: "new",
        };

        const response = await dispatch(addUpdateWayof(data));
        const responseReceivingMethods = await dispatch(getFormsofReceipt());
        const receivingMethodCreated =
          responseReceivingMethods.payload.data.find(
            (recMethod: any) =>
              recMethod["ID_FORMA_FRECB"] === response.payload.data.insertId,
          );

        receivingMethodToUpdate = {
          id: receivingMethodCreated["ID_FORMA_FRECB"],
          type: receivingMethodCreated["FL_BOLETOSUPERLOGICA_FRECB"],
          isActive: receivingMethodCreated["FL_PRODUCAO_FRECB"] === 0,
        };
      }

      const obj = {
        ID_FORMA_FRECB: receivingMethodToUpdate.id,
        FL_PRODUCAO_FRECB: receivingMethodToUpdate.isActive ? 1 : 0,
        ID_CONTA_CB: bankAccount.id,
        FL_BOLETOSUPERLOGICA_FRECB: receivingMethodToUpdate.type, // receiving method type 0 = kenlo pay, 1 = homologated, 2 = caixa
        ACTION: "changeStatus",
      };

      const res = await dispatch(updateStatesWayof(obj));
      if (res.payload.type === "Success") {
        dispatch(getFormsofReceipt());
        setSuccessToast({
          visible: true,
          message: `${RECEIVIMENT_METHOD_TYPES[receivingMethodToUpdate.type]} ${
            receivingMethodToUpdate.isActive ? "desativada" : "ativada"
          } com sucesso para a conta ${bankAccount.nameReference} `,
        });
      }
    } catch (err: any) {
      Swal.fire({
        title: "Erro",
        text: `Falha ao ${
          receivingMethodToUpdate.isActive ? "desativar" : "ativar"
        } forma de recebimento ${
          RECEIVIMENT_METHOD_TYPES[receivingMethodToUpdate.type]
        }. Atualize a página e tente novamente.`,
        confirmButtonText: "Ok",
      });
    } finally {
      element.disabled = false;
    }
  }

  return (
    <Card>
      <Card.Body>
        <Table
          responsive="lg"
          className="table table-custom table-custom-bordered mb-0"
        >
          <thead>
            <tr>
              <th className="text-start">Contas bancárias</th>
              <th className="text-center">Kenlo pay</th>
              <th className="text-center">Homologada</th>
              <th className="text-center">Caixa manual</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!bankAccounts || bankAccounts.length <= 0 ? (
              <tr>
                <td colSpan={5}>
                  <BankAccountsNotFound />
                </td>
              </tr>
            ) : (
              bankAccounts.map((bankAccount) => (
                <tr key={bankAccount.id}>
                  <td>
                    <BankAccountInfo bankAccount={bankAccount} />
                  </td>
                  {bankAccount.accountType === "2" ? (
                    <td colSpan={2}>
                      <div
                        className="d-flex align-items-center px-3 py-1 rounded-3"
                        style={{
                          backgroundColor: "#f2f2f2",
                          maxWidth: "15.5rem",
                        }}
                      >
                        <p className="text-dark-80 fs-12 fw-normal mb-0">
                          Esta conta não permite o uso de Kenlo Pay e conta
                          homologada
                        </p>
                      </div>
                    </td>
                  ) : (
                    <>
                      <td className="px-0" style={{ width: "7.5rem" }}>
                        <div className="d-flex align-items-center gap-1">
                          <Form.Switch
                            id="kenlo-pay-switch"
                            defaultChecked={
                              bankAccount.receivingMethods?.kenloPay &&
                              bankAccount.receivingMethods.kenloPay.isActive
                            }
                            disabled={
                              bankAccount.status === 1 ||
                              bankAccount.accountType === "2"
                            }
                            onClick={(e: any) =>
                              handleUpdateReceivimentMethodStatus({
                                element: e.target,
                                bankAccount,
                                receivingMethod:
                                  bankAccount.receivingMethods?.kenloPay,
                                type: 0,
                              })
                            }
                          />
                          <Form.Label
                            htmlFor="kenlo-pay-switch"
                            className="text-start fs-12 fw-normal align-middle mb-0"
                          >
                            Boleto automático com split
                          </Form.Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <Form.Switch
                          id="homologated-switch"
                          defaultChecked={
                            bankAccount.receivingMethods?.homologated &&
                            bankAccount.receivingMethods.homologated.isActive
                          }
                          disabled={
                            !bankAccount.isHomologated ||
                            bankAccount.status === 1 ||
                            bankAccount.accountType === "3"
                          }
                          onClick={(e: any) =>
                            handleUpdateReceivimentMethodStatus({
                              element: e.target,
                              bankAccount,
                              receivingMethod:
                                bankAccount.receivingMethods?.homologated,
                              type: 1,
                            })
                          }
                        />
                      </td>
                    </>
                  )}
                  <td className="text-center">
                    <Form.Switch
                      id="manual-cash-switch"
                      defaultChecked={
                        bankAccount.receivingMethods?.manualCash &&
                        bankAccount.receivingMethods.manualCash.isActive
                      }
                      disabled={bankAccount.status === 1}
                      onClick={(e: any) =>
                        handleUpdateReceivimentMethodStatus({
                          element: e.target,
                          bankAccount,
                          receivingMethod:
                            bankAccount.receivingMethods?.manualCash,
                          type: 2,
                        })
                      }
                    />
                  </td>
                  <td>
                    <Dropdown className="radio--custom" autoClose="outside">
                      <Dropdown.Toggle
                        variant="link"
                        size="sm"
                        className="mainStatus dropdown-arrow-none p-0"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/dots_danger.svg"
                          }
                          className="header-logo"
                          alt="Dots"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            setShowBankAccountDrawer({
                              action: "edit",
                              bankAccount,
                            })
                          }
                        >
                          Editar conta
                        </Dropdown.Item>
                        {bankAccount.main_ac !== 1 && (
                          <Dropdown.Item
                            onClick={() =>
                              handleMakeBankAccountAsMain(bankAccount)
                            }
                          >
                            Tornar principal
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={() =>
                            handleUpdateBankAccountStatus(bankAccount)
                          }
                        >
                          {bankAccount.status === 0 ? "Desativar" : "Ativar"}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDeleteBankAccount(bankAccount)}
                        >
                          Deletar
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card.Body>
      <SuccessToast
        show={successToast.visible}
        message={successToast.message}
        onClose={() => setSuccessToast({ visible: false, message: "" })}
      />
    </Card>
  );
}
