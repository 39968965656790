import React, { useEffect, useState } from "react";
import { ComProps } from "../../../Interface";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { Bankslip, update_bank_slip, editBank, get_blocking_and_cancellation, update_blocking, getFormsofReceipt, updateStatesWayof, get_bank_slip } from "../../../../redux/slice/formsOfReceiptSlice";
import { Card, Col, Row, Form, Button, FloatingLabel, Dropdown, Table, Accordion, Modal, Alert } from "react-bootstrap";
import { Practiced_rates } from "./Practiced_rates";
import { Link, useNavigate } from "react-router-dom";
import { AccountChange, Main_Account, getBankList, get_BankAccountList, updateStatus } from "../../../../redux/slice/bankSlice";
import BankCredits from "./BankCredits";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import Swal from "sweetalert2";
import { encrypt_Decrypt } from "../../../constants/utils";
import { numericOnly } from "../../../Pages/Contracts/Common/actionPerform";

const Formreceipt = ({ submenu }: ComProps) => {
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation();
    // Used Redux-Toolkit, Got the data and Destructors the data from forms of receipt slice inside the redux folder
    const { userbanklist, status } = useSelector((state: RootState) => state.bank);
    const { Bankslip, blocking, wayofreceiving, status1 } = useSelector((state: RootState) => state.forms);
    const [newwayShow, setNewWayShow] = useState(false)
    const [othersShow, setOthersShow] = useState(false)
    const [block, setBlock] = useState('')
    const [accounttype, setaccounttype] = useState("");
    const [check, setCheck] = useState<number>();
    // used it to show validation error on form
    const validationSchema = yup.object().shape({
        // Updatethevalue: yup.string().required("Update the value of the ticket on the second copy is required"),
        // sprated: yup.string().required("number is required"),
        // envirment: yup.string().required("envirment is required"),
    });
    const blockingSchema = yup.object().shape({
        day: yup.string().required("day is required"),
    })
    // used it to show validation error on form crate a variable
    var frm = {
        resolver: yupResolver(validationSchema),
    };
    // This line initializes the useDispatch hook and assigns it to the dispatch variable
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3120) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
    const [crtsld, setcrtSld] = useState<any>({
        formid: [],
        bankid: [],
        status: "",
        accounttype: ""
    })
    // Represents the homologation and Production environment
    const [environments, setEnvironments] = useState([
        { value: "1", label: "Homologation" },
        { value: "2", label: "Production" },
    ]);


    const [healthInsurance, sethealthInsurance] = useState([
        // { value: "1", label: "Technospeed" },
        { value: "0", label: "Kenlo Pay" },
        { value: "2", label: "Manual" },
    ]);


    const [checkeddata, setCheckData] = useState({
        tradename: false,
        bodyslip: false,
    });

    if (block == "blocking") {
        // Use blockingSchema as the resolver for form validation
        var frm = {
            resolver: yupResolver(blockingSchema),
        };
    }
    // The useForm hook is used to handle form registration, submission, and validation.
    const { handleSubmit, register, setValue, getValues, formState: { errors }, } = useForm<Bankslip>(frm);
    useEffect(() => {
        // Dispatching an asynchronous action to get bank list
        dispatch(getBankList(obj));
        (async () => {
            // Dispatching an immediately invoked asynchronous function to get forms of receipt
            dispatch(getFormsofReceipt());
            dispatch(get_bank_slip())
        })()
        dispatch(get_blocking_and_cancellation())
    }, [dispatch]);

    useEffect(() => {
        // Set field values from Bankslip data
        const fields: string[] = ["Updatethevalue", "sprated", "envirment", "Billing_Instructions", "messageticket",];
        if (Bankslip && Bankslip.length > 0) {
            fields.forEach((field: any) => setValue(field, Bankslip[0]?.[`${field}`]));
        }
        const fields1: string[] = ["tradename", "displaytheamount", "displaythetotal", "companylogo", "pendingcharges", "sortbillet", "donotdisplay",];
        if (Bankslip && Bankslip.length > 0) {
            fields1?.forEach((field: any) => setValue(field, Bankslip[0]?.[`${field}`] === "0" ? false : true));
            setCheck(Bankslip[0]?.['displaytheamount'])

        }
        // Set day and id values from blocking data
        const days: string[] = ["day", "id"];
        days.forEach((field: any) => setValue(field, blocking[0]?.[field]));

    }, [blocking, Bankslip]);

    //Getting the data shown when the page is rendered for the first time  
    useEffect(() => {
        if (check == 1) {
            document.querySelector(".Show_simple_nacional")?.removeAttribute('hidden')
        } else {
            document.querySelector(".Show_simple_nacional")?.setAttribute('hidden', '')
        }
    }, [check])
    const crltsetData = (formid, bankid, status, banktype) => {
        // Set the formid, bankid, status, and banktype in the crtSld state
        setcrtSld({
            formid: formid != "" ? [formid] : [],
            bankid: bankid != "" ? [bankid] : [],
            status: status,
            accounttype: banktype
        })
        // Set the accounttype state with the banktype value
        setaccounttype(banktype)
        setValue('bankaccount', bankid);
    }

    const handleStatus = async (status: number, id: number, accid: any, banktype: any) => {
        let countActive = 0;
        // Count the number of active wayofreceiving elements
        wayofreceiving.forEach((curElem, ind: number) => {
            if (curElem["FL_PRODUCAO_FRECB"] == 0) {
                countActive++
            }
        })
        // Retrieve the current account from userbanklist
        let current_account = userbanklist.filter(item => Number(item.id) == Number(accid));
        if (current_account[0].status == 1) {
            // Display a warning if the account is not active
            Swal.fire({
                // icon: 'success',"info",
                title: `${t('Sweetalert.Warning')}`,
                text: `${t('Sweetalert.activeBank')}`,
                showConfirmButton: true
            });
        } else {
            let obj = {
                ID_FORMA_FRECB: id, // form_receipt id
                FL_PRODUCAO_FRECB: status, // status of account
                ID_CONTA_CB: accid, // account id
                FL_BOLETOSUPERLOGICA_FRECB: banktype, // account type like kobana, arian or manual
                ACTION: 'changeStatus'
            }

            // Dispatch an action to update statesWayof using updateStatesWayof function
            let res = await dispatch(updateStatesWayof(obj));
            if (res.payload.type == "Success") {
                // Display a success message if the update is successful
                Swal.fire({
                    // icon: 'success',"success",
                    title: `${t('Sweetalert.Success')}`,
                    text: `${t('Sweetalert.statusUpdate')}`,
                    showConfirmButton: true,
                    timer: 2000,
                });
                dispatch(getFormsofReceipt());
                setNewWayShow(false)
            } else {
                // Display an error message if the update fails
                Swal.fire({
                    title: `${t('Sweetalert.Error')}`,
                    text: `${t('Sweetalert.somethingWent')}`,
                    // icon: 'success',"error",
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 2000
                });
            }
            // }
            // }
        }
    };
    // Function to handle bank credit show
    const BankCreditShow = (bankid, formid, status, type) => {
        // Call crltsetData function with formid, bankid, status, and type parameters
        crltsetData(formid, bankid, status, type)
        dispatch(editBank(true));
    };

    const New_Way_of_received = () => {
        // Clearing the data and setting state values
        crltsetData("", "", "", "")
        setNewWayShow(true)
        setValue("accounttype", "");
        setaccounttype("")
    }
    const handleClose = () => {
        // Clearing the data and setting state values
        crltsetData("", "", "", "")
        setNewWayShow(false)
    }
    const Other_Action_show = () => {
        // Set the "othersShow" state to true, indicating that the "Others" action should be shown
        setOthersShow(true)
    }
    const Other_Action_close = () => {
        // Set the "othersShow" state to false, indicating that the "Others" action should be closed
        setOthersShow(false)
    }
    const way_of_rec = () => {
        // Check if accounttype is not empty
        if (accounttype !== "") {
            setNewWayShow(false)
            dispatch(editBank(true));
        } else {
            // Show an error message if accounttype is empty
            Swal.fire({
                // icon: 'success',"error",
                title: `${t('Sweetalert.healthInsurance')}`,
                text: `${t('Sweetalert.selectHealthInsurance')}`,
                showConfirmButton: true,
            });
        }
    }

    const OtherAction = () => {
        // Hide the 'Others' section
        setOthersShow(false);
        // Dispatch an action to enable bank editing
        dispatch(editBank(true));
    }
    // Function to handle the account type change
    const accounttypeHandle = (e) => {
        setaccounttype("")
        // Check if the selected value is not empty
        if (e.target.value !== "") {
            setaccounttype(e.target.value)
        }
    }

    const HandleEdit = (e: any, cmd: string, data: string) => {
        // Set the block data
        setBlock(data)
        e.preventDefault();
        // Get references to the form and button elements
        let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement;
        let thisButton: any = e.target.parentElement.parentElement.parentElement;
        if (cmd == "edit") {
            // Enable input fields within form-floating elements
            if (thisDivform.querySelectorAll(".form-floating").length) {
                thisDivform
                    .querySelectorAll(".form-floating input, textarea")
                    .forEach((item: any, index: number) => {
                        item.removeAttribute("disabled");
                    });
                thisDivform
                    .querySelectorAll('.form-check input[type="checkbox"]')
                    .forEach((item: any, index: number) => {
                        item.removeAttribute('disabled');
                    });
                thisDivform
                    .querySelectorAll(".form-floating select")
                    .forEach((item: any, index: number) => {
                        item.removeAttribute("disabled");
                    });
            }
            // Update button visibility
            if (
                thisButton.querySelectorAll(".BtnAction.Update, .BtnAction.Edit")
                    .length > 0
            ) {
                thisButton
                    .querySelector(".BtnAction.Update")
                    ?.removeAttribute("hidden");
                thisButton.querySelector(".BtnAction.Edit")?.setAttribute("hidden", "");
            }
        }
    };
    // Function to handle checkbox change event
    const checkChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        let checkcheckdata = e.target;
        let name = e.target.name;
        if (checkcheckdata.checked) {
            // If checkbox is checked, update the checkData state with the corresponding name as true
            setCheckData({
                ...checkeddata,
                [name]: true,
            });
            setCheck(1)
        } else {
            // If checkbox is unchecked, update the checkData state with the corresponding name as false
            setCheckData({
                ...checkeddata,
                [name]: false,
            });
            setCheck(0)
        }
    };
    // Define an object with properties
    let obj = {
        start: "0",
        end: "100",
    };

    const onSubmit = async (data: any, e: any) => {
        e.preventDefault();
        let thisDivform: any = e.target.parentElement.parentElement;
        let thisButton: any = e.target.parentElement;
        // Extract form data and create a bank slip object
        let bankslip = {
            sprated: data.sprated,
            messageticket: data.messageticket,
            tradename: data.tradename,
            Label_Type: data.Label_Type,
            Updatethevalue: data.Updatethevalue,
            donotdisplay: data.donotdisplay,
            Billing_Instructions: data.Billing_Instructions,
            displaytheamount: data.displaytheamount,
            displaythetotal: data.displaythetotal,
            companylogo: data.companylogo,
            sortbillet: data.sortbillet,
            congratulations: data.congratulations,
            pendingcharges: data.pendingcharges,
            envirment: data.envirment
        };
        // Dispatch an action to update the form data
        let res = await dispatch(update_bank_slip(bankslip));
        if (res.payload.type == "Success") {
            Swal.fire({
                // icon: 'success',"success",
                title: `${t('Sweetalert.Success')}`,
                text: `${t('Sweetalert.RecordUpdated')}`,
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 2000
            });
            dispatch(get_bank_slip())
            if (thisDivform.querySelectorAll(".form-floating").length) {
                thisDivform.querySelectorAll(".form-floating textarea, .form-check input[type='checkbox']").forEach((item: any) => {
                    item.setAttribute("disabled", "");
                });
            }

            if (
                thisButton.querySelector(".BtnAction.Update") ||
                thisButton.querySelector(".BtnAction.Edit")
            ) {
                thisButton.querySelector(".BtnAction.Update")?.setAttribute("hidden", "");
                thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000,
            });
        }
        // Show a success message to the user
    };

    //When we first have to show the data in the input field
    const submitCustom = async (data: any, e: any) => {
        let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement;
        let thisButton: any = e.target.parentElement;
        let Days = {
            day: data.day,
            // day: 60,
        };
        let res = await dispatch(update_blocking(Days))
        if (res.payload.type == "Success") {
            // Show a success message using Swal
            Swal.fire({
                position: 'center',
                // icon: 'success','success',
                title: `${t('Sweetalert.Success')}`,
                text: `${t('Sweetalert.RecordUpdated')}`,
                showConfirmButton: false,
                timer: 2000
            });

            if (thisDivform.querySelectorAll(".form-floating").length) {
                thisDivform.querySelectorAll(".form-floating input").forEach((item: any, index: number) => {
                    item.setAttribute("disabled", "");
                });
            }
            if (
                thisButton.querySelector(".BtnAction.Update") ||
                thisButton.querySelector(".BtnAction.Edit")
            ) {
                thisButton.querySelector(".BtnAction.Update")?.setAttribute("hidden", "");
                thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
            }

        } else {
            // Show error message if the branch creation fails
            Swal.fire({
                position: 'center',
                // icon: 'success','error',
                title: `${t('Sweetalert.Error')}`,
                text: `${t('Sweetalert.somethingWent')}`,
                showConfirmButton: true,
            })
        }

    };

    const deleteAccountHandle = (formid) => {
        Swal.fire({
            title: `${t("Sweetalert.Warning")}`,
            text: `${t("Sweetalert.deleteBankAccount")}`,
            // icon: 'success',"warning",
            showCancelButton: true,
            confirmButtonText: `${t("Sweetalert.yesDeleteIt")}`
        }).then(async (result) => {
            if (result.isConfirmed == true) {
                let obj = {
                    ID_FORMA_FRECB: formid, // form_receipt id  
                    ACTION: 'delete'
                }
                let ress = await dispatch(updateStatesWayof(obj));
                if (ress.payload.type == "Success") {
                    // Display a success message if the update is successful
                    Swal.fire({
                        // icon: 'success',"success",
                        title: `${t('Sweetalert.Success')}`,
                        text: `${t('Sweetalert.deleteRecord')}`,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    dispatch(getFormsofReceipt());
                } else {
                    // Display an error message if the update fails
                    Swal.fire({
                        title: `${t('Sweetalert.Error')}`,
                        text: `${t('Sweetalert.somethingWent')}`,
                        // icon: 'success',"error",
                        confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                        timer: 2000
                    });
                }
            } else {
                console.log("no")
            };
        });
    }

    const handleCancel = (e, type) => {
        let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement;
        let thisButton: any = e.target.parentElement.parentElement;
        if (type == "blocking") {
            dispatch(get_blocking_and_cancellation())
        } else {
            dispatch(get_bank_slip())
        }
        if (thisDivform.querySelectorAll(".form-floating").length) {
            thisDivform.querySelectorAll(".form-floating textarea, .form-check input[type='checkbox']").forEach((item: any) => {
                item.setAttribute("disabled", "");
            });
        }
        if (thisDivform.querySelectorAll(".form-floating").length) {
            thisDivform.querySelectorAll(".form-floating input").forEach((item: any, index: number) => {
                item.setAttribute("disabled", "");
            });
        }
        if (
            thisButton.querySelector(".BtnAction.Update") ||
            thisButton.querySelector(".BtnAction.Edit")
        ) {
            thisButton.querySelector(".BtnAction.Update")?.setAttribute("hidden", "");
            thisButton.querySelector(".BtnAction.Edit")?.removeAttribute("hidden");
        }
    }

    const [totalTextCount, setTotalTextCount] = useState(0);
    const [totalTextCounta, setTotalTextCounta] = useState(0);

    useEffect(() => {
        let getValueLength = getValues('Billing_Instructions')?.length || 0;
        setTotalTextCount(250 - getValueLength);

        let getValueLengtha = getValues('messageticket')?.length || 0;
        setTotalTextCounta(250 - getValueLengtha);

    }, [getValues('Billing_Instructions'), getValues('messageticket')])

    const handleCharacter = (e) => {
        let insertLenght = (e.target.value).length;
        setTotalTextCount(250 - insertLenght);
    }

    const handleCharactera = (e) => {
        let insertLenght = (e.target.value).length;
        setTotalTextCounta(250 - insertLenght);
    }

    const [typeAction, setTypeAction] = useState<any>({
        main: true,
        status: false
    })

    const PrimaryAccount = (e: any, id:any, activeStatus: any) => {
        if (activeStatus == 1 || activeStatus == -1) {
            Swal.fire({
                title: `${t("Sweetalert.Warning")}`,
                text: `${t("activateAccountFirst")}`,
                icon: 'warning',
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`
            });
            return; // Exit the function if the account is inactive
        }


        let check = e.target;
        // Uncheck all other input elements
        document.querySelectorAll('.newUserCh1 input').forEach((item) => {
            item["checked"] = false;
        })
        setTimeout(() => {
            // Check the selected input element
            check["checked"] = true;
            Swal.fire({
                title: `${t("Sweetalert.Warning")}`,
                text: `${t("Sweetalert.changeMainAccount")}`,
                // icon: 'success',"warning",
                showCancelButton: true,
                confirmButtonText: `${t("Sweetalert.changeIt")}`,
            }).then(async (res) => {
                if (res.isConfirmed) {
                    // Dispatch the account change action
                    let update = await dispatch(AccountChange(id))
                    // dispatch(Main_Account())
                    // Dispatch the Main_Account action
                    if (update.payload.type == "Success") {
                        dispatch(Main_Account())
                    };
                    // Dispatch the getBankList action
                    dispatch(getBankList(obj))
                    dispatch(get_BankAccountList(obj))
                    dispatch(getFormsofReceipt());
                } else {
                    check["checked"] = false;
                }
            })
        }, 100)
    }


    return (
        <section className="content-wrapper Formreceipttsx" id="wayofreceivingtsx">
            {/* <!-- Breadcrumb section start from here --> */}
            {submenu}
            {/* <!-- /Breadcrumb section end -->
            <!-- Main content start from here --> */}
            <>
                <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                    <Row className="gy-3 gy-lg-4">

                        <Col lg={8}>
                            {wayofreceiving && wayofreceiving.length > 0 ? (
                                wayofreceiving.map((curElem, ind: number) => {
                                    let current_account: any = userbanklist.filter(item => Number(item.id) == Number(curElem["ID_CONTA_CB"]));
                                    if (current_account && current_account?.[0]?.status == 0 && current_account?.[0]?.accountType != "2") {
                                        if (curElem["FL_PRODUCAO_FRECB"] == "0" && curElem["CON_AARIN_ID"] == null) {
                                            return (
                                                <></>
                                                // <Alert variant="danger">
                                                //     {t("formReceipt.Pleaseconfigureyour")}  {curElem["ST_DESCRICAO_CB"]} {t("formReceipt.account")} <Link
                                                //         to={"/bank_accounts"}
                                                //         className="fw-bold text-nowrap"
                                                //     // key={ind}
                                                //     >{t("formReceipt.here")}</Link>.
                                                // </Alert>
                                            )
                                        }
                                    }
                                })
                            ) : (
                                <></>
                            )
                            }
                            <Accordion alwaysOpen defaultActiveKey={['0', '1', '2']}>
                                {/* Ways of receving section start from here */}
                                <Accordion.Item className="position-relative" eventKey="0">
                                    <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                                        {userJson && userJson.length > 0 && (userJson.indexOf(3121) !== -1) &&
                                            <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0" onClick={New_Way_of_received}>  {t("formReceipt.newWayOfReceiving")}</Button>
                                        }
                                    </div>
                                    <Accordion.Header>
                                        {t("formReceipt.waysOfReceiving")}
                                    </Accordion.Header>
                                    <Accordion.Body className="pb-1">
                                        {/* <!-- Table start from here --> */}
                                        <Table responsive="lg" className="table-custom table-custom-bordered mb-0 frmreceipt-wt1010">
                                            <thead>
                                                <tr>
                                                    <td className="text-start">{t("mainAccountStatus")}</td>
                                                    <td>{t("formLabel.name")}</td>
                                                    <td className="text-center">{t("dropdownItem.status")}</td>
                                                    <td className="text-end">{t("dropdownItem.connected")}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {wayofreceiving && wayofreceiving.length > 0 ? (
                                                    wayofreceiving.map((curElem, ind) => {
                                                        let current_account = userbanklist.filter(
                                                            (item) => Number(item.id) === Number(curElem["ID_CONTA_CB"])
                                                        );
                                                        if (current_account && current_account[0]?.status === 0) {
                                                            return (
                                                                <>
                                                                    {curElem["FL_PRINCIPAL_CB"] === 1 ? (
                                                                        <tr key={ind}>
                                                                            <td className="text-start">
                                                                                <img
                                                                                    src="assets/images/icons/oval-star.svg"
                                                                                    alt="star"
                                                                                    className="h-20 cursor-pe"
                                                                                />
                                                                            </td>
                                                                            <td>{curElem["ST_DESCRICAO_CB"]}</td>
                                                                            <td className="text-center">
                                                                                {userJson && userJson.length > 0 && userJson.indexOf(3121) !== -1 && (
                                                                                    <Dropdown className="dropdown--custom" autoClose="outside">
                                                                                        <Dropdown.Toggle
                                                                                            variant=""
                                                                                            size="sm"
                                                                                            className={
                                                                                                curElem["FL_PRODUCAO_FRECB"] === 1 ||
                                                                                                    curElem["FL_PRODUCAO_FRECB"] === -1
                                                                                                    ? "btn-link border bg-light border-light text-dark rounded-3 fs-12 px-2"
                                                                                                    : "bg-success-30 text-dark border-success-30 fs-12 px-2"
                                                                                            }
                                                                                        >
                                                                                            {curElem["FL_PRODUCAO_FRECB"] === 1 ||
                                                                                                curElem["FL_PRODUCAO_FRECB"] === -1 ? (
                                                                                                <a>{t("formReceipt.disabled")}</a>
                                                                                            ) : (
                                                                                                t("formReceipt.active")
                                                                                            )}
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            {curElem["FL_PRODUCAO_FRECB"] !== 0 && (
                                                                                                <Dropdown.Item
                                                                                                    onClick={() =>
                                                                                                        handleStatus(
                                                                                                            0,
                                                                                                            curElem["ID_FORMA_FRECB"],
                                                                                                            curElem["ID_CONTA_CB"],
                                                                                                            curElem["FL_BOLETOSUPERLOGICA_FRECB"]
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {t("formReceipt.active")}
                                                                                                </Dropdown.Item>
                                                                                            )}
                                                                                            {curElem["FL_PRODUCAO_FRECB"] !== 1 &&
                                                                                                curElem["FL_PRODUCAO_FRECB"] !== -1 && (
                                                                                                    <Dropdown.Item
                                                                                                        onClick={() =>
                                                                                                            handleStatus(
                                                                                                                1,
                                                                                                                curElem["ID_FORMA_FRECB"],
                                                                                                                curElem["ID_CONTA_CB"],
                                                                                                                curElem["FL_BOLETOSUPERLOGICA_FRECB"]
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {t("formReceipt.disabled")}
                                                                                                    </Dropdown.Item>
                                                                                                )}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                )}
                                                                            </td>
                                                                            <td className="text-end">
                                                                                {curElem["showPaymentMethod"] ? (
                                                                                    curElem["FL_BOLETOSUPERLOGICA_FRECB"] === 0
                                                                                        ? "Kenlo pay"
                                                                                        : curElem["FL_BOLETOSUPERLOGICA_FRECB"] === 1
                                                                                            ? "Technospeed"
                                                                                            : curElem["FL_BOLETOSUPERLOGICA_FRECB"] === 2
                                                                                                ? "Manual"
                                                                                                : ""
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        <tr key={ind}>
                                                                            <td className="text-start">

                                                                                {typeAction.main ?

                                                                                    <Form.Check type="checkbox" className="mb-lg-4 mb-3 newUserCh1" id={"newUserCh1" + ind} onClick={(e) => PrimaryAccount(e, curElem["ID_CONTA_CB"], curElem["FL_PRODUCAO_FRECB"])} />
                                                                                    :
                                                                                    <Form.Check type="checkbox" className="mb-lg-4 mb-3 newUserCh1" id={"newUserCh1" + ind} data-id={curElem["ID_CONTA_CB"]} />

                                                                                }

                                                                            </td>
                                                                            <td>{curElem["ST_DESCRICAO_CB"]}</td>
                                                                            <td className="text-center">
                                                                                {userJson && userJson.length > 0 && userJson.indexOf(3121) !== -1 && (
                                                                                    <Dropdown className="dropdown--custom" autoClose="outside">
                                                                                        <Dropdown.Toggle
                                                                                            variant=""
                                                                                            size="sm"
                                                                                            className={
                                                                                                curElem["FL_PRODUCAO_FRECB"] === 1 ||
                                                                                                    curElem["FL_PRODUCAO_FRECB"] === -1
                                                                                                    ? "btn-link border bg-light border-light text-dark rounded-3 fs-12 px-2"
                                                                                                    : "bg-success-30 text-dark border-success-30 fs-12 px-2"
                                                                                            }
                                                                                        >
                                                                                            {curElem["FL_PRODUCAO_FRECB"] === 1 ||
                                                                                                curElem["FL_PRODUCAO_FRECB"] === -1 ? (
                                                                                                <a>{t("formReceipt.disabled")}</a>
                                                                                            ) : (
                                                                                                t("formReceipt.active")
                                                                                            )}
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            {curElem["FL_PRODUCAO_FRECB"] !== 0 && (
                                                                                                <Dropdown.Item
                                                                                                    onClick={() =>
                                                                                                        handleStatus(
                                                                                                            0,
                                                                                                            curElem["ID_FORMA_FRECB"],
                                                                                                            curElem["ID_CONTA_CB"],
                                                                                                            curElem["FL_BOLETOSUPERLOGICA_FRECB"]
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {t("formReceipt.active")}
                                                                                                </Dropdown.Item>
                                                                                            )}
                                                                                            {curElem["FL_PRODUCAO_FRECB"] !== 1 &&
                                                                                                curElem["FL_PRODUCAO_FRECB"] !== -1 && (
                                                                                                    <Dropdown.Item
                                                                                                        onClick={() =>
                                                                                                            handleStatus(
                                                                                                                1,
                                                                                                                curElem["ID_FORMA_FRECB"],
                                                                                                                curElem["ID_CONTA_CB"],
                                                                                                                curElem["FL_BOLETOSUPERLOGICA_FRECB"]
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {t("formReceipt.disabled")}
                                                                                                    </Dropdown.Item>
                                                                                                )}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                )}
                                                                            </td>
                                                                            <td className="text-end">
                                                                                {curElem["showPaymentMethod"] ? (
                                                                                    curElem["FL_BOLETOSUPERLOGICA_FRECB"] === 0
                                                                                        ? "Kenlo pay"
                                                                                        : curElem["FL_BOLETOSUPERLOGICA_FRECB"] === 1
                                                                                            ? "Technospeed"
                                                                                            : curElem["FL_BOLETOSUPERLOGICA_FRECB"] === 2
                                                                                                ? "Manual"
                                                                                                : ""
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan={4} className="text-center">
                                                            {t("datanotfound")}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>

                                        {/* <!-- /Table end --> */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* /Ways of receving section end */}
                                {/* Bank slip options section start from here */}
                                <Accordion.Item className="position-relative" eventKey="1">
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                                            <div className="BtnAction Edit">
                                                <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0 me-2 me-xl-3 me-xxl-4"><Link to="/billing_rule">{t("formReceipt.configureBillingRule")} </Link></Button>
                                                {userJson && userJson.length > 0 && (userJson.indexOf(3121) !== -1) &&
                                                    <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0" onClick={(e) => HandleEdit(e, "edit", "bankslip")}> {t("button.edit")}</Button>}
                                            </div>
                                            <div className="BtnAction Update" hidden>
                                                <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0 me-3" onClick={(e) => handleCancel(e, "bankslip")}>{t("button.cancel")}</Button>
                                                <Button className="fw-bold lh-1 text-primary p-0" variant="link" size="sm" type="submit" id="bankslip"> {t("button.update")}</Button>
                                            </div>
                                        </div>
                                        <Accordion.Header>
                                            {t("formReceipt.bankSlipOptions")}
                                        </Accordion.Header>
                                        <Accordion.Body className="pb-1">
                                            {/* <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                                    <Col md={5}>
                                                        <FloatingLabel controlId="floatingSelect" label={t("ticketonthesecondcopy")}>
                                                            <Form.Select className={`${errors.Updatethevalue ? "is-invalid" : ""}`} aria-label="Auto (Recomendado)" {...register("Updatethevalue")} disabled>
                                                                <option value="0">Auto (Recomendado)</option>
                                                                <option value="1">Nunca atualizar boleto</option>
                                                                <option value="2">Atualizar se banco emissor for diferente do pagador</option>
                                                                <option value="3">Sempre atualizar boleto</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">{errors.Updatethevalue?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FloatingLabel controlId="floatingInputValue" label={t("sprate")}>
                                                            <Form.Control type="number" className={`${errors.sprated ? "is-invalid" : ""}`} placeholder="1549" {...register("sprated")} disabled />
                                                            <Form.Control.Feedback type="invalid">{errors.sprated?.message}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={5}>
                                                        <FloatingLabel controlId="floatingSelect" label={t("formLabel.environment")}>
                                                            <Form.Select className={`${errors.envirment ? "is-invalid" : ""}`} {...register("envirment")} aria-label="Produção" disabled>
                                                                <option value="">--environment--</option>
                                                                {environments && environments.length > 0 &&
                                                                    environments.map((curElem: any, ind: number) => (
                                                                        <option key={ind} value={curElem.value}>
                                                                            {curElem.label}
                                                                        </option>
                                                                    ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.envirment?.message}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row> */}
                                            <FloatingLabel
                                                controlId="floatingInputValue"
                                                label={t("additionalbillinginstruct")}
                                                className="mb-lg-4 mb-3"
                                            >
                                                <Form.Control
                                                    as="textarea" disabled
                                                    className={`${errors.Billing_Instructions ? "is-invalid" : ""}`}
                                                    {...register("Billing_Instructions")}
                                                    placeholder={t("additionalbillinginstruct")}
                                                    onChange={(e) => handleCharacter(e)}
                                                    maxLength={250}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.Billing_Instructions?.message}
                                                </Form.Control.Feedback>

                                                {totalTextCount} {totalTextCount !== 0 ? t("formReceipt.characterremains") : t("formReceipt.characterremain")}
                                            </FloatingLabel>


                                            {/* <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" {...register("tradename")} label={t("formReceipt.tradeName")} id="newUserCh1" />
                                                <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" {...register("displaytheamount")} label={t("formReceipt.displayTheAmount")} id="newUserCh2" onChange={(e) => checkChecked(e)} />
                                                <div className="Show_simple_nacional">
                                                    <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" id="newUserCh3" {...register("displaythetotal")} label={t("formReceipt.displayTheTotal")} />
                                                </div>
                                                <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" {...register("companylogo")} label={t("formReceipt.doNotDisplay")} id="companylogo" />
                                                <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" {...register("congratulations")} label={t("formReceipt.congratulations")} id="newUserCh2" />
                                                <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" id="newUserCh3" {...register("pendingcharges")} label={t("formReceipt.pendingCharges")} />
                                                <Form.Check type="checkbox" disabled className="mb-lg-4 mb-3" {...register("sortbillet")} label={t("formReceipt.sortBillet")} id="newUserCh1" /> */}
                                            <FloatingLabel controlId="floatingInputValue" label={t("messageonalltickets")} {...register("messageticket")} className="mb-lg-4 mb-3">
                                                <Form.Control as="textarea" disabled className={`${errors.messageticket ? "is-invalid" : ""}`} placeholder="Cambui" {...register("messageticket")} onChange={(e) => handleCharactera(e)} maxLength={250} />
                                                <Form.Control.Feedback type="invalid">{errors.messageticket?.message} </Form.Control.Feedback>
                                                {totalTextCounta} {totalTextCounta !== 0 ? t("formReceipt.characterremains") : t("formReceipt.characterremain")}
                                            </FloatingLabel>
                                            <Form.Check className="mb-3 mb-lg-4" disabled {...register("donotdisplay")} label={t("formReceipt.doNotDisplaycomposition")} type="checkbox" id="newUserCh11" />
                                        </Accordion.Body>
                                    </Form>
                                </Accordion.Item>
                                {/* /Bank slip options section end */}
                                {/* Blocking and cancelation section start from here */}
                                <Accordion.Item className="position-relative" eventKey="2">
                                    <Form onSubmit={handleSubmit(submitCustom)}>
                                        <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                                            <div className="BtnAction Edit">
                                                {userJson && userJson.length > 0 && (userJson.indexOf(3121) !== -1) &&
                                                    <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0" onClick={(e) => HandleEdit(e, "edit", "blocking")}>{t("button.edit")}</Button>}
                                            </div>
                                            <div className="BtnAction Update" hidden>
                                                <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0 me-3" onClick={(e) => handleCancel(e, "blocking")}>{t("button.cancel")}</Button>
                                                <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0" type="submit" id="blocking">{t("button.update")}</Button>
                                            </div>
                                        </div>
                                        <Accordion.Header>
                                            {t("formReceipt.blockingAndCancellation")}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <FloatingLabel controlId="floatingSelect" label={t("daytounavailable") + " *"}>
                                                <Form.Control type="text" className={`${errors.day ? "is-invalid" : ""}`} {...register("day")} placeholder="Additional Billing Instructions" disabled onKeyDown={numericOnly} />
                                                <Form.Control.Feedback type="invalid"> {errors.day?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Accordion.Body>
                                    </Form>
                                </Accordion.Item>
                                {/* /Blocking and cancelation section end */}
                            </Accordion>
                        </Col>
                        <Practiced_rates />
                    </Row>
                </main>
                {/* <!-- /Main content start end --> */}
                <BankCredits crtsld={crtsld} setcrtSld={setcrtSld} accounttype={accounttype} setaccounttype={setaccounttype} />
            </>

            <Modal show={newwayShow} onHide={handleClose} className="FormofReceiptForm">
                <Modal.Header closeButton>
                    <Modal.Title>{t("formReceipt.selectwayofbankheader")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="warning" className="mb-lg-4 mb-3 fw-medium">
                        {/* <div>{t("formReceipt.Youcanissueslipsusingyou")}.</div> */}
                        <div>{t("formReceipt.selectwayofbankcontent")}</div>
                        {/* <strong>{t("formReceipt.WerecommendKobanaslips")}</strong> {t("formReceipt.astheyarefullyautomated")}. <a className="fw-bold">{t("formReceipt.knowmore")}</a> */}
                    </Alert>
                    <FloatingLabel controlId="floatingSelect" label={t("bankTransaction.Paymentmethod") + " *"} className="mt-4">
                        <Form.Select className={`${errors.envirment ? 'is-invalid' : ''}`} {...register('accounttype')} onChange={e => accounttypeHandle(e)}
                            aria-label="Produção">
                            <option value="">{t("formLabel.select")}</option>
                            {healthInsurance && healthInsurance.length > 0 &&
                                healthInsurance.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    )
                                })
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.envirment?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        {t("button.toClose")}
                    </Button>
                    <Button variant="primary" onClick={way_of_rec}>
                        {t("button.next")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={othersShow} onHide={Other_Action_close}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("formReceipt.HireKobana")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="mb-lg-4 mb-3">{t("formReceipt.HireKobanatoreceivewithacreditcard")}?</h5>
                    <Alert variant="warning" className="mb-0 fw-medium">
                        <div>{t("formReceipt.Youcanissueslipsusingyou")}.</div>
                        <strong>{t("formReceipt.WerecommendKobanaslips")}</strong> {t("formReceipt.astheyarefullyautomated")}. <a className="fw-bold">{t("formReceipt.knowmore")}</a>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={Other_Action_close}>
                        {t("button.toClose")}
                    </Button>
                    <Button variant="primary" onClick={OtherAction}>
                        {t("button.next")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default Formreceipt;

