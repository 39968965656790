import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { gettoken } from "../../services/apiConfig";
import { config } from "../../services/apiConfig";
import customerService from "../../services/customerService";
let connectionName: any = '';
if (localStorage.getItem("userData")) {
    connectionName = localStorage.getItem("userData");
}


export const STATUSES = Object.freeze({
    LOADING: "loading",
    SUCEESS: "success",
    ERROR: 'error'
});
export interface mailData {
    variable: string;
    description: string;
    status: string;
    subject: string;
    content: string;
    layout: string;
    sender: string;
    to: any;
    cc: string[];
    bcc: string[];
    identifier: string;
    model: string;
    caption: string;
    title: string;
    desc: any
}

interface toBePaidState {
    emailsList: any
    status: string,
    emailTemplateLst: any
    templatevariable: any
    listTemplatefolder:any
    listTemplateInsideFolder: any,
    listTemplateContents: any,
}

const initialState: toBePaidState = {
    emailsList: [],
    emailTemplateLst: [],
    templatevariable:[],
    listTemplatefolder: [],
    listTemplateInsideFolder: [],
    listTemplateContents: [],
    status: STATUSES.LOADING,
};

const SendEmailSlice = createSlice({
    name: "SendEmailSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(emailPost.pending, (state) => {
                state.status = STATUSES.LOADING
            })
            .addCase(emailPost.fulfilled, (state, action) => {
                state.status = STATUSES.SUCEESS
            })
            .addCase(emailPost.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
            builder
            .addCase(emailPostSave.pending, (state) => {
                state.status = STATUSES.LOADING
            })
            .addCase(emailPostSave.fulfilled, (state, action) => {
                state.status = STATUSES.SUCEESS
            })
            .addCase(emailPostSave.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
        builder
            .addCase(allEmaillist.pending, (state, action) => {
                state.status = STATUSES.LOADING
            })
            .addCase(allEmaillist.fulfilled, (state, action) => {
                state.status = STATUSES.SUCEESS
            })
            .addCase(allEmaillist.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
            builder
            .addCase(allEmailTemplate.pending, (state, action) => {
                state.status = STATUSES.LOADING
            })
            .addCase(allEmailTemplate.fulfilled, (state, action) => {
                state.emailTemplateLst = action.payload.data[0]
                state.templatevariable = action.payload.data[1]
                state.status = STATUSES.SUCEESS
            })
            .addCase(allEmailTemplate.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
            builder
            .addCase(listTemplate.pending, (state, action) => {
                state.status = STATUSES.LOADING
            })
            .addCase(listTemplate.fulfilled, (state, action) => {
                // console.log(action.payload, "222")
                state.listTemplatefolder = action.payload.data
                state.status = STATUSES.SUCEESS
            })
            .addCase(listTemplate.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
            builder
            .addCase(listTemplatesInsideFolder.pending, (state, action) => {
                state.status = STATUSES.LOADING
            })
            .addCase(listTemplatesInsideFolder.fulfilled, (state, action) => {
                // console.log(action.payload, "222")
                state.listTemplateInsideFolder = action.payload.data
                state.status = STATUSES.SUCEESS
            })
            .addCase(listTemplatesInsideFolder.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
            builder
            .addCase(listTemplateContent.pending, (state, action) => {
                state.status = STATUSES.LOADING
            })
            .addCase(listTemplateContent.fulfilled, (state, action) => {
                state.listTemplateContents = action.payload.data
                state.status = STATUSES.SUCEESS
            })
            .addCase(listTemplateContent.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
            builder
            .addCase(singleemailfetchData.pending, (state, action) => {
                state.status = STATUSES.LOADING
            })
            .addCase(singleemailfetchData.fulfilled, (state, action) => {
                state.status = STATUSES.SUCEESS
            })
            .addCase(singleemailfetchData.rejected, (state) => {
                state.status = STATUSES.ERROR
            })
    },
});

export default SendEmailSlice.reducer

// GET CATEGORY 
export const emailPost = createAsyncThunk(
    'emailPost',
    async (postData: mailData) => {

        var raw = JSON.stringify({
            "url": config.emailApi,
            "Authorization": "Basic Vmd3V3REeHFkN1B0NnpjOXI2dVhwdThOOmUlIWdYV1BJUUVZJVJteWIhNyNPOTVCbE1Pam1PbVBLJDVyOQ==",
            data: postData,
            ["user_id"]: 1
        });
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(config.companyV2_URL + "/send_email/send_email", {
            method: "POST",
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json',
                'connection-name': connectionName
            },
            body: raw
        })
        const data = await response.json();
        return data
    }
)

export const emailPostTransfer = createAsyncThunk(
    'emailPost_transfer',
    async (postData: mailData) => {
        var raw = JSON.stringify({
            "url": config.emailApi,
            "Authorization": "Basic Vmd3V3REeHFkN1B0NnpjOXI2dVhwdThOOmUlIWdYV1BJUUVZJVJteWIhNyNPOTVCbE1Pam1PbVBLJDVyOQ==",
            data: postData,
            ["user_id"]: 1
        });
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(config.companyV2_URL + "/send_email/send_email_transfer", {
            method: "POST",
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json',
                'connection-name': connectionName
            },
            body: raw
        })
        const data = await response.json();
        return data
    }
)

export const emailPostSave = createAsyncThunk(
    'emailPostAve',
    async (postData: mailData) => {

        var raw = JSON.stringify({
            "url": config.emailApi,
            "Authorization": "Basic Vmd3V3REeHFkN1B0NnpjOXI2dVhwdThOOmUlIWdYV1BJUUVZJVJteWIhNyNPOTVCbE1Pam1PbVBLJDVyOQ==",
            data: postData,
            ["user_id"]: 1
        });
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(config.companyV2_URL + "/send_email/send_email_save", {
            method: "POST",
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json',
                'connection-name': connectionName
            },
            body: raw
        })
        const data = await response.json();
        return data
    }
)
export const singleemailPost = createAsyncThunk(
    'singleemailPost',
    async (postData:any) => {
        let reqobj = JSON.stringify({
            id: postData
        })
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.companyV2_URL}/send_email/get_single_email`, {
            method: "POST",
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json',
                'connection-name': connectionName
            },
            body: reqobj
        })
        const data = await response.json();
        return data
    }
)

export const singleemailfetchData = createAsyncThunk(
    'singleemailfetchData',
    async (postData: any) => {
     
        let reqobj = JSON.stringify({
            email: postData
        });
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.companyV2_URL}/send_email/get_single_email_data`, {
            method: "POST",
            headers: {
                'x-auth-token': token,
                'Content-type': 'application/json',
                'connection-name': connectionName
            },
            body: reqobj
        })
        const data = await response.json();
        return data
    }
)

export const allEmaillist = createAsyncThunk(
    'posts/list',
    async (postData: any) => {
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.companyV2_URL}/send_email/getAllEmail`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'x-auth-token': token,
                'connection-name': connectionName
            },
        });

        const data = await response.json();
    }
);

export const allEmailTemplate = createAsyncThunk(
    'posts/emailTemplate',
    async (postData:any) => {
        let token:any = localStorage.getItem("tokenV2");
        const response = await fetch(`${config.TRANSFER_API}/selectEmailTemplate`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
              'x-auth-token': token,
              'connection-name' : connectionName
            },
        });
  
        const data = await response.json();
        return data
    }
  );

  export const listTemplate = createAsyncThunk(
    'posts/listTemplateFolder',
    async () => {
        let token:any = localStorage.getItem("tokenV2");
      const response = await fetch(`${config.TRANSFER_API}/listTemplateFolder`, {
        method: 'GET',
        headers: {
          'x-auth-token': token,
          'connection-name' : connectionName
        },
      });
  
      const data = await response.json();
      return data
    }
  );


  export const listTemplatesInsideFolder = createAsyncThunk(
    'contract/listTemplatesInsideFolder',
    async (postData:any) => {
      let resp = await customerService.listTemplatesInsideFolder(postData);
      let data =  JSON.parse(JSON.stringify(resp)).data;
      return data;    
    }
  ); 

  export const listTemplateContent = createAsyncThunk(
    'contract/listTemplateContent',
    async (template_id:any) => {
      let resp = await customerService.listTemplateContent(template_id);
      let data =  JSON.parse(JSON.stringify(resp)).data;
      return data;    
    }
  ); 


  