import { config } from "../../../../../services/apiConfig";
import { getLocalStorageItem } from "../../../shared/utils/getLocalStorageItem";

export class BankSlipService {
  private readonly token: string = getLocalStorageItem("tokenV2");
  private readonly userData: string = getLocalStorageItem("userData");
  private readonly url = `${config.TRANSFER_API}/homologate/bankslip/bankslip`;

  async cancelInBank(ids: string[]) {
    const response = await fetch(`${this.url}/cancel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": this.token,
        "connection-name": this.userData,
      },
      body: JSON.stringify({ ids }),
    });

    const data = await response.json();

    return data;
  }

  async exportToRemittanceFile(ids: string[]) {
    const response = await fetch(`${this.url}/files`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": this.token,
        "connection-name": this.userData,
      },
      body: JSON.stringify({ ids }),
    });

    const data = await response.json();

    return data;
  }

  async getAllHomologatedBankslip() {
    const response = await fetch(this.url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": this.token,
        "connection-name": this.userData,
      },
    });

    const data = await response.json();

    return data;
  }

  async uploadReturnFile(fileContent: string) {
    const response = await fetch(`${this.url}/upload`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": this.token,
        "connection-name": this.userData,
      },
      body: JSON.stringify({
        arquivo: fileContent,
      }),
    });

    const data = await response.json();

    return data;
  }

  async discardBankSlip(ids: string[]) {
    const response = await fetch(this.url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": this.token,
        "connection-name": this.userData,
      },
      body: JSON.stringify({ ids }),
    });

    const data = await response.json();

    return data;
  }

  async getBankSlipById(id: string) {
    const response = await fetch(`${this.url}?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": this.token,
        "connection-name": this.userData,
      },
    });

    const data = await response.json();

    return data.data?.bankSlip?.at(0)
  }
}
