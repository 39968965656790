import React, { useEffect, useState } from 'react';
import { Dropdown, Col, Row, InputGroup, Form, Button, Modal, Accordion, Alert, FloatingLabel, Collapse } from 'react-bootstrap';
import SendEmailOverview from './SendEmailOverview';
import SendEmailListing from './SendEmailListing';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { allEmaillist } from '../../../../redux/slice/SendEmailSlice';
// const [t] = useTranslation();

const SentEmail = () => {
    //Bouncing email modal initialization
    const [t] = useTranslation();
    const dispatch = useDispatch()
    const [showBouncingEmailModal, setBouncingEmailModal] = useState(false);
    const bouncingEmailModalClose = () => setBouncingEmailModal(false);
    const bouncingEmailModalShow = () => setBouncingEmailModal(true);
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                <h1 className="mb-0 lh-base text-truncate">{t("formLabel.sendEmail")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                />
                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="moreDropdown">
                                            {t('button.more')}
                                            <img src="assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>{t("sendEmail.ForceSend")}</Dropdown.Item>
                                            <Dropdown.Item onClick={bouncingEmailModalShow}>{t("sendEmail.RemoveEmailBounceList")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li> */}
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                    </a>
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">{t("dropdownItem.filters")}</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t("dropdownItem.status")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="px-4">
                                        <div className="py-2"> {t("formReceipt.all")} </div>
                                        <div className="py-2">{t("pending")}</div>
                                        <div className="py-2">{t("sent")}</div>
                                        <div className="py-2">{t("sendEmail.Returning")}</div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t("dropdownItem.Seenonline")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="px-4">
                                        <div className="py-2">{t("formReceipt.all")} </div>
                                        <div className="py-2">{t("sendEmail.unseen")} </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- overview -->*/}
                <SendEmailOverview />
                {/* <!-- /overview -->*/}
                {/*<!-- Table start from here -->*/}
                <SendEmailListing />
                {/*<!-- /Table end -->*/}
                <p className="my-lg-4 my-3">{t("formLabel.listing")} 50 {t("sendEmail.emailsBeing")} 50 {t("sent")}.</p>
                {/* <!-- Nav listing start from here -->*/}
                <ul className="list-inline mb-0">
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm">
                                {t("formLabel.mark")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#">{t("all")}</Dropdown.Item>
                                <Dropdown.Item href="#">{t("formLabel.none")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("formLabel.withTagged")}</span></li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button variant="light" size="sm">{t("button.delete")}</Button>
                    </li>
                </ul>
                {/*<!-- /Nav listing  end -->*/}
            </main>
            {/*<!-- /Main content end -->*/}
            {/* Bouncing email modal start from here */}
            <Modal show={showBouncingEmailModal} onHide={bouncingEmailModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("sendEmail.BouncingEmail")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Accordion defaultActiveKey="0" className="accordion-custom">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("sendEmail.RemoveEmailFromList")}</Accordion.Header>
                                <Accordion.Body>
                                    <Alert className="mb-lg-4 mb-3" variant="warning">
                                        {t("sendEmail.RemoveEmailFromListContent")}
                                    </Alert>
                                    <FloatingLabel className="mb-lg-4 mb-3" controlId="Email" label="Email">
                                        <Form.Control type="email" placeholder="Email" />
                                    </FloatingLabel>
                                    <img src="assets/images/temp/code-img.jpg" alt="code" className="img-fluid mb-lg-4 mb-3" />
                                    <FloatingLabel controlId="enter_code" label="Enter the code from above">
                                        <Form.Control type="text" placeholder="Enter the code from above" />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={bouncingEmailModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={bouncingEmailModalClose}>
                            {t("sendEmail.ToRemove")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Bouncing email modal end */}
        </section>
    )
}

export default SentEmail