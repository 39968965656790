import { Accordion, FloatingLabel, Modal, Form, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Liquidate, Edit_new_expense } from '../../../redux/slice/toBePaidSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { openErrorbox } from '../Contracts/Common/actionPerform';

const Liquidate2 = (props) => {
    const [t] = useTranslation();
    const Schema = yup.object().shape({
        payment_method: yup.string().required(t("requiredMmessage.fieldRequired")),
        date: yup.string().required(t("requiredMmessage.fieldRequired")),
        value: yup.string().required(t("requiredMmessage.fieldRequired")),
        check_number: yup.string().required(t("requiredMmessage.fieldRequired")),
        bank_account: yup.string().required(t("requiredMmessage.fieldRequired")),
    })
    const { showLiquidate2, liquidateClose2, liquidateId } = props;
    const dispatch = useDispatch()
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<Liquidate>({
        resolver: yupResolver(Schema)
    });

    // Used Redux-Toolkit, Got the data and Destructors the data from companyDataSlice inside the redux folder
    const { expenses_list } = useSelector((state: RootState) => state.toBePaid);
    const [files, setFiles] = useState<{ name: string; data: string }[]>([]);
    useEffect(() => {
        (async () => {
            let thisisliquidity = expenses_list.filter(item => item.id == liquidateId);
            if (thisisliquidity.length) {
                const fields: string[] = Object.keys(thisisliquidity[0]);
                fields.forEach((field: any) => {
                    if (field == "date") {
                        setValue(field, (thisisliquidity[0]?.[field]).split('/').reverse().join("-"));
                    } else {
                        setValue(field, thisisliquidity[0]?.[field]);

                    }
                });
            }
        })();
    }, [dispatch, liquidateId])

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(e.target.files || []);

        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const dataUrl = reader.result as string;
                const data = dataUrl.split(',')[1];
                setFiles((prevFiles) => [...prevFiles, { name: file.name, data }]);
            };
            reader.readAsDataURL(file);
        });
    };

    const handleAddFileControl = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.onchange = (ev: Event) =>
            handleFileChange(ev as unknown as React.ChangeEvent<HTMLInputElement>);
        fileInput.click();
    };
    const onSubmit = (data: any) => {
        let obj = {
            payment_method: data.payment_method,
            date: data.date,
            value: data.value,
            check_number: data.check_number,
            bank_account: data.bank_account,
        }
        Swal.fire({
            // icon: 'success','success',
            title: t("Sweetalert.Success"),
            text: t("Sweetalert.successget"),
            showConfirmButton: false,
            timer: 2000
        })
        liquidateClose2()
    }

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });
    };

    return (
        <Modal show={showLiquidate2} onHide={liquidateClose2}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Liquidate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel label="Payment method" className="mb-3 mb-lg-4" >
                        <Form.Select aria-label="Payment method" className={`${errors.payment_method?.message ? 'is-invalid' : ''}`} {...register("payment_method")}>
                            <option value="1">{t("all")}</option>
                            <option value="2">{t("customerAndEmployees.Only")} 1 {t("customerAndEmployees.propertie")}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.payment_method?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="sale-off" label="Sale of" className="mb-3 mb-lg-4">
                        <Form.Control type="date" placeholder="Sale of" className={`${errors.date?.message ? 'is-invalid' : ''}`}   {...register("date")} />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                    <FloatingLabel controlId="value" label="Value" className="mb-3 mb-lg-4">
                        <Form.Control type="text" placeholder="Value" className={`${errors.value?.message ? 'is-invalid' : ''}`}  {...register("value")} />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                    <FloatingLabel controlId="authorization-date" label="Check number" className="mb-3 mb-lg-4">
                        <Form.Control type="text" placeholder="Check number" className={`${errors.check_number?.message ? 'is-invalid' : ''}`}  {...register("check_number")} />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">{errors.check_number?.message}</Form.Control.Feedback>
                    {/* <FloatingLabel controlId="attach-file" label="Attach file" className="mb-3 mb-lg-4">
                        <Form.Control type="file" placeholder="Attach file" />
                    </FloatingLabel> */}
                    <Button variant="outline-primary" onClick={handleAddFileControl}>
                        Add File
                    </Button>
                    {files.map((file, index) => (
                        <div key={index}>
                            <img className="mt-4" src={`data:image/png;base64, ${file.data}`} alt={file.name} style={{ width: '100%', height: '200px' }} />
                            <img onClick={() => handleDeleteFile(index)} src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-20 cursor-pe position-absolute left-50%" />
                        </div>
                    ))}
                    <Accordion className="accordion-custom">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>More fileds</Accordion.Header>
                            <Accordion.Body>
                                <FloatingLabel label="Bank account">
                                    <Form.Select aria-label="Bank account" className={`${errors}`} {...register('bank_account')}>
                                        <option value="1">To carry out</option>
                                        <option value="2">Performed</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="h4">Summary</div>
                    <table className="table table-custom table-custom-bordered mb-0">
                        <thead className="p-0 m-0">
                            <tr>
                                <th className="p-0 m-0"></th>
                                <th className="p-0 m-0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="d-block">{t("OriginalValue")}</span>
                                    <span className="d-block">{t("Additions")}</span>
                                    <span className="d-block">{t("formReceipt.discount")}</span>
                                </td>
                                <td className="text-end">
                                    <span className="d-block">-100.00</span>
                                    <span className="d-block">0.00</span>
                                    <span className="d-block">0.00</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="d-block">Paid</span>
                                </td>
                                <td className="text-end">
                                    <span className="d-block">-100.00</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={liquidateClose2}>
                        {t("button.close")}
                    </Button>
                    <Button variant="primary" type='submit' onClick={openErrorbox}>
                        Liquidate
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default Liquidate2
