import { contractPauseForm, reverseForm, contractSearchForm, indexForm, newExtraChargeForm, checklistsForm } from '../../component/Interface';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
import customerService from '../../services/customerService';
import transferService from '../../services/transferService';
import Swal from 'sweetalert2';

export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCEESS: "success",
  ERROR: "error"
});

export interface Columns {
  id: number;
  name: string;
  email: string;
  telephone: string;
  responsible: string;
  observation: string;
  status: number;
  itemName: string;
  bank_code: string;
  bank_name: string;
  bankname: string;
  accountRef: string;
  account: string;
  rentDate: string;
  rent: string;
  adm: string;
  clientId: string;
  clientName: string;
  start: string;
  end: string;
  rentExpnse: string;
  totalRentExpnse: string;
  code: string;
  indexDate: string;
  indexValue: string;
}

interface SliceState {
  suppliersData: Columns[] | any,
  checklistData: Columns[] | any,
  checklistItems: Columns[] | any,
  contractChecklist: Columns[] | any,
  accountData: Columns[] | any,
  billingData: Columns[] | any,
  generateMeatData: Columns[] | any,
  generateChargeData: Columns[] | any,
  serviceCodeData: Columns[] | any,
  indexData: Columns[] | any,
  readjustData: Columns[] | any,
  readjustData_threemonth: Columns[] | any,
  readjustData_based_on_name: Columns[] | any,
  simulateData: Columns[] | any,
  indexedData: Columns[] | any,
  indexedData_based_on_name: Columns[] | any,
  fetch_allFinancialIndexing_data: Columns[] | any,
  singleIndexData: Columns[] | any,
  contPeninfChck: contractSearchForm[] | any,
  chargeData: newExtraChargeForm[] | any,
  singleChargeData: newExtraChargeForm[] | any,
  category: any,
  chargeStatus: string;
  newContractShow: boolean,
  expType: boolean,
  newChecklistShow: boolean,
  newChecklistItemShow: boolean,
  newQuestionContract: boolean,
  newPauseContract: boolean;
  status: string,
  accountStatus: string,
  billingStatus: string,
  checklistStatus: string,
  singleChargeStatus: string,
  generateMeatStatus: string,
  generateChargeStatus: string,
  serviceCodeStatus: string,
  indexStatus: string,
  readjustStatus: string,
  readjustStatus_threemonth: string,
  readjustStatus_based_on_name: string,
  simulateStatus: string,
  indexedStatus: string,
  indexedStatus_based_on_name: string,
  fetch_allFinancialIndexing_status: string,
  newIndexShow: boolean,
  newIndexedShow: boolean,
  indexId: string,
  newReadjustShow: boolean,
  reverseShow: boolean,
  reverseId: string,
  show: boolean
  typeCustomerAndEmp: string;
  typeCustomerAndEmp1: string;
  typeOfContract: string,
  checklistScroll: string
}

const initialState: SliceState = {
  suppliersData: [],
  checklistData: [],
  checklistItems: [],
  contractChecklist: [],
  contPeninfChck: [],
  accountData: [],
  billingData: [],
  chargeData: [],
  singleChargeData: [],
  generateMeatData: [],
  generateChargeData: [],
  serviceCodeData: [],
  indexData: [],
  readjustData: [],
  readjustData_threemonth: [],
  readjustData_based_on_name: [],
  simulateData: [],
  indexedData: [],
  indexedData_based_on_name: [],
  fetch_allFinancialIndexing_data: [],
  singleIndexData: [],
  category: [],
  newContractShow: false,
  expType: true,
  newChecklistShow: false,
  newChecklistItemShow: false,
  newQuestionContract: false,
  newPauseContract: false,
  newIndexShow: false,
  newReadjustShow: false,
  newIndexedShow: false,
  reverseShow: false,
  show: false,
  chargeStatus: STATUSES.IDLE,
  status: STATUSES.IDLE,
  accountStatus: STATUSES.IDLE,
  billingStatus: STATUSES.IDLE,
  checklistStatus: STATUSES.IDLE,
  singleChargeStatus: STATUSES.IDLE,
  generateMeatStatus: STATUSES.IDLE,
  generateChargeStatus: STATUSES.IDLE,
  serviceCodeStatus: STATUSES.IDLE,
  indexStatus: STATUSES.IDLE,
  readjustStatus: STATUSES.IDLE,
  readjustStatus_threemonth: STATUSES.IDLE,
  readjustStatus_based_on_name: STATUSES.IDLE,
  simulateStatus: STATUSES.IDLE,
  indexedStatus: STATUSES.IDLE,
  indexedStatus_based_on_name: STATUSES.IDLE,
  fetch_allFinancialIndexing_status: STATUSES.IDLE,
  indexId: '',
  reverseId: '',
  typeCustomerAndEmp: "",
  typeCustomerAndEmp1: "",
  typeOfContract: "",
  checklistScroll: ""
};
let user_id = gettoken.userId

const expenditureDataSlice = createSlice({
  name: "expenditureDataSlice",
  initialState,
  reducers: {
    contractClose: (state, action) => {
      state.newContractShow = action.payload
    },
    contractOpen: (state, action) => {
      state.newContractShow = action.payload
    },
    questionContractClose: (state, action) => {
      state.newQuestionContract = action.payload
    },
    questionContractOpen: (state, action) => {
      state.newQuestionContract = action.payload
    },
    pauseContractClose: (state, action) => {
      state.newPauseContract = action.payload
    },
    pauseContractOpen: (state, action) => {
      state.newPauseContract = action.payload
    },
    checklistClose: (state, action) => {
      state.newChecklistShow = action.payload
    },
    checklistOpen: (state, action) => {
      state.newChecklistShow = action.payload
    },
    checklistItemClose: (state, action) => {
      state.newChecklistItemShow = action.payload
    },
    checklistItemOpen: (state, action) => {
      state.newChecklistItemShow = action.payload
    },
    indexClose: (state, action) => {
      state.newIndexShow = action.payload
    },
    indexOpen: (state, action) => {
      state.newIndexShow = action.payload
    },
    readjustClose: (state, action) => {
      state.newReadjustShow = action.payload
    },
    readjustOpen: (state, action) => {
      state.newReadjustShow = action.payload
    },
    setExpType: (state, action) => {
      state.expType = action.payload
    },
    setIndexId: (state, action) => {
      state.indexId = action.payload
    },
    indexedClose: (state, action) => {
      state.newIndexedShow = action.payload
    },
    indexedOpen: (state, action) => {
      state.newIndexedShow = action.payload
    },
    reverseClose: (state, action) => {
      state.reverseShow = action.payload
    },
    reverseOpen: (state, action) => {
      state.reverseShow = action.payload
    },
    reverseIdSet: (state, action) => {
      state.reverseId = action.payload
    },
    openNewGuarantors: (state, action) => {
      state.show = action.payload
    },
    openTypeCustomer: (state, action) => {
      state.typeCustomerAndEmp = action.payload
      localStorage.setItem("Suppliers", action.payload);
    },
    openTypeCustomer1: (state, action) => {
      localStorage.setItem("Suppliers", action.payload);
      state.typeCustomerAndEmp1 = action.payload
    },
    typeofcontract: (state, action) => {
      state.typeOfContract = action.payload
    },
    getChecklist: (state, action) => {
      state.checklistScroll = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSuppliers.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllSuppliers.fulfilled, (state, action: PayloadAction<any>) => {
        state.suppliersData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllSuppliers.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertChecklists.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertChecklists.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertChecklists.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(contractPause.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(contractPause.fulfilled, (state, action) => {
        let response = action
        state.status = STATUSES.SUCEESS;
      })
      .addCase(contractPause.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertReverseCharge.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertReverseCharge.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertReverseCharge.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertIndexs.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertIndexs.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertIndexs.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertLunchIndex.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertLunchIndex.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertLunchIndex.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertReadjustContract.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertReadjustContract.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertReadjustContract.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllChecklists.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllChecklists.fulfilled, (state, action) => {
        state.checklistData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllChecklists.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllChecklistItems.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllChecklistItems.fulfilled, (state, action) => {
        state.checklistItems = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllChecklistItems.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllAccounts.pending, (state) => {
        state.accountStatus = STATUSES.LOADING;
      })
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.accountData = action.payload
        state.accountStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllAccounts.rejected, (state, action) => {
        state.accountStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllBillMethodListing.pending, (state) => {
        state.billingStatus = STATUSES.LOADING;
      })
      .addCase(getAllBillMethodListing.fulfilled, (state, action) => {
        state.billingData = action.payload
        state.billingStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllBillMethodListing.rejected, (state, action) => {
        state.billingStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllContractChecklist.pending, (state) => {
        state.checklistStatus = STATUSES.LOADING;
      })
      .addCase(getAllContractChecklist.fulfilled, (state, action) => {
        state.contractChecklist = action.payload
        state.checklistStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllContractChecklist.rejected, (state, action) => {
        state.checklistStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllContPendingChck.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllContPendingChck.fulfilled, (state, action) => {
        state.contPeninfChck = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllContPendingChck.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllContractCharge.pending, (state) => {
        state.chargeStatus = STATUSES.LOADING;
      })
      .addCase(getAllContractCharge.fulfilled, (state, action) => {
        state.chargeData = action.payload
        state.chargeStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllContractCharge.rejected, (state, action) => {
        state.chargeStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getSingleContractCharge.pending, (state) => {
        state.singleChargeStatus = STATUSES.LOADING;
      })
      .addCase(getSingleContractCharge.fulfilled, (state, action) => {
        state.singleChargeData = action.payload.data
        state.singleChargeStatus = STATUSES.SUCEESS;
      })
      .addCase(getSingleContractCharge.rejected, (state, action) => {
        state.singleChargeStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getGenerateMeat.pending, (state) => {
        state.generateMeatStatus = STATUSES.LOADING;
      })
      .addCase(getGenerateMeat.fulfilled, (state, action) => {
        state.generateMeatData = action.payload
        state.generateMeatStatus = STATUSES.SUCEESS;
      })
      .addCase(getGenerateMeat.rejected, (state, action) => {
        state.generateMeatStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getGenerateCharge.pending, (state) => {
        state.generateChargeStatus = STATUSES.LOADING;
      })
      .addCase(getGenerateCharge.fulfilled, (state, action) => {
        state.generateChargeData = action.payload
        state.generateChargeStatus = STATUSES.SUCEESS;
      })
      .addCase(getGenerateCharge.rejected, (state, action) => {
        state.generateChargeStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllServiceCode.pending, (state) => {
        state.serviceCodeStatus = STATUSES.LOADING;
      })
      .addCase(getAllServiceCode.fulfilled, (state, action) => {
        state.serviceCodeData = action.payload
        state.serviceCodeStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllServiceCode.rejected, (state, action) => {
        state.serviceCodeStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllIndexs.pending, (state) => {
        state.indexStatus = STATUSES.LOADING;
      })
      .addCase(getAllIndexs.fulfilled, (state, action) => {
        state.indexData = action.payload
        state.indexStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllIndexs.rejected, (state, action) => {
        state.indexStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllLunchIndexs.pending, (state) => {
        state.indexedStatus = STATUSES.LOADING;
      })
      .addCase(getAllLunchIndexs.fulfilled, (state, action) => {
        state.indexedData = action.payload
        state.indexedStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllLunchIndexs.rejected, (state, action) => {
        state.indexedStatus = STATUSES.ERROR;
      });
    builder
      .addCase(fetch_allFinancialIndexing.pending, (state) => {
        state.fetch_allFinancialIndexing_status = STATUSES.LOADING;
      })
      .addCase(fetch_allFinancialIndexing.fulfilled, (state, action) => {
        state.fetch_allFinancialIndexing_data = action.payload.data
        state.fetch_allFinancialIndexing_status = STATUSES.SUCEESS;
      })
      .addCase(fetch_allFinancialIndexing.rejected, (state, action) => {
        state.fetch_allFinancialIndexing_status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllLunchIndexs_based_on_Name.pending, (state) => {
        state.indexedData_based_on_name = [];
        state.indexedStatus_based_on_name = STATUSES.LOADING;
      })
      .addCase(getAllLunchIndexs_based_on_Name.fulfilled, (state, action) => {
        state.indexedData_based_on_name = action.payload.data
        state.indexedStatus_based_on_name = STATUSES.SUCEESS;
      })
      .addCase(getAllLunchIndexs_based_on_Name.rejected, (state, action) => {
        state.indexedData_based_on_name = [];
        state.indexedStatus_based_on_name = STATUSES.ERROR;
      });
    builder
      .addCase(getAllReadjustIndexs.pending, (state) => {
        state.readjustStatus = STATUSES.LOADING;
      })
      .addCase(getAllReadjustIndexs.fulfilled, (state, action) => {
        state.readjustData = action.payload
        state.readjustStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllReadjustIndexs.rejected, (state, action) => {
        state.readjustStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getThreemonth.pending, (state) => {
        state.readjustStatus_threemonth = STATUSES.LOADING;
      })
      .addCase(getThreemonth.fulfilled, (state, action) => {
        state.readjustData_threemonth = action.payload
        state.readjustStatus_threemonth = STATUSES.SUCEESS;
      })
      .addCase(getThreemonth.rejected, (state, action) => {
        state.readjustStatus_threemonth = STATUSES.ERROR;
      });
    builder
      .addCase(getAllReadjustIndexs_based_on_name.pending, (state) => {
        state.readjustStatus_based_on_name = STATUSES.LOADING;
      })
      .addCase(getAllReadjustIndexs_based_on_name.fulfilled, (state, action) => {
        state.readjustData_based_on_name = action.payload.data
        state.readjustStatus_based_on_name = STATUSES.SUCEESS;
      })
      .addCase(getAllReadjustIndexs_based_on_name.rejected, (state, action) => {
        state.readjustStatus_based_on_name = STATUSES.ERROR;
      });
    builder
      .addCase(getReadSimulateContract.pending, (state) => {
        state.simulateStatus = STATUSES.LOADING;
      })
      .addCase(getReadSimulateContract.fulfilled, (state, action) => {
        state.simulateData = action.payload
        state.simulateStatus = STATUSES.SUCEESS;
      })
      .addCase(getReadSimulateContract.rejected, (state, action) => {
        state.simulateStatus = STATUSES.ERROR;
      });
    builder
      .addCase(deleteIndexs.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteIndexs.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(deleteIndexs.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(indexDatails.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(indexDatails.fulfilled, (state, action: PayloadAction<any>) => {
        state.singleIndexData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(indexDatails.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllSuplierCate.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllSuplierCate.fulfilled, (state, action: PayloadAction<any>) => {
        state.category = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllSuplierCate.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
  }
})
export const { pauseContractClose, pauseContractOpen, typeofcontract, getChecklist, questionContractOpen, questionContractClose, reverseIdSet, reverseOpen, reverseClose, readjustOpen, readjustClose, indexedOpen, indexedClose, setIndexId, indexOpen, indexClose, setExpType, checklistOpen, checklistItemOpen, checklistClose, checklistItemClose, contractClose, contractOpen, openNewGuarantors, openTypeCustomer, openTypeCustomer1 } = expenditureDataSlice.actions;
export default expenditureDataSlice.reducer

/*----------Get the single index details api-----------*/

export const indexDatails = createAsyncThunk(
  'property/fetchIndexDatails',
  async (id: number) => {
    let resp = await customerService.getIndexDatails(id);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the generate meat simulate api-----------*/

export const getGenerateMeat = createAsyncThunk(
  'property/fetchGenerateMeatData',
  async (id: number) => {
    let resp = await customerService.getGenerateMeat(id);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);

export const getGenerateCharge = createAsyncThunk(
  'property/fetchenerateChargeData',
  async (postData: any) => {
    let resp = await customerService.getGenerateCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);

/*----------Delete index api-----------*/

export const deleteIndexs = createAsyncThunk(
  'contract/deleteIndexs',
  async (postData: any) => {
    postData.userId = gettoken.userId
    let resp = await customerService.deleteIndexs(postData);
    return resp
  }
);

/*----------Get the single contract charge  api-----------*/

export const getSingleContractCharge = createAsyncThunk(
  'property/fetchSingleContractChargeData',
  async (id: number) => {
    let resp = await customerService.getSingleContractCharge(id);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contract pending checklists api-----------*/

export const getAllContractCharge = createAsyncThunk(
  'property/fetchAllContractChargeData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllContractCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getAllContractChargeForterminaton = createAsyncThunk(
  'property/fetchAllContractChargeData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllContractCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contract pending checklists api-----------*/

export const getAllContPendingChck = createAsyncThunk(
  'property/fetchAllContPendingChckData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllContPendingChck(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contract checklists api-----------*/

export const getAllContractChecklist = createAsyncThunk(
  'property/fetchAllContractChecklistData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllContractChecklist(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the checklists item api-----------*/

export const getAllChecklistItems = createAsyncThunk(
  'property/fetchAllChecklistItemsData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllChecklistItems(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the user account api-----------*/

export const getAllAccounts = createAsyncThunk(
  'property/fetchAllAccountsData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllAccounts(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the billing methid api-----------*/

export const getAllBillMethodListing = createAsyncThunk(
  'property/fetchAllBillMethodData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllBillMethodListing(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the category in Supplier methid api-----------*/

export const getAllSuplierCate = createAsyncThunk(
  'property/getAllSuplierCate',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllSuppliersCate(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Get the checklists api-----------*/

export const getAllChecklists = createAsyncThunk(
  'property/fetchAllChecklistsData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllChecklists(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the suppliers api-----------*/

export const getAllSuppliers = createAsyncThunk(
  'property/fetchAllSuppliersData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllSuppliers(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the readustment indexed api-----------*/

export const getAllLunchIndexs = createAsyncThunk(
  'property/fetchAllLunchIndexsData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllLunchIndexs(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const fetch_allFinancialIndexing = createAsyncThunk(
  'property/fetch_allFinancialIndexing',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.fetch_allFinancialIndexing(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getAllLunchIndexs_based_on_Name = createAsyncThunk(
  'property/getAllLunchIndexs_based_on_Name',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllLunchIndexs_based_on_Name(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contract readustment indexed api-----------*/

export const getReadSimulateContract = createAsyncThunk(
  'property/fetchReadSimulateContractData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getReadSimulateContract(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contract readustment indexed api-----------*/

//working herrr
export const getAllReadjustIndexs = createAsyncThunk(
  'property/fetchAllReadjustIndexsData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllReadjustIndexs(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getThreemonth = createAsyncThunk(
  'property/getThreemonth',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getThreeReadjustIndexs(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

//working herrr
export const getAllReadjustIndexs_based_on_name = createAsyncThunk(
  'property/getAllReadjustIndexs_based_on_name',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllReadjustIndexs_based_on_name(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Get the readustment index api-----------*/

export const getAllIndexs = createAsyncThunk(
  'property/fetchAllIndexsData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllIndexs(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the service code api-----------*/

export const getAllServiceCode = createAsyncThunk(
  'property/fetchAllServiceCodeData',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllServiceCode(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the contract readjustment data api-----------*/

export const insertReadjustContract = createAsyncThunk(
  'contract/insertReadjustContractData',
  async (postData: indexForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertReadjustContract(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the readjustment indexed data api-----------*/

export const insertLunchIndex = createAsyncThunk(
  'contract/insertLunchIndexData',
  async (postData: indexForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertLunchIndex(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const insertLunchIndex1 = createAsyncThunk(
  'contract/insertLunchIndexData1',
  async (postData: indexForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertLunchIndex1(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getSingleLunchIndex = createAsyncThunk(
  'contract/getSingleLunchIndex',
  async (postData: indexForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getSingleLunchIndex(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the index data api-----------*/

export const insertReverseCharge = createAsyncThunk(
  'contract/insertReverseChargeData',
  async (postData: reverseForm) => {
    postData.userId = gettoken.userId
    let resp = await transferService.insertReverseCharge(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the index data api-----------*/

export const insertIndexs = createAsyncThunk(
  'contract/insertIndexsData',
  async (postData: indexForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertIndexs(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const insertIndexs1 = createAsyncThunk(
  'contract/insertIndexsData1',
  async (postData: indexForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertIndexs1(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the checklist data api-----------*/

export const insertChecklists = createAsyncThunk(
  'contract/insertChecklistsData',
  async (postData: checklistsForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.insertChecklists(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the Contract pause data api-----------*/

export const contractPause = createAsyncThunk(
  'contract/contractPauseData',
  async (postData: contractPauseForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.contractPause(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the user account api-----------*/

export const getAllBanks = createAsyncThunk(
  'property/fetchAllBanks',
  async (postData: contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await customerService.getAllBanks(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);