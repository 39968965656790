import React from 'react';
import { Modal, Form, FloatingLabel, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import customerService from '../../../../services/customerService';
import { useDispatch } from 'react-redux';
import { listTemplate } from '../../../../redux/slice/SendEmailSlice';
import { AppDispatch } from '../../../../redux/store';

const NewFolder = (props) => {
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { showNewFolder, newFolderClose } = props;

    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {

        if (data.folderName !== "" && data.folderName !== null && data.folderName !== undefined) {
            // Handle form submission logic here
            //console.log(data, "folder name"); // You can access form data here

            let response = await customerService.createNewTemplateFolder(data);
            //console.log("resp of insert folder", response);
            if (response.data.resCode == 202 || response.data.resCode == 201) {
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: "Folder Created",
                    confirmButtonText: "OK",
                    timer: 2000
                })
                await dispatch(listTemplate())
                newFolderClose();
                
            } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: "There is some issue in creating folder",
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                }

        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Please Write Folder Name",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }

    };

    return (
        <>
            <Modal show={showNewFolder} onHide={newFolderClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("DocumentTemplate.enterFolderName")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="folder_name" label={t("DocumentTemplate.folderName")}>
                            <Form.Control type="text" placeholder={t("DocumentTemplate.folderName")} {...register('folderName')} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit">
                            {t("button.confirm")}
                        </Button>
                        <Link to={"/empty_folder"} className="btn btn-outline-primary" onClick={newFolderClose}>
                            {t("button.cancel")}
                        </Link>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default NewFolder;
