import { Form,Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { historyClose,insertHistory,getAllHistory} from "../../../../redux/slice/propertyDataSlice";
import React,{ useEffect,useState } from 'react';
import * as Yup                     from 'yup';
import { useForm  }                 from "react-hook-form";
import { useNavigate,useParams }    from 'react-router-dom';
import { yupResolver }              from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState }   from '../../../../redux/store';
import { useTranslation }           from 'react-i18next';
import { historyForm }              from '../../../Interface';
import Swal                         from 'sweetalert2';
import moment from "moment-timezone";
import '../../../../App.css';

const HistoryService = (historyForm) => {
  const { getHistoryData } = historyForm;
  const [double,setDouble] = useState(false);
  const {id}               = useParams();
  const dispatch           = useDispatch<AppDispatch>()
  const [t]                = useTranslation();
  const { historyShow }    = useSelector((state: RootState) => state.propertyData)
  let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).format("MMM/YYYY");
  let endDt   = moment(newDate).add(2, 'M').format("MMM/YYYY");

  const [state, setState] = useState({
      propertyId: Number(id),
      startDate: startDt,
      endDate: endDt,
      history:"",
      start: 0,
      end: 20
  })
  const newHistoryClose = () => { 
    setDouble(false);
    dispatch(historyClose(false)); 
    reset(); 
  } 
  const historyValidation = Yup.object().shape({
    history: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired'))       
  });
  let frm = {
    resolver: yupResolver(historyValidation)
  } 
  const { register,reset,handleSubmit,setValue,getValues,formState: { errors } } = useForm<historyForm>(frm);
  const onSubmit = async (data: historyForm,e: any) => {
    e.preventDefault();
    setDouble(true)
    let response = await dispatch(insertHistory(data));
    if(typeof response.payload !=="undefined" && response.payload.resCode===202){
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:response.payload.message,
        confirmButtonText:"OK",
        timer:2000})
      let histData={
        "start"       : 0,
        "end"         : 20,
        "propertyId"  : response.payload.data            
      }     
      dispatch(getAllHistory(histData))
      newHistoryClose();
      getHistoryData(state);
    }
    else {
      setDouble(false)
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:response.payload.message,
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  }
  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={historyShow} onHide={newHistoryClose}>
        <Modal.Header closeButton>
          <Modal.Title>New History</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
            <FloatingLabel label="History" >
              <input type="hidden" {...register('id')} value={id} />
              <Form.Control rows={3}  as="textarea" id="history" placeholder="History" className={`form-control ${errors.history ? 'is-invalid' : ''}`} {...register('history')} style={{ height: '100px' }} />
              <Form.Control.Feedback type="invalid">{errors.history?.message}</Form.Control.Feedback>
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={newHistoryClose}>
              {t('button.close')}
            </Button>
            <Button disabled={double} type="submit" variant="primary">
              {t('button.toSave')}
            </Button>
          </Modal.Footer>            
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default HistoryService;